import React, { KeyboardEventHandler, useState } from 'react';
import { useTiktokInfluencerWideSearch } from './useTiktokInfluencerWideSearch';
import TiktokInfluencerWideSearchTable from './TiktokInfluencerWideSearchTable/TiktokInfluencerWideSearchTable';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import styles from './TiktokInfluencerWideSearch.module.css';
import useUrlState from 'Hooks/useUrlState';
import { showNotification } from 'helpers';
import {
    Button,
    Checkbox,
    DropdownOptionWithSubLabel,
    Label,
    OptionWithSubLabel,
    Popover,
    Select,
} from '@round/ui-kit';
import cn from 'classnames';
import { DebounceInput } from 'react-debounce-input';
import { ReactComponent as FiltersIcon } from '../../../../assets/FilterSearch.svg';
import { ReactComponent as DropdownIcon } from '../../../../assets/ReactSelectDropdownArrow.svg';
import { getPhylloLocations, OrderingValues, PhylloTiktokCreatorProfilesSearchSortableFields } from '@round/api';
import { StylesConfig, ValueType } from 'react-select';
import usePhylloLocationOptions from '../../../Phyllo/hooks/usePhylloLocationOptions';
import capitalize from 'lodash/capitalize';
import { GenericDropdownOption } from 'App.types';
import GroupedOptionMultiSelect from '../../../../ui/DataEntry/Select/GroupedSelectionsMultiSelect/GroupedSelectionsMultiSelect';
import PhylloAudienceLocationsMultiSelect, {
    PhylloLocationPercentageOption,
} from '../../../Phyllo/WideSearch/components/AudienceLocationsSelect/PhylloAudienceLocationsSelect';
import { ParsedPhylloAudienceLocationsQueryParamItem } from '@round/api';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';
import FollowerGrowthFilter from 'Modules/Phyllo/WideSearch/components/FollowerGrowthFilter/FollowerGrowthFilter';
import { sortableOptions } from 'Modules/TikTok/containers/TiktokInfluencerWideSearch/helpers';

type UrlState = {
    page: number;
    pageSize: number;
} & Partial<{
    ordering: OrderingValues<PhylloTiktokCreatorProfilesSearchSortableFields>;
    min_follower_count: number;
    max_follower_count: number;
    min_average_views: number;
    max_average_views: number;
    engagement_rate: number;
    bio_phrase: string;
    audience_lookalikes: string;
    creator_lookalikes: string;
    min_audience_locations: string;
    hashtags: string;
    has_contact_details: boolean;
    location: string;
    follower_growth_interval: number;
    follower_growth_percentage_value: number;
}>;

const initialState: UrlState = {
    page: 1,
    pageSize: 25,
    ordering: '-average_views',
};

const DEBOUNCE_TIMEOUT = 700;

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        borderColor: '#C2CFE0',
        minHeight: '2.5rem',
    }),
    valueContainer: (base) => ({
        ...base,
        flexWrap: 'nowrap',
    }),
};

const hashtagSelectStyles: StylesConfig = {
    ...selectStyles,
    ...{ valueContainer: (base) => ({ ...base, flexWrap: 'wrap' }) },
};

const hashtagSelectComponents = {
    DropdownIndicator: null,
    Menu: () => null,
    MenuList: () => null,
};

const TiktokInfluencerWideSearch = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialState, { shouldSetMissingInitialValues: true });
    const [isFiltersBarVisible, setIsFiltersBarVisible] = useState(false);

    const isTrialUser = useCheckUserGroupsAccess(['wide_search_trial']);

    const page = urlState.page ? Number(urlState.page) : initialState.page;
    const pageSize = urlState.pageSize ? Number(urlState.pageSize) : initialState.pageSize;
    const minFollowers = urlState.min_follower_count ? Number(urlState.min_follower_count) : undefined;
    const maxFollowers = urlState.max_follower_count ? Number(urlState.max_follower_count) : undefined;
    const minAvgViews = urlState.min_average_views ? Number(urlState.min_average_views) : undefined;
    const maxAvgViews = urlState.max_average_views ? Number(urlState.max_average_views) : undefined;
    const minEngagementRate = urlState.engagement_rate ? parseFloat(urlState.engagement_rate) : undefined;
    const followerGrowthPercentageValue = urlState.follower_growth_percentage_value
        ? Number(urlState.follower_growth_percentage_value)
        : undefined;
    const followerGrowthInterval = urlState.follower_growth_interval
        ? Number(urlState.follower_growth_interval)
        : undefined;

    const locationsSelectProps = usePhylloLocationOptions({ type: 'COUNTRY' });
    const [selectedLocations, setSelectedLocations] = useState<ValueType<DropdownOptionWithSubLabel<string>, true>>([]);
    const [isLocationsFilterInitialized, setIsLocationFilterInitialized] = useState(false);

    const { options: audienceLocationOptions, ...audienceLocationSelectProps } = usePhylloLocationOptions({
        type: 'COUNTRY',
    });
    const audienceLocationOptionsWithPercentage: PhylloLocationPercentageOption[] = audienceLocationOptions.map(
        (option) => ({
            ...option,
            percentage: null,
        })
    );

    const [selectedAudienceLocations, setSelectedAudienceLocations] = useState<PhylloLocationPercentageOption[]>([]);
    const selectedAudienceLocationsWithPercentageValue = selectedAudienceLocations.filter((opt) => !!opt.percentage);

    const handleSetAudienceLocations = (options: PhylloLocationPercentageOption[]) => {
        const incomingOptionsWithPercentageValue = options.filter((opt) => !!opt.percentage);
        const currentOptionsWithPercentageValue = selectedAudienceLocations.filter((opt) => !!opt.percentage);

        const shouldDataReset = incomingOptionsWithPercentageValue.length !== currentOptionsWithPercentageValue.length;

        setSelectedAudienceLocations(options);
        setUrlState({
            min_audience_locations: !!options.length
                ? JSON.stringify(options.map((opt) => [opt.value, opt.percentage]))
                : undefined,
            ...(shouldDataReset ? { page: 1 } : {}),
        });

        if (shouldDataReset) {
            reset();
        }
    };

    const { influencers, isLoading, hasError, isInitialized, count, init, reset } = useTiktokInfluencerWideSearch({
        page,
        page_size: pageSize,
        min_follower_count: minFollowers,
        max_follower_count: maxFollowers,
        min_average_views: minAvgViews,
        max_average_views: maxAvgViews,
        engagement_rate: minEngagementRate,
        ordering: urlState.ordering as OrderingValues<PhylloTiktokCreatorProfilesSearchSortableFields>,
        bio_phrase: urlState.bio_phrase?.trim(),
        hashtags: urlState.hashtags?.trim(),
        audience_lookalikes: urlState.audience_lookalikes?.trim(),
        creator_lookalikes: urlState.creator_lookalikes?.trim(),
        has_contact_details: isTrialUser ? undefined : urlState.has_contact_details === 'true' || undefined,
        min_audience_locations: selectedAudienceLocationsWithPercentageValue.length
            ? JSON.stringify(
                  selectedAudienceLocationsWithPercentageValue.map((opt) => [
                      opt.value,
                      typeof opt.percentage === 'number' ? opt.percentage / 100 : null,
                  ])
              ).replace(/"/g, '')
            : undefined,
        location: selectedLocations?.map((loc) => loc.value).join(),
        follower_growth_interval: followerGrowthInterval,
        follower_growth_percentage_value: followerGrowthPercentageValue
            ? followerGrowthPercentageValue / 100
            : undefined,
    });

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                init({ signal }).catch(() => {
                    showNotification('Error while loading influencers', 'error');
                });
            }
        },
        [isInitialized, init]
    );

    useAbortableEffect(
        (signal) => {
            const selectedLocationIds = selectedLocations?.map((loc) => loc.value) ?? [];
            const locationsToFetch = (urlState.location?.split(',').filter((item) => !!item) ?? []).filter(
                (loc) => !selectedLocationIds.includes(loc)
            );

            const partialAudienceLocationOptionsFromUrl: {
                value: string;
                percentage: number | null;
            }[] = urlState.min_audience_locations
                ? JSON.parse(urlState.min_audience_locations).map(
                      (el: ParsedPhylloAudienceLocationsQueryParamItem) => ({
                          value: el[0],
                          percentage: el[1],
                      })
                  )
                : [];

            const selectedAudienceLocationIds = selectedAudienceLocations.map((loc) => loc.value);
            const audienceLocationsToFetch = partialAudienceLocationOptionsFromUrl
                .map((loc) => loc.value)
                .filter((id) => !selectedAudienceLocationIds.includes(id));

            const allLocationsToFetch = locationsToFetch.concat(
                audienceLocationsToFetch.filter((id) => !locationsToFetch.includes(id))
            );

            if (allLocationsToFetch.length && !isLocationsFilterInitialized) {
                getPhylloLocations(
                    { uuid: allLocationsToFetch.join(), page_size: allLocationsToFetch.length },
                    { signal }
                )
                    .then((response) => {
                        setSelectedLocations(
                            response.data.results
                                .filter((item) => locationsToFetch.includes(item.uuid))
                                .map((item) => ({
                                    value: item.uuid,
                                    label: item.display_name,
                                    subLabel: capitalize(item.type),
                                }))
                        );

                        setSelectedAudienceLocations(
                            partialAudienceLocationOptionsFromUrl.map((opt) => {
                                const locationInResponse = response.data.results.find(
                                    (item) => item.uuid === opt.value
                                );
                                return {
                                    value: opt.value,
                                    label: locationInResponse?.display_name ?? opt.value,
                                    subLabel: capitalize(locationInResponse?.type ?? ''),
                                    percentage: opt.percentage,
                                };
                            })
                        );

                        setIsLocationFilterInitialized(true);
                    })
                    .catch((e) => {
                        if (e instanceof Error && e.name === 'AbortError') {
                            return;
                        }

                        showNotification('Could not initialize location filter', 'error');
                    });
            }
        },
        [
            isLocationsFilterInitialized,
            selectedLocations,
            selectedAudienceLocations,
            urlState.location,
            urlState.min_audience_locations,
        ]
    );

    const setPage = (page: number) => {
        setUrlState({ page: page });
        reset();
    };

    const setPageSize = (pageSize: number) => {
        setUrlState({ pageSize: pageSize });
        reset();
    };

    const selectedHashtags = urlState.hashtags?.split(',') ?? [];
    const [hashtagFilterRef, setHashtagFilterRef] = useState<HTMLButtonElement | null>(null);
    const [hashtagInputValue, setHashtagInputValue] = useState('');

    const handleHashtagKeyDown: KeyboardEventHandler = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();

            if (selectedHashtags.includes(hashtagInputValue)) {
                return;
            }

            setUrlState({
                hashtags: [...selectedHashtags, hashtagInputValue].toString(),
                page: 1,
            });
            reset();
            setHashtagInputValue('');
        }
    };

    const selectedHashtagsLabel =
        selectedHashtags && selectedHashtags.length > 0
            ? `${selectedHashtags.length} hashtag${selectedHashtags.length > 1 ? 's' : ''} selected`
            : '';

    const isTableLoading = isLoading || (!isInitialized && !hasError);

    return (
        <main className={styles.container}>
            <div className={styles.header}>
                <h1 className={styles.title}>Tiktok Influencer Wide Search</h1>

                <div className={styles.actions}>
                    <div className={styles.orderingSelectContainer}>
                        <Label className={styles.orderingSelectLabel} htmlFor="ordering">
                            Sort by
                        </Label>
                        <Select
                            id="ordering"
                            isSearchable={false}
                            className={styles.orderingSelect}
                            options={sortableOptions}
                            value={sortableOptions.find((o) => urlState.ordering === o.value)}
                            onChange={(option) => {
                                setUrlState({ ordering: option?.value });
                                reset();
                            }}
                        />
                    </div>

                    <Button
                        className={styles.showFiltersButton}
                        type="bordered"
                        onClick={() => setIsFiltersBarVisible((visible) => !visible)}
                    >
                        <FiltersIcon className={styles.showFiltersIcon} />
                        Filters
                    </Button>
                </div>
            </div>

            <div className={cn(styles.filters, { [styles.visible]: isFiltersBarVisible })}>
                <div className={styles.filtersRow}>
                    <div className={cn(styles.filter, styles.locationFilter)}>
                        <Label htmlFor="location" className={styles.filterLabel}>
                            Location
                        </Label>
                        <GroupedOptionMultiSelect
                            styles={selectStyles}
                            className={styles.filterSelect}
                            menuPortalTarget={document.body}
                            value={selectedLocations}
                            onChange={(value) => {
                                setSelectedLocations(value);
                                setUrlState({ location: value?.map((o) => o.value).join(','), page: 1 });
                                reset();
                            }}
                            components={{ Option: OptionWithSubLabel }}
                            placeholder="Search locations"
                            valueLabel={`${selectedLocations?.length} locations selected`}
                            {...locationsSelectProps}
                        />
                    </div>

                    <div className={styles.filter}>
                        <Label className={styles.filterLabel} htmlFor="audienceLocations">
                            Audience Locations
                        </Label>
                        <PhylloAudienceLocationsMultiSelect
                            value={selectedAudienceLocations}
                            options={audienceLocationOptionsWithPercentage}
                            onChange={handleSetAudienceLocations}
                            className={styles.hashtagFilter}
                            {...audienceLocationSelectProps}
                        />
                    </div>

                    <div className={cn(styles.filterGroup, styles.pair)}>
                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="audienceLookalikes">
                                Audience lookalike
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                id="audienceLookalikes"
                                value={urlState.audience_lookalikes}
                                onChange={(e) => {
                                    setUrlState({
                                        audience_lookalikes: e.target.value,
                                        page: 1,
                                    });
                                    reset();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                                placeholder="Enter username..."
                            />
                        </div>

                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="creatorLookalikes">
                                Creator lookalike
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                id="creatorLookalikes"
                                value={urlState.creator_lookalikes}
                                onChange={(e) => {
                                    setUrlState({
                                        creator_lookalikes: e.target.value,
                                        page: 1,
                                    });
                                    reset();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                                placeholder="Enter username..."
                            />
                        </div>
                    </div>

                    <div className={styles.filter}>
                        <Label className={styles.filterLabel} htmlFor="bioPhrase">
                            Bio phrase
                        </Label>
                        <DebounceInput
                            className={styles.filterInput}
                            id="bioPhrase"
                            value={urlState.bio_phrase}
                            onChange={(e) => {
                                setUrlState({
                                    bio_phrase: e.target.value,
                                    page: 1,
                                });
                                reset();
                            }}
                            debounceTimeout={DEBOUNCE_TIMEOUT}
                            placeholder="Enter phrase..."
                        />
                    </div>

                    <div className={styles.filter}>
                        <Label className={styles.filterLabel} htmlFor="hashtags">
                            Hashtags
                        </Label>
                        <>
                            <button ref={setHashtagFilterRef} className={styles.hashtagFilter}>
                                {selectedHashtags && selectedHashtags?.length > 0 ? (
                                    <span>{selectedHashtagsLabel}</span>
                                ) : (
                                    <span className={styles.placeholder}>Select hashtags...</span>
                                )}
                                <DropdownIcon />
                            </button>
                            <Popover
                                anchorElement={hashtagFilterRef}
                                showOn="click"
                                options={{
                                    placement: 'bottom-start',
                                    modifiers: [
                                        { name: 'offset', options: { offset: [0, 8] } },
                                        { name: 'recalcDependency', options: selectedHashtags },
                                    ],
                                }}
                            >
                                <div className={styles.hashtagDropdown}>
                                    <Select
                                        value={selectedHashtags.map((hashtag) => ({
                                            value: hashtag,
                                            label: hashtag,
                                        }))}
                                        onChange={(options: ValueType<GenericDropdownOption<string>, true>) => {
                                            setUrlState({
                                                hashtags: options?.length
                                                    ? options.map((opt) => opt.value).toString()
                                                    : undefined,
                                                page: 1,
                                            });
                                            reset();
                                        }}
                                        inputValue={hashtagInputValue}
                                        onInputChange={(value) => setHashtagInputValue(value)}
                                        onKeyDown={handleHashtagKeyDown}
                                        menuPortalTarget={null}
                                        isClearable
                                        isMulti
                                        autoFocus
                                        filterOption={null}
                                        placeholder="Type a hashtag and press Enter"
                                        components={hashtagSelectComponents}
                                        styles={hashtagSelectStyles}
                                    />
                                </div>
                            </Popover>
                        </>
                    </div>

                    {!isTrialUser && (
                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="hasContactDetails">
                                Has contact details
                            </Label>
                            <div className={styles.checkboxInputContainer}>
                                <Checkbox
                                    className={styles.filterCheckboxInput}
                                    value={urlState.has_contact_details === 'true'}
                                    onChange={(hasContactDetails) => {
                                        setUrlState({ has_contact_details: hasContactDetails || undefined, page: 1 });
                                        reset();
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.filtersRow}>
                    <div className={cn(styles.filterGroup, styles.pair)}>
                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="minFollowers">
                                Min followers
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                id="minFollowers"
                                type="number"
                                value={minFollowers}
                                onChange={(e) => {
                                    setUrlState({
                                        min_follower_count: e.target.value ? parseInt(e.target.value) : undefined,
                                        page: 1,
                                    });
                                    reset();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>

                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="maxFollowers">
                                Max followers
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                id="maxFollowers"
                                type="number"
                                value={maxFollowers}
                                onChange={(e) => {
                                    setUrlState({
                                        max_follower_count: e.target.value ? parseInt(e.target.value) : undefined,
                                        page: 1,
                                    });
                                    reset();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>
                    </div>

                    <div className={cn(styles.filterGroup, styles.pair)}>
                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="minAvgViews">
                                Min average views
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                id="minAvgViews"
                                type="number"
                                value={minAvgViews}
                                onChange={(e) => {
                                    setUrlState({
                                        min_average_views: e.target.value ? parseInt(e.target.value) : undefined,
                                        page: 1,
                                    });
                                    reset();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>

                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="maxAvgViews">
                                Max average views
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                id="maxAvgViews"
                                type="number"
                                value={maxAvgViews}
                                onChange={(e) => {
                                    setUrlState({
                                        max_average_views: e.target.value ? parseInt(e.target.value) : undefined,
                                        page: 1,
                                    });
                                    reset();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>
                    </div>

                    <div className={styles.filter}>
                        <Label className={styles.filterLabel} htmlFor="minEngagementRate">
                            Min engagement rate
                        </Label>
                        <DebounceInput
                            className={styles.filterInput}
                            id="minEngagementRate"
                            type="number"
                            value={minEngagementRate}
                            onChange={(e) => {
                                setUrlState({
                                    engagement_rate: e.target.value ? parseFloat(e.target.value) : undefined,
                                    page: 1,
                                });
                                reset();
                            }}
                            debounceTimeout={DEBOUNCE_TIMEOUT}
                        />
                    </div>

                    <div className={styles.filter}>
                        <Label className={styles.filterLabel} htmlFor="followerGrowth">
                            Min follower growth
                        </Label>
                        <FollowerGrowthFilter
                            value={{ interval: followerGrowthInterval, percentage: followerGrowthPercentageValue }}
                            onChange={({ percentage, interval }) => {
                                setUrlState({
                                    follower_growth_interval: interval,
                                    follower_growth_percentage_value: percentage,
                                    page: 1,
                                });
                                reset();
                            }}
                        />
                    </div>
                </div>
            </div>

            <TiktokInfluencerWideSearchTable
                rows={influencers}
                count={count}
                isLoading={isTableLoading}
                hasError={hasError}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
            />
        </main>
    );
};

export default TiktokInfluencerWideSearch;
