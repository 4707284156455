import { getDateDiff, resetTime } from '../../../../utility/utility';
import { MediaPlanItem } from '../../../../App.types';
import {
    FacebookDailyReportItem,
    GenericReportItem,
    Metrics as ReportMetrics,
    ReportItem,
    SnapchatDailyReportItem,
    TiktokDailyReportItem,
    TwitterDailyReportItem,
    YoutubeDailyReportItem,
} from '../types/MediaPlanResults.types';
import * as Metrics from '../../Metrics/Metrics';

export function getTargetingFromAdSetName(adSetName: string) {
    const result = adSetName.match(/\|\|(.+?)(?=\|)/m);
    if (!result) {
        return result;
    }

    return result[1].trim();
}

export function getCreativeNameFromAdName(adName: string) {
    const split = adName.split('|');
    if (split.length > 7) {
        return split[4].trim();
    } else if (split.length > 4) {
        return split[3].trim();
    }

    return null;
}

function calculateAmountSpent(startDate: Date, endDate: Date, cost: number): number {
    let amountSpent = 0;
    const currentDateInMs = Date.parse(resetTime(new Date()).toString());
    const startDateInMs = Date.parse(resetTime(startDate).toString());
    const endDateInMs = Date.parse(resetTime(endDate).toString());
    const duration = getDateDiff(startDateInMs, endDateInMs);
    const passedDays = getDateDiff(currentDateInMs, startDateInMs);

    if (currentDateInMs < startDateInMs) {
        amountSpent = 0;
    }

    if (currentDateInMs >= endDateInMs) {
        amountSpent = cost;
    }

    if (currentDateInMs >= startDateInMs && currentDateInMs < endDateInMs) {
        amountSpent = (passedDays / duration) * cost;
    }

    return amountSpent;
}

export const mapYouTubeDailyReportItemsToReportItem = (y: YoutubeDailyReportItem): ReportItem => ({
    mediaPlanItemId: y.media_plan_item_id,
    day: y.day,
    targeting: getTargetingFromAdSetName(y.ad_group),
    creative: getCreativeNameFromAdName(y.image_ad_name),
    amount_spent: y.amount_spent_in_media_plan_currency,
    impressions: y.impressions,
    cpm: Metrics.cpm(y.amount_spent_in_media_plan_currency, y.impressions),
    clicks: y.clicks,
    cpc: Metrics.cpc(y.amount_spent_in_media_plan_currency, y.clicks),
    views: y.views,
    cpv: Metrics.cpv(y.amount_spent_in_media_plan_currency, y.views),
    purchases: y.all_conversions,
    cpa: Metrics.cpa(y.amount_spent_in_media_plan_currency, y.all_conversions),
    revenue: Number(y.all_conversion_value),
    roas: Metrics.roas(Number(y.all_conversion_value), y.amount_spent_in_media_plan_currency),
    ctr: Metrics.ctr(y.clicks, y.impressions),
});

export const mapFacebookDailyReportItemToReportItem = (f: FacebookDailyReportItem): ReportItem => ({
    mediaPlanItemId: f.media_plan_item_id,
    day: f.day,
    targeting: getTargetingFromAdSetName(f.ad_set_name),
    creative: getCreativeNameFromAdName(f.ad_name),
    amount_spent: f.amount_spent_in_media_plan_currency,
    impressions: f.impressions,
    cpm: Metrics.cpm(f.amount_spent_in_media_plan_currency, f.impressions),
    clicks: f.link_clicks,
    cpc: Metrics.cpc(f.amount_spent_in_media_plan_currency, f.link_clicks),
    views: f.video_plays_3_sec,
    cpv: Metrics.cpv(f.amount_spent_in_media_plan_currency, f.video_plays_3_sec),
    purchases: f.purchases,
    cpa: Metrics.cpa(f.amount_spent_in_media_plan_currency, f.purchases),
    revenue: Number(f.purchases_conversion_value),
    roas: Metrics.roas(Number(f.purchases_conversion_value), f.amount_spent_in_media_plan_currency),
    leads: f.leads,
    cpl: Metrics.cpl(f.amount_spent_in_media_plan_currency, f.leads),
    ctr: Metrics.ctr(f.link_clicks, f.impressions),
});

export const mapSnapchatDailyReportItemToReportItem = (s: SnapchatDailyReportItem): ReportItem => ({
    mediaPlanItemId: s.media_plan_item_id,
    day: s.day,
    targeting: getTargetingFromAdSetName(s.ad_set_name),
    creative: getCreativeNameFromAdName(s.ad_name),
    amount_spent: s.amount_spent_in_media_plan_currency,
    impressions: s.paid_impressions,
    cpm: Metrics.cpm(s.amount_spent_in_media_plan_currency, s.paid_impressions),
    clicks: s.swipe_ups,
    cpc: Metrics.cpc(s.amount_spent_in_media_plan_currency, s.swipe_ups),
    views: s.video_views_2_sec,
    cpv: Metrics.cpv(s.amount_spent_in_media_plan_currency, s.video_views_2_sec),
    purchases: undefined,
    cpa: undefined,
    revenue: undefined,
    roas: undefined,
    ctr: Metrics.ctr(s.swipe_ups, s.paid_impressions),
});

export const mapTikTokDailyReportItemToReportItem = (tt: TiktokDailyReportItem): ReportItem => ({
    mediaPlanItemId: tt.media_plan_item_id,
    day: tt.day,
    targeting: getTargetingFromAdSetName(tt.ad_group_name),
    creative: getCreativeNameFromAdName(tt.ad_name),
    amount_spent: tt.amount_spent_in_media_plan_currency,
    impressions: tt.impressions,
    cpm: Metrics.cpm(tt.amount_spent_in_media_plan_currency, tt.impressions),
    clicks: tt.clicks,
    cpc: Metrics.cpc(tt.amount_spent_in_media_plan_currency, tt.clicks),
    views: tt.video_views,
    cpv: Metrics.cpv(tt.amount_spent_in_media_plan_currency, tt.video_views),
    purchases: tt.conversions,
    cpa: Metrics.cpa(tt.amount_spent_in_media_plan_currency, tt.conversions),
    revenue: tt.total_complete_payment_value ? Number(tt.total_complete_payment_value) : undefined,
    roas: Metrics.roas(Number(tt.total_complete_payment_value), tt.amount_spent_in_media_plan_currency),
    six_sec_views: tt.video_views_6_sec,
    six_sec_cpv: Metrics.cpv(tt.amount_spent_in_media_plan_currency, tt.video_views_6_sec),
    ctr: Metrics.ctr(tt.clicks, tt.impressions),
});

export const mapTwitterDailyReportItemToReportItem = (tw: TwitterDailyReportItem): ReportItem => ({
    mediaPlanItemId: tw.media_plan_item_id,
    day: tw.day,
    targeting: getTargetingFromAdSetName(tw.ad_group_name),
    tweetId: tw.tweet_id,
    tweetUsername: tw.tweet_username,
    tweetText: tw.tweet_text,
    amount_spent: tw.amount_spent_in_media_plan_currency,
    impressions: tw.impressions,
    cpm: Metrics.cpm(tw.amount_spent_in_media_plan_currency, tw.impressions),
    clicks: tw.link_clicks,
    cpc: Metrics.cpc(tw.amount_spent_in_media_plan_currency, tw.link_clicks),
    views: tw.video_views,
    cpv: Metrics.cpv(tw.amount_spent_in_media_plan_currency, tw.video_views),
    purchases: undefined,
    cpa: undefined,
    revenue: undefined,
    roas: undefined,
    ctr: Metrics.ctr(tw.link_clicks, tw.impressions),
});

export const createPlaylistReportItem = (mediaPlanItem: MediaPlanItem): ReportItem => {
    return createReportItem({
        mediaPlanItemId: mediaPlanItem.id,
        amount_spent: calculateAmountSpent(
            new Date(mediaPlanItem.start_date),
            new Date(mediaPlanItem.end_date),
            Number(mediaPlanItem.cost)
        ),
    });
};

export function createReportItem(options: Partial<GenericReportItem> & { mediaPlanItemId: number }): GenericReportItem {
    return {
        amount_spent: options.amount_spent ?? NaN,
        clicks: options.clicks ?? undefined,
        cpa: options.cpa,
        cpc: options.cpc,
        cpl: options.cpl,
        cpm: options.cpm,
        cpv: options.cpv,
        impressions: options.impressions,
        leads: options.leads,
        mediaPlanItemId: options.mediaPlanItemId,
        purchases: options.purchases,
        revenue: options.revenue,
        roas: options.roas,
        six_sec_cpv: options.six_sec_cpv,
        six_sec_views: options.six_sec_views,
        targeting: options.targeting ?? '-',
        view_rate: options.view_rate,
        views: options.views,
        creative: options.creative ?? '-',
        day: options.day ?? '',
    };
}

const sum = (reportItems: ReportMetrics[], field: keyof ReportMetrics) => {
    const hasNumericValues = reportItems.some((report) => {
        const value = report[field];
        return typeof value === 'number' && isFinite(value);
    });

    if (!hasNumericValues) {
        return NaN;
    }

    return reportItems.reduce((acc, reportItem) => (reportItem[field] ?? 0) + acc, 0);
};
export const getTotalReportItemValues = <T extends ReportMetrics>(reportItems: T[]): T => {
    const metrics: T = {
        ...reportItems[0],
        amount_spent: sum(reportItems, 'amount_spent'),
        impressions: sum(reportItems, 'impressions'),
        clicks: sum(reportItems, 'clicks'),
        views: sum(reportItems, 'views'),
        purchases: sum(reportItems, 'purchases'),
        revenue: sum(reportItems, 'revenue'),
        six_sec_views: sum(reportItems, 'six_sec_views'),
        leads: sum(reportItems, 'leads'),
    };

    return {
        ...metrics,
        cpc: Metrics.cpc(metrics.amount_spent, metrics.clicks),
        cpv: Metrics.cpv(metrics.amount_spent, metrics.views),
        cpm: Metrics.cpm(metrics.amount_spent, metrics.impressions),
        cpa: Metrics.cpa(metrics.amount_spent, metrics.purchases),
        roas: Metrics.roas(metrics.revenue, metrics.amount_spent),
        six_sec_cpv: Metrics.cpv(metrics.amount_spent, metrics.six_sec_views),
        cpl: Metrics.cpl(metrics.amount_spent, metrics.leads),
        view_rate: Metrics.viewRate(metrics.views, metrics.impressions),
        ctr: Metrics.ctr(metrics.clicks, metrics.impressions),
    };
};
export const aggregateReportItemsByMediaPlanItemId = <T extends ReportMetrics>(reportItems: T[]): T[] => {
    const aggregatedReportItemsObj: { [mediaPlanItemId: number]: T[] } = {};
    const aggregatedReportItems: T[] = [];

    for (const reportItem of reportItems) {
        aggregatedReportItemsObj[reportItem.mediaPlanItemId] =
            aggregatedReportItemsObj[reportItem.mediaPlanItemId] || [];
        aggregatedReportItemsObj[reportItem.mediaPlanItemId].push(reportItem);
    }

    for (const mediaPlanItemId in aggregatedReportItemsObj) {
        const reportItemsArr: T[] = aggregatedReportItemsObj[mediaPlanItemId];
        const totalReportItemValues = getTotalReportItemValues(reportItemsArr);
        aggregatedReportItems.push(totalReportItemValues);
    }

    return aggregatedReportItems;
};
