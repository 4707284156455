import React from 'react';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import styles from './InstagramInfluencerSearchPage.module.css';
import InstagramWideSearch from '../../../Modules/Instagram/InstagramWideSearch/InstagramWideSearch';
import { Card } from '@round/ui-kit';

const InstagramWideSearchPage = () => (
    <>
        <NavBar />
        <Card className={styles.container}>
            <InstagramWideSearch />
        </Card>
    </>
);

export default InstagramWideSearchPage;
