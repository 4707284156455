import { ApiResponse, NotFoundResponse } from '../../types';
import { VoteLink } from './voteLinks.types';
import { call } from '../../helpers';

export type GetVoteLinkResponse = ApiResponse<VoteLink, 200> | NotFoundResponse;

export async function getVoteLink(voteLinkId: string, requestInit?: RequestInit): Promise<GetVoteLinkResponse> {
    const response = await call(`/api/fandom/vote-links/${voteLinkId}/`, requestInit, { shouldBypassTokens: true });

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get vote link');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
