import { TiktokPostInviteForm } from './tiktokPostInviteForm.types';
import { AppServer } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokPostInviteForm: Model as ModelDefinition<TiktokPostInviteForm>,
};

export const serializers = {
    tiktokPostInviteForm: makeSerializer<TiktokPostInviteForm>([]),
};

export const factories = {
    tiktokPostInviteForm: createFactory<TiktokPostInviteForm>({
        audio_url: (index: number) => `audio_url${index}`,
        username: (index: number) => `username ${index}`,
    }),
};

export function handleRequests(server: AppServer) {
    server.get('/api/microwave/tiktok-post-invite-forms/:id/', (schema, request) => {
        const tiktokPostInviteForm = schema.find('tiktokPostInviteForm', request.params.id);
        if (!tiktokPostInviteForm) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return tiktokPostInviteForm;
    });
}
