import React, { useState } from 'react';
import { useCheckUserGroupsAccess } from '../../../../../Auth/hooks/useCheckUserGroupsAccess';
import styles from './PromotionsTab.module.css';
import PreviousPromotions from './PreviousPromotions/PreviousPromotions';
import PromotionsScatterChart from './PromotionsScatterChart/PromotionsScatterChart';
import { useTiktokUserPromotions } from '../TiktokUserDataContext/TiktokUserDataContext';
import { getTiktokVideos } from '../../../../TikTok.api';
import useAbortableEffect from '../../../../../../Hooks/useAbortableEffect';
import { InfluencerPlan } from '../../../../../../App.types';
import { getInfluencerPlans } from '../../../../../Advertising/InfluencerPlan/InfluencerPlan.api';
import isNumber from 'lodash/isNumber';

type PromotionsTabProps = {
    influencerUserId: number | undefined | null;
    userId: number | undefined | null;
};

export type VideoStatsWithData = {
    id: number;
    digg_count: number;
    share_count: number;
    comment_count: number;
    play_count: number;
    save_count: number | null;
    timestamp: string;
    video_id: number;
    create_time?: number;
    desc?: string;
};

const Promotions = ({ influencerUserId, userId }: PromotionsTabProps) => {
    const canAccess = useCheckUserGroupsAccess(['plan_viewer']);
    const {
        promotionsPostStats,
        promotionsPostStatsLoading,
        errorLoadingPromotionsPostStats,
        influencerPosts,
        influencerPostsLoading,
        errorLoadingInfluencerPosts,
        influencerPostsVideoStats,
        influencerPostsVideoStatsLoading,
        errorLoadingInfluencerPostsVideoStats,
    } = useTiktokUserPromotions(influencerUserId, userId);

    const [videoStatsWithData, setVideoStatsWithData] = useState<VideoStatsWithData[]>([]);
    const [influencerPlans, setInfluencerPlans] = useState<InfluencerPlan[]>([]);

    const dataLoading = promotionsPostStatsLoading || influencerPostsLoading || influencerPostsVideoStatsLoading;
    const errorLoadingData =
        errorLoadingPromotionsPostStats || errorLoadingInfluencerPosts || errorLoadingInfluencerPostsVideoStats;

    useAbortableEffect(() => {
        async function fetchTiktokVideos() {
            const videoIds = influencerPostsVideoStats.map((result) => result.id);
            const videos = videoIds.length ? await getTiktokVideos({ videoIds: videoIds }) : [];

            const statsWithData = influencerPostsVideoStats.map((result) => {
                const postVideo = videos.find((video) => video.id === result.video_id);
                return {
                    id: result.id,
                    digg_count: result.digg_count,
                    share_count: result.share_count,
                    comment_count: result.comment_count,
                    play_count: result.play_count,
                    timestamp: result.timestamp,
                    video_id: result.video_id,
                    create_time: postVideo?.create_time,
                    desc: postVideo?.desc,
                    save_count: result.save_count,
                };
            });
            setVideoStatsWithData(statsWithData);
        }
        fetchTiktokVideos();
    }, [influencerPostsVideoStats]);

    useAbortableEffect(
        (signal) => {
            const influencerPlanIds = Array.from(new Set(influencerPosts.map((p) => p.plan_id).filter(isNumber)));
            if (!influencerPlanIds.length) {
                return;
            }

            getInfluencerPlans(
                {
                    id: influencerPlanIds.toString(),
                    page_size: influencerPlanIds.length,
                },
                { signal }
            )
                .then((response) => setInfluencerPlans(response.results))
                .catch(() => {});
        },
        [influencerPosts]
    );

    if (!canAccess) {
        return <div className={styles.tabContainer}>No campaigns</div>;
    }

    return (
        <div className={styles.tabContainer}>
            <PromotionsScatterChart
                loading={dataLoading}
                errorLoading={errorLoadingData}
                influencerPosts={influencerPosts}
                results={videoStatsWithData}
                postStats={promotionsPostStats}
                influencerPlans={influencerPlans}
            />

            <PreviousPromotions
                loading={dataLoading}
                errorLoading={errorLoadingData}
                posts={influencerPosts}
                results={videoStatsWithData}
                influencerPlans={influencerPlans}
            />
        </div>
    );
};

export default Promotions;
