import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { TiktokHashtag } from './hashtags.types';

export const models = {
    tiktokHashtag: Model as ModelDefinition<TiktokHashtag>,
};

export const factories = {
    tiktokHashtag: createFactory<TiktokHashtag>({
        title(index: number) {
            return `tiktokHashtag title: ${index}`;
        },
        desc(index: number) {
            return `tiktokHashtag desc: ${index}`;
        },
        tiktok_id(index: number) {
            return `${index}`;
        },
    }),
};

export const serializers = {
    tiktokHashtag: makeSerializer<TiktokHashtag>([]),
};

export function handleRequests(server: AppServer) {
    const getTiktokHashtagsListPath = '/api/tiktok/hashtags/';

    server.get(getTiktokHashtagsListPath, function (this: RouteHandlerContext, schema, request) {
        const { search } = request.queryParams;
        const hashtags = schema
            .all('tiktokHashtag')
            .filter((stat) => !search || stat.title.includes(search) || stat.desc.includes(search));
        return buildPaginatedResponse(hashtags, {
            url: getTiktokHashtagsListPath,
            serialize: (resource) => this.serialize(resource, 'tiktokHashtag'),
            queryParams: request.queryParams,
        });
    });
}
