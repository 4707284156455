import React from 'react';
import cn from 'classnames';
import { MediaPlanItemGroup } from '@round/api';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/ArrowIcon.svg';
import styles from './MobileAdvertisingGroupBar.module.css';

type MobileAdvertisingGroupBarProps = {
    isExpanded: boolean;
    onExpandToggle: () => void;
    group: MediaPlanItemGroup;
    numberOfMediaPlanItems: number;
};

const MobileAdvertisingGroupBar = ({
    group,
    numberOfMediaPlanItems,
    isExpanded,
    onExpandToggle,
}: MobileAdvertisingGroupBarProps) => {
    const expandButtonLabel = isExpanded ? 'Collapse group' : 'Expand group';

    return (
        <div className={cn(styles.row, styles.group)} data-test-id={`advertising-group-${group.id}`}>
            <button
                className={cn(styles.button, styles.expandButton, { [styles.expanded]: isExpanded })}
                aria-label={expandButtonLabel}
                onClick={() => onExpandToggle()}
            >
                <ArrowIcon />
            </button>
            <div className={styles.groupInfoColumns}>
                <div className={styles.groupInfoColumn}>
                    <span className={cn(styles.groupInfoColumnHeading, styles.groupName)}>{group.name}</span>
                    <span className={styles.groupInfoColumnValue}>
                        {numberOfMediaPlanItems} plan item{numberOfMediaPlanItems !== 1 ? 's' : ''}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default MobileAdvertisingGroupBar;
