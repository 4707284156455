import React, { useCallback, useMemo, useState } from 'react';
import styles from './TikTokHashtagsExplore.module.css';
import { SearchBox } from '../../../../SharedComponents/SearchBox/SearchBox';
import { DebounceNumberInputBox } from '../../../../SharedComponents/DebounceNumberInputBox/DebounceNumberInputBox';
import useUrlState from '../../../../Hooks/useUrlState';
import { MonitoredTiktokHashtagStats } from '../../TikTok.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../../../helpers';
import {
    PaginatedApiResponseData,
    OrderByAscQueryParam,
    OrderByDescQueryParam,
    OrderByQueryParam,
} from '../../../../App.types';
import TikTokHashtagsList from '../../components/TikTokHashtagsList/TikTokHashtagsList';
import TikTokHashtagsModal from '../TikTokHashtagsModal/TikTokHashtagsModal';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import { Primitives } from '../../../../utility/utility.types';

type TikTokHashtagsExploreUrlState = {
    search: string;
    minVideoCount: number | undefined;
    maxVideoCount: number | undefined;
    minViewCount: number | undefined;
    maxViewCount: number | undefined;
} & OrderByQueryParam;

const TikTokHashtagsExplore = () => {
    const [hashtagStats, setHashtagStats] = useState<MonitoredTiktokHashtagStats[]>([]);
    const [hashtagStatsLoading, setHashtagStatsLoading] = useState(false);
    const [hashtagStatsCount, setHashtagStatsCount] = useState<number>();
    const [nextUrl, setNextUrl] = useState<string | null>(null);

    const [urlState, setUrlState] = useUrlState<TikTokHashtagsExploreUrlState>(
        {
            search: '',
            minVideoCount: 100,
            maxVideoCount: undefined,
            minViewCount: 0,
            maxViewCount: undefined,
            order_by_desc: 'daily_change_video_relative',
            order_by_asc: undefined,
        },
        {
            shouldSetMissingInitialValues: true,
        }
    );

    const queryParams = useMemo(() => {
        const params: Record<string, string | number> = {};
        if (urlState.search) {
            params['search'] = urlState.search;
        }

        if (typeof urlState.minVideoCount !== 'undefined') {
            params['min_video_count'] = urlState.minVideoCount;
        }

        if (typeof urlState.maxVideoCount !== 'undefined') {
            params['max_video_count'] = urlState.maxVideoCount;
        }

        if (typeof urlState.minViewCount !== 'undefined') {
            params['min_view_count'] = urlState.minViewCount;
        }

        if (typeof urlState.maxViewCount !== 'undefined') {
            params['max_view_count'] = urlState.maxViewCount;
        }

        if (urlState.order_by_desc) {
            params['order_by_desc'] = urlState.order_by_desc;
        }

        if (urlState.order_by_asc) {
            params['order_by_asc'] = urlState.order_by_asc;
        }

        return params;
    }, [
        urlState.maxVideoCount,
        urlState.maxViewCount,
        urlState.minVideoCount,
        urlState.minViewCount,
        urlState.order_by_asc,
        urlState.order_by_desc,
        urlState.search,
    ]);

    const [isHashtagModalOpen, setIsHashtagModalOpen] = useState(false);
    const [selectedHashtagId, setSelectedHashtagId] = useState<number>();

    const setSearchFilter = useCallback((search: string) => setUrlState({ search }), [setUrlState]);
    const setMinVideoCountFilter = useCallback((minVideoCount: number) => setUrlState({ minVideoCount }), [
        setUrlState,
    ]);
    const setMaxVideoCountFilter = useCallback((maxVideoCount: number) => setUrlState({ maxVideoCount }), [
        setUrlState,
    ]);
    const setMinViewCountFilter = useCallback((minViewCount: number) => setUrlState({ minViewCount }), [setUrlState]);
    const setMaxViewCountFilter = useCallback((maxViewCount: number) => setUrlState({ maxViewCount }), [setUrlState]);
    const setOrderByQueryParam = useCallback(
        (q: OrderByQueryParam) =>
            setUrlState({
                order_by_desc: (q as OrderByDescQueryParam).order_by_desc,
                order_by_asc: (q as OrderByAscQueryParam).order_by_asc,
            }),
        [setUrlState]
    );

    const fetchData = useCallback(async (url: string, requestInit?: RequestInit) => {
        const response = await fetchWithToken(url, requestInit);
        if (response.ok) {
            return (await response.json()) as PaginatedApiResponseData<MonitoredTiktokHashtagStats>;
        }
    }, []);

    const fetchMoreStats = useCallback(async () => {
        if (!nextUrl) {
            return;
        }

        setHashtagStatsLoading(true);
        const res = await fetchData(nextUrl);
        setNextUrl(res?.next ?? null);
        setHashtagStatsCount(res?.count);
        if (hashtagStats && res?.results) {
            setHashtagStats(hashtagStats.concat(res.results));
        }
        setHashtagStatsLoading(false);
    }, [fetchData, hashtagStats, nextUrl]);

    const setInitialStats = useCallback(
        async (params: Record<string, Primitives>, requestInit?: RequestInit) => {
            setHashtagStats([]);
            setHashtagStatsLoading(true);
            const queryString = encodeUrlSearchParams(params);
            const res = await fetchData(`/api/tiktok/monitored-hashtag-stats/${queryString}`, requestInit);
            if (res !== undefined) {
                setHashtagStats(res.results);
                setNextUrl(res.next);
                setHashtagStatsCount(res.count);
            }
            setHashtagStatsLoading(false);
        },
        [fetchData]
    );

    useAbortableEffect(
        (signal) => {
            // Hashtags list component will have at least sorting set,
            // so we can skip invoking without any params to fix initial aborted request
            if (Object.keys(queryParams).length) {
                setInitialStats(queryParams, { signal });
            }
        },
        [queryParams, setInitialStats]
    );

    return (
        <>
            <h1 className={styles.title}>TikTok Hashtags</h1>
            <div className={styles.toolBarContainer}>
                <div className={styles.toolBarLeft}>
                    <div className={styles.searchBoxItem}>
                        <SearchBox initialValue={urlState.search} setValue={setSearchFilter} />
                    </div>

                    <div className={styles.countItem}>
                        <div className={styles.filterLabel}>Min video count</div>
                        <DebounceNumberInputBox
                            initialValue={Number(urlState.minVideoCount)}
                            setValue={setMinVideoCountFilter}
                        />
                    </div>

                    <div className={styles.countItem}>
                        <div className={styles.filterLabel}>Max video count</div>
                        <DebounceNumberInputBox
                            initialValue={Number(urlState.maxVideoCount)}
                            setValue={setMaxVideoCountFilter}
                            placeholder="Unlimited"
                        />
                    </div>

                    <div className={styles.countItem}>
                        <div className={styles.filterLabel}>Min view count</div>
                        <DebounceNumberInputBox
                            initialValue={Number(urlState.minViewCount)}
                            setValue={setMinViewCountFilter}
                        />
                    </div>

                    <div className={styles.countItem}>
                        <div className={styles.filterLabel}>Max View count</div>
                        <DebounceNumberInputBox
                            initialValue={Number(urlState.maxViewCount)}
                            setValue={setMaxViewCountFilter}
                            placeholder="Unlimited"
                        />
                    </div>
                </div>
            </div>
            <TikTokHashtagsList
                orderByState={[
                    { order_by_asc: urlState.order_by_asc, order_by_desc: urlState.order_by_desc },
                    setOrderByQueryParam,
                ]}
                stats={hashtagStats}
                statsCount={hashtagStatsCount}
                openModal={(hashtagId) => {
                    setSelectedHashtagId(hashtagId);
                    setIsHashtagModalOpen(true);
                }}
                loadData={nextUrl ? fetchMoreStats : null}
                isLoadingData={hashtagStatsLoading}
            />

            <TikTokHashtagsModal
                isModalOpen={isHashtagModalOpen}
                closeModal={() => {
                    setSelectedHashtagId(undefined);
                    setIsHashtagModalOpen(false);
                }}
                hashtagId={selectedHashtagId}
            />
        </>
    );
};

export default TikTokHashtagsExplore;
