import React from 'react';
import styles from './InstagramInfluencerExplorePage.module.css';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import Card from '../../../ui/Card/Card';
import InstagramInfluencerExplore from './InstagramInfluencerList/InstagramInfluencerExplore';

const InstagramInfluencerExplorePage = () => {
    return (
        <>
            <NavBar />
            <Card className={styles.container}>
                <InstagramInfluencerExplore />
            </Card>
        </>
    );
};

export default InstagramInfluencerExplorePage;
