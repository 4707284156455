import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { InfluencerTag } from './influencerTag.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    influencerTag: Model as ModelDefinition<InfluencerTag>,
};

export const factories = {
    influencerTag: createFactory<InfluencerTag>({
        name(index: number) {
            return `Influencer tag: ${index}`;
        },
        type(index: number) {
            return index % 2 === 0 ? 'CATEGORY' : 'LOCATION';
        },
    }),
};

export const serializers = {
    influencerTag: makeSerializer<InfluencerTag>([]),
};

export function handleInfluencerTagRequests(server: AppServer) {
    const getInfluencerTagsListPath = '/api/advertising/viewsets/influencer-tag/';
    server.get(getInfluencerTagsListPath, function (this: RouteHandlerContext, schema, request) {
        const { page = 1, search = '' } = request.queryParams;
        const influencerTags = schema
            .all('influencerTag')
            .filter((tag) => tag.name.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(influencerTags, {
            url: getInfluencerTagsListPath,
            queryParams: { page: String(page), search },
            serialize: (resource) => this.serialize(resource, 'influencerTag'),
        });
    });
}
