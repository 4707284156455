import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { creatorbase } from '@round/api';
import { createReducer } from 'helpers';

export type TiktokCreations = creatorbase.TimeSeriesResponse<
    'tiktok_daily_change' | 'tiktok_daily_totals'
>[keyof creatorbase.TimeSeriesResponse];

export type State = DataState<TiktokCreations>;
export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export type Actions =
    | ReducerAction<'loadTiktokCreations'>
    | ReducerActionWithPayload<'tiktokCreationsInitialized', TiktokCreations>
    | ReducerActionWithPayload<'tiktokCreationsErrorLoading', string>;

export const reducer = createReducer<State, Actions>({
    loadTiktokCreations: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    tiktokCreationsErrorLoading: (state, { payload }) => ({
        ...state,
        status: 'error',
        error: payload,
    }),
    tiktokCreationsInitialized: (_, { payload }) => ({
        data: payload,
        error: null,
        status: 'success',
    }),
});
