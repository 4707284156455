import React, { CSSProperties } from 'react';
import styles from './OptionsList.module.css';
import SearchInput from '../../../../SearchInput/SearchInput';
import List, { ListItemProps } from '../../../../DataDisplay/List/List';
import { SelectBoxOption } from '../../SelectBox.types.';

export type OptionsListProps<T extends SelectBoxOption> = {
    searchable?: boolean;
    searchQuery?: string;
    setSearchQuery?: (searchQuery: string) => void;
    searchPlaceholder?: string;
    options: T[];
    count: number;
    listStyles?: CSSProperties;
    noOptionsLabel?: string;
    children: (props: ListItemProps<T>) => React.ReactNode;
    loading?: boolean;
    loadMore?: () => Promise<void>;
};

const OptionsList = <T extends SelectBoxOption>({
    options,
    count,
    listStyles,
    searchQuery,
    setSearchQuery,
    searchable,
    noOptionsLabel = 'No options',
    children,
    loading,
    loadMore,
    searchPlaceholder,
}: OptionsListProps<T>) => {
    const handleSearchQueryChange = (query: string) => {
        if (typeof setSearchQuery === 'function') {
            setSearchQuery(query);
        }
    };

    return (
        <div>
            {searchable && (
                <SearchInput
                    className={styles.searchInput}
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                    placeholder={searchPlaceholder}
                />
            )}
            <div className={styles.list} style={listStyles ?? { height: count * 70 }}>
                {!options.length && <span className={styles.noOptionsLabel}>{noOptionsLabel}</span>}
                {options.length > 0 && (
                    <List loading={loading} data={options} rowCount={count} loadMoreRows={loadMore}>
                        {(props) => {
                            if (!props.item && !props.loading) {
                                return null;
                            }

                            return children(props);
                        }}
                    </List>
                )}
            </div>
        </div>
    );
};

export default OptionsList;
