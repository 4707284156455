import { Context } from '@fluentui/react-context-selector';
import { youtube } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { makeErrorStateSlice, makeLoadingStateSlice, makeSuccessStateSlice } from 'utility/dataState';

type Data = youtube.Channel | null;

export type State = {
    [channelId: number]: DataState<Data> | undefined;
};

export const initialState: State = {};

export type Actions =
    | ReducerActionWithPayload<'loadYoutubeChannels', number[]>
    | ReducerActionWithPayload<'youtubeChannelsSuccess', { channelIds: number[]; channels: youtube.Channel[] }>
    | ReducerActionWithPayload<'youtubeChannelsErrorLoading', { channelIds: number[]; error: string }>;

export const reducer = createReducer<State, Actions>({
    loadYoutubeChannels: (state, { payload: channelIds }) => {
        const loadingData = makeLoadingStateSlice<number, Data>(channelIds);

        return { ...state, ...loadingData };
    },
    youtubeChannelsSuccess: (state, { payload: { channelIds, channels } }) => {
        const successData = makeSuccessStateSlice<number, Data>(
            channelIds,
            (id) => channels.find((c) => c.id === id) ?? null
        );

        return { ...state, ...successData };
    },
    youtubeChannelsErrorLoading: (state, { payload: { channelIds, error } }) => {
        const errorData = makeErrorStateSlice<number, Data>(channelIds, error);

        return { ...state, ...errorData };
    },
});

export function makeYoutubeChannelsDataHook<Value extends BaseDataHookContextValue<Actions>>(
    context: Context<Value>,
    selector: ContextSelector<Value, State>
) {
    return function useYoutubeChannels() {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (channelIds: number[], requestInit?: RequestInit) => {
                if (!channelIds.length) {
                    dispatch({ type: 'youtubeChannelsSuccess', payload: { channelIds: [], channels: [] } });
                    return;
                }

                try {
                    dispatch({ type: 'loadYoutubeChannels', payload: channelIds });
                    const response = await youtube.getChannels(
                        { id: channelIds.toString(), page_size: channelIds.length },
                        requestInit
                    );

                    if (response.status === 200) {
                        dispatch({
                            type: 'youtubeChannelsSuccess',
                            payload: { channelIds, channels: response.data.results },
                        });
                        return response;
                    }

                    dispatch({
                        type: 'youtubeChannelsErrorLoading',
                        payload: { channelIds, error: 'Could not load youtube channels' },
                    });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({
                        type: 'youtubeChannelsErrorLoading',
                        payload: { channelIds, error: 'Could not load youtube channels' },
                    });
                    throw e;
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
}
