import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { Project } from './projects.types';

export const models = {
    creatorbaseProject: Model as ModelDefinition<Project>,
};

export const serializers = {
    creatorbaseProject: makeSerializer<Project>([]),
};

export const factories = {
    creatorbaseProject: createFactory<Project>({
        name(index: number) {
            return `Project ${index}`;
        },
        associations_updated_at: () => new Date().toISOString(),
        brand_id: (index: number) => index,
        song_id: null,
        currency_id: null,
        total_cost: null,
        users_assigned_to_campaigns: [],
        team_id: (index: number) => index,
        platforms: [],
    }),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/creatorbase/projects/';

    server.get(rootUrl, function (this: RouteHandlerContext, schema, request) {
        let projects = schema.all('creatorbaseProject');

        if (request.queryParams.users_assigned_to_campaigns) {
            const userFilterIds = (request.queryParams.users_assigned_to_campaigns || '').split(',');
            projects = projects.filter((project) => {
                return project.users_assigned_to_campaigns.some((userId) => userFilterIds.includes(userId.toString()));
            });
        }

        return buildPaginatedResponse(projects, {
            url: rootUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'creatorbaseProject'),
        });
    });

    server.get(`${rootUrl}:projectId/`, function (this: RouteHandlerContext, schema, request) {
        const project = schema.find('creatorbaseProject', request.params.projectId);
        if (!project) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return project;
    });

    server.post(rootUrl, function (this: RouteHandlerContext, schema, request) {
        const attrs = JSON.parse(request.requestBody);
        const project = schema.create('creatorbaseProject', attrs);
        return project;
    });

    server.patch(`${rootUrl}:id`, (schema, request) => {
        const project = schema.find('creatorbaseProject', request.params.id);

        if (!project) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        project.update(JSON.parse(request.requestBody));
        return project;
    });
}
