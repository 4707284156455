import { getInstagramPosts, GetInstagramPostsParams, InstagramPost } from '@round/api';
import { showNotification } from 'helpers';
import { useCallback, useState } from 'react';

export function useInstagramPosts({ audio_id, page, page_size, ordering }: GetInstagramPostsParams) {
    const [posts, setPosts] = useState<InstagramPost[]>([]);
    const [count, setCount] = useState(0);
    const [isInitialized, setIsInitialized] = useState(false);
    const [hasError, setHasError] = useState(false);

    const fetchPosts = async (params: GetInstagramPostsParams, requestInit: RequestInit) => {
        try {
            const response = await getInstagramPosts(params, requestInit);
            if (response.status !== 200) {
                setHasError(true);
                showNotification(response.data.detail, 'error');
                return;
            }

            setPosts(response.data.results);
            setCount(response.data.count);
            setIsInitialized(true);
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }
            setHasError(true);
            showNotification(`Could not fetch instagram posts`, 'error');
        }
    };

    const init = useCallback(
        async (requestInit: RequestInit) => {
            await fetchPosts({ audio_id, page, page_size, ordering }, requestInit);
        },
        [audio_id, page, page_size, ordering]
    );

    const reset = () => {
        setPosts([]);
        setIsInitialized(false);
        setHasError(false);
    };

    return {
        posts,
        count,
        isInitialized,
        isLoading: !isInitialized && !hasError,
        hasError,

        init,
        reset,
    };
}
