import { AppServer, RouteHandlerContext } from '../../mirage';
import { belongsTo, Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokAudio, TiktokAudioAsset, TrendingTiktokAudioStats, TrendingTiktokAudioSuggestion } from './audio.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { GetTrendingTiktokAudioStatsSortableKeys, GetTrendingTiktokAudioSuggestionsParams } from './audio.api';

export const models = {
    tiktokAudio: Model as ModelDefinition<TiktokAudio>,
    tiktokAudioAsset: Model.extend({
        image_thumb: belongsTo('cachedImage'),
        audio_file: belongsTo('cachedAudio'),
    }) as ModelDefinition<TiktokAudioAsset>,
    trendingTiktokAudioSuggestion: Model as ModelDefinition<TrendingTiktokAudioSuggestion>,
    trendingTiktokAudioStat: Model as ModelDefinition<TrendingTiktokAudioStats>,
};

export const factories = {
    tiktokAudio: createFactory<TiktokAudio>({
        album(index: number) {
            return `album ${index}`;
        },
        tiktok_id(index: number) {
            return `tiktok id ${index}`;
        },
        alternate_name(index: number) {
            return `alternate name ${index}`;
        },
        author_name(index: number) {
            return `author name ${index}`;
        },
        cover_medium(index: number) {
            return `cover medium ${index}`;
        },
        cover_thumb(index: number) {
            return `cover thumb ${index}`;
        },
        duration() {
            return 10;
        },
        monitored_audio_id() {
            return [];
        },
        title(index: number) {
            return `title ${index}`;
        },
        is_original() {
            return false;
        },
        play_url(index: number) {
            return `play url ${index}`;
        },
    }),
    tiktokAudioAsset: createFactory<TiktokAudioAsset>({
        audio_id(index: number) {
            return index;
        },
        afterCreate(asset, s) {
            const server: AppServer = s;
            if (!asset.audio_file) {
                asset.update({
                    audio_file: server.create('cachedAudio'),
                });
            }

            if (!asset.image_thumb) {
                asset.update({
                    image_thumb: server.create('cachedImage'),
                });
            }
        },
    }),
    trendingTiktokAudioSuggestion: createFactory<TrendingTiktokAudioSuggestion>({
        audio_author_name(index: number) {
            return `audio author name ${index}`;
        },
        audio_id(index: number) {
            return index;
        },
        audio_tiktok_id(index: number) {
            return `audio tiktok id ${index}`;
        },
        audio_is_original() {
            return false;
        },
        created() {
            return new Date().toISOString();
        },
        audio_title(index: number) {
            return `audio title ${index}`;
        },
        search_terms() {
            return [];
        },
        status() {
            return 'SUGGESTED';
        },
    }),
    trendingTiktokAudioStat: createFactory<TrendingTiktokAudioStats>({
        audio_id(index: number) {
            return index;
        },
        tiktok_id(index: number) {
            return `tiktok id ${index}`;
        },
        author_name(index: number) {
            return `author name ${index}`;
        },
        monitored_audio_id(index: number) {
            return index;
        },
        standardised_data() {
            return { daily_video_counts: [] };
        },
        title(index: number) {
            return `title ${index}`;
        },
        is_original() {
            return false;
        },
        video_count(index: number) {
            return index;
        },
        video_count_daily_change(index: number) {
            return index;
        },
        video_count_daily_change_relative() {
            return 0.0;
        },
        video_count_weekly_change(index: number) {
            return index;
        },
        video_count_weekly_change_relative() {
            return 0.0;
        },
        catalogue_ids() {
            return [];
        },
        first_seen_at() {
            return new Date().getTime() / 1000;
        },
        found_in() {
            return [];
        },
    }),
};

export const serializers = {
    tiktokAudio: makeSerializer<TiktokAudio>([]),
    tiktokAudioAsset: makeSerializer<TiktokAudioAsset>(['image_thumb', 'audio_file']),
    trendingTiktokAudioSuggestion: makeSerializer<TrendingTiktokAudioSuggestion>([]),
    trendingTiktokAudioStat: makeSerializer<TrendingTiktokAudioStats>([]),
};

export function handleTiktokAudioRequests(server: AppServer) {
    server.get('/api/tiktok/viewsets/audio/:id/', function (this: RouteHandlerContext, schema, request) {
        const audio = schema.find('tiktokAudio', request.params.id);
        if (!audio) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return audio;
    });

    server.get('/api/tiktok/viewsets/audio/', function (this: RouteHandlerContext, schema, request) {
        const audios = schema.all('tiktokAudio', request.params.id);

        return buildPaginatedResponse(audios, {
            url: '/api/tiktok/viewsets/audio/',
            serialize: (resource) => this.serialize(resource, 'tiktokAudio'),
            queryParams: request.queryParams,
        });
    });

    server.get('/api/tiktok/audio-asset/', function (this: RouteHandlerContext, schema, request) {
        const { audio_id = '' } = request.queryParams;
        const ids = audio_id
            .split(',')
            .filter((id) => id.length > 0)
            .map((id) => Number(id));
        const assets = schema.all('tiktokAudioAsset').filter((asset) => ids.includes(Number(asset.audio_id)));

        return this.serialize(assets, 'tiktokAudioAsset');
    });

    const getTrendingTiktokAudioSuggestionsPath = '/api/tiktok/viewsets/trending-audio-suggestion/';
    server.get(getTrendingTiktokAudioSuggestionsPath, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams as GetTrendingTiktokAudioSuggestionsParams;
        const trendingAudioSuggestions = schema
            .all('trendingTiktokAudioSuggestion')
            .filter((s) => !search || s.audio_title.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(trendingAudioSuggestions, {
            url: getTrendingTiktokAudioSuggestionsPath,
            serialize: (resource) => this.serialize(resource, 'trendingTiktokAudioSuggestion'),
            queryParams: request.queryParams,
        });
    });

    server.put(
        '/api/tiktok/viewsets/trending-audio-suggestion/:id/accept/',
        function (this: RouteHandlerContext, schema, request) {
            const { id = '' } = request.params;
            const suggestion = schema.find('trendingTiktokAudioSuggestion', id);
            if (!suggestion) {
                return new Response(404);
            }

            suggestion.update({
                status: 'ACCEPTED',
            });

            return suggestion;
        }
    );

    server.put(
        '/api/tiktok/viewsets/trending-audio-suggestion/:id/reject/',
        function (this: RouteHandlerContext, schema, request) {
            const { id = '' } = request.params;
            const suggestion = schema.find('trendingTiktokAudioSuggestion', id);
            if (!suggestion) {
                return new Response(404);
            }

            suggestion.update({
                status: 'REJECTED',
            });

            return suggestion;
        }
    );

    const getTrendingTiktokAudioStatsListPath = '/api/tiktok/viewsets/trending-audio/';
    server.get(getTrendingTiktokAudioStatsListPath, function (this: RouteHandlerContext, schema, request) {
        const { search = '', location = '', genre = '', ordering } = request.queryParams;
        const stats = schema
            .all('trendingTiktokAudioStat')
            .filter((s) => !search || s.title.toLowerCase().includes(search.toLowerCase()))
            .filter((s) => {
                if (!location) {
                    return true;
                }

                const countries: string[] = s.found_in.map((item) => item.country_code);
                const queriedCountries = location.split(',');
                return queriedCountries.filter((c) => countries.includes(c)).length > 0;
            })
            .filter((s) => {
                if (!genre) {
                    return true;
                }

                const genres: string[] = s.found_in.map((item) => item.search_term);
                const queriedGenres = genre.split(',');
                return queriedGenres.filter((g) => genres.includes(g)).length > 0;
            })
            .sort((a, b) => {
                if (!ordering) {
                    return 0;
                }

                const isDescending = ordering.startsWith('-');
                const key = (isDescending ? ordering.slice(1) : ordering) as GetTrendingTiktokAudioStatsSortableKeys;

                const valueA = a[key];
                const valueB = b[key];

                if (isDescending) {
                    return valueB - valueA;
                }

                return valueA - valueB;
            });

        return buildPaginatedResponse(stats, {
            url: getTrendingTiktokAudioStatsListPath,
            serialize: (resource) => this.serialize(resource, 'trendingTiktokAudioStat'),
            queryParams: request.queryParams,
        });
    });
}
