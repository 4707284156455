import { creatorbase } from '@round/api';
import { CellContext } from '@tanstack/react-table';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { ProjectTableMeta } from '../types';
import moment from 'moment/moment';
import RunDates from '../../../components/RunDates/RunDates';

type Row = creatorbase.Project;
type Context<T extends Row> = CellContext<T, null>;

const getTableMeta = getTableMetaHelper<ProjectTableMeta<Row>>();

const DurationCell = <T extends Row>({ row: { original }, table }: Context<T>) => {
    const { getIsRowLoading } = getTableMeta(table);

    if (getIsRowLoading(original)) {
        return <Skeleton width="10rem" />;
    }

    if (!original.start_date && !original.end_date) {
        return '-';
    }

    const formattedStartDate = moment(original.start_date).format('MMM DD');
    const formattedEndDate = moment(original.end_date).format('MMM DD');

    return <RunDates startDate={formattedStartDate} endDate={formattedEndDate} />;
};

export default DurationCell;
