import { ApiResponse, NotFoundResponse } from '../../types';
import { PhylloUserAnalyticsData } from '../phyllo.types';
import { fetchWithToken } from '../../helpers';

export type GetTiktokUserAnalyticsResponse =
    | NotFoundResponse
    | ApiResponse<{ created: string; data: PhylloUserAnalyticsData; user_id: number }, 200>;
export async function getTiktokUserAnalytics(
    userId: number,
    requestInit?: RequestInit
): Promise<GetTiktokUserAnalyticsResponse> {
    const response = await fetchWithToken(`/api/creator-data/tiktok-user-analytics/${userId}/`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not fetch tiktok user analytics');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
