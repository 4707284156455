import { useCallback, useMemo, useState } from 'react';
import { getPhylloLocations, PhylloSearchLocation } from '@round/api';
import useAbortableEffect from '../../../Hooks/useAbortableEffect';
import { DropdownOptionWithSubLabel } from '@round/ui-kit';
import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';

type Params = Pick<Parameters<typeof getPhylloLocations>[0], 'type'>;

export default function usePhylloLocationOptions({ type }: Params = {}) {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);

    const [locations, setLocations] = useState<PhylloSearchLocation[]>([]);
    const [optionsLoading, setOptionsLoading] = useState(false);
    const [errorLoadingOption, setErrorLoadingOptions] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);

    const reset = useCallback(() => {
        setPage(1);
        setHasNextPage(false);
        setLocations([]);
    }, []);

    const fetchLocations = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                setOptionsLoading(true);
                setErrorLoadingOptions(false);
                const {
                    data: { results, next },
                } = await getPhylloLocations({ page, page_size: 25, search, type }, requestInit);
                setLocations((locations) => locations.concat(results));
                setHasNextPage(Boolean(next));
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setErrorLoadingOptions(true);
            } finally {
                setOptionsLoading(false);
                setSearchLoading(false);
            }
        },
        [page, search, type]
    );

    const debouncedFetch = useMemo(() => debounce(fetchLocations, 700), [fetchLocations]);

    useAbortableEffect(
        (signal) => {
            debouncedFetch({ signal });
        },
        [debouncedFetch]
    );

    const options = useMemo(
        () =>
            locations.map<DropdownOptionWithSubLabel<string>>((location) => ({
                value: location.uuid,
                label: location.display_name,
                subLabel: capitalize(location.type),
            })),
        [locations]
    );

    return {
        options,
        inputValue: search,
        onInputChange: (value: string) => {
            if (value === search) {
                return;
            }

            reset();
            setSearch(value);
            setSearchLoading(true);
        },
        onMenuScrollToBottom: () => {
            if (!hasNextPage) {
                return;
            }

            setPage((page) => page + 1);
        },
        isLoading: optionsLoading || searchLoading,
        noOptionsMessage: () => (errorLoadingOption ? 'Something went wrong' : 'No options'),
    };
}
