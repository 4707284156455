import { AppServer } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { InfluencerPostGroup } from './InfluencerPostGroup.types';

export const models = {
    influencerPostGroup: Model as ModelDefinition<InfluencerPostGroup>,
};

export const factories = {
    influencerPostGroup: createFactory<InfluencerPostGroup>({
        id(index: number) {
            return index;
        },
        name(index: number) {
            return `name ${index}`;
        },
        ordering_index(index: number) {
            return index;
        },
        influencer_plan(index: number) {
            return index * 10;
        },
        budget(index: number) {
            return `${index}00`;
        },
    }),
};

export const serializers = {
    influencerPostGroup: makeSerializer<InfluencerPostGroup>([]),
};

export async function handleInfluencerPostGroupRequests(server: AppServer) {
    const instagramGroupPath = '/api/advertising/viewsets/instagram-influencer-post-group/';
    const tiktokGroupPath = '/api/advertising/viewsets/tiktok-influencer-post-group/';

    server.get(instagramGroupPath, (schema, request) => {
        return schema.all('influencerPostGroup');
    });

    server.get(tiktokGroupPath, (schema, request) => {
        return schema.all('influencerPostGroup');
    });

    server.post(tiktokGroupPath, (schema, request) => {
        const body = JSON.parse(request.requestBody);
        const postGroup = schema.create('influencerPostGroup', {
            name: body.name,
            influencer_plan: body.influencer_plan,
            ordering_index: body.ordering_index,
            budget: body.budget,
        });
        return postGroup;
    });

    server.post(instagramGroupPath, (schema, request) => {
        const body = JSON.parse(request.requestBody);
        const postGroup = schema.create('influencerPostGroup', {
            name: body.name,
            influencer_plan: body.influencer_plan,
            ordering_index: body.ordering_index,
            budget: body.budget,
        });
        return postGroup;
    });

    server.patch(`${instagramGroupPath}:id/`, (schema, request) => {
        const group = schema.find('influencerPostGroup', request.params.id);

        if (!group) {
            return new Response(404);
        }

        const body = JSON.parse(request.requestBody);

        group.update({
            name: body.name ?? group.name,
            ordering_index: body.ordering_index ?? group.ordering_index,
            budget: body.budget ?? group.budget,
            brief: body.brief ?? group.brief ?? null,
            brief_turnaround: body.brief_turnaround ?? group.brief_turnaround ?? null,
        });
        return group;
    });

    server.patch(`${tiktokGroupPath}:id/`, (schema, request) => {
        const group = schema.find('influencerPostGroup', request.params.id);

        if (!group) {
            return new Response(404);
        }

        const body = JSON.parse(request.requestBody);

        group.update({
            name: body.name ?? group.name,
            ordering_index: body.ordering_index ?? group.ordering_index,
            budget: body.budget ?? group.budget,
            brief: body.brief ?? group.brief ?? null,
            brief_turnaround: body.brief_turnaround ?? group.brief_turnaround ?? null,
        });
        return group;
    });

    server.delete(`${instagramGroupPath}:id/`, (schema, request) => {
        const group = schema.find('influencerPostGroup', request.params.id);

        if (!group) {
            return new Response(404);
        }

        group.destroy();
        return new Response(204);
    });

    server.delete(`${tiktokGroupPath}:id/`, (schema, request) => {
        const group = schema.find('influencerPostGroup', request.params.id);

        if (!group) {
            return new Response(404);
        }

        group.destroy();
        return new Response(204);
    });
}
