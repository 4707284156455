import { useCallback } from 'react';
import { SongDataContext } from '../SongDataContext';
import { creatorbase, music } from '@round/api';
import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';

export default function useSongData() {
    const state = useNonNullContextSelector(SongDataContext, (v) => v[0].song);
    const dispatch = useNonNullContextSelector(SongDataContext, (v) => v[1]);

    const fetchData = useCallback(
        async (songId: number, requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'setSongDataLoading' });
                const response = await creatorbase.getSong(songId, requestInit);
                if (response.status === 404 || response.status === 403) {
                    dispatch({ type: 'songDataLoadingError', payload: response.data.detail });
                    return response;
                }

                let musicSong: music.Song | undefined = undefined;
                if (response.data.music_song_id) {
                    const musicSongResponse = await music.getSong(response.data.music_song_id);
                    musicSong = musicSongResponse.status === 200 ? musicSongResponse.data : undefined;
                }

                dispatch({ type: 'initSongData', payload: { song: response.data, musicSong } });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({
                    type: 'songDataLoadingError',
                    payload: e instanceof Error ? e.message : 'Could not get song',
                });
                throw e;
            }
        },
        [dispatch]
    );

    return {
        ...state,
        fetchData,
    };
}
