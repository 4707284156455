import { ApiResponse, ApiResponseError } from '../../types';
import { call } from '../../helpers';

export type PostTiktokSubmissionPostData = {
    // uuid
    challenge_id: string;
    post_url: string;
};

export type PostTiktokSubmissionPostsResponse =
    | ApiResponse<null, 201>
    | ApiResponse<ApiResponseError<PostTiktokSubmissionPostData>, 400>
    | ApiResponse<string[], 400>;

export async function postTiktokSubmissionPosts(
    data: PostTiktokSubmissionPostData
): Promise<PostTiktokSubmissionPostsResponse> {
    const response = await call(
        '/api/fandom/tiktok-submission-posts/',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        },
        {
            shouldBypassTokens: true,
        }
    );

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not post tiktok video');
    }

    return {
        status: 201,
        data: null,
    };
}
