import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import {
    Creative,
    FacebookCampaignMediaPlanItemRelationship,
    FacebookTargeting,
    MediaPlanItem,
    MediaPlanItemRow,
    Targeting,
} from '../../../../../App.types';
import Table from '../../../../../ui/NewTable/Table';
import { CellProps, Column } from 'react-table';
import { applyCommission, asMoneyWithoutZeros, numberWithCommas } from '../../../../../helpers';
import { AdvertisingContext } from '../../contexts/AdvertisingContext/AdvertisingContext';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import ProgressBar from '../../../../../ui/ProgressBar/ProgressBar';
import styles from './AdvertisingTable.module.css';
import TargetingBadges from '../Targeting/TargetingBadges/TargetingBadges';
import CreativesBadges from '../Creatives/CreativesBadges/CreativesBadges';
import cn from 'classnames';
import { ProtectedByUserGroups } from '../../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import DatePicker from '../../../../../ui/DataEntry/DatePicker/DatePicker';
import CurrencyInput from 'react-currency-input-field';
import { MediaPlanOptionsContext } from '../../contexts/MediaPlanOptionsContext/MediaPlanOptionsContext';
import TableSelect from '../../../components/TableSelect/TableSelect';
import Toggle from '../../../../../ui/DataEntry/Toggle/Toggle';
import { ReactComponent as LinkIcon } from '../../assets/LinkIcon.svg';
import { Popover, Checkbox } from '@round/ui-kit';
import LinkedCampaigns from './components/LinkedCampaigns/LinkedCampaigns';
import { useCheckUserGroupsAccess } from '../../../../Auth/hooks/useCheckUserGroupsAccess';
import { Draggable } from 'react-beautiful-dnd';
import { ReactComponent as PlusIcon } from '../../../../../assets/PlusIcon.svg';
import Button from '../../../../../ui/Buttons/Button/Button';
import CreateTargeting from '../Targeting/CreateTargeting/CreateTargeting';
import TargetingModal from '../Targeting/TargetingModal/TargetingModal';
import { TargetingTemplate } from '../../types/MediaPlanTargeting.types';
import TargetingTemplateModal from '../Targeting/TargetingTemplateModal/TargetingTemplateModal';
import CreateFacebookTargeting from '../Targeting/CreateFacebookTargeting/CreateFacebookTargeting';
import { isFacebookItemRow, isFacebookTargeting, isFacebookTargetingTemplate } from '../../helpers/Facebook.helpers';
import FacebookTargetingModal from '../Targeting/FacebookTargetingModal/FacebookTargetingModal';
import { FacebookTargetingTemplate } from '../../types/Facebook.types';
import FacebookTargetingTemplateModal from '../Targeting/FacebookTargetingTemplateModal/FacebookTargetingTemplateModal';
import CreateCreativeModal from '../Creatives/CreateCreativeModal/CreateCreativeModal';
import EditCreativeModal from '../Creatives/EditCreativeModal/EditCreativeModal';
import SelectInvoiceModal from './Invoice/SelectInvoiceModal';
import { getXeroInvoice, XeroInvoiceSimple } from '@round/api';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import { ApiResponse } from '../../../../../utility/utility.types';

type AdvertisingTableProps = {
    rows: MediaPlanItemRow[];
    selectedRowIds: number[];
    postHighlightedId: number;
    onRowSelect: (rowId: number) => void;
    onAllRowsSelect: (rowIds: number[]) => void;
    updateMediaPlanItem: (id: number, data: Partial<MediaPlanItem>) => Promise<void>;
    unlinkCampaign: (campaign: FacebookCampaignMediaPlanItemRelationship) => Promise<void>;
    onTargetingCreated: (targeting: Targeting | FacebookTargeting) => void;
    onTargetingUpdated: (targeting: Targeting | FacebookTargeting) => void;
    onTargetingDeleted: (targeting: Targeting | FacebookTargeting) => void;
    onCreativeCreated: (creative: Creative) => void;
    onCreativeUpdated: (creative: Creative) => void;
    onCreativeDeleted: (creative: Creative) => void;
};

const amountSpentTrackStyles: CSSProperties = {
    height: '0.2rem',
};

const AdvertisingTable = ({
    rows,
    selectedRowIds,
    postHighlightedId,
    onRowSelect,
    onAllRowsSelect,
    updateMediaPlanItem,
    unlinkCampaign,
    onTargetingCreated,
    onTargetingUpdated,
    onTargetingDeleted,
    onCreativeCreated,
    onCreativeDeleted,
    onCreativeUpdated,
}: AdvertisingTableProps) => {
    const [contextState] = useNonNullContext(AdvertisingContext);
    const {
        channelOptions,
        formatOptions,
        objectiveOptions,
        buyMethodOptions,
        buyPlatformOptions,
        optimisationMetricOptions,
    } = useNonNullContext(MediaPlanOptionsContext);
    const hasEditorPermissions = useCheckUserGroupsAccess(['advertising_editor']);
    const hasFacebookApiViewerPermissions = useCheckUserGroupsAccess(['facebook_api_viewer']);
    const hasFacebookApiEditorPermissions = useCheckUserGroupsAccess(['facebook_api_editor']);

    const [createTargetingModalOpen, setCreateTargetingModalOpen] = useState(false);
    const [selectedTargeting, setSelectedTargeting] = useState<Targeting>();
    const [selectedTargetingTemplate, setSelectedTargetingTemplate] = useState<
        TargetingTemplate | FacebookTargetingTemplate
    >();
    const [currentMediaPlanItem, setCurrentMediaPlanItem] = useState<MediaPlanItemRow>();
    const [createCreativeModalOpen, setCreateCreativeModalOpen] = useState(false);
    const [selectedCreative, setSelectedCreative] = useState<Creative>();

    const [mediaPlanInvoices, setMediaPlanInvoices] = useState<XeroInvoiceSimple[]>();
    const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

    const fetchInvoices = useCallback(
        async (requestInit?: RequestInit) => {
            const ids = rows.map((row) => row.xero_invoice_id).filter((id): id is number => typeof id === 'number');
            const requests = ids.map((id) => {
                return getXeroInvoice(id, requestInit);
            });
            const invoiceData = await Promise.all(requests).then((response) =>
                response
                    .filter((res): res is ApiResponse<XeroInvoiceSimple, 200> => res.status === 200)
                    .map((res) => ({
                        id: res.data.id,
                        invoice_number: res.data.invoice_number,
                        reference: res.data.reference,
                    }))
            );
            setMediaPlanInvoices(invoiceData);
        },
        [rows]
    );

    useAbortableEffect(
        (signal) => {
            fetchInvoices({ signal });
        },
        [fetchInvoices]
    );

    const columns: Column<MediaPlanItemRow>[] = useMemo(() => {
        const columns: Column<MediaPlanItemRow>[] = [
            {
                Header: () => (
                    <div className={styles.id}>
                        <ProtectedByUserGroups groups={['advertising_editor']}>
                            <Checkbox
                                ariaLabel="Select all"
                                className={styles.checkbox}
                                value={rows.length > 0 && rows.every((row) => selectedRowIds.includes(row.id))}
                                onChange={() => onAllRowsSelect(rows.map((row) => row.id))}
                            />
                        </ProtectedByUserGroups>
                        ID
                    </div>
                ),
                accessor: 'id',
                Cell: ({ value }) => (
                    <div className={cn(styles.id, styles.cellContent)}>
                        <ProtectedByUserGroups groups={['advertising_editor']}>
                            <Checkbox
                                className={styles.checkbox}
                                ariaLabel={`Select item ${value}`}
                                value={selectedRowIds.includes(value)}
                                onChange={() => onRowSelect(value)}
                            />
                        </ProtectedByUserGroups>
                        {value}
                    </div>
                ),
            },
            {
                Header: 'START',
                accessor: 'start_date',
                Cell: ({ value, row: { original } }) => (
                    <div className={styles.cellContent}>
                        <DatePicker
                            className={cn(styles.dateInput, { [styles.disabled]: !hasEditorPermissions })}
                            disabled={!hasEditorPermissions}
                            value={value}
                            onChange={(startDate) => updateMediaPlanItem(original.id, { start_date: startDate })}
                        />
                    </div>
                ),
            },
            {
                Header: 'END',
                accessor: 'end_date',
                Cell: ({ value, row: { original } }) => (
                    <div className={styles.cellContent}>
                        <DatePicker
                            className={cn(styles.dateInput, { [styles.disabled]: !hasEditorPermissions })}
                            disabled={!hasEditorPermissions}
                            value={value}
                            onChange={(endDate) => updateMediaPlanItem(original.id, { end_date: endDate })}
                        />
                    </div>
                ),
            },
            {
                Header: 'BUDGET',
                accessor: 'cost',
                Cell: ({ value, row: { original } }) => {
                    const [inputValue, setInputValue] = useState(value);
                    return (
                        <div className={styles.cellContent}>
                            <CurrencyInput
                                disabled={!hasEditorPermissions}
                                className={cn(styles.cellInput, { [styles.invalid]: Number(inputValue) === 0 })}
                                prefix={contextState.mediaPlan?.currency.symbol}
                                value={inputValue}
                                onValueChange={(value) => setInputValue(value ?? 0)}
                                onBlur={() => {
                                    if (Number(value) !== Number(inputValue)) {
                                        updateMediaPlanItem(original.id, { cost: inputValue });
                                    }
                                }}
                                groupSeparator=","
                                decimalSeparator="."
                                allowDecimals={false}
                                decimalScale={0}
                                decimalsLimit={0}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'SPENT',
                id: 'amountSpent',
                Cell: (props: CellProps<MediaPlanItemRow>) => {
                    if (!contextState.mediaPlan?.currency) {
                        return null;
                    }

                    const amountSpent = applyCommission(
                        props.row.original.amount_spent_in_media_plan_currency ?? 0,
                        Number(contextState.mediaPlan.client_commission ?? 0)
                    );

                    const cost = Number(props.row.original.cost);
                    const percentage = cost !== 0 ? Math.floor((amountSpent / cost) * 100) : 0;
                    return (
                        <div className={cn(styles.amountSpentContainer)}>
                            <p className={styles.amountSpent}>
                                {asMoneyWithoutZeros(amountSpent, contextState.mediaPlan.currency)}
                                <span className={styles.amountSpentPercentage}>{percentage}%</span>
                            </p>
                            <ProgressBar
                                progress={Math.min(100, percentage)}
                                trackStyles={amountSpentTrackStyles}
                                barStyles={{ backgroundColor: amountSpent > cost * 1.05 ? '#F7685B' : '' }}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'CHANNEL',
                accessor: 'channel',
                Cell: ({ value, row: { original } }) => {
                    const options = channelOptions
                        .filter((c) => {
                            if (!original.targeting.length) {
                                return true;
                            }
                            const isFacebookChannel = ['Facebook', 'Instagram', 'FBIG'].includes(c.name);
                            return isFacebookItemRow(original) ? isFacebookChannel : !isFacebookChannel;
                        })
                        .map((c) => ({
                            label: c.name,
                            value: c.id,
                        }));

                    const selected = {
                        value: value.id,
                        label: value.name,
                    };

                    return (
                        <div className={cn(styles.cellContent, styles.selectCellContent)}>
                            <TableSelect
                                disabled={!hasEditorPermissions}
                                options={options}
                                value={selected}
                                onChange={(selected) => {
                                    const channel = channelOptions.find((c) => c.id === selected?.value);
                                    if (channel) {
                                        const format = formatOptions.find((f) => f.channel.id === channel.id);
                                        const buyMethod = buyMethodOptions.find((bm) => bm.channel === channel.id);
                                        const optimisationMetric = optimisationMetricOptions.find(
                                            (om) => om.channel.id === channel.id
                                        );
                                        updateMediaPlanItem(original.id, {
                                            channel,
                                            format,
                                            optimisation_metric: optimisationMetric,
                                            buy_method: buyMethod,
                                        });
                                    }
                                }}
                                hint={original.targeting.length ? 'Remove targetings to increase channel options' : ''}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'FORMAT',
                accessor: 'format',
                Cell: ({ value, row: { original } }) => {
                    const options = formatOptions
                        .filter((o) => o.channel.id === original.channel.id)
                        .map((o) => ({
                            value: o.id,
                            label: o.name,
                        }));

                    const selected = {
                        value: value.id,
                        label: value.name,
                    };

                    return (
                        <div className={cn(styles.cellContent, styles.selectCellContent)}>
                            <TableSelect
                                disabled={!hasEditorPermissions}
                                options={options}
                                value={selected}
                                onChange={(selected) => {
                                    const format = formatOptions.find((f) => f.id === selected?.value);
                                    if (format) {
                                        updateMediaPlanItem(original.id, { format });
                                    }
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'OBJECTIVE',
                accessor: 'objective',
                Cell: ({ value, row: { original } }) => {
                    const options = objectiveOptions.map((o) => ({
                        value: o.id,
                        label: o.name,
                    }));

                    const selected = {
                        value: value.id,
                        label: value.name,
                    };

                    return (
                        <div className={cn(styles.cellContent, styles.selectCellContent)}>
                            <TableSelect
                                disabled={!hasEditorPermissions}
                                options={options}
                                value={selected}
                                onChange={(selected) => {
                                    const objective = objectiveOptions.find((f) => f.id === selected?.value);
                                    if (objective) {
                                        updateMediaPlanItem(original.id, { objective });
                                    }
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'TARGETING',
                accessor: 'targeting',
                Cell: ({ value, row: { original } }) => {
                    return (
                        <div className={styles.targetingContainer}>
                            <TargetingBadges
                                disabled={!hasEditorPermissions}
                                className={styles.badges}
                                items={value}
                                onClick={setSelectedTargeting}
                            />
                            <ProtectedByUserGroups groups={['advertising_editor']}>
                                <Button
                                    className={styles.addTargetingButton}
                                    type="link"
                                    onClick={() => {
                                        setCurrentMediaPlanItem(original);
                                        setCreateTargetingModalOpen(true);
                                    }}
                                >
                                    <PlusIcon />
                                </Button>
                            </ProtectedByUserGroups>
                        </div>
                    );
                },
            },
            {
                Header: 'CREATIVE(S)',
                accessor: 'creatives',
                Cell: ({ value, row: { original } }) => (
                    <div className={styles.creativesContainer}>
                        <CreativesBadges
                            disabled={!hasEditorPermissions}
                            className={styles.badges}
                            items={value}
                            onClick={setSelectedCreative}
                        />
                        <ProtectedByUserGroups groups={['advertising_editor']}>
                            <Button
                                className={styles.addCreativeButton}
                                type="link"
                                onClick={() => {
                                    setCurrentMediaPlanItem(original);
                                    setCreateCreativeModalOpen(true);
                                }}
                            >
                                <PlusIcon />
                            </Button>
                        </ProtectedByUserGroups>
                    </div>
                ),
            },
            {
                Header: 'OPTIMISATION',
                accessor: 'optimisation_metric',
                Cell: ({ value, row: { original } }) => {
                    const options = optimisationMetricOptions
                        .filter((o) => o.channel.id === original.channel.id)
                        .map((o) => ({
                            value: o.id,
                            label: o.name,
                        }));

                    const selected = {
                        value: value.id,
                        label: value.name,
                    };

                    return (
                        <div className={cn(styles.cellContent, styles.selectCellContent)}>
                            <TableSelect
                                disabled={!hasEditorPermissions}
                                options={options}
                                value={selected}
                                onChange={(selected) => {
                                    const optimisationMetric = optimisationMetricOptions.find(
                                        (f) => f.id === selected?.value
                                    );
                                    if (optimisationMetric) {
                                        updateMediaPlanItem(original.id, { optimisation_metric: optimisationMetric });
                                    }
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'BUY PLATFORM',
                accessor: 'buy_platform',
                Cell: ({ value, row: { original } }) => {
                    const options = buyPlatformOptions
                        .filter((o) => original.channel.buy_platforms.includes(o.id))
                        .map((o) => ({
                            value: o.id,
                            label: o.name,
                        }));

                    const selected = {
                        value: value.id,
                        label: value.name,
                    };

                    return (
                        <div className={cn(styles.cellContent, styles.selectCellContent)}>
                            <TableSelect
                                disabled={!hasEditorPermissions}
                                options={options}
                                value={selected}
                                onChange={(selected) => {
                                    const buyPlatform = buyPlatformOptions.find((f) => f.id === selected?.value);
                                    if (buyPlatform) {
                                        updateMediaPlanItem(original.id, { buy_platform: buyPlatform });
                                    }
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'BUY METHOD',
                accessor: 'buy_method',
                Cell: ({ value, row: { original } }) => {
                    const options = buyMethodOptions
                        .filter((o) => o.channel === original.channel.id)
                        .map((o) => ({
                            value: o.id,
                            label: o.name,
                        }));

                    const selected = {
                        value: value.id,
                        label: value.name,
                    };

                    return (
                        <div className={cn(styles.cellContent, styles.selectCellContent)}>
                            <TableSelect
                                disabled={!hasEditorPermissions}
                                options={options}
                                value={selected}
                                onChange={(selected) => {
                                    const buyMethod = buyMethodOptions.find((f) => f.id === selected?.value);
                                    if (buyMethod) {
                                        updateMediaPlanItem(original.id, { buy_method: buyMethod });
                                    }
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'EST. COST',
                accessor: 'estimated_cost',
                Cell: ({ value, row: { original } }) => {
                    const [inputValue, setInputValue] = useState(value);
                    return (
                        <div className={styles.cellContent}>
                            <CurrencyInput
                                disabled={!hasEditorPermissions}
                                className={cn(styles.cellInput, { [styles.invalid]: Number(inputValue) === 0 })}
                                prefix={contextState.mediaPlan?.currency.symbol}
                                value={inputValue}
                                onValueChange={(value) => setInputValue(value ?? '0')}
                                onBlur={() => {
                                    if (Number(value) !== Number(inputValue)) {
                                        updateMediaPlanItem(original.id, { estimated_cost: inputValue });
                                    }
                                }}
                                groupSeparator=","
                                decimalSeparator="."
                                decimalsLimit={3}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'EST. RESULTS',
                id: 'estimatedResults',
                Cell: ({ row: { original } }: CellProps<MediaPlanItemRow>) => {
                    const cost = Number(original.cost);
                    const estimatedCost = Number(original.estimated_cost);
                    if (cost === 0 || estimatedCost === 0) {
                        return null;
                    }

                    return (
                        <div className={styles.cellContent}>
                            <p>{numberWithCommas(Math.floor(cost / estimatedCost))}</p>
                            <p>{original.optimisation_metric.name}</p>
                        </div>
                    );
                },
            },
            {
                Header: 'INVOICE',
                accessor: 'xero_invoice_id',
                Cell: ({ value, row: { original } }) => {
                    const invoice = mediaPlanInvoices?.find((i) => i.id === value);
                    const hasAccess = useCheckUserGroupsAccess(['advertising_editor'], 'all');
                    return (
                        <div className={cn(styles.cellContent, styles.selectCellContent)}>
                            <div
                                className={styles.invoiceContainer}
                                onClick={() => {
                                    if (!hasAccess) {
                                        return;
                                    }
                                    setCurrentMediaPlanItem(original);
                                    setInvoiceModalOpen(true);
                                }}
                            >
                                <p className={styles.invoiceNum}>{invoice ? `#${invoice.invoice_number}` : '-'}</p>
                            </div>
                        </div>
                    );
                },
            },
        ];

        if (hasFacebookApiViewerPermissions) {
            columns.push({
                Header: 'LINK STATUS',
                accessor: 'campaigns',
                Cell: ({ value }) => {
                    const [linkIcon, setLinkIcon] = useState<SVGSVGElement | null>(null);
                    return (
                        <div className={styles.linkedCampaignsContent}>
                            <LinkIcon
                                ref={setLinkIcon}
                                className={cn(styles.linkIcon, { [styles.linked]: Boolean(value.length) })}
                            />
                            {value.length === 0 && (
                                <Popover anchorElement={linkIcon} showOn="hover">
                                    <div className={styles.unlinkedPopover}>Unlinked</div>
                                </Popover>
                            )}
                            {value.length > 0 && (
                                <Popover anchorElement={linkIcon} showOn="click">
                                    <LinkedCampaigns
                                        readonly={!Boolean(hasFacebookApiEditorPermissions)}
                                        campaigns={value}
                                        unlinkCampaign={unlinkCampaign}
                                    />
                                </Popover>
                            )}
                        </div>
                    );
                },
            });
        }

        if (hasEditorPermissions) {
            columns.push({
                Header: 'ALERT',
                accessor: 'alerts_enabled',
                Cell: ({ value, row: { original } }) => (
                    <div className={cn(styles.cellContent, styles.alertsCellContent)}>
                        <Toggle
                            ariaLabel="Alerts enabled"
                            value={value}
                            onChange={(enabled) => updateMediaPlanItem(original.id, { alerts_enabled: enabled })}
                        />
                    </div>
                ),
            });
        }

        return columns;
    }, [
        hasFacebookApiViewerPermissions,
        hasEditorPermissions,
        rows,
        selectedRowIds,
        onAllRowsSelect,
        onRowSelect,
        updateMediaPlanItem,
        contextState.mediaPlan?.currency,
        contextState.mediaPlan?.client_commission,
        channelOptions,
        formatOptions,
        buyMethodOptions,
        optimisationMetricOptions,
        objectiveOptions,
        buyPlatformOptions,
        mediaPlanInvoices,
        hasFacebookApiEditorPermissions,
        unlinkCampaign,
    ]);

    return (
        <>
            {currentMediaPlanItem && (
                <CreateCreativeModal
                    isModalOpen={createCreativeModalOpen}
                    closeModal={() => setCreateCreativeModalOpen(false)}
                    onCreated={onCreativeCreated}
                    mediaPlanItemId={currentMediaPlanItem.id}
                />
            )}

            {selectedCreative && (
                <EditCreativeModal
                    isModalOpen={Boolean(selectedCreative)}
                    closeModal={() => setSelectedCreative(undefined)}
                    creative={selectedCreative}
                    onUpdated={onCreativeUpdated}
                    onDeleted={onCreativeDeleted}
                />
            )}

            {currentMediaPlanItem && isFacebookItemRow(currentMediaPlanItem) && (
                <CreateFacebookTargeting
                    isModalOpen={createTargetingModalOpen}
                    mediaPlanItem={currentMediaPlanItem}
                    closeModal={() => {
                        setCreateTargetingModalOpen(false);
                        setCurrentMediaPlanItem(undefined);
                    }}
                    onTargetingCreated={(targeting) => {
                        onTargetingCreated(targeting);
                        setSelectedTargeting(targeting);
                    }}
                    onTargetingTemplateCreated={(template) => {
                        setSelectedTargetingTemplate(template);
                    }}
                    editTargetingTemplate={(template) => {
                        setSelectedTargetingTemplate(template);
                    }}
                />
            )}

            {currentMediaPlanItem && !isFacebookItemRow(currentMediaPlanItem) && (
                <CreateTargeting
                    isModalOpen={createTargetingModalOpen}
                    mediaPlanItem={currentMediaPlanItem}
                    closeModal={() => {
                        setCreateTargetingModalOpen(false);
                        setCurrentMediaPlanItem(undefined);
                    }}
                    onTargetingCreated={(targeting) => {
                        onTargetingCreated(targeting);
                        setSelectedTargeting(targeting);
                    }}
                    onTargetingTemplateCreated={(template) => {
                        setSelectedTargetingTemplate(template);
                    }}
                    editTargetingTemplate={(template) => setSelectedTargetingTemplate(template)}
                />
            )}

            {selectedTargeting && isFacebookTargeting(selectedTargeting) && (
                <FacebookTargetingModal
                    isModalOpen={Boolean(selectedTargeting)}
                    closeModal={() => setSelectedTargeting(undefined)}
                    targeting={selectedTargeting}
                    onTargetingDeleted={onTargetingDeleted}
                    onTargetingUpdated={onTargetingUpdated}
                />
            )}

            {selectedTargeting && !isFacebookTargeting(selectedTargeting) && (
                <TargetingModal
                    targeting={selectedTargeting}
                    isModalOpen={Boolean(selectedTargeting)}
                    closeModal={() => setSelectedTargeting(undefined)}
                    onTargetingUpdated={onTargetingUpdated}
                    onTargetingDeleted={onTargetingDeleted}
                />
            )}

            {selectedTargetingTemplate && isFacebookTargetingTemplate(selectedTargetingTemplate) && (
                <FacebookTargetingTemplateModal
                    targetingTemplate={selectedTargetingTemplate}
                    isModalOpen={Boolean(selectedTargetingTemplate)}
                    closeModal={() => setSelectedTargetingTemplate(undefined)}
                />
            )}

            {selectedTargetingTemplate && !isFacebookTargetingTemplate(selectedTargetingTemplate) && (
                <TargetingTemplateModal
                    template={selectedTargetingTemplate}
                    isModalOpen={Boolean(selectedTargetingTemplate)}
                    closeModal={() => setSelectedTargetingTemplate(undefined)}
                />
            )}

            {currentMediaPlanItem && (
                <SelectInvoiceModal
                    isModalOpen={invoiceModalOpen}
                    closeModal={() => setInvoiceModalOpen(false)}
                    onSelected={(value) => {
                        updateMediaPlanItem(currentMediaPlanItem.id, { xero_invoice_id: value });
                    }}
                    initialInvoiceId={currentMediaPlanItem.xero_invoice_id}
                />
            )}

            <Table
                disableSortBy
                data={rows}
                columns={columns}
                tableClassName={styles.table}
                theadClassName={styles.thead}
                tbodyClassName={styles.tbody}
                renderRow={({ row, rowProps, cellProps }) => (
                    <Draggable
                        isDragDisabled={!hasEditorPermissions}
                        draggableId={row.original.id.toString()}
                        index={row.original.id}
                    >
                        {(provided) => (
                            <tr
                                id={`${row.original.id}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                {...rowProps}
                                {...row.getRowProps({
                                    className: cn({ [styles.highlighted]: row.original.id === postHighlightedId }),
                                })}
                            >
                                {row.cells.map((cell) => (
                                    <td
                                        {...cellProps}
                                        className={cn(cellProps.className, styles[cell.column.id], {
                                            [styles.editorUser]: hasEditorPermissions,
                                        })}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        )}
                    </Draggable>
                )}
            />
        </>
    );
};

export default AdvertisingTable;
