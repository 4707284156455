import {
    InstagramInfluencerPost,
    InstagramInfluencerPostApiBody,
    InstagramInfluencerPostResult,
    InstagramInfluencerPostResultApiBody,
} from '../types/Instagram.types';
import { encodeUrlSearchParams, fetchWithToken } from '@round/api';
import { ApiResponse, ApiResponseError } from '../../../../utility/utility.types';
import { PaginatedApiResponseData, PaginatedRequest } from '../../../../App.types';

export type GetInstagramInfluencerPostsParams = Partial<
    PaginatedRequest & { search: string; no_invoice: boolean; id: string }
>;

export async function getInstagramInfluencerPosts(
    params: GetInstagramInfluencerPostsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<InstagramInfluencerPost>, 200>> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/instagram-influencer-post/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not get instagram influencer posts');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PatchInstagramInfluencerPostResponse =
    | ApiResponse<InstagramInfluencerPost, 200>
    | ApiResponse<ApiResponseError<InstagramInfluencerPostApiBody>, 400>;

export async function patchInstagramInfluencerPost(
    postId: number,
    data: Partial<InstagramInfluencerPostApiBody>
): Promise<PatchInstagramInfluencerPostResponse> {
    const response = await fetchWithToken(`/api/advertising/viewsets/instagram-influencer-post/${postId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not patch instagram influencer post: ${postId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function deleteInstagramInfluencerPost(postId: number): Promise<ApiResponse<undefined, 204>> {
    const response = await fetchWithToken(`/api/advertising/viewsets/instagram-influencer-post/${postId}/`, {
        method: 'DELETE',
    });

    if (!response.ok) {
        throw new Error(`Could not delete instagram influencer post ${postId}`);
    }

    return {
        status: 204,
        data: undefined,
    };
}

type PostInstagramInfluencerPostResultsResponse =
    | ApiResponse<InstagramInfluencerPostResult, 200>
    | ApiResponse<ApiResponseError<InstagramInfluencerPostResultApiBody>, 400>;
export async function postInstagramInfluencerPostResults(
    data: InstagramInfluencerPostResultApiBody
): Promise<PostInstagramInfluencerPostResultsResponse> {
    const response = await fetchWithToken('/api/advertising/viewsets/instagram-influencer-post-results/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not POST instagram influencer post results');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PatchInstagramInfluencerPostResultsResponse = PostInstagramInfluencerPostResultsResponse;
export async function patchInstagramInfluencerPostResults(
    resultsId: number,
    data: Partial<InstagramInfluencerPostResultApiBody>
): Promise<PatchInstagramInfluencerPostResultsResponse> {
    const response = await fetchWithToken(`/api/advertising/viewsets/instagram-influencer-post-results/${resultsId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not patch instagram influencer post results ${resultsId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function deleteInstagramInfluencerPostResults(
    postResultsId: number
): Promise<ApiResponse<undefined, 204>> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/instagram-influencer-post-results/${postResultsId}/`,
        { method: 'DELETE' }
    );

    if (!response.ok) {
        throw new Error(`Could not delete instagram influencer post results ${postResultsId}`);
    }

    return {
        status: 204,
        data: undefined,
    };
}
