import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import { ApiResponse, NotFoundResponse, OrderingValues, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { InstagramAudioListStats } from './audioStats.types';

export type GetInstagramAudioListStatsOrderingKeys = OrderingValues<
    keyof Pick<
        InstagramAudioListStats,
        | 'reels_count'
        | 'reels_count_daily_change'
        | 'reels_count_daily_change_relative'
        | 'reels_count_weekly_change'
        | 'reels_count_weekly_change_relative'
    >
>;

export type GetInstagramAudioListStatsParams = Partial<
    PaginatedRequest & {
        title: string;
        author: string;
        instagram_id: string;
        ordering: GetInstagramAudioListStatsOrderingKeys;
        min_reels_count: number;
        max_reels_count: number;
    }
>;

export async function getInstagramAudioListStats(
    params: GetInstagramAudioListStatsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<InstagramAudioListStats>, 200> | NotFoundResponse> {
    const response = await fetchWithToken(
        `/api/instagram/viewsets/audio-stats/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not fetch instagram audio stats`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
