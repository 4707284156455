import { AdvancedFilterConditionTypes } from '@round/ui-kit';

export type CategoriesFilterCondition = {
    type: AdvancedFilterConditionTypes;
    value: number[];
};

export function parseConditionsFromUrlString(conditions: string | undefined): CategoriesFilterCondition[] {
    if (!conditions) {
        return [];
    }

    return JSON.parse(conditions);
}

export function stringifyConditionsToUrlString(conditions: CategoriesFilterCondition[]): string | undefined {
    if (conditions.length) {
        return JSON.stringify(conditions);
    }
}

export function mapConditionsToApi(conditions: CategoriesFilterCondition[]): number[][] {
    return conditions.reduce<number[][]>((acc, item) => {
        if (!item.value.length) {
            return acc;
        }

        if (item.type === 'all') {
            return acc.concat(item.value.map((tag) => [tag]));
        }

        return acc.concat([item.value]);
    }, []);
}

export function mapExcludeConditionsToApi(conditions: CategoriesFilterCondition[]): number[][] {
    return conditions.reduce<number[][]>((acc, item) => {
        if (!item.value.length) {
            return acc;
        }

        if (item.type === 'exclude_if_all') {
            return acc.concat(item.value.map((tag) => [tag]));
        }

        return acc.concat([item.value]);
    }, []);
}

export function isIncludeCondition(condition: CategoriesFilterCondition) {
    return condition.type === 'any' || condition.type === 'all';
}

export function isExcludeCondition(condition: CategoriesFilterCondition) {
    return condition.type === 'exclude_if_all' || condition.type === 'exclude_if_any';
}
