export const addPublicReportIdToRequestInit = (
    publicReportId: string,
    requestInit: RequestInit
): PublicReportRequestInit => {
    const headers = new Headers(requestInit.headers);

    return {
        ...requestInit,
        headers: {
            ...headers,
            'Public-Report-Id': publicReportId,
        },
    };
};

export type PublicReportRequestInit = Omit<RequestInit, 'headers'> & {
    headers: HeadersInit & {
        'Public-Report-Id': string;
    };
};

export const isPublicReportRequestInit = (
    requestInit: PublicReportRequestInit | RequestInit | undefined
): requestInit is PublicReportRequestInit =>
    requestInit?.headers !== undefined && Object.getOwnPropertyNames(requestInit.headers).includes('Public-Report-ID');
