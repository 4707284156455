import { FacebookTargeting } from '../../../../../../../../App.types';
import React, { useCallback, useEffect } from 'react';
import LoadingSpinner from '../../../../../../../../SharedComponents/LoadingSpinner/LoadingSpinner';
import cn from 'classnames';
import { numberWithCommas } from '../../../../../../../../helpers';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '../../../../../../../../SharedComponents/svg/Icons/Info';
import styles from './FacebookTargetingAudienceReach.module.css';

export type FacebookTargetingAudienceReachProps = {
    reach: FacebookTargeting['estimated_reach'];
    loading?: boolean;
    needsUpdate?: boolean;
    className?: string;
    innerRef?: React.Ref<HTMLDivElement>;
};

const FacebookTargetingAudienceReach = ({
    loading,
    reach,
    needsUpdate,
    className,
    innerRef,
}: FacebookTargetingAudienceReachProps) => {
    const renderContent = useCallback(() => {
        if (reach.lower_bound === null || reach.upper_bound === null) {
            return <span>{reach.error_message || 'estimate is not available at the moment'}</span>;
        }

        if (loading) {
            return <LoadingSpinner containerClassName={styles.loadingSpinnerContainer} />;
        }

        return (
            <>
                <span className={cn(styles.value, { [styles.needsUpdate]: needsUpdate })}>
                    {numberWithCommas(reach.lower_bound)} - {numberWithCommas(reach.upper_bound)}
                </span>
                people
            </>
        );
    }, [loading, needsUpdate, reach.error_message, reach.lower_bound, reach.upper_bound]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [needsUpdate]);

    return (
        <div
            ref={innerRef}
            className={cn(styles.container, className, { [styles.needsUpdate]: needsUpdate })}
            onMouseLeave={() => ReactTooltip.hide()}
        >
            <span className={styles.label}>Potential reach: </span>
            {renderContent()}
            {needsUpdate && (
                <>
                    <span data-tip="" data-for="audienceReach" className={styles.infoIcon}>
                        <InfoIcon width={14} height={14} />
                    </span>
                    <ReactTooltip id="audienceReach">Please, submit form to recalculate potential reach</ReactTooltip>
                </>
            )}
        </div>
    );
};

export default FacebookTargetingAudienceReach;
