import React, { useEffect, useMemo, useState } from 'react';
import { Modal, ModalContent, ModalFooter, ModalTitle } from '@round/ui-kit';
import Button from '../../../../../ui/Buttons/Button/Button';
import InfluencerPostStatus from '../../components/InfluencerPostStatus/InfluencerPostStatus';
import { InfluencerPostStatus as InfluencerPostStatusType } from '../../InfluencerPlan.types';
import styles from './InfluencerPostStatusModal.module.css';
import { EDITABLE_STATUSES } from '../../InfluencerPlan.helpers';
import cn from 'classnames';
import Calendar from '../../../../../ui/DataEntry/Calendar/Calendar';
import moment from 'moment';

type InfluencerPostStatusModalProps = {
    isModalOpen: boolean;
    onClose: () => void;
    status: InfluencerPostStatusType | null | undefined;
    draftExpectedBy?: string | null;
    onChange: (status: InfluencerPostStatusType, draftExpectedBy: string | null) => void;
};

const InfluencerPostStatusModal = ({
    isModalOpen,
    onClose,
    status,
    draftExpectedBy,
    onChange,
}: InfluencerPostStatusModalProps) => {
    const [selectedStatus, setSelectedStatus] = useState(status);
    const [draftExpectedByPickerValue, setDraftExpectedByPickerValue] = useState<string | null>(
        draftExpectedBy ?? null
    );

    const submitDisabled = useMemo(() => {
        if (selectedStatus === 'draft_pending') {
            return (
                (selectedStatus === status && draftExpectedBy === draftExpectedByPickerValue) ||
                draftExpectedByPickerValue === null
            );
        }

        return status === selectedStatus;
    }, [draftExpectedBy, draftExpectedByPickerValue, selectedStatus, status]);

    useEffect(() => {
        if (!isModalOpen) {
            setSelectedStatus(status);
            setDraftExpectedByPickerValue(draftExpectedBy ?? null);
        }
    }, [draftExpectedBy, isModalOpen, status]);

    return (
        <Modal className={styles.modal} closeOnOverlayClick isOpen={isModalOpen} onClose={onClose}>
            <ModalTitle className={styles.modalTitle}>Change Status</ModalTitle>
            <ModalContent className={styles.content}>
                {EDITABLE_STATUSES.map((status) => (
                    <React.Fragment key={status}>
                        <button
                            className={cn(styles.statusButton, { [styles.selected]: status === selectedStatus })}
                            onClick={() => {
                                setSelectedStatus(status);
                                if (status !== 'draft_pending') {
                                    setDraftExpectedByPickerValue(null);
                                }
                            }}
                        >
                            <InfluencerPostStatus className={styles.status} status={status} />
                        </button>
                        {status === 'draft_pending' && selectedStatus === 'draft_pending' && (
                            <div className={styles.calendarContainer}>
                                <p className={styles.selectDateLabel}>Select date</p>
                                <Calendar
                                    value={draftExpectedByPickerValue}
                                    onChange={(date) =>
                                        setDraftExpectedByPickerValue(moment.utc(date).hours(12).toISOString())
                                    }
                                />
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </ModalContent>
            <ModalFooter className={styles.modalFooter} align="left">
                <Button
                    type="filled"
                    color="black"
                    disabled={submitDisabled}
                    onClick={() => {
                        if (!selectedStatus) {
                            return;
                        }
                        onChange(selectedStatus, draftExpectedByPickerValue);
                    }}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default InfluencerPostStatusModal;
