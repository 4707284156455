import React, { useCallback, useMemo } from 'react';
import { GetTiktokAudioPostStatsSortableKeys, TiktokAudioPostStats } from '@round/api';
import PaginationTable, { PaginationTableProps } from '../../../../../ui/PaginationTable/PaginationTable';
import { Column } from 'react-table';
import { buildTiktokPostUrl, numberWithCommas } from '../../../../../helpers';
import styles from './TopPostsTable.module.css';
import Skeleton from '../../../../../ui/Skeleton/Skeleton';
import { OrderByParam } from '../../../../../Hooks/useReactTableSortToOrderBy';
import TruncatedTextCell from '../../../../../SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import { getCountry } from '../../../../../utility/utility';
import {
    convertOrderByToOrdering,
    convertOrderingToOrderBy,
} from '../../../../../ui/PaginationTable/PaginationTable.helpers';
import { useCheckUserGroupsAccess } from '../../../../Auth/hooks/useCheckUserGroupsAccess';

type TopPostsTableProps = {
    rows: TiktokTopPostsRow[];
    page: number;
    pageSize: number;
    count: number;
    setPageSize: (pageSize: number) => void;
    setPage: (page: number) => void;
    loading: boolean;
    ordering: GetTiktokAudioPostStatsSortableKeys | undefined;
    setOrdering: (ordering: GetTiktokAudioPostStatsSortableKeys) => void;
    noDataLabel?: string;
} & Pick<PaginationTableProps<TiktokTopPostsRow>, 'onPaginationChange'>;

export type TiktokTopPostsRow = Omit<TiktokAudioPostStats, 'author_follower_count'> & {
    author_follower_count: number | null;
    sponsored?: boolean;
    user_id?: number | null;
};

const TopPostsTable = ({
    page,
    setPage,
    setPageSize,
    noDataLabel,
    pageSize,
    count,
    rows,
    loading,
    ordering,
    setOrdering,
    onPaginationChange,
}: TopPostsTableProps) => {
    const isUserStaff = useCheckUserGroupsAccess(['influencer_editor']);
    const orderBy = useMemo(() => convertOrderingToOrderBy(ordering) as OrderByParam<TiktokTopPostsRow>, [ordering]);
    const onOrderByChange = useCallback(
        (orderBy: OrderByParam<TiktokTopPostsRow>) => {
            const ordering = convertOrderByToOrdering(orderBy);
            setOrdering(ordering as GetTiktokAudioPostStatsSortableKeys);
        },
        [setOrdering]
    );

    const columns = useMemo(() => {
        const columns: Column<TiktokTopPostsRow>[] = [
            {
                Header: 'Username',
                accessor: 'author_unique_id',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Followers',
                accessor: 'author_follower_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value === null ? '-' : numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Location',
                accessor: 'location',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }

                    return (
                        <div className={styles.location}>
                            <span className={`fi fi-${value.toLowerCase()}`} />
                            {getCountry(value)?.label}
                        </div>
                    );
                },
            },
            {
                Header: 'Description',
                accessor: 'description',
                disableSortBy: true,
                Cell: ({ value, row: { original } }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <a
                            className={styles.description}
                            href={buildTiktokPostUrl(original.author_unique_id, original.video_tiktok_id)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <TruncatedTextCell>{value || 'No Caption'}</TruncatedTextCell>
                        </a>
                    );
                },
            },
            {
                Header: 'Date posted',
                accessor: 'create_time',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{new Date(value * 1000).toLocaleDateString('en-GB')}</>;
                },
            },
            {
                Header: 'Boosted',
                accessor: 'is_ad',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value ? 'Yes' : 'No'}</>;
                },
            },
            {
                Header: 'Views',
                accessor: 'play_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Likes',
                accessor: 'like_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Comments',
                accessor: 'comment_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Shares',
                accessor: 'share_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Saves',
                accessor: 'save_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value ? numberWithCommas(value) : '-'}</>;
                },
            },
        ];

        const showSponsoredColumn = !!isUserStaff || rows.some((row) => row.sponsored);

        if (showSponsoredColumn) {
            columns.splice(5, 0, {
                Header: 'Sponsored',
                accessor: 'sponsored',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return value ? 'Yes' : 'No';
                },
            } as Column<TiktokTopPostsRow>);
        }

        return columns;
    }, [loading, rows, isUserStaff]);

    return (
        <PaginationTable
            disableSortBy={false}
            manualSortBy
            manualPagination
            data={rows}
            columns={columns}
            page={page}
            pageSize={pageSize}
            setPageSize={(pageSize) => setPageSize(pageSize ?? 10)}
            setPage={setPage}
            loading={loading}
            count={count}
            noDataLabel={noDataLabel}
            orderBy={orderBy}
            onOrderByChange={onOrderByChange}
            tableClassName={styles.table}
            theadClassName={styles.thead}
            tbodyClassName={styles.tbody}
            onPaginationChange={onPaginationChange}
        />
    );
};

export default TopPostsTable;
