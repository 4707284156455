import { youtube } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

export type YoutubeAccountsContextData = {
    [accountId: number]: DataState<{ channel: youtube.Channel | null }>;
};

export type YoutubeAccountsContextState = {
    isInitialized: boolean;
    data: YoutubeAccountsContextData;
};

export type YoutubeAccountsContextActions =
    | ReducerActionWithPayload<'setAccountsDataLoading', { accountIds: number[] }>
    | ReducerActionWithPayload<'setAccountsDataSuccess', { accountIds: number[]; channels: youtube.Channel[] }>
    | ReducerActionWithPayload<'setAccountsDataError', { accountIds: number[]; error: string }>
    | ReducerActionWithPayload<'setAccountsDataIdle', { accountIds: number[] }>
    | ReducerAction<'setAccountsDataInitialized'>;

export default createReducer<YoutubeAccountsContextState, YoutubeAccountsContextActions>({
    setAccountsDataLoading: (state, { payload: { accountIds } }) => {
        const incomingAccountsDataAsLoading = accountIds.reduce((acc, accountId) => {
            acc[accountId] = { status: 'loading', data: null, error: null };
            return acc;
        }, {} as YoutubeAccountsContextData);

        return { ...state, data: { ...state.data, ...incomingAccountsDataAsLoading } };
    },
    setAccountsDataSuccess: (state, { payload: { accountIds, channels } }) => {
        const incomingAccountsDataAsSuccess = accountIds.reduce((acc, accountId) => {
            const linkedChannel = channels.find((channel) => channel.id === accountId) || null;

            acc[accountId] = {
                status: 'success',
                data: {
                    channel: linkedChannel,
                },
                error: null,
            };
            return acc;
        }, {} as YoutubeAccountsContextData);

        return { ...state, data: { ...state.data, ...incomingAccountsDataAsSuccess } };
    },
    setAccountsDataError: (state, { payload: { accountIds, error } }) => {
        const incomingAccountsDataAsError = accountIds.reduce((acc, accountId) => {
            acc[accountId] = { status: 'error', data: null, error };
            return acc;
        }, {} as YoutubeAccountsContextData);

        return { ...state, data: { ...state.data, ...incomingAccountsDataAsError } };
    },
    setAccountsDataIdle: (state, { payload: { accountIds } }) => {
        const incomingAccountsDataAsIdle = accountIds.reduce((acc, accountId) => {
            acc[accountId] = { status: 'idle', data: null, error: null };
            return acc;
        }, {} as YoutubeAccountsContextData);

        return { ...state, data: { ...state.data, ...incomingAccountsDataAsIdle } };
    },
    setAccountsDataInitialized: (state) => ({ ...state, isInitialized: true }),
});
