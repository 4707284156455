import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { InstagramCreatorsContext, InstagramCreatorsDispatchContext } from './InstagramCreatorsContext';
import { useCallback, useContext } from 'react';
import { InfluencerPlanContext } from '../InfluencerPlanContext';
import {
    addInstagramAudioToCreatorPlan,
    deleteInstagramAudioFromCreatorPlan,
    getInstagramAudio,
    getInstagramAudios,
} from '@round/api';

export default function useInstagramInfluencerPlanAudios() {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const state = useNonNullContext(InstagramCreatorsContext);
    const dispatch = useNonNullContext(InstagramCreatorsDispatchContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (!influencerPlan) {
                return;
            }

            if (!influencerPlan.instagram_audios.length) {
                dispatch({ type: 'instagramInfluencerPlanAudiosInitialized', payload: [] });
                return;
            }

            try {
                dispatch({ type: 'loadInstagramInfluencerPlanAudios' });
                const response = await getInstagramAudios({ id: influencerPlan.instagram_audios.join() }, requestInit);
                if (response.status === 404) {
                    dispatch({
                        type: 'setInstagramInfluencerPlanAudiosError',
                        payload: response.data.detail || 'No audios found',
                    });
                    return;
                }

                dispatch({ type: 'instagramInfluencerPlanAudiosInitialized', payload: response.data.results });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'setInstagramInfluencerPlanAudiosError',
                    payload: 'Could not fetch instagram audios',
                });
            }
        },
        [dispatch, influencerPlan]
    );

    const addAudio = useCallback(
        async (url: string) => {
            if (!influencerPlan) {
                return;
            }

            const response = await addInstagramAudioToCreatorPlan(influencerPlan.id, url);
            if (response.status !== 201) {
                throw new Error(response.status === 404 ? response.data.detail : response.data.instagram_url);
            }

            const audioId = response.data.audio_id;
            const getAudioResponse = await getInstagramAudio(audioId);
            if (getAudioResponse.status === 404) {
                throw new Error('Could not fetch added audio');
            }
            dispatch({ type: 'instagramAudioAdded', payload: getAudioResponse.data });
        },
        [dispatch, influencerPlan]
    );

    const deleteAudio = useCallback(
        async (audioId: number) => {
            if (!influencerPlan) {
                return;
            }

            const response = await deleteInstagramAudioFromCreatorPlan(influencerPlan.id, audioId);
            if (response.status !== 204) {
                throw new Error(response.data.detail);
            }
            dispatch({ type: 'instagramAudioDeleted', payload: audioId });
        },
        [dispatch, influencerPlan]
    );

    return {
        init,
        addAudio,
        deleteAudio,
        audios: state.instagramInfluencerPlanAudios,
        error: state.instagramInfluencerPlanAudiosError,
        isLoading: state.areInstagramInfluencerPlanAudiosLoading,
        isInitialized: state.areInstagramInfluencerPlanAudiosInitialized,
    };
}
