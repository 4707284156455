import * as yup from 'yup';
import moment from 'moment';
import { ValueType } from 'react-select';
import { GenericDropdownOption } from 'App.types';
import { microwave } from '@round/api';

export type CampaignFormValues = Partial<
    Omit<
        microwave.CampaignApiBody,
        'team_members' | 'release_id' | 'currency_id' | 'budget' | 'instagram_audio_id' | 'tiktok_audio_id'
    > & {
        team_members: ValueType<GenericDropdownOption<number>, true>;
        currency: ValueType<GenericDropdownOption<number>, false>;
        budget: string;
        release: ValueType<GenericDropdownOption<number>, false>;
        instagram_audio: ValueType<GenericDropdownOption<number>, false>;
        instagram_audio_identifier: string;
        is_instagram_audio_select_mode: boolean;
        tiktok_audio: ValueType<GenericDropdownOption<number>, false>;
        tiktok_audio_identifier: string;
        is_tiktok_audio_select_mode: boolean;
    }
>;

export const validationSchema = yup.object<
    Record<
        keyof Omit<
            CampaignFormValues,
            | 'tiktok_audio'
            | 'instagram_audio'
            | 'instagram_audio_identifier'
            | 'is_instagram_audio_select_mode'
            | 'tiktok_audio_identifier'
            | 'is_tiktok_audio_select_mode'
        >,
        yup.AnySchema
    >
>({
    budget: yup.string().required('Budget is required'),
    currency: yup.object().nullable().required('Currency is required'),
    target_post_count: yup.number().required('Post target is required'),
    release: yup.object().nullable().required('Release is required'),
    planned_start_date: yup.string().required('Start date is required'),
    planned_end_date: yup.string().when('planned_start_date', (startDate: string) => {
        return yup.string().test('end-date', 'End date cannot come before start date', (value) => {
            return moment(value).isSameOrAfter(moment(startDate));
        });
    }),
    platform: yup.string().required('Platform is required'),
    team_members: yup.array().min(1, 'Account team is required'),
});
