import { FeatureFlagName } from 'utility/featureFlags';
import { ReactComponent as ProjectsIcon } from 'assets/icons/DocumentText.svg';
import { ReactComponent as SongIcon } from 'assets/whitelabel/Song.svg';
import { ReactComponent as ChartIcon } from 'assets/whitelabel/Chart.svg';
import { FunctionComponent, SVGProps } from 'react';

export const CREATORBASE_ROOT_PATH = 'c';

export type NavItem = {
    title: string;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    url: string;
    featureFlag?: FeatureFlagName;
};

export type NavItemGroup = {
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    title: string;
    items: NavItem[];
};

export const isNavItemGroup = (item: NavElement): item is NavItemGroup => {
    return (item as NavItemGroup).items !== undefined;
};

export type NavElement = NavItem | NavItemGroup;

export const routes: NavElement[] = [
    {
        title: 'Projects',
        //TODO: Replace with actual icon
        icon: ProjectsIcon,
        url: `/${CREATORBASE_ROOT_PATH}/projects/`,
    },
    {
        title: 'Songs',
        icon: SongIcon,
        url: `/${CREATORBASE_ROOT_PATH}/songs/`,
    },
    {
        title: 'Reports',
        icon: ChartIcon,
        url: `/${CREATORBASE_ROOT_PATH}/reports/`,
    },
];
