/**
 * Module to store all API requests related to MediaPlan entity
 * @module
 */
import { MediaPlanItemRow } from '../../../../App.types';
import { downloadFileFromResponse, fetchWithToken } from '../../../../helpers';
import { MediaPlan } from '@round/api';

type FetchTextToCopyResult = {
    text_to_copy: string;
};

export async function fetchTextToCopy(rows: MediaPlanItemRow[], mediaPlan: MediaPlan) {
    const response = await fetchWithToken('/api/advertising/text-to-copy', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            media_plan_id: mediaPlan.id,
            media_plan_item_id: rows.map((row) => row.id),
        }),
    });

    const result: FetchTextToCopyResult = await response.json();
    return result.text_to_copy;
}

export type MediaPlanReportTypes = 'pdf' | 'excel';

export async function downloadMediaPlanReport(mediaPlanId: number, type: MediaPlanReportTypes) {
    const fileTypeEndpoint = type === 'pdf' ? 'media-plan-report-pdf' : 'media-plan-report';
    const response = await fetchWithToken(`/api/advertising/${fileTypeEndpoint}/${mediaPlanId}`);

    if (!response.ok) {
        throw new Error(`Could not download media plan report`);
    }

    // For now pdf endpoint returns application/vnd.ms-excel content-type
    const mimeType = type === 'pdf' ? 'application/pdf' : '';
    await downloadFileFromResponse(response, mimeType);
}

type ExchangeRateResponseType = {
    exchange_rate_multiplier: string;
};

export async function getMediaPlanExchangeRatetoTargetCurrency(
    planId: number,
    currencyName: string,
    requestInit?: RequestInit
): Promise<ExchangeRateResponseType> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/media-plan/${planId}/exchange-rate-multiplier/?currency=${currencyName}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not fetch currency conversion rate`);
    }

    return response.json();
}
