import { useCallback, useMemo, useState } from 'react';
import { influencer } from '@round/api';
import { ValueType } from 'react-select';
import { showNotification } from '../../helpers';
import { debounce } from 'lodash';
import useAbortableEffect from '../useAbortableEffect';

export type InfluencerLocationOption = {
    value: number;
    label: string;
    countryCode: string;
    country: string;
    city: string;
    state: string;
    type: string;
};

export const makeLocationOption = (location: influencer.InfluencerLocation): InfluencerLocationOption => {
    return {
        value: location.id,
        label: `${location.country_name}${location.city ? `, ${location.city}` : ''}${
            location.state ? ', ' + location.state : ''
        }`,
        countryCode: location.country_code,
        country: location.country_name,
        city: location.city,
        state: location.state,
        type: location.type,
    };
};

type Params = Pick<
    Parameters<typeof influencer.getInfluencerLocations>[0],
    'country_code' | 'ordering' | 'state' | 'type'
>;

const PAGE_SIZE = 25;

export function useInfluencerLocationSelect<IsMulti extends boolean = false>({
    country_code,
    state,
    ordering,
    type,
}: Params = {}) {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [locations, setLocations] = useState<influencer.InfluencerLocation[]>([]);
    const [locationSelection, setLocationSelection] = useState<ValueType<InfluencerLocationOption, IsMulti>>(null);

    const fetchData = useCallback(
        async (params: Parameters<typeof influencer.getInfluencerLocations>[0], requestInit?: RequestInit) => {
            try {
                setIsLoading(true);
                const response = await influencer.getInfluencerLocations(params, requestInit);

                if (response.status === 404) {
                    showNotification(response.data.detail, 'error');
                    return;
                }

                setLocations((prev) => prev.concat(response.data.results));
                setHasNextPage(!!response.data.next);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                const errorMessage = e instanceof Error ? e.message : 'Could not fetch influencer locations';
                showNotification(errorMessage, 'error');
            } finally {
                setIsLoading(false);
                setIsSearchLoading(false);
            }
        },
        []
    );

    const debouncedFetchData = useMemo(() => debounce(fetchData, 700), [fetchData]);

    useAbortableEffect(
        (signal) => {
            debouncedFetchData(
                { search: search, page, page_size: PAGE_SIZE, country_code, ordering, state, type },
                { signal }
            );
        },
        [debouncedFetchData, page, search, country_code, ordering, state, type]
    );

    const reset = useCallback(() => {
        setPage(1);
        setLocations([]);
        setHasNextPage(false);
        setIsLoading(false);
        setIsSearchLoading(false);
    }, []);

    const options = useMemo(() => locations.map((location) => makeLocationOption(location)), [locations]);

    return {
        selectProps: {
            value: locationSelection,
            onChange: setLocationSelection,
            options,
            inputValue: search,
            onInputChange: (value: string) => {
                if (value === search) {
                    return;
                }

                reset();
                setSearch(value);

                if (value) {
                    setIsSearchLoading(true);
                }
            },
            onMenuScrollToBottom: () => {
                if (!hasNextPage) {
                    return;
                }
                setPage((prev) => prev + 1);
            },
            isLoading: isLoading || isSearchLoading,
            filterOption: null,
        },
        reset,
    };
}
