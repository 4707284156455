import { numberWithCommas } from 'helpers';

type Props = {
    values: (number | null | undefined)[];
};

const TotalFooterCell = ({ values }: Props) => {
    const isAllEmpty = values.every((value) => value === null || value === undefined);

    if (isAllEmpty) {
        return <span>-</span>;
    }

    const total: number =
        values.reduce((acc, value) => {
            return typeof value === 'number' ? value + (acc ?? 0) : acc;
        }, 0) ?? 0;

    return <span>{numberWithCommas(total)}</span>;
};

export default TotalFooterCell;
