import React from 'react';
import styles from './MetricCardWithChart.module.css';
import { formatNumberToKNotation, numberWithCommas } from '../../../../helpers';
import { IconProps } from '../../../../SharedComponents/svg/Icons/Icon.types';
import BarsSmallChart, { ChartValuesEntry } from '../../Charts/BarsSmallChart/BarsSmallChart';
import ReactTooltip from 'react-tooltip';
import Skeleton from '../../../Skeleton/Skeleton';

type MetricCardWithChartProps = {
    value: number | string | null | undefined;
    label: string;
    subtitle?: string;
    loading?: boolean;
    Icon?: (iconProps: IconProps) => JSX.Element;
    /**
     * Base color for a Metrics Card. Used as the icon color and other colors
     * are derived from it.
     */
    baseColor?: string;
    /**
     * A custom formatting function to display metrics value.
     * By default formats to K notation. (e.g. 500, 2.2K, 44K, 1.2M, 10M)
     * @param rawValue
     */
    format?: (rawValue: number) => string | number;
    /**
     * Values over time. Needs to be sorted.
     * Value is processed with format.
     */
    chartValues?: Array<ChartValuesEntry>;
};

const MetricCardWithChart = ({
    value,
    label,
    subtitle,
    loading,
    Icon,
    baseColor = '#707683',
    format = formatNumberToKNotation,
    chartValues = [],
}: MetricCardWithChartProps) => {
    const secondaryColor = `${baseColor}20`;
    const lastSevenValues = chartValues.slice(-7);
    const displayValue =
        value === null || value === undefined ? '-' : typeof value === 'number' ? format(value) : value;
    const tooltipValue = value === null || value === undefined ? '-' : numberWithCommas(value);
    const tooltipId = `${label}-${value}`;

    return (
        <div className={styles.container} onMouseLeave={() => ReactTooltip.hide()}>
            {Icon && (
                <div className={styles.iconContainer} style={{ backgroundColor: secondaryColor, color: baseColor }}>
                    {loading ? <Skeleton circle /> : <Icon width={16} height={16} stroke={baseColor} />}
                </div>
            )}
            <div className={styles.valuesContainer}>
                <p data-for={tooltipId} data-tip={tooltipValue} className={styles.value}>
                    {loading ? <Skeleton width="20%" inline /> : displayValue}
                    {subtitle && (
                        <span className={styles.subtitle}>{loading ? <Skeleton inline width="40%" /> : subtitle}</span>
                    )}
                </p>
                <ReactTooltip id={tooltipId} />
                <p className={styles.label}>{loading ? <Skeleton width="70%" /> : label}</p>
            </div>
            {!loading && (
                <BarsSmallChart
                    className={styles.chartContainer}
                    values={lastSevenValues}
                    format={format}
                    color={baseColor}
                />
            )}
        </div>
    );
};

export default MetricCardWithChart;
