import { useCallback, useContext } from 'react';
import { InfluencerPlanContext } from '../InfluencerPlanContext';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { TiktokCreatorsDispatchContext, TiktokCreatorsStateContext } from './TiktokCreatorsContext';
import { getTiktokInfluencerPlanStats } from '@round/api';

export default function useTiktokInfluencerPlanStats() {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const state = useNonNullContext(TiktokCreatorsStateContext);
    const dispatch = useNonNullContext(TiktokCreatorsDispatchContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (!influencerPlan?.id) {
                return;
            }

            try {
                dispatch({ type: 'loadTiktokInfluencerPlanStats' });
                const response = await getTiktokInfluencerPlanStats(influencerPlan.id, requestInit);
                if (response.status === 404) {
                    dispatch({ type: 'setTiktokInfluencerPlanStatsLoadingError', payload: response.data.detail });
                    return;
                }

                dispatch({ type: 'tiktokInfluencerPlanStatsInitialized', payload: response.data });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'setTiktokInfluencerPlanStatsLoadingError',
                    payload: "Sorry, we're experiencing technical issues",
                });
            }
        },
        [dispatch, influencerPlan?.id]
    );

    return {
        init,
        isInitialized: state.tiktokInfluencerPlanStatsInitialized,
        loadingError: state.tiktokInfluencerPlanStatsLoadingError,
        isLoading: state.tiktokInfluencerPlanStatsLoading,
        stats: state.tiktokInfluencerPlanStats,
    };
}
