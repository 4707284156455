import React from 'react';
import useFilterManager, { Filter, Options } from './useFilterManager';
import styles from './Filters.module.css';
import FilterMenu from './FiltersMenu/FiltersMenu';
import cn from 'classnames';

type Props<T extends string> = {
    sessionKey: string;
    filters: Filter<T>[];
    options?: Options<T>;
    className?: string;
};

const Filters = <T extends string>({ sessionKey, filters, options, className }: Props<T>) => {
    const manager = useFilterManager(sessionKey, filters, options);

    return (
        <div className={cn(styles.container, className)}>
            {manager.selectedFilters.map((filter) => {
                return (
                    <React.Fragment key={filter.name}>
                        {filter.render({
                            isOpen: manager.isOpen(filter.name),
                            open: () => manager.setOpen(filter.name, true),
                            close: () => manager.setOpen(filter.name, false),
                            remove: () => manager.setSelected(filter.name, false),
                        })}
                    </React.Fragment>
                );
            })}

            <FilterMenu
                filters={manager.unselectedFilters}
                onSelect={(filter) => {
                    manager.setSelected(filter.name, true);
                    manager.setOpen(filter.name, true);
                }}
            />
        </div>
    );
};

export default Filters;
