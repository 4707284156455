import { creatorbase, getTiktokAudios, postTikTokAudio, TiktokAudio } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<(TiktokAudio & Partial<{ video_count: number }>)[]>;

const initialState: State = {
    status: 'idle',
    data: [],
    error: null,
};

export function useSongFormTiktokAudios() {
    const [state, setState] = useState<State>(initialState);

    const fetchAudios = useCallback(async (ids: number[], requestInit: RequestInit) => {
        if (!ids.length) {
            setState({
                status: 'success',
                data: [],
                error: null,
            });
            return;
        }

        try {
            const response = await getTiktokAudios({ id: ids.toString(), page_size: ids.length }, requestInit);

            if (response.status === 200) {
                let audioData = response.data.results;

                const creatorbaseAudioResponse = await creatorbase.getTiktokAudios({
                    id: ids.toString(),
                    page_size: ids.length,
                });
                if (creatorbaseAudioResponse.status === 200) {
                    const creatorbaseAudioMap = new Map(creatorbaseAudioResponse.data.results.map((ca) => [ca.id, ca]));

                    audioData = audioData.map((audio) => {
                        const creatorbaseAudio = creatorbaseAudioMap.get(audio.id);
                        return creatorbaseAudio ? { ...audio, video_count: creatorbaseAudio.video_count } : audio;
                    });
                }

                setState({
                    status: 'success',
                    data: audioData,
                    error: null,
                });
                return;
            }

            setState({
                status: 'error',
                data: [],
                error: "Couldn't fetch audios",
            });
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                setState(initialState);
                return;
            }

            setState({
                status: 'error',
                data: [],
                error: "Couldn't fetch audios",
            });
        }
    }, []);

    const getOrCreateAudio = async (url: string) => {
        const findResponse = await getTiktokAudios({ identifier_search: url });

        if (findResponse.status !== 200) {
            throw new Error(`Couldn't add audio`);
        }

        let audio = findResponse.data.results[0] ?? null;

        if (!audio) {
            const postResponse = await postTikTokAudio({ identifier: url });

            if (postResponse.status === 201) {
                audio = postResponse.data;
            }
        }

        if (!audio) {
            throw new Error(`Couldn't add audio`);
        }

        const isAudioAlreadyInState = state.data?.find((a) => a.id === audio.id);
        if (!isAudioAlreadyInState) {
            setState((prev) => ({
                ...prev,
                data: [...(prev.data ?? []), audio],
            }));
        }

        return audio;
    };

    return {
        ...state,
        fetchAudios,
        addAudio: getOrCreateAudio,
        filterAudiosByIds: (ids: number[]) => {
            setState((prev) => ({
                ...prev,
                data: (prev.data ?? []).filter((audio) => ids.includes(audio.id)),
            }));
        },
    };
}
