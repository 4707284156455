import React, { ComponentType, useState } from 'react';
import { createPortal } from 'react-dom';
import { OptionProps } from 'react-select';
import { usePopper } from 'react-popper';
import { FacebookTargetingAudienceSelectionOption } from '../../../../../../../../App.types';
import styles from './FacebookDetailedTargeting.module.css';
import FacebookDetailedTargetingSelectionDescription from './components/FacebookDetailedTargetingSelectionDescription/FacebookDetailedTargetingSelectionDescription';

const FacebookTargetingSelectionOption: ComponentType<OptionProps<FacebookTargetingAudienceSelectionOption, false>> = (
    props
) => {
    const [showDescription, setShowDescription] = useState(false);
    const [optionRef, setOptionRef] = useState<HTMLDivElement | null>(null);
    const [popperElementRef, setPopperElementRef] = useState<HTMLDivElement | null>(null);
    const { styles: popperStyles, attributes: popperAttributes } = usePopper(optionRef, popperElementRef, {
        placement: 'right',
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
    });

    return (
        <div
            style={props.getStyles('option', props)}
            className={props.cx(
                {
                    option: true,
                    'option--is-disabled': props.isDisabled,
                    'option--is-focused': props.isFocused,
                    'option--is-selected': props.isSelected,
                },
                props.className
            )}
            ref={props.innerRef}
            {...props.innerProps}
        >
            <div
                ref={setOptionRef}
                className={styles.row}
                onMouseEnter={() => setShowDescription(true)}
                onMouseLeave={() => setShowDescription(false)}
            >
                <p className={styles.optionLabel}>{props.label}</p>
                <span className={styles.optionType}>{props.data.typeLabel}</span>
            </div>

            {showDescription &&
                createPortal(
                    <div
                        ref={setPopperElementRef}
                        className={styles.selectionDescriptionContainer}
                        style={{ ...popperStyles.popper, zIndex: 100000 }}
                        {...popperAttributes.popper}
                    >
                        <FacebookDetailedTargetingSelectionDescription selection={props.data} />
                    </div>,
                    document.body
                )}
        </div>
    );
};

export default FacebookTargetingSelectionOption;
