import React, { useMemo } from 'react';
import { AggregatedDataByType } from '../helpers';
import { Column } from 'react-table';
import Table from '../../../../../../../ui/NewTable/Table';
import { numberWithCommas } from '../../../../../../../helpers';
import Skeleton from '../../../../../../../ui/Skeleton/Skeleton';
import styles from './AudioTypesTable.module.css';

type AudiosUsedTableProps = {
    data: AggregatedDataByType[];
    loading: boolean;
    errorLoading: boolean;
};

const AudioTypesTable = ({ data, errorLoading, loading }: AudiosUsedTableProps) => {
    const columns = useMemo<Column<AggregatedDataByType>[]>(
        () => [
            {
                Header: 'Audio type',
                accessor: 'type',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Number of posts',
                accessor: 'numberOfPosts',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Average video plays',
                accessor: 'video_plays',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Average post likes',
                accessor: 'post_likes',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Average post comments',
                accessor: 'post_comments',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Average post shares',
                accessor: 'post_shares',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Average post saves',
                accessor: 'post_saves',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{typeof value === 'number' ? numberWithCommas(value) : value}</>;
                },
            },
        ],
        [loading]
    );

    return (
        <Table
            disableSortBy
            tableClassName={styles.table}
            columns={columns}
            data={data}
            loading={loading}
            noDataLabel={errorLoading ? 'Sorry, we are experiencing some technical issues' : 'No Data'}
        />
    );
};

export default AudioTypesTable;
