import { IconProps } from '../Icon.types';

const InstagramIcon = ({ width, height, className, onClick }: IconProps) => {
    return (
        <svg
            role="img"
            aria-label="Instagram icon"
            onClick={onClick}
            className={className}
            width={width}
            height={height}
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_89_13220)">
                <path
                    d="M9.5 11.3457C10.5194 11.3457 11.3457 10.5194 11.3457 9.5C11.3457 8.48065 10.5194 7.6543 9.5 7.6543C8.48065 7.6543 7.6543 8.48065 7.6543 9.5C7.6543 10.5194 8.48065 11.3457 9.5 11.3457Z"
                    fill="#727F8F"
                />
                <path
                    d="M9.5 0.710938C4.64594 0.710938 0.710938 4.64594 0.710938 9.5C0.710938 14.3541 4.64594 18.2891 9.5 18.2891C14.3541 18.2891 18.2891 14.3541 18.2891 9.5C18.2891 4.64594 14.3541 0.710938 9.5 0.710938ZM14.9302 11.7338C14.888 12.5694 14.6532 13.3934 14.0442 13.9959C13.4294 14.6041 12.6015 14.8307 11.7578 14.8725H7.2422C6.39845 14.8307 5.57066 14.6042 4.95581 13.9959C4.34683 13.3934 4.11206 12.5694 4.06987 11.7338V7.26617C4.11206 6.43058 4.34687 5.60659 4.95581 5.00408C5.57066 4.39587 6.39855 4.16926 7.2422 4.12753H11.7578C12.6016 4.16926 13.4293 4.39577 14.0442 5.00408C14.6532 5.60659 14.8879 6.43058 14.9301 7.26617L14.9302 11.7338Z"
                    fill="#727F8F"
                />
                <path
                    d="M11.7008 5.14543C10.6009 5.11526 8.39943 5.11526 7.29953 5.14543C6.72715 5.16114 6.07827 5.30363 5.67046 5.73922C5.24668 6.19199 5.08655 6.73902 5.0703 7.35099C5.04176 8.42508 5.0703 11.6489 5.0703 11.6489C5.0889 12.2608 5.24668 12.8079 5.67046 13.2607C6.07827 13.6964 6.72715 13.8388 7.29953 13.8545C8.39943 13.8846 10.6009 13.8846 11.7008 13.8545C12.2732 13.8388 12.9221 13.6963 13.3299 13.2607C13.7537 12.8079 13.9138 12.2609 13.93 11.6489V7.35099C13.9138 6.73902 13.7537 6.19199 13.3299 5.73922C12.9219 5.30349 12.273 5.16114 11.7008 5.14543ZM9.50003 12.3608C8.93421 12.3608 8.38109 12.193 7.91063 11.8787C7.44017 11.5643 7.07349 11.1175 6.85696 10.5947C6.64043 10.072 6.58377 9.49678 6.69416 8.94183C6.80454 8.38688 7.07701 7.87713 7.47711 7.47703C7.8772 7.07694 8.38696 6.80447 8.94191 6.69408C9.49685 6.58369 10.0721 6.64035 10.5948 6.85688C11.1176 7.07341 11.5644 7.44009 11.8787 7.91055C12.1931 8.38102 12.3609 8.93413 12.3609 9.49995C12.3609 10.2587 12.0595 10.9864 11.5229 11.5229C10.9864 12.0594 10.2588 12.3608 9.50003 12.3608ZM12.373 7.22963C12.2599 7.2296 12.1493 7.19602 12.0552 7.13313C11.9611 7.07025 11.8878 6.98088 11.8445 6.87632C11.8012 6.77177 11.7899 6.65673 11.812 6.54575C11.8341 6.43477 11.8886 6.33283 11.9686 6.25283C12.0487 6.17282 12.1506 6.11834 12.2616 6.09628C12.3726 6.07421 12.4876 6.08555 12.5922 6.12886C12.6967 6.17217 12.7861 6.24551 12.8489 6.3396C12.9118 6.43369 12.9453 6.5443 12.9453 6.65746C12.9453 6.73261 12.9305 6.80703 12.9018 6.87645C12.873 6.94588 12.8309 7.00896 12.7777 7.0621C12.7246 7.11523 12.6615 7.15737 12.592 7.18612C12.5226 7.21486 12.4482 7.22965 12.373 7.22963Z"
                    fill="#727F8F"
                />
            </g>
            <defs>
                <clipPath id="clip0_89_13220">
                    <rect width="18" height="18" fill="white" transform="translate(0.5 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default InstagramIcon;
