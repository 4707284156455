import { creatorbase } from '@round/api';
import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { SongDataContext } from '../../SongDataContext';
import uniq from 'lodash/uniq';

export default function useTiktokPostStats() {
    const songData = useNonNullContextSelector(SongDataContext, (value) => value[0].song);
    const state = useNonNullContextSelector(SongDataContext, (value) => value[0].tiktok.topPosts);
    const dispatch = useNonNullContextSelector(SongDataContext, (value) => value[1]);

    const fetchData = useCallback(
        async (page: number, page_size: number, ordering: string, signal?: AbortSignal) => {
            const songAudioIds = songData.data?.song?.tiktok_audios || [];
            const musicSongAudioIds = songData.data?.musicSong?.tiktok_audios || [];
            const mergedAudioIds = uniq([...songAudioIds, ...musicSongAudioIds]);
            if (mergedAudioIds.length === 0) {
                return;
            }

            dispatch({ type: 'loadTiktokPostStats' });

            try {
                const response = await creatorbase.postGetPostStats(
                    {
                        audio_ids: mergedAudioIds,
                        ordering: ordering,
                        page: page,
                        page_size: page_size,
                    },
                    signal
                );

                if (response.status !== 200) {
                    dispatch({ type: 'tiktokPostStatsErrorLoading', payload: 'Could not get tiktok audio post stats' });
                    return response;
                }

                const audioIds = response.data.results.map((post) => post.audio_id);

                if (!audioIds.length) {
                    dispatch({
                        type: 'tiktokPostStatsSuccess',
                        payload: { postsStats: response.data, audios: [] },
                    });
                    return response;
                }

                const audioResponse = await creatorbase.getTiktokAudios({ id: audioIds.join(',') });

                if (audioResponse.status !== 200) {
                    dispatch({ type: 'tiktokPostStatsErrorLoading', payload: 'Could not get posts audio data' });
                    return audioResponse;
                }

                dispatch({
                    type: 'tiktokPostStatsSuccess',
                    payload: { postsStats: response.data, audios: audioResponse.data.results },
                });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'tiktokPostStatsIdle' });
                    throw e;
                }

                dispatch({
                    type: 'tiktokPostStatsErrorLoading',
                    payload: e instanceof Error ? e.message : 'Could not get tiktok audio post stats',
                });
                throw e;
            }
        },
        [dispatch, songData.data?.song?.tiktok_audios, songData.data?.musicSong?.tiktok_audios]
    );

    const reset = useCallback(() => {
        dispatch({ type: 'tiktokPostStatsIdle' });
    }, [dispatch]);

    return {
        ...state,
        fetchData,
        reset,
    };
}
