import { Image, Skeleton } from '@round/ui-kit';
import styles from './Account.module.css';
import TruncatedTextCell from 'SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';

export type AccountData = {
    imageUrl: string | undefined;
    nickname: string;
    username: string;
    profileUrl: string;
};

type Props = {
    isLoading?: boolean;
    user: AccountData | undefined;
};

const Account = ({ isLoading, user }: Props) => {
    if (!isLoading && !user) {
        return <>-</>;
    }

    return (
        <div className={styles.container}>
            <Image loading={isLoading} className={styles.image} src={user?.imageUrl} />

            <div className={styles.titles}>
                {isLoading ? (
                    <Skeleton width="7rem" />
                ) : (
                    <TruncatedTextCell className={styles.nickname}>{user?.nickname ?? '-'}</TruncatedTextCell>
                )}

                {isLoading ? (
                    <Skeleton width="5rem" />
                ) : (
                    <a className={styles.username} target="_blank" rel="noopener noreferrer" href={user?.profileUrl}>
                        <TruncatedTextCell>{user?.username ? `@${user.username}` : '-'}</TruncatedTextCell>
                    </a>
                )}
            </div>
        </div>
    );
};

export default Account;
