import { ApiResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { TiktokInfluencerPost } from './tiktokInfluencerPost.types';
import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';
import { PaymentRequestStatus, PaypalPaymentStatus } from '../payments';

type GetTiktokInfluencerPostsParams = Partial<
    PaginatedRequest & {
        user_id: number;
        music_id: number;
        influencer_id: number;
        plan_id: number;
        xero_invoice_id: string;
        no_invoice: boolean;
        search: string;
        paypal_payment_status: PaypalPaymentStatus;
        payment_request_status: PaymentRequestStatus;
        ordering: string;
        id: string;
    }
>;

type GetTiktokInfluencerPostsResponse = ApiResponse<PaginatedApiResponseData<TiktokInfluencerPost>, 200>;

export async function getTiktokInfluencerPosts(
    params: GetTiktokInfluencerPostsParams,
    requestInit?: RequestInit
): Promise<GetTiktokInfluencerPostsResponse> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/tiktok-influencer-post/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not get tiktok influencer posts`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetTiktokInfluencerPostResponse = ApiResponse<TiktokInfluencerPost, 200 | 404>;

export async function getTiktokInfluencerPost(
    postId: number,
    requestInit?: RequestInit
): Promise<GetTiktokInfluencerPostResponse> {
    const response = await fetchWithToken(`/api/advertising/viewsets/tiktok-influencer-post/${postId}/`, requestInit);

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get tiktok influencer post: ${postId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getAllTiktokInfluencerPosts(
    params: GetTiktokInfluencerPostsParams,
    requestInit?: RequestInit
): Promise<TiktokInfluencerPost[]> {
    return await fetchAll(
        `/api/advertising/viewsets/tiktok-influencer-post/${encodeUrlSearchParams(params)}`,
        requestInit
    );
}
