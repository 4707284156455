import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ConfirmPassword.module.css';
import { Form, Formik, Field, FormikHelpers, ErrorMessage } from 'formik';
import FormField from '../../../../ui/DataEntry/FormField/FormField';
import Label from '../../../../ui/DataEntry/Label/Label';
import Button from '../../../../ui/Buttons/Button/Button';
import * as Yup from 'yup';
import { confirmPassword, validateConfirmPasswordToken } from '@round/api';
import cn from 'classnames';
import Skeleton from '../../../../ui/Skeleton/Skeleton';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

type FormValues = {
    password: string;
    confirmPassword: string;
};

const initialValues: FormValues = { password: '', confirmPassword: '' };

const validationSchema = Yup.object().shape({
    password: Yup.string().required('This field is required'),
    confirmPassword: Yup.string()
        .required('This field is required')
        .when('password', {
            is: (val: string | any[]) => !!(val && val.length > 0),
            then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
        }),
});

const ConfirmPassword = () => {
    const token = new URLSearchParams(window.location.search).get('token');
    const navigate = useNavigate();
    const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const tokenValidating = isTokenValid === null;

    const errorCopy = useMemo(() => {
        if (token === null) {
            return 'The link is broken';
        }

        if (isTokenValid === false) {
            return 'The link is outdated';
        }

        return errorMessage;
    }, [token, isTokenValid, errorMessage]);

    const validateToken = useCallback(async (token: string) => {
        try {
            await validateConfirmPasswordToken(token);
            setIsTokenValid(true);
        } catch {
            setIsTokenValid(false);
        }
    }, []);

    const onSubmit = useCallback(
        async ({ password }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
            if (token === null || !isTokenValid) {
                return;
            }

            try {
                setErrorMessage('');
                setSubmitting(true);
                await confirmPassword(password, token);
                navigate('/login/', { replace: true });
            } catch (e) {
                const errorMessage = e instanceof Error ? e.message : 'Could not confirm password';
                setErrorMessage(errorMessage);
            } finally {
                setSubmitting(false);
            }
        },
        [isTokenValid, navigate, token]
    );

    useEffect(() => {
        if (token === null) {
            setIsTokenValid(false);
            return;
        }
        validateToken(token);
    }, [token, validateToken]);

    return (
        <>
            <Helmet title="Confirm password | round" />
            <div className={styles.container}>
                <h1 className={styles.title}>{tokenValidating ? <Skeleton width="60%" /> : 'Confirm password'}</h1>
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                    {({ isSubmitting, submitForm, isValid }) => (
                        <Form className={styles.form}>
                            <FormField>
                                <Label htmlFor="password">
                                    {tokenValidating ? <Skeleton width="40%" /> : 'Password'}
                                </Label>
                                {tokenValidating ? (
                                    <Skeleton height="2.5rem" />
                                ) : (
                                    <Field
                                        type="password"
                                        id="password"
                                        name="password"
                                        placeholder="Enter new password"
                                        disabled={!isTokenValid}
                                    />
                                )}
                                <ErrorMessage name="password">
                                    {(msg) => <span className={styles.error}>{msg}</span>}
                                </ErrorMessage>
                            </FormField>

                            <FormField>
                                <Label htmlFor="confirmPassword">
                                    {tokenValidating ? <Skeleton width="40%" /> : 'Confirm password'}
                                </Label>
                                {tokenValidating ? (
                                    <Skeleton height="2.5rem" />
                                ) : (
                                    <Field
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        placeholder="Confirm password"
                                        disabled={!isTokenValid}
                                    />
                                )}
                                <ErrorMessage name="confirmPassword">
                                    {(msg) => <span className={styles.error}>{msg}</span>}
                                </ErrorMessage>
                            </FormField>

                            {tokenValidating ? (
                                <Skeleton className={cn(styles.submit, styles.skeleton)} />
                            ) : (
                                <Button
                                    type="filled"
                                    color="black"
                                    htmlType="button"
                                    loading={isSubmitting}
                                    className={styles.submit}
                                    onClick={submitForm}
                                    disabled={!isValid || !isTokenValid}
                                >
                                    Confirm password
                                </Button>
                            )}

                            {errorCopy && !tokenValidating && <p className={styles.error}>{errorCopy}</p>}
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default ConfirmPassword;
