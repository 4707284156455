import { GenreAffinityStats } from '@round/api';
import { transformSnakeCaseToCapitalizedString } from 'utility/utility';
import styles from './GenreAffinityCell.module.css';

type Props = {
    genreAffinityStats: GenreAffinityStats;
};

const GenreAffinityCell = ({ genreAffinityStats }: Props) => {
    const { score, relevant_post_count: relevantPostCount, embedding: genreScores } = genreAffinityStats;

    const genreAffinity = parseFloat(score);
    const genreAffinityDisplayValue = !isNaN(genreAffinity) ? `${(genreAffinity * 100).toFixed(2)}%` : '-';

    const topGenres = Object.entries(genreScores)
        .sort(([, scoreA], [, scoreB]) => parseFloat(scoreB) - parseFloat(scoreA))
        .slice(0, 3);

    const topGenresDisplayValue = topGenres.map(([genre]) => transformSnakeCaseToCapitalizedString(genre)).join(', ');

    return (
        <div className={styles.container}>
            <div className={styles.scoreRow}>
                <span className={styles.score}>{genreAffinityDisplayValue}</span>
                <span>({relevantPostCount} posts)</span>
            </div>
            <div className={styles.topGenres}>
                <span className={styles.topGenresLabel}>Top Genres: </span>
                <span>{topGenresDisplayValue}</span>
            </div>
        </div>
    );
};

export default GenreAffinityCell;
