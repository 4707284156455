import { useLayoutEffect, useState } from 'react';

const setOverflowingAttribute = (item: HTMLElement) => {
    if (item.dataset.overflowing !== 'ignore') {
        item.dataset.overflowing = 'overflowing';
    }
};

export const isOverflowingItem = (item: HTMLElement) => item.dataset.overflowing === 'overflowing';

export default function useOverflowingChildren<T extends HTMLElement>() {
    const [containerRef, setContainerRef] = useState<T | null>(null);
    const [overflowingChildren, setOverflowingChildren] = useState<HTMLElement[]>([]);

    useLayoutEffect(() => {
        if (!containerRef) {
            return;
        }

        const observer = new MutationObserver((mutations) => {
            const [mutation] = mutations;
            const containerRect = containerRef.getBoundingClientRect();
            const overflowingChildren = Array.from(containerRef.children).filter((item): item is HTMLElement => {
                const rect = item.getBoundingClientRect();
                return rect.right >= containerRect.right;
            });

            overflowingChildren.forEach(setOverflowingAttribute);
            const addedNodes: HTMLElement[] = (Array.from(mutation.addedNodes) as unknown) as HTMLElement[];
            addedNodes.forEach((item) => {
                const rect = item.getBoundingClientRect();
                if (rect.right >= containerRect.right) {
                    setOverflowingAttribute(item);
                }
            });

            setOverflowingChildren((prev) => {
                /*
                    Mutation observer is called every time there are new child elements added.
                    But if there are already overflowing children, then every newly added child
                    is overflowing by default.
                    In case CSS hides overflowing children returned from this hook,
                    default case with returning computed overflowing children would be wrong,
                    because newly added element wouldn't be hidden initially.
                */
                if (prev.length && addedNodes.length) {
                    return prev.concat(addedNodes).filter(isOverflowingItem);
                }

                return overflowingChildren.filter(isOverflowingItem);
            });
        });

        observer.observe(containerRef, { childList: true, attributes: true });

        return () => observer.disconnect();
    }, [containerRef]);

    return {
        ref: setContainerRef,
        overflowingChildren,
        reset: () => setOverflowingChildren([]),
    };
}
