import { microwave } from '@round/api';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { Form, Formik, FormikHelpers } from 'formik';
import useNonNullContext from 'Hooks/useNonNullContext';
import SelectField from 'ui-new/formik/SelectField';
import Button from 'ui/Button/Button';
import FullScreenModal from 'ui/FullScreenModal/FullScreenModal';
import CampaignForm from '../components/CampaignForm/CampaignForm';
import { CampaignFormValues, validationSchema } from '../components/CampaignForm/helpers';
import ExistingCampaigns from '../components/ExistingCampaigns/ExistingCampaigns';
import { MicroCampaignsTableRow } from '../MicroCampaignsTable/MicroCampaignsTable';
import styles from './EditCampaignModal.module.css';
import { getObjectDifference, showNotification } from 'helpers';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import useInstagramAudio from 'Modules/Instagram/hooks/useInstagramAudio';
import useTikTokAudio from 'Modules/TikTok/hooks/useTikTokAudio';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { mapInstagramAudioToOption } from 'Modules/Instagram/hooks/selectHooks/useInstagramAudiosSelect';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    campaign: MicroCampaignsTableRow | null;
    relatedCampaigns: MicroCampaignsTableRow[];
    updateCampaign: typeof microwave.patchCampaign;
};

const EditCampaignModal = ({ isOpen, onClose, campaign, relatedCampaigns, updateCampaign }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);

    const {
        audio: instagramAudio,
        init: initInstagramAudio,
        isInitialized: isInstagramAudioInitialized,
        reset: resetInstagramAudio,
    } = useInstagramAudio(campaign?.instagram_audio_id);

    const {
        data: tiktokAudio,
        fetchData: fetchTikTokAudio,
        status: tiktokAudioRequestStatus,
        reset: resetTikTokAudio,
    } = useTikTokAudio();

    useAbortableEffect(
        (signal) => {
            if (campaign?.instagram_audio_id && !isInstagramAudioInitialized) {
                initInstagramAudio({ signal });
            }
        },
        [campaign?.instagram_audio_id, initInstagramAudio, isInstagramAudioInitialized]
    );

    useAbortableEffect(
        (signal) => {
            if (
                campaign?.tiktok_audio_id &&
                tiktokAudioRequestStatus !== 'success' &&
                tiktokAudioRequestStatus !== 'error'
            ) {
                fetchTikTokAudio(campaign.tiktok_audio_id, { signal }).catch(() => {});
            }
        },
        [campaign?.tiktok_audio_id, fetchTikTokAudio, tiktokAudioRequestStatus]
    );

    const handleClose = () => {
        resetInstagramAudio();
        resetTikTokAudio();
        onClose();
    };

    if (!campaign) {
        return null;
    }

    const selectedCurrency = currencies.find((c) => c.id === campaign.currency_id);
    const initialValues: CampaignFormValues = {
        release: { value: campaign.release.id, label: campaign.release.name },
        planned_start_date: campaign.planned_start_date,
        planned_end_date: campaign.planned_end_date,
        platform: campaign.platform,
        target_post_count: campaign.target_post_count,
        team_members: campaign.planners.map((planner) => ({
            value: planner.id,
            label: `${planner.first_name} ${planner.last_name}`,
        })),
        budget: campaign.budget.toString(),
        currency: selectedCurrency
            ? {
                  value: selectedCurrency?.id,
                  label: selectedCurrency?.name,
              }
            : null,
        instagram_audio: instagramAudio ? mapInstagramAudioToOption(instagramAudio) : null,
        tiktok_audio: tiktokAudio
            ? { value: tiktokAudio.id, label: `${tiktokAudio.title} - ${tiktokAudio.tiktok_id}` }
            : null,
        is_instagram_audio_select_mode: true,
        is_tiktok_audio_select_mode: true,
    };

    const handleSubmit = async (values: CampaignFormValues, helpers: FormikHelpers<CampaignFormValues>) => {
        // shouldn't probably block the update while backfilling isn't done yet.
        if (
            Object.entries(values)
                .filter(([key]) => key !== 'tiktok_audio' && key !== 'instagram_audio')
                .some(([, value]) => value === null || value === undefined)
        ) {
            console.error('Some form values are missing');
            return;
        }

        const dataFromValues: microwave.PatchCampaignData = {
            planned_start_date: values.planned_start_date!,
            planned_end_date: values.planned_end_date!,
            target_post_count: values.target_post_count!,
            currency_id: values.currency?.value!,
            team_members: values.team_members?.map((member) => member.value) || [],
            budget: parseFloat(values?.budget!),
        };

        const changedData = getObjectDifference(dataFromValues, campaign);

        try {
            helpers.setSubmitting(true);
            const response = await updateCampaign(campaign.id, changedData);

            if (response.status === 200) {
                showNotification('Campaign updated', 'info');
                helpers.resetForm();
                handleClose();
                return;
            }

            if (response.status === 404) {
                showNotification('Campaign not found', 'error');
                return;
            }

            if (Array.isArray(response.data)) {
                showNotification(response.data.join(', '), 'error');
                return;
            }

            helpers.setErrors(
                mapApiErrorsToFormikErrors({
                    target_post_count: response.data.target_post_count,
                    currency: response.data.currency_id,
                    planned_end_date: response.data.planned_end_date,
                    planned_start_date: response.data.planned_start_date,
                    budget: response.data.budget,
                    team_members: response.data.team_members,
                })
            );
        } catch {
            showNotification('Could not create campaign', 'error');
        }
    };

    return (
        <FullScreenModal isOpen={isOpen} onClose={handleClose} closeOnEscape>
            <FullScreenModal.Header>Edit Campaign</FullScreenModal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={handleSubmit}
            >
                {({ submitForm, isSubmitting, dirty }) => (
                    <>
                        <FullScreenModal.Content className={styles.content}>
                            <Form className={styles.form}>
                                <SelectField
                                    label="Release"
                                    name="release"
                                    placeholder="Select a release"
                                    isClearable
                                    isSearchable
                                    disabled
                                />

                                <CampaignForm
                                    availablePlatforms={[campaign.platform]}
                                    readonlyFields={['instagram_audio', 'tiktok_audio']}
                                />
                            </Form>

                            <ExistingCampaigns campaigns={relatedCampaigns} />
                        </FullScreenModal.Content>

                        <FullScreenModal.Footer>
                            <Button appearance="ghost" onClick={handleClose}>
                                Cancel
                            </Button>

                            <Button
                                appearance="primary"
                                type="submit"
                                onClick={submitForm}
                                disabled={isSubmitting || !dirty}
                                isLoading={isSubmitting}
                            >
                                Save changes
                            </Button>
                        </FullScreenModal.Footer>
                    </>
                )}
            </Formik>
        </FullScreenModal>
    );
};

export default EditCampaignModal;
