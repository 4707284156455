import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Post } from './posts.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';

export const models = {
    creatorbasePost: Model as ModelDefinition<Post>,
};

export const serializers = {
    creatorbasePost: makeSerializer<Post>([]),
};

export const factories = {
    creatorbasePost: createFactory<Post>({
        id: (index: number) => index.toString(),
        cost: null,
        campaign_id: (index: number) => index,
        post_url: (index: number) => `post url ${index}`,
        platform: 'youtube',
        tiktok_details: null,
        instagram_details: null,
        youtube_details: null,
    }),
};

export function handleRequests(server: AppServer) {
    const postsRootUrl = '/api/creatorbase/posts/';
    server.get(postsRootUrl, function (this: RouteHandlerContext, schema, request) {
        const campaignIds =
            request.queryParams.campaign_id
                ?.split(',')
                .filter((v) => !!v)
                .map(Number) ?? [];

        const posts = schema
            .all('creatorbasePost')
            .filter((post) => !campaignIds.length || campaignIds.includes(Number(post.campaign_id)));

        return buildPaginatedResponse(posts, {
            url: postsRootUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'creatorbasePost'),
        });
    });

    server.post(postsRootUrl, (schema, request) => {
        return schema.create('creatorbasePost', JSON.parse(request.requestBody));
    });

    server.patch(`${postsRootUrl}:id/`, (schema, request) => {
        const post = schema.find('creatorbasePost', request.params.id);
        if (!post) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        post.update(JSON.parse(request.requestBody));
        return post;
    });

    server.delete(`${postsRootUrl}:id/`, (schema, request) => {
        const post = schema.find('creatorbasePost', request.params.id);
        if (!post) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        post.destroy();
        return new Response(204);
    });
}
