import React, { useCallback, useContext, useMemo } from 'react';
import styles from './CreateTiktokInfluencerPostModal.module.css';
import { GenericDropdownOption } from '../../../../../../App.types';
import Modal, { ModalContent, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import TiktokInfluencerPostForm, {
    TiktokInfluencerPostFormValues,
} from '../InfluencerPostForm/TiktokInfluencerPostForm';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { TiktokCreatorsContext } from '../../../contexts/TiktokCreatorsContext/TiktokCreatorsContext';
import { FormikHelpers } from 'formik';
import { InfluencerPlanContext } from '../../../contexts/InfluencerPlanContext';
import { OptionsContext } from '../../../../../../contexts/OptionsContext/OptionsContext';
import { mapApiErrorsToFormikErrors } from '../../../../../../utility/utility';
import { showNotification } from '../../../../../../helpers';

type CreateTiktokInfluencerPostModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onCreated: () => void;
    groupId: number | null;
};

const CreateTiktokInfluencerPostModal = ({
    isOpen,
    onClose,
    onCreated,
    groupId,
}: CreateTiktokInfluencerPostModalProps) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const { createTiktokInfluencerPost } = useNonNullContext(TiktokCreatorsContext);
    const { initialized, influencerPlan } = useContext(InfluencerPlanContext);

    const currencyOptions: GenericDropdownOption<number>[] = useMemo(
        () =>
            currencies.map((c) => ({
                value: c.id,
                label: c.name,
            })),
        [currencies]
    );

    const initialValues: TiktokInfluencerPostFormValues = {
        influencer_id: null,
        currency_id: currencyOptions[0],
        cost: '',
        xero_invoice_id: null,
        is_carousel: false,
        post_url: '',
    };

    const handleSubmit = useCallback(
        async (
            values: TiktokInfluencerPostFormValues,
            { setSubmitting, setErrors }: FormikHelpers<TiktokInfluencerPostFormValues>
        ) => {
            if (!initialized || !influencerPlan || !groupId) {
                return;
            }

            try {
                setSubmitting(true);
                const response = await createTiktokInfluencerPost(groupId, {
                    influencer_id: values.influencer_id?.value ?? null,
                    currency_id: values.currency_id?.value ?? currencyOptions[0].value,
                    cost: values.cost ? values.cost : '',
                    post_url: values.post_url ?? '',
                    xero_invoice_id: values.xero_invoice_id ? values.xero_invoice_id.value : null,
                    is_carousel: values.is_carousel ?? false,
                });

                if (!response) {
                    showNotification("Could't create post. No associated influencer plan", 'error');
                    return;
                }

                if (response.status === 400) {
                    setErrors(mapApiErrorsToFormikErrors(response.data));
                    return;
                }
                onCreated();
            } catch {
                // no op
            } finally {
                setSubmitting(false);
            }
        },
        [createTiktokInfluencerPost, initialized, influencerPlan, groupId, currencyOptions, onCreated]
    );

    return (
        <Modal modalClassName={styles.modal} isOpen={isOpen} onClose={onClose} closeOnOverlayClick>
            <ModalTitle>Create Post</ModalTitle>
            <ModalContent className={styles.content}>
                <TiktokInfluencerPostForm handleSubmit={handleSubmit} initialValues={initialValues} />
            </ModalContent>
        </Modal>
    );
};

export default CreateTiktokInfluencerPostModal;
