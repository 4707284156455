import { creatorbase, influencer, TiktokAudio, TiktokTimeSeriesResponse } from '@round/api';
import { getTableMetaHelper, TableProps, ProgressBar } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './PublicReportCampaignTable.module.css';
import TruncationPopover from 'ui-new/TruncationPopover/TruncationPopover';
import CampaignsTable from 'Modules/Plans/Campaigns/components/CampaignsTable/CampaignsTable';
import ExpandCell from 'Modules/Plans/Campaigns/components/cells/ExpandCell/ExpandCell';
import ProjectCell, {
    Meta as ProjectCellMeta,
    ProjectData,
} from 'Modules/Plans/Campaigns/components/cells/ProjectCell/ProjectCell';
import CampaignStatsCell from 'Modules/Plans/Campaigns/components/cells/CampaignStatsCell';
import { CampaignsTableMeta } from 'Modules/Plans/Campaigns/types';
import TooltipHeader from 'ui-new/whitelabel/Table/TooltipHeader/TooltipHeader';
import { tooltipCopy } from 'Modules/Plans/helpers';
import { toDecimalPoint } from '@round/utils';
import DurationCell from 'Modules/Plans/Campaigns/components/cells/DurationCell';
import AudioGraph from 'Modules/Plans/components/AudioGraph/AudioGraph';
import { buildTiktokMusicUrl, numberWithCommas } from 'helpers';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';

export type CampaignTableRow = creatorbase.PublicCampaign & {
    stats: influencer.AggregatedPostStats | null;
    project: creatorbase.PublicProject | null;
    brand: creatorbase.PublicBrand | null;
    team: creatorbase.PublicTeam | null;
    audioTimeSeries?: TiktokTimeSeriesResponse[number] | null;
    audio?: TiktokAudio | null;
};

type Props = Pick<
    TableProps<CampaignTableRow>,
    'data' | 'expandedState' | 'onExpandedChange' | 'renderSubComponent'
> & {
    hasError?: boolean;
    isLoading: boolean;
    meta: Meta;
};

type TableCellContext<TKey extends keyof CampaignTableRow> = CellContext<CampaignTableRow, CampaignTableRow[TKey]>;

export type Meta = CampaignsTableMeta &
    Pick<ProjectCellMeta<CampaignTableRow>, 'getIsBrandLoading' | 'getIsProjectLoading' | 'getIsTeamLoading'>;
const getTableMeta = getTableMetaHelper<Meta>();

//TODO: Split into table per platform
const PublicReportCampaignsTable = ({
    data,
    isLoading,
    hasError,
    expandedState,
    onExpandedChange,
    renderSubComponent,
    meta,
}: Props) => {
    const featureFlags = useFeatureFlags();
    const arePlannedPostsEnabled = featureFlags.isEnabled('cb-release-nov-24');

    const columns = useMemo<ColumnDef<CampaignTableRow, any>[]>(
        () => [
            {
                header: '',
                id: 'expand',
                cell: ExpandCell,
            },
            {
                header: 'Project',
                id: 'project',
                accessorFn: (row): ProjectData => ({
                    name: row.project?.name ?? '-',
                    brandImage: row.brand?.image,
                    brandName: row.brand?.name ?? '-',
                    teamName: row.team?.name ?? '-',
                }),
                cell: ProjectCell,
            },
            {
                header: 'Campaign',
                accessorKey: 'name',
                meta: {
                    className: styles.campaignColumn,
                },
                cell: ({ getValue, table }: TableCellContext<'name'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <TruncationPopover content={getValue()}>
                            {(setRef) => (
                                <p ref={setRef} className={styles.campaignName}>
                                    {getValue()}
                                </p>
                            )}
                        </TruncationPopover>
                    );
                },
            },
            {
                header: 'Posts',
                id: 'posts',
                cell: ({ row: { original }, table }) => {
                    const { isLoading, getIsCampaignStatsLoading } = getTableMeta(table);
                    const stats = original.stats;

                    if (isLoading || getIsCampaignStatsLoading?.(original)) {
                        return <Skeleton />;
                    }

                    if (!stats) {
                        return '-';
                    }

                    const { post_count: postCount, live_post_count: livePostCount } = stats;
                    if (!arePlannedPostsEnabled) {
                        return postCount;
                    }

                    return postCount === 0 ? 0 : `${livePostCount}/${postCount}`;
                },
            },
            {
                header: 'Progress',
                id: 'progress',
                cell: ({ row: { original }, table }) => {
                    const { isLoading, getIsCampaignStatsLoading } = getTableMeta(table);
                    const stats = original.stats;

                    if (isLoading || getIsCampaignStatsLoading?.(original)) {
                        return <Skeleton />;
                    }

                    if (!stats) {
                        return '-';
                    }

                    const { post_count: postCount, live_post_count: livePostCount } = stats;
                    const progressPercentage = postCount ? (livePostCount / postCount) * 100 : null;

                    return (
                        <div className={styles.progressContainer}>
                            <p className={styles.progressPercentage}>
                                {typeof progressPercentage === 'number'
                                    ? `${toDecimalPoint(progressPercentage, 2)}%`
                                    : '-'}
                            </p>
                            {typeof progressPercentage === 'number' && (
                                <ProgressBar
                                    progress={progressPercentage}
                                    trackClassName={styles.progressTrack}
                                    barClassName={styles.progressBar}
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                header: 'Views',
                id: 'views',
                accessorFn: (row) => (row.stats?.view_count ? numberWithCommas(row.stats.view_count) : '-'),
                cell: CampaignStatsCell,
            },
            {
                header: () => (
                    <TooltipHeader
                        header="ER"
                        tooltipTitle="Engagement Rate"
                        tooltipBody={tooltipCopy.engagementRate}
                    />
                ),
                id: 'er',
                accessorFn: (row) =>
                    typeof row.stats?.engagement_rate === 'number'
                        ? `${toDecimalPoint(row.stats.engagement_rate * 100, 2)}%`
                        : '-',
                cell: CampaignStatsCell,
            },
            {
                header: 'Duration',
                id: 'duration',
                cell: DurationCell,
            },
            {
                header: 'Audio',
                accessorKey: 'audioTimeSeries',
                meta: {
                    className: styles.audioGraph,
                },
                id: 'audio',
                cell: ({ getValue, table, row }: TableCellContext<'audioTimeSeries'>) => {
                    const { isLoading, getIsTimeSeriesLoading } = getTableMeta(table);
                    const isDataLoading = isLoading || !!getIsTimeSeriesLoading?.(row.original);

                    const timeSeriesData = getValue()?.posts_daily_change ?? [];

                    const shouldDisplayTimeSeriesData = !!timeSeriesData.length;
                    const points = timeSeriesData.map((s) => ({ x: s.timestamp, y: s.value }));

                    const audio = row.original.audio;
                    const audioUrl = audio ? buildTiktokMusicUrl(audio.title, audio.tiktok_id) : undefined;

                    return (
                        <AudioGraph
                            audioUrl={audioUrl}
                            points={points}
                            shouldDisplayTimeSeriesData={shouldDisplayTimeSeriesData}
                            isTimeSeriesLoading={isDataLoading}
                        />
                    );
                },
            },
        ],
        [arePlannedPostsEnabled]
    );

    return (
        <CampaignsTable
            data={data}
            columns={columns}
            meta={meta}
            isLoading={isLoading}
            hasError={hasError}
            expandedState={expandedState}
            onExpandedChange={onExpandedChange}
            renderSubComponent={renderSubComponent}
            columnVisibility={{
                audio: data.some((d) => d.audioTimeSeries),
                progress: arePlannedPostsEnabled,
            }}
        />
    );
};

export default PublicReportCampaignsTable;
