import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './MediaPlanResultsModal.module.css';
import ModalRight from '../../../../../SharedComponents/ModalRight/ModalRight';
import * as Metrics from '../../../../../Modules/Advertising/Metrics/Metrics';
import HeadlineStatsCard from './HeadlineStatsCard/HeadlineStatsCard';
import { applyCommission, formatNumberToKNotation, formatTo2dp, showNotification } from '../../../../../helpers';
import { GenericDropdownOption, MediaPlanItem, MediaPlanItemRow } from '../../../../../App.types';
import PlatformFormatBreakdown from './PlatformFormatBreakdown/PlatformFormatBreakdown';
import { ReportItem } from '../../types/MediaPlanResults.types';
import Tabs from '../../../../../ui/Tabs/Tabs';
import TargetingBreakdown from './TargetingBreakdown/TargetingBreakdown';
import CloseIcon from '../../../../../SharedComponents/svg/Icons/CloseIcon';
import DropdownButton from '../../../../../ui/Buttons/DropdownButton/DropdownButton';
import Button from '../../../../../ui/Buttons/Button/Button';
import pick from 'lodash/pick';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { AdvertisingContext } from '../../contexts/AdvertisingContext/AdvertisingContext';
import { ReactComponent as ChevronDownIcon } from '../../../../../assets/ChevronDown.svg';
import { Popover } from '@round/ui-kit';
import MediaPlanItemGroup, {
    MediaPlanItemGroupedOption,
} from './SelectComponents/MediaPlanItemGroup/MediaPlanItemGroup';
import MediaPlanItemOption from './SelectComponents/MediaPlanItemOption/MediaPlanItemOption';
import { ReactComponent as DownloadDocumentIcon } from '../../assets/DownloadDocument.svg';
import Image from '../../../../../ui/Image/Image';
import CustomDatePicker from '../../../../../SharedComponents/CustomDatePicker/CustomDatePicker';
import { downloadMediaPlanResults, getReports } from '../../api/MediaPlanResults.api';
import {
    aggregateReportItemsByMediaPlanItemId,
    createPlaylistReportItem,
    createReportItem,
    getTotalReportItemValues,
    mapFacebookDailyReportItemToReportItem,
    mapSnapchatDailyReportItemToReportItem,
    mapTikTokDailyReportItemToReportItem,
    mapTwitterDailyReportItemToReportItem,
    mapYouTubeDailyReportItemsToReportItem,
} from '../../helpers/MediaPlanResult.helpers';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import MediaPlanResultsChart from './MediaPlanResultsChart/MediaPlanResultsChart';
import { resetTime } from '../../../../../utility/utility';
import uniq from 'lodash/uniq';
import PlatformSummaryTable, { PlatformSummaryTableRow } from './PlatformSummaryTable/PlatformSummaryTable';
import CreativeBreakdown from './CreativeBreakdown/CreativeBreakdown';
import { Checkbox } from '@round/ui-kit';
import { sortNumerically } from '@round/utils';
import useUrlState from 'Hooks/useUrlState';
import moment from 'moment';

export type ChannelFormatBreakdownRow = ReportItem & {
    cost?: MediaPlanItemRow['cost'];
    startDate?: string;
    endDate?: string;
    channel: string;
    format: string;
    objective: string;
};

type MediaPlanResultsModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
};

export type TableList = {
    id: number | undefined;
    title: string | undefined;
    data: ChannelFormatBreakdownRow[];
};

const SPOTIFY_CHANNEL_NAME = 'spotify';
const YOUTUBE_CHANNEL_NAME = 'youtube';
const PLAYLIST_FORMAT_NAME = 'playlists';

type UrlState = Partial<{
    hideEmpty: boolean;
    start: string;
    end: string;
}>;

const MediaPlanResultsModal = ({ isModalOpen, closeModal }: MediaPlanResultsModalProps) => {
    const [contextState] = useNonNullContext(AdvertisingContext);
    const [urlState, setUrlState] = useUrlState<UrlState>();

    const [reportItemsInitialized, setReportItemsInitialized] = useState(false);
    const [reportItems, setReportItems] = useState<ReportItem[]>([]);
    const [selectedMediaPlanItems, setSelectedMediaPlanItems] = useState<ReadonlyArray<GenericDropdownOption<number>>>(
        []
    );

    const shouldHideEmptyRows = urlState.hideEmpty === 'true';
    const dateRangeStartDate = useMemo(() => (urlState.start ? new Date(urlState.start) : null), [urlState.start]);
    const setDateRangeStartDate = useCallback(
        (date: Date | string | null) => setUrlState({ start: date ? moment(date).format('YYYY-MM-DD') : undefined }),
        [setUrlState]
    );

    const dateRangeEndDate = useMemo(() => (urlState.end ? new Date(urlState.end) : null), [urlState.end]);
    const setDateRangeEndDate = useCallback(
        (date: Date | string | null) => setUrlState({ end: date ? moment(date).format('YYYY-MM-DD') : undefined }),
        [setUrlState]
    );

    useAbortableEffect(
        (signal) => {
            async function fetchReportItems() {
                const mediaPlanId = contextState.mediaPlan?.id;
                if (typeof mediaPlanId !== 'number') {
                    return;
                }

                const responses = await Promise.all([
                    getReports(mediaPlanId, 'youtube', { signal }).then((items) =>
                        items.map(mapYouTubeDailyReportItemsToReportItem)
                    ),
                    getReports(mediaPlanId, 'snapchat', { signal }).then((items) =>
                        items.map(mapSnapchatDailyReportItemToReportItem)
                    ),
                    getReports(mediaPlanId, 'facebook', { signal }).then((items) =>
                        items.map(mapFacebookDailyReportItemToReportItem)
                    ),
                    getReports(mediaPlanId, 'tiktok', { signal }).then((items) =>
                        items.map(mapTikTokDailyReportItemToReportItem)
                    ),
                    getReports(mediaPlanId, 'twitter', { signal }).then((items) =>
                        items.map(mapTwitterDailyReportItemToReportItem)
                    ),
                ]);

                setReportItems(responses.flat());
                setReportItemsInitialized(true);
            }

            if (isModalOpen && !reportItemsInitialized) {
                fetchReportItems();
            }
        },
        [contextState.mediaPlan?.id, isModalOpen, reportItemsInitialized]
    );

    const dailyReportItems = useMemo<ReportItem[]>(() => {
        const filterSpotifyPlaylistCampaigns = (item: MediaPlanItem) =>
            item.channel.name.toLowerCase() === SPOTIFY_CHANNEL_NAME &&
            item.format.name.toLowerCase() === PLAYLIST_FORMAT_NAME;

        const filterYoutubePlaylistCampaigns = (item: MediaPlanItem) =>
            item.channel.name.toLowerCase() === YOUTUBE_CHANNEL_NAME &&
            item.format.name.toLowerCase() === PLAYLIST_FORMAT_NAME;

        const filterNonPlaylistResults = (item: ReportItem) =>
            contextState.mediaPlanItems
                .find((mediaPlanItem) => mediaPlanItem.id === item.mediaPlanItemId)
                ?.format.name.toLowerCase() !== PLAYLIST_FORMAT_NAME;

        const spotifyPlaylists = contextState.mediaPlanItems
            .filter(filterSpotifyPlaylistCampaigns)
            .map(createPlaylistReportItem);
        const youtubePlaylists = contextState.mediaPlanItems
            .filter(filterYoutubePlaylistCampaigns)
            .map(createPlaylistReportItem);

        const playlistResults = spotifyPlaylists.concat(youtubePlaylists);
        const nonPlaylistResultsWithCommission = reportItems.filter(filterNonPlaylistResults).map(
            (item): ReportItem => ({
                ...item,
                amount_spent: applyCommission(
                    item.amount_spent,
                    Number(contextState.mediaPlan?.client_commission ?? '0')
                ),
            })
        );

        return nonPlaylistResultsWithCommission
            .concat(playlistResults)
            .filter((r) => {
                const date = resetTime(new Date(r.day)).getTime();
                return (dateRangeStartDate?.getTime() ?? 0) <= date && date <= (dateRangeEndDate?.getTime() ?? 0);
            })
            .filter((r) => !!selectedMediaPlanItems.find((mpi) => mpi.value === r.mediaPlanItemId));
    }, [
        contextState.mediaPlan?.client_commission,
        contextState.mediaPlanItems,
        dateRangeEndDate,
        dateRangeStartDate,
        reportItems,
        selectedMediaPlanItems,
    ]);

    const currency = contextState.mediaPlan?.currency;
    const currencySymbol = currency?.symbol ?? '';
    const mediaPlanItems = useMemo(() => contextState.mediaPlanItemRows ?? [], [contextState.mediaPlanItemRows]);
    const mediaPlanGroups = useMemo(() => contextState.groups ?? [], [contextState.groups]);
    const [mediaPlanItemsSelectContainer, setMediaPlanItemsSelectContainer] = useState<HTMLDivElement | null>(null);
    const mediaPlanItemsSelectContainerWidth = useMemo(
        () => mediaPlanItemsSelectContainer?.getBoundingClientRect().width,
        [mediaPlanItemsSelectContainer]
    );

    const [platformFormatMetricsSelected, setPlatformFormatMetricsSelected] = useState({});
    const onSelectedPlatformFormatMetricsChange = useCallback(
        (groupId: number, metrics: string[]) =>
            setPlatformFormatMetricsSelected((state) => ({
                ...state,
                [groupId]: metrics,
            })),
        []
    );
    const [targetingCreativeMetricsSelected, setTargetingCreativeMetricsSelected] = useState({});
    const onSelectedTargetingCreativeMetricsChange = useCallback(
        (mediaPlanItemId: number, metrics: string[]) =>
            setTargetingCreativeMetricsSelected((state) => ({
                ...state,
                [mediaPlanItemId]: metrics,
            })),
        []
    );

    const [reportLoading, setReportLoading] = useState(false);

    const channelFormatBreakdown = useMemo<ChannelFormatBreakdownRow[]>(() => {
        return dailyReportItems.map<ChannelFormatBreakdownRow>((item) => {
            const mediaPlanItem = mediaPlanItems.find((itemRow) => itemRow.id === item.mediaPlanItemId);
            return {
                ...item,
                startDate: mediaPlanItem?.start_date,
                endDate: mediaPlanItem?.end_date,
                format: mediaPlanItem?.format.name || '-',
                channel: mediaPlanItem?.channel.name || '-',
                objective: mediaPlanItem?.objective.name || '-',
            };
        });
    }, [dailyReportItems, mediaPlanItems]);

    const groupedMediaPlanItems: MediaPlanItemGroupedOption[] = useMemo(() => {
        const groupedOptions =
            mediaPlanItems.reduce((groupedOptions, item) => {
                const group = groupedOptions.find((g) => g.id === item.group?.id);
                if (!group) {
                    return groupedOptions.concat({
                        id: item.group?.id,
                        label: item.group?.name,
                        options: [{ value: item.id, label: `${item.channel.name} - ${item.format.name}` }],
                    });
                }

                return groupedOptions.map((g) => {
                    if (g.id === item.group?.id) {
                        return {
                            ...g,
                            options: g.options.concat({
                                value: item.id,
                                label: `${item.channel.name} - ${item.format.name}`,
                            }),
                        };
                    }

                    return g;
                });
            }, [] as MediaPlanItemGroupedOption[]) ?? [];

        return [...groupedOptions].sort((a, b) => {
            const aGroup = mediaPlanGroups.find((g) => g.id === a.id);
            const bGroup = mediaPlanGroups.find((g) => g.id === b.id);

            return (aGroup?.ordering_index ?? Infinity) - (bGroup?.ordering_index ?? Infinity);
        });
    }, [mediaPlanGroups, mediaPlanItems]);

    const tables: TableList[] = useMemo(() => {
        const tables: TableList[] = [];

        groupedMediaPlanItems.forEach((group) => {
            const selectedGroupMediaPlanItems = group.options.filter((o) =>
                selectedMediaPlanItems.map((selected) => selected.value).includes(o.value)
            );

            if (!selectedGroupMediaPlanItems.length) {
                return;
            }

            const selectedGroupMediaPlanItemReports = channelFormatBreakdown.filter((reportItem) =>
                selectedGroupMediaPlanItems.find((option) => option.value === reportItem.mediaPlanItemId)
            );

            if (!selectedGroupMediaPlanItemReports.length && shouldHideEmptyRows) {
                return;
            }

            let aggregatedData = aggregateReportItemsByMediaPlanItemId(selectedGroupMediaPlanItemReports).map((row) => {
                row.cost = mediaPlanItems.find((item) => item.id === row.mediaPlanItemId)?.cost;
                return row;
            });

            if (aggregatedData.length !== selectedGroupMediaPlanItems.length) {
                const groupMediaPlanItemsWithoutReportItems = mediaPlanItems.filter(
                    (m) =>
                        selectedGroupMediaPlanItems.map((o) => o.value).includes(m.id) &&
                        !aggregatedData.map((d) => d.mediaPlanItemId).includes(m.id)
                );

                aggregatedData = aggregatedData.concat(
                    groupMediaPlanItemsWithoutReportItems.map<ChannelFormatBreakdownRow>((item) => ({
                        startDate: item.start_date,
                        endDate: item.end_date,
                        channel: item.channel.name,
                        format: item.format.name,
                        objective: item.objective.name,
                        ...createReportItem({
                            mediaPlanItemId: item.id,
                        }),
                    }))
                );
            }

            tables.push({
                id: group.id,
                title: group.label,
                data: aggregatedData,
            });
        });

        return tables;
    }, [groupedMediaPlanItems, mediaPlanItems, channelFormatBreakdown, selectedMediaPlanItems, shouldHideEmptyRows]);

    const platformTableData: PlatformSummaryTableRow[] = useMemo(() => {
        const relevantPlanItems = mediaPlanItems.filter((item) =>
            selectedMediaPlanItems.map((selected) => selected.value).includes(item.id)
        );

        const totalSpend = dailyReportItems.reduce((acc, i) => acc + (i.amount_spent ?? 0), 0);

        const channels = uniq(relevantPlanItems.map((item) => item.channel.name));

        const rows = channels.map((channel) => {
            const channelPlanItems = mediaPlanItems.filter((item) => item.channel.name === channel);
            const channelReportItems = channelFormatBreakdown.filter((item) => item.channel === channel);

            const totalCost = mediaPlanItems.reduce(
                (acc, i) => acc + (typeof i.cost === 'number' ? i.cost : parseFloat(i.cost) ?? 0),
                0
            );

            const reportTotals = getTotalReportItemValues(channelReportItems);

            return {
                ...reportTotals,
                cost: totalCost,
                totalSpend,
                itemCount: channelPlanItems.length,
                channel,
            };
        }, []);

        return rows.sort((a, b) => sortNumerically(a.amount_spent, b.amount_spent));
    }, [mediaPlanItems, channelFormatBreakdown, selectedMediaPlanItems, dailyReportItems]);

    const formatNaN = useCallback((n: number | string) => (isNaN(Number(n)) ? '-' : n), []);

    const totalCost = useMemo(() => dailyReportItems.reduce((acc, i) => acc + (i.amount_spent ?? 0), 0), [
        dailyReportItems,
    ]);
    const totalImpressionsRaw = useMemo(() => dailyReportItems.reduce((acc, i) => (i.impressions ?? 0) + acc, 0), [
        dailyReportItems,
    ]);
    const totalCPM = useMemo(
        () => `${currencySymbol}${formatNaN(Metrics.cpm(totalCost, totalImpressionsRaw).toFixed(2))} CPM`,
        [currencySymbol, formatNaN, totalCost, totalImpressionsRaw]
    );

    const totalClicksRaw = useMemo(() => dailyReportItems.reduce((acc, i) => acc + (i.clicks ?? 0), 0), [
        dailyReportItems,
    ]);
    const totalCPC = useMemo(
        () => `${currencySymbol}${formatNaN(Metrics.cpc(totalCost, totalClicksRaw).toFixed(2))} CPC`,
        [currencySymbol, formatNaN, totalClicksRaw, totalCost]
    );

    const totalViewsRaw = useMemo(() => dailyReportItems.reduce((acc, i) => acc + (i.views ?? 0), 0), [
        dailyReportItems,
    ]);
    const totalCPV = useMemo(
        () => `${currencySymbol}${formatNaN(Metrics.cpv(totalCost, totalViewsRaw).toFixed(3))} CPV`,
        [currencySymbol, formatNaN, totalCost, totalViewsRaw]
    );

    const totalRevenueRaw = useMemo(() => dailyReportItems.reduce((acc, i) => acc + (i.revenue ?? 0), 0), [
        dailyReportItems,
    ]);
    const totalROAS = useMemo(
        () => `${currencySymbol}${formatNaN(Metrics.roas(totalRevenueRaw, totalCost).toFixed(2))} ROAS`,
        [currencySymbol, formatNaN, totalCost, totalRevenueRaw]
    );

    const budgetSpentInPercents = useMemo(
        () => formatNaN(((totalCost / Number(contextState.mediaPlan?.budget)) * 100).toFixed(2)),
        [contextState.mediaPlan?.budget, formatNaN, totalCost]
    );

    const downloadPDFSummary = useCallback(async () => {
        if (!contextState.mediaPlan) {
            return;
        }

        setReportLoading(true);
        try {
            const targetingCreativeMetricsToSend = pick(
                targetingCreativeMetricsSelected,
                Object.keys(targetingCreativeMetricsSelected).filter(
                    (key) => !!selectedMediaPlanItems.find((m) => m.value === Number(key))
                )
            );

            const platformFormatMetricsToSend = pick(
                platformFormatMetricsSelected,
                Object.keys(platformFormatMetricsSelected).filter((key) => !!tables.find((t) => t.id === Number(key)))
            );

            await downloadMediaPlanResults(contextState.mediaPlan.id, {
                media_plan_items: targetingCreativeMetricsToSend,
                media_plan_item_groups: platformFormatMetricsToSend,
            });
        } catch (e) {
            const errorMessage = e instanceof Error ? e.message : 'Could not download summary';
            showNotification(errorMessage, 'error');
        } finally {
            setReportLoading(false);
        }
    }, [
        contextState.mediaPlan,
        platformFormatMetricsSelected,
        selectedMediaPlanItems,
        tables,
        targetingCreativeMetricsSelected,
    ]);

    useEffect(() => {
        if (mediaPlanItems.length) {
            setSelectedMediaPlanItems(
                mediaPlanItems.map((m) => ({
                    value: m.id,
                    label: `${m.channel.name} - ${m.format.name}`,
                }))
            );
        }
    }, [mediaPlanItems]);

    useEffect(() => {
        if (isModalOpen && contextState.mediaPlan && !urlState.start && !urlState.end) {
            setUrlState({
                start: moment(contextState.mediaPlan.start_date).format('YYYY-MM-DD'),
                end: moment(contextState.mediaPlan.end_date).format('YYYY-MM-DD'),
            });
        }
    }, [contextState.mediaPlan, isModalOpen, setUrlState, urlState.end, urlState.start]);

    const filteredMediaPlanItems = mediaPlanItems.filter(
        (m) =>
            selectedMediaPlanItems.find((s) => s.value === m.id) &&
            (!shouldHideEmptyRows || !!dailyReportItems.find((r) => r.mediaPlanItemId === m.id))
    );

    const selectedMediaPlanItemGroups = mediaPlanGroups
        .filter((g) => filteredMediaPlanItems.find((m) => m.group?.id === g.id))
        .sort((a, b) => a.ordering_index - b.ordering_index);

    return (
        <ModalRight isModalOpen={isModalOpen} closeModal={closeModal}>
            <>
                <CloseIcon className={styles.closeIcon} onClick={closeModal} />
                <div className={styles.container}>
                    <div className={styles.header}>
                        <div ref={setMediaPlanItemsSelectContainer} className={styles.mediaPlanItemsSelectContainer}>
                            <Image
                                className={styles.brandImage}
                                src={contextState.mediaPlan?.release.brand.picture}
                                alt="brand"
                            />

                            <div className={styles.release}>
                                <p className={styles.releaseName}>{contextState.mediaPlan?.release.name}</p>
                                <p className={styles.brandName}>{contextState.mediaPlan?.release.brand.name}</p>
                                <p className={styles.itemsSelectedCount}>
                                    {selectedMediaPlanItems.length} media plan item
                                    {selectedMediaPlanItems.length !== 1 ? 's' : ''} selected
                                </p>
                            </div>

                            <ChevronDownIcon className={styles.mediaPlanItemsSelectDropdownIcon} />
                        </div>

                        <div className={styles.headerToolbar}>
                            <div
                                className={styles.checkboxContainer}
                                onClick={() => {
                                    setUrlState((prev) => ({ hideEmpty: prev.hideEmpty ? undefined : true }));
                                }}
                            >
                                <label>Hide Empty</label>
                                <Checkbox
                                    value={shouldHideEmptyRows}
                                    onChange={(value, e) => {
                                        e.stopPropagation();
                                        setUrlState({ hideEmpty: value || undefined });
                                    }}
                                />
                            </div>

                            <div className={styles.dateRangeContainer}>
                                <div className={styles.dateField}>
                                    <label>From</label>
                                    <CustomDatePicker
                                        selectsStart
                                        className={styles.dateInput}
                                        value={dateRangeStartDate}
                                        onChange={setDateRangeStartDate}
                                        startDate={dateRangeStartDate}
                                        endDate={dateRangeEndDate}
                                    />
                                </div>

                                <div className={styles.dateField}>
                                    <label>To</label>
                                    <CustomDatePicker
                                        selectsEnd
                                        className={styles.dateInput}
                                        value={dateRangeEndDate}
                                        onChange={setDateRangeEndDate}
                                        startDate={dateRangeStartDate}
                                        endDate={dateRangeEndDate}
                                        minDate={dateRangeStartDate}
                                    />
                                </div>
                            </div>

                            <DropdownButton
                                label="Export"
                                type="bordered"
                                Icon={() => <DownloadDocumentIcon className={styles.exportIcon} />}
                                className={styles.exportButton}
                            >
                                <div className={styles.dropdownList}>
                                    <Button onClick={downloadPDFSummary} loading={reportLoading}>
                                        PDF - summary
                                    </Button>
                                </div>
                            </DropdownButton>
                        </div>
                    </div>

                    <Popover anchorElement={mediaPlanItemsSelectContainer} showOn="click">
                        <div
                            style={{ width: mediaPlanItemsSelectContainerWidth }}
                            className={styles.mediaPlanItemsSelectDropdown}
                        >
                            {groupedMediaPlanItems.map((group) => {
                                return (
                                    <MediaPlanItemGroup
                                        key={group.id}
                                        group={group}
                                        selectedItems={selectedMediaPlanItems}
                                        setSelectedItems={setSelectedMediaPlanItems}
                                    >
                                        {group.options.map((option) => {
                                            const selected = Boolean(
                                                selectedMediaPlanItems.find((i) => i.value === option.value)
                                            );
                                            return (
                                                <MediaPlanItemOption
                                                    key={option.value}
                                                    option={option}
                                                    selected={selected}
                                                    onSelectedChange={() => {
                                                        setSelectedMediaPlanItems((items) => {
                                                            if (selected) {
                                                                return items.filter(
                                                                    (item) => item.value !== option.value
                                                                );
                                                            }

                                                            return items.concat(option);
                                                        });
                                                    }}
                                                />
                                            );
                                        })}
                                    </MediaPlanItemGroup>
                                );
                            })}
                        </div>
                    </Popover>

                    <h3 className={styles.campaignTotalsHeading}>Campaign totals</h3>
                    <div className={styles.totalsContainer}>
                        <HeadlineStatsCard
                            title="Impressions"
                            value={formatNumberToKNotation(totalImpressionsRaw)}
                            rawValue={totalImpressionsRaw}
                            subtitle={totalCPM}
                        />

                        <HeadlineStatsCard
                            title="Clicks"
                            value={formatNumberToKNotation(totalClicksRaw)}
                            rawValue={totalClicksRaw}
                            subtitle={totalCPC}
                        />

                        <HeadlineStatsCard
                            title="Views"
                            value={formatNumberToKNotation(totalViewsRaw)}
                            rawValue={totalViewsRaw}
                            subtitle={totalCPV}
                        />

                        <HeadlineStatsCard
                            title="Revenue"
                            value={`${currencySymbol}${formatNumberToKNotation(totalRevenueRaw)}`}
                            rawValue={totalRevenueRaw}
                            subtitle={totalROAS}
                        />

                        <HeadlineStatsCard
                            title="Spend"
                            value={`${currencySymbol}${formatNumberToKNotation(formatTo2dp(totalCost))}`}
                            rawValue={totalCost}
                            subtitle={`${budgetSpentInPercents}% of total budget`}
                        />
                    </div>

                    <MediaPlanResultsChart reportItems={dailyReportItems} />

                    <Tabs
                        className={styles.tabs}
                        headerClassName={styles.tabsHeader}
                        activeTabIndicatorClassName={styles.activeTabIndicator}
                        panels={[
                            {
                                name: 'platform-format-breakdown',
                                title: 'Platform/Format Breakdown',
                                render: () => (
                                    <>
                                        {!!platformTableData.length && (
                                            <PlatformSummaryTable data={platformTableData} currency={currency} />
                                        )}

                                        {tables.map((table) => (
                                            <PlatformFormatBreakdown
                                                table={table}
                                                currency={currency}
                                                onSelectedMetricsChange={onSelectedPlatformFormatMetricsChange}
                                            />
                                        ))}
                                    </>
                                ),
                            },
                            {
                                name: 'targeting-breakdown',
                                title: 'Targeting Breakdown',
                                render: () => (
                                    <>
                                        {selectedMediaPlanItemGroups.map((group) => {
                                            const relevantItems = filteredMediaPlanItems.filter(
                                                (i) => i.group?.id === group.id
                                            );

                                            return (
                                                <article className={styles.breakdownGroup}>
                                                    <h2>{group.name}</h2>
                                                    <div className={styles.groupBarsContainer}>
                                                        {relevantItems.map((m) => (
                                                            <TargetingBreakdown
                                                                key={m.id}
                                                                mediaPlanItem={m}
                                                                onSelectedMetricsChange={
                                                                    onSelectedTargetingCreativeMetricsChange
                                                                }
                                                                reportItems={dailyReportItems}
                                                            />
                                                        ))}
                                                    </div>
                                                </article>
                                            );
                                        })}
                                    </>
                                ),
                            },
                            {
                                name: 'creative-breakdown',
                                title: 'Creative Breakdown',
                                render: () => (
                                    <>
                                        {selectedMediaPlanItemGroups.map((group) => {
                                            const relevantItems = filteredMediaPlanItems.filter(
                                                (i) => i.group?.id === group.id
                                            );

                                            return (
                                                <article className={styles.breakdownGroup}>
                                                    <h2>{group.name}</h2>
                                                    <div className={styles.groupBarsContainer}>
                                                        {relevantItems.map((m) => (
                                                            <CreativeBreakdown
                                                                key={m.id}
                                                                mediaPlanItem={m}
                                                                reportItems={dailyReportItems.filter(
                                                                    (r) => r.mediaPlanItemId === m.id
                                                                )}
                                                            />
                                                        ))}
                                                    </div>
                                                </article>
                                            );
                                        })}
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
            </>
        </ModalRight>
    );
};

export default MediaPlanResultsModal;
