import { getVideos, postVideo, putVideo, deleteVideo, putVideoToS3 } from './AssetsLibraryModal.api';
import { AssetsLibraryPlatform, Video } from './AssetsLibraryModal.types';

export const fetchVideos = async (platform: AssetsLibraryPlatform, planId: number): Promise<Video[]> => {
    const { results } = await getVideos(platform, planId);
    return results;
};

export const uploadVideo = async (platform: AssetsLibraryPlatform, planId: number, file: File): Promise<Video> => {
    const video = await postVideo(platform, planId, file);
    await putVideoToS3(video.put_file_url, file);

    return video;
};

export const changeVideoName = async (platform: AssetsLibraryPlatform, video: Video, name: string): Promise<Video> => {
    return await putVideo(platform, { id: video.id, plan: video.plan, name });
};

export const acceptVideo = async (platform: AssetsLibraryPlatform, video: Video): Promise<Video> => {
    return await putVideo(platform, { id: video.id, plan: video.plan, is_approved: true });
};

export const declineVideo = async (platform: AssetsLibraryPlatform, video: Video): Promise<Video> => {
    return await putVideo(platform, { id: video.id, plan: video.plan, is_approved: false });
};

export const changeVideoNotes = async (
    platform: AssetsLibraryPlatform,
    video: Video,
    notes: string
): Promise<Video> => {
    return await putVideo(platform, { id: video.id, plan: video.plan, notes });
};

export const removeVideo = async (platform: AssetsLibraryPlatform, id: number): Promise<void> => {
    await deleteVideo(platform, id);
};
