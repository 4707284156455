import React, { useCallback, useMemo, useState } from 'react';
import { Release, usePaginatedFetch, getReleases } from '@round/api';
import styles from './ReleaseSelect.module.css';
import Skeleton from '../../../../../ui/Skeleton/Skeleton';
import { Popover } from '@round/ui-kit';
import SearchInput from '../../../../../ui/SearchInput/SearchInput';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import Image from '../../../../../ui/Image/Image';
import List from '../../../../../ui/DataDisplay/List/List';
import { ReactComponent as ArrowIcon } from '../../../../../assets/ArrowIcon.svg';
import cn from 'classnames';

type ReleaseSelectProps = {
    release: Release | null;
    releaseLoading: boolean;
    onReleaseChange: (release: Release) => void;
};

const PAGE_SIZE = 20;
const ReleaseSelect = ({ release, releaseLoading, onReleaseChange }: ReleaseSelectProps) => {
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const {
        data: releases,
        getCurrentPage: getCurrentReleasePage,
        hasNextPage: hasNextReleasePage,
        loading: releasesLoading,
        count: releasesCount,
    } = usePaginatedFetch(getReleases, {
        page: page,
        page_size: PAGE_SIZE,
        search: search,
    });

    const fetchInfluencerUsers = useMemo(() => getCurrentReleasePage, [getCurrentReleasePage]);
    useAbortableEffect(
        (signal) => {
            fetchInfluencerUsers({ signal });
        },
        [fetchInfluencerUsers]
    );

    const [releaseRef, setReleaseRef] = useState<HTMLDivElement | null>(null);
    const releaseDropdownWidth = useMemo(() => releaseRef?.getBoundingClientRect().width, [releaseRef]);
    const releasesToDisplay = releases.filter((r) => r.id !== release?.id);
    const rowCount = useMemo(
        () => (release && releases.map((r) => r.id).includes(release.id) ? releasesCount - 1 : releasesCount),
        [release, releases, releasesCount]
    );

    const loadMoreRows = useCallback(async () => {
        if (releasesLoading || !hasNextReleasePage) {
            return;
        }
        setPage((prev) => prev + 1);
    }, [releasesLoading, hasNextReleasePage]);

    return (
        <>
            <div ref={setReleaseRef} className={styles.release}>
                {releaseLoading ? (
                    <Skeleton className={styles.brandImage} enableAnimation={false} />
                ) : (
                    <Image className={styles.brandImage} src={release?.brand.picture} alt="Brand" />
                )}

                <div className={styles.releaseDescription}>
                    <p className={styles.releaseName}>
                        {releaseLoading ? <Skeleton width="75%" enableAnimation={false} /> : release?.name}
                    </p>
                    <p className={styles.brandName}>
                        {releaseLoading ? (
                            <Skeleton width="50%" enableAnimation={false} />
                        ) : (
                            `${release?.brand.name} | ${release?.brand.client.name}`
                        )}
                    </p>
                </div>

                <ArrowIcon
                    className={cn(styles.releaseDropdownIcon, { [styles.releaseDropdownOpen]: isDropdownOpen })}
                />
            </div>

            <Popover anchorElement={releaseRef} showOn="click" onShowPopoverChange={setIsDropdownOpen}>
                {(setShow) => (
                    <div style={{ width: releaseDropdownWidth }} className={styles.releaseDropdown}>
                        <SearchInput
                            className={styles.searchInput}
                            value={search}
                            onChange={(search) => {
                                setSearch(search);
                                setPage(1);
                            }}
                        />
                        <div className={styles.releasesList} style={{ height: releasesToDisplay.length * 70 }}>
                            <List
                                loading={releasesLoading}
                                data={releasesToDisplay}
                                rowCount={rowCount}
                                loadMoreRows={loadMoreRows}
                            >
                                {({ item: release, style, loading }) => {
                                    if (!release && !loading) {
                                        return null;
                                    }

                                    return (
                                        <div
                                            className={styles.releasesListItem}
                                            onClick={() => {
                                                if (!release) {
                                                    return;
                                                }

                                                onReleaseChange(release);
                                                setShow(false);
                                            }}
                                            style={style}
                                        >
                                            <Image
                                                loading={loading}
                                                className={styles.releaseItemImage}
                                                src={release?.brand.picture}
                                                alt="brand"
                                            />
                                            <div className={styles.releaseItemContent}>
                                                <p className={styles.label}>{loading ? <Skeleton /> : release?.name}</p>
                                                <p className={styles.subtitle}>
                                                    {loading ? (
                                                        <Skeleton />
                                                    ) : (
                                                        `${release?.brand.name} - ${release?.brand.client.name}`
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                }}
                            </List>
                        </div>
                    </div>
                )}
            </Popover>
        </>
    );
};

export default ReleaseSelect;
