import { microwave } from '@round/api';
import { buildInstagramUserUrl, formatDate, numberWithCommas, roundTo2Dp } from 'helpers';
import Table from 'ui/NewTable/Table';
import { CellProps, Column } from 'react-table';
import styles from './MicroCreatorsPostsTable.module.css';
import { Skeleton } from '@round/ui-kit';
import { getTotalValue } from '../helpers';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';
import { filterByVisibility } from 'utility/utility';

type Props = {
    data: microwave.InstagramCampaignPost[];
    isLoading: boolean;
    noDataLabel: string;
};

const getEngagementRate = (engagements: number, views: number) => {
    if (!views) {
        return 0;
    }

    return roundTo2Dp((engagements / views) * 100);
};

const InstagramMicroCampaignPostsTable = ({ data, isLoading, noDataLabel }: Props) => {
    const isStaff = useCheckUserGroupsAccess(['influencer_editor']);

    const columns: Column<microwave.InstagramCampaignPost>[] = filterByVisibility([
        {
            accessor: 'author_username',
            Header: 'Account',
            Cell: ({ value }) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                if (!value?.length) {
                    return <>-</>;
                }

                return (
                    <a rel="noopener noreferrer" target="_blank" href={buildInstagramUserUrl(value ?? '')}>
                        @{value}
                    </a>
                );
            },
        },
        {
            accessor: 'post_url',
            Header: 'Post',
            Cell: ({ value }) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                if (!value.length) {
                    return <>-</>;
                }

                return (
                    <a href={value} target="_blank" rel="noopener noreferrer">
                        {value}
                    </a>
                );
            },
        },
        {
            accessor: 'taken_at',
            Header: 'Post date',
            isVisible: !!isStaff,
            Cell: ({ value }) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                if (!value) {
                    return <>-</>;
                }

                return <>{formatDate(value)}</>;
            },
        },
        {
            accessor: 'reel_views',
            Header: 'Reel views',
            Cell: ({ value }) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                return <>{value ? numberWithCommas(value) : '-'}</>;
            },
        },
        {
            accessor: 'feed_views',
            Header: 'Feed views',
            Cell: ({ value }) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                return <>{value ? numberWithCommas(value) : '-'}</>;
            },
        },
        {
            id: 'engagementRate',
            Header: 'Engagement rate',
            Cell: ({ row: { original } }: CellProps<microwave.InstagramCampaignPost>) => {
                const { reel_views, comments, likes } = original;
                return <>{getEngagementRate((comments ?? 0) + (likes ?? 0), reel_views ?? 0)}%</>;
            },
        },
        {
            accessor: 'comments',
            Header: 'Comments',
            Cell: ({ value }) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                return <>{value ? numberWithCommas(value) : '-'}</>;
            },
        },
        {
            accessor: 'likes',
            Header: 'Likes',
            Cell: ({ value }) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                return <>{value ? numberWithCommas(value) : '-'}</>;
            },
        },
    ]);

    return (
        <Table
            loading={isLoading}
            noDataLabel={noDataLabel}
            disableSortBy
            tableClassName={styles.table}
            columns={columns}
            data={data}
            renderFooter={() => {
                const reelsViewsTotal = getTotalValue(data, 'reel_views');
                const feedViewsTotal = getTotalValue(data, 'feed_views');
                const commentsTotal = getTotalValue(data, 'comments');
                const likesTotal = getTotalValue(data, 'likes');

                return (
                    <tfoot>
                        <tr>
                            <td colSpan={isStaff ? 3 : 2} />
                            <td>{typeof reelsViewsTotal === 'number' ? numberWithCommas(reelsViewsTotal) : '-'}</td>
                            <td>{typeof feedViewsTotal === 'number' ? numberWithCommas(feedViewsTotal) : '-'}</td>
                            <td>
                                {getEngagementRate((commentsTotal ?? 0) + (likesTotal ?? 0), reelsViewsTotal ?? 0)}%
                            </td>
                            <td>{typeof commentsTotal === 'number' ? numberWithCommas(commentsTotal) : '-'}</td>
                            <td>{typeof likesTotal === 'number' ? numberWithCommas(likesTotal) : '-'}</td>
                        </tr>
                    </tfoot>
                );
            }}
        />
    );
};

export default InstagramMicroCampaignPostsTable;
