import { forwardRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import styles from './Popover.module.css';
import cn from 'classnames';

export type Options = Parameters<typeof usePopper>[2];

export type ControlledPopoverProps = {
    anchorElement: HTMLElement | SVGSVGElement | null;
    children: React.ReactNode;
    options?: Options;
    style?: React.CSSProperties;
    isOpen: boolean;
    overlayClassName?: string;
};

const ControlledPopover = forwardRef<HTMLDivElement, ControlledPopoverProps>(
    ({ isOpen, children, anchorElement, options, style, overlayClassName }, ref) => {
        const [popoverContainer, setPopoverContainer] = useState<HTMLDivElement | null>(null);
        const { attributes, styles: popperStyles } = usePopper(anchorElement, popoverContainer, options);

        return (
            <>
                {isOpen &&
                    createPortal(
                        <div className={cn(styles.overlay, overlayClassName)} onClick={(e) => e.stopPropagation()}>
                            <div
                                ref={(instance) => {
                                    setPopoverContainer(instance);
                                    if (typeof ref === 'function') {
                                        ref(instance);
                                    }
                                }}
                                className={styles.popover}
                                style={{ ...popperStyles.popper, ...style }}
                                {...attributes.popper}
                            >
                                {children}
                            </div>
                        </div>,
                        document.body
                    )}
            </>
        );
    }
);

export default ControlledPopover;
