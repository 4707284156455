import { microwave } from '@round/api';
import React, { ReactNode } from 'react';
import styles from '../TiktokMicrosStatsBar/TiktokMicrosStatsBar.module.css';
import cn from 'classnames';
import { ReactComponent as ArrowIcon } from 'assets/ArrowIcon.svg';
import { getPostCountDisplayValue } from 'Modules/Advertising/InfluencerPlan/features/MicrosStatsBar/helpers';
import { asMoney, formatNumberToKNotation, roundTo2Dp } from 'helpers';
import ProgressBar from 'ui/ProgressBar/ProgressBar';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { ProtectedByUserGroups } from 'SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';

type Props = {
    campaign: microwave.Campaign | undefined;
    stats: microwave.InstagramCampaignStats | null;
    isExpanded: boolean;
    onExpandedChange: (isExpanded: boolean) => void;
    children?: ReactNode | undefined;
    onAddPost: () => void;
};

const InstagramMicrosStatsBar = ({ campaign, stats, isExpanded, onExpandedChange, children, onAddPost }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const campaignCurrency = currencies.find((c) => c.id === campaign?.currency_id);

    const postCountDisplayValue = getPostCountDisplayValue(stats?.post_count ?? 0, campaign?.target_post_count);
    const budgetDisplayValue = asMoney(campaign?.budget, campaignCurrency);

    const amountSpentPercent =
        typeof campaign?.budget === 'number' ? ((stats?.amount_spent ?? 0) / campaign?.budget) * 100 : 0;
    const amountSpentPercentDisplayValue = `${amountSpentPercent.toFixed(2)}%`;

    const totalViewsDisplayValue =
        typeof stats?.total_views === 'number' ? formatNumberToKNotation(stats.total_views) : '-';

    const engagementRateDisplayValue =
        typeof stats?.engagement_rate === 'number' ? `${roundTo2Dp(stats?.engagement_rate * 100)}%` : '-';

    const cpmDisplayValue = typeof stats?.cpm === 'number' ? asMoney(stats.cpm, campaignCurrency) : '-';

    return (
        <>
            <section className={styles.container}>
                <button
                    className={cn(styles.expandButton, { [styles.expanded]: isExpanded })}
                    onClick={() => onExpandedChange(!isExpanded)}
                >
                    <ArrowIcon />
                </button>

                <div className={styles.statsContainer}>
                    <div className={cn(styles.statColumn, styles.name)}>
                        <div className={styles.statHeading}>{campaign?.name ?? 'Micros'}</div>
                        <div className={styles.statValue}>{postCountDisplayValue}</div>
                    </div>
                    <div className={cn(styles.statColumn, styles.costStats)}>
                        <div className={styles.budgetRow}>
                            <div className={cn(styles.statHeading, styles.budget)}>{budgetDisplayValue}</div>
                            <div className={styles.statValue}>{amountSpentPercentDisplayValue}</div>
                        </div>

                        <ProgressBar
                            trackStyles={{
                                width: '100%',
                                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                                height: '0.33rem',
                                marginTop: '0.33rem',
                            }}
                            barStyles={{
                                backgroundColor: 'white',
                            }}
                            progress={amountSpentPercent}
                        />
                    </div>

                    <div className={styles.statColumn}>
                        <div className={styles.statHeading}>Total Views</div>
                        <div className={styles.statValue}>{totalViewsDisplayValue}</div>
                    </div>

                    <div className={styles.statColumn}>
                        <div className={styles.statHeading}>CPM</div>
                        <div className={styles.statValue}>{cpmDisplayValue}</div>
                    </div>

                    <div className={cn(styles.statColumn, styles.engagementRate)}>
                        <div className={styles.statHeading}>Engagement Rate</div>
                        <div className={styles.statValue}>{engagementRateDisplayValue}</div>
                    </div>
                </div>

                <div className={styles.actionsContainer}>
                    <ProtectedByUserGroups groups={['microwave_admin']}>
                        <button className={styles.actionsContainerButton} onClick={onAddPost}>
                            Add Post
                        </button>
                    </ProtectedByUserGroups>
                </div>
            </section>
            {isExpanded && children}
        </>
    );
};

export default InstagramMicrosStatsBar;
