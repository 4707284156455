import moment from 'moment';
import { ReactComponent as CalendarIcon } from 'assets/icons/Calendar.svg';
import { ReactComponent as DangerIcon } from 'assets/icons/Warning.svg';
import styles from './DateWarning.module.css';
import cn from 'classnames';
import { useState } from 'react';
import { Popover } from '@round/ui-kit';

type Props = {
    value: Date | string;
    valueFormat?: string;
    warnOn?: boolean;
    dangerOn?: boolean;
    tooltipHint?: string;
};

const DateWarning = ({ value, valueFormat, warnOn, dangerOn, tooltipHint }: Props) => {
    const [ref, setRef] = useState<SVGSVGElement | null>(null);

    const defaultFormat = 'MMM DD';
    const formattedDate = moment(value).format(valueFormat || defaultFormat);

    return (
        <div className={cn(styles.container, { [styles.warning]: warnOn, [styles.danger]: dangerOn })}>
            <span>{formattedDate}</span>
            {warnOn ? (
                <CalendarIcon ref={setRef} className={cn(styles.calendar, { [styles.hasTooltip]: !!tooltipHint })} />
            ) : dangerOn ? (
                <DangerIcon ref={setRef} className={cn(styles.danger, { [styles.hasTooltip]: !!tooltipHint })} />
            ) : null}

            {tooltipHint && (
                <Popover anchorElement={ref} showOn="hover">
                    <div className={styles.tooltip}>{tooltipHint}</div>
                </Popover>
            )}
        </div>
    );
};

export default DateWarning;
