import { IconProps } from '../../../../../SharedComponents/svg/Icons/Icon.types';

const StatusInfoIcon = ({ width, height, className, onClick, title }: IconProps) => {
    return (
        <svg
            role="img"
            aria-label={title}
            className={className}
            onClick={onClick}
            width={width}
            height={height}
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.8139 1H1.47182C1.21123 1 1 1.20986 1 1.46875V10.75C1 11.0089 1.21123 11.2188 1.47182 11.2188H4.15451L5.80923 12.8627C5.99348 13.0458 6.29221 13.0458 6.47648 12.8627L8.13121 11.2188H10.8139C11.0745 11.2188 11.2857 11.0089 11.2857 10.75V1.46875C11.2857 1.20986 11.0745 1 10.8139 1ZM10.3421 10.2813H7.93578C7.81065 10.2813 7.69062 10.3306 7.60215 10.4185L6.14286 11.8683L4.68356 10.4185C4.59509 10.3306 4.47506 10.2813 4.34993 10.2813H1.94364V1.9375H10.3421V10.2813ZM6.6077 5.67126V7.92684C6.6077 8.06894 6.39913 8.21102 6.14288 8.21102C5.87472 8.21102 5.68403 8.06894 5.68403 7.92684V5.67126C5.68403 5.50549 5.87472 5.39301 6.14288 5.39301C6.39913 5.39301 6.6077 5.50549 6.6077 5.67126ZM5.65426 4.42209C5.65426 4.19711 5.86878 4.00176 6.14288 4.00176C6.41104 4.00176 6.62555 4.19711 6.62555 4.42209C6.62555 4.64706 6.41104 4.84834 6.14288 4.84834C5.86878 4.84834 5.65426 4.64706 5.65426 4.42209Z"
                fill="#90A0B7"
                stroke="#90A0B7"
                strokeWidth="0.5"
            />
        </svg>
    );
};

export default StatusInfoIcon;
