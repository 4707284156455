import { MediaPlanItem } from '../../../../App.types';
import { MediaPlanItemData } from '../types/MediaPlanItem.types';

export function mapMediaPlanItemToApi(item: MediaPlanItem): MediaPlanItemData {
    return {
        alerts_enabled: item.alerts_enabled,
        buy_method_id: item.buy_method.id,
        buy_platform_id: item.buy_platform.id,
        channel_id: item.channel.id,
        cost: item.cost,
        end_date: item.end_date,
        estimated_cost: item.estimated_cost,
        format_id: item.format.id,
        group_id: item.group?.id ?? null,
        media_plan: item.media_plan,
        objective_id: item.objective.id,
        optimisation_metric_id: item.optimisation_metric.id,
        start_date: item.start_date,
        xero_invoice_id: item.xero_invoice_id,
    };
}

export function mapPartialMediaPlanItemToApi(item: Partial<MediaPlanItem>): Partial<MediaPlanItemData> {
    return {
        alerts_enabled: item.alerts_enabled,
        buy_method_id: item.buy_method?.id,
        buy_platform_id: item.buy_platform?.id,
        channel_id: item.channel?.id,
        cost: item.cost,
        end_date: item.end_date,
        estimated_cost: item.estimated_cost,
        format_id: item.format?.id,
        group_id: typeof item.group === 'undefined' ? undefined : item.group?.id ?? null,
        media_plan: item.media_plan,
        objective_id: item.objective?.id,
        optimisation_metric_id: item.optimisation_metric?.id,
        start_date: item.start_date,
        xero_invoice_id: item.xero_invoice_id,
    };
}
