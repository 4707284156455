import React from 'react';
import { TiktokInfluencerPlanAudio } from '../../../../../../App.types';
import BadgeDropdown from '../../../components/BadgeDropdown/BadgeDropdown';
import { ReactComponent as MusicNoteIcon } from '../../../../../../assets/MusicNote.svg';
import styles from './Audios.module.css';
import Button from '../../../../../../ui/Buttons/Button/Button';
import { ReactComponent as TrashIcon } from '../../../../../../assets/TrashIcon.svg';

type AudiosProps = {
    loading?: boolean;
    audios: TiktokInfluencerPlanAudio[];
    readonly?: boolean;
    onAddAudio?: () => void;
    onAudioClick?: (audio: TiktokInfluencerPlanAudio) => void;
    onAudioDelete?: (audio: TiktokInfluencerPlanAudio) => void;
    disabled?: boolean;
};

const Audios = ({ audios, onAudioClick, onAudioDelete, onAddAudio, readonly, loading, disabled }: AudiosProps) => {
    const handleAddAudio = () => {
        if (typeof onAddAudio === 'function') {
            onAddAudio();
        }
    };

    const handleItemClick = (audio: TiktokInfluencerPlanAudio) => {
        if (typeof onAudioClick === 'function') {
            onAudioClick(audio);
        }
    };

    const handleDeleteClick = (audio: TiktokInfluencerPlanAudio) => {
        if (typeof onAudioDelete === 'function') {
            onAudioDelete(audio);
        }
    };

    return (
        <BadgeDropdown disabled={disabled} loading={loading} value={audios.length} label="Audios" Icon={MusicNoteIcon}>
            {(setShow) => (
                <>
                    {!readonly && (
                        <Button
                            className={styles.addAudioButton}
                            type="link"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleAddAudio();
                                setShow(false);
                            }}
                        >
                            + Add audio
                        </Button>
                    )}
                    <ul className={styles.list}>
                        {audios.map((audio) => (
                            <li
                                className={styles.listItem}
                                onClick={() => {
                                    handleItemClick(audio);
                                    setShow(false);
                                }}
                            >
                                <span className={styles.audio}>{audio.title}</span>
                                {!readonly && (
                                    <Button
                                        className={styles.deleteButton}
                                        color="negative"
                                        type="link"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setShow(false);
                                            handleDeleteClick(audio);
                                        }}
                                    >
                                        <TrashIcon className={styles.deleteIcon} />
                                    </Button>
                                )}
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </BadgeDropdown>
    );
};

export default Audios;
