import { PostProjectData } from '@round/api/dist/esm/types/src/creatorbase';
import { ApiResponseError } from '@round/api';
import * as yup from 'yup';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import { FormikErrors } from 'formik';
import { ProjectFormValues } from './ProjectForm';

export const projectFormValidationSchema = yup.object().shape({
    name: yup.string().required(),
    brand: yup.object().required('Artist is required').nullable(),
});

export const convertPostProjectFormApiErrorsToFormikErrors = (
    errorObject: ApiResponseError<PostProjectData>
): FormikErrors<ProjectFormValues> => {
    const errors = {
        ...errorObject,
        brand: errorObject.brand_id,
        song: errorObject.song_id,
    };

    return mapApiErrorsToFormikErrors(errors);
};
