import { microwave } from '@round/api';
import { ReactComponent as CalendarIcon } from 'assets/icons/Calendar.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';
import { ReactComponent as MinusCircle } from 'assets/icons/MinusCircle.svg';
import styles from './Status.module.css';
import cn from 'classnames';

type Props = {
    status: microwave.CampaignStatus;
};

const icons: Record<microwave.CampaignStatus, typeof CalendarIcon> = {
    Live: MinusCircle,
    Completed: CheckIcon,
    Planned: CalendarIcon,
};

const Status = ({ status }: Props) => {
    const Icon = icons[status];

    return (
        <div className={styles.status}>
            <Icon className={cn(styles.icon, styles[status.toLowerCase()])} />
            <span className={cn(styles.label, styles[status.toLowerCase()])}>{status}</span>
        </div>
    );
};

export default Status;
