import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Artist } from './artist.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';

export const models = {
    artist: Model as ModelDefinition<Artist>,
};

export const factories = {
    artist: createFactory<Artist>({
        name(index: number) {
            return `Artist name: ${index}`;
        },
        genres() {
            return null;
        },
        image() {
            return null;
        },
        spotify_artist() {
            return null;
        },
    }),
};

export const serializers = {
    artist: makeSerializer<Artist>([]),
};

export function handleArtistRequests(server: AppServer) {
    const getArtistsListPath = '/api/music/viewsets/artist/';
    server.get(getArtistsListPath, function (this: RouteHandlerContext, schema, request) {
        const { search = '', id = '' } = request.queryParams;
        const artistIds = id
            .split(',')
            .filter((item) => item.length > 0)
            .map((id) => Number(id));
        const artists = schema
            .all('artist')
            .filter((a) => !search || a.name.toLowerCase().includes(search.toLowerCase()))
            .filter((a) => !artistIds.length || artistIds.includes(Number(a.id)));

        return buildPaginatedResponse(artists, {
            url: getArtistsListPath,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'artist'),
        });
    });

    server.get('/api/music/viewsets/artist/:id/', function (this: RouteHandlerContext, schema, request) {
        const { id } = request.params;
        if (!id) {
            return new Response(400);
        }

        const artist = schema.find('artist', id);
        if (!artist) {
            return new Response(404, {}, { detail: 'Not found' });
        }

        return artist;
    });

    server.post('/api/music/viewsets/artist/', function (this: RouteHandlerContext, schema, request) {
        const body = JSON.parse(request.requestBody);
        const artist = schema.create('artist', {
            name: body.name,
            spotify_artist: body.spotify_artist,
            genres: body.genres ?? [],
            related_artists: body.related_artists ?? [],
        });

        return schema.find('artist', artist.id);
    });

    server.patch('/api/music/viewsets/artist/:id/', function (this: RouteHandlerContext, schema, request) {
        const { id } = request.params;
        const body = JSON.parse(request.requestBody);
        const artist = schema.find('artist', id);
        if (!artist) {
            return new Response(404);
        }

        const { spotify_artist, name, related_artists } = body;
        if (name) {
            artist.update({ name: body.name });
        }

        if (related_artists) {
            artist.update({ related_artists });
        }

        if (!spotify_artist) {
            return artist;
        }

        const spotifyArtist = schema.find('spotifyArtist', spotify_artist);
        if (!spotifyArtist) {
            return new Response(400);
        }

        artist.update({ spotify_artist: Number(spotifyArtist.id) });
        return artist;
    });

    server.post('/api/music/viewsets/artist/create-from-spotify/', function (this: RouteHandlerContext, schema) {
        return schema.create('artist');
    });

    server.post(
        '/api/music/viewsets/artist/:artistId/refresh-from-spotify/',
        function (this: RouteHandlerContext, schema, request) {
            const artist = schema.find('artist', request.params.artistId);
            if (!artist) {
                return new Response(404, {}, { detail: 'Not found.' });
            }

            const { spotify_identifier = '' } = JSON.parse(request.requestBody);
            if (spotify_identifier && typeof artist.spotify_artist === 'number') {
                return new Response(
                    400,
                    {},
                    {
                        error: 'This artist has already been associated with another artist.',
                    }
                );
            }

            if (!spotify_identifier && typeof artist.spotify_artist !== 'number') {
                return new Response(400, {}, { error: 'Likely caused by no spotify URL provided.' });
            }

            return artist;
        }
    );
}
