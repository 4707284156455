import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    OrderingValues,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { InvoiceRequest } from './invoiceRequest.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';

export type GetInvoiceRequestsSortingKey = keyof Pick<InvoiceRequest, 'modified'>;

export type GetInvoiceRequestsParams = PaginatedRequest &
    Partial<{
        client_id: string;
        release_id: number;
        planner_id: string;
        status: string;
        plan_start_date_start: string;
        plan_start_date_end: string;
        with_notes: boolean;
        ordering: OrderingValues<GetInvoiceRequestsSortingKey>;
    }>;

type GetInvoiceRequestsResponse = ApiResponse<PaginatedApiResponseData<InvoiceRequest>, 200> | NotFoundResponse;

export async function getInvoiceRequests(
    params: GetInvoiceRequestsParams,
    requestInit?: RequestInit
): Promise<GetInvoiceRequestsResponse> {
    const response = await fetchWithToken(
        `/api/advertising/invoice-requests/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get invoice requests ${encodeUrlSearchParams(params)}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PatchInvoiceRequestData = Partial<
    Pick<InvoiceRequest, 'contact' | 'po_number' | 'notes' | 'status' | 'invoice_id'>
>;

type PatchInvoicerRequestResponse =
    | ApiResponse<InvoiceRequest, 200>
    | NotFoundResponse
    | ApiResponse<ApiResponseError<PatchInvoiceRequestData>, 400>;

export async function patchInvoiceRequest(
    id: number,
    data: PatchInvoiceRequestData
): Promise<PatchInvoicerRequestResponse> {
    const response = await fetchWithToken(`/api/advertising/invoice-requests/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not patch invoice request ${id}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
