import { TiktokVideo } from './video.types';
import { call, encodeUrlSearchParams, fetchAll } from '../../helpers';
import {
    ApiResponse,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { isPublicReportRequestInit } from '../../influencer';

type GetTiktokVideosParams = Partial<
    PaginatedRequest & {
        id: string;
    }
>;

type GetTiktokVideosResponse =
    | ApiResponse<PaginatedApiResponseData<TiktokVideo>, 200>
    | NotFoundResponse
    | ForbiddenResponse;

export async function getTiktokVideos(
    params: GetTiktokVideosParams,
    requestInit?: RequestInit
): Promise<GetTiktokVideosResponse> {
    const shouldBypassTokens = requestInit && isPublicReportRequestInit(requestInit);

    const response = await call(`/api/tiktok/viewsets/video/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens,
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get tiktok videos');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getAllTiktokVideos(
    params: GetTiktokVideosParams,
    requestInit?: RequestInit
): Promise<TiktokVideo[]> {
    return fetchAll(`/api/tiktok/viewsets/video/${encodeUrlSearchParams(params)}`, requestInit);
}
