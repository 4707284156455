import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { SongCollection, SongCollectionStats, SongCollectionTiktokAudioStats } from './songCollection.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { SongTiktokStats } from '../song';

export const models = {
    songCollection: Model as ModelDefinition<SongCollection>,
    songCollectionTiktokAudioStat: Model as ModelDefinition<SongCollectionTiktokAudioStats>,
    songCollectionStat: Model as ModelDefinition<SongCollectionStats>,
};

export const serializers = {
    songCollection: makeSerializer<SongCollection>([]),
    songCollectionTiktokAudioStat: makeSerializer<SongCollectionTiktokAudioStats>([]),
    songCollectionStat: makeSerializer<SongCollectionStats>([]),
};

export const factories = {
    songCollection: createFactory<SongCollection>({
        client(index: number) {
            return index;
        },
        name(index: number) {
            return `song collection name ${index}`;
        },
        picture() {
            return null;
        },
    }),
    songCollectionTiktokAudioStat: createFactory<SongCollectionTiktokAudioStats>({
        audio_id(index: number) {
            return `audio id ${index}`;
        },
        tiktok_id(index: number) {
            return `tiktok id ${index}`;
        },
        title(index: number) {
            return `title ${index}`;
        },
        author_name(index: number) {
            return `author name ${index}`;
        },
        monitored_audio_id(index: number) {
            return index;
        },
        standardised_data() {
            return { daily_total_video_counts: [] };
        },
        is_original() {
            return false;
        },
        video_count(index: number) {
            return index;
        },
        video_count_daily_change() {
            return 1;
        },
        video_count_daily_change_relative() {
            return '0.0';
        },
        video_count_weekly_change() {
            return 1;
        },
        video_count_weekly_change_relative() {
            return '0.0';
        },
    }),
    songCollectionStat: createFactory<SongCollectionStats>({
        total_video_count() {
            return 0;
        },
        new_video_count_one_day() {
            return 0;
        },
        new_video_count_seven_days() {
            return 0;
        },
        total_audios_tracked() {
            return 0;
        },
    }),
};

export function handleSongCollectionRequests(server: AppServer) {
    server.get('/api/music/viewsets/song-collection/:id/', function (this: RouteHandlerContext, schema, request) {
        const collection = schema.find('songCollection', request.params.id);
        if (!collection) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return collection;
    });

    const getSongCollectionsListPath = '/api/music/viewsets/song-collection/';
    server.get(getSongCollectionsListPath, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const collections = schema
            .all('songCollection')
            .filter((c) => !search || c.name.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(collections, {
            url: getSongCollectionsListPath,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'songCollection'),
        });
    });

    server.post('/api/music/viewsets/song-collection/', function (this: RouteHandlerContext, schema, request) {
        const body = JSON.parse(request.requestBody);
        return schema.create('songCollection', body);
    });

    server.patch('/api/music/viewsets/song-collection/:id/', function (this: RouteHandlerContext, schema, request) {
        const collection = schema.find('songCollection', request.params.id);
        if (!collection) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return collection;
    });

    const getSongCollectionSongStatsPath = '/api/music/viewsets/song-collection/:id/song-stats/';
    server.get(getSongCollectionSongStatsPath, function (this: RouteHandlerContext, schema, request) {
        const { search = '', ordering } = request.queryParams;
        const stats = schema
            .all('songTiktokStat')
            .filter((stats) => !search || stats.song.title.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => {
                if (!ordering) {
                    return 0;
                }

                if (ordering.startsWith('-')) {
                    const key = ordering.slice(1) as keyof SongTiktokStats;
                    return (b[key] as number) - (a[key] as number);
                }

                const key = ordering as keyof SongTiktokStats;
                return (a[key] as number) - (b[key] as number);
            });

        return buildPaginatedResponse(stats, {
            url: getSongCollectionSongStatsPath,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'songTiktokStat'),
        });
    });

    const getSongCollectionTiktokAudioStatsPath = '/api/music/viewsets/song-collection/:id/tiktok-audio-stats/';
    server.get(getSongCollectionTiktokAudioStatsPath, function (this: RouteHandlerContext, schema, request) {
        const { search = '', ordering } = request.queryParams;
        const stats = schema
            .all('songCollectionTiktokAudioStat')
            .filter((stats) => !search || stats.title.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => {
                if (!ordering) {
                    return 0;
                }

                if (ordering.startsWith('-')) {
                    const key = ordering.slice(1) as keyof SongCollectionTiktokAudioStats;
                    return (b[key] as number) - (a[key] as number);
                }

                const key = ordering as keyof SongCollectionTiktokAudioStats;
                return (a[key] as number) - (b[key] as number);
            });

        return buildPaginatedResponse(stats, {
            url: getSongCollectionTiktokAudioStatsPath,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'songCollectionTiktokAudioStat'),
        });
    });

    server.get('/api/music/viewsets/song-collection/:id/stats/', function (this: RouteHandlerContext, schema) {
        return schema.first('songCollectionStat');
    });

    server.post(
        '/api/music/viewsets/song-collection/:songCollectionId/add-song/',
        function (this: RouteHandlerContext, schema, request) {
            const collection = schema.find('songCollection', request.params.songCollectionId);

            if (!collection) {
                return new Response(404, {}, { detail: 'Not found.' });
            }

            return new Response(204);
        }
    );

    server.post(
        '/api/music/viewsets/song-collection/:songCollectionId/remove-song/',
        function (this: RouteHandlerContext, schema, request) {
            const collection = schema.find('songCollection', request.params.songCollectionId);

            if (!collection) {
                return new Response(404, {}, { detail: 'Not found.' });
            }

            return new Response(204);
        }
    );
}
