import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModalRight from '../../../../../SharedComponents/ModalRight/ModalRight';
import { ReactComponent as CloseIcon } from '../../../../../assets/Close.svg';
import styles from './TiktokInfluencerUserModal.module.css';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import Image from '../../../../../ui/Image/Image';
import Skeleton from '../../../../../ui/Skeleton/Skeleton';
import {
    asMoney,
    buildTiktokUserUrl,
    formatNumberToKNotation,
    formatPostingFrequencyFromAverage,
    formatTo2dp,
    getLastUpdatedLabel,
    roundTo2Dp,
    showNotification,
} from '../../../../../helpers';
import { influencerTagSelectStyles } from '../components/Tags/InfluencerUserTags';
import {
    getInfluencerUserInfluencerPlans,
    InfluencerTag,
    InfluencerUserCampaigns,
    getTiktokInfluencerUser,
    patchTiktokInfluencerUser,
    TiktokInfluencerUser,
    TiktokInfluencerUserApiBody,
    TikTokInfluencerUserStats,
    TiktokUser,
    getTiktokUser,
    InfluencerPlan,
} from '@round/api';
import { useCheckUserGroupsAccess } from '../../../../Auth/hooks/useCheckUserGroupsAccess';
import DataCard from '../../../../../ui/DataDisplay/DataCard/DataCard';
import Tabs, { TabNav, TabNavItem, TabPanel, TabPanels } from '../../../../../ui/DataDisplay/Tabs/Tabs';
import { ReactComponent as ProfileAddIcon } from '../../../../../assets/ProfileAdd.svg';
import { ReactComponent as MusicNoteIcon } from '../../../../../assets/MusicNote.svg';
import { ReactComponent as MegaphoneIcon } from '../../../../../assets/Megaphone.svg';
import { ReactComponent as InfoCircleIcon } from '../../../../../assets/InfoCircle.svg';
import { ReactComponent as ApexLogo } from '../../../../../assets/ApexLogo.svg';
import Audios from './Audios/Audios';
import Promotions from './Promotions/Promotions';
import EngagementChart from './Engagement/EngagementChart/EngagementChart';
import TiktokUserTopPosts from './Engagement/TiktokUserTopPosts/TiktokUserTopPosts';
import TiktokUserBoostedPosts from './Engagement/TiktokUserBoostedPosts/TiktokUserBoostedPosts';
import { useClearTiktokUserData, useRefreshTiktokUserData } from './TiktokUserDataContext/TiktokUserDataContext';
import Button from '../../../../../ui/Buttons/Button/Button';
import { ProtectedByUserGroups } from '../../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import { ReactComponent as RefreshIcon } from '../../../../../assets/Refresh.svg';
import TextArea from '../../../../../ui/TextArea/TextArea';
import Select from '../../../../../ui/DataEntry/Select/Select';
import { StylesConfig } from 'react-select';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../../contexts/OptionsContext/OptionsContext';
import { GenericDropdownOption } from '../../../../../App.types';
import AddTiktokInfluencerToCampaign from '../../../../Influencer/containers/AddInfluencerToCampaign/AddTiktokInfluencerToCampaign';
import { isTiktokInfluencerTableRow } from '../components/TiktokInfluencersTable/TiktokInfluencersTable.types';
import { useInfluencerTags } from '../../../../../Hooks/useInfluencerTags';
import cn from 'classnames';
import TiktokUserAudienceBreakdown from './Audience/TiktokUserAudienceBreakdown';
import { ReactComponent as PeopleIcon } from '../../../../../assets/People.svg';
import { getCountry } from '../../../../../utility/utility';
import Lookalikes from './Lookalikes/Lookalikes';
import EditableContentTags from 'Modules/Influencer/components/EditableContentTags/EditableContentTags';
import useInfluencerPlans from 'Modules/Advertising/InfluencerPlan/hooks/useInfluencerPlans';
import uniq from 'lodash/uniq';

const oneWeekAgo = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);

type TiktokInfluencerUserModalProps = {
    isModalOpen: boolean;
    closeModal: (InfluencerUser: TiktokInfluencerUser | null) => void;
    influencerUserStats: TikTokInfluencerUserStats | null;
    plans?: InfluencerPlan[];
    influencerUserId: number | null | undefined;
    onInfluencerUpdated?: (influencer: TiktokInfluencerUser) => void;
};

const currencySelectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        borderColor: '#C2CFE0',
        fontSize: '0.9rem',
        height: '2.4rem',
        minWidth: '7rem',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: '#C2CFE0',
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: '0.9rem',
    }),
};

const TiktokInfluencerUserModal = ({
    isModalOpen,
    closeModal,
    influencerUserStats,
    influencerUserId,
    onInfluencerUpdated,
    plans,
}: TiktokInfluencerUserModalProps) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const hasInfluencerEditorPermissions = useCheckUserGroupsAccess(['influencer_editor']);
    const [activeTab, setActiveTab] = useState('audience');

    const {
        influencerUser,
        influencerUserLoading,
        addLocationTag,
        resetInfluencerUser,
        updateInfluencerUser,
    } = useInfluencerUser(influencerUserId);
    const { refreshDisabled, refreshData, refreshLoading, lastUpdated } = useRefreshTiktokUserData(influencerUser);
    const clearTiktokUserData = useClearTiktokUserData();
    const [tiktokUser, setTiktokUser] = useState<TiktokUser | null>(null);
    const [tiktokUserLoading, setTiktokUserLoading] = useState(false);
    const { influencerTags: tags } = useInfluencerTags();

    const [showInfluencerUserEditing, setShowInfluencerUserEditing] = useState(false);
    const [
        soundPromotionCurrencySelectValue,
        setSoundPromotionCurrencySelectValue,
    ] = useState<GenericDropdownOption<number> | null>(null);
    const [soundPromotionCostInputValue, setSoundPromotionCostInputValue] = useState<string>('');
    const [
        brandPromotionCurrencySelectValue,
        setBrandPromotionCurrencySelectValue,
    ] = useState<GenericDropdownOption<number> | null>(null);
    const [brandPromotionCostInputValue, setBrandPromotionCostInputValue] = useState<string>('');
    const [contactDetailsInputValue, setContactDetailsInputValue] = useState('');
    const [paypalEmailInputValue, setPaypalEmailInputValue] = useState('');
    const [notesInputValue, setNotesInputValue] = useState('');
    const currencyOptions: GenericDropdownOption<number>[] = useMemo(
        () => currencies.map((c) => ({ value: c.id, label: c.name })),
        [currencies]
    );
    const [influencerUserCampaigns, setInfluencerUserCampaigns] = useState<InfluencerUserCampaigns | null>(null);

    const { fetchData: fetchInfluencerPlans, ...influencerPlansData } = useInfluencerPlans();

    useAbortableEffect(
        (signal) => {
            if (plans?.length) {
                return;
            }

            const plansToFetch = uniq(influencerUserCampaigns?.plans.map((p) => p.plan_id)) ?? [];

            if (!plansToFetch.length || influencerPlansData.status === 'initialized') {
                return;
            }

            fetchInfluencerPlans(
                { id: plansToFetch.toString(), page_size: plansToFetch.length },
                { signal }
            ).catch(() => {});
        },
        [fetchInfluencerPlans, influencerPlansData.status, influencerUserCampaigns?.plans, plans?.length]
    );

    useEffect(() => {
        if (!influencerUser) {
            return;
        }

        const { currency, cost, notes, brand_promotion_cost, brand_promotion_currency } = influencerUser;
        setSoundPromotionCurrencySelectValue(currencyOptions.find((c) => c.value === currency) ?? null);
        setSoundPromotionCostInputValue(cost ?? '');
        setBrandPromotionCostInputValue(brand_promotion_cost ?? '');
        setBrandPromotionCurrencySelectValue(currencyOptions.find((c) => c.value === brand_promotion_currency) ?? null);
        setContactDetailsInputValue(influencerUser.contact_details ?? '');
        setPaypalEmailInputValue(influencerUser.paypal_email ?? '');
        setNotesInputValue(notes);
    }, [currencyOptions, influencerUser]);

    const handleUpdateSoundPromotionCurrency = useCallback(async () => {
        if (influencerUser?.currency === (soundPromotionCurrencySelectValue?.value ?? null)) {
            return;
        }

        try {
            const result = await updateInfluencerUser({ currency: soundPromotionCurrencySelectValue?.value ?? null });

            if (result && typeof onInfluencerUpdated === 'function') {
                onInfluencerUpdated(result);
            }

            showNotification('Sound promotion currency updated', 'info');
        } catch {
            showNotification('Could not update sound promotion currency', 'error');
        }
    }, [influencerUser?.currency, soundPromotionCurrencySelectValue?.value, updateInfluencerUser, onInfluencerUpdated]);

    const handleUpdateSoundPromotionCost = useCallback(async () => {
        if (Number(influencerUser?.cost ?? '') === Number(soundPromotionCostInputValue)) {
            return;
        }

        try {
            const result = await updateInfluencerUser({
                cost: soundPromotionCostInputValue !== '' ? soundPromotionCostInputValue : null,
            });

            if (result && typeof onInfluencerUpdated === 'function') {
                onInfluencerUpdated(result);
            }

            showNotification('Sound promotion cost updated', 'info');
        } catch {
            showNotification('Could not update sound promotion cost', 'error');
        }
    }, [influencerUser?.cost, soundPromotionCostInputValue, updateInfluencerUser, onInfluencerUpdated]);

    const handleUpdateNotes = useCallback(async () => {
        if (influencerUser?.notes === notesInputValue) {
            return;
        }

        try {
            const result = await updateInfluencerUser({ notes: notesInputValue });

            if (result && typeof onInfluencerUpdated === 'function') {
                onInfluencerUpdated(result);
            }

            showNotification('Notes updated', 'info');
        } catch {
            showNotification('Could not update notes', 'error');
        }
    }, [influencerUser?.notes, notesInputValue, onInfluencerUpdated, updateInfluencerUser]);

    const handleUpdateContactDetails = useCallback(async () => {
        if (influencerUser?.contact_details === contactDetailsInputValue) {
            return;
        }

        try {
            const result = await updateInfluencerUser({ contact_details: contactDetailsInputValue });

            if (result && typeof onInfluencerUpdated === 'function') {
                onInfluencerUpdated(result);
            }

            showNotification('Contact details updated', 'info');
        } catch {
            showNotification('Could not update contact details', 'error');
        }
    }, [contactDetailsInputValue, influencerUser?.contact_details, onInfluencerUpdated, updateInfluencerUser]);

    const handleUpdatePaypalEmail = useCallback(async () => {
        if (influencerUser?.paypal_email === paypalEmailInputValue) {
            return;
        }

        try {
            const result = await updateInfluencerUser({ paypal_email: paypalEmailInputValue });

            if (result && typeof onInfluencerUpdated === 'function') {
                onInfluencerUpdated(result);
            }

            showNotification('PayPal email updated', 'info');
        } catch {
            showNotification('Could not update PayPal email', 'error');
        }
    }, [influencerUser?.paypal_email, onInfluencerUpdated, paypalEmailInputValue, updateInfluencerUser]);

    const handleUpdateBrandPromotionCost = useCallback(async () => {
        if (influencerUser?.brand_promotion_cost === brandPromotionCostInputValue) {
            return;
        }

        try {
            const result = await updateInfluencerUser({ brand_promotion_cost: brandPromotionCostInputValue });

            if (result && typeof onInfluencerUpdated === 'function') {
                onInfluencerUpdated(result);
            }

            showNotification('Brand Promotion Cost updated', 'info');
        } catch {
            showNotification('Could not update brand promotioncost', 'error');
        }
    }, [brandPromotionCostInputValue, influencerUser?.brand_promotion_cost, onInfluencerUpdated, updateInfluencerUser]);

    const handleUpdateBrandPromotionCurrency = useCallback(async () => {
        if (influencerUser?.brand_promotion_currency === (brandPromotionCurrencySelectValue?.value ?? null)) {
            return;
        }

        try {
            const result = await updateInfluencerUser({
                brand_promotion_currency: brandPromotionCurrencySelectValue?.value ?? null,
            });

            if (result && typeof onInfluencerUpdated === 'function') {
                onInfluencerUpdated(result);
            }

            showNotification('Brand Promotion Currency updated', 'info');
        } catch {
            showNotification('Could not update brand promotion currency', 'error');
        }
    }, [
        brandPromotionCurrencySelectValue?.value,
        influencerUser?.brand_promotion_currency,
        onInfluencerUpdated,
        updateInfluencerUser,
    ]);

    const followers = useMemo(() => {
        if (!influencerUserStats || typeof influencerUserStats.follower_count !== 'number') {
            return '-';
        }

        return formatNumberToKNotation(influencerUserStats.follower_count);
    }, [influencerUserStats]);

    const engagementRate = useMemo(() => {
        if (!influencerUserStats || !influencerUserStats.avg_engagement_rate) {
            return '-';
        }

        return `${roundTo2Dp(influencerUserStats.avg_engagement_rate * 100)}%`;
    }, [influencerUserStats]);

    const estCPM = useMemo(() => {
        if (!influencerUserStats || !influencerUserStats.estimated_cpm || !influencerUserStats.currency) {
            return '-';
        }

        return asMoney(influencerUserStats.estimated_cpm, influencerUserStats.currency);
    }, [influencerUserStats]);

    const averageVideoPlays = useMemo(() => {
        if (!influencerUserStats || !influencerUserStats.avg_video_plays_per_post) {
            return '-';
        }

        return formatNumberToKNotation(formatTo2dp(influencerUserStats.avg_video_plays_per_post));
    }, [influencerUserStats]);

    const postingFrequency = influencerUserStats?.avg_posts_per_day
        ? formatPostingFrequencyFromAverage(influencerUserStats?.avg_posts_per_day)
        : '-';

    const lastPostDate = influencerUserStats?.last_post_datetime
        ? new Date(influencerUserStats?.last_post_datetime)
        : null;
    const showLastPostDateWarning = Boolean(lastPostDate && lastPostDate < oneWeekAgo);

    const lastPostDateWarningValue = showLastPostDateWarning ? 'Last post was over 7 days ago' : undefined;

    const isInfluencerApex = influencerUser?.is_apex || false;

    const locationTags: InfluencerTag[] = useMemo(() => tags.filter((t) => t.type === 'LOCATION'), [tags]);

    const locationTagOptions: GenericDropdownOption<number>[] = useMemo(
        () =>
            locationTags.map((t) => ({
                value: t.id,
                label: t.name,
            })),
        [locationTags]
    );

    const creatorLocation = useMemo(() => getCountry(influencerUser?.location ?? influencerUserStats?.location), [
        influencerUser?.location,
        influencerUserStats?.location,
    ]);

    const username = tiktokUser?.unique_id ?? influencerUser?.username;
    const usernameDisplayValue = username ? `@${username}` : null;

    const handleAddLocationTag = useCallback(
        async (tag: InfluencerTag) => {
            try {
                const succeeded = await addLocationTag(tag);
                if (succeeded) {
                    showNotification('Tag added', 'info');
                    return;
                }
                showNotification('Could not add tag', 'error');
            } catch {
                showNotification('Could not add tag', 'error');
            }
        },
        [addLocationTag]
    );

    const handleUpdateContentTags = useCallback(
        async (tagIds: number[]) => {
            if (!influencerUser) {
                return;
            }

            try {
                const succeeded = await updateInfluencerUser({ content_tags: tagIds });

                if (succeeded) {
                    showNotification('Tags updated', 'info');
                    return;
                }
                showNotification('Could not update tags', 'error');
            } catch {
                showNotification('Could not update tags', 'error');
            }
        },
        [influencerUser, updateInfluencerUser]
    );

    const handleRefreshData = useCallback(async () => {
        try {
            await refreshData();
            showNotification('Data refreshed', 'info');
        } catch {
            showNotification('Could not refresh data', 'error');
        }
    }, [refreshData]);

    const fetchInfluencerUserCampaigns = useCallback(async () => {
        if (typeof influencerUserId !== 'number') {
            return;
        }

        try {
            const response = await getInfluencerUserInfluencerPlans({
                tiktok_influencer_users: influencerUserId.toString(),
            });
            if (response.status === 200) {
                const [campaigns] = response.data;
                setInfluencerUserCampaigns(campaigns ?? null);
            }
        } catch {
            // no-op
        }
    }, [influencerUserId]);

    const handleClose = () => {
        setShowInfluencerUserEditing(false);
        resetInfluencerUser();
        clearTiktokUserData();
        setTiktokUser(null);
        setActiveTab('audience');
        closeModal(influencerUser);
    };

    const fetchTiktokUser = useCallback(async (userId: number, requestInit?: RequestInit) => {
        try {
            setTiktokUserLoading(true);
            const tiktokUser = await getTiktokUser(userId, requestInit);
            setTiktokUser(tiktokUser);
        } catch {
            // no-op
        } finally {
            setTiktokUserLoading(false);
        }
    }, []);

    useAbortableEffect(
        (signal) => {
            if (typeof influencerUser?.user === 'number') {
                fetchTiktokUser(influencerUser?.user, { signal });
            }
        },
        [influencerUser?.user, fetchTiktokUser]
    );

    useEffect(() => {
        if (influencerUserStats && isTiktokInfluencerTableRow(influencerUserStats)) {
            setInfluencerUserCampaigns(influencerUserStats.campaigns ?? null);
            return;
        }

        fetchInfluencerUserCampaigns();
    }, [fetchInfluencerUserCampaigns, influencerUserId, influencerUserStats]);

    return (
        <ModalRight className={styles.modal} isModalOpen={isModalOpen} closeModal={handleClose}>
            <div className={styles.modalContent}>
                <button className={styles.closeButton} onClick={handleClose}>
                    <CloseIcon className={styles.closeIcon} />
                </button>

                <div className={styles.influencerUserInfo}>
                    <div className={styles.influencerProfile}>
                        <Image
                            className={styles.influencerUserImage}
                            src={tiktokUser?.avatar_thumb}
                            alt="Influencer"
                            loading={tiktokUserLoading}
                        />

                        <div className={styles.influencerUserDetails}>
                            <p className={styles.influencerUserName}>
                                {tiktokUserLoading ? <Skeleton width="12rem" /> : tiktokUser?.nickname}
                            </p>
                            <a
                                className={styles.influencerUserAccount}
                                href={buildTiktokUserUrl(username ?? '')}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {tiktokUserLoading ? <Skeleton width="7rem" /> : usernameDisplayValue}
                            </a>
                            {tiktokUser?.signature && !tiktokUserLoading ? (
                                <p className={styles.influencerUserSignature}>{tiktokUser.signature}</p>
                            ) : null}
                            <p className={styles.influencerUserBio}>
                                {tiktokUserLoading ? (
                                    <Skeleton width="5rem" />
                                ) : tiktokUser?.bio_link ? (
                                    <a
                                        href={tiktokUser.bio_link}
                                        className={styles.influencerUserBio}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {tiktokUser.bio_link}
                                    </a>
                                ) : (
                                    'No bio yet.'
                                )}
                            </p>

                            {!influencerUserLoading && !tiktokUserLoading && (
                                <div className={styles.tags}>
                                    <div className={styles.tagsContainer}>
                                        <span className={styles.tagsLabel}>Content</span>
                                        <EditableContentTags
                                            tagIds={influencerUser?.content_tags ?? []}
                                            onChange={handleUpdateContentTags}
                                            readonly={!hasInfluencerEditorPermissions}
                                        />
                                    </div>

                                    <div className={styles.locationContainer}>
                                        <span className={styles.label}>Creator Location</span>
                                        {!!creatorLocation && (
                                            <span className={styles.location}>
                                                <span
                                                    className={cn(
                                                        `fi fi-${creatorLocation.code?.toLowerCase()}`,
                                                        styles.flagIcon
                                                    )}
                                                />
                                                {creatorLocation.label}
                                            </span>
                                        )}
                                        {!creatorLocation && (
                                            <Select
                                                styles={influencerTagSelectStyles}
                                                placeholder={`Add location`}
                                                value={null}
                                                options={locationTagOptions}
                                                onChange={(option) => {
                                                    const tag = tags.find((t) => t.id === option?.value);
                                                    if (tag) {
                                                        handleAddLocationTag(tag);
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={styles.actions}>
                            <ProtectedByUserGroups groups={['influencer_editor', 'user_admin']}>
                                {isInfluencerApex && (
                                    <div className={styles.apexLogoContainer}>
                                        <ApexLogo />
                                    </div>
                                )}
                                <Button
                                    type="bordered"
                                    disabled={influencerUserLoading}
                                    className={styles.influencerUserEditButton}
                                    onClick={() => setShowInfluencerUserEditing(!showInfluencerUserEditing)}
                                >
                                    <InfoCircleIcon className={styles.infoIcon} />
                                </Button>

                                {influencerUserCampaigns && (
                                    <AddTiktokInfluencerToCampaign
                                        className={styles.addToCampaignButton}
                                        influencerCampaigns={influencerUserCampaigns}
                                        plans={plans ?? influencerPlansData.data?.results ?? []}
                                        influencerUserStats={influencerUserStats}
                                        onInfluencerAddedToCampaign={() => {
                                            fetchInfluencerUserCampaigns().then(() => {
                                                influencerPlansData.reset();
                                            });
                                        }}
                                    >
                                        + Add to campaign
                                    </AddTiktokInfluencerToCampaign>
                                )}
                            </ProtectedByUserGroups>

                            <ProtectedByUserGroups groups={['user_admin']}>
                                <Button
                                    className={styles.refreshButton}
                                    type="bordered"
                                    disabled={refreshDisabled}
                                    loading={refreshLoading}
                                    onClick={handleRefreshData}
                                    hint={`Last updated: ${getLastUpdatedLabel(lastUpdated)}`}
                                >
                                    <RefreshIcon className={styles.refreshButtonIcon} />
                                    Refresh Data
                                </Button>
                            </ProtectedByUserGroups>
                        </div>
                    </div>

                    {showInfluencerUserEditing && (
                        <ProtectedByUserGroups groups={['influencer_editor', 'user_admin']}>
                            <div className={styles.influencerUserForm}>
                                <div className={styles.influencerUserFormSection}>
                                    <label className={styles.influencerUserFormLabel}>Contact Details</label>
                                    <input
                                        className={styles.influencerUserContactDetails}
                                        value={contactDetailsInputValue}
                                        onChange={(e) => setContactDetailsInputValue(e.target.value)}
                                        onBlur={handleUpdateContactDetails}
                                        placeholder="Enter contact details"
                                    />
                                </div>

                                <div className={styles.influencerUserFormSection}>
                                    <label className={styles.influencerUserFormLabel}>PayPal Email Address</label>
                                    <input
                                        className={styles.influencerUserPaypalEmail}
                                        value={paypalEmailInputValue}
                                        onChange={(e) => setPaypalEmailInputValue(e.target.value)}
                                        onBlur={handleUpdatePaypalEmail}
                                        placeholder="Enter contact details"
                                    />
                                </div>

                                <div className={styles.influencerFormSplitSection}>
                                    <div className={styles.influencerUserCostColumn}>
                                        <div className={styles.influencerUserFormSection}>
                                            <label className={styles.influencerUserFormLabel}>
                                                Sound Promotion Cost
                                            </label>
                                            <div className={styles.influencerUserCostField}>
                                                <Select
                                                    isSearchable={false}
                                                    styles={currencySelectStyles}
                                                    className={styles.influencerUserCurrencySelect}
                                                    placeholder="currency"
                                                    value={soundPromotionCurrencySelectValue}
                                                    onChange={(value) =>
                                                        setSoundPromotionCurrencySelectValue(value ?? null)
                                                    }
                                                    options={currencyOptions}
                                                    onBlur={handleUpdateSoundPromotionCurrency}
                                                />

                                                <input
                                                    className={styles.influencerUserCost}
                                                    type="number"
                                                    placeholder="cost"
                                                    value={soundPromotionCostInputValue}
                                                    onChange={(e) => setSoundPromotionCostInputValue(e.target.value)}
                                                    onBlur={handleUpdateSoundPromotionCost}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.influencerUserFormSection}>
                                            <label className={styles.influencerUserFormLabel}>
                                                Brand Promotion Cost
                                            </label>
                                            <div className={styles.influencerUserCostField}>
                                                <Select
                                                    isSearchable={false}
                                                    styles={currencySelectStyles}
                                                    className={styles.influencerUserCurrencySelect}
                                                    placeholder="currency"
                                                    value={brandPromotionCurrencySelectValue}
                                                    onChange={(value) =>
                                                        setBrandPromotionCurrencySelectValue(value ?? null)
                                                    }
                                                    options={currencyOptions}
                                                    onBlur={handleUpdateBrandPromotionCurrency}
                                                />

                                                <input
                                                    className={styles.influencerUserCost}
                                                    type="number"
                                                    placeholder="cost"
                                                    value={brandPromotionCostInputValue}
                                                    onChange={(e) => setBrandPromotionCostInputValue(e.target.value)}
                                                    onBlur={handleUpdateBrandPromotionCost}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.influencerUserFormSection}>
                                        <label className={styles.influencerUserFormLabel}>Notes</label>
                                        <TextArea
                                            className={styles.influencerUserNotes}
                                            placeholder="Enter notes"
                                            value={notesInputValue}
                                            onChange={(e) => setNotesInputValue(e.target.value)}
                                            onBlur={handleUpdateNotes}
                                        />
                                    </div>
                                </div>
                            </div>
                        </ProtectedByUserGroups>
                    )}
                </div>

                <div className={styles.statsContainer}>
                    <DataCard
                        loading={tiktokUserLoading}
                        value={followers}
                        title="Followers"
                        hint="Total followers on TikTok"
                    />
                    <DataCard
                        loading={tiktokUserLoading}
                        value={engagementRate}
                        title="Engagement rate"
                        hint="(likes + comments + shares + saves) / views for the most recent 20 videos the creator has posted"
                    />
                    <DataCard
                        loading={tiktokUserLoading}
                        value={estCPM}
                        title="Est. CPM"
                        hint="Estimated cost per thousand impressions (CPM) of an ad on the platform"
                    />
                    <DataCard
                        loading={tiktokUserLoading}
                        value={averageVideoPlays}
                        title="Avg. video plays"
                        hint="The average number of views/plays per post"
                    />
                    <DataCard
                        loading={tiktokUserLoading}
                        value={postingFrequency}
                        title="Posting frequency"
                        additionalValue={
                            showLastPostDateWarning ? (
                                <InfoCircleIcon className={cn(styles.infoIcon, styles.warning)} />
                            ) : null
                        }
                        valuesClassName={cn(styles.cardValue, { [styles.warning]: showLastPostDateWarning })}
                        tooltipValue={lastPostDateWarningValue}
                        hint="On average how often a user uploads new content on the platform"
                    />
                </div>

                <Tabs activeTab={activeTab} onChange={setActiveTab}>
                    <TabNav containerClassName={styles.tabNav}>
                        <TabNavItem name="audience" className={styles.tabNavItem}>
                            <PeopleIcon width="1.25rem" className={styles.tabNavItemIcon} />
                            Audience
                        </TabNavItem>
                        <TabNavItem name="engagement" className={styles.tabNavItem}>
                            <ProfileAddIcon className={styles.tabNavItemIcon} />
                            Engagement
                        </TabNavItem>
                        <TabNavItem name="audios" className={styles.tabNavItem}>
                            <MusicNoteIcon className={styles.tabNavItemIcon} />
                            Audios
                        </TabNavItem>
                        <TabNavItem name="promotions" className={styles.tabNavItem}>
                            <MegaphoneIcon className={styles.tabNavItemIcon} />
                            Promotions
                        </TabNavItem>
                        <TabNavItem name="lookalikes" className={styles.tabNavItem}>
                            Lookalikes
                        </TabNavItem>
                    </TabNav>

                    <TabPanels>
                        <TabPanel name="audience">
                            <TiktokUserAudienceBreakdown userId={tiktokUser?.id} />
                        </TabPanel>
                        <TabPanel name="engagement">
                            <EngagementChart loading={tiktokUserLoading} tiktokUserId={tiktokUser?.id} />
                            <TiktokUserTopPosts loading={tiktokUserLoading} user={tiktokUser} />
                            <TiktokUserBoostedPosts loading={tiktokUserLoading} tiktokUserId={tiktokUser?.id} />
                        </TabPanel>
                        <TabPanel name="audios">
                            <Audios influencerUserId={influencerUserId} />
                        </TabPanel>
                        <TabPanel name="promotions">
                            <Promotions influencerUserId={influencerUserId} userId={tiktokUser?.id} />
                        </TabPanel>
                        <TabPanel name="lookalikes">
                            <Lookalikes userId={tiktokUser?.id} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        </ModalRight>
    );
};

export default TiktokInfluencerUserModal;

type UseInfluencerUser = {
    influencerUser: TiktokInfluencerUser | null;
    influencerUserLoading: boolean;
    addLocationTag: (tag: InfluencerTag) => Promise<boolean>;
    resetInfluencerUser: () => void;
    updateInfluencerUser: (
        data: Partial<
            Pick<
                TiktokInfluencerUserApiBody,
                | 'currency'
                | 'cost'
                | 'notes'
                | 'contact_details'
                | 'brand_promotion_cost'
                | 'brand_promotion_currency'
                | 'paypal_email'
                | 'content_tags'
            >
        >
    ) => Promise<TiktokInfluencerUser | false>;
};

function useInfluencerUser(influencerUserId: number | undefined | null): UseInfluencerUser {
    const [influencerUser, setInfluencerUser] = useState<TiktokInfluencerUser | null>(null);
    const [influencerUserLoading, setInfluencerUserLoading] = useState(false);

    const updateInfluencerUser = useCallback(
        async (data: Parameters<UseInfluencerUser['updateInfluencerUser']>[0]) => {
            if (!influencerUser) {
                return false;
            }

            try {
                const response = await patchTiktokInfluencerUser(influencerUser.id, data);
                if (response.status === 200) {
                    setInfluencerUser(response.data);
                    return response.data;
                }

                return false;
            } catch (e) {
                throw e;
            }
        },
        [influencerUser]
    );

    const addLocationTag = useCallback(
        async (tag: InfluencerTag) => {
            if (!influencerUser) {
                return false;
            }

            try {
                const response = await patchTiktokInfluencerUser(influencerUser.id, {
                    tags: [tag.id],
                });

                if (response.status === 200) {
                    setInfluencerUser(response.data);
                }

                return response.status === 200;
            } catch (e) {
                throw e;
            }
        },
        [influencerUser]
    );

    const resetInfluencerUser = useCallback(() => {
        setInfluencerUser(null);
        setInfluencerUserLoading(false);
    }, []);

    const fetchInfluencerUser = useCallback(async (influencerUserId: number, requestInit?: RequestInit) => {
        try {
            setInfluencerUserLoading(true);
            const influencerUser = await getTiktokInfluencerUser(influencerUserId, requestInit);
            setInfluencerUser(influencerUser);
        } catch {
            // no-op
        } finally {
            setInfluencerUserLoading(false);
        }
    }, []);

    useAbortableEffect(
        (signal) => {
            if (typeof influencerUserId === 'number') {
                fetchInfluencerUser(influencerUserId, { signal });
            }
        },
        [influencerUserId, fetchInfluencerUser]
    );

    return {
        resetInfluencerUser,
        addLocationTag,
        influencerUserLoading,
        influencerUser,
        updateInfluencerUser,
    };
}
