import React from 'react';
import cn from 'classnames';
import styles from './Label.module.css';
import { FCWithChildren } from '../../../utility/utility.types';

type LabelProps = {
    htmlFor: string;
    className?: string;
};

const Label: FCWithChildren<LabelProps> = ({ children, htmlFor, className }) => (
    <label className={cn(styles.label, className)} htmlFor={htmlFor}>
        {children}
    </label>
);

export default Label;
