import { IconProps } from '../../../../../SharedComponents/svg/Icons/Icon.types';

const LiveIcon = ({ width, height, onClick, className, title }: IconProps) => {
    return (
        <svg
            role="img"
            aria-label={title}
            className={className}
            onClick={onClick}
            width={width}
            height={height}
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="7" cy="7.5" r="6" fill="#2ED47A" />
            <path
                d="M4.6001 7.49995L6.2001 9.29995L9.4001 5.69995"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default LiveIcon;
