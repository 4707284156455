import { combineReducers } from 'helpers';
import songReducer, { songDataInitialState } from './SongData/reducer';
import { tiktokReducer, tiktokInitialState } from './Tiktok/reducer';

export type State = ReturnType<typeof reducer>;
export type Actions = Parameters<typeof reducer>[1];

const reducer = combineReducers({
    song: songReducer,
    tiktok: tiktokReducer,
});

export const initialState: State = {
    song: songDataInitialState,
    tiktok: tiktokInitialState,
};

export default reducer;
