import { microwave } from '@round/api';
import { DataState } from 'App.types';
import useNonNullContext from 'Hooks/useNonNullContext';
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useState } from 'react';

type State = DataState<microwave.Campaign[]>;
const initialState: State = { status: 'idle', data: null, error: null };

const CampaignsContext = createContext<[State, Dispatch<SetStateAction<State>>] | null>(null);

type Props = { children?: ReactNode | undefined };
export const CampaignsProvider = ({ children }: Props) => {
    const state = useState<State>(initialState);

    return <CampaignsContext.Provider value={state}>{children}</CampaignsContext.Provider>;
};

export function useCampaigns() {
    const [state, setState] = useNonNullContext(CampaignsContext);

    const fetchData = useCallback(
        async (releaseId: number, requestInit?: RequestInit) => {
            setState({ status: 'loading', data: null, error: null });

            try {
                const response = await microwave.getCampaigns({ release_id: releaseId.toString() }, requestInit);
                if (response.status === 404) {
                    setState({ data: null, error: response.data.detail, status: 'error' });
                    return response;
                }

                setState({ status: 'success', data: response.data.results, error: null });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                setState({ status: 'error', data: null, error: 'Could not fetch campaigns' });
                throw e;
            }
        },
        [setState]
    );

    const reset = useCallback(() => setState(initialState), [setState]);

    return {
        ...state,
        fetchData,
        reset,
    };
}
