import { creatorbase, youtube } from '@round/api';
import { Skeleton, TableProps, getTableMetaHelper } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import PostsTable from 'Modules/Plans/Posts/components/PostsTable/PostsTable';
import { usePublicReportYoutubePosts } from './hooks/usePublicReportYoutubePosts';
import { CampaignTableRow } from '../../Campaigns/CampaignsTable/PublicReportCampaignTable';
import PostsTableStatusCell, {
    StatusData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/StatusCell/PostsTableStatusCell';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import PostStatsValueCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsStatsValueCell';
import PostStatsFooterTotalCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableStatFooterCell';
import usePublicReportCampaignStats from '../../Campaigns/usePublicReportCampaignStats';
import PostStatsViewsFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostStatsViewsFooterCell';
import PostsTableUploadedDateCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableUploadedDateCell';
import PostsTableAccountCell, {
    AccountData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableAccountCell/PostsTableAccountCell';

export type YoutubePostsTableRow = creatorbase.PublicYoutubePost & {
    channel: youtube.Channel | null;
};

type Props = Pick<TableProps<YoutubePostsTableRow>, 'noDataLabel'> & {
    campaign: CampaignTableRow;
    publicReportId: string | undefined;
};

type TableCellContext<K extends keyof YoutubePostsTableRow> = CellContext<
    YoutubePostsTableRow,
    YoutubePostsTableRow[K]
>;

type Meta = BasePostTableMeta<YoutubePostsTableRow>;

const getTableMeta = getTableMetaHelper<Meta>();

const PublicReportYoutubePostsTable = ({ campaign, publicReportId }: Props) => {
    const { postsData, channelsData, fetchData, getIsAccountDataLoading } = usePublicReportYoutubePosts(campaign.id);
    const isInitialized = postsData?.status === 'success' || postsData?.status === 'error';

    const { data: campaignStats, getAreCampaignStatsLoading } = usePublicReportCampaignStats();

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && publicReportId) {
                fetchData(publicReportId, { signal }).catch(() => {});
            }
        },
        [publicReportId, fetchData, isInitialized]
    );

    const columns = useMemo<ColumnDef<YoutubePostsTableRow, any>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                cell: ({ getValue, table }: TableCellContext<'id'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue().slice(-8);
                },
            },
            {
                header: 'Account',
                accessorFn: (row): AccountData => ({
                    image: row.channel?.thumbnail || undefined,
                    title: row.channel?.title ?? '-',
                }),
                id: 'account',
                cell: PostsTableAccountCell,
            },
            {
                header: 'Status',
                id: 'status',
                accessorFn: (original): StatusData => ({
                    postUrl: original.post_url,
                    platform: original.platform,
                    isReadOnly: true,
                }),
                cell: PostsTableStatusCell,
            },
            {
                header: 'Uploaded date',
                accessorFn: (original) => original.upload_date,
                cell: PostsTableUploadedDateCell,
            },
            {
                header: 'Views',
                id: 'views',
                accessorFn: (original) => original.view_count,
                cell: PostStatsValueCell,
                footer: PostStatsViewsFooterCell,
            },
            {
                header: 'Likes',
                accessorFn: (original) => original.youtube_details?.like_count,
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Comments',
                accessorFn: (original) => original.youtube_details?.comment_count,
                id: 'comments',
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
        ],
        []
    );

    const meta: Meta = {
        isLoading: !isInitialized,
        getIsAccountDataLoading: (row: YoutubePostsTableRow) =>
            getIsAccountDataLoading(row.youtube_details?.account_id),
        campaignStats: campaignStats[campaign.id]?.data ?? null,
        areCampaignStatsLoading: getAreCampaignStatsLoading(campaign.id),
    };

    const rows: YoutubePostsTableRow[] = (
        postsData?.data?.filter((p): p is creatorbase.PublicYoutubePost => p.platform === 'youtube') || []
    ).map((post) => {
        const channelId = post.youtube_details?.account_id;
        const channel = channelId ? channelsData?.[channelId]?.data ?? null : null;

        return {
            ...post,
            channel,
        };
    });

    return (
        <PostsTable
            columns={columns}
            data={rows}
            meta={meta}
            isLoading={!isInitialized}
            hasError={postsData?.status === 'error'}
        />
    );
};

export default PublicReportYoutubePostsTable;
