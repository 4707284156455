import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { TiktokAudio } from './tiktokAudios.types';

export const models = {
    creatorbaseTiktokAudio: Model as ModelDefinition<TiktokAudio>,
};

export const serializers = {
    creatorbaseTiktokAudio: makeSerializer<TiktokAudio>([]),
};

export const factories = {
    creatorbaseTiktokAudio: createFactory<TiktokAudio>({
        author_name: (index: number) => `author_name ${index}`,
        is_original: false,
        tiktok_id: (index: number) => `tiktok-id-${index}`,
        title: (index: number) => `title ${index}`,
        video_count: 0,
        video_count_daily_change: 0,
        video_count_daily_change_relative: 0,
        video_count_weekly_change: 0,
        video_count_weekly_change_relative: 0,
    }),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/creatorbase/tiktok-audios/';
    server.get(rootUrl, function (this: RouteHandlerContext, schema, request) {
        const songId = request.queryParams.song_id;
        if (!songId) {
            return new Response(400, {}, { song_id: 'This field is required' });
        }

        const song = schema.find('creatorbaseSong', songId);
        const musicSong = song?.music_song_id ? schema.find('song', song.music_song_id.toString()) : null;

        const tiktokAudioIds =
            song?.tiktok_audios
                ?.concat(musicSong?.tiktok_audios || [])
                .filter((id) => !song?.tiktok_audios.includes(id)) || [];

        const audios = schema.all('creatorbaseTiktokAudio').filter((audio) => tiktokAudioIds.includes(audio.id));

        return buildPaginatedResponse(audios, {
            url: rootUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'creatorbaseBrand'),
        });
    });
}
