import React, { PropsWithChildren } from 'react';
import { ReactComponent as CheckCircleIcon } from '../../../assets/icons/CheckCircle.svg';
import { ReactComponent as DangerIcon } from '../../../assets/icons/Danger.svg';
import { ReactComponent as InfoCircleIcon } from '../../../assets/icons/InfoCircle.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import cn from 'classnames';
import styles from './Banner.module.css';

export type BannerProps = PropsWithChildren<{
    appearance?: 'danger' | 'success' | 'info' | 'warning';
    displayClose?: boolean;
    onClose?: () => void;
    className?: string;
}>;

const iconMapping: {
    [key in Exclude<BannerProps['appearance'], undefined>]: typeof CheckCircleIcon;
} = {
    success: CheckCircleIcon,
    danger: DangerIcon,
    info: InfoCircleIcon,
    warning: DangerIcon,
};

const Banner = ({ appearance = 'info', children, displayClose, onClose, className }: BannerProps) => {
    const Icon = iconMapping[appearance];
    return (
        <div className={cn(styles.banner, styles[appearance], className)}>
            <Icon className={styles.icon} />
            <div>{children}</div>
            {displayClose && (
                <button className={styles.closeButton} onClick={onClose}>
                    <CloseIcon className={styles.closeIcon} />
                </button>
            )}
        </div>
    );
};

export default Banner;
