import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { InfluencerCost } from './influencerCost.types';

export const models = {
    influencerCost: Model as ModelDefinition<InfluencerCost>,
};

export const factories = {
    influencerCost: createFactory<InfluencerCost>({
        campaign_type() {
            return Math.random() > 0.5 ? 'AUDIO' : 'BRAND';
        },
        cost(index: number) {
            return index * 100;
        },
        currency: 1,
        influencer(index: number) {
            return index;
        },
        platform() {
            const random = Math.random();
            const platform = random > 0.33 ? 'INSTAGRAM' : random > 0.66 ? 'TIKTOK' : 'YOUTUBE';
            return platform;
        },
    }),
};

export const serializers = {
    influencerCost: makeSerializer<InfluencerCost>([]),
};

export function handleInfluencerCostRequests(server: AppServer) {
    server.get('/api/apex/influencer-cost/', function (this: RouteHandlerContext, schema, request) {
        const ids = request.queryParams.ids.split(',');
        const influencerCosts = schema.all('influencerCost').filter((cost) => ids.includes(`${cost.influencer}`));
        return buildPaginatedResponse(influencerCosts, {
            url: '/api/apex/influencer-cost/',
            serialize: (resource) => this.serialize(resource, 'managerInfluencer'),
            queryParams: request.queryParams,
        });
    });
}
