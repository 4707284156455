import { influencer } from '@round/api';
import usePlatformHeadlineStats from './usePlatformHeadlineStats';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import usePublicReportData from '../ReportData/usePublicReportData';
import PlatformHeadlineStats, { Stats } from 'Modules/Plans/components/PlatformHeadlineStats/PlatformHeadlineStats';
import { formatNumberToKNotation } from 'helpers';
import { tooltipCopy } from 'Modules/Plans/helpers';
import { toDecimalPoint } from '@round/utils';

type Props = {
    reportId: string | undefined;
    platform: influencer.Platform;
};

const AggregatedPlatformStats = ({ platform, reportId }: Props) => {
    const { data: report } = usePublicReportData();
    const { data, status, fetchData } = usePlatformHeadlineStats(platform);
    const isInitialized = status === 'success' || status === 'error';
    const shouldInitialize = report?.platforms.includes(platform) && !!reportId;
    const isLoading = !isInitialized && shouldInitialize;

    useAbortableEffect(
        (signal) => {
            if (isInitialized || !shouldInitialize) {
                return;
            }

            fetchData(reportId, { signal }).catch(() => {});
        },
        [fetchData, isInitialized, reportId, shouldInitialize]
    );

    const stats: Stats = {
        totalPosts: {
            label: 'Total Posts',
            value: data?.post_count,
        },
        totalFollowers: {
            label: 'Total Followers',
            value: typeof data?.follower_count === 'number' ? formatNumberToKNotation(data?.follower_count) : '-',
        },
        totalViews: {
            label: 'Total Views',
            value: typeof data?.view_count === 'number' ? formatNumberToKNotation(data?.view_count) : '-',
        },
        engagementRate: {
            label: 'Engagement Rate',
            value:
                typeof data?.engagement_rate === 'number' ? `${toDecimalPoint(data?.engagement_rate * 100, 2)}%` : '-',
            tooltip: tooltipCopy.engagementRate,
        },
    };

    return <PlatformHeadlineStats stats={stats} isLoading={isLoading} />;
};

export default AggregatedPlatformStats;
