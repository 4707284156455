import { Skeleton } from '@round/ui-kit';
import React, { useMemo, useState } from 'react';
import { Column } from 'react-table';
import styles from './PreviousPromotions.module.css';
import { InfluencerPlan } from '../../../../../../App.types';
import { OptionsContext } from '../../../../../../contexts/OptionsContext/OptionsContext';
import { displayOptionalNumericTableValue, formatDateShort, numberWithCommas } from '../../../../../../helpers';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import PaginationTable from '../../../../../../ui/PaginationTable/PaginationTable';
import { InstagramInfluencerPost } from '../../../../../Advertising/InfluencerPlan/types/Instagram.types';
import { PostStatsWithData } from '../Promotions';
type PreviousPromotionsTableProps = {
    loading: boolean;
    errorLoading: boolean;
    posts: InstagramInfluencerPost[];
    results: PostStatsWithData[];
    influencerPlans: InfluencerPlan[];
};

type PreviousPromotionsTableRow = {
    campaignBrand: string;
    campaignRelease: string;
    campaignId: number | null;
    datePosted: string | null;
    cost: string | null;
    videoPlays: number | null;
    likes: number | null;
    comments: number | null;
};

const PreviousPromotions = ({
    posts,
    results,
    loading,
    errorLoading,
    influencerPlans,
}: PreviousPromotionsTableProps) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { currencies } = useNonNullContext(OptionsContext);

    const rows: PreviousPromotionsTableRow[] = useMemo(
        () =>
            posts
                .filter((post) => !!post.post)
                .map((post) => {
                    const result = results.find((result) => result.post_id === post.post);
                    const plan = influencerPlans.find((plan) => plan.id === post.plan_id);
                    const postCurrency = currencies.find((currency) => currency.id === post.currency_id);
                    return {
                        campaignBrand: plan?.release.brand.name ?? '-',
                        campaignRelease: plan?.release.name ?? '-',
                        campaignId: plan?.release.id ?? null,
                        datePosted: result?.create_time ?? null,
                        cost:
                            typeof post.cost !== 'undefined'
                                ? `${postCurrency?.symbol || ''}${numberWithCommas(post.cost)}`
                                : '-',
                        videoPlays: result?.play_count ?? null,
                        likes: result?.like_count ?? null,
                        comments: result?.comment_count ?? null,
                    };
                }),
        [currencies, influencerPlans, posts, results]
    );

    const columns = useMemo<Column<PreviousPromotionsTableRow>[]>(
        () => [
            {
                Header: 'Campaign Brand',
                accessor: 'campaignBrand',
                Cell: ({ value, row: { original } }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <a href={`/campaigns/${original.campaignId}/creators/`} target={'_blank'} rel={'noreferrer'}>
                            {value}
                        </a>
                    );
                },
            },
            {
                Header: 'Campaign Release',
                accessor: 'campaignRelease',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Date Posted',
                accessor: 'datePosted',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value ? formatDateShort(value) : '-'}</>;
                },
            },
            {
                Header: 'Cost',
                accessor: 'cost',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Video Plays',
                accessor: 'videoPlays',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{displayOptionalNumericTableValue(value)}</>;
                },
            },
            {
                Header: 'Likes',
                accessor: 'likes',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{displayOptionalNumericTableValue(value)}</>;
                },
            },
            {
                Header: 'Comments',
                accessor: 'comments',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{displayOptionalNumericTableValue(value)}</>;
                },
            },
        ],
        [loading]
    );

    return (
        <div className={styles.container}>
            <div>
                <p className={styles.heading}>Previous promotions</p>
                <p className={styles.subheading}>all time</p>
            </div>
            <PaginationTable
                tableClassName={styles.table}
                loading={loading}
                manualPagination={false}
                noDataLabel={errorLoading ? "Sorry, we're experiencing technical issues" : 'No previous promotions'}
                data={rows}
                columns={columns}
                count={rows.length}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={(pageSize) => setPageSize(pageSize ?? 10)}
            />
        </div>
    );
};
export default PreviousPromotions;
