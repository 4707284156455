import { useMemo, useState } from 'react';
import { ChartProps, Line } from 'react-chartjs-2';
import { createPortal } from 'react-dom';
import tooltipStyles from 'ui-new/whitelabel/Tooltip/Tooltip.module.css';
import commonStyles from '../Charts.module.css';
import cn from 'classnames';
import { Point } from '../types';
import LoadingSpinner from 'SharedComponents/LoadingSpinner/LoadingSpinner';
import moment from 'moment';

type Props = {
    points: Point[];
    height?: number | string;
    width?: number | string;
    lineColor?: string;
    renderTooltip?: (item: Point) => React.ReactNode;
    isLoading?: boolean;
    flatLineOnEmpty?: boolean;
    emptyChartLineColor?: string;
};

const TOOLTIP_WIDTH_IN_PX = 225;
const MiniLineChart = ({
    points,
    height,
    width,
    lineColor,
    renderTooltip,
    isLoading,
    flatLineOnEmpty,
    emptyChartLineColor = '#E0E0E0',
}: Props) => {
    const [tooltipParams, setTooltipParams] = useState<{ x: number; y: number; transformX: number } | null>(null);
    const [tooltipItem, setTooltipItem] = useState<Point | null>(null);
    //If there is no chart data, we will create an array of "empty" points for the last 14 days to display a flat line
    const chartPoints =
        points.length === 0 && flatLineOnEmpty
            ? new Array(14).fill(undefined).map((_, i) => ({
                  x: moment()
                      .subtract(i + 1)
                      .toISOString(),
                  y: 0,
              }))
            : points;

    const data: ChartProps<'line', Point[]>['data'] = {
        datasets: [
            {
                data: chartPoints,
                tension: 0.2,
                borderWidth: 2,
                borderColor: points.length === 0 && flatLineOnEmpty ? emptyChartLineColor : lineColor,
            },
        ],
    };

    const options: ChartProps<'line', Point[]>['options'] = useMemo(
        () => ({
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    position: 'nearest',
                    external({ tooltip, chart }) {
                        if (tooltip.opacity === 0) {
                            setTooltipParams(null);
                            setTooltipItem(null);
                        } else {
                            const position = chart.canvas.getBoundingClientRect();
                            let left = position.left + tooltip.caretX;
                            let transform = -75;

                            setTooltipParams({
                                x: left,
                                y: position.top + tooltip.caretY,
                                transformX: transform,
                            });

                            if (Array.isArray(tooltip.dataPoints) && tooltip.dataPoints.length) {
                                const point = tooltip.dataPoints[0];
                                const item = points[point.dataIndex];
                                setTooltipItem(item ?? null);
                            }
                        }
                    },
                },
            },
            elements: {
                point: {
                    radius: 0.01,
                    hoverRadius: 6,
                    hitRadius: 4,
                },
            },
            scales: {
                y: {
                    grid: {
                        display: false,
                    },
                    border: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                        count: 5,
                    },
                },
                x: {
                    type: 'time',
                    time: {
                        unit: 'day',
                    },
                    grid: {
                        display: false,
                    },
                    border: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
            },
            maintainAspectRatio: false,
            animation: false,
        }),
        [points]
    );

    if (isLoading) {
        return <LoadingSpinner containerClassName={commonStyles.loadingSpinnerContainer} />;
    }

    return (
        <>
            <Line height={height} width={width} data={data} options={options} />
            {tooltipParams &&
                tooltipItem &&
                typeof renderTooltip === 'function' &&
                createPortal(
                    <div
                        className={cn(tooltipStyles.tooltip, commonStyles.root)}
                        style={{
                            position: 'fixed',
                            zIndex: 20,
                            left: tooltipParams.x + 'px',
                            top: tooltipParams.y + 'px',
                            transform: `translate(${tooltipParams.transformX}%, -115%)`,
                            minWidth: TOOLTIP_WIDTH_IN_PX,
                            width: 'max-content',
                        }}
                    >
                        {renderTooltip(tooltipItem)}
                    </div>,
                    document.body
                )}
        </>
    );
};

export default MiniLineChart;
