import { Props } from 'react-select';
import { InferPaginatedFetchFunctionModel, PaginatedFetchFunction, usePaginatedFetch } from '@round/api';
import useAbortableEffect from './useAbortableEffect';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { GenericDropdownOption } from '../App.types';

type UseSelectPaginatedOptionsResult<OptionType extends GenericDropdownOption<any>, T> = {
    props: Pick<Props<OptionType>, 'inputValue' | 'onInputChange' | 'isLoading' | 'onMenuScrollToBottom'> & {
        options: ReadonlyArray<OptionType>;
    };
    data: T[];
    reset: () => void;
};

type UseSelectPaginatedOptionsParams<
    OptionType extends GenericDropdownOption<any> = GenericDropdownOption<any>,
    Function extends PaginatedFetchFunction<any, any> = PaginatedFetchFunction<any, any>
> = {
    fetchOptions: Function;
    mapToOption: (item: InferPaginatedFetchFunctionModel<Function>) => OptionType;
};

export function useSelectPaginatedOptions<
    OptionType extends GenericDropdownOption<any> = GenericDropdownOption<any>,
    Function extends PaginatedFetchFunction<any, any> = PaginatedFetchFunction<any, any>
>(
    params: UseSelectPaginatedOptionsParams<OptionType, Function>
): UseSelectPaginatedOptionsResult<OptionType, InferPaginatedFetchFunctionModel<Function>> {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const { data, hasNextPage, getCurrentPage, loading, reset: resetPaginatedFetch } = usePaginatedFetch(
        params.fetchOptions,
        {
            page: page,
            search,
            page_size: 10,
        }
    );

    const options = useMemo(() => data.map(params.mapToOption), [data, params.mapToOption]);

    const fetchDebounced = useMemo(
        () =>
            debounce((requestInit?: RequestInit) => {
                getCurrentPage(requestInit).then(() => setSearchLoading(false));
            }, 700),
        [getCurrentPage]
    );

    useAbortableEffect(
        (signal) => {
            fetchDebounced({ signal });
        },
        [fetchDebounced]
    );

    return {
        props: {
            options,
            isLoading: loading || searchLoading,
            inputValue: search,
            onInputChange: (searchValue) => {
                if (searchValue === search) {
                    return;
                }
                setSearchLoading(true);
                setSearch(searchValue);
                setPage(1);
            },
            onMenuScrollToBottom: () => {
                if (hasNextPage) {
                    setPage((page) => page + 1);
                }
            },
        },
        data,
        reset: () => {
            setPage(1);
            setSearch('');
            resetPaginatedFetch();
            fetchDebounced();
        },
    };
}
