import React from 'react';
import { microwave } from '@round/api';
import styles from './SubmissionTracker.module.css';
import cn from 'classnames';

import { submissionTrackerStages } from './SubmissionStages';

type Props = {
    formData?: microwave.TiktokPostInviteForm;
};

const SubmissionTracker = ({ formData }: Props) => {
    const isPaymentComplete = !!formData?.payment_date;

    const stages = submissionTrackerStages.filter((stage) => stage.shouldRenderStage(formData));

    return (
        <article className={styles.container}>
            {stages.map((stage, index) => {
                const isFirstStage = index === 0;
                const isLastStage = index === stages.length - 1;

                return (
                    <React.Fragment key={index}>
                        {!isFirstStage && (
                            <>
                                {isLastStage && !isPaymentComplete ? (
                                    <div className={cn(styles.line, styles.grey)} />
                                ) : (
                                    <div className={cn(styles.line, styles.green)} />
                                )}
                            </>
                        )}

                        {stage.render(formData)}
                    </React.Fragment>
                );
            })}
        </article>
    );
};

export default SubmissionTracker;
