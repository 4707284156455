import React from 'react';
import styles from './PageContentCardContainer.module.css';
import Card from '../../Card/Card';
import { FCWithChildren } from '../../../utility/utility.types';
import cn from 'classnames';

const PageContentCardContainer: FCWithChildren<{ className?: string }> = ({ children, className }) => (
    <Card className={cn(styles.container, className)}>{children}</Card>
);

export default PageContentCardContainer;
