import { Image, Skeleton } from '@round/ui-kit';
import styles from './ReleaseBadge.module.css';

type Props = {
    releaseName: string;
    artistName: string;
    imageUrl: string;
    linkUrl?: string;
    isLoading: boolean;
};

const ReleaseBadge = ({ releaseName, artistName, imageUrl, linkUrl, isLoading }: Props) => {
    return (
        <div className={styles.container}>
            <Image loading={isLoading} src={imageUrl ?? ''} className={styles.image} />
            <div className={styles.titles}>
                <a className={styles.title} href={linkUrl} target="_blank" rel="noreferrer">
                    {isLoading ? <Skeleton /> : releaseName}
                </a>
                <p className={styles.subTitle}>{isLoading ? <Skeleton /> : artistName}</p>
            </div>
        </div>
    );
};

export default ReleaseBadge;
