import { AppServer, RouteHandlerContext } from '../../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AdminManager } from './manager.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';

export const models = {
    adminManager: Model as ModelDefinition<AdminManager>,
};

export const serializers = {
    adminManager: makeSerializer<AdminManager>([]),
};

export const factories = {
    adminManager: createFactory<AdminManager>({
        username(index: number) {
            return `username ${index}`;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const getAdminManagersListUrl = '/api/apex/admin/manager/';
    server.get(getAdminManagersListUrl, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const managers = schema
            .all('adminManager')
            .filter((manager) => !search || manager.username.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(managers, {
            url: getAdminManagersListUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'adminManager'),
        });
    });

    server.get('/api/apex/admin/manager/:id/', function (this: RouteHandlerContext, schema, request) {
        const manager = schema.find('adminManager', request.params.id);
        if (!manager) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return manager;
    });
}
