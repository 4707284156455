import * as genresMirage from './genres/genres.mirage';
import { AppServer } from '../mirage';

export const models = {
    ...genresMirage.models,
};

export const serializers = {
    ...genresMirage.serializers,
};

export const factories = {
    ...genresMirage.factories,
};

export function handleRequests(server: AppServer) {
    genresMirage.handleRequests(server);
}
