import React from 'react';
import styles from './TitleCell.module.css';
import Skeleton from '../../../ui/Skeleton/Skeleton';

type TitleCellProps = {
    title: string;
    subtitle: string;
    loading?: boolean;
};

const TitleCell = ({ title, subtitle, loading }: TitleCellProps) => {
    return (
        <div>
            <p className={styles.title}>{loading ? <Skeleton /> : title}</p>
            <p className={styles.subtitle}>{loading ? <Skeleton width="70%" /> : subtitle}</p>
        </div>
    );
};

export default TitleCell;
