import React, { useCallback, useEffect, useState } from 'react';
import useNonNullContext from '../../../../../../../../Hooks/useNonNullContext';
import { MediaPlanOptionsContext } from '../../../../../contexts/MediaPlanOptionsContext/MediaPlanOptionsContext';
import {
    FacebookPositions,
    FacebookTargeting,
    FacebookTargetingAudienceSelectionValues,
    FacebookTargetingLocationSearchOption,
} from '../../../../../../../../App.types';
import * as Yup from 'yup';
import { CustomAudienceOption } from '../../../../../types/Facebook.types';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import FacebookTargetingCustomAudience from '../FacebookTargetingCustomAudience/FacebookTargetingCustomAudience';
import FacebookTargetingLocationsInput from '../FacebookTargetingLocations/FacebookTargetingLocationsInput';
import FacebookDetailedTargetingInput from '../FacebookDetailedTargeting/FacebookDetailedTargetingInput';
import MultiSelect from '../../../../../../../../SharedComponents/Forms/MultiSelect/MultiSelect';
import TextArea from '../../../../../../../../SharedComponents/Forms/TextArea/TextArea';
import Button from '../../../../../../../../ui/Buttons/Button/Button';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../../../../ui/General/Modal/Modal';
import FormField from '../../../../../../../../ui/DataEntry/FormField/FormField';
import Label from '../../../../../../../../ui/DataEntry/Label/Label';
import styles from './FacebookTargetingModalForm.module.css';
import cn from 'classnames';
import FacebookTargetingAudienceReach from '../FacebookTargetingAudienceReach/FacebookTargetingAudienceReach';
import { fetchWithToken } from '../../../../../../../../helpers';
import { StylesConfig } from 'react-select';
import {
    mapFacebookTargetingLocationsToBody,
    mapSelectionOptionsToSpecs,
} from '../../../../../helpers/Facebook.helpers';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';

const multiSelectStyles: StylesConfig = {
    control: (provided) => ({
        ...provided,
        borderColor: '#C2CFE0',
        fontSize: '0.75rem',
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingTop: '0.3rem',
        paddingBottom: '0.3rem',
    }),
    multiValue: (provided) => ({
        ...provided,
        color: '#192A3E',
        backgroundColor: '#E7EEF6',
    }),
    input: (provided) => ({
        ...provided,
        marginTop: '0',
        paddingTop: '0',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#90A0B7',
        fontSize: '0.75rem',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 1000,
    }),
};

type FacebookTargetingModalFormProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    initialValues: TargetingFormProps;
    onDelete: () => void;
    onSubmit: (targeting: FacebookTargeting) => void;
    loading?: boolean;
    targeting: FacebookTargeting;
};

export type TargetingFormProps = {
    name: string;
    minAge: number;
    maxAge: number;
    location: string;
    isMale: boolean;
    isFemale: boolean;
    remarketingAudience: string;
    keywords: string;
    devicePlatforms: number[];
    publisherPlatforms: number[];
    facebookPositions: number[];
    instagramPositions: number[];
    messengerPositions: number[];
    audienceNetworkPositions: number[];
    geoLocations: FacebookTargetingLocationSearchOption[];
    detailedTargeting: FacebookTargetingAudienceSelectionValues;
    customAudiences: {
        included: CustomAudienceOption[];
        excluded: CustomAudienceOption[];
    };
    notes: string;
    estimatedReach: FacebookTargeting['estimated_reach'];
};

const FacebookTargetingModalForm = ({
    isModalOpen,
    closeModal,
    loading,
    onDelete,
    onSubmit,
    initialValues,
    targeting,
}: FacebookTargetingModalFormProps) => {
    const featureFlags = useFeatureFlags();

    const [hasError, setHasError] = useState(false);
    const [showDiscardChangesPrompt, setShowDiscardChangesPrompt] = useState(false);
    const [audienceReachRef, setAudienceReachRef] = useState<HTMLDivElement | null>(null);
    const [modalRef, setModalRef] = useState<HTMLDivElement | null>(null);
    const [stickAudienceReachToTop, setStickAudienceReachToTop] = useState(false);
    const {
        facebookDeviceOptions,
        facebookPublisherPlatformOptions,
        facebookPositionOptions,
        instagramPositionOptions,
        messengerPositionOptions,
        audienceNetworkPositionOptions,
    } = useNonNullContext(MediaPlanOptionsContext);

    const positionEnabled = useCallback(
        (position: FacebookPositions, selectedPublisherPlatforms: number[]) => {
            const positionsPublisherPlatform = facebookPublisherPlatformOptions.find(
                (platform) => platform.value === position
            );
            return !!(positionsPublisherPlatform && selectedPublisherPlatforms.includes(positionsPublisherPlatform.id));
        },
        [facebookPublisherPlatformOptions]
    );

    const getConditionalPositionValidation = (position: FacebookPositions) => {
        return (publisherPlatforms: number[]) => {
            if (positionEnabled(position, publisherPlatforms)) {
                return Yup.array().of(Yup.number()).min(1, 'Field is required');
            }

            return Yup.array();
        };
    };

    const validationSchema = Yup.object({
        devicePlatforms: Yup.array().of(Yup.number()).min(1, 'Field is required'),
        publisherPlatforms: Yup.array().of(Yup.number()).min(1, 'Field is required'),
        facebookPositions: Yup.array().when('publisherPlatforms', getConditionalPositionValidation('facebook')),
        instagramPositions: Yup.array().when('publisherPlatforms', getConditionalPositionValidation('instagram')),
        messengerPositions: Yup.array().when('publisherPlatforms', getConditionalPositionValidation('messenger')),
        audienceNetworkPositions: Yup.array().when(
            'publisherPlatforms',
            getConditionalPositionValidation('audience_network')
        ),
    });

    const handleSubmit = useCallback(
        async (values: TargetingFormProps, { setSubmitting, resetForm }: FormikHelpers<TargetingFormProps>) => {
            setSubmitting(true);
            setHasError(false);
            const response = await fetchWithToken(
                `/api/facebook/viewsets/facebook-campaign-targeting/${targeting.id}/`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(
                        mapValuesToTargeting(targeting.media_plan_item, {
                            ...values,
                            messengerPositions: positionEnabled('messenger', values.publisherPlatforms)
                                ? values.messengerPositions
                                : [],
                            audienceNetworkPositions: positionEnabled('audience_network', values.publisherPlatforms)
                                ? values.audienceNetworkPositions
                                : [],
                            instagramPositions: positionEnabled('instagram', values.publisherPlatforms)
                                ? values.instagramPositions
                                : [],
                            facebookPositions: positionEnabled('facebook', values.publisherPlatforms)
                                ? values.facebookPositions
                                : [],
                        })
                    ),
                }
            );
            if (response.ok) {
                onSubmit(await response.json());
                setSubmitting(false);
                resetForm({ values });
            } else {
                setHasError(true);
            }
        },
        [onSubmit, positionEnabled, targeting.id, targeting.media_plan_item]
    );

    useEffect(() => {
        const modal = modalRef;
        const audienceReach = audienceReachRef;
        const handler = () => {
            if (!modal || !audienceReach) {
                return;
            }

            const audienceReachRect = audienceReach.getBoundingClientRect();
            const shouldStick = modal.scrollTop >= audienceReachRect.height;
            setStickAudienceReachToTop(shouldStick);
        };

        modal?.addEventListener('scroll', handler);
        return () => modal?.removeEventListener('scroll', handler);
    }, [audienceReachRef, modalRef]);

    return (
        <Formik
            enableReinitialize
            validateOnMount
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {({ dirty, values, isSubmitting, resetForm, submitForm }) => {
                const selectedPublisherPlatforms = values.publisherPlatforms;
                return (
                    <>
                        <Modal
                            closeOnOverlayClick
                            isOpen={showDiscardChangesPrompt}
                            onClose={() => setShowDiscardChangesPrompt(false)}
                        >
                            <ModalTitle>Discard changes?</ModalTitle>
                            <ModalContent className={styles.discardChangesContent}>
                                <p>This operation cannot be undone</p>
                            </ModalContent>
                            <ModalFooter>
                                <Button
                                    type="filled"
                                    color="black"
                                    onClick={() => {
                                        resetForm();
                                        setShowDiscardChangesPrompt(false);
                                        closeModal();
                                    }}
                                >
                                    Discard
                                </Button>
                            </ModalFooter>
                        </Modal>

                        <Modal
                            closeOnOverlayClick
                            isOpen={isModalOpen}
                            onClose={() => {
                                if (!dirty) {
                                    closeModal();
                                    return;
                                }

                                setShowDiscardChangesPrompt(true);
                            }}
                        >
                            <ModalTitle>Edit Facebook Targeting</ModalTitle>
                            <ModalContent loading={loading}>
                                <div ref={setModalRef} className={styles.modalContent}>
                                    {hasError && <div>Error creating targeting</div>}
                                    <FacebookTargetingAudienceReach
                                        className={cn(styles.audienceReach, {
                                            [styles.stickToTop]: stickAudienceReachToTop,
                                        })}
                                        reach={values.estimatedReach}
                                        innerRef={setAudienceReachRef}
                                        needsUpdate={dirty}
                                        loading={isSubmitting}
                                    />
                                    <Form className={styles.form}>
                                        <FormField>
                                            <Label htmlFor="name">Name</Label>
                                            <Field name="name" id="name" placeholder="Enter name" />
                                        </FormField>
                                        <div className={cn(styles.row, styles.evenFields)}>
                                            <FormField>
                                                <Label htmlFor="minAge">Min Age</Label>
                                                <Field
                                                    type="number"
                                                    id="minAge"
                                                    name="minAge"
                                                    placeholder="Enter min age"
                                                />
                                            </FormField>
                                            <FormField>
                                                <Label htmlFor="maxAge">Max Age</Label>
                                                <Field
                                                    type="number"
                                                    id="maxAge"
                                                    name="maxAge"
                                                    placeholder="Enter max age"
                                                />
                                            </FormField>
                                        </div>
                                        {featureFlags.isEnabled('fb_custom_audience_targeting') && (
                                            <FormField>
                                                <Label htmlFor="customAudiences">Custom Audiences</Label>
                                                <Field name="customAudiences" id="customAudiences">
                                                    {({ form, field, meta }: FieldProps) => (
                                                        <FacebookTargetingCustomAudience
                                                            onChange={(customAudiences) =>
                                                                form.setFieldValue(field.name, customAudiences)
                                                            }
                                                            value={field.value}
                                                            error={meta.error}
                                                        />
                                                    )}
                                                </Field>
                                            </FormField>
                                        )}
                                        <FormField>
                                            <Label htmlFor="geoLocations">Location</Label>
                                            <Field name="geoLocations" id="geoLocations">
                                                {({ form, field, meta }: FieldProps) => (
                                                    <FacebookTargetingLocationsInput
                                                        onChange={(l: FacebookTargetingLocationSearchOption[]) =>
                                                            form.setFieldValue(field.name, l)
                                                        }
                                                        locations={field.value}
                                                        error={meta.error}
                                                    />
                                                )}
                                            </Field>
                                        </FormField>
                                        <FormField>
                                            <Label htmlFor="detailedTargeting">Detailed Targeting</Label>
                                            <Field name="detailedTargeting" id="detailedTargeting">
                                                {({ form, field, meta }: FieldProps) => (
                                                    <FacebookDetailedTargetingInput
                                                        onChange={(s: FacebookTargetingAudienceSelectionValues) => {
                                                            form.setFieldValue(field.name, s);
                                                        }}
                                                        value={field.value}
                                                        error={meta.error}
                                                    />
                                                )}
                                            </Field>
                                        </FormField>
                                        <div>
                                            <Label htmlFor="">Gender</Label>
                                            <div className={styles.row}>
                                                <FormField className={cn(styles.row, styles.inline)}>
                                                    <Field type="checkbox" name="isMale" />
                                                    <Label htmlFor="isMale">Male</Label>
                                                </FormField>
                                                <FormField className={cn(styles.row, styles.inline)}>
                                                    <Field type="checkbox" name="isFemale" />
                                                    <Label htmlFor="isFemale">Female</Label>
                                                </FormField>
                                            </div>
                                        </div>
                                        <FormField>
                                            <Label htmlFor="remarketingAudience">Remarketing Audiences</Label>
                                            <Field
                                                name="remarketingAudience"
                                                id="remarketingAudience"
                                                placeholder="Enter remarketing audience"
                                            />
                                        </FormField>
                                        <FormField>
                                            <Label htmlFor="devicePlatforms">Device Platforms</Label>
                                            <MultiSelect
                                                name="devicePlatforms"
                                                optionLabel="name"
                                                optionValue="id"
                                                options={facebookDeviceOptions}
                                                stylesConfig={multiSelectStyles}
                                            />
                                        </FormField>
                                        <FormField>
                                            <Label htmlFor="publisherPlatforms">Publisher Platforms</Label>
                                            <MultiSelect
                                                name="publisherPlatforms"
                                                optionLabel="name"
                                                optionValue="id"
                                                options={facebookPublisherPlatformOptions}
                                                stylesConfig={multiSelectStyles}
                                            />
                                        </FormField>
                                        {positionEnabled('facebook', selectedPublisherPlatforms) && (
                                            <FormField>
                                                <Label htmlFor="facebookPositions">Facebook Positions</Label>
                                                <MultiSelect
                                                    options={facebookPositionOptions}
                                                    name="facebookPositions"
                                                    optionLabel="name"
                                                    optionValue="id"
                                                    stylesConfig={multiSelectStyles}
                                                />
                                            </FormField>
                                        )}
                                        {positionEnabled('instagram', selectedPublisherPlatforms) && (
                                            <FormField>
                                                <Label htmlFor="instagramPositions">Instagram Positions</Label>
                                                <MultiSelect
                                                    options={instagramPositionOptions}
                                                    name="instagramPositions"
                                                    optionLabel="name"
                                                    optionValue="id"
                                                    stylesConfig={multiSelectStyles}
                                                />
                                            </FormField>
                                        )}
                                        {positionEnabled('messenger', selectedPublisherPlatforms) && (
                                            <FormField>
                                                <Label htmlFor="messengerPositions">Messenger Positions</Label>
                                                <MultiSelect
                                                    options={messengerPositionOptions}
                                                    name="messengerPositions"
                                                    optionLabel="name"
                                                    optionValue="id"
                                                    stylesConfig={multiSelectStyles}
                                                />
                                            </FormField>
                                        )}
                                        {positionEnabled('audience_network', selectedPublisherPlatforms) && (
                                            <FormField>
                                                <Label htmlFor="audienceNetworkPositions">
                                                    Audience Network Positions
                                                </Label>
                                                <MultiSelect
                                                    options={audienceNetworkPositionOptions}
                                                    name="audienceNetworkPositions"
                                                    optionLabel="name"
                                                    optionValue="id"
                                                    stylesConfig={multiSelectStyles}
                                                />
                                            </FormField>
                                        )}
                                        <FormField>
                                            <Label htmlFor="notes">Notes</Label>
                                            <TextArea name="notes" placeholder="Write notes" />
                                        </FormField>
                                    </Form>
                                </div>
                            </ModalContent>
                            <ModalFooter>
                                <Button
                                    className={styles.submit}
                                    disabled={!dirty}
                                    loading={isSubmitting}
                                    type="filled"
                                    color="black"
                                    onClick={submitForm}
                                >
                                    Save Targeting
                                </Button>

                                <Button type="filled" color="negative" onClick={onDelete}>
                                    Delete
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </>
                );
            }}
        </Formik>
    );
};

function mapValuesToTargeting(mediaPlanItemId: number, values: TargetingFormProps) {
    return {
        media_plan_item: mediaPlanItemId,
        name: values.name,
        min_age: values.minAge,
        max_age: values.maxAge,
        location: values.location,
        geo_locations: mapFacebookTargetingLocationsToBody(
            values.geoLocations.filter((location) => location.isIncluded)
        ),
        excluded_geo_locations: mapFacebookTargetingLocationsToBody(
            values.geoLocations.filter((location) => !location.isIncluded)
        ),
        is_male: values.isMale,
        is_female: values.isFemale,
        remarketing_audience: values.remarketingAudience,
        keywords: values.keywords,
        device_platforms: values.devicePlatforms,
        publisher_platforms: values.publisherPlatforms,
        facebook_positions: values.facebookPositions,
        instagram_positions: values.instagramPositions,
        messenger_positions: values.messengerPositions,
        audience_network_positions: values.audienceNetworkPositions,
        notes: values.notes,
        flexible_spec: [
            mapSelectionOptionsToSpecs(values.detailedTargeting.include),
            mapSelectionOptionsToSpecs(values.detailedTargeting.narrow),
        ].filter((selection) => !!selection),
        exclusions: mapSelectionOptionsToSpecs(values.detailedTargeting.exclude) ?? {},
        custom_audiences: values.customAudiences.included.map((a) => a.value),
        excluded_custom_audiences: values.customAudiences.excluded.map((a) => a.value),
    };
}

export default FacebookTargetingModalForm;
