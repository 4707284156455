import styles from './MobileCreators.module.css';
import { useCallback, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { InstagramCreatorsContext } from '../../contexts/InstagramCreatorsContext/InstagramCreatorsContext';
import Tabs, { TabNav, TabNavItem, TabPanel, TabPanels } from '../../../../../ui/DataDisplay/Tabs/Tabs';
import { ReactComponent as InstagramIcon } from '../../assets/Instagram.svg';
import { ReactComponent as TiktokIcon } from '../../assets/Tiktok.svg';
import { InfluencerPlanContext } from '../../contexts/InfluencerPlanContext';
import { Spinner } from '@round/ui-kit';
import EmptyPlan from '../../../components/EmptyPlan/EmptyPlan';
import TiktokCreatorsTab from './components/CreatorsTabs/TiktokCreatorsTab';
import InstagramCreatorsTab from './components/CreatorsTabs/InstagramCreatorsTab';
import useTiktokInfluencerPostGroups from '../../contexts/TiktokCreatorsContext/useTiktokInfluencerPostGroups';

type CreatorsPlatform = 'instagram' | 'tiktok';
const isPlatform = (platform: string | null): platform is CreatorsPlatform => {
    return platform === 'instagram' || platform === 'tiktok';
};

const MobileCreators = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { loading: influencerPlanLoading, influencerPlan } = useContext(InfluencerPlanContext);

    const {
        groupsInitialized: tiktokInfluencerPostGroupsInitialized,
        groups: tiktokInfluencerPostGroups,
    } = useTiktokInfluencerPostGroups();

    const { instagramInfluencerPostGroups, isPrefetchCompleted: isInstagramPrefetchCompleted } = useNonNullContext(
        InstagramCreatorsContext
    );

    const platformHash = location.hash.replace('#', '');
    const getPlatform = (platform: string) => {
        if (!isPlatform(platform)) {
            return null;
        }
        return platform;
    };

    const activePlatformTab = getPlatform(platformHash);

    const handleSetPlatform = useCallback(
        (platform: CreatorsPlatform | null) => {
            if (!platform) {
                return;
            }
            navigate(`#${platform}`, { replace: true });
        },
        [navigate]
    );

    useEffect(() => {
        if (platformHash || !tiktokInfluencerPostGroupsInitialized || !isInstagramPrefetchCompleted) {
            return;
        }

        const defaultPlatform =
            !tiktokInfluencerPostGroups.length && instagramInfluencerPostGroups.length ? 'instagram' : 'tiktok';

        handleSetPlatform(defaultPlatform);
    }, [
        platformHash,
        handleSetPlatform,
        location.hash,
        isInstagramPrefetchCompleted,
        tiktokInfluencerPostGroups.length,
        instagramInfluencerPostGroups.length,
        tiktokInfluencerPostGroupsInitialized,
    ]);

    if (influencerPlanLoading) {
        return <Spinner />;
    }

    if (!influencerPlan) {
        return <EmptyPlan readOnly />;
    }

    return (
        <>
            <Tabs activeTab={activePlatformTab} onChange={handleSetPlatform}>
                <TabNav>
                    <TabNavItem name="instagram">
                        <InstagramIcon className={styles.tabIcon} /> Instagram
                    </TabNavItem>
                    <TabNavItem name="tiktok">
                        <TiktokIcon className={styles.tabIcon} /> Tiktok
                    </TabNavItem>
                </TabNav>

                <TabPanels>
                    <TabPanel name="instagram">
                        <div className={styles.tabContainer}>
                            <InstagramCreatorsTab />
                        </div>
                    </TabPanel>

                    <TabPanel name="tiktok">
                        <div className={styles.tabContainer}>
                            <TiktokCreatorsTab />
                        </div>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default MobileCreators;
