import React, { useState } from 'react';
import useInstagramInfluencerPlanAudios from '../../contexts/InstagramCreatorsContext/useInstagramInfluencerPlanAudios';
import BadgeDropdown from '../../components/BadgeDropdown/BadgeDropdown';
import { ReactComponent as MusicNoteIcon } from 'assets/MusicNote.svg';
import { ReactComponent as TrashIcon } from 'assets/TrashIcon.svg';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import styles from './InstagramInfluencerPlanAudios.module.css';
import { Button, Modal, ModalFooter, ModalTitle } from '@round/ui-kit';
import AddAudioModal from './AddAudio/AddAudio';
import cn from 'classnames';
import { InstagramAudio } from '@round/api';
import { showNotification } from '../../../../../helpers';
import useUrlState from '../../../../../Hooks/useUrlState';
import InstagramAudioModal from '../../../../Instagram/InstagramAudioDetails/InstagramAudioModal';

type Props = {
    isReadonly?: boolean;
};

type UrlState = {
    igAudio?: number;
};

const getAudioTitle = (audio: InstagramAudio | null) => {
    if (!audio) {
        return '';
    }

    return audio.title ?? audio.instagram_id;
};

const InstagramInfluencerPlanAudios = ({ isReadonly }: Props) => {
    const [urlState, setUrlState] = useUrlState<UrlState>();
    const isAudioDetailsModalOpen = Boolean(urlState.igAudio);

    const { audios, error, isLoading, init, isInitialized, deleteAudio } = useInstagramInfluencerPlanAudios();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [audioToDelete, setAudioToDelete] = useState<InstagramAudio | null>(null);
    const [isDeleteAudioLoading, setIsDeleteAudioLoading] = useState(false);

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                init({ signal });
            }
        },
        [isInitialized, init]
    );

    const handleDeleteAudio = async () => {
        if (!audioToDelete) {
            return;
        }

        try {
            setIsDeleteAudioLoading(true);
            await deleteAudio(audioToDelete.id);
            showNotification('Deleted', 'info');
            setAudioToDelete(null);
        } catch (e) {
            if (e instanceof Error) {
                showNotification(e.message, 'error');
                return;
            }

            showNotification('Could not delete audio', 'error');
        } finally {
            setIsDeleteAudioLoading(false);
        }
    };

    return (
        <>
            <BadgeDropdown
                loading={isLoading || !isInitialized}
                value={audios.length}
                label="Audios"
                Icon={MusicNoteIcon}
            >
                {(setShow) => (
                    <>
                        {!isReadonly && (
                            <Button
                                className={styles.addAudioButton}
                                type="link"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setIsAddModalOpen(true);
                                    setShow(false);
                                }}
                            >
                                + Add audio
                            </Button>
                        )}

                        {isInitialized && !audios.length && !error && (
                            <div className={styles.error}>No audios found</div>
                        )}
                        {isInitialized && error && <div className={styles.error}>{error}</div>}
                        {isInitialized && (
                            <ul className={styles.list}>
                                {audios.map((audio) => (
                                    <li
                                        key={audio.id}
                                        className={styles.listItem}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setUrlState({ igAudio: audio.id });
                                            setShow(false);
                                        }}
                                    >
                                        <span className={cn(styles.audio, { [styles.pending]: !audio.title })}>
                                            {getAudioTitle(audio)}
                                        </span>
                                        {!isReadonly && (
                                            <Button
                                                className={styles.deleteButton}
                                                color="negative"
                                                type="link"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setShow(false);
                                                    setAudioToDelete(audio);
                                                }}
                                            >
                                                <TrashIcon className={styles.deleteIcon} />
                                            </Button>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </>
                )}
            </BadgeDropdown>

            <AddAudioModal isOpen={isAddModalOpen} closeModal={() => setIsAddModalOpen(false)} />

            <Modal
                className={styles.modal}
                closeOnOverlayClick
                isOpen={!!audioToDelete}
                onClose={() => setAudioToDelete(null)}
            >
                <ModalTitle>Delete "{getAudioTitle(audioToDelete)}"?</ModalTitle>
                <ModalFooter align="center">
                    <Button onClick={() => setAudioToDelete(null)}>Cancel</Button>
                    <Button type="filled" color="black" loading={isDeleteAudioLoading} onClick={handleDeleteAudio}>
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>

            <InstagramAudioModal
                isModalOpen={isAudioDetailsModalOpen}
                closeModal={() => setUrlState({ igAudio: undefined })}
                audioId={Number(urlState.igAudio)}
                audio={audios.find((a) => a.id === Number(urlState.igAudio))}
            />
        </>
    );
};

export default InstagramInfluencerPlanAudios;
