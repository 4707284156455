import { InvoiceRequestStatus } from '@round/api';
import cn from 'classnames';
import styles from './StatusBadge.module.css';
import { invoiceRequestStatusOptions } from 'Modules/Finance/InvoiceRequests/helpers';
import { Badge } from '@round/ui-kit';
import React from 'react';

type Props = {
    status: InvoiceRequestStatus;
};

const StatusBadge = ({ status }: Props) => (
    <Badge className={cn(styles.badge, styles[status.toLowerCase()])}>
        {invoiceRequestStatusOptions.find((o) => o.value === status)?.label}
    </Badge>
);

export default StatusBadge;
