import { creatorbase } from '@round/api';
import { GenericDropdownOption } from 'App.types';
import { OptionsParams, UseMultiValueSelectParams, useSelect } from 'Hooks/useSelect';

export type UserOption = GenericDropdownOption<number>;
export const mapUserToOption = (user: creatorbase.User): UserOption => ({ value: user.id, label: user.name });

type MultiValueParams = Omit<UseMultiValueSelectParams<UserOption>, 'isMulti'>;

const fetchOptions = async (params: creatorbase.GetUsersParams, requestInit?: RequestInit) => {
    const response = await creatorbase.getUsers(params, requestInit);

    if (response.status !== 200) {
        throw new Error('Could not get users');
    }

    return {
        ...response.data,
        results: response.data.results.map(mapUserToOption),
    };
};

export const buildUserSelectFetchOptions = (usersParams: creatorbase.GetUsersParams) => (
    params: OptionsParams,
    requestInit?: RequestInit
) => fetchOptions({ ...usersParams, ...params }, requestInit);

export function useUsersMultiSelect(params: MultiValueParams) {
    return useSelect({ isMulti: true, ...params });
}
