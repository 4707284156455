import { AppServer, RouteHandlerContext } from '../../mirage';
import { belongsTo, Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { InstagramUserImage } from './instagramUserImage.types';

export const models = {
    instagramUserImage: Model.extend({
        avatar_thumb: belongsTo('cachedImage'),
    }) as ModelDefinition<InstagramUserImage>,
};

export const factories = {
    instagramUserImage: createFactory<InstagramUserImage>({
        user_id(index: number) {
            return index;
        },
        afterCreate(image, s) {
            const server: AppServer = s;
            if (!image.avatar_thumb) {
                image.update({
                    avatar_thumb: server.create('cachedImage'),
                });
            }
        },
    }),
};

export const serializers = {
    instagramUserImage: makeSerializer<InstagramUserImage>([]),
};

export function handleInstagramUserImageRequests(server: AppServer) {
    server.get('/api/instagram/user-image/', function (this: RouteHandlerContext, schema, request) {
        const { user_id = '' } = request.queryParams;
        const ids = user_id.split(',');
        const images = schema.all('instagramUserImage').filter((image) => ids.includes(String(image.id)));

        return this.serialize(images);
    });
}
