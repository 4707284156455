import React from 'react';
import { usePopper } from 'react-popper';
import { useCallback, useEffect, useState } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import ControlledPopover, { ControlledPopoverProps } from './ControlledPopover';
import styles from './Popover.module.css';
import cn from 'classnames';

export type Options = Parameters<typeof usePopper>[2];

export type PopoverProps = Omit<ControlledPopoverProps, 'isOpen' | 'children'> & {
    showOn: 'hover' | 'click';
    children: React.ReactNode | ((setShow: (show: boolean) => void) => React.ReactNode);
    disabled?: boolean;
    onShowPopoverChange?: (show: boolean) => void;
};

const Popover = ({
    children,
    showOn,
    anchorElement,
    disabled,
    onShowPopoverChange,
    overlayClassName,
    ...restProps
}: PopoverProps) => {
    const [show, setShow] = useState(false);
    const [popoverContainer, setPopoverContainer] = useState<HTMLDivElement | null>(null);

    const handleShowPopoverChange = useCallback(
        (show: boolean) => {
            if (typeof onShowPopoverChange === 'function') {
                onShowPopoverChange(show);
            }
        },
        [onShowPopoverChange]
    );

    const addClickEvents = useCallback(
        (anchor: HTMLElement | SVGSVGElement) => {
            const handleClick = () => {
                setShow(!show);
                handleShowPopoverChange(!show);
            };

            anchor.addEventListener('click', handleClick);
            return () => anchor.removeEventListener('click', handleClick);
        },
        [handleShowPopoverChange, show]
    );

    const addHoverEvents = useCallback(
        (anchor: HTMLElement | SVGSVGElement) => {
            const handleMouseEnter = () => {
                setShow(true);
                handleShowPopoverChange(true);
            };

            const handleMouseLeave = () => {
                setShow(false);
                handleShowPopoverChange(false);
            };

            anchor.addEventListener('mouseenter', handleMouseEnter);
            anchor.addEventListener('mouseleave', handleMouseLeave);

            return () => {
                anchor.removeEventListener('mouseenter', handleMouseEnter);
                anchor.removeEventListener('mouseleave', handleMouseLeave);
            };
        },
        [handleShowPopoverChange]
    );

    useEffect(() => {
        if (!disabled && showOn === 'hover' && anchorElement) {
            return addHoverEvents(anchorElement);
        }
    }, [addHoverEvents, anchorElement, disabled, showOn]);

    useEffect(() => {
        if (!disabled && showOn === 'click' && anchorElement) {
            return addClickEvents(anchorElement);
        }
    }, [addClickEvents, anchorElement, disabled, showOn]);

    useOnClickOutside([anchorElement, popoverContainer], () => {
        if (show) {
            setShow(false);
            handleShowPopoverChange(false);
        }
    });

    return (
        <ControlledPopover
            ref={setPopoverContainer}
            isOpen={!disabled && show}
            anchorElement={anchorElement}
            overlayClassName={cn(overlayClassName, { [styles.noPointerEvents]: showOn === 'hover' })}
            {...restProps}
        >
            {typeof children === 'function' ? children(setShow) : children}
        </ControlledPopover>
    );
};

export default Popover;
