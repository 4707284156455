import React from 'react';
import { FCWithChildren } from '../../../../utility/utility.types';
import { InvoicePlanItemRow } from '../InvoiceBar/InvoiceBar';
import styles from './InvoicePlanItemTable.module.css';
import cn from 'classnames';
import InvoicePlanItemTableRow from './InvoicePlanItemTableRow/InvoicePlanItemTableRow';

type InvoicePlanItemTableProps = {
    rows: InvoicePlanItemRow[];
};

const InvoicePlanItemTable: FCWithChildren<InvoicePlanItemTableProps> = ({ rows }) => {
    return (
        <div className={styles.table}>
            <div className={styles.headerContainer}>
                <thead className={styles.itemInfoColumns}>
                    <tr className={cn(styles.itemInfoColumn, styles.idColumn)}>
                        <span className={cn(styles.itemInfoColumnValue)}>ID</span>
                    </tr>
                    <tr className={cn(styles.itemInfoColumn, styles.itemColumn)}>
                        <span className={cn(styles.itemInfoColumnValue)}>ITEM</span>
                    </tr>
                    <tr className={styles.itemInfoColumn}>
                        <span className={cn(styles.itemInfoColumnValue)}>CLIENT COST</span>
                    </tr>
                    <tr className={styles.itemInfoColumn}>
                        <span className={cn(styles.itemInfoColumnValue)}>ROUND COST</span>
                    </tr>
                    <tr className={styles.itemInfoColumn}>
                        <span className={cn(styles.itemInfoColumnValue)}>SPENT</span>
                    </tr>
                    <tr className={styles.itemInfoColumn}>
                        <span className={cn(styles.itemInfoColumnValue)}>BRAND</span>
                    </tr>
                    <tr className={styles.itemInfoColumn}>
                        <span className={cn(styles.itemInfoColumnValue)}>CAMPAIGN</span>
                    </tr>
                    <tr className={styles.itemInfoColumn}>
                        <span className={cn(styles.itemInfoColumnValue)}>CLIENT</span>
                    </tr>
                    <tr className={styles.itemInfoColumn}>
                        <span className={cn(styles.itemInfoColumnValue)}>CAMPAIGN TYPE</span>
                    </tr>
                </thead>
            </div>
            {rows.map((row) => (
                <InvoicePlanItemTableRow row={row} />
            ))}
        </div>
    );
};

export default InvoicePlanItemTable;
