import { ApiResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { XeroInvoice, XeroInvoiceSimple } from './invoice.types';
import { fetchWithToken, encodeUrlSearchParams } from '../../helpers';

export async function getXeroReceivableInvoices(
    params: Partial<PaginatedRequest & { search: string }>,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<XeroInvoiceSimple>, 200>> {
    const response = await fetchWithToken(
        `/api/xero/receivable-invoices/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not get receivable invoices');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getXeroInvoice(
    id: number,
    requestInit?: RequestInit
): Promise<ApiResponse<XeroInvoiceSimple, 200> | ApiResponse<{ detail: string }, 404>> {
    const response = await fetchWithToken(`/api/xero/viewsets/invoice/${id}/`, requestInit);

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`could not retrieve invoice with id ${id}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getXeroInvoices(
    params: Partial<
        PaginatedRequest & {
            search: string;
            brand_id: string;
            client_id: string;
            exclude_if_no_plan_items: boolean;
            start_date: string;
            end_date: string;
        }
    >,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<XeroInvoice>, 200>> {
    const response = await fetchWithToken(`/api/xero/viewsets/invoice/${encodeUrlSearchParams(params)}`, requestInit);

    if (!response.ok) {
        throw new Error('Could not get invoices');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
