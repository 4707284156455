import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokInfluencerPlanVideo } from './tiktokInfluencerPlanVideo.types';
import { createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokInfluencerPlanVideo: Model as ModelDefinition<TiktokInfluencerPlanVideo>,
};

export const serializers = {
    tiktokInfluencerPlanVideo: makeSerializer<TiktokInfluencerPlanVideo>([]),
};

export const factories = {
    tiktokInfluencerPlanVideo: createFactory<TiktokInfluencerPlanVideo>({
        video_file: null,
        plan(index: number) {
            return index;
        },
        get_file_url(index: number) {
            return `get file url ${index}`;
        },
        put_file_url(index: number) {
            return `put file url ${index}`;
        },
        name(index: number) {
            return `name ${index}`;
        },
        notes(index: number) {
            return `notes ${index}`;
        },
        is_approved: false,
    }),
};
