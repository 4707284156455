import React, { useState } from 'react';
import styles from './ApproveMicrowavePosts.module.css';
import { useTiktokMicroApprovalsTableData } from './useTiktokMicroApprovalsTableData';
import TiktokMicroPostApprovalTable, {
    TiktokMicroPostApprovalTableRow,
} from './TiktokMicroPostApprovalTable/TiktokMicroPostApprovalTable';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import { microwave } from '@round/api';
import { ConfirmationModal, DatePickerSelect, Select, Skeleton } from '@round/ui-kit';
import { showNotification } from '../../../../helpers';
import { useAudioSelect } from '../../../TikTok/hooks/useAudioSelect';
import { AudioOption, AudioSelectOption } from '../../../TikTok/components/AudioSelectComponents/AudioSelectComponents';
import useUrlState from '../../../../Hooks/useUrlState';
import { AudioPlayerProvider } from '../../../AudioPlayer/AudioPlayerContext';
import { ValueType } from 'react-select';
import { MakeRequired } from '../../../../utility/utility.types';
import { GenericDropdownOption } from '../../../../App.types';
import { microPaymentRequestStatusOptions as baseStatusOptions } from '../constants';
import { usePlanSelect } from '../../InfluencerPlan/hooks/usePlanSelect';
import {
    InfluencerPlanOption,
    InfluencerPlanSelectOption,
} from '../../InfluencerPlan/components/InfluencerPlanSelectOption/InfluencerPlanSelectOption';
import { formatToIsoDateString } from '../../../../utility/utility';
import useMicrowaveInfluencersSelect from '../useMicrowaveInfluencersSelect';
import { DebounceInput } from 'react-debounce-input';
import cn from 'classnames';
import MicrowaveInfluencerOptionLabel from '../components/Filters/MicrowaveInfluencerFilter/components/OptionLabel/MicrowaveInfluencerOptionLabel';

type UrlState = MakeRequired<Parameters<typeof microwave.getTiktokMicrowaveInfluencerPosts>[0], 'page' | 'page_size'>;

const initialUrlState: UrlState = {
    page: 1,
    page_size: 10,
    payment_request_status: 'PENDING',
};

const microPaymentRequestStatusOptions = [
    ...baseStatusOptions,
    {
        label: 'All',
        value: undefined,
    },
] as GenericDropdownOption<microwave.PaymentRequestStatus | undefined>[];

const ApproveMicrowavePosts = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState);

    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const setPage = (page: number) => {
        setUrlState({ page });
        reset();
    };

    const pageSize = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;
    const setPageSize = (pageSize: number | undefined) => {
        setUrlState({ page_size: pageSize || initialUrlState.page_size });
        reset();
    };

    const paymentRequestedAtDates: [Date, Date] | null =
        urlState.payment_requested_at_start && urlState.payment_requested_at_end
            ? [new Date(urlState.payment_requested_at_start), new Date(urlState.payment_requested_at_end)]
            : null;
    const setPaymentRequestedAtDates = (date: [Date, Date] | [Date] | null) => {
        if (!date) {
            setUrlState({ payment_requested_at_start: undefined, payment_requested_at_end: undefined });
            reset();
            return;
        }

        const [start, end] = date;
        if (!start || !end) {
            return;
        }

        setUrlState({
            payment_requested_at_start: formatToIsoDateString(start),
            payment_requested_at_end: formatToIsoDateString(end),
        });
        reset();
    };

    const selectedAudioId = urlState.audio_id ? Number(urlState.audio_id) : undefined;
    const selectedPlanId = urlState.plan_id ? Number(urlState.plan_id) : undefined;
    const selectedStatusFilter = urlState.payment_request_status as microwave.PaymentRequestStatus;
    const selectedInfluencerId = urlState.influencer_id ? Number(urlState.influencer_id) : undefined;

    const [isConfirmStatusModalOpen, setIsConfirmStatusModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<TiktokMicroPostApprovalTableRow | null>(null);
    const [selectedStatusUpdateAction, setSelectedStatusUpdateAction] = useState<microwave.PaymentRequestStatus | null>(
        null
    );

    const [isStatusUpdateLoading, setIsStatusUpdateLoading] = useState(false);

    const selectedStatusUpdateActionLabel = selectedStatusUpdateAction === 'APPROVED' ? 'Approve' : 'Reject';

    const {
        rows,
        count,
        isInitialized,
        hasError,
        isLoading,
        init,
        reset,
        updatePaymentRequestStatus,
        removePostFromState,
    } = useTiktokMicroApprovalsTableData({
        page,
        page_size: pageSize,
        payment_request_status: selectedStatusFilter,
        audio_id: selectedAudioId,
        plan_id: selectedPlanId,
        payment_status: 'UNPAID',
        payment_requested_at_start: urlState.payment_requested_at_start,
        payment_requested_at_end: urlState.payment_requested_at_end,
        ordering: '-plan_id',
        influencer_id: selectedInfluencerId,
        invite_id: urlState.invite_id,
    });

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                init({ signal });
            }
        },
        [isInitialized, init]
    );

    const isTableLoading = isLoading || (!isInitialized && !hasError);

    const [rowsDisplayed, setRowsDisplayed] = useState(0);
    const rowsDisplayedText = `displaying ${rowsDisplayed} out of ${count} post${count !== 1 ? 's' : ''}`;

    const { value: selectedAudio, onChange: onChangeSelectedAudio, ...audioSelectProps } = useAudioSelect({
        initialAudioIdData: selectedAudioId,
        searchOnly: true,
    });

    const handleChangeSelectedAudio = (option: ValueType<AudioOption, boolean>) => {
        const o = option as ValueType<AudioOption, false>;
        setUrlState({
            audio_id: o ? o.value : undefined,
            page: 1,
        });
        onChangeSelectedAudio(o);
        reset();
    };

    const { value: selectedPlan, onChange: onChangeSelectedPlan, ...planSelectProps } = usePlanSelect({
        initialPlanIdData: selectedPlanId,
    });

    const handleChangeSelectedPlan = (option: ValueType<InfluencerPlanOption, boolean>) => {
        const o = option as ValueType<InfluencerPlanOption, false>;
        setUrlState({
            plan_id: o ? o.value : undefined,
            page: 1,
        });
        onChangeSelectedPlan(o);
        reset();
    };

    const creatorSelectProps = useMicrowaveInfluencersSelect(selectedInfluencerId);

    const onClickUpdatePaymentStatus = (
        row: TiktokMicroPostApprovalTableRow,
        status: microwave.PaymentRequestStatus
    ) => {
        setSelectedRow(row);
        setSelectedStatusUpdateAction(status);
        setIsConfirmStatusModalOpen(true);
    };

    const handleUpdatePaymentRequestStatus = async (
        row: TiktokMicroPostApprovalTableRow | null,
        status: microwave.PaymentRequestStatus | null
    ) => {
        if (!row || !status) {
            return;
        }

        setIsStatusUpdateLoading(true);
        const success = await updatePaymentRequestStatus(row.payment_request_id, status);
        if (!success) {
            showNotification(`Couldn't update payment request status`, 'error');
            setIsStatusUpdateLoading(false);
            return;
        }

        showNotification('Payment request status updated successfully', 'info');
        setIsConfirmStatusModalOpen(false);
        removePostFromState(row.id);
        setSelectedRow(null);
        setSelectedStatusUpdateAction(null);
        setIsStatusUpdateLoading(false);
    };

    const noDataLabel = hasError ? 'Error loading posts' : 'No posts found';

    return (
        <>
            <main className={styles.container}>
                <h1>Approve Posts</h1>
                <div className={styles.header}>
                    <div className={styles.filters}>
                        <div className={styles.filter}>
                            <label>Audio</label>
                            <AudioPlayerProvider>
                                <Select
                                    {...audioSelectProps}
                                    value={selectedAudio}
                                    onChange={handleChangeSelectedAudio}
                                    components={{ Option: AudioSelectOption }}
                                    filterOption={null}
                                    formatOptionLabel={(option) => `${option.title} - ${option.authorName}`}
                                    isClearable
                                />
                            </AudioPlayerProvider>
                        </div>

                        <div className={styles.filter}>
                            <label>Campaign</label>
                            <Select
                                {...planSelectProps}
                                value={selectedPlan}
                                onChange={handleChangeSelectedPlan}
                                components={{ Option: InfluencerPlanSelectOption }}
                                filterOption={null}
                                isClearable
                            />
                        </div>

                        <div className={styles.filter}>
                            <label>Creator</label>
                            <Select
                                {...creatorSelectProps}
                                isClearable
                                onChange={(o, action) => {
                                    creatorSelectProps.onChange?.(o, action);
                                    setUrlState({ page: 1, influencer_id: o?.value });
                                    reset();
                                }}
                                formatOptionLabel={(option, { context }) => {
                                    if (context === 'menu') {
                                        return <MicrowaveInfluencerOptionLabel {...option} context={context} />;
                                    }
                                    return option.label;
                                }}
                            />
                        </div>

                        <div className={styles.filter}>
                            <label>Status</label>
                            <Select
                                value={microPaymentRequestStatusOptions.find(
                                    (option) => option.value === selectedStatusFilter
                                )}
                                options={microPaymentRequestStatusOptions}
                                onChange={(option) => {
                                    setUrlState({
                                        payment_request_status: option?.value,
                                    });
                                    reset();
                                }}
                            />
                        </div>

                        <div className={styles.filter}>
                            <label>Payment Request Date</label>
                            <DatePickerSelect
                                placeholder="Select Date Range..."
                                onChange={(dates) => {
                                    setPaymentRequestedAtDates(dates);
                                }}
                                onClear={() => setPaymentRequestedAtDates(null)}
                                value={paymentRequestedAtDates}
                                className={styles.datePicker}
                                selectRange
                            />
                        </div>

                        <div className={styles.filter}>
                            <label>Invite ID</label>
                            <DebounceInput
                                className={styles.filterField}
                                placeholder="Enter invite id..."
                                debounceTimeout={700}
                                value={urlState.invite_id}
                                onChange={(e) => {
                                    setUrlState({ invite_id: e.target.value, page: 1 });
                                    reset();
                                }}
                            />
                        </div>
                    </div>

                    <div className={styles.headerDetails}>
                        <p className={styles.rowsDisplayedText}>
                            {isTableLoading ? <Skeleton width="7rem" /> : rowsDisplayedText}
                        </p>
                    </div>
                </div>

                <article className={styles.tableContainer}>
                    <TiktokMicroPostApprovalTable
                        rows={rows}
                        isLoading={isLoading}
                        noDataLabel={noDataLabel}
                        page={page}
                        pageSize={pageSize}
                        setPage={setPage}
                        setPageSize={setPageSize}
                        count={count}
                        onClickUpdatePaymentStatus={onClickUpdatePaymentStatus}
                        onPaginationChange={(state) => setRowsDisplayed(state.pageRows.length)}
                    />
                </article>
            </main>
            <ConfirmationModal
                isOpen={isConfirmStatusModalOpen}
                modalTitle={`${selectedStatusUpdateActionLabel} Payment Request`}
                modalText={`Are you sure you want to ${selectedStatusUpdateActionLabel.toLocaleLowerCase()} this payment request`}
                confirmButtonText={selectedStatusUpdateActionLabel}
                handleConfirm={() => handleUpdatePaymentRequestStatus(selectedRow, selectedStatusUpdateAction)}
                onClose={() => setIsConfirmStatusModalOpen(false)}
                confirmActionLoading={isStatusUpdateLoading}
                confirmButtonClassName={cn({
                    [styles.confirmApproveButton]: selectedStatusUpdateAction === 'APPROVED',
                })}
            />
        </>
    );
};

export default ApproveMicrowavePosts;
