import { Release, getRelease } from '@round/api';
import { useCallback, useEffect, useState } from 'react';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';

export function useRelease(releaseId: number | undefined) {
    const [release, setRelease] = useState<Release | null>(null);
    const [releaseLoading, setReleaseLoading] = useState(false);
    const [errorLoadingRelease, setErrorLoadingRelease] = useState(false);

    const fetchRelease = useCallback(async (releaseId: number, requestInit?: RequestInit) => {
        try {
            setReleaseLoading(true);
            setErrorLoadingRelease(false);
            const release = await getRelease(releaseId, requestInit);
            if (release.status === 404) {
                setErrorLoadingRelease(true);
                return;
            }
            setRelease(release.data);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return;
            }

            setErrorLoadingRelease(true);
        } finally {
            setReleaseLoading(false);
        }
    }, []);

    useEffect(() => {
        setRelease(null);
    }, [releaseId]);

    useAbortableEffect(
        (signal) => {
            if (!release && releaseId !== undefined && !isNaN(releaseId)) {
                fetchRelease(releaseId, { signal });
            }
        },
        [fetchRelease, release, releaseId]
    );

    return {
        release,
        setRelease,
        releaseLoading,
        errorLoadingRelease,
    };
}
