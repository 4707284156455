import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokAudioCollectionPostStats } from './collectionPostStats.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokAudioCollectionPostStats: Model as ModelDefinition<TiktokAudioCollectionPostStats>,
};

export const serializers = {
    tiktokAudioCollectionPostStats: makeSerializer<TiktokAudioCollectionPostStats>([]),
};

export const factories = {
    tiktokAudioCollectionPostStats: createFactory<TiktokAudioCollectionPostStats>({}),
};

export function handleRequests(server: AppServer) {
    const listUrl = '/api/catalogue/viewsets/catalogue/:collectionId/tiktok-audio-post-stats/';
    server.get(listUrl, function (this: RouteHandlerContext, schema, request) {
        const stats = schema.all('tiktokAudioCollectionPostStats');
        return buildPaginatedResponse(stats, {
            url: listUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'tiktokAudioCollectionPostStats'),
        });
    });
}
