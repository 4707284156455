import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import styles from './PlatformHeadlineStats.module.css';
import cn from 'classnames';
import { SkeletonizedValue } from '@round/ui-kit';

export type Stats = {
    [key: string]: {
        label: string;
        value: string | number | null | undefined;
        tooltip?: string;
    };
};

type Props = {
    stats: Stats;
    isLoading?: boolean;
};

const PlatformHeadlineStats = ({ stats, isLoading }: Props) => {
    return (
        <article className={styles.container}>
            <dl className={styles.stats}>
                {Object.values(stats).map(({ label, value, tooltip }) => (
                    <div key={label} className={styles.item}>
                        {tooltip ? (
                            <Tooltip.Root>
                                {(ref, setRef, underlineClassName) => (
                                    <>
                                        <dt ref={setRef} className={cn(styles.label, underlineClassName)}>
                                            {label}
                                        </dt>
                                        <Tooltip anchorElement={ref}>
                                            <Tooltip.Title>{label}</Tooltip.Title>
                                            <Tooltip.Body>{tooltip}</Tooltip.Body>
                                        </Tooltip>
                                    </>
                                )}
                            </Tooltip.Root>
                        ) : (
                            <dt className={styles.label}>{label}</dt>
                        )}
                        <dd className={styles.value}>
                            {<SkeletonizedValue isInitialized={!isLoading}>{value ?? '-'}</SkeletonizedValue>}
                        </dd>
                    </div>
                ))}
            </dl>
        </article>
    );
};

export default PlatformHeadlineStats;
