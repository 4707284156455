import { creatorbase } from '@round/api';
import { ReactComponent as TiktokSolidIcon } from 'assets/whitelabel/platform/TiktokSolid.svg';
import { ReactComponent as TiktokColoredIcon } from 'assets/whitelabel/platform/TiktokColor.svg';
import { ReactComponent as InstagramSolidIcon } from 'assets/whitelabel/platform/InstagramSolid.svg';
import { ReactComponent as InstagramColoredIcon } from 'assets/whitelabel/platform/InstagramColor.svg';
import { ReactComponent as YoutubeSolidIcon } from 'assets/whitelabel/platform/YoutubeSolid.svg';
import { ReactComponent as YoutubeColoredIcon } from 'assets/whitelabel/platform/YoutubeColor.svg';
import { SVGProps } from 'react';

type Props = {
    platform: creatorbase.Platform;
    colored?: boolean;
} & SVGProps<SVGSVGElement>;

type IconsMap = { [key in creatorbase.Platform]?: typeof TiktokSolidIcon };

const solidIcons: IconsMap = {
    tiktok: TiktokSolidIcon,
    instagram: InstagramSolidIcon,
    youtube: YoutubeSolidIcon,
};

const coloredIcons: IconsMap = {
    tiktok: TiktokColoredIcon,
    instagram: InstagramColoredIcon,
    youtube: YoutubeColoredIcon,
};

const PlatformIcon = ({ platform, colored, ...props }: Props) => {
    const Icon = colored ? coloredIcons[platform] : solidIcons[platform];
    if (!Icon) {
        return null;
    }

    return <Icon {...props} />;
};

export default PlatformIcon;
