import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { ChartProps, Scatter } from 'react-chartjs-2';
import { InfluencerPlan } from '../../../../../../../App.types';
import { TiktokInfluencerPost, TiktokUserPostStats } from '@round/api';
import { formatNumberToKNotation } from '../../../../../../../helpers';
import styles from './PromotionsScatterChart.module.css';
import LoadingSpinner from '../../../../../../../SharedComponents/LoadingSpinner/LoadingSpinner';
import { VideoStatsWithData } from '../Promotions';

type PromotionsScatterChartProps = {
    loading: boolean;
    errorLoading: boolean;
    influencerPosts: TiktokInfluencerPost[];
    results: VideoStatsWithData[];
    postStats: TiktokUserPostStats[];
    influencerPlans: InfluencerPlan[];
};

type ScatterDataItem = {
    author: string;
    song: string;
    caption: string;
    x: number;
    y: number;
    isSponsored?: boolean;
};

const TOOLTIP_WIDTH_IN_PX = 160;

const PromotionsScatterChart = ({
    results,
    influencerPosts,
    postStats,
    loading,
    influencerPlans,
}: PromotionsScatterChartProps) => {
    const [tooltipParams, setTooltipParams] = useState<{ x: number; y: number; transformX: number } | null>(null);
    const [tooltipItem, setTooltipItem] = useState<ScatterDataItem | null>(null);

    const sponsoredPostStats: ScatterDataItem[] = useMemo(() => {
        const sponsoredPostStatsChartPoints = postStats.reduce<ScatterDataItem[]>((acc, postStat) => {
            const influencerPost = influencerPosts.find(
                (post) => post.tiktok_post_tiktok_id === postStat.video_tiktok_id
            );

            if (!influencerPost) {
                return acc;
            }

            const plan = influencerPlans.find((plan) => plan.id === influencerPost.plan_id);

            return acc.concat({
                song: plan?.release.name ?? '',
                author: plan?.release.brand.name ?? '',
                caption: postStat.description,
                x: postStat.play_count,
                y: postStat.like_count,
                isSponsored: true,
            });
        }, []);

        const sponsoredVideoStatsChartPoints = results.reduce<ScatterDataItem[]>((acc, result) => {
            const influencerPost = influencerPosts.find((post) => post.tiktok_post === result.video_id);

            if (!influencerPost) {
                return acc;
            }

            const hasRelatedPostStat = !!postStats.find(
                (post) => post.video_tiktok_id === influencerPost.tiktok_post_tiktok_id
            );

            if (hasRelatedPostStat) {
                return acc;
            }

            const plan = influencerPlans.find((plan) => plan.id === influencerPost.plan_id);

            return acc.concat({
                song: plan?.release.name ?? '',
                author: plan?.release.brand.name ?? '',
                caption: result.desc ?? '',
                x: result.play_count,
                y: result.digg_count,
                isSponsored: true,
            });
        }, []);

        return [...sponsoredPostStatsChartPoints, ...sponsoredVideoStatsChartPoints];
    }, [influencerPosts, influencerPlans, postStats, results]);

    const notSponsoredPostStats: ScatterDataItem[] = useMemo(() => {
        return postStats
            .filter((video) => !influencerPosts.find((post) => post.tiktok_post_tiktok_id === video.video_tiktok_id))
            .map((video) => ({
                author: '',
                song: '',
                caption: video.description,
                x: video.play_count,
                y: video.like_count,
                isSponsored: false,
            }));
    }, [influencerPosts, postStats]);

    const data: ChartProps<'scatter', ScatterDataItem[]>['data'] = useMemo(
        () => ({
            datasets: [
                {
                    label: 'Sponsored',
                    data: sponsoredPostStats,
                    backgroundColor: 'rgb(112, 69, 204, 0.5)',
                    showLine: false,
                },
                {
                    label: 'Not Sponsored',
                    data: notSponsoredPostStats,
                    showLine: false,
                },
            ],
        }),
        [notSponsoredPostStats, sponsoredPostStats]
    );

    const options: ChartProps<'scatter', ScatterDataItem[]>['options'] = useMemo(
        () => ({
            elements: {
                point: {
                    radius: 6,
                    hoverRadius: 7,
                },
            },
            scales: {
                y: {
                    ticks: {
                        count: 3,
                        beginAtZero: true,
                        callback: (value: string | number) => formatNumberToKNotation(Number(value)),
                    },
                    title: {
                        display: true,
                        text: 'Likes',
                    },
                    border: {
                        display: false,
                    },
                    grid: {
                        display: true,
                    },
                },
                x: {
                    ticks: {
                        count: 6,
                        beginAtZero: true,
                        callback: (value: string | number) => formatNumberToKNotation(Number(value)),
                    },
                    title: {
                        display: true,
                        text: 'Views',
                    },
                    grid: {
                        display: false,
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external({ tooltip, chart }) {
                        if (tooltip.opacity === 0) {
                            setTooltipParams(null);
                            setTooltipItem(null);
                        } else {
                            const position = chart.canvas.getBoundingClientRect();
                            const tooltipHalfWidth = TOOLTIP_WIDTH_IN_PX / 2;
                            let left = position.left + tooltip.caretX;
                            let transform = -50;

                            if (tooltip.caretX <= tooltipHalfWidth) {
                                transform = 0;
                            } else if (tooltipHalfWidth >= position.right - (tooltip.caretX + position.left)) {
                                transform = -100;
                            }

                            setTooltipParams({
                                x: left,
                                y: position.top + tooltip.caretY,
                                transformX: transform,
                            });

                            if (Array.isArray(tooltip.dataPoints) && tooltip.dataPoints.length) {
                                const point = tooltip.dataPoints[0];
                                const item = data.datasets[point.datasetIndex]?.data[point.dataIndex];
                                setTooltipItem(item ?? null);
                            }
                        }
                    },
                },
            },
        }),
        [data.datasets]
    );

    return (
        <div className={styles.container}>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <div className={styles.chart}>
                    <Scatter height={75} data={data} options={options} />
                </div>
            )}
            {tooltipParams &&
                tooltipItem &&
                ReactDOM.createPortal(
                    <div
                        className={styles.tooltip}
                        style={{
                            left: tooltipParams.x + 'px',
                            top: tooltipParams.y + 'px',
                            transform: `translate(${tooltipParams.transformX}%, -115%)`,
                            width: TOOLTIP_WIDTH_IN_PX,
                        }}
                    >
                        <div>
                            <p className={styles.tooltipMetrics}>{formatNumberToKNotation(tooltipItem.x)} views</p>
                            <p className={styles.tooltipMetrics}>{formatNumberToKNotation(tooltipItem.y)} likes</p>
                        </div>
                        {tooltipItem.isSponsored && (
                            <div>
                                <div />
                                <p className={styles.tooltipFooter}>
                                    {tooltipItem.author} - {tooltipItem.song}
                                </p>
                            </div>
                        )}
                        {!tooltipItem.isSponsored && <p className={styles.tooltipFooter}>{tooltipItem.caption}</p>}
                    </div>,
                    document.body
                )}
        </div>
    );
};

export default PromotionsScatterChart;
