import useAbortableEffect from 'Hooks/useAbortableEffect';
import useTiktokTopAudios from './useTiktokTopAudios';
import TiktokTopAudiosTable, { TiktokTopAudiosTableRow } from './TiktokTopAudiosTable/TiktokTopAudiosTable';
import styles from './TiktokTopAudios.module.css';
import { useState } from 'react';
import { creatorbase } from '@round/api';
import Select from 'ui-new/whitelabel/Select/Select';
import { GenericDropdownOption } from 'App.types';

const orderingOptions: GenericDropdownOption<creatorbase.GetTiktokAudiosOrderingParam>[] = [
    { label: 'Total Videos', value: '-video_count' },
    { label: 'Daily Growth (# videos)', value: '-video_count_daily_change' },
    { label: 'Daily Growth (% increase)', value: '-video_count_daily_change_relative' },
    { label: 'Weekly Growth (# videos)', value: '-video_count_weekly_change' },
    { label: 'Weekly Growth (% increase)', value: '-video_count_weekly_change_relative' },
];

const defaultOrder: creatorbase.GetTiktokAudiosOrderingParam = '-video_count_daily_change';
const TiktokTopAudios = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [ordering, setOrdering] = useState<creatorbase.GetTiktokAudiosOrderingParam>(defaultOrder);

    const { data, status, fetchData, reset } = useTiktokTopAudios();
    const isInitialized = status === 'success' || status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                fetchData({ page, page_size: pageSize, ordering }, { signal }).catch(() => {});
            }
        },
        [fetchData, isInitialized, page, pageSize, ordering]
    );

    const rows: TiktokTopAudiosTableRow[] =
        data?.audios.results?.map((audio) => ({
            ...audio,
            timeSeries: data.timeSeries?.[audio.id] ?? null,
            asset: data.assets.find((asset) => asset.audio_id === audio.id) ?? null,
        })) ?? [];

    return (
        <section className={styles.container}>
            <menu>
                <Select
                    value={orderingOptions.find((o) => o.value === ordering)}
                    options={orderingOptions}
                    onChange={(option) => {
                        setOrdering(option?.value ?? defaultOrder);
                        reset();
                    }}
                    className={styles.orderingSelect}
                />
            </menu>

            <TiktokTopAudiosTable
                page={page}
                setPage={(page) => {
                    setPage(page);
                    reset();
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                    setPageSize(pageSize);
                    reset();
                }}
                ordering={ordering}
                count={data?.audios.count ?? 0}
                data={rows}
                isLoading={!isInitialized}
                hasError={status === 'error'}
            />
        </section>
    );
};

export default TiktokTopAudios;
