import React from 'react';
import styles from './OutstandingItems.module.css';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import TableRow from '../../../SharedComponents/TableRow/TableRow';

const OutstandingItems = () => {
    return (
        <div className={styles.page}>
            <NavBar />
            <div className={styles.content}>
                <h1>Outstanding Items</h1>
                <div>
                    <table className={styles.table}>
                        <thead>
                            <tr className={styles.columnHeaderRow}>
                                <th className={styles.cell}>Outstanding Items</th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRow linkTo="outstanding-media-plan-items">Outstanding Media Plan Items</TableRow>
                            <TableRow linkTo="outstanding-instagram-influencer-posts">
                                Outstanding Instagram Influencer Posts
                            </TableRow>
                            <TableRow linkTo="outstanding-tiktok-influencer-posts">
                                Outstanding Tiktok Influencer Posts
                            </TableRow>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default OutstandingItems;
