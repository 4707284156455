import { creatorbase } from '@round/api';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import { useState } from 'react';
import { showNotification } from 'helpers';
import styles from './DeleteReportModal.module.css';
import cn from 'classnames';
import Button from 'ui-new/whitelabel/Button/Button';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    report: creatorbase.Report | null;
    deleteReport: typeof creatorbase.deleteReport;
};

const DeleteReportModal = ({ isOpen, onClose, report, deleteReport }: Props) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const handleDelete = async () => {
        if (!report) {
            return;
        }

        try {
            setIsDeleting(true);
            const response = await deleteReport(report.id);
            if (response.status === 204) {
                showNotification('Report deleted', 'info');
                onClose();
                return;
            }

            showNotification('Could not delete report', 'error');
        } catch {
            showNotification('Could not delete report', 'error');
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <Modal className={styles.modal} closeOnOverlayClick isOpen={isOpen} onClose={onClose}>
            <Modal.Header>
                <Modal.Title>Delete Report</Modal.Title>
            </Modal.Header>

            <Modal.Body className={styles.body}>
                <p className={cn(styles.copy, styles.bold)}>You're about to permanently delete the following:</p>
                <div className={styles.banner}>
                    <p className={cn(styles.copy, styles.bold)}>Report:</p>
                    {report && (
                        <>
                            <p className={styles.copy}>{report.name}</p>
                            <p className={styles.copy}>{report.description}</p>
                        </>
                    )}
                </div>

                <p className={styles.copy}>You cannot restore reports. They are deleted permanently.</p>
            </Modal.Body>

            <Modal.Actions>
                <Button appearance="critical" isLoading={isDeleting} onClick={handleDelete}>
                    Delete Report
                </Button>
                <Button appearance="ghost" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteReportModal;
