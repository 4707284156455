import { InstagramUser, InstagramUserImage, TiktokUser } from '@round/api';
import { buildInstagramUserUrl, buildTiktokUserUrl } from 'helpers';
import { AccountData } from './Account';

export const mapTiktokUserToAccountData = (user: TiktokUser): AccountData => ({
    imageUrl: user.avatar_thumb,
    nickname: user.nickname,
    profileUrl: buildTiktokUserUrl(user.unique_id),
    username: user.unique_id,
});

export const mapInstagramUserToAccountData = (
    user: InstagramUser,
    image?: InstagramUserImage | undefined
): AccountData => ({
    imageUrl: image?.avatar_thumb.cached_url || image?.avatar_thumb.original_url || '',
    nickname: user.name || '-',
    profileUrl: buildInstagramUserUrl(user.username),
    username: user.username,
});
