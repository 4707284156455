import React from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './FileUpload.module.css';
import cn from 'classnames';
import { ReactComponent as FolderIcon } from '../../../assets/Folder.svg';
import { ReactComponent as FolderAddIcon } from '../../../assets/FolderAdd.svg';
import LoadingSpinner from '../../../SharedComponents/LoadingSpinner/LoadingSpinner';

type FileUploadProps = {
    files: File[];
    onUpload: (files: File[]) => void;
    children: (props: FileUploadChildrenProps) => React.ReactNode;
    className?: string;
    Icon?: typeof FolderIcon;
    loading?: boolean;
};

type FileUploadChildrenProps = {
    files: File[];
};

const FileUpload = ({ files, onUpload, className, children, Icon = FolderIcon, loading }: FileUploadProps) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onUpload });
    const hasPreview = files.filter((f) => f instanceof File).length > 0;
    const previewProps: FileUploadChildrenProps = { files };
    return (
        <div
            className={cn(
                styles.container,
                { [styles.hasPreview]: hasPreview, [styles.labelsVisible]: isDragActive },
                className
            )}
            {...getRootProps()}
        >
            {hasPreview && !loading && <div className={styles.previewContainer}>{children(previewProps)}</div>}
            <div className={styles.labelsContainer}>
                {loading && (
                    <div className={styles.label}>
                        <LoadingSpinner spinnerClassName={styles.spinner} />
                    </div>
                )}

                {!isDragActive && !loading && (
                    <div className={cn(styles.label, styles.uploadLabel)}>
                        <Icon className={styles.labelIcon} />
                        Drop a file here or click to browse
                        <input type="file" data-test-id="file-dropzone" {...getInputProps()} />
                    </div>
                )}

                {isDragActive && !loading && (
                    <div className={cn(styles.label, styles.dropActiveLabel)}>
                        <FolderAddIcon className={styles.labelIcon} />
                        Drop to upload an image
                    </div>
                )}
            </div>
        </div>
    );
};

export { default as ImagePreview } from './FileUploadImagePreview/FileUploadImagePreview';
export default FileUpload;
