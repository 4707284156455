/**
 * Get the exponent of a number. Exponent of 0 is -Infinity
 * @param n
 */
export const getExponent = (n: number) => Math.floor(Math.log10(Math.abs(n)));

export const formatYAxisLabels = (n: number, fractionDigits: number = 0) => {
    if (isNaN(n)) {
        console.log('Error: number is NaN');
        return '';
    }

    const exponent = getExponent(n);
    const format = (n: number) => {
        return parseFloat((n / Math.pow(10, exponent - (exponent % 3))).toFixed(fractionDigits));
    };

    const sliceRules = [
        {
            threshold: 3,
            exponentSign: '',
            format: (n: number) => n,
        },
        {
            threshold: 6,
            exponentSign: 'K',
            format,
        },
        {
            threshold: 9,
            exponentSign: 'M',
            format,
        },
        {
            threshold: Infinity,
            exponentSign: 'B',
            format,
        },
    ];

    const rule = sliceRules.find((r) => r.threshold > exponent);
    if (!rule) {
        return n.toString();
    }
    if (`${rule.format(n)}${rule.exponentSign}`.length === n.toString().length + 1) {
        return n.toString();
    }

    return `${rule.format(n)}${rule.exponentSign}`;
};
