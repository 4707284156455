import { AppServer, RouteHandlerContext } from '../mirage';
import { hasMany, Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { SpotifyArtist, SpotifySong } from './spotify.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../mirage.helpers';
import { GetSpotifyArtistsParams } from './spotify.api';

export const models = {
    spotifyArtist: Model as ModelDefinition<SpotifyArtist>,
    spotifySong: Model.extend({
        artists: hasMany('spotifyArtist'),
    }) as ModelDefinition<SpotifySong>,
};

export const factories = {
    spotifyArtist: createFactory<SpotifyArtist>({
        name(index: number) {
            return `Spotify artist name: ${index}`;
        },
        spotify_id(index: number) {
            return `Spotify artist spotify id: ${index}`;
        },
    }),
    spotifySong: createFactory<SpotifySong>({
        title(index: number) {
            return `title ${index}`;
        },
        spotify_id(index: number) {
            return `spotify id ${index}`;
        },
        artists() {
            return [];
        },
    }),
};

export const serializers = {
    spotifyArtist: makeSerializer<SpotifyArtist>([]),
    spotifySong: makeSerializer<SpotifySong>([]),
};

export function handleSpotifyRequests(server: AppServer) {
    const getSpotifyArtistsPath = '/api/spotify/viewsets/artist/';
    server.get(getSpotifyArtistsPath, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams as GetSpotifyArtistsParams;
        const artists = schema
            .all('spotifyArtist')
            .filter((a) => !search || a.name.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(artists, {
            url: getSpotifyArtistsPath,
            serialize: (resource) => this.serialize(resource, 'spotifyArtist'),
            queryParams: request.queryParams,
        });
    });

    server.get('/api/spotify/viewsets/artist/:id/', function (this: RouteHandlerContext, schema, request) {
        const artist = schema.find('spotifyArtist', request.params.id);
        if (!artist) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return artist;
    });

    const getSpotifySongsPath = '/api/spotify/viewsets/song/';
    server.get(getSpotifySongsPath, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const songs = schema
            .all('spotifySong')
            .filter((song) => !search || song.title.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(songs, {
            url: getSpotifyArtistsPath,
            serialize: (resource) => this.serialize(resource, 'spotifySong'),
            queryParams: request.queryParams,
        });
    });

    server.get('/api/spotify/viewsets/song/:id/', function (this: RouteHandlerContext, schema, request) {
        const song = schema.find('spotifySong', request.params.id);
        if (song) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return song;
    });
}
