import { DetailedHTMLProps, InputHTMLAttributes, useLayoutEffect, useRef } from 'react';
import styles from './AutoGrowthInput.module.css';
import cn from 'classnames';
import { SkeletonizedValue } from '@round/ui-kit';

type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    containerClassName?: string;
    isLoading?: boolean;
};

const AutoGrowthInput = ({ containerClassName, isLoading, ...props }: Props) => {
    const containerRef = useRef<HTMLSpanElement | null>(null);

    useLayoutEffect(() => {
        if (containerRef.current && props.value) {
            containerRef.current.dataset['value'] = props.value.toString();
        }
    }, [props.value]);

    return (
        <span ref={containerRef} className={cn(styles.container, containerClassName)}>
            <SkeletonizedValue containerClassName={styles.skeleton} isInitialized={!isLoading}>
                <input
                    {...props}
                    className={cn(styles.input, props.className)}
                    onChange={(e) => {
                        props.onChange?.(e);
                        if (containerRef.current) {
                            containerRef.current.dataset['value'] = e.target.value;
                        }
                    }}
                />
            </SkeletonizedValue>
        </span>
    );
};

export default AutoGrowthInput;
