import React, { useMemo } from 'react';
import { AggregatedDataByType } from '../helpers';
import { ChartProps, Doughnut } from 'react-chartjs-2';

type AudioTypesChartProps = {
    audios: AggregatedDataByType[];
    className?: string;
};

const AudioTypesChart = ({ audios, className }: AudioTypesChartProps) => {
    const total = audios.reduce((total, { numberOfPosts }) => total + numberOfPosts, 0);
    const data = useMemo(
        () =>
            ({
                labels: audios.map((v) => v.type),
                datasets: [
                    {
                        data: audios.map((audio) => {
                            if (total === 0) {
                                return 0;
                            }

                            return parseFloat(((audio.numberOfPosts * 100) / total).toFixed(2));
                        }),
                        backgroundColor: ['#54DB9150', '#F8642450', '#7A61DF50'],
                        borderColor: ['#54DB9150', '#F8642450', '#7A61DF50'],
                    },
                ],
            } as ChartProps<'doughnut', number[]>['data']),
        [audios, total]
    );

    const options: ChartProps<'doughnut', AggregatedDataByType[]>['options'] = useMemo(
        () => ({
            plugins: {
                tooltip: {
                    callbacks: {
                        title() {
                            return '';
                        },
                        label: (item) => {
                            return `${item.label}: ${item.parsed}%`;
                        },
                    },
                },
                legend: {
                    position: 'bottom',
                    align: 'start',
                    labels: {
                        boxWidth: 5,
                        padding: 5,
                    },
                },
            },
        }),
        []
    );

    return (
        <div className={className}>
            <Doughnut data={data} options={options} />
        </div>
    );
};

export default AudioTypesChart;
