import { creatorbase } from '@round/api';
import { combineReducers } from 'helpers';
import {
    makeProjectsListReducer,
    makeInitialState as makeProjectsListInitialState,
} from 'Modules/Plans/Project/hooks/dataHooks/useProjectsListDataHook';
import {
    makeTeamsReducer,
    initialState as teamsInitialState,
} from 'Modules/Plans/Team/hooks/dataHooks/useTeamsDataHook';
import {
    makeBrandsReducer,
    initialState as brandsInitialState,
} from 'Modules/Plans/Brand/hooks/dataHooks/useBrandsDataHook';
import {
    reducer as usersReducer,
    initialState as usersInitialState,
} from 'Modules/Plans/User/hooks/dataHooks/useUsersDataHook';

export type State = ReturnType<typeof reducer>;
export type Actions = Parameters<typeof reducer>[1];

const projectsListReducer = makeProjectsListReducer<creatorbase.Project>();
const projectsListInitialState = makeProjectsListInitialState<creatorbase.Project>();

export const initialState: State = {
    projectsList: projectsListInitialState,
    teams: teamsInitialState,
    brands: brandsInitialState,
    assignees: usersInitialState,
};

export const reducer = combineReducers({
    projectsList: projectsListReducer,
    teams: makeTeamsReducer<creatorbase.Team>(),
    brands: makeBrandsReducer<creatorbase.Brand>(),
    assignees: usersReducer,
});
