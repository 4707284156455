import { getTiktokUserImages, TiktokUserImage } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<TiktokUserImage[]>;

const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export function useTiktokUserImages() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (tiktokUserIds: number[], requestInit?: RequestInit) => {
        if (!tiktokUserIds?.length) {
            return;
        }

        setState((prev) => ({ data: prev.data, error: null, status: 'loading' }));
        try {
            const incomingTiktokImages = await getTiktokUserImages(tiktokUserIds, requestInit);

            setState((prev) => ({
                data: (prev.data ?? []).concat(incomingTiktokImages),
                status: 'success',
                error: null,
            }));
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                setState((prev) => ({ data: prev.data, error: null, status: 'idle' }));
                return;
            }
            setState((prev) => ({
                data: prev.data,
                status: 'error',
                error: 'something went wrong getting user images',
            }));
        }
    }, []);

    const reset = () => {
        setState(initialState);
    };

    return { ...state, fetchData, reset };
}
