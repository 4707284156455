import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokMicrowaveInfluencerPost } from './tiktokPosts.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokMicrowaveInfluencerPost: Model as ModelDefinition<TiktokMicrowaveInfluencerPost>,
};

export const serializers = {
    tiktokMicrowaveInfluencerPost: makeSerializer<TiktokMicrowaveInfluencerPost>([]),
};

export const factories = {
    tiktokMicrowaveInfluencerPost: createFactory<TiktokMicrowaveInfluencerPost>({
        post_url: (index: number) => `post url ${index}`,
        plan: (index: number) => index,
    }),
};

export function handleRequests(server: AppServer) {
    const baseUrl = '/api/microwave/tiktok-posts/';
    server.post(baseUrl, function (this: RouteHandlerContext, schema, request) {
        const body = JSON.parse(request.requestBody);
        return schema.create('tiktokMicrowaveInfluencerPost', {
            plan: body.plan,
            post_url: body.post_url,
        });
    });

    server.get(baseUrl, function (this: RouteHandlerContext, schema, request) {
        const posts = schema.all('tiktokMicrowaveInfluencerPost');

        return buildPaginatedResponse(posts, {
            url: baseUrl,
            serialize: (resource) => this.serialize(resource, 'tiktokMicrowaveInfluencerPost'),
            queryParams: request.queryParams,
        });
    });
}
