import { PaginatedApiResponseData } from '../../types';
import { InfluencerTag } from './influencerTag.types';
import { fetchWithToken } from '../../helpers';

export async function getAllInfluencerTags(
    requestInit?: RequestInit
): Promise<PaginatedApiResponseData<InfluencerTag>> {
    const response = await fetchWithToken(`/api/advertising/viewsets/influencer-tag/`, requestInit);

    if (!response.ok) {
        throw new Error(`Could not fetch ${response.url}`);
    }

    return response.json();
}
