import { ReactComponent as PencilIcon } from 'assets/whitelabel/Pencil.svg';
import { ButtonShell, ControlledPopover } from '@round/ui-kit';
import Input from 'ui-new/whitelabel/Input/Input';
import { useState } from 'react';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import styles from './UpdateInfluencerPopout.module.css';
import cn from 'classnames';
import { showNotification } from 'helpers';
import { creatorbase } from '@round/api';
import Spinner from 'ui-new/Loading/Spinner/Spinner';

type Props = {
    accountHandle: string;
    updateAccountHandle?: (accountHandle: string) => ReturnType<typeof creatorbase.patchPost>;
    className?: string;
};

const UpdateInfluencerPopout = ({ accountHandle: initialAccountHandle, updateAccountHandle, className }: Props) => {
    const [targetRef, setTargetRef] = useState<HTMLButtonElement | null>(null);
    const [popoutRef, setPopoutRef] = useState<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClickOutside = () => {
        if (!isOpen) {
            return;
        }

        if (popoutRef?.contains(document.activeElement) && document.activeElement instanceof HTMLInputElement) {
            document.activeElement?.blur();
            return;
        }

        setIsOpen(false);
    };

    useOnClickOutside([popoutRef], handleClickOutside);

    const [accountHandle, setAccountHandle] = useState(initialAccountHandle);
    const [isAccountHandleSubmitting, setIsAccountHandleSubmitting] = useState(false);

    const submitAccountHandle = async () => {
        if (!updateAccountHandle || accountHandle === initialAccountHandle) {
            return;
        }

        if (!accountHandle) {
            showNotification('Account handle is required', 'error');
            setAccountHandle(initialAccountHandle);
            return;
        }

        try {
            setIsAccountHandleSubmitting(true);
            const response = await updateAccountHandle(accountHandle);

            if (response.status === 200) {
                showNotification('Account updated', 'info');
                return;
            }

            const errorMessage =
                response.status === 400 && response.data.influencer_account_identifier
                    ? Array.isArray(response.data.influencer_account_identifier)
                        ? response.data.influencer_account_identifier.join('. ')
                        : response.data.influencer_account_identifier
                    : 'Failed to update account';

            showNotification(errorMessage, 'error');
            setAccountHandle(initialAccountHandle);
        } catch (e) {
            showNotification('Failed to update account', 'error');
            setAccountHandle(initialAccountHandle);
        } finally {
            setIsAccountHandleSubmitting(false);
        }
    };

    return (
        <>
            <ButtonShell
                className={cn(styles.button, className)}
                ref={setTargetRef}
                onClick={() => setIsOpen((prev) => !prev)}
                data-open={isOpen}
            >
                <PencilIcon />
            </ButtonShell>

            <ControlledPopover
                anchorElement={targetRef}
                isOpen={isOpen}
                options={{ placement: 'top', modifiers: [{ name: 'offset', options: { offset: [0, 12] } }] }}
                overlayClassName={styles.overlay}
            >
                <div className={styles.container} ref={setPopoutRef}>
                    <div className={styles.field}>
                        <label htmlFor="accountHandle" className={styles.label}>
                            Influencer
                        </label>
                        <Input
                            type="text"
                            id="accountHandle"
                            name="accountHandle"
                            placeholder="Account Handle"
                            value={accountHandle}
                            onChange={(e) => setAccountHandle(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    e.currentTarget.blur();
                                }
                            }}
                            onBlur={submitAccountHandle}
                            renderRight={() => isAccountHandleSubmitting && <Spinner className={styles.spinner} />}
                        />
                    </div>
                </div>
            </ControlledPopover>
        </>
    );
};

export default UpdateInfluencerPopout;
