import { TiktokInfluencerUser, TiktokUserStats, TiktokInfluencerPost } from '@round/api';
import styles from './MobileTiktokTable.module.css';
import { buildTiktokUserUrl, formatNumberToKNotation, openInNewTab } from '../../../../../../../helpers';
import Table, { TableProps } from '../../../../../../../ui/NewTable/Table';
import InfluencerPostStatus from '../../../../components/InfluencerPostStatus/InfluencerPostStatus';
import { ReactComponent as LinkIcon } from 'assets/icons/Link.svg';
import Image from '../../../../../../../ui/Image/Image';
import { ColumnWithLooseAccessor } from 'react-table';
import useNonNullContext from '../../../../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../../../../contexts/OptionsContext/OptionsContext';
import { useContext, useMemo, useState } from 'react';
import cn from 'classnames';
import CurrencyInput from 'react-currency-input-field';
import { InfluencerPlanContext } from '../../../../contexts/InfluencerPlanContext';
import { useCheckUserGroupsAccess } from '../../../../../../Auth/hooks/useCheckUserGroupsAccess';
import { EDITABLE_STATUSES } from '../../../../InfluencerPlan.helpers';

export type MobileTiktokTableRow = {
    post: TiktokInfluencerPost;
    influencer: TiktokInfluencerUser | null;
    userStats: TiktokUserStats | null;
    imageUrl: string | undefined;
};

type MobileTiktokTableProps = {
    data: MobileTiktokTableRow[];
    updateCost: (post: TiktokInfluencerPost, cost: string) => Promise<void>;
    updateNotes: (post: TiktokInfluencerPost, notes: string) => Promise<void>;
    updateContactDetails: (influencer: TiktokInfluencerUser, contactDetails: string) => Promise<void>;
    onEditStatusClick: (post: TiktokInfluencerPost) => void;
};

type TableColumn = ColumnWithLooseAccessor<MobileTiktokTableRow> & {
    editorCell?: boolean;
};
const renderRow: TableProps<any>['renderRow'] = ({ row, rowProps, cellProps }) => (
    <tr {...rowProps} {...row.getRowProps()}>
        {row.cells.map((cell) => (
            <td
                {...cellProps}
                className={cn({
                    [styles.editorCell]: (cell.column as TableColumn).editorCell,
                })}
            >
                {cell.render('Cell')}
            </td>
        ))}
    </tr>
);

const handleEnterKeyDown = (e: any) => {
    if (e.key === 'Enter') {
        e.currentTarget.blur();
    }
};

const MobileTiktokTable = ({
    data,
    updateContactDetails,
    updateCost,
    updateNotes,
    onEditStatusClick,
}: MobileTiktokTableProps) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const { influencerPlan } = useContext(InfluencerPlanContext);

    const canEdit = useCheckUserGroupsAccess(['influencer_editor']);

    const columns: TableColumn[] = useMemo(
        () => [
            {
                Header: 'Account',
                Cell: ({ row }) => {
                    const influencer = row.original.influencer;
                    const userStats = row.original.userStats;
                    return (
                        <div className={styles.accountContainer}>
                            {!!row.original.imageUrl && (
                                <Image
                                    className={styles.influencerThumbnail}
                                    src={row.original.imageUrl}
                                    alt="avatar"
                                    crossOrigin="anonymous"
                                />
                            )}
                            <div className={styles.accountDetails}>
                                <span>
                                    {!!influencer?.username ? (
                                        <a
                                            onClick={(e) => e.stopPropagation()}
                                            className={styles.influencer}
                                            href={buildTiktokUserUrl(influencer.username)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            @{influencer.username}
                                        </a>
                                    ) : (
                                        '-'
                                    )}
                                </span>
                                <span>
                                    {userStats?.follower_count
                                        ? `${formatNumberToKNotation(userStats.follower_count)} followers`
                                        : '-'}
                                </span>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Status',
                Cell: ({ row }) => {
                    const post = row.original.post;
                    const postStatusEditable = EDITABLE_STATUSES.includes(post.status) && Boolean(canEdit);
                    return (
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                if (post.status === 'live') {
                                    openInNewTab(post.post_url);
                                }

                                if (!postStatusEditable) {
                                    return;
                                }

                                onEditStatusClick(post);
                            }}
                        >
                            <div className={styles.statusContainer}>
                                <button className={styles.statusButton}>
                                    <InfluencerPostStatus
                                        className={styles.status}
                                        status={post.status}
                                        draftExpectedBy={post.draft_expected_by}
                                    />
                                </button>
                                {post.status === 'live' && <LinkIcon className={styles.linkIcon} />}
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Contact',
                editorCell: true,
                Cell: ({ row }) => {
                    const influencer = row.original.influencer;
                    const [contactDetails, setContactDetails] = useState(influencer?.contact_details || '');

                    return (
                        <input
                            placeholder="Enter contact details"
                            className={cn(styles.input, styles.inputWide)}
                            disabled={!influencer}
                            value={contactDetails}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={handleEnterKeyDown}
                            onChange={(e) => setContactDetails(e.target.value)}
                            onBlur={() => {
                                if (!influencer || influencer.contact_details === contactDetails) {
                                    return;
                                }

                                updateContactDetails(influencer, contactDetails).catch(() => {
                                    setContactDetails(influencer.contact_details || '');
                                });
                            }}
                        />
                    );
                },
            },
            {
                Header: 'Cost',
                editorCell: true,
                Cell: ({ row }) => {
                    const post = row.original.post;
                    const [cost, setCost] = useState(post.cost);

                    const postCurrencyId = post.currency_id;
                    const currency = currencies.find((curr) => curr.id === postCurrencyId);

                    return (
                        <CurrencyInput
                            className={cn(styles.input, styles.costInput, {
                                [styles.invalid]: postCurrencyId !== influencerPlan?.currency.id,
                            })}
                            prefix={currency?.symbol}
                            value={cost}
                            onValueChange={(val) => setCost(val ?? cost)}
                            onKeyDown={handleEnterKeyDown}
                            onBlur={() => {
                                if (Number(cost) === Number(post.cost)) {
                                    return;
                                }

                                updateCost(post, cost ?? '0').catch(() => {
                                    setCost(post.cost);
                                });
                            }}
                            groupSeparator=","
                            decimalSeparator="."
                            allowDecimals={false}
                            decimalScale={0}
                            decimalsLimit={0}
                            disabled={post.payment_status === 'PAID'}
                        />
                    );
                },
            },
            {
                Header: 'Notes',
                editorCell: true,
                Cell: ({ row }) => {
                    const post = row.original.post;
                    const [notes, setNotes] = useState(post.notes || '');

                    return (
                        <textarea
                            rows={2}
                            className={cn(styles.input, styles.inputWide)}
                            placeholder={'-'}
                            value={notes}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => setNotes(e.target.value)}
                            onBlur={(e) => {
                                if (post.notes === notes) {
                                    return;
                                }

                                updateNotes(row.original.post, notes).catch(() => {
                                    setNotes(post.notes || '');
                                });
                            }}
                            onKeyDown={handleEnterKeyDown}
                        />
                    );
                },
            },
        ],
        [currencies, influencerPlan, canEdit, updateContactDetails, updateCost, updateNotes, onEditStatusClick]
    );

    const influencerViewerColumns = columns.filter((column) => !column.editorCell);
    const columnsToUse = canEdit ? columns : influencerViewerColumns;

    return (
        <Table columns={columnsToUse} data={data} tableClassName={styles.table} disableSortBy renderRow={renderRow} />
    );
};

export default MobileTiktokTable;
