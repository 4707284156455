import { AppServer, RouteHandlerContext } from '../../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AdminClient } from './client.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';

export const models = {
    adminClient: Model as ModelDefinition<AdminClient>,
};

export const serializers = {
    adminClient: makeSerializer<AdminClient>([]),
};

export const factories = {
    adminClient: createFactory<AdminClient>({
        name(index: number) {
            return `name ${index}`;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const getAdminClientListUrl = '/api/apex/admin/client/';
    server.get(getAdminClientListUrl, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const clients = schema
            .all('adminClient')
            .filter((client) => !search || client.name.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(clients, {
            url: getAdminClientListUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'adminClient'),
        });
    });
}
