import React from 'react';
import styles from './OrderByTableHeading.module.css';
import { OrderByQueryParam } from '../../App.types';
import { ReactComponent as OrderByAsc } from '../svg/OrderByAsc.svg';
import { ReactComponent as OrderByDefault } from '../svg/OrderByDefault.svg';
import { ReactComponent as OrderByDesc } from '../svg/OrderByDesc.svg';
import cn from 'classnames';
import { FCWithChildren } from '../../utility/utility.types';

const OrderByTableHeading: FCWithChildren<{
    className?: string;
    fieldName: string;
    orderByState: [OrderByQueryParam, (orderBy: OrderByQueryParam) => void];
}> = ({ className, children, fieldName, orderByState }) => {
    const [orderBy, setOrderBy] = orderByState;
    const isOrderedByDesc = 'order_by_desc' in orderBy && orderBy.order_by_desc === fieldName;
    const isOrderedByAsc = 'order_by_asc' in orderBy && orderBy.order_by_asc === fieldName;
    const isNotOrdered = !isOrderedByDesc && !isOrderedByAsc;
    const toggleOrderBy = (fieldName: string) => {
        if (isOrderedByDesc) {
            setOrderBy({ order_by_asc: fieldName });
        } else {
            setOrderBy({ order_by_desc: fieldName });
        }
    };
    return (
        <th className={cn(styles.th, className)} onClick={() => toggleOrderBy(fieldName)}>
            <div className={styles.cell}>
                {children}
                {isOrderedByDesc && <OrderByDesc />}
                {isOrderedByAsc && <OrderByAsc />}
                {isNotOrdered && <OrderByDefault />}
            </div>
        </th>
    );
};

export default OrderByTableHeading;
