import React, { forwardRef } from 'react';
import CustomDatePicker from '../../../SharedComponents/CustomDatePicker/CustomDatePicker';
import { formatDate } from '../../../helpers';
import styles from './DatePicker.module.css';
import cn from 'classnames';

type DatePickerProps = {
    value: string;
    onChange: (datetime: string) => void;
    dateTimeFormatOptions?: Intl.DateTimeFormatOptions;
    disabled?: boolean;
    className?: string;
};

type GetDefaultDateInputProps = {
    dateTimeFormatOptions?: Intl.DateTimeFormatOptions;
    className?: string;
};

const getDefaultDateInput = ({ dateTimeFormatOptions, className }: GetDefaultDateInputProps) =>
    forwardRef<
        HTMLButtonElement,
        { value: string; onClick: () => void; dateTimeFormatOptions?: Intl.DateTimeFormatOptions }
    >((props, ref) => {
        return (
            <button type="button" ref={ref} onClick={props.onClick} className={cn(styles.input, className)}>
                {formatDate(new Date(Number(props.value)).toISOString(), dateTimeFormatOptions)}
            </button>
        );
    });

const DatePicker = ({
    disabled,
    value,
    onChange,
    className,
    dateTimeFormatOptions = { timeZone: 'Europe/London', day: '2-digit', month: 'short', year: undefined },
}: DatePickerProps) => {
    return (
        <CustomDatePicker
            disabled={disabled}
            value={new Date(value)}
            onChange={(date) =>
                onChange(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)).toISOString())
            }
            customInput={React.createElement(getDefaultDateInput({ dateTimeFormatOptions, className }))}
            dateFormat="T"
        />
    );
};

export default DatePicker;
