import { getAllInfluencerTags } from '@round/api';
import { useCallback, useState } from 'react';
import { OptionsContext, OptionsDispatchContext } from '../contexts/OptionsContext/OptionsContext';
import useAbortableEffect from './useAbortableEffect';
import useNonNullContext from './useNonNullContext';

export function useInfluencerTags() {
    const { influencerTags, tagsInitialized } = useNonNullContext(OptionsContext);
    const dispatch = useNonNullContext(OptionsDispatchContext);
    const [errorLoadingTags, setErrorLoadingTags] = useState(false);
    const [tagsLoading, setTagsLoading] = useState(false);

    const fetchTags = useCallback(
        async (requestInit: RequestInit) => {
            if (tagsInitialized) {
                return;
            }
            try {
                setErrorLoadingTags(false);
                setTagsLoading(true);
                const tagsResponse = await getAllInfluencerTags(requestInit);
                dispatch({ type: 'influencerTagsLoaded', payload: tagsResponse.results });
            } catch {
                setErrorLoadingTags(true);
            } finally {
                setTagsLoading(false);
            }
        },
        [dispatch, tagsInitialized]
    );

    useAbortableEffect(
        (signal) => {
            fetchTags({ signal });
        },
        [fetchTags]
    );

    return {
        influencerTags,
        errorLoadingTags,
        tagsLoading,
    };
}
