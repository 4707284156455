import { microwave } from '@round/api';
import { DataState } from 'App.types';
import useNonNullContext from 'Hooks/useNonNullContext';
import { useCallback } from 'react';
import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

type InstagramCampaignStatsPayload = microwave.InstagramCampaignStats & { instagramCampaignId: number };
type State = DataState<InstagramCampaignStatsPayload[]>;
const initialState: State = { status: 'idle', data: null, error: null };

const InstagramCampaignStatsContext = createContext<[State, Dispatch<SetStateAction<State>>] | null>(null);

type Props = { children?: ReactNode | undefined };
export const InstagramCampaignStatsProvider = ({ children }: Props) => {
    const state = useState<State>(initialState);

    return <InstagramCampaignStatsContext.Provider value={state}>{children}</InstagramCampaignStatsContext.Provider>;
};

export function useInstagramCampaignStats() {
    const [state, setState] = useNonNullContext(InstagramCampaignStatsContext);

    const fetchData = useCallback(
        async (campaignIds: number[], requestInit?: RequestInit) => {
            if (!campaignIds.length) {
                setState({ status: 'success', data: [], error: null });
                return;
            }

            try {
                const stats: InstagramCampaignStatsPayload[] = await Promise.all(
                    campaignIds.map(async (campaignId) => {
                        const response = await microwave.getInstagramCampaignStats(campaignId, requestInit);
                        if (response.status === 200) {
                            return { instagramCampaignId: campaignId, ...response.data };
                        }

                        throw new Error(response.data.detail);
                    })
                );

                setState({ status: 'success', data: stats, error: null });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                setState({
                    status: 'error',
                    data: null,
                    error: e instanceof Error ? e.message : 'Could not fetch instagram campaign stats',
                });
                throw e;
            }
        },
        [setState]
    );

    const reset = useCallback(() => setState(initialState), [setState]);

    return {
        ...state,
        fetchData,
        reset,
    };
}
