import { components, OptionProps } from 'react-select';
import { GenericDropdownOption } from 'App.types';
import { InvoiceRequestStatus } from '@round/api';
import React from 'react';
import StatusBadge from './StatusBadge';

type Props<IsMulti extends boolean> = OptionProps<GenericDropdownOption<InvoiceRequestStatus>, IsMulti>;

const StatusBadgeOption = <IsMulti extends boolean>(props: Props<IsMulti>) => (
    <components.Option {...props}>
        <StatusBadge status={props.data.value} />
    </components.Option>
);

export default StatusBadgeOption;
