import useNonNullContext from 'Hooks/useNonNullContext';
import { DropdownContext } from '../Dropdown';
import { ReactComponent as DotsVerticalIcon } from 'assets/whitelabel/DotsVertical.svg';
import cn from 'classnames';
import styles from '../Dropdown.module.css';

type Props = {
    className?: string;
};

export const Target = ({ className }: Props) => {
    const { setAnchorElement, setIsOpen } = useNonNullContext(DropdownContext);
    return (
        <DotsVerticalIcon
            className={cn(className, styles.target)}
            ref={setAnchorElement}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
        />
    );
};
