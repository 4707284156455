import { AppServer, RouteHandlerContext } from '../../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AdminClientUser } from './clientUser.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';
import { PostAdminClientUserData } from './clientUser.api';

export const models = {
    adminClientUser: Model as ModelDefinition<AdminClientUser>,
};

export const serializers = {
    adminClientUser: makeSerializer<AdminClientUser>([]),
};

export const factories = {
    adminClientUser: createFactory<AdminClientUser>({
        client_name(index: number) {
            return `client name ${index}`;
        },
        email(index: number) {
            return `email ${index}`;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const getAdminClientUserListUrl = '/api/apex/admin/client-user/';
    server.get(getAdminClientUserListUrl, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const clientUsers = schema
            .all('adminClientUser')
            .filter((u) => !search || u.client_name.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(clientUsers, {
            url: getAdminClientUserListUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'adminClientUser'),
        });
    });

    server.post('/api/apex/admin/client-user/', function (this: RouteHandlerContext, schema, request) {
        const data = JSON.parse(request.requestBody) as PostAdminClientUserData;
        const client = schema.find('adminClient', data.client.toString());
        schema.create('adminClientUser', {
            client_name: client?.name || '',
            email: data.email,
        });

        return schema.findBy('adminClientUser', { email: data.email });
    });
}
