import { BaseFilterObject } from 'App.types';
import {
    InstagramInfluencerUserOption,
    useInstagramInfluencerUsersSelect,
} from 'Modules/Instagram/hooks/selectHooks/useInstagramInfluencerUsersSelect';
import { ValueType } from 'react-select';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import { Image } from '@round/ui-kit';
import styles from './InstagramInfluencerUserFilter.module.css';

type Props = {
    initialInfluencerUserId?: number | undefined;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onRemove: () => void;
    onClear: () => void;
    onChange: (value: ValueType<InstagramInfluencerUserOption, false>) => void;
} & Pick<BaseFilterObject, 'name' | 'icon'>;

const InstagramInfluencerUsersFilter = ({
    initialInfluencerUserId,
    icon,
    name,
    isOpen,
    onOpen,
    onClose,
    onClear,
    onChange,
    onRemove,
}: Props) => {
    const { props: selectProps } = useInstagramInfluencerUsersSelect({
        initialId: initialInfluencerUserId,
        initOn: 'mount',
    });

    const clearState = () => {
        selectProps.onChange?.(null);
    };

    return (
        <SelectFilter
            name={name}
            icon={icon}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            onClear={() => {
                onClear();
                clearState();
            }}
            onRemove={() => {
                clearState();
                onRemove();
            }}
            {...selectProps}
            onChange={(value): void => {
                selectProps.onChange(value);
                onChange(value);
            }}
            formatOptionLabel={(option, meta) => {
                if (meta.context === 'value') {
                    return option.label;
                }

                const accountHandle = option.accountHandle ? `@${option.accountHandle}` : '-';
                return (
                    <div className={styles.container}>
                        <Image src={option.imageUrl} className={styles.image} />
                        <div className={styles.titles}>
                            <p className={styles.title}>{option.label}</p>
                            <p className={styles.subTitle}>{accountHandle}</p>
                        </div>
                    </div>
                );
            }}
        />
    );
};

export default InstagramInfluencerUsersFilter;
