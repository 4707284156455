import useAbortableEffect from 'Hooks/useAbortableEffect';
import useUrlState from 'Hooks/useUrlState';
import { microwave, OrderingValues } from '@round/api';
import MicroCampaignsTable, { MicroCampaignsTableRow } from './MicroCampaignsTable/MicroCampaignsTable';
import { useMicrowaveCampaignsList } from './useMicrowaveCampaignsList';
import styles from './MicroCampaigns.module.css';
import { useMemo } from 'react';
import Button from 'ui/Button/Button';
import { useState } from 'react';
import CreateCampaignModal from './CreateCampaignModal/CreateCampaignModal';
import MicroCampaignsFilters, { getFilterValues } from './MicroCampaignsFilters/MicroCampaignsFilters';
import EditCampaignModal from './EditCampaignModal/EditCampaignModal';

type UrlState = microwave.GetReleasesParams;

const initialUrlState: Pick<Required<UrlState>, 'page' | 'page_size' | 'ordering'> = {
    page: 1,
    page_size: 10,
    ordering: '-latest_campaign_live_date',
};

const MicroCampaigns = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState);
    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const pageSize = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;
    const ordering = useMemo(
        () =>
            urlState.ordering
                ?.split(',')
                ?.filter((ordering): ordering is OrderingValues<microwave.GetReleasesSortableKeys> =>
                    microwave.isGetReleasesOrderingValid(ordering)
                ) ?? [],
        [urlState.ordering]
    );
    const filters = useMemo(() => getFilterValues(urlState), [urlState]);

    const {
        fetchData,
        data,
        status,
        reset: resetListData,
        createCampaign,
        updateCampaign,
    } = useMicrowaveCampaignsList();

    const [isCreateCampaignModalOpen, setIsCreateCampaignModalOpen] = useState(false);
    const [isEditCampaignModalOpen, setIsEditCampaignModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<MicroCampaignsTableRow | null>(null);

    useAbortableEffect(
        (signal) => {
            const isInitialized = status === 'initialized' || status === 'error';
            if (!isInitialized) {
                fetchData(
                    {
                        page,
                        page_size: pageSize,
                        ordering: ordering.length ? ordering[0] : undefined,
                        ...filters,
                    },
                    { signal }
                );
            }
        },
        [fetchData, status, page, pageSize, ordering, filters]
    );

    return (
        <>
            <div className={styles.header}>
                <h1 className={styles.title}>All campaigns</h1>

                <div className={styles.actions}>
                    <MicroCampaignsFilters
                        value={filters}
                        onChange={(val) => {
                            setUrlState({ ...val, page: 1 });
                            resetListData();
                        }}
                    />

                    <Button appearance="primary" onClick={() => setIsCreateCampaignModalOpen(true)}>
                        Create Campaign
                    </Button>
                </div>
            </div>

            <MicroCampaignsTable
                data={data.rows}
                page={page}
                setPage={(page) => {
                    setUrlState({ page });
                    resetListData();
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                    setUrlState({ page_size: pageSize || initialUrlState.page_size });
                    resetListData();
                }}
                count={data.count}
                isLoading={status === 'loading' || status === 'not-initialized'}
                noDataLabel={status === 'error' ? 'Error loading campaigns' : 'No campaigns found'}
                ordering={ordering}
                onOrderingChange={(ordering) => {
                    const orderingValue = ordering[0] || undefined;
                    setUrlState({ ordering: orderingValue });
                    resetListData();
                }}
                onClickEdit={(row) => {
                    setSelectedRow(row);
                    setIsEditCampaignModalOpen(true);
                }}
            />

            <CreateCampaignModal
                isOpen={isCreateCampaignModalOpen}
                onClose={() => setIsCreateCampaignModalOpen(false)}
                createCampaign={createCampaign}
            />

            <EditCampaignModal
                isOpen={isEditCampaignModalOpen}
                onClose={() => {
                    setIsEditCampaignModalOpen(false);
                    setSelectedRow(null);
                }}
                campaign={selectedRow}
                relatedCampaigns={data.rows.filter(
                    (row) => row.id !== selectedRow?.id && row.release.id === selectedRow?.release.id
                )}
                updateCampaign={updateCampaign}
            />
        </>
    );
};

export default MicroCampaigns;
