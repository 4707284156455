import { ApiResponse, ApiResponseError, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { Artist, ArtistSimple } from './artist.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';

type GetArtistsParams = Partial<PaginatedRequest & { search: string; id: string }>;
export async function getArtists(
    params: GetArtistsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<Artist>, 200>> {
    const response = await fetchWithToken(`/api/music/viewsets/artist/${encodeUrlSearchParams(params)}`, requestInit);

    if (!response.ok) {
        throw new Error('Could not fetch artists');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetArtistResponse = ApiResponse<Artist, 200> | ApiResponse<{ detail: string }, 404>;

export async function getArtist(artistId: number, requestInit?: RequestInit): Promise<GetArtistResponse> {
    const response = await fetchWithToken(`/api/music/viewsets/artist/${artistId}/`, requestInit);
    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not retrieve artist with id ${artistId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type ArtistBody = Omit<Artist, 'id' | 'genres' | 'image'>;
type ArtistResponse = ApiResponse<Artist, 201> | ApiResponse<ApiResponseError<PostArtistBody>, 400>;

type PostArtistBody = ArtistBody;
type PostArtistResponse = ArtistResponse;

export async function postArtist(data: PostArtistBody): Promise<PostArtistResponse> {
    const response = await fetchWithToken('/api/music/viewsets/artist/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not POST music artist');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type PatchArtistBody = ArtistBody;
type PatchArtistResponse = ArtistResponse;
export async function patchArtist(artistId: number, data: PatchArtistBody): Promise<PatchArtistResponse> {
    const response = await fetchWithToken(`/api/music/viewsets/artist/${artistId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not PATCH music artist');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type UploadArtistImageResponse = ApiResponse<Artist, 201>;
export async function uploadArtistImage(artistId: number, image: File): Promise<UploadArtistImageResponse> {
    const formData = new FormData();
    formData.append('new_image', image);
    const response = await fetchWithToken(`/api/music/viewsets/artist/${artistId}/`, {
        method: 'PATCH',
        body: formData,
    });

    if (!response.ok) {
        throw new Error('Could not upload artist image');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type CreateArtistFromSpotifyResponse = ApiResponse<ArtistSimple, 201> | ApiResponse<{ error: string }, 400>;

export async function createArtistFromSpotify(spotifyUrl: string): Promise<CreateArtistFromSpotifyResponse> {
    const response = await fetchWithToken('/api/music/viewsets/artist/create-from-spotify/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ spotify_identifier: spotifyUrl }),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create artist from spotify');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type RefreshArtistFromSpotifyResponse =
    | ApiResponse<{ detail: string }, 404>
    | ApiResponse<{ error: string }, 400>
    | ApiResponse<ArtistSimple, 200>;

export async function refreshArtistFromSpotify(
    artistId: number,
    spotifyUrl?: string
): Promise<RefreshArtistFromSpotifyResponse> {
    const response = await fetchWithToken(`/api/music/viewsets/artist/${artistId}/refresh-from-spotify/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(spotifyUrl ? { spotify_identifier: spotifyUrl } : {}),
    });

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not refresh artist ${artistId} from spotify`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
