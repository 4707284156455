import React, { memo, useState } from 'react';
import styles from './AssetsLibraryModalVideo.module.css';
import { Video } from '../../AssetsLibraryModal.types';
import AcceptButton from '../../../../ui/Buttons/AcceptButton/AcceptButton';
import DeleteButton from '../../../../ui/Buttons/DeleteButton/DeleteButton';
import cn from 'classnames';
import { Label } from '../../../../SharedComponents/Forms/Label/Label';
import TextArea from '../../../../ui/TextArea/TextArea';
import { FCWithChildren } from '../../../../utility/utility.types';

interface AssetsLibraryModalVideoProps {
    video: Video;
    inProgress: boolean;
    onChangeName: (video: Video, name: string) => void;
    onChangeNotes: (video: Video, notes: string) => void;
    onAccept: (video: Video) => void;
    onDecline: (video: Video) => void;
    onDelete: (video: Video) => void;
}

const AssetsLibraryModalVideo: FCWithChildren<AssetsLibraryModalVideoProps> = ({
    video,
    video: { name, get_file_url, is_approved, notes },
    inProgress,
    onChangeName,
    onChangeNotes,
    onAccept,
    onDecline,
    onDelete,
}) => {
    const [inputName, setInputName] = useState(name);
    const [inputNotes, setInputNotes] = useState(notes);

    return (
        <div className={styles.videosItem}>
            <div className={styles.videosItemHeader}>
                <input
                    type="text"
                    value={inputName}
                    onChange={({ target: { value } }) => setInputName(value)}
                    onBlur={({ target: { value } }) => (value ? onChangeName(video, value) : setInputName(video.name))}
                />
                <AcceptButton
                    accepted={is_approved}
                    className={cn(styles.videosItemBtnAccept, {
                        [styles.videosItemBtnAccepted]: is_approved,
                        [styles.videosItemBtnAcceptInProgress]: inProgress,
                    })}
                    onClick={() => (!is_approved ? onAccept(video) : onDecline(video))}
                />
                <DeleteButton
                    className={cn(styles.videosItemBtnDelete, {
                        [styles.videosItemBtnDeleteInProgress]: inProgress,
                    })}
                    onClick={() => onDelete(video)}
                />
            </div>
            {get_file_url && (
                <div className={styles.videosItemWrapper}>
                    <video controls>
                        <source src={get_file_url} />
                    </video>
                </div>
            )}
            <Label htmlFor="notes">Notes</Label>
            <TextArea
                value={inputNotes}
                placeholder="Notes for declined video"
                disabled={is_approved}
                onChange={({ target: { value } }) => setInputNotes(value)}
                onBlur={({ target: { value } }) => (value ? onChangeNotes(video, value) : setInputNotes(notes))}
            />
        </div>
    );
};

export default memo(AssetsLibraryModalVideo);
