import { CellContext } from '@tanstack/react-table';
import { creatorbase } from '@round/api';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { ProjectTableMeta } from '../../types';
import styles from './ProjectCell.module.css';
import TruncationPopover from 'ui-new/TruncationPopover/TruncationPopover';

type Row = creatorbase.Project & {
    team: creatorbase.Team | null;
};

type Context<T extends Row> = CellContext<T, T['name']>;

const getTableMeta = getTableMetaHelper<ProjectTableMeta<Row>>();

const ProjectCell = <T extends Row>({ getValue, row: { original }, table }: Context<T>) => {
    const { getIsRowLoading } = getTableMeta(table);

    if (getIsRowLoading(original)) {
        return <Skeleton width="10rem" />;
    }

    const projectName = getValue();
    const teamName = original.team?.name;
    return (
        <div className={styles.cell}>
            <TruncationPopover content={projectName}>{(setRef) => <p ref={setRef}>{projectName}</p>}</TruncationPopover>

            <p className={styles.subValue}>{teamName}</p>
        </div>
    );
};

export default ProjectCell;
