import React, { useMemo } from 'react';
import styles from './MobilePostGroupBar.module.css';
import { ReactComponent as ArrowIcon } from '../../../../../../../assets/ArrowIcon.svg';
import cn from 'classnames';
import { ReactComponent as CopyIcon } from '../../../../../../../assets/CopyIcon.svg';
import { InstagramInfluencerPost } from '../../../../types/Instagram.types';
import { postInactiveStatuses } from '../../../../InfluencerPlan.helpers';
import { InfluencerPostGroup, TiktokInfluencerPost } from '@round/api';
import { TiktokInfluencerPostGroupStatsWithId } from '../../../../contexts/TiktokCreatorsContext/reducer';
import { InstagramInfluencerPostGroupStatsWithId } from '../../../../contexts/InstagramCreatorsContext/reducer';
import { ProtectedByUserGroups } from 'SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';

type MobilePostGroupBarProps = {
    platform: 'tiktok' | 'instagram';
    group: InfluencerPostGroup;
    groupStats?: TiktokInfluencerPostGroupStatsWithId | InstagramInfluencerPostGroupStatsWithId;
    expandedGroups: number[];
    items?: (InstagramInfluencerPost | TiktokInfluencerPost)[];
    checkGroup: (position: number) => void;
    copyBrief: (group: InfluencerPostGroup) => void;
};

export const MobilePostGroupBar = ({
    platform,
    group,
    expandedGroups,
    items = [],
    checkGroup,
    copyBrief,
    groupStats,
}: MobilePostGroupBarProps) => {
    const activePosts = useMemo(() => items?.filter((post) => !postInactiveStatuses.includes(post.status)), [items]);
    const postCountText = useMemo(() => {
        if (!groupStats) {
            return activePosts.length === 0
                ? '0 posts'
                : `${activePosts.filter((item) => item.status === 'live').length}/${activePosts.length} post${
                      activePosts?.length !== 1 ? 's' : ''
                  } live`;
        }

        const postCount = groupStats.post_count;
        const livePostCount = groupStats.live_post_count;
        return postCount === 0 ? '0 posts' : `${livePostCount}/${postCount} post${postCount !== 1 ? 's' : ''} live`;
    }, [activePosts, groupStats]);

    return (
        <section
            className={cn(styles.wrapper, {
                [styles.groupHidden]: group.is_hidden,
            })}
            data-test-id={`influencer-post-group-${group.id}`}
        >
            <button
                data-test-id={`expand-group-${group.id}-button`}
                className={cn(styles.button, styles.expandButton, {
                    [styles.expanded]: expandedGroups.includes(group.id),
                })}
                onClick={() => checkGroup(group.id)}
            >
                <ArrowIcon />
            </button>

            <div className={styles.infoColumnContainer}>
                <div className={styles.infoColumn}>
                    <span className={styles.heading}>{group.name}</span>
                    <span className={styles.value}>{postCountText}</span>
                </div>
            </div>
            <ProtectedByUserGroups groups={['influencer_editor']}>
                <button className={cn(styles.button, styles.copyButton)} onClick={() => copyBrief(group)}>
                    <CopyIcon />
                </button>
            </ProtectedByUserGroups>
        </section>
    );
};
