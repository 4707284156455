import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokVideoStats } from './videoStats.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokVideoStats: Model as ModelDefinition<TiktokVideoStats>,
};

export const serializers = {
    tiktokVideoStats: makeSerializer<TiktokVideoStats>([]),
};

export const factories = {
    tiktokVideoStats: createFactory<TiktokVideoStats>({
        video_id(index: number) {
            return index;
        },
        timestamp: new Date().toISOString(),
        comment_count: 0,
        digg_count: 0,
        play_count: 0,
        share_count: 0,
    }),
};

export function handleRequests(server: AppServer) {
    const listUrl = '/api/tiktok/video-stats/';
    server.get(listUrl, function (this: RouteHandlerContext, schema, request) {
        const stats = schema.all('tiktokVideoStats');
        return buildPaginatedResponse(stats, {
            url: listUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'tiktokVideoStats'),
        });
    });
}
