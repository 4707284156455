import { Model } from 'miragejs';
import { AppServer } from '../../mirage';
import { ModelDefinition } from 'miragejs/-types';
import { AggregatedPostStats } from './aggregatedPostStats.types';
import { createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    influencerAggregatedPostStats: Model as ModelDefinition<AggregatedPostStats>,
};

export const serializers = {
    influencerAggregatedPostStats: makeSerializer<AggregatedPostStats>([]),
};

export const factories = {
    influencerAggregatedPostStats: createFactory<AggregatedPostStats>({
        engagement_count: 0,
        engagement_rate: 0,
        first_post_date: null,
        last_post_date: null,
        follower_count: 0,
        post_count: 0,
        view_count: 0,
    }),
};

export function handleRequests(server: AppServer) {
    const url = '/api/influencer/aggregated-post-stats/';
    server.post(url, (schema) => {
        return schema.first('influencerAggregatedPostStats') || schema.create('influencerAggregatedPostStats');
    });
}
