import { creatorbase } from '@round/api';
import { Form, useFormikContext } from 'formik';
import { FormField, SelectField } from 'ui-new/whitelabel/formik';
import * as yup from 'yup';
import styles from './ReportForm.module.css';
import useTeamSelect, { buildTeamSelectFetchOptions, TeamOption } from 'Modules/Plans/Team/hooks/useTeamSelect';
import omit from 'lodash/omit';
import { GenericDropdownOption } from 'App.types';
import { useCurrencies } from 'contexts/OptionsContext/useCurrencies';
import { ValueType } from 'react-select';
import { buildUserSelectFetchOptions, useUsersMultiSelect } from '../../../User/hooks/useUsersSelect';
import { useMemo } from 'react';
import ExternalMultiValuesControl from 'ui-new/whitelabel/Select/components/ExternalMultiValuesControl/ExternalMultiValuesControl';
import StaticTextValue from 'ui-new/whitelabel/Select/components/StaticTextValue';
import None from 'ui-new/whitelabel/Select/components/None';
import { useCurrentCreatorbaseUser } from 'Modules/Auth/hooks/useCurrentCreatorbaseUser';

export type FormValues = Pick<creatorbase.PostReportData, 'description' | 'name'> & {
    team: TeamOption | null;
    currency: GenericDropdownOption<number> | null;
    assignees: ValueType<GenericDropdownOption<number>, true>;
};

export const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    team: yup.object().nullable().required('Team is required'),
    currency: yup.object().nullable().required('Currency is required'),
});

type Props = {
    disabledFields?: { [key in keyof FormValues]?: boolean };
};

const ReportForm = ({ disabledFields }: Props) => {
    const { creatorbaseUserId } = useCurrentCreatorbaseUser();

    const formik = useFormikContext<FormValues>();
    const { options: currencyOptions } = useCurrencies();

    const { props: teamSelectProps } = useTeamSelect({
        initOn: 'mount',
        fetchOptions: useMemo(
            () =>
                buildTeamSelectFetchOptions({
                    user_with_direct_access_id: creatorbaseUserId || undefined,
                }),
            [creatorbaseUserId]
        ),
    });
    const { props: teamMembersProps, resetOptions } = useUsersMultiSelect({
        initOn: 'menuOpen',
        fetchOptions: useMemo(() => buildUserSelectFetchOptions({ team_id: formik.values.team?.value }), [
            formik.values.team?.value,
        ]),
    });

    return (
        <Form className={styles.form}>
            <FormField name="name" label="Name" placeholder="Enter a report name" disabled={disabledFields?.['name']} />

            <FormField
                name="description"
                label="Description"
                placeholder="Enter a report description"
                component="textarea"
                disabled={disabledFields?.['description']}
            />

            <SelectField
                {...omit(teamSelectProps, ['value', 'onChange'])}
                name="team"
                label="Team"
                placeholder="Select a team"
                onChange={() => {
                    resetOptions();
                    formik.setFieldValue('assignees', [], false);
                }}
                disabled={disabledFields?.['team']}
            />

            <SelectField
                name="currency"
                label="Currency"
                placeholder="Select a currency"
                options={currencyOptions}
                disabled={disabledFields?.['currency']}
            />

            <SelectField
                name="assignees"
                label="Assignees"
                disabled={!formik.values.team || disabledFields?.['assignees']}
                isClearable={false}
                controlShouldRenderValue={false}
                {...omit(teamMembersProps, ['value', 'onChange'])}
                components={{
                    Control: ExternalMultiValuesControl,
                    Input: StaticTextValue,
                    MultiValue: None,
                    Placeholder: None,
                }}
            />
        </Form>
    );
};

export default ReportForm;
