import { encodeUrlSearchParams, fetchAll, fetchWithToken, ApiResponse, PaginatedApiResponseData } from '@round/api';
import { FacebookCampaignMediaPlanItemRelationship, MediaPlanItem, PaginatedRequest } from '../../../../App.types';
import { MediaPlanItemData } from '../types/MediaPlanItem.types';

type GetMediaPlanItemsParams = Partial<
    PaginatedRequest & {
        xero_invoice_id: string;
        media_plan_id: number;
        has_spend: boolean;
        no_invoice: boolean;
        excluded_clients: string;
        search: string;
    }
>;

export async function getMediaPlanItems(
    params: GetMediaPlanItemsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<MediaPlanItem>, 200>> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/media-plan-item/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not get media plan items');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getAllMediaPlanItems(
    params: Exclude<GetMediaPlanItemsParams, keyof PaginatedRequest>,
    requestInit?: RequestInit
): Promise<MediaPlanItem[]> {
    return fetchAll<MediaPlanItem>(
        `/api/advertising/viewsets/media-plan-item/${encodeUrlSearchParams(params)}`,
        requestInit
    );
}

export async function getMediaPlanItemFacebookCampaignRelationship(
    mediaPlanItemIds: number[]
): Promise<FacebookCampaignMediaPlanItemRelationship[]> {
    if (!mediaPlanItemIds.length) {
        return [];
    }

    return (
        (await fetchAll<FacebookCampaignMediaPlanItemRelationship>(
            `/api/advertising/viewsets/facebook-campaign-media-plan-item-relationship/?media_plan_item=${mediaPlanItemIds.join(
                ','
            )}`
        )) ?? []
    );
}

export async function deleteMediaPlanItemFacebookCampaignRelationship(relationshipId: number): Promise<void> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/facebook-campaign-media-plan-item-relationship/${relationshipId}/`,
        {
            method: 'DELETE',
        }
    );

    if (!response.ok) {
        throw new Error(`Couldn't delete media plan item facebook campaign relationship with id ${relationshipId}`);
    }
}

export async function postMediaPlanItem(data: MediaPlanItemData): Promise<MediaPlanItem> {
    const response = await fetchWithToken('/api/advertising/viewsets/media-plan-item/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error('Could not create media plan item');
    }

    return response.json();
}

export async function buildMediaPlanItem(mediaPlanId: number, groupId: number): Promise<MediaPlanItem> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item-build/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            media_plan: mediaPlanId,
            group_id: groupId,
        }),
    });

    if (!response.ok) {
        throw new Error('Could not build media plan item');
    }

    return (await response.json()) as MediaPlanItem;
}

export async function deleteMediaPlanItem(mediaPlanItemId: number, requestInit?: RequestInit): Promise<boolean> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item/${mediaPlanItemId}/`, {
        method: 'DELETE',
        ...requestInit,
    });
    return response.ok;
}

export async function patchMediaPlanItem(
    mediaPlanItemId: number,
    data: Partial<MediaPlanItemData>
): Promise<MediaPlanItem> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item/${mediaPlanItemId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(`Could not update media plan item ${mediaPlanItemId}`);
    }

    return response.json();
}
