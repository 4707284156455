import React, { useState } from 'react';
import { Column } from 'react-table';
import { PhylloCreatorProfileBasicDetails } from '@round/api';
import { useUserAnalyticsData } from '../TiktokUserDataContext/TiktokUserDataContext';
import styles from './Lookalikes.module.css';
import PaginationTable from '../../../../../../ui/PaginationTable/PaginationTable';
import { Image, Skeleton } from '@round/ui-kit';
import { formatNumberToKNotation } from '../../../../../../helpers';

type Props = {
    userId: number | undefined | null;
};

const Lookalikes = ({ userId }: Props) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const { analytics, isLoading, error } = useUserAnalyticsData(userId);

    const columns: Column<PhylloCreatorProfileBasicDetails>[] = [
        {
            Header: 'Account',
            accessor: 'platform_username',
            Cell: ({ row: { original } }) => {
                return (
                    <div className={styles.account}>
                        <Image
                            className={styles.accountImage}
                            src={original.image_url}
                            alt="Influencer"
                            loading={isLoading}
                        />
                        <div className={styles.accountDetails}>
                            {isLoading ? (
                                <Skeleton />
                            ) : (
                                <a
                                    className={styles.accountUsername}
                                    onClick={(e) => e.stopPropagation()}
                                    href={original.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    @{original.platform_username}
                                </a>
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            Header: 'Follower count',
            accessor: 'follower_count',
            Cell: ({ value }) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                return <>{typeof value === 'number' ? formatNumberToKNotation(value) : '-'}</>;
            },
        },
    ];

    const lookalikes = analytics?.profile.lookalikes ?? [];

    return (
        <PaginationTable
            manualPagination={false}
            tableClassName={styles.table}
            count={lookalikes.length}
            data={lookalikes}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={(size) => setPageSize(size ?? 10)}
            columns={columns}
            loading={isLoading}
            noDataLabel={error ?? 'No data available'}
        />
    );
};

export default Lookalikes;
