import React, { useCallback, useState } from 'react';
import { Targeting } from '../../../../../../App.types';
import { fetchDeleteMediaPlanItemTargeting, updateTargeting } from '../../../api/MediaPlanTargetings.api';
import { Form, Formik, FormikHelpers, Field } from 'formik';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import FormField from '../../../../../../ui/DataEntry/FormField/FormField';
import Label from '../../../../../../ui/DataEntry/Label/Label';
import styles from './TargetingModal.module.css';
import cn from 'classnames';
import TextArea from '../../../../../../SharedComponents/Forms/TextArea/TextArea';
import Button from '../../../../../../ui/Buttons/Button/Button';
import { showNotification } from '../../../../../../helpers';

type TargetingModalProps = {
    targeting: Targeting;
    isModalOpen: boolean;
    closeModal: () => void;
    onTargetingUpdated: (targeting: Targeting) => void;
    onTargetingDeleted: (targeting: Targeting) => void;
};

const TargetingModal = ({
    targeting,
    isModalOpen,
    closeModal,
    onTargetingUpdated,
    onTargetingDeleted,
}: TargetingModalProps) => {
    const [showDiscardChangesPrompt, setShowDiscardChangesPrompt] = useState(false);
    const [errorSubmitting, setErrorSubmitting] = useState(false);
    const [showDeleteTargetingPrompt, setShowDeleteTargetingPrompt] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const handleSubmit = useCallback(
        async (values: Targeting, { setSubmitting, resetForm }: FormikHelpers<Targeting>) => {
            try {
                setSubmitting(true);
                setErrorSubmitting(false);
                const updated = await updateTargeting(values);
                onTargetingUpdated(updated);
                resetForm({ values });
                showNotification('Targeting updated', 'info');
            } catch {
                setErrorSubmitting(true);
            } finally {
                setSubmitting(false);
            }
        },
        [onTargetingUpdated]
    );

    const handleDelete = useCallback(async () => {
        try {
            setDeleteLoading(true);
            await fetchDeleteMediaPlanItemTargeting(targeting.id);
            onTargetingDeleted(targeting);
            setDeleteLoading(false);
            setShowDeleteTargetingPrompt(false);
            closeModal();
            showNotification(`Targeting "${targeting.name}" has been deleted`, 'info');
        } catch {
            showNotification('Could not delete targeting', 'error');
        } finally {
            setDeleteLoading(false);
            setShowDeleteTargetingPrompt(false);
        }
    }, [closeModal, onTargetingDeleted, targeting]);

    return (
        <>
            <Modal
                className={styles.deleteTargeting}
                closeOnOverlayClick
                isOpen={showDeleteTargetingPrompt}
                onClose={() => setShowDeleteTargetingPrompt(false)}
            >
                <ModalTitle>Delete "{targeting.name}" targeting?</ModalTitle>
                <ModalContent>
                    <p>You're going to delete "{targeting.name}" targeting. This operation cannot be undone.</p>
                </ModalContent>
                <ModalFooter align="center">
                    <Button type="bordered" color="negative" loading={deleteLoading} onClick={handleDelete}>
                        Delete targeting
                    </Button>
                </ModalFooter>
            </Modal>

            <Formik enableReinitialize initialValues={targeting} onSubmit={handleSubmit}>
                {({ dirty, submitForm, isSubmitting, resetForm }) => (
                    <>
                        <Modal
                            className={styles.discardChanges}
                            isOpen={showDiscardChangesPrompt}
                            onClose={() => setShowDiscardChangesPrompt(false)}
                        >
                            <ModalTitle>Discard changes?</ModalTitle>
                            <ModalContent className={styles.discardChangesContent}>
                                <p>This operation cannot be undone</p>
                            </ModalContent>
                            <ModalFooter>
                                <Button
                                    type="filled"
                                    color="black"
                                    onClick={() => {
                                        resetForm();
                                        setShowDiscardChangesPrompt(false);
                                        closeModal();
                                    }}
                                >
                                    Discard
                                </Button>
                            </ModalFooter>
                        </Modal>

                        <Modal
                            closeOnOverlayClick
                            isOpen={isModalOpen}
                            onClose={() => {
                                if (!dirty) {
                                    closeModal();
                                    return;
                                }

                                setShowDiscardChangesPrompt(true);
                            }}
                        >
                            <ModalTitle>Edit Targeting</ModalTitle>
                            <ModalContent className={styles.modalContent}>
                                {errorSubmitting && <div>Error creating targeting</div>}
                                <Form className={styles.form}>
                                    <FormField>
                                        <Label htmlFor="name">Name</Label>
                                        <Field name="name" id="name" placeholder="Enter name" />
                                    </FormField>

                                    <div className={cn(styles.row, styles.twoBadges)}>
                                        <FormField>
                                            <Label htmlFor="min_age">Min Age</Label>
                                            <Field
                                                type="number"
                                                id="min_age"
                                                name="min_age"
                                                placeholder="Enter min age"
                                            />
                                        </FormField>

                                        <FormField>
                                            <Label htmlFor="max_age">Max Age</Label>
                                            <Field
                                                type="number"
                                                id="max_age"
                                                name="max_age"
                                                placeholder="Enter max age"
                                            />
                                        </FormField>
                                    </div>

                                    <FormField>
                                        <Label htmlFor="location">Location</Label>
                                        <Field id="location" name="location" placeholder="Enter location" />
                                    </FormField>

                                    <div>
                                        <Label className={styles.genderLabel} htmlFor="">
                                            Gender
                                        </Label>
                                        <div className={styles.row}>
                                            <FormField className={styles.inline}>
                                                <Field type="checkbox" name="is_male" id="is_male" />
                                                <Label htmlFor="is_male">Male</Label>
                                            </FormField>

                                            <FormField className={styles.inline}>
                                                <Field type="checkbox" name="is_female" id="is_female" />
                                                <Label htmlFor="is_female">Female</Label>
                                            </FormField>
                                        </div>
                                    </div>

                                    <FormField>
                                        <Label htmlFor="remarketing_audience">Remarketing Audience</Label>
                                        <Field
                                            id="remarketing_audience"
                                            name="remarketing_audience"
                                            placeholder="Enter remarketing audience"
                                        />
                                    </FormField>

                                    <FormField>
                                        <Label htmlFor="keywords">Keywords</Label>
                                        <Field id="keywords" name="keywords" placeholder="Enter keywords" />
                                    </FormField>

                                    <FormField>
                                        <Label htmlFor="notes">Notes</Label>
                                        <TextArea name="notes" placeholder="Write notes" />
                                    </FormField>
                                </Form>
                            </ModalContent>
                            <ModalFooter>
                                <Button
                                    disabled={!dirty}
                                    loading={isSubmitting}
                                    className={styles.submit}
                                    htmlType="submit"
                                    onClick={submitForm}
                                    type="filled"
                                    color="black"
                                >
                                    Save targeting
                                </Button>

                                <Button
                                    type="filled"
                                    color="negative"
                                    onClick={() => setShowDeleteTargetingPrompt(true)}
                                >
                                    Delete
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </>
                )}
            </Formik>
        </>
    );
};

export default TargetingModal;
