import React, { useCallback, useEffect, useState } from 'react';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { AdvertisingContext } from '../../contexts/AdvertisingContext/AdvertisingContext';
import Skeleton from '../../../../../ui/Skeleton/Skeleton';
import { applyCommission, asMoney, formatDate, formatDateShort, showNotification } from '../../../../../helpers';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import DataCard from '../../../../../ui/DataDisplay/DataCard/DataCard';
import styles from './Advertising.module.css';
import AdvertisingGroups from '../AdvertisingGroups/AdvertisingGroups';
import Button from '../../../../../ui/Buttons/Button/Button';
import { ProtectedByUserGroups } from '../../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import EmptyPlan from '../../../components/EmptyPlan/EmptyPlan';
import { Release } from '@round/api';
import { ReactComponent as EditIcon } from '../../../assets/EditIcon.svg';
import { ReactComponent as NotesIcon } from '../../assets/NotesIcon.svg';
import EditPlanModal from './EditPlanModal/EditPlanModal';
import TextArea from '../../../../../ui/TextArea/TextArea';
import CreatePlanModal from './CreatePlanModal/CreatePlanModal';
import { MediaPlanOptionsContext } from '../../contexts/MediaPlanOptionsContext/MediaPlanOptionsContext';
import { useCheckUserGroupsAccess } from '../../../../Auth/hooks/useCheckUserGroupsAccess';

const formatDateLong = (date: string) => formatDate(date, { month: 'long', day: '2-digit' });

type AdvertisingProps = {
    release: Release | null;
};

const Advertising = ({ release }: AdvertisingProps) => {
    const { init: initOptions, optionsInitialized } = useNonNullContext(MediaPlanOptionsContext);
    const [context, { init, updateMediaPlanNotes }] = useNonNullContext(AdvertisingContext);
    const [editPlanModalOpen, setEditPlanModalOpen] = useState(false);
    const [showMediaPlanNotesField, setShowMediaPlanNotesField] = useState(false);
    const [notesInputValue, setNotesInputValue] = useState('');
    const [showCreatePlanModal, setShowCreatePlanModal] = useState(false);

    const hasEditAccess = useCheckUserGroupsAccess(['advertising_editor']);

    const submitMediaPlanNotes = useCallback(async () => {
        if (context.mediaPlan?.notes === notesInputValue) {
            return;
        }

        try {
            await updateMediaPlanNotes(notesInputValue);
            showNotification('Notes updated', 'info');
        } catch {
            showNotification('Could not update notes', 'error');
        }
    }, [context.mediaPlan?.notes, notesInputValue, updateMediaPlanNotes]);

    useAbortableEffect(
        (signal) => {
            if (!context.initialized) {
                init({ signal });
            }
        },
        [context.initialized, init]
    );

    useAbortableEffect(
        (signal) => {
            if (!optionsInitialized) {
                initOptions({ signal });
            }
        },
        [optionsInitialized, initOptions]
    );

    useEffect(() => {
        if (context.mediaPlan) {
            setNotesInputValue(context.mediaPlan.notes ?? '');
        }
    }, [context.mediaPlan, context.mediaPlan?.notes]);

    if (context.errorLoadingMediaPlan) {
        return <p>Sorry, we're experiencing technical issues</p>;
    }

    if (!context.mediaPlanLoading && !context.mediaPlan) {
        return (
            <>
                <EmptyPlan
                    release={release}
                    onCreatePlan={() => setShowCreatePlanModal(true)}
                    readOnly={!hasEditAccess}
                />
                {release && (
                    <CreatePlanModal
                        release={release}
                        isModalOpen={showCreatePlanModal}
                        closeModal={() => setShowCreatePlanModal(false)}
                    />
                )}
            </>
        );
    }

    const totalBudget = context.mediaPlan ? asMoney(Number(context.mediaPlan.budget), context.mediaPlan.currency) : '-';
    const runDates = context.mediaPlan
        ? `${formatDateLong(context.mediaPlan.start_date)} - ${formatDateLong(context.mediaPlan.end_date)}`
        : '-';
    const plannedCost = context.mediaPlanItems.reduce((acc, item) => acc + Number(item.cost), 0);
    const plannedCostLabel = context.mediaPlan
        ? `${asMoney(plannedCost, context.mediaPlan.currency)} planned cost`
        : undefined;

    const amountSpent = context.mediaPlanItems.reduce(
        (acc, report) =>
            acc +
            applyCommission(
                report.amount_spent_in_media_plan_currency ?? 0,
                Number(context.mediaPlan?.client_commission ?? 0)
            ),
        0
    );
    const amountSpentLabel = context.mediaPlan ? asMoney(amountSpent, context.mediaPlan.currency) : '-';

    return (
        <>
            <div className={styles.row}>
                <div className={styles.dataCards}>
                    <DataCard loading={!context.initialized} title="Run dates" value={runDates} />
                    <DataCard
                        loading={!context.initialized}
                        title="Total budget"
                        value={totalBudget}
                        additionalValue={plannedCostLabel}
                    />
                    <DataCard loading={!context.initialized} title="Amount spent" value={amountSpentLabel} />
                </div>

                <div className={styles.actionButtons}>
                    <div className={styles.notesContainer}>
                        <ProtectedByUserGroups groups={['advertising_editor']}>
                            <Button
                                className={styles.actionButton}
                                type="bordered"
                                Icon={() => <NotesIcon className={styles.actionButtonIcon} />}
                                onClick={() => setShowMediaPlanNotesField((show) => !show)}
                            >
                                View Notes
                            </Button>
                        </ProtectedByUserGroups>

                        {showMediaPlanNotesField && (
                            <TextArea
                                placeholder="Write notes"
                                className={styles.notesField}
                                value={notesInputValue}
                                onChange={(e) => setNotesInputValue(e.target.value)}
                                onBlur={submitMediaPlanNotes}
                            />
                        )}
                    </div>

                    <div>
                        {context.mediaPlan && (
                            <ProtectedByUserGroups groups={['advertising_editor']}>
                                <Button
                                    className={styles.actionButton}
                                    type="bordered"
                                    Icon={() => <EditIcon className={styles.actionButtonIcon} />}
                                    onClick={() => setEditPlanModalOpen(true)}
                                >
                                    Edit Plan
                                </Button>
                                <EditPlanModal
                                    isModalOpen={editPlanModalOpen}
                                    closeModal={() => setEditPlanModalOpen(false)}
                                    plan={context.mediaPlan}
                                />
                            </ProtectedByUserGroups>
                        )}

                        <p className={styles.lastUpdatedLabel}>
                            {context.mediaPlanLoading ? (
                                <Skeleton />
                            ) : (
                                <>
                                    Last edited:&nbsp;
                                    <span className={styles.lastUpdated}>
                                        {formatDateShort(context.mediaPlan.updated_at)}
                                    </span>
                                </>
                            )}
                        </p>
                    </div>
                </div>
            </div>

            <AdvertisingGroups />
        </>
    );
};

export default Advertising;
