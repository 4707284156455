import { SetStateAction } from 'react';

export type DomainFiltersProps<T> = {
    value: T;
    onChange: (setter: SetStateAction<T>) => void;
};

/**
 * Returns the initial filters based on the current session state, active filters, and default filters.
 */
export const getInitialFilters = <T extends string[]>(state: T | null, activeFilters: T, defaultFilters?: T) => {
    if (!state) {
        return [...activeFilters, ...(defaultFilters ?? [])];
    }

    return [...activeFilters, ...state];
};
