import { getTiktokAudio, TiktokAudio } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<TiktokAudio>;

const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export default function useTikTokAudio() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (tiktokAudioId: number, requestInit?: RequestInit) => {
        try {
            setState({ data: null, error: null, status: 'loading' });

            const response = await getTiktokAudio(tiktokAudioId, requestInit);
            if (response.status === 404) {
                setState({ data: null, error: response.data.detail, status: 'error' });
                return response;
            }

            setState({ data: response.data, error: null, status: 'success' });
            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            setState({ data: null, error: 'Could not fetch tiktok audio', status: 'error' });
            throw e;
        }
    }, []);

    const reset = useCallback(() => setState(initialState), []);

    return {
        ...state,
        fetchData,
        reset,
    };
}
