import { RefObject, useLayoutEffect, useState } from 'react';

export default function useScrolled(ref: RefObject<HTMLDivElement>) {
    const [scrolled, setScrolled] = useState(false);
    const scrollEventHandler = () => setScrolled(true);

    useLayoutEffect(() => {
        const listElement = ref.current;

        if (listElement) {
            listElement.addEventListener('scroll', scrollEventHandler);
            return () => {
                listElement.removeEventListener('scroll', scrollEventHandler);
            };
        }
    });

    return scrolled;
}
