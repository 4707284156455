import { ApiResponse, ApiResponseError, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';
import { MediaPlan, MediaPlanApiModel } from './mediaPlan.types';

type GetMediaPlansParams = Partial<PaginatedRequest & { release_id: number; search: string; id: string }>;
type GetMediaPlansResponse =
    | ApiResponse<PaginatedApiResponseData<MediaPlan>, 200>
    | ApiResponse<{ release_id: string[] }, 400>
    | ApiResponse<{ detail: string }, 404>;

export async function getMediaPlans(
    params: GetMediaPlansParams,
    requestInit?: RequestInit
): Promise<GetMediaPlansResponse> {
    const queryParams = encodeUrlSearchParams(params);
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan/${queryParams}`, requestInit);

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get media plans ${queryParams}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetAllMediaPlansParams = Exclude<GetMediaPlansParams, PaginatedRequest>;
export async function getAllMediaPlans(
    params: GetAllMediaPlansParams,
    requestInit?: RequestInit
): Promise<MediaPlan[]> {
    return fetchAll(`/api/advertising/viewsets/media-plan/${encodeUrlSearchParams(params)}`, requestInit);
}

type PostMediaPlanResponse = ApiResponse<MediaPlan, 200> | ApiResponse<ApiResponseError<MediaPlanApiModel>, 400>;
export async function postMediaPlan(data: MediaPlanApiModel): Promise<PostMediaPlanResponse> {
    const response = await fetchWithToken('/api/advertising/viewsets/media-plan/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not post media plan');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PatchMediaPlanResponse =
    | ApiResponse<MediaPlan, 200>
    | ApiResponse<{ detail: string }, 404>
    | ApiResponse<ApiResponseError<MediaPlanApiModel>, 400>;

export async function patchMediaPlan(id: number, data: Partial<MediaPlanApiModel>): Promise<PatchMediaPlanResponse> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not patch media plan ${id}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type DeleteMediaPlanResponse = ApiResponse<null, 204> | ApiResponse<{ detail: string }, 404>;
export async function deleteMediaPlan(id: number): Promise<DeleteMediaPlanResponse> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan/${id}/`, { method: 'DELETE' });

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not delete media plan ${id}`);
    }

    return {
        status: 204,
        data: null,
    };
}
