import { creatorbase } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

type State = DataState<creatorbase.User[]>;
type Actions =
    | ReducerAction<'loadAssignees'>
    | ReducerActionWithPayload<'assigneesLoadSuccess', creatorbase.User[]>
    | ReducerActionWithPayload<'assigneesLoadingError', string>;

export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export const reducer = createReducer<State, Actions>({
    loadAssignees: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    assigneesLoadingError: (state, { payload: errorMessage }) => ({
        ...state,
        status: 'error',
        error: errorMessage,
    }),
    assigneesLoadSuccess: (state, { payload: users }) => ({
        status: 'success',
        data: state.data?.concat(users) ?? users,
        error: null,
    }),
});
