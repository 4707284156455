import { InputHTMLAttributes, forwardRef } from 'react';
import cn from 'classnames';
import styles from './TableInput.module.css';

type Props = InputHTMLAttributes<HTMLInputElement>;

const TableInput = forwardRef<HTMLInputElement, Props>(({ value, onChange, className, ...props }, ref) => {
    return (
        <input
            value={value}
            onChange={onChange}
            className={cn(styles.input, className)}
            ref={ref}
            tabIndex={props.readOnly ? -1 : props.tabIndex || 0}
            {...props}
        />
    );
});

export default TableInput;
