export type RequestStatus =
    | {
          success: true;
          error: null;
      }
    | {
          success: false;
          error: string;
      };

export const getSuccessfulPaymentsMessage = (successfulPayments: number, totalPayments: number) => {
    return `${successfulPayments === totalPayments ? 'All ' : ''}${successfulPayments} payment${
        successfulPayments !== 1 ? 's' : ''
    } successful`;
};

export const getFailedPaymentsMessage = (failedPayments: number, totalPayments: number) => {
    return `${failedPayments === totalPayments ? 'All ' : ''}${failedPayments} payment${
        failedPayments !== 1 ? 's' : ''
    } failed`;
};
