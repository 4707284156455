import { PaymentRequest } from './paymentRequests.types';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    microwavePaymentRequest: Model as ModelDefinition<PaymentRequest>,
};

export const serializers = {
    microwavePaymentRequest: makeSerializer<PaymentRequest>([]),
};

export const factories = {
    microwavePaymentRequest: createFactory<PaymentRequest>({
        brief: (index: number) => `brief${index}`,
        cost: 10,
        currency_id: 1,
        decision_made_by: (index: number) => `decision_made_by${index}`,
        influencer_id: (index: number) => index,
        instagram_audio_id: (index: number) => (index % 2 === 0 ? index : null),
        invite_id: (index: number) => `invite_id${index}`,
        paid_at: new Date().toISOString(),
        paid_by: (index: number) => `paid_by${index}`,
        payment_deadline: new Date().toISOString(),
        payment_details: (index: number) => `payment_details${index}`,
        payment_method: 'PAYPAL',
        payment_reference: (index: number) => `payment_reference${index}`,
        payment_status: 'PAID',
        paypal_email_address: (index: number) => `paypal_email_address${index}`,
        paypal_transaction_id: (index: number) => `paypal_transaction_id${index}`,
        paypal_transaction_status: 'COMPLETED',
        platform: 'INSTAGRAM',
        posted_at: new Date().toISOString(),
        post_deadline: new Date().toISOString(),
        post_url: (index: number) => `post_url${index}`,
        preferred_payment_method: (index: number) => (index % 2 === 0 ? 'PAYPAL' : 'VENMO'),
        release_id: (index: number) => index,
        requested_at: new Date().toISOString(),
        status: 'UNPAID',
        tiktok_audio_id: (index: number) => (index % 2 !== 0 ? index : null),
        venmo_email_address: (index: number) => `venmo_email_address${index}`,
        view_count: (index: number) => index,
    }),
};

export function handleRequests(server) {
    server.get('/api/microwave/payment-requests/', function (this, schema, request) {
        const paymentRequests = schema.all('microwavePaymentRequest');
        return buildPaginatedResponse(paymentRequests, {
            url: '/api/microwave/payment-requests/',
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'microwavePaymentRequest'),
        });
    });

    server.patch('/api/microwave/payment-requests/:id/', (schema, request) => {
        const paymentRequest = schema.find('microwavePaymentRequest', request.params.id);

        if (!paymentRequest) {
            return new Response(404, {}, { detail: 'Not found.' });
        }
        paymentRequest.update(request.requestBody);
        return paymentRequest;
    });
}
