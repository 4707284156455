import React, { useState } from 'react';
import Skeleton from '../../Skeleton/Skeleton';
import styles from './DataCard.module.css';
import cn from 'classnames';
import Tooltip from '../../DataDisplay/Tooltip/Tooltip';
import { ReactComponent as QuestionMarkIcon } from '../../../assets/QuestionMark.svg';
import { Card, Popover } from '@round/ui-kit';

type DataCardProps = {
    title: string;
    value: React.ReactNode;
    additionalValue?: React.ReactNode;
    loading?: boolean;
    tooltipValue?: string | number;
    valuesClassName?: string;
    hint?: React.ReactNode | undefined;
    HintIcon?: typeof QuestionMarkIcon;
    hintClassName?: string;
};

const DataCard = ({
    title,
    value,
    additionalValue,
    loading,
    valuesClassName,
    tooltipValue,
    hint,
    HintIcon = QuestionMarkIcon,
    hintClassName,
}: DataCardProps) => {
    const [tooltipRef, setTooltipRef] = useState<HTMLElement | null>(null);
    const [hintRef, setHintRef] = useState<HTMLElement | null>(null);

    return (
        <>
            <div className={styles.container}>
                {hint && (
                    <div className={cn(styles.hintIconContainer, hintClassName)} ref={setHintRef}>
                        <HintIcon className={styles.hintIcon} />
                    </div>
                )}
                <p className={styles.title}>{loading ? <Skeleton width="5.625rem" /> : title}</p>
                <div className={cn(styles.values, valuesClassName)} ref={setTooltipRef}>
                    <span className={styles.value}>{loading ? <Skeleton width="11.25rem" /> : value}</span>
                    {additionalValue && !loading && <span className={styles.additionalValue}>{additionalValue}</span>}
                </div>
            </div>
            {tooltipValue && <Tooltip anchorElement={tooltipRef} value={tooltipValue} />}
            <Popover anchorElement={hintRef} showOn="hover">
                <Card className={styles.hintPopover}>{hint}</Card>
            </Popover>
        </>
    );
};

export default DataCard;
