import { influencer } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

export type State = {
    [key in influencer.Platform]: DataState<influencer.AggregatedPostStats>;
};

const defaultState: State[influencer.Platform] = {
    data: null,
    error: null,
    status: 'idle',
};

export const initialState: State = {
    instagram: defaultState,
    tiktok: defaultState,
    youtube: defaultState,
};

export type Actions =
    | ReducerActionWithPayload<'loadInfluencerAggregatedPostStatsByPlatform', influencer.Platform>
    | ReducerActionWithPayload<
          'influencerAggregatedPostStatsByPlatformLoaded',
          { platform: influencer.Platform; stats: influencer.AggregatedPostStats }
      >
    | ReducerActionWithPayload<
          'errorLoadingInfluencerAggregatedPostStatsByPlatform',
          { platform: influencer.Platform; error: string }
      >;

export const reducer = createReducer<State, Actions>({
    loadInfluencerAggregatedPostStatsByPlatform: (state, { payload }) => ({
        ...state,
        [payload]: {
            ...state[payload],
            status: 'loading',
            error: null,
        },
    }),
    errorLoadingInfluencerAggregatedPostStatsByPlatform: (state, { payload: { platform, error } }) => ({
        ...state,
        [platform]: {
            ...state[platform],
            status: 'error',
            error,
        },
    }),
    influencerAggregatedPostStatsByPlatformLoaded: (state, { payload: { platform, stats } }) => ({
        ...state,
        [platform]: {
            data: stats,
            status: 'success',
            error: null,
        },
    }),
});
