import { Dispatch, Reducer, ReducerAction, ReducerState, useCallback, useEffect, useReducer, useRef } from 'react';

/**
 * Reducer that doesn't update state after component is unmounted
 * @param reducer
 * @param initialState
 */
export default function useSafeReducer<R extends Reducer<any, any>>(
    reducer: R,
    initialState: ReducerState<R>
): [ReducerState<R>, Dispatch<ReducerAction<R>>] {
    const [state, unsafeDispatch] = useReducer(reducer, initialState);
    const mountedRef = useRef(false);

    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const dispatch = useCallback((action: ReducerAction<R>) => {
        if (mountedRef.current) {
            unsafeDispatch(action);
        }
    }, []);

    return [state, dispatch];
}
