import styles from './PostsNoDataLabel.module.css';
import Button from 'ui-new/whitelabel/Button/Button';
import { ReactComponent as PlusIcon } from 'assets/whitelabel/Plus.svg';

type Props = {
    hasError: boolean;
    onAddPost: () => void;
};

const PostsNoDataLabel = ({ onAddPost, hasError }: Props) => {
    return (
        <div className={styles.container} aria-label="no posts">
            {hasError ? (
                'Could not load posts'
            ) : (
                <Button appearance="primary" onClick={onAddPost} iconLeft={<PlusIcon className={styles.plusIcon} />}>
                    Post
                </Button>
            )}
        </div>
    );
};

export default PostsNoDataLabel;
