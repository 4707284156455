import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { Target, Menu, Item, Separator } from './components';

type ContextState = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    anchorElement: HTMLElement | SVGSVGElement | null;
    setAnchorElement: Dispatch<SetStateAction<HTMLElement | SVGSVGElement | null>>;
};

export const DropdownContext = createContext<ContextState | null>(null);

type Props = {
    children: React.ReactNode | ((context: ContextState) => React.ReactNode);
};

const Dropdown = ({ children }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorElement, setAnchorElement] = useState<HTMLElement | SVGSVGElement | null>(null);

    return (
        <DropdownContext.Provider value={{ isOpen, setIsOpen, anchorElement, setAnchorElement }}>
            {typeof children === 'function'
                ? children({ isOpen, setIsOpen, anchorElement, setAnchorElement })
                : children}
        </DropdownContext.Provider>
    );
};

Dropdown.Target = Target;
Dropdown.Menu = Menu;
Dropdown.Item = Item;
Dropdown.Separator = Separator;

export default Dropdown;
