import { GenericDropdownOption } from 'App.types';
import { InvoiceRequestStatus } from '@round/api';

export const invoiceRequestStatusOptions: GenericDropdownOption<InvoiceRequestStatus>[] = [
    {
        value: 'PENDING',
        label: 'Pending',
    },
    {
        value: 'NEEDS_TO_CHASE',
        label: 'Needs to chase',
    },
    {
        value: 'NEEDS_TO_BE_INVOICED',
        label: 'Needs to be invoiced',
    },
    {
        value: 'SUBMITTED_TO_UNIPORT',
        label: 'Submitted to uniport',
    },
    {
        value: 'INVOICED',
        label: 'Invoiced',
    },
    {
        value: 'OTHER',
        label: 'Other',
    },
];

export const notesFilterOptions: GenericDropdownOption<boolean>[] = [
    {
        value: true,
        label: 'With notes',
    },
    {
        value: false,
        label: 'Without notes',
    },
];
