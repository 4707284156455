import { NavBar } from 'SharedComponents/NavBar/NavBar';
import InvoiceRequests from 'Modules/Finance/InvoiceRequests/InvoiceRequests';
import styles from 'Pages/Finance/InvoiceRequestsPage/InvoiceRequestsPage.module.css';

const InvoiceRequestsPage = () => {
    return (
        <>
            <NavBar />
            <div className={styles.container}>
                <InvoiceRequests />
            </div>
        </>
    );
};

export default InvoiceRequestsPage;
