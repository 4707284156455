import React from 'react';
import { TabNavItem, TabPanel, TabPanels, Tabs } from '@round/ui-kit';
import OutstandingPayments from './OutstandingPayments/OutstandingPayments';
import styles from './TiktokPayments.module.css';
import useUrlState from '../../../Hooks/useUrlState';
import PastPayments from './PastPayments/PastPayments';

type UrlState = {
    tab: 'outstanding' | 'past';
};

const initialUrlState: UrlState = {
    tab: 'outstanding',
};

const TiktokPayments = () => {
    const [urlState, setUrlState] = useUrlState(initialUrlState, { shouldSetMissingInitialValues: true });

    return (
        <Tabs
            activeTab={urlState.tab}
            onChange={(tab) => setUrlState({ tab: (tab as UrlState['tab']) || initialUrlState.tab }, { replace: true })}
        >
            <div className={styles.navItems}>
                <TabNavItem name="outstanding" className={styles.navItem} activeClassName={styles.activeNavItem}>
                    Outstanding
                </TabNavItem>

                <TabNavItem name="past" className={styles.navItem} activeClassName={styles.activeNavItem}>
                    Past
                </TabNavItem>
            </div>

            <TabPanels>
                <TabPanel name="outstanding">
                    <OutstandingPayments />
                </TabPanel>

                <TabPanel name="past">
                    <PastPayments />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default TiktokPayments;
