import styles from './RenderPostUrlIcon.module.css';
import LoadingSpinner from '../../../../../../SharedComponents/LoadingSpinner/LoadingSpinner';
import { ReactComponent as ValidCircle } from '../../../../../../assets/CheckCircle.svg';
import { ReactComponent as InvalidCircle } from '../../../../../../assets/CloseCircle.svg';
import { ReactComponent as ArrowRight } from '../../../../../../assets/ArrowRight.svg';
import cn from 'classnames';

type PostUrlIconStatus = 'PENDING' | 'LOADING' | 'VALID' | 'INVALID';

type RenderPostUrlIconProps = {
    status: PostUrlIconStatus;
    onSubmit: () => void;
};

const Icon = ({ status, onSubmit }: RenderPostUrlIconProps) => {
    switch (status) {
        case 'LOADING':
            return <LoadingSpinner spinnerClassName={styles.spinner} containerClassName={styles.spinnerContainer} />;
        case 'VALID':
            return <ValidCircle className={cn(styles.icon, styles.valid)} />;
        case 'INVALID':
            return <InvalidCircle className={cn(styles.icon, styles.invalid)} />;
        default:
            return <ArrowRight className={cn(styles.icon, styles.clickable)} onClick={() => onSubmit()} />;
    }
};

const RenderPostUrlIcon = ({ status, onSubmit }: RenderPostUrlIconProps) => {
    return (
        <div className={styles.iconContainer}>
            <Icon status={status} onSubmit={onSubmit} />
        </div>
    );
};

export default RenderPostUrlIcon;
