import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from '../../../../ui/Skeleton/Skeleton';
import { Popover } from '@round/ui-kit';
import { SelectBoxOptionWithId } from '../../../../ui/DataEntry/SelectBox/SelectBox.types.';
import Card from '../../../../ui/Card/Card';
import styles from './AddInfluencerToCampaign.module.css';
import cn from 'classnames';
import useNonNullContext from '../../../../Hooks/useNonNullContext';
import { AddInfluencerToCampaignOptionsContext } from './AddInfluencerToCampaignContext';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import OptionsList from '../../../../ui/DataEntry/SelectBox/components/OptionsList/OptionsList';
import { ListItemProps } from '../../../../ui/DataDisplay/List/List';
import { ReactComponent as PlusIcon } from '../../../../assets/PlusIcon.svg';
import LoadingSpinner from '../../../../SharedComponents/LoadingSpinner/LoadingSpinner';
import { postTiktokInfluencerPost } from '../../../Advertising/InfluencerPlan/InfluencerPlan.api';
import { showNotification } from '../../../../helpers';
import {
    TikTokInfluencerUserStats,
    InfluencerUserCampaign,
    InfluencerUserCampaigns,
    getAllInfluencerPostGroups,
    InfluencerPostGroup,
    TiktokInfluencerPost,
    InfluencerPlan,
} from '@round/api';
import { ReactComponent as TriangleIcon } from '../../../../assets/Triangle.svg';
import { FCWithChildren } from '../../../../utility/utility.types';
import { uniqBy } from 'lodash';

type AddTiktokInfluencerToCampaignProps = {
    influencerCampaigns: InfluencerUserCampaigns | undefined;
    plans: InfluencerPlan[];
    readonly?: boolean;
    onInfluencerAddedToCampaign?: (post: TiktokInfluencerPost) => void;
    influencerUserStats?: TikTokInfluencerUserStats | null;
    className?: string;
};

type InfluencerPlanOptionType = SelectBoxOptionWithId & { releaseId: number };
const AddTiktokInfluencerToCampaign: FCWithChildren<AddTiktokInfluencerToCampaignProps> = ({
    influencerCampaigns,
    plans,
    readonly,
    onInfluencerAddedToCampaign,
    influencerUserStats,
    children,
    className,
}) => {
    const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
    const { getNextPage, count, search, setSearch, data, loading } = useNonNullContext(
        AddInfluencerToCampaignOptionsContext
    );
    const [groupsExpandedCount, setGroupsExpandedCount] = useState<number>(0);

    const options: InfluencerPlanOptionType[] = useMemo(() => {
        const options = data.map((plan) => ({
            id: plan.id,
            name: plan.release.name,
            subtitle: `${plan.release.brand.name} - ${plan.release.brand.client.name}`,
            releaseId: plan.release.id,
        }));

        if (search.length) {
            return options;
        }

        const concatenatedOptions = plans
            .map((plan) => ({
                id: plan.id,
                name: plan.release.name,
                subtitle: `${plan.release.brand.name} - ${plan.release.brand.client.name}`,
                releaseId: plan.release.id,
            }))
            .concat(options);

        return uniqBy(concatenatedOptions, 'id');
    }, [data, plans, search.length]);

    const addInfluencerToCampaign = useCallback(
        async (planId: number, groupId: number) => {
            try {
                const response = await postTiktokInfluencerPost({
                    post_url: '',
                    influencer_id: influencerCampaigns?.influencer_id,
                    plan_id: planId,
                    currency_id: influencerUserStats?.currency?.id ?? 1,
                    group_id: groupId,
                    cost: typeof influencerUserStats?.cost === 'number' ? String(influencerUserStats.cost) : '1',
                });

                if (response.status === 400) {
                    showNotification('Could not add influencer to campaign', 'error');
                    return;
                }

                if (typeof onInfluencerAddedToCampaign === 'function') {
                    onInfluencerAddedToCampaign(response.data);
                }
                showNotification('Successfully added', 'info');
            } catch {
                showNotification('Could not add influencer to campaign', 'error');
            }
        },
        [
            influencerCampaigns?.influencer_id,
            influencerUserStats?.cost,
            influencerUserStats?.currency?.id,
            onInfluencerAddedToCampaign,
        ]
    );

    const onExpandedGroup = useCallback((expanded: boolean, count: number) => {
        if (expanded) {
            setGroupsExpandedCount((prev) => prev + count / 4 + 1);
            return;
        }
        setGroupsExpandedCount((prev) => prev - count / 4 - 1);
    }, []);

    const optionsCount = search.length ? count : plans.length + count;

    return (
        <div
            onClick={(e) => {
                if (!readonly) {
                    e.stopPropagation();
                }
            }}
        >
            <button className={cn(styles.cellText, className, { [styles.readonly]: readonly })} ref={setButtonRef}>
                {children}
            </button>
            <Popover disabled={readonly} anchorElement={buttonRef} showOn="click" options={{ placement: 'bottom-end' }}>
                <Card className={styles.dropdown}>
                    <OptionsList
                        searchable
                        searchPlaceholder="Search campaigns"
                        searchQuery={search}
                        setSearchQuery={setSearch}
                        options={options}
                        count={optionsCount}
                        listStyles={{ height: (optionsCount + groupsExpandedCount) * 70 }}
                        loading={loading}
                        loadMore={getNextPage}
                    >
                        {({ item, ...props }) => {
                            const influencerCampaign = influencerCampaigns?.plans.find((p) => p.plan_id === item?.id);
                            return (
                                <InfluencerPlanOption
                                    {...props}
                                    item={item}
                                    influencerCampaign={influencerCampaign}
                                    addInfluencerToCampaign={addInfluencerToCampaign}
                                    onExpandedGroup={onExpandedGroup}
                                />
                            );
                        }}
                    </OptionsList>
                </Card>
            </Popover>
        </div>
    );
};

type InfluencerPlanOptionProps = ListItemProps<InfluencerPlanOptionType> & {
    item: InfluencerPlanOptionType | undefined;
    style: CSSProperties;
    influencerCampaign: InfluencerUserCampaign | undefined;
    loading?: boolean;
    addInfluencerToCampaign: (planId: number, groupId: number) => void;
    onExpandedGroup?: (expanded: boolean, count: number) => void;
};

function InfluencerPlanOption({
    item,
    loading,
    style,
    influencerCampaign,
    parent,
    index,
    columnIndex,
    clearCache,
    addInfluencerToCampaign,
    onExpandedGroup,
}: InfluencerPlanOptionProps) {
    const [expanded, setExpanded] = useState(false);
    const [groups, setGroups] = useState<InfluencerPostGroup[]>([]);
    const [groupsLoading, setGroupsLoading] = useState(false);
    const [errorLoadingGroups, setErrorLoadingGroups] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const fetchGroups = useCallback(async (influencerPlanId: number, requestInit?: RequestInit) => {
        try {
            setErrorLoadingGroups(false);
            setGroupsLoading(true);
            const groups = await getAllInfluencerPostGroups('tiktok', influencerPlanId, requestInit);
            setGroups(groups);
            setInitialized(true);
        } catch (e: any) {
            if (e.name === 'AbortError') {
                return;
            }

            setErrorLoadingGroups(true);
        } finally {
            setGroupsLoading(false);
        }
    }, []);

    useAbortableEffect(
        (signal) => {
            if (typeof item?.id === 'number' && expanded && !initialized) {
                fetchGroups(item.id, { signal });
            }
        },
        [item?.id, fetchGroups, expanded, initialized]
    );

    useEffect(() => {
        if (typeof parent.recomputeGridSize === 'function') {
            clearCache(index, columnIndex);
            parent.recomputeGridSize({ rowIndex: index, columnIndex });
        }
    }, [clearCache, columnIndex, expanded, index, parent, groupsLoading, errorLoadingGroups, groups]);

    useEffect(() => {
        if (onExpandedGroup && initialized) {
            onExpandedGroup(expanded, groups.length);
        }
    }, [expanded, onExpandedGroup, groups.length, initialized]);

    return (
        <>
            <div
                style={style}
                className={cn(styles.option, { [styles.expanded]: expanded })}
                onClick={() => setExpanded(!expanded)}
            >
                <div className={styles.optionLabels}>
                    <TriangleIcon className={cn(styles.expandedIcon, { [styles.expanded]: expanded })} />
                    <a
                        href={`/campaigns/${item?.releaseId}/creators#tiktok`}
                        target="_blank"
                        className={cn(styles.optionName, {
                            [styles.withInfluencer]: !!influencerCampaign,
                        })}
                        rel="noreferrer noopener"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {loading ? <Skeleton /> : item?.name}
                    </a>
                    <p className={styles.optionSubtitle}>{loading ? <Skeleton /> : item?.subtitle}</p>
                </div>
                {expanded && (
                    <div className={styles.groupsContainer} onClick={(e) => e.stopPropagation()}>
                        {groupsLoading && <LoadingSpinner />}
                        {errorLoadingGroups && <div className={styles.groupsNoticeText}>Couldn't get groups</div>}
                        {!groupsLoading && !errorLoadingGroups && groups.length === 0 && (
                            <div className={styles.groupsNoticeText}>No groups</div>
                        )}
                        {groups.map((group) => (
                            <div key={group.id} className={styles.group}>
                                <span
                                    className={cn(styles.groupName, {
                                        [styles.withInfluencer]: influencerCampaign?.group_ids.includes(group.id),
                                    })}
                                >
                                    {group.name}
                                </span>
                                <button
                                    className={styles.addToGroupButton}
                                    onClick={() => {
                                        if (item) {
                                            addInfluencerToCampaign(item?.id, group.id);
                                        }
                                    }}
                                >
                                    <PlusIcon />
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}

export default AddTiktokInfluencerToCampaign;
