import { Image } from '@round/ui-kit';
import styles from './FormIssues.module.css';
import formIssueImage from './FormIssueImage.png';

export const FormClosed = () => {
    return (
        <div className={styles.container}>
            <Image src={formIssueImage} className={styles.image} />
            <h1>Form Deadline Expired</h1>
            <p>
                Please check your link again or <a href="mailto:songcollaborations@thisisround.com">contact us</a> to
                get help
            </p>
        </div>
    );
};

export const FormLinkBroken = () => {
    return (
        <div className={styles.container}>
            <Image src={formIssueImage} className={styles.image} />
            <h1>Link Broken</h1>
            <p>
                Please check your link again or <a href="mailto:songcollaborations@thisisround.com">contact us</a> to
                get help
            </p>
        </div>
    );
};

export const FormError = () => {
    return (
        <div className={styles.container}>
            <Image src={formIssueImage} className={styles.image} />
            <h1>Something went wrong</h1>
            <p>
                Please try again later or <a href="mailto:songcollaborations@thisisround.com">contact us</a> to get help
            </p>
        </div>
    );
};
