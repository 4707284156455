import { encodeUrlSearchParams, fetchWithToken } from '../../../helpers';
import {
    ApiResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
    ApiResponseError,
} from '../../../types';
import { PaypalPaymentStatus, PostPaymentRequest } from './postPaymentRequests.types';

export type GetPostPaymentRequestsParams = Partial<
    PaginatedRequest & {
        plan_id: number;
        paypal_payment_status: PaypalPaymentStatus;
        tiktok_influencer_user_id: number;
    }
>;

type GetPostPaymentRequestsResponse = ApiResponse<PaginatedApiResponseData<PostPaymentRequest>, 200> | NotFoundResponse;

export async function getPostPaymentRequests(
    params: GetPostPaymentRequestsParams,
    requestInit?: RequestInit
): Promise<GetPostPaymentRequestsResponse> {
    const response = await fetchWithToken(
        `/api/advertising/post-payment-requests/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get post payment requests');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PostPostPaymentRequestData =
    | Pick<PostPaymentRequest, 'amount' | 'currency' | 'tiktok_influencer_post' | 'paypal_email_address'>
    | Pick<PostPaymentRequest, 'amount' | 'currency' | 'instagram_influencer_post' | 'paypal_email_address'>
    | Pick<PostPaymentRequest, 'amount' | 'currency' | 'youtube_influencer_post' | 'paypal_email_address'>;

type PostPostPaymentRequestResponse =
    | ApiResponse<PostPaymentRequest, 201>
    | ApiResponse<ApiResponseError<PostPostPaymentRequestData>, 400>
    | ApiResponse<{ detail: string }, 403>;

export async function postPostPaymentRequest(
    data: PostPostPaymentRequestData
): Promise<PostPostPaymentRequestResponse> {
    const response = await fetchWithToken('/api/advertising/post-payment-requests/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create post payment request');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export type PatchPostPaymentRequestData = Pick<
    PostPaymentRequest,
    'amount' | 'currency' | 'payment_reference' | 'paypal_email_address'
>;

type PatchPostPaymentRequestResponse =
    | ApiResponse<PostPaymentRequest, 200>
    | NotFoundResponse
    | ApiResponse<ApiResponseError<PatchPostPaymentRequestData>, 400>;

export async function patchPostPaymentRequest(
    id: number,
    data: Partial<PatchPostPaymentRequestData>
): Promise<PatchPostPaymentRequestResponse> {
    const response = await fetchWithToken(`/api/advertising/post-payment-requests/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not update post payment request');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
