import { PublicReport } from './publicReports.types';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer } from '../../mirage';

export const models = {
    publicReport: Model as ModelDefinition<PublicReport>,
};

export const serializers = {
    publicReport: makeSerializer<PublicReport>([]),
};

const formatDate = (date: Date) => date.toISOString().split('T')[0];

export const factories = {
    publicReport: createFactory<PublicReport>({
        name: 'Report',
        description: 'Report description',
        platforms: ['instagram', 'tiktok', 'youtube'],
        first_post_date: () => formatDate(new Date()),
        last_post_date: () => formatDate(new Date()),
    }),
};

export function handleRequests(server: AppServer) {
    const publicReportsRootUrl = '/api/influencer/public-reports/';
    server.get(`${publicReportsRootUrl}:uuid/`, function (schema, request) {
        const uuid = request.params.uuid;

        const publicReport = schema.find('publicReport', uuid);

        if (!publicReport) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return publicReport;
    });
}
