import cn from 'classnames';
import styles from 'ui-new/formik/FormField.module.css';
import { Popover } from '@round/ui-kit';
import Calendar, { CalendarProps } from 'ui/DataEntry/Calendar/Calendar';
import { FieldHookConfig, useField } from 'formik';
import { useState } from 'react';
import moment from 'moment/moment';
import { ReactComponent as CalendarIcon } from 'assets/Calendar.svg';

type Props = {
    label: string;
    className?: string;
    valueFormat: string;
} & FieldHookConfig<string> &
    Pick<CalendarProps, 'minDate'>;

const DateField = ({ label, className, disabled, valueFormat, minDate, ...props }: Props) => {
    const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
    const [field, meta, helpers] = useField(props);

    const hasError = meta.error;

    return (
        <>
            <div ref={setAnchor} className={cn(styles.field, className, { [styles.disabled]: disabled })}>
                <label className={styles.label} htmlFor={props.name}>
                    {label}
                </label>
                <div
                    tabIndex={0}
                    className={cn(styles.input, styles.dateInput, {
                        [styles.error]: hasError,
                        [styles.disabled]: disabled,
                    })}
                >
                    <span>{moment(field.value).format(valueFormat)}</span>
                    <CalendarIcon className={styles.dateIcon} />
                </div>
                {hasError ? <span className={styles.errorMessage}>{meta.error}</span> : null}
            </div>

            <Popover anchorElement={anchor} showOn="click" disabled={disabled}>
                <div className={styles.dropdown}>
                    <Calendar
                        minDate={minDate}
                        value={field.value}
                        onChange={(value) => {
                            helpers.setTouched(true);
                            helpers.setValue(value);
                        }}
                    />
                </div>
            </Popover>
        </>
    );
};

export default DateField;
