import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { ClientSongCollection } from './songCollection.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    clientSongCollection: Model as ModelDefinition<ClientSongCollection>,
};

export const serializers = {
    clientSongCollection: makeSerializer<ClientSongCollection>([]),
};

export const factories = {
    clientSongCollection: createFactory<ClientSongCollection>({
        name(index: number) {
            return `client song collection name ${index}`;
        },
        picture: null,
    }),
};

export function handleRequests(server: AppServer) {
    const getClientSongCollections = '/api/trnds/client/:clientId/song-collection/';
    server.get(getClientSongCollections, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('clientSongCollection'), {
            url: getClientSongCollections,
            serialize: (resource) => this.serialize(resource, 'clientSongCollection'),
            queryParams: request.queryParams,
        });
    });

    server.get(
        '/api/trnds/client/:clientId/song-collection/:collectionId/',
        function (this: RouteHandlerContext, schema, request) {
            const collection = schema.find('clientSongCollection', request.params.collectionId);
            if (!collection) {
                return new Response(404, {}, { detail: 'Not found.' });
            }

            return collection;
        }
    );

    server.get(
        '/api/trnds/client/:clientId/song-collection/:collectionId/stats/',
        function (this: RouteHandlerContext, schema) {
            const stats = schema.first('songCollectionStat');
            if (!stats) {
                return new Response(404, {}, { error: 'No stats cache found for given song collection' });
            }

            return stats;
        }
    );

    const getCollectionSongsStats = '/api/trnds/client/:clientId/song-collection/:collectionId/song-stats/';
    server.get(getCollectionSongsStats, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('songTiktokStat'), {
            url: getCollectionSongsStats,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'songTiktokStat'),
        });
    });

    const getCollectionTiktokAudioStats =
        '/api/trnds/client/:clientId/song-collection/:collectionId/tiktok-audio-stats/';
    server.get(getCollectionTiktokAudioStats, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('trndsClientTiktokAudioStat'), {
            url: getCollectionTiktokAudioStats,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'trndsClientTiktokAudioStat'),
        });
    });

    server.post('/api/trnds/client/:clientId/song-collection/', function (this: RouteHandlerContext, schema, request) {
        const data = JSON.parse(request.requestBody);
        return schema.create('clientSongCollection', data);
    });

    server.patch(
        '/api/trnds/client/:clientId/song-collection/:collectionId/',
        function (this: RouteHandlerContext, schema, request) {
            const collection = schema.find('clientSongCollection', request.params.collectionId);
            if (!collection) {
                return new Response(404, {}, { detail: 'Not founds' });
            }

            collection.update(JSON.parse(request.requestBody));
            return collection;
        }
    );
}
