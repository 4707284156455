import { useCallback, useContext } from 'react';
import { InfluencerPlanContext } from '../InfluencerPlanContext';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { TiktokCreatorsDispatchContext, TiktokCreatorsStateContext } from './TiktokCreatorsContext';
import { fetchTiktokInfluencerPlanHashtag } from '../../InfluencerPlan.api';

export default function useCreatorPlanTiktokHashtags() {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const state = useNonNullContext(TiktokCreatorsStateContext);
    const dispatch = useNonNullContext(TiktokCreatorsDispatchContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (!influencerPlan?.id) {
                return;
            }

            try {
                dispatch({ type: 'loadTiktokHashtags' });
                const hashtags = await fetchTiktokInfluencerPlanHashtag(influencerPlan.id, requestInit);
                dispatch({ type: 'tiktokHashtagsInitialized', payload: hashtags });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'setTiktokHashtagError',
                    payload: e instanceof Error ? e.message : "Sorry, we're experiencing technical issues",
                });
            }
        },
        [dispatch, influencerPlan?.id]
    );

    return {
        init,
        hashtags: state.tiktokHashtags,
        isLoading: state.tiktokHashtagsLoading,
        error: state.tiktokHashtagsLoadingError,
        isInitialized: state.tiktokHashtagsInitialized,
    };
}
