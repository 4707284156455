import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styles from './InfluencerPlanItemModal.module.css';
import { FormikHelpers, FormikProps } from 'formik';
import { showNotification } from '../../../../../helpers';
import { GenericDropdownOption } from '../../../../../App.types';
import Modal, { ModalContent, ModalTitle } from '../../../../../ui/General/Modal/Modal';
import DeletePostConfirmationModal from './DeletePostConfirmationModal';
import { InstagramInfluencerPost, InstagramInfluencerPostResult } from '../../types/Instagram.types';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../../contexts/OptionsContext/OptionsContext';
import {
    deleteInstagramInfluencerPostResults,
    patchInstagramInfluencerPost,
    patchInstagramInfluencerPostResults,
    postInstagramInfluencerPostResults,
} from '../../api/Instagram.api';
import { mapApiErrorsToFormikErrors } from '../../../../../utility/utility';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { getXeroInvoice, getInstagramInfluencerUser } from '@round/api';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import { DropdownOptionWithSubLabel } from '../../../../../ui/DataEntry/Select/OptionWithSubLabel/OptionWithSubLabel';
import { InstagramCreatorsContext } from '../../contexts/InstagramCreatorsContext/InstagramCreatorsContext';
import InstagramInfluencerPostForm, {
    InstagramInfluencerPostFields,
    InstagramInfluencerPostFormValues,
    InstagramInfluencerPostResultsFields,
} from './InfluencerPostForm/InstagramInfluencerPostForm';
import Button from '../../../../../ui/Buttons/Button/Button';

type UpdateInstagramInfluencerModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    onUpdated: (updated?: {
        post?: InstagramInfluencerPost | undefined;
        results?: InstagramInfluencerPostResult | undefined;
        resultsIdToDelete?: number | undefined;
    }) => void;
    onDeleted: () => void;
    post: InstagramInfluencerPost | null;
    postResults: InstagramInfluencerPostResult | null;
};

const getPostData = (
    values: InstagramInfluencerPostFormValues
): Pick<InstagramInfluencerPostFormValues, InstagramInfluencerPostFields> =>
    pick(values, [
        'currency_id',
        'influencer_id',
        'cost',
        'xero_invoice_id',
        'post_url',
    ] as InstagramInfluencerPostFields[]);

const getResultsData = (
    values: InstagramInfluencerPostFormValues
): Pick<InstagramInfluencerPostFormValues, InstagramInfluencerPostResultsFields> =>
    pick(values, [
        'page_followers',
        'feed_views',
        'likes',
        'comments',
        'story_views',
    ] as InstagramInfluencerPostResultsFields[]);

const UpdateInstagramInfluencerPostModal = ({
    isModalOpen,
    closeModal,
    post,
    postResults,
    onUpdated,
    onDeleted,
}: UpdateInstagramInfluencerModalProps) => {
    const [hasErrorOnUpdate, setHasErrorOnUpdate] = useState(false);

    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { currencies } = useNonNullContext(OptionsContext);
    const { deleteInstagramInfluencerPost } = useNonNullContext(InstagramCreatorsContext);

    const currencyOptions: GenericDropdownOption<number>[] = useMemo(
        () =>
            currencies.map((c) => ({
                value: c.id,
                label: c.name,
            })),
        [currencies]
    );

    useEffect(() => {
        if (isModalOpen) {
            return;
        }
        setInitialInfluencerUser(null);
        setInitialInvoiceOption(null);
    }, [isModalOpen]);

    const [initialInfluencerUser, setInitialInfluencerUser] = useState<GenericDropdownOption<number> | null>(null);

    useAbortableEffect(
        (signal) => {
            if (!post || typeof post.influencer_id !== 'number') {
                return;
            }

            getInstagramInfluencerUser(post.influencer_id, { signal })
                .then((response) => {
                    if (response.status !== 200) {
                        return;
                    }
                    setInitialInfluencerUser({ value: response.data.id, label: response.data.username });
                })
                .catch(() => {});
        },
        [post, post?.influencer_id]
    );

    const [initialInvoiceOption, setInitialInvoiceOption] = useState<DropdownOptionWithSubLabel<number> | null>(null);

    useAbortableEffect(
        (signal) => {
            async function fetchInitialInvoice() {
                if (!post?.xero_invoice_id) {
                    return;
                }
                const response = await getXeroInvoice(post.xero_invoice_id, { signal });
                if (response.status === 404) {
                    return;
                }
                const invoice = response.data;
                setInitialInvoiceOption({
                    value: invoice.id,
                    label: invoice.invoice_number,
                    subLabel: invoice.reference,
                });
            }
            fetchInitialInvoice();
        },
        [post?.xero_invoice_id]
    );

    const initialValues: InstagramInfluencerPostFormValues = useMemo(
        () => ({
            currency_id: currencyOptions.find((o) => o.value === post?.currency_id) ?? null,
            influencer_id: initialInfluencerUser,
            cost: post?.cost,
            xero_invoice_id: initialInvoiceOption ?? null,
            post_url: post?.post_url,
            comments: postResults?.comments,
            feed_views: postResults?.feed_views,
            likes: postResults?.likes,
            page_followers: postResults?.page_followers,
            story_views: postResults?.story_views,
        }),
        [
            currencyOptions,
            initialInfluencerUser,
            post?.cost,
            post?.post_url,
            post?.currency_id,
            initialInvoiceOption,
            postResults?.comments,
            postResults?.feed_views,
            postResults?.likes,
            postResults?.page_followers,
            postResults?.story_views,
        ]
    );

    const submitInstagramInfluencerPost = useCallback(
        async (id: number, values: InstagramInfluencerPostFormValues) => {
            const postData = getPostData(values);
            const postDataChanged = !isEqual(getPostData(initialValues), postData);
            if (!postDataChanged) {
                return;
            }

            return patchInstagramInfluencerPost(id, {
                ...postData,
                influencer_id: postData.influencer_id?.value,
                currency_id: postData.currency_id?.value,
                xero_invoice_id: values.xero_invoice_id ? values.xero_invoice_id.value : null,
            });
        },
        [initialValues]
    );

    const submitInstagramInfluencerPostResults = useCallback(
        async (id: number | undefined, values: InstagramInfluencerPostFormValues) => {
            const resultsData = getResultsData(values);
            const resultsChanged = !isEqual(getResultsData(initialValues), resultsData);
            if (!resultsChanged) {
                return;
            }

            if (typeof id === 'number') {
                if (Object.values(resultsData).every((val) => typeof val !== 'number')) {
                    return deleteInstagramInfluencerPostResults(id);
                } else {
                    return patchInstagramInfluencerPostResults(id, resultsData);
                }
            }

            if (typeof post?.id !== 'number') {
                return;
            }

            return postInstagramInfluencerPostResults({ ...resultsData, post: post.id });
        },
        [initialValues, post?.id]
    );

    const handleSubmit = useCallback(
        async (
            values: InstagramInfluencerPostFormValues,
            { setSubmitting, setErrors }: FormikHelpers<InstagramInfluencerPostFormValues>
        ) => {
            if (!post) {
                return;
            }

            try {
                setHasErrorOnUpdate(false);
                setSubmitting(true);
                const instagramInfluencerPostResponse = await submitInstagramInfluencerPost(post.id, values);
                if (instagramInfluencerPostResponse?.status === 400) {
                    setErrors(mapApiErrorsToFormikErrors(instagramInfluencerPostResponse.data));
                    return;
                }

                const resultsResponse = await submitInstagramInfluencerPostResults(postResults?.id, values);
                if (resultsResponse?.status === 400) {
                    setErrors(mapApiErrorsToFormikErrors(resultsResponse.data));
                    return;
                }

                onUpdated({
                    post: instagramInfluencerPostResponse?.data as InstagramInfluencerPost,
                    results: resultsResponse?.data as InstagramInfluencerPostResult,
                    resultsIdToDelete: resultsResponse?.status === 204 ? postResults?.id : undefined,
                });
            } catch {
                setHasErrorOnUpdate(true);
            } finally {
                setSubmitting(false);
            }
        },
        [onUpdated, post, postResults?.id, submitInstagramInfluencerPost, submitInstagramInfluencerPostResults]
    );
    const handleDelete = useCallback(async () => {
        if (!post) {
            return;
        }

        try {
            setDeleteLoading(true);
            await deleteInstagramInfluencerPost(post.id);
            showNotification('Post deleted', 'info');
            onDeleted();
        } catch {
            showNotification('Could not delete post', 'error');
        } finally {
            setDeleteLoading(false);
            setShowDeletePrompt(false);
        }
    }, [deleteInstagramInfluencerPost, onDeleted, post]);

    return (
        <>
            <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={closeModal} modalClassName={styles.modal}>
                <ModalTitle>Change details</ModalTitle>
                <ModalContent className={styles.content}>
                    {hasErrorOnUpdate && <div>Error writing data</div>}
                    <InstagramInfluencerPostForm
                        handleSubmit={handleSubmit}
                        initialValues={initialValues}
                        renderActions={({
                            isSubmitting,
                            submitForm,
                            dirty,
                        }: FormikProps<InstagramInfluencerPostFormValues>) => (
                            <div className={styles.renderActions}>
                                <Button
                                    className={styles.submitButton}
                                    type="filled"
                                    color="black"
                                    disabled={!dirty}
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Save
                                </Button>
                                <Button
                                    className={styles.deleteButton}
                                    type="filled"
                                    color="negative"
                                    loading={deleteLoading}
                                    onClick={() => setShowDeletePrompt(true)}
                                >
                                    Delete
                                </Button>
                            </div>
                        )}
                    />
                </ModalContent>
            </Modal>
            <DeletePostConfirmationModal
                handleDelete={handleDelete}
                isModalOpen={showDeletePrompt}
                deleteLoading={deleteLoading}
                onClose={() => setShowDeletePrompt(false)}
            />
        </>
    );
};

export default UpdateInstagramInfluencerPostModal;
