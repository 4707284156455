import { microwave } from '@round/api';
import { Skeleton } from '@round/ui-kit';
import InfluencerBadge from 'Modules/Influencer/components/AccountBadge/InfluencerBadge';
import styles from './AccountCell.module.css';
import { ReactComponent as LinkIcon } from 'assets/icons/Link.svg';
import { getMicrowaveInfluencerAccountData } from './helpers';

type Props = {
    influencer: microwave.MicrowaveInfluencer | null;
    platform: microwave.Platform;
    imageUrl?: string;
    postUrl?: string;
    isLoading?: boolean;
};

const AccountCell = ({ influencer, imageUrl, postUrl, platform, isLoading }: Props) => {
    if (isLoading) {
        return <Skeleton />;
    }

    if (!influencer) {
        return <>-</>;
    }

    const influencerData = getMicrowaveInfluencerAccountData(influencer, platform);

    return (
        <div className={styles.container}>
            <InfluencerBadge
                data={{
                    name: influencerData?.name || '-',
                    profileUrl: influencerData?.profileUrl || '',
                    username: influencerData?.username || '-',
                    imageUrl: imageUrl || '',
                }}
                isLoading={isLoading}
            />
            {postUrl && (
                <a href={postUrl} target="_blank" rel="noreferrer">
                    <LinkIcon className={styles.linkIcon} />
                </a>
            )}
        </div>
    );
};

export default AccountCell;
