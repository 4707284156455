import { getPhylloInstagramCreatorProfiles, PhylloInstagramCreatorProfile } from '@round/api';
import { useState } from 'react';
import useAbortableEffect from '../../../Hooks/useAbortableEffect';

type Params = Parameters<typeof getPhylloInstagramCreatorProfiles>[0];

export default function useInstagramCreatorProfiles({
    page,
    page_size,
    min_follower_count,
    max_follower_count,
    min_reels_views,
    max_reels_views,
    engagement_rate,
    has_contact_details,
    location,
    bio_phrase,
    creator_lookalikes,
    audience_lookalikes,
    creator_interests,
    audience_interests,
    min_audience_locations,
    hashtags,
    ordering,
    follower_growth_percentage_value,
    follower_growth_interval,
}: Params) {
    const [creators, setCreators] = useState<PhylloInstagramCreatorProfile[]>([]);
    const [creatorsCount, setCreatorsCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);

    useAbortableEffect(
        (signal) => {
            async function fetchData() {
                try {
                    setIsLoading(true);
                    setErrorLoading(false);
                    const response = await getPhylloInstagramCreatorProfiles(
                        {
                            page,
                            page_size,
                            min_follower_count,
                            max_follower_count,
                            min_reels_views,
                            max_reels_views,
                            engagement_rate,
                            has_contact_details,
                            location,
                            bio_phrase,
                            creator_lookalikes,
                            audience_lookalikes,
                            creator_interests,
                            audience_interests,
                            min_audience_locations,
                            hashtags,
                            ordering,
                            follower_growth_interval,
                            follower_growth_percentage_value,
                        },
                        { signal }
                    );
                    setCreators(response.data.results);
                    setCreatorsCount(response.data.count);
                    setIsInitialized(true);
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    setErrorLoading(true);
                } finally {
                    setIsLoading(false);
                }
            }

            fetchData();
        },
        [
            bio_phrase,
            has_contact_details,
            creator_lookalikes,
            audience_lookalikes,
            creator_interests,
            audience_interests,
            location,
            max_follower_count,
            min_follower_count,
            min_reels_views,
            max_reels_views,
            engagement_rate,
            min_audience_locations,
            hashtags,
            ordering,
            page,
            page_size,
            follower_growth_interval,
            follower_growth_percentage_value,
        ]
    );

    return {
        creators,
        creatorsCount,
        isLoading,
        errorLoading,
        isInitialized,
        reset: () => {
            setCreators([]);
            setIsLoading(false);
            setErrorLoading(false);
            setCreatorsCount(0);
            setIsInitialized(false);
        },
    };
}
