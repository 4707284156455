import { useFormik } from 'formik';
import useReportData from '../ReportData/useReportData';
import { creatorbase } from '@round/api';
import { ProjectSelectTableRow } from './ProjectSelectTable/ProjectSelectTable';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { useEffect, useState } from 'react';
import { DataState } from 'App.types';

type FormValues = {
    projects: ProjectSelectTableRow[];
};

type Params = {
    onSubmitResponse: (response: creatorbase.PatchReportResponse | undefined) => void;
    onSubmitError: (error: Error) => void;
    isFormActive: boolean;
};

export function useProjectSelectForm({ onSubmitResponse, onSubmitError, isFormActive }: Params) {
    const { updateReport, data: reportData } = useReportData();

    const [initialProjectsData, setInitialProjectsData] = useState<DataState<ProjectSelectTableRow[]>>({
        status: 'idle',
        data: [],
        error: null,
    });

    const isInitialized = initialProjectsData.status === 'success' || initialProjectsData.status === 'error';

    const fetchInitialData = async (projectIds: number[], requestInit: RequestInit) => {
        if (!projectIds.length) {
            setInitialProjectsData({ status: 'success', data: [], error: null });
            return;
        }
        const response = await creatorbase.getProjects(
            {
                id: projectIds.toString(),
                page_size: projectIds.length,
            },
            requestInit
        );

        if (response.status !== 200) {
            setInitialProjectsData({ status: 'error', data: [], error: "Couldn't fetch initial projects" });
            return;
        }

        const teamIds = response.data.results.map((p) => p.team_id);
        const brandIds = response.data.results.map((p) => p.brand_id);
        const assigneeIds = response.data.results.map((p) => p.users_assigned_to_campaigns).flat();

        const [teamsResult, brandsResult, assigneesResult] = await Promise.allSettled([
            creatorbase.getTeams({ id: teamIds.toString() }, requestInit),
            creatorbase.getBrands({ id: brandIds.toString() }, requestInit),
            creatorbase.getUsers({ id: assigneeIds.toString() }, requestInit),
        ]);

        const teams =
            teamsResult.status === 'fulfilled' && teamsResult.value.status === 200
                ? teamsResult.value.data.results
                : null;
        const brands =
            brandsResult.status === 'fulfilled' && brandsResult.value.status === 200
                ? brandsResult.value.data.results
                : null;
        const assignees =
            assigneesResult.status === 'fulfilled' && assigneesResult.value.status === 200
                ? assigneesResult.value.data.results
                : null;

        setInitialProjectsData({
            status: 'success',
            data: response.data.results.map((project) => ({
                ...project,
                team: teams?.find((t) => t.id === project.team_id) ?? null,
                brand: brands?.find((b) => b.id === project.brand_id) ?? null,
                users: project.users_assigned_to_campaigns
                    .map((userId) => assignees?.find((a) => a.id === userId) ?? null)
                    .filter((u): u is Exclude<typeof u, null> => !!u),
            })),
            error: null,
        });
    };

    useAbortableEffect(
        (signal) => {
            if (isInitialized || !reportData || !isFormActive) {
                return;
            }
            fetchInitialData(reportData.project_ids, { signal }).catch(() => {});
        },
        [reportData, isInitialized, isFormActive]
    );

    const formik = useFormik<FormValues>({
        initialValues: {
            projects: initialProjectsData.data ?? [],
        },
        onSubmit: async (values, helpers) => {
            if (!reportData?.id) {
                return;
            }

            try {
                helpers.setSubmitting(true);

                const response = await updateReport({
                    project_ids: values.projects.map((p) => p.id) ?? [],
                });

                onSubmitResponse(response);
            } catch (e) {
                if (e instanceof Error) {
                    onSubmitError(e);
                }
            }
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        if (isFormActive) {
            return;
        }

        setInitialProjectsData({ status: 'idle', data: [], error: null });
    }, [isFormActive]);

    return {
        status: initialProjectsData.status,
        formik,
    };
}
