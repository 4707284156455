import React from 'react';
import SearchIcon from '../../SharedComponents/svg/Icons/SearchIcon';
import { DebounceInput } from 'react-debounce-input';
import styles from './SearchInput.module.css';
import cn from 'classnames';

type SearchInputProps = {
    onChange: (value: string) => void;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    debounceTimout?: number;
    inputClassName?: string;
};

const SearchInput = ({
    debounceTimout = 750,
    placeholder = 'Search',
    value,
    className,
    disabled,
    onChange,
    inputClassName,
}: SearchInputProps) => (
    <div className={cn(styles.container, className)}>
        <SearchIcon fill="#90A0B7" width={16} height={16} />
        <DebounceInput
            className={cn(styles.input, inputClassName)}
            type="text"
            disabled={disabled}
            debounceTimeout={debounceTimout}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    </div>
);

export default SearchInput;
