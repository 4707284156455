import { createContext, Dispatch, ReactNode, useCallback, useReducer } from 'react';
import { ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import useNonNullContext from 'Hooks/useNonNullContext';
import { microwave } from '@round/api';
import { getAllItems } from 'utility/api';

type State = {
    campaigns: microwave.Campaign[];
    areCampaignsLoading: boolean;
    hasCampaignsLoadingError: boolean;
    areCampaignsInitialized: boolean;
    campaignStats: microwave.CampaignStats[];
    areCampaignStatsLoading: boolean;
    areCampaignStatsInitialized: boolean;
    hasCampaignStatsLoadingError: boolean;
};

const initialState: State = {
    campaigns: [],
    areCampaignsLoading: false,
    hasCampaignsLoadingError: false,
    areCampaignsInitialized: false,
    campaignStats: [],
    areCampaignStatsInitialized: false,
    areCampaignStatsLoading: false,
    hasCampaignStatsLoadingError: false,
};

type Actions =
    | ReducerActionWithPayload<'campaignsInitialized', State['campaigns']>
    | ReducerAction<'loadCampaigns'>
    | ReducerAction<'setHasErrorLoadingCampaign'>
    | ReducerAction<'loadCampaignStats'>
    | ReducerActionWithPayload<'campaignStatsInitialized', State['campaignStats']>
    | ReducerAction<'errorLoadingCampaignStats'>
    | ReducerAction<'resetCampaignStats'>;

const reducer = createReducer<State, Actions>({
    campaignsInitialized: (state, { payload }) => ({
        ...state,
        campaigns: payload,
        areCampaignsLoading: false,
        hasCampaignsLoadingError: false,
        areCampaignsInitialized: true,
    }),
    loadCampaigns: (state) => ({
        ...state,
        areCampaignsLoading: true,
        hasCampaignsLoadingError: false,
        areCampaignsInitialized: false,
    }),
    setHasErrorLoadingCampaign: (state) => ({
        ...state,
        areCampaignsLoading: false,
        hasCampaignsLoadingError: true,
        areCampaignsInitialized: true,
    }),
    loadCampaignStats: (state) => ({
        ...state,
        areCampaignStatsLoading: true,
        areCampaignStatsInitialized: false,
        hasCampaignStatsLoadingError: false,
    }),
    errorLoadingCampaignStats: (state) => ({
        ...state,
        areCampaignStatsLoading: false,
        areCampaignStatsInitialized: true,
        hasCampaignStatsLoadingError: true,
    }),
    campaignStatsInitialized: (state, { payload }) => ({
        ...state,
        campaignStats: payload,
        areCampaignStatsInitialized: true,
        areCampaignStatsLoading: false,
        hasCampaignStatsLoadingError: false,
    }),
    resetCampaignStats: (state) => ({
        ...state,
        campaignStats: [],
        areCampaignStatsInitialized: false,
        areCampaignStatsLoading: false,
        hasCampaignStatsLoadingError: false,
    }),
});

const MicroCampaignContext = createContext<State | null>(null);
const MicroCampaignDispatchContext = createContext<Dispatch<Actions> | null>(null);

export const MicroCampaignContextProvider = ({ children }: { children?: ReactNode | undefined }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <MicroCampaignContext.Provider value={state}>
            <MicroCampaignDispatchContext.Provider value={dispatch}>{children}</MicroCampaignDispatchContext.Provider>
        </MicroCampaignContext.Provider>
    );
};

export function useMicroCampaigns(releaseId: number | undefined) {
    const state = useNonNullContext(MicroCampaignContext);
    const dispatch = useNonNullContext(MicroCampaignDispatchContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (!releaseId) {
                return;
            }

            try {
                dispatch({ type: 'loadCampaigns' });
                const response = await microwave.getCampaigns({ release_id: `${releaseId}` }, requestInit);
                if (response.status === 404) {
                    dispatch({ type: 'setHasErrorLoadingCampaign' });
                    return;
                }

                dispatch({ type: 'campaignsInitialized', payload: response.data.results });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'setHasErrorLoadingCampaign' });
            }
        },
        [dispatch, releaseId]
    );

    return {
        campaigns: state.campaigns,
        isLoading: state.areCampaignsLoading,
        isInitialized: state.areCampaignsInitialized,
        hasError: state.hasCampaignsLoadingError,
        init,
    };
}

export function useMicrowaveCampaignStats() {
    const state = useNonNullContext(MicroCampaignContext);
    const dispatch = useNonNullContext(MicroCampaignDispatchContext);

    const fetchData = useCallback(
        async (campaignIds: number[]) => {
            if (!campaignIds.length) {
                return;
            }

            dispatch({ type: 'loadCampaignStats' });
            try {
                const campaignStats = await getAllItems(microwave.getCampaignStats, campaignIds);
                dispatch({ type: 'campaignStatsInitialized', payload: campaignStats });
            } catch (e) {
                dispatch({ type: 'errorLoadingCampaignStats' });
                throw e;
            }
        },
        [dispatch]
    );

    const reset = useCallback(() => dispatch({ type: 'resetCampaignStats' }), [dispatch]);

    return {
        campaignStats: state.campaignStats,
        isLoading: state.areCampaignStatsLoading,
        isInitialized: state.areCampaignStatsInitialized,
        hasError: state.hasCampaignStatsLoadingError,
        fetchData,
        reset,
    };
}
