import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './TiktokStatsMultiLineChart.module.css';
import LineChart from '../LineChart/LineChart';

const TiktokStatsMultiLineChart: FunctionComponent<{
    datasets: { label: string; data: { x: Date; y: number }[] }[];
    activeDate?: number;
}> = ({ datasets, activeDate }) => {
    const defaultLabel = '6 weeks';
    const [selectedPeriodLabel, setSelectedPeriodLabel] = useState(defaultLabel);
    const [selectedDatasetLabel, setSelectedDatasetLabel] = useState(datasets[0].label);
    const [daysToDisplay, setDaysToDisplay] = useState<number | undefined>(42);
    const [dataToDisplay, setDataToDisplay] = useState<{ x: Date; y: number }[]>([]);

    useEffect(() => {
        const data = datasets.find((set) => set.label === selectedDatasetLabel)?.data ?? [];
        if (daysToDisplay === undefined) {
            setDataToDisplay(data);
        } else {
            const thresholdDatetime = Date.now() - daysToDisplay * 24 * 60 * 60 * 1000;
            setDataToDisplay(data.filter(({ x }) => x.getTime() > thresholdDatetime));
        }
    }, [datasets, daysToDisplay, selectedDatasetLabel]);
    const pills = [
        { label: '2 weeks', days: 14 },
        { label: defaultLabel, days: 42 },
        { label: 'all time', days: undefined },
    ];

    return (
        <>
            <div className={styles.topRow}>
                <div className={styles.labelChoices}>
                    {datasets.map(({ label }, index) => (
                        <div
                            key={index}
                            onClick={() => setSelectedDatasetLabel(label)}
                            className={`${styles.datasetLabel} ${
                                selectedDatasetLabel === label && styles.activeDatasetLabel
                            }`}
                        >
                            {label}
                            {selectedDatasetLabel === label && <div className={styles.activeDatasetLabelUnderline} />}
                        </div>
                    ))}
                </div>
                <div className={styles.pills}>
                    {pills.map(({ label, days }, index) => {
                        return (
                            <div
                                key={index}
                                className={`${styles.pill} ${selectedPeriodLabel === label && styles.selectedPill}`}
                                onClick={() => {
                                    setSelectedPeriodLabel(label);
                                    setDaysToDisplay(days);
                                }}
                            >
                                {label}
                            </div>
                        );
                    })}
                </div>
            </div>
            <LineChart data={dataToDisplay} activeDate={activeDate} />
        </>
    );
};

export default TiktokStatsMultiLineChart;
