import { OrderingValues } from '@round/api';
import { ColumnSort } from '@tanstack/react-table';

export const mapOrderingToTableSorting = <T extends keyof Record<string, any>>(
    ordering: OrderingValues<T>[]
): ColumnSort[] => {
    return ordering.map((ordering) =>
        ordering.startsWith('-') ? { id: ordering.slice(1), desc: true } : { id: ordering, desc: false }
    );
};

export const mapTableSortingToOrdering = <T extends string>(sorting: ColumnSort[]): OrderingValues<T>[] => {
    return sorting.map((sorting) => {
        return `${sorting.desc ? '-' : ''}${sorting.id}` as OrderingValues<T>;
    });
};
