import { Checkbox, Table } from '@round/ui-kit';
import { ColumnDef, ExpandedState } from '@tanstack/react-table';
import { defaultData } from './TablePlayground';
import WrapperTable from 'ui-new/whitelabel/Table/Table';
import { useState } from 'react';

const ExpandingTablePlayground = () => {
    const [expandedState, setExpandedState] = useState<ExpandedState>({});

    const columns: ColumnDef<typeof defaultData[number], any>[] = [
        {
            header: 'Expand',
            cell: ({ row }) => <Checkbox value={row.getIsExpanded()} onChange={() => row.toggleExpanded()} />,
        },
        {
            accessorKey: 'firstName',
            header: 'First name',
        },
        {
            accessorKey: 'lastName',
            header: 'Last name',
        },
        {
            accessorKey: 'age',
        },
        {
            accessorKey: 'visits',
            footer: ({ table }) => table.getRowModel().rows.reduce((acc, row) => row.original.visits + acc, 0),
        },
        {
            accessorKey: 'status',
        },
        {
            accessorKey: 'progress',
        },
        {
            accessorKey: 'email',
        },
        {
            accessorKey: 'occupation',
        },
        {
            accessorKey: 'companyName',
            header: 'Company name',
        },
        {
            accessorKey: 'hobby',
        },
    ];

    const subTableColumns = columns.slice(1);

    return (
        <WrapperTable
            columns={columns}
            data={defaultData}
            enableExpanding
            manualExpanding
            expandedState={expandedState}
            onExpandedChange={setExpandedState}
            renderSubComponent={(row) => (
                <Table
                    style={{ marginLeft: '3rem', background: '#DDDD', width: '100%' }}
                    columns={subTableColumns}
                    data={defaultData}
                />
            )}
        />
    );
};

export default ExpandingTablePlayground;
