import { fetchWithToken } from '../../helpers';
import { ApiResponse, ApiResponseError, ForbiddenResponse, NotFoundResponse } from '../../types';
import { AggregatedPostStats } from './aggregatedPostStats.types';

export type AggregatedPostStatsForCampaignData = {
    campaign_id: number;
};

export type AggregatedPostStatsForProjectData = {
    platform: string;
    project_id: number;
};

export type AggregatedPostStatsForReportData = {
    platform: string;
    report_id: string;
};

export type AggregatedPostStatsPostData =
    | AggregatedPostStatsForProjectData
    | AggregatedPostStatsForCampaignData
    | AggregatedPostStatsForReportData;

type AggregatedPostStatsResponseError = ApiResponse<
    ApiResponseError<{
        details: string;
        platform: string;
    }>,
    400
>;

type AggregatedPostStatsResponse =
    | ApiResponse<AggregatedPostStats, 200>
    | AggregatedPostStatsResponseError
    | ForbiddenResponse
    | NotFoundResponse;

export async function postGetAggregatedPostStats(
    data: AggregatedPostStatsPostData,
    signal?: AbortSignal
): Promise<AggregatedPostStatsResponse> {
    const response = await fetchWithToken(`/api/creatorbase/aggregated-post-stats/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        signal,
    });

    if (response.status === 400 || response.status === 403 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get stats');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
