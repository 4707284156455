import { IconProps } from '../Icon.types';

const YoutubeIcon = ({ width, height, onClick, className }: IconProps) => {
    return (
        <svg
            role="img"
            aria-label="Youtube icon"
            onClick={onClick}
            className={className}
            width={width}
            height={height}
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_89_13221)">
                <path
                    d="M18.1417 5.13906C18.0399 4.75612 17.8394 4.40662 17.5601 4.12555C17.2808 3.84448 16.9326 3.64168 16.5503 3.53747C15.1431 3.15906 9.51807 3.15906 9.51807 3.15906C9.51807 3.15906 3.89307 3.15906 2.48579 3.53747C2.10351 3.64168 1.75531 3.84448 1.47603 4.12555C1.19675 4.40662 0.996196 4.75612 0.89443 5.13906C0.518066 6.55247 0.518066 9.49997 0.518066 9.49997C0.518066 9.49997 0.518066 12.4475 0.89443 13.8609C0.996196 14.2438 1.19675 14.5933 1.47603 14.8744C1.75531 15.1555 2.10351 15.3582 2.48579 15.4625C3.89307 15.8409 9.51807 15.8409 9.51807 15.8409C9.51807 15.8409 15.1431 15.8409 16.5503 15.4625C16.9326 15.3582 17.2808 15.1555 17.5601 14.8744C17.8394 14.5933 18.0399 14.2438 18.1417 13.8609C18.5181 12.4475 18.5181 9.49997 18.5181 9.49997C18.5181 9.49997 18.5181 6.55247 18.1417 5.13906Z"
                    fill="#727F8F"
                />
                <path d="M7.67676 12.1764V6.82349L12.3813 9.49996L7.67676 12.1764Z" fill="#FEFEFE" />
            </g>
            <defs>
                <clipPath id="clip0_89_13221">
                    <rect width="18" height="18" fill="white" transform="translate(0.5 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default YoutubeIcon;
