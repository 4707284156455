import { creatorbase, OrderingValues } from '@round/api';
import { ScrollView, Skeleton, TableProps, getTableMetaHelper } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import styles from './TiktokTopPostsTable.module.css';
import Table from 'ui-new/whitelabel/Table/Table';
import { ReactComponent as CrossCircle } from 'assets/whitelabel/CrossCircle.svg';
import PaginationToolbar, { PaginationProps } from 'ui-new/whitelabel/Table/PaginationToolbar/PaginationToolbar';
import { buildTiktokMusicUrl, buildTiktokPostUrl, formatDateObjShort, numberWithCommas } from 'helpers';
import { mapOrderingToTableSorting, mapTableSortingToOrdering } from 'ui/WrapperTable/helpers';
import TruncationPopover from 'ui-new/TruncationPopover/TruncationPopover';

export type TiktokTopPostsTableRow = creatorbase.PostStats & {
    audio: creatorbase.TiktokAudio | null;
};

type Props = Pick<TableProps<TiktokTopPostsTableRow>, 'data'> &
    PaginationProps & {
        isLoading?: boolean;
        hasError: boolean;
        ordering: OrderingValues<creatorbase.PostStatsSortableKeys>;
        onOrderingChange: (ordering: OrderingValues<creatorbase.PostStatsSortableKeys>[]) => void;
    };

type TableCellContext<K extends keyof TiktokTopPostsTableRow> = CellContext<
    TiktokTopPostsTableRow,
    TiktokTopPostsTableRow[K]
>;

type Meta = Pick<Props, 'isLoading'>;
const getTableMeta = getTableMetaHelper<Meta>();

const TiktokTopPostsTable = ({
    data,
    isLoading,
    hasError,
    page,
    count,
    setPage,
    pageSize,
    setPageSize,
    ordering,
    onOrderingChange,
}: Props) => {
    const columns = useMemo<ColumnDef<TiktokTopPostsTableRow, any>[]>(
        () => [
            {
                header: 'Username',
                accessorKey: 'author_name',
                cell: ({ getValue, table }: TableCellContext<'author_name'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue() || '-';
                },
            },
            {
                header: 'Followers',
                accessorKey: 'author_follower_count',
                cell: ({ getValue, table }: TableCellContext<'author_follower_count'>) => {
                    const { isLoading } = getTableMeta(table);
                    const value = getValue();

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return value ? numberWithCommas(value) : '-';
                },
            },
            {
                header: 'Location',
                accessorKey: 'location',
                cell: ({ getValue, table }: TableCellContext<'location'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue() || '-';
                },
            },
            {
                header: 'Description',
                id: 'caption',
                meta: { className: styles.truncatedColumn },
                cell: ({ row: { original }, table }) => {
                    const { isLoading } = getTableMeta(table);
                    const caption = original.caption || '-';
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <a
                            href={buildTiktokPostUrl(original.author_username, original.tiktok_id)}
                            className={styles.truncatedTextContainer}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <TruncationPopover content={caption}>
                                {(ref) => (
                                    <span ref={ref} className={styles.truncatedText}>
                                        {caption}
                                    </span>
                                )}
                            </TruncationPopover>
                        </a>
                    );
                },
            },
            {
                header: 'Audio',
                id: 'audio',
                meta: { className: styles.truncatedColumn },
                cell: ({ row: { original }, table }) => {
                    const { isLoading } = getTableMeta(table);
                    const audioAuthorName = original.audio?.author_name || '-';
                    const audioTitle = original.audio?.title || '';
                    const audioTiktokId = original.audio?.tiktok_id || '';
                    const combinedAudioInfo = `${audioAuthorName} - ${audioTitle}`;

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <a
                            href={buildTiktokMusicUrl(audioTitle, audioTiktokId)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.truncatedTextContainer}
                        >
                            <TruncationPopover content={combinedAudioInfo}>
                                {(ref) => (
                                    <span ref={ref} className={styles.truncatedText}>
                                        {combinedAudioInfo}
                                    </span>
                                )}
                            </TruncationPopover>
                        </a>
                    );
                },
            },
            {
                header: 'Audio Type',
                id: 'type',
                cell: ({ row: { original }, table }) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return original.audio?.is_original ? 'Original Audio' : 'Official Sound';
                },
            },
            {
                header: 'Date Posted',
                accessorKey: 'create_time',
                cell: ({ getValue, table }: TableCellContext<'create_time'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return <>{formatDateObjShort(new Date(getValue() * 1000))}</>;
                },
            },
            {
                header: 'Views',
                accessorKey: 'play_count',
                enableSorting: true,
                cell: ({ getValue, table }: TableCellContext<'play_count'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return numberWithCommas(getValue()) || '-';
                },
            },
            {
                header: 'Shares',
                accessorKey: 'share_count',
                cell: ({ getValue, table }: TableCellContext<'share_count'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return numberWithCommas(getValue()) || '-';
                },
            },
            {
                header: 'Saves',
                accessorKey: 'save_count',
                cell: ({ getValue, table }: TableCellContext<'save_count'>) => {
                    const { isLoading } = getTableMeta(table);
                    const value = getValue();

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return typeof value === 'number' ? numberWithCommas(value) : '-';
                },
            },
        ],
        []
    );

    const meta = {
        isLoading,
    };

    const initialLoading = !data.length && isLoading;
    const emptyRows = new Array(6).fill({}) as TiktokTopPostsTableRow[];
    const rows = initialLoading ? emptyRows : data;

    return (
        <>
            <ScrollView className={styles.scrollWrapper}>
                <Table
                    manualSorting
                    enableSorting
                    sorting={mapOrderingToTableSorting([ordering])}
                    onSortingChange={(sortingState) => onOrderingChange(mapTableSortingToOrdering(sortingState))}
                    className={styles.table}
                    data={rows}
                    columns={columns}
                    meta={meta}
                    noDataLabel={
                        <div className={styles.noDataLabel}>
                            {hasError ? (
                                <div className={styles.error}>
                                    <CrossCircle />
                                    <p>Failed to load posts</p>
                                </div>
                            ) : (
                                <p>No posts found</p>
                            )}
                        </div>
                    }
                />
            </ScrollView>
            <PaginationToolbar
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                count={count}
                isLoading={isLoading}
            />
        </>
    );
};

export default TiktokTopPostsTable;
