import { fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
} from '../../types';
import { PostStats } from './postStats.types';

export type PostStatsSortableKeys = 'play_count';

export type PostStatsPostData = {
    audio_ids: number[];
    ordering: string;
    page: number;
    page_size: number;
};

type PostStatsResponseError = ApiResponse<ApiResponseError<{ detail: string }>, 400>;

type PostStatsResponse =
    | ApiResponse<PaginatedApiResponseData<PostStats>, 200>
    | PostStatsResponseError
    | ForbiddenResponse
    | NotFoundResponse;

export async function postGetPostStats(data: PostStatsPostData, signal?: AbortSignal): Promise<PostStatsResponse> {
    const response = await fetchWithToken(`/api/tiktok/post-stats/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        signal,
    });

    if (response.status === 400 || response.status === 403 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get post stats');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
