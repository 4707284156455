import { CustomAudienceOption } from '../../../../../../../types/Facebook.types';
import styles from '../FacebookTargetingCustomAudienceSelect.module.css';
import { formatSnakeCaseToDisplay } from '../../../../../../../../../../helpers';
import React from 'react';

type CustomAudienceDetailsProps = {
    data: CustomAudienceOption;
};

const CustomAudienceDetails = ({ data: { description, label, subtype } }: CustomAudienceDetailsProps) => (
    <div className={styles.detailsContainer}>
        <p className={styles.detailsRow}>
            Name: <span className={styles.detailsValue}>{label}</span>
        </p>

        <p className={styles.detailsRow}>
            Type: <span className={styles.detailsValue}>{formatSnakeCaseToDisplay(subtype)}</span>
        </p>

        <p className={styles.detailsRow}>
            Description: <span className={styles.detailsValue}>{description}</span>
        </p>
    </div>
);

export default CustomAudienceDetails;
