import React, { memo, useCallback, useEffect, useState } from 'react';
import styles from './AssetsLibraryModal.module.css';
import { showNotification } from '../../../../helpers';
import cn from 'classnames';
import CloseIcon from '../../../../SharedComponents/svg/Icons/CloseIcon';
import LoadingSpinner from '../../../../SharedComponents/LoadingSpinner/LoadingSpinner';
import AssetsLibraryModalVideo from '../../components/AssetsLibraryModalVideo/AssetsLibraryModalVideo';
import ModalRight from '../../../../SharedComponents/ModalRight/ModalRight';
import { AssetsLibraryPlatform, Video } from '../../AssetsLibraryModal.types';
import {
    acceptVideo,
    changeVideoName,
    changeVideoNotes,
    declineVideo,
    fetchVideos,
    removeVideo,
    uploadVideo,
} from '../../AssetsLibraryModal.services';
import ConfirmDeleteDialog from '../../../../ui/Dialogs/ConfirmDeleteDialog/ConfirmDeleteDialog';
import FileUpload, { FileType } from '../../../../ui/FileUpload/FileUpload';
import { FCWithChildren } from '../../../../utility/utility.types';

interface AssetsLibraryModalProps {
    platform: AssetsLibraryPlatform;
    planId: number;
    closeModal: () => void;
}

const AssetsLibraryModal: FCWithChildren<AssetsLibraryModalProps> = ({ platform, planId, closeModal }) => {
    const [videos, setVideos] = useState<Video[]>([]);
    const [loading, setLoading] = useState(true);
    const [inProgress, setInProgress] = useState(false);
    const [videoIdToBeDeleted, setVideoIdToBeDeleted] = useState<number | null>(null);
    const onUpload = useCallback(
        async ([file]: File[]) => {
            if (!file) {
                return;
            }

            const video = await uploadVideo(platform, planId, file);
            setVideos([...videos, video]);
            showNotification('Video has been uploaded', 'info');
        },
        [platform, planId, setVideos, videos]
    );
    const onChangeName = useCallback(
        async (video: Video, name: string) => {
            if (video.name !== name) {
                const updatedVideo = await changeVideoName(platform, video, name);
                setVideos(videos.map((video) => (video.id === updatedVideo.id ? updatedVideo : video)));
                showNotification('Video name has been changed', 'info');
            }
        },
        [platform, videos]
    );
    const onChangeNotes = useCallback(
        async (video: Video, notes: string) => {
            if (video.notes !== notes) {
                const updatedVideo = await changeVideoNotes(platform, video, notes);
                setVideos(videos.map((video) => (video.id === updatedVideo.id ? updatedVideo : video)));
                showNotification('Video notes has been changed', 'info');
            }
        },
        [platform, videos]
    );
    const onAccept = useCallback(
        async (video: Video) => {
            if (!inProgress) {
                setInProgress(true);
                const updatedVideo = await acceptVideo(platform, video);
                setVideos(videos.map((video) => (video.id === updatedVideo.id ? updatedVideo : video)));
                setInProgress(false);
                showNotification('Video has been approved', 'info');
            }
        },
        [platform, inProgress, videos]
    );
    const onDecline = useCallback(
        async (video: Video) => {
            if (!inProgress) {
                setInProgress(true);
                const updatedVideo = await declineVideo(platform, video);
                setVideos(videos.map((video) => (video.id === updatedVideo.id ? updatedVideo : video)));
                setInProgress(false);
                showNotification('Video has been declined', 'info');
            }
        },
        [platform, inProgress, videos]
    );
    const onDelete = useCallback(async () => {
        if (videoIdToBeDeleted && !inProgress) {
            setInProgress(true);
            await removeVideo(platform, videoIdToBeDeleted);
            setVideos(videos.filter((video) => video.id !== videoIdToBeDeleted));
            setInProgress(false);
            setVideoIdToBeDeleted(null);
            showNotification('Video has been deleted', 'info');
        }
    }, [platform, inProgress, videoIdToBeDeleted, videos]);

    useEffect(() => {
        const getVideos = async () => {
            const videos = await fetchVideos(platform, planId);
            setVideos(videos);
            setLoading(false);
        };

        getVideos();
    }, [platform, planId, setVideos]);

    return (
        <>
            <ConfirmDeleteDialog
                closeOnOverlayClick
                show={Boolean(videoIdToBeDeleted)}
                loading={inProgress}
                title="Are you sure?"
                body="You are going to delete this video."
                confirmLabel="Delete video"
                onConfirm={onDelete}
                onClose={() => setVideoIdToBeDeleted(null)}
            />
            <ModalRight isModalOpen={true} closeModal={closeModal}>
                <div className={cn(styles.inner, { [styles.inProgress]: inProgress })}>
                    <div className={styles.header}>
                        <CloseIcon width={20} onClick={closeModal} className={styles.close} />
                        <h2 className={styles.title}>Assets Library</h2>
                    </div>
                    <div className={styles.content}>
                        <FileUpload fileType={FileType.Video} onUpload={onUpload} />
                        {loading ? <LoadingSpinner /> : null}
                        {!loading && videos.length ? (
                            <div className={styles.videos}>
                                {videos.map((video) => (
                                    <AssetsLibraryModalVideo
                                        key={video.id}
                                        video={video}
                                        inProgress={inProgress}
                                        onChangeName={onChangeName}
                                        onChangeNotes={onChangeNotes}
                                        onAccept={onAccept}
                                        onDecline={onDecline}
                                        onDelete={() => !inProgress && setVideoIdToBeDeleted(video.id)}
                                    />
                                ))}
                            </div>
                        ) : null}
                    </div>
                </div>
            </ModalRight>
        </>
    );
};

export default memo(AssetsLibraryModal);
