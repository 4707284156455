import React, { useMemo } from 'react';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { TabsContext } from '../../Tabs';
import TabNavItem from '../TabNavItem/TabNavItem';
import styles from './TabNav.module.css';
import { getNamesFromChildren } from '../../helpers';

type TabNavChildType = React.ReactElement<typeof TabNavItem> | null;
type TabNavProps = { children?: TabNavChildType | TabNavChildType[]; containerClassName?: string };

const TabNav = ({ children, containerClassName }: TabNavProps) => {
    const { activeTab } = useNonNullContext(TabsContext);
    const tabNames = useMemo(() => getNamesFromChildren(children), [children]);
    const activeTabindex = tabNames.findIndex((tab) => tab === activeTab);
    const indicatorWidth = 100 / tabNames.length;
    const left = activeTabindex < 0 ? '-100%' : `${activeTabindex * indicatorWidth}%`;

    if (!tabNames.length) {
        return null;
    }

    return (
        <div className={containerClassName}>
            <div className={styles.container}>{children}</div>
            <div className={styles.track}>
                <div style={{ left, width: `${indicatorWidth}%` }} className={styles.indicator} />
            </div>
        </div>
    );
};

export default TabNav;
