import { AppServer, RouteHandlerContext } from '../../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { FinanceCreatorPaymentOutstanding, FinanceInfluencerPayment } from './creatorPayment.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';

export const models = {
    financeCreatorPaymentOutstanding: Model as ModelDefinition<FinanceCreatorPaymentOutstanding>,
    financeInfluencerPayment: Model as ModelDefinition<FinanceInfluencerPayment>,
};

export const serializers = {
    financeCreatorPaymentOutstanding: makeSerializer<FinanceCreatorPaymentOutstanding>([]),
    financeInfluencerPayment: makeSerializer<FinanceInfluencerPayment>([]),
};

export const factories = {
    financeCreatorPaymentOutstanding: createFactory<FinanceCreatorPaymentOutstanding>({
        campaign_name(index: number) {
            return `campaign name ${index}`;
        },
        status: 'confirmed',
        campaign_type: 'AUDIO',
        currency: 2,
        client_name(index: number) {
            return `client name ${index}`;
        },
        platform: 'TIKTOK',
        date_approved: new Date().toISOString(),
        date_influencer_paid: null,
        influencer_bank_account_number(index: number) {
            return `influencer bank account number ${index}`;
        },
        tiktok_ad_code(index: number) {
            return `tiktok ad code ${index}`;
        },
        influencer_bank_name(index: number) {
            return `influencer bank name ${index}`;
        },
        influencer_bank_sort_code(index: number) {
            return `influencer bank short code ${index}`;
        },
        influencer_name(index: number) {
            return `influencer name ${index}`;
        },
        influencer_payment_amount: 100,
        payment_terms: 'client_terms',
        post_url(index: number) {
            return `post url ${index}`;
        },
        influencer_paypal_email(index: number) {
            return `influencer paypal email ${index}`;
        },
        payment_reference_bank(index: number) {
            return `payment reference bank ${index}`;
        },
        payment_reference_paypal(index: number) {
            return `payment reference paypal ${index}`;
        },
        posted_at: new Date().getTime() / 1000,
        price: 100,
        payment_due_at: new Date().getTime() / 1000,
        manager_email: null,
    }),
    financeInfluencerPayment: createFactory<FinanceInfluencerPayment>({
        paid_by(index: number) {
            return `paid by user ${index}`;
        },
        influencer_name(index: number) {
            return `influencer name ${index}`;
        },
        amount: 100.0,
        method: 'paypal',
        booking_id(index: number) {
            return index;
        },
        currency_id: 1,
        created() {
            return new Date().toISOString();
        },
        campaign_name(index: number) {
            return `campaign name ${index}`;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const getOutstandingCreatorPaymentsUrl = '/api/apex/finance/creator-payment/outstanding/';
    server.get(getOutstandingCreatorPaymentsUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('financeCreatorPaymentOutstanding'), {
            url: getOutstandingCreatorPaymentsUrl,
            serialize: (resource) => this.serialize(resource, 'financeCreatorPaymentOutstanding'),
            queryParams: request.queryParams,
        });
    });

    const getCreatorPaymentsUrl = '/api/apex/finance/creator-payment/';
    server.get(getCreatorPaymentsUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('financeInfluencerPayment'), {
            url: getCreatorPaymentsUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'financeInfluencerPayment'),
        });
    });
}
