import Button from '../Button/Button';
import styles from './ErrorFallback.module.css';

const ErrorFallback = () => {
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Something unexpected happened</h1>
            <p className={styles.copy}>
                We are aware of the issue and are working to fix it. Please try refreshing the page in the meantime.
            </p>

            <Button appearance="primary" onClick={() => window.location.reload()}>
                Reload page
            </Button>
        </div>
    );
};

export default ErrorFallback;
