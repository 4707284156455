import React from 'react';
import styles from './ReportItems.module.css';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import TableRow from '../../../SharedComponents/TableRow/TableRow';

const ReportItems = () => {
    return (
        <div className={styles.page}>
            <NavBar />
            <div className={styles.content}>
                <h1>Report Items</h1>
                <table className={styles.table}>
                    <thead>
                        <tr className={styles.columnHeaderRow}>
                            <th className={styles.cell}>Report Item</th>
                        </tr>
                    </thead>
                    <tbody>
                        <TableRow linkTo="spotify">Spotify</TableRow>
                        <TableRow linkTo="youtube">YouTube</TableRow>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ReportItems;
