import { creatorbase } from '@round/api';
import { GenericDropdownOption } from 'App.types';
import useNonNullContext from 'Hooks/useNonNullContext';
import { UseCampaigns, useCampaigns } from '../../hooks/useCampaigns';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { useMemo } from 'react';
import TiktokCampaignForm, { TiktokCampaignFormValues } from '../components/TiktokCampaignForm';
import useTikTokAudio from 'Modules/TikTok/hooks/useTikTokAudio';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { FormikProvider, useFormik, yupToFormErrors } from 'formik';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import Button from 'ui-new/whitelabel/Button/Button';
import { omit } from 'lodash';
import {
    mapCampaignApiErrorsToFormikErrors,
    mapCampaignFormValuesToApiData,
    validationSchema as validationSchemaBase,
} from '../../components/CampaignFormBase/CampaignFormBase';
import { getObjectDifference, showNotification } from 'helpers';
import { useUsers } from 'Modules/Plans/User/hooks/useUsers';
import { mapUserToOption } from 'Modules/Plans/User/hooks/useUsersSelect';
import styles from './UpdateTiktokCampaignModal.module.css';
import { useProjectDetails } from 'Modules/Plans/Project/contexts/ProjectContext';
import { usePosts } from 'Modules/Plans/Project/contexts/PostsContext';
import * as yup from 'yup';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
    campaign: creatorbase.TiktokCampaign | null;
    updateCampaign: UseCampaigns['updateCampaign'];
};

const UpdateTiktokCampaignModal = ({ isOpen, closeModal, campaign, updateCampaign }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const currency = currencies
        .map<GenericDropdownOption<number>>((c) => ({ value: c.id, label: c.name }))
        .find((c) => c.value === campaign?.currency_id);

    const { data: campaignsData } = useCampaigns();
    const tiktokCampaigns = campaignsData['tiktok'].data?.results;

    const { data: projectData } = useProjectDetails();

    const { data: posts } = usePosts();
    const campaignPosts = posts?.filter((post) => post.campaign_id === campaign?.id) ?? [];

    const {
        fetchData: fetchAudio,
        data: tiktokAudio,
        status: tiktokAudioStatus,
        reset: resetTiktokAudio,
    } = useTikTokAudio();

    useAbortableEffect(
        (signal) => {
            const isInitialized = tiktokAudioStatus === 'success' || tiktokAudioStatus === 'error';
            if (campaign?.tiktok_details?.audio_id && !isInitialized) {
                fetchAudio(campaign.tiktok_details.audio_id, { signal }).catch(() => {});
            }
        },
        [campaign?.tiktok_details?.audio_id, fetchAudio, tiktokAudioStatus]
    );

    const { fetchData: fetchUsers, data: users, reset: resetUsers, status: usersStatus } = useUsers();
    const isInitialized = usersStatus === 'success' || usersStatus === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && campaign?.team_members.length) {
                fetchUsers(
                    {
                        id: campaign.team_members.join(),
                        page_size: campaign.team_members.length,
                    },
                    { signal }
                ).catch(() => {});
            }
        },
        [campaign?.team_members, fetchUsers, isInitialized]
    );

    const initialValues = useMemo<TiktokCampaignFormValues>(
        () => ({
            cost: String(campaign?.cost ?? ''),
            currency: currency,
            is_post_cost_editable: campaign?.is_post_cost_editable ?? null,
            name: campaign?.name ?? '',
            audio_identifier:
                tiktokAudioStatus === 'error'
                    ? `Couldn't find audio`
                    : tiktokAudio
                    ? `${tiktokAudio.title} - ${tiktokAudio.author_name}`
                    : '',
            team_members: users?.results.map(mapUserToOption),
        }),
        [
            campaign?.cost,
            campaign?.is_post_cost_editable,
            campaign?.name,
            currency,
            tiktokAudio,
            users?.results,
            tiktokAudioStatus,
        ]
    );

    const formik = useFormik<TiktokCampaignFormValues>({
        initialValues,
        onSubmit: async (values, helpers) => {
            if (values.is_post_cost_editable === null || !campaign?.id) {
                return;
            }

            try {
                const mappedFormValues = mapCampaignFormValuesToApiData(values);
                const updatedFields = getObjectDifference(mappedFormValues, campaign);

                const response = await updateCampaign(campaign.id, updatedFields);

                if (response.status === 200) {
                    showNotification('Campaign updated', 'info');
                    helpers.resetForm();
                    resetTiktokAudio();
                    resetUsers();
                    closeModal();
                    return;
                }

                if (response.status === 400) {
                    helpers.setErrors(mapCampaignApiErrorsToFormikErrors(response.data));
                    return;
                }

                showNotification('Could not update campaign', 'error');
            } catch {
                showNotification('Could not update campaign', 'error');
            }
        },
        // validationSchema param threw an error on validation
        validate: async (values) => {
            const validationSchema =
                tiktokAudioStatus === 'error'
                    ? validationSchemaBase
                    : validationSchemaBase.shape({
                          audio_identifier: yup.string().required('Audio is required'),
                      });

            try {
                const transformedValues = values.is_post_cost_editable === false ? values : omit(values, 'cost');
                await validationSchema.validate(transformedValues, { abortEarly: false });
            } catch (e) {
                return yupToFormErrors(e);
            }
        },
        enableReinitialize: true,
    });

    const handleClose = () => {
        formik.resetForm();
        resetTiktokAudio();
        resetUsers();
        closeModal();
    };

    const isSpendingTypeDisabled = campaign?.is_post_cost_editable && campaignPosts.some((post) => post.cost !== null);

    const readonlyFieldConditions = {
        is_post_cost_editable: isSpendingTypeDisabled,
        audio_identifier: true,
        currency: !!tiktokCampaigns?.length && tiktokCampaigns.length > 1,
    };

    const readonlyFields = Object.entries(readonlyFieldConditions)
        .filter(([field, condition]) => !!condition)
        .map(([field]) => field as keyof TiktokCampaignFormValues);

    return (
        <Modal className={styles.modal} closeOnOverlayClick isOpen={isOpen} onClose={handleClose}>
            <Modal.Header>
                <Modal.Title>Edit campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormikProvider value={formik}>
                    <TiktokCampaignForm readonlyFields={readonlyFields} teamId={projectData?.team?.id} />
                </FormikProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    appearance="primary"
                    disabled={!formik.dirty || !formik.isValid}
                    isLoading={formik.isSubmitting}
                    onClick={formik.submitForm}
                >
                    Submit
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default UpdateTiktokCampaignModal;
