import { useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Modules/Auth/contexts/AuthContext';
import { ProtectedByUserGroups } from '../ProtectedByUserGroup/ProtectedByUserGroups';
import { NAVBAR_ITEMS, NavBarItemGroup } from './Routes';
import { ReactComponent as Logo } from '../../assets/brand/LogoFull.svg';
import styles from './NavBar.module.css';
import { makeUserHasGroupAccess, openInNewTab } from '../../helpers';
import { ReactComponent as LogoutIcon } from '../../assets/Logout.svg';
import cn from 'classnames';
import MobileNavMenu from './components/MobileNavMenu/MobileNavMenu';
import { useGetScreenDimensions } from '../../Hooks/useGetScreenDimensions';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';
import { CREATORBASE_ROOT_PATH } from 'Modules/Navigation/whitelabel/routes';

export function NavBar() {
    const featureFlags = useFeatureFlags();
    const authContext = useContext(AuthContext);
    const isCreatorBaseUser = !!authContext.user?.creator_base_access;
    const location = useLocation();
    const navigate = useNavigate();
    const routes = useMemo(() => NAVBAR_ITEMS.map((item) => item.items).flat(), []);
    const title = useMemo(() => {
        const route = routes.find((route) => location.pathname.includes(route.url));
        const group = NAVBAR_ITEMS.find((group) => !!group.items.find((item) => location.pathname.includes(item.url)));

        if (route && group) {
            return `${route.title} | ${group.title} | round`;
        }

        return 'round';
    }, [location.pathname, routes]);

    const navBarToDisplay = useMemo(() => {
        if (authContext.loading || !authContext.authenticated) {
            return [];
        }

        const userGroups = authContext.user.groups;
        const userHasGroupAccess = makeUserHasGroupAccess(userGroups);

        return NAVBAR_ITEMS.reduce<NavBarItemGroup[]>((navBar, itemGroup) => {
            const navItems = itemGroup.items.filter((item) => {
                const isAllowedByFeatureFlag = !item.featureFlag || featureFlags.isEnabled(item.featureFlag);
                return userHasGroupAccess(item.groups, item.shouldMatch ?? 'all') && isAllowedByFeatureFlag;
            });

            if (navItems.length) {
                navBar.push({ title: itemGroup.title, items: navItems });
            }

            return navBar;
        }, []);
    }, [authContext.authenticated, authContext.loading, authContext.user?.groups, featureFlags]);

    const navigateToUrl = function (url: string) {
        if (url.startsWith('http')) {
            openInNewTab(url);
            return;
        }

        navigate(url);
    };

    const { screenWidth } = useGetScreenDimensions();
    const shouldRenderMobileNavMenu = screenWidth <= 700 && !!navBarToDisplay.length;

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className={styles.navBar}>
                <div className={styles.navBarSection}>
                    <div className={styles.logoContainer}>
                        <Logo color="white" width={80} className={styles.logo} />
                    </div>
                </div>
                {shouldRenderMobileNavMenu ? (
                    <div className={cn(styles.navBarSection, styles.stretchSection)}>
                        <MobileNavMenu navItems={navBarToDisplay} />
                    </div>
                ) : (
                    <div className={cn(styles.navBarSection, styles.stretchSection)}>
                        <div className={styles.links}>
                            {navBarToDisplay.map((itemGroup) => (
                                <div key={itemGroup.title} className={styles.menuGroup}>
                                    <div className={styles.menuHeading}>
                                        <div className={styles.menuHeadingText}>{itemGroup.title}</div>
                                    </div>
                                    <div className={styles.menuItems}>
                                        {itemGroup.items.map((item) => (
                                            <ProtectedByUserGroups
                                                key={item.title}
                                                groups={item.groups}
                                                shouldMatch={item.shouldMatch}
                                            >
                                                <div className={styles.menuItemContainer}>
                                                    {item.url.startsWith('http') ? (
                                                        <a
                                                            className={styles.menuItem}
                                                            href={item.url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {item.title}
                                                        </a>
                                                    ) : (
                                                        <Link className={styles.menuItem} to={item.url}>
                                                            {item.title}
                                                        </Link>
                                                    )}
                                                </div>
                                            </ProtectedByUserGroups>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className={styles.navBarSection}>
                    {isCreatorBaseUser && (
                        <Link className={styles.whitelabelLink} to={`/${CREATORBASE_ROOT_PATH}/projects/`}>
                            Internal View
                        </Link>
                    )}
                    <div
                        className={cn(styles.logout)}
                        onClick={() => {
                            if (!authContext.loading && authContext.authenticated) {
                                authContext.logout();
                                navigateToUrl('/login/');
                            }
                        }}
                    >
                        <span>Logout</span>
                        <LogoutIcon className={styles.logoutIcon} width={20} height={20} />
                    </div>
                </div>
            </div>
        </>
    );
}
