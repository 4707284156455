import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReportContext } from '../../ReportContext';
import { useCallback } from 'react';
import { youtube } from '@round/api';

export default function useYoutubeChannels() {
    const state = useNonNullContextSelector(ReportContext, ([values]) => values.youtubeChannels);
    const dispatch = useNonNullContextSelector(ReportContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (channelIds: number[], requestInit?: RequestInit) => {
            if (!channelIds.length) {
                dispatch({ type: 'youtubeChannelsLoaded', payload: { ids: [], channels: [] } });
                return;
            }

            try {
                dispatch({ type: 'loadYoutubeChannels', payload: channelIds });
                const response = await youtube.getChannels(
                    { id: channelIds.toString(), page_size: channelIds.length },
                    requestInit
                );
                if (response.status === 200) {
                    dispatch({
                        type: 'youtubeChannelsLoaded',
                        payload: { ids: channelIds, channels: response.data.results },
                    });
                    return response;
                }

                dispatch({
                    type: 'youtubeChannelsErrorLoading',
                    payload: { ids: channelIds, error: response.data.detail },
                });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({
                    type: 'youtubeChannelsErrorLoading',
                    payload: { ids: channelIds, error: 'Could not load youtube channels' },
                });
                throw e;
            }
        },
        [dispatch]
    );

    return {
        data: state,
        fetchData,
    };
}
