import { Link, useParams } from 'react-router-dom';
import { useProjectDetails } from '../contexts/ProjectContext';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { Image, Tabs, TabPanel, SkeletonizedValue, Skeleton } from '@round/ui-kit';
import styles from './ProjectDetails.module.css';
import InstagramCampaigns from './InstagramCampaigns/InstagramCampaigns';
import TiktokCampaigns from './TiktokCampaigns/TiktokCampaigns';
import YoutubeCampaigns from './YoutubeCampaigns/YoutubeCampaigns';
import usePlatformHashSelect, { DefaultPlatformSelectorFunction } from '../../hooks/usePlatformHashSelect';
import { PlatformStatsProvider } from '../contexts/PlatformStatsContext';
import AggregatedPlatformStats from './PlatformHeadlineStats/PlatformHeadlineStats';
import { useCallback, useState } from 'react';
import { asMoney, showNotification } from 'helpers';
import { CampaignsStatsProvider } from '../contexts/CampaignStatsContext/CampaignsStatsContext';
import { PostsProvider } from '../contexts/PostsContext';
import cn from 'classnames';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import AutoGrowthInput from 'ui-new/whitelabel/AutoGrowthInput/AutoGrowthInput';
import { InstagramPostStatsProvider } from '../contexts/PostStatsContexts/InstagramPostStatsContext/InstagramPostStatsContext';
import { TiktokVideosProvider } from '../contexts/PostStatsContexts/TiktokVideosContext/TiktokVideosContext';
import { ReactComponent as SongIcon } from 'assets/whitelabel/Song.svg';
import RunDates from 'Modules/Plans/components/RunDates/RunDates';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import capitalize from 'lodash/capitalize';
import { ReactComponent as ChevronDownFilledIcon } from 'assets/whitelabel/ChevronDownFilled.svg';
import { YoutubeAccountsProvider } from '../contexts/AccountDataContext/YoutubeAccountsContext/YoutubeAccountsContext';
import { TiktokAccountsProvider } from '../contexts/AccountDataContext/TiktokAccountsContext/TiktokAccountsContext';
import { InstagramAccountsProvider } from '../contexts/AccountDataContext/InstagramAccountsContext/InstagramAccountsContext';
import ProjectSongChart from './components/ProjectSongChart/ProjectSongChart';
import ProjectSongDropdown from './components/ProjectSongDropdown/ProjectSongDropdown';
import { AudioTimeSeriesProvider } from '../contexts/AudioTimeSeriesContext/AudioTimeSeriesContext';
import PlatformTabs from 'Modules/Plans/components/PlatformTabs/PlatformTabs';
import useUrlState from 'Hooks/useUrlState';
import SongDataModal from 'Modules/Plans/Songs/SongDataModal/SongDataModal';
import { TiktokAudiosProvider } from 'Modules/Plans/Project/contexts/TiktokAudiosContext';
import HeaderSummary from 'Modules/Plans/components/HeaderSummary/HeaderSummary';
import CircleBadge from 'Modules/Plans/components/CircleBadge/CircleBadge';
import { getUserNameInitials } from 'Modules/Plans/User/user.helpers';

type UrlState = {
    song: string;
};

const ProjectDetails = () => {
    const [urlState, setUrlState] = useUrlState<Partial<UrlState>>();

    const { currencies } = useNonNullContext(OptionsContext);
    const { id } = useParams<{ id: string }>();

    const { data, status, fetchData, updateProject } = useProjectDetails();
    const [projectName, setProjectName] = useState('');
    const budget = asMoney(
        data?.project?.total_cost,
        currencies.find((c) => c.id === data?.project?.currency_id)
    );

    const isProjectDataInitialized = status === 'error' || status === 'success';
    useAbortableEffect(
        (signal) => {
            if (!isProjectDataInitialized && !!id) {
                fetchData(Number(id), { signal })
                    .then((response) => {
                        if (response.status === 200) {
                            setProjectName(response.data.name);
                        }
                    })
                    .catch(() => {});
            }
        },
        [fetchData, id, isProjectDataInitialized]
    );

    const handleProjectNameSubmit = () => {
        if (status !== 'success' || projectName === data.project.name) {
            return;
        }

        updateProject({ name: projectName })
            .then((response) => {
                if (response?.status === 200) {
                    setProjectName(response.data.name);
                    showNotification('Project name updated', 'info');
                    return;
                }

                setProjectName(data.project.name);
                showNotification('Could not update project name', 'error');
            })
            .catch(() => {
                setProjectName(data.project.name);
                showNotification('Could not update project name', 'error');
            });
    };

    const [selectedPlatform, setSelectedPlatform] = usePlatformHashSelect(
        useCallback<DefaultPlatformSelectorFunction>(
            (goToPlatform) => {
                if (status !== 'success') {
                    return;
                }

                const platformsInOrder = ['tiktok', 'instagram', 'youtube'] as const;
                const platform = platformsInOrder.find((platform) =>
                    data?.project.platforms.some((p) => p === platform)
                );

                goToPlatform(platform || 'instagram', true);
            },
            [data?.project.platforms, status]
        )
    );

    const helmetTitle = data?.project.name
        ? `${data.project.name}${selectedPlatform ? ` · ${capitalize(selectedPlatform)}` : ''}`
        : null;

    return (
        <>
            <Tabs activeTab={selectedPlatform} onChange={setSelectedPlatform}>
                <Helmet>{helmetTitle && <title>{helmetTitle}</title>}</Helmet>

                <header className={styles.header}>
                    <div className={styles.goBackContainer}>
                        <Link className={styles.goBackButton} to="/c/projects/">
                            <ChevronDownFilledIcon className={styles.goBackButtonIcon} />
                            Projects
                        </Link>
                    </div>
                    <div className={styles.projectInfo}>
                        <Image
                            loading={!isProjectDataInitialized}
                            skeletonCircle={false}
                            className={styles.brandImage}
                            src={data?.brand?.image ?? ''}
                            alt="artist image"
                        />

                        <div className={styles.projectDetails}>
                            <AutoGrowthInput
                                isLoading={!isProjectDataInitialized}
                                type="text"
                                containerClassName={styles.projectNameInputContainer}
                                value={projectName}
                                onChange={(e) => {
                                    setProjectName(e.target.value);
                                }}
                                onBlur={handleProjectNameSubmit}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.currentTarget.blur();
                                    }
                                }}
                            />

                            <p className={styles.projectSubTitle}>
                                <SkeletonizedValue
                                    containerClassName={styles.projectSubTitle}
                                    isInitialized={isProjectDataInitialized}
                                >
                                    {data?.brand?.name} | {data?.team?.name}
                                </SkeletonizedValue>
                            </p>
                        </div>

                        <HeaderSummary>
                            <HeaderSummary.Item>
                                <HeaderSummary.Label>Run dates</HeaderSummary.Label>
                                <HeaderSummary.Value isLoading={!isProjectDataInitialized}>
                                    {data?.project?.start_date && data?.project?.end_date ? (
                                        <RunDates
                                            startDate={moment(data.project.start_date).format('DD MMM YYYY')}
                                            endDate={moment(data.project.end_date).format('DD MMM YYYY')}
                                        />
                                    ) : (
                                        '-'
                                    )}
                                </HeaderSummary.Value>
                            </HeaderSummary.Item>

                            <HeaderSummary.Item>
                                <HeaderSummary.Label>Total budget</HeaderSummary.Label>
                                <HeaderSummary.Value isLoading={!isProjectDataInitialized}>
                                    {budget}
                                </HeaderSummary.Value>
                            </HeaderSummary.Item>

                            <HeaderSummary.Item>
                                <HeaderSummary.Label>Account team</HeaderSummary.Label>
                                <HeaderSummary.Value className={styles.teamContainer}>
                                    {!isProjectDataInitialized &&
                                        new Array(3)
                                            .fill(undefined)
                                            .map((_, i) => <Skeleton circle className={styles.userBadge} key={i} />)}

                                    {isProjectDataInitialized &&
                                        (data?.usersAssignedToCampaigns?.length
                                            ? data.usersAssignedToCampaigns.map((user, index) => (
                                                  <CircleBadge
                                                      key={index}
                                                      className={styles.userBadge}
                                                      tooltip={user.name}
                                                  >
                                                      {getUserNameInitials(user.name)}
                                                  </CircleBadge>
                                              ))
                                            : '-')}
                                </HeaderSummary.Value>
                            </HeaderSummary.Item>

                            <HeaderSummary.Item
                                className={cn(styles.songStats, { [styles.hidden]: selectedPlatform !== 'tiktok' })}
                            >
                                <HeaderSummary.Label>Song stats</HeaderSummary.Label>
                                <div className={styles.songStatsContainer}>
                                    <HeaderSummary.Value
                                        className={cn(styles.value, styles.songChartContainer, {
                                            [styles.pointer]: !!data?.project.song_id,
                                        })}
                                        onClick={() => {
                                            if (!data?.project?.song_id) {
                                                return;
                                            }
                                            setUrlState({ song: data.project.song_id.toString() });
                                        }}
                                    >
                                        <SongIcon className={styles.songIcon} />

                                        <ProjectSongChart key={data?.project.song_id} songId={data?.project?.song_id} />
                                    </HeaderSummary.Value>
                                    <ProjectSongDropdown
                                        songId={data?.project?.song_id}
                                        updateProject={updateProject}
                                    />
                                </div>
                            </HeaderSummary.Item>
                        </HeaderSummary>
                    </div>

                    <PlatformTabs className={styles.tabsNav}>
                        <PlatformTabs.Tab name="instagram" platform="instagram" />
                        <PlatformTabs.Tab name="tiktok" platform="tiktok" />
                        <PlatformTabs.Tab name="youtube" platform="youtube" />
                    </PlatformTabs>
                </header>

                <PlatformStatsProvider>
                    <CampaignsStatsProvider>
                        <InstagramPostStatsProvider>
                            <InstagramAccountsProvider>
                                <PostsProvider>
                                    <TabPanel name="instagram">
                                        <AggregatedPlatformStats platform="instagram" />
                                        <InstagramCampaigns />
                                    </TabPanel>
                                </PostsProvider>
                            </InstagramAccountsProvider>
                        </InstagramPostStatsProvider>
                    </CampaignsStatsProvider>
                </PlatformStatsProvider>

                <PlatformStatsProvider>
                    <CampaignsStatsProvider>
                        <TiktokVideosProvider>
                            <TiktokAccountsProvider>
                                <TiktokAudiosProvider>
                                    <AudioTimeSeriesProvider>
                                        <PostsProvider>
                                            <TabPanel name="tiktok">
                                                <AggregatedPlatformStats platform="tiktok" />
                                                <TiktokCampaigns />
                                            </TabPanel>
                                        </PostsProvider>
                                    </AudioTimeSeriesProvider>
                                </TiktokAudiosProvider>
                            </TiktokAccountsProvider>
                        </TiktokVideosProvider>
                    </CampaignsStatsProvider>
                </PlatformStatsProvider>

                <PlatformStatsProvider>
                    <CampaignsStatsProvider>
                        <YoutubeAccountsProvider>
                            <PostsProvider>
                                <TabPanel name="youtube">
                                    <AggregatedPlatformStats platform="youtube" />
                                    <YoutubeCampaigns />
                                </TabPanel>
                            </PostsProvider>
                        </YoutubeAccountsProvider>
                    </CampaignsStatsProvider>
                </PlatformStatsProvider>
            </Tabs>

            <SongDataModal
                songId={urlState.song ? Number(urlState.song) : undefined}
                isOpen={!!urlState.song}
                onClose={() => {
                    setUrlState({ song: undefined });
                }}
            />
        </>
    );
};

export default ProjectDetails;
