import React from 'react';
import { InfluencerPostStatus as InfluencerPostStatusType } from '../../InfluencerPlan.types';
import { ReactComponent as BriefedIcon } from '../../assets/statuses/Briefed.svg';
import { ReactComponent as DraftApprovedIcon } from '../../assets/statuses/DraftApproved.svg';
import { ReactComponent as DraftReceivedIcon } from '../../assets/statuses/DraftReceived.svg';
import { ReactComponent as ExpectingDraftIcon } from '../../assets/statuses/ExpectingDraft.svg';
import { ReactComponent as LiveIcon } from '../../assets/statuses/Live.svg';
import { ReactComponent as PlannedIcon } from '../../assets/statuses/Planned.svg';
import { ReactComponent as ToReplaceIcon } from '../../assets/statuses/ToReplace.svg';
import { ReactComponent as RejectedDeclinedIcon } from '../../assets/statuses/RejectedDeclined.svg';
import { ReactComponent as RedraftingIcon } from '../../assets/statuses/Redrafting.svg';

import styles from './InfluencerPostStatus.module.css';
import cn from 'classnames';
import { formatDate } from '../../../../../helpers';
import { StatusLabels } from '../../InfluencerPlan.helpers';

type InfluencerPostStatusProps = {
    status: InfluencerPostStatusType;
    className?: string;
    draftExpectedBy?: string | null;
};

const icons: Record<InfluencerPostStatusType, typeof BriefedIcon | null> = {
    briefed: BriefedIcon,
    draft_approved: DraftApprovedIcon,
    draft_pending: ExpectingDraftIcon,
    draft_received: DraftReceivedIcon,
    redrafting: RedraftingIcon,
    live: LiveIcon,
    planned: PlannedIcon,
    to_replace: ToReplaceIcon,
    backup: null,
    rejected_or_declined: RejectedDeclinedIcon,
};

const InfluencerPostStatus = ({ status, className, draftExpectedBy }: InfluencerPostStatusProps) => {
    const Icon = icons[status];
    return (
        <div className={cn(styles.container, className)}>
            {Icon && <Icon className={styles.icon} />}
            <div className={styles.content}>
                <span className={styles.label}>{StatusLabels[status]}</span>
                {draftExpectedBy && status === 'draft_pending' && (
                    <span className={styles.expectedByLabel}>
                        {formatDate(draftExpectedBy, { day: '2-digit', month: '2-digit', year: '2-digit' })}
                    </span>
                )}
            </div>
        </div>
    );
};

export default InfluencerPostStatus;
