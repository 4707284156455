import { Dispatch, ReactNode, createContext, useCallback, useContext, useReducer } from 'react';
import reducer, { TiktokAudiosByIdActions, TiktokAudiosByIdState } from '../../TiktokAudios/reducers/tiktokAudiosById';
import useNonNullContext from 'Hooks/useNonNullContext';
import { creatorbase } from '@round/api';

const initialState: TiktokAudiosByIdState = {
    isInitialized: false,
    data: {},
};

const TiktokAudiosContext = createContext<[TiktokAudiosByIdState, Dispatch<TiktokAudiosByIdActions>] | null>(null);

type Props = { children?: ReactNode };
export const TiktokAudiosProvider = ({ children }: Props) => {
    const state = useReducer(reducer, initialState);

    return <TiktokAudiosContext.Provider value={state}>{children}</TiktokAudiosContext.Provider>;
};

export function useTiktokAudios() {
    const [state, dispatch] = useNonNullContext(TiktokAudiosContext);

    const fetchData = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                dispatch({ type: 'tiktokAudiosInitialized' });
                return;
            }

            dispatch({ type: 'tiktokAudiosLoading', payload: { ids } });

            try {
                const response = await creatorbase.getTiktokAudios({ id: ids.join(',') }, requestInit);

                if (response.status !== 200) {
                    dispatch({ type: 'tiktokAudiosError', payload: { ids, error: 'Failed to fetch Tiktok audios' } });
                    return;
                }

                dispatch({ type: 'tiktokAudiosSuccess', payload: { ids, tiktokAudios: response.data.results } });
                dispatch({ type: 'tiktokAudiosInitialized' });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'tiktokAudiosIdle', payload: { ids } });
                    throw error;
                }

                dispatch({ type: 'tiktokAudiosError', payload: { ids, error: 'Failed to fetch Tiktok audios' } });
                throw error;
            }
        },
        [dispatch]
    );

    return {
        data: state.data,
        isInitialized: state.isInitialized,
        fetchData,
    };
}

export function useTiktokAudiosActions() {
    const context = useContext(TiktokAudiosContext);
    const [, dispatch] = context || [];

    const fetchData = useCallback(
        async (ids: number[]) => {
            if (!dispatch) {
                console.warn('useTiktokAudiosActions must be used within a TiktokAudiosProvider');
                return;
            }

            if (!ids.length) {
                return;
            }

            try {
                dispatch({ type: 'tiktokAudiosLoading', payload: { ids } });
                const response = await creatorbase.getTiktokAudios({ id: ids.join(',') });

                if (response.status !== 200) {
                    dispatch({ type: 'tiktokAudiosError', payload: { ids, error: 'Failed to fetch Tiktok audios' } });
                    return;
                }

                dispatch({ type: 'tiktokAudiosSuccess', payload: { ids, tiktokAudios: response.data.results } });
            } catch (error) {
                dispatch({ type: 'tiktokAudiosError', payload: { ids, error: 'Failed to fetch Tiktok audios' } });
            }
        },
        [dispatch]
    );

    return {
        fetchData,
    };
}
