import React from 'react';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import styles from './ApproveMicrowavePostsPage.module.css';
import { Card } from '@round/ui-kit';
import ApproveMicrowavePosts from '../../../Modules/Advertising/Microwave/ApprovePosts/ApproveMicrowavePosts';

const ApproveMicrowavePostsPage = () => (
    <>
        <NavBar />
        <Card className={styles.container}>
            <ApproveMicrowavePosts />
        </Card>
    </>
);

export default ApproveMicrowavePostsPage;
