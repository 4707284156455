import { useMemo } from 'react';
import { ReportItem } from '../../../types/MediaPlanResults.types';
import { Chart, ChartProps } from 'react-chartjs-2';
import styles from './MediaPlanResultsChart.module.css';
import { metricsOptions } from '../PresetsSelect/presets';
import { Select } from '@round/ui-kit';
import { StylesConfig } from 'react-select';
import cn from 'classnames';
import useUrlState from 'Hooks/useUrlState';

type MediaPlanResultsChartProps = {
    reportItems: ReportItem[];
};

type Point = { x: Date; y: number };

/**
 * Get the exponent of a number. Exponent of 0 is -Infinity
 * @param n
 */
const getExponent = (n: number) => Math.floor(Math.log10(Math.abs(n)));
const formatYAxisLabels = (n: number, fractionDigits: number = 0) => {
    const exponent = getExponent(n);
    const format = (n: number) => {
        return parseFloat((n / Math.pow(10, exponent - (exponent % 3))).toFixed(fractionDigits));
    };

    const sliceRules = [
        {
            threshold: 3,
            exponentSign: '',
            format: (n: number) => n,
        },
        {
            threshold: 6,
            exponentSign: 'K',
            format,
        },
        {
            threshold: 9,
            exponentSign: 'M',
            format,
        },
        {
            threshold: Infinity,
            exponentSign: 'B',
            format,
        },
    ];

    const rule = sliceRules.find((r) => r.threshold > exponent);
    if (!rule) {
        return n.toString();
    }

    return `${rule.format(n)}${rule.exponentSign}`;
};

const getPoints = (reportItems: ReportItem[], metric: keyof ReportItem): Point[] => {
    const dates = Array.from(new Set(reportItems.map((r) => r.day)));
    return dates
        .map((date) => ({
            x: new Date(date),
            y: reportItems
                .filter((item) => item.day === date)
                .reduce((acc, curr) => {
                    const value = curr[metric];
                    if (typeof value === 'number') {
                        return acc + value;
                    }

                    return acc;
                }, 0),
        }))
        .sort((a, b) => a.x.getTime() - b.x.getTime());
};

const selectStyles: StylesConfig = {
    container: (base) => ({ ...base, width: '10rem' }),
    control: (base) => ({
        ...base,
        border: 'none',
        backgroundColor: 'transparent',
        borderRadius: '0 0.25rem 0.25rem 0',
        boxShadow: 'none',
    }),
    valueContainer: (base) => ({ ...base, padding: '0.325rem 1rem' }),
};

type UrlState = Partial<{
    cLine: string;
    cBar: string;
}>;

const MediaPlanResultsChart = ({ reportItems }: MediaPlanResultsChartProps) => {
    const [urlState, setUrlState] = useUrlState<UrlState>({
        cLine: 'revenue',
        cBar: 'amount_spent',
    });

    const lineScaleMetric = metricsOptions.find((o) => o.value === urlState.cLine);
    const barScaleMetric = metricsOptions.find((o) => o.value === urlState.cBar);

    const data: ChartProps<'bar' | 'line', Point[]>['data'] = {
        datasets: [
            {
                type: 'line',
                label: lineScaleMetric?.label,
                data: getPoints(reportItems, lineScaleMetric?.value!),
                borderColor: '#FFA114',
                pointBackgroundColor: '#FFA114',
                pointBorderColor: 'white',
                pointBorderWidth: 0,
                pointHoverBorderWidth: 3,
                pointHoverBorderColor: 'white',
                xAxisID: 'time',
                yAxisID: 'line',
            },
            {
                type: 'bar',
                label: barScaleMetric?.label,
                data: getPoints(reportItems, barScaleMetric?.value!),
                backgroundColor: '#7A61DF',
                xAxisID: 'time',
                yAxisID: 'bar',
            },
        ],
    };

    const options = useMemo(() => {
        const lineScalePoints = reportItems.map((point) => point.amount_spent);
        const lineScaleMaxValue = Math.max(...lineScalePoints);
        const lineScaleMinValue = Math.min(...lineScalePoints);
        const lineScaleMinMaxDiff = lineScaleMaxValue - lineScaleMinValue;
        const lineScaleStepSize =
            isFinite(lineScaleMinMaxDiff) && lineScaleMinMaxDiff >= 0
                ? undefined
                : Math.pow(10, getExponent(lineScalePoints[0]) - 1);

        const barScalePoints = reportItems
            .map((point) => point.revenue)
            .filter((n): n is number => typeof n === 'number');
        const barScaleMaxValue = Math.max(...barScalePoints);
        const barScaleMinValue = Math.min(...barScalePoints);
        const barScaleMinMaxDiff = barScaleMaxValue - barScaleMinValue;
        const barScaleStepSize =
            isFinite(barScaleMinMaxDiff) && barScaleMinMaxDiff >= 0
                ? undefined
                : Math.pow(10, getExponent(barScalePoints[0]) - 1);

        const options: ChartProps['options'] = {
            elements: {
                point: {
                    radius: 4,
                    hoverRadius: 7,
                },
                line: {
                    borderWidth: 3,
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                line: {
                    axis: 'y',
                    position: 'left',
                    ticks: {
                        color: '#90A0B7',
                        count: 5,
                        stepSize: lineScaleStepSize,
                        callback: (value) => {
                            if (new Set(lineScalePoints).size < 2) {
                                return formatYAxisLabels(Number(value), 1);
                            }
                            const minMaxDifferenceExponent = getExponent(lineScaleMinMaxDiff);
                            const maxExponent = getExponent(lineScaleMaxValue);
                            const fractionDigits = maxExponent - minMaxDifferenceExponent;
                            const labelsFD = fractionDigits > 0 ? (fractionDigits < 100 ? fractionDigits : 99) : 0;
                            return formatYAxisLabels(Number(value), labelsFD);
                        },
                    },
                    grid: {
                        display: true,
                    },
                    border: {
                        display: false,
                        dash: [10, 10],
                    },
                },
                bar: {
                    axis: 'y',
                    position: 'right',
                    ticks: {
                        color: '#90A0B7',
                        count: 5,
                        stepSize: barScaleStepSize,
                        callback: (value) => {
                            if (new Set(barScalePoints).size < 2) {
                                return formatYAxisLabels(Number(value), 1);
                            }
                            const minMaxDifferenceExponent = getExponent(barScaleMinMaxDiff);
                            const maxExponent = getExponent(barScaleMaxValue);
                            const fractionDigits = maxExponent - minMaxDifferenceExponent;
                            const labelsFD = fractionDigits > 0 ? (fractionDigits < 100 ? fractionDigits : 99) : 0;
                            return formatYAxisLabels(Number(value), labelsFD);
                        },
                    },
                    grid: {
                        display: true,
                    },
                    border: {
                        display: false,
                        dash: [10, 10],
                    },
                },
                time: {
                    axis: 'x',
                    type: 'timeseries',
                    ticks: {
                        color: '#90A0B7',
                        autoSkip: true,
                    },
                    time: {
                        unit: 'day',
                        displayFormats: {
                            day: 'MMM D',
                        },
                    },
                    grid: {
                        display: false,
                    },
                    border: {
                        display: false,
                    },
                },
            },
        };

        return options;
    }, [reportItems]);

    const scaleMetricsOptions = metricsOptions.filter(
        (option) => ![lineScaleMetric?.value, barScaleMetric?.value].includes(option.value)
    );

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.metricsSelect}>
                    <div className={cn(styles.metricsSelectIndicator, styles.line)} />
                    <Select
                        styles={selectStyles}
                        isClearable={false}
                        value={lineScaleMetric}
                        options={scaleMetricsOptions}
                        onChange={(value) => setUrlState({ cLine: value?.value })}
                    />
                </div>
                <div className={styles.metricsSelect}>
                    <div className={cn(styles.metricsSelectIndicator, styles.bar)} />
                    <Select
                        styles={selectStyles}
                        isClearable={false}
                        value={barScaleMetric}
                        options={scaleMetricsOptions}
                        onChange={(value) => setUrlState({ cBar: value?.value })}
                    />
                </div>
            </div>
            <Chart height={100} type="bar" data={data} options={options} />
        </div>
    );
};

export default MediaPlanResultsChart;
