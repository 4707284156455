import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import { FormikHelpers, FormikProps } from 'formik';
import PlanForm, { PlanFormValues } from '../components/PlanForm/PlanForm';
import Button from '../../../../../../ui/Buttons/Button/Button';
import { useCallback, useMemo, useState } from 'react';
import { showNotification } from '../../../../../../helpers';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { AdvertisingContext } from '../../../contexts/AdvertisingContext/AdvertisingContext';
import styles from './EditPlanModal.module.css';
import { deleteMediaPlan, patchMediaPlan, MediaPlan } from '@round/api';

type EditPlanModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    plan: MediaPlan;
};

const EditPlanModal = ({ isModalOpen, closeModal, plan }: EditPlanModalProps) => {
    const [, contextUtils] = useNonNullContext(AdvertisingContext);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const initialValues: PlanFormValues = useMemo(
        () => ({
            objective_description: plan.objective_description,
            start_date: plan.start_date,
            end_date: plan.end_date,
            budget: plan.budget,
            currency: {
                value: plan.currency.id,
                label: plan.currency.name,
            },
        }),
        [plan.budget, plan.currency.id, plan.currency.name, plan.end_date, plan.objective_description, plan.start_date]
    );

    const handleSubmit = useCallback(
        async (values: PlanFormValues, { setSubmitting }: FormikHelpers<PlanFormValues>) => {
            if (!values.currency) {
                return;
            }

            try {
                setSubmitting(true);
                const response = await patchMediaPlan(plan.id, {
                    currency_id: values.currency.value,
                    release_id: plan.release.id,
                    budget: values.budget.toString(),
                    end_date: values.end_date,
                    start_date: values.start_date,
                    objective_description: values.objective_description,
                });

                if (response.status === 200) {
                    contextUtils.setPlan(response.data);
                    closeModal();
                    showNotification('Plan updated', 'info');
                    return;
                }

                showNotification('Could not update plan', 'error');
            } catch {
                showNotification('Could not update plan', 'error');
            } finally {
                setSubmitting(false);
            }
        },
        [closeModal, contextUtils, plan.id, plan.release.id]
    );

    const handleDelete = useCallback(async () => {
        try {
            setDeleteLoading(true);
            const response = await deleteMediaPlan(plan.id);
            if (response.status === 204) {
                contextUtils.deletePlan();
                showNotification('Plan deleted', 'info');
                closeModal();
                return;
            }

            showNotification('Could not delete plan', 'error');
        } catch {
            showNotification('Could not delete plan', 'error');
        } finally {
            setDeleteLoading(false);
            setShowDeletePrompt(false);
        }
    }, [closeModal, contextUtils, plan]);

    return (
        <>
            <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={closeModal}>
                <ModalTitle>Edit Plan</ModalTitle>
                <ModalContent className={styles.content}>
                    <PlanForm
                        handleSubmit={handleSubmit}
                        initialValues={initialValues}
                        renderActions={({ isSubmitting, submitForm, dirty }: FormikProps<PlanFormValues>) => (
                            <div className={styles.renderActions}>
                                <Button
                                    className={styles.submitButton}
                                    type="filled"
                                    color="black"
                                    disabled={!dirty}
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Save
                                </Button>
                                <Button
                                    className={styles.deleteButton}
                                    type="filled"
                                    color="negative"
                                    onClick={() => setShowDeletePrompt(true)}
                                >
                                    Delete
                                </Button>
                            </div>
                        )}
                    />
                </ModalContent>
            </Modal>

            <Modal
                className={styles.deletePlan}
                closeOnOverlayClick
                isOpen={showDeletePrompt}
                onClose={() => setShowDeletePrompt(false)}
                role="dialog"
                ariaLabel="confirmDeleteModal"
            >
                <ModalTitle>Delete media plan?</ModalTitle>
                <ModalContent className={styles.deletePlanContent}>
                    You are going to delete media plan for "{plan.release.name}". This operation cannot be undone
                </ModalContent>
                <ModalFooter align="center">
                    <Button loading={deleteLoading} type="bordered" color="negative" onClick={handleDelete}>
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default EditPlanModal;
