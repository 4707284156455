import { Column, RowData, Table } from '@tanstack/react-table';

export const renderDefaultSorting = <TableData extends RowData>(column: Column<TableData>) => {
    if (!column.getIsSorted()) {
        return null;
    }

    return column.getIsSorted();
};

/**
 * Returns a helper function that retrieves the meta data of a table with the correct type.
 */
export const getTableMetaHelper = <Meta extends {}>() => (table: Table<any>) => table.options.meta as Meta;
