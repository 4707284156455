import React, { useMemo } from 'react';
import PaginationTable, { PaginationTableProps } from '../../../../ui/PaginationTable/PaginationTable';
import { PhylloInstagramCreatorProfile } from '@round/api';
import { Column } from 'react-table';
import { Image, Skeleton } from '@round/ui-kit';
import styles from './InstagramWideSearchTable.module.css';
import { formatNumberToKNotation, roundTo2Dp } from 'helpers';
import PhylloContactDetailsBadge from '../../../TikTok/containers/TiktokInfluencerWideSearch/TiktokInfluencerWideSearchTable/components/ContactDetailBadge/PhylloContactDetailsBadge';
import { ReactComponent as VerifiedIcon } from '../../../../assets/Verified.svg';
import TruncatedTextCell from '../../../../SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';

type Props = Omit<PaginationTableProps<PhylloInstagramCreatorProfile>, 'columns' | 'orderBy' | 'onOrderByChange'>;

const InstagramWideSearchTable = (props: Props) => {
    const columns: Column<PhylloInstagramCreatorProfile>[] = useMemo(
        () => [
            {
                Header: 'User',
                accessor: 'username',
                Cell: ({ value, row: { original } }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.userDetails}>
                            <Image className={styles.profileImage} src={original.image_url} alt={value} />
                            <div>
                                <div>
                                    <a href={original.url} target="_blank" rel="noopener noreferrer">
                                        {value}
                                    </a>
                                    {original.is_verified && <VerifiedIcon className={styles.verifiedIcon} />}
                                </div>
                                <span>{original.fullname}</span>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Bio',
                accessor: 'bio',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }

                    return <TruncatedTextCell className={styles.bio}>{value}</TruncatedTextCell>;
                },
            },
            {
                Header: 'Followers',
                accessor: 'follower_count',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return <>{typeof value === 'number' ? formatNumberToKNotation(value) : '-'}</>;
                },
            },
            {
                Header: 'Engagement',
                accessor: 'engagement_rate',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    const displayValue = value ? `${roundTo2Dp(value)}%` : '-';
                    return <>{displayValue}</>;
                },
            },
            {
                Header: 'Avg Reels Views',
                accessor: 'reels_views',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return <>{typeof value === 'number' ? formatNumberToKNotation(value) : '-'}</>;
                },
            },
            {
                Header: 'Location',
                accessor: 'creator_location',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }

                    const { country, city, state } = value;
                    const subLocation = `${city ? city + ', ' : ''}${state ? state : ''}`;

                    return (
                        <div className={styles.location}>
                            <span className={styles.country}>{country}</span>
                            <span className={styles.subLocation}>{subLocation}</span>
                        </div>
                    );
                },
            },
            {
                Header: 'Age Group',
                accessor: 'age_group',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Account Type',
                accessor: 'account_type',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }
                    const formattedValue =
                        value.charAt(0).toUpperCase() + value.slice(1).toLowerCase().replace('_', ' ');

                    return <>{formattedValue}</>;
                },
            },
            {
                Header: 'Contact',
                accessor: 'contact_details',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!value?.length) {
                        return <>-</>;
                    }

                    return (
                        <div className={styles.contactDetails}>
                            {value?.map((contact) => (
                                <PhylloContactDetailsBadge contactDetails={contact} key={contact.value} />
                            ))}
                        </div>
                    );
                },
            },
        ],
        [props.loading]
    );

    const isTrialUser = useCheckUserGroupsAccess(['wide_search_trial']);
    const filteredColumns = useMemo(
        () => (isTrialUser ? columns.filter((column) => column.accessor !== 'contact_details') : columns),
        [columns, isTrialUser]
    );

    return <PaginationTable columns={filteredColumns} manualPagination {...props} />;
};

export default InstagramWideSearchTable;
