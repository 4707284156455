import { InfluencerPlan, InfluencerUserCampaigns, TikTokInfluencerUserStats } from '@round/api';

export type TiktokInfluencerTableRow = TikTokInfluencerUserStats & {
    image: string | undefined;
    campaigns: InfluencerUserCampaigns | undefined;
    plans: InfluencerPlan[];
};

export const isTiktokInfluencerTableRow = (stats: TikTokInfluencerUserStats): stats is TiktokInfluencerTableRow => {
    return typeof (stats as TiktokInfluencerTableRow).campaigns === 'object';
};
