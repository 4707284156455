import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import styles from './MicroCampaignsPage.module.css';
import MicroCampaigns from 'Modules/Advertising/Microwave/MicroCampaigns/MicroCampaigns';

const MicroCampaignsPage = () => {
    return (
        <>
            <NavBar />
            <div className={styles.content}>
                <MicroCampaigns />
            </div>
        </>
    );
};

export default MicroCampaignsPage;
