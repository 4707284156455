import Button from 'ui-new/whitelabel/Button/Button';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import styles from './CreateSongModal.module.css';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import SongForm, { SongFormValues, validationSchema } from '../../components/SongForm/SongForm';
import { creatorbase } from '@round/api';
import { showNotification } from 'helpers';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import { formatSongFormValuesToApiData } from '../../components/SongForm/helpers';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    createSong: typeof creatorbase.postSong;
};

const CreateSongModal = ({ isOpen, onClose, createSong }: Props) => {
    const handleSubmit = async (values: SongFormValues, helpers: FormikHelpers<SongFormValues>) => {
        try {
            const formattedValues = formatSongFormValuesToApiData(values);
            const response = await createSong(formattedValues);

            if (response.status === 201) {
                showNotification('Song created', 'info');
                formik.resetForm();
                onClose();
                return;
            }

            if (response.status !== 400) {
                showNotification('Could not create song', 'error');
                return;
            }

            if (Array.isArray(response.data)) {
                showNotification(response.data.join(', '), 'error');
                return;
            }

            helpers.setErrors(mapApiErrorsToFormikErrors(response.data));
        } catch {
            showNotification('Could not create song', 'error');
        }
    };

    const formik = useFormik<SongFormValues>({
        initialValues: {
            title: '',
            artist_name: '',
            status: 'unreleased',
            spotify_url: '',
            tiktok_audios: [],
            instagram_audios: [],
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    const handleClose = () => {
        formik.resetForm();
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} className={styles.modal}>
            <Modal.Header>
                <Modal.Title>Add a new song</Modal.Title>
            </Modal.Header>

            <FormikProvider value={formik}>
                <Modal.Body>
                    <SongForm />
                </Modal.Body>

                <Modal.Actions>
                    <Button
                        appearance="primary"
                        onClick={() => {
                            formik.handleSubmit();
                        }}
                        isLoading={formik.isSubmitting}
                        disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
                    >
                        Create song
                    </Button>
                    <Button appearance="ghost" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Actions>
            </FormikProvider>
        </Modal>
    );
};

export default CreateSongModal;
