import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Brand } from './brands.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';

export const models = {
    creatorbaseBrand: Model as ModelDefinition<Brand>,
};

export const serializers = {
    creatorbaseBrand: makeSerializer<Brand>([]),
};

export const factories = {
    creatorbaseBrand: createFactory<Brand>({
        team_id: (index: number) => index,
        image: null,
        name: (index: number) => `brand ${index}`,
    }),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/creatorbase/brands/';
    server.get(rootUrl, function (this: RouteHandlerContext, schema, request) {
        const ids: number[] = request.queryParams.id ? request.queryParams.id.split(',').map(Number) : [];

        const brands = schema
            .all('creatorbaseBrand')
            .filter((brand) => ids.includes(Number(brand.id)) || !request.queryParams.id);

        return buildPaginatedResponse(brands, {
            url: rootUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'creatorbaseBrand'),
        });
    });

    server.get(`${rootUrl}:id/`, (schema, request) => {
        const brand = schema.find('creatorbaseBrand', request.params.id);
        if (!brand) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return brand;
    });

    server.post(rootUrl, (schema, request) => {
        const formData: FormData = (request.requestBody as unknown) as FormData;
        const parsedFormData = {};

        formData.forEach((value, key) => {
            // @ts-ignore
            parsedFormData[key] = value;
        });

        return schema.create('creatorbaseBrand', parsedFormData);
    });
}
