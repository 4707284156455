import { microwave, PaginatedApiResponseData } from '@round/api';
import { useCallback, useState } from 'react';

type State =
    | {
          data: null;
          status: 'not-initialized';
          error: null;
      }
    | {
          data: null;
          status: 'loading';
          error: null;
      }
    | {
          data: Pick<PaginatedApiResponseData<microwave.Release>, 'results' | 'count'>;
          status: 'initialized';
          error: null;
      }
    | {
          data: null;
          status: 'error';
          error: string;
      };

const initialState: State = {
    status: 'not-initialized',
    data: null,
    error: null,
};

export function useMicrowaveReleases() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (params: microwave.GetReleasesParams, requestInit?: RequestInit) => {
        try {
            setState({ status: 'loading', data: null, error: null });
            const response = await microwave.getReleases(params, requestInit);
            if (response.status === 404) {
                setState({ status: 'error', error: response.data.detail, data: null });
                return response;
            }

            setState({
                status: 'initialized',
                data: {
                    results: response.data.results,
                    count: response.data.count,
                },
                error: null,
            });
            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            const errorMessage = e instanceof Error ? e.message : 'Could not fetch microwave releases';
            setState({ status: 'error', error: errorMessage, data: null });
            throw e;
        }
    }, []);

    const reset = () => setState(initialState);

    return { ...state, fetchData, reset };
}
