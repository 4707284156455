import { microwave, OrderingValues, UserPlanner } from '@round/api';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import WrapperPaginationTable, {
    WrapperPaginationTableProps,
} from 'ui/WrapperTable/WrapperPaginationTable/WrapperPaginationTable';
import styles from './MicroCampaignsTable.module.css';
import { Image, Skeleton } from '@round/ui-kit';
import { Link } from 'react-router-dom';
import CostLabel from 'ui/DataDisplay/Money/CostLabel/CostLabel';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import useNonNullContext from 'Hooks/useNonNullContext';
import { getPlatformIcon } from '../../helpers';
import Status from '../components/Status/Status';
import moment from 'moment';
import { mapOrderingToTableSorting, mapTableSortingToOrdering } from 'ui/WrapperTable/helpers';
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';
import { filterByVisibility } from 'utility/utility';
import { ReactComponent as LinkIcon } from 'assets/icons/Link.svg';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';

export type MicroCampaignsTableRow = microwave.Campaign & {
    release: microwave.Release;
    planners: UserPlanner[];
};

type TableCellContext<K extends keyof MicroCampaignsTableRow> = CellContext<
    MicroCampaignsTableRow,
    MicroCampaignsTableRow[K]
>;

type Props = Pick<
    WrapperPaginationTableProps<MicroCampaignsTableRow>,
    'isLoading' | 'data' | 'noDataLabel' | 'page' | 'setPage' | 'pageSize' | 'setPageSize' | 'count'
> & {
    onOrderingChange: (ordering: OrderingValues<microwave.GetReleasesSortableKeys>[]) => void;
    ordering: OrderingValues<microwave.GetReleasesSortableKeys>[];
    onClickEdit: (row: MicroCampaignsTableRow) => void;
};

const MicroCampaignsTable = ({ isLoading, ordering, onOrderingChange, onClickEdit, ...props }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const featureFlags = useFeatureFlags();

    const columns = filterByVisibility<ColumnDef<MicroCampaignsTableRow, any>>([
        {
            accessorKey: 'release',
            id: 'brand',
            header: 'Brand',
            cell: ({ getValue }: TableCellContext<'release'>) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                const release = getValue();
                if (!release) {
                    return <>-</>;
                }

                const { brand_image, brand_name, client_name } = release;

                return (
                    <div className={styles.accountContainer}>
                        <Image loading={isLoading} src={brand_image ?? ''} className={styles.brandImage} />
                        <div className={styles.titles}>
                            <p className={styles.title}>{isLoading ? <Skeleton /> : brand_name}</p>
                            <p className={styles.subTitle}>{isLoading ? <Skeleton /> : client_name}</p>
                        </div>
                    </div>
                );
            },
        },
        {
            accessorKey: 'release',
            id: 'project',
            header: 'Project',
            cell: ({ getValue, row: { original } }: TableCellContext<'release'>) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                const release = getValue();
                if (!release) {
                    return <>-</>;
                }

                const { type_name, name } = release;

                return (
                    <div className={styles.titles}>
                        <Link
                            to={`/campaigns/${release.id}/creators/?microsExpanded=true#${original.platform}`}
                            target="_blank"
                            className={styles.title}
                        >
                            {isLoading ? <Skeleton /> : name}
                        </Link>
                        <p className={styles.subTitle}>{isLoading ? <Skeleton /> : type_name}</p>
                    </div>
                );
            },
        },
        {
            header: 'Platform',
            accessorKey: 'platform',
            cell: ({ getValue }: TableCellContext<'platform'>) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                const PlatformIcon = getPlatformIcon(getValue());

                return <PlatformIcon />;
            },
        },
        {
            accessorKey: 'planners',
            header: 'Team',
            cell: ({ row: { original } }) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                return (
                    <span className={styles.team}>
                        {original.planners.length
                            ? original.planners.map((p) => `${p.first_name} ${p.last_name}`).join(', ')
                            : '-'}
                    </span>
                );
            },
        },
        {
            accessorKey: 'live_date',
            //sorting id
            id: 'latest_campaign_live_date',
            enableSorting: true,
            header: 'Live date',
            cell: ({ getValue }: TableCellContext<'live_date'>) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                return <span className={styles.liveDate}>{moment(getValue()).format('DD MMM YYYY')}</span>;
            },
        },
        {
            id: 'completion',
            header: 'Completion',
            cell: (props) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                const { target_post_count, approved_post_count } = props.row.original;
                return `${approved_post_count ?? 0}/${target_post_count} posts`;
            },
        },
        {
            accessorKey: 'to_approve_post_count',
            header: 'To approve',
            cell: ({ getValue }: TableCellContext<'to_approve_post_count'>) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                return <span className={styles.toApprove}>{getValue() ?? 0}</span>;
            },
        },
        {
            accessorKey: 'contacted_creator_count',
            header: 'Contacted',
            cell: ({ getValue }: TableCellContext<'contacted_creator_count'>) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                return <span className={styles.contacted}>{getValue()}</span>;
            },
        },
        {
            accessorKey: 'budget',
            header: 'Budget',
            cell: ({ getValue, row: { original } }: TableCellContext<'budget'>) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                const currency = currencies.find((c) => c.id === original.currency_id);
                return <CostLabel cost={getValue()} currencySymbol={currency?.symbol ?? ''} />;
            },
        },
        {
            accessorKey: 'status',
            id: 'status',
            header: 'Status',
            cell: ({ getValue }: TableCellContext<'status'>) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                return <Status status={getValue()} />;
            },
        },
        {
            id: 'edit',
            header: 'Edit',
            size: 44,
            cell: ({ row: { original } }) => {
                if (isLoading) {
                    return <Skeleton />;
                }

                return (
                    <button className={styles.editButton} onClick={() => onClickEdit(original)}>
                        <EditIcon />
                    </button>
                );
            },
        },
        {
            id: 'link',
            header: 'Details',
            size: 44,
            isVisible: featureFlags.isEnabled('microwave_campaign_details_page'),
            cell: ({ row: { original } }) => {
                // It's a temporary link to campaign details page
                // Until we decide how UI should look for that.
                if (isLoading) {
                    return <Skeleton />;
                }

                return (
                    <Link to={`/microwave/campaigns/${original.release.id}/`} className={styles.editButton}>
                        <LinkIcon width={20} height={20} />
                    </Link>
                );
            },
        },
    ]);

    return (
        <WrapperPaginationTable
            manualPagination
            manualSorting
            enableSorting
            sorting={mapOrderingToTableSorting(ordering)}
            onSortingChange={(sortingState) => onOrderingChange(mapTableSortingToOrdering(sortingState))}
            isLoading={isLoading}
            columns={columns}
            columnPinning={{
                right: ['status', 'edit', 'link'],
            }}
            rowSpanHelper={{
                brand: (row) => row.original.release?.id.toString(),
                project: (row) => row.original.release?.id.toString(),
            }}
            className={styles.table}
            {...props}
        />
    );
};

export default MicroCampaignsTable;
