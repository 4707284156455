import { GetInvoiceRequestsParams, GetInvoiceRequestsSortingKey, OrderingValues } from '@round/api';
import useUrlState from 'Hooks/useUrlState';
import useInvoiceRequests from 'Modules/Finance/InvoiceRequests/useInvoiceRequests';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import InvoiceRequestsTable, { InvoiceRequestsTableRow } from './InvoiceRequestsTable/InvoiceRequestsTable';
import styles from './InvoiceRequests.module.css';
import React, { useState } from 'react';
import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';
import { showNotification } from 'helpers';
import copyComponentRenderToClipboard from 'utility/copyToClipboard/copyComponentRenderToClipboard';
import ClipboardCopyTable from 'Modules/Finance/InvoiceRequests/ClipboardCopyTable/ClipboardCopyTable';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import IconButton from 'ui-new/Buttons/IconButton/IconButton';
import InvoiceRequestsFilters, { InvoiceRequestsFilterParams } from './InvoiceRequestsFilters/InvoiceRequestsFilters';

type UrlState = GetInvoiceRequestsParams;

const initialUrlState: UrlState = {
    page: 1,
    page_size: 10,
};

const getFiltersFromUrl = (urlState: Partial<Record<keyof UrlState, string>>): InvoiceRequestsFilterParams => {
    return {
        client_id: urlState.client_id,
        plan_start_date_end: urlState.plan_start_date_end,
        plan_start_date_start: urlState.plan_start_date_start,
        planner_id: urlState.planner_id,
        status: urlState.status,
        with_notes: urlState.with_notes?.length ? urlState.with_notes === 'true' : undefined,
        release_id: urlState.release_id ? Number(urlState.release_id) : undefined,
    };
};

const InvoiceRequests = () => {
    const { currencies } = useNonNullContext(OptionsContext);
    const [selectedRows, setSelectedRows] = useState<InvoiceRequestsTableRow[]>([]);

    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState);

    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const pageSize = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;
    const ordering: OrderingValues<GetInvoiceRequestsSortingKey>[] = urlState?.ordering
        ? (urlState.ordering.split(',') as OrderingValues<GetInvoiceRequestsSortingKey>[])
        : [];

    const filters = getFiltersFromUrl(urlState);
    const {
        isLoading,
        isInitialized,
        error,
        count,
        rows,
        init,
        reset,
        updateInvoiceRequest,
        updateInvoice,
    } = useInvoiceRequests({
        page,
        page_size: pageSize,
        ordering: urlState.ordering as OrderingValues<GetInvoiceRequestsSortingKey>,
        ...filters,
    });

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                init({ signal });
            }
        },
        [init, isInitialized]
    );

    const resetPageState = () => {
        reset();
        setSelectedRows([]);
    };

    const copyTable = () => {
        if (!selectedRows.length) {
            return;
        }

        const success = copyComponentRenderToClipboard(
            {
                currencies,
                data: selectedRows,
            },
            ClipboardCopyTable
        );

        if (success) {
            showNotification('Copied!', 'info');
        } else {
            showNotification('Could not copy', 'error');
        }
    };

    return (
        <>
            <div className={styles.header}>
                <h1 className={styles.title}>Invoice Requests</h1>

                <div className={styles.actions}>
                    <InvoiceRequestsFilters
                        value={filters}
                        onChange={(val) => {
                            setUrlState({ ...val, page: 1 });
                            resetPageState();
                        }}
                    />

                    {selectedRows.length > 0 && (
                        <>
                            <IconButton hint="Clear selection" onClick={() => setSelectedRows([])}>
                                <TrashIcon />
                            </IconButton>
                            <IconButton hint="Copy selection to clipboard" onClick={copyTable}>
                                <CopyIcon />
                            </IconButton>
                        </>
                    )}
                </div>
            </div>
            <InvoiceRequestsTable
                isLoading={isLoading}
                data={rows}
                count={count}
                page={page}
                noDataLabel={error ?? 'No data found.'}
                setPage={(page) => {
                    setUrlState({ page });
                    resetPageState();
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                    setUrlState({ page_size: pageSize || initialUrlState.page_size });
                    resetPageState();
                }}
                updateInvoiceRequest={updateInvoiceRequest}
                updateInvoice={updateInvoice}
                selectedRows={selectedRows}
                onSelectedRowsChange={setSelectedRows}
                ordering={ordering}
                onOrderingChange={(ordering) => {
                    setUrlState({ ordering: ordering.toString() as OrderingValues<GetInvoiceRequestsSortingKey> });
                    resetPageState();
                }}
            />
        </>
    );
};

export default InvoiceRequests;
