import { useCallback, useState } from 'react';
import { getTiktokUserImages, searchMicros, SearchMicrosParams, TiktokUserImage, UserCreator } from '@round/api';
import useAbortableEffect from '../../../../../../Hooks/useAbortableEffect';
import { OutreachTableRow } from './OutreachTable/OutreachTable';

export default function useOutreachCreators({
    planId,
    tiktokAudioId,
    page,
    page_size,
    location,
    min_follower_count,
    max_follower_count,
    similar_audio_ids,
    has_influencer_user,
    hashtag_ids,
    search,
    with_genre_affinity,
    min_relevant_post_count,
    ordering,
}: SearchMicrosParams) {
    const [creators, setCreators] = useState<UserCreator[]>([]);
    const [creatorsCount, setCreatorsCount] = useState(0);

    const [isInitialized, setIsInitialized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [images, setImages] = useState<TiktokUserImage[]>([]);

    const fetchData = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                setLoading(true);
                setError(null);
                const response = await searchMicros(
                    {
                        planId,
                        tiktokAudioId,
                        page,
                        page_size,
                        location,
                        min_follower_count,
                        max_follower_count,
                        similar_audio_ids,
                        has_influencer_user,
                        hashtag_ids,
                        search,
                        with_genre_affinity,
                        min_relevant_post_count,
                        ordering,
                    },
                    requestInit
                );

                setCreators(response.data.results);
                setCreatorsCount(response.data.count);
                const imagesToFetch = response.data.results.map((creator) => creator.id);
                if (imagesToFetch.length) {
                    const images = await getTiktokUserImages(imagesToFetch, requestInit);
                    setImages(images);
                }

                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setError("Sorry, we're having a technical issue");
            } finally {
                setLoading(false);
            }
        },
        [
            tiktokAudioId,
            location,
            max_follower_count,
            min_follower_count,
            page,
            page_size,
            planId,
            search,
            similar_audio_ids,
            has_influencer_user,
            hashtag_ids,
            with_genre_affinity,
            min_relevant_post_count,
            ordering,
        ]
    );

    useAbortableEffect(
        (signal) => {
            fetchData({ signal });
        },
        [fetchData]
    );

    return {
        creators: creators.map<OutreachTableRow>((creator) => ({
            ...creator,
            image: images.find((image) => image.user_id === creator.id),
        })),
        creatorsCount,
        loading,
        error,
        isInitialized,
        reset: () => {
            setCreators([]);
            setImages([]);
            setCreatorsCount(0);
            setLoading(false);
            setError(null);
            setIsInitialized(false);
        },
        removeCreators: (ids: number[]) => {
            setCreators((creators) => creators.filter((creator) => !ids.includes(creator.id)));
        },
        refresh: fetchData,
    };
}
