import { Context } from '@fluentui/react-context-selector';
import { getInstagramUserImages } from '@round/api';
import { DataState, InstagramUserImage, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { makeErrorStateSlice, makeLoadingStateSlice, makeSuccessStateSlice } from 'utility/dataState';

type Data = InstagramUserImage | null;

export type State = {
    [imageId: number]: DataState<Data> | undefined;
};

export const initialState: State = {};

export type Actions =
    | ReducerActionWithPayload<'loadInstagramUserImages', number[]>
    | ReducerActionWithPayload<'instagramUserImagesSuccess', { imageIds: number[]; images: InstagramUserImage[] }>
    | ReducerActionWithPayload<'instagramUserImagesErrorLoading', { imageIds: number[]; error: string }>;

export const reducer = createReducer<State, Actions>({
    loadInstagramUserImages: (state, { payload: imageIds }) => {
        const loadingData = makeLoadingStateSlice<number, Data>(imageIds);

        return { ...state, ...loadingData };
    },
    instagramUserImagesSuccess: (state, { payload: { imageIds, images } }) => {
        const successData = makeSuccessStateSlice<number, Data>(
            imageIds,
            (id) => images.find((i) => i.user_id === id) ?? null
        );

        return { ...state, ...successData };
    },
    instagramUserImagesErrorLoading: (state, { payload: { imageIds, error } }) => {
        const errorData = makeErrorStateSlice<number, Data>(imageIds, error);

        return { ...state, ...errorData };
    },
});

export function makeInstagramUserImagesDataHook<Value extends BaseDataHookContextValue<Actions>>(
    context: Context<Value>,
    selector: ContextSelector<Value, State>
) {
    return function useInstagramUserImages() {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (imageIds: number[], requestInit?: RequestInit) => {
                if (!imageIds.length) {
                    dispatch({ type: 'instagramUserImagesSuccess', payload: { imageIds: [], images: [] } });
                    return;
                }

                try {
                    dispatch({ type: 'loadInstagramUserImages', payload: imageIds });
                    const response = await getInstagramUserImages(imageIds, requestInit);

                    if (response.status === 200) {
                        dispatch({
                            type: 'instagramUserImagesSuccess',
                            payload: { imageIds, images: response.data },
                        });
                        return response;
                    }

                    dispatch({
                        type: 'instagramUserImagesErrorLoading',
                        payload: { imageIds, error: 'Could not load Instagram user images' },
                    });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({
                        type: 'instagramUserImagesErrorLoading',
                        payload: { imageIds, error: 'Could not load Instagram user images' },
                    });
                    throw e;
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
}
