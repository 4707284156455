import React, { useMemo, useState } from 'react';
import { Table } from '@round/ui-kit';
import { isTwitterReportItem, ReportItem } from '../../../types/MediaPlanResults.types';
import {
    asMoney,
    asMoneyWithoutZeros,
    displayOptionalNumericTableValue,
    numberWithCommas,
    roundTo2Dp,
} from '../../../../../../helpers';
import ReactTooltip from 'react-tooltip';
import { MediaPlanItemRow } from '../../../../../../App.types';
import styles from './CreativeBreakdown.module.css';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { AdvertisingContext } from '../../../contexts/AdvertisingContext/AdvertisingContext';
import TargetingCreativeExpandBar from '../TargetingCreativeExpandBar/TargetingCreativeExpandBar';
import cn from 'classnames';
import ProgressBar from '../../../../../../ui/ProgressBar/ProgressBar';
import { createReportItem, getTotalReportItemValues } from '../../../helpers/MediaPlanResult.helpers';
import { presetOptions, SelectableMetrics } from '../PresetsSelect/presets';
import PresetsSelect from '../PresetsSelect/PresetsSelect';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import uniq from 'lodash/uniq';
import TruncatedTextCell from 'SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import { sortNumerically } from '@round/utils';
import { toDecimalPoint } from '@round/utils';

type CreativeBreakdownProps = {
    mediaPlanItem: MediaPlanItemRow;
    reportItems: ReportItem[];
};

type CreativeBreakdownRow = ReportItem & { itemCount: number };

type TableCellContext<K extends keyof CreativeBreakdownRow> = CellContext<
    CreativeBreakdownRow,
    CreativeBreakdownRow[K]
>;

const ALWAYS_SHOWN_COLUMNS = ['targeting', 'creative'];

const CreativeBreakdown = ({ mediaPlanItem, reportItems }: CreativeBreakdownProps) => {
    const [contextState] = useNonNullContext(AdvertisingContext);
    const currency = contextState.mediaPlan?.currency;

    const columns: ColumnDef<CreativeBreakdownRow, any>[] = useMemo(
        () =>
            currency
                ? [
                      {
                          id: 'creative',
                          header: 'Creative',
                          accessorKey: 'creative',
                          cell: ({ getValue, row: { original } }) => {
                              const creative = getValue();
                              const creativeLabel = isTwitterReportItem(original)
                                  ? original.tweetText.length > 22
                                      ? `${original.tweetText.slice(0, 22)}...`
                                      : original.tweetText
                                  : creative;

                              return (
                                  <div className={cn(styles.creativeContainer, styles.alwaysShownCell)}>
                                      <span>{creativeLabel}</span>
                                  </div>
                              );
                          },
                      },
                      {
                          id: 'targeting',
                          header: 'Targeting',
                          accessorKey: 'targeting',
                          cell: ({ getValue }) => {
                              const targeting = getValue() || '-';

                              return (
                                  <TruncatedTextCell
                                      className={cn(styles.targetingContainer, styles.alwaysShownCell)}
                                      popoverClassName={styles.targetingPopover}
                                  >
                                      {targeting}
                                  </TruncatedTextCell>
                              );
                          },
                      },
                      {
                          id: 'amount_spent',
                          accessorKey: 'amount_spent',
                          header: 'Amount Spent',
                          cell: ({ getValue }: TableCellContext<'amount_spent'>) => {
                              const amountSpent = getValue();
                              if (isNaN(amountSpent)) {
                                  return '-';
                              }

                              const cost = Number(mediaPlanItem.cost);
                              const percentage = cost !== 0 ? (amountSpent / cost) * 100 : 0;
                              return (
                                  <div className={cn(styles.amountSpentContainer)}>
                                      <p className={styles.amountSpent}>
                                          {asMoneyWithoutZeros(amountSpent, currency)}
                                          <span className={styles.amountSpentPercentage}>
                                              {toDecimalPoint(percentage, 1)}%
                                          </span>
                                      </p>
                                      <ProgressBar
                                          progress={Math.min(100, percentage)}
                                          trackStyles={{ height: '0.2rem' }}
                                          barStyles={{
                                              backgroundColor: amountSpent > cost * 1.05 ? '#F7685B' : '',
                                          }}
                                      />
                                  </div>
                              );
                          },
                      },
                      {
                          id: 'impressions',
                          accessorKey: 'impressions',
                          header: 'Impressions',
                          cell: ({ getValue }: TableCellContext<'impressions'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpm',
                          accessorKey: 'cpm',
                          header: 'CPM',
                          cell: ({ getValue }: TableCellContext<'cpm'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'clicks',
                          accessorKey: 'clicks',
                          header: 'Clicks',
                          cell: ({ getValue }: TableCellContext<'clicks'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpc',
                          accessorKey: 'cpc',
                          header: 'CPC',
                          cell: ({ getValue }: TableCellContext<'cpc'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'views',
                          accessorKey: 'views',
                          header: 'Views',
                          cell: ({ getValue }: TableCellContext<'views'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpv',
                          accessorKey: 'cpv',
                          header: 'CPV',
                          cell: ({ getValue }: TableCellContext<'cpv'>) => asMoney(getValue(), currency, 3),
                      },
                      {
                          id: 'six_sec_views',
                          accessorKey: 'six_sec_views',
                          header: 'Views (6sec)',
                          cell: ({ getValue }: TableCellContext<'six_sec_views'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'six_sec_cpv',
                          accessorKey: 'six_sec_cpv',
                          header: 'CPV (6sec)',
                          cell: ({ getValue }: TableCellContext<'six_sec_cpv'>) => asMoney(getValue(), currency, 3),
                      },

                      {
                          id: 'leads',
                          accessorKey: 'leads',
                          header: 'Leads',
                          cell: ({ getValue }: TableCellContext<'leads'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpl',
                          accessorKey: 'cpl',
                          header: 'CPL',
                          cell: ({ getValue }: TableCellContext<'cpl'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'view_rate',
                          accessorKey: 'view_rate',
                          header: 'View Rate',
                          cell: ({ getValue }: TableCellContext<'view_rate'>) => {
                              const viewRate = getValue();
                              return typeof viewRate === 'number' && !isNaN(viewRate)
                                  ? `${numberWithCommas(viewRate)}%`
                                  : '-';
                          },
                      },
                      {
                          id: 'purchases',
                          accessorKey: 'purchases',
                          header: 'Purchases',
                          cell: ({ getValue }: TableCellContext<'purchases'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpa',
                          accessorKey: 'cpa',
                          header: 'CPA',
                          cell: ({ getValue }: TableCellContext<'cpa'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'revenue',
                          accessorKey: 'revenue',
                          header: 'Revenue',
                          cell: ({ getValue }: TableCellContext<'revenue'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'roas',
                          accessorKey: 'roas',
                          header: 'ROAS',
                          cell: ({ getValue }: TableCellContext<'roas'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'ctr',
                          accessorKey: 'ctr',
                          header: 'CTR',
                          cell: ({ getValue }: TableCellContext<'ctr'>) => {
                              const ctr = getValue();
                              return typeof ctr === 'number' && !isNaN(ctr) ? `${roundTo2Dp(ctr * 100)}%` : '-';
                          },
                      },
                  ]
                : [],
        [currency, mediaPlanItem.cost]
    );

    const [selectedPreset, setSelectedPreset] = useState(
        presetOptions.find((option) => option.value === 'performance')
    );

    const data: Array<CreativeBreakdownRow> = useMemo(() => {
        const uniqueCreativesFromReportItems = uniq(
            reportItems.map((row) => (isTwitterReportItem(row) ? row.tweetText : row.creative))
        );

        const rows = uniqueCreativesFromReportItems.map((creative) => {
            const linkedReportItems = reportItems.filter((row) =>
                isTwitterReportItem(row) ? row.tweetText === creative : row.creative === creative
            );
            return {
                ...getTotalReportItemValues(linkedReportItems),
                creative,
                targeting: uniq(linkedReportItems.map((r) => r.targeting))
                    .filter((t): t is string => typeof t === 'string')
                    .sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()) || 0)
                    .join(', '),
                itemCount: linkedReportItems.length,
            };
        });

        if (!rows.length && !mediaPlanItem.creatives.length) {
            return [{ ...createReportItem({ mediaPlanItemId: mediaPlanItem.id }), itemCount: 0 }];
        }

        if (!rows.length) {
            return mediaPlanItem.creatives.map<CreativeBreakdownRow>((creative) => ({
                ...createReportItem({
                    mediaPlanItemId: mediaPlanItem.id,
                    creative: creative.name,
                }),
                itemCount: 0,
            }));
        }

        return rows.sort((a, b) => sortNumerically(a.amount_spent, b.amount_spent));
    }, [mediaPlanItem.creatives, mediaPlanItem.id, reportItems]);

    const shouldRenderUnsupportedNamingConvention = useMemo(
        () =>
            data.some((row) => {
                if (isTwitterReportItem(row)) {
                    return row.targeting === null;
                }

                return row.targeting === null || row.creative === null;
            }),
        [data]
    );

    const columnVisibility = selectedPreset
        ? columns.reduce((acc, column) => {
              if (!column.id) {
                  return acc;
              }

              const metricName = column.id as SelectableMetrics;

              if (![...selectedPreset?.metrics, ...ALWAYS_SHOWN_COLUMNS].includes(metricName)) {
                  acc[metricName] = false;
              }

              return acc;
          }, {} as Record<SelectableMetrics, boolean>)
        : {};

    if (!data.length) {
        return null;
    }

    return (
        <>
            <TargetingCreativeExpandBar
                mediaPlanItem={mediaPlanItem}
                renderToolbar={() =>
                    !shouldRenderUnsupportedNamingConvention && (
                        <PresetsSelect value={selectedPreset} onChange={setSelectedPreset} />
                    )
                }
            >
                {!shouldRenderUnsupportedNamingConvention && (
                    <div className={styles.tableWrapper}>
                        <Table
                            className={styles.table}
                            columns={columns}
                            data={data}
                            columnVisibility={columnVisibility}
                        />
                    </div>
                )}
                {shouldRenderUnsupportedNamingConvention && <p>Naming convention not currently supported</p>}
            </TargetingCreativeExpandBar>
            <ReactTooltip place="right" />
        </>
    );
};

export default CreativeBreakdown;
