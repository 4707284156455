import { microwave } from '@round/api';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { asMoney, formatNumberToKNotation, roundTo2Dp } from 'helpers';
import useNonNullContext from 'Hooks/useNonNullContext';
import { CampaignStats } from '../helpers';
import styles from './Campaign.module.css';
import cn from 'classnames';
import { ProgressBar } from '@round/ui-kit';
import { ReactComponent as ArrowIcon } from 'assets/ArrowIcon.svg';
import IconButton from 'ui-new/Buttons/IconButton/IconButton';
import { ReactNode } from 'react';

type Props = {
    campaign: microwave.Campaign;
    stats: CampaignStats | undefined;
    className?: string;
    isExpanded?: boolean;
    onExpand?: () => void;
    children?: ReactNode;
};

const Campaign = ({ campaign, stats, className, isExpanded, onExpand, children }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);

    const budget = campaign.budget;
    const amountSpent = stats?.amountSpent ?? 0;
    const amountSpentPercentage = roundTo2Dp((amountSpent / budget) * 100);

    return (
        <div className={cn(styles.container, className, { [styles.isExpanded]: isExpanded })}>
            <div className={styles.campaignCard}>
                <div className={styles.header}>
                    <p className={styles.title}>{campaign.name}</p>
                </div>

                <div className={styles.info}>
                    <div className={styles.infoColumn}>
                        <div className={styles.amountSpentValues}>
                            <span>
                                {stats?.amountSpent
                                    ? asMoney(
                                          stats?.amountSpent,
                                          currencies.find((c) => c.id === campaign.currency_id)
                                      )
                                    : '-'}
                            </span>

                            <span>{amountSpentPercentage}%</span>
                        </div>

                        <ProgressBar
                            progress={amountSpentPercentage}
                            barClassName={styles.progressBarThumb}
                            trackClassName={styles.progressBarTrack}
                        />
                    </div>

                    <div className={styles.infoColumn}>
                        <span className={styles.infoColumnLabel}>Total posts</span>
                        <span className={styles.infoColumnValue}>
                            {stats ? `${stats.postCount}/${campaign.target_post_count}` : '-'}
                        </span>
                    </div>

                    <div className={styles.infoColumn}>
                        <span className={styles.infoColumnLabel}>Total views</span>
                        <span className={styles.infoColumnValue}>
                            {stats ? formatNumberToKNotation(stats.totalViews) : '-'}
                        </span>
                    </div>

                    <div className={styles.infoColumn}>
                        <span className={styles.infoColumnLabel}>Engagement Rate</span>
                        <span className={styles.infoColumnValue}>
                            {stats ? `${roundTo2Dp(stats.engagementRate * 100)}%` : '-'}
                        </span>
                    </div>
                </div>

                <div className={styles.actionsRow}>
                    <IconButton onClick={() => onExpand?.()}>
                        <ArrowIcon className={cn(styles.expandIcon, { [styles.isExpanded]: isExpanded })} />
                    </IconButton>
                </div>
            </div>

            {isExpanded && children}
        </div>
    );
};

export default Campaign;
