import AudioPlaybackThumbnail from 'Modules/AudioPlayer/AudioPlaybackThumbnail/AudioPlaybackThumbnail';
import styles from './AudioBadge.module.css';
import cn from 'classnames';
import { SkeletonizedValue } from '@round/ui-kit';

export type AudioData = {
    title: string;
} & Partial<{ subTitle: string; imageUrl: string; audioPlayUrl: string; platformAudioUrl: string }>;

type Props = {
    audio: AudioData | null | undefined;
    isLoading?: boolean;
    className?: string;
};

const AudioBadge = ({ audio, isLoading, className }: Props) => {
    return (
        <div className={cn(styles.container, className)}>
            <AudioPlaybackThumbnail
                className={styles.thumbnail}
                loading={isLoading}
                audioUrl={audio?.audioPlayUrl}
                imageUrl={audio?.imageUrl}
            />

            <div className={styles.info}>
                <SkeletonizedValue isInitialized={!isLoading} width="10rem">
                    <a
                        className={styles.title}
                        onClick={(e) => e.stopPropagation()}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={audio?.platformAudioUrl}
                    >
                        {audio?.title}
                    </a>
                </SkeletonizedValue>

                <SkeletonizedValue isInitialized={!isLoading} width="7rem">
                    <span className={styles.subTitle}>{audio?.subTitle}</span>
                </SkeletonizedValue>
            </div>
        </div>
    );
};

export default AudioBadge;
