import { InputProps, components, Props as SelectProps } from 'react-select';

type Props = InputProps & {
    selectProps: SelectProps;
};

const StaticTextValue = (props: Props) => {
    return (
        <>
            {!props.selectProps.menuIsOpen && <span>Select team members</span>}
            <components.Input {...props} />
        </>
    );
};

export default StaticTextValue;
