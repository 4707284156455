import Button from 'ui-new/whitelabel/Button/Button';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import styles from './CreateReportModal.module.css';
import ReportForm, { FormValues, validationSchema } from '../components/ReportForm/ReportForm';
import { FormikProvider, useFormik } from 'formik';
import { creatorbase } from '@round/api';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import { showNotification } from 'helpers';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    createReport: typeof creatorbase.postReport;
    onCreated: (report: creatorbase.Report) => void;
};

const CreateReportModal = ({ isOpen, onClose, onCreated }: Props) => {
    const formik = useFormik<FormValues>({
        initialValues: {
            name: '',
            description: '',
            team: null,
            currency: null,
            assignees: [],
        },
        onSubmit: async (values, helpers) => {
            try {
                helpers.setSubmitting(true);
                const response = await creatorbase.postReport({
                    name: values.name,
                    description: values.description,
                    team_id: values.team?.value!,
                    currency_id: values.currency?.value!,
                    assignee_ids: values.assignees?.map((a) => a.value) ?? [],
                    //Not part of form
                    campaign_ids: [],
                    project_ids: [],
                });

                if (response.status === 201) {
                    showNotification('Report created', 'info');
                    helpers.resetForm();
                    onCreated(response.data);
                    onClose();
                    return;
                }

                if (response.status === 400) {
                    helpers.setErrors(mapApiErrorsToFormikErrors(response.data));
                    return;
                }

                showNotification('Could not create report', 'error');
            } catch {
                showNotification('Could not create report', 'error');
            }
        },
        validationSchema: validationSchema,
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnEscape closeOnOverlayClick className={styles.modal}>
            <Modal.Header>
                <Modal.Title>Create a Report</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FormikProvider value={formik}>
                    <ReportForm />
                </FormikProvider>
            </Modal.Body>

            <Modal.Actions>
                <Button
                    appearance="primary"
                    isLoading={formik.isSubmitting}
                    disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                    onClick={formik.submitForm}
                >
                    Create
                </Button>

                <Button onClick={onClose} appearance="ghost">
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateReportModal;
