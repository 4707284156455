import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Skeleton } from '@round/ui-kit';
import { formatNumberToKNotation } from '../../../../../../../helpers';
import WrapperPaginationTable, {
    WrapperPaginationTableProps,
} from 'ui/WrapperTable/WrapperPaginationTable/WrapperPaginationTable';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { PastPaymentsTableCellContext, PastPaymentsTableRow } from '../../helpers';
import DateCell from '../../../TableCells/DateCell/DateCell';
import PaypalTransactionIdCell from '../../../TableCells/PaypalTransactionIdCell';
import PaypalTransactionStatusCell from '../../../TableCells/PaypalTransactionStatus/PaypalTransactionStatusCell';
import PaymentStatusCell from '../../../TableCells/PaymentStatus/PaymentStatusCell';
import AccountCell from '../../../TableCells/AccountCell/AccountCell';
import CostLabel from 'ui/DataDisplay/Money/CostLabel/CostLabel';
import ReferenceCell from '../../../TableCells/ReferenceCell/ReferenceCell';
import ReleaseCell from '../../../TableCells/ReleaseCell/ReleaseCell';
import PlatformCell from '../../../TableCells/PlatformCell/PlatformCell';

type Props = Omit<WrapperPaginationTableProps<PastPaymentsTableRow>, 'columns' | 'columnPinning'>;

const PastVenmoPaymentsTable = ({ ...props }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);

    const columns: ColumnDef<PastPaymentsTableRow, any>[] = useMemo(
        () => [
            {
                header: 'Account',
                accessorKey: 'influencer',
                cell: ({ getValue, row: { original } }: PastPaymentsTableCellContext<'influencer'>) => (
                    <AccountCell
                        influencer={getValue() || null}
                        imageUrl={original.userImageUrl}
                        postUrl={original.post_url}
                        platform={original.platform}
                        isLoading={props.isLoading}
                    />
                ),
            },
            {
                header: 'Platform',
                accessorKey: 'platform',
                cell: ({ getValue }: PastPaymentsTableCellContext<'platform'>) => (
                    <PlatformCell platform={getValue()} isLoading={props.isLoading} />
                ),
            },
            {
                header: 'Payment date',
                accessorKey: 'paid_at',
                cell: ({ getValue }: PastPaymentsTableCellContext<'paid_at'>) => (
                    <DateCell value={getValue()} isLoading={props.isLoading} />
                ),
            },
            {
                header: 'Release',
                accessorKey: 'release',
                cell: ({ getValue }: PastPaymentsTableCellContext<'release'>) => (
                    <ReleaseCell release={getValue()} isLoading={props.isLoading} />
                ),
            },
            {
                header: 'Cost',
                accessorKey: 'cost',
                cell: ({ getValue, row: { original } }: PastPaymentsTableCellContext<'cost'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <CostLabel
                            cost={getValue()}
                            currencySymbol={currencies.find((curr) => curr.id === original.currency_id)?.symbol ?? ''}
                        />
                    );
                },
            },
            {
                header: 'Post views',
                accessorKey: 'view_count',
                cell: ({ getValue }: PastPaymentsTableCellContext<'view_count'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const viewCount = getValue();

                    return <>{viewCount ? formatNumberToKNotation(viewCount) : '-'}</>;
                },
            },
            {
                header: 'Reference',
                accessorKey: 'payment_reference',
                cell: ({ getValue }: PastPaymentsTableCellContext<'payment_reference'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return <ReferenceCell reference={getValue()} isReadonly />;
                },
            },
            {
                header: 'Venmo email',
                accessorKey: 'venmo_email_address',
                cell: ({ getValue }: PastPaymentsTableCellContext<'venmo_email_address'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const venmoEmailAddress = getValue();

                    if (!venmoEmailAddress) {
                        return <>-</>;
                    }

                    return <>{venmoEmailAddress}</>;
                },
            },
            {
                header: 'Paypal transaction ID',
                accessorKey: 'paypal_transaction_id',
                cell: ({ getValue }: PastPaymentsTableCellContext<'paypal_transaction_id'>) => (
                    <PaypalTransactionIdCell value={getValue()} isLoading={props.isLoading} />
                ),
            },
            {
                header: 'Paypal transaction status',
                accessorKey: 'paypal_transaction_status',
                cell: ({ getValue }: PastPaymentsTableCellContext<'paypal_transaction_status'>) => (
                    <PaypalTransactionStatusCell status={getValue()} isLoading={props.isLoading} />
                ),
            },
            {
                header: 'Status',
                id: 'payment_status',
                accessorKey: 'payment_status',
                cell: ({ getValue }: PastPaymentsTableCellContext<'payment_status'>) => (
                    <PaymentStatusCell status={getValue()} isLoading={props.isLoading} />
                ),
            },
        ],
        [props.isLoading, currencies]
    );

    return <WrapperPaginationTable {...props} columns={columns} columnPinning={{ right: ['payment_status'] }} />;
};

export default PastVenmoPaymentsTable;
