import { components, OptionProps } from 'react-select';
import styles from 'ui-new/Select/Select.module.css';
import { ReactComponent as TickCircleIcon } from 'assets/icons/TickCircle.svg';
import Checkbox from 'ui/DataEntry/Checkbox/Checkbox';
import cn from 'classnames';

const Option = (props: OptionProps<any, any>) => (
    <components.Option {...props}>
        <div className={styles.container}>
            {props.isMulti && <Checkbox value={props.isSelected} />}
            <div className={styles.content}>{props.children}</div>
            {!props.isMulti && <TickCircleIcon className={cn(styles.tick, { [styles.visible]: props.isSelected })} />}
        </div>
    </components.Option>
);

export default Option;
