import { creatorbase } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

export type AudioTimeSeriesData = DataState<creatorbase.TimeSeriesResponse<'posts_daily_change'>[number]>;

export type AudioTimeSeriesContextData = {
    [id: number]: AudioTimeSeriesData;
};

export type AudioTimeSeriesContextState = {
    isInitialized: boolean;
    data: AudioTimeSeriesContextData;
};

export type AudioTimeSeriesContextActions =
    | ReducerActionWithPayload<'audioTimeSeriesDataLoading', { ids: number[] }>
    | ReducerActionWithPayload<
          'audioTimeSeriesDataSuccess',
          { ids: number[]; data: creatorbase.TimeSeriesResponse<'posts_daily_change'> }
      >
    | ReducerActionWithPayload<'audioTimeSeriesDataError', { ids: number[]; error: string }>
    | ReducerActionWithPayload<'audioTimeSeriesDataIdle', { ids: number[] }>
    | ReducerAction<'audioTimeSeriesDataInitialized'>;

export default createReducer<AudioTimeSeriesContextState, AudioTimeSeriesContextActions>({
    audioTimeSeriesDataLoading: (state, { payload: { ids } }) => {
        const incomingAudioTimeSeriesDataAsLoading = ids.reduce((acc, id) => {
            acc[id] = { status: 'loading', data: null, error: null };
            return acc;
        }, {} as AudioTimeSeriesContextData);

        return { ...state, data: { ...state.data, ...incomingAudioTimeSeriesDataAsLoading } };
    },
    audioTimeSeriesDataSuccess: (state, { payload: { ids, data } }) => {
        const incomingAudioTimeSeriesDataAsSuccess = ids.reduce((acc, id) => {
            acc[id] = {
                status: 'success',
                data: data[id],
                error: null,
            };
            return acc;
        }, {} as AudioTimeSeriesContextData);

        return { ...state, data: { ...state.data, ...incomingAudioTimeSeriesDataAsSuccess } };
    },
    audioTimeSeriesDataError: (state, { payload: { ids, error } }) => {
        const incomingAudioTimeSeriesDataAsError = ids.reduce((acc, id) => {
            acc[id] = { status: 'error', data: null, error };
            return acc;
        }, {} as AudioTimeSeriesContextData);

        return { ...state, data: { ...state.data, ...incomingAudioTimeSeriesDataAsError } };
    },
    audioTimeSeriesDataIdle: (state, { payload: { ids } }) => {
        const incomingAudioTimeSeriesDataAsIdle = ids.reduce((acc, id) => {
            acc[id] = { status: 'idle', data: null, error: null };
            return acc;
        }, {} as AudioTimeSeriesContextData);

        return { ...state, data: { ...state.data, ...incomingAudioTimeSeriesDataAsIdle } };
    },
    audioTimeSeriesDataInitialized: (state) => ({ ...state, isInitialized: true }),
});
