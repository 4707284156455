import { creatorbase } from '@round/api';
import useReportData from '../../ReportData/useReportData';
import useCampaigns from '../useCampaigns';
import { useAbortSignal } from '@round/utils';

export function useReorderReportCampaigns(platform: creatorbase.Platform) {
    const { updateReport } = useReportData();
    const { reorderCampaigns } = useCampaigns(platform);

    const { getSignal, resetSignal } = useAbortSignal();

    return async (newOrder: number[], prevOrder: number[]) => {
        try {
            resetSignal();

            reorderCampaigns(newOrder);

            const response = await updateReport(
                {
                    [`${platform}_campaign_ids_order`]: newOrder,
                },
                { signal: getSignal() }
            );

            if (response?.status !== 200) {
                reorderCampaigns(prevOrder);
            }

            return response;
        } catch (e) {
            if (e instanceof Error && e.name !== 'AbortError') {
                reorderCampaigns(prevOrder);
            }

            throw e;
        }
    };
}
