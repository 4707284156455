import React from 'react';
import styles from './EditableContentTags.module.css';
import cn from 'classnames';
import { ReactComponent as CloseIcon } from '../../../../assets/Close.svg';
import { StylesConfig } from 'react-select';
import { useContentTags } from 'contexts/ContentTagsContext';
import Badge from 'ui/Badge/Badge';
import { mapContentTagsToOptions } from 'helpers';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import Select from 'ui/DataEntry/Select/Select';

type Props = {
    tagIds: number[];
    readonly?: boolean;
    onChange: (tagIds: number[]) => void;
};

export const ContentTagSelectStyles: StylesConfig = {
    valueContainer: (base) => ({
        ...base,
        padding: '0.125rem 0.3125rem',
        width: '6.25rem',
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: '0.625rem',
    }),
    dropdownIndicator: () => ({ display: 'none' }),
    menu: (base) => ({
        ...base,
        minWidth: '12.5rem',
    }),
};

const EditableContentTags = ({ tagIds, readonly, onChange }: Props) => {
    const { tags: allContentTags, init, isInitialized } = useContentTags();
    const options = mapContentTagsToOptions(allContentTags.filter((t) => !tagIds.includes(t.id)));

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                init({ signal });
            }
        },
        [isInitialized, init]
    );

    return (
        <>
            <div className={styles.container}>
                {tagIds.map((id) => {
                    const contentTag = allContentTags.find((t) => t.id === id);
                    if (!contentTag) {
                        return null;
                    }
                    return (
                        <Badge
                            key={contentTag.name}
                            label={contentTag.name}
                            className={cn(styles.tag)}
                            icon={() =>
                                !readonly ? (
                                    <button
                                        className={styles.deleteButton}
                                        onClick={() => onChange(tagIds.filter((i) => i !== contentTag.id))}
                                    >
                                        <CloseIcon className={styles.deleteIcon} />
                                    </button>
                                ) : null
                            }
                        />
                    );
                })}
            </div>
            {!readonly && (
                <Select
                    styles={ContentTagSelectStyles}
                    placeholder={`Add Content Tag`}
                    value={null}
                    options={options}
                    onChange={(option) => {
                        if (!option) {
                            return;
                        }
                        onChange([...tagIds, option.value]);
                    }}
                />
            )}
        </>
    );
};

export default EditableContentTags;
