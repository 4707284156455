import { GenericDropdownOption } from '../../../../App.types';
import { microwave } from '@round/api';

export const microwaveCampaignStatusOptions: GenericDropdownOption<microwave.CampaignStatus>[] = [
    {
        value: 'Live',
        label: 'Live',
    },
    {
        value: 'Planned',
        label: 'Planned',
    },
    {
        value: 'Completed',
        label: 'Completed',
    },
];
