import React from 'react';
import { Form, Field } from 'formik';
import FormField from '../../../../../../ui/DataEntry/FormField/FormField';
import Label from '../../../../../../ui/DataEntry/Label/Label';
import TextArea from '../../../../../../SharedComponents/Forms/TextArea/TextArea';

const CreativeForm = () => (
    <Form>
        <FormField>
            <Label htmlFor="name">Name</Label>
            <Field name="name" id="name" placeholder="Enter name" />
        </FormField>
        <FormField>
            <Label htmlFor="creative_url">Creative</Label>
            <Field name="creative_url" id="creative_url" placeholder="Enter creative" />
        </FormField>
        <FormField>
            <Label htmlFor="copy">Copy</Label>
            <Field name="copy" id="copy" placeholder="Enter copy" />
        </FormField>
        <FormField>
            <Label htmlFor="headline">Headline</Label>
            <Field name="headline" id="headline" placeholder="Enter headline" />
        </FormField>
        <FormField>
            <Label htmlFor="description">Description</Label>
            <Field name="description" id="description" placeholder="Enter description" />
        </FormField>
        <FormField>
            <Label htmlFor="cta_text">CTA</Label>
            <Field name="cta_text" id="cta_text" placeholder="Enter CTA" />
        </FormField>
        <FormField>
            <Label htmlFor="ad_url">URL</Label>
            <Field name="ad_url" id="ad_url" placeholder="Enter URL" />
        </FormField>
        <FormField>
            <Label htmlFor="notes">Notes</Label>
            <TextArea name="notes" placeholder="Write notes" />
        </FormField>
    </Form>
);

export default CreativeForm;
