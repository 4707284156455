import { TiktokUserImage, TiktokUser, TiktokUserPostStats } from './tiktokUser.types';
import { call, encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ForbiddenResponse,
    NotFoundResponse,
    OrderingValues,
    PaginatedApiResponseData,
    PaginatedFetchParams,
    PaginatedRequest,
} from '../../types';
import { isPublicReportRequestInit } from '../../influencer';

export async function getTiktokUser(id: number, requestInit?: RequestInit): Promise<TiktokUser> {
    const response = await fetchWithToken(`/api/tiktok/viewsets/users/${id}/`, requestInit);
    if (!response.ok) {
        throw new Error(`Could not get tiktok user: ${id}`);
    }

    return response.json();
}

export type GetTiktokUsersParams = Partial<PaginatedFetchParams & { id: string; search: string }>;
export async function getTiktokUsers(
    params: GetTiktokUsersParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<TiktokUser>, 200> | NotFoundResponse | ForbiddenResponse> {
    const response = await call(`/api/tiktok/viewsets/users/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens: isPublicReportRequestInit(requestInit),
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not fetch tiktok users');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getTiktokUserImages(ids: number[], requestInit?: RequestInit): Promise<TiktokUserImage[]> {
    const response = await call(
        `/api/tiktok/user-image/${encodeUrlSearchParams({
            user_id: ids.join(','),
        })}`,
        requestInit,
        {
            shouldBypassTokens: isPublicReportRequestInit(requestInit),
        }
    );

    if (!response.ok) {
        throw new Error('Could not fetch tiktok user images');
    }

    return response.json();
}

export type GetTiktokUserPostStatsOrderingKeys = keyof Pick<TiktokUserPostStats, 'create_time' | 'play_count'>;
export type GetTiktokUserPostStatsParams = { user_id: number } & Partial<
    PaginatedRequest & {
        ordering: OrderingValues<GetTiktokUserPostStatsOrderingKeys>;
    }
>;

type GetTiktokUserPostStatsResponse = ApiResponse<PaginatedApiResponseData<TiktokUserPostStats>, 200>;

export async function getTiktokUserPostStats(
    { user_id, ...params }: GetTiktokUserPostStatsParams,
    requestInit?: RequestInit
): Promise<GetTiktokUserPostStatsResponse> {
    const response = await fetchWithToken(
        `/api/tiktok/users/${user_id}/post-stats/${encodeUrlSearchParams({ ...params })}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not get tiktok post stats');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetAllTiktokUserPostStatsParams = Pick<GetTiktokUserPostStatsParams, 'page_size' | 'user_id'>;

export async function getAllTiktokUserPostStats(
    { user_id, ...params }: GetAllTiktokUserPostStatsParams,
    requestInit?: RequestInit
): Promise<TiktokUserPostStats[]> {
    return await fetchAll(
        `/api/tiktok/users/${user_id}/post-stats/${encodeUrlSearchParams({ ...params })}`,
        requestInit
    );
}
