import { influencer } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

export type State = {
    [campaignId: number]: DataState<influencer.AggregatedPostStats>;
};

export const initialState: State = {};

export type Actions =
    | ReducerActionWithPayload<'loadInfluencerAggregatedPostStatsByCampaignId', number[]>
    | ReducerActionWithPayload<
          'errorLoadingInfluencerAggregatedPostStatsByCampaignId',
          { campaignId: number; error: string }
      >
    | ReducerActionWithPayload<
          'influencerAggregatedPostStatsByCampaignIdLoaded',
          { campaignId: number; stats: influencer.AggregatedPostStats }
      >;

export const reducer = createReducer<State, Actions>({
    loadInfluencerAggregatedPostStatsByCampaignId: (state, { payload: campaignIds }) => {
        const loadingCampaignStats = campaignIds.reduce((acc, campaignId) => {
            acc[campaignId] = { status: 'loading', error: null, data: null };
            return acc;
        }, {} as State);

        return { ...state, ...loadingCampaignStats };
    },
    errorLoadingInfluencerAggregatedPostStatsByCampaignId: (state, { payload: { campaignId, error } }) => ({
        ...state,
        [campaignId]: {
            ...state[campaignId],
            error,
            status: 'error',
        },
    }),
    influencerAggregatedPostStatsByCampaignIdLoaded: (state, { payload: { campaignId, stats } }) => ({
        ...state,
        [campaignId]: {
            data: stats,
            status: 'success',
            error: null,
        },
    }),
});
