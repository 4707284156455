import { fetchWithToken } from '../../helpers';
import { ApiResponse, ApiResponseError, NotFoundResponse } from '../../types';
import { InstagramInfluencerUser, InstagramInfluencerUserApiBody } from './instagramInfluencerUser.types';

type GetInstagramInfluencerUserResponse = ApiResponse<InstagramInfluencerUser, 200> | NotFoundResponse;

export async function getInstagramInfluencerUser(
    influencerId: number,
    requestInit?: RequestInit
): Promise<GetInstagramInfluencerUserResponse> {
    const response = await fetchWithToken(`/api/instagram/viewsets/influencer-user/${influencerId}/`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get influencer user');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PatchInstagramInfluencerUserResponse =
    | ApiResponse<InstagramInfluencerUser, 200>
    | ApiResponse<ApiResponseError<InstagramInfluencerUserApiBody>, 400>;

type PostInstagramInfluencerUserResponse =
    | ApiResponse<InstagramInfluencerUser, 201>
    | ApiResponse<ApiResponseError<InstagramInfluencerUserApiBody>, 400>;

export async function patchInstagramInfluencerUser(
    influencerId: number,
    data: Partial<InstagramInfluencerUserApiBody>
): Promise<PatchInstagramInfluencerUserResponse> {
    const response = await fetchWithToken(`/api/instagram/viewsets/influencer-user/${influencerId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...data,
        }),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not update influencer user');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function postInstagramInfluencerUser(
    data: InstagramInfluencerUserApiBody
): Promise<PostInstagramInfluencerUserResponse> {
    const response = await fetchWithToken(`/api/instagram/viewsets/influencer-user/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...data,
        }),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create influencer user');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}
