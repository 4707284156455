import useUrlState from '../../../../Hooks/useUrlState';
import styles from './RoundClientUsers.module.css';
import { Button, ConfirmationModal, SearchInput, Select } from '@round/ui-kit';
import { useRoundClientUsers } from './useRoundClientUsers';
import RoundClientUserTable, { RoundClientUserTableRow } from './components/RoundClientUserTable/RoundClientUserTable';
import { useCallback, useMemo, useState } from 'react';
import EditRoundClientUserModal from './components/RoundClientUserModal/EditRoundClientUserModal';
import CreateRoundClientUserModal from './components/RoundClientUserModal/CreateRoundClientUserModal';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import { patchRoundClientUser, getClients } from '@round/api';
import { showNotification } from '../../../../helpers';
import { useSelectPaginatedOptions } from '../../../../Hooks/useSelectPaginatedOptions';
import { GenericDropdownOption } from '../../../../App.types';
import { StylesConfig, ValueType } from 'react-select';

type UrlState = {
    page: number;
    page_size: number;
    search: string;
    client_ids?: string;
};

const selectStyles: StylesConfig = {
    control: (provided) => ({
        ...provided,
        minWidth: '12rem',
    }),
};

const RoundClientUsers = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>(
        { page: 1, page_size: 10, search: '' },
        { shouldSetMissingInitialValues: true }
    );

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<RoundClientUserTableRow | null>(null);

    const [selectedClientOptions, setSelectedClientOptions] = useState<ValueType<GenericDropdownOption<number>, true>>(
        []
    );
    const handleSetSelectedClientOptions = (options: ValueType<GenericDropdownOption<number>, true>) => {
        setSelectedClientOptions(options);
        setUrlState({
            client_ids: options?.map((option) => option.value).join(','),
        });
        reset();
    };

    const [isSelectedClientsInitialized, setIsSelectedClientsInitialized] = useState(false);

    const [isDeactivateLoading, setIsDeactivateLoading] = useState(false);

    const page = Number(urlState.page);
    const setPage = (page: number) => {
        setUrlState({ page });
        reset();
    };

    const pageSize = Number(urlState.page_size);
    const setPageSize = (page_size: number) => {
        setUrlState({ page_size });
        reset();
    };

    const search = urlState.search;
    const setSearch = (search: string) => {
        setUrlState({ search, page: 1 });
        reset();
    };

    const {
        roundClientUsers,
        clients,
        isLoading,
        isInitialized,
        count,
        hasError,
        setClientUsers,
        removeClientUser,
        reset,
        init,
    } = useRoundClientUsers({
        page,
        page_size: pageSize,
        search,
        client_id: urlState.client_ids,
    });

    const { props: clientSelectProps } = useSelectPaginatedOptions({
        fetchOptions: getClients,
        mapToOption: (client) => ({
            label: client.name,
            value: client.id,
        }),
    });

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                init({ signal });
            }
        },
        [isInitialized, init]
    );

    const rows = useMemo(() => {
        return roundClientUsers.map((roundClientUser) => ({
            ...roundClientUser,
            clients: clients.filter((client) => roundClientUser.client_ids.includes(client.id)),
        }));
    }, [roundClientUsers, clients]);

    const noDataLabel = hasError ? 'Error loading users' : 'No users found';

    const handleCloseEditModal = useCallback(() => {
        setIsEditModalOpen(false);
        setSelectedRow(null);
    }, []);

    const handleCloseCreateModal = useCallback(() => {
        setIsCreateModalOpen(false);
    }, []);

    const handleDeactivateUser = useCallback(
        async (row: RoundClientUserTableRow | null) => {
            if (!row) {
                return;
            }
            try {
                setIsDeactivateLoading(true);
                const response = await patchRoundClientUser(row.id, { is_active: false });

                if (response.status !== 200) {
                    showNotification(`Couldn't deactivate user`, 'error');
                    return;
                }

                showNotification('User deactivated', 'info');
                removeClientUser(row.id);
                setIsConfirmationModalOpen(false);
            } catch {
                showNotification(`Couldn't deactivate user`, 'error');
            } finally {
                setIsDeactivateLoading(false);
            }
        },
        [removeClientUser]
    );

    useAbortableEffect(() => {
        function initSelectedClientOptions() {
            const urlStateClientIds = urlState.client_ids?.split(',').map((id) => Number(id)) ?? [];
            const selectedClientIds = selectedClientOptions?.map((option) => option.value);

            const clientsToFetch = urlStateClientIds.filter((id) => !selectedClientIds?.includes(id));

            if (!clientsToFetch.length) {
                setIsSelectedClientsInitialized(true);
                return;
            }

            getClients({ id: clientsToFetch.join(','), page_size: clientsToFetch.length })
                .then((response) => {
                    setSelectedClientOptions(
                        response.data.results.map((client) => ({
                            label: client.name,
                            value: client.id,
                        }))
                    );
                    setIsSelectedClientsInitialized(true);
                })
                .catch((e) => {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    showNotification('Could not initialize location filter', 'error');
                });
        }

        if (!isSelectedClientsInitialized) {
            initSelectedClientOptions();
        }
    }, [urlState.client_ids, selectedClientOptions, isSelectedClientsInitialized]);

    return (
        <>
            <div className={styles.headerContainer}>
                <div className={styles.filters}>
                    <SearchInput
                        placeholder="Search client users..."
                        value={search}
                        onChange={setSearch}
                        className={styles.searchBar}
                    />

                    <div className={styles.filter}>
                        <label htmlFor="clients">Clients</label>
                        <Select
                            id="clients"
                            value={selectedClientOptions}
                            onChange={handleSetSelectedClientOptions}
                            isMulti
                            styles={selectStyles}
                            placeholder="Select clients..."
                            {...clientSelectProps}
                        />
                    </div>
                </div>

                <Button onClick={() => setIsCreateModalOpen(true)} color="black" type="filled">
                    Create User
                </Button>
            </div>
            <RoundClientUserTable
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={(pageSize) => setPageSize(pageSize || 10)}
                count={count}
                data={rows}
                loading={isLoading || (!isInitialized && !hasError)}
                noDataLabel={noDataLabel}
                onRowClick={(row) => {
                    setSelectedRow(row);
                    setIsEditModalOpen(true);
                }}
                onClickDeactivateUser={(row) => {
                    setSelectedRow(row);
                    setIsConfirmationModalOpen(true);
                }}
            />
            <EditRoundClientUserModal
                isOpen={isEditModalOpen}
                onUpdate={setClientUsers}
                closeModal={handleCloseEditModal}
                clientUserTableRow={selectedRow}
            />
            <CreateRoundClientUserModal
                isOpen={isCreateModalOpen}
                closeModal={handleCloseCreateModal}
                onCreated={reset}
            />

            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                modalTitle="Deactivate User"
                modalText="Are you sure you want to deactivate this user?"
                confirmButtonText="Deactivate"
                handleConfirm={() => handleDeactivateUser(selectedRow)}
                confirmActionLoading={isDeactivateLoading}
                onClose={() => setIsConfirmationModalOpen(false)}
            />
        </>
    );
};

export default RoundClientUsers;
