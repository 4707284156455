import { encodeQueryString, fetchAll, fetchWithToken } from '../../../../helpers';
import { FacebookCampaignCreatePostBody, FacebookTargeting } from '../../../../App.types';
import {
    CreateFacebookCampaignError,
    CustomAudienceRefreshRequest,
    FacebookCustomAudience,
    FacebookTargetingDto,
    FacebookTargetingTemplate,
} from '../types/Facebook.types';
import { TargetingTemplateSimplified } from '../types/MediaPlanTargeting.types';

type GetFacebookTargetingParams = {
    mediaPlanId?: number;
    mediaPlanItemIds?: number[];
};

export async function getFacebookTargetings(
    params: GetFacebookTargetingParams,
    requestInit?: RequestInit
): Promise<FacebookTargeting[]> {
    const queryParams: Record<string, string | number> = {};

    if (params.mediaPlanId) {
        queryParams['media_plan_item__media_plan_id'] = params.mediaPlanId;
    }

    if (params.mediaPlanItemIds) {
        queryParams['media_plan_item_id'] = params.mediaPlanItemIds.join();
    }

    const queryString = encodeQueryString(queryParams);
    return (await fetchAll(`/api/facebook/viewsets/facebook-campaign-targeting/${queryString}`, requestInit)) ?? [];
}

type PostFacebookTargetingBody = Partial<Omit<FacebookTargetingDto, 'media_plan_item'>> & {
    template?: number;
    media_plan_item: number;
};
export async function createFacebookTargeting(targeting: PostFacebookTargetingBody): Promise<FacebookTargeting> {
    const response = await fetchWithToken(`/api/facebook/viewsets/facebook-campaign-targeting/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(targeting),
    });

    if (!response.ok) {
        throw new Error('Could not create facebook targeting');
    }

    return response.json();
}

type PostFacebookTargetingTemplateBody = Pick<FacebookTargetingTemplate, 'client'> &
    Partial<Omit<FacebookTargetingTemplate, 'client'>>;
export async function createFacebookTargetingTemplate(
    template: PostFacebookTargetingTemplateBody
): Promise<FacebookTargetingTemplate> {
    const response = await fetchWithToken(`/api/facebook/viewsets/facebook-campaign-targeting-template/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(template),
    });

    if (!response.ok) {
        throw new Error(`Could not create facebook targeting template for client with id ${template.client}`);
    }

    return response.json();
}

export async function getFacebookTargetingTemplates(
    clientId: number,
    simplified?: false
): Promise<FacebookTargetingTemplate[]>;
export async function getFacebookTargetingTemplates(
    clientId: number,
    simplified: true
): Promise<TargetingTemplateSimplified[]>;
export async function getFacebookTargetingTemplates(
    clientId: number,
    simplified: boolean = false
): Promise<(FacebookTargetingTemplate | TargetingTemplateSimplified)[]> {
    const simplifiedParam = simplified ? `&simplified=${simplified}` : '';
    return (
        (await fetchAll<FacebookTargetingTemplate | TargetingTemplateSimplified>(
            `/api/facebook/viewsets/facebook-campaign-targeting-template?client_id=${clientId}${simplifiedParam}`
        )) ?? []
    );
}

export async function deleteFacebookTargetingTemplate(templateId: number): Promise<void> {
    const response = await fetchWithToken(
        `/api/facebook/viewsets/facebook-campaign-targeting-template/${templateId}/`,
        {
            method: 'DELETE',
        }
    );

    if (!response.ok) {
        throw new Error(`Could not delete targeting template with id ${templateId}`);
    }
}

export async function getCustomAudiences(
    clientId: number,
    requestInit?: RequestInit
): Promise<FacebookCustomAudience[]> {
    return (
        (await fetchAll(`/api/facebook/viewsets/facebook-custom-audience/?client_id=${clientId}`, requestInit)) ?? []
    );
}

export async function requestCustomAudiencesRefresh(clientId: number) {
    const response = await fetchWithToken(`/api/facebook/refresh-custom-audience/${clientId}/`);
    if (!response.ok) {
        throw new Error("Couldn't refresh custom audience");
    }
}

type GetCustomAudienceRefreshRequestParams = {
    clientId: number;
    latest?: boolean;
};
export async function getCustomAudienceRefreshRequests(
    { clientId, latest = true }: GetCustomAudienceRefreshRequestParams,
    requestInit?: RequestInit
): Promise<CustomAudienceRefreshRequest[]> {
    const latestQueryParam = latest ? `&latest=true` : '';
    return (
        (await fetchAll(
            `/api/facebook/custom-audience-refresh-request/?client_id=${clientId}${latestQueryParam}`,
            requestInit
        )) ?? []
    );
}

export type FacebookCampaignCreateResult = {
    id: number;
    campaign_id: string;
    media_plan_item_id: number;
};

export async function fetchFacebookCampaignCreate(
    body: FacebookCampaignCreatePostBody
): Promise<FacebookCampaignCreateResult | CreateFacebookCampaignError | never> {
    const response = await fetchWithToken(`/api/facebook/campaign-create/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (response.status >= 500) {
        throw new Error('Create Facebook campaign was not successful');
    }

    return response.json();
}
