import { useCallback, useMemo, useState } from 'react';
import { getTiktokUser, getTiktokUsers, GetTiktokUsersParams, TiktokUser } from '@round/api';
import { GenericDropdownOption } from 'App.types';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { OptionsType, ValueType } from 'react-select';
import { showNotification } from 'helpers';
import { SelectProps } from '@round/ui-kit';
import debounce from 'lodash/debounce';

export type TiktokUserOption = GenericDropdownOption<number> & Pick<TiktokUser, 'unique_id' | 'avatar_thumb'>;

export const mapTiktokUserToOption = (user: TiktokUser): TiktokUserOption => ({
    value: user.id,
    label: user.nickname,
    avatar_thumb: user.avatar_thumb,
    unique_id: user.unique_id,
});

type Return = {
    props: Pick<
        SelectProps<TiktokUserOption>,
        | 'filterOption'
        | 'onMenuOpen'
        | 'onMenuClose'
        | 'onMenuScrollToBottom'
        | 'inputValue'
        | 'onInputChange'
        | 'isLoading'
        | 'value'
        | 'onChange'
        | 'isMulti'
        | 'noOptionsMessage'
    > & {
        options: OptionsType<TiktokUserOption>;
    };
    resetValue: () => void;
};

const defaultPageSize = 25;

export default function useTiktokUserSelect(
    initialUserId?: number | undefined,
    params: {
        initOptionsOnMenuOpen?: boolean;
    } = {}
): Return {
    const [value, setValue] = useState<ValueType<TiktokUserOption, false>>(null);
    const [isValueInitialized, setIsValueInitialized] = useState(false);

    useAbortableEffect(
        (signal) => {
            if (isValueInitialized || typeof initialUserId !== 'number') {
                return;
            }

            getTiktokUser(initialUserId, { signal })
                .then((response) => {
                    setValue(mapTiktokUserToOption(response));
                    setIsValueInitialized(true);
                })
                .catch((e) => {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    setIsValueInitialized(true);
                    showNotification('Could not load initial tiktok user', 'error');
                });
        },
        [isValueInitialized, initialUserId]
    );

    const [users, setUsers] = useState<TiktokUser[]>([]);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [search, setSearch] = useState('');
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const fetchUsers = useCallback(
        async (params: Omit<GetTiktokUsersParams, 'search'>, requestInit?: RequestInit) => {
            try {
                setIsLoading(true);
                const response = await getTiktokUsers({ ...params, search }, requestInit);
                if (response.status !== 200) {
                    throw new Error('Could not fetch tiktok user options');
                }

                setUsers((prev) => prev.concat(response.data.results));
                setHasNextPage(Boolean(response.data.next));
                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setError('Could not fetch tiktok user options');
                setIsInitialized(true);
            } finally {
                setIsLoading(false);
                setIsSearchLoading(false);
            }
        },
        [search]
    );

    const debouncedInit = useMemo(() => debounce(fetchUsers, 700), [fetchUsers]);

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && (!params.initOptionsOnMenuOpen || isMenuOpen)) {
                debouncedInit({ page: 1, page_size: defaultPageSize }, { signal });

                return () => debouncedInit.cancel();
            }
        },
        [debouncedInit, isInitialized, isMenuOpen, params.initOptionsOnMenuOpen]
    );

    const loadNextPage = useCallback(async () => {
        fetchUsers({ page: page + 1, page_size: defaultPageSize }).then(() => setPage((page) => page + 1));
    }, [fetchUsers, page]);

    const reset = useCallback(() => {
        setUsers([]);
        setPage(1);
        setHasNextPage(false);
        setSearch('');
        setIsInitialized(false);
    }, []);

    const options: TiktokUserOption[] = useMemo(() => users.map(mapTiktokUserToOption), [users]);

    return {
        props: {
            isMulti: false,
            value,
            onChange: setValue,
            options,
            filterOption: null,
            inputValue: search,
            onInputChange: (value: string) => {
                if (value === search) {
                    return;
                }

                reset();
                setSearch(value);
                if (value) {
                    setIsSearchLoading(true);
                }
            },
            onMenuScrollToBottom: () => {
                if (!hasNextPage) {
                    return;
                }

                loadNextPage();
            },
            onMenuOpen: () => setIsMenuOpen(true),
            onMenuClose: () => {
                setIsMenuOpen(false);
            },
            isLoading: isLoading || isSearchLoading,
            noOptionsMessage: () => error ?? 'No options',
        },
        resetValue: () => {
            setValue(null);
            setIsValueInitialized(false);
        },
    };
}
