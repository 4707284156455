import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import styles from './HeaderSummary.module.css';
import cn from 'classnames';
import { SkeletonizedValue } from '@round/ui-kit';

type Props = {
    children?: ReactNode;
    className?: string;
};

const HeaderSummary = ({ children, className }: Props) => {
    return <dl className={cn(styles.container, className)}>{children}</dl>;
};

type HeaderSummaryItemProps = { children: ReactNode; className?: string };
const HeaderSummaryItem = ({ children, className }: HeaderSummaryItemProps) => {
    return <div className={cn(styles.item, className)}>{children}</div>;
};

type HeaderSummaryItemLabelProps = { children: ReactNode; className?: string };
const HeaderSummaryItemLabel = ({ children, className }: HeaderSummaryItemLabelProps) => {
    return <dt className={cn(styles.label, className)}>{children}</dt>;
};

type HeaderSummaryItemValueProps = { children: ReactNode; className?: string; isLoading?: boolean } & DetailedHTMLProps<
    HTMLAttributes<HTMLElement>,
    HTMLElement
>;
const HeaderSummaryItemValue = ({ children, className, isLoading, ...props }: HeaderSummaryItemValueProps) => {
    return (
        <dd className={cn(styles.value, className)} {...props}>
            <SkeletonizedValue isInitialized={!isLoading}>{children}</SkeletonizedValue>
        </dd>
    );
};

HeaderSummary.Item = HeaderSummaryItem;
HeaderSummary.Label = HeaderSummaryItemLabel;
HeaderSummary.Value = HeaderSummaryItemValue;

export default HeaderSummary;
