import React from 'react';
import cn from 'classnames';
import styles from './Spinner.module.css';

type SpinnerProps = {
    containerClassName?: string;
    spinnerClassName?: string;
};

const Spinner = ({ containerClassName, spinnerClassName }: SpinnerProps) => {
    return (
        <div className={cn(styles.container, containerClassName)}>
            <div className={cn(styles.loadingSpinner, spinnerClassName)} />
        </div>
    );
};

export default Spinner;
