import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import { ApiResponse, NotFoundResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import {
    TiktokInfluencerPlanStats,
    InfluencerUserCampaigns,
    InstagramInfluencerPlanStats,
    InfluencerPlan,
    YoutubeInfluencerPlanStats,
} from './influencerPlan.types';

export type GetInfluencerPlansParams = Partial<
    PaginatedRequest & {
        search: string;
        release_id: number;
        id: string;
    }
>;

type GetInfluencerPlansResponse = ApiResponse<PaginatedApiResponseData<InfluencerPlan>, 200> | NotFoundResponse;

export async function getInfluencerPlans(
    params: GetInfluencerPlansParams,
    requestInit?: RequestInit
): Promise<GetInfluencerPlansResponse> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/influencer-plan/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not fetch influencer plans');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getInfluencerUserInfluencerPlans(
    params: Partial<{ tiktok_influencer_users: string; instagram_influencer_users: string }>,
    requestInit?: RequestInit
): Promise<ApiResponse<InfluencerUserCampaigns[], 200> | ApiResponse<string, 400>> {
    const response = await fetchWithToken(
        `/api/advertising/influencer-plans/${encodeUrlSearchParams(params)}`,
        requestInit
    );
    if (response.status === 400) {
        return {
            status: 400,
            data: await response.text(),
        };
    }

    if (!response.ok) {
        throw new Error(
            `Could not get influencer user campaigns.
            influencer user ids: ${params.instagram_influencer_users || ''} ${params.tiktok_influencer_users || ''}`
        );
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getTiktokInfluencerPlanStats(
    influencerPlanId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<TiktokInfluencerPlanStats, 200> | NotFoundResponse> {
    const response = await fetchWithToken(
        `/api/advertising/influencer-plan/${influencerPlanId}/tiktok-live-stats/`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get influencer plan ${influencerPlanId} stats.`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getInstagramInfluencerPlanStats(
    influencerPlanId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<InstagramInfluencerPlanStats, 200> | NotFoundResponse> {
    const response = await fetchWithToken(
        `/api/advertising/influencer-plan/${influencerPlanId}/instagram-live-stats/`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get influencer plan ${influencerPlanId} instagram stats`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getYoutubeInfluencerPlanStats(
    influencerPlanId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<YoutubeInfluencerPlanStats, 200> | NotFoundResponse> {
    const response = await fetchWithToken(
        `/api/advertising/influencer-plan/${influencerPlanId}/youtube-live-stats/`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get influencer plan youtube stats`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
