import React, { createContext, useCallback, useMemo, useReducer, useState } from 'react';
import reducer, { initialState, TiktokUserDataState } from './reducer';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import {
    getAllTiktokArtistDailyReportItems,
    getTiktokInfluencerUserPostAudioData,
    getTiktokVideoStats,
    triggerTikTokUserScraping,
} from '../../../../TikTok.api';
import useAbortableEffect from '../../../../../../Hooks/useAbortableEffect';
import { BoostedPostsRow } from '../Engagement/TiktokUserBoostedPosts/TiktokUserBoostedPosts';
import { OptionsContext } from '../../../../../../contexts/OptionsContext/OptionsContext';
import { TiktokDailyReportItem } from '../../../../../Advertising/MediaPlan/types/MediaPlanResults.types';
import { FCWithChildren } from '../../../../../../utility/utility.types';
import {
    TiktokInfluencerUser,
    getAllTiktokUserStats,
    TiktokUserStats,
    getTiktokUserAnalytics,
    getAllTiktokInfluencerPosts,
    getTiktokUserPostStats,
    GetTiktokUserPostStatsParams,
    getAllTiktokUserPostStats,
} from '@round/api';

type TiktokUserDataContextValues = TiktokUserDataState & TiktokUserDataContextUtils;
type TiktokUserDataContextUtils = {
    fetchUserStats: (userId: number, requestInit?: RequestInit) => Promise<void>;
    resetUserStats: () => void;
    fetchPostStats: (params: GetTiktokUserPostStatsParams, requestInit?: RequestInit) => Promise<void>;
    resetPostStats: () => void;
    fetchTiktokUserDailyReportItems: (userId: number, requestInit?: RequestInit) => Promise<void>;
    resetTiktokUserDailyReportItems: () => void;
    fetchPromotions: (influencerUserId: number, userId: number, requestInit?: RequestInit) => Promise<void>;
    resetPromotions: () => void;
    fetchInfluencerUserPostsAudioData: (influencerUserId: number, requestInit?: RequestInit) => Promise<void>;
    resetInfluencerUserPostsAudioData: () => void;
    fetchUserAnalyticsData: (userId: number, requestInit?: RequestInit) => Promise<void>;
    resetUserAnalyticsData: () => void;
};

const TiktokUserDataContext = createContext<TiktokUserDataContextValues | null>(null);
export const TiktokUserDataProvider: FCWithChildren = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchUserStats = useCallback(
        async (userId: number, requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'loadTiktokUserStats' });
                const stats = await getAllTiktokUserStats(userId, requestInit);
                const sortedStats = stats.sort(
                    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
                );
                const getDateString = (stats: TiktokUserStats) => stats.timestamp.split('T')[0];
                const shown = new Set();
                sortedStats.reverse(); // Reverse Chronological
                let filteredStats = sortedStats.filter((el) => {
                    const dateString = getDateString(el);
                    const duplicate = shown.has(dateString);
                    shown.add(dateString);
                    return !duplicate;
                });
                sortedStats.reverse(); // Chronological
                if (sortedStats.length > 1 && getDateString(sortedStats[0]) === getDateString(sortedStats[1])) {
                    filteredStats.push(sortedStats[0]);
                }

                dispatch({
                    type: 'loadedTiktokUserStats',
                    payload: filteredStats.sort(
                        (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
                    ),
                });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'errorLoadingTiktokUserStats' });
            }
        },
        [dispatch]
    );

    const resetUserStats = useCallback(() => {
        dispatch({ type: 'resetTiktokUserStats' });
    }, []);

    const fetchPostStats = useCallback(async (params: GetTiktokUserPostStatsParams, requestInit?: RequestInit) => {
        try {
            dispatch({ type: 'loadPostStats' });
            const postStatsResponse = await getTiktokUserPostStats(params, requestInit);
            if (postStatsResponse.status === 200) {
                dispatch({
                    type: 'loadedPostStats',
                    payload: { data: postStatsResponse.data.results, count: postStatsResponse.data.count },
                });
            }
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            dispatch({ type: 'errorLoadingPostStats' });
        }
    }, []);

    const resetPostStats = useCallback(() => {
        dispatch({ type: 'resetPostStats' });
    }, []);

    const fetchTiktokUserDailyReportItems = useCallback(async (artistUserId: number, requestInit?: RequestInit) => {
        try {
            dispatch({ type: 'loadDailyReportItems' });
            const reportItems = await getAllTiktokArtistDailyReportItems(artistUserId, requestInit);
            dispatch({ type: 'loadedDailyReportItems', payload: reportItems });
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            dispatch({ type: 'errorLoadingDailyReportItems' });
        }
    }, []);

    const resetTiktokUserDailyReportItems = useCallback(() => {
        dispatch({ type: 'resetDailyReportItems' });
    }, []);

    const fetchPromotions = useCallback(async (influencerUserId: number, userId: number, requestInit?: RequestInit) => {
        const fetchPromotionsPostStats = async () => {
            try {
                dispatch({ type: 'loadPromotionsPostStats' });
                const postStats = await getAllTiktokUserPostStats({ user_id: userId, page_size: 500 }, requestInit);

                dispatch({ type: 'loadedPromotionsPostStats', payload: postStats });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'errorLoadingPromotionsPostStats' });
            }
        };

        const fetchInfluencerPosts = async () => {
            try {
                dispatch({ type: 'loadInfluencerPosts' });
                const posts = await getAllTiktokInfluencerPosts(
                    { influencer_id: influencerUserId, ordering: '-tiktok_post__create_time' },
                    requestInit
                );

                dispatch({ type: 'loadedInfluencerPosts', payload: posts });
                return posts;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'errorLoadingInfluencerPosts' });
                return;
            }
        };

        const [, posts] = await Promise.all([fetchPromotionsPostStats(), fetchInfluencerPosts()]);
        const postsVideoIds = posts?.map((p) => p.tiktok_post).filter((p): p is number => typeof p === 'number');

        if (!postsVideoIds || !postsVideoIds.length) {
            dispatch({ type: 'loadedInfluencerPostsVideoStats', payload: [] });
            return;
        }

        try {
            dispatch({ type: 'loadInfluencerPostsVideoStats' });
            const stats = await getTiktokVideoStats({ videoIds: postsVideoIds, latest: true }, requestInit);
            dispatch({ type: 'loadedInfluencerPostsVideoStats', payload: stats });
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            dispatch({ type: 'errorLoadingInfluencerPostsVideoStats' });
        }
    }, []);

    const resetPromotions = useCallback(() => {
        dispatch({ type: 'resetInfluencerPosts' });
        dispatch({ type: 'resetInfluencerPostsVideoStats' });
        dispatch({ type: 'resetPromotionsPostStats' });
    }, []);

    const fetchInfluencerUserPostsAudioData = useCallback(
        async (influencerUserId: number, requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'loadInfluencerUserPostsAudioData' });
                const data = await getTiktokInfluencerUserPostAudioData({ influencerUserId }, requestInit);
                dispatch({ type: 'loadedInfluencerUserPostsAudioData', payload: data });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'errorLoadingInfluencerUserPostsAudioData' });
            }
        },
        []
    );

    const resetInfluencerUserPostsAudioData = useCallback(
        () => dispatch({ type: 'resetInfluencerUserPostsAudioData' }),
        []
    );

    const fetchUserAnalyticsData = useCallback(async (userId: number, requestInit?: RequestInit) => {
        try {
            dispatch({ type: 'loadTiktokUserAnalyticsData' });
            const response = await getTiktokUserAnalytics(userId, requestInit);
            if (response.status === 404) {
                dispatch({ type: 'errorLoadingTiktokUserAnalyticsData', payload: 'Data not found' });
                return;
            }

            dispatch({ type: 'tiktokUserAnalyticsDataLoaded', payload: response.data.data });
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            dispatch({
                type: 'errorLoadingTiktokUserAnalyticsData',
                payload: "Sorry, we're experiencing technical issues",
            });
        }
    }, []);

    const resetUserAnalyticsData = useCallback(() => dispatch({ type: 'resetTiktokUserAnalytics' }), []);

    const values: TiktokUserDataContextValues = {
        ...state,
        fetchUserStats,
        resetUserStats,
        fetchPostStats,
        resetPostStats,
        fetchTiktokUserDailyReportItems,
        resetTiktokUserDailyReportItems,
        fetchPromotions,
        resetPromotions,
        fetchInfluencerUserPostsAudioData,
        resetInfluencerUserPostsAudioData,
        fetchUserAnalyticsData,
        resetUserAnalyticsData,
    };

    return <TiktokUserDataContext.Provider value={values}>{children}</TiktokUserDataContext.Provider>;
};

export const useClearTiktokUserData = () => {
    const {
        resetUserStats,
        resetPromotions,
        resetInfluencerUserPostsAudioData,
        resetTiktokUserDailyReportItems,
        resetPostStats,
        resetUserAnalyticsData,
    } = useNonNullContext(TiktokUserDataContext);

    return useCallback(() => {
        resetUserStats();
        resetPromotions();
        resetInfluencerUserPostsAudioData();
        resetTiktokUserDailyReportItems();
        resetPostStats();
        resetUserAnalyticsData();
    }, [
        resetInfluencerUserPostsAudioData,
        resetPromotions,
        resetTiktokUserDailyReportItems,
        resetPostStats,
        resetUserAnalyticsData,
        resetUserStats,
    ]);
};

type UseRefreshTiktokUserData = {
    refreshLoading: boolean;
    refreshDisabled: boolean;
    lastUpdated: Date;
    refreshData: () => Promise<void>;
};

export const useRefreshTiktokUserData = (influencerUser: TiktokInfluencerUser | null): UseRefreshTiktokUserData => {
    const {
        fetchTiktokUserDailyReportItems,
        resetPostStats,
        fetchUserStats,
        tiktokUserStats,
        tiktokUserStatsInitialized,
        postStatsInitialized,
        tiktokUserDailyReportItemsInitialized,
        fetchInfluencerUserPostsAudioData,
        influencerUserPostsAudioDataInitialized,
        fetchPromotions,
        promotionsPostStatsInitialized,
        influencerPostsInitialized,
        influencerPostsVideoStatsInitialized,
    } = useNonNullContext(TiktokUserDataContext);

    const [refreshLoading, setRefreshLoading] = useState(false);
    const lastUpdated = useMemo(() => {
        const dates = tiktokUserStats.map((s) => Date.parse(s.timestamp));
        return new Date(Math.max(...dates));
    }, [tiktokUserStats]);

    const updatedRecently = Date.now() - lastUpdated.getTime() <= 1000 * 60 * 30;
    const refreshDisabled = !influencerUser || typeof influencerUser.user !== 'number' || updatedRecently;

    const refreshData = useCallback(async () => {
        if (refreshDisabled) {
            return;
        }

        const { id, user, monitored_user } = influencerUser!;

        try {
            setRefreshLoading(true);
            await triggerTikTokUserScraping(monitored_user);
            const updates: PromiseLike<any>[] = [];
            if (tiktokUserStatsInitialized || tiktokUserDailyReportItemsInitialized) {
                updates.push(fetchUserStats(user!), fetchTiktokUserDailyReportItems(user!));
            }

            if (influencerUserPostsAudioDataInitialized) {
                updates.push(fetchInfluencerUserPostsAudioData(id));
            }

            if (
                user &&
                (influencerPostsInitialized || influencerPostsVideoStatsInitialized || promotionsPostStatsInitialized)
            ) {
                updates.push(fetchPromotions(id, user));
            }

            if (postStatsInitialized) {
                resetPostStats();
            }
            await Promise.all(updates);
        } catch (e) {
            throw e;
        } finally {
            setRefreshLoading(false);
        }
    }, [
        refreshDisabled,
        influencerUser,
        tiktokUserStatsInitialized,
        postStatsInitialized,
        tiktokUserDailyReportItemsInitialized,
        influencerUserPostsAudioDataInitialized,
        influencerPostsInitialized,
        influencerPostsVideoStatsInitialized,
        fetchUserStats,
        resetPostStats,
        fetchTiktokUserDailyReportItems,
        fetchInfluencerUserPostsAudioData,
        fetchPromotions,
        promotionsPostStatsInitialized,
    ]);

    return {
        refreshData,
        lastUpdated,
        refreshDisabled,
        refreshLoading,
    };
};

type UseTiktokUserData = Pick<
    TiktokUserDataContextValues,
    'tiktokUserStats' | 'tiktokUserStatsLoading' | 'errorLoadingTiktokUserStats' | 'resetUserStats'
>;
export const useTiktokUserStats = (userId: number | undefined): UseTiktokUserData => {
    const {
        tiktokUserStats,
        tiktokUserStatsLoading,
        errorLoadingTiktokUserStats,
        fetchUserStats,
        resetUserStats,
        tiktokUserStatsInitialized,
    } = useNonNullContext(TiktokUserDataContext);

    useAbortableEffect(
        (signal) => {
            if (typeof userId === 'number' && !tiktokUserStatsInitialized) {
                fetchUserStats(userId, { signal });
            }
        },
        [userId, fetchUserStats, tiktokUserStatsInitialized]
    );

    return {
        tiktokUserStats,
        tiktokUserStatsLoading,
        errorLoadingTiktokUserStats,
        resetUserStats,
    };
};

type UseTiktokUserTopPosts = Pick<
    TiktokUserDataContextValues,
    | 'postStats'
    | 'postStatsCount'
    | 'postStatsLoading'
    | 'errorLoadingPostStats'
    | 'fetchPostStats'
    | 'postStatsInitialized'
    | 'resetPostStats'
>;
export const useTiktokUserTopPosts = (): UseTiktokUserTopPosts => {
    const {
        postStats,
        postStatsCount,
        postStatsLoading,
        errorLoadingPostStats,
        postStatsInitialized,
        fetchPostStats,
        resetPostStats,
    } = useNonNullContext(TiktokUserDataContext);

    return {
        fetchPostStats,
        resetPostStats,
        postStats,
        postStatsCount,
        postStatsLoading,
        postStatsInitialized,
        errorLoadingPostStats,
    };
};

type UseTiktokUserBoostedPosts = {
    boostedPostsLoading: boolean;
    errorLoadingBoostedPosts: boolean;
    boostedPosts: BoostedPostsRow[];
};
export const useTiktokUserBoostedPosts = (userId: number | undefined): UseTiktokUserBoostedPosts => {
    const {
        tiktokUserDailyReportItems,
        tiktokUserDailyReportItemsLoading,
        errorLoadingTiktokUserDailyReportItems,
        fetchTiktokUserDailyReportItems,
        tiktokUserDailyReportItemsInitialized,
    } = useNonNullContext(TiktokUserDataContext);
    const { currencies } = useNonNullContext(OptionsContext);

    useAbortableEffect(
        (signal) => {
            if (typeof userId === 'number' && !tiktokUserDailyReportItemsInitialized) {
                fetchTiktokUserDailyReportItems(userId, { signal });
            }
        },
        [userId, fetchTiktokUserDailyReportItems, tiktokUserDailyReportItemsInitialized]
    );

    const boostedPosts: BoostedPostsRow[] = useMemo(() => {
        const reportItemsGroupedByMediaPlanItemAndAdName = new Map<string, TiktokDailyReportItem[]>();
        tiktokUserDailyReportItems.forEach((item) => {
            const key = `${item.media_plan_item_id}${item.ad_name}`;
            if (reportItemsGroupedByMediaPlanItemAndAdName.has(key)) {
                reportItemsGroupedByMediaPlanItemAndAdName.get(key)?.push(item);
                return;
            }

            reportItemsGroupedByMediaPlanItemAndAdName.set(key, [item]);
        });

        return Array.from(reportItemsGroupedByMediaPlanItemAndAdName.values()).reduce((boosted, reportItems) => {
            const reportItem = reportItems[0];
            const amountSpent = reportItems.reduce((acc, item) => acc + Number(item.amount_spent), 0);
            const impressions = reportItems.reduce((acc, item) => acc + Number(item.impressions), 0);
            const paidFollowers = reportItems.reduce((acc, item) => acc + Number(item.paid_followers), 0);

            return boosted.concat({
                adName: reportItem?.ad_name,
                startDate: reportItem?.day,
                endDate: reportItems[reportItems.length - 1]?.day,
                amountSpent: amountSpent,
                impressions: impressions,
                cpm: (1000 * amountSpent) / impressions,
                paidFollowers: paidFollowers,
                costPerFollower: amountSpent / paidFollowers,
                currency: currencies.find((c) => c.id === reportItem?.currency),
            });
        }, [] as BoostedPostsRow[]);
    }, [currencies, tiktokUserDailyReportItems]);

    return {
        boostedPosts,
        errorLoadingBoostedPosts: errorLoadingTiktokUserDailyReportItems,
        boostedPostsLoading: tiktokUserDailyReportItemsLoading,
    };
};

type UseTiktokUserPromotions = Pick<
    TiktokUserDataContextValues,
    | 'influencerPosts'
    | 'influencerPostsLoading'
    | 'errorLoadingInfluencerPosts'
    | 'influencerPostsVideoStats'
    | 'influencerPostsVideoStatsLoading'
    | 'errorLoadingInfluencerPostsVideoStats'
    | 'promotionsPostStats'
    | 'promotionsPostStatsLoading'
    | 'errorLoadingPromotionsPostStats'
>;
export function useTiktokUserPromotions(
    influencerUserId: number | undefined | null,
    userId: number | undefined | null
): UseTiktokUserPromotions {
    const {
        influencerPosts,
        promotionsPostStats,
        fetchPromotions,
        promotionsPostStatsLoading,
        promotionsPostStatsInitialized,
        errorLoadingPromotionsPostStats,
        influencerPostsVideoStats,
        influencerPostsVideoStatsLoading,
        errorLoadingInfluencerPostsVideoStats,
        errorLoadingInfluencerPosts,
        influencerPostsLoading,
        influencerPostsInitialized,
        influencerPostsVideoStatsInitialized,
    } = useNonNullContext(TiktokUserDataContext);

    const promotionsInitialized = useMemo(
        () => influencerPostsInitialized && influencerPostsVideoStatsInitialized && promotionsPostStatsInitialized,
        [influencerPostsInitialized, influencerPostsVideoStatsInitialized, promotionsPostStatsInitialized]
    );

    useAbortableEffect(
        (signal) => {
            if (typeof influencerUserId === 'number' && typeof userId === 'number' && !promotionsInitialized) {
                fetchPromotions(influencerUserId, userId, { signal });
            }
        },
        [influencerUserId, userId, fetchPromotions, promotionsInitialized]
    );

    return {
        promotionsPostStats,
        promotionsPostStatsLoading,
        errorLoadingPromotionsPostStats,
        influencerPostsVideoStats,
        errorLoadingInfluencerPostsVideoStats,
        influencerPostsVideoStatsLoading,
        errorLoadingInfluencerPosts,
        influencerPostsLoading,
        influencerPosts,
    };
}

type UseInfluencerUserPostsAudioData = Pick<
    TiktokUserDataContextValues,
    'influencerUserPostsAudioData' | 'influencerUserPostsAudioDataLoading' | 'errorLoadingInfluencerUserPostsAudioData'
>;
export function useInfluencerUserPostsAudioData(
    influencerUserId: number | undefined | null
): UseInfluencerUserPostsAudioData {
    const {
        fetchInfluencerUserPostsAudioData,
        influencerUserPostsAudioDataLoading,
        influencerUserPostsAudioData,
        errorLoadingInfluencerUserPostsAudioData,
        influencerUserPostsAudioDataInitialized,
    } = useNonNullContext(TiktokUserDataContext);

    useAbortableEffect(
        (signal) => {
            if (typeof influencerUserId === 'number' && !influencerUserPostsAudioDataInitialized) {
                fetchInfluencerUserPostsAudioData(influencerUserId, { signal });
            }
        },
        [influencerUserId, fetchInfluencerUserPostsAudioData, influencerUserPostsAudioDataInitialized]
    );

    return {
        influencerUserPostsAudioData,
        influencerUserPostsAudioDataLoading,
        errorLoadingInfluencerUserPostsAudioData,
    };
}

export function useUserAnalyticsData(userId: number | undefined | null) {
    const {
        fetchUserAnalyticsData,
        tiktokUserAnalytics,
        tiktokUserAnalyticsLoading,
        tiktokUserAnalyticsLoadingError,
    } = useNonNullContext(TiktokUserDataContext);

    useAbortableEffect(
        (signal) => {
            if (!userId || tiktokUserAnalytics) {
                return;
            }

            fetchUserAnalyticsData(userId, { signal });
        },
        [userId, fetchUserAnalyticsData, tiktokUserAnalytics]
    );

    return {
        analytics: tiktokUserAnalytics,
        isLoading: tiktokUserAnalyticsLoading,
        error: tiktokUserAnalyticsLoadingError,
    };
}
