import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    ForbiddenResponse,
    NotFoundResponse,
    OrderingValues,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { Song } from './songs.types';

export type GetSongsParams = Partial<PaginatedRequest> & {
    search?: string;
    ordering?: OrderingValues<keyof Pick<Song, 'created'>>;
    status?: string;
    id?: string;
};
type GetSongsResponse = ApiResponse<PaginatedApiResponseData<Song>, 200> | NotFoundResponse | ForbiddenResponse;

export async function getSongs(params: GetSongsParams, requestInit?: RequestInit): Promise<GetSongsResponse> {
    const response = await fetchWithToken(`/api/creatorbase/songs/${encodeUrlSearchParams(params)}`, requestInit);

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get songs');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type ReleasedSongApiData = Pick<Song, 'status'> & { spotify_url: string };
export type UnreleasedSongApiData = Pick<Song, 'status' | 'title' | 'artist_name'>;

export type PostSongData = ReleasedSongApiData | UnreleasedSongApiData;

type PostSongResponse =
    | ApiResponse<Song, 201>
    | ApiResponse<ApiResponseError<Song>, 400>
    | ApiResponse<string[], 400>
    | ForbiddenResponse;

export async function postSong(data: PostSongData): Promise<PostSongResponse> {
    const response = await fetchWithToken('/api/creatorbase/songs/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 403 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create song');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export type PatchSongData = Partial<PostSongData>;

type PatchSongResponse =
    | ApiResponse<Song, 200>
    | ApiResponse<ApiResponseError<Song>, 400>
    | NotFoundResponse
    | ForbiddenResponse;

export async function patchSong(id: number, data: PatchSongData): Promise<PatchSongResponse> {
    const response = await fetchWithToken(`/api/creatorbase/songs/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 403 || response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not update song');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type RetrieveSongResponse = ApiResponse<Song, 200> | NotFoundResponse | ForbiddenResponse;
export async function getSong(songId: number, requestInit?: RequestInit): Promise<RetrieveSongResponse> {
    const response = await fetchWithToken(`/api/creatorbase/songs/${songId}/`, requestInit);

    if (response.status === 403 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not load song');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type DeleteSongResponse = ApiResponse<null, 204> | ForbiddenResponse | NotFoundResponse;

export async function deleteSong(songId: Song['id']): Promise<DeleteSongResponse> {
    const response = await fetchWithToken(`/api/creatorbase/songs/${songId}/`, {
        method: 'DELETE',
    });

    if (response.status === 403 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not delete song');
    }

    return {
        status: 204,
        data: null,
    };
}
