import { patchPostPaymentRequest } from '@round/api';
import styles from './ManualPaypalPaymentModal.module.css';
import { useEffect, useState } from 'react';
import { showNotification } from 'helpers';
import Button from 'ui/Button/Button';
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';
import Modal from 'ui-new/Modals/Modal/Modal';
import ClickToCopyButton from 'ui-new/ClickToCopyButton';
import TableMoneyInput, { Amount } from 'ui-new/TableComponents/TableMoneyInput/TableMoneyInput';
import { UseOutstandingPaymentsReturn } from '../../../useOutstandingPayments';
import { mapApiErrorsToFormikErrors } from 'utility/utility';

type Data = {
    amount: number | null;
    currency: number | null;
    reference: string | null;
    paypalEmailAddress: string | null;
    postUrl: string | null;
    postPaymentRequestId: number;
};

type Props = {
    data: Data | null;
    isOpen: boolean;
    onClose: () => void;
    updatePostPaymentRequest: typeof patchPostPaymentRequest;
    makePayment: UseOutstandingPaymentsReturn['payPayPalManually'];
};

const ManualPaypalPaymentModal = ({ data, isOpen, onClose, updatePostPaymentRequest, makePayment }: Props) => {
    const [paymentReference, setPaymentReference] = useState(data?.reference || '');
    const [amount, setAmount] = useState(data?.amount);
    const [currency, setCurrency] = useState(data?.currency);
    const [isReferenceEditable, setIsReferenceEditable] = useState(false);
    const [isCostEditable, setIsCostEditable] = useState(false);

    const [isPaymentConfirmationLoading, setIsPaymentConfirmationLoading] = useState(false);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setPaymentReference(data?.reference || '');
            setAmount(data?.amount);
            setCurrency(data?.currency);
        }
    }, [isOpen, data?.reference, data?.amount, data?.currency]);

    const handleConfirm = async () => {
        if (!data || !amount || !currency) {
            return;
        }

        if (!paymentReference) {
            showNotification('Please enter an reference note', 'error');
            return;
        }

        try {
            setIsPaymentConfirmationLoading(true);
            const response = await makePayment({
                payment_request_id: data.postPaymentRequestId,
                amount: amount,
                currency: currency,
            });

            if (response.status === 204) {
                showNotification('Post paid', 'info');
                onClose();
                return;
            }

            const message = Array.isArray(response.data)
                ? response.data.toString()
                : Object.values(mapApiErrorsToFormikErrors(response.data)).toString();
            showNotification(message, 'error');
        } catch {
            showNotification("Couldn't confirm manual payment.", 'error');
        } finally {
            setIsPaymentConfirmationLoading(false);
        }
    };

    const handleReferenceUpdate = async () => {
        if (!data || paymentReference === data.reference) {
            return;
        }

        try {
            setIsUpdateLoading(true);
            const response = await updatePostPaymentRequest(data.postPaymentRequestId, {
                payment_reference: paymentReference,
            });

            if (response.status === 200) {
                setPaymentReference(response.data.payment_reference);
                return;
            }

            showNotification('Could not update payment reference', 'error');
        } catch {
            showNotification('Could not update payment reference', 'error');
        } finally {
            setIsUpdateLoading(false);
        }
    };

    const handleAmountUpdate = async ({ amount, currency }: Amount) => {
        if (!currency || !data?.postPaymentRequestId) {
            return;
        }

        try {
            setIsUpdateLoading(true);
            const response = await updatePostPaymentRequest(data?.postPaymentRequestId, {
                amount: amount.toString(),
                currency: currency,
            });

            if (response.status === 200) {
                setAmount(Number(response.data.amount));
                setCurrency(response.data.currency);
                return;
            }

            showNotification('Could not update cost', 'error');
        } catch {
            showNotification('Could not update cost', 'error');
        } finally {
            setIsUpdateLoading(false);
        }
    };

    return (
        <Modal className={styles.modal} isOpen={isOpen} onClose={onClose} closeOnOverlayClick closeOnEscape>
            <Modal.Header>Paypal manual payment</Modal.Header>

            <dl className={styles.detailsContainer}>
                <div className={styles.row}>
                    <dd>Email</dd>
                    <dt className={styles.bold}>{data?.paypalEmailAddress}</dt>
                    <ClickToCopyButton copyValue={`${data?.paypalEmailAddress}`} />
                </div>

                <div className={styles.row}>
                    <dd>Balance</dd>
                    <dt className={styles.bold}>
                        <TableMoneyInput
                            key={`${currency}-${amount}`}
                            editClassName={styles.costEditInput}
                            isEditing={isCostEditable}
                            onEditingChange={setIsCostEditable}
                            value={{ currency: currency ?? null, amount: amount ?? 0 }}
                            onChange={handleAmountUpdate}
                        />
                    </dt>
                    {!isCostEditable && (
                        <>
                            <EditIcon
                                className={styles.editIcon}
                                onClick={() => {
                                    setIsCostEditable(true);
                                }}
                            />
                            <ClickToCopyButton copyValue={`${data?.amount?.toFixed(2)}`} />
                        </>
                    )}
                </div>

                <div>
                    <dd>Post Link</dd>
                    <dt className={styles.bold}>{data?.postUrl}</dt>
                </div>

                <div>
                    <dd>Note</dd>
                    {isReferenceEditable ? (
                        <input
                            type="text"
                            value={paymentReference}
                            className={styles.ghostInput}
                            onChange={(e) => {
                                setPaymentReference(e.target.value);
                            }}
                            onBlur={() => {
                                handleReferenceUpdate().then(() => setIsReferenceEditable(false));
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleReferenceUpdate().then(() => setIsReferenceEditable(false));
                                }
                            }}
                            autoFocus
                        />
                    ) : (
                        <>
                            <dt className={styles.bold}>{paymentReference}</dt>
                            <EditIcon
                                className={styles.editIcon}
                                onClick={() => {
                                    setIsReferenceEditable(true);
                                }}
                            />
                            <ClickToCopyButton copyValue={paymentReference} />
                        </>
                    )}
                </div>
            </dl>

            <Modal.Actions>
                <Button appearance="ghost" onClick={onClose}>
                    Cancel
                </Button>

                <Button
                    appearance="primary"
                    onClick={() => handleConfirm()}
                    isLoading={isPaymentConfirmationLoading}
                    disabled={isUpdateLoading}
                >
                    Confirm Payment
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ManualPaypalPaymentModal;
