import React, { useState, useMemo } from 'react';
import styles from './InstagramInfluencerPostForm.module.css';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { GenericDropdownOption } from '../../../../../../App.types';
import Select from '../../../../../../ui/DataEntry/Select/Select';
import { StylesConfig } from 'react-select';
import Label from '../../../../../../ui/DataEntry/Label/Label';
import FormField from '../../../../../../ui/DataEntry/FormField/FormField';
import { InstagramInfluencerPostApiBody, InstagramInfluencerPostResultApiBody } from '../../../types/Instagram.types';
import { ChangeValueTypes } from '../../../../../../utility/utility.types';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../../../contexts/OptionsContext/OptionsContext';
import { usePaginatedFetch, getXeroReceivableInvoices } from '@round/api';
import { getInstagramInfluencerUsers } from '../../../../../Instagram/Instagram.api';
import useAbortableEffect from '../../../../../../Hooks/useAbortableEffect';
import debounce from 'lodash/debounce';
import cn from 'classnames';
import OptionWithSubLabel, {
    DropdownOptionWithSubLabel,
} from '../../../../../../ui/DataEntry/Select/OptionWithSubLabel/OptionWithSubLabel';
import Button from '../../../../../../ui/Buttons/Button/Button';
import { OptionalObjectSchema } from 'yup/lib/object';

const selectStyles: StylesConfig = {
    control: (provided, props) => ({
        ...provided,
        cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        fontWeight: 400,
        fontSize: '0.8125rem',
        backgroundColor: props.isDisabled ? '#C2CFE050' : provided.backgroundColor,
        borderColor: '#C2CFE0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.625rem 1rem',
    }),
    singleValue: (provided, props) => ({
        ...provided,
        color: props.isDisabled ? '#323C4780' : '#323C47',
    }),
    input: (provided) => ({
        ...provided,
        height: '1rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }),
};

export type InstagramInfluencerPostFields = 'currency_id' | 'influencer_id' | 'cost' | 'post_url' | 'xero_invoice_id';
export type InstagramInfluencerPostResultsFields =
    | 'page_followers'
    | 'feed_views'
    | 'likes'
    | 'comments'
    | 'story_views';
type ApiDataUsedInTheForm = Pick<InstagramInfluencerPostApiBody, InstagramInfluencerPostFields> &
    Pick<InstagramInfluencerPostResultApiBody, InstagramInfluencerPostResultsFields>;

export type InstagramInfluencerPostFormValues = Partial<
    ChangeValueTypes<
        ApiDataUsedInTheForm,
        'currency_id' | 'influencer_id' | 'xero_invoice_id',
        GenericDropdownOption<number> | DropdownOptionWithSubLabel<number> | null
    >
>;

const defaultRenderActions = ({ isSubmitting, submitForm, dirty }: FormikProps<InstagramInfluencerPostFormValues>) => (
    <div className={styles.defaultRenderActions}>
        <Button
            className={styles.defaultSubmitButton}
            type="filled"
            color="black"
            disabled={!dirty}
            loading={isSubmitting}
            onClick={submitForm}
        >
            Save
        </Button>
    </div>
);

type InstagramInfluencerPostFormProps = {
    handleSubmit: (
        values: InstagramInfluencerPostFormValues,
        helpers: FormikHelpers<InstagramInfluencerPostFormValues>
    ) => void;
    initialValues: InstagramInfluencerPostFormValues;
    validationSchema?: OptionalObjectSchema<any>;
    renderActions?: (props: FormikProps<InstagramInfluencerPostFormValues>) => React.ReactNode;
};

const InstagramInfluencerPostForm = ({
    handleSubmit,
    initialValues,
    validationSchema,
    renderActions = defaultRenderActions,
}: InstagramInfluencerPostFormProps) => {
    const { currencies } = useNonNullContext(OptionsContext);

    const [influencerUsersPage, setInfluencerUsersPage] = useState(1);
    const [influencerUsersSearch, setInfluencerUsersSearch] = useState('');
    const {
        data: instagramInfluencerUsers,
        getCurrentPage: getCurrentUserPage,
        hasNextPage: hasNextUserPage,
        loading: instagramInfluencerUsersLoading,
    } = usePaginatedFetch(getInstagramInfluencerUsers, {
        page: influencerUsersPage,
        search: influencerUsersSearch,
    });

    const [invoicesPage, setInvoicesPage] = useState(1);
    const [invoicesSearch, setInvoicesSearch] = useState('');
    const {
        data: invoices,
        getCurrentPage: getCurrentInvoicesPage,
        hasNextPage: hasNextInvoicesPage,
        loading: invoicesLoading,
    } = usePaginatedFetch(getXeroReceivableInvoices, {
        page: invoicesPage,
        search: invoicesSearch,
    });

    const fetchInfluencerUsers = useMemo(() => debounce(getCurrentUserPage, 700), [getCurrentUserPage]);
    useAbortableEffect(
        (signal) => {
            fetchInfluencerUsers({ signal });
        },
        [fetchInfluencerUsers]
    );

    const fetchInvoices = useMemo(() => debounce(getCurrentInvoicesPage, 700), [getCurrentInvoicesPage]);
    useAbortableEffect(
        (signal) => {
            fetchInvoices({ signal });
        },
        [fetchInvoices]
    );

    const currencyOptions: GenericDropdownOption<number>[] = useMemo(
        () =>
            currencies.map((c) => ({
                value: c.id,
                label: c.name,
            })),
        [currencies]
    );

    const instagramInfluencerUserOptions: GenericDropdownOption<number>[] = useMemo(
        () =>
            instagramInfluencerUsers.map((u) => ({
                value: u.id,
                label: u.username,
            })),
        [instagramInfluencerUsers]
    );

    const invoiceOptions: DropdownOptionWithSubLabel<number>[] = useMemo(
        () =>
            invoices.map((i) => ({
                value: i.id,
                label: i.invoice_number,
                subLabel: i.reference,
            })),
        [invoices]
    );

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema ?? null}
            validateOnBlur
            validateOnChange
            enableReinitialize
        >
            {(props) => (
                <>
                    <Form className={styles.form}>
                        <FormField className={styles.field}>
                            <Label htmlFor="influencer_id">Influencer</Label>
                            <Field name="influencer_id">
                                {(props: FieldProps) => (
                                    <Select
                                        styles={selectStyles}
                                        inputValue={influencerUsersSearch}
                                        onInputChange={(search: string) => {
                                            setInfluencerUsersSearch(search);
                                            setInfluencerUsersPage(1);
                                        }}
                                        isLoading={instagramInfluencerUsersLoading}
                                        options={instagramInfluencerUserOptions}
                                        onChange={(option) => {
                                            props.form.setFieldValue(props.field.name, option);
                                            const influencer = instagramInfluencerUsers.find(
                                                (influencer) => option?.value === influencer.id
                                            );
                                            if (influencer && influencer.reels_cost && influencer.reels_currency) {
                                                props.form.setFieldValue('cost', influencer.reels_cost);
                                                props.form.setFieldValue(
                                                    'currency_id',
                                                    currencyOptions.find(
                                                        (currency) => currency.value === influencer.reels_currency
                                                    )
                                                );
                                            } else {
                                                props.form.setFieldValue('cost', initialValues.cost);
                                                props.form.setFieldValue('currency_id', initialValues.currency_id);
                                            }
                                        }}
                                        value={props.field.value}
                                        onMenuScrollToBottom={() => {
                                            if (hasNextUserPage) {
                                                setInfluencerUsersPage((page) => page + 1);
                                            }
                                        }}
                                    />
                                )}
                            </Field>
                            <ErrorMessage name="influencer_id">
                                {(message) => <span className={styles.errorMessage}>{message}</span>}
                            </ErrorMessage>
                        </FormField>

                        <div className={styles.row}>
                            <div className={styles.col4}>
                                <FormField className={styles.field}>
                                    <Label htmlFor="currency_id">Currency</Label>
                                    <Field name="currency_id">
                                        {(props: FieldProps) => (
                                            <Select
                                                styles={selectStyles}
                                                options={currencyOptions}
                                                onChange={(option) => {
                                                    props.form.setFieldValue(props.field.name, option);
                                                }}
                                                value={props.field.value}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="currency_id">
                                        {(message) => <span className={styles.errorMessage}>{message}</span>}
                                    </ErrorMessage>
                                </FormField>
                            </div>
                            <div className={styles.col5}>
                                <FormField className={styles.field}>
                                    <Label htmlFor="cost">Cost</Label>
                                    <Field name="cost" type="number" />
                                    <ErrorMessage name="cost">
                                        {(message) => <span className={styles.errorMessage}>{message}</span>}
                                    </ErrorMessage>
                                </FormField>
                            </div>
                            <div className={styles.col}>
                                <FormField className={styles.field}>
                                    <Label htmlFor="xero_invoice_id">Invoice</Label>
                                    <Field name="xero_invoice_id">
                                        {(props: FieldProps) => (
                                            <Select
                                                isClearable
                                                components={{ Option: OptionWithSubLabel }}
                                                styles={selectStyles}
                                                inputValue={invoicesSearch}
                                                onInputChange={(search: string) => {
                                                    setInvoicesSearch(search);
                                                    setInvoicesPage(1);
                                                }}
                                                filterOption={null}
                                                isLoading={invoicesLoading}
                                                options={invoiceOptions}
                                                formatOptionLabel={(option, { context }) =>
                                                    context === 'value'
                                                        ? `${option.label} - ${option.subLabel}`
                                                        : option.label
                                                }
                                                onChange={(option) => {
                                                    props.form.setFieldValue(props.field.name, option);
                                                }}
                                                value={props.field.value}
                                                onMenuScrollToBottom={() => {
                                                    if (hasNextInvoicesPage) {
                                                        setInvoicesPage((page) => page + 1);
                                                    }
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="xero_invoice_id">
                                        {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                                    </ErrorMessage>
                                </FormField>
                            </div>
                        </div>

                        <div className={cn(styles.row, styles.metricsRow)}>
                            <FormField className={styles.field}>
                                <Label htmlFor="page_followers">Followers</Label>
                                <Field name="page_followers" type="number" />
                                <ErrorMessage name="page_followers">
                                    {(message) => <span className={styles.errorMessage}>{message}</span>}
                                </ErrorMessage>
                            </FormField>
                            <FormField className={styles.field}>
                                <Label htmlFor="feed_views">Feed views</Label>
                                <Field name="feed_views" type="number" />
                                <ErrorMessage name="feed_views">
                                    {(message) => <span className={styles.errorMessage}>{message}</span>}
                                </ErrorMessage>
                            </FormField>
                            <FormField className={styles.field}>
                                <Label htmlFor="likes">Likes</Label>
                                <Field name="likes" type="number" />
                                <ErrorMessage name="likes">
                                    {(message) => <span className={styles.errorMessage}>{message}</span>}
                                </ErrorMessage>
                            </FormField>
                            <FormField className={styles.field}>
                                <Label htmlFor="comments">Comments</Label>
                                <Field name="comments" type="number" />
                                <ErrorMessage name="comments">
                                    {(message) => <span className={styles.errorMessage}>{message}</span>}
                                </ErrorMessage>
                            </FormField>
                            <FormField className={styles.field}>
                                <Label htmlFor="story_views">Story views</Label>
                                <Field name="story_views" type="number" />
                                <ErrorMessage name="story_views">
                                    {(message) => <span className={styles.errorMessage}>{message}</span>}
                                </ErrorMessage>
                            </FormField>
                        </div>

                        <div className={styles.row}>
                            <FormField className={styles.field}>
                                <Label htmlFor="post_url">Post URL</Label>
                                <Field name="post_url" />
                                <ErrorMessage name="post_url">
                                    {(message) => <span className={styles.errorMessage}>{message}</span>}
                                </ErrorMessage>
                            </FormField>
                        </div>
                    </Form>
                    {renderActions(props)}
                </>
            )}
        </Formik>
    );
};

export default InstagramInfluencerPostForm;
