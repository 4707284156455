import { ApiResponse, PaginatedApiResponseData, ContentTag } from '../../types';
import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';

export type GetContentTagsParams = Partial<{
    page: number;
    search: string;
}>;

export async function getContentTags(
    params: GetContentTagsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<ContentTag>, 200>> {
    const response = await fetchWithToken(`/api/microwave/content-tags/${encodeUrlSearchParams(params)}`, requestInit);

    if (!response.ok) {
        throw new Error(`Could not get microwave content tags ${encodeUrlSearchParams(params)}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getAllContentTags(params: Omit<GetContentTagsParams, 'page'>, requestInit?: RequestInit) {
    return fetchAll<ContentTag>(`/api/microwave/content-tags/${encodeUrlSearchParams(params)}`, requestInit);
}
