import { Form, FormikErrors } from 'formik';
import CampaignFormBase, {
    BaseCampaignFormValues,
    getBaseCampaignFormFields,
    mapCampaignApiErrorsToFormikErrors,
} from '../../components/CampaignFormBase/CampaignFormBase';
import { FormField } from 'ui-new/whitelabel/formik';
import { creatorbase } from '@round/api';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import styles from './TiktokCampaignForm.module.css';

export type TiktokCampaignFormValues = BaseCampaignFormValues & {
    audio_identifier: string;
};

type Props = {
    readonlyFields?: Array<keyof TiktokCampaignFormValues>;
    teamId: number | undefined;
};

const TiktokCampaignForm = ({ readonlyFields, teamId }: Props) => {
    return (
        <Form className={styles.form}>
            <CampaignFormBase readonlyFields={getBaseCampaignFormFields(readonlyFields)} teamId={teamId} />

            <FormField
                name="audio_identifier"
                label="Audio"
                placeholder="Enter audio id or url"
                disabled={readonlyFields?.includes('audio_identifier')}
            />
        </Form>
    );
};

export const mapTiktokCampaignApiErrorsToFormikErrors = (
    apiErrors: creatorbase.PostCampaignErrorResponse
): FormikErrors<TiktokCampaignFormValues> => {
    return {
        ...mapCampaignApiErrorsToFormikErrors(apiErrors),
        ...mapApiErrorsToFormikErrors({ audio_identifier: apiErrors.audio_identifier }),
    };
};

export default TiktokCampaignForm;
