import { creatorbase } from '@round/api';
import { getTableMetaHelper, Image, SkeletonizedValue } from '@round/ui-kit';
import { CellContext } from '@tanstack/react-table';
import styles from './ProjectCell.module.css';

export type ProjectData = {
    name: string;
    brandName: string;
    brandImage: string | undefined | null;
    teamName: string;
};

type Row = creatorbase.PublicCampaign;
type Context<T extends Row> = CellContext<T, ProjectData>;

export type Meta<T extends Row = Row> = {
    isLoading: boolean;
    getIsProjectLoading: (row: T) => boolean;
    getIsBrandLoading: (row: T) => boolean;
    getIsTeamLoading: (row: T) => boolean;
};

const getTableMeta = getTableMetaHelper<Meta>();

const ProjectCell = <T extends Row>({ getValue, table, row: { original } }: Context<T>) => {
    const { isLoading, getIsProjectLoading, getIsBrandLoading, getIsTeamLoading } = getTableMeta(table);
    const isDataLoading =
        isLoading || getIsProjectLoading(original) || getIsBrandLoading(original) || getIsTeamLoading(original);

    const { brandImage, brandName, name, teamName } = getValue();
    return (
        <div className={styles.projectContainer}>
            <Image
                skeletonCircle={false}
                loading={isDataLoading}
                src={brandImage || ''}
                className={styles.projectImage}
            />
            <div className={styles.projectDetails}>
                <p className={styles.projectName}>
                    <SkeletonizedValue width="10rem" isInitialized={!isDataLoading}>
                        {name || '-'}
                    </SkeletonizedValue>
                </p>

                <p className={styles.brandName}>
                    <SkeletonizedValue width="7rem" isInitialized={!isDataLoading}>
                        {brandName} · {teamName}
                    </SkeletonizedValue>
                </p>
            </div>
        </div>
    );
};

export default ProjectCell;
