import React from 'react';
import styles from './FormSubmitted.module.css';
import { ReactComponent as TickCircle } from '../../../../../assets/TickCircle.svg';
import { ReactComponent as TiktokIcon } from '../../../../../assets/TiktokIcon.svg';
import { microwave } from '@round/api';
import CampaignDetails from '../CampaignDetails/CampaignDetails';
import SubmissionTracker from './SubmissionTracker/SubmissionTracker';

type Props = {
    data: microwave.TiktokPostInviteForm;
};

const FormSubmitted = ({ data }: Props) => {
    return (
        <div className={styles.container}>
            <section className={styles.releaseContainer}>
                <CampaignDetails
                    releaseName={data.release_name}
                    artistName={data.artist_name}
                    imageUrl={data.image_url}
                    audioUrl={data.audio_url}
                />
            </section>
            <section className={styles.contentContainer}>
                <div className={styles.copyContainer}>
                    {data.payment_date ? (
                        <div className={styles.successRow}>
                            <div className={styles.iconContainer}>
                                <TickCircle className={styles.icon} />
                            </div>
                            <h1>Submission Successful</h1>
                        </div>
                    ) : (
                        <>
                            <h1>Post Submitted</h1>
                            <p>
                                Thank you for taking part, we look forward to working with you again.
                                <br />
                                Please track your payment status below.
                            </p>
                        </>
                    )}
                </div>
                <div className={styles.submissionDetails}>
                    {data.post_url && (
                        <div className={styles.badge}>
                            <a href={data.post_url} target="_blank" rel="noopener noreferrer">
                                Post Link
                            </a>

                            <TiktokIcon />
                        </div>
                    )}

                    <SubmissionTracker formData={data} />
                </div>
            </section>
        </div>
    );
};

export default FormSubmitted;
