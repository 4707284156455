import React from 'react';
import { PhylloUserAnalyticsData } from '@round/api';
import { Bar, ChartProps } from 'react-chartjs-2';
import capitalize from 'lodash/capitalize';
import { genderAgeChartColors } from './helpers';
import LoadingSpinner from '../../../../SharedComponents/LoadingSpinner/LoadingSpinner';
import { numberWithCommas, roundTo2Dp } from '../../../../helpers';

type Props = {
    data: PhylloUserAnalyticsData['profile']['audience']['gender_age_distribution'];
    className?: string;
    loading?: boolean;
};

const AgeChart = ({ data, className, loading }: Props) => {
    const genders = Array.from(new Set(data.map((item) => item.gender)));
    const ageGroups = Array.from(new Set(data.map((item) => item.age_range)));

    const datasets: ChartProps<'bar'>['data']['datasets'] = genders.map((gender) => {
        return {
            data: data
                .filter((item) => item.gender === gender)
                .sort((a, b) => a.age_range.localeCompare(b.age_range))
                .map((item) => item.value),
            label: capitalize(gender),
            backgroundColor: genderAgeChartColors[gender],
        };
    });

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <div className={className}>
            <Bar
                data={{
                    datasets: datasets,
                    labels: ageGroups,
                }}
                options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            display: true,
                            formatter: (value) => {
                                return `${numberWithCommas(roundTo2Dp(Number(value)))}%`;
                            },
                            align: 'end',
                            anchor: 'end',
                            offset: 0,
                        },
                    },
                    scales: {
                        y: {
                            // add 5 points for axis range to fit data label on top of a bar
                            suggestedMax: Math.max(...data.map((item) => item.value)) + 5,
                            ticks: {
                                display: false,
                            },
                            grid: {
                                display: false,
                            },
                            border: {
                                display: false,
                            },
                        },
                        x: {
                            grid: {
                                display: false,
                            },
                            border: {
                                display: false,
                            },
                        },
                    },
                }}
            />
        </div>
    );
};

export default AgeChart;
