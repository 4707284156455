import { CellContext, ColumnDef } from '@tanstack/react-table';
import { MediaPlanItemRow } from 'App.types';
import { ReportItem } from 'Modules/Advertising/MediaPlan/types/MediaPlanResults.types';
import { useMemo, useState } from 'react';
import { presetOptions, SelectableMetrics } from '../PresetsSelect/presets';

import { asMoney, asMoneyWithoutZeros, displayOptionalNumericTableValue, numberWithCommas, roundTo2Dp } from 'helpers';
import { ProgressBar, Table } from '@round/ui-kit';
import cn from 'classnames';
import styles from './PlatformSummaryTable.module.css';
import PlatformIcon from 'Modules/Advertising/Projects/components/ProjectsTable/PlatformIcon';
import { Currency } from '@round/api';
import PresetsSelect from '../PresetsSelect/PresetsSelect';
import { toDecimalPoint } from '@round/utils';

export type PlatformSummaryTableRow = Omit<ReportItem, 'mediaPlanItemId'> & {
    itemCount: number;
    cost?: MediaPlanItemRow['cost'];
    totalSpend: number;
    channel: string;
};

type Props = {
    currency: Currency | undefined;
    data: PlatformSummaryTableRow[];
};

const ALWAYS_SHOWN_COLUMNS = ['channel'];

type TableCellContext<K extends keyof PlatformSummaryTableRow> = CellContext<
    PlatformSummaryTableRow,
    PlatformSummaryTableRow[K]
>;

const PlatformSummaryTable = ({ currency, data }: Props) => {
    const [selectedPreset, setSelectedPreset] = useState(
        presetOptions.find((option) => option.value === 'performance')
    );

    const columns: ColumnDef<PlatformSummaryTableRow, any>[] = useMemo(
        () =>
            currency
                ? [
                      {
                          id: 'channel',
                          accessorKey: 'channel',
                          header: 'Channel',
                          cell: ({ getValue, row: { original } }: TableCellContext<'channel'>) => {
                              const channel = getValue();
                              return (
                                  <div className={styles.channelContent}>
                                      <PlatformIcon name={channel.toLowerCase()} width={14} height={14} />
                                      <span>{channel}</span>
                                      <span>({original.itemCount})</span>
                                  </div>
                              );
                          },
                      },
                      {
                          id: 'amount_spent',
                          accessorKey: 'amount_spent',
                          header: 'Amount Spent / Total Spend',
                          cell: ({ getValue, row: { original } }: TableCellContext<'amount_spent'>) => {
                              const amountSpent = getValue();
                              if (isNaN(amountSpent)) {
                                  return '-';
                              }

                              const totalSpend = original.totalSpend;
                              const percentage = totalSpend !== 0 ? (amountSpent / totalSpend) * 100 : 0;

                              return (
                                  <div className={cn(styles.amountSpentContainer)}>
                                      <p className={styles.amountSpent}>
                                          {asMoneyWithoutZeros(amountSpent, currency)}
                                          <span className={styles.amountSpentPercentage}>
                                              {toDecimalPoint(percentage, 1)}%
                                          </span>
                                      </p>
                                      <ProgressBar
                                          progress={Math.min(100, percentage)}
                                          trackStyles={{ height: '0.2rem' }}
                                          barStyles={{
                                              backgroundColor: amountSpent > totalSpend * 1.05 ? '#F7685B' : '',
                                          }}
                                      />
                                  </div>
                              );
                          },
                      },
                      {
                          id: 'impressions',
                          accessorKey: 'impressions',
                          header: 'Impressions',
                          cell: ({ getValue }: TableCellContext<'impressions'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpm',
                          accessorKey: 'cpm',
                          header: 'CPM',
                          cell: ({ getValue }: TableCellContext<'cpm'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'clicks',
                          accessorKey: 'clicks',
                          header: 'Clicks',
                          cell: ({ getValue }: TableCellContext<'clicks'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpc',
                          accessorKey: 'cpc',
                          header: 'CPC',
                          cell: ({ getValue }: TableCellContext<'cpc'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'views',
                          accessorKey: 'views',
                          header: 'Views',
                          cell: ({ getValue }: TableCellContext<'views'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpv',
                          accessorKey: 'cpv',
                          header: 'CPV',
                          cell: ({ getValue }: TableCellContext<'cpv'>) => asMoney(getValue(), currency, 3),
                      },
                      {
                          id: 'six_sec_views',
                          accessorKey: 'six_sec_views',
                          header: 'Views (6sec)',
                          cell: ({ getValue }: TableCellContext<'six_sec_views'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'six_sec_cpv',
                          accessorKey: 'six_sec_cpv',
                          header: 'CPV (6sec)',
                          cell: ({ getValue }: TableCellContext<'six_sec_cpv'>) => asMoney(getValue(), currency, 3),
                      },

                      {
                          id: 'leads',
                          accessorKey: 'leads',
                          header: 'Leads',
                          cell: ({ getValue }: TableCellContext<'leads'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpl',
                          accessorKey: 'cpl',
                          header: 'CPL',
                          cell: ({ getValue }: TableCellContext<'cpl'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'view_rate',
                          accessorKey: 'view_rate',
                          header: 'View Rate',
                          cell: ({ getValue }: TableCellContext<'view_rate'>) => {
                              const viewRate = getValue();
                              return typeof viewRate === 'number' && !isNaN(viewRate)
                                  ? `${numberWithCommas(viewRate)}%`
                                  : '-';
                          },
                      },
                      {
                          id: 'purchases',
                          accessorKey: 'purchases',
                          header: 'Purchases',
                          cell: ({ getValue }: TableCellContext<'purchases'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpa',
                          accessorKey: 'cpa',
                          header: 'CPA',
                          cell: ({ getValue }: TableCellContext<'cpa'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'revenue',
                          accessorKey: 'revenue',
                          header: 'Revenue',
                          cell: ({ getValue }: TableCellContext<'revenue'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'roas',
                          accessorKey: 'roas',
                          header: 'ROAS',
                          cell: ({ getValue }: TableCellContext<'roas'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'ctr',
                          accessorKey: 'ctr',
                          header: 'CTR',
                          cell: ({ getValue }: TableCellContext<'ctr'>) => {
                              const ctr = getValue();
                              return typeof ctr === 'number' && !isNaN(ctr) ? `${roundTo2Dp(ctr * 100)}%` : '-';
                          },
                      },
                  ]
                : [],
        [currency]
    );

    const columnVisibility = columns.reduce((acc, column) => {
        if (!column.id || !selectedPreset) {
            return acc;
        }

        const metricName = column.id as SelectableMetrics;

        if (![...selectedPreset?.metrics, ...ALWAYS_SHOWN_COLUMNS].includes(metricName)) {
            acc[metricName] = false;
        }

        return acc;
    }, {} as Record<SelectableMetrics, boolean>);

    return (
        <div className={styles.container}>
            <div className={styles.tableHeader}>
                <h2>Platform Summary</h2>
                <PresetsSelect value={selectedPreset} onChange={setSelectedPreset} />
            </div>
            <div className={styles.tableWrapper}>
                <Table className={styles.table} columns={columns} data={data} columnVisibility={columnVisibility} />
            </div>
        </div>
    );
};

export default PlatformSummaryTable;
