import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { PublicReportContext } from '../PublicReportContext';
import { useCallback } from 'react';
import { influencer } from '@round/api';

export default function usePublicReportCampaignStats() {
    const data = useNonNullContextSelector(PublicReportContext, ([values]) => values.campaignStats);
    const dispatch = useNonNullContextSelector(PublicReportContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (campaignIds: number[], requestInit?: RequestInit) => {
            dispatch({ type: 'loadInfluencerAggregatedPostStatsByCampaignId', payload: campaignIds });

            const results = await Promise.allSettled(
                campaignIds.map(async (campaignId) => {
                    try {
                        const response = await influencer.postAggregatedPostStats(
                            { campaign_id: campaignId },
                            requestInit
                        );
                        if (response.status === 201) {
                            dispatch({
                                type: 'influencerAggregatedPostStatsByCampaignIdLoaded',
                                payload: { campaignId, stats: response.data },
                            });
                            return response;
                        }

                        dispatch({
                            type: 'errorLoadingInfluencerAggregatedPostStatsByCampaignId',
                            payload: { campaignId, error: Object.values(response.data).flat().join('. ') },
                        });
                        return response;
                    } catch (e) {
                        if (e instanceof Error && e.name === 'AbortError') {
                            throw e;
                        }

                        dispatch({
                            type: 'errorLoadingInfluencerAggregatedPostStatsByCampaignId',
                            payload: { campaignId, error: 'Could not load stats' },
                        });
                        throw e;
                    }
                })
            );

            requestInit?.signal?.throwIfAborted();
            return results;
        },
        [dispatch]
    );

    return {
        data,
        fetchData,

        getAreCampaignStatsLoading: (campaignId: number) =>
            data[campaignId]?.status !== 'success' && data[campaignId]?.status !== 'error',
    };
}
