import React from 'react';
import styles from './EmptyPlan.module.css';
import { ReactComponent as Empty } from './Empty.svg';
import Button from '../../../../ui/Buttons/Button/Button';
import { Release } from '@round/api';

type EmptyPlanProps = {
    release?: Release | null;
    onCreatePlan?: () => void;
    readOnly?: boolean;
};

const EmptyPlan = ({ release, onCreatePlan, readOnly }: EmptyPlanProps) => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    <Empty />
                    <p>There are no plans added yet</p>
                    {!readOnly && (
                        <Button disabled={!release} type="filled" color="black" onClick={onCreatePlan}>
                            Create Plan
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};

export default EmptyPlan;
