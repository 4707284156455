import React from 'react';
import { ContextState } from './Tabs';

export type TabChild = React.ReactElement<{ name: string }>;

export function getNamesFromChildren<T extends TabChild>(children: T | T[] | undefined) {
    return React.Children.map(children, (child) => child?.props.name) ?? [];
}

export function getTabId(name: ContextState['activeTab']) {
    return `tab-${name}`;
}

export function getPanelId(name: ContextState['activeTab']) {
    return `panel-${name}`;
}
