import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InstagramUserImage } from '../../../../App.types';
import ModalRight from '../../../../SharedComponents/ModalRight/ModalRight';
import styles from './InstagramInfluencerUserModal.module.css';
import { ReactComponent as CloseIcon } from '../../../../assets/Close.svg';
import Image from '../../../../ui/Image/Image';
import {
    asMoney,
    buildInstagramUserUrl,
    fetchAll,
    formatNumberToKNotation,
    formatPostingFrequencyFromAverage,
    getLastUpdatedLabel,
    roundTo2Dp,
    showNotification,
} from '../../../../helpers';
import { ProtectedByUserGroups } from '../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import { ReactComponent as InfoCircleIcon } from '../../../../assets/InfoCircle.svg';
import Skeleton from '../../../../ui/Skeleton/Skeleton';
import { ReactComponent as ProfileAddIcon } from '../../../../assets/ProfileAdd.svg';
import { ReactComponent as MegaphoneIcon } from '../../../../assets/Megaphone.svg';
import Tabs, { TabNav, TabNavItem, TabPanel, TabPanels } from '../../../../ui/DataDisplay/Tabs/Tabs';
import { getInstagramUser, triggerInstagramUserScraping } from '../../../Instagram/Instagram.api';
import { ReactComponent as RefreshIcon } from '../../../../assets/Refresh.svg';
import { ReactComponent as ApexLogo } from '../../../../assets/ApexLogo.svg';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import { InstagramUser, InstagramUserStats } from '../../../Instagram/Instagram.types';
import {
    InfluencerTag,
    patchInstagramInfluencerUser,
    InstagramInfluencerUser,
    InstagramInfluencerUserStats,
    getInfluencerUserInfluencerPlans,
    InfluencerUserCampaigns,
    getInstagramTopUserVideos,
    InstagramTopVideo,
    getInstagramInfluencerUser,
    InfluencerPlan,
} from '@round/api';
import EngagementChart from './Engagement/EngagementChart/EngagementChart';
import Button from '../../../../ui/Buttons/Button/Button';
import useNonNullContext from '../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../contexts/OptionsContext/OptionsContext';
import { useCheckUserGroupsAccess } from '../../../Auth/hooks/useCheckUserGroupsAccess';
import { useInfluencerTags } from '../../../../Hooks/useInfluencerTags';
import InfluencerUserTags from '../../../TikTok/containers/TiktokInfluencerExplore/components/Tags/InfluencerUserTags';
import InstagramUserTopPosts from './Engagement/InstagramUserTopPosts/InstagramUserTopPosts';
import DataCard from '../../../../ui/DataDisplay/DataCard/DataCard';
import { cpm } from '../../../Advertising/Metrics/Metrics';
import UpdateInstagramInfluencerUserForm, {
    UpdateInstagramInfluencerUserFormValues,
} from './InfluencerUserForm/UpdateInstagramInfluencerUserForm';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import fromPairs from 'lodash/fromPairs';
import differenceWith from 'lodash/differenceWith';
import { isInstagramInfluencerTableRow } from '../InstagramInfluencersTable/InstagramInfluencersTable';
import AddInstagramInfluencerToCampaign from '../../containers/AddInfluencerToCampaign/AddInstagramInfluencerToCampaign';
import Promotions from './Promotions/Promotions';
import { InstagramInfluencerPostApiBody } from '../../../Advertising/InfluencerPlan/types/Instagram.types';
import cn from 'classnames';
import InstagramUserAudienceBreakdown from './Audience/InstagramUserAudienceBreakdown';
import { ReactComponent as PeopleIcon } from '../../../../assets/People.svg';
import Lookalikes from './Lookalikes/Lookalikes';
import EditableContentTags from '../EditableContentTags/EditableContentTags';
import useInfluencerPlans from 'Modules/Advertising/InfluencerPlan/hooks/useInfluencerPlans';
import { uniq } from 'lodash';

const oneWeekAgo = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);

type InstagramInfluencerUserModalProps = {
    isModalOpen: boolean;
    closeModal: (influencerUser: InstagramInfluencerUser | null) => void;
    influencerId: number | null | undefined;
    userId?: number | null;
    profileImage?: InstagramUserImage;
    monitoredUserStats?: InstagramInfluencerUserStats | null;
    plans?: InfluencerPlan[];
};

const InstagramInfluencerUserModal = ({
    isModalOpen,
    closeModal,
    monitoredUserStats,
    influencerId,
    userId: initialUserId,
    profileImage,
    plans,
}: InstagramInfluencerUserModalProps) => {
    const { currencies } = useNonNullContext(OptionsContext);

    const [stats, setStats] = useState<InstagramUserStats[]>([]);
    const [statsLoading, setStatsLoading] = useState(false);

    const [topVideos, setTopVideos] = useState<InstagramTopVideo[]>([]);
    const [topVideosLoading, setTopVideosLoading] = useState(false);
    const [errorLoadingTopVideos, setErrorLoadingTopVideos] = useState(false);

    const [refreshLoading, setRefreshLoading] = useState(false);

    const [instagramUser, setInstagramUser] = useState<InstagramUser | null>(null);
    const [instagramUserLoading, setInstagramUserLoading] = useState(false);

    const [instagramInfluencerUser, setInstagramInfluencerUser] = useState<InstagramInfluencerUser | null>(null);
    const [instagramInfluencerUserLoading, setInstagramInfluencerUserLoading] = useState(false);

    const [showInfluencerUserEditing, setShowInfluencerUserEditing] = useState(false);
    const hasInfluencerEditorPermissions = useCheckUserGroupsAccess(['influencer_editor']);

    const [influencerUserCampaigns, setInfluencerUserCampaigns] = useState<InfluencerUserCampaigns | null>(null);

    const { fetchData: fetchInfluencerPlans, ...influencerPlansData } = useInfluencerPlans();

    useAbortableEffect(
        (signal) => {
            if (plans?.length) {
                return;
            }

            const plansToFetch = uniq(influencerUserCampaigns?.plans.map((p) => p.plan_id)) ?? [];

            if (!plansToFetch.length || influencerPlansData.status === 'initialized') {
                return;
            }

            fetchInfluencerPlans(
                { id: plansToFetch.toString(), page_size: plansToFetch.length },
                { signal }
            ).catch(() => {});
        },
        [fetchInfluencerPlans, influencerPlansData.status, influencerUserCampaigns?.plans, plans?.length]
    );

    const [activeTab, setActiveTab] = useState('audience');
    const { influencerTags } = useInfluencerTags();

    const userId = initialUserId ?? instagramInfluencerUser?.user;

    const selectedLocationTags: InfluencerTag[] = useMemo(
        () => instagramInfluencerUser?.tags?.filter((t) => t.type === 'LOCATION') ?? [],
        [instagramInfluencerUser?.tags]
    );

    const locationTags: InfluencerTag[] = useMemo(() => influencerTags.filter((t) => t.type === 'LOCATION'), [
        influencerTags,
    ]);

    const initialValues: UpdateInstagramInfluencerUserFormValues | null = useMemo(() => {
        const reelsCurrency = currencies.find((curr) => curr.id === instagramInfluencerUser?.reels_currency);
        const storyCurrency = currencies.find((curr) => curr.id === instagramInfluencerUser?.story_currency);
        const feedCurrency = currencies.find((curr) => curr.id === instagramInfluencerUser?.feed_currency);
        return {
            notes: instagramInfluencerUser?.notes,
            contact_details: instagramInfluencerUser?.contact_details,
            reels_cost: instagramInfluencerUser?.reels_cost,
            reels_currency: reelsCurrency ? { label: reelsCurrency?.name, value: reelsCurrency?.id } : null,
            story_cost: instagramInfluencerUser?.story_cost,
            story_currency: storyCurrency ? { label: storyCurrency?.name, value: storyCurrency?.id } : null,
            feed_cost: instagramInfluencerUser?.feed_cost,
            feed_currency: feedCurrency ? { label: feedCurrency?.name, value: feedCurrency?.id } : null,
            paypal_email: instagramInfluencerUser?.paypal_email,
        };
    }, [currencies, instagramInfluencerUser]);

    const handleUpdateInfluencerUser = useCallback(
        async (updatedFields: UpdateInstagramInfluencerUserFormValues) => {
            if (isEqual(updatedFields, initialValues) || !instagramInfluencerUser) {
                return;
            }

            const changedFields: Partial<UpdateInstagramInfluencerUserFormValues> = fromPairs(
                differenceWith(toPairs(updatedFields), toPairs(initialValues), isEqual)
            );
            const changedFieldsWithOptionsAsValues: Partial<InstagramInfluencerPostApiBody> = {
                ...changedFields,
                ...(changedFields.reels_currency ? { reels_currency: changedFields.reels_currency.value } : {}),
                ...(changedFields.story_currency ? { story_currency: changedFields.story_currency.value } : {}),
                ...(changedFields.feed_currency ? { feed_currency: changedFields.feed_currency.value } : {}),
            };

            try {
                const response = await patchInstagramInfluencerUser(
                    instagramInfluencerUser.id,
                    changedFieldsWithOptionsAsValues
                );
                showNotification('influencer user updated', 'info');
                if (response.status === 400) {
                    showNotification('Could not update influencer user', 'error');
                    return;
                }
                if (response.status === 200) {
                    setInstagramInfluencerUser(response.data);
                }
            } catch {
                showNotification('Could not update influencer user', 'error');
            }
        },
        [initialValues, instagramInfluencerUser]
    );

    const name = instagramUser?.name || monitoredUserStats?.name;
    const username = instagramUser?.username || instagramInfluencerUser?.username || monitoredUserStats?.username;
    const userBio = instagramUser?.bio;
    const userProfileURL = username ? buildInstagramUserUrl(username) : '';

    const lastPostDate = monitoredUserStats?.last_post_datetime
        ? new Date(monitoredUserStats?.last_post_datetime)
        : null;
    const showLastPostDateWarning = Boolean(lastPostDate && lastPostDate < oneWeekAgo);

    const lastPostDateWarningValue = showLastPostDateWarning ? 'Last post was over 7 days ago' : undefined;

    const statsLastUpdated = useMemo(() => {
        const dates = stats.map((stat) => Date.parse(stat.timestamp));
        return new Date(Math.max(...dates));
    }, [stats]);

    const followers = useMemo(() => {
        if (!stats.length) {
            return '-';
        }

        return formatNumberToKNotation(stats[stats.length - 1].follower_count);
    }, [stats]);

    const engagementRate = useMemo(() => {
        if (!monitoredUserStats || !monitoredUserStats.avg_engagement_rate) {
            return '-';
        }
        return `${roundTo2Dp(parseFloat(monitoredUserStats.avg_engagement_rate) * 100)}%`;
    }, [monitoredUserStats]);

    const estCPM = useMemo(() => {
        if (
            !monitoredUserStats ||
            (!monitoredUserStats.reels_cost && !instagramInfluencerUser?.reels_currency) ||
            typeof monitoredUserStats.reels_cost !== 'number' ||
            typeof monitoredUserStats.avg_video_plays_per_post !== 'number'
        ) {
            return '-';
        }

        const userCurrency = currencies.find(
            (curr) => curr.id === monitoredUserStats.reels_currency || instagramInfluencerUser?.reels_currency
        );

        return asMoney(cpm(monitoredUserStats.reels_cost, monitoredUserStats.avg_video_plays_per_post), userCurrency);
    }, [monitoredUserStats, currencies, instagramInfluencerUser?.reels_currency]);

    const averageVideoPlays = useMemo(() => {
        if (!monitoredUserStats || !monitoredUserStats.avg_video_plays_per_post) {
            return '-';
        }

        return formatNumberToKNotation(Number(monitoredUserStats.avg_video_plays_per_post));
    }, [monitoredUserStats]);

    const averageComments = monitoredUserStats?.avg_comments_per_post
        ? formatNumberToKNotation(monitoredUserStats.avg_comments_per_post)
        : '-';

    const postingFrequency = monitoredUserStats?.avg_posts_per_day
        ? formatPostingFrequencyFromAverage(monitoredUserStats?.avg_posts_per_day)
        : '-';

    const isInfluencerApex = !!instagramInfluencerUser?.is_apex;

    const fetchStats = useCallback(
        async (requestInit?: RequestInit) => {
            if (!userId) {
                return;
            }
            try {
                setStatsLoading(true);
                const stats = await fetchAll<InstagramUserStats>(
                    `/api/instagram/user-stats/?instagram_user_id=${userId}`,
                    requestInit
                );
                if (stats !== undefined) {
                    const sortedStats = stats.sort(
                        (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
                    );

                    const getDateString = (stats: InstagramUserStats) => {
                        return stats.timestamp.split('T')[0];
                    };

                    const shown = new Set();
                    sortedStats.reverse(); // Reverse Chronological
                    let filteredStats = sortedStats.filter((el) => {
                        const dateString = getDateString(el);
                        const duplicate = shown.has(dateString);
                        shown.add(dateString);
                        return !duplicate;
                    });
                    sortedStats.reverse();
                    if (sortedStats.length > 1 && getDateString(sortedStats[0]) === getDateString(sortedStats[1])) {
                        filteredStats.push(sortedStats[0]);
                    }

                    setStats(
                        filteredStats.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
                    );
                }
            } catch {
                //noop
            } finally {
                setStatsLoading(false);
            }
        },
        [userId]
    );

    const fetchInfluencerUser = useCallback(
        async (requestInit?: RequestInit) => {
            if (!influencerId || instagramInfluencerUser) {
                return;
            }

            try {
                setInstagramInfluencerUserLoading(true);
                const response = await getInstagramInfluencerUser(influencerId, requestInit);
                if (response.status !== 200) {
                    showNotification('Could not load influencer user', 'error');
                    return;
                }

                setInstagramInfluencerUser(response.data);
            } catch {
                //noop
            } finally {
                setInstagramInfluencerUserLoading(false);
            }
        },
        [influencerId, instagramInfluencerUser]
    );

    const fetchTopVideos = useCallback(
        async (requestInit?: RequestInit) => {
            if (!userId) {
                return;
            }
            try {
                setErrorLoadingTopVideos(false);
                setTopVideosLoading(true);
                const topVideosResponse = await getInstagramTopUserVideos(userId, requestInit);

                if (topVideosResponse.status === 404) {
                    setErrorLoadingTopVideos(true);
                    return;
                }
                if (topVideosResponse.status === 200) {
                    setTopVideos(topVideosResponse.data);
                }
            } catch {
                setErrorLoadingTopVideos(true);
            } finally {
                setTopVideosLoading(false);
            }
        },
        [userId]
    );

    const removeLocationTag = useCallback(
        async (tag: InfluencerTag) => {
            if (!instagramInfluencerUser) {
                return;
            }

            try {
                const updatedInfluencerUserResponse = await patchInstagramInfluencerUser(instagramInfluencerUser.id, {
                    tags: instagramInfluencerUser.tags?.filter((t) => t.id !== tag.id).map((t) => t.id) ?? [],
                });
                if (updatedInfluencerUserResponse.status === 200) {
                    showNotification(`Removed ${tag.name} tag`, 'info');
                    setInstagramInfluencerUser(updatedInfluencerUserResponse.data);
                }
            } catch {
                showNotification('Could not remove tag', 'error');
            }
        },
        [instagramInfluencerUser]
    );

    const addLocationTag = useCallback(
        async (tag: InfluencerTag) => {
            if (!instagramInfluencerUser) {
                return;
            }

            try {
                const updatedInfluencerUserResponse = await patchInstagramInfluencerUser(instagramInfluencerUser.id, {
                    tags: [...(instagramInfluencerUser.tags?.map((tag) => tag.id) ?? []), tag.id],
                });
                if (updatedInfluencerUserResponse.status === 200) {
                    showNotification(`Added ${tag.name} tag`, 'info');
                    setInstagramInfluencerUser(updatedInfluencerUserResponse.data);
                }
            } catch {
                showNotification('Could not add tag', 'error');
            }
        },
        [instagramInfluencerUser]
    );

    const handleUpdateContentTags = useCallback(
        async (tagIds: number[]) => {
            if (!instagramInfluencerUser) {
                return;
            }

            try {
                const updatedInfluencerUserResponse = await patchInstagramInfluencerUser(instagramInfluencerUser.id, {
                    content_tags: tagIds,
                });
                if (updatedInfluencerUserResponse.status === 200) {
                    showNotification(`Updated content tags`, 'info');
                    setInstagramInfluencerUser(updatedInfluencerUserResponse.data);
                }
            } catch {
                showNotification('Could not update content tags', 'error');
            }
        },
        [instagramInfluencerUser]
    );

    const onClose = useCallback(() => {
        setActiveTab('audience');
        closeModal(instagramInfluencerUser);
    }, [closeModal, instagramInfluencerUser]);

    //we dont have instagram scraping on demand currently.
    //This function and the relevant UI element are disabled until then.
    //eslint-disable-next-line
    const refreshStats = useCallback(async () => {
        if (!userId || !instagramInfluencerUser) {
            return;
        }

        try {
            setRefreshLoading(true);
            await triggerInstagramUserScraping(instagramInfluencerUser.monitored_user);
            await Promise.all([fetchStats(), fetchTopVideos()]);
            showNotification('Refresh successful', 'info');
        } catch {
            showNotification('Refresh unsuccessful', 'error');
        } finally {
            setRefreshLoading(false);
        }
    }, [fetchStats, fetchTopVideos, instagramInfluencerUser, userId]);

    const fetchInstagramUser = useCallback(
        async (requestInit?: RequestInit) => {
            if (typeof instagramInfluencerUser?.user !== 'number') {
                return;
            }

            try {
                setInstagramUserLoading(true);
                const response = await getInstagramUser(instagramInfluencerUser.user, requestInit);
                if (response.status === 200) {
                    setInstagramUser(response.data);
                }
            } catch {
                // no-op
            } finally {
                setInstagramUserLoading(false);
            }
        },
        [instagramInfluencerUser?.user]
    );

    useAbortableEffect(
        (signal) => {
            fetchStats({ signal });
            fetchInfluencerUser({ signal });
            fetchTopVideos({ signal });
        },
        [fetchInfluencerUser, fetchStats, fetchTopVideos]
    );

    useAbortableEffect(
        (signal) => {
            fetchInstagramUser({ signal });
        },
        [fetchInstagramUser]
    );

    useEffect(() => {
        if (!isModalOpen) {
            setInstagramUser(null);
            setInstagramInfluencerUser(null);
            setStats([]);
            setTopVideos([]);
        }
    }, [isModalOpen]);

    const fetchInfluencerUserCampaigns = useCallback(async () => {
        if (!influencerId) {
            return;
        }

        try {
            const response = await getInfluencerUserInfluencerPlans({
                instagram_influencer_users: influencerId.toString(),
            });

            if (response.status === 200) {
                const [campaigns] = response.data;
                setInfluencerUserCampaigns(campaigns ?? null);
            }
        } catch {
            // no-op
        }
    }, [influencerId]);

    useEffect(() => {
        if (monitoredUserStats && isInstagramInfluencerTableRow(monitoredUserStats)) {
            setInfluencerUserCampaigns(monitoredUserStats.campaigns ?? null);
            return;
        }

        fetchInfluencerUserCampaigns();
    }, [fetchInfluencerUserCampaigns, monitoredUserStats]);

    return (
        <ModalRight isModalOpen={isModalOpen} closeModal={onClose} className={styles.modal}>
            <div className={styles.modalContent}>
                <button className={styles.closeButton} onClick={onClose}>
                    <CloseIcon className={styles.closeIcon} />
                </button>
                <div className={styles.influencerUserInfo}>
                    <div className={styles.headingBar}>
                        <Image
                            src={profileImage?.avatar_thumb.cached_url || profileImage?.avatar_thumb.original_url}
                            alt="user"
                            className={styles.influencerUserImage}
                        />
                        <div className={styles.headingBarContent}>
                            {instagramUserLoading ? (
                                <>
                                    <Skeleton className={styles.userInfoSkeleton} />
                                    <Skeleton className={styles.userInfoSkeleton} />
                                    <Skeleton className={styles.userInfoSkeleton} />
                                </>
                            ) : (
                                <>
                                    {name && <div className={styles.influencerUserName}>{name}</div>}
                                    {username && (
                                        <div className={styles.influencerUserAccount}>
                                            <a href={userProfileURL} target="_blank" rel="noopener noreferrer">
                                                @{username}
                                            </a>
                                        </div>
                                    )}
                                    {userBio && <div className={styles.influencerUserBio}>{userBio}</div>}
                                </>
                            )}

                            <div className={styles.tags}>
                                {instagramInfluencerUserLoading ? (
                                    <>
                                        <Skeleton className={styles.tagSkeleton} />
                                        <Skeleton className={styles.tagSkeleton} />
                                    </>
                                ) : (
                                    <>
                                        <div className={styles.tagsContainer}>
                                            <span className={styles.tagsLabel}>Content</span>
                                            <EditableContentTags
                                                tagIds={instagramInfluencerUser?.content_tags ?? []}
                                                onChange={handleUpdateContentTags}
                                                readonly={!hasInfluencerEditorPermissions}
                                            />
                                        </div>

                                        <InfluencerUserTags
                                            readonly={!hasInfluencerEditorPermissions}
                                            selected={selectedLocationTags}
                                            tags={locationTags}
                                            type="LOCATION"
                                            selectTag={addLocationTag}
                                            deleteTag={removeLocationTag}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={styles.headerActions}>
                            <ProtectedByUserGroups groups={['influencer_editor', 'user_admin']}>
                                {isInfluencerApex && (
                                    <div className={styles.apexLogoContainer}>
                                        <ApexLogo />
                                    </div>
                                )}
                                <Button
                                    type="bordered"
                                    disabled={!instagramInfluencerUser}
                                    className={styles.influencerUserEditButton}
                                    onClick={() => setShowInfluencerUserEditing(!showInfluencerUserEditing)}
                                >
                                    <InfoCircleIcon className={styles.infoIcon} />
                                </Button>
                                <AddInstagramInfluencerToCampaign
                                    className={styles.addToCampaignButton}
                                    influencerCampaigns={influencerUserCampaigns}
                                    plans={plans ?? influencerPlansData.data?.results ?? []}
                                    influencerUserStats={monitoredUserStats}
                                    onInfluencerAddedToCampaign={() => {
                                        fetchInfluencerUserCampaigns().then(() => {
                                            influencerPlansData.reset();
                                        });
                                    }}
                                >
                                    + Add to campaign
                                </AddInstagramInfluencerToCampaign>
                            </ProtectedByUserGroups>
                            {
                                //We dont currently have on demand instagram scraping. This element and the associated function are disabled.
                            }
                            {false && influencerUserCampaigns && (
                                <ProtectedByUserGroups groups={['user_admin']}>
                                    <Button
                                        className={styles.refreshButton}
                                        type="bordered"
                                        loading={refreshLoading}
                                        onClick={refreshStats}
                                        hint={`Last updated: ${getLastUpdatedLabel(statsLastUpdated)}`}
                                    >
                                        <RefreshIcon className={styles.refreshButtonIcon} />
                                        Refresh Data
                                    </Button>
                                </ProtectedByUserGroups>
                            )}
                        </div>
                    </div>
                    {showInfluencerUserEditing && (
                        <ProtectedByUserGroups groups={['influencer_editor', 'user_admin']}>
                            <UpdateInstagramInfluencerUserForm
                                handleSubmit={handleUpdateInfluencerUser}
                                initialValues={initialValues}
                            />
                        </ProtectedByUserGroups>
                    )}
                </div>

                <div className={styles.statsContainer}>
                    <DataCard title="Followers" value={followers} loading={statsLoading} />
                    <DataCard title="Engagement Rate" value={engagementRate} />
                    <DataCard title="Est. CPM" value={estCPM} />
                    <DataCard title="Avg. Video Plays" value={averageVideoPlays} />
                    <DataCard
                        title="Posting Frequency"
                        value={postingFrequency}
                        additionalValue={
                            showLastPostDateWarning ? (
                                <InfoCircleIcon className={cn(styles.infoIcon, styles.warning)} />
                            ) : null
                        }
                        valuesClassName={cn(styles.cardValue, { [styles.warning]: showLastPostDateWarning })}
                        tooltipValue={lastPostDateWarningValue}
                    />
                    <DataCard title="Avg. Comments" value={averageComments} />
                </div>

                <Tabs activeTab={activeTab} onChange={setActiveTab}>
                    <TabNav containerClassName={styles.tabNav}>
                        <TabNavItem name="audience" className={styles.tabNavItem}>
                            <PeopleIcon width="1.25rem" className={styles.tabNavItemIcon} />
                            Audience
                        </TabNavItem>
                        <TabNavItem name="engagement" className={styles.tabNavItem}>
                            <ProfileAddIcon className={styles.tabNavItemIcon} />
                            Engagement
                        </TabNavItem>
                        <TabNavItem name="promotions" className={styles.tabNavItem}>
                            <MegaphoneIcon className={styles.tabNavItemIcon} />
                            Promotions
                        </TabNavItem>
                        <TabNavItem name="lookalikes" className={styles.tabNavItem}>
                            Lookalikes
                        </TabNavItem>
                    </TabNav>

                    <TabPanels>
                        <TabPanel name="audience">
                            <InstagramUserAudienceBreakdown userId={userId} />
                        </TabPanel>
                        <TabPanel name="engagement">
                            <EngagementChart stats={stats} loading={statsLoading} />
                            <InstagramUserTopPosts
                                topVideos={topVideos}
                                loading={topVideosLoading}
                                errorLoadingTopVideos={errorLoadingTopVideos}
                            />
                        </TabPanel>
                        <TabPanel name="promotions">
                            <Promotions influencerUserId={instagramInfluencerUser?.id} userId={userId} />
                        </TabPanel>
                        <TabPanel name="lookalikes">
                            <Lookalikes userId={userId} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        </ModalRight>
    );
};

export default InstagramInfluencerUserModal;
