import { ReactNode } from 'react';
import cn from 'classnames';
import { Skeleton } from '@round/ui-kit';
import useOverflowingChildren from 'Hooks/useOverflowingChildren';
import styles from './OverflowCircleBadgeContainer.module.css';
import CircleBadge from '../CircleBadge/CircleBadge';

type Props = {
    isLoading?: boolean;
    skeletonCount?: number;
    className?: string;
    children?: ReactNode;
    skeletonClassName?: string;
    overflowingCountBadgeClassName?: string;
};

const OverflowCircleBadgeContainer = ({
    isLoading,
    skeletonCount = 3,
    className,
    children,
    skeletonClassName,
    overflowingCountBadgeClassName,
}: Props) => {
    const { ref, overflowingChildren } = useOverflowingChildren();
    const overflowingChildrenCount = overflowingChildren.length;

    const skeletons = new Array(skeletonCount)
        .fill(undefined)
        .map((_, index) => (
            <Skeleton containerClassName={skeletonClassName} circle key={index} width="2rem" height="2rem" />
        ));

    return (
        <div ref={ref} className={cn(styles.values, className)}>
            {isLoading ? skeletons : children}
            {overflowingChildrenCount > 0 && (
                <CircleBadge
                    data-overflowing="ignore"
                    className={cn(styles.overflowingCountBadge, overflowingCountBadgeClassName)}
                >
                    +{overflowingChildrenCount}
                </CircleBadge>
            )}
        </div>
    );
};

export default OverflowCircleBadgeContainer;
