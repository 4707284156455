import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import styles from './Tooltip.module.css';
import { Popover } from '@round/ui-kit';
import { useRectObserver } from 'Hooks/useRectObserver';
import cn from 'classnames';
import { Placement } from '@popperjs/core';

type Props = {
    anchorElement: HTMLElement | SVGSVGElement | null;
    placement?: Placement;
    disabled?: boolean;
    className?: string;
    children?: React.ReactNode;
};

const Tooltip = ({ anchorElement, placement = 'top', disabled, children, className }: Props) => {
    const [contentRef, setContentRef] = useState<HTMLElement | null>(null);
    const contentRect = useRectObserver(contentRef);

    return (
        <Popover
            anchorElement={anchorElement}
            disabled={disabled}
            showOn="hover"
            options={{
                placement,
                modifiers: [
                    { name: 'offset', options: { offset: [0, 10] } },
                    { name: 'recalcDependency', options: contentRect ? [contentRect.width, contentRect.height] : [] },
                ],
            }}
            overlayClassName={styles.overlay}
        >
            <div className={cn(styles.tooltip, className)} ref={setContentRef}>
                {children}
            </div>
            <div className={styles.arrow} data-popper-arrow />
        </Popover>
    );
};

const Root = ({
    children,
}: {
    children: (
        ref: HTMLElement | SVGSVGElement | null,
        setRef: Dispatch<SetStateAction<HTMLElement | SVGSVGElement | null>>,
        underlineClassName: string
    ) => React.ReactNode;
}) => {
    const [anchorElement, setAnchorElement] = useState<HTMLElement | SVGSVGElement | null>(null);
    return <>{children(anchorElement, setAnchorElement, styles.underline)}</>;
};

const Title = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
    <div className={cn(styles.title, className)}>{children}</div>
);

const Body = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
    <div className={cn(styles.body, className)}>{children}</div>
);

Tooltip.Title = Title;
Tooltip.Body = Body;
Tooltip.Root = Root;

export default Tooltip;
