import { creatorbase } from '@round/api';
import { useAbortSignal } from '@round/utils';
import { useProjectDetails } from '../../contexts/ProjectContext';
import { useCampaigns } from './useCampaigns';

export function useReorderProjectCampaigns(platform: creatorbase.Platform) {
    const { updateProject } = useProjectDetails();
    const { reorderCampaigns } = useCampaigns();

    const { getSignal, resetSignal } = useAbortSignal();

    return async (newOrder: number[], prevOrder: number[]) => {
        try {
            resetSignal();

            reorderCampaigns(platform, newOrder);

            const response = await updateProject(
                {
                    [`${platform}_campaign_ids_order`]: newOrder,
                },
                { signal: getSignal() }
            );

            if (response?.status !== 200) {
                reorderCampaigns(platform, prevOrder);
            }

            return response;
        } catch (e) {
            if (e instanceof Error && e.name !== 'AbortError') {
                reorderCampaigns(platform, prevOrder);
            }

            throw e;
        }
    };
}
