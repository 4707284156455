import { creatorbase } from '@round/api';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { CellContext } from '@tanstack/react-table';
import { formatPostStatsCellValue } from 'Modules/Plans/Posts/helpers';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';

type Row = creatorbase.PublicPost;

type Context<T extends Row> = CellContext<T, number | null | undefined>;

const getTableMeta = getTableMetaHelper<BasePostTableMeta<Row>>();

const PostStatsValueCell = <T extends Row>({ row, table, getValue }: Context<T>) => {
    const { isLoading, getAreStatsLoading } = getTableMeta(table);
    const areStatsLoading = getAreStatsLoading ? getAreStatsLoading(row.original) : false;

    if (isLoading || areStatsLoading) {
        return <Skeleton />;
    }

    return formatPostStatsCellValue(getValue());
};

export default PostStatsValueCell;
