import WrapperTable from 'ui/WrapperTable/WrapperTable';
import { OutstandingPaymentsTableRow } from '../../../OutstandingPaymentsTable/OutstandingPaymentsTable';
import { TableProps } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import Account from 'Modules/Finance/Payments/components/Account/Account';
import { showNotification } from 'helpers';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import styles from './BulkPaymentTable.module.css';
import { useEffect, useMemo, useState } from 'react';
import TruncatedTextCell from 'SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import { PaymentResults } from '../BulkPaymentModal';
import LoadingSpinner from 'SharedComponents/LoadingSpinner/LoadingSpinner';
import { ReactComponent as SuccessIcon } from 'assets/TickCircle.svg';
import { ReactComponent as FailureIcon } from 'assets/CloseCircle.svg';
import { UseOutstandingPaymentsReturn } from '../../../useOutstandingPayments';
import CostLabel from 'ui/DataDisplay/Money/CostLabel/CostLabel';
import {
    mapInstagramUserToAccountData,
    mapTiktokUserToAccountData,
} from 'Modules/Finance/Payments/components/Account/helpers';

type Props = Pick<TableProps<OutstandingPaymentsTableRow>, 'data'> & {
    updatePaymentRequest: UseOutstandingPaymentsReturn['update'];
    paymentResults: PaymentResults;
    isPaymentLoading: boolean;
};

type TableCellContext<K extends keyof OutstandingPaymentsTableRow> = CellContext<
    OutstandingPaymentsTableRow,
    OutstandingPaymentsTableRow[K]
>;

const BulkPaymentTable = ({ data, updatePaymentRequest, paymentResults, isPaymentLoading }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);

    const columns: ColumnDef<OutstandingPaymentsTableRow, any>[] = useMemo(
        () => [
            {
                id: 'account',
                header: 'Account',
                cell: ({ row: { original } }) => {
                    if (!original.tiktokUser && !original.instagramUser && !original.youtubeInfluencerPost) {
                        return '-';
                    }

                    const accountData = original.tiktokUser
                        ? mapTiktokUserToAccountData(original.tiktokUser)
                        : original.instagramUser
                        ? mapInstagramUserToAccountData(original.instagramUser, original.instagramUserImage)
                        : original.youtubeInfluencerPost
                        ? //using channel name from post until we can fetch channels
                          {
                              imageUrl: '',
                              nickname: original.youtubeInfluencerPost.channel_title || '-',
                              username: '',
                              profileUrl: '',
                          }
                        : undefined;

                    return <Account user={accountData} />;
                },
            },
            {
                accessorKey: 'amount',
                header: 'Cost',
                cell: ({ getValue, row: { original } }: TableCellContext<'amount'>) => {
                    const currency = currencies.find((c) => c.id === original.currency);
                    return <CostLabel cost={getValue()} currencySymbol={currency?.symbol ?? ''} />;
                },
            },
            {
                accessorKey: 'paypal_email_address',
                header: 'PayPal email',
                cell: ({ getValue }: TableCellContext<'paypal_email_address'>) => {
                    return getValue() || '-';
                },
            },
            {
                accessorKey: 'payment_reference',
                header: 'Payment Reference',
                cell: function PaymentReference({
                    getValue,
                    row: { original },
                }: TableCellContext<'payment_reference'>) {
                    const [reference, setReference] = useState(getValue() ?? '');
                    const [isEditing, setIsEditing] = useState(false);

                    useEffect(() => {
                        setReference(original.payment_reference ?? '');
                    }, [original.payment_reference]);

                    if (!isEditing) {
                        return (
                            <TruncatedTextCell
                                onDoubleClick={() => {
                                    const result = paymentResults[original.id];
                                    if (result) {
                                        return;
                                    }

                                    setIsEditing(true);
                                }}
                            >
                                {reference || '-'}
                            </TruncatedTextCell>
                        );
                    }

                    const save = () => {
                        if (reference === original.payment_reference) {
                            setIsEditing(false);
                            return;
                        }

                        updatePaymentRequest(original.id, { payment_reference: reference })
                            .then((response) => {
                                if (response.status === 200) {
                                    showNotification('Updated', 'info');
                                    return;
                                }

                                const errorMessage =
                                    response.status === 400
                                        ? Object.values(response.data).join(', ')
                                        : response.data.detail;
                                showNotification(errorMessage, 'error');
                            })
                            .catch(() => showNotification('Could not update reference', 'error'))
                            .finally(() => setIsEditing(false));
                    };

                    return (
                        <input
                            autoFocus
                            className={styles.reference}
                            readOnly={!isEditing}
                            value={reference}
                            onChange={(e) => setReference(e.target.value)}
                            onBlur={save}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    save();
                                }
                            }}
                        />
                    );
                },
            },
            {
                id: 'status',
                header: 'Status',
                cell: ({ row: { original } }) => {
                    if (isPaymentLoading) {
                        return (
                            <LoadingSpinner
                                spinnerClassName={styles.spinner}
                                containerClassName={styles.spinnerContainer}
                            />
                        );
                    }

                    const result = paymentResults[original.id];
                    if (!result) {
                        return 'Unpaid';
                    }

                    if (result.success) {
                        return (
                            <div className={styles.statusContainer}>
                                <SuccessIcon className={styles.success} />
                                <span>Success</span>
                            </div>
                        );
                    }

                    return (
                        <div className={styles.statusContainer}>
                            <FailureIcon className={styles.failure} />
                            <span>{result.error}</span>
                        </div>
                    );
                },
            },
        ],
        [currencies, isPaymentLoading, paymentResults, updatePaymentRequest]
    );

    return <WrapperTable className={styles.table} data={data} columns={columns} />;
};

export default BulkPaymentTable;
