import { creatorbase } from '@round/api';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { CellContext } from '@tanstack/react-table';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import { Image, SkeletonizedValue } from '@round/ui-kit';
import styles from './PostsTableAccountCell.module.css';
import { formatNumberToKNotation } from 'helpers';

type Row = creatorbase.PublicPost;
export type AccountData = {
    image: string | undefined;
    title: string;
    followerCount?: number | null;
};

type Context<T extends Row> = CellContext<T, AccountData>;

const getTableMeta = getTableMetaHelper<BasePostTableMeta<Row>>();

const PostsTableAccountCell = <T extends Row>({ row, table, getValue }: Context<T>) => {
    const { isLoading, getIsAccountDataLoading } = getTableMeta(table);

    if (isLoading || getIsAccountDataLoading(row.original)) {
        return <Skeleton />;
    }

    const { image, title, followerCount } = getValue();

    return (
        <div className={styles.container}>
            <Image loading={isLoading} src={image} className={styles.image} />
            <div className={styles.details}>
                <p>
                    <SkeletonizedValue isInitialized={!isLoading}>{title}</SkeletonizedValue>
                </p>
                <p className={styles.followerCount}>
                    <SkeletonizedValue isInitialized={!isLoading}>
                        {typeof followerCount === 'number' ? formatNumberToKNotation(followerCount) : '-'}
                    </SkeletonizedValue>
                </p>
            </div>
        </div>
    );
};

export default PostsTableAccountCell;
