import { DatePicker, DatePickerProps } from '@round/ui-kit';
import Dropdown from 'ui-new/Dropdown/Dropdown';
import FilterBadgeClearable from 'ui/Filters/FilterBadge/FilterBadgeClearable/FilterBadgeClearable';
import styles from './DateRangeFilter.module.css';
import FilterHeader from 'ui/Filters/FilterHeader/FilterHeader';
import { ReactComponent as CalendarIcon } from 'assets/icons/Calendar.svg';
import { ReactComponent as CalendarOutlineIcon } from 'assets/icons/CalendarOutline.svg';
import moment from 'moment';
import cn from 'classnames';

type Props = Omit<DatePickerProps<true>, 'selectRange' | 'onChange'> & {
    name: string;
    onClear: () => void;
    onChange: (value: DatePickerProps<true>['value']) => void;
    renderValue?: (value: DatePickerProps<true>['value']) => React.ReactNode;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
};

export const DateRangeFilterIcon = <CalendarIcon className={styles.filterIcon} />;

const defaultRenderValue = (value: DatePickerProps<true>['value'], name: string) => {
    return (
        <div className={styles.renderValue}>
            <span>{name}:</span>
            {value ? (
                <span className={styles.dateValue}>
                    {`${moment(value[0]).format('DD.MM.YY')} - ${moment(value[1]).format('DD.MM.YY')}`}
                </span>
            ) : (
                <span>None</span>
            )}
        </div>
    );
};

export const DateRangeFilter = ({ value, onChange, renderValue, onClear, name, isOpen, onOpen, onClose }: Props) => {
    const fromDisplayValue = moment(value?.[0]).format('MMM D, YYYY');
    const toDisplayValue = moment(value?.[1]).format('MMM D, YYYY');
    const todayDisplayValue = moment().format('MMM D, YYYY');

    return (
        <Dropdown
            isOpen={isOpen}
            onClose={onClose}
            control={
                <FilterBadgeClearable onClick={onOpen} onClear={onClear}>
                    {renderValue ? renderValue(value) : defaultRenderValue(value, name)}
                </FilterBadgeClearable>
            }
            className={styles.container}
        >
            <div className={styles.selectionContainer}>
                <FilterHeader
                    name={name}
                    icon={DateRangeFilterIcon}
                    hasValue={!!value}
                    onReset={() => onChange(null)}
                />
                <div className={styles.selectionsRow}>
                    <div className={cn(styles.selection, { [styles.active]: !!value })}>
                        <span>{value?.[0] ? fromDisplayValue : todayDisplayValue}</span>
                        <CalendarOutlineIcon className={styles.selectionIcon} />
                    </div>

                    <span>to</span>

                    <div className={cn(styles.selection, { [styles.active]: !!value })}>
                        <span>{value?.[1] ? toDisplayValue : todayDisplayValue}</span>
                        <CalendarOutlineIcon className={styles.selectionIcon} />
                    </div>
                </div>
            </div>

            <DatePicker selectRange value={value} onChange={onChange} className={styles.calendar} />
        </Dropdown>
    );
};
