import { PaginatedApiResponseData, PaginatedRequest, PlaylistReportItem } from '../../../../App.types';
import { ApiResponse } from '../../../../utility/utility.types';
import { ApiResponseError, encodeUrlSearchParams, fetchWithToken } from '@round/api';

type GetYoutubePlaylistReportItemsParams = Partial<PaginatedRequest>;
export async function getYoutubePlaylistReportItems(
    params: GetYoutubePlaylistReportItemsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<PlaylistReportItem>, 200>> {
    const response = await fetchWithToken(
        `/api/youtube/viewsets/playlist-report-item/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not get youtube playlist report items');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type YoutubePlaylistReportItemApiModel = {
    media_plan_item_id: number;
    amount_spent: number;
};

type PostYoutubePlaylistReportItemResponse =
    | ApiResponse<PlaylistReportItem, 200>
    | ApiResponse<ApiResponseError<YoutubePlaylistReportItemApiModel>, 400>;

export async function postYoutubePlaylistReportItem(
    data: YoutubePlaylistReportItemApiModel
): Promise<PostYoutubePlaylistReportItemResponse> {
    const response = await fetchWithToken('/api/youtube/viewsets/playlist-report-item/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create youtube playlist report item');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PatchYoutubePlaylistReportItemResponse =
    | ApiResponse<PlaylistReportItem, 200>
    | ApiResponse<ApiResponseError<YoutubePlaylistReportItemApiModel>, 400>
    | ApiResponse<{ detail: string }, 404>;

export async function patchYoutubePlaylistReportItem(
    id: number,
    data: Partial<YoutubePlaylistReportItemApiModel>
): Promise<PatchYoutubePlaylistReportItemResponse> {
    const response = await fetchWithToken(`/api/youtube/viewsets/playlist-report-item/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not patch youtube playlist report item ${id}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type DeleteYoutubePlaylistReportItemResponse = ApiResponse<null, 204> | ApiResponse<{ detail: string }, 404>;
export async function deleteYoutubePlaylistReportItem(id: number): Promise<DeleteYoutubePlaylistReportItemResponse> {
    const response = await fetchWithToken(`/api/youtube/viewsets/playlist-report-item/${id}/`, { method: 'DELETE' });

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not delete youtube playlist report item ${id}`);
    }

    return {
        status: 204,
        data: null,
    };
}
