import { creatorbase } from '@round/api';
import { GenericDropdownOption } from 'App.types';
import { OptionsParams, UseSingleValueSelectParams, useSelect } from 'Hooks/useSelect';
import { useMemo } from 'react';

export type TeamOption = GenericDropdownOption<number>;

export const mapTeamToOption = (team: creatorbase.Team): TeamOption => ({
    value: team.id,
    label: team.name,
});

type Params = Omit<UseSingleValueSelectParams<TeamOption>, 'fetchOptions' | 'isMulti'> & {
    fetchOptions?: UseSingleValueSelectParams<TeamOption>['fetchOptions'];
};

const fetchOptions = async (params: creatorbase.GetTeamsParams, requestInit?: RequestInit) => {
    const response = await creatorbase.getTeams(params, requestInit);

    if (response.status === 404 || response.status === 403) {
        throw new Error(response.data.detail);
    }

    return {
        ...response.data,
        results: response.data.results.map(mapTeamToOption),
    };
};

export const buildTeamSelectFetchOptions = (usersParams: creatorbase.GetTeamsParams) => (
    params: OptionsParams,
    requestInit?: RequestInit
) => fetchOptions({ ...usersParams, ...params }, requestInit);

export default function useTeamSelect(params: Params) {
    const defaultFetchOptions = useMemo(() => buildTeamSelectFetchOptions({}), []);
    const fetchOptions = params.fetchOptions || defaultFetchOptions;

    return useSelect<TeamOption>({ isMulti: false, fetchOptions, ...params });
}
