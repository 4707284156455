import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import { ApiResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { Release } from '../release';

export async function getRelease(
    id: number,
    requestInit?: RequestInit
): Promise<ApiResponse<Release, 200> | ApiResponse<{ detail: string }, 404>> {
    const response = await fetchWithToken(`/api/advertising/viewsets/release/${id}/`, requestInit);

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`could not get release with id ${id}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type GetReleasesParams = Partial<PaginatedRequest & { search: string; id: string }>;
export async function getReleases(
    params: GetReleasesParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<Release>, 200>> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/release/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not fetch releases');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
