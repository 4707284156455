import {
    deleteYoutubeInfluencerPost,
    getAllYoutubeInfluencerPosts,
    getYoutubeInfluencerPostGroupLiveStats,
    patchYoutubeInfluencerPost,
    postYoutubeInfluencerPost,
    youtube,
    YoutubeInfluencerPostBody,
    YoutubeInfluencerPostPatchData,
} from '@round/api';
import useNonNullContext from 'Hooks/useNonNullContext';
import { uniq } from 'lodash';
import { YoutubeInfluencerPostTableRow } from 'Modules/Advertising/InfluencerPlan/features/YoutubeTable/components/YoutubeInfluencerPostsTable/YoutubeInfluencerPostsTable';
import { useCallback, useContext, useMemo } from 'react';
import { isNumber } from 'utility/utility';
import { InfluencerPlanContext } from '../../InfluencerPlanContext';
import { YoutubeCreatorsStateContext, YoutubeCreatorsDispatchContext } from '../YoutubeCreatorsContext';

export default function useYoutubeInfluencerPosts() {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const state = useNonNullContext(YoutubeCreatorsStateContext);
    const dispatch = useNonNullContext(YoutubeCreatorsDispatchContext);

    const fetchData = useCallback(
        async (requestInit?: RequestInit) => {
            if (!influencerPlan) {
                return;
            }

            try {
                dispatch({ type: 'loadYoutubeInfluencerPosts' });

                const influencerPosts = await getAllYoutubeInfluencerPosts({ plan_id: influencerPlan.id }, requestInit);
                const videosToFetch = uniq(influencerPosts.map((post) => post.video_id).filter(isNumber));
                const videos = videosToFetch.length
                    ? await youtube
                          .getYoutubeVideos(
                              { id: videosToFetch.join(','), page_size: videosToFetch.length },
                              requestInit
                          )
                          .then((response) => (response.status === 200 ? response.data.results : []))
                    : [];

                dispatch({
                    type: 'setIsYoutubeInfluencerPostsInitialized',
                    payload: { youtubeInfluencerPosts: influencerPosts, youtubeVideos: videos },
                });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({
                    type: 'setYoutubeInfluencerPostsLoadingError',
                    payload: "Sorry, we're experiencing technical issues",
                });

                throw e;
            }
        },
        [dispatch, influencerPlan]
    );

    const createPost = useCallback(
        async (data: Omit<YoutubeInfluencerPostBody, 'plan_id'>) => {
            if (!influencerPlan) {
                return;
            }

            const response = await postYoutubeInfluencerPost({ ...data, plan_id: influencerPlan.id });
            if (response.status !== 201) {
                return response;
            }

            if (response.data.group_id) {
                await getYoutubeInfluencerPostGroupLiveStats({
                    id: response.data.group_id.toString(),
                })
                    .then((groupStatsResponse) => {
                        if (groupStatsResponse.status === 200) {
                            dispatch({
                                type: 'setIsYoutubeInfluencerPostGroupStatsInitialized',
                                payload: (
                                    state.youtubeInfluencerPostGroupStats?.filter(
                                        (stat) => stat.group_id !== groupStatsResponse.data[0].group_id
                                    ) ?? []
                                ).concat(groupStatsResponse.data[0]),
                            });
                        }
                    })
                    .catch(() => {
                        //noop
                    });
            }

            let video: youtube.YoutubeVideo | null = null;
            if (response.data.video_id) {
                await youtube
                    .getYoutubeVideos({ id: response.data.video_id.toString(), page_size: 1 })
                    .then((videoResponse) => {
                        video = videoResponse?.status === 200 ? videoResponse.data.results[0] ?? null : null;
                    })
                    .catch(() => {
                        //noop
                    });
            }

            dispatch({ type: 'youtubeInfluencerPostCreated', payload: { post: response.data, video } });
            dispatch({ type: 'resetYoutubeInfluencerPlanStats' });
            return response;
        },
        [dispatch, influencerPlan, state.youtubeInfluencerPostGroupStats]
    );

    const deletePost = useCallback(
        async (postId: number) => {
            const response = await deleteYoutubeInfluencerPost(postId);
            if (response.status === 204) {
                dispatch({ type: 'youtubeInfluencerPostDeleted', payload: postId });
            }

            return response;
        },
        [dispatch]
    );

    const updatePost = useCallback(
        async (postId: number, data: Partial<YoutubeInfluencerPostPatchData>) => {
            const currentVideoId = state.youtubeInfluencerPosts?.find((post) => post.id === postId)?.video_id;

            const response = await patchYoutubeInfluencerPost(postId, data);
            if (response.status !== 200) {
                return response;
            }

            if (response.data.video_id === currentVideoId) {
                dispatch({ type: 'youtubeInfluencerPostUpdated', payload: { post: response.data } });
                return response;
            }

            if (response.data.group_id) {
                await getYoutubeInfluencerPostGroupLiveStats({
                    id: response.data.group_id.toString(),
                })
                    .then((groupStatsResponse) => {
                        if (groupStatsResponse.status === 200) {
                            dispatch({
                                type: 'setIsYoutubeInfluencerPostGroupStatsInitialized',
                                payload: (
                                    state.youtubeInfluencerPostGroupStats?.filter(
                                        (stat) => stat.group_id !== groupStatsResponse.data[0].group_id
                                    ) ?? []
                                ).concat(groupStatsResponse.data[0]),
                            });
                        }
                    })
                    .catch(() => {
                        //noop
                    });
            }

            let video: youtube.YoutubeVideo | null = null;

            if (response.data.video_id) {
                await youtube
                    .getYoutubeVideos({ id: response.data.video_id.toString(), page_size: 1 })
                    .then((videoResponse) => {
                        if (videoResponse.status === 200) {
                            video = videoResponse.data.results[0] ?? null;
                        }
                    })
                    .catch(() => {
                        //noop
                    });
            }

            dispatch({ type: 'youtubeInfluencerPostUpdated', payload: { post: response.data, video } });
            return response;
        },
        [dispatch, state.youtubeInfluencerPosts, state.youtubeInfluencerPostGroupStats]
    );

    const data: YoutubeInfluencerPostTableRow[] = useMemo(
        () =>
            state.youtubeInfluencerPosts?.map((post) => {
                const video = state.youtubeVideos?.find((video) => video.id === post.video_id);
                return { ...post, video };
            }) ?? [],
        [state.youtubeInfluencerPosts, state.youtubeVideos]
    );

    return {
        isLoading: state.isYouTubeInfluencerPostsLoading,
        isInitialized: state.isYouTubeInfluencerPostsInitialized,
        error: state.youtubeInfluencerPostsLoadingError,
        influencerPosts: data,

        fetchData,
        createPost,
        deletePost,
        updatePost,
    };
}
