import React, { useEffect, useMemo, useState } from 'react';
import Table from '../../../../../../ui/Table/Table';
import { Column } from '../../../../../../ui/Table/Table.types';
import { isTwitterReportItem, ReportItem } from '../../../types/MediaPlanResults.types';
import {
    asMoney,
    asMoneyWithoutZeros,
    displayOptionalNumericTableValue,
    numberWithCommas,
    roundTo2Dp,
} from '../../../../../../helpers';
import ReactTooltip from 'react-tooltip';
import { MediaPlanItemRow } from '../../../../../../App.types';
import styles from '../MediaPlanResultsModal.module.css';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { AdvertisingContext } from '../../../contexts/AdvertisingContext/AdvertisingContext';
import TargetingCreativeExpandBar from '../TargetingCreativeExpandBar/TargetingCreativeExpandBar';
import cn from 'classnames';
import ProgressBar from '../../../../../../ui/ProgressBar/ProgressBar';
import { createReportItem, getTotalReportItemValues } from '../../../helpers/MediaPlanResult.helpers';
import { presetOptions, SelectableMetrics } from '../PresetsSelect/presets';
import PresetsSelect from '../PresetsSelect/PresetsSelect';
import uniq from 'lodash/uniq';
import TruncatedTextCell from 'SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import { sortNumerically } from '@round/utils';
import { toDecimalPoint } from '@round/utils';

type TargetingBreakdownProps = {
    mediaPlanItem: MediaPlanItemRow;
    onSelectedMetricsChange: (mediaPlanItemId: number, metrics: string[]) => void;
    reportItems: ReportItem[];
};

type TargetingBreakdownRow = Omit<ReportItem, 'creative'> & {
    creative: string;
};

const ALWAYS_SHOWN_COLUMNS = ['targeting', 'creative'];

const TargetingBreakdown = ({ mediaPlanItem, onSelectedMetricsChange, reportItems }: TargetingBreakdownProps) => {
    const [contextState] = useNonNullContext(AdvertisingContext);
    const currency = contextState.mediaPlan?.currency;

    const columns: Column<TargetingBreakdownRow>[] = useMemo(
        () =>
            currency
                ? [
                      {
                          name: 'targeting',
                          label: 'Targeting',
                          value: 'targeting',
                          renderCell: (value, index, array) => {
                              const rowSpan = array.filter((el) => el.targeting === value.targeting).length;
                              const prevElement = array[index - 1];
                              if (!prevElement || prevElement.targeting !== value.targeting) {
                                  const tooltipProps: Record<string, string> = {};
                                  const targetingName = value.targeting ?? '';

                                  if (targetingName.length > 22) {
                                      tooltipProps['data-tip'] = targetingName;
                                  }

                                  const label =
                                      targetingName.length > 22 ? `${targetingName.slice(0, 22)}...` : targetingName;
                                  return (
                                      <td key={index} rowSpan={rowSpan}>
                                          <span {...tooltipProps}>{label}</span>
                                      </td>
                                  );
                              }

                              return null;
                          },
                          className: styles.alwaysShownCell,
                      },
                      {
                          name: 'creative',
                          label: 'Creatives',
                          value: (data) => {
                              const creatives = data.creative || '-';

                              return (
                                  <TruncatedTextCell
                                      className={styles.creativeContainer}
                                      popoverClassName={styles.creativePopover}
                                  >
                                      {creatives}
                                  </TruncatedTextCell>
                              );
                          },
                          className: styles.alwaysShownCell,
                      },
                      {
                          name: 'amount_spent',
                          label: 'Amount Spent',
                          value: (item) => {
                              if (isNaN(item.amount_spent)) {
                                  return '-';
                              }

                              const cost = Number(mediaPlanItem.cost);
                              const percentage = cost !== 0 ? (item.amount_spent / cost) * 100 : 0;
                              return (
                                  <div className={cn(styles.amountSpentContainer)}>
                                      <p className={styles.amountSpent}>
                                          {asMoneyWithoutZeros(item.amount_spent, currency)}
                                          <span className={styles.amountSpentPercentage}>
                                              {toDecimalPoint(percentage, 1)}%
                                          </span>
                                      </p>
                                      <ProgressBar
                                          progress={Math.min(100, percentage)}
                                          trackStyles={{ height: '0.2rem' }}
                                          barStyles={{
                                              backgroundColor: item.amount_spent > cost * 1.05 ? '#F7685B' : '',
                                          }}
                                      />
                                  </div>
                              );
                          },
                          className: cn(styles.alwaysShownCell, styles.amountSpentCell),
                      },
                      {
                          name: 'impressions',
                          label: 'Impressions',
                          value: (item) => displayOptionalNumericTableValue(item.impressions),
                          className: styles.tableCell,
                      },
                      {
                          name: 'cpm',
                          label: 'CPM',
                          value: (item) => asMoney(item.cpm, currency),
                          className: styles.tableCell,
                      },
                      {
                          name: 'clicks',
                          label: 'Clicks',
                          value: (item) => displayOptionalNumericTableValue(item.clicks),
                          className: styles.tableCell,
                      },
                      {
                          name: 'cpc',
                          label: 'CPC',
                          value: (item) => asMoney(item.cpc, currency),
                          className: styles.tableCell,
                      },
                      {
                          name: 'views',
                          label: 'Views',
                          value: (item) => displayOptionalNumericTableValue(item.views),
                          className: styles.tableCell,
                      },
                      {
                          name: 'cpv',
                          label: 'CPV',
                          value: (item) => asMoney(item.cpv, currency, 3),
                          className: styles.tableCell,
                      },
                      {
                          name: 'six_sec_views',
                          label: 'Views (6sec)',
                          value: (item) => displayOptionalNumericTableValue(item.six_sec_views),
                          className: styles.tableCell,
                      },
                      {
                          name: 'six_sec_cpv',
                          label: 'CPV (6sec)',
                          value: (item) => asMoney(item.six_sec_cpv, currency, 3),
                          className: styles.tableCell,
                      },
                      {
                          name: 'leads',
                          label: 'Leads',
                          value: (item) => displayOptionalNumericTableValue(item.leads),
                          className: styles.tableCell,
                      },
                      {
                          name: 'cpl',
                          label: 'CPL',
                          value: (item) => asMoney(item.cpl, currency),
                          className: styles.tableCell,
                      },
                      {
                          name: 'view_rate',
                          label: 'View Rate',
                          value: (item) =>
                              typeof item.view_rate === 'number' && !isNaN(item.view_rate)
                                  ? `${numberWithCommas(item.view_rate)}%`
                                  : '-',
                          className: styles.tableCell,
                      },
                      {
                          name: 'purchases',
                          label: 'Purchases',
                          value: (item) => displayOptionalNumericTableValue(item.purchases),
                          className: styles.tableCell,
                      },
                      {
                          name: 'cpa',
                          label: 'CPA',
                          value: (item) => asMoney(item.cpa, currency),
                          className: styles.tableCell,
                      },
                      {
                          name: 'revenue',
                          label: 'Revenue',
                          value: (item) => asMoney(item.revenue, currency),
                          className: styles.tableCell,
                      },
                      {
                          name: 'roas',
                          label: 'ROAS',
                          value: (item) => asMoney(item.roas, currency),
                          className: styles.tableCell,
                      },
                      {
                          name: 'ctr',
                          label: 'CTR',
                          value: (item) =>
                              typeof item.ctr === 'number' && !isNaN(item.ctr) ? `${roundTo2Dp(item.ctr * 100)}%` : '-',
                          className: styles.tableCell,
                      },
                  ]
                : [],
        [currency, mediaPlanItem.cost]
    );

    const [selectedPreset, setSelectedPreset] = useState(
        presetOptions.find((option) => option.value === 'performance')
    );

    const filteredColumns = useMemo(() => {
        const filteredNames = selectedPreset?.metrics ?? [];
        return columns.filter(
            (column) =>
                filteredNames.includes(column.name as SelectableMetrics) || ALWAYS_SHOWN_COLUMNS.includes(column.name)
        );
    }, [selectedPreset?.metrics, columns]);

    const data: Array<TargetingBreakdownRow> = useMemo(() => {
        const rowsMappedByTargetting = reportItems
            .filter((r) => r.mediaPlanItemId === mediaPlanItem.id)
            .reduce((acc, row) => {
                const targeting = row.targeting ?? '';
                if (!targeting) {
                    return acc;
                }

                if (!acc[targeting]) {
                    acc[targeting] = [];
                }

                acc[targeting].push(row);
                return acc;
            }, {} as { [key: string]: ReportItem[] });

        const getCreativesFromReportItems = (reportItems: ReportItem[]) =>
            uniq(
                reportItems
                    .map((r) => (isTwitterReportItem(r) ? r.tweetText : r.creative))
                    .filter((c): c is string => typeof c === 'string')
                    .sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()) || 0)
            );

        const rows = Object.values(rowsMappedByTargetting)
            .map((rows) => ({
                ...getTotalReportItemValues(rows),
                creative: getCreativesFromReportItems(rows).join(', '),
            }))
            .flat();

        if (!rows.length && !mediaPlanItem.targeting.length) {
            return [{ ...createReportItem({ mediaPlanItemId: mediaPlanItem.id }), creative: '' }];
        }

        if (!rows.length) {
            return mediaPlanItem.targeting.map((targeting) => {
                return {
                    ...createReportItem({
                        mediaPlanItemId: mediaPlanItem.id,
                        targeting: targeting.name,
                    }),
                    creative: mediaPlanItem.creatives
                        .map((creative) => creative.name)
                        .filter((c): c is string => typeof c === 'string')
                        .join(', '),
                };
            });
        }

        return rows.sort((a, b) => sortNumerically(a.amount_spent, b.amount_spent));
    }, [mediaPlanItem.creatives, mediaPlanItem.id, mediaPlanItem.targeting, reportItems]);

    const shouldRenderUnsupportedNamingConvention = useMemo(
        () =>
            data.some((row) => {
                if (isTwitterReportItem(row)) {
                    return row.targeting === null;
                }

                return row.targeting === null || row.creative === null;
            }),
        [data]
    );

    useEffect(() => {
        if (data.length) {
            onSelectedMetricsChange(mediaPlanItem.id, ALWAYS_SHOWN_COLUMNS.concat(selectedPreset?.metrics ?? []));
        }
    }, [data.length, mediaPlanItem.id, onSelectedMetricsChange, selectedPreset?.metrics]);

    if (!data.length) {
        return null;
    }

    return (
        <>
            <TargetingCreativeExpandBar
                mediaPlanItem={mediaPlanItem}
                renderToolbar={() =>
                    !shouldRenderUnsupportedNamingConvention && (
                        <PresetsSelect value={selectedPreset} onChange={setSelectedPreset} />
                    )
                }
            >
                {!shouldRenderUnsupportedNamingConvention && (
                    <Table className={styles.breakdownTable} columns={filteredColumns} data={data} />
                )}
                {shouldRenderUnsupportedNamingConvention && <p>Naming convention not currently supported</p>}
            </TargetingCreativeExpandBar>
            <ReactTooltip place="right" />
        </>
    );
};

export default TargetingBreakdown;
