import { AppServer } from '../mirage';
import * as currencyMirage from './currency/currency.mirage';
import * as influencerTagMirage from './influencerTag/influencerTag.mirage';
import * as clientMirage from './client/client.mirage';
import * as brandMirage from './brand/brand.mirage';
import * as releaseTypesMirage from './releaseType/releaseType.mirage';
import * as releaseMirage from './release/release.mirage';
import * as mediaPlanMirage from './mediaPlan/mediaPlan.mirage';
import * as channelMirage from './channel/channel.mirage';
import * as formatMirage from './format/format.mirage';
import * as optimisationMetricMirage from './optimisationMetric/optimisationMetric.mirage';
import * as objectiveMirage from './objective/objective.mirage';
import * as buyMethodMirage from './buyMethod/buyMethod.mirage';
import * as buyPlatformMirage from './buyPlatform/buyPlatform.mirage';
import * as influencerPostGroupMirage from './InfluencerPostGroup/influencerPostGroup.mirage';
import * as mediaPlanItemGroupMirage from './mediaPlanItemGroup/mediaPlanItemGroup.mirage';
import * as influencerPlanMirage from './influencerPlan/influencerPlan.mirage';
import * as tiktokInfluencerPlanVideoMirage from './tiktokInfluencerPlanVideo/tiktokInfluencerPlanVideo.mirage';
import * as tiktokInfluencerPostMirage from './tiktokInfluencerPost/tiktokInfluencerPost.mirage';
import * as creatorPlansMirage from './creatorPlans/creatorPlans.mirage';
import * as invoiceRequestMirage from './invoiceRequest/invoiceRequest.mirage';
import * as contentTagsMirage from './contentTags/contentTags.mirage';
import * as youtubeInfluencerPostGroupsMirage from './youtubeInfluencerPostGroups/youtubeInfluencerPostGroups.mirage';
import * as youtubeInfluencerPostMirage from './youtubeInfluencerPosts/youtubeInfluencerPosts.mirage';

export const models = {
    ...currencyMirage.models,
    ...influencerTagMirage.models,
    ...clientMirage.models,
    ...brandMirage.models,
    ...releaseTypesMirage.models,
    ...releaseMirage.models,
    ...mediaPlanMirage.models,
    ...channelMirage.models,
    ...formatMirage.models,
    ...optimisationMetricMirage.models,
    ...buyMethodMirage.models,
    ...buyPlatformMirage.models,
    ...objectiveMirage.models,
    ...influencerPostGroupMirage.models,
    ...mediaPlanItemGroupMirage.models,
    ...influencerPlanMirage.models,
    ...tiktokInfluencerPlanVideoMirage.models,
    ...tiktokInfluencerPostMirage.models,
    ...creatorPlansMirage.models,
    ...invoiceRequestMirage.models,
    ...contentTagsMirage.models,
    ...youtubeInfluencerPostGroupsMirage.models,
    ...youtubeInfluencerPostMirage.models,
};

export const serializers = {
    ...currencyMirage.serializers,
    ...influencerTagMirage.serializers,
    ...clientMirage.serializers,
    ...brandMirage.serializers,
    ...releaseTypesMirage.serializers,
    ...releaseMirage.serializers,
    ...mediaPlanMirage.serializers,
    ...channelMirage.serializers,
    ...formatMirage.serializers,
    ...optimisationMetricMirage.serializers,
    ...buyMethodMirage.serializers,
    ...buyPlatformMirage.serializers,
    ...objectiveMirage.serializers,
    ...influencerPostGroupMirage.serializers,
    ...mediaPlanItemGroupMirage.serializers,
    ...influencerPlanMirage.serializers,
    ...tiktokInfluencerPlanVideoMirage.serializers,
    ...tiktokInfluencerPostMirage.serializers,
    ...creatorPlansMirage.serializers,
    ...invoiceRequestMirage.serializers,
    ...contentTagsMirage.serializers,
    ...youtubeInfluencerPostGroupsMirage.serializers,
    ...youtubeInfluencerPostMirage.serializers,
};

export const factories = {
    ...currencyMirage.factories,
    ...influencerTagMirage.factories,
    ...clientMirage.factories,
    ...brandMirage.factories,
    ...releaseTypesMirage.factories,
    ...releaseMirage.factories,
    ...mediaPlanMirage.factories,
    ...channelMirage.factories,
    ...formatMirage.factories,
    ...optimisationMetricMirage.factories,
    ...buyMethodMirage.factories,
    ...buyPlatformMirage.factories,
    ...objectiveMirage.factories,
    ...influencerPostGroupMirage.factories,
    ...mediaPlanItemGroupMirage.factories,
    ...influencerPlanMirage.factories,
    ...tiktokInfluencerPlanVideoMirage.factories,
    ...tiktokInfluencerPostMirage.factories,
    ...creatorPlansMirage.factories,
    ...invoiceRequestMirage.factories,
    ...contentTagsMirage.factories,
    ...youtubeInfluencerPostGroupsMirage.factories,
    ...youtubeInfluencerPostMirage.factories,
};

export function handleAdvertisingRequests(server: AppServer) {
    currencyMirage.handleCurrencyRequests(server);
    influencerTagMirage.handleInfluencerTagRequests(server);
    clientMirage.handleClientRequests(server);
    brandMirage.handleBrandRequests(server);
    releaseTypesMirage.handleReleaseTypeRequests(server);
    releaseMirage.handleReleaseRequests(server);
    mediaPlanMirage.handleMediaPlanRequests(server);
    channelMirage.handleChannelRequests(server);
    formatMirage.handleFormatRequests(server);
    optimisationMetricMirage.handleOptimisationMetricRequests(server);
    buyMethodMirage.handleRequests(server);
    buyPlatformMirage.handleRequests(server);
    objectiveMirage.handleRequests(server);
    influencerPostGroupMirage.handleInfluencerPostGroupRequests(server);
    mediaPlanItemGroupMirage.handleRequests(server);
    influencerPlanMirage.handleInfluencerPlanRequests(server);
    tiktokInfluencerPostMirage.handleRequests(server);
    creatorPlansMirage.handleRequests(server);
    invoiceRequestMirage.handleRequests(server);
    contentTagsMirage.handleRequests(server);
    youtubeInfluencerPostGroupsMirage.handleRequests(server);
    youtubeInfluencerPostMirage.handleRequests(server);
}
