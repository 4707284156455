import { useCallback, useState } from 'react';
import { getTiktokUsers, GetTiktokUsersParams, TiktokUser } from '@round/api';

type Params = Pick<GetTiktokUsersParams, 'search'>;

export default function useTiktokUsers({ search }: Params = {}) {
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);

    const [users, setUsers] = useState<TiktokUser[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isInitialized, setIsInitialized] = useState(false);

    const fetchData = useCallback(async (params: GetTiktokUsersParams, requestInit?: RequestInit) => {
        try {
            setError(null);
            setIsLoading(true);
            const response = await getTiktokUsers(params, requestInit);
            if (response.status !== 200) {
                throw new Error('Could not fetch tiktok users');
            }

            setUsers((users) => users.concat(response.data.results));
            setHasNextPage(Boolean(response.data.next));
            setIsInitialized(true);
            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            setError('Could not fetch tiktok users');
            setIsInitialized(true);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            fetchData({ page, search, page_size: 25 }, requestInit);
        },
        [fetchData, page, search]
    );

    const loadNextPage = useCallback(async () => {
        if (hasNextPage) {
            fetchData({ page: page + 1, search, page_size: 25 }).then(() => {
                setPage((page) => page + 1);
            });
        }
    }, [fetchData, hasNextPage, page, search]);

    return {
        fetchData,
        init,
        loadNextPage,
        reset: () => {
            setPage(1);
            setHasNextPage(false);
            setUsers([]);
            setIsLoading(false);
            setIsInitialized(false);
            setError(null);
        },
        users,
        isLoading,
        isInitialized,
        error,
    };
}
