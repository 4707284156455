import React, { PropsWithChildren } from 'react';
import { FCWithChildren } from '../../../utility/utility.types';
import styles from './Tooltip.module.css';
import { Popover } from '@round/ui-kit';

type TooltipProps = PropsWithChildren<{
    anchorElement: HTMLElement | SVGSVGElement | null;
    disabled?: boolean;
    value: string | number;
}>;

const Tooltip: FCWithChildren<TooltipProps> = ({ anchorElement, disabled, value }) => {
    return (
        <Popover anchorElement={anchorElement} disabled={disabled} showOn="hover">
            <div className={styles.tooltip}>{value}</div>
        </Popover>
    );
};

export default Tooltip;
