import { ValueType } from 'react-select';
import { BaseFilterObject, GenericDropdownOption } from 'App.types';
import { useClients } from 'contexts/ClientsContext';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import { useEffect, useMemo } from 'react';

type Props = {
    selectedClientIds: number[];
    plannerIds?: number[];
    onChange: (value: ValueType<GenericDropdownOption<number>, true>) => void;
    onClear: () => void;
    onRemove: () => void;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
} & Pick<BaseFilterObject, 'name' | 'icon'>;

const ClientsFilter = ({
    icon,
    name,
    selectedClientIds,
    onRemove,
    onChange,
    onClear,
    plannerIds,
    isOpen,
    onOpen,
    onClose,
}: Props) => {
    const { clients, init: initClients, error: clientsError, state: clientsState, reset } = useClients({
        planner_id: plannerIds?.join(','),
    });

    const planners = useMemo(() => plannerIds?.toString(), [plannerIds]);

    useEffect(() => {
        reset();
    }, [planners, reset]);

    useAbortableEffect(
        (signal) => {
            if (clientsState !== 'initialized') {
                initClients({ signal });
            }
        },
        [clientsState, initClients]
    );

    const clientOptions = clients
        .map<GenericDropdownOption<number>>((c) => ({ value: c.id, label: c.name }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const selectedClients = clientOptions.filter((o) => selectedClientIds.includes(o.value));

    return (
        <SelectFilter
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            isMulti
            name={name}
            icon={icon}
            value={selectedClients}
            onChange={onChange}
            onClear={onClear}
            onRemove={onRemove}
            noOptionsMessage={() => clientsError ?? 'No Options'}
            options={clientOptions}
        />
    );
};

export default ClientsFilter;
