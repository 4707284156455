import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PaymentStatus } from '../../../InfluencerPlan.types';
import {
    asMoney,
    buildInstagramUserUrl,
    displayOptionalNumericTableValue,
    formatDateObjShort,
    formatNumberToKNotation,
    openInNewTab,
    roundTo2Dp,
    showNotification,
} from '../../../../../../helpers';
import styles from './InstagramTableRow.module.css';
import cn from 'classnames';
import { useCheckUserGroupsAccess } from '../../../../../Auth/hooks/useCheckUserGroupsAccess';
import InfluencerPostStatus from '../../../components/InfluencerPostStatus/InfluencerPostStatus';
import { EDITABLE_STATUSES, paymentStatusOptions } from '../../../InfluencerPlan.helpers';
import InfluencerPostStatusModal from '../../InfluencerPostStatusModal/InfluencerPostStatusModal';
import { setManualStatusOnInstagramInfluencerPost } from '../../../InfluencerPlan.api';
import { InfluencerPlanContext } from '../../../contexts/InfluencerPlanContext';
import { ProtectedByUserGroups } from '../../../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import TableSelect from '../../../../components/TableSelect/TableSelect';
import { ReactComponent as LinkIcon } from 'assets/icons/Link.svg';
import { ReactComponent as DiagramIcon } from '../../../../../../assets/Diagram.svg';
import PaymentStatusSelectValue from '../../../components/PaymentStatusSelectValue/PaymentStatusSelectValue';
import CurrencyInput from 'react-currency-input-field';
import { cpm } from '../../../../Metrics/Metrics';
import { InstagramInfluencerPost, InstagramTablePostStats } from '../../../types/Instagram.types';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { InstagramCreatorsContext } from '../../../contexts/InstagramCreatorsContext/InstagramCreatorsContext';
import { OptionsContext } from '../../../../../../contexts/OptionsContext/OptionsContext';
import Button from '../../../../../../ui/Buttons/Button/Button';
import {
    InstagramInfluencerUserStats,
    InstagramUserImage,
    getInstagramInfluencerUserStatsById,
    patchInstagramInfluencerUser,
} from '@round/api';
import { ReactComponent as PaymentCardIcon } from 'assets/PaymentCard.svg';
import PostPaymentRequestModal from '../PostPaymentRequestModal/PostPaymentRequestModal';
import capitalize from 'lodash/capitalize';

type InstagramTableRowData = {
    post: InstagramInfluencerPost;
    stats: InstagramTablePostStats;
    profileImage?: InstagramUserImage;
    videoUploadedDate: string | null;
};

const InstagramTableRow: FunctionComponent<{
    row: InstagramTableRowData;
    isHighlighted?: boolean;
    onRowClick?: (post: InstagramInfluencerPost) => void;
    onOpenInfluencerModal: (influencerUserId: number, stats: InstagramInfluencerUserStats | null) => void;
    innerRef?: any;
    dragHandleProps?: any;
    draggableProps?: any;
}> = ({
    row: { post, stats, profileImage, videoUploadedDate },
    isHighlighted,
    onRowClick,
    onOpenInfluencerModal,
    innerRef,
    dragHandleProps,
    draggableProps,
}) => {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const {
        instagramInfluencerUsers,
        updateInstagramInfluencerPost,
        refreshInstagramInfluencerPost,
        setInstagramInfluencerUser,
    } = useNonNullContext(InstagramCreatorsContext);

    const { currencies } = useNonNullContext(OptionsContext);
    const postCurrency = currencies.find((currency) => post.currency_id === currency.id);

    const influencer = useMemo(
        () => instagramInfluencerUsers.find((influencer) => influencer.id === post.influencer_id),
        [instagramInfluencerUsers, post.influencer_id]
    );

    const canEdit = useCheckUserGroupsAccess(['influencer_editor']);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [isPostPaymentRequestModalOpen, setIsPostPaymentRequestModalOpen] = useState(false);

    const postStatusEditable = EDITABLE_STATUSES.includes(post.status) && Boolean(canEdit);
    const [contactDetails, setContactDetails] = useState('');
    const [cost, setCost] = useState(post.cost);
    const [estimatedViews, setEstimatedViews] = useState(post.estimated_views_override ?? post.estimated_views);
    const [notes, setNotes] = useState(post.notes);
    const [adCode, setAdCode] = useState(post.ad_code);

    const [isInstagramInfluencerUserStatsLoading, setIsInstagramInfluencerUserStatsLoading] = useState(false);

    const handleTableRowClick = useCallback(() => {
        if (typeof onRowClick === 'function') {
            onRowClick(post);
        }
    }, [onRowClick, post]);

    const updateStatus = useCallback(
        async (status: InstagramInfluencerPost['status'], draftExpectedBy: string | null) => {
            try {
                await setManualStatusOnInstagramInfluencerPost({
                    status,
                    draft_expected_by: draftExpectedBy,
                    influencer_post_id: post.id,
                });
                await refreshInstagramInfluencerPost(post.id);
                showNotification('Status updated', 'info');
                setIsStatusModalOpen(false);
            } catch {
                showNotification('Could not set status', 'error');
            }
        },
        [post.id, refreshInstagramInfluencerPost]
    );

    const updateContactDetails = useCallback(async () => {
        if (!influencer || influencer.contact_details === contactDetails) {
            return;
        }

        try {
            const response = await patchInstagramInfluencerUser(influencer.id, { contact_details: contactDetails });

            if (response.status !== 200) {
                throw new Error('Could not update contact details');
            }

            setInstagramInfluencerUser(response.data);
            showNotification('Contact details updated', 'info');
        } catch (e) {
            const errorMessage = e instanceof Error ? e.message : 'Could not update contact details';
            showNotification(errorMessage, 'error');
            throw e;
        }
    }, [contactDetails, influencer, setInstagramInfluencerUser]);

    const updatePaymentStatus = useCallback(
        async (paymentStatus: PaymentStatus) => {
            try {
                await updateInstagramInfluencerPost(post.id, { payment_status: paymentStatus });
                showNotification('Payment status updated', 'info');
            } catch {
                showNotification('Could not update payment status', 'error');
            }
        },
        [post.id, updateInstagramInfluencerPost]
    );

    const updateCost = useCallback(
        async (cost: string) => {
            try {
                await updateInstagramInfluencerPost(post.id, { cost });
                showNotification('Cost updated', 'info');
            } catch {
                showNotification('Could not update cost', 'error');
            }
        },
        [post.id, updateInstagramInfluencerPost]
    );

    const updateEstimatedViews = useCallback(async () => {
        if (
            (post.estimated_views_override === null && estimatedViews === post.estimated_views) ||
            estimatedViews === post.estimated_views_override
        ) {
            return;
        }

        try {
            await updateInstagramInfluencerPost(post.id, { estimated_views_override: estimatedViews });
            showNotification('Estimated views updated', 'info');
        } catch {
            showNotification('Could not update estimated views', 'error');
        }
    }, [estimatedViews, post.estimated_views, post.estimated_views_override, post.id, updateInstagramInfluencerPost]);

    const updateNotes = useCallback(async () => {
        if (post.notes === notes) {
            return;
        }

        try {
            await updateInstagramInfluencerPost(post.id, { notes });
            showNotification('Notes updated', 'info');
        } catch {
            showNotification('Could not update notes', 'error');
        }
    }, [notes, post.id, post.notes, updateInstagramInfluencerPost]);

    const updateAdCode = useCallback(async () => {
        if (post.ad_code === adCode) {
            return;
        }

        try {
            await updateInstagramInfluencerPost(post.id, { ad_code: adCode });
            showNotification('Ad code updated', 'info');
        } catch {
            showNotification('Could not update ad code', 'error');
        }
    }, [adCode, post.ad_code, post.id, updateInstagramInfluencerPost]);

    useEffect(() => {
        if (post) {
            setContactDetails(influencer?.contact_details ?? '');
            setCost(post.cost);
            setEstimatedViews(post.estimated_views_override ?? post.estimated_views);
            setAdCode(post.ad_code);
        }
    }, [influencer?.contact_details, post]);

    const handleOpenInfluencerModalOpen = useCallback(
        async (e: React.MouseEvent) => {
            e.stopPropagation();
            if (typeof post.influencer_id !== 'number') {
                return;
            }

            let stats: InstagramInfluencerUserStats | null = null;
            try {
                setIsInstagramInfluencerUserStatsLoading(true);
                const response = await getInstagramInfluencerUserStatsById(post.influencer_id);

                if (response.status !== 200) {
                    throw new Error(response.data.detail);
                }

                stats = response.data;
            } catch (e) {
                const errorMessage = e instanceof Error ? e.message : 'Could not fetch instagram influencer user stats';
                showNotification(errorMessage, 'error');
            } finally {
                setIsInstagramInfluencerUserStatsLoading(false);
                onOpenInfluencerModal(post.influencer_id, stats);
            }
        },
        [onOpenInfluencerModal, post.influencer_id]
    );

    const handleNotesBlur = (element: HTMLTextAreaElement) => {
        updateNotes();
        element.scrollTop = 0;
    };

    const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.key !== 'Enter') {
            return;
        }
        if (e.shiftKey) {
            e.currentTarget.dispatchEvent(new KeyboardEvent('keydown', { key: 'Enter' }));
            return;
        }
        e.currentTarget.blur();
    };

    const postPaymentRequestStatus =
        post.paypal_payment_status !== 'UNPAID' ? post.paypal_payment_status : post.payment_request_status;

    const isPaymentRequestAvailable = postPaymentRequestStatus === 'UNREQUESTED' && post.payment_status !== 'PAID';
    const displayPostPaymentRequestStatus =
        postPaymentRequestStatus
            ?.split('_')
            .map((word) => capitalize(word.toLowerCase()))
            .join(' ') ?? '-';

    return (
        <>
            <tr
                id={`${post.id}`}
                ref={innerRef}
                className={cn(styles.row, { [styles.readonly]: !canEdit, [styles.highlighted]: isHighlighted })}
                onClick={handleTableRowClick}
                {...dragHandleProps}
                {...draggableProps}
            >
                <td className={styles.td}>{post.id}</td>
                <td className={styles.td}>
                    <div className={styles.accountCell}>
                        <div className={styles.accountContainer}>
                            {profileImage && (
                                <img
                                    className={styles.influencerThumbnail}
                                    src={profileImage.avatar_thumb.cached_url || profileImage.avatar_thumb.original_url}
                                    alt="avatar"
                                    crossOrigin="anonymous"
                                />
                            )}
                            <div className={styles.accountDetails}>
                                <span>
                                    {influencer ? (
                                        <a
                                            onClick={(e) => e.stopPropagation()}
                                            className={styles.influencer}
                                            href={buildInstagramUserUrl(influencer.username)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            @{influencer.username}
                                        </a>
                                    ) : (
                                        '-'
                                    )}
                                </span>

                                <span>
                                    {stats.followers ? `${formatNumberToKNotation(stats.followers)} followers` : '-'}
                                </span>
                            </div>
                        </div>
                        {typeof post.influencer_id === 'number' && (
                            <ProtectedByUserGroups groups={['user_admin', 'explorer_tiktok_viewer']}>
                                <Button
                                    className={styles.instagramInfluencerModalButton}
                                    disabled={!post.influencer_id}
                                    loading={isInstagramInfluencerUserStatsLoading}
                                    onClick={handleOpenInfluencerModalOpen}
                                >
                                    <DiagramIcon />
                                </Button>
                            </ProtectedByUserGroups>
                        )}
                    </div>
                </td>
                <td
                    className={cn(styles.td, styles.editableHover)}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (post.status === 'live') {
                            openInNewTab(post.post_url);
                            return;
                        }

                        if (!postStatusEditable) {
                            return;
                        }
                        setIsStatusModalOpen(postStatusEditable);
                    }}
                >
                    <div className={styles.statusContainer}>
                        <button
                            className={cn(styles.statusButton, {
                                [styles.readonly]: !postStatusEditable && post.status !== 'live',
                            })}
                        >
                            <InfluencerPostStatus
                                className={styles.status}
                                status={post.status}
                                draftExpectedBy={post.draft_expected_by}
                            />
                        </button>
                        {post.status === 'live' && <LinkIcon className={styles.linkIcon} />}
                    </div>
                </td>
                <ProtectedByUserGroups groups={['influencer_editor']}>
                    <td
                        className={cn(styles.td, styles.editorCell, styles.contactCell, {
                            [styles.editableHover]: influencer,
                        })}
                    >
                        <input
                            placeholder="Enter contact details"
                            className={styles.wideInput}
                            disabled={!influencer}
                            value={contactDetails}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => setContactDetails(e.target.value)}
                            onKeyDown={handleEnterKeyDown}
                            onBlur={() => {
                                updateContactDetails().catch(() => {
                                    setContactDetails(influencer?.contact_details ?? '');
                                });
                            }}
                        />
                    </td>
                    <td
                        className={cn(styles.td, styles.editorCell, styles.editableHover)}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <CurrencyInput
                            className={cn({ [styles.invalid]: post.currency_id !== influencerPlan?.currency.id })}
                            prefix={postCurrency?.symbol}
                            value={cost}
                            onValueChange={setCost}
                            onKeyDown={handleEnterKeyDown}
                            onBlur={() => {
                                if (Number(cost) !== Number(post.cost)) {
                                    updateCost(cost ?? '0');
                                }
                            }}
                            groupSeparator=","
                            decimalSeparator="."
                            allowDecimals={false}
                            decimalScale={0}
                            decimalsLimit={0}
                        />
                    </td>
                    <td className={cn(styles.td, styles.editorCell)}>{asMoney(post.client_cost, postCurrency)}</td>
                    <td
                        className={cn(styles.td, styles.editorCell, styles.editableHover)}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <TableSelect
                            options={paymentStatusOptions}
                            value={paymentStatusOptions.find((o) => o.value === post.payment_status)}
                            onChange={(option) => option && updatePaymentStatus(option.value)}
                            components={{ SingleValue: PaymentStatusSelectValue }}
                        />
                    </td>
                </ProtectedByUserGroups>

                <ProtectedByUserGroups groups={['round_planner']}>
                    <td className={cn(styles.td, styles.editorCell, styles.editableHover)}>
                        <div className={styles.paymentRequestContainer}>
                            <span
                                className={cn(styles.status, {
                                    [styles.paid]: postPaymentRequestStatus === 'PAID',
                                    [styles.inProgress]: postPaymentRequestStatus === 'IN_PROGRESS',
                                    [styles.requested]: postPaymentRequestStatus === 'REQUESTED',
                                    [styles.unrequested]: postPaymentRequestStatus === 'UNREQUESTED',
                                    [styles.failed]: postPaymentRequestStatus === 'FAILED',
                                })}
                            >
                                {displayPostPaymentRequestStatus}
                            </span>

                            {isPaymentRequestAvailable && (
                                <Button
                                    className={styles.paymentRequestButton}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsPostPaymentRequestModalOpen(true);
                                    }}
                                >
                                    <PaymentCardIcon className={styles.paymentRequestIcon} />
                                </Button>
                            )}
                        </div>
                    </td>
                </ProtectedByUserGroups>

                <ProtectedByUserGroups groups={['influencer_editor']}>
                    <td
                        className={cn(styles.td, styles.editorCell, styles.editableHover)}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <CurrencyInput
                            placeholder="Enter est. views"
                            value={estimatedViews ?? undefined}
                            onValueChange={(value) =>
                                setEstimatedViews(typeof value === 'undefined' ? null : Number(value))
                            }
                            onKeyDown={handleEnterKeyDown}
                            onBlur={updateEstimatedViews}
                            groupSeparator=","
                            decimalSeparator="."
                            allowDecimals={false}
                            decimalScale={0}
                            decimalsLimit={0}
                        />
                    </td>
                    <td className={cn(styles.td, styles.editorCell)}>
                        {asMoney(cpm(Number(post.client_cost), Number(estimatedViews ?? 0)), postCurrency, 2)}
                    </td>

                    <td className={cn(styles.td, styles.editorCell)}>
                        {asMoney(cpm(Number(post.cost), Number(stats.totalViews ?? 0)), postCurrency, 2)}
                    </td>

                    <td className={cn(styles.editorCell, styles.editableHover, styles.notesCell)}>
                        <textarea
                            rows={2}
                            className={styles.wideInput}
                            placeholder={'-'}
                            value={notes}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => setNotes(e.target.value)}
                            onKeyDown={handleEnterKeyDown}
                            onBlur={(e) => handleNotesBlur(e.currentTarget)}
                        />
                    </td>
                </ProtectedByUserGroups>

                <td className={styles.td}>
                    {videoUploadedDate ? formatDateObjShort(new Date(videoUploadedDate)) : '-'}
                </td>
                <td className={styles.td}>{displayOptionalNumericTableValue(stats.totalViews)}</td>
                <td className={styles.td}>{displayOptionalNumericTableValue(stats.feedViews)}</td>
                <td className={styles.td}>{displayOptionalNumericTableValue(stats.plays)}</td>
                <td className={styles.td}>{displayOptionalNumericTableValue(stats.storyViews)}</td>
                <td className={styles.td}>{displayOptionalNumericTableValue(stats.totalEngagements)}</td>
                <td className={styles.td}>
                    {typeof stats.engagementRate === 'number' && isFinite(stats.engagementRate)
                        ? `${roundTo2Dp(stats.engagementRate * 100)}%`
                        : '-'}
                </td>
                <td className={styles.td}>{displayOptionalNumericTableValue(stats.likes)}</td>
                <td className={styles.td}>{displayOptionalNumericTableValue(stats.comments)}</td>
                <td className={styles.td}>
                    {canEdit ? (
                        <input
                            placeholder="Enter ad code"
                            onClick={(e) => e.stopPropagation()}
                            value={adCode}
                            onChange={(e) => setAdCode(e.target.value)}
                            onBlur={updateAdCode}
                            onKeyDown={handleEnterKeyDown}
                        />
                    ) : (
                        post.ad_code
                    )}
                </td>
            </tr>

            <InfluencerPostStatusModal
                isModalOpen={isStatusModalOpen}
                onClose={() => setIsStatusModalOpen(false)}
                status={post.status}
                onChange={updateStatus}
            />

            <ProtectedByUserGroups groups={['round_planner']}>
                <PostPaymentRequestModal
                    isOpen={isPostPaymentRequestModalOpen}
                    onClose={() => setIsPostPaymentRequestModalOpen(false)}
                    influencerUser={influencer}
                    instagramInfluencerPost={post}
                    onSuccess={() => {
                        refreshInstagramInfluencerPost(post.id);
                    }}
                />
            </ProtectedByUserGroups>
        </>
    );
};

export default InstagramTableRow;
