import styles from './MicrosPaymentRequest.module.css';
import { ReactComponent as RoundLogoFull } from '../../../../assets/brand/LogoFull.svg';
import { useParams } from 'react-router-dom';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import { useMicrosPaymentRequest } from './useMicrosPaymentRequest';
import FormSubmitted from './FormSubmitted/FormSubmitted';
import { FormClosed, FormError, FormLinkBroken } from './FormIssues/FormIssues';
import FormLoading from './FormLoading/FormLoading';
import FormOpen from './FormOpen/FormOpen';

const MicrosPaymentRequest = () => {
    const { uuid } = useParams();

    const { init, status, data, updateFormData } = useMicrosPaymentRequest(uuid);

    useAbortableEffect(
        (signal) => {
            if (status === 'NOT_INITIALIZED') {
                init({ signal });
            }
        },
        [init, status]
    );

    const currentYear = new Date().getFullYear();

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <header>
                    <div>
                        <RoundLogoFull />
                    </div>
                </header>
                <main>
                    {status === 'NOT_INITIALIZED' ? (
                        <FormLoading />
                    ) : status === 'ERROR' || !data ? (
                        <FormError />
                    ) : status === 'LINK_BROKEN' || !uuid ? (
                        <FormLinkBroken />
                    ) : status === 'FORM_CLOSED' ? (
                        <FormClosed />
                    ) : status === 'FORM_SUBMITTED' ? (
                        <FormSubmitted data={data} />
                    ) : (
                        status === 'FORM_OPEN' && <FormOpen data={data} uuid={uuid} onSuccess={updateFormData} />
                    )}
                </main>
                <footer>
                    <span>© {currentYear} round.</span>
                </footer>
            </div>
        </div>
    );
};

export default MicrosPaymentRequest;
