import { AuthPageLayout as AuthPageLayoutLib } from '@round/auth';
import { ReactComponent as LogoFull } from '../../../../assets/brand/LogoFull.svg';
import { ReactComponent as LogoShort } from '../../../../assets/brand/LogoShort.svg';
import backgroundImage from '../../assets/AuthBackground.png';
import backgroundImageMobile from '../../assets/AuthBackgroundMobile.png';
import styles from './AuthPageLayout.module.css';
import { FCWithChildren } from '../../../../utility/utility.types';

const AuthPageLayout: FCWithChildren = ({ children }) => (
    <AuthPageLayoutLib
        LogoFull={() => <LogoFull className={styles.logoFull} />}
        LogoShort={() => <LogoShort className={styles.logo} />}
        brandingImageSrc={backgroundImage}
        brandingImageMobileSrc={backgroundImageMobile}
    >
        {children}
    </AuthPageLayoutLib>
);

export default AuthPageLayout;
