import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { InstagramAudioListStats } from './audioStats.types';

export const factories = {
    instagramAudioListStats: createFactory<InstagramAudioListStats>({
        audio_id(index: number) {
            return index;
        },
        reels_count(index: number) {
            return index * 100;
        },
        reels_count_daily_change() {
            return Math.random() * 100;
        },
        reels_count_daily_change_relative() {
            return Math.random() * 10;
        },
        reels_count_weekly_change() {
            return Math.random() * 100;
        },
        reels_count_weekly_change_relative() {
            return Math.random() * 10;
        },
        reels_count_time_series: [],
    }),
};

export const models = {
    instagramAudioListStats: Model as ModelDefinition<InstagramAudioListStats>,
};

export const serializers = {
    instagramAudioListStats: makeSerializer<InstagramAudioListStats>([]),
};

export function handleRequests(server: AppServer) {
    const getInstagramAudioStatsUrl = '/api/instagram/viewsets/audio-stats/';
    server.get('/api/instagram/viewsets/audio-stats/', function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('instagramAudioListStats'), {
            url: getInstagramAudioStatsUrl,
            serialize: (resource) => this.serialize(resource, 'instagramAudioListStats'),
            queryParams: request.queryParams,
        });
    });
}
