import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AdminInfluencer } from './admin.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    microwaveAdminInfluencer: Model as ModelDefinition<AdminInfluencer>,
};

export const serializers = {
    microwaveAdminInfluencer: makeSerializer<AdminInfluencer>([]),
};

export const factories = {
    microwaveAdminInfluencer: createFactory<AdminInfluencer>({
        email: (index: number) => `email${index}@gmail.com`,
        location: null,
        follower_count: (index: number) => index,
        tiktok_id: (index: number) => `tiktok_id_${index}`,
        unique_id: (index: number) => `username${index}`,
        user_id: (index: number) => index,
    }),
};

export function handleRequests(server: AppServer) {
    server.get('/api/microwave/admin/influencers/', function (this: RouteHandlerContext, schema, request) {
        const influencers = schema.all('microwaveAdminInfluencer');
        return buildPaginatedResponse(influencers, {
            url: '/api/microwave/admin/influencers/',
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'microwaveAdminInfluencer'),
        });
    });

    server.post('/api/microwave/admin/influencers/', function (this: RouteHandlerContext, schema, request) {
        const body = JSON.parse(request.requestBody);
        schema.create('microwaveAdminInfluencer', { user_id: body.user_id, email: body.email });
        return new Response(
            201,
            {},
            this.serialize(schema.findBy('microwaveAdminInfluencer', { email: body.email }), 'microwaveAdminInfluencer')
        );
    });
}
