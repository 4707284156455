import moment from 'moment';
import DateWarning from '../DateWarning/DateWarning';

type Props = {
    value: Date | string;
    valueFormat?: string;
};

const DueDate = ({ value, valueFormat }: Props) => {
    const currentDate = moment();
    const dueDate = moment(value);
    const isDueDate = dueDate.isSame(currentDate, 'date');
    const isLate = dueDate.isBefore(currentDate, 'date');
    const distance = currentDate.diff(dueDate, 'day');

    const hint = isDueDate ? "It's due date" : `Due date was ${distance} day${distance !== 1 ? 's' : ''} ago`;
    return (
        <DateWarning value={value} valueFormat={valueFormat} warnOn={isDueDate} dangerOn={isLate} tooltipHint={hint} />
    );
};

export default DueDate;
