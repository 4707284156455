import React from 'react';
import { IconProps } from './Icon.types';

type CheckProps = IconProps;

const CheckIcon = ({ width, height, strokeWidth = 2, onClick, className }: CheckProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
            onClick={onClick}
        >
            <polyline points="20 6 9 17 4 12" />
        </svg>
    );
};

export default CheckIcon;
