import React, { forwardRef } from 'react';
import styles from './ScrollView.module.css';
import cn from 'classnames';

type Props = {
    children?: React.ReactNode | undefined;
    className?: string;
    style?: React.CSSProperties;
};

/**
 * Just a simple wrapper component to display pre-styled scrollbar
 * and scrolling behavior
 */
const ScrollView = forwardRef<HTMLDivElement, Props>(({ children, className, style }, ref) => {
    return (
        <div ref={ref} style={style} className={cn(styles.scrollContainer, className)}>
            {children}
        </div>
    );
});

export default ScrollView;
