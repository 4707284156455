import { Model, belongsTo } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AppServer, RouteHandlerContext } from '../../../mirage';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';
import {
    ClientCampaign,
    ClientCampaignBooking,
    ClientCampaignBookingInfluencer,
    ClientCampaignInstagramPostStats,
    ClientCampaignInstagramPostStatsTotals,
    ClientCampaignTiktokPostStats,
    ClientCampaignTiktokPostStatsTotals,
} from './clientCampaign.types';

export const models = {
    clientCampaign: Model as ModelDefinition<ClientCampaign>,
    clientCampaignBooking: Model.extend({
        influencer: belongsTo('clientCampaignBookingInfluencer'),
    }) as ModelDefinition<ClientCampaignBooking>,
    clientCampaignBookingInfluencer: Model as ModelDefinition<ClientCampaignBookingInfluencer>,
    clientCampaignTiktokPostStats: Model as ModelDefinition<ClientCampaignTiktokPostStats>,
    clientCampaignTiktokPostStatsTotals: Model as ModelDefinition<ClientCampaignTiktokPostStatsTotals>,
};

export const factories = {
    clientCampaign: createFactory<ClientCampaign>({
        id(index: number) {
            return index;
        },
        currency: 1,
        name(index: number) {
            return `name ${index}`;
        },
        type() {
            return Math.random() > 0.5 ? 'AUDIO' : 'BRAND';
        },
    }),
    clientCampaignBookingInfluencer: createFactory<ClientCampaignBookingInfluencer>({
        instagram_display_name(index: number) {
            return `Instagram Display Name ${index}`;
        },
        instagram_follower_count(index: number) {
            return index * 1000;
        },
        instagram_user_id(index: number) {
            return index * 10;
        },
        instagram_username(index: number) {
            return `instagram_username_${index}`;
        },
        tiktok_display_name(index: number) {
            return `TikTok Display Name ${index}`;
        },
        tiktok_follower_count(index: number) {
            return index * 2000;
        },
        tiktok_user_id(index: number) {
            return index * 20;
        },
        tiktok_username(index: number) {
            return `tiktok_username_${index}`;
        },
        youtube_username(index: number) {
            return `youtube_username_${index}`;
        },
    }),
    clientCampaignBooking: createFactory<ClientCampaignBooking>({
        brief(index: number) {
            return `brief ${index}`;
        },
        campaign_id(index: number) {
            return index;
        },
        platform() {
            const rand = Math.random();
            return rand > 0.5 ? 'INSTAGRAM' : 'TIKTOK';
        },
        posted_at(index: number) {
            return new Date().getTime() - index * 1000;
        },
        price(index: number) {
            return index * 100;
        },
        status() {
            return 'offer_sent';
        },
        afterCreate(booking, s) {
            const server: AppServer = s;
            if (!booking.influencer) {
                booking.update({
                    influencer: server.create('clientCampaignBookingInfluencer'),
                });
            }
        },
    }),
    clientCampaignTiktokPostStats: createFactory<ClientCampaignTiktokPostStats>({
        digg_count(index: number) {
            return index * 1000;
        },
        share_count(index: number) {
            return index * 500;
        },
        comment_count(index: number) {
            return index * 200;
        },
        play_count(index: number) {
            return index * 10000;
        },
        video_create_time(index: number) {
            return new Date().getTime() - index * 1000;
        },
        ad_code(index: number) {
            return `Ad code ${index}`;
        },
    }),
    clientCampaignTiktokPostStatsTotals: createFactory<ClientCampaignTiktokPostStatsTotals>({
        digg_count(index: number) {
            return index * 1000;
        },
        share_count(index: number) {
            return index * 500;
        },
        comment_count(index: number) {
            return index * 200;
        },
        play_count(index: number) {
            return index * 10000;
        },
    }),
    clientCampaignInstagramPostStats: createFactory<ClientCampaignInstagramPostStats>({
        likes(index: number) {
            return index * 1000;
        },
        comments(index: number) {
            return index * 200;
        },
        plays(index: number) {
            return index * 10000;
        },
        views(index: number) {
            return index * 5000;
        },
        create_time(index: number) {
            return new Date().getTime() - index * 1000;
        },
    }),
    clientCampaignInstagramPostStatsTotals: createFactory<ClientCampaignInstagramPostStatsTotals>({
        likes(index: number) {
            return index * 1000;
        },
        comments(index: number) {
            return index * 200;
        },
        plays(index: number) {
            return index * 10000;
        },
        views(index: number) {
            return index * 5000;
        },
    }),
};

export const serializers = {
    clientCampaign: makeSerializer<ClientCampaign>([]),
    clientCampaignBooking: makeSerializer<ClientCampaignBooking>(['influencer']),
    clientCampaignBookingInfluencer: makeSerializer<ClientCampaignBookingInfluencer>([]),
    clientCampaignTiktokPostStats: makeSerializer<ClientCampaignTiktokPostStats>([]),
    clientCampaignTiktokPostStatsTotals: makeSerializer<ClientCampaignTiktokPostStatsTotals>([]),
    clientCampaignInstagramPostStats: makeSerializer<ClientCampaignInstagramPostStats>([]),
    clientCampaignInstagramPostStatsTotals: makeSerializer<ClientCampaignInstagramPostStatsTotals>([]),
};

export async function handleClientCampaignRequests(server: AppServer) {
    server.get('/api/apex/client/campaign/', (schema, request) => {
        return schema.all('clientCampaign');
    });

    server.get('/api/apex/client/campaign/:id/', function (schema, request) {
        return schema.find('clientCampaign', request.params.id);
    });

    server.post('/api/apex/client/campaign/', function (schema, request) {
        return schema.create('clientCampaign', JSON.parse(request.requestBody));
    });

    server.get('/api/apex/client/campaign/:campaignId/booking/', function (this: RouteHandlerContext, schema, request) {
        const bookings = schema.all('clientCampaignBooking');
        return bookings.filter((booking) => booking.campaign_id === request.params.campaignId);
    });

    server.get(
        '/api/apex/client/campaign/:campaignId/tiktok-post-stats/',
        function (this: RouteHandlerContext, schema, request) {
            const stats = schema.all('clientCampaignTiktokPostStats');

            const totals = schema.find('clientCampaignTiktokPostStatsTotals');

            const paginatedResponse = buildPaginatedResponse(stats, {
                url: `/api/apex/client/campaign/${request.params.campaignId}/tiktok-post-stats/`,
                queryParams: request.queryParams,
                serialize: (resource) => this.serialize('clientCampaignTiktokPostStats', resource),
            });

            return {
                ...paginatedResponse,
                totals,
            };
        }
    );

    server.get(
        '/api/apex/client/campaign/:campaignId/instagram-post-stats/',
        function (this: RouteHandlerContext, schema, request) {
            const stats = schema.all('clientCampaignInstagramPostStats');

            const totals = schema.find('clientCampaignInstagramPostStatsTotals');

            const paginatedResponse = buildPaginatedResponse(stats, {
                url: `/api/apex/client/campaign/${request.params.campaignId}/instagram-post-stats/`,
                queryParams: request.queryParams,
                serialize: (resource) => this.serialize('clientCampaignInstagramPostStats', resource),
            });

            return {
                ...paginatedResponse,
                totals,
            };
        }
    );
}
