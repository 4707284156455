import { creatorbase } from '@round/api';
import { showNotification } from 'helpers';
import { usePosts } from 'Modules/Plans/Project/contexts/PostsContext';
import { useState } from 'react';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import styles from './DeletePostModal.module.css';
import Button from 'ui-new/whitelabel/Button/Button';
import cn from 'classnames';
import { ReactComponent as LinkIcon } from 'assets/icons/Link.svg';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    postId: creatorbase.Post['id'] | null;
};

const DeletePostModal = ({ isOpen, onClose, postId }: Props) => {
    const { data: posts, deletePost } = usePosts();
    const post = posts?.find((p) => p.id === postId);

    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const handleDelete = async () => {
        if (!post) {
            return;
        }

        try {
            setIsDeleteLoading(true);
            const response = await deletePost(post.id);
            if (response?.status === 204) {
                showNotification('Post deleted', 'info');
                onClose();
                return;
            }

            showNotification('Could not delete post', 'error');
        } catch {
            showNotification('Could not delete post', 'error');
        } finally {
            setIsDeleteLoading(false);
        }
    };

    return (
        <Modal className={styles.modal} closeOnOverlayClick isOpen={isOpen} onClose={onClose}>
            <Modal.Header>
                <Modal.Title>Delete post</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.body}>
                <p className={cn(styles.copy, styles.bold)}>You’re about to permanently delete the following:</p>
                <div className={styles.banner}>
                    <p className={styles.copy}>
                        <span className={styles.bold}>Post</span>:&nbsp;
                        {post && (
                            <a className={styles.link} href={post.post_url} target="_blank" rel="noreferrer">
                                {post.id.slice(-8)} <LinkIcon className={styles.linkIcon} />
                            </a>
                        )}
                    </p>
                </div>

                <p className={styles.copy}>You cannot restore posts. They are deleted permanently.</p>
            </Modal.Body>

            <Modal.Actions>
                <Button appearance="critical" isLoading={isDeleteLoading} onClick={handleDelete}>
                    Delete Post
                </Button>
                <Button appearance="ghost" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DeletePostModal;
