import { forwardRef, InputHTMLAttributes, ReactNode, Ref } from 'react';
import styles from './Input.module.css';
import cn from 'classnames';

type Props = InputHTMLAttributes<HTMLInputElement> & {
    hasError?: boolean;
    containerClassName?: string;
    renderRight?: () => ReactNode;
};

const Input = forwardRef(
    ({ className, hasError, containerClassName, renderRight, ...props }: Props, ref: Ref<HTMLInputElement>) => {
        return (
            <div className={cn(styles.inputContainer, containerClassName, { [styles.error]: hasError })}>
                <input ref={ref} className={cn(styles.input, className)} {...props} />
                {renderRight?.()}
            </div>
        );
    }
);

export default Input;
