import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokAudioDailyStats } from './audioStatsDaily.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokAudioDailyStats: Model as ModelDefinition<TiktokAudioDailyStats>,
};

export const serializers = {
    tiktokAudioDailyStats: makeSerializer<TiktokAudioDailyStats>([]),
};

export const factories = {
    tiktokAudioDailyStats: createFactory<TiktokAudioDailyStats>({
        timestamp: new Date().toISOString(),
        video_count: 0,
    }),
};

export function handleRequests(server: AppServer) {
    const listUrl = '/api/tiktok/music-stats-daily/';
    server.get(listUrl, function (this: RouteHandlerContext, schema, request) {
        const stats = schema.all('tiktokAudioDailyStats');
        return buildPaginatedResponse(stats, {
            url: listUrl,
            serialize: (resource) => this.serialize(resource, 'tiktokAudioDailyStats'),
            queryParams: request.queryParams,
        });
    });
}
