import { Modal, ModalTitle, ModalContent, FormField, Label, Button } from '@round/ui-kit';
import styles from './CreateTiktokMicroPostModal.module.css';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import InputWithIcons from '../../../../ui/DataEntry/Input/InputWithIcons/InputWithIcons';
import { microwave } from '@round/api';
import * as Yup from 'yup';
import { mapApiErrorsToFormikErrors } from '../../../../utility/utility';
import { showNotification } from '../../../../helpers';
import cn from 'classnames';
import { isValidTikTokPostUrl, sanitizeTikTokPostUrl } from '../../../../utility/tiktokPostValidation';
import { ReactComponent as ValidCircle } from '../../../../assets/CheckCircle.svg';
import { ReactComponent as InvalidCircle } from '../../../../assets/CloseCircle.svg';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onCreated: () => void;
    campaignId: number | undefined;
};

type FormValues = Pick<microwave.TiktokMicrowaveInfluencerPost, 'post_url'> & {
    payment_details: string;
    preferred_payment_method: microwave.PaymentMethod | null;
    venmo_email_address: string;
    paypal_email_address: string;
};

const initialValues: FormValues = {
    post_url: '',
    preferred_payment_method: null,
    venmo_email_address: '',
    paypal_email_address: '',
    payment_details: '',
};

const validationSchema = Yup.object().shape(
    {
        post_url: Yup.string()
            .required('TikTok Post URL is required')
            .test('post_url', (value, ctx) => {
                const sanitizedValue = sanitizeTikTokPostUrl(value || '');
                const localValidationResult = isValidTikTokPostUrl(sanitizedValue);
                if (!localValidationResult.isValid) {
                    return ctx.createError({ message: localValidationResult.message, path: 'post_url' });
                }
                return true;
            }),
        preferred_payment_method: Yup.string().required('At least one payment method is required'),
        paypal_email_address: Yup.string()
            .when('preferred_payment_method', {
                is: (value: microwave.PaymentMethod) => value === 'PAYPAL',
                then: Yup.string().required('Payment details are required'),
                otherwise: Yup.string(),
            })
            .email('Must be a valid email address'),
        payment_details: Yup.string().when('preferred_payment_method', {
            is: (value: microwave.PaymentMethod) => value === 'BANK_TRANSFER',
            then: Yup.string().required('Payment details are required'),
            otherwise: Yup.string(),
        }),
        venmo_email_address: Yup.string().when('preferred_payment_method', {
            is: (value: microwave.PaymentMethod) => value === 'VENMO',
            then: Yup.string().required('Payment details are required'),
            otherwise: Yup.string(),
        }),
    },
    [['paypal_email_address', 'payment_details']]
);

const CreateTiktokMicroPostModal = ({ isOpen, onClose, onCreated, campaignId }: Props) => {
    const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        if (!campaignId || !values.preferred_payment_method) {
            return;
        }

        try {
            helpers.setSubmitting(true);
            const response = await microwave.postTiktokMicrowaveInfluencerPost({
                campaign_id: campaignId,
                post_url: values.post_url,
                payment_details: values.payment_details,
                paypal_email_address: values.paypal_email_address,
                preferred_payment_method: values.preferred_payment_method,
                venmo_email_address: values.venmo_email_address,
            });

            if (response.status === 201) {
                showNotification('Post added', 'info');
                onCreated();
                return;
            }

            if (Array.isArray(response.data)) {
                showNotification(response.data.join('. '), 'error');
                return;
            }

            helpers.setErrors(mapApiErrorsToFormikErrors<FormValues>(response.data));
        } catch {
            showNotification(`Couldn't submit the form. Please try again later.`, 'error');
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal className={styles.modal} isOpen={isOpen} onClose={handleClose} closeOnOverlayClick>
            <ModalTitle>Create Post</ModalTitle>
            <ModalContent className={styles.content}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnBlur
                    validateOnChange={false}
                    onSubmit={handleSubmit}
                >
                    {(props) => {
                        const selectedPaymentMethod = props.values.preferred_payment_method;

                        const handlePaymentMethodChange = (value: FormValues['preferred_payment_method']) => {
                            if (selectedPaymentMethod === value) {
                                return;
                            }

                            props.setErrors({
                                ...props.errors,
                                preferred_payment_method: '',
                                payment_details: '',
                                paypal_email_address: '',
                                venmo_email_address: '',
                            });
                            props.setValues({
                                ...props.values,
                                paypal_email_address: '',
                                payment_details: '',
                                venmo_email_address: '',
                            });

                            props.setFieldValue('preferred_payment_method', value);
                        };
                        return (
                            <Form className={styles.form}>
                                <FormField>
                                    <Label htmlFor="post_url">Post URL</Label>
                                    <Field name="post_url">
                                        {(fieldProps: FieldProps<FormValues['post_url']>) => (
                                            <InputWithIcons
                                                name="post_url"
                                                className={cn(styles.field, styles.postUrl, {
                                                    [styles.invalid]:
                                                        !!props.errors.post_url && fieldProps.form.touched.post_url,
                                                })}
                                                value={fieldProps.field.value}
                                                onChange={(e) => {
                                                    props.setFieldValue('post_url', e.target.value);
                                                    // Reset the touched state so that the validity icon is not shown
                                                    fieldProps.form.setFieldTouched('post_url', false);
                                                }}
                                                onBlur={(e) => {
                                                    fieldProps.form.setFieldTouched('post_url', true);
                                                }}
                                                placeholder="Enter TikTok Post link"
                                                renderIconRight={() => {
                                                    if (!fieldProps.field.value || !fieldProps.form.touched.post_url) {
                                                        return null;
                                                    }
                                                    return renderValidityIcon(!props.errors.post_url);
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="post_url">
                                        {(msg) => <div className={styles.error}>{msg}</div>}
                                    </ErrorMessage>
                                </FormField>

                                <div className={styles.paymentOptionsContainer}>
                                    <label>Payment Method</label>
                                    <article>
                                        <button
                                            type="button"
                                            onClick={() => handlePaymentMethodChange('PAYPAL')}
                                            className={cn(styles.paymentButton, {
                                                [styles.selected]: selectedPaymentMethod === 'PAYPAL',
                                            })}
                                        >
                                            <div>PayPal</div>
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handlePaymentMethodChange('VENMO')}
                                            className={cn(styles.paymentButton, {
                                                [styles.selected]: selectedPaymentMethod === 'VENMO',
                                            })}
                                        >
                                            <div>Venmo</div>
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handlePaymentMethodChange('BANK_TRANSFER')}
                                            className={cn(styles.paymentButton, {
                                                [styles.selected]: selectedPaymentMethod === 'BANK_TRANSFER',
                                            })}
                                        >
                                            <div>Bank Details</div>
                                        </button>
                                    </article>

                                    <ErrorMessage name="preferred_payment_method">
                                        {(msg) => <div className={styles.error}>{msg}</div>}
                                    </ErrorMessage>
                                </div>

                                {selectedPaymentMethod === 'PAYPAL' && (
                                    <div>
                                        <FormField>
                                            <Label htmlFor="paypal_email_address">PayPal Email Address</Label>
                                            <Field
                                                name="paypal_email_address"
                                                className={styles.field}
                                                placeholder="Enter PayPal Email Address"
                                            />
                                            <ErrorMessage name="paypal_email_address">
                                                {(msg) => <div className={styles.error}>{msg}</div>}
                                            </ErrorMessage>
                                        </FormField>
                                    </div>
                                )}

                                {selectedPaymentMethod === 'VENMO' && (
                                    <div>
                                        <FormField>
                                            <Label htmlFor="venmo_email_address">Venmo Email Address</Label>
                                            <Field
                                                name="venmo_email_address"
                                                className={styles.field}
                                                placeholder="Enter Venmo Email Address"
                                            />
                                            <ErrorMessage name="venmo_email_address">
                                                {(msg) => <div className={styles.error}>{msg}</div>}
                                            </ErrorMessage>
                                        </FormField>
                                    </div>
                                )}

                                {selectedPaymentMethod === 'BANK_TRANSFER' && (
                                    <div>
                                        <FormField>
                                            <Label htmlFor="payment_details">Payment Information</Label>
                                            <Field
                                                as="textarea"
                                                name="payment_details"
                                                className={styles.field}
                                                placeholder="Enter Payment Details"
                                            />
                                            <ErrorMessage name="payment_details">
                                                {(msg) => <div className={styles.error}>{msg}</div>}
                                            </ErrorMessage>
                                        </FormField>
                                    </div>
                                )}

                                <Button
                                    className={styles.submit}
                                    htmlType="submit"
                                    loading={props.isSubmitting}
                                    disabled={!props.dirty || !props.isValid}
                                    color="black"
                                    type="filled"
                                >
                                    Submit Form
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default CreateTiktokMicroPostModal;

const renderValidityIcon = (isValid: boolean) => {
    if (isValid) {
        return <ValidCircle className={styles.valid} />;
    }
    return <InvalidCircle className={styles.invalid} />;
};
