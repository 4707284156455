import { TiktokInfluencerPostApiBody } from '../types/Tiktok.types';
import { fetchWithToken, TiktokInfluencerPost } from '@round/api';
import { ApiResponse, ApiResponseError } from '../../../../utility/utility.types';

type PatchTiktokInfluencerPostResponse =
    | ApiResponse<TiktokInfluencerPost, 200>
    | ApiResponse<ApiResponseError<TiktokInfluencerPostApiBody>, 400>;

export async function patchTiktokInfluencerPost(
    postId: number,
    post: Partial<TiktokInfluencerPostApiBody>
): Promise<PatchTiktokInfluencerPostResponse> {
    const response = await fetchWithToken(`/api/advertising/viewsets/tiktok-influencer-post/${postId}/`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(post),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not PATCH tiktok influencer post: ${postId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function deleteTiktokInfluencerPost(postId: number): Promise<ApiResponse<undefined, 204>> {
    const response = await fetchWithToken(`/api/advertising/viewsets/tiktok-influencer-post/${postId}/`, {
        method: 'DELETE',
    });

    if (!response.ok) {
        throw new Error(`Could not delete tiktok influencer post ${postId}`);
    }

    return {
        status: 204,
        data: undefined,
    };
}
