import React, { useMemo } from 'react';
import { useInfluencerUserPostsAudioData } from '../TiktokUserDataContext/TiktokUserDataContext';
import { groupBy } from 'lodash';
import { AudioData } from '../../../../TikTok.types';
import { AggregatedAudioData, AggregatedDataByType, AudioTypes, getAudioType, getAverageAudioValues } from './helpers';
import AudioTypesChart from './AudioTypesChart/AudioTypesChart';
import AudioTypesTable from './AudioTypesTable/AudioTypesTable';
import styles from './Audios.module.css';
import AudiosUsedTable from './AudiosUsedTable/AudiosUsedTable';

export type AudiosProps = {
    influencerUserId: number | undefined | null;
};

const Audios = ({ influencerUserId }: AudiosProps) => {
    const {
        influencerUserPostsAudioData,
        influencerUserPostsAudioDataLoading,
        errorLoadingInfluencerUserPostsAudioData,
    } = useInfluencerUserPostsAudioData(influencerUserId);

    const numberOfPostsUsed = `Last ${influencerUserPostsAudioData.length} post${
        influencerUserPostsAudioData.length !== 1 ? 's' : ''
    } used`;

    const audioDataByType: AggregatedDataByType[] = useMemo(() => {
        const groups: Record<AudioTypes, AudioData[]> = {
            [AudioTypes.Official]: [],
            [AudioTypes.OriginalOwn]: [],
            [AudioTypes.OriginalOther]: [],
        };

        influencerUserPostsAudioData.forEach((a) => groups[getAudioType(a)].push(a));
        return Object.entries(groups).map(([t, data]) => {
            const type = t as AudioTypes;
            if (data.length === 0) {
                return {
                    type,
                    post_shares: 0,
                    post_comments: 0,
                    post_likes: 0,
                    video_plays: 0,
                    post_saves: 0,
                    numberOfPosts: 0,
                };
            }

            return { type, numberOfPosts: data.length, ...getAverageAudioValues(data) };
        });
    }, [influencerUserPostsAudioData]);

    const groupedByAudios: AggregatedAudioData[] = useMemo(() => {
        const grouped = groupBy(influencerUserPostsAudioData, (a) => a.audio_id);
        return Object.values(grouped).map<AggregatedAudioData>((data) => {
            const first = data[0];
            return {
                ...getAverageAudioValues(data),
                type: getAudioType(first),
                audio_id: first.audio_id,
                audio_name: first.audio_name,
                audio_tiktok_id: first.audio_tiktok_id,
                audio_type: first.audio_type,
                is_own_audio: first.is_own_audio,
                numberOfPosts: data.length,
            };
        });
    }, [influencerUserPostsAudioData]);

    return (
        <div className={styles.container}>
            <div>
                <p className={styles.title}>Types of audio used</p>
                <p className={styles.numberOfPostsUsed}>{numberOfPostsUsed}</p>
            </div>
            <div className={styles.audioTypesRow}>
                <AudioTypesChart className={styles.audioTypesChart} audios={audioDataByType} />
                <AudioTypesTable
                    data={audioDataByType}
                    loading={influencerUserPostsAudioDataLoading}
                    errorLoading={errorLoadingInfluencerUserPostsAudioData}
                />
            </div>

            <div>
                <p className={styles.title}>Audios used</p>
                <p className={styles.numberOfPostsUsed}>{numberOfPostsUsed}</p>
            </div>
            <AudiosUsedTable
                data={groupedByAudios}
                loading={influencerUserPostsAudioDataLoading}
                errorLoading={errorLoadingInfluencerUserPostsAudioData}
            />
        </div>
    );
};

export default Audios;
