import React from 'react';
import Select, { StylesConfig, ValueType } from 'react-select';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';

import formStyles from '../Forms.module.css';
import styles from './MultiSelect.module.css';

const selectStyle: StylesConfig = {
    control: (provided) => ({
        ...provided,
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingTop: '0.3rem',
        paddingBottom: '0.3rem',
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    input: (provided) => ({
        ...provided,
        marginTop: '0',
        paddingTop: '0',
    }),
    option: (provided, state) => {
        return {
            ...provided,
            backgroundColor: state.isFocused ? '#F2F9FF !important' : 'null',
            color: '#707683',
            cursor: 'pointer',
            padding: '0.4rem 0.2rem',
        };
    },
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 1000,
    }),
};

type MultiSelectProps<SelectOption extends Record<string, any>> = {
    options: SelectOption[];
    /**
     * property of option to use as label
     * @default 'label'
     */
    optionLabel?: keyof SelectOption;
    /**
     * property of option to use as value
     * @default 'value'
     */
    optionValue?: keyof SelectOption;
    stylesConfig?: StylesConfig;
} & FieldHookConfig<ValueType<SelectOption, true>>;

/**
 * MultiSelect component that can be used as Formik field.
 * Values are values of optionValue property.
 * @param stylesConfig
 * @param props
 * @constructor
 * @example
 * const options = [
 *     {id: 1, name: 'Mobile', value: 'mobile'},
 *     {id: 2, name: 'Desktop', value: 'desktop'},
 * ];
 * // ...
 * return (
 *  <Formik
 *      initialValues={{ devicePlatforms: [] }}
 *      onSubmit={(values) => {
 *          console.log(values); // 1, 2 ... id property of options
 *      }}
 *  >
 *      {() => (
 *          <Form>
 *              <MultiSelect
 *                  options={options}
 *                  optionLabel="name"
 *                  optionValue="id"
 *                  name="devicePlatforms"
 *              />
 *          </Form>
 *      )}
 *  </Formik>
 * );
 */
export default function MultiSelect<MultiSelectOptionType extends Record<string, any>>({
    stylesConfig = selectStyle,
    ...props
}: MultiSelectProps<MultiSelectOptionType>) {
    const { options, optionLabel = 'label', optionValue = 'value', ...otherProps } = props;

    type Option = {
        value: MultiSelectOptionType[keyof MultiSelectOptionType];
        label: MultiSelectOptionType[keyof MultiSelectOptionType];
    };

    const selectOptions = options.map<Option>((option) => ({
        value: option[optionValue],
        label: option[optionLabel],
    }));

    const fieldHook = useField(otherProps);
    const field = fieldHook[0];
    const helpers = fieldHook[2];

    const getSelectValue = (values: ValueType<any, true>) =>
        selectOptions.filter((option) => !!values?.find((value) => value === option.value));

    const onChange = (selectedValues: ValueType<Option, true>) => {
        helpers.setValue(
            options
                .filter((option) => selectedValues?.find((selected) => option[optionValue] === selected.value))
                .map((option) => option[optionValue])
        );
    };

    return (
        <>
            <Select
                {...field}
                isMulti
                cacheOptions
                defaultOptions
                closeMenuOnSelect={false}
                menuPortalTarget={document.body}
                styles={stylesConfig}
                options={selectOptions}
                value={getSelectValue(field.value)}
                onChange={onChange}
                onBlur={() => helpers.setTouched(true)}
                className={styles.field}
            />
            <ErrorMessage name={field.name}>
                {(message) => <span className={formStyles.errorMessage}>{message}</span>}
            </ErrorMessage>
        </>
    );
}
