import { creatorbase } from '@round/api';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { CellContext } from '@tanstack/react-table';
import { formatPostUploadDate } from 'Modules/Plans/Posts/helpers';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';

type Row = creatorbase.PublicPost;

type Context<T extends Row> = CellContext<T, string | null | undefined>;

const getTableMeta = getTableMetaHelper<BasePostTableMeta<Row>>();

const TiktokPostUploadDateCell = <T extends Row>({ row, table, getValue }: Context<T>) => {
    const { isLoading, getAreStatsLoading } = getTableMeta(table);
    const areStatsLoading = getAreStatsLoading ? getAreStatsLoading(row.original) : false;

    if (isLoading || areStatsLoading) {
        return <Skeleton />;
    }

    const uploadDate = getValue();

    if (uploadDate === null || uploadDate === undefined) {
        return <>-</>;
    }

    return <>{formatPostUploadDate(uploadDate)}</>;
};

export default TiktokPostUploadDateCell;
