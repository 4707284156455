import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { InstagramUser } from './instagramUser.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';

export async function getInstagramUser(
    userId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<InstagramUser, 200> | NotFoundResponse> {
    const response = await fetchWithToken(`/api/instagram/viewsets/user/${userId}/`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not fetch instagram user');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type GetInstagramUsersParams = Partial<
    PaginatedRequest & {
        id: string;
        search: string;
        //username || url
        identifier_search: string;
    }
>;

export async function getInstagramUsers(
    params: GetInstagramUsersParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<InstagramUser>, 200> | NotFoundResponse> {
    const response = await fetchWithToken(`/api/instagram/viewsets/user/${encodeUrlSearchParams(params)}`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not fetch instagram users');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PostInstagramUserData = {
    //username || url
    identifier: string;
};

type PostInstagramUserResponse =
    | ApiResponse<InstagramUser, 201>
    | ApiResponse<ApiResponseError<PostInstagramUserData>, 400>;

export async function postInstagramUser(data: PostInstagramUserData): Promise<PostInstagramUserResponse> {
    const response = await fetchWithToken('/api/instagram/viewsets/user/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create instagram user');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}
