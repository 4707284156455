import { CampaignStatus } from '../microwave.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import { ApiResponse, NotFoundResponse, OrderingValues, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { Release } from './releases.types';

export type GetReleasesSortableKeys = 'latest_campaign_live_date';

export const isGetReleasesOrderingValid = (key: string): key is OrderingValues<GetReleasesSortableKeys> =>
    key === 'latest_campaign_live_date' || key === '-latest_campaign_live_date';
export type GetReleasesParams = Partial<
    PaginatedRequest & {
        ordering: OrderingValues<GetReleasesSortableKeys>;
        status: CampaignStatus;
        search: string;
    }
>;

type GetReleasesResponse = ApiResponse<PaginatedApiResponseData<Release>, 200> | NotFoundResponse;

/**
 * Retrieves releases that have microwave campaigns associated.
 */
export async function getReleases(params: GetReleasesParams, requestInit?: RequestInit): Promise<GetReleasesResponse> {
    const response = await fetchWithToken(`/api/microwave/releases/${encodeUrlSearchParams(params)}`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get microwave releases');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetReleaseResponse = ApiResponse<Release, 200> | NotFoundResponse;
export async function getRelease(releaseId: number, requestInit?: RequestInit): Promise<GetReleaseResponse> {
    const response = await fetchWithToken(`/api/microwave/releases/${releaseId}/`, requestInit);

    if (response.status === 404) {
        return { status: response.status, data: await response.json() };
    }

    if (!response.ok) {
        throw new Error('Could not fetch release');
    }

    return { status: 200, data: await response.json() };
}
