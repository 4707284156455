import { microwave } from '@round/api';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { useCallback, useMemo, useState } from 'react';
import { useMicrowaveCampaigns } from '../hooks/useMicrowaveCampaigns';
import { useMicrowaveReleases } from '../hooks/useMicrowaveReleases';
import uniq from 'lodash/uniq';
import { MicroCampaignsTableRow } from './MicroCampaignsTable/MicroCampaignsTable';
import usePlannerUsers from 'Modules/Auth/hooks/usePlannerUsers';
import { showNotification } from 'helpers';
import { isNumber } from 'lodash';

type Status = 'not-initialized' | 'loading' | 'initialized' | 'error';

export function useMicrowaveCampaignsList() {
    const [status, setStatus] = useState<Status>('not-initialized');
    const { fetchData: fetchReleases, ...microwaveReleases } = useMicrowaveReleases();

    const fetchData = useCallback(
        async (params: microwave.GetReleasesParams, requestInit?: RequestInit) => {
            try {
                const response = await fetchReleases(params, requestInit);

                if (response.status === 404) {
                    setStatus('error');
                    return response;
                }
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setStatus('error');
                throw e;
            }
        },
        [fetchReleases]
    );

    const {
        fetchData: fetchCampaigns,
        createCampaign: microwaveCampaignsCreateCampaign,
        updateCampaign,
        ...microwaveCampaigns
    } = useMicrowaveCampaigns();

    useAbortableEffect(
        (signal) => {
            const fetchCampaignsData = async (releaseIds: number[], pageSize: number) => {
                try {
                    const response = await fetchCampaigns(
                        { release_id: releaseIds.join(','), page_size: pageSize },
                        { signal }
                    );

                    if (response.status === 404) {
                        setStatus('error');
                        return;
                    }
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }
                    setStatus('error');
                }
            };

            if (microwaveReleases.status !== 'initialized') {
                return;
            }

            const releaseIds = uniq(microwaveReleases.data.results.map((release) => release.id));

            if (releaseIds.length === 0) {
                setStatus('initialized');
                return;
            }

            const campaignCount = microwaveReleases.data.results.flatMap((release) => release.campaign_ids).length;
            fetchCampaignsData(releaseIds, campaignCount);
        },
        [microwaveReleases.status, microwaveReleases.data?.results, fetchCampaigns]
    );

    const { fetchData: fetchPlanners, ...plannersData } = usePlannerUsers();

    useAbortableEffect(
        (signal) => {
            const fetchPlannersData = async (ids: number[]) => {
                try {
                    await fetchPlanners({ id: ids.join(','), page_size: ids.length }, { signal });
                } catch {
                    showNotification('Could not fetch planners', 'error');
                } finally {
                    setStatus('initialized');
                }
            };

            if (microwaveCampaigns.status !== 'initialized') {
                return;
            }

            const plannerIds = uniq(
                microwaveCampaigns.data.results.flatMap((campaign) => campaign.team_members)
            ).filter(isNumber);

            if (plannerIds.length === 0) {
                setStatus('initialized');
                return;
            }

            fetchPlannersData(plannerIds);
        },
        [microwaveCampaigns.status, microwaveCampaigns.data?.results, fetchPlanners]
    );

    const reset = useCallback(() => {
        microwaveReleases.reset();
        microwaveCampaigns.reset();
        plannersData.reset();
        setStatus('not-initialized');
    }, [microwaveReleases, microwaveCampaigns, plannersData]);

    const createCampaign = useCallback(
        async (data: microwave.CampaignApiBody) => {
            const response = await microwaveCampaignsCreateCampaign(data);

            if (response.status === 201) {
                reset();
            }

            return response;
        },
        [microwaveCampaignsCreateCampaign, reset]
    );

    const rows: MicroCampaignsTableRow[] = useMemo(
        () =>
            microwaveReleases.data?.results.reduce((acc, release) => {
                const campaigns =
                    microwaveCampaigns.data?.results.filter((campaign) => release.campaign_ids.includes(campaign.id)) ??
                    [];

                if (!campaigns.length) {
                    return acc;
                }

                const campaignRows = campaigns.map<MicroCampaignsTableRow>((campaign) => {
                    const planners = plannersData.planners.filter((planner) =>
                        campaign.team_members?.includes(planner.id)
                    );

                    return {
                        ...campaign,
                        release,
                        planners,
                    };
                });

                return [...acc, ...campaignRows];
            }, [] as MicroCampaignsTableRow[]) ?? [],

        [microwaveReleases.data?.results, microwaveCampaigns.data?.results, plannersData.planners]
    );

    return {
        data: {
            rows: rows,
            count: microwaveReleases.data?.count ?? 0,
        },
        status,

        fetchData,
        createCampaign,
        updateCampaign,
        reset,
    };
}
