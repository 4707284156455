import { fetchWithToken } from '../../helpers';
import { ApiResponse, ApiResponseError } from '../../types';
import { InstagramTopVideo } from './instagramTopVideo.types';

export async function getInstagramTopUserVideos(
    userId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<InstagramTopVideo[], 200> | ApiResponse<ApiResponseError<InstagramTopVideo>, 404>> {
    const response = await fetchWithToken(`/api/instagram/top-user-videos/${userId}/`, requestInit);
    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not fetch top videos for user ID ${userId}`);
    }

    return {
        status: 200,
        data: (await response.json()).results,
    };
}
