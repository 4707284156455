import { AppServer } from '../mirage';
import * as clientsMirage from './clients/clients.mirage';
import * as songMirage from './song/song.mirage';
import * as audioStatsMirage from './tiktokAudioStats/tiktokAudioStats.mirage';
import * as songCollectionMirage from './songCollection/songCollection.mirage';
import * as topVideosMirage from './topVideos/topVideos.mirage';
import * as tiktokAudioMirage from './tiktokAudio/tiktokAudio.mirage';

export const models = {
    ...clientsMirage.models,
    ...songMirage.models,
    ...audioStatsMirage.models,
    ...songCollectionMirage.models,
    ...topVideosMirage.models,
    ...tiktokAudioMirage.models,
};

export const serializers = {
    ...clientsMirage.serializers,
    ...songMirage.serializers,
    ...audioStatsMirage.serializers,
    ...songCollectionMirage.serializers,
    ...topVideosMirage.serializers,
    ...tiktokAudioMirage.serializers,
};

export const factories = {
    ...clientsMirage.factories,
    ...songMirage.factories,
    ...audioStatsMirage.factories,
    ...songCollectionMirage.factories,
    ...topVideosMirage.factories,
    ...tiktokAudioMirage.factories,
};

export function handleRequests(server: AppServer) {
    clientsMirage.handleRequests(server);
    songMirage.handleRequests(server);
    audioStatsMirage.handleRequests(server);
    songCollectionMirage.handleRequests(server);
    topVideosMirage.handleRequests(server);
    tiktokAudioMirage.handleRequests(server);
}
