import { GetPostPaymentRequestsParams, MakePaymentData } from '@round/api';
import usePostPaymentRequests from './usePostPaymentRequests';
import useInfluencerPlans from '../../../Advertising/InfluencerPlan/hooks/useInfluencerPlans';
import useTiktokUsers from '../../../Advertising/Microwave/MicrowaveInfluencers/CreateMicrowaveInfluencer/useTiktokUsers';
import useTiktokInfluencerPosts from '../../../Advertising/InfluencerPlan/hooks/useTiktokInfluencerPosts';
import { useCallback, useMemo, useState } from 'react';
import { OutstandingPaymentsTableRow } from './OutstandingPaymentsTable/OutstandingPaymentsTable';
import { isNumber } from 'utility/utility';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import uniq from 'lodash/uniq';
import { InferPromiseValue } from 'utility/utility.types';
import useInstagramInfluencerPosts from 'Modules/Advertising/InfluencerPlan/hooks/useInstagramInfluencerPosts';
import { useInstagramUsers } from 'Modules/Instagram/hooks/useInstagramUsers';
import useInstagramInfluencerUsers from 'Modules/Instagram/hooks/useInstagramInfluencerUsers';
import useInstagramUserImages from 'Modules/Instagram/hooks/useInstagramUserImages';
import { useYoutubeInfluencerPosts } from 'Modules/Advertising/InfluencerPlan/hooks/useYoutubeInfluencerPosts';

type Params = GetPostPaymentRequestsParams;
type Status = 'not-initialized' | 'loading' | 'initialized' | 'error';

export type UseOutstandingPaymentsReturn = ReturnType<typeof useOutstandingPayments>;

export default function useOutstandingPayments() {
    const [status, setStatus] = useState<Status>('not-initialized');

    const {
        fetchData: fetchPaymentRequestsData,
        payPayPal: paymentRequestsPayPayPal,
        remove: removePaymentRequest,
        payPayPalManually: paymentRequestsPayPayPalManually,
        ...paymentRequests
    } = usePostPaymentRequests();
    const { fetchData: fetchInfluencerPlansData, ...influencerPlans } = useInfluencerPlans();
    const { fetchData: fetchTiktokInfluencerPostsData, ...tiktokInfluencerPosts } = useTiktokInfluencerPosts();
    const { fetchData: fetchTiktokUsersData, ...tiktokUsers } = useTiktokUsers({});
    const { fetchData: fetchInstagramInfluencerPostsData, ...instagramInfluencerPosts } = useInstagramInfluencerPosts();
    const { fetchData: fetchInstagramInfluencerUsersData, ...instagramInfluencerUsers } = useInstagramInfluencerUsers();
    const { fetchData: fetchInstagramUsers, ...instagramUsers } = useInstagramUsers();
    const { fetchData: fetchInstagramUserImages, ...instagramUserImages } = useInstagramUserImages();
    const { fetchData: fetchYoutubeInfluencerPosts, ...youtubeInfluencerPosts } = useYoutubeInfluencerPosts();

    const fetchData = useCallback(
        async (params: Params, requestInit?: RequestInit) => {
            try {
                setStatus('loading');
                const paymentRequestsResponse = await fetchPaymentRequestsData(params, requestInit);
                if (paymentRequestsResponse.status === 404) {
                    setStatus('error');
                    return paymentRequestsResponse;
                }

                if (!paymentRequestsResponse.data.results.length) {
                    setStatus('initialized');
                }
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setStatus('error');
                throw e;
            }
        },
        [fetchPaymentRequestsData]
    );

    const fetchPosts = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                return [];
            }

            return fetchTiktokInfluencerPostsData({ id: ids.toString(), page_size: ids.length }, requestInit).then(
                (response) => response.data.results
            );
        },
        [fetchTiktokInfluencerPostsData]
    );

    const fetchTiktokUsers = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                return [];
            }

            return fetchTiktokUsersData({ id: ids.toString(), page_size: ids.length }, requestInit);
        },
        [fetchTiktokUsersData]
    );

    const fetchInstagramPosts = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                return [];
            }

            return fetchInstagramInfluencerPostsData({ id: ids.toString(), page_size: ids.length }, requestInit);
        },
        [fetchInstagramInfluencerPostsData]
    );

    const fetchInstagramUsersData = useCallback(
        async (influencerUserIds: number[], requestInit?: RequestInit) => {
            if (!influencerUserIds.length) {
                return [];
            }

            const influencerUsersResponse = await fetchInstagramInfluencerUsersData(
                {
                    id: influencerUserIds.toString(),
                    page_size: influencerUserIds.length,
                },
                requestInit
            );

            const existingUserIds = instagramUsers.state.data?.users.map((u) => u.id) ?? [];
            const ids = uniq(
                influencerUsersResponse.data.results
                    .map((user) => user.user)
                    .filter(isNumber)
                    .filter((id) => !existingUserIds.includes(id))
            );

            if (!ids.length) {
                return [];
            }

            return Promise.allSettled([
                fetchInstagramUsers({ id: ids.toString(), page_size: ids.length }, requestInit),
                fetchInstagramUserImages(ids, requestInit),
            ]);
        },
        [
            fetchInstagramInfluencerUsersData,
            fetchInstagramUserImages,
            fetchInstagramUsers,
            instagramUsers.state.data?.users,
        ]
    );

    const fetchYoutubePosts = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                return [];
            }

            return fetchYoutubeInfluencerPosts({ id: ids.toString(), page_size: ids.length }, requestInit);
        },
        [fetchYoutubeInfluencerPosts]
    );

    const fetchPlans = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                return [];
            }

            return fetchInfluencerPlansData({ id: ids.toString(), page_size: ids.length }, requestInit).then(
                (response) => {
                    if (response.status !== 200) {
                        return [];
                    }

                    return response.data.results;
                }
            );
        },
        [fetchInfluencerPlansData]
    );

    useAbortableEffect(
        (signal) => {
            async function fetchData() {
                try {
                    const existingPlanIds = influencerPlans.data?.results.map((p) => p.id) ?? [];
                    const plansToFetchIds = uniq(
                        paymentRequests.data?.results
                            .map((req) => req.plan_id)
                            .filter(isNumber)
                            .filter((id) => !existingPlanIds.includes(id)) ?? []
                    );

                    const existingTiktokInfluencerPostsIds = tiktokInfluencerPosts.posts.map((p) => p.id);
                    const tiktokInfluencerPostsToFetchIds = uniq(
                        paymentRequests.data?.results
                            .map((req) => req.tiktok_influencer_post)
                            .filter(isNumber)
                            .filter((id) => !existingTiktokInfluencerPostsIds.includes(id)) ?? []
                    );

                    const existingInstagramInfluencerPostsIds =
                        instagramInfluencerPosts.data?.data.map((post) => post.id) ?? [];
                    const instagramInfluencerPostsToFetchIds = uniq(
                        paymentRequests.data?.results
                            .map((req) => req.instagram_influencer_post)
                            .filter(isNumber)
                            .filter((id) => !existingInstagramInfluencerPostsIds?.includes(id))
                    );

                    const existingYoutubeInfluencerPostsIds = youtubeInfluencerPosts.data?.map((post) => post.id) ?? [];
                    const youtubeInfluencerPostsToFetchIds = uniq(
                        paymentRequests.data?.results
                            .map((req) => req.youtube_influencer_post)
                            .filter(isNumber)
                            .filter((id) => !existingYoutubeInfluencerPostsIds.includes(id))
                    );

                    await Promise.allSettled([
                        fetchPosts(tiktokInfluencerPostsToFetchIds, { signal }),
                        fetchPlans(plansToFetchIds, { signal }),
                        fetchInstagramPosts(instagramInfluencerPostsToFetchIds, { signal }),
                        fetchYoutubePosts(youtubeInfluencerPostsToFetchIds, { signal }),
                    ]);
                } catch {
                    // no-op
                }
            }

            if (paymentRequests.status === 'initialized') {
                fetchData();
            }
        },
        [
            fetchInstagramPosts,
            fetchPlans,
            fetchPosts,
            influencerPlans.data?.results,
            instagramInfluencerPosts.data?.data,
            paymentRequests.data?.results,
            paymentRequests.status,
            tiktokInfluencerPosts.posts,
            youtubeInfluencerPosts.data,
            fetchYoutubePosts,
        ]
    );

    useAbortableEffect(
        (signal) => {
            async function fetchData() {
                const existingUserIds = tiktokUsers.users.map((u) => u.id);
                const usersToFetchIds = uniq(
                    tiktokInfluencerPosts.posts
                        .map((p) => p.tiktok_user)
                        .filter(isNumber)
                        .filter((id) => !existingUserIds.includes(id))
                );

                const instagramInfluencerUserIds =
                    instagramInfluencerPosts.data?.data.map((p) => p.influencer_id).filter(isNumber) ?? [];

                try {
                    await Promise.allSettled([
                        fetchTiktokUsers(usersToFetchIds, { signal }),
                        fetchInstagramUsersData(instagramInfluencerUserIds, { signal }),
                    ]);
                    setStatus('initialized');
                } catch {
                    // no-op
                }
            }

            if (
                paymentRequests.status === 'initialized' &&
                (tiktokInfluencerPosts.isInitialized ||
                    instagramInfluencerPosts.status === 'success' ||
                    youtubeInfluencerPosts.status === 'success')
            ) {
                fetchData();
            }
        },
        [
            fetchInstagramUsersData,
            fetchTiktokUsers,
            instagramInfluencerPosts.data?.data,
            instagramInfluencerPosts.status,
            paymentRequests.status,
            tiktokInfluencerPosts.isInitialized,
            tiktokInfluencerPosts.posts,
            tiktokUsers.users,
            youtubeInfluencerPosts.status,
        ]
    );

    const reset = useCallback(() => {
        setStatus('not-initialized');
        paymentRequests.reset();
    }, [paymentRequests]);

    const payPayPal = useCallback(
        async (data: MakePaymentData) => {
            const response = await paymentRequestsPayPayPal(data);
            if (response.status === 204) {
                removePaymentRequest(data.payment_request_id);
            }

            return response;
        },
        [paymentRequestsPayPayPal, removePaymentRequest]
    );

    const payPayPalManually = useCallback(
        async (data: MakePaymentData) => {
            const response = await paymentRequestsPayPayPalManually(data);
            if (response.status === 204) {
                removePaymentRequest(data.payment_request_id);
            }

            return response;
        },
        [paymentRequestsPayPayPalManually, removePaymentRequest]
    );

    const payPayPalBulk = useCallback(
        async (data: MakePaymentData[]) => {
            type Result = [number, InferPromiseValue<ReturnType<typeof paymentRequestsPayPayPal>> | Error];

            const results = await Promise.allSettled(
                data.map<Promise<Result>>(async (data) => {
                    try {
                        return [data.payment_request_id, await paymentRequestsPayPayPal(data)];
                    } catch (e) {
                        const error = e instanceof Error ? e : new Error('Could not make paypal payment');
                        return [data.payment_request_id, error];
                    }
                })
            );

            const hasSuccessfulPayments = results
                .filter((result): result is PromiseFulfilledResult<Result> => result.status === 'fulfilled')
                .some((result) => {
                    const [, response] = result.value;
                    return !(response instanceof Error) && response.status === 204;
                });

            if (hasSuccessfulPayments) {
                reset();
            }

            return results;
        },
        [paymentRequestsPayPayPal, reset]
    );

    const rows = useMemo<OutstandingPaymentsTableRow[]>(
        () =>
            paymentRequests.data?.results.map((req) => {
                const plan = influencerPlans.data?.results.find((p) => p.id === req.plan_id);
                const tiktok_influencer_post = tiktokInfluencerPosts.posts.find(
                    (p) => p.id === req.tiktok_influencer_post
                );

                const instagram_influencer_post = instagramInfluencerPosts.data?.data.find(
                    (p) => p.id === req.instagram_influencer_post
                );

                const youtubeInfluencerPost = youtubeInfluencerPosts.data?.find(
                    (p) => p.id === req.youtube_influencer_post
                );

                const tiktokUser = tiktokUsers.users.find((u) => u.id === tiktok_influencer_post?.tiktok_user);
                const instagramInfluencerUser = instagramInfluencerUsers.data?.influencerUsers.find(
                    (u) => u.id === instagram_influencer_post?.influencer_id
                );

                const instagramUser = instagramUsers.state.data?.users.find(
                    (u) => u.id === instagramInfluencerUser?.user
                );

                const instagramUserImage = instagramUserImages.data?.images.find(
                    (image) => image.user_id === instagramInfluencerUser?.user
                );

                return {
                    ...req,
                    plan,
                    tiktokUser,
                    instagramUser,
                    tiktok_influencer_post,
                    instagram_influencer_post,
                    youtubeInfluencerPost,
                    instagramUserImage,
                };
            }) ?? [],
        [
            influencerPlans.data?.results,
            instagramInfluencerPosts.data?.data,
            instagramInfluencerUsers.data?.influencerUsers,
            instagramUserImages.data?.images,
            instagramUsers.state.data?.users,
            paymentRequests.data?.results,
            tiktokInfluencerPosts.posts,
            tiktokUsers.users,
            youtubeInfluencerPosts.data,
        ]
    );

    const data = paymentRequests.data
        ? {
              rows,
              count: paymentRequests.data.count,
          }
        : null;

    return {
        status,
        data,
        error: paymentRequests.error,
        fetchData,
        reset,
        update: paymentRequests.update,
        payPayPal,
        payPayPalManually,
        payPayPalBulk,
    };
}
