import { microwave } from '@round/api';
import { showNotification } from 'helpers';
import { useState } from 'react';
import PopoutEditCell from 'ui-new/TableComponents/PopoutEditCell/PopoutEditCell';
import Spinner from 'ui-new/Loading/Spinner/Spinner';
import styles from './ReferenceCell.module.css';
import cn from 'classnames';

type Props =
    | {
          reference: string;
          updatePayment: (reference: string) => ReturnType<typeof microwave.patchPaymentRequest>;
          isReadonly?: false;
      }
    | {
          reference: string;
          updatePayment?: (reference: string) => ReturnType<typeof microwave.patchPaymentRequest>;
          isReadonly: true;
      };

const ReferenceCell = ({ reference: initialReference, isReadonly, updatePayment }: Props) => {
    const [reference, setReference] = useState(initialReference);
    const [isEditing, setIsEditing] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const handleUpdateReference = async (reference: string) => {
        if (!updatePayment) {
            return;
        }

        setIsUpdating(true);
        try {
            const response = await updatePayment(reference);

            if (response.status !== 200) {
                const errorMessage =
                    response.status === 404
                        ? response.data.detail
                        : Array.isArray(response.data.payment_reference)
                        ? response.data.payment_reference.join(', ')
                        : response.data.payment_reference;
                showNotification(errorMessage || 'Could not update reference', 'error');
                setReference(initialReference);
                return;
            }

            showNotification('Reference updated', 'info');
        } catch {
            showNotification('Could not update reference', 'error');
            setReference(initialReference);
        } finally {
            setIsUpdating(false);
        }
    };

    const onEditingChange = async (value: boolean) => {
        const didReferenceChange = reference !== initialReference;
        if (didReferenceChange) {
            await handleUpdateReference(reference);
        }
        setIsEditing(value);
    };

    return (
        <PopoutEditCell
            target={
                <div className={cn(styles.reference, { [styles.invisible]: isEditing })}>{initialReference || '-'}</div>
            }
            isEditing={isEditing}
            onEditingChange={onEditingChange}
            editClassName={styles.editContainer}
            isReadonly={isReadonly}
        >
            <div className={styles.container}>
                <textarea
                    autoFocus
                    className={styles.input}
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                    onBlur={() => onEditingChange(false)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && e.shiftKey) {
                            e.preventDefault();
                            return;
                        }

                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.currentTarget.blur();
                        }
                    }}
                    disabled={isUpdating}
                />
                {isUpdating && <Spinner />}
            </div>
        </PopoutEditCell>
    );
};

export default ReferenceCell;
