import {
    getAllYoutubeInfluencerPostGroups,
    getYoutubeInfluencerPostGroupLiveStats,
    patchYoutubeInfluencerPostGroup,
    PatchYoutubeInfluencerPostGroupBody,
    postYoutubeInfluencerPostGroup,
    PostYoutubeInfluencerPostGroupBody,
    deleteYoutubeInfluencerPostGroup as apiDeleteYoutubeInfluencerPostGroup,
    deleteYoutubeInfluencerPost,
} from '@round/api';
import useNonNullContext from 'Hooks/useNonNullContext';
import { useCallback, useContext } from 'react';
import { InfluencerPlanContext } from '../../InfluencerPlanContext';
import { YoutubeCreatorsDispatchContext, YoutubeCreatorsStateContext } from '../YoutubeCreatorsContext';

export function useYoutubePostGroups() {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const state = useNonNullContext(YoutubeCreatorsStateContext);
    const dispatch = useNonNullContext(YoutubeCreatorsDispatchContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (!influencerPlan?.id) {
                return;
            }

            try {
                dispatch({ type: 'loadYoutubeInfluencerPostGroups' });
                const postGroups = await getAllYoutubeInfluencerPostGroups(
                    { influencer_plan_id: influencerPlan.id },
                    requestInit
                );

                dispatch({ type: 'setIsYoutubeInfluencerPostGroupsInitialized', payload: postGroups });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'setYoutubeInfluencerPostGroupsLoadingError',
                    payload: "Sorry, we're experiencing technical issues",
                });
            }
        },
        [dispatch, influencerPlan?.id]
    );

    const initStats = useCallback(
        async (requestInit?: RequestInit) => {
            if (!state.isYoutubeInfluencerPostGroupsInitialized) {
                return;
            }

            const groupStatsToFetch = state.youtubeInfluencerPostGroups?.map((group) => group.id) || [];

            if (!groupStatsToFetch.length && !state.isYoutubeInfluencerPostGroupStatsInitialized) {
                dispatch({ type: 'setIsYoutubeInfluencerPostGroupStatsInitialized', payload: [] });
                return;
            }

            try {
                dispatch({ type: 'loadYoutubeInfluencerPostGroupStats' });
                const response = await getYoutubeInfluencerPostGroupLiveStats(
                    { id: groupStatsToFetch.join(',') },
                    requestInit
                );

                dispatch({ type: 'setIsYoutubeInfluencerPostGroupStatsInitialized', payload: response.data });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'setYoutubeInfluencerPostGroupStatsLoadingError',
                    payload: "Sorry, we're experiencing technical issues",
                });
            }
        },
        [
            dispatch,
            state.isYoutubeInfluencerPostGroupsInitialized,
            state.youtubeInfluencerPostGroups,
            state.isYoutubeInfluencerPostGroupStatsInitialized,
        ]
    );

    const createYoutubeInfluencerPostGroup = useCallback(
        async (data: PostYoutubeInfluencerPostGroupBody) => {
            try {
                const response = await postYoutubeInfluencerPostGroup(data);

                if (response.status === 201) {
                    dispatch({ type: 'createYoutubeInfluencerPostGroup', payload: response.data });
                    return response;
                }

                return response;
            } catch {
                throw new Error('Could not create youtube influencer post group');
            }
        },
        [dispatch]
    );

    const updateYoutubeInfluencerPostGroup = useCallback(
        async (id: number, data: PatchYoutubeInfluencerPostGroupBody) => {
            try {
                const response = await patchYoutubeInfluencerPostGroup(id, data);

                if (response.status === 200) {
                    dispatch({ type: 'updateYoutubeInfluencerPostGroup', payload: response.data });
                    return response;
                }

                return response;
            } catch {
                throw new Error('Could not update youtube influencer post group');
            }
        },
        [dispatch]
    );

    const updateYoutubeInfluencerPostGroupOrderingIndex = useCallback(
        async (groupId: number, currentIndex: number, targetIndex: number) => {
            if (targetIndex < 0 || !state.youtubeInfluencerPostGroups) {
                return;
            }

            const adjacentGroup = state.youtubeInfluencerPostGroups.find((g) => g.ordering_index === targetIndex);
            if (!adjacentGroup) {
                return;
            }

            try {
                const requests = [
                    updateYoutubeInfluencerPostGroup(groupId, { ordering_index: targetIndex }),
                    updateYoutubeInfluencerPostGroup(adjacentGroup.id, { ordering_index: currentIndex }),
                ];

                const responses = await Promise.all(requests);

                if (responses.some((response) => response.status !== 200)) {
                    return false;
                }

                return true;
            } catch {
                return false;
            }
        },
        [state.youtubeInfluencerPostGroups, updateYoutubeInfluencerPostGroup]
    );

    const deleteYoutubeInfluencerPostGroup = useCallback(
        async (groupId: number): Promise<{ success: true } | { success: false; reason: string }> => {
            const postsInGroupIds = state.youtubeInfluencerPosts
                ? state.youtubeInfluencerPosts.filter((post) => post.group_id === groupId).map((post) => post.id)
                : [];

            const deletePostRequests = postsInGroupIds.map(async (postId) => {
                deleteYoutubeInfluencerPost(postId).then((response) => {
                    if (response.status !== 204) {
                        throw new Error('Could not delete post');
                    }

                    dispatch({ type: 'youtubeInfluencerPostDeleted', payload: postId });
                });
            });

            const deletePostResponses = await Promise.allSettled(deletePostRequests);

            if (deletePostResponses.some((response) => response.status !== 'fulfilled')) {
                return { success: false, reason: 'Could not delete all posts in the group' };
            }

            const response = await apiDeleteYoutubeInfluencerPostGroup(groupId);

            if (response.status === 204) {
                dispatch({ type: 'deleteYoutubeInfluencerPostGroup', payload: groupId });
                return { success: true };
            }

            return { success: false, reason: 'Could not delete the group' };
        },
        [dispatch, state.youtubeInfluencerPosts]
    );

    return {
        init,
        postGroups: state.youtubeInfluencerPostGroups,
        isInitialized: state.isYoutubeInfluencerPostGroupsInitialized,
        isLoading: state.isYoutubeInfluencerPostGroupsLoading,
        loadingError: state.youtubeInfluencerPostGroupsLoadingError,

        createYoutubeInfluencerPostGroup,
        updateYoutubeInfluencerPostGroup,
        updateYoutubeInfluencerPostGroupOrderingIndex,
        deleteYoutubeInfluencerPostGroup,

        initStats,
        postGroupStats: state.youtubeInfluencerPostGroupStats,
        isStatsInitialized: state.isYoutubeInfluencerPostGroupStatsInitialized,
        isStatsLoading: state.isYoutubeInfluencerPostGroupStatsLoading,
        statsLoadingError: state.youtubeInfluencerPostGroupStatsLoadingError,
    };
}
