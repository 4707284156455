import React, { FunctionComponent } from 'react';

import styles from '../TikTokHashtagList.module.css';
import { MonitoredTiktokHashtagStats } from '../../../TikTok.types';
import { buildTiktokHashtagUrl, numberWithCommas, roundTo2Dp } from '../../../../../helpers';

const TikTokHashtagRow = ({
    data,
    onClick,
}: {
    data: MonitoredTiktokHashtagStats;
    onClick: (hashtagId: number) => void;
}) => {
    function handleChildClick(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation();
    }

    return (
        <>
            <tr onClick={() => onClick(data.hashtag_id)} className={styles.row}>
                <td className={styles.imageCell}>
                    <div className={styles.imageContainer} />
                </td>
                <td className={styles.nameCell}>
                    <a
                        onClick={handleChildClick}
                        href={buildTiktokHashtagUrl(data.title)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        #{data.title}
                    </a>
                </td>
                <td>{numberWithCommas(data.video_count)}</td>
                <StatsDeltaCell
                    absolute={data.daily_change_video_absolute}
                    relative={data.daily_change_video_relative}
                />
                <StatsDeltaCell
                    absolute={data.weekly_change_video_absolute}
                    relative={data.weekly_change_video_relative}
                />

                <td>{numberWithCommas(data.view_count)}</td>
                <StatsDeltaCell absolute={data.daily_change_view_absolute} relative={data.daily_change_view_relative} />
                <StatsDeltaCell
                    absolute={data.weekly_change_view_absolute}
                    relative={data.weekly_change_view_relative}
                />
            </tr>
        </>
    );
};

const StatsDeltaCell: FunctionComponent<{ absolute: number | null; relative: number | null }> = ({
    absolute,
    relative,
}) => {
    return absolute === null || relative === null ? (
        <td>-</td>
    ) : (
        <td>
            {numberWithCommas(absolute)}
            <span
                className={`${styles.relativeStatsChange} ${relative > 0 && styles.green} ${
                    relative < 0 && styles.red
                }`}
            >
                {roundTo2Dp(relative * 100)}%
            </span>
        </td>
    );
};

export default TikTokHashtagRow;
