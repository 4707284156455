import React, { SVGProps, useMemo, useState } from 'react';
import Button from '../../../../../ui/Buttons/Button/Button';
import styles from './BadgeDropdown.module.css';
import { numberWithCommas } from '../../../../../helpers';
import { Popover, PopoverProps } from '@round/ui-kit';
import Skeleton from '../../../../../ui/Skeleton/Skeleton';
import cn from 'classnames';

type BadgeDropdownProps = {
    loading?: boolean;
    value: number;
    label: string;
    Icon: React.FunctionComponent<SVGProps<SVGSVGElement>>;
    disabled?: boolean;
} & Pick<PopoverProps, 'children'>;

const BadgeDropdown = ({ label, Icon, value, children, loading, disabled }: BadgeDropdownProps) => {
    const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(null);
    const width = useMemo(() => buttonElement?.getBoundingClientRect().width, [buttonElement]);

    return (
        <>
            <Button
                ref={setButtonElement}
                type="bordered"
                className={cn(styles.button, { [styles.disabled]: disabled })}
            >
                <div className={styles.buttonContent}>
                    <div className={styles.iconContainer}>{loading ? <Skeleton circle /> : <Icon />}</div>
                    <div className={styles.content}>
                        <span className={styles.value}>
                            {loading ? <Skeleton width="1rem" /> : numberWithCommas(value)}
                        </span>
                        <span className={styles.label}>{loading ? <Skeleton width="4rem" /> : label}</span>
                    </div>
                </div>
            </Button>
            <Popover
                disabled={disabled}
                anchorElement={buttonElement}
                showOn="click"
                options={{ placement: 'bottom-end' }}
            >
                {(setShow) => (
                    <div className={styles.popoverContainer} style={{ minWidth: width }}>
                        {typeof children === 'function' ? children(setShow) : children}
                    </div>
                )}
            </Popover>
        </>
    );
};

export default BadgeDropdown;
