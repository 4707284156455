import Filters from 'ui-new/Filters/Filters';
import { DomainFiltersProps, getInitialFilters } from 'ui-new/Filters/helpers';
import { Filter } from 'ui-new/Filters/useFilterManager';
import { formatToIsoDateString, getNonEmptyKeys } from 'utility/utility';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import MicrowaveInfluencerFilter from 'Modules/Advertising/Microwave/components/Filters/MicrowaveInfluencerFilter/MicrowaveInfluencerFilter';
import { ReactComponent as CalendarIcon } from 'assets/icons/Calendar.svg';
import { DateRangeFilter } from 'ui-new/Filters/DateRangeFilter/DateRangeFilter';
import { ReactComponent as HashIcon } from 'assets/icons/Hash.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/Share.svg';
import TextFilter from 'ui-new/Filters/TextFilter/TextFilter';
import styles from './OutstandingPaymentsFilters.module.css';
import { microwave } from '@round/api';
import PlatformFilter from 'Modules/Advertising/Microwave/components/Filters/PlatformFilter/PlatformFilter';

export type OutstandingPaymentsFiltersParams = Pick<
    microwave.GetPaymentRequestsParams,
    'influencer_id' | 'payment_deadline_start' | 'payment_deadline_end' | 'invite_id' | 'platform'
>;

export const getFilterValues = (
    urlState: Partial<Record<keyof OutstandingPaymentsFiltersParams, string>>
): OutstandingPaymentsFiltersParams => {
    return {
        influencer_id: urlState.influencer_id ? Number(urlState.influencer_id) : undefined,
        invite_id: urlState.invite_id,
        payment_deadline_start: urlState.payment_deadline_start,
        payment_deadline_end: urlState.payment_deadline_end,
        platform: urlState.platform,
    };
};

type Props = DomainFiltersProps<OutstandingPaymentsFiltersParams>;

const OutstandingPaymentsFilters = ({ value, onChange }: Props) => {
    const filters: Filter<keyof OutstandingPaymentsFiltersParams>[] = [
        {
            name: 'influencer_id',
            label: 'Account',
            icon: UserIcon,
            render(this, helpers) {
                const Icon = this.icon;
                return (
                    <MicrowaveInfluencerFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        name={this.label}
                        icon={<Icon className={styles.icon} />}
                        initialInfluencerId={value.influencer_id}
                        onChange={(val) => onChange({ influencer_id: val?.value })}
                        onClear={() => onChange({ influencer_id: undefined })}
                        onRemove={() => {
                            onChange({ influencer_id: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'payment_deadline_start',
            label: 'Due Date',
            icon: CalendarIcon,
            render(this, helpers) {
                const selectedDueDateRange: [Date] | [Date, Date] | null =
                    value.payment_deadline_start && value.payment_deadline_end
                        ? [new Date(value.payment_deadline_start), new Date(value.payment_deadline_end)]
                        : null;

                return (
                    <DateRangeFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        name={this.label}
                        value={selectedDueDateRange}
                        onChange={(dates) =>
                            onChange({
                                payment_deadline_start: dates?.[0] ? formatToIsoDateString(dates[0]) : undefined,
                                payment_deadline_end: dates?.[1] ? formatToIsoDateString(dates[1]) : undefined,
                            })
                        }
                        onClear={() => {
                            onChange({ payment_deadline_end: undefined, payment_deadline_start: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'invite_id',
            label: 'Invite ID',
            icon: HashIcon,
            render(this, helpers) {
                const Icon = this.icon;

                return (
                    <TextFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        icon={<Icon className={styles.icon} />}
                        name={this.label}
                        value={value.invite_id ?? ''}
                        onChange={(val) => onChange({ invite_id: val ?? undefined })}
                        onClear={() => {
                            onChange({ invite_id: undefined });
                            helpers.remove();
                        }}
                        placeholder="Enter invite ID"
                    />
                );
            },
        },
        {
            name: 'platform',
            label: 'Platform',
            icon: ShareIcon,
            render(this, helpers) {
                const Icon = this.icon;
                return (
                    <PlatformFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        icon={<Icon className={styles.icon} />}
                        name={this.label}
                        value={value.platform as microwave.Platform}
                        onChange={(val) => onChange({ platform: val })}
                        onClear={() => onChange({ platform: undefined })}
                        onRemove={() => {
                            onChange({ platform: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
    ];

    const defaultFilters: (keyof OutstandingPaymentsFiltersParams)[] = ['payment_deadline_start'];

    return (
        <Filters
            sessionKey="microwave-outstanding-payments-filters-v2"
            filters={filters}
            options={{
                initialFilters: (state) => getInitialFilters(state, getNonEmptyKeys(value), defaultFilters),
            }}
        />
    );
};

export default OutstandingPaymentsFilters;
