import { buildApiUrl } from '../../utils';
import { ApiResponse, ApiResponseError } from '../../types';
import { PaymentMethod } from '../microwave.types';
import { TiktokPostInviteForm } from './tiktokPostInviteForm.types';

export async function getTiktokPostInviteForm(
    uuid: string,
    requestInit?: RequestInit
): Promise<ApiResponse<TiktokPostInviteForm, 200> | ApiResponse<{ detail: string }, 404>> {
    const url = buildApiUrl(`/api/microwave/tiktok-post-invite-forms/${uuid}/`);
    const response = await fetch(url, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to fetch TiktokPostInviteForm');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function validateTiktokPostUrl(
    id: string,
    post_url: string,
    requestInit?: RequestInit
): Promise<ApiResponse<null, 204> | ApiResponse<ApiResponseError<{ post_url: string }>, 400>> {
    const url = buildApiUrl(`/api/microwave/tiktok-post-invite-forms/${id}/validate-post-url/`);
    const response = await fetch(url, {
        ...requestInit,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post_url }),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to validate TiktokPostUrl');
    }

    return {
        status: 204,
        data: null,
    };
}

export type PostTiktokPostInviteFormApiBody = {
    post_url: string;
    preferred_payment_method: PaymentMethod;
    paypal_email_address: string;
    venmo_email_address: string;
    payment_details: string;
} & Partial<{
    //country code
    location_id: number;
}>;

type PostTiktokPostInviteFormResponse =
    | ApiResponse<TiktokPostInviteForm, 201>
    | ApiResponse<string[] | ApiResponseError<PostTiktokPostInviteFormApiBody>, 400>;

export async function postTiktokPostInviteFormSubmission(
    uuid: string,
    data: PostTiktokPostInviteFormApiBody,
    requestInit?: RequestInit
): Promise<PostTiktokPostInviteFormResponse> {
    const url = buildApiUrl(`/api/microwave/tiktok-post-invite-forms/${uuid}/submit/`);
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        ...requestInit,
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not submit post invite form');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}
