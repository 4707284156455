import React, { useState } from 'react';
import { metricsOptions, PresetOption, presetOptions } from './presets';
import { Popover, Checkbox } from '@round/ui-kit';
import styles from './PresetsSelect.module.css';
import Card from '../../../../../../ui/Card/Card';
import cn from 'classnames';

type PresetsSelectProps = {
    value: PresetOption | undefined;
    onChange: (preset: PresetOption) => void;
    placeholder?: string;
};

const PresetsSelect = ({ onChange, value, placeholder = '' }: PresetsSelectProps) => {
    const [valueRef, setValueRef] = useState<HTMLElement | null>(null);
    return (
        <>
            <div className={styles.valueContainer} ref={setValueRef}>
                {value?.label ?? placeholder}
            </div>
            <Popover anchorElement={valueRef} showOn="click" options={{ placement: 'bottom-end', strategy: 'fixed' }}>
                {() => (
                    <Card className={styles.dropdown}>
                        {presetOptions.map((option) => {
                            if (option.value === 'custom') {
                                return (
                                    <>
                                        <div
                                            key={option.value}
                                            className={cn(styles.presetOption, {
                                                [styles.selected]: value?.value === option.value,
                                            })}
                                            onClick={() => onChange({ ...option, metrics: value?.metrics ?? [] })}
                                        >
                                            {option.label}
                                        </div>

                                        <div
                                            className={cn(styles.customMetricsList, {
                                                [styles.visible]: value?.value === option.value,
                                            })}
                                        >
                                            {metricsOptions.map((metric) => {
                                                const metricSelected = !!value?.metrics.includes(metric.value);

                                                return (
                                                    <div
                                                        key={metric.value}
                                                        className={styles.customMetricOption}
                                                        onClick={(e) => {
                                                            e.stopPropagation();

                                                            if (!value) {
                                                                onChange(option);
                                                                return;
                                                            }

                                                            const metrics = metricSelected
                                                                ? value.metrics.filter((m) => m !== metric.value)
                                                                : value.metrics.concat(metric.value);

                                                            onChange({ ...option, metrics });
                                                        }}
                                                    >
                                                        <Checkbox value={metricSelected} />
                                                        <span>{metric.label}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                );
                            }

                            return (
                                <div
                                    key={option.value}
                                    className={cn(styles.presetOption, {
                                        [styles.selected]: value?.value === option.value,
                                    })}
                                    onClick={() => onChange(option)}
                                >
                                    {option.label}
                                </div>
                            );
                        })}
                    </Card>
                )}
            </Popover>
        </>
    );
};

export default PresetsSelect;
