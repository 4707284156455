import {
    TiktokAudio,
    TiktokAudioAsset,
    TiktokAudioPostStats,
    TrendingTiktokAudioStats,
    TrendingTiktokAudioSuggestion,
} from './audio.types';
import { call, encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    OrderingParams,
    OrderingValues,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { isPublicReportRequestInit } from '../../influencer';

type GetTiktokAudioResponse = ApiResponse<TiktokAudio, 200> | ApiResponse<{ detail: string }, 404>;

export async function getTiktokAudio(id: number, requestInit?: RequestInit): Promise<GetTiktokAudioResponse> {
    const response = await fetchWithToken(`/api/tiktok/viewsets/audio/${id}/`, requestInit);

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get tiktok audio ${id}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetTiktokAudiosResponse = ApiResponse<PaginatedApiResponseData<TiktokAudio>, 200>;
type GetTiktokAudiosParams = Partial<
    PaginatedRequest & {
        id: string;
        search: string;
        tiktok_id: string;
        identifier_search: string;
    }
>;

export async function getTiktokAudios(
    params: GetTiktokAudiosParams,
    requestInit?: RequestInit
): Promise<GetTiktokAudiosResponse> {
    const response = await call(`/api/tiktok/viewsets/audio/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens: isPublicReportRequestInit(requestInit),
    });

    if (!response.ok) {
        throw new Error('Could not get tiktok audios');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PostTikTokAudioPayload = {
    identifier: string; // url or id
};

type PostTikTokAudioResponse =
    | ApiResponse<TiktokAudio, 201>
    | ApiResponse<ApiResponseError<PostTikTokAudioPayload>, 400>;

export async function postTikTokAudio(data: PostTikTokAudioPayload): Promise<PostTikTokAudioResponse> {
    const response = await fetchWithToken('/api/tiktok/viewsets/audio/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create tiktok audio');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export async function getTiktokAudioAssets(audioIds: number[], requestInit?: RequestInit): Promise<TiktokAudioAsset[]> {
    const response = await fetchWithToken(`/api/tiktok/audio-asset/?audio_id=${audioIds.join(',')}`, requestInit);

    if (!response.ok) {
        throw new Error(`Could not fetch tiktok audio assets for ids ${audioIds.join(',')}`);
    }

    return response.json();
}

export async function linkTiktokAudioToSongs(audioId: number, songIds: number[]): Promise<void> {
    const response = await fetchWithToken(`/api/tiktok/viewsets/audio/${audioId}/link-to-song/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ song_ids: songIds }),
    });

    if (!response.ok) {
        throw new Error('Could not link audio to songs');
    }
}

export type GetTrendingTiktokAudioSuggestionsParams = Partial<
    PaginatedRequest & {
        search: string;
    }
>;

type GetTrendingTiktokAudioSuggestionsResponse = ApiResponse<
    PaginatedApiResponseData<TrendingTiktokAudioSuggestion>,
    200
>;

export async function getTrendingTiktokAudioSuggestions(
    params: GetTrendingTiktokAudioSuggestionsParams,
    requestInit?: RequestInit
): Promise<GetTrendingTiktokAudioSuggestionsResponse> {
    const response = await fetchWithToken(
        `/api/tiktok/viewsets/trending-audio-suggestion/${encodeUrlSearchParams(params)}`,
        requestInit
    );
    if (!response.ok) {
        throw new Error('Could not fetch trending tiktok audio suggestions');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function acceptTrendingTiktokAudioSuggestion(suggestionId: number): Promise<void> {
    const response = await fetchWithToken(`/api/tiktok/viewsets/trending-audio-suggestion/${suggestionId}/accept/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Could not accept trending audio suggestion ${suggestionId}`);
    }
}

export async function rejectTrendingTiktokAudioSuggestion(suggestionId: number): Promise<void> {
    const response = await fetchWithToken(`/api/tiktok/viewsets/trending-audio-suggestion/${suggestionId}/reject/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Could not reject trending audio suggestion ${suggestionId}`);
    }
}

export type GetTrendingTiktokAudioStatsSortableKeys = keyof Pick<
    TrendingTiktokAudioStats,
    | 'first_seen_at'
    | 'video_count'
    | 'video_count_daily_change'
    | 'video_count_daily_change_relative'
    | 'video_count_weekly_change'
    | 'video_count_weekly_change_relative'
>;

export type GetTrendingTiktokAudioStatsParams = Partial<
    PaginatedRequest &
        OrderingParams<GetTrendingTiktokAudioStatsSortableKeys> & {
            search: string;
            genre: string;
            location: string;
        }
>;

type GetTrendingTiktokAudioStatsResponse = ApiResponse<PaginatedApiResponseData<TrendingTiktokAudioStats>, 200>;

export async function getTrendingTiktokAudioStats(
    params: GetTrendingTiktokAudioStatsParams,
    requestInit?: RequestInit
): Promise<GetTrendingTiktokAudioStatsResponse> {
    const response = await fetchWithToken(
        `/api/tiktok/viewsets/trending-audio/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not get trending tiktok audio stats');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type GetTiktokAudioPostStatsSortableKeys = OrderingValues<
    keyof Pick<
        TiktokAudioPostStats,
        'play_count' | 'like_count' | 'comment_count' | 'share_count' | 'author_follower_count' | 'save_count'
    >
>;

type GetTiktokAudioPostStatsParams = { audio_id: number } & Partial<
    PaginatedRequest & {
        ordering: GetTiktokAudioPostStatsSortableKeys;
    }
>;

type GetTiktokAudioPostStatsResponse = ApiResponse<PaginatedApiResponseData<TiktokAudioPostStats>, 200>;

export async function getTiktokAudioPostStats(
    { audio_id, ...params }: GetTiktokAudioPostStatsParams,
    requestInit?: RequestInit
): Promise<GetTiktokAudioPostStatsResponse> {
    const response = await fetchWithToken(
        `/api/tiktok/viewsets/audio/${audio_id}/post-stats/${encodeUrlSearchParams({ ...params })}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not get tiktok audio post stats for audio ${audio_id}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
