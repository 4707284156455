import { ValueContainerProps, components } from 'react-select';
import { GenericDropdownOption } from '../../../../utility/utility.types';

export type SelectedCountValueContainerProps<T extends GenericDropdownOption<number>> = ValueContainerProps<T, true> & {
    label?: string;
    numberOnly?: boolean;
};

const SelectedCountValueContainer = <T extends GenericDropdownOption<number>>(
    props: SelectedCountValueContainerProps<T>
) => {
    const count = props.getValue()?.length;
    const countText = props.numberOnly ? count : `${count} selected`;

    return (
        <components.ValueContainer {...props}>
            {props.label} {count === props.options.length && props.options.length > 0 ? 'All' : countText}
            <div style={{ position: 'relative', overflow: 'hidden', height: 0, width: 0 }}>{props.children}</div>
        </components.ValueContainer>
    );
};

export default SelectedCountValueContainer;
