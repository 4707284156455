import React from 'react';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import styles from './CampaignDetailsPage.module.css';
import CampaignDetails from '../../../Modules/Advertising/Projects/containers/CampaignDetails/CampaignDetails';
import MobileCampaignDetails from '../../../Modules/Advertising/Projects/containers/MobileCampaignDetails/MobileCampaignDetails';
import { InfluencerPlanProvider } from '../../../Modules/Advertising/InfluencerPlan/contexts/InfluencerPlanContext';
import { useParams } from 'react-router-dom';
import { TiktokCreatorsContextProvider } from '../../../Modules/Advertising/InfluencerPlan/contexts/TiktokCreatorsContext/TiktokCreatorsContext';
import { InstagramCreatorsContextProvider } from '../../../Modules/Advertising/InfluencerPlan/contexts/InstagramCreatorsContext/InstagramCreatorsContext';
import { useIsViewportMobileDimensions } from '../../../Hooks/useIsViewportMobileDimensions';
import { AdvertisingContextProvider } from '../../../Modules/Advertising/MediaPlan/contexts/AdvertisingContext/AdvertisingContext';
import { YoutubeCreatorsContextProvider } from 'Modules/Advertising/InfluencerPlan/contexts/YoutubeCreatorsContext/YoutubeCreatorsContext';

const CampaignDetailsPage = () => {
    const showMobilePage = useIsViewportMobileDimensions();
    const { releaseId } = useParams<{ releaseId: string }>();

    return (
        <div>
            <NavBar />
            <InfluencerPlanProvider releaseId={Number(releaseId)}>
                <TiktokCreatorsContextProvider key={releaseId}>
                    <InstagramCreatorsContextProvider key={releaseId}>
                        <YoutubeCreatorsContextProvider key={releaseId}>
                            <AdvertisingContextProvider releaseId={Number(releaseId)} key={releaseId}>
                                {showMobilePage ? (
                                    <div
                                        data-test-id="mobile-campaign-details-container"
                                        className={styles.mobileContent}
                                    >
                                        <MobileCampaignDetails releaseId={Number(releaseId)} />
                                    </div>
                                ) : (
                                    <div data-test-id="campaign-details-container" className={styles.content}>
                                        <CampaignDetails releaseId={Number(releaseId)} />
                                    </div>
                                )}
                            </AdvertisingContextProvider>
                        </YoutubeCreatorsContextProvider>
                    </InstagramCreatorsContextProvider>
                </TiktokCreatorsContextProvider>
            </InfluencerPlanProvider>
        </div>
    );
};

export default CampaignDetailsPage;
