import { AppServer, RouteHandlerContext } from '../../mirage';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TrndsClientTiktokAudioStats } from './tiktokAudioStats.types';

export const models = {
    trndsClientTiktokAudioStat: Model as ModelDefinition<TrndsClientTiktokAudioStats>,
};

export const serializers = {
    trndsClientTiktokAudioStat: makeSerializer<TrndsClientTiktokAudioStats>([]),
};

export const factories = {
    trndsClientTiktokAudioStat: createFactory<TrndsClientTiktokAudioStats>({
        audio_id(index: number) {
            return index;
        },
        tiktok_id(index: number) {
            return `tiktok id ${index}`;
        },
        author_name(index: number) {
            return `author name ${index}`;
        },
        title(index: number) {
            return `title ${index}`;
        },
        video_count: 0,
        is_original: false,
        video_count_daily_change: 0,
        video_count_daily_change_relative: '0.00',
        video_count_weekly_change: 0,
        video_count_weekly_change_relative: '0.00',
        standardised_data: {
            daily_video_counts: [],
        },
    }),
};

export function handleRequests(server: AppServer) {
    const getClientTiktokAudioStatsUrl = '/api/trnds/client/:clientId/tiktok-audio-stats/';
    server.get(getClientTiktokAudioStatsUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('trndsClientTiktokAudioStat'), {
            url: getClientTiktokAudioStatsUrl,
            serialize: (resource) => this.serialize(resource, 'trndsClientTiktokAudioStat'),
            queryParams: request.queryParams,
        });
    });

    server.get(`${getClientTiktokAudioStatsUrl}:audioId/`, (schema, request) => {
        const stats = schema
            .all('trndsClientTiktokAudioStat')
            .filter((stats) => Number(stats.audio_id) === Number(request.params.audioId)).models[0];

        if (!stats) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return stats;
    });
}
