import { ApiResponse, NotFoundResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { FeedPost } from './feedPost.types';
import { call, encodeUrlSearchParams } from '../../helpers';

export type GetFeedPostsParams = Partial<PaginatedRequest> & {
    ordering: 'random';
    // hexadecimal 0-f
    seed: string;
};

export type GetFeedPostsResponse = ApiResponse<PaginatedApiResponseData<FeedPost>, 200> | NotFoundResponse;

export async function getFeedPosts(
    params: GetFeedPostsParams,
    requestInit?: RequestInit
): Promise<GetFeedPostsResponse> {
    const response = await call(`/api/fandom/feed-posts/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens: true,
    });

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get posts');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
