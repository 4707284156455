import { SelectProps } from '@round/ui-kit';
import { FieldHookConfig, useField } from 'formik';
import { OptionTypeBase, ValueType } from 'react-select';
import Select from '../Select/Select';
import cn from 'classnames';
import styles from './FormField.module.css';
import { Label } from './Label/Label';
import ErrorMessage from './ErrorMessage/ErrorMessage';

type Props<T extends OptionTypeBase, TIsMulti extends boolean = false> = {
    label: string;
    className?: string;
} & Omit<SelectProps<T, TIsMulti>, 'value'> &
    FieldHookConfig<ValueType<T, TIsMulti>>;

const SelectField = <T extends OptionTypeBase, TIsMulti extends boolean = false>({
    label,
    disabled,
    className,
    isMulti,
    options,
    onMenuScrollToBottom,
    onMenuOpen,
    onMenuClose,
    inputValue,
    onInputChange,
    filterOption,
    isLoading,
    placeholder,
    components,
    formatOptionLabel,
    styles: stylesConfig,
    isClearable,
    onChange,
    ...props
}: Props<T, TIsMulti>) => {
    const [field, meta, helpers] = useField(props);

    const hasError = !!meta.error && !!meta.touched;

    return (
        <div className={cn(styles.field, className)}>
            <Label htmlFor={props.name}>{label}</Label>
            <Select
                inputId={field.name}
                name={field.name}
                isMulti={isMulti}
                value={field.value}
                styles={{
                    control: (base) => ({
                        ...base,
                        border: hasError ? '1px solid #FF5050' : base.border,
                    }),
                    ...stylesConfig,
                }}
                onChange={(value: ValueType<T, TIsMulti>) => {
                    helpers.setTouched(true);
                    helpers.setValue(value);
                    // @ts-ignore
                    onChange?.(value);
                }}
                options={options}
                onBlur={field.onBlur}
                onMenuScrollToBottom={onMenuScrollToBottom}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                inputValue={inputValue}
                onInputChange={onInputChange}
                filterOption={filterOption}
                isLoading={isLoading}
                isClearable={isClearable}
                placeholder={placeholder}
                components={components}
                isDisabled={disabled}
                formatOptionLabel={formatOptionLabel}
            />

            <ErrorMessage name={props.name} />
        </div>
    );
};

export default SelectField;
