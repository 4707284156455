import { HtmlHTMLAttributes } from 'react';
import styles from './CrossButton.module.css';
import { ReactComponent as ClearIcon } from 'assets/Close.svg';
import cn from 'classnames';

type Props = HtmlHTMLAttributes<HTMLButtonElement> & {
    className?: string;
};

const CrossButton = ({ className, ...props }: Props) => {
    return (
        <button className={cn(styles.button, className)} {...props}>
            <ClearIcon />
        </button>
    );
};

export default CrossButton;
