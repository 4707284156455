import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokAudioCollectionTiktokAudioRelationship } from './collectionAudioRelationship.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokAudioCollectionTiktokAudioRelationship: Model as ModelDefinition<TiktokAudioCollectionTiktokAudioRelationship>,
};

export const serializers = {
    tiktokAudioCollectionTiktokAudioRelationship: makeSerializer<TiktokAudioCollectionTiktokAudioRelationship>([]),
};

export const factories = {
    tiktokAudioCollectionTiktokAudioRelationship: createFactory<TiktokAudioCollectionTiktokAudioRelationship>({
        catalogue(index: number) {
            return index;
        },
        music(index: number) {
            return index;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/catalogue/viewsets/catalogue-tiktok-audio-relationship/';
    server.get(rootUrl, function (this: RouteHandlerContext, schema, request) {
        const relationships = schema.all('tiktokAudioCollectionTiktokAudioRelationship');
        return buildPaginatedResponse(relationships, {
            url: rootUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'tiktokAudioCollectionTiktokAudioRelationship'),
        });
    });

    server.post(rootUrl, function (this: RouteHandlerContext, schema, request) {
        return schema.create('tiktokAudioCollectionTiktokAudioRelationship', JSON.parse(request.requestBody));
    });

    server.delete(`${rootUrl}/:id/`, function (this: RouteHandlerContext, schema, request) {
        const relationship = schema.find('tiktokAudioCollectionTiktokAudioRelationship', request.params.id);
        if (!relationship) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        relationship.destroy();
        return new Response(204);
    });
}
