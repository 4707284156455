import React, { useEffect, useState } from 'react';
import { microwave } from '@round/api';
import useAbortableEffect from '../../../../../../../Hooks/useAbortableEffect';
import { Image, Skeleton, TabNavItem, TabPanel, TabPanels, Tabs, ScrollView } from '@round/ui-kit';
import styles from './EmailHistory.module.css';
import moment from 'moment';
import * as jsx from '../../../../../../../utility/jsx';
import { emailTypeLabels, getEmailEvents } from './helpers';
import { TiktokPostInviteData } from '../useTiktokPostInvites';
import { getCountry } from 'utility/utility';
import cn from 'classnames';

type Props = {
    contactedCreator: TiktokPostInviteData;
};

const getEmailKey = (email: microwave.TiktokPostInviteEmail) => `${email.notification_type}-${email.requested_at}`;
const getEmailStatusText = (email: microwave.TiktokPostInviteEmail) => {
    if (email.status === 'PENDING') {
        return `Email requested on ${moment(email.requested_at).format('MMM Do')}`;
    }

    if (email.status === 'SUCCESS' && email.sent_at) {
        return `Email sent on ${moment(email.sent_at).format('MMM Do')}`;
    }

    return email.status === 'CANCELLED' ? 'Email was cancelled' : "Email wasn't sent";
};

const EmailHistory = ({ contactedCreator }: Props) => {
    const [emails, setEmails] = useState<microwave.TiktokPostInviteEmail[]>([]);
    const [isInitialized, setIsInitialized] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useAbortableEffect(
        (signal) => {
            async function fetchEmails() {
                try {
                    const response = await microwave.getTiktokPostInviteEmails(
                        { tiktok_post_invite: contactedCreator.id },
                        { signal }
                    );

                    if (response.status === 404) {
                        setError(response.data.detail);
                        setIsInitialized(true);
                        return;
                    }

                    setEmails(response.data.results);
                    setIsInitialized(true);
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    setError('Could not fetch emails');
                    setIsInitialized(true);
                }
            }

            fetchEmails();
        },
        [contactedCreator]
    );

    const [selectedEmailKey, setSelectedEmailKey] = useState<string>();

    useEffect(() => {
        if (isInitialized && emails.length) {
            setSelectedEmailKey(getEmailKey(emails[0]));
        }
    }, [emails, isInitialized]);

    return (
        <Tabs activeTab={selectedEmailKey} onChange={setSelectedEmailKey}>
            <div className={styles.container}>
                <div className={styles.sideBar}>
                    <p className={styles.title}>Email History</p>

                    <div className={styles.creator}>
                        <Image
                            className={styles.creatorImage}
                            src={contactedCreator.userImage?.avatar_thumb.cached_url}
                        />
                        <div className={styles.creatorDetails}>
                            <p className={styles.creatorName}>{contactedCreator.user?.nickname ?? '-'}</p>
                            <p className={styles.creatorUsername}>@{contactedCreator.user?.unique_id ?? '-'}</p>
                            <p className={styles.creatorEmailAddress}>{contactedCreator.influencer_email_address}</p>
                        </div>
                    </div>

                    <ScrollView className={styles.navigation}>
                        <div className={styles.navigationList}>
                            {!isInitialized &&
                                jsx.repeat(3, (i) => (
                                    <div key={i} className={styles.navItem}>
                                        <span className={styles.navItemTitle}>
                                            <Skeleton width="10rem" />
                                        </span>
                                        <span className={styles.navItemSubtitle}>
                                            <Skeleton width="7rem" />
                                        </span>
                                    </div>
                                ))}

                            {isInitialized &&
                                emails.map((email) => (
                                    <TabNavItem
                                        key={getEmailKey(email)}
                                        className={styles.navItem}
                                        activeClassName={styles.activeNavItem}
                                        name={getEmailKey(email)}
                                    >
                                        <div className={styles.navItemTitleRow}>
                                            <span className={styles.navItemTitle}>
                                                {emailTypeLabels[email.notification_type]}
                                            </span>
                                            <span className={styles.navItemTimestamp}>
                                                {moment(email.requested_at).format('MMM Do HH:mm')}
                                            </span>
                                        </div>
                                        <span className={styles.navItemSubtitle}>{getEmailStatusText(email)}</span>
                                    </TabNavItem>
                                ))}
                        </div>
                    </ScrollView>
                </div>
                <ScrollView className={styles.content}>
                    {error && <div className={styles.errorMessage}>{error}</div>}
                    {isInitialized && !error && (
                        <TabPanels>
                            {emails.map((email) => {
                                const events = getEmailEvents(email);
                                return (
                                    <TabPanel name={getEmailKey(email)}>
                                        <div className={styles.stages}>
                                            {events.map((event, index) => {
                                                const { status, timestamp, city, countryCode } = event;

                                                const showLocation = !!event.countryCode || !!event.city;
                                                const country = getCountry(countryCode);

                                                const locationText = ` in ${city ? city + ', ' : ''}${
                                                    country ? country?.label : ''
                                                }`;

                                                return (
                                                    <div className={styles.stage}>
                                                        <div className={styles.stageIndex}>{index + 1}</div>
                                                        <div>
                                                            <div className={styles.stageTitle}>{status}</div>
                                                            <div className={styles.stageTimestamp}>
                                                                {timestamp
                                                                    ? moment(timestamp).format(
                                                                          'MMMM DD, YYYY [at] hh:mma'
                                                                      )
                                                                    : '-'}
                                                                {showLocation && locationText}
                                                                {country && (
                                                                    <span
                                                                        className={cn(
                                                                            `fi fi-${countryCode?.toLowerCase()}`,
                                                                            styles.flagIcon
                                                                        )}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div>
                                                                {event.link && (
                                                                    <a
                                                                        href={event.link}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        {event.link}
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </TabPanel>
                                );
                            })}
                        </TabPanels>
                    )}
                </ScrollView>
            </div>
        </Tabs>
    );
};

export default EmailHistory;
