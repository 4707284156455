import styles from './ClickToCopyButton.module.css';
import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg';
import copyToClipboard from 'copy-to-clipboard';
import cn from 'classnames';
import { showNotification } from 'helpers';

type Props = {
    copyValue: string;
    className?: string;
};

const ClickToCopyButton = ({ copyValue, className }: Props) => {
    return (
        <button
            className={styles.button}
            onClick={() => {
                copyToClipboard(copyValue);
                showNotification('Copied to clipboard', 'info');
            }}
        >
            <CopyIcon className={cn(className, styles.icon)} />
        </button>
    );
};

export default ClickToCopyButton;
