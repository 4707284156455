import { InstagramUser } from './Instagram.types';
import { InstagramUserImage, PaginatedApiResponseData } from '../../App.types';
import { ApiResponse } from '../../utility/utility.types';
import { encodeUrlSearchParams, InstagramInfluencerUser, fetchWithToken } from '@round/api';

export async function triggerInstagramUserScraping(monitoredUserId: number) {
    const response = await fetchWithToken(`/api/instagram/trigger-user-scraping/${monitoredUserId}/`, {
        method: 'POST',
    });

    if (!response.ok) {
        throw new Error(`Could not trigger scraping for instagram monitored user with id ${monitoredUserId}`);
    }
}

export async function getInstagramInfluencerUsers(
    params: Partial<{ page: number; page_size: number; id: string; search: string }>,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<InstagramInfluencerUser>, 200>> {
    const response = await fetchWithToken(
        `/api/instagram/viewsets/influencer-user/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not get instagram influencer users`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function fetchInstagramUserImages(userIds: number[]): Promise<InstagramUserImage[]> {
    if (!userIds.length) {
        return [];
    }

    const response = await fetchWithToken(`/api/instagram/user-image/?user_id=${userIds.join(',')}`);
    if (!response.ok) {
        return [];
    }

    return response.json();
}

export async function getInstagramUser(
    userId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<InstagramUser, 200> | ApiResponse<{ detail: string }, 404>> {
    const response = await fetchWithToken(`/api/instagram/viewsets/user/${userId}/`, requestInit);
    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not fetch instagram user ${userId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
