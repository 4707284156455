import Modal from 'ui-new/Modals/Modal/Modal';
import Button from 'ui/Button/Button';
import styles from './CreateMicrowavePostModal.module.css';
import { Form, Formik, FormikHelpers } from 'formik';
import FormField from 'ui-new/formik/FormField';
import useMicrowaveInfluencersSelect, {
    MicrowaveInfluencerOption,
} from 'Modules/Advertising/Microwave/useMicrowaveInfluencersSelect';
import { ValueType } from 'react-select';
import SelectField from 'ui-new/formik/SelectField';
import { omit } from 'lodash';
import React from 'react';
import * as yup from 'yup';
import { microwave } from '@round/api';
import { showNotification } from 'helpers';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import MicrowaveInfluencerOptionLabel from '../components/Filters/MicrowaveInfluencerFilter/components/OptionLabel/MicrowaveInfluencerOptionLabel';
import cn from 'classnames';
import { preferredPaymentMethodOptions } from '../constants';

type Props = {
    campaignId: number | undefined;
    isOpen: boolean;
    closeModal: () => void;
    onCreated: () => void;
};

type FormValues = Omit<microwave.PostMicroPostData, 'influencer_id' | 'campaign_id' | 'preferred_payment_method'> & {
    influencer: ValueType<MicrowaveInfluencerOption, false>;
    preferred_payment_method: microwave.PaymentMethod | null;
};

const initialValues: FormValues = {
    post_url: '',
    influencer: null,
    preferred_payment_method: null,
    venmo_email_address: '',
    paypal_email_address: '',
    payment_details: '',
};

const validationSchema = yup.object().shape(
    {
        post_url: yup.string().url().required().label('Post url'),
        influencer: yup.object().required().nullable().label('Influencer'),
        preferred_payment_method: yup.string().nullable().required('At least one payment method is required'),
        paypal_email_address: yup
            .string()
            .email('Must be a valid email address')
            .when('preferred_payment_method', {
                is: (value: microwave.PaymentMethod) => value === 'PAYPAL',
                then: (schema) => schema.required('Payment details are required'),
            }),
        payment_details: yup.string().when('preferred_payment_method', {
            is: (value: microwave.PaymentMethod) => value === 'BANK_TRANSFER',
            then: (schema) => schema.required('Payment details are required'),
        }),
        venmo_email_address: yup
            .string()
            .email('Must be a valid email address')
            .when('preferred_payment_method', {
                is: (value: microwave.PaymentMethod) => value === 'VENMO',
                then: (schema) => schema.required('Payment details are required'),
            }),
    },
    [['paypal_email_address', 'payment_details']]
);

const CreateMicrowavePostModal = ({ isOpen, closeModal, campaignId, onCreated }: Props) => {
    const selectProps = useMicrowaveInfluencersSelect(undefined, { initOptionsOnMenuOpen: true });

    const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        if (typeof campaignId !== 'number') {
            return;
        }

        try {
            helpers.setSubmitting(true);

            const response = await microwave.postMicroPost({
                campaign_id: campaignId,
                influencer_id: values.influencer?.value!,
                post_url: values.post_url!,
                preferred_payment_method: values.preferred_payment_method!,
                paypal_email_address: values.paypal_email_address,
                venmo_email_address: values.venmo_email_address,
                payment_details: values.payment_details,
            });

            if (response.status === 404) {
                showNotification(response.data.detail, 'error');
                return;
            }

            if (Array.isArray(response.data)) {
                showNotification(response.data.join('. '), 'error');
                return;
            }

            if (response.status === 400) {
                helpers.setErrors(
                    mapApiErrorsToFormikErrors<FormValues>({
                        ...response.data,
                        influencer: response.data.influencer_id,
                    })
                );
                return;
            }

            showNotification('Post added', 'info');
            onCreated();
        } catch {
            showNotification('Could not add post', 'error');
        } finally {
            helpers.setSubmitting(false);
        }
    };

    return (
        <Modal closeOnEscape closeOnOverlayClick className={styles.modal} isOpen={isOpen} onClose={closeModal}>
            <Modal.Header>Add post</Modal.Header>
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ dirty, submitForm, isSubmitting, ...props }) => {
                    const selectedPaymentMethod = props.values.preferred_payment_method;

                    const handlePaymentMethodChange = (value: FormValues['preferred_payment_method']) => {
                        if (selectedPaymentMethod === value) {
                            return;
                        }

                        props.setErrors({
                            ...props.errors,
                            preferred_payment_method: '',
                            payment_details: '',
                            paypal_email_address: '',
                            venmo_email_address: '',
                        });

                        props.setValues({
                            ...props.values,
                            paypal_email_address: '',
                            payment_details: '',
                            venmo_email_address: '',
                        });

                        props.setFieldValue('preferred_payment_method', value);
                    };

                    return (
                        <>
                            <Form className={styles.form}>
                                <FormField name="post_url" label="Post URL" placeholder="Enter post url..." />

                                <SelectField
                                    name="influencer"
                                    label="Influencer"
                                    {...omit(selectProps, ['value', 'onChange'])}
                                    formatOptionLabel={(
                                        option: MicrowaveInfluencerOption,
                                        { context }: { context: 'menu' | 'value' }
                                    ) => {
                                        return <MicrowaveInfluencerOptionLabel {...option} context={context} />;
                                    }}
                                    filterOption={null}
                                />

                                <FormField
                                    name="preferred_payment_method"
                                    label="Payment Method"
                                    component={() => (
                                        <article className={styles.paymentOptionsContainer}>
                                            {preferredPaymentMethodOptions.map(({ label, value: method }) => {
                                                return (
                                                    <button
                                                        type="button"
                                                        key={method}
                                                        onClick={() => handlePaymentMethodChange(method)}
                                                        className={cn(styles.paymentButton, {
                                                            [styles.selected]: selectedPaymentMethod === method,
                                                        })}
                                                    >
                                                        <div>{label}</div>
                                                    </button>
                                                );
                                            })}
                                        </article>
                                    )}
                                />

                                {selectedPaymentMethod === 'PAYPAL' && (
                                    <div>
                                        <FormField
                                            name="paypal_email_address"
                                            label="PayPal Email Address"
                                            placeholder="Enter PayPal email..."
                                        />
                                    </div>
                                )}

                                {selectedPaymentMethod === 'VENMO' && (
                                    <div>
                                        <FormField
                                            name="venmo_email_address"
                                            label="Venmo Email Address"
                                            placeholder="Enter Venmo email..."
                                        />
                                    </div>
                                )}

                                {selectedPaymentMethod === 'BANK_TRANSFER' && (
                                    <div>
                                        <FormField
                                            name="payment_details"
                                            label="Payment Details"
                                            placeholder="Enter bank details..."
                                        />
                                    </div>
                                )}
                            </Form>
                            <Modal.Actions>
                                <Button disabled={isSubmitting} appearance="ghost" onClick={closeModal}>
                                    Cancel
                                </Button>
                                <Button
                                    appearance="primary"
                                    disabled={!dirty}
                                    isLoading={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Add
                                </Button>
                            </Modal.Actions>
                        </>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default CreateMicrowavePostModal;
