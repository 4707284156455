import styles from './RoundClientUsersPage.module.css';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import RoundClientUsers from './RoundClientUsers/RoundClientUsers';
import PageContentCardContainer from '../../../ui/Layout/PageContentCardContainer/PageContentCardContainer';

const RoundClientUsersPage = () => {
    return (
        <div className={styles.page}>
            <NavBar />
            <PageContentCardContainer className={styles.container}>
                <h1 className={styles.title}>Client Users</h1>
                <RoundClientUsers />
            </PageContentCardContainer>
        </div>
    );
};

export default RoundClientUsersPage;
