import React, { useMemo, useState } from 'react';
import { Project } from '../../Projects.types';
import { CellProps, Column } from 'react-table';
import TitleCell from '../../../../../SharedComponents/TableComponents/TitleCell/TitleCell';
import { formatDate } from '../../../../../helpers';
import ArrowRightCircleIcon from '../../../../../SharedComponents/svg/Icons/ArrowRightCircleIcon';
import styles from './ProjectsTable.module.css';
import cn from 'classnames';
import Skeleton from '../../../../../ui/Skeleton/Skeleton';
import PlatformIcon from './PlatformIcon';
import capitalize from 'lodash/capitalize';
import StatusIcon from '../StatusIcon/StatusIcon';
import StatusInfoIcon from '../icons/StatusInfoIcon';
import PaginationTable from '../../../../../ui/PaginationTable/PaginationTable';
import Image from '../../../../../ui/Image/Image';
import Tooltip from '../../../../../ui/DataDisplay/Tooltip/Tooltip';
import { useNavigate } from 'react-router-dom';
import { Popover } from '@round/ui-kit';
import { useCheckUserGroupsAccess } from '../../../../Auth/hooks/useCheckUserGroupsAccess';

type ProjectsTableProps = {
    projects: Project[];
    columns?: Column<Project>[];
    setPage: (page: number) => void;
    page: number;
    count: number;
    pageSize: number;
    setPageSize: (size: number | undefined) => void;
    loading: boolean;
    noDataLabel?: string;
};

const ProjectsTable = ({
    projects,
    columns,
    noDataLabel,
    setPage,
    page,
    count,
    pageSize,
    setPageSize,
    loading,
}: ProjectsTableProps) => {
    const navigate = useNavigate();
    const userHasAdmin = useCheckUserGroupsAccess(['user_admin']);

    const defaultColumns = useMemo<Column<Project>[]>(() => {
        const columns: Column<Project>[] = [
            {
                Header: 'Brand',
                accessor: 'brand_name',
                Cell: (props) => (
                    <div className={styles.brandContainer}>
                        <Image className={styles.brandImage} src={props.row.original.brand_image} alt="brand" />
                        <TitleCell loading={loading} title={props.value} subtitle={props.row.original.client_name} />
                    </div>
                ),
            },
            {
                Header: 'Project',
                accessor: 'release_name',
                Cell: (props) => (
                    <TitleCell loading={loading} title={props.value} subtitle={props.row.original.release_type} />
                ),
            },
            {
                Header: 'Advertising',
                accessor: 'media_plan_channel_names',
                Cell: (props) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    const names = props.value
                        .map((n) => n.toLowerCase())
                        .map((name) => (name === 'fbig' ? ['facebook', 'instagram'] : name))
                        .flat();

                    return (
                        <div data-test-id="advertising-cell" className={styles.advertisingCell}>
                            {Array.from(new Set(names)).map((name) => (
                                <PlatformIcon key={name} name={name} />
                            ))}
                        </div>
                    );
                },
            },
            {
                Header: 'Creators',
                accessor: 'influencer_plan_channel_names',
                Cell: (props) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    const names = props.value.map((n) => n.toLowerCase());
                    return (
                        <div data-test-id="creators-cell" className={styles.creatorsCell}>
                            {Array.from(new Set(names)).map((name) => (
                                <PlatformIcon key={name} name={name} />
                            ))}
                        </div>
                    );
                },
            },
            {
                Header: () => {
                    const [statusRef, setStatusRef] = useState<HTMLDivElement | null>(null);
                    return (
                        <>
                            <div ref={setStatusRef} className={styles.statusHeader}>
                                <span>Status</span>
                                <StatusInfoIcon
                                    className={styles.statusHeaderIcon}
                                    width={18}
                                    height={18}
                                    title="Statuses description"
                                />
                            </div>

                            <Popover anchorElement={statusRef} showOn="hover" options={{ placement: 'bottom-start' }}>
                                <div className={styles.statusesDescriptionsContainer}>
                                    <div className={styles.statusDescriptionRow}>
                                        <div className={styles.statusCell}>
                                            <StatusIcon status="live" title="Live icon" />
                                            <span className={cn(styles.statusLabel, styles.live)}>Live</span>
                                        </div>
                                        <p>All campaigns running</p>
                                    </div>

                                    <div className={styles.statusDescriptionRow}>
                                        <div className={styles.statusCell}>
                                            <StatusIcon status="completed" title="Completed icon" />
                                            <span className={cn(styles.statusLabel, styles.completed)}>Completed</span>
                                        </div>
                                        <p>Campaigns successfully completed</p>
                                    </div>

                                    <div className={styles.statusDescriptionRow}>
                                        <div className={styles.statusCell}>
                                            <StatusIcon status="planned" title="Planned icon" />
                                            <span className={cn(styles.statusLabel, styles.planned)}>Planned</span>
                                        </div>
                                        <p>Campaigns will run in the future</p>
                                    </div>
                                </div>
                            </Popover>
                        </>
                    );
                },
                accessor: 'status',
                Cell: (props) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    const formatted = capitalize(props.value.replace(/[^a-zA-Z]/g, ' '));
                    return (
                        <div className={styles.statusCell}>
                            <StatusIcon status={props.value} title={`${formatted} icon`} />
                            <span
                                className={cn(styles.statusLabel, {
                                    [styles.live]: props.value === 'live',
                                    [styles.completed]: props.value === 'completed',
                                    [styles.planned]: props.value === 'planned',
                                })}
                            >
                                {formatted}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: 'Last updated',
                accessor: 'last_updated',
                Cell: (props) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{props.value ? formatDate(props.value) : '-'}</>;
                },
            },
            {
                Header: 'Action',
                accessor: 'release_id',
                Cell: (props: CellProps<Project>) => {
                    if (loading) {
                        return <Skeleton />;
                    }
                    const { media_plan_channel_names, influencer_plan_channel_names } = props.row.original;
                    const defaultTab =
                        media_plan_channel_names.length === 0 && influencer_plan_channel_names.length > 0
                            ? 'creators'
                            : 'advertising';

                    return (
                        <a
                            className={styles.viewDetailsLink}
                            href={`/campaigns/${props.value}/${defaultTab}`}
                            onClick={(e) => e.stopPropagation()}
                        >
                            View Details <ArrowRightCircleIcon className={styles.viewDetailsIcon} />
                        </a>
                    );
                },
            },
        ];

        if (userHasAdmin) {
            columns.splice(-3, 0, {
                Header: 'Team',
                accessor: 'planners' as const,
                Cell: (props: CellProps<Project>) => {
                    const [accountTeamTooltipRef, setAccountTeamTooltipRef] = useState<HTMLElement | null>(null);

                    if (loading) {
                        return <Skeleton />;
                    }
                    return (
                        <>
                            <div className={styles.accountTeamCell} ref={setAccountTeamTooltipRef}>
                                {props.value.join(', ')}
                            </div>
                            {props.value && (
                                <Tooltip anchorElement={accountTeamTooltipRef} value={props.value.join(', ')} />
                            )}
                        </>
                    );
                },
            });
        }

        return columns;
    }, [loading, userHasAdmin]);

    return (
        <PaginationTable
            loading={loading}
            noDataLabel={noDataLabel}
            columns={columns || defaultColumns}
            data={projects}
            count={count}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            onRowClick={(row) => {
                if (loading) {
                    return;
                }

                const { media_plan_channel_names, influencer_plan_channel_names } = row;
                const defaultTab =
                    media_plan_channel_names.length === 0 && influencer_plan_channel_names.length > 0
                        ? 'creators'
                        : 'advertising';
                navigate(`/campaigns/${row.release_id}/${defaultTab}`);
            }}
        />
    );
};

export default ProjectsTable;
