import { GenericDropdownOption } from '../../utility/utility.types';

export type AdvancedFilterConditionTypes = 'all' | 'any' | 'exclude_if_any' | 'exclude_if_all';
export type AdvancedFilterCondition<T> = {
    type: AdvancedFilterConditionTypes;
    value: T;
};

export type AdvancedFilterConditionTypeOption = GenericDropdownOption<AdvancedFilterConditionTypes>;
export const conditionTypeOptions: AdvancedFilterConditionTypeOption[] = [
    { value: 'any', label: 'has any' },
    { value: 'all', label: 'has all' },
];

export const excludeConditionTypeOptions: AdvancedFilterConditionTypeOption[] = [
    { value: 'exclude_if_any', label: 'exclude if any' },
    { value: 'exclude_if_all', label: 'exclude if all' },
];

export const conditionTypeOptionsWithExclude = conditionTypeOptions.concat(excludeConditionTypeOptions);
