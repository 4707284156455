import { encodeUrlSearchParams, fetchWithToken } from '../../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../../types';
import { PaypalPaymentStatus } from '../postPaymentRequests';
import { PostPayment } from './postPayments.types';

export type GetPostPaymentsParams = Partial<
    PaginatedRequest & {
        plan_id: number;
        paypal_payment_status: PaypalPaymentStatus;
        tiktok_influencer_user_id: number;
        instagram_influencer_user_id: number;
    }
>;

type GetPostPaymentsResponse = ApiResponse<PaginatedApiResponseData<PostPayment>, 200> | NotFoundResponse;

export async function getPostPayments(
    params: GetPostPaymentsParams,
    requestInit?: RequestInit
): Promise<GetPostPaymentsResponse> {
    const response = await fetchWithToken(
        `/api/advertising/post-payments/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get post payments');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type MakePaymentData = {
    amount: number;
    currency: number;
    payment_request_id: number;
};

type MakePayPalPaymentResponse =
    | ApiResponse<null, 204>
    | ApiResponse<ApiResponseError<MakePaymentData>, 400>
    | ApiResponse<string[], 400>;

export async function makePayPalPayment(data: MakePaymentData): Promise<MakePayPalPaymentResponse> {
    const response = await fetchWithToken('/api/advertising/post-payments/make-paypal-payment/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not make paypal payment');
    }

    return {
        status: 204,
        data: null,
    };
}

type MakeManualPayPalPaymentResponse =
    | ApiResponse<null, 204>
    | ApiResponse<ApiResponseError<MakePaymentData>, 400>
    | ApiResponse<string[], 400>;

export async function makeManualPayPalPayment(data: MakePaymentData): Promise<MakeManualPayPalPaymentResponse> {
    const response = await fetchWithToken('/api/advertising/post-payments/confirm-paypal-manual-payment/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not make manual paypal payment');
    }

    return {
        status: 204,
        data: null,
    };
}
