import styles from './BrandForm.module.css';
import { creatorbase } from '@round/api';
import { GenericDropdownOption } from 'App.types';
import { omit } from 'lodash';
import useTeamSelect from 'Modules/Plans/Team/hooks/useTeamSelect';
import { FormField, SelectField } from 'ui-new/whitelabel/formik';
import { useFormikContext } from 'formik';
import ImageUpload from 'ui-new/whitelabel/ImageUpload/ImageUpload';
import { useCallback } from 'react';

export type BrandFormValues = Pick<creatorbase.PostBrandData, 'name' | 'image'> & {
    team: GenericDropdownOption<number> | null;
};

const BrandForm = () => {
    const { props: teamSelectProps } = useTeamSelect({
        initOn: 'menuOpen',
    });

    const { setFieldValue, setFieldTouched, values } = useFormikContext<BrandFormValues>();

    const renderImageUpload = useCallback(() => {
        return (
            <ImageUpload
                name="image"
                file={values.image}
                onUpload={(file) => {
                    setFieldTouched('image', true);
                    setFieldValue('image', file);
                }}
            />
        );
    }, [setFieldTouched, setFieldValue, values.image]);

    return (
        <form className={styles.container}>
            <FormField name="image" component={renderImageUpload} />

            <FormField className={styles.field} type="text" name="name" label="Name" placeholder="Enter artist name" />

            <SelectField
                {...omit(teamSelectProps, ['value', 'onChange'])}
                className={styles.field}
                name="team"
                label="Team"
                placeholder="Select team"
            />
        </form>
    );
};

export default BrandForm;
