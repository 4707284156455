import useNonNullContext from 'Hooks/useNonNullContext';
import { PropsWithChildren } from 'react';
import { TabsContext, TabsContextState } from '@round/ui-kit';
import styles from './AccordionTabNavItem.module.css';
import cn from 'classnames';

type AccordionTabNavItemProps = PropsWithChildren<{
    name: TabsContextState['activeTab'];
}>;

export const AccordionTabNavItem = ({ name, children }: AccordionTabNavItemProps) => {
    const { activeTab, onChange } = useNonNullContext(TabsContext);

    return (
        <button
            className={cn(styles.tabButton, {
                [styles.active]: activeTab === name,
            })}
            onClick={() => onChange(name)}
        >
            {children}
        </button>
    );
};
