import { getTiktokAudioAssets, getTiktokAudios, TiktokAudio, TiktokAudioAsset } from '@round/api';
import { AudioOption } from '../components/AudioSelectComponents/AudioSelectComponents';
import { showNotification } from '../../../helpers';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import useAbortableEffect from '../../../Hooks/useAbortableEffect';
import { ValueType } from 'react-select';

const PAGE_SIZE = 10;

const makeAudioOption = (audio: TiktokAudio, asset?: TiktokAudioAsset | undefined): AudioOption => ({
    value: audio.id,
    label: audio.title,
    title: audio.title,
    authorName: audio.author_name,
    tiktokId: audio.tiktok_id,
    isOriginal: audio.is_original,
    imageUrl: asset?.image_thumb.cached_url || asset?.image_thumb.original_url || '',
    audioPlayUrl: asset?.audio_file.cached_url || asset?.audio_file.original_url || '',
});

type Params<IsMulti extends boolean> = IsMulti extends true
    ? {
          initialAudioIdData: number[];
          isMulti: IsMulti;
          searchOnly?: boolean;
      }
    : {
          initialAudioIdData: number | undefined;
          isMulti?: IsMulti;
          searchOnly?: boolean;
      };

export function useAudioSelect<IsMulti extends boolean = false>({
    initialAudioIdData,
    isMulti,
    searchOnly,
}: Params<IsMulti>) {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [audios, setAudios] = useState<TiktokAudio[]>([]);
    const [assets, setAssets] = useState<TiktokAudioAsset[]>([]);

    const [audioSelection, setAudioSelection] = useState<ValueType<AudioOption, IsMulti>>(null);

    const fetchData = useCallback(async (params: Parameters<typeof getTiktokAudios>[0], requestInit?: RequestInit) => {
        try {
            setIsLoading(true);
            const {
                data: { results, next },
            } = await getTiktokAudios(params, requestInit);

            setAudios((prev) => prev.concat(results));
            setHasNextPage(!!next);

            const audioIds = results.map((audio) => audio.id);

            if (!audioIds.length) {
                return;
            }

            const assets = await getTiktokAudioAssets(audioIds, requestInit);
            setAssets((prev) => prev.concat(assets));
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            const errorMessage = e instanceof Error ? e.message : 'Could not fetch audios';
            showNotification(errorMessage, 'error');
        } finally {
            setIsLoading(false);
            setIsSearchLoading(false);
        }
    }, []);

    const debouncedFetchData = useMemo(() => debounce(fetchData, 700), [fetchData]);

    useAbortableEffect(
        (signal) => {
            if (searchOnly && !search) {
                return;
            }
            debouncedFetchData({ tiktok_id: search, page, page_size: PAGE_SIZE }, { signal });
        },
        [debouncedFetchData, page, search, searchOnly]
    );

    const reset = useCallback(() => {
        setAudios([]);
        setAssets([]);
        setPage(1);
        setHasNextPage(false);
    }, []);

    const options: AudioOption[] = useMemo(
        () =>
            audios.map((item) => {
                const asset = assets.find((asset) => asset.audio_id === item.id);
                return makeAudioOption(item, asset);
            }),
        [assets, audios]
    );

    useAbortableEffect(
        (signal) => {
            async function initData() {
                if (!initialAudioIdData || (Array.isArray(initialAudioIdData) && !initialAudioIdData.length)) {
                    return;
                }

                try {
                    const audioIdData = Array.isArray(initialAudioIdData) ? initialAudioIdData : [initialAudioIdData];
                    let audiosToFetch: number[] = [];
                    if (isMulti) {
                        audiosToFetch = audioIdData.filter(
                            (id) =>
                                !(audioSelection as ValueType<AudioOption, true>)?.some((audio) => audio.value === id)
                        );
                    } else {
                        audiosToFetch =
                            (audioSelection as ValueType<AudioOption, false>)?.value === audioIdData[0]
                                ? []
                                : audioIdData;
                    }

                    if (!audiosToFetch.length) {
                        return;
                    }

                    const [audiosResponse, assets] = await Promise.all([
                        getTiktokAudios({ id: audiosToFetch.join(','), page_size: audiosToFetch.length }, { signal }),
                        getTiktokAudioAssets(audiosToFetch, { signal }),
                    ]);

                    if (!isMulti) {
                        (setAudioSelection as Dispatch<SetStateAction<ValueType<AudioOption, false>>>)(() => {
                            const audio = audiosResponse.data.results[0];
                            const asset = assets.find((a) => a.audio_id === audio.id);
                            return makeAudioOption(audio, asset);
                        });
                        return;
                    }

                    (setAudioSelection as Dispatch<SetStateAction<ValueType<AudioOption, true>>>)((options) =>
                        (options ?? []).concat(
                            audiosResponse.data.results.map((audio) => {
                                const asset = assets.find((a) => a.audio_id === audio.id);
                                return makeAudioOption(audio, asset);
                            })
                        )
                    );
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    showNotification('Could not fetch initial audios', 'error');
                }
            }
            initData();
        },
        [audioSelection, initialAudioIdData, isMulti]
    );

    return {
        value: audioSelection,
        onChange: setAudioSelection,
        options,
        inputValue: search,
        onInputChange: (value: string) => {
            if (value === search) {
                return;
            }

            reset();
            setSearch(value);

            if (value) {
                setIsSearchLoading(true);
            }
        },
        onMenuScrollToBottom: () => {
            if (!hasNextPage) {
                return;
            }
            setPage((prev) => prev + 1);
        },
        isLoading: isLoading || isSearchLoading,
        filterOption: null,
    };
}
