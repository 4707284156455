import React from 'react';
import styles from './ModalContent.module.css';
import cn from 'classnames';
import { FCWithChildren } from '../../../utility/utility.types';

type ModalContentProps = {
    className?: string;
};

const ModalContent: FCWithChildren<ModalContentProps> = ({ children, className }) => {
    return <div className={cn(styles.modalContent, className)}>{children}</div>;
};

export default ModalContent;
