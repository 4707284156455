import { ApiResponseError, creatorbase } from '@round/api';
import { GenericDropdownOption } from 'App.types';
import { UseCampaigns } from '../../hooks/useCampaigns';
import { UserOption, buildUserSelectFetchOptions, useUsersMultiSelect } from 'Modules/Plans/User/hooks/useUsersSelect';
import { FormikErrors, useFormikContext } from 'formik';
import { omit } from 'lodash';
import { useMemo, useState } from 'react';
import { StylesConfig, ValueType } from 'react-select';
import { FormField, SelectField, MoneyField } from 'ui-new/whitelabel/formik';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import { DistributiveOmit } from 'utility/utility.types';
import * as yup from 'yup';
import styles from './CampaignFormBase.module.css';
import ExternalMultiValuesControl from 'ui-new/whitelabel/Select/components/ExternalMultiValuesControl/ExternalMultiValuesControl';
import None from 'ui-new/whitelabel/Select/components/None';
import StaticTextValue from 'ui-new/whitelabel/Select/components/StaticTextValue';
import RadioOptionCard from 'ui-new/whitelabel/Radio/RadioOptionCard/RadioOptionCard';

export type BaseCampaignFormValues = Pick<creatorbase.PostCampaignBody, 'name'> & {
    cost: string;
    is_post_cost_editable: boolean | null;
    currency: ValueType<GenericDropdownOption<number>, false>;
    team_members: ValueType<UserOption, true>;
};

export const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    is_post_cost_editable: yup.boolean().nullable().required('Spending type is required'),
    cost: yup.string().when('is_post_cost_editable', {
        is: (isPostCostEditable: boolean | null) => isPostCostEditable === false,
        then: yup
            .string()
            .required('Cost is required')
            .matches(/^[0-9]+$/, {
                message: 'Cost should be a number',
                excludeEmptyString: true,
            }),
        otherwise: undefined,
    }),
    currency: yup.object().nullable().required('Currency is required'),
});

export const mapCampaignApiErrorsToFormikErrors = (
    apiErrors: ApiResponseError<creatorbase.PostCampaignBody>
): FormikErrors<BaseCampaignFormValues> =>
    mapApiErrorsToFormikErrors({
        cost: apiErrors.cost,
        currency: apiErrors.currency_id,
        is_post_cost_editable: apiErrors.is_post_cost_editable,
        name: apiErrors.name,
        team_members: apiErrors.team_members,
    });

type ApiParameters = Parameters<UseCampaigns['createCampaign']>[0];
export const mapCampaignFormValuesToApiData = (
    values: BaseCampaignFormValues
): DistributiveOmit<ApiParameters, 'platform'> => {
    const base = {
        currency_id: values.currency?.value!,
        name: values.name,
        team_members: values.team_members?.map((user) => user.value) ?? [],
    };

    return values.is_post_cost_editable!
        ? { ...base, is_post_cost_editable: true, cost: null }
        : { ...base, is_post_cost_editable: false, cost: Number(values.cost) };
};

export const getBaseCampaignFormFields = (fields?: string[]) => {
    const baseFields: Array<keyof BaseCampaignFormValues> = [
        'cost',
        'currency',
        'is_post_cost_editable',
        'name',
        'team_members',
    ];

    return fields?.filter((f): f is keyof BaseCampaignFormValues =>
        baseFields.includes(f as keyof BaseCampaignFormValues)
    );
};

const currencySelectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        pointerEvents: 'initial',
    }),
};

type Props = {
    teamId: number | undefined;
    readonlyFields?: Array<keyof BaseCampaignFormValues>;
};

const CampaignFormBase = ({ readonlyFields, teamId }: Props) => {
    const { values, setFieldError, setFieldValue } = useFormikContext<BaseCampaignFormValues>();
    const [currencyValueAnchor, setCurrencyValueAnchor] = useState<HTMLSpanElement | null>(null);

    const { props: teamMembersProps } = useUsersMultiSelect({
        initOn: 'menuOpen',
        fetchOptions: useMemo(() => buildUserSelectFetchOptions({ team_id: teamId }), [teamId]),
    });

    const setIsPostCostEditable = (isPostCostEditable: boolean) => {
        if (isPostCostEditable === values.is_post_cost_editable) {
            return;
        }

        setFieldError('is_post_cost_editable', undefined);
        setFieldError('currency', undefined);
        setFieldError('cost', undefined);

        setFieldValue('is_post_cost_editable', isPostCostEditable);
    };

    return (
        <>
            <FormField
                name="name"
                label="Campaign name"
                placeholder="Enter a campaign name"
                disabled={readonlyFields?.includes('name')}
            />
            <FormField
                name="is_post_cost_editable"
                label="Spending type"
                subLabel="Select how the campaign will be billed"
                component={() => (
                    <div className={styles.spendingType}>
                        <RadioOptionCard
                            name="is_post_cost_editable"
                            label="Campaign-led"
                            subLabel="Enter one cost for the whole campaign"
                            isDisabled={readonlyFields?.includes('is_post_cost_editable')}
                            onClick={() => setIsPostCostEditable(false)}
                            isSelected={values.is_post_cost_editable === false}
                        />

                        <RadioOptionCard
                            name="is_post_cost_editable"
                            label="Post-led"
                            subLabel="Enter individual costs for each creator post"
                            isDisabled={readonlyFields?.includes('is_post_cost_editable')}
                            onClick={() => setIsPostCostEditable(true)}
                            isSelected={values.is_post_cost_editable === true}
                        />
                    </div>
                )}
            />

            {typeof values.is_post_cost_editable === 'boolean' && (
                <>
                    <MoneyField
                        name="cost"
                        currencyFieldName="currency"
                        label={values.is_post_cost_editable ? 'Campaign currency' : 'Campaign budget'}
                        isCurrencyDisabled={readonlyFields?.includes('currency')}
                        hideCostInput={values.is_post_cost_editable}
                        currencySelectStyles={currencySelectStyles}
                        className={styles.moneyInput}
                        formatOptionLabel={(
                            option: GenericDropdownOption<number>,
                            { context }: { context: 'menu' | 'value' }
                        ) => {
                            if (context === 'value') {
                                return <span ref={setCurrencyValueAnchor}>{option.label}</span>;
                            }

                            return option.label;
                        }}
                    />

                    {readonlyFields?.includes('currency') && (
                        <Tooltip anchorElement={currencyValueAnchor} placement="top-start">
                            <Tooltip.Title>Unable to change campaign currency</Tooltip.Title>
                            <Tooltip.Body>You cannot change currency if you have 2 or more campaigns</Tooltip.Body>
                        </Tooltip>
                    )}
                </>
            )}

            <SelectField
                name="team_members"
                label="Team members"
                isClearable={false}
                controlShouldRenderValue={false}
                {...omit(teamMembersProps, ['value', 'onChange'])}
                components={{
                    Control: ExternalMultiValuesControl,
                    Input: StaticTextValue,
                    MultiValue: None,
                    Placeholder: None,
                }}
            />
        </>
    );
};

export default CampaignFormBase;
