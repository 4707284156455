import { Field, FieldProps, Form } from 'formik';
import { FormField } from 'ui-new/whitelabel/formik';
import * as yup from 'yup';
import styles from './PostForm.module.css';
import { CurrencyField } from 'ui-new/whitelabel/formik';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';
import RadioOptionCard from 'ui-new/whitelabel/Radio/RadioOptionCard/RadioOptionCard';

export type PostFormValues = {
    identifier: string;
    influencer_account_identifier: string;
    cost: number | null | undefined;
    isLivePost: boolean | undefined;
};

export const getValidationSchema = (isPostCostEditable: boolean) => {
    const schema = yup.object({
        isLivePost: yup.boolean().required('Please, enter either influencer or post url'),
        identifier: yup.string().when('isLivePost', {
            is: true,
            then: (schema) => schema.required('Post field is required'),
        }),
        influencer_account_identifier: yup.string().when('isLivePost', {
            is: false,
            then: (schema) => schema.required('Influencer field is required'),
        }),
    });

    if (isPostCostEditable) {
        return schema.shape({ cost: yup.string().required('Cost is required') });
    }

    return schema;
};

type Props = {
    isPostCostEditable?: boolean;
    currency?: number;
};

const PostForm = ({ isPostCostEditable, currency }: Props) => {
    const featureFlags = useFeatureFlags();
    const isPlannedPostEnabled = featureFlags.isEnabled('cb-release-nov-24');

    return (
        <Form className={styles.form}>
            {isPlannedPostEnabled && (
                <Field name="isLivePost">
                    {({
                        field: { value: isLivePost },
                        form,
                    }: FieldProps<PostFormValues['isLivePost'], PostFormValues>) => (
                        <div className={styles.postType}>
                            <RadioOptionCard
                                name="isLivePost"
                                label="Enter Influencer"
                                isSelected={isLivePost === false}
                                onClick={() => form.setFieldValue('isLivePost', false)}
                            >
                                {isLivePost === false && (
                                    <div className={styles.nestedInputContainer} onClick={(e) => e.stopPropagation()}>
                                        <FormField
                                            className={styles.nestedInput}
                                            name="influencer_account_identifier"
                                            placeholder="Enter influencer handle or url"
                                        />
                                    </div>
                                )}
                            </RadioOptionCard>

                            <RadioOptionCard
                                name="isLivePost"
                                label="Enter Post URL"
                                isSelected={!!isLivePost}
                                onClick={() => form.setFieldValue('isLivePost', true)}
                            >
                                {!!isLivePost && (
                                    <div className={styles.nestedInputContainer} onClick={(e) => e.stopPropagation()}>
                                        <FormField
                                            className={styles.nestedInput}
                                            name="identifier"
                                            placeholder="Enter post URL"
                                        />
                                    </div>
                                )}
                            </RadioOptionCard>
                        </div>
                    )}
                </Field>
            )}

            {!isPlannedPostEnabled && <FormField name="identifier" label="Post" placeholder="Enter post URL" />}

            {isPostCostEditable && (
                <CurrencyField
                    name="cost"
                    label="Cost"
                    placeholder="Enter cost"
                    currency={currency}
                    groupSeparator=","
                    decimalSeparator="."
                    decimalsLimit={2}
                />
            )}
        </Form>
    );
};

export default PostForm;
