import { useLayoutEffect, useRef, useState } from 'react';

export function useRectObserver(element: HTMLElement | null) {
    const [rect, setRect] = useState<DOMRect | null>(null);
    const observer = useRef<ResizeObserver | null>(null);

    const cleanUp = () => {
        if (observer.current) {
            observer.current.disconnect();
        }
    };

    useLayoutEffect(() => {
        if (!element) {
            setRect(null);
            return;
        }
        cleanUp();

        observer.current = new ResizeObserver(([entry]) => {
            setRect(entry.target.getBoundingClientRect());
        });
        observer.current.observe(element);

        return cleanUp;
    }, [element]);

    return rect;
}
