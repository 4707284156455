import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Team } from './teams.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';

export const models = {
    creatorbaseTeam: Model as ModelDefinition<Team>,
};

export const serializers = {
    creatorbaseTeam: makeSerializer<Team>([]),
};

export const factories = {
    creatorbaseTeam: createFactory<Team>({
        name: (index: number) => `name ${index}`,
        website: (index: number) => `website ${index}`,
        logo: (index: number) => `logo ${index}`,
        logo_large: (index: number) => `logo_large ${index}`,
    }),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/creatorbase/teams/';
    server.get(rootUrl, function (this: RouteHandlerContext, schema, request) {
        const ids: number[] = request.queryParams.id ? request.queryParams.id.split(',').map(Number) : [];

        const teams = schema
            .all('creatorbaseTeam')
            .filter((team) => ids.includes(Number(team.id)) || !request.queryParams.id);

        return buildPaginatedResponse(teams, {
            url: rootUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'creatorbaseTeam'),
        });
    });

    server.get(`${rootUrl}:id/`, (schema, request) => {
        const team = schema.find('creatorbaseTeam', request.params.id);
        if (!team) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return team;
    });
}
