import React, { FunctionComponent, useMemo, useState } from 'react';
import Card from '../../../ui/Card/Card';
import LeftArrowIcon from '../../../SharedComponents/svg/Icons/LeftArrowIcon';
import RightArrowIcon from '../../../SharedComponents/svg/Icons/RightArrowIcon';
import styles from './InvoiceTracker.module.css';
import { getBrands, getClients } from '@round/api';
import useUrlState from '../../../Hooks/useUrlState';
import cn from 'classnames';
import Select from '../../../ui/DataEntry/Select/Select';
import { StylesConfig, ValueType } from 'react-select';
import PaginatedSelect from '../../../ui/DataEntry/PaginatedSelect/PaginatedSelect';
import { GenericDropdownOption } from '../../../App.types';
import SearchInput from '../../../ui/SearchInput/SearchInput';
import { Checkbox } from '@round/ui-kit';
import CustomDatePicker from '../../../SharedComponents/CustomDatePicker/CustomDatePicker';
import { formatToIsoDateString, isNumber } from '../../../utility/utility';
import Skeleton from '../../../ui/Skeleton/Skeleton';
import { InvoiceBar } from './InvoiceBar/InvoiceBar';
import { useInvoiceData } from './useInvoiceData';

type UrlState = {
    search?: string;
    page?: number;
    pageSize?: number;
    expandedBars?: string;
    excludeWithoutItems?: boolean;
    dateFrom?: string;
    dateTo?: string;
};

const pageSizeSelectStyles: StylesConfig = {
    valueContainer: (base) => ({
        ...base,
        minWidth: '2rem',
        fontWeight: 500,
    }),
    menu: (base) => ({
        ...base,
        fontSize: '0.75rem',
        fontWeight: 500,
    }),
};

const filterSelectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        minWidth: '10rem',
        width: '100%',
        fontSize: '0.75rem',
        border: '1px solid #c2cfe0',
        borderRadius: '5px',
        overflow: 'auto',
        height: '100%',
    }),
    placeholder: (base) => ({
        ...base,
        color: '#90A0B7',
        fontSize: '0.8125rem',
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '0 1rem',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: '0.5rem',
    }),
    input: (base) => ({
        ...base,
        padding: '0',
    }),
};

const INITIAL_START_DATE = '2019-04-01';
const today = new Date();

const InvoiceTracker: FunctionComponent = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>({
        search: '',
        page: 1,
        pageSize: 10,
        dateFrom: INITIAL_START_DATE,
        dateTo: formatToIsoDateString(today),
        excludeWithoutItems: true,
    });

    const page = Number(urlState.page);
    const setPage = (page: number) => setUrlState({ page });

    const pageSize = Number(urlState.pageSize);
    const setPageSize = (pageSize: number | undefined) => setUrlState({ pageSize: pageSize });

    const search = urlState.search ?? '';
    const setSearch = (search: string) => setUrlState({ search, page: 1 });

    const [currentBrandSelections, setCurrentBrandSelections] = useState<
        ValueType<GenericDropdownOption<number>, true>
    >([]);
    const brandIds = useMemo(() => currentBrandSelections?.map((b) => b.value).join(','), [currentBrandSelections]);

    const [currentClientSelections, setCurrentClientSelections] = useState<
        ValueType<GenericDropdownOption<number>, true>
    >([]);
    const clientIds = useMemo(() => currentClientSelections?.map((b) => b.value).join(','), [currentClientSelections]);

    const expandedBars = urlState.expandedBars?.split(',').map((id) => Number(id)) ?? [];
    const toggleIsBarExpanded = (invoiceId: number) => {
        if (expandedBars.includes(invoiceId)) {
            setUrlState({ expandedBars: expandedBars.filter((id) => id !== invoiceId).join(',') });
            return;
        }
        setUrlState({ expandedBars: expandedBars.concat(invoiceId).join(',') });
    };

    const excludeWithoutItems = urlState.excludeWithoutItems === 'true';
    const setExcludeWithoutItems = (value: boolean | undefined) => setUrlState({ excludeWithoutItems: value, page: 1 });

    const dateFrom = useMemo(() => (urlState.dateFrom ? new Date(urlState.dateFrom) : new Date(INITIAL_START_DATE)), [
        urlState.dateFrom,
    ]);
    const dateTo = useMemo(() => (urlState.dateTo ? new Date(urlState.dateTo) : today), [urlState.dateTo]);

    const {
        instagramInfluencerPosts,
        instagramInfluencerUsers,
        influencerPlans,
        instagramUserStats,
        tiktokUserImages,
        instagramUserImages,
        invoicesCount,
        invoices,
        hasPreviousInvoicesPage,
        errorLoadingInvoiceData,
        tiktokInfluencerPosts,
        invoiceIdsWithExchangeRateLoadingError,
        invoiceDataLoading,
        hasNextInvoicesPage,
        mediaPlanItems,
        tiktokInfluencerUsers,
        tiktokUserStats,
        exchangeRates,
        mediaPlans,
    } = useInvoiceData({
        search,
        page,
        page_size: pageSize,
        brand_id: brandIds,
        client_id: clientIds,
        start_date: formatToIsoDateString(dateFrom),
        end_date: formatToIsoDateString(dateTo),
        exclude_if_no_plan_items: excludeWithoutItems,
    });

    const goToPrevPage = () => {
        if (!hasPreviousInvoicesPage) {
            return;
        }
        setPage(page - 1);
    };
    const goToNextPage = () => {
        if (!hasNextInvoicesPage) {
            return;
        }
        setPage(page + 1);
    };

    const pageSizeOptions = [
        { value: 10, label: '10' },
        { value: 25, label: '25' },
        { value: 35, label: '35' },
        { value: 50, label: '50' },
    ];

    return (
        <Card className={styles.container}>
            <h1 className={styles.title}>Invoice Tracker</h1>
            <div className={styles.filterOptionsContainer}>
                <div className={styles.filterOptions}>
                    <div className={cn(styles.filterOptions, styles.left)}>
                        <SearchInput
                            value={search}
                            onChange={setSearch}
                            className={styles.search}
                            inputClassName={styles.searchInput}
                        />

                        <PaginatedSelect
                            isMulti
                            className={styles.multiFilter}
                            styles={filterSelectStyles}
                            placeholder="Filter by brands"
                            value={currentBrandSelections}
                            fetchOptions={getBrands}
                            mapToOption={(brand) => ({
                                value: brand.id,
                                label: brand.name,
                            })}
                            onChange={(values) => {
                                setCurrentBrandSelections(values);
                                setUrlState({ page: 1 });
                            }}
                            menuPortalTarget={document.body}
                        />

                        <PaginatedSelect
                            isMulti
                            className={styles.multiFilter}
                            styles={filterSelectStyles}
                            placeholder="Filter by clients"
                            value={currentClientSelections}
                            fetchOptions={getClients}
                            mapToOption={(client) => ({
                                value: client.id,
                                label: client.name,
                            })}
                            onChange={(values) => {
                                setCurrentClientSelections(values);
                                setUrlState({ page: 1 });
                            }}
                            menuPortalTarget={document.body}
                        />
                    </div>

                    <div className={styles.dateRangeFilter}>
                        <label>From</label>
                        <CustomDatePicker
                            className={styles.dateInput}
                            selectsStart
                            value={dateFrom}
                            onChange={(date) => setUrlState({ dateFrom: formatToIsoDateString(date), page: 1 })}
                            startDate={dateFrom}
                            endDate={dateTo}
                            maxDate={today}
                            dateFormat="MMM dd, yy"
                        />

                        <label>To</label>
                        <CustomDatePicker
                            className={styles.dateInput}
                            selectsEnd
                            value={dateTo}
                            onChange={(date) => setUrlState({ dateTo: formatToIsoDateString(date), page: 1 })}
                            startDate={dateFrom}
                            endDate={dateTo}
                            minDate={dateFrom}
                            maxDate={today}
                            dateFormat="MMM dd, yy"
                        />
                    </div>
                </div>

                <div className={styles.filterOptions}>
                    <div className={styles.excludeWithoutPlanItemsFilter}>
                        <Checkbox
                            id="excludeWithNoItems"
                            disabled={invoiceDataLoading}
                            value={excludeWithoutItems}
                            onChange={(value) => setExcludeWithoutItems(value || undefined)}
                        />
                        <label
                            htmlFor="excludeWithNoItems"
                            onClick={() => {
                                if (!invoiceDataLoading) {
                                    setExcludeWithoutItems(!excludeWithoutItems || undefined);
                                }
                            }}
                        >
                            Remove invoices without items assigned{' '}
                        </label>
                    </div>
                </div>
            </div>
            <div className={styles.table}>
                {invoiceDataLoading && (
                    <>
                        <Skeleton className={styles.groupSkeleton} />
                        <Skeleton className={styles.groupSkeleton} />
                        <Skeleton className={styles.groupSkeleton} />
                    </>
                )}
                {!invoiceDataLoading && errorLoadingInvoiceData && <span>Error loading invoices</span>}
                {!invoiceDataLoading &&
                    !errorLoadingInvoiceData &&
                    invoices.map((invoice, i) => {
                        const relevantMediaPlanItems = mediaPlanItems.filter(
                            (item) => item.xero_invoice_id === invoice.id
                        );

                        const relevantInstagramInfluencerIds = instagramInfluencerPosts
                            .filter((item) => item.xero_invoice_id === invoice.id)
                            .map((p) => p.influencer_id)
                            .filter(isNumber);
                        const relevantInstagramUserIds = instagramInfluencerUsers
                            .filter((influencer) => relevantInstagramInfluencerIds.includes(influencer.id))
                            .map((i) => i?.user)
                            .filter(isNumber);

                        const relevantTiktokInfluencerIds = tiktokInfluencerPosts
                            .filter((post) => post.xero_invoice_id === invoice.id)
                            .map((post) => post.influencer_id)
                            .filter(isNumber);
                        const relevantTiktokUserIds = tiktokInfluencerUsers
                            .filter((influencer) => relevantTiktokInfluencerIds.includes(influencer.id))
                            .map((influencer) => influencer.user)
                            .filter(isNumber);

                        const relevantExchangeRates = exchangeRates.filter((rate) => rate.invoice_id === invoice.id);
                        const errorLoadingExchangeRates = invoiceIdsWithExchangeRateLoadingError.includes(invoice.id);

                        return (
                            <InvoiceBar
                                key={i}
                                invoice={invoice}
                                mediaPlanItems={relevantMediaPlanItems}
                                instagramInfluencerPosts={instagramInfluencerPosts.filter(
                                    (item) => item.xero_invoice_id === invoice.id
                                )}
                                instagramInfluencerUsers={instagramInfluencerUsers.filter((influencer) =>
                                    relevantInstagramInfluencerIds.includes(influencer.id)
                                )}
                                tiktokInfluencerPosts={tiktokInfluencerPosts.filter(
                                    (item) => item.xero_invoice_id === invoice.id
                                )}
                                tiktokInfluencerUsers={tiktokInfluencerUsers.filter((influencer) =>
                                    relevantTiktokInfluencerIds.includes(influencer.id)
                                )}
                                instagramUserStats={instagramUserStats.filter((stat) =>
                                    relevantInstagramUserIds.includes(stat.instagram_user)
                                )}
                                tiktokUserStats={tiktokUserStats.filter((stat) =>
                                    relevantTiktokUserIds.includes(stat.user_id)
                                )}
                                tiktokUserImages={tiktokUserImages.filter((img) =>
                                    relevantTiktokUserIds.includes(img.user_id)
                                )}
                                instagramUserImages={instagramUserImages.filter((img) =>
                                    relevantInstagramUserIds.includes(img.user_id)
                                )}
                                isExpanded={expandedBars.includes(invoice.id)}
                                toggleIsExpanded={() => toggleIsBarExpanded(invoice.id)}
                                influencerPlans={influencerPlans}
                                loading={invoiceDataLoading}
                                exchangeRates={relevantExchangeRates}
                                errorLoadingExchangeRates={errorLoadingExchangeRates}
                                mediaPlans={mediaPlans}
                            />
                        );
                    })}
            </div>
            <div className={styles.paginationOptionsContainer}>
                <div className={styles.pageSelectContainer}>
                    <Select
                        isSearchable={false}
                        isClearable={false}
                        styles={pageSizeSelectStyles}
                        options={pageSizeOptions}
                        value={pageSizeOptions.find((o) => o.value === pageSize)}
                        onChange={(pageSizeOption) => {
                            setPageSize(pageSizeOption?.value);
                        }}
                        menuPosition="fixed"
                    />
                    <span>Records per page</span>
                </div>
                <div className={styles.paginationArrowButtonContainer}>
                    <span>{`Page ${page} of ${Math.ceil(invoicesCount / pageSize)}`}</span>
                    <div
                        className={cn(styles.paginationArrowButton, {
                            [styles.paginationButtonDisabled]: !hasPreviousInvoicesPage || invoiceDataLoading,
                        })}
                        onClick={goToPrevPage}
                    >
                        <LeftArrowIcon
                            ariaRole="button"
                            ariaLabel="Go to previous page"
                            className={cn(styles.paginationIcon, {
                                [styles.paginationIconDisabled]: !hasPreviousInvoicesPage || invoiceDataLoading,
                            })}
                            width={16}
                            height={16}
                        />
                    </div>

                    <div
                        className={cn(styles.paginationArrowButton, {
                            [styles.paginationButtonDisabled]: !hasNextInvoicesPage || invoiceDataLoading,
                        })}
                        onClick={goToNextPage}
                    >
                        <RightArrowIcon
                            ariaRole="button"
                            ariaLabel="Go to next page"
                            className={cn(styles.paginationIcon, {
                                [styles.paginationIconDisabled]: !hasNextInvoicesPage || invoiceDataLoading,
                            })}
                            height={22}
                            width={22}
                        />
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default InvoiceTracker;
