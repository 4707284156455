import React from 'react';
import styles from './CustomDatePicker.module.css';
import './CustomDatePicker.css';
import { datePickerFormat } from '../../helpers';
import DatePicker from 'react-datepicker';
import ArrowRightIcon from '../svg/Icons/RightArrowIcon';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import { FCWithChildren } from '../../utility/utility.types';

interface ICustomDatePicker {
    value: Date | null | undefined;
    onChange: (date: Date) => void;
    className?: string;
    disabled?: boolean;
    dateFormat?: string;
    customInput?: React.ReactNode;
    startDate?: Date | null;
    endDate?: Date | null;
    minDate?: Date | null;
    maxDate?: Date | null;
    selectsStart?: boolean;
    selectsEnd?: boolean;
}

const CustomDatePicker: FCWithChildren<ICustomDatePicker> = ({
    value,
    onChange,
    disabled,
    dateFormat,
    customInput,
    className,
    startDate,
    endDate,
    minDate,
    selectsStart,
    selectsEnd,
    maxDate,
}: ICustomDatePicker) => {
    return (
        <DatePicker
            popperContainer={({ children }) => createPortal(children, document.body)}
            disabled={disabled}
            popperClassName={styles.datePickerPopup}
            className={cn(styles.dateField, className)}
            selected={value}
            onChange={onChange}
            dateFormat={dateFormat || datePickerFormat}
            previousMonthButtonLabel={<ArrowRightIcon width={14} />}
            nextMonthButtonLabel={<ArrowRightIcon width={14} />}
            customInput={customInput}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            showPopperArrow={false}
        />
    );
};

export default CustomDatePicker;
