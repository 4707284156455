import { microwave } from '@round/api';
import { ReactComponent as TiktokIcon } from 'assets/icons/platform/TikTok.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/platform/Instagram.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/platform/Youtube.svg';

export const MICROWAVE_PLATFORMS: microwave.Platform[] = ['tiktok', 'instagram', 'youtube'];

export const microwavePlatformLabels = {
    tiktok: 'TikTok',
    instagram: 'Instagram',
    youtube: 'YouTube',
};

export const getMicrowavePlatformLabel = (platform: microwave.Platform) => microwavePlatformLabels[platform];

type InfluencerPlatformUsernameKey = keyof Pick<
    microwave.MicrowaveInfluencer | microwave.AdminInfluencer,
    'instagram_user_username' | 'tiktok_user_unique_id' | 'youtube_channel_title'
>;

const InfluencerUsernameKeyMap: { [key in microwave.Platform]?: InfluencerPlatformUsernameKey } = {
    tiktok: 'tiktok_user_unique_id',
    instagram: 'instagram_user_username',
    youtube: 'youtube_channel_title',
};

export const getMicrowaveInfluencerPlatformUsername = (
    influencer: microwave.MicrowaveInfluencer | microwave.AdminInfluencer,
    platform: microwave.Platform
): string | null => {
    const key = InfluencerUsernameKeyMap[platform];
    if (!key) {
        return null;
    }

    return influencer[key];
};

type InfluencerPlatformPlatformIdKey = keyof Pick<
    microwave.MicrowaveInfluencer | microwave.AdminInfluencer,
    'instagram_user_username' | 'tiktok_user_unique_id' | 'youtube_channel_youtube_id'
>;

const InfluencerUsernamePlatformIdKeyMap: { [key in microwave.Platform]?: InfluencerPlatformPlatformIdKey } = {
    tiktok: 'tiktok_user_unique_id',
    instagram: 'instagram_user_username',
    youtube: 'youtube_channel_youtube_id',
};

export const getMicrowaveInfluencerPlatformId = (
    influencer: microwave.MicrowaveInfluencer | microwave.AdminInfluencer,
    platform: microwave.Platform
): string | null => {
    const key = InfluencerUsernamePlatformIdKeyMap[platform];
    if (!key) {
        return null;
    }

    return influencer[key];
};

const platformIcons: {
    [key in microwave.Platform]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
    tiktok: TiktokIcon,
    instagram: InstagramIcon,
    youtube: YoutubeIcon,
};

export const getPlatformIcon = (platform: microwave.Platform) => {
    return platformIcons[platform];
};
