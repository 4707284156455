import { refreshTokens, getTokensFromStorage } from './auth';
import { OrderingValues, PaginatedApiResponseData, Primitives } from './types';
import { buildApiUrl } from './utils';

type CallModifiers = {
    shouldBypassTokens?: boolean;
};

export const call = async (url: string, args?: RequestInit, modifiers?: CallModifiers) =>
    modifiers?.shouldBypassTokens ? fetch(buildApiUrl(url), args) : fetchWithToken(url, args);

export async function fetchAll<T>(url: string, requestInit?: RequestInit): Promise<T[]> {
    const response = await fetchWithToken(url, requestInit);

    if (!response.ok) {
        return [];
    }

    const body = (await response.json()) as PaginatedApiResponseData<T>;
    const results = body.results;

    if (body.next !== null) {
        const nextResults = await fetchAll<T>(body.next);
        return results.concat(nextResults);
    }

    return results;
}

export async function fetchWithToken(url: string, args?: RequestInit): Promise<Response> {
    const apiUrl = buildApiUrl(url);
    const response = await fetch(apiUrl, addAuthHeadersToRequestConfig(args));
    if (response.status === 401) {
        const retryResponse = await retryFetchAfterRefreshingTokens(apiUrl, args);
        return retryResponse.ok ? retryResponse : response;
    }

    return response;
}

async function retryFetchAfterRefreshingTokens(url: string, args?: RequestInit): Promise<Response> {
    await refreshTokens();
    const apiUrl = buildApiUrl(url);
    return fetch(apiUrl, addAuthHeadersToRequestConfig(args));
}

export function addAuthHeadersToRequestConfig(args?: RequestInit): RequestInit {
    const { access } = getTokensFromStorage();
    if (!access) {
        return { ...args };
    }

    return {
        ...args,
        ...{
            headers: {
                ...args?.headers,
                Authorization: `JWT ${access}`,
            },
        },
    };
}

/**
 * Encode an object as url query string parameters
 * - includes the leading "?" prefix
 * - example input — {key: "value", alpha: "beta"}
 * - example output — output "?key=value&alpha=beta"
 * - returns empty string when given an empty object
 */
export function encodeQueryString(params: Record<string, string | number | boolean>) {
    const keys = Object.keys(params);
    return keys.length
        ? '?' + keys.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&')
        : '';
}

/**
 * Encode an object as url query string parameters. Also strips undefined/null
 */
export function encodeUrlSearchParams(params: Record<string, Primitives | Primitives[]>) {
    // type guard stripping empty string, null or undefined
    const notEmptyValue = (param: Primitives): param is string | number | boolean => {
        return (
            (typeof param === 'string' && param.trim() !== '') ||
            (param !== undefined && param !== null && typeof param !== 'string')
        );
    };

    const keys = Object.keys(params);
    const strippedParams = keys.reduce<Record<string, string | number | boolean>>((strippedParams, key) => {
        const param = params[key];
        const value = Array.isArray(param) ? param.filter(notEmptyValue).join(',') : param;

        if (notEmptyValue(value)) {
            strippedParams[key] = value;
        }

        return strippedParams;
    }, {});

    return encodeQueryString(strippedParams);
}

export type OrderingParamsArray<T extends string> = OrderingValues<T>[];

export async function getFileFromResponse(response: Response) {
    const FILE_NAME_REGEX = /filename="(.*)"/i;
    const contentDisposition = response.headers.get('content-disposition');
    const fileName = contentDisposition?.match(FILE_NAME_REGEX)?.[1] || '';

    const buffer = await response.arrayBuffer();
    const responseContentType = response.headers.get('content-type') || undefined;
    return new File([buffer], fileName, { type: responseContentType });
}
