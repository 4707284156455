import styles from './Dropdown.module.css';
import { Popover } from '@round/ui-kit';
import React, { useLayoutEffect, useState } from 'react';
import cn from 'classnames';

type Props = {
    control: React.ReactNode;
    children: React.ReactNode;
    className?: string;
};

const Dropdown = ({ control, children, className }: Props) => {
    const [controlRef, setControlRef] = useState<HTMLDivElement | null>(null);
    const [dropdownRef, setDropdownRef] = useState<HTMLDivElement | null>(null);
    const [recalcDependency, setRecalcDependency] = useState<Array<number>>([]);

    useLayoutEffect(() => {
        if (!dropdownRef) {
            return;
        }
        const resizeObserver = new ResizeObserver(() => {
            setRecalcDependency([dropdownRef.clientWidth, dropdownRef.clientHeight]);
        });
        resizeObserver.observe(dropdownRef);
        return () => resizeObserver.disconnect();
    }, [dropdownRef]);

    return (
        <>
            <div ref={setControlRef}>{control}</div>
            <Popover
                anchorElement={controlRef}
                showOn="click"
                options={{
                    placement: 'bottom-end',
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 8] } },
                        { name: 'recalcDependency', options: recalcDependency },
                    ],
                }}
            >
                <div className={cn(styles.dropdown, className)} ref={setDropdownRef}>
                    {children}
                </div>
            </Popover>
        </>
    );
};

export default Dropdown;
