import { FieldHookConfig, useField, useFormikContext } from 'formik';
import cn from 'classnames';
import styles from './FormField.module.css';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { GenericDropdownOption } from 'App.types';
import { StylesConfig, mergeStyles } from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import { Label } from './Label/Label';
import { SelectProps } from '@round/ui-kit';
import { ReactComponent as ChevronDownFilledIcon } from 'assets/whitelabel/ChevronDownFilled.svg';
import Select from '../Select/Select';

type Props = {
    currencyFieldName: string;
    label: string;
    className?: string;
    hideCostInput?: boolean;
    currencySelectStyles?: StylesConfig;
    isCurrencyDisabled?: boolean;
    decimalsLimit?: number;
} & FieldHookConfig<number> &
    Pick<SelectProps<GenericDropdownOption<number>, false>, 'formatOptionLabel'>;

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        border: 'none',
        boxShadow: 'none',
        outline: 'none',
        minWidth: '65px',
        padding: '0.25rem',
        background: '#F3F4F5',
        ':hover': {
            border: 'none',
            boxShadow: 'none',
            outline: 'none',
        },
    }),
    valueContainer: (base) => ({
        ...base,
        fontSize: '0.75rem',
        color: '#000',
        fontWeight: 700,
        padding: 0,
    }),
    singleValue: (base) => ({
        ...base,
        minWidth: 'max-content',
    }),
    menu: (base) => ({
        ...base,
        minWidth: '7.5rem',
    }),
    indicatorsContainer: (base) => ({
        ...base,
        padding: 0,
    }),
};

const DropdownIndicator = () => <ChevronDownFilledIcon />;

const MoneyField = ({
    currencyFieldName,
    label,
    className,
    disabled,
    placeholder,
    formatOptionLabel,
    hideCostInput,
    currencySelectStyles,
    isCurrencyDisabled,
    decimalsLimit = 2,
    ...props
}: Props) => {
    const [{ onChange, ...field }, meta, helpers] = useField(props);
    const formik = useFormikContext<Record<string, any>>();
    const { currencies } = useNonNullContext(OptionsContext);

    const currencyOptions = currencies.map<GenericDropdownOption<number>>((c) => ({ value: c.id, label: c.name }));

    const hasError = !!meta.error && !!meta.touched;

    return (
        <div className={cn(styles.field, className)}>
            <Label htmlFor={props.name}>{label}</Label>

            <div
                className={cn(styles.input, styles.moneyInput, {
                    [styles.error]: hasError,
                    [styles.disabled]: disabled,
                })}
            >
                <Select
                    placeholder="..."
                    isSearchable={false}
                    isClearable={false}
                    isDisabled={isCurrencyDisabled}
                    styles={mergeStyles(selectStyles, currencySelectStyles ?? {})}
                    value={formik.values[currencyFieldName]}
                    onChange={(value) => {
                        formik.setFieldValue(currencyFieldName, value);
                        formik.setFieldTouched(currencyFieldName, true);
                    }}
                    options={currencyOptions}
                    formatOptionLabel={formatOptionLabel}
                    components={{
                        DropdownIndicator,
                    }}
                />

                {!hideCostInput && (
                    <CurrencyInput
                        {...field}
                        disabled={disabled}
                        onValueChange={(value) => {
                            helpers.setTouched(true);
                            formik.setFieldValue(props.name, value);
                        }}
                        placeholder={placeholder}
                        groupSeparator=","
                        decimalSeparator="."
                        allowDecimals
                        decimalsLimit={decimalsLimit}
                        className={styles.inputField}
                    />
                )}
            </div>

            <ErrorMessage name={props.name} />
        </div>
    );
};

export default MoneyField;
