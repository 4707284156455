import { AppServer, RouteHandlerContext } from '../../mirage';
import { belongsTo, Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { OptimisationMetric } from './optimisationMetric.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    optimisationMetric: Model.extend({
        channel: belongsTo('channel'),
    }) as ModelDefinition<OptimisationMetric>,
};

export const serializers = {
    optimisationMetric: makeSerializer<OptimisationMetric>(['channel']),
};

export const factories = {
    optimisationMetric: createFactory<OptimisationMetric>({
        name(index: number) {
            return `name ${index}`;
        },
        cost_per_cost_unit(index: number) {
            return `cost ${index}`;
        },
        afterCreate(metric, s) {
            const server: AppServer = s;
            if (!metric.channel) {
                metric.update({
                    channel: server.create('channel'),
                });
            }
        },
    }),
};

export function handleOptimisationMetricRequests(server: AppServer) {
    const getOptimisationMetricListUrl = '/api/advertising/viewsets/optimisation-metric/';
    server.get(getOptimisationMetricListUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('optimisationMetric'), {
            url: getOptimisationMetricListUrl,
            serialize: (resource) => this.serialize(resource, 'optimisationMetric'),
            queryParams: request.queryParams,
        });
    });
}
