import { ChangeEvent, CSSProperties, useRef, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { ReactComponent as CloseCircle } from 'assets/icons/CloseCircle.svg';
import styles from './ExpandableSearchInput.module.css';
import cn from 'classnames';

type Props = {
    value: string | undefined;
    onChange: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    style?: CSSProperties;
    placeholder?: string;
    debounceTimeout?: number;
    persistIfValuePresent?: boolean;
};

const ExpandableSearchInput = ({
    value = '',
    onChange,
    className,
    style,
    placeholder = 'Search',
    debounceTimeout = 700,
    persistIfValuePresent,
}: Props) => {
    const [isExpanded, setIsExpanded] = useState((value?.length ?? 0) > 0 && persistIfValuePresent);
    const input = useRef<HTMLInputElement>(null);

    const isClearable = input.current?.value.length && isExpanded;

    return (
        <div
            style={style}
            className={cn(styles.container, className, { [styles.focused]: isExpanded })}
            onClick={() => {
                if (!input.current) {
                    return;
                }

                input.current.focus();
                setIsExpanded(true);
            }}
        >
            <SearchIcon className={styles.icon} />
            <DebounceInput
                className={cn(styles.input, { [styles.isExpanded]: isExpanded })}
                inputRef={input}
                value={value}
                onChange={(e) => {
                    onChange(e.target.value, e);
                }}
                placeholder={placeholder}
                onBlur={(e) => {
                    if (e.target !== document.activeElement && (!persistIfValuePresent || !value?.length)) {
                        setIsExpanded(false);
                    }
                }}
                debounceTimeout={debounceTimeout}
            />
            <button
                className={cn(styles.clearButton, {
                    [styles.clearable]: isClearable,
                    [styles.expanded]: isExpanded,
                })}
                onMouseDown={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (!isClearable) {
                        return;
                    }

                    onChange('');
                }}
            >
                <CloseCircle />
            </button>
        </div>
    );
};

export default ExpandableSearchInput;
