import useUrlState from '../../../../../../../Hooks/useUrlState';
import { MobilePostGroupBar } from '../MobilePostGroupBar/MobilePostGroupBar';
import { Skeleton } from '@round/ui-kit';
import styles from './CreatorsTab.module.css';
import MobileInstagramTable, { MobileInstagramTableRow } from '../MobileInstagramTable/MobileInstagramTable';
import { useInstagramTableDataHelper } from '../../../../hooks/useInstagramTableDataHelper';
import { useCallback, useContext, useMemo, useState } from 'react';
import useNonNullContext from '../../../../../../../Hooks/useNonNullContext';
import { InstagramCreatorsContext } from '../../../../contexts/InstagramCreatorsContext/InstagramCreatorsContext';
import { InfluencerPostGroup, InstagramInfluencerUser, patchInstagramInfluencerUser } from '@round/api';
import { showNotification } from '../../../../../../../helpers';
import { InstagramInfluencerPost } from '../../../../types/Instagram.types';
import { setManualStatusOnInstagramInfluencerPost } from '../../../../InfluencerPlan.api';
import InfluencerPostStatusModal from '../../../InfluencerPostStatusModal/InfluencerPostStatusModal';
import ExpandBar from '../../../../components/ExpandBar/ExpandBar';
import { ProtectedByUserGroups } from '../../../../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import { InfluencerPlanContext } from 'Modules/Advertising/InfluencerPlan/contexts/InfluencerPlanContext';
import useInstagramInfluencerPlanAudios from 'Modules/Advertising/InfluencerPlan/contexts/InstagramCreatorsContext/useInstagramInfluencerPlanAudios';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import copy from 'copy-to-clipboard';
import { formatInstagramBriefDataToWhatsAppMarkdown } from '../../../copyToClipboard/copyToClipboard.helpers';

type UrlState = {
    expandedGroups: string;
    expandedBackupTables: string;
};

const InstagramCreatorsTab = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>();
    const [postToEditStatus, setPostToEditStatus] = useState<InstagramInfluencerPost | null>(null);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

    const expandedGroups = urlState.expandedGroups?.split(',').map((g) => Number(g)) ?? [];
    const setExpandedGroups = (group: number) => {
        if (expandedGroups.includes(group)) {
            setUrlState({ expandedGroups: expandedGroups.filter((g) => g !== group).join(',') });
            return;
        }
        setUrlState({ expandedGroups: expandedGroups.concat(group).join(',') });
    };

    const expandedBackupTables = urlState.expandedBackupTables?.split(',').map((g) => Number(g)) ?? [];
    const setExpandedBackupTables = (group: number) => {
        if (expandedBackupTables.includes(group)) {
            setUrlState({ expandedBackupTables: expandedBackupTables.filter((g) => g !== group).join(',') });
            return;
        }
        setUrlState({ expandedBackupTables: expandedBackupTables.concat(group).join(',') });
    };

    const {
        instagramInfluencerPostGroups,
        instagramUserImages,
        instagramInfluencerUsers,
        isInitCompleted,
        isPrefetchCompleted,
        updateInstagramInfluencerPost,
        setInstagramInfluencerUser,
        refreshInstagramInfluencerPost,
    } = useNonNullContext(InstagramCreatorsContext);

    const { influencerPlan } = useContext(InfluencerPlanContext);

    const {
        audios: planAudios,
        init: initPlanAudios,
        isInitialized: arePlanAudiosInitialized,
    } = useInstagramInfluencerPlanAudios();

    useAbortableEffect(
        (signal) => {
            if (!arePlanAudiosInitialized) {
                initPlanAudios({ signal });
            }
        },
        [arePlanAudiosInitialized, initPlanAudios]
    );

    const { sortedActiveInfluencerPosts, sortedBackupInfluencerPosts } = useInstagramTableDataHelper();

    const activeRows: MobileInstagramTableRow[] = useMemo(
        () =>
            sortedActiveInfluencerPosts.map((post) => {
                const influencer = instagramInfluencerUsers.find((influencer) => influencer.id === post.influencer_id);
                const profileImage = instagramUserImages.find(
                    (image) => image.user_id === (influencer?.user ?? post.instagram_user)
                );
                return {
                    post,
                    influencer: influencer ?? null,
                    profileImage: profileImage ?? null,
                };
            }),
        [sortedActiveInfluencerPosts, instagramInfluencerUsers, instagramUserImages]
    );

    const backupRows: MobileInstagramTableRow[] = useMemo(
        () =>
            sortedBackupInfluencerPosts.map((post) => {
                const influencer = instagramInfluencerUsers.find((influencer) => influencer.id === post.influencer_id);
                const profileImage = instagramUserImages.find(
                    (image) => image.user_id === (influencer?.user ?? post.instagram_user)
                );
                return {
                    post,
                    influencer: influencer ?? null,
                    profileImage: profileImage ?? null,
                };
            }),
        [sortedBackupInfluencerPosts, instagramInfluencerUsers, instagramUserImages]
    );

    const updateContactDetails = useCallback(
        async (influencer: InstagramInfluencerUser, contactDetails: string) => {
            try {
                const response = await patchInstagramInfluencerUser(influencer.id, { contact_details: contactDetails });
                if (response.status === 200) {
                    setInstagramInfluencerUser(response.data);
                    showNotification('Contact details updated', 'info');
                    return;
                }

                if (typeof response.data.contact_details === 'string') {
                    throw new Error(response.data.contact_details);
                }

                throw new Error('Could not update contact details');
            } catch (e) {
                const errorMessage = e instanceof Error ? e.message : 'Could not update contact details';
                showNotification(errorMessage, 'error');
                throw e;
            }
        },
        [setInstagramInfluencerUser]
    );

    const updateCost = useCallback(
        async (post: InstagramInfluencerPost, cost: string) => {
            try {
                await updateInstagramInfluencerPost(post.id, { cost });
                showNotification('Cost updated', 'info');
            } catch (e) {
                showNotification('Could not update cost', 'error');
                throw e;
            }
        },
        [updateInstagramInfluencerPost]
    );

    const updateNotes = useCallback(
        async (post: InstagramInfluencerPost, updatedNotes: string) => {
            try {
                await updateInstagramInfluencerPost(post.id, { notes: updatedNotes });
                showNotification('Notes updated', 'info');
            } catch (e) {
                showNotification('Could not update notes', 'error');
                throw e;
            }
        },
        [updateInstagramInfluencerPost]
    );

    const onEditStatusClick = useCallback(async (post: InstagramInfluencerPost) => {
        setPostToEditStatus(post);
        setIsStatusModalOpen(true);
    }, []);

    const updateStatus = useCallback(
        async (status: InstagramInfluencerPost['status'], draftExpectedBy: string | null) => {
            if (!postToEditStatus) {
                return;
            }
            try {
                await setManualStatusOnInstagramInfluencerPost({
                    status,
                    draft_expected_by: draftExpectedBy,
                    influencer_post_id: postToEditStatus.id,
                });
                refreshInstagramInfluencerPost(postToEditStatus.id);
                showNotification('Status updated', 'info');

                setIsStatusModalOpen(false);
                setPostToEditStatus(null);
            } catch {
                showNotification('Could not set status', 'error');
            }
        },
        [refreshInstagramInfluencerPost, postToEditStatus]
    );

    const copyBriefToClipboard = (group: InfluencerPostGroup) => {
        if (!influencerPlan) {
            return;
        }

        const formattedBriefData = formatInstagramBriefDataToWhatsAppMarkdown({
            release: influencerPlan.release,
            sound: planAudios[0],
            brief: group.brief,
            turnaround: group.brief_turnaround,
        });

        const success = copy(formattedBriefData, { format: 'text/plain' });

        if (success) {
            showNotification('Copied!', 'info');
        } else {
            showNotification('Could not copy', 'error');
        }
    };

    if (!isInitCompleted || !isPrefetchCompleted) {
        return (
            <>
                <Skeleton className={styles.skeleton} />
                <Skeleton className={styles.skeleton} />
                <Skeleton className={styles.skeleton} />
            </>
        );
    }

    if (!instagramInfluencerPostGroups.length) {
        return (
            <div className={styles.noGroupsMessage}>
                <p>No post groups found</p>
            </div>
        );
    }

    return (
        <>
            {instagramInfluencerPostGroups
                .sort((a, b) => a.ordering_index - b.ordering_index)
                .map((group) => {
                    const groupActiveRows = activeRows.filter((row) => row.post.group_id === group.id);
                    const groupBackupRows = backupRows.filter((row) => row.post.group_id === group.id);

                    return (
                        <div key={group.id}>
                            <MobilePostGroupBar
                                platform="instagram"
                                group={group}
                                items={sortedActiveInfluencerPosts.filter((post) => post.group_id === group.id)}
                                expandedGroups={expandedGroups}
                                checkGroup={setExpandedGroups}
                                copyBrief={copyBriefToClipboard}
                            />
                            {expandedGroups.includes(group.id) && (
                                <>
                                    <MobileInstagramTable
                                        data={groupActiveRows}
                                        updateContactDetails={updateContactDetails}
                                        updateCost={updateCost}
                                        updateNotes={updateNotes}
                                        onEditStatusClick={onEditStatusClick}
                                    />
                                    <ProtectedByUserGroups groups={['influencer_editor']}>
                                        <ExpandBar
                                            isOpen={expandedBackupTables.includes(group.id)}
                                            title={`${groupBackupRows.length} backup creator${
                                                groupBackupRows.length !== 1 ? 's' : ''
                                            }`}
                                            onExpandClicked={() => setExpandedBackupTables(group.id)}
                                        >
                                            <MobileInstagramTable
                                                data={groupBackupRows}
                                                updateContactDetails={updateContactDetails}
                                                updateCost={updateCost}
                                                updateNotes={updateNotes}
                                                onEditStatusClick={onEditStatusClick}
                                            />
                                        </ExpandBar>
                                    </ProtectedByUserGroups>
                                </>
                            )}
                        </div>
                    );
                })}
            <InfluencerPostStatusModal
                status={postToEditStatus?.status ?? null}
                draftExpectedBy={postToEditStatus?.draft_expected_by}
                isModalOpen={isStatusModalOpen}
                onClose={() => setIsStatusModalOpen(false)}
                onChange={updateStatus}
            />
        </>
    );
};

export default InstagramCreatorsTab;
