import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import styles from './DateCell.module.css';

type Props = {
    value: string | null;
    isLoading?: boolean;
};

const DateCell = ({ value, isLoading }: Props) => {
    if (isLoading) {
        return <Skeleton />;
    }

    if (!value) {
        return <>-</>;
    }

    return <div className={styles.container}>{moment(value).format('Do MMM [at] HH:mm')}</div>;
};

export default DateCell;
