import React, { useCallback, useEffect, useState } from 'react';
import { FacebookTargeting } from '../../../../../../App.types';
import { CustomAudienceOption } from '../../../types/Facebook.types';
import useAbortableEffect from '../../../../../../Hooks/useAbortableEffect';
import FacebookTargetingModalForm, {
    TargetingFormProps,
} from './components/FacebookTargetingModalForm/FacebookTargetingModalForm';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import Button from '../../../../../../ui/Buttons/Button/Button';
import { showNotification } from '../../../../../../helpers';
import styles from './FacebookTargetingModal.module.css';
import { mapFacebookTargetingLocationsToOptions, mapSpecsToOptions } from '../../../helpers/Facebook.helpers';
import { fetchDeleteFacebookTargeting } from '../../../api/MediaPlanTargetings.api';

type FacebookTargetingModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    targeting: FacebookTargeting;
    onTargetingUpdated: (targeting: FacebookTargeting) => void;
    onTargetingDeleted: (targeting: FacebookTargeting) => void;
};

const FacebookTargetingModal = ({
    isModalOpen,
    closeModal,
    targeting,
    onTargetingDeleted,
    onTargetingUpdated,
}: FacebookTargetingModalProps) => {
    const [loading, setLoading] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const mapToTargetingFormProps = useCallback(
        (targeting: FacebookTargeting) => ({
            name: targeting.name,
            minAge: targeting.min_age,
            maxAge: targeting.max_age,
            location: targeting.location,
            isMale: targeting.is_male,
            isFemale: targeting.is_female,
            remarketingAudience: targeting.remarketing_audience,
            keywords: targeting.keywords,
            devicePlatforms: targeting.device_platforms,
            publisherPlatforms: targeting.publisher_platforms,
            facebookPositions: targeting.facebook_positions ?? [],
            instagramPositions: targeting.instagram_positions ?? [],
            messengerPositions: targeting.messenger_positions ?? [],
            audienceNetworkPositions: targeting.audience_network_positions ?? [],
            geoLocations: [],
            detailedTargeting: {
                include: Array.isArray(targeting.flexible_spec)
                    ? mapSpecsToOptions(targeting.flexible_spec.length > 0 ? targeting.flexible_spec[0] : undefined)
                    : [],
                exclude: mapSpecsToOptions(targeting.exclusions),
                narrow: Array.isArray(targeting.flexible_spec)
                    ? mapSpecsToOptions(targeting.flexible_spec.length > 1 ? targeting.flexible_spec[1] : undefined)
                    : [],
            },
            notes: targeting.notes ?? '',
            customAudiences: {
                included: targeting.custom_audiences.map<CustomAudienceOption>((audience) => ({
                    value: audience.id,
                    description: audience.description,
                    subtype: audience.subtype,
                    label: audience.name,
                })),
                excluded: targeting.excluded_custom_audiences.map<CustomAudienceOption>((audience) => ({
                    value: audience.id,
                    description: audience.description,
                    subtype: audience.subtype,
                    label: audience.name,
                })),
            },
            estimatedReach: targeting.estimated_reach,
        }),
        []
    );

    const [initialValues, setInitialValues] = useState<TargetingFormProps>(mapToTargetingFormProps(targeting));

    const onConfirmDelete = async () => {
        try {
            setDeleteLoading(true);
            await fetchDeleteFacebookTargeting(targeting.id);
            setDeleteLoading(false);
            setShowConfirmDelete(false);
            closeModal();
            onTargetingDeleted(targeting);
            showNotification(`Targeting "${targeting.name}" has been deleted`, 'info');
        } catch {
            showNotification("Couldn't perform delete operation", 'error');
            setDeleteLoading(false);
            setShowConfirmDelete(false);
        }
    };

    useEffect(() => {
        setInitialValues(mapToTargetingFormProps(targeting));
    }, [mapToTargetingFormProps, targeting]);

    useAbortableEffect(
        (signal) => {
            const mapLocations = async () => {
                const included = await mapFacebookTargetingLocationsToOptions(targeting.geo_locations, { signal });
                const excluded = await mapFacebookTargetingLocationsToOptions(targeting.excluded_geo_locations, {
                    signal,
                });

                const geoLocations = [
                    ...included.map((l) => ({ ...l, isIncluded: true })),
                    ...excluded.map((l) => ({ ...l, isIncluded: false })),
                ].sort((a, b) => {
                    if (a.label < b.label) return -1;
                    if (a.label > b.label) return 1;
                    return 0;
                });
                setInitialValues((initialValues) => ({ ...initialValues, geoLocations }));
            };

            setLoading(true);
            mapLocations().then(() => setLoading(false));
        },
        [targeting.excluded_geo_locations, targeting.geo_locations]
    );

    return (
        <>
            <Modal
                className={styles.deleteTargeting}
                closeOnOverlayClick
                isOpen={showConfirmDelete}
                onClose={() => setShowConfirmDelete(false)}
            >
                <ModalTitle>Delete "{targeting.name}" targeting?</ModalTitle>
                <ModalContent>
                    <p>You're going to delete "{targeting.name}" targeting. This operation cannot be undone</p>
                </ModalContent>
                <ModalFooter align="center">
                    <Button type="bordered" color="negative" loading={deleteLoading} onClick={onConfirmDelete}>
                        Delete targeting
                    </Button>
                </ModalFooter>
            </Modal>

            <FacebookTargetingModalForm
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                onSubmit={onTargetingUpdated}
                initialValues={initialValues}
                loading={loading}
                onDelete={() => setShowConfirmDelete(true)}
                targeting={targeting}
            />
        </>
    );
};

export default FacebookTargetingModal;
