import { Dispatch, ReactNode, useReducer } from 'react';
import reducer, { State, Actions, initialState } from './reducer';
import { createSelectableContext } from 'Hooks/useNonNullContextSelector';

export const SongDataContext = createSelectableContext<[State, Dispatch<Actions>] | null>(null);
type Props = {
    children?: ReactNode | undefined;
};

export const SongDataProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return <SongDataContext.Provider value={[state, dispatch]}>{children}</SongDataContext.Provider>;
};
