import { fetchWithToken } from '../../helpers';
import { ApiResponse, ApiResponseError, ForbiddenResponse, NotFoundResponse } from '../../types';
import { AggregatedAudioStats } from './aggregatedAudioStats.types';

export type AggregatedAudioStatsPostData = {
    song_id: number;
    platform: string;
};

type AggregatedAudioStatsResponseError = ApiResponse<ApiResponseError<{ song_id?: string; platform?: string }>, 400>;

type AggregatedAudioStatsResponse =
    | ApiResponse<AggregatedAudioStats, 200>
    | AggregatedAudioStatsResponseError
    | ForbiddenResponse
    | NotFoundResponse;

export async function postGetAggregatedAudioStats(
    data: AggregatedAudioStatsPostData,
    signal?: AbortSignal
): Promise<AggregatedAudioStatsResponse> {
    const response = await fetchWithToken(`/api/creatorbase/aggregated-audio-stats/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        signal,
    });

    if (response.status === 400 || response.status === 403 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get audio stats');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
