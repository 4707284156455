import { useState } from 'react';
import { creatorbase } from '@round/api';
import { DataState } from 'App.types';
import useAbortableEffect from 'Hooks/useAbortableEffect';

export default function useTeam(teamId: number | undefined) {
    const [teamData, setTeamData] = useState<DataState<creatorbase.Team | null>>({
        data: null,
        status: 'idle',
        error: null,
    });

    useAbortableEffect(
        (signal) => {
            if (typeof teamId !== 'number') {
                return;
            }

            creatorbase
                .getTeam(teamId, { signal })
                .then((response) => {
                    if (response.status === 200) {
                        setTeamData({ status: 'success', data: response.data, error: null });
                        return;
                    }

                    setTeamData({ status: 'error', data: null, error: response.data.detail });
                })
                .catch((e) => {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    setTeamData({ status: 'error', data: null, error: 'Could not load team' });
                });
        },
        [teamId]
    );

    return teamData;
}
