import { IconProps } from './Icon.types';

const MusicNoteIcon = ({
    width,
    height,
    title = 'Music note',
    onClick,
    className,
    ariaRole = 'img',
    ariaLabel,
    stroke,
}: IconProps) => (
    <svg
        className={className}
        onClick={onClick}
        width={width}
        height={height}
        aria-label={ariaLabel}
        role={ariaRole}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <path
            d="M5.31315 14.6663C6.78591 14.6663 7.97982 13.4724 7.97982 11.9997C7.97982 10.5269 6.78591 9.33301 5.31315 9.33301C3.84039 9.33301 2.64648 10.5269 2.64648 11.9997C2.64648 13.4724 3.84039 14.6663 5.31315 14.6663Z"
            stroke={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.98047 12.0003V2.66699"
            stroke={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.7397 1.40634L12.6864 2.38634C13.3997 2.62634 13.9864 3.43301 13.9864 4.18634V4.96634C13.9864 5.98634 13.1997 6.55301 12.233 6.23301L9.28637 5.25301C8.57304 5.01301 7.98637 4.20634 7.98637 3.45301V2.66634C7.9797 1.65301 8.77304 1.07967 9.7397 1.40634Z"
            stroke={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default MusicNoteIcon;
