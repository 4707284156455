import { FormikProvider, useFormik } from 'formik';
import ReportForm, { FormValues, validationSchema } from '../components/ReportForm/ReportForm';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import styles from './EditReportModal.module.css';
import Button from 'ui-new/whitelabel/Button/Button';
import { creatorbase } from '@round/api';
import useTeam from '../../Team/hooks/useTeam';
import { useCurrencies } from 'contexts/OptionsContext/useCurrencies';
import { showNotification } from '../../../../helpers';
import { mapApiErrorsToFormikErrors } from '../../../../utility/utility';
import { useUsers } from '../../User/hooks/useUsers';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    report: creatorbase.Report | null;
    updateReport: typeof creatorbase.patchReport;
};

export const EditReportModal = ({ isOpen, onClose, report, updateReport }: Props) => {
    const currency = useCurrencies().options.find((c) => c.value === report?.currency_id);
    const { data: team } = useTeam(report?.team_id);
    const {
        data: assigneesData,
        status: assigneesStatus,
        fetchData: fetchAssigneesData,
        reset: resetAssignees,
    } = useUsers();
    const areAssigneesInitialized = assigneesStatus === 'success' || assigneesStatus === 'error';

    useAbortableEffect(
        (signal) => {
            if (!areAssigneesInitialized && report?.assignee_ids.length && isOpen) {
                fetchAssigneesData(
                    { id: report?.assignee_ids.toString(), page_size: report?.assignee_ids.length },
                    { signal }
                ).catch(() => {});
            }
        },
        [areAssigneesInitialized, fetchAssigneesData, isOpen, report?.assignee_ids]
    );

    const formik = useFormik<FormValues>({
        initialValues: {
            name: report?.name || '',
            description: report?.description || '',
            team: team ? { value: team.id, label: team.name } : null,
            currency: currency ?? null,
            assignees: assigneesData?.results.map((a) => ({ value: a.id, label: a.name })) ?? [],
        },
        validationSchema,
        onSubmit: async (values, helpers) => {
            if (!report?.id) {
                return;
            }

            try {
                helpers.setSubmitting(true);
                const response = await updateReport(report.id, {
                    name: values.name,
                    description: values.description,
                    team_id: values.team?.value!,
                    currency_id: values.currency?.value!,
                    assignee_ids: values.assignees?.map((a) => a.value) ?? [],
                });

                if (response.status === 200) {
                    showNotification('Updated', 'info');
                    helpers.resetForm();
                    onClose();
                    resetAssignees();
                    return;
                }

                if (response.status === 400) {
                    helpers.setErrors(
                        mapApiErrorsToFormikErrors({
                            name: response.data.name,
                            description: response.data.description,
                            currency: response.data.currency_id,
                            team: response.data.team_id,
                        })
                    );
                    return;
                }

                showNotification('Could not update report', 'error');
            } catch {
                showNotification('Could not update report', 'error');
            }
        },
        enableReinitialize: true,
    });

    const handleClose = () => {
        onClose();
        formik.resetForm();
        resetAssignees();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} closeOnEscape closeOnOverlayClick className={styles.modal}>
            <Modal.Header>
                <Modal.Title>Edit Report</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FormikProvider value={formik}>
                    <ReportForm disabledFields={{ team: !!report?.project_ids.length }} />
                </FormikProvider>
            </Modal.Body>

            <Modal.Actions>
                <Button
                    appearance="primary"
                    isLoading={formik.isSubmitting}
                    disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                    onClick={formik.submitForm}
                >
                    Update
                </Button>

                <Button appearance="ghost" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default EditReportModal;
