import { GetPostPaymentsParams } from '@round/api';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import uniq from 'lodash/uniq';
import useInfluencerPlans from 'Modules/Advertising/InfluencerPlan/hooks/useInfluencerPlans';
import useInstagramInfluencerPosts from 'Modules/Advertising/InfluencerPlan/hooks/useInstagramInfluencerPosts';
import useTiktokInfluencerPosts from 'Modules/Advertising/InfluencerPlan/hooks/useTiktokInfluencerPosts';
import { useYoutubeInfluencerPosts } from 'Modules/Advertising/InfluencerPlan/hooks/useYoutubeInfluencerPosts';
import useTiktokUsers from 'Modules/Advertising/Microwave/MicrowaveInfluencers/CreateMicrowaveInfluencer/useTiktokUsers';
import useInstagramInfluencerUsers from 'Modules/Instagram/hooks/useInstagramInfluencerUsers';
import useInstagramUserImages from 'Modules/Instagram/hooks/useInstagramUserImages';
import { useInstagramUsers } from 'Modules/Instagram/hooks/useInstagramUsers';
import { useCallback, useMemo, useState } from 'react';
import { isNumber } from 'utility/utility';
import { PastPaymentsTableRow } from './PastPaymentsTable/PastPaymentsTable';
import usePostPayments from './usePostPayments';

type Params = GetPostPaymentsParams;
type Status = 'not-initialized' | 'loading' | 'initialized' | 'error';

export type UseOutstandingPaymentsReturn = ReturnType<typeof usePastPayments>;

export default function usePastPayments() {
    const [status, setStatus] = useState<Status>('not-initialized');

    const { fetchData: fetchPaymentsData, ...postPayments } = usePostPayments();
    const { fetchData: fetchInfluencerPlansData, ...influencerPlans } = useInfluencerPlans();
    const { fetchData: fetchTiktokInfluencerPostsData, ...tiktokInfluencerPosts } = useTiktokInfluencerPosts();
    const { fetchData: fetchTiktokUsersData, ...tiktokUsers } = useTiktokUsers({});
    const { fetchData: fetchInstagramInfluencerPostsData, ...instagramInfluencerPosts } = useInstagramInfluencerPosts();
    const { fetchData: fetchInstagramInfluencerUsersData, ...instagramInfluencerUsers } = useInstagramInfluencerUsers();
    const { fetchData: fetchInstagramUsersData, ...instagramUsers } = useInstagramUsers();
    const { fetchData: fetchInstagramUserImages, ...instagramUserImages } = useInstagramUserImages();
    const { fetchData: youtubeInfluencerPostsData, ...youtubeInfluencerPosts } = useYoutubeInfluencerPosts();

    const fetchData = useCallback(
        async (params: Params, requestInit?: RequestInit) => {
            try {
                setStatus('loading');
                const paymentRequestsResponse = await fetchPaymentsData(params, requestInit);
                if (paymentRequestsResponse.status === 404) {
                    setStatus('error');
                    return paymentRequestsResponse;
                }

                if (!paymentRequestsResponse.data.results.length) {
                    setStatus('initialized');
                }
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setStatus('error');
                throw e;
            }
        },
        [fetchPaymentsData]
    );

    const fetchTiktokPosts = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                return [];
            }

            return fetchTiktokInfluencerPostsData({ id: ids.toString(), page_size: ids.length }, requestInit).then(
                (response) => response.data.results
            );
        },
        [fetchTiktokInfluencerPostsData]
    );

    const fetchTiktokUsers = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                return [];
            }

            return fetchTiktokUsersData({ id: ids.toString(), page_size: ids.length }, requestInit).then((response) => {
                if (!response || response.status !== 200) {
                    return [];
                }

                return response.data.results;
            });
        },
        [fetchTiktokUsersData]
    );

    const fetchInstagramPosts = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                return [];
            }

            return fetchInstagramInfluencerPostsData({ id: ids.toString(), page_size: ids.length }, requestInit);
        },
        [fetchInstagramInfluencerPostsData]
    );

    const fetchInstagramInfluencerUsers = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                return [];
            }

            return fetchInstagramInfluencerUsersData({ id: ids.toString(), page_size: ids.length }, requestInit);
        },
        [fetchInstagramInfluencerUsersData]
    );

    const fetchInstagramUsers = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                return [];
            }

            return fetchInstagramUsersData({ id: ids.toString(), page_size: ids.length }, requestInit);
        },
        [fetchInstagramUsersData]
    );

    const fetchInstagramImages = useCallback(
        async (userIds: number[], requestInit?: RequestInit) => {
            if (!userIds.length) {
                return [];
            }

            return fetchInstagramUserImages(userIds, requestInit).then((response) => response.data);
        },
        [fetchInstagramUserImages]
    );

    const fetchYoutubePosts = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                return [];
            }

            return youtubeInfluencerPostsData({ id: ids.toString(), page_size: ids.length }, requestInit);
        },
        [youtubeInfluencerPostsData]
    );

    const fetchPlans = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                return [];
            }

            return fetchInfluencerPlansData({ id: ids.toString(), page_size: ids.length }, requestInit).then(
                (response) => {
                    if (response.status !== 200) {
                        return [];
                    }

                    return response.data.results;
                }
            );
        },
        [fetchInfluencerPlansData]
    );

    useAbortableEffect(
        (signal) => {
            async function fetchData() {
                try {
                    const existingTiktokPostIds = tiktokInfluencerPosts.posts.map((p) => p.id);
                    const tiktokPostsToFetchIds = uniq(
                        postPayments.data?.results
                            .map((req) => req.tiktok_influencer_post_id)
                            .filter(isNumber)
                            .filter((id) => !existingTiktokPostIds.includes(id)) ?? []
                    );

                    const existingInstagramPostIds = instagramInfluencerPosts.data?.data.map((p) => p.id);
                    const instagramPostsToFetchIds = uniq(
                        postPayments.data?.results
                            .map((req) => req.instagram_influencer_post_id)
                            .filter(isNumber)
                            .filter((id) => !existingInstagramPostIds?.includes(id)) ?? []
                    );

                    const existingInstagramInfluencerUserIds = instagramInfluencerUsers.data?.influencerUsers.map(
                        (u) => u.id
                    );
                    const instagramInfluencerUsersToFetchIds = uniq(
                        postPayments.data?.results
                            .map((p) => p.instagram_influencer_user_id)
                            .filter(isNumber)
                            .filter((id) => !existingInstagramInfluencerUserIds?.includes(id)) ?? []
                    );

                    const existingYoutubePostIds = youtubeInfluencerPosts.data?.map((p) => p.id) ?? [];
                    const youtubePostsToFetchIds = uniq(
                        postPayments.data?.results
                            .map((req) => req.youtube_influencer_post_id)
                            .filter(isNumber)
                            .filter((id) => !existingYoutubePostIds.includes(id)) ?? []
                    );

                    await Promise.allSettled([
                        fetchTiktokPosts(tiktokPostsToFetchIds, { signal }),
                        fetchInstagramPosts(instagramPostsToFetchIds, { signal }),
                        fetchInstagramInfluencerUsers(instagramInfluencerUsersToFetchIds, { signal }),
                        fetchYoutubePosts(youtubePostsToFetchIds, { signal }),
                    ]);
                } catch {
                    // no-op
                }
            }

            if (postPayments.status === 'initialized') {
                fetchData();
            }
        },
        [
            fetchInstagramInfluencerUsers,
            fetchInstagramPosts,
            fetchTiktokPosts,
            instagramInfluencerPosts.data?.data,
            instagramInfluencerUsers.data?.influencerUsers,
            postPayments.data?.results,
            postPayments.status,
            tiktokInfluencerPosts.posts,
            youtubeInfluencerPosts.data,
            fetchYoutubePosts,
        ]
    );

    useAbortableEffect(
        (signal) => {
            async function fetchData() {
                const existingPlanIds = influencerPlans.data?.results.map((p) => p.id) ?? [];
                const plansToFetchIds = uniq(
                    [
                        ...tiktokInfluencerPosts.posts,
                        ...(instagramInfluencerPosts.data?.data ?? []),
                        ...(youtubeInfluencerPosts.data ?? []),
                    ]
                        .map((post) => post.plan_id)
                        .filter(isNumber)
                        .filter((id) => !existingPlanIds.includes(id)) ?? []
                );

                const existingUserIds = tiktokUsers.users.map((u) => u.id);
                const usersToFetchIds = uniq(
                    tiktokInfluencerPosts.posts
                        .map((p) => p.tiktok_user)
                        .filter(isNumber)
                        .filter((id) => !existingUserIds.includes(id))
                );

                const existingInstagramUserIds = instagramUsers.state.data?.users.map((u) => u.id);
                const instagramUsersToFetchIds = uniq(
                    instagramInfluencerUsers.data?.influencerUsers
                        .map((u) => u.user)
                        .filter(isNumber)
                        .filter((id) => !existingInstagramUserIds?.includes(id)) ?? []
                );

                const existingInstagramImageUserIds = instagramUserImages.data?.images.map((image) => image.user_id);
                const instagramImageUserIdsToFetch = uniq(
                    instagramInfluencerUsers.data?.influencerUsers
                        .map((u) => u.user)
                        .filter(isNumber)
                        .filter((id) => !existingInstagramImageUserIds?.includes(id)) ?? []
                );

                try {
                    await Promise.all([
                        fetchTiktokUsers(usersToFetchIds, { signal }),
                        fetchPlans(plansToFetchIds, { signal }),
                        fetchInstagramUsers(instagramUsersToFetchIds, { signal }),
                        fetchInstagramImages(instagramImageUserIdsToFetch, { signal }),
                    ]);

                    setStatus('initialized');
                } catch {
                    // no-op
                }
            }

            if (
                postPayments.status === 'initialized' &&
                (tiktokInfluencerPosts.isInitialized ||
                    instagramInfluencerPosts.status === 'success' ||
                    youtubeInfluencerPosts.status === 'success')
            ) {
                fetchData();
            }
        },
        [
            fetchInstagramImages,
            fetchInstagramUsers,
            fetchPlans,
            fetchTiktokUsers,
            influencerPlans.data?.results,
            instagramInfluencerPosts.status,
            instagramInfluencerUsers.data?.influencerUsers,
            instagramUserImages.data?.images,
            instagramUsers.state.data?.users,
            postPayments.status,
            tiktokInfluencerPosts.isInitialized,
            tiktokInfluencerPosts.posts,
            tiktokUsers.users,
            youtubeInfluencerPosts.status,
            youtubeInfluencerPosts.data,
            instagramInfluencerPosts.data?.data,
        ]
    );

    const reset = useCallback(() => {
        setStatus('not-initialized');
        postPayments.reset();
    }, [postPayments]);

    const rows = useMemo<PastPaymentsTableRow[]>(
        () =>
            postPayments.data?.results.map((payment) => {
                const tiktokInfluencerPost = tiktokInfluencerPosts.posts.find(
                    (p) => p.id === payment.tiktok_influencer_post_id
                );

                const tiktokUser = tiktokUsers.users.find((u) => u.id === tiktokInfluencerPost?.tiktok_user);

                const instagramInfluencerPost = instagramInfluencerPosts.data?.data.find(
                    (p) => p.id === payment.instagram_influencer_post_id
                );

                const youtubeInfluencerPost = youtubeInfluencerPosts.data?.find(
                    (p) => p.id === payment.youtube_influencer_post_id
                );

                const planId =
                    tiktokInfluencerPost?.plan_id || instagramInfluencerPost?.plan_id || youtubeInfluencerPost?.plan_id;
                const plan = influencerPlans.data?.results.find((p) => p.id === planId);

                const instagramInfluencerUser = instagramInfluencerUsers.data?.influencerUsers.find(
                    (u) => u.id === payment.instagram_influencer_user_id
                );
                const instagramUser = instagramUsers.state.data?.users.find(
                    (u) => u.id === instagramInfluencerUser?.user
                );

                const instagramUserImage = instagramUserImages.data?.images.find(
                    (image) => image.user_id === instagramInfluencerUser?.user
                );

                return {
                    ...payment,
                    tiktokInfluencerPost,
                    plan,
                    tiktokUser,
                    instagramInfluencerPost,
                    instagramUser,
                    instagramUserImage,
                    youtubeInfluencerPost,
                };
            }) ?? [],
        [
            influencerPlans.data?.results,
            instagramInfluencerPosts.data?.data,
            instagramInfluencerUsers.data?.influencerUsers,
            instagramUserImages.data?.images,
            instagramUsers.state.data?.users,
            postPayments.data?.results,
            tiktokInfluencerPosts.posts,
            tiktokUsers.users,
            youtubeInfluencerPosts.data,
        ]
    );

    const data = postPayments.data
        ? {
              rows,
              count: postPayments.data.count,
          }
        : null;

    return {
        status,
        data,
        error: postPayments.error,
        fetchData,
        reset,
    };
}
