import React from 'react';
import { default as ReactCalendar } from 'react-calendar';
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/ArrowRight.svg';
type ConditionalRangeProps<IsRange extends boolean> = IsRange extends true
    ? {
          selectRange: IsRange;
          onChange: (value: [Date] | [Date, Date]) => void;
          value: [Date] | [Date, Date] | null | undefined;
      }
    : {
          selectRange?: IsRange;
          onChange: (value: Date) => void;
          value: Date | null | undefined;
      };
export type DatePickerProps<IsRange extends boolean> = ConditionalRangeProps<IsRange> & {
    className?: string;
};
const DatePicker = <IsRange extends boolean>({
    value,
    onChange,
    selectRange = false,
    className,
}: DatePickerProps<IsRange>) => {
    return (
        <ReactCalendar
            locale="en-GB"
            maxDetail="month"
            prevLabel={<ArrowLeft />}
            nextLabel={<ArrowRight />}
            prev2Label={null}
            next2Label={null}
            value={value}
            onChange={onChange}
            selectRange={selectRange}
            className={className}
        />
    );
};
export default DatePicker;
