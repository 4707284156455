import React, { PropsWithChildren } from 'react';
import { default as SkeletonComponent, SkeletonProps } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const wrapper = ({ children }: PropsWithChildren) => <span data-test-id="skeleton">{children}</span>;

const Skeleton = ({ containerTestId, ...props }: SkeletonProps) => {
    return <SkeletonComponent wrapper={wrapper} {...props} />;
};

export default Skeleton;
