import React from 'react';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import Payments from '../../../Modules/Advertising/Microwave/Payments/Payments';
import styles from './MicrowavePayments.module.css';

const MicrowavePayments = () => (
    <>
        <NavBar />
        <div className={styles.container}>
            <Payments />
        </div>
    </>
);

export default MicrowavePayments;
