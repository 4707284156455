import React, { useCallback } from 'react';
import { Column } from 'react-table';
import { StylesConfig } from 'react-select';
import cn from 'classnames';
import { GenericDropdownOption } from '../../App.types';
import Table, { PaginationToolbarProps, TableProps } from '../NewTable/Table';
import LeftArrowIcon from '../../SharedComponents/svg/Icons/LeftArrowIcon';
import RightArrowIcon from '../../SharedComponents/svg/Icons/RightArrowIcon';
import { OrderByParam } from '../../Hooks/useReactTableSortToOrderBy';
import Select from '../DataEntry/Select/Select';

import styles from './PaginationTable.module.css';
import { defaultPageSizeOptions } from 'utility/constants';

const pageSizeSelectStyles: StylesConfig = {
    valueContainer: (base) => ({
        ...base,
        minWidth: '2rem',
        fontWeight: 500,
        fontSize: '0.75rem',
        '@media only screen and min-width: 768px)': {
            fontSize: 'unset',
        },
    }),
    menu: (base) => ({
        ...base,
        fontSize: '0.75rem',
        fontWeight: 500,
    }),
};

export type PaginationTableProps<T extends object> = {
    loading: boolean;
    noDataLabel?: string;
    data: T[];
    columns: Column<T>[];
    count: number;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (size: number | undefined) => void;
    pageSizeOptions?: GenericDropdownOption<number>[];
    manualPagination?: boolean;
    disableSortBy?: boolean;
    orderBy?: OrderByParam<T>;
    onOrderByChange?: (param: OrderByParam<T>) => void;
    manualSortBy?: boolean;
    autoResetPage?: boolean;
    tbodyClassName?: string;
    theadClassName?: string;
    tableClassName?: string;
    renderFooter?: (() => React.ReactNode) | null;
    renderPaginationToolbar?: (props: PaginationToolbarProps) => React.ReactNode;
} & Pick<
    TableProps<T>,
    'onPaginationChange' | 'renderSortingIcons' | 'onToggleSortBy' | 'onRowClick' | 'onContextMenu' | 'renderRow'
>;

const PaginationTable = <T extends object>({
    loading,
    noDataLabel,
    data,
    columns,
    count,
    page,
    setPage,
    pageSize,
    setPageSize,
    pageSizeOptions = defaultPageSizeOptions,
    onRowClick,
    manualPagination = true,
    disableSortBy = true,
    autoResetPage,
    onOrderByChange,
    orderBy,
    manualSortBy,
    tbodyClassName,
    theadClassName,
    tableClassName,
    onPaginationChange,
    renderSortingIcons,
    onToggleSortBy,
    renderRow,
    renderFooter,
    renderPaginationToolbar,
}: PaginationTableProps<T>) => {
    const defaultRenderPaginationToolbar = useCallback(
        ({
            pageSize: tablePageSize,
            setPageSize: tableSetPageSize,
            page: tablePage,
            setPage: tableSetPage,
            count,
        }: PaginationToolbarProps) => {
            const pageCount = Math.ceil(count / tablePageSize);
            const hasNextPage = tablePage < pageCount;
            const hasPrevPage = tablePage > 1;

            return (
                <div className={styles.paginationToolbar}>
                    <div className={styles.pageSizeSelectContainer} data-test-id="page-size-select-container">
                        <Select
                            isSearchable={false}
                            isClearable={false}
                            styles={pageSizeSelectStyles}
                            options={pageSizeOptions}
                            value={pageSizeOptions.find((o) => o.value === tablePageSize)}
                            onChange={(pageSizeOption) => {
                                if (!manualPagination) {
                                    tableSetPageSize(pageSizeOption?.value ?? 10);
                                }

                                setPageSize(pageSizeOption?.value);
                            }}
                            menuPosition="fixed"
                        />
                        <label className={styles.paginationLabel}>Records per page</label>
                    </div>

                    <div className={styles.paginationContainer}>
                        {pageCount > 0 && (
                            <span className={styles.paginationLabel}>
                                Page {tablePage} of {pageCount}
                            </span>
                        )}

                        <div className={styles.paginationButtonsContainer}>
                            <LeftArrowIcon
                                ariaRole="button"
                                ariaLabel="Go to previous page"
                                className={cn(styles.paginationIcon, {
                                    [styles.paginationIconDisabled]: !hasPrevPage || loading,
                                })}
                                onClick={() => {
                                    if (!hasPrevPage) {
                                        return;
                                    }
                                    tableSetPage(tablePage - 1);
                                }}
                            />

                            <RightArrowIcon
                                ariaRole="button"
                                ariaLabel="Go to next page"
                                className={cn(styles.paginationIcon, {
                                    [styles.paginationIconDisabled]: !hasNextPage || loading,
                                })}
                                onClick={() => {
                                    if (!hasNextPage) {
                                        return;
                                    }
                                    tableSetPage(tablePage + 1);
                                }}
                            />
                        </div>
                    </div>
                </div>
            );
        },
        [loading, manualPagination, pageSizeOptions, setPageSize]
    );

    // skeletons are rendered in table cells,
    // so we need some rows to display skeletons on initial loading
    const initialLoading = !data.length && loading;
    const emptyRows = new Array(pageSize || 0).fill({}) as T[];
    const rows = initialLoading ? emptyRows : data;

    return (
        <Table
            disableSortBy={disableSortBy}
            manualSortBy={manualSortBy}
            manualPagination={manualPagination}
            data={rows}
            columns={columns}
            noDataLabel={noDataLabel}
            count={count}
            pageIndex={page - 1}
            pageSize={pageSize}
            onPageIndexChange={(pageIndex) => setPage(pageIndex + 1)}
            autoResetPage={autoResetPage}
            tableClassName={tableClassName}
            theadClassName={cn(styles.thead, theadClassName)}
            tbodyClassName={cn(styles.tbody, tbodyClassName)}
            renderFooter={renderFooter}
            onRowClick={onRowClick}
            orderBy={orderBy}
            onOrderByChange={onOrderByChange}
            onPaginationChange={onPaginationChange}
            renderSortingIcons={renderSortingIcons}
            onToggleSortBy={onToggleSortBy}
            renderRow={renderRow}
            renderPaginationToolbar={
                typeof renderPaginationToolbar === 'function' ? renderPaginationToolbar : defaultRenderPaginationToolbar
            }
        />
    );
};

export default PaginationTable;
