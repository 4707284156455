import { creatorbase } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

export type State = {
    [key in creatorbase.Platform]: DataState<creatorbase.AggregatedPostStats>;
};

const emptyState: DataState<creatorbase.AggregatedPostStats> = {
    data: null,
    error: null,
    status: 'idle',
};

export const initialState: State = {
    instagram: emptyState,
    tiktok: emptyState,
    youtube: emptyState,
};

export type Actions =
    | ReducerActionWithPayload<'loadPlatformAggregatedPostStats', creatorbase.Platform>
    | ReducerActionWithPayload<
          'platformAggregatedPostStatsLoaded',
          { platform: creatorbase.Platform; stats: creatorbase.AggregatedPostStats }
      >
    | ReducerActionWithPayload<'platformAggregatedPostStatsError', { platform: creatorbase.Platform; error: string }>
    | ReducerAction<'resetPlatformAggregatedPostStats'>;

export const reducer = createReducer<State, Actions>({
    loadPlatformAggregatedPostStats: (state, { payload: platform }) => ({
        ...state,
        [platform]: {
            ...state[platform],
            status: 'loading',
            error: null,
        },
    }),
    platformAggregatedPostStatsError: (state, { payload: { error, platform } }) => ({
        ...state,
        [platform]: {
            ...state[platform],
            error: error,
            status: 'error',
        },
    }),
    platformAggregatedPostStatsLoaded: (state, { payload: { platform, stats } }) => ({
        ...state,
        [platform]: {
            data: stats,
            error: null,
            status: 'success',
        },
    }),
    resetPlatformAggregatedPostStats: () => initialState,
});
