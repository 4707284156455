import { creatorbase } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

type State = DataState<creatorbase.PublicTeam>;
type Actions =
    | ReducerAction<'loadTeam'>
    | ReducerActionWithPayload<'teamLoadSuccess', creatorbase.PublicTeam>
    | ReducerActionWithPayload<'teamLoadingError', string>;

export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export const reducer = createReducer<State, Actions>({
    loadTeam: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    teamLoadingError: (state, { payload: errorMessage }) => ({
        ...state,
        status: 'error',
        error: errorMessage,
    }),
    teamLoadSuccess: (state, { payload }) => ({
        ...state,
        status: 'success',
        data: payload,
        error: null,
    }),
});
