import React, { useState } from 'react';
import styles from './BarsSmallChart.module.css';
import cn from 'classnames';
import { formatDate, formatNumberToKNotation } from '../../../../helpers';
import ReactTooltip from 'react-tooltip';

export type ChartValuesEntry = { timestamp: string; value: number };

type BarsSmallChartProps = {
    values: ChartValuesEntry[];
    color?: string;
    className?: string;
    format?: (rawValue: number) => string | number;
};

const BarsSmallChart = ({
    values,
    className,
    color = '#707683',
    format = formatNumberToKNotation,
}: BarsSmallChartProps) => {
    const [hoveredBar, setHoveredBar] = useState<ChartValuesEntry>();
    const maxValue = Math.max(...values.map((entry) => entry.value));
    const getHeightInPercents = (value: number) => Math.min(Math.floor((value / (maxValue || 1)) * 100) + 5, 100);
    const getKey = ({ value, timestamp }: ChartValuesEntry) => value + timestamp;

    return (
        <>
            <div className={cn(styles.container, className)}>
                {values.map((entry) => {
                    const key = getKey(entry);
                    const isHovered = hoveredBar && key === getKey(hoveredBar);
                    return (
                        <div
                            data-tip=""
                            data-for="bar-tooltip"
                            key={key}
                            className={styles.bar}
                            onMouseEnter={() => setHoveredBar(entry)}
                            onMouseLeave={() => {
                                if (isHovered) {
                                    setHoveredBar(undefined);
                                }
                            }}
                            style={{
                                height: `${getHeightInPercents(entry.value)}%`,
                                background: color,
                                opacity: isHovered ? 0.2 : 1,
                            }}
                        />
                    );
                })}
            </div>
            <ReactTooltip id="bar-tooltip" class={styles.tooltip} arrowColor="white">
                {hoveredBar && (
                    <>
                        <span className={styles.timestamp}>{formatDate(hoveredBar.timestamp)}</span>
                        <span className={styles.value}>{format(hoveredBar.value)}</span>
                    </>
                )}
            </ReactTooltip>
        </>
    );
};

export default BarsSmallChart;
