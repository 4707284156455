import { useInfluencerLocationSelect } from '../../../Hooks/selectOptions/useInfluencerLocationSelect';
import { HierarchicalOptionLabel, Select } from '@round/ui-kit';

const Location = () => {
    const { selectProps: countrySelectProps } = useInfluencerLocationSelect({
        type: 'country',
        ordering: 'country_name',
    });

    const { selectProps: stateSelectProps, reset: resetStateSelect } = useInfluencerLocationSelect({
        type: 'state',
        ordering: 'state',
        country_code: countrySelectProps.value?.countryCode,
    });

    const { selectProps: citySelectProps, reset: resetCitySelect } = useInfluencerLocationSelect({
        type: 'city',
        ordering: 'city',
        state: stateSelectProps.value?.state,
        country_code: countrySelectProps.value?.countryCode,
    });

    return (
        <>
            <div>Country</div>
            <Select
                {...countrySelectProps}
                onChange={(option) => {
                    resetStateSelect();
                    resetCitySelect();
                    countrySelectProps.onChange(option);
                }}
                placeholder="Select country"
                isClearable
                filterOption={null}
                formatOptionLabel={(option) => option.country}
            />

            <div>State</div>
            <Select
                {...stateSelectProps}
                onChange={(option) => {
                    resetCitySelect();
                    stateSelectProps.onChange(option);
                }}
                placeholder="Select state"
                isClearable
                filterOption={null}
                formatOptionLabel={(option, meta) => {
                    if (meta.context === 'menu') {
                        return (
                            <HierarchicalOptionLabel
                                labels={[option.state, !countrySelectProps.value ? option.country : undefined]}
                            />
                        );
                    }
                    return option.state;
                }}
            />

            <div>City</div>
            <Select
                {...citySelectProps}
                placeholder="Select city"
                isClearable
                filterOption={null}
                formatOptionLabel={(option, meta) => {
                    if (meta.context === 'menu') {
                        return (
                            <HierarchicalOptionLabel
                                labels={[
                                    option.city,
                                    !stateSelectProps.value ? option.state : undefined,
                                    !countrySelectProps.value ? option.country : undefined,
                                ]}
                            />
                        );
                    }
                    return option.city;
                }}
            />
        </>
    );
};

export default Location;
