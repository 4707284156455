import { AppServer, RouteHandlerContext } from '../../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';
import { CampaignManagerInfluencer, CampaignManagerRetrieveInfluencer } from './influencer.types';

export const models = {
    campaignManagerInfluencer: Model as ModelDefinition<CampaignManagerInfluencer>,
    campaignManagerRetrieveInfluencer: Model as ModelDefinition<CampaignManagerRetrieveInfluencer>,
};

export const factories = {
    campaignManagerInfluencer: createFactory<CampaignManagerInfluencer>({
        name(index: number) {
            return `name ${index}`;
        },
        instagram_display_name(index: number) {
            return `instagram display name ${index}`;
        },
        instagram_user_id(index: number) {
            return index;
        },
        instagram_username(index: number) {
            return `instagram username ${index}`;
        },
        tiktok_display_name(index: number) {
            return `tiktok display name ${index}`;
        },
        tiktok_user_id(index: number) {
            return index;
        },
        tiktok_username(index: number) {
            return `tiktok username ${index}`;
        },
        youtube_username(index: number) {
            return `youtube username ${index}`;
        },
        is_available_for_booking(index: number) {
            return index % 2 === 0;
        },
    }),
    campaignManagerRetrieveInfluencer: createFactory<CampaignManagerRetrieveInfluencer>({
        first_name(index: number) {
            return `first name ${index}`;
        },
        last_name(index: number) {
            return `last name ${index}`;
        },
        email(index: number) {
            return `email${index}@domain.com`;
        },
        phone(index: number) {
            return `${index}0000000000`;
        },
        birthday() {
            return new Date().toISOString();
        },
        location(index: number) {
            return index;
        },
        exclusivity() {
            const random = Math.random();
            return random > 0.33 ? 'coleton_partnership' : random > 0.66 ? 'exclusive' : 'non_exclusive';
        },
        agency_commission(index: number) {
            return 1 + index / 100;
        },
        manager_commission(index: number) {
            return 1 + index / 100;
        },
        address_line_1(index: number) {
            return `address line 1 ${index}`;
        },
        address_city(index: number) {
            return `address city ${index}`;
        },
        address_region(index: number) {
            return `address region ${index}`;
        },
        address_postcode(index: number) {
            return `address postcode ${index}`;
        },
        content_tags: [],
        payment_terms() {
            const random = Math.random();
            return random > 0.33 ? 'accelerated_45' : random > 0.66 ? 'accelerated_30' : 'client_terms';
        },
        preferred_currency() {
            const random = Math.random();
            return random > 0.33 ? 1 : random > 0.66 ? 2 : 5;
        },
        bank_name(index: number) {
            return `bank name ${index}`;
        },
        bank_account_number(index: number) {
            return `${index}${index}${index}${index}${index}${index}${index}${index}`;
        },
        bank_sort_code(index: number) {
            return `${index}${index}${index}${index}${index}${index}`;
        },
        tiktok_username(index: number) {
            return `tiktok username ${index}`;
        },
        instagram_username(index: number) {
            return `instagram username ${index}`;
        },
        youtube_username(index: number) {
            return `youtube username ${index}`;
        },
        name(index: number) {
            return `name ${index}`;
        },
        is_available_for_booking(index: number) {
            return index % 2 === 0;
        },
        paypal_email_address(index: number) {
            return `paypal email address ${index}`;
        },
        preferred_payment_method() {
            const random = Math.random();
            return random > 0.5 ? 'paypal' : 'bank_transfer';
        },
        contact_details(index: number) {
            return `contact details ${index}`;
        },
        roster_share_agency(index: number) {
            return index;
        },
    }),
};

export const serializers = {
    campaignManagerInfluencer: makeSerializer<CampaignManagerInfluencer>([]),
    campaignManagerRetrieveInfluencer: makeSerializer<CampaignManagerRetrieveInfluencer>([]),
};

export function handleRequests(server: AppServer) {
    const campaignManagerInfluencerRoute = '/api/apex/campaign-manager/influencer/';
    server.get(campaignManagerInfluencerRoute, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const campaignManagerInfluencers = schema
            .all('campaignManagerInfluencer')
            .filter(
                (inf) =>
                    !search ||
                    inf.instagram_display_name?.toLowerCase().includes(search.toLowerCase()) ||
                    inf.instagram_username?.toLowerCase().includes(search.toLowerCase()) ||
                    inf.tiktok_display_name?.toLowerCase().includes(search.toLowerCase()) ||
                    inf.tiktok_username?.toLowerCase().includes(search.toLowerCase())
            );
        return buildPaginatedResponse(campaignManagerInfluencers, {
            url: campaignManagerInfluencerRoute,
            serialize: (resource) => this.serialize(resource, 'campaignManagerInfluencer'),
            queryParams: request.queryParams,
        });
    });

    server.get(`${campaignManagerInfluencerRoute}/:id/`, function (this: RouteHandlerContext, schema, request) {
        const influencer = schema.find('campaignManagerRetrieveInfluencer', request.params.id);
        if (!influencer) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return influencer;
    });

    server.patch(`${campaignManagerInfluencerRoute}/:id/`, function (this: RouteHandlerContext, schema, request) {
        const influencer = schema.find('campaignManagerRetrieveInfluencer', request.params.id);
        if (!influencer) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        influencer.update(JSON.parse(request.requestBody));
        return influencer;
    });
}
