import { ValueType } from 'react-select';
import { BaseFilterObject, GenericDropdownOption } from 'App.types';
import usePlannerUsersSelect from 'Modules/Auth/hooks/usePlannerUsersSelect';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';

type Props = {
    initialPlannerUserIds: number[];
    onChange: (value: ValueType<GenericDropdownOption<number>, true>) => void;
    onClear: () => void;
    onRemove: () => void;
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
} & Pick<BaseFilterObject, 'name' | 'icon'>;

const PlannerUsersFilter = ({
    initialPlannerUserIds,
    name,
    onClear,
    onRemove,
    onChange,
    icon,
    isOpen,
    onOpen,
    onClose,
}: Props) => {
    const selectProps = usePlannerUsersSelect(initialPlannerUserIds);

    const clearState = () => {
        selectProps.onChange?.(null);
    };

    return (
        <SelectFilter
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            isMulti
            name={name}
            icon={icon}
            onClear={() => {
                onClear();
                clearState();
            }}
            onRemove={() => {
                onRemove();
                clearState();
            }}
            {...selectProps}
            onChange={(value: ValueType<GenericDropdownOption<number>, true>) => {
                selectProps?.onChange(value);
                onChange(value);
            }}
        />
    );
};

export default PlannerUsersFilter;
