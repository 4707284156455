import styles from './CountBadge.module.css';
import cn from 'classnames';

type Props = {
    count: number | string;
    className?: string;
};

const CountBadge = ({ count, className }: Props) => {
    return <span className={cn(styles.badge, className)}>{count}</span>;
};

export default CountBadge;
