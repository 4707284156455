import React, { useMemo } from 'react';
import useUrlState from 'Hooks/useUrlState';
import PastVenmoPaymentsTable from './Table/PastVenmoPaymentsTable';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import styles from '../PastPayments.module.css';
import useMicrowavePayments from '../../useMicrowavePayments';
import { getFilterValues } from '../PastPaymentsFilters/PastPaymentsFilters';
import PastPaymentsFilters from '../PastPaymentsFilters/PastPaymentsFilters';
import { microwave } from '@round/api';

type UrlState = microwave.GetPaymentRequestsParams;

const initialUrlState: Pick<Required<UrlState>, 'page' | 'page_size' | 'payment_status'> = {
    page: 1,
    page_size: 10,
    payment_status: 'PAID',
};

const PastVenmoPayments = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState);

    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const pageSize = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;

    const filters = useMemo(() => getFilterValues(urlState), [urlState]);

    const { rows, count, status, fetchData, reset } = useMicrowavePayments();

    useAbortableEffect(
        (signal) => {
            if (status === 'not-initialized') {
                fetchData(
                    {
                        page,
                        page_size: pageSize,
                        preferred_payment_method: 'VENMO',
                        status: 'APPROVED',
                        ...filters,
                    },
                    { signal }
                );
            }
        },
        [status, fetchData, page, pageSize, filters]
    );

    const isInitialized = status === 'initialized' || status === 'error';

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div>
                    <h1 className={styles.title}>Past Venmo payments</h1>
                </div>

                <div className={styles.optionsContainer}>
                    <PastPaymentsFilters
                        value={filters}
                        onChange={(val) => {
                            setUrlState({ ...val, page: 1 });
                            reset();
                        }}
                    />
                </div>
            </div>

            <PastVenmoPaymentsTable
                isLoading={!isInitialized}
                data={rows}
                count={count}
                page={page}
                setPage={(page) => {
                    setUrlState({ page });
                    reset();
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                    setUrlState({ page_size: pageSize || initialUrlState.page_size });
                    reset();
                }}
                manualPagination
                noDataLabel={status === 'error' ? 'Could not load payments' : 'No payments found'}
            />
        </div>
    );
};

export default PastVenmoPayments;
