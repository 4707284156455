import React, { useContext, useMemo } from 'react';
import styles from './HeadlineCalculations.module.css';
import { asMoney, roundTo2Dp } from '../../../../../helpers';
import MetricCardWithChart from '../../../../../ui/DataDisplay/Statistics/MetricCardWithChart/MetricCardWithChart';
import { ReactComponent as TotalAccountsIcon } from '../../assets/TotalAccounts.svg';
import { ReactComponent as TotalViewsIcon } from '../../assets/TotalViews.svg';
import { ReactComponent as TotalLikesIcon } from '../../assets/TotalLikes.svg';
import { ReactComponent as TotalSpendIcon } from '../../assets/TotalSpend.svg';
import { ReactComponent as CPMIcon } from '../../assets/CPM.svg';
import { InfluencerPlanContext } from '../../contexts/InfluencerPlanContext';
import { useYoutubePlanStats } from '../../contexts/YoutubeCreatorsContext/hooks/useYoutubePlanStats';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';

export const YoutubeHeadlineStats = () => {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const currency = influencerPlan?.currency;

    const { init, isLoading, isInitialized, stats, loadingError } = useYoutubePlanStats();

    const engagementRateText = useMemo(() => {
        return typeof stats?.engagement_rate === 'number' ? `${roundTo2Dp(stats.engagement_rate * 100)}%` : '-';
    }, [stats?.engagement_rate]);

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                init({ signal });
            }
        },
        [init, isInitialized]
    );

    const statsLoading = isLoading || (!isInitialized && !loadingError);
    return (
        <div className={styles.headlineCalculations}>
            <MetricCardWithChart
                loading={statsLoading}
                baseColor="#0090FF"
                label="Total accounts"
                value={stats?.post_count}
                Icon={() => <TotalAccountsIcon />}
            />

            <MetricCardWithChart
                loading={statsLoading}
                baseColor="#F7685B"
                value={stats?.total_views}
                label="Total views"
                Icon={() => <TotalViewsIcon />}
            />

            <MetricCardWithChart
                loading={statsLoading}
                baseColor="#48D98A"
                value={engagementRateText}
                label="Engagement rate"
                Icon={() => <TotalLikesIcon />}
            />

            <MetricCardWithChart
                loading={statsLoading}
                baseColor="#F7685B"
                value={asMoney(stats?.total_spend, currency)}
                label="Total spend"
                Icon={() => <TotalSpendIcon />}
            />

            <MetricCardWithChart
                loading={statsLoading}
                baseColor="#f5d400"
                value={asMoney(stats?.total_cpm, currency)}
                label="Live CPM"
                Icon={() => <CPMIcon />}
            />
        </div>
    );
};
