import React from 'react';
import styles from './Rating.module.css';
import { ReactComponent as StarLinearIcon } from '../../assets/StarLinear.svg';
import { ReactComponent as StarIcon } from '../../assets/Star.svg';
import range from 'lodash/range';
import cn from 'classnames';

type Props = {
    rating: number;
    onChange?: (rating: number) => void;
    maxRating: number;
    disabled?: boolean;
};

const Rating = ({ rating, onChange, maxRating, disabled }: Props) => {
    return (
        <div className={styles.container}>
            {range(1, maxRating + 1).map((i) => {
                const isSelected = i <= rating;
                return (
                    <button
                        key={i}
                        className={cn(styles.star, { [styles.selected]: isSelected, [styles.disabled]: disabled })}
                        onClick={() => {
                            if (!disabled && typeof onChange === 'function') {
                                onChange(i);
                            }
                        }}
                    >
                        {isSelected ? <StarIcon /> : <StarLinearIcon />}
                    </button>
                );
            })}
        </div>
    );
};

export default Rating;
