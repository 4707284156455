import { FacebookTargeting } from '../../../../App.types';
import { createFacebookTargeting, createFacebookTargetingTemplate } from '../api/Facebook.api';
import { FacebookTargetingTemplate } from '../types/Facebook.types';

export async function createFacebookTargetingWithDefaultsSet(mediaPlanItemId: number): Promise<FacebookTargeting> {
    return createFacebookTargeting({ media_plan_item: mediaPlanItemId });
}

export async function createFacebookTargetingTemplateWithDefaultsSet(
    clientId: number
): Promise<FacebookTargetingTemplate> {
    return createFacebookTargetingTemplate({ client: clientId });
}

export async function createFacebookTargetingFromTemplate(
    mediaPlanItemId: number,
    templateId: number
): Promise<FacebookTargeting> {
    return createFacebookTargeting({ media_plan_item: mediaPlanItemId, template: templateId });
}
