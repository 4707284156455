import {
    YoutubeInfluencerPlanStats,
    YouTubeInfluencerPost,
    YoutubeInfluencerPostGroup,
    YoutubeInfluencerPostGroupStats,
} from '@round/api';
import { YoutubeVideo } from '@round/api/dist/esm/types/src/youtube';
import { ReducerAction, ReducerActionWithPayload } from '../../../../../App.types';
import { createReducer } from '../../../../../helpers';

export const initialState: YoutubeCreatorsState = {
    //influencer-plan-stats
    youtubeInfluencerPlanStats: null,
    isYoutubeInfluencerPlanStatsInitialized: false,
    isYoutubeInfluencerPlanStatsLoading: false,
    youtubeInfluencerPlanStatsLoadingError: null,
    //influencer-post-groups
    youtubeInfluencerPostGroups: null,
    isYoutubeInfluencerPostGroupsInitialized: false,
    isYoutubeInfluencerPostGroupsLoading: false,
    youtubeInfluencerPostGroupsLoadingError: null,
    //influencer-post-group-stats
    youtubeInfluencerPostGroupStats: null,
    isYoutubeInfluencerPostGroupStatsInitialized: false,
    isYoutubeInfluencerPostGroupStatsLoading: false,
    youtubeInfluencerPostGroupStatsLoadingError: null,
    // youtube-influencer-posts-data
    youtubeInfluencerPosts: null,
    isYouTubeInfluencerPostsInitialized: false,
    isYouTubeInfluencerPostsLoading: false,
    youtubeInfluencerPostsLoadingError: null,
    youtubeVideos: null,
};

export type YoutubeCreatorsState = {
    //influencer-plan-stats
    youtubeInfluencerPlanStats: YoutubeInfluencerPlanStats | null;
    isYoutubeInfluencerPlanStatsLoading: boolean;
    isYoutubeInfluencerPlanStatsInitialized: boolean;
    youtubeInfluencerPlanStatsLoadingError: string | null;
    //influencer-post-groups
    youtubeInfluencerPostGroups: YoutubeInfluencerPostGroup[] | null;
    isYoutubeInfluencerPostGroupsLoading: boolean;
    isYoutubeInfluencerPostGroupsInitialized: boolean;
    youtubeInfluencerPostGroupsLoadingError: string | null;
    //influencer-post-group-stats
    youtubeInfluencerPostGroupStats: YoutubeInfluencerPostGroupStats[] | null;
    isYoutubeInfluencerPostGroupStatsInitialized: boolean;
    isYoutubeInfluencerPostGroupStatsLoading: boolean;
    youtubeInfluencerPostGroupStatsLoadingError: string | null;
    // influencer-posts-data
    youtubeInfluencerPosts: YouTubeInfluencerPost[] | null;
    isYouTubeInfluencerPostsLoading: boolean;
    isYouTubeInfluencerPostsInitialized: boolean;
    youtubeInfluencerPostsLoadingError: string | null;
    youtubeVideos: YoutubeVideo[] | null;
};

export type Actions =
    //influencer-plan-stats
    | ReducerAction<'loadYoutubeInfluencerPlanStats'>
    | ReducerActionWithPayload<'setIsYoutubeInfluencerPlanStatsInitialized', YoutubeInfluencerPlanStats>
    | ReducerActionWithPayload<'setYoutubeInfluencerPlanStatsLoadingError', string | null>
    | ReducerAction<'resetYoutubeInfluencerPlanStats'>
    //influencer-post-groups
    | ReducerAction<'loadYoutubeInfluencerPostGroups'>
    | ReducerActionWithPayload<'setIsYoutubeInfluencerPostGroupsInitialized', YoutubeInfluencerPostGroup[]>
    | ReducerActionWithPayload<'setYoutubeInfluencerPostGroupsLoadingError', string | null>
    | ReducerActionWithPayload<'createYoutubeInfluencerPostGroup', YoutubeInfluencerPostGroup>
    | ReducerActionWithPayload<'updateYoutubeInfluencerPostGroup', YoutubeInfluencerPostGroup>
    | ReducerActionWithPayload<'deleteYoutubeInfluencerPostGroup', number>
    //influencer-post-group-stats
    | ReducerAction<'loadYoutubeInfluencerPostGroupStats'>
    | ReducerActionWithPayload<'setIsYoutubeInfluencerPostGroupStatsInitialized', YoutubeInfluencerPostGroupStats[]>
    | ReducerActionWithPayload<'setYoutubeInfluencerPostGroupStatsLoadingError', string | null>
    // influencer-posts-data
    | ReducerAction<'loadYoutubeInfluencerPosts'>
    | ReducerActionWithPayload<
          'setIsYoutubeInfluencerPostsInitialized',
          Pick<YoutubeCreatorsState, 'youtubeInfluencerPosts' | 'youtubeVideos'>
      >
    | ReducerActionWithPayload<'setYoutubeInfluencerPostsLoadingError', string | null>
    | ReducerActionWithPayload<
          'youtubeInfluencerPostCreated',
          { post: YouTubeInfluencerPost; video: YoutubeVideo | null }
      >
    | ReducerActionWithPayload<'youtubeInfluencerPostDeleted', number>
    | ReducerActionWithPayload<
          'youtubeInfluencerPostUpdated',
          { post: YouTubeInfluencerPost; video?: YoutubeVideo | null }
      >;

export default createReducer<YoutubeCreatorsState, Actions>({
    //influencer-plan-stats
    loadYoutubeInfluencerPlanStats: (state) => ({
        ...state,
        youtubeInfluencerPlanStats: null,
        isYoutubeInfluencerPlanStatsLoading: true,
        isYoutubeInfluencerPlanStatsInitialized: false,
        youtubeInfluencerPlanStatsLoadingError: null,
    }),
    setIsYoutubeInfluencerPlanStatsInitialized: (state, { payload }) => ({
        ...state,
        youtubeInfluencerPlanStats: payload,
        isYoutubeInfluencerPlanStatsLoading: false,
        isYoutubeInfluencerPlanStatsInitialized: true,
        youtubeInfluencerPlanStatsLoadingError: null,
    }),
    setYoutubeInfluencerPlanStatsLoadingError: (state, { payload }) => ({
        ...state,
        isYoutubeInfluencerPlanStatsLoading: false,
        isYoutubeInfluencerPlanStatsInitialized: false,
        youtubeInfluencerPlanStatsLoadingError: payload,
    }),
    resetYoutubeInfluencerPlanStats: (state) => ({
        ...state,
        youtubeInfluencerPlanStats: null,
        isYoutubeInfluencerPlanStatsInitialized: false,
        isYoutubeInfluencerPlanStatsLoading: false,
        youtubeInfluencerPlanStatsLoadingError: null,
    }),
    //influencer-post-groups
    loadYoutubeInfluencerPostGroups: (state) => ({
        ...state,
        youtubeInfluencerPostGroups: null,
        isYoutubeInfluencerPostGroupsLoading: true,
        isYoutubeInfluencerPostGroupsInitialized: false,
        youtubeInfluencerPostGroupsLoadingError: null,
    }),
    setIsYoutubeInfluencerPostGroupsInitialized: (state, { payload }) => ({
        ...state,
        youtubeInfluencerPostGroups: payload,
        isYoutubeInfluencerPostGroupsLoading: false,
        isYoutubeInfluencerPostGroupsInitialized: true,
        youtubeInfluencerPostGroupsLoadingError: null,
    }),
    setYoutubeInfluencerPostGroupsLoadingError: (state, { payload }) => ({
        ...state,
        isYoutubeInfluencerPostGroupsLoading: false,
        isYoutubeInfluencerPostGroupsInitialized: false,
        youtubeInfluencerPostGroupsLoadingError: payload,
    }),
    createYoutubeInfluencerPostGroup: (state, { payload }) => ({
        ...state,
        youtubeInfluencerPostGroups: state.youtubeInfluencerPostGroups
            ? [...state.youtubeInfluencerPostGroups, payload]
            : [payload],
    }),
    updateYoutubeInfluencerPostGroup: (state, { payload }) => ({
        ...state,
        youtubeInfluencerPostGroups:
            state.youtubeInfluencerPostGroups?.map((group) => (group.id === payload.id ? payload : group)) || null,
    }),
    deleteYoutubeInfluencerPostGroup: (state, { payload }) => ({
        ...state,
        youtubeInfluencerPostGroups: state.youtubeInfluencerPostGroups?.filter((group) => group.id !== payload) ?? null,
    }),
    //influencer-post-group-stats
    loadYoutubeInfluencerPostGroupStats: (state) => ({
        ...state,
        isYoutubeInfluencerPostGroupStatsLoading: true,
        isYoutubeInfluencerPostGroupStatsInitialized: false,
        youtubeInfluencerPostGroupStatsLoadingError: null,
    }),
    setIsYoutubeInfluencerPostGroupStatsInitialized: (state, { payload }) => ({
        ...state,
        youtubeInfluencerPostGroupStats: payload,
        isYoutubeInfluencerPostGroupStatsLoading: false,
        isYoutubeInfluencerPostGroupStatsInitialized: true,
        youtubeInfluencerPostGroupStatsLoadingError: null,
    }),
    setYoutubeInfluencerPostGroupStatsLoadingError: (state, { payload }) => ({
        ...state,
        isYoutubeInfluencerPostGroupStatsLoading: false,
        isYoutubeInfluencerPostGroupStatsInitialized: false,
        youtubeInfluencerPostGroupStatsLoadingError: payload,
    }),
    // influencer-posts
    loadYoutubeInfluencerPosts: (state) => ({
        ...state,
        youtubeInfluencerPosts: null,
        isYouTubeInfluencerPostsInitialized: false,
        isYouTubeInfluencerPostsLoading: true,
        youtubeInfluencerPostsLoadingError: null,
    }),
    setIsYoutubeInfluencerPostsInitialized: (state, { payload }) => ({
        ...state,
        ...payload,
        isYouTubeInfluencerPostsLoading: false,
        isYouTubeInfluencerPostsInitialized: true,
        youtubeInfluencerPostsLoadingError: null,
    }),
    setYoutubeInfluencerPostsLoadingError: (state, { payload }) => ({
        ...state,
        isYouTubeInfluencerPostsLoading: false,
        isYouTubeInfluencerPostsInitialized: false,
        youtubeInfluencerPostsLoadingError: payload,
    }),
    youtubeInfluencerPostCreated: (state, { payload: { post, video } }) => ({
        ...state,
        youtubeInfluencerPosts: state.youtubeInfluencerPosts?.concat(post) ?? [post],
        youtubeVideos: video ? (state.youtubeVideos ?? [])?.concat(video) : state.youtubeVideos,
    }),
    youtubeInfluencerPostDeleted: (state, { payload }) => ({
        ...state,
        youtubeInfluencerPosts: state.youtubeInfluencerPosts?.filter((p) => p.id !== payload) ?? null,
    }),
    youtubeInfluencerPostUpdated: (state, { payload: { post, video } }) => {
        const shouldUpdateVideo = !!video || video === null;
        let updatedVideos = state.youtubeVideos;

        if (video) {
            updatedVideos = (state.youtubeVideos ?? []).concat(video);
        } else if (video === null) {
            updatedVideos = state.youtubeVideos?.filter((v) => v.id !== post.video_id) ?? null;
        }

        return {
            ...state,
            youtubeInfluencerPosts: state.youtubeInfluencerPosts?.map((p) => (p.id === post.id ? post : p)) ?? null,
            youtubeVideos: shouldUpdateVideo ? updatedVideos : state.youtubeVideos,
        };
    },
});
