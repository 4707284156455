import { Select as BaseSelect, SelectProps } from '@round/ui-kit';
import { IndicatorProps, OptionTypeBase, SelectComponentsConfig, StylesConfig, mergeStyles } from 'react-select';
import { ReactComponent as ChevronDownIcon } from 'assets/whitelabel/ChevronDown.svg';
import { ReactComponent as DeleteIcon } from 'assets/whitelabel/Delete.svg';

type Props<T extends OptionTypeBase, IsMulti extends boolean = false> = SelectProps<T, IsMulti>;

const baseStyles: StylesConfig = {
    control: (base, { isFocused, isDisabled }) => ({
        ...base,
        columnGap: '0.5rem',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: isFocused ? '#007FFF' : isDisabled ? '#e7e7e7' : 'rgba(0, 0, 0, 0.10)',
        borderRadius: '0.25rem',
        background: isDisabled ? 'rgba(0, 0, 0, .03)' : 'white',
        boxShadow: 'none',
        padding: '0.375rem 0.5rem 0.375rem 0.875rem',
        fontFamily: 'Inter',
        fontSize: '14px',
        color: isDisabled ? 'rgba(0, 0, 0, .2)' : '#555',
        ':hover': {
            padding: '0.375rem 0.5rem 0.375rem 0.875rem',
            borderColor: isFocused ? '#007FFF' : 'rgba(0, 0, 0, 0.20)',
        },
    }),
    valueContainer: (base) => ({
        ...base,
        padding: 0,
        lineHeight: '20px',
        gap: '0.375rem',
    }),
    multiValue: (base) => ({
        ...base,
        background: '#FFF',
        padding: '0.125rem 0.25rem 0.125rem 0.5rem',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '0.375rem',
        color: '#555',
        margin: 0,
    }),
    multiValueLabel: (base) => ({
        ...base,
        padding: 0,
    }),
    multiValueRemove: (base) => ({
        ...base,
        padding: '0 0.375rem',
        cursor: 'pointer',
        ':hover': {
            background: '#FFF',
        },
    }),
    placeholder: (base) => ({
        ...base,
        color: '#a9a9a9',
    }),
    indicatorsContainer: (base, { isDisabled }) => ({
        ...base,
        color: '#A9A9A9',
        padding: '0 0.375rem',
        columnGap: '0.5rem',
        cursor: isDisabled ? 'auto' : 'pointer',
        ':hover': {
            color: isDisabled ? '#A9A9A9' : 'rgb(102, 102, 102)',
        },
    }),
    loadingIndicator: (base) => ({
        ...base,
        padding: '0 0.375rem',
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: 0,
        color: 'inherit',
        ':hover': {
            color: 'rgb(102, 102, 102)',
        },
    }),
    option: (base, { isSelected }) => ({
        ...base,
        padding: '0.5rem 0.75rem',
        fontSize: '0.875rem',
        color: isSelected ? '#007FFF' : '#555',
        fontWeight: isSelected ? 600 : 400,
        background: '#FFF',
        ':hover': {
            background: '#f6f6f6',
        },
    }),
    menu: (base) => ({
        ...base,
        border: '1px solid rgba(0, 0, 0, 0.1)',
        boxShadow: '0px 16px 32px -12px rgba(0, 0, 0, 0.03)',
    }),
};

const DropdownIndicator = () => <ChevronDownIcon />;
const ClearIndicator = <T extends OptionTypeBase, IsMulti extends boolean>(props: IndicatorProps<T, IsMulti>) => (
    <DeleteIcon ref={props.innerProps.ref} {...props.innerProps} style={props.getStyles('clearIndicator', props)} />
);

const Select = <T extends OptionTypeBase, IsMulti extends boolean = false>({
    styles,
    components,
    ...props
}: Props<T, IsMulti>) => {
    const mergedStyles = styles ? mergeStyles(baseStyles, styles) : baseStyles;
    const overriddenComponents: SelectComponentsConfig<T, IsMulti> = {
        DropdownIndicator,
        ClearIndicator,
        ...components,
    };

    return <BaseSelect styles={mergedStyles} components={overriddenComponents} {...props} />;
};

export default Select;
