import { useEffect, useState, Dispatch, SetStateAction } from 'react';

type Return<T> = [T | undefined, Dispatch<SetStateAction<T | undefined>>];

export function useSessionStorage<T>(
    sessionKey: string,
    initialState?: T | ((sessionState: T | null) => T)
): Return<T> {
    const getInitialState = (state: T | null) => {
        if (typeof initialState === 'function') {
            return (initialState as (sessionState: T | null) => T)(state);
        }

        return initialState;
    };

    const [value, setValue] = useState<T | undefined>(() => {
        try {
            const item = window.sessionStorage.getItem(sessionKey);

            if (!item) {
                return getInitialState(null);
            }

            const parsedItem: T = JSON.parse(item);

            return getInitialState(parsedItem);
        } catch (error) {
            console.error(error);
            return getInitialState(null);
        }
    });

    useEffect(() => {
        try {
            window.sessionStorage.setItem(sessionKey, JSON.stringify(value));
        } catch (error) {
            console.error(error);
        }
    }, [value, sessionKey]);

    return [value, setValue];
}
