import { Metrics } from '../../../types/MediaPlanResults.types';
import { GenericDropdownOption } from '../../../../../../App.types';
import capitalize from 'lodash/capitalize';

export type SelectableMetrics = keyof Omit<Metrics, 'mediaPlanItemId'>;
export type Preset = {
    name: 'default' | 'performance' | 'custom';
    metrics: SelectableMetrics[];
};

export type PresetOption = GenericDropdownOption<Preset['name']> & { metrics: Preset['metrics'] };
export type MetricOption = GenericDropdownOption<SelectableMetrics>;

export const presets: Preset[] = [
    {
        name: 'default',
        metrics: ['impressions', 'amount_spent', 'cpm', 'clicks', 'cpc', 'views', 'cpv'],
    },
    {
        name: 'performance',
        metrics: ['impressions', 'amount_spent', 'cpm', 'purchases', 'revenue', 'cpa', 'roas'],
    },
    {
        name: 'custom',
        metrics: [],
    },
];

export const presetOptions: PresetOption[] = presets.map((preset) => ({
    value: preset.name,
    label: capitalize(preset.name),
    metrics: preset.metrics,
}));

export const metricsOptions: MetricOption[] = [
    {
        value: 'amount_spent',
        label: 'Amount Spent',
    },
    {
        value: 'impressions',
        label: 'Impressions',
    },
    {
        value: 'cpm',
        label: 'CPM',
    },
    {
        value: 'clicks',
        label: 'Clicks',
    },
    {
        value: 'cpc',
        label: 'CPC',
    },
    {
        value: 'views',
        label: 'Views',
    },
    {
        value: 'cpv',
        label: 'CPV',
    },
    {
        value: 'six_sec_views',
        label: 'Views (6 sec)',
    },
    {
        value: 'six_sec_cpv',
        label: 'CPV (6 sec)',
    },
    {
        value: 'leads',
        label: 'Leads',
    },
    {
        value: 'cpl',
        label: 'CPL',
    },
    {
        value: 'view_rate',
        label: 'View Rate',
    },
    {
        value: 'purchases',
        label: 'Purchases',
    },
    {
        value: 'cpa',
        label: 'CPA',
    },
    {
        value: 'revenue',
        label: 'Revenue',
    },
    {
        value: 'roas',
        label: 'ROAS',
    },
    {
        value: 'ctr',
        label: 'CTR',
    },
];
