import { showNotification } from 'helpers';
import { useState } from 'react';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import styles from './DeleteProjectModal.module.css';
import Button from 'ui-new/whitelabel/Button/Button';
import cn from 'classnames';
import { ProjectsTableRow } from '../ProjectsTable/ProjectsTable';
import { creatorbase } from '@round/api';

type Props = {
    project: ProjectsTableRow | null;
    isOpen: boolean;
    onClose: () => void;
    deleteProject: typeof creatorbase.deleteProject;
};

const DeleteProjectModal = ({ project, isOpen, onClose, deleteProject }: Props) => {
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const handleDelete = async () => {
        if (!project) {
            return;
        }

        try {
            setIsDeleteLoading(true);
            const response = await deleteProject(project.id);
            if (response?.status === 204) {
                showNotification('project deleted', 'info');
                onClose();
                return;
            }

            showNotification('Could not delete project', 'error');
            return;
        } catch {
            showNotification('Could not delete project', 'error');
        } finally {
            setIsDeleteLoading(false);
        }
    };

    return (
        <Modal className={styles.modal} closeOnOverlayClick isOpen={isOpen} onClose={onClose}>
            <Modal.Header>
                <Modal.Title>Delete project</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.body}>
                <p className={cn(styles.copy, styles.bold)}>You’re about to permanently delete the following:</p>
                <div className={styles.banner}>
                    <p className={styles.copy}>
                        <span className={styles.bold}>Project</span>:&nbsp;
                        {project && <>{project?.name}</>}
                    </p>
                    <p className={styles.copy}>
                        <span className={styles.bold}>Campaigns</span>:&nbsp;
                        {project && <>{project?.campaign_count}</>}
                    </p>
                    <p className={styles.copy}>
                        <span className={styles.bold}>Posts</span>:&nbsp;
                        {project && <>{project?.post_count}</>}
                    </p>
                </div>

                <p className={styles.copy}>
                    You cannot restore projects, campaigns, or posts. They are deleted permanently.
                </p>
            </Modal.Body>

            <Modal.Actions>
                <Button appearance="critical" isLoading={isDeleteLoading} onClick={handleDelete}>
                    Delete 1 project
                    {project && ` and ${project?.campaign_count + project?.post_count} associated entities`}
                </Button>
                <Button appearance="ghost" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteProjectModal;
