import { AppServer } from '../../mirage';
import * as influencerMirage from './influencer/influencer.mirage';
import * as influencerCostMirage from './influencerCost/influencerCost.mirage';
import * as bookingMirage from './booking/booking.mirage';
import * as campaignMirage from './campaign/campaign.mirage';
import * as creatorAgencyMirage from './creatorAgency/creatorAgency.mirage';
import * as invoiceMirage from './invoice/invoice.mirage';

export const models = {
    ...influencerMirage.models,
    ...bookingMirage.models,
    ...campaignMirage.models,
};

export const serializers = {
    ...influencerMirage.serializers,
    ...bookingMirage.serializers,
    ...campaignMirage.serializers,
};

export const factories = {
    ...influencerMirage.factories,
    ...bookingMirage.factories,
    ...campaignMirage.factories,
};

export function handleRequests(server: AppServer) {
    influencerMirage.handleRequests(server);
    influencerCostMirage.handleRequests(server);
    bookingMirage.handleRequests(server);
    campaignMirage.handleRequests(server);
    creatorAgencyMirage.handleRequests(server);
    invoiceMirage.handleRequests(server);
}
