import { creatorbase } from '@round/api';
import { DropdownOptionWithSubLabel } from '@round/ui-kit';
import { OptionsParams, UseSingleValueSelectParams, useSelect } from 'Hooks/useSelect';

export type BrandOption = DropdownOptionWithSubLabel<number> & {
    image?: string | null;
};
export const mapBrandToOption = (brand: creatorbase.Brand, team?: creatorbase.Team | null): BrandOption => ({
    value: brand.id,
    label: brand.name,
    subLabel: team?.name || '',
    image: brand.image,
});

const fetchOptions = async (params: OptionsParams, requestInit?: RequestInit) => {
    const response = await creatorbase.getBrands(
        { page: params.page, page_size: params.page_size, search: params.search },
        requestInit
    );

    if (response.status === 404 || response.status === 403) {
        throw new Error(response.data.detail);
    }

    const teamIds = response.data.results.map((brand) => brand.team_id);

    let teams: creatorbase.Team[] = [];

    if (!!teamIds.length) {
        const teamsResponse = await creatorbase.getTeams(
            { id: teamIds.join(), page_size: teamIds.length },
            requestInit
        );

        teams = teamsResponse.status === 200 ? teamsResponse.data.results : [];
    }

    return {
        ...response.data,
        results: response.data.results.map((brand) => {
            const team = teams.find((team) => team.id === brand.team_id);

            return mapBrandToOption(brand, team);
        }),
    };
};

type Params = Omit<UseSingleValueSelectParams<BrandOption>, 'fetchOptions' | 'isMulti'>;

export default function useBrandSelect(params: Params) {
    return useSelect<BrandOption>({ fetchOptions, isMulti: false, ...params });
}
