import React from 'react';
import styles from './Card.module.css';
import cn from 'classnames';
import LoadingSpinner from '../../SharedComponents/LoadingSpinner/LoadingSpinner';
import { FCWithChildren } from '../../utility/utility.types';

type CardProps = {
    loading?: boolean;
    className?: string;
};

const Card: FCWithChildren<CardProps> = ({ children, className, loading }) => (
    <div className={cn(styles.card, className)}>{loading ? <LoadingSpinner /> : children}</div>
);

export default Card;
