/**
 * This module contains all helper functions
 * to calculate metrics **without any formatting**.
 * It could be a duplicate of metrics helper functions
 * all over the project. But in the future would be ideal
 * to keep it here.
 * @module Metrics
 */

/**
 * Calculates cost per mille (thousand)
 * @param cost - total amount spent
 * @param impressions - total impressions
 */
export function cpm(cost: number | undefined, impressions: number | undefined): number {
    if (typeof cost === 'undefined' || !impressions) {
        return NaN;
    }

    return (cost / impressions) * 1000;
}

/**
 * Calculates cost per click
 * @param cost - total amount spent
 * @param clicks - total clicks
 */
export function cpc(cost: number | undefined, clicks: number | undefined): number {
    if (typeof cost === 'undefined' || !clicks) {
        return NaN;
    }

    return cost / clicks;
}

/**
 * Calculates cost per view
 * @param cost - total amount spent
 * @param views - total views
 */
export function cpv(cost: number | undefined, views: number | undefined): number {
    if (typeof cost === 'undefined' || !views) {
        return NaN;
    }

    return cost / views;
}

/**
 * Calculates cost per action
 * @param cost - total amount spent
 * @param actions - total actions
 */
export function cpa(cost: number | undefined, actions: number | undefined): number {
    if (typeof cost === 'undefined' || !actions) {
        return NaN;
    }

    return cost / actions;
}

/**
 * Calculates Return on ad spend
 * @param revenue
 * @param cost
 */
export function roas(revenue: number | undefined, cost: number | undefined): number {
    if (typeof revenue === 'undefined' || !cost) {
        return NaN;
    }

    return revenue / cost;
}

/**
 * Calculates cost per lead
 * @param cost
 * @param leads
 */
export function cpl(cost: number | undefined, leads: number | undefined): number {
    if (typeof cost === 'undefined' || !leads) {
        return NaN;
    }

    return cost / leads;
}

export function viewRate(views: number | undefined, impressions: number | undefined): number {
    if (typeof views === 'undefined' || !impressions) {
        return NaN;
    }

    return Math.round((views / impressions) * 100);
}

export function ctr(clicks: number | undefined, impressions: number | undefined): number {
    if (typeof clicks === 'undefined' || !impressions) {
        return NaN;
    }

    return clicks / impressions;
}
