import { microwave } from '@round/api';
import { useCallback, useState } from 'react';

type FormStatus = microwave.PaymentRequestFormState | 'NOT_INITIALIZED' | 'LINK_BROKEN' | 'ERROR';

export function useMicrosPaymentRequest(uuid: string | null | undefined) {
    const [data, setData] = useState<microwave.TiktokPostInviteForm | null>(null);
    const [error, setError] = useState<'LINK_BROKEN' | 'ERROR' | null>(null);

    const status: FormStatus = data?.form_state ? data.form_state : error ? error : 'NOT_INITIALIZED';

    const fetchData = useCallback(
        async (requestInit?: RequestInit) => {
            if (!uuid) {
                setError('LINK_BROKEN');
                return;
            }

            try {
                const response = await microwave.getTiktokPostInviteForm(uuid, requestInit);
                if (response.status === 404) {
                    setError('LINK_BROKEN');
                    return;
                }

                setData(response.data);
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    return;
                }

                setError('ERROR');
            }
        },
        [uuid]
    );

    const updateFormData = useCallback((data: Partial<microwave.TiktokPostInviteForm>) => {
        setData((prev) => {
            if (!prev) {
                return prev;
            }
            return { ...prev, ...data };
        });
    }, []);

    return {
        data,
        status,
        error,

        init: useCallback((requestInit?: RequestInit) => fetchData(requestInit), [fetchData]),
        updateFormData,
    };
}
