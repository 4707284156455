import { createContext, Dispatch, ReactNode, useCallback, useContext, useReducer } from 'react';
import { InstagramAccountsContextActions, InstagramAccountsContextState } from './reducer';
import reducer from './reducer';
import useNonNullContext from 'Hooks/useNonNullContext';
import { getInstagramUserImages, getInstagramUsers } from '@round/api';
import { fetchInstagramUserStats } from 'Modules/Advertising/InfluencerPlan/InfluencerPlan.api';

const initialState: InstagramAccountsContextState = {
    isInitialized: false,
    data: {},
};

const InstagramAccountsContext = createContext<
    [InstagramAccountsContextState, Dispatch<InstagramAccountsContextActions>] | null
>(null);

type Props = { children?: ReactNode };
export const InstagramAccountsProvider = ({ children }: Props) => {
    const state = useReducer(reducer, initialState);

    return <InstagramAccountsContext.Provider value={state}>{children}</InstagramAccountsContext.Provider>;
};

export function useInstagramAccountsData() {
    const [state, dispatch] = useNonNullContext(InstagramAccountsContext);

    const fetchData = useCallback(
        async (accountIds: number[], requestInit?: RequestInit) => {
            if (!accountIds.length) {
                dispatch({ type: 'setAccountsDataInitialized' });
                return;
            }

            try {
                dispatch({ type: 'setAccountsDataLoading', payload: { accountIds } });

                const responses = await Promise.allSettled([
                    getInstagramUserImages(accountIds, requestInit),
                    getInstagramUsers({ id: accountIds.join(), page_size: accountIds.length }, requestInit),
                    fetchInstagramUserStats(accountIds, requestInit),
                ]);

                if (
                    responses.every((response) => response.status === 'rejected') ||
                    (responses[1].status === 'fulfilled' && responses[1].value.status !== 200)
                ) {
                    dispatch({
                        type: 'setAccountsDataError',
                        payload: { accountIds, error: `Couldn't fetch instagram user data` },
                    });
                    return;
                }

                dispatch({
                    type: 'setAccountsDataSuccess',
                    payload: {
                        accountIds,
                        userImages: responses[0].status === 'fulfilled' ? responses[0].value.data : [],
                        users:
                            responses[1].status === 'fulfilled' && responses[1].value.status === 200
                                ? responses[1].value.data.results
                                : [],
                        stats: responses[2].status === 'fulfilled' ? responses[2].value : [],
                    },
                });

                dispatch({ type: 'setAccountsDataInitialized' });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'setAccountsDataIdle', payload: { accountIds } });
                    return;
                }

                dispatch({
                    type: 'setAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch instagram user data` },
                });
            }
        },
        [dispatch]
    );

    return {
        data: state.data,
        isInitialized: state.isInitialized,
        fetchData,
        getIsLoading: (accountId: number) =>
            !state.data[accountId] ||
            state.data[accountId]?.status === 'idle' ||
            state.data[accountId]?.status === 'loading',
    };
}

export function useInstagramAccountsDataActions() {
    const context = useContext(InstagramAccountsContext);

    const [, dispatch] = context ?? [];

    const fetchData = useCallback(
        async (accountIds: number[], requestInit?: RequestInit) => {
            if (!dispatch) {
                return;
            }

            try {
                dispatch({ type: 'setAccountsDataLoading', payload: { accountIds } });

                const responses = await Promise.allSettled([
                    getInstagramUserImages(accountIds, requestInit),
                    getInstagramUsers({ id: accountIds.join(), page_size: accountIds.length }, requestInit),
                    fetchInstagramUserStats(accountIds, requestInit),
                ]);

                if (
                    responses.every((response) => response.status === 'rejected') ||
                    (responses[1].status === 'fulfilled' && responses[1].value.status !== 200)
                ) {
                    dispatch({
                        type: 'setAccountsDataError',
                        payload: { accountIds, error: `Couldn't fetch instagram user data` },
                    });
                    return;
                }

                dispatch({
                    type: 'setAccountsDataSuccess',
                    payload: {
                        accountIds,
                        userImages: responses[0].status === 'fulfilled' ? responses[0].value.data : [],
                        users:
                            responses[1].status === 'fulfilled' && responses[1].value.status === 200
                                ? responses[1].value.data.results
                                : [],
                        stats: responses[2].status === 'fulfilled' ? responses[2].value : [],
                    },
                });

                dispatch({ type: 'setAccountsDataInitialized' });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'setAccountsDataIdle', payload: { accountIds } });
                    return;
                }

                dispatch({
                    type: 'setAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch instagram user data` },
                });
            }
        },
        [dispatch]
    );

    return {
        fetchData,
    };
}
