import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReportContext } from '../../ReportContext';
import { useCallback } from 'react';
import { getInstagramUserImages } from '@round/api';

export default function useInstagramUserImages() {
    const state = useNonNullContextSelector(ReportContext, ([values]) => values.instagramUserImages);
    const dispatch = useNonNullContextSelector(ReportContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (userIds: number[], requestInit?: RequestInit) => {
            if (!userIds.length) {
                dispatch({ type: 'instagramUserImagesLoaded', payload: { ids: [], images: [] } });
                return;
            }

            try {
                dispatch({ type: 'loadInstagramUserImages', payload: userIds });
                const response = await getInstagramUserImages(userIds, requestInit);
                dispatch({ type: 'instagramUserImagesLoaded', payload: { ids: userIds, images: response.data } });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({
                    type: 'instagramUserImagesErrorLoading',
                    payload: { ids: userIds, error: 'Could not load account images' },
                });
                throw e;
            }
        },
        [dispatch]
    );

    return {
        data: state,
        fetchData,
    };
}
