import { creatorbase } from '@round/api';
import { useState } from 'react';
import { showNotification } from 'helpers';
import TableInput from 'ui-new/whitelabel/TableInput/TableInput';
import TruncationPopover from 'ui-new/TruncationPopover/TruncationPopover';
import styles from './NotesCell.module.css';
type Props = {
    notes: string;
    updateNotes?: (notes: string) => ReturnType<typeof creatorbase.patchPost>;
};

const formatEmptyNotes = (notes: string) => (notes === '' ? '-' : notes);

const NotesCell = ({ notes: initialNotes, updateNotes }: Props) => {
    const [isInputFocused, setIsInputFocused] = useState(false);

    const [notes, setNotes] = useState<string>(initialNotes);
    const [isNotesUpdating, setIsNotesUpdating] = useState(false);

    const handleUpdateNotes = async () => {
        if (!updateNotes || notes === initialNotes) {
            return;
        }

        let errorMessage = 'Could not update notes';

        try {
            setIsNotesUpdating(true);

            if (typeof notes !== 'string') {
                showNotification('Notes must be a string', 'error');
                setNotes(initialNotes);
                return;
            }

            const response = await updateNotes(notes);

            if (response.status === 200) {
                setNotes(response.data.notes);
                showNotification('Notes updated', 'info');
                return;
            }

            if (response.status === 400 && 'notes' in response.data) {
                const apiErrorMessage = Array.isArray(response.data.notes)
                    ? response.data.notes.join(', ')
                    : String(response.data.notes);
                errorMessage = apiErrorMessage || errorMessage;
            }

            showNotification(errorMessage, 'error');
            setNotes(initialNotes);
        } catch {
            showNotification(errorMessage, 'error');
            setNotes(initialNotes);
        } finally {
            setIsNotesUpdating(false);
        }
    };

    return (
        <TruncationPopover content={notes} className={styles.popover} isDisabled={isInputFocused}>
            {(setRef) => (
                <TableInput
                    ref={setRef}
                    value={isInputFocused ? notes : formatEmptyNotes(notes)}
                    onFocus={() => setIsInputFocused(true)}
                    onChange={(e) => setNotes(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            e.currentTarget.blur();
                        }
                    }}
                    onBlur={() => {
                        setIsInputFocused(false);
                        handleUpdateNotes();
                    }}
                    disabled={!updateNotes || isNotesUpdating}
                />
            )}
        </TruncationPopover>
    );
};

export default NotesCell;
