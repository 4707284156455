import React, { forwardRef } from 'react';
import Skeleton from '../../Indicators/Skeleton/Skeleton';
import styles from './Badge.module.css';
import cn from 'classnames';

type BadgeProps = {
    children?: React.ReactNode | undefined;
    className?: string;
    onClick?: () => void;
    loading?: boolean;
};

const Badge = forwardRef<HTMLDivElement, BadgeProps>(({ children, className, loading, onClick }, ref) => {
    if (loading) {
        return <Skeleton containerClassName={cn(styles.badge, className, styles.skeleton)} />;
    }

    return (
        <div ref={ref} className={cn(styles.badge, className)} onClick={onClick}>
            {children}
        </div>
    );
});

export default Badge;
