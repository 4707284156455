import { AuthTokenPair } from './auth.types';
import { buildApiUrl } from '../utils';
import { getTokensFromStorage, setTokensToStorage } from './auth.helpers';

/**
 * Signs user in and saves tokens to storage
 * @param email
 * @param password
 */
export async function signIn(email: string, password: string): Promise<AuthTokenPair> {
    const response = await fetch(buildApiUrl('/api/token/'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: email, password }),
    });

    if (!response.ok) {
        throw new Error('Could not authenticate');
    }

    const tokens: AuthTokenPair = await response.json();
    setTokensToStorage(tokens);
    return tokens;
}

/**
 * Refreshes tokens and saves them in storage
 */
export async function refreshTokens(): Promise<AuthTokenPair> {
    const { refresh } = getTokensFromStorage();
    const response = await fetch(buildApiUrl('/api/token/refresh/'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh }),
    });

    if (!response.ok) {
        throw new Error('Could not refresh tokens');
    }

    const tokens: AuthTokenPair = await response.json();
    setTokensToStorage(tokens);
    return tokens;
}

export async function resetPassword(email: string) {
    const response = await fetch(buildApiUrl('/api/password_reset/'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
    });

    if (!response.ok) {
        const body = await response.json();
        const email = Array.isArray(body?.email) ? body.email[0] : null;
        throw new Error(email ?? 'Sorry, we are experiencing technical issue');
    }
}

export async function validateConfirmPasswordToken(token: string) {
    const response = await fetch(buildApiUrl('/api/password_reset/validate_token/'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
    });

    if (!response.ok) {
        throw new Error('Could not validate token');
    }
}

export async function confirmPassword(password: string, token: string) {
    const response = await fetch(buildApiUrl('/api/password_reset/confirm/'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password, token }),
    });

    if (!response.ok) {
        const body = await response.json();
        const password = Array.isArray(body?.password) ? body.password[0] : null;
        throw new Error(password ?? 'Sorry, we are experiencing technical issue');
    }
}
