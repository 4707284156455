import { AppServer } from '../mirage';
import * as influencersMirage from './influencers/influencers.mirage';
import * as tiktokPostsMirage from './tiktokPosts/tiktokPosts.mirage';
import * as tiktokPostInvitesMirage from './tiktokPostInvites/tiktokPostInvites.mirage';
import * as contentTagsMirage from './contentTags/contentTags.mirage';
import * as adminMirage from './admin/admin.mirage';
import * as tiktokPostInviteFormMirage from './tiktokPostInviteForm/tiktokPostInviteForm.mirage';
import * as campaignsMirage from './campaigns/campaigns.mirage';
import * as releaseMirage from './releases/releases.mirage';
import * as paymentRequestMirage from './paymentRequests/paymentRequests.mirage';
import * as postsMirage from './posts/posts.mirage';

export const models = {
    ...influencersMirage.models,
    ...tiktokPostsMirage.models,
    ...tiktokPostInvitesMirage.models,
    ...adminMirage.models,
    ...tiktokPostInviteFormMirage.models,
    ...campaignsMirage.models,
    ...releaseMirage.models,
    ...paymentRequestMirage.models,
    ...postsMirage.models,
};

export const serializers = {
    ...influencersMirage.serializers,
    ...tiktokPostsMirage.serializers,
    ...tiktokPostInvitesMirage.serializers,
    ...adminMirage.serializers,
    ...tiktokPostInviteFormMirage.serializers,
    ...campaignsMirage.serializers,
    ...releaseMirage.serializers,
    ...paymentRequestMirage.serializers,
    ...postsMirage.serializers,
};

export const factories = {
    ...influencersMirage.factories,
    ...tiktokPostsMirage.factories,
    ...tiktokPostInvitesMirage.factories,
    ...adminMirage.factories,
    ...tiktokPostInviteFormMirage.factories,
    ...campaignsMirage.factories,
    ...releaseMirage.factories,
    ...paymentRequestMirage.factories,
    ...postsMirage.factories,
};

export function handleRequests(server: AppServer) {
    influencersMirage.handleRequests(server);
    tiktokPostsMirage.handleRequests(server);
    tiktokPostInvitesMirage.handleRequests(server);
    contentTagsMirage.handleRequests(server);
    adminMirage.handleRequests(server);
    tiktokPostInviteFormMirage.handleRequests(server);
    campaignsMirage.handleRequests(server);
    releaseMirage.handleRequests(server);
    paymentRequestMirage.handleRequests(server);
    postsMirage.handleRequests(server);
}
