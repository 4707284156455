import { useCallback, useContext } from 'react';
import { InfluencerPlanContext } from '../InfluencerPlanContext';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { TiktokCreatorsDispatchContext, TiktokCreatorsStateContext } from './TiktokCreatorsContext';
import { getAllInfluencerPostGroups, getTiktokInfluencerPostGroupStats } from '@round/api';
import { TiktokInfluencerPostGroupStatsWithId } from './reducer';

export default function useTiktokInfluencerPostGroups() {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const state = useNonNullContext(TiktokCreatorsStateContext);
    const dispatch = useNonNullContext(TiktokCreatorsDispatchContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (!influencerPlan?.id) {
                return;
            }

            try {
                dispatch({ type: 'loadInfluencerPostGroups' });
                const response = await getAllInfluencerPostGroups('tiktok', influencerPlan.id, requestInit);
                dispatch({ type: 'influencerPostGroupsInitialized', payload: response });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'setInfluencerPostGroupsLoadingError',
                    payload: "Sorry, we're having some technical issues",
                });
            }
        },
        [dispatch, influencerPlan?.id]
    );

    const initStats = useCallback(
        async (requestInit?: RequestInit) => {
            if (!state.tiktokInfluencerPostGroupsInitialized) {
                return;
            }

            const groupStatsToFetch = state.tiktokInfluencerPostGroups.map((group) => group.id);
            if (!groupStatsToFetch.length && !state.tiktokInfluencerPostGroupStatsInitialized) {
                dispatch({ type: 'influencerPostGroupStatsInitialized', payload: [] });
                return;
            }

            if (state.tiktokInfluencerPostGroupStatsInitialized) {
                return;
            }

            try {
                dispatch({ type: 'loadInfluencerPostGroupStats' });
                const results = await Promise.allSettled(
                    groupStatsToFetch.map((id) =>
                        getTiktokInfluencerPostGroupStats(id, requestInit).then((response) => {
                            if (response.status === 200) {
                                return { ...response.data, group_id: id } as TiktokInfluencerPostGroupStatsWithId;
                            }

                            throw new Error(`Couldn't fetch influencer post group ${id} stats`);
                        })
                    )
                );

                const stats = results
                    .filter(
                        (r): r is PromiseFulfilledResult<TiktokInfluencerPostGroupStatsWithId> =>
                            r.status === 'fulfilled'
                    )
                    .map((r) => r.value);
                dispatch({ type: 'influencerPostGroupStatsInitialized', payload: stats });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'setInfluencerPostGroupStatsLoadingError',
                    payload: "Sorry, we're experiencing technical issues",
                });
            }
        },
        [
            dispatch,
            state.tiktokInfluencerPostGroupStatsInitialized,
            state.tiktokInfluencerPostGroups,
            state.tiktokInfluencerPostGroupsInitialized,
        ]
    );

    return {
        init,
        initStats,
        groups: state.tiktokInfluencerPostGroups,
        groupsLoading: state.tiktokInfluencerPostGroupsLoading,
        groupsLoadingError: state.tiktokInfluencerPostGroupsLoadingError,
        groupsInitialized: state.tiktokInfluencerPostGroupsInitialized,
        groupStats: state.tiktokInfluencerPostGroupStats,
        groupStatsLoading: state.tiktokInfluencerPostGroupStatsLoading,
        groupStatsLoadingError: state.tiktokInfluencerPostGroupStatsLoadingError,
        groupStatsInitialized: state.tiktokInfluencerPostGroupStatsInitialized,
    };
}
