import { ApiResponseError, creatorbase } from '@round/api';
import { FormikErrors } from 'formik';
import * as Yup from 'yup';
import { BrandFormValues } from './BrandForm';
import { mapApiErrorsToFormikErrors } from 'utility/utility';

export const brandFormValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    team: Yup.object().nullable().required('Team is required'),
    image: Yup.mixed()
        .nullable()
        .test('singleFile', 'Only one file is allowed', (value) => {
            if (!value || !Array.isArray(value)) {
                return true;
            }

            return false;
        })
        .test('fileType', 'Invalid file type. Valid types are: jpeg, jpg, png, webp', (value) => {
            const validTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/jpg'];
            if (!value || (value instanceof File && validTypes.includes(value.type))) {
                return true;
            }

            return false;
        })
        .test('fileSize', 'File size is too large. Max size is 8MB', (value) => {
            if (!value || (value instanceof File && value.size <= 8 * 1024 * 1024)) {
                return true;
            }

            return false;
        }),
});

export const convertBrandApiErrorsToFormikErrors = (
    errorObject: ApiResponseError<creatorbase.PostBrandData>
): FormikErrors<BrandFormValues> => {
    const errors = {
        ...errorObject,
        team: errorObject.team_id,
    };

    return mapApiErrorsToFormikErrors(errors);
};
