import React from 'react';
import styles from '../InstagramTable.module.css';
import { asMoney, displayOptionalNumericTableValue, numberWithCommas, roundTo2Dp } from '../../../../../../helpers';
import cn from 'classnames';
import { useCheckUserGroupsAccess } from '../../../../../Auth/hooks/useCheckUserGroupsAccess';
import { ProtectedByUserGroups } from '../../../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import CurrencyInput from 'react-currency-input-field';
import { Currency, InfluencerPostGroup } from '@round/api';
import { cpm } from '../../../../Metrics/Metrics';
import { InstagramTablePostStats } from '../../../types/Instagram.types';

type InstagramTotalsRowProps = {
    currency?: Currency;
    group: InfluencerPostGroup;
    stats: InstagramTablePostStats | undefined;
};

const InstagramTotalsRow = ({ stats = {}, group, currency }: InstagramTotalsRowProps) => {
    const {
        totalViews,
        feedViews,
        plays,
        totalEngagements,
        storyViews,
        comments,
        likes,
        cost,
        clientCost,
        liveCost,
        estimatedViews,
        engagementRate,
    } = stats;
    const canEdit = useCheckUserGroupsAccess(['influencer_editor']);
    const isCostOverBudget = canEdit && clientCost ? clientCost > parseFloat(group.budget) : undefined;

    return (
        <tr className={cn(styles.row, styles.totals)}>
            <td className={styles.td} colSpan={canEdit ? 4 : 3}>
                Total
            </td>
            <ProtectedByUserGroups groups={['influencer_editor']}>
                <td className={cn(styles.td, styles.editorCell)}>
                    <CurrencyInput
                        prefix={currency?.symbol}
                        value={cost}
                        disabled
                        groupSeparator=","
                        decimalSeparator="."
                        allowDecimals={false}
                        decimalScale={0}
                        decimalsLimit={0}
                    />
                </td>
                <td
                    className={cn(styles.td, styles.editorCell, {
                        [styles.negativeCell]: isCostOverBudget === true,
                        [styles.positiveCell]: isCostOverBudget === false,
                    })}
                >
                    {currency?.symbol}
                    {numberWithCommas(roundTo2Dp(clientCost ?? 0))}
                </td>
                <td className={styles.td} />
            </ProtectedByUserGroups>

            {/* Post payment request column */}
            <ProtectedByUserGroups groups={['round_planner']}>
                <td className={styles.td} />
            </ProtectedByUserGroups>

            <ProtectedByUserGroups groups={['influencer_editor']}>
                <td className={cn(styles.td, styles.editorCell)}>{displayOptionalNumericTableValue(estimatedViews)}</td>
                <td className={cn(styles.td, styles.editorCell)}>
                    {currency ? asMoney(cpm(clientCost, estimatedViews), currency, 2) : '-'}
                </td>
                <td className={cn(styles.td, styles.editorCell)}>
                    {currency ? asMoney(cpm(liveCost, totalViews), currency, 2) : '-'}
                </td>
                <td className={styles.td} colSpan={1} />
            </ProtectedByUserGroups>
            <td className={styles.td} colSpan={1} />
            <td className={styles.td}>{displayOptionalNumericTableValue(totalViews)}</td>
            <td className={styles.td}>{displayOptionalNumericTableValue(feedViews)}</td>
            <td className={styles.td}>{displayOptionalNumericTableValue(plays)}</td>
            <td className={styles.td}>{displayOptionalNumericTableValue(storyViews)}</td>
            <td className={styles.td}>{displayOptionalNumericTableValue(totalEngagements)}</td>
            <td className={styles.td}>
                {typeof engagementRate === 'number' && isFinite(engagementRate)
                    ? `${roundTo2Dp(engagementRate * 100)}%`
                    : '-'}
            </td>
            <td className={styles.td}>{displayOptionalNumericTableValue(likes)}</td>
            <td className={styles.td}>{displayOptionalNumericTableValue(comments)}</td>
            <td className={styles.td} />
        </tr>
    );
};

export default InstagramTotalsRow;
