import React from 'react';
import { NavBar } from '../../../../../SharedComponents/NavBar/NavBar';
import styles from './UnauthorisedScreen.module.css';

const UnauthorisedScreen = () => (
    <>
        <NavBar />
        <div className={styles.container}>
            <h1 className={styles.title}>Access Denied!</h1>
            <p className={styles.copy}>You currently don't have access to this page</p>
        </div>
    </>
);

export default UnauthorisedScreen;
