import { ApiResponse, NotFoundResponse, PaginatedApiResponseData, PaginatedRequest } from '../../../types';
import { UserCreator } from './micros.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../../helpers';

type MicrosPlanAudioParams = {
    planId: number;
    tiktokAudioId: number;
};

export type SearchMicrosParams = MicrosPlanAudioParams &
    Partial<
        PaginatedRequest & {
            min_follower_count: number;
            max_follower_count: number;
            location: string;
            similar_audio_ids: string;
            hashtag_ids: string;
            has_influencer_user: string;
            search: string;
            with_genre_affinity: string;
            //genre affinity post count
            min_relevant_post_count: number;
            ordering: '-genre_affinity';
        }
    >;

export async function searchMicros(
    { planId, tiktokAudioId, ...queryParams }: SearchMicrosParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<UserCreator>, 200>> {
    const response = await fetchWithToken(
        `/api/advertising/creator-plans/${planId}/tiktok-audios/${tiktokAudioId}/micro-search/${encodeUrlSearchParams({
            ...queryParams,
        })}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(
            `Could not search micros for plan ${planId}, audio ${tiktokAudioId} with params ${encodeUrlSearchParams({
                ...queryParams,
            })}`
        );
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PreviewMicrosEmailParams = MicrosPlanAudioParams & { userId: number; email_template?: number };
export type MicrosEmailPreview = { subject: string; body: string };
export async function previewMicrosEmail(
    { userId, tiktokAudioId, planId, email_template }: PreviewMicrosEmailParams,
    requestInit?: RequestInit
): Promise<ApiResponse<MicrosEmailPreview, 200> | ApiResponse<string[], 400> | NotFoundResponse> {
    const response = await fetchWithToken(
        `/api/advertising/creator-plans/${planId}/tiktok-audios/${tiktokAudioId}/micros/${userId}/emails/song-promo-initial/preview/${encodeUrlSearchParams(
            { email_template }
        )}`,
        requestInit
    );

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not download preview');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type SendMicrosEmailDataItem = {
    user_id: number;
    email: string;
    template_id: number;
};

export type SendMicrosEmailParams = MicrosPlanAudioParams & {
    data: SendMicrosEmailDataItem[];
};
export async function sendMicrosEmail({
    data,
    planId,
    tiktokAudioId,
}: SendMicrosEmailParams): Promise<ApiResponse<{ created: number[]; failed: number[] }, 201 | 400>> {
    const response = await fetchWithToken(
        `/api/advertising/creator-plans/${planId}/tiktok-audios/${tiktokAudioId}/micros/emails/song-promo-initial/send/`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data }),
        }
    );

    if (response.status === 201 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    throw new Error(`Could not send emails`);
}

export async function previewChaseEmail(
    { planId, tiktokAudioId, userId, email_template }: PreviewMicrosEmailParams,
    requestInit?: RequestInit
): Promise<ApiResponse<MicrosEmailPreview, 200> | ApiResponse<string[], 400> | NotFoundResponse> {
    const response = await fetchWithToken(
        `/api/advertising/creator-plans/${planId}/tiktok-audios/${tiktokAudioId}/micros/${userId}/emails/song-promo-chase/preview/${encodeUrlSearchParams(
            { email_template }
        )}`,
        requestInit
    );

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not download preview');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function sendChaseEmail({
    data,
    planId,
    tiktokAudioId,
}: SendMicrosEmailParams): Promise<ApiResponse<{ created: number[]; failed: number[] }, 201 | 400>> {
    const response = await fetchWithToken(
        `/api/advertising/creator-plans/${planId}/tiktok-audios/${tiktokAudioId}/micros/emails/song-promo-chase/send/`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data }),
        }
    );

    if (response.status === 201 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    throw new Error(`Could not send emails`);
}
