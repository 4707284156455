import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './PromotionsScatterChart.module.css';
import { ChartProps, Scatter } from 'react-chartjs-2';
import { InfluencerPlan } from '../../../../../../../App.types';
import { InstagramInfluencerPost } from '../../../../../../Advertising/InfluencerPlan/types/Instagram.types';
import { PostStatsWithData } from '../../Promotions';
import { InstagramTopVideo } from '@round/api';
import { formatNumberToKNotation } from '../../../../../../../helpers';
import LoadingSpinner from '../../../../../../../SharedComponents/LoadingSpinner/LoadingSpinner';

type PromotionsScatterChartProps = {
    loading: boolean;
    errorLoading: boolean;
    influencerPosts: InstagramInfluencerPost[];
    results: PostStatsWithData[];
    videos: InstagramTopVideo[];
    influencerPlans: InfluencerPlan[];
};

type ScatterDataItem = {
    author: string;
    song: string;
    caption: string;
    x: number | null;
    y: number | null;
    isSponsored?: boolean;
};

function filterItemsWithNullDataPoints(items: ScatterDataItem[]) {
    return items
        .filter(
            (stat): stat is Omit<ScatterDataItem, 'x' | 'y'> & { x: number; y: number } => typeof stat.x === 'number'
        )
        .filter(
            (stat): stat is Omit<ScatterDataItem, 'y' | 'x'> & { y: number; x: number } => typeof stat.y === 'number'
        );
}

const TOOLTIP_WIDTH_IN_PX = 160;

const PromotionsScatterChart = ({
    results,
    influencerPosts,
    videos,
    loading,
    influencerPlans,
}: PromotionsScatterChartProps) => {
    const [tooltipParams, setTooltipParams] = useState<{ x: number; y: number; transformX: number } | null>(null);
    const [tooltipItem, setTooltipItem] = useState<ScatterDataItem | null>(null);
    const sponsoredPosts: ScatterDataItem[] = useMemo(() => {
        const sponsored = videos.filter((video) => !!influencerPosts.find((post) => post.post === video.post_id));

        const videoPostIds = videos.map((v) => v.post_id);
        const influencerPostsWithoutTopVideo = influencerPosts.filter(
            (post) => post.post !== null && !videoPostIds.includes(post.post)
        );
        const postIdsOfPostsNotInVideos = influencerPostsWithoutTopVideo
            .map((p) => p.post)
            .filter((id): id is number => typeof id === 'number');

        const postsNotInVideosResults: ScatterDataItem[] = results
            .filter(
                (result): result is Omit<PostStatsWithData, 'post_id'> & { post_id: number } => result.post_id !== null
            )
            .filter((result) => postIdsOfPostsNotInVideos.includes(result.post_id))
            .map((result) => {
                const post = influencerPostsWithoutTopVideo.find((post) => post.id === result.post_id);
                const plan = influencerPlans.find((plan) => plan.id === post?.plan_id);
                return {
                    author: plan?.release.brand.name ?? '',
                    song: plan?.release.name ?? '',
                    caption: result.desc ?? '',
                    x: result.play_count,
                    y: result.like_count,
                };
            });

        return sponsored
            .map<ScatterDataItem>((video) => {
                const post = influencerPosts.find((p) => p.post !== null && p.post === video.post_id);
                const plan = influencerPlans.find((plan) => plan.id === post?.plan_id);
                return {
                    song: plan?.release.name ?? '',
                    author: plan?.release.brand.name ?? '',
                    caption: video.description,
                    y: video.like_count,
                    x: video.play_count,
                };
            })
            .concat(postsNotInVideosResults)
            .map((item) => ({ ...item, isSponsored: true }));
    }, [influencerPlans, influencerPosts, results, videos]);

    const notSponsoredPosts: ScatterDataItem[] = useMemo(() => {
        return videos
            .filter((video) => !influencerPosts.find((post) => post.post === video.post_id))
            .map((video) => ({
                author: '',
                song: '',
                caption: video.description,
                x: video.play_count,
                y: video.like_count,
                isSponsored: false,
            }));
    }, [influencerPosts, videos]);

    const data: ChartProps<'scatter', ScatterDataItem[]>['data'] = useMemo(
        () => ({
            datasets: [
                {
                    label: 'Sponsored',
                    data: filterItemsWithNullDataPoints(sponsoredPosts),
                    backgroundColor: 'rgb(112, 69, 204, 0.5)',
                    showLine: false,
                },
                {
                    label: 'Not Sponsored',
                    data: filterItemsWithNullDataPoints(notSponsoredPosts),
                    showLine: false,
                },
            ],
        }),
        [notSponsoredPosts, sponsoredPosts]
    );

    const options: ChartProps<'scatter', ScatterDataItem[]>['options'] = useMemo(
        () => ({
            elements: {
                point: {
                    radius: 6,
                    hoverRadius: 7,
                },
            },
            scales: {
                y: {
                    ticks: {
                        count: 3,
                        beginAtZero: true,
                        callback: (value: string | number) => formatNumberToKNotation(Number(value)),
                    },
                    title: {
                        display: true,
                        text: 'Likes',
                    },
                    border: {
                        display: false,
                    },
                    grid: {
                        display: true,
                    },
                },
                x: {
                    ticks: {
                        count: 6,
                        beginAtZero: true,
                        callback: (value: string | number) => formatNumberToKNotation(Number(value)),
                    },
                    title: {
                        display: true,
                        text: 'Views',
                    },
                    grid: {
                        display: false,
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external({ tooltip, chart }) {
                        if (tooltip.opacity === 0) {
                            setTooltipParams(null);
                            setTooltipItem(null);
                        } else {
                            const position = chart.canvas.getBoundingClientRect();
                            const tooltipHalfWidth = TOOLTIP_WIDTH_IN_PX / 2;
                            let left = position.left + tooltip.caretX;
                            let transform = -50;

                            if (tooltip.caretX <= tooltipHalfWidth) {
                                transform = 0;
                            } else if (tooltipHalfWidth >= position.right - (tooltip.caretX + position.left)) {
                                transform = -100;
                            }

                            setTooltipParams({
                                x: left,
                                y: position.top + tooltip.caretY,
                                transformX: transform,
                            });

                            if (Array.isArray(tooltip.dataPoints) && tooltip.dataPoints.length) {
                                const point = tooltip.dataPoints[0];
                                const item = data.datasets[point.datasetIndex]?.data[point.dataIndex];
                                setTooltipItem(item ?? null);
                            }
                        }
                    },
                },
            },
        }),
        [data.datasets]
    );

    return (
        <div className={styles.container}>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <div className={styles.chart}>
                    <Scatter height={75} data={data} options={options} />
                </div>
            )}
            {tooltipParams &&
                tooltipItem &&
                typeof tooltipItem.x === 'number' &&
                typeof tooltipItem.y === 'number' &&
                ReactDOM.createPortal(
                    <div
                        className={styles.tooltip}
                        style={{
                            left: tooltipParams.x + 'px',
                            top: tooltipParams.y + 'px',
                            transform: `translate(${tooltipParams.transformX}%, -115%)`,
                            width: TOOLTIP_WIDTH_IN_PX,
                        }}
                    >
                        <div>
                            <p className={styles.tooltipMetrics}>{formatNumberToKNotation(tooltipItem.x)} views</p>
                            <p className={styles.tooltipMetrics}>{formatNumberToKNotation(tooltipItem.y)} likes</p>
                        </div>
                        {tooltipItem.isSponsored && (
                            <div>
                                <div />
                                <p className={styles.tooltipFooter}>
                                    {tooltipItem.author} - {tooltipItem.song}
                                </p>
                            </div>
                        )}
                        {!tooltipItem.isSponsored && <p className={styles.tooltipFooter}>{tooltipItem.caption}</p>}
                    </div>,
                    document.body
                )}
        </div>
    );
};

export default PromotionsScatterChart;
