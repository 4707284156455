import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { ContentTag } from '../../types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';

export const models = {
    contentTag: Model as ModelDefinition<ContentTag>,
};

export const serializers = {
    contentTag: makeSerializer<ContentTag>([]),
};

export const factories = {
    contentTag: createFactory<ContentTag>({
        name: (index: number) => `Tag ${index}`,
    }),
};

export function handleRequests(server: AppServer) {
    server.get('/api/advertising/content-tags/', function (this: RouteHandlerContext, schema, request) {
        const tags = schema.all('contentTag');
        return buildPaginatedResponse(tags, {
            serialize: (resource) => this.serialize(resource, 'contentTag'),
            url: '/api/advertising/content-tags/',
            queryParams: request.queryParams,
        });
    });
}
