import React, { FunctionComponent, useCallback, useState } from 'react';
import { InfluencerUser, MonitoredUserStats } from '../../App.types';
import ModalRight from '../ModalRight/ModalRight';
import styles from './UserModal.module.css';
import {
    asMoney,
    buildTiktokUserUrl,
    fetchAll,
    fetchWithToken,
    formatDateObjShort,
    numberWithCommas,
    showNotification,
} from '../../helpers';
import Insight from '../Insight/Insight';
import InsightNumber from '../InsightNumber/InsightNumber';
import TiktokStatsMultiLineChart from '../TiktokStatsMultiLineChart/TiktokStatsMultiLineChart';
import CloseIcon from '../svg/Icons/CloseIcon';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { ProtectedByUserGroups } from '../ProtectedByUserGroup/ProtectedByUserGroups';
import InfoIcon from '../svg/Icons/Info';
import { usePopper } from 'react-popper';
import TextArea from '../../ui/TextArea/TextArea';
import useOnClickOutside from '../../Hooks/useOnClickOutside';
import useAbortableEffect from '../../Hooks/useAbortableEffect';
import { TiktokDailyReportItem } from '../../Modules/Advertising/MediaPlan/types/MediaPlanResults.types';
import { Currency, TiktokUserImage, TiktokUser, getTiktokUser } from '@round/api';
import { getAllTiktokUserStats, TiktokUserStats } from '@round/api';

const UserModal: FunctionComponent<{
    isModalOpen: boolean;
    closeModal: () => void;
    userId?: number;
    weeklyNewFollowers?: number;
    weeklyNewLikes?: number;
    profileImage?: TiktokUserImage | undefined;
    url: string;
    showNotesField?: boolean;
    monitoredUserStats?: MonitoredUserStats;
}> = ({
    isModalOpen,
    closeModal,
    userId,
    weeklyNewFollowers,
    weeklyNewLikes,
    profileImage,
    url,
    showNotesField,
    monitoredUserStats,
}) => {
    const [stats, setStats] = useState<TiktokUserStats[]>([]);
    const [dailyItems, setDailyItems] = useState<TiktokDailyReportItem[]>([]);

    const [influencerUser, setInfluencerUser] = useState<InfluencerUser | null>(null);
    const [tiktokUser, setTiktokUser] = useState<TiktokUser | null>(null);
    const [notes, setNotes] = useState('');
    const [showNotesFieldModal, setShowNotesFieldModal] = useState(false);
    const [notesUpdating, setNotesUpdating] = useState(false);

    const [iconRef, setIconRef] = useState<HTMLButtonElement | null>(null);
    const [notesModalRef, setNotesModalRef] = useState<HTMLDivElement | null>(null);
    const { styles: popperStyles, attributes } = usePopper(iconRef, notesModalRef, {
        placement: 'top-start',
        modifiers: [{ name: 'offset', options: { offset: [25, -10] } }],
    });

    const updateInfluencerUser = useCallback(
        async (influencerUser: InfluencerUser) => {
            const response = await fetchWithToken(`${url}/viewsets/influencer-user/${influencerUser.id}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...influencerUser,
                    content_tags: influencerUser.content_tags,
                }),
            });

            if (!response.ok) {
                throw new Error('Could not update influencer user');
            }

            return response.json();
        },
        [url]
    );

    const saveNotes = useCallback(async () => {
        if (!showNotesFieldModal || !influencerUser || notesUpdating || influencerUser.notes === notes) {
            return;
        }

        try {
            setNotesUpdating(true);
            const updatedInfluencerUser = await updateInfluencerUser({
                ...influencerUser,
                notes: notes,
            });

            showNotification('Updated notes', 'info');
            setInfluencerUser(updatedInfluencerUser);
        } catch {
            showNotification('Could not update notes', 'error');
            setNotes(influencerUser.notes);
        } finally {
            setNotesUpdating(false);
        }
    }, [influencerUser, notes, notesUpdating, showNotesFieldModal, updateInfluencerUser]);

    useOnClickOutside([iconRef, notesModalRef], async () => {
        await saveNotes();
        setShowNotesFieldModal(false);
    });

    const onClose = useCallback(() => {
        setStats([]);
        setDailyItems([]);
        closeModal();
        setTiktokUser(null);
    }, [closeModal]);

    useAbortableEffect(
        (signal) => {
            async function fetchStats() {
                if (userId) {
                    const stats = await getAllTiktokUserStats(userId, { signal });
                    const sortedStats = stats.sort(
                        (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
                    );

                    const getDateString = (stats: TiktokUserStats) => {
                        return stats.timestamp.split('T')[0];
                    };
                    const shown = new Set();
                    sortedStats.reverse(); // Reverse Chronological
                    let filteredStats = sortedStats.filter((el) => {
                        const dateString = getDateString(el);
                        const duplicate = shown.has(dateString);
                        shown.add(dateString);
                        return !duplicate;
                    });
                    sortedStats.reverse(); // Chronological
                    if (sortedStats.length > 1 && getDateString(sortedStats[0]) === getDateString(sortedStats[1])) {
                        filteredStats.push(sortedStats[0]);
                    }

                    setStats(
                        filteredStats.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
                    );
                }
            }
            async function fetchTiktokDailyItems() {
                if (userId) {
                    const tiktokItems = await fetchAll<TiktokDailyReportItem>(
                        `${url}/artist-daily-report-items/${userId}/`,
                        { signal }
                    );
                    if (tiktokItems !== undefined) {
                        setDailyItems(tiktokItems);
                    }
                }
            }

            async function fetchInfluencerUser() {
                if (monitoredUserStats) {
                    const response = await fetchWithToken(
                        `${url}/viewsets/influencer-user/${monitoredUserStats.influencer_user_id}/`,
                        { signal }
                    );
                    if (response.ok) {
                        const user = (await response.json()) as InfluencerUser;
                        setInfluencerUser(user);
                        setNotes(user.notes);
                    }
                }
            }
            async function fetchTiktokUser() {
                if (userId) {
                    const tiktokUser = await getTiktokUser(userId, { signal });
                    setTiktokUser(tiktokUser);
                }
            }

            fetchStats();
            fetchTiktokDailyItems();
            fetchInfluencerUser();
            fetchTiktokUser();
        },
        [userId, url, monitoredUserStats]
    );

    const boostedPosts: TiktokDailyReportItem[][] = [];
    for (const item of dailyItems) {
        let foundMatch = false;
        for (const itemArray of boostedPosts) {
            const firstItem = itemArray[0];
            if (firstItem.media_plan_item_id === item.media_plan_item_id && firstItem.ad_name === item.ad_name) {
                itemArray.push(item);
                foundMatch = true;
                break;
            }
        }
        if (!foundMatch) {
            boostedPosts.push([item]);
        }
    }
    const HARDCODED_CURRENCY: Currency = { id: 1, name: 'GBP', symbol: '£' };

    return (
        <ModalRight isModalOpen={isModalOpen} closeModal={onClose}>
            <div className={styles.content}>
                {!stats.length && <LoadingSpinner />}
                <div className={styles.headingBar}>
                    <CloseIcon width={20} onClick={onClose} className={styles.closeIcon} />
                    {tiktokUser && stats[0] && (
                        <>
                            <img
                                src={profileImage?.avatar_thumb.cached_url || tiktokUser.avatar_thumb}
                                alt="user"
                                className={styles.artistImage}
                            />
                            <div>
                                <div className={styles.headingBarContent}>
                                    <div className={styles.topRow}>
                                        <div className={styles.artistName}>{tiktokUser.nickname}</div>
                                        <div className={styles.greyHeading}>
                                            <a
                                                href={buildTiktokUserUrl(tiktokUser.unique_id)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                @{tiktokUser.unique_id}
                                            </a>
                                        </div>
                                        {tiktokUser.signature ? (
                                            <div className={styles.signature}>{tiktokUser.signature}</div>
                                        ) : null}
                                        {tiktokUser.bio_link ? (
                                            <div className={styles.bioLink}>
                                                <a href={tiktokUser.bio_link} target="_blank" rel="noopener noreferrer">
                                                    {tiktokUser.bio_link}
                                                </a>
                                            </div>
                                        ) : (
                                            <div className={styles.bioLink}>No bio yet.</div>
                                        )}
                                    </div>
                                    <div className={`${styles.greyHeading} ${styles.greyHeadingInline}`}>
                                        {numberWithCommas(stats[stats.length - 1].follower_count)} followers
                                    </div>
                                    <div className={`${styles.greyHeading} ${styles.greyHeadingInline}`}>
                                        {numberWithCommas(stats[stats.length - 1].heart)} likes
                                    </div>
                                </div>

                                <div className={styles.notesContainer}>
                                    {showNotesField && (
                                        <ProtectedByUserGroups groups={['influencer_editor', 'user_admin']}>
                                            <button
                                                ref={setIconRef}
                                                className={styles.infoIcon}
                                                onClick={() => setShowNotesFieldModal((prevState) => !prevState)}
                                            >
                                                <InfoIcon height={16} width={16} />
                                            </button>

                                            {showNotesFieldModal && (
                                                <div
                                                    ref={setNotesModalRef}
                                                    className={styles.notesModal}
                                                    style={popperStyles.popper}
                                                    {...attributes.popper}
                                                >
                                                    <p className={styles.notesModalLabel}>Notes</p>
                                                    <TextArea
                                                        className={styles.notesModalTextArea}
                                                        value={notes}
                                                        onChange={(e) => setNotes(e.target.value)}
                                                        onBlur={saveNotes}
                                                    />
                                                </div>
                                            )}
                                        </ProtectedByUserGroups>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {weeklyNewFollowers !== undefined && (
                    <div className={styles.contentContainer}>
                        {weeklyNewLikes !== undefined ? (
                            <Insight>
                                This account has received{' '}
                                <InsightNumber>{numberWithCommas(weeklyNewFollowers)}</InsightNumber> new followers and{' '}
                                <InsightNumber>{numberWithCommas(weeklyNewLikes)}</InsightNumber> new likes in the last
                                7 days.
                            </Insight>
                        ) : (
                            <Insight>
                                This account has received{' '}
                                <InsightNumber>{numberWithCommas(weeklyNewFollowers)}</InsightNumber> new followers in
                                the last 7 days.
                            </Insight>
                        )}
                    </div>
                )}
                {stats.length > 0 && (
                    <div className={styles.contentContainer}>
                        <div className={styles.graphContainer}>
                            <TiktokStatsMultiLineChart
                                datasets={[
                                    {
                                        label: 'total followers',
                                        data: stats.map((stat) => ({
                                            x: new Date(stat.timestamp),
                                            y: stat.follower_count,
                                        })),
                                    },
                                    {
                                        label: 'total likes',
                                        data: stats.map((stat) => ({ x: new Date(stat.timestamp), y: stat.heart })),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                )}

                {boostedPosts.length > 0 ? (
                    <div className={styles.contentContainer}>
                        <div className={styles.tableTitle}>Boosted posts</div>
                        <div className={styles.tableWrapper}>
                            <div className={styles.postTable}>
                                <table className={styles.postTable}>
                                    <thead>
                                        <tr className={styles.columnHeaderRow}>
                                            <th>Ad name</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Spend</th>
                                            <th>Impressions</th>
                                            <th>CPM</th>
                                            <th>Paid Followers</th>
                                            <th>Cost Per Follower</th>
                                        </tr>
                                        <tr>
                                            <td colSpan={8}>
                                                <hr className={styles.hr} />
                                                <hr className={styles.hr} />
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {boostedPosts.map((items) => {
                                            items.sort((a, b) => new Date(a.day).getTime() - new Date(b.day).getTime());

                                            const nameParts = items[0].ad_name.split(' | ');
                                            const name = nameParts.length > 4 ? nameParts[3] : items[0].ad_name;
                                            const minDay = items[0].day;
                                            const maxDay = items[items.length - 1].day;
                                            const spend = items.reduce(
                                                (acc, item) => acc + Number(item.amount_spent),
                                                0
                                            );
                                            const impressions = items.reduce(
                                                (acc, item) => acc + Number(item.impressions),
                                                0
                                            );
                                            const cpm = (1000 * spend) / impressions;
                                            const paidFollowers = items.reduce(
                                                (acc, item) => acc + Number(item.paid_followers),
                                                0
                                            );
                                            const costPerFollower = spend / paidFollowers;

                                            return (
                                                <>
                                                    <tr className={styles.tableRow}>
                                                        <td>{name}</td>
                                                        <td>{formatDateObjShort(new Date(minDay))}</td>
                                                        <td>{formatDateObjShort(new Date(maxDay))}</td>
                                                        <td>{asMoney(spend, HARDCODED_CURRENCY)}</td>
                                                        <td>{numberWithCommas(impressions)}</td>
                                                        <td>{asMoney(cpm, HARDCODED_CURRENCY)}</td>
                                                        <td>{numberWithCommas(paidFollowers)}</td>
                                                        <td>{asMoney(costPerFollower, HARDCODED_CURRENCY)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={8}>
                                                            <hr className={styles.hr} />
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </ModalRight>
    );
};

export default UserModal;
