import React, { useMemo } from 'react';
import { useCheckUserGroupsAccess } from '../../../../Auth/hooks/useCheckUserGroupsAccess';
import PreviousPromotions from './PreviousPromotions/PreviousPromotions';
import PromotionsScatterChart from './PreviousPromotions/PromotionsScatterChart/PromotionsScatterChart';
import styles from './Promotions.module.css';
import { useInstagramUserPromotions } from './useInstagramUserPromotions';

type PromotionsProps = {
    influencerUserId: number | undefined | null;
    userId: number | undefined | null;
};

export type PostStatsWithData = {
    post_id: number | null;
    like_count: number | null;
    comment_count: number | null;
    play_count: number | null;
    create_time: string | null;
    desc: string | null;
};

const Promotions = ({ influencerUserId, userId }: PromotionsProps) => {
    const {
        influencerPosts,
        influencerPlans,
        postResults,
        topVideos,
        instagramPosts,
        promotionsDataLoading,
        errorLoadingPromotionsData,
    } = useInstagramUserPromotions({
        influencerId: influencerUserId,
        userId,
    });
    const canAccess = useCheckUserGroupsAccess(['plan_viewer']);

    const postStatsWithData: PostStatsWithData[] = useMemo(
        () =>
            influencerPosts.map((post) => {
                const result = postResults.find((result) => result.post === post.id);
                const video = topVideos.find((video) => video.post_id === post.post);
                const instagramPost = instagramPosts.find((instagramPost) => instagramPost.id === post.post);

                return {
                    like_count: instagramPost?.like_count ?? video?.like_count ?? result?.likes ?? null,
                    comment_count: instagramPost?.comment_count ?? video?.comment_count ?? result?.comments ?? null,
                    play_count: instagramPost?.play_count ?? result?.feed_views ?? null,
                    post_id: post.post ?? video?.post_id ?? null,
                    create_time: video?.create_time ?? instagramPost?.taken_at ?? null,
                    desc: instagramPost?.text ?? video?.description ?? null,
                };
            }),
        [influencerPosts, postResults, topVideos, instagramPosts]
    );

    if (!canAccess) {
        return <div className={styles.tabContainer}>No campaigns</div>;
    }

    return (
        <div className={styles.tabContainer}>
            <PromotionsScatterChart
                influencerPosts={influencerPosts}
                videos={topVideos}
                results={postStatsWithData}
                loading={promotionsDataLoading}
                errorLoading={errorLoadingPromotionsData}
                influencerPlans={influencerPlans}
            />
            <PreviousPromotions
                results={postStatsWithData}
                posts={influencerPosts}
                influencerPlans={influencerPlans}
                loading={promotionsDataLoading}
                errorLoading={errorLoadingPromotionsData}
            />
        </div>
    );
};

export default Promotions;
