import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useNonNullContext from '../../../../../../../Hooks/useNonNullContext';
import useUrlState from '../../../../../../../Hooks/useUrlState';
import { TiktokCreatorsContext } from '../../../../contexts/TiktokCreatorsContext/TiktokCreatorsContext';
import { MobilePostGroupBar } from '../MobilePostGroupBar/MobilePostGroupBar';
import styles from './CreatorsTab.module.css';
import { Skeleton } from '@round/ui-kit';
import MobileTiktokTable, { MobileTiktokTableRow } from '../MobileTiktokTable/MobileTiktokTable';
import { useTiktokTableDataHelper } from '../../../../hooks/useTiktokTableDataHelper';
import {
    TiktokInfluencerUser,
    patchTiktokInfluencerUser,
    InfluencerPostGroup,
    TiktokAudio,
    getTiktokAudio,
    TiktokInfluencerPost,
} from '@round/api';
import { showNotification } from '../../../../../../../helpers';
import { setManualStatusOnTiktokInfluencerPost } from '../../../../InfluencerPlan.api';
import InfluencerPostStatusModal from '../../../InfluencerPostStatusModal/InfluencerPostStatusModal';
import copy from 'copy-to-clipboard';
import useAbortableEffect from '../../../../../../../Hooks/useAbortableEffect';
import { InfluencerPlanContext } from '../../../../contexts/InfluencerPlanContext';
import { formatTiktokBriefDataToWhatsAppMarkdown } from '../../../copyToClipboard/copyToClipboard.helpers';
import ExpandBar from '../../../../components/ExpandBar/ExpandBar';
import { ProtectedByUserGroups } from '../../../../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import useCreatorPlanTiktokAudios from '../../../../contexts/TiktokCreatorsContext/useCreatorPlanTiktokAudios';
import useTiktokInfluencerPostGroups from '../../../../contexts/TiktokCreatorsContext/useTiktokInfluencerPostGroups';
import useCreatorPlanTiktokInfluencerPosts from '../../../../contexts/TiktokCreatorsContext/useCreatorPlanTiktokInfluencerPosts';

type UrlState = {
    expandedGroups: string;
    expandedBackupTables: string;
};

const TiktokCreatorsTab = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>();

    const [postToEditStatus, setPostToEditStatus] = useState<TiktokInfluencerPost | null>(null);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

    const [currentTiktokAudio, setCurrentTiktokAudio] = useState<TiktokAudio | null>(null);

    const expandedGroups = urlState.expandedGroups?.split(',').map((g) => Number(g)) ?? [];
    const setExpandedGroups = (group: number) => {
        if (expandedGroups.includes(group)) {
            setUrlState({ expandedGroups: expandedGroups.filter((g) => g !== group).join(',') });
            return;
        }
        setUrlState({ expandedGroups: expandedGroups.concat(group).join(',') });
    };

    const expandedBackupTables = urlState.expandedBackupTables?.split(',').map((g) => Number(g)) ?? [];
    const setExpandedBackupTables = (group: number) => {
        if (expandedBackupTables.includes(group)) {
            setUrlState({ expandedBackupTables: expandedBackupTables.filter((g) => g !== group).join(',') });
            return;
        }
        setUrlState({ expandedBackupTables: expandedBackupTables.concat(group).join(',') });
    };

    const { influencerPlan } = useContext(InfluencerPlanContext);

    const { updateTiktokInfluencerPost, updateTiktokInfluencerUser, refreshTiktokInfluencerPost } = useNonNullContext(
        TiktokCreatorsContext
    );

    const {
        groupsInitialized,
        groups: tiktokInfluencerPostGroups,
        initStats: initTiktokInfluencerPostGroupStats,
        groupStatsInitialized: tiktokInfluencerPostGroupStatsInitialized,
        groupStats: tiktokInfluencerPostGroupStats,
    } = useTiktokInfluencerPostGroups();

    const {
        init: initInfluencerPosts,
        initialized: influencerPostsInitialized,
        influencerUsers,
        userStats,
        userImages,
    } = useCreatorPlanTiktokInfluencerPosts();

    const { influencerPlanAudios: tiktokAudios } = useCreatorPlanTiktokAudios();

    const fetchTiktokAudio = useCallback(
        async (requestInit: RequestInit) => {
            const audioId = tiktokAudios.find((audio) => audio.plan === influencerPlan?.id)?.audio_id;
            if (!audioId) {
                return;
            }

            try {
                const response = await getTiktokAudio(audioId, requestInit);
                if (response.status === 404) {
                    throw new Error(`Couldn't get tiktok audio`);
                }

                setCurrentTiktokAudio(response.data);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                const errorMessage = e instanceof Error ? e.message : 'Could not get tiktok audio';
                showNotification(errorMessage, 'error');
            }
        },
        [influencerPlan?.id, tiktokAudios]
    );

    useAbortableEffect(
        (signal) => {
            fetchTiktokAudio({ signal });
        },
        [fetchTiktokAudio]
    );

    const copyBriefToClipboard = useCallback(
        (group: InfluencerPostGroup) => {
            if (!influencerPlan) {
                return;
            }

            const formattedBriefData = formatTiktokBriefDataToWhatsAppMarkdown({
                release: influencerPlan.release,
                sound: currentTiktokAudio,
                brief: group.brief,
                turnaround: group.brief_turnaround,
            });

            const success = copy(formattedBriefData, { format: 'text/plain' });

            if (success) {
                showNotification('Copied!', 'info');
            } else {
                showNotification('Could not copy', 'error');
            }
        },
        [influencerPlan, currentTiktokAudio]
    );

    useAbortableEffect(
        (signal) => {
            if (!influencerPostsInitialized) {
                initInfluencerPosts({ signal });
            }
        },
        [influencerPostsInitialized, initInfluencerPosts]
    );

    useEffect(() => {
        if (!tiktokInfluencerPostGroupStatsInitialized) {
            initTiktokInfluencerPostGroupStats();
        }
    }, [initTiktokInfluencerPostGroupStats, tiktokInfluencerPostGroupStatsInitialized]);

    const {
        sortedActiveInfluencerPosts: activePosts,
        sortedBackupInfluencerPosts: backupPosts,
    } = useTiktokTableDataHelper();

    const activeRows: MobileTiktokTableRow[] = useMemo(
        () =>
            activePosts.map((post) => {
                const influencer = influencerUsers.find((user) => user.id === post.influencer_id);
                const tiktokUserStats = userStats.find(
                    (stats) => stats.user_id === (influencer?.user ?? post.tiktok_user)
                );
                const profileImage = userImages.find(
                    (image) => image.user_id === (influencer?.user ?? post.tiktok_user)
                );
                const imageUrl = profileImage?.avatar_thumb.cached_url || profileImage?.avatar_thumb.original_url || '';

                return {
                    groupId: post.group_id,
                    post,
                    influencer: influencer ?? null,
                    userStats: tiktokUserStats ?? null,
                    imageUrl,
                };
            }),
        [activePosts, influencerUsers, userStats, userImages]
    );

    const backupRows: MobileTiktokTableRow[] = useMemo(
        () =>
            backupPosts.map((post) => {
                const influencer = influencerUsers.find((user) => user.id === post.influencer_id);
                const tiktokUserStats = userStats.find(
                    (stats) => stats.user_id === (influencer?.user ?? post.tiktok_user)
                );
                const profileImage = userImages.find(
                    (image) => image.user_id === (influencer?.user ?? post.tiktok_user)
                );
                const imageUrl = profileImage?.avatar_thumb.cached_url || profileImage?.avatar_thumb.original_url || '';

                return {
                    groupId: post.group_id,
                    post,
                    influencer: influencer ?? null,
                    userStats: tiktokUserStats ?? null,
                    imageUrl,
                };
            }),
        [backupPosts, influencerUsers, userStats, userImages]
    );

    const updateContactDetails = useCallback(
        async (influencer: TiktokInfluencerUser, contactDetails: string) => {
            try {
                const response = await patchTiktokInfluencerUser(influencer.id, { contact_details: contactDetails });
                if (response.status === 200) {
                    updateTiktokInfluencerUser(response.data);
                    showNotification('Contact details updated', 'info');
                    return;
                }

                if (typeof response.data.contact_details === 'string') {
                    throw new Error(response.data.contact_details);
                }

                throw new Error('Could not update contact details');
            } catch (e) {
                const errorMessage = e instanceof Error ? e.message : 'Could not update contact details';
                showNotification(errorMessage, 'error');
                throw e;
            }
        },
        [updateTiktokInfluencerUser]
    );

    const updateCost = useCallback(
        async (post: TiktokInfluencerPost, cost: string) => {
            try {
                const response = await updateTiktokInfluencerPost(post.id, { cost });
                if (response.status === 200) {
                    showNotification('Cost updated', 'info');
                    return;
                }

                throw new Error('Could not update cost');
            } catch (e) {
                showNotification('Could not update cost', 'error');
                throw e;
            }
        },
        [updateTiktokInfluencerPost]
    );

    const updateNotes = useCallback(
        async (post: TiktokInfluencerPost, updatedNotes: string) => {
            try {
                const response = await updateTiktokInfluencerPost(post.id, { notes: updatedNotes });
                if (response.status === 200) {
                    showNotification('Notes updated', 'info');
                    return;
                }

                throw new Error('Could not update notes');
            } catch (e) {
                showNotification('Could not update notes', 'error');
                throw e;
            }
        },
        [updateTiktokInfluencerPost]
    );

    const onEditStatusClick = useCallback(async (post: TiktokInfluencerPost) => {
        setPostToEditStatus(post);
        setIsStatusModalOpen(true);
    }, []);

    const updateStatus = useCallback(
        async (status: TiktokInfluencerPost['status'], draftExpectedBy: string | null) => {
            if (!postToEditStatus) {
                return;
            }
            try {
                await setManualStatusOnTiktokInfluencerPost({
                    status,
                    draft_expected_by: draftExpectedBy,
                    influencer_post_id: postToEditStatus.id,
                });
                refreshTiktokInfluencerPost(postToEditStatus.id);
                showNotification('Status updated', 'info');
                setPostToEditStatus(null);
                setIsStatusModalOpen(false);
            } catch {
                showNotification('Could not set status', 'error');
            }
        },
        [refreshTiktokInfluencerPost, postToEditStatus]
    );

    if (!groupsInitialized || !tiktokInfluencerPostGroupStatsInitialized) {
        return (
            <>
                <Skeleton className={styles.skeleton} />
                <Skeleton className={styles.skeleton} />
                <Skeleton className={styles.skeleton} />
            </>
        );
    }

    if (!tiktokInfluencerPostGroups.length) {
        return (
            <div className={styles.noGroupsMessage}>
                <p>No post groups found</p>
            </div>
        );
    }

    return (
        <>
            {tiktokInfluencerPostGroups
                .sort((a, b) => a.ordering_index - b.ordering_index)
                .map((group) => {
                    const isExpanded = expandedGroups.includes(group.id);
                    const groupStats = tiktokInfluencerPostGroupStats.find((s) => s.group_id === group.id);
                    const groupActiveRows = activeRows.filter((row) => row.post.group_id === group.id);
                    const groupBackupRows = backupRows.filter((row) => row.post.group_id === group.id);

                    return (
                        <div key={group.id}>
                            <MobilePostGroupBar
                                platform="tiktok"
                                group={group}
                                groupStats={groupStats}
                                expandedGroups={expandedGroups}
                                checkGroup={setExpandedGroups}
                                copyBrief={copyBriefToClipboard}
                            />
                            {isExpanded && (
                                <>
                                    <MobileTiktokTable
                                        data={groupActiveRows}
                                        updateContactDetails={updateContactDetails}
                                        updateCost={updateCost}
                                        updateNotes={updateNotes}
                                        onEditStatusClick={onEditStatusClick}
                                    />
                                    <ProtectedByUserGroups groups={['influencer_editor']}>
                                        <ExpandBar
                                            isOpen={expandedBackupTables.includes(group.id)}
                                            title={`${groupBackupRows.length} backup creator${
                                                groupBackupRows.length !== 1 ? 's' : ''
                                            }`}
                                            onExpandClicked={() => setExpandedBackupTables(group.id)}
                                        >
                                            <MobileTiktokTable
                                                data={groupBackupRows}
                                                updateContactDetails={updateContactDetails}
                                                updateCost={updateCost}
                                                updateNotes={updateNotes}
                                                onEditStatusClick={onEditStatusClick}
                                            />
                                        </ExpandBar>
                                    </ProtectedByUserGroups>
                                </>
                            )}
                        </div>
                    );
                })}
            <InfluencerPostStatusModal
                status={postToEditStatus?.status ?? null}
                draftExpectedBy={postToEditStatus?.draft_expected_by}
                isModalOpen={isStatusModalOpen}
                onClose={() => setIsStatusModalOpen(false)}
                onChange={updateStatus}
            />
        </>
    );
};

export default TiktokCreatorsTab;
