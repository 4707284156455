import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { YoutubeInfluencerPostGroup, YoutubeInfluencerPostGroupStats } from './youtubeInfluencerPostGroups.types';

export const factories = {
    youtubeInfluencerPostGroup: createFactory<YoutubeInfluencerPostGroup>({
        name(index: number) {
            return `name ${index}`;
        },
        ordering_index(index: number) {
            return index;
        },
        influencer_plan(index: number) {
            return index * 10;
        },
        budget(index: number) {
            return `${index}00`;
        },
        brief: null,
        brief_turnaround: null,
        is_hidden: false,
        invoice_request_status: null,
    }),
    youtubeInfluencerPostGroupStats: createFactory<YoutubeInfluencerPostGroupStats>({
        group_id(index: number) {
            return index;
        },
        live_post_count(index: number) {
            return index;
        },
        post_count(index: number) {
            return index * 2;
        },
        total_views(index: number) {
            return index * 1000;
        },
        engagement_rate(index: number) {
            return index / 10;
        },
        first_post_dt: null,
        last_post_dt: null,
    }),
};

export const models = {
    youtubeInfluencerPostGroup: Model as ModelDefinition<YoutubeInfluencerPostGroup>,
    youtubeInfluencerPostGroupStats: Model as ModelDefinition<YoutubeInfluencerPostGroupStats>,
};

export const serializers = {
    youtubeInfluencerPostGroup: makeSerializer<YoutubeInfluencerPostGroup>([]),
    youtubeInfluencerPostGroupStats: makeSerializer<YoutubeInfluencerPostGroupStats>([]),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/advertising/youtube-influencer-post-groups/';
    server.get(rootUrl, function (this: RouteHandlerContext, schema, request) {
        const planId = request.queryParams['influencer_plan_id'];

        const groups = schema.all('youtubeInfluencerPostGroup').filter((group) => {
            return group.influencer_plan === Number(planId);
        });
        return buildPaginatedResponse(groups, {
            queryParams: request.queryParams,
            url: rootUrl,
            serialize: (resource) => this.serialize(resource, 'youtubeInfluencerPostGroup'),
        });
    });

    server.post(rootUrl, (schema, request) => {
        const attrs = JSON.parse(request.requestBody);
        return schema.create('youtubeInfluencerPostGroup', attrs);
    });

    server.patch(`${rootUrl}/:id/`, (schema, request) => {
        const id = request.params.id;
        const attrs = JSON.parse(request.requestBody);
        const group = schema.find('youtubeInfluencerPostGroup', id);
        if (!group) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        group.update(attrs);
        return group;
    });

    server.delete(`${rootUrl}/:id/`, (schema, request) => {
        const id = request.params.id;
        schema.find('youtubeInfluencerPostGroup', id)?.destroy();
        return new Response(204);
    });

    server.get('/api/advertising/youtube-influencer-post-groups-live-stats/', (schema, request) => {
        const groupIdsParam: string = request.queryParams['id'];
        const groupIds = groupIdsParam.split(',');

        const stats = schema.all('youtubeInfluencerPostGroupStats').filter((stat) => {
            return groupIds.includes(String(stat.group_id));
        });

        return stats;
    });
}
