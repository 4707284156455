import { creatorbase } from '@round/api';
import { DataState } from 'App.types';
import useNonNullContext from 'Hooks/useNonNullContext';
import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useContext, useState } from 'react';

type State = DataState<creatorbase.AggregatedPostStats>;

const initialState: State = {
    status: 'idle',
    data: null,
    error: null,
};

const PlatformStatsContext = createContext<[State, Dispatch<SetStateAction<State>>] | null>(null);

type Props = { children?: ReactNode };
export const PlatformStatsProvider = ({ children }: Props) => {
    const state = useState<State>(initialState);

    return <PlatformStatsContext.Provider value={state}>{children}</PlatformStatsContext.Provider>;
};

export function useAggregatedPlatformStats() {
    const [state, setState] = useNonNullContext(PlatformStatsContext);

    const fetchData = useCallback(
        async (projectId: number, platform: string, abortSignal?: AbortSignal) => {
            setState({ status: 'loading', data: null, error: null });

            try {
                const response = await creatorbase.postGetAggregatedPostStats(
                    {
                        project_id: projectId,
                        platform,
                    },
                    abortSignal
                );

                if (response.status !== 200) {
                    setState({ status: 'error', data: null, error: `Couldn't get aggregated stats` });
                    return response;
                }

                setState({ status: 'success', data: response.data, error: null });
                return response;
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    setState({ status: 'idle', data: null, error: null });
                    throw error;
                }

                setState({ status: 'error', data: null, error: `Couldn't get aggregated stats` });
                throw error;
            }
        },
        [setState]
    );

    return {
        ...state,
        fetchData,
    };
}

//creates a stats refresh handler that can be safely called even if the context is not provided/initialized
//this allows us to call it in campaigns context, which is initialized before platform stats context
export function useRefreshPlatformStats() {
    const platformStatsContextValues = useContext(PlatformStatsContext);

    const isContextProvided = platformStatsContextValues !== null;

    const [, setState] = platformStatsContextValues || [];

    return useCallback(() => {
        if (!isContextProvided) {
            console.warn('PlatformStatsContext is not provided');
        }

        if (!setState) {
            return;
        }

        setState(initialState);
    }, [setState, isContextProvided]);
}
