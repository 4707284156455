import {
    AudienceNetworkPosition,
    FacebookDevicePlatform,
    FacebookPosition,
    FacebookPublisherPlatform,
    InstagramPosition,
    MessengerPosition,
    ReducerActionWithPayload,
} from '../../../../../App.types';
import { createReducer } from '../../../../../helpers';
import { CustomAudienceRefreshRequest, FacebookCustomAudience } from '../../types/Facebook.types';
import { Channel, Format, OptimisationMetric, BuyPlatform, BuyMethod, Objective } from '@round/api';

export type MediaPlanOptionsState = {
    optionsInitialized: boolean;
    optionsLoadingError: string;
    channelOptions: Channel[];
    optimisationMetricOptions: OptimisationMetric[];
    buyPlatformOptions: BuyPlatform[];
    buyMethodOptions: BuyMethod[];
    objectiveOptions: Objective[];
    formatOptions: Format[];
    facebookDeviceOptions: FacebookDevicePlatform[];
    facebookPublisherPlatformOptions: FacebookPublisherPlatform[];
    facebookPositionOptions: FacebookPosition[];
    instagramPositionOptions: InstagramPosition[];
    messengerPositionOptions: MessengerPosition[];
    audienceNetworkPositionOptions: AudienceNetworkPosition[];
    customAudiencesLoading: boolean;
    errorLoadingCustomAudiences: boolean;
    customAudiences: FacebookCustomAudience[];
    customAudienceRefreshRequests: CustomAudienceRefreshRequest[];
    errorLoadingCustomAudienceRefreshRequests: boolean;
};

type Actions =
    | ReducerActionWithPayload<'loadedChannelOptions', Channel[]>
    | ReducerActionWithPayload<'loadedFormatOptions', Format[]>
    | ReducerActionWithPayload<'loadedObjectiveOptions', Objective[]>
    | ReducerActionWithPayload<'loadedOptimisationMetricOptions', OptimisationMetric[]>
    | ReducerActionWithPayload<'loadedBuyMethodOptions', BuyMethod[]>
    | ReducerActionWithPayload<'loadedBuyPlatformOptions', BuyPlatform[]>
    | ReducerActionWithPayload<'loadedFacebookDeviceOptions', FacebookDevicePlatform[]>
    | ReducerActionWithPayload<'loadedFacebookPositionOptions', FacebookPosition[]>
    | ReducerActionWithPayload<'loadedFacebookPublisherPlatformOptions', FacebookPublisherPlatform[]>
    | ReducerActionWithPayload<'loadedInstagramPositionOptions', InstagramPosition[]>
    | ReducerActionWithPayload<'loadedMessengerPositionOptions', MessengerPosition[]>
    | ReducerActionWithPayload<'loadedAudienceNetworkPositionOptions', AudienceNetworkPosition[]>
    | ReducerActionWithPayload<'setInitialized', boolean>
    | ReducerActionWithPayload<'setOptionsLoadingError', string>
    | ReducerActionWithPayload<'loadedCustomAudiences', FacebookCustomAudience[]>
    | ReducerActionWithPayload<'setErrorLoadingCustomAudiences', boolean>
    | ReducerActionWithPayload<'setLoadingCustomAudiences', boolean>
    | ReducerActionWithPayload<'loadedCustomAudienceRefreshRequests', CustomAudienceRefreshRequest[]>
    | ReducerActionWithPayload<'setErrorLoadingCustomAudienceRefreshRequests', boolean>;

export default createReducer<MediaPlanOptionsState, Actions>({
    loadedAudienceNetworkPositionOptions: (state, action) => ({
        ...state,
        audienceNetworkPositionOptions: action.payload,
    }),
    loadedChannelOptions: (state, action) => ({
        ...state,
        channelOptions: action.payload,
    }),
    loadedBuyMethodOptions: (state, action) => ({
        ...state,
        buyMethodOptions: action.payload,
    }),
    loadedBuyPlatformOptions: (state, action) => ({
        ...state,
        buyPlatformOptions: action.payload,
    }),
    loadedFacebookDeviceOptions: (state, action) => ({
        ...state,
        facebookDeviceOptions: action.payload,
    }),
    loadedFacebookPositionOptions: (state, action) => ({
        ...state,
        facebookPositionOptions: action.payload,
    }),
    loadedFacebookPublisherPlatformOptions: (state, action) => ({
        ...state,
        facebookPublisherPlatformOptions: action.payload,
    }),
    loadedFormatOptions: (state, action) => ({
        ...state,
        formatOptions: action.payload,
    }),
    loadedInstagramPositionOptions: (state, action) => ({
        ...state,
        instagramPositionOptions: action.payload,
    }),
    loadedMessengerPositionOptions: (state, action) => ({
        ...state,
        messengerPositionOptions: action.payload,
    }),
    loadedObjectiveOptions: (state, action) => ({
        ...state,
        objectiveOptions: action.payload,
    }),
    loadedOptimisationMetricOptions: (state, action) => ({
        ...state,
        optimisationMetricOptions: action.payload,
    }),
    setInitialized: (state, action) => ({
        ...state,
        optionsInitialized: action.payload,
    }),
    setOptionsLoadingError: (state, action) => ({
        ...state,
        optionsLoadingError: action.payload,
    }),
    loadedCustomAudiences: (state, action) => ({
        ...state,
        customAudiences: action.payload,
    }),
    setErrorLoadingCustomAudiences: (state, action) => ({
        ...state,
        errorLoadingCustomAudiences: action.payload,
    }),
    setLoadingCustomAudiences: (state, action) => ({
        ...state,
        customAudiencesLoading: action.payload,
    }),
    setErrorLoadingCustomAudienceRefreshRequests: (state, action) => ({
        ...state,
        errorLoadingCustomAudienceRefreshRequests: action.payload,
    }),
    loadedCustomAudienceRefreshRequests: (state, action) => ({
        ...state,
        customAudienceRefreshRequests: action.payload,
    }),
});
