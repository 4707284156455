import React, { useState } from 'react';
import styles from './InitialEmailPreview.module.css';
import { contact, sendMicrosEmail } from '@round/api';
import { Button, Select } from '@round/ui-kit';
import { showNotification } from '../../../../../../helpers';
import { GenericDropdownOption } from '../../../../../../App.types';
import useInitialEmailPreview from './useInitialEmailPreview';
import useEmailTemplatesSelect from '../useEmailTemplatesSelect';
import cn from 'classnames';
import EmailContent from '../EmailContent/EmailContent';
import { notificationTypeLabels } from '../../helpers';

export type PreviewUser = {
    user_id: number;
    nickname: string;
    email: string;
};

type Props = {
    creators: PreviewUser[];
    influencerPlanId: number;
    tiktokAudioId: number;
    onEmailSent: (userIds: number[]) => void;
    notificationType: contact.NotificationType;
};

const InitialEmailPreview = ({ influencerPlanId, tiktokAudioId, creators, onEmailSent, notificationType }: Props) => {
    const {
        status: templatesStatus,
        templateOptions,
        error: templatesError,
        selectedTemplate,
        setSelectedTemplate,
    } = useEmailTemplatesSelect(notificationType);

    const [selectedCreator, setSelectedCreator] = useState<GenericDropdownOption<number>>({
        value: creators[0].user_id,
        label: creators[0].nickname,
    });

    const { previewState, resetPreview } = useInitialEmailPreview({
        tiktokAudioId,
        userId: selectedCreator.value,
        planId: influencerPlanId,
        email_template: selectedTemplate?.value,
    });

    const [emailData, setEmailData] = useState<PreviewUser[]>(creators);

    const [isSending, setIsSending] = useState(false);
    const handleSend = async () => {
        if (previewState.status !== 'initialized' || !selectedTemplate) {
            return;
        }

        try {
            setIsSending(true);
            const response = await sendMicrosEmail({
                data: emailData.map((previewUser) => ({
                    user_id: previewUser.user_id,
                    email: previewUser.email,
                    template_id: selectedTemplate.value,
                })),
                tiktokAudioId: tiktokAudioId,
                planId: influencerPlanId,
            });

            if (response.status === 201) {
                showNotification('Success', 'info');
                onEmailSent(response.data.created);
                return;
            }

            showNotification('Something went wrong', 'error');
        } catch (e) {
            if (e instanceof Error) {
                showNotification(e.message, 'error');
                return;
            }

            showNotification('Something went wrong', 'error');
        } finally {
            setIsSending(false);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {creators.length > 1 ? (
                    <div className={styles.control}>
                        <label className={styles.controlLabel}>Creator</label>
                        <Select
                            isClearable={false}
                            value={selectedCreator}
                            onChange={(creator) => {
                                setSelectedCreator(creator!);
                                resetPreview();
                            }}
                            options={creators.map((c) => ({ value: c.user_id, label: c.nickname }))}
                        />
                    </div>
                ) : null}

                <div className={styles.control}>
                    <label className={styles.controlLabel}>Email</label>
                    <input
                        className={styles.emailInput}
                        value={emailData.find((d) => d.user_id === selectedCreator.value)?.email}
                        onChange={(e) => {
                            setEmailData((emailData) =>
                                emailData.map((item) => {
                                    if (item.user_id === selectedCreator.value) {
                                        return { ...item, email: e.target.value };
                                    }

                                    return item;
                                })
                            );
                        }}
                    />
                </div>

                <div className={cn(styles.control, styles.templateControl)}>
                    <label className={styles.controlLabel}>{notificationTypeLabels[notificationType]} Templates</label>
                    <Select
                        isLoading={templatesStatus === 'not-initialized'}
                        options={templateOptions}
                        value={selectedTemplate}
                        onChange={(value) => {
                            if (value) {
                                setSelectedTemplate(value);
                                resetPreview();
                            }
                        }}
                        noOptionsMessage={() => templatesError || 'No options'}
                    />
                </div>
            </div>

            <EmailContent
                isLoading={previewState.status === 'not-initialized' || previewState.status === 'loading'}
                error={previewState.error}
                preview={previewState.preview}
            />

            <Button
                type="filled"
                color="black"
                loading={isSending}
                disabled={previewState.status !== 'initialized'}
                onClick={handleSend}
            >
                Send
            </Button>
        </div>
    );
};

export default InitialEmailPreview;
