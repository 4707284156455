import React, { useCallback, useRef } from 'react';
import {
    List as VirtualizedList,
    ListRowProps as VirtualizedListRowProps,
    CellMeasurerCache,
    CellMeasurer,
    AutoSizer,
    InfiniteLoader,
    InfiniteLoaderProps,
} from 'react-virtualized';

export type ListItemProps<T> = VirtualizedListRowProps & {
    item: T | undefined;
    loading?: boolean;
    clearCache: (rowIndex: number, columnIndex: number) => void;
};

export type ListProps<T> = {
    data: T[];
    children: (props: ListItemProps<T>) => React.ReactNode;
    rowCount: number;
    loadMoreRows?: InfiniteLoaderProps['loadMoreRows'];
    loading?: boolean;
    defaultHeight?: number;
    overscanRowCount?: number;
};

const List = <T extends any>({
    data,
    children,
    rowCount,
    loadMoreRows,
    loading,
    defaultHeight = 50,
    overscanRowCount = 3,
}: ListProps<T>) => {
    const cache = useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight,
        })
    );

    const handleLoadMore = useCallback<InfiniteLoaderProps['loadMoreRows']>(
        async (params) => {
            if (typeof loadMoreRows === 'function') {
                await loadMoreRows(params);
            }
        },
        [loadMoreRows]
    );

    const clearCache = (rowIndex: number, columnIndex: number) => {
        cache.current.clear(rowIndex, columnIndex);
    };

    return (
        // @ts-ignore
        <AutoSizer>
            {({ width, height }) => (
                // @ts-ignore
                <InfiniteLoader
                    loadMoreRows={handleLoadMore}
                    isRowLoaded={({ index }) => !!data[index]}
                    rowCount={rowCount}
                >
                    {({ onRowsRendered, registerChild }) => (
                        // @ts-ignore
                        <VirtualizedList
                            ref={registerChild}
                            onRowsRendered={onRowsRendered}
                            rowCount={rowCount}
                            deferredMeasurementCache={cache.current}
                            width={width}
                            height={height}
                            rowHeight={cache.current.rowHeight}
                            rowRenderer={(props) => {
                                return (
                                    // @ts-ignore
                                    <CellMeasurer
                                        key={props.key}
                                        cache={cache.current}
                                        parent={props.parent}
                                        columnIndex={0}
                                        rowIndex={props.index}
                                    >
                                        {children({
                                            ...props,
                                            clearCache,
                                            loading: loading && !data[props.index],
                                            item: data[props.index],
                                        })}
                                    </CellMeasurer>
                                );
                            }}
                            overscanRowCount={overscanRowCount}
                        />
                    )}
                </InfiniteLoader>
            )}
        </AutoSizer>
    );
};

export default List;
