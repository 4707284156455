import { PatchYoutubeInfluencerPostGroupResponse, PatchYoutubeInfluencerPostGroupBody } from '@round/api';
import { mapApiErrorsToFormikErrors } from 'utility/utility';

export const getPostGroupPatchKeyErrorMessage = (
    response: PatchYoutubeInfluencerPostGroupResponse,
    key: keyof PatchYoutubeInfluencerPostGroupBody
): string => {
    if (response.status === 400) {
        return Array.isArray(response.data)
            ? response.data.join(', ')
            : mapApiErrorsToFormikErrors(response.data)[key] || 'Failed to update group';
    }
    return 'Failed to update group';
};
