import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PaginatedApiResponseData, DropdownOption, FormField, GenericDropdownOption } from '../../App.types';
import AdminPage from '../../SharedComponents/AdminPage/AdminPage';
import * as yup from 'yup';
import {
    fetchWithToken,
    getBrands,
    getSong,
    getSongs,
    Currency,
    FacebookAdAccount,
    getAdAccounts,
    Client,
    BrandFormValue,
    Brand,
    Song,
    getArtists,
    getArtist,
    Artist,
    getAllReleaseTypes,
    Release,
    Channel,
    Format,
    getAllBuyPlatforms,
    BuyPlatform,
    Objective,
    OptimisationMetric,
} from '@round/api';
import { OptionProps } from 'react-select';
import styles from './AdminPages.module.css';

export const ClientAdmin = () => {
    const [adAccounts, setAdAccounts] = useState<FacebookAdAccount[]>([]);

    const url = '/api/advertising/viewsets/client/staff/';
    const formFields: FormField[] = useMemo(
        () => [
            { name: 'name', label: 'Name', type: 'text' },
            {
                name: 'facebook_ad_account',
                label: 'Facebook Ad Account',
                type: 'select',
                clearable: true,
                options: adAccounts.map((adAccount) => ({
                    value: adAccount.id,
                    label: adAccount.name,
                })),
            },
            {
                name: 'media_commission_multiplier',
                label: 'Media Client Commission (%)',
                type: 'number',
                validation: yup.string().required().label('Client commission'),
            },
            {
                name: 'instagram_influencer_commission_multiplier',
                label: 'Instagram Influencer Client Commission (%)',
                type: 'number',
                validation: yup.string().required().label('Instagram client commission'),
            },
            {
                name: 'tiktok_influencer_commission_multiplier',
                label: 'TikTok Influencer Client Commission (%)',
                type: 'number',
                validation: yup.string().required().label('TikTok client commission'),
            },
        ],
        [adAccounts]
    );

    const formatCommissionToFormValue = (val: string) => parseFloat((Number(val) * 100).toFixed(4)).toString();
    const formatCommissionToApiValue = (val: string) => (Number(val) / 100).toFixed(4);

    const listDisplay = [
        { heading: 'Name', instanceToProperty: (client: Client) => client.name },
        {
            heading: 'Media Client Commission',
            instanceToProperty: (client: Client) =>
                `${formatCommissionToFormValue(client.media_commission_multiplier)}%`,
        },
        {
            heading: 'Instagram Influencer Client Commission',
            instanceToProperty: (client: Client) =>
                `${formatCommissionToFormValue(client.instagram_influencer_commission_multiplier)}%`,
        },
        {
            heading: 'TikTok Influencer Client Commission',
            instanceToProperty: (client: Client) =>
                `${formatCommissionToFormValue(client.tiktok_influencer_commission_multiplier)}%`,
        },
    ];

    useEffect(() => {
        getAdAccounts().then((adAccounts) => setAdAccounts(adAccounts));
    }, []);

    return (
        <AdminPage
            modelName="Client"
            url={(method, id) => {
                if (['DELETE', 'PUT', 'PATCH'].includes(method ?? '')) {
                    return `/api/advertising/viewsets/client/${id}/staff/`;
                }

                return url;
            }}
            initialValues={{
                name: '',
                facebook_ad_account: null,
                media_commission_multiplier: '',
                instagram_influencer_commission_multiplier: '',
                tiktok_influencer_commission_multiplier: '',
            }}
            instanceToFormValues={(instance: Client) => ({
                name: instance.name,
                facebook_ad_account: instance.facebook_ad_account && {
                    value: instance.facebook_ad_account.id,
                    label: instance.facebook_ad_account.name,
                },
                media_commission_multiplier: formatCommissionToFormValue(instance.media_commission_multiplier),
                instagram_influencer_commission_multiplier: formatCommissionToFormValue(
                    instance.instagram_influencer_commission_multiplier
                ),
                tiktok_influencer_commission_multiplier: formatCommissionToFormValue(
                    instance.tiktok_influencer_commission_multiplier
                ),
            })}
            toBody={(values) => ({
                name: values.name,
                facebook_ad_account: values.facebook_ad_account?.value ?? null,
                media_commission_multiplier: formatCommissionToApiValue(values.media_commission_multiplier),
                instagram_influencer_commission_multiplier: formatCommissionToApiValue(
                    values.instagram_influencer_commission_multiplier
                ),
                tiktok_influencer_commission_multiplier: formatCommissionToApiValue(
                    values.tiktok_influencer_commission_multiplier
                ),
            })}
            formFields={formFields}
            listDisplay={listDisplay}
        />
    );
};

export const BrandAdmin = () => {
    const [clientOptions, setClientOptions] = useState<DropdownOption[]>([]);
    useEffect(() => {
        async function fetchData() {
            const response = await fetchWithToken('/api/advertising/viewsets/client/');

            if (response.ok) {
                const body = (await response.json()) as PaginatedApiResponseData<Client>;
                setClientOptions(
                    body.results.map((client) => ({
                        value: client.id.toString(),
                        label: client.name,
                    }))
                );
            }
        }

        fetchData();
    }, []);

    const url = '/api/advertising/viewsets/brand/';
    const formFields: FormField[] = [
        { name: 'name', label: 'Name', type: 'text' },
        { name: 'client', label: 'Client', type: 'select', options: clientOptions },
        {
            name: 'artist',
            label: 'Artist',
            type: 'paginated-select',
            fetchOptions: getArtists,
            fetchValue: async (id) => {
                const response = await getArtist(id);
                if (response.status === 200) {
                    return response.data;
                }

                return null;
            },
            mapToOption: (artist: Artist) => ({ value: artist.id, label: artist.name }),
        },
        { name: 'picture', label: 'Image', type: 'image-file', url: '/api/advertising/viewsets/brand-image/' },
    ];

    const listDisplay = [
        { heading: 'Name', instanceToProperty: (a: BrandFormValue) => a.name },
        { heading: 'Client', instanceToProperty: (a: BrandFormValue) => a.client.name },
    ];

    return clientOptions ? (
        <AdminPage
            modelName="Brand"
            url={url}
            initialValues={{
                name: '',
                client: clientOptions[0],
                client_id: clientOptions[0]?.value ?? '',
                artist: null,
                picture: null,
            }}
            instanceToFormValues={(instance: BrandFormValue) => ({
                name: instance.name,
                client: { value: instance.client.id.toString(), label: instance.client.name },
                client_id: instance.client.id.toString(),
                picture: instance.picture,
                artist: instance.artist,
            })}
            toBody={(values) => ({
                name: values.name,
                client_id: values.client.value,
                picture: values.picture,
                artist: (values.artist as any)?.value ?? null,
            })}
            formFields={formFields}
            listDisplay={listDisplay}
            searchable
        />
    ) : null;
};

const BrandOption = (props: OptionProps<any, false>) => {
    return (
        <div {...props.innerProps} className={styles.brandOption}>
            <span>{props.label}</span>
            <p className={styles.brandOptionSubtitle}>{props.data.subtitle}</p>
        </div>
    );
};

export const ReleaseAdmin = () => {
    const [releaseTypeOptions, setReleaseTypeOptions] = useState<DropdownOption[]>([]);
    const [defaultReleaseTypeOption, setDefaultReleaseTypeOption] = useState<DropdownOption>();

    useEffect(() => {
        async function fetchReleaseTypes() {
            const releaseTypes = await getAllReleaseTypes();
            if (releaseTypes) {
                const defaultReleaseType = releaseTypes.find((type) => type.default);
                setDefaultReleaseTypeOption(
                    defaultReleaseType
                        ? { value: defaultReleaseType.id.toString(), label: defaultReleaseType.name }
                        : undefined
                );

                setReleaseTypeOptions(
                    releaseTypes.map((type) => ({
                        value: type.id.toString(),
                        label: type.name,
                    }))
                );
            }
        }

        fetchReleaseTypes();
    }, []);

    const url = '/api/advertising/viewsets/release/';
    const formFields: FormField[] = [
        { name: 'name', label: 'Name', type: 'text' },
        {
            name: 'brand',
            label: 'Brand',
            type: 'paginated-select',
            fetchOptions: getBrands,
            mapToOption: (brand: Brand) => ({
                value: brand.id,
                label: brand.name,
                subtitle: brand.client.name,
            }),
            components: {
                Option: BrandOption,
            },
            filterOption: null,
        },
        {
            name: 'song',
            type: 'paginated-select',
            label: 'Song',
            fetchValue: async (id: number) => {
                const response = await getSong(id);
                if (response.status === 200) {
                    return response.data;
                }

                return null;
            },
            fetchOptions: getSongs,
            mapToOption: (song: Song) => ({
                value: song.id,
                label: song.title,
            }),
        },
        {
            name: 'type',
            label: 'Release Type',
            type: 'select',
            options: releaseTypeOptions,
        },
    ];
    const listDisplay = [
        { heading: 'Name', instanceToProperty: (r: Release) => r.name },
        { heading: 'Brand', instanceToProperty: (r: Release) => r.brand.name },
        { heading: 'Client', instanceToProperty: (r: Release) => r.brand.client.name },
        { heading: 'Type', instanceToProperty: (r: Release) => r.type.name },
    ];

    return (
        <AdminPage
            searchable
            deletable
            modelName="Release"
            url={url}
            initialValues={{
                name: '',
                // @ts-ignore
                brand: null,
                type: defaultReleaseTypeOption!,
                song: null,
            }}
            instanceToFormValues={(instance: Release) => ({
                name: instance.name,
                brand: { value: instance.brand.id, label: instance.brand.name },
                type: { value: instance.type.id.toString(), label: instance.type.name },
                song: instance.song,
            })}
            toBody={(values) => ({
                name: values.name,
                brand_id: values.brand.value,
                type_id: values.type.value,
                song: (values.song as any)?.value,
            })}
            formFields={formFields}
            listDisplay={listDisplay}
        />
    );
};

export const CurrencyAdmin = () => {
    const url = '/api/advertising/viewsets/currency/';
    const formFields = [
        { name: 'name', label: 'Name', type: 'text' },
        { name: 'symbol', label: 'Symbol', type: 'text' },
    ];
    const listDisplay = [
        { heading: 'Name', instanceToProperty: (instance: Currency) => `${instance.name} (${instance.symbol})` },
    ];
    return (
        <AdminPage
            modelName="Currency"
            pluralName="Currencies"
            url={url}
            initialValues={{ name: '', symbol: '' }}
            instanceToFormValues={(instance: Currency) => ({ name: instance.name, symbol: instance.symbol })}
            formFields={formFields}
            listDisplay={listDisplay}
        />
    );
};

export const BuyPlatformAdmin = () => {
    const url = '/api/advertising/viewsets/buy-platform/';
    const formFields = [{ name: 'name', label: 'Buying platform', type: 'text' }];
    const listDisplay = [{ heading: 'Name', instanceToProperty: (instance: BuyPlatform) => instance.name }];
    return (
        <AdminPage
            modelName="Buying platform"
            url={url}
            initialValues={{ name: '' }}
            instanceToFormValues={(instance: BuyPlatform) => ({ name: instance.name })}
            formFields={formFields}
            listDisplay={listDisplay}
        />
    );
};

export const ObjectiveAdmin = () => {
    const url = '/api/advertising/viewsets/objective/';
    const formFields = [{ name: 'name', label: 'Objective', type: 'text' }];
    const listDisplay = [{ heading: 'Name', instanceToProperty: (instance: Objective) => instance.name }];
    return (
        <AdminPage
            modelName="Objective"
            url={url}
            initialValues={{ name: '' }}
            instanceToFormValues={(instance: Objective) => ({ name: instance.name })}
            formFields={formFields}
            listDisplay={listDisplay}
        />
    );
};

export const ChannelAdmin = () => {
    const [buyPlatforms, setBuyPlatforms] = useState<GenericDropdownOption<number>[]>([]);

    const url = '/api/advertising/viewsets/channel/';
    const formFields = [
        { name: 'name', label: 'Channel', type: 'text' },
        { name: 'code', label: 'Code', type: 'text' },
        { name: 'buy_platforms', label: 'Buy Platforms', type: 'select', options: buyPlatforms, isMulti: true },
    ];

    const listDisplay = [
        { heading: 'Name', instanceToProperty: (instance: Channel) => instance.name },
        { heading: 'Code', instanceToProperty: (instance: Channel) => instance.code },
    ];

    const fetchBuyPlatforms = useCallback(async () => {
        const buyPlatforms = await getAllBuyPlatforms();
        setBuyPlatforms(
            buyPlatforms.map((buyPlatform) => ({
                value: buyPlatform.id,
                label: buyPlatform.name,
            }))
        );
    }, []);

    useEffect(() => {
        fetchBuyPlatforms();
    }, [fetchBuyPlatforms]);

    return (
        <AdminPage
            modelName="Channel"
            url={url}
            initialValues={{ name: '', code: '', buy_platforms: [] }}
            instanceToFormValues={(instance: Channel) => ({
                name: instance.name,
                code: instance.code,
                buy_platforms: instance.buy_platforms
                    .map((id) => buyPlatforms.find((platform) => platform.value === id))
                    .filter(
                        (
                            platform: GenericDropdownOption<number> | undefined
                        ): platform is GenericDropdownOption<number> => typeof platform !== 'undefined'
                    ),
            })}
            toBody={(values) => ({
                name: values.name,
                code: values.code,
                buy_platforms: values.buy_platforms.map((option) => option.value),
            })}
            formFields={formFields}
            listDisplay={listDisplay}
        />
    );
};

export const FormatAdmin = () => {
    const [channelOptions, setChannelOptions] = useState<DropdownOption[]>([]);
    useEffect(() => {
        async function fetchData() {
            const response = await fetchWithToken('/api/advertising/viewsets/channel/');

            if (response.ok) {
                const body = (await response.json()) as PaginatedApiResponseData<Channel>;
                setChannelOptions(
                    body.results.map((channel) => ({ value: channel.id.toString(), label: channel.name }))
                );
            }
        }
        fetchData();
    }, []);

    const url = '/api/advertising/viewsets/format/';
    const formFields = [
        { name: 'name', label: 'Name', type: 'text' },
        { name: 'code', label: 'Code', type: 'text' },
        { name: 'channel', label: 'Channel', type: 'select', options: channelOptions },
    ];
    const listDisplay = [
        { heading: 'Name', instanceToProperty: (f: Format) => f.name },
        { heading: 'Code', instanceToProperty: (f: Format) => f.code },
        { heading: 'Channel', instanceToProperty: (f: Format) => f.channel.name },
    ];

    return channelOptions ? (
        <AdminPage
            modelName="Format"
            url={url}
            initialValues={{
                name: '',
                code: '',
                channel: channelOptions[0],
                channel_id: channelOptions[0]?.value ?? '',
            }}
            instanceToFormValues={(instance: Format) => ({
                name: instance.name,
                code: instance.code,
                channel: { value: instance.channel.id.toString(), label: instance.channel.name },
                channel_id: instance.channel.id.toString(),
            })}
            formFields={formFields}
            listDisplay={listDisplay}
        />
    ) : null;
};

export const OptimisationMetricAdmin = () => {
    const [channelOptions, setChannelOptions] = useState<DropdownOption[]>([]);
    useEffect(() => {
        async function fetchData() {
            const response = await fetchWithToken('/api/advertising/viewsets/channel/');

            if (response.ok) {
                const body = (await response.json()) as PaginatedApiResponseData<Channel>;
                setChannelOptions(
                    body.results.map((channel) => ({ value: channel.id.toString(), label: channel.name }))
                );
            }
        }
        fetchData();
    }, []);

    const url = '/api/advertising/viewsets/optimisation-metric/';
    const formFields = [
        { name: 'name', label: 'Name', type: 'text' },
        { name: 'channel', label: 'Channel', type: 'select', options: channelOptions },
        { name: 'cost_per_cost_unit', label: 'Cost per cost unit', type: 'text' },
    ];
    const listDisplay = [
        { heading: 'Name', instanceToProperty: (o: OptimisationMetric) => o.name },
        { heading: 'Channel', instanceToProperty: (o: OptimisationMetric) => o.channel.name },
        { heading: 'Cost per cost unit', instanceToProperty: (o: OptimisationMetric) => o.cost_per_cost_unit },
    ];

    return channelOptions ? (
        <AdminPage
            modelName="Optimisation Metric"
            url={url}
            initialValues={{
                name: '',
                channel: channelOptions[0],
                channel_id: channelOptions[0]?.value ?? '',
                cost_per_cost_unit: '',
            }}
            instanceToFormValues={(instance: OptimisationMetric) => ({
                name: instance.name,
                channel: { value: instance.channel.id.toString(), label: instance.channel.name },
                channel_id: instance.channel.id.toString(),
                cost_per_cost_unit: instance.cost_per_cost_unit,
            })}
            formFields={formFields}
            listDisplay={listDisplay}
        />
    ) : null;
};

export const FacebookAdAccountAdmin = () => {
    const url = '/api/facebook/viewsets/facebook-ad-account/';
    const formFields: FormField[] = useMemo(
        () => [
            { name: 'name', label: 'Name', type: 'text' },
            { name: 'ad_account_id', label: 'Ad Account ID', type: 'text' },
        ],
        []
    );

    const listDisplay = useMemo(
        () => [
            { heading: 'Name', instanceToProperty: (account: FacebookAdAccount) => account.name },
            { heading: 'Ad Account ID', instanceToProperty: (account: FacebookAdAccount) => account.ad_account_id },
        ],
        []
    );

    return (
        <AdminPage
            modelName="Facebook Ad Account"
            url={url}
            initialValues={{
                name: '',
                ad_account_id: '',
            }}
            instanceToFormValues={(account: FacebookAdAccount) => ({
                name: account.name,
                ad_account_id: account.ad_account_id,
            })}
            formFields={formFields}
            listDisplay={listDisplay}
        />
    );
};
