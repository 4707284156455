import styles from './TiktokInfluencerPostPaymentRequestModal.module.css';
import { TiktokInfluencerPost, TiktokInfluencerUser, postPostPaymentRequest } from '@round/api';
import { Button, FormField, Label, Modal, ModalTitle, Select } from '@round/ui-kit';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { mapApiErrorsToFormikErrors } from '../../../../../utility/utility';
import { asMoney, showNotification } from '../../../../../helpers';
import { GenericDropdownOption } from '../../../../../App.types';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../../contexts/OptionsContext/OptionsContext';

type Props = {
    influencerPost: TiktokInfluencerPost | null;
    influencer: TiktokInfluencerUser | null;
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
};

type FormValues = {
    paypal_email_address: string;
    amount: string;
    currency: GenericDropdownOption<number> | null;
};

const validationSchema = Yup.object().shape({
    amount: Yup.string().required('Amount is required'),
    currency: Yup.object().nullable().required('Currency is required'),
    paypal_email_address: Yup.string()
        .email('Must be a valid email address')
        .required('PayPal email address is required'),
});

const hasNumericalMismatch = (a: number | undefined | null, b: number | undefined | null) => {
    if (typeof a !== 'number' || typeof b !== 'number') {
        return false;
    }

    return a !== b;
};

const TiktokInfluencerPostPaymentRequestModal = ({ isOpen, onClose, onSuccess, influencerPost, influencer }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const currencyOptions = currencies.map((c) => ({
        label: c.name,
        value: c.id,
    }));
    const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        if (!influencerPost?.id || !values.currency?.value) {
            return;
        }

        helpers.setSubmitting(true);
        try {
            const response = await postPostPaymentRequest({
                paypal_email_address: values.paypal_email_address,
                amount: values.amount,
                currency: values.currency?.value ?? null,
                tiktok_influencer_post: influencerPost.id,
            });

            if (response.status === 201) {
                showNotification('Payment request created', 'info');
                onSuccess();
                onClose();
                return;
            }

            if (response.status === 400) {
                if (Array.isArray(response.data)) {
                    showNotification(response.data.join('. '), 'error');
                    return;
                }

                helpers.setErrors(mapApiErrorsToFormikErrors<FormValues>(response.data));
                return;
            }

            showNotification(response.data.detail, 'error');
        } catch (error) {
            showNotification('Could not request payment', 'error');
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const initialValues: FormValues = {
        paypal_email_address: influencer?.paypal_email || '',
        amount: influencerPost?.cost || '',
        currency: currencyOptions.find((opt) => opt.value === influencerPost?.currency_id) ?? null,
    };

    const brandPromoCurrency = currencies.find((curr) => curr.id === influencer?.brand_promotion_currency);
    const brandPromoRateDisplayValue =
        influencer?.brand_promotion_cost && brandPromoCurrency
            ? asMoney(parseFloat(influencer.brand_promotion_cost), brandPromoCurrency)
            : '-';

    const soundPromoCurrency = currencies.find((curr) => curr.id === influencer?.currency);
    const soundPromoRateDisplayValue =
        influencer?.cost && soundPromoCurrency ? asMoney(parseFloat(influencer.cost), soundPromoCurrency) : '-';

    const postCurrency = currencies.find((curr) => curr.id === influencerPost?.currency_id);
    const postCostDisplayValue =
        influencerPost?.cost && postCurrency ? asMoney(parseFloat(influencerPost.cost), postCurrency) : '-';

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick className={styles.container}>
            <ModalTitle>Request PayPal Payment</ModalTitle>
            <dl className={styles.detailsContainer}>
                <div>
                    <dt>Sound promo rate</dt>
                    <dd>{soundPromoRateDisplayValue}</dd>
                </div>

                <div>
                    <dt>Brand promo rate</dt>
                    <dd>{brandPromoRateDisplayValue}</dd>
                </div>

                <div>
                    <dt>Post cost</dt>
                    <dd>{postCostDisplayValue}</dd>
                </div>
            </dl>
            <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
                {(props) => {
                    const currencyFieldValue = props.values.currency?.value;

                    const hasCurrencyMismatch =
                        hasNumericalMismatch(currencyFieldValue, influencer?.brand_promotion_currency) ||
                        hasNumericalMismatch(currencyFieldValue, influencer?.currency);

                    return (
                        <Form>
                            <div className={styles.splitSection}>
                                <div>
                                    <FormField>
                                        <Label htmlFor="currency">Currency</Label>
                                        <Field id="currency" name="currency">
                                            {(fieldProps: FieldProps<FormValues['currency']>) => (
                                                <Select
                                                    options={currencyOptions}
                                                    placeholder="Select currency"
                                                    value={fieldProps.field.value}
                                                    onChange={(value) => {
                                                        fieldProps.form.setFieldValue(fieldProps.field.name, value);
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="currency">
                                            {(msg) => <span className={styles.error}>{msg}</span>}
                                        </ErrorMessage>
                                    </FormField>
                                </div>

                                <div>
                                    <FormField>
                                        <Label htmlFor="amount">Amount</Label>
                                        <Field
                                            type="number"
                                            id="amount"
                                            name="amount"
                                            placeholder="Enter amount"
                                            decimalsLimit={2}
                                            className={styles.currencyInput}
                                        />
                                        <ErrorMessage name="amount">
                                            {(msg) => <span className={styles.error}>{msg}</span>}
                                        </ErrorMessage>
                                    </FormField>
                                </div>
                            </div>

                            <FormField>
                                <Label htmlFor="paypal_email_address">PayPal Email</Label>
                                <Field
                                    type="email"
                                    id="paypal_email_address"
                                    name="paypal_email_address"
                                    placeholder="Enter PayPal email address"
                                />
                                <ErrorMessage name="paypal_email_address">
                                    {(msg) => <span className={styles.error}>{msg}</span>}
                                </ErrorMessage>
                            </FormField>

                            <Button
                                type="filled"
                                color="black"
                                htmlType="submit"
                                loading={props.isSubmitting}
                                disabled={!props.isValid}
                                className={styles.submitButton}
                            >
                                Submit
                            </Button>
                            {hasCurrencyMismatch && (
                                <p className={styles.warning}>
                                    The selected currency does not match the influencer's requested currency.
                                </p>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default TiktokInfluencerPostPaymentRequestModal;
