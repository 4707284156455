import { youtube } from '@round/api';
import { buildYoutubeChannelUrl, numberWithCommas, roundTo2Dp } from 'helpers';
import { MicrowaveCampaignPostData } from 'Modules/Advertising/Microwave/MicroCreators/contexts/MIcrowavePosts';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';
import moment from 'moment';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Column } from 'react-table';
import TruncatedTextCell from 'SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import Table from 'ui/NewTable/Table';
import { filterByVisibility, isNumber } from 'utility/utility';
import { getTotalValue } from '../helpers';
import styles from './MicroCreatorsPostsTable.module.css';

type Props = {
    data: MicrowaveCampaignPostData[];
    isLoading: boolean;
    noDataLabel?: string;
};

const YoutubeMicrowaveCampaignPosts = ({ data, isLoading, noDataLabel }: Props) => {
    const isStaff = useCheckUserGroupsAccess(['influencer_editor']);

    const columns = useMemo<Column<MicrowaveCampaignPostData>[]>(
        () =>
            filterByVisibility([
                {
                    Header: 'Channel',
                    accessor: 'influencer',
                    id: 'channel',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const channelTitle = value?.youtube_channel_title;
                        const channelId = value?.youtube_channel_youtube_id;
                        if (!channelId || !channelTitle) {
                            return <>-</>;
                        }

                        return (
                            <a href={buildYoutubeChannelUrl(channelId)} target="_blank" rel="noopener noreferrer">
                                {channelTitle}
                            </a>
                        );
                    },
                },
                {
                    Header: 'Post',
                    accessor: 'youtubeVideo',
                    id: 'postUrl',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        if (!value?.url) {
                            return <>-</>;
                        }

                        return (
                            <a href={value.url} target="_blank" rel="noopener noreferrer">
                                <TruncatedTextCell>{value.url}</TruncatedTextCell>
                            </a>
                        );
                    },
                },
                {
                    Header: 'Post date',
                    accessor: 'youtubeVideo',
                    id: 'postDate',
                    isVisible: Boolean(isStaff),
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        return <>{value?.published_at ? moment(value.published_at).format('DD MMM YYYY') : '-'}</>;
                    },
                },
                {
                    Header: 'Views',
                    accessor: 'youtubeVideo',
                    id: 'views',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        return <>{value?.view_count ? numberWithCommas(value.view_count) : '-'}</>;
                    },
                },
                {
                    Header: 'Total engagements',
                    id: 'totalEngagements',
                    accessor: 'youtubeVideo',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const likes = value?.like_count;
                        const comments = value?.comment_count;

                        if (!isNumber(likes) && !isNumber(comments)) {
                            return <>-</>;
                        }

                        return <>{numberWithCommas((likes ?? 0) + (comments ?? 0))}</>;
                    },
                },
                {
                    Header: 'Engagement rate',
                    id: 'engagementRate',
                    accessor: 'youtubeVideo',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const views = value?.view_count;
                        const likes = value?.like_count;
                        const comments = value?.comment_count;
                        if (!views || (!isNumber(likes) && !isNumber(comments))) {
                            return <>-</>;
                        }

                        const engagements = (likes ?? 0) + (comments ?? 0);
                        return <>{roundTo2Dp((engagements / views) * 100)}%</>;
                    },
                },
                {
                    Header: 'Likes',
                    accessor: 'youtubeVideo',
                    id: 'likes',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        return <>{isNumber(value?.like_count) ? numberWithCommas(value!.like_count) : '-'}</>;
                    },
                },
                {
                    Header: 'Comments',
                    accessor: 'youtubeVideo',
                    id: 'comments',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        return <>{isNumber(value?.comment_count) ? numberWithCommas(value!.comment_count) : '-'}</>;
                    },
                },
            ]),
        [isLoading, isStaff]
    );

    return (
        <Table
            tableClassName={styles.table}
            disableSortBy
            data={isLoading ? new Array(5).fill({}) : data}
            columns={columns}
            noDataLabel={noDataLabel}
            renderFooter={() => {
                const videos = data.map((p) => p.youtubeVideo).filter((v): v is youtube.YoutubeVideo => !!v);
                const views = getTotalValue(videos, 'view_count');
                const likes = getTotalValue(videos, 'like_count');
                const comments = getTotalValue(videos, 'comment_count');

                const totalEngagements =
                    !isNumber(likes) && !isNumber(comments) ? null : (likes ?? 0) + (comments ?? 0);
                const engagementRate = !views || !isNumber(totalEngagements) ? null : totalEngagements / views;

                return (
                    <tfoot>
                        <tr>
                            <td colSpan={Boolean(isStaff) ? 3 : 2} />
                            <td>{isNumber(views) ? numberWithCommas(views) : '-'}</td>
                            <td>{isNumber(totalEngagements) ? numberWithCommas(totalEngagements) : '-'}</td>
                            <td>{isNumber(engagementRate) ? `${roundTo2Dp(engagementRate * 100)}%` : '-'}</td>
                            <td>{isNumber(likes) ? numberWithCommas(likes) : '-'}</td>
                            <td>{isNumber(comments) ? numberWithCommas(comments) : '-'}</td>
                        </tr>
                    </tfoot>
                );
            }}
        />
    );
};

export default YoutubeMicrowaveCampaignPosts;
