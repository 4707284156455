import * as creatorPaymentMirage from './creatorPayment/creatorPayment.mirage';
import { AppServer } from '../../mirage';
import * as managerMirage from './manager/financeManager.mirage';

export const models = {
    ...creatorPaymentMirage.models,
};

export const serializers = {
    ...creatorPaymentMirage.serializers,
};

export const factories = {
    ...creatorPaymentMirage.factories,
};

export function handleRequests(server: AppServer) {
    creatorPaymentMirage.handleRequests(server);
    managerMirage.handleRequests(server);
}
