import React, { FunctionComponent, useLayoutEffect, useRef } from 'react';
import { FacebookTargetingAudienceSelectionOption } from '../../../../../../../../App.types';
import useScrolled from '../../../../../../../../Hooks/useScrolled';
import styles from './FacebookDetailedTargeting.module.css';
import FacebookDetailedTargetingListItem from './components/FacebookDetailedTargetingListItem/FacebookDetailedTargetingListItem';

type FacebookTargetingListProps = {
    selections: FacebookTargetingAudienceSelectionOption[];
    onDelete: (selection: FacebookTargetingAudienceSelectionOption) => void;
};

const FacebookTargetingList: FunctionComponent<FacebookTargetingListProps> = ({ selections, onDelete }) => {
    const listElementRef = useRef<HTMLDivElement>(null);
    const scrolled = useScrolled(listElementRef);

    useLayoutEffect(() => {
        if (listElementRef.current && !scrolled) {
            // scroll down list on length change
            listElementRef.current.scrollTop = listElementRef.current.scrollHeight;
        }
    }, [selections.length, scrolled]);

    return (
        <div ref={listElementRef} className={styles.selectionList}>
            {selections.map((selection) => (
                <FacebookDetailedTargetingListItem key={selection.value} selection={selection} onDelete={onDelete} />
            ))}
        </div>
    );
};

export default FacebookTargetingList;
