import cn from 'classnames';
import styles from './AcceptButton.module.css';

interface AcceptButtonProps {
    accepted?: boolean;
    className?: string;
    onClick?: () => void;
}

const AcceptButton = ({ accepted = false, className, onClick }: AcceptButtonProps) => {
    return (
        <svg
            className={cn(className, { [styles.accepted]: accepted })}
            onClick={onClick}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.5" y="0.5" width="13" height="13" rx="3.5" stroke="#D5D5D5" />
            <path
                d="M5.68828 8.69579L3.92149 6.929L3.85078 6.85829L3.78007 6.929L3.16757 7.5415L3.09686 7.61221L3.16757 7.68292L5.61757 10.1329L5.68828 10.2036L5.75899 10.1329L11.009 4.88292L11.0797 4.81221L11.009 4.7415L10.3965 4.129L10.3258 4.05829L10.2551 4.129L5.68828 8.69579Z"
                fill="#D5D5D5"
                stroke="#D5D5D5"
            />
        </svg>
    );
};

export default AcceptButton;
