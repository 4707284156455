import { Image, Skeleton } from '@round/ui-kit';
import styles from './InfluencerPlanBadge.module.css';
import { buildInfluencerPlanUrl } from '../../InfluencerPlan.helpers';
import cn from 'classnames';

export type InfluencerPlanBadgeInfo = Partial<{
    releaseId: number;
    releaseName: string;
    artistName: string;
    imageUrl: string;
    planUrl?: string;
}>;

type Props = {
    planInfo: InfluencerPlanBadgeInfo | null | undefined;
    isLoading?: boolean;
    className?: string;
};

const InfluencerPlanBadge = ({ planInfo, isLoading, className }: Props) => {
    const defaultPlanUrl = planInfo?.releaseId ? buildInfluencerPlanUrl(planInfo?.releaseId) : undefined;
    const planUrl = planInfo?.planUrl ?? defaultPlanUrl;

    return (
        <div className={cn(styles.container, className)}>
            <Image src={planInfo?.imageUrl} className={styles.image} loading={isLoading} />
            <div>
                <div className={styles.title}>
                    {isLoading ? (
                        <Skeleton width="10rem" />
                    ) : (
                        <a
                            className={styles.title}
                            onClick={(e) => e.stopPropagation()}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={planUrl}
                        >
                            {planInfo?.releaseName ?? '-'}
                        </a>
                    )}
                </div>
                <div>{planInfo?.artistName}</div>
            </div>
        </div>
    );
};

export default InfluencerPlanBadge;
