import { useCallback, useEffect } from 'react';

type ElementsToCheck = SVGSVGElement | HTMLElement | null | undefined;

export default function useOnClickOutside(el: ElementsToCheck | ElementsToCheck[], handler: Function) {
    const memoHandler = useCallback((e: PointerEvent) => handler(e), [handler]);

    useEffect(() => {
        const listener = (event: PointerEvent) => {
            if (
                !el ||
                (!Array.isArray(el) && el.contains(event.target as Node)) ||
                (Array.isArray(el) && el.some((e) => e?.contains(event.target as Node)))
            ) {
                return;
            }

            memoHandler(event);
        };

        document.addEventListener('pointerdown', listener, { passive: true });
        return () => {
            document.removeEventListener('pointerdown', listener);
        };
    }, [el, memoHandler]);
}
