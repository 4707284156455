import { InstagramPost } from './instagramPosts.types';
import { ApiResponse, ApiResponseError } from '../../../types';
import { fetchWithToken } from '../../../helpers';

type PostInstagramPostData = Pick<InstagramPost, 'campaign_id' | 'post_url' | 'influencer_id'>;
type PostInstagramPostResponse =
    | ApiResponse<InstagramPost, 201>
    | ApiResponse<ApiResponseError<PostInstagramPostData & { post: string[] }>, 400>
    | ApiResponse<{ detail: string }, 403>;

export async function postInstagramPost(data: PostInstagramPostData): Promise<PostInstagramPostResponse> {
    const response = await fetchWithToken('/api/microwave/instagram-posts/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create post');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}
