import styles from './SinglePaymentModalStyles.module.css';
import { useEffect, useState } from 'react';
import { showNotification } from 'helpers';
import { patchPostPaymentRequest } from '@round/api';
import TableMoneyInput, { Amount } from 'ui-new/TableComponents/TableMoneyInput/TableMoneyInput';
import Modal from 'ui-new/Modals/Modal/Modal';
import Button from 'ui/Button/Button';
import { UseOutstandingPaymentsReturn } from '../../useOutstandingPayments';
import { mapApiErrorsToFormikErrors } from 'utility/utility';

type SinglePaypalPaymentModalData = {
    beneficiary: string;
    cost: number | null;
    currencyId: number | null;
    paypalEmailAddress: string;
    reference: string;
    postPaymentRequestId: number;
};

type Props = {
    data: SinglePaypalPaymentModalData | null;
    isOpen: boolean;
    onClose: () => void;
    updatePostPaymentRequest: typeof patchPostPaymentRequest;
    makePayment: UseOutstandingPaymentsReturn['payPayPal'];
};

const PaypalPaymentModal = ({ data, makePayment, isOpen, onClose, updatePostPaymentRequest }: Props) => {
    const [paymentReference, setPaymentReference] = useState<string>(data?.reference || '');
    const [currency, setCurrency] = useState(data?.currencyId);
    const [amount, setAmount] = useState(data?.cost);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [isCostEditing, setIsCostEditing] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setPaymentReference(data?.reference || '');
            setCurrency(data?.currencyId);
            setAmount(data?.cost);
        }
    }, [isOpen, data?.reference, data?.currencyId, data?.cost]);

    const handleConfirm = async () => {
        if (!data || !amount || !currency) {
            return;
        }

        if (!paymentReference) {
            showNotification('Please enter payment reference', 'error');
            return;
        }

        try {
            setIsLoading(true);
            const response = await makePayment({ amount, currency, payment_request_id: data.postPaymentRequestId });
            if (response.status === 204) {
                showNotification('Post paid', 'info');
                onClose();
                return;
            }

            const message = Array.isArray(response.data)
                ? response.data.join('.\n')
                : Object.values(mapApiErrorsToFormikErrors(response.data)).join('.\n');
            showNotification(message, 'error');
        } catch (e) {
            if (e instanceof Error) {
                showNotification(e.message, 'error');
                return;
            }
            showNotification(`Couldn't make payment`, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePaymentReferenceUpdate = async () => {
        if (!data?.postPaymentRequestId || paymentReference === data?.reference) {
            return;
        }

        try {
            setIsUpdateLoading(true);
            const response = await updatePostPaymentRequest(data?.postPaymentRequestId, {
                payment_reference: paymentReference,
            });
            if (response.status === 200) {
                setPaymentReference(response.data.payment_reference);
                return;
            }

            showNotification('Could not update reference', 'error');
        } catch {
            showNotification('Could not update reference', 'error');
        } finally {
            setIsUpdateLoading(false);
        }
    };

    const handleAmountUpdate = async ({ amount, currency }: Amount) => {
        if (!currency || !data?.postPaymentRequestId) {
            return;
        }

        try {
            setIsUpdateLoading(true);
            const response = await updatePostPaymentRequest(data?.postPaymentRequestId, {
                amount: amount.toString(),
                currency: currency,
            });

            if (response.status === 200) {
                setAmount(Number(response.data.amount));
                setCurrency(response.data.currency);
                return;
            }

            showNotification('Could not update cost', 'error');
        } catch {
            showNotification('Could not update cost', 'error');
        } finally {
            setIsUpdateLoading(false);
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} className={styles.container} closeOnOverlayClick>
                <Modal.Header>Payment Confirmation</Modal.Header>
                <dl className={styles.detailsContainer}>
                    <div>
                        <dd>Payment Balance</dd>
                        <dt>
                            <b>
                                <TableMoneyInput
                                    key={`${currency}-${amount}`}
                                    editClassName={styles.editCostInput}
                                    value={{ currency: currency ?? null, amount: amount ?? 0 }}
                                    onChange={handleAmountUpdate}
                                    isEditing={isCostEditing}
                                    onEditingChange={setIsCostEditing}
                                />
                            </b>
                        </dt>
                    </div>
                    <div>
                        <dd>Payment Method</dd>
                        <dt>
                            <b>PayPal</b>
                        </dt>
                    </div>
                    <div>
                        <dd>Beneficiary</dd>
                        <dt>{data?.beneficiary}</dt>
                    </div>
                    <div>
                        <dd>PayPal Email Address</dd>
                        <dt>{data?.paypalEmailAddress}</dt>
                    </div>
                </dl>

                <div className={styles.messageContainer}>
                    <label htmlFor="paymentReference">Payment Reference</label>
                    <textarea
                        id="paymentReference"
                        value={paymentReference}
                        onChange={(e) => {
                            if (e.target.value.length > 4000) {
                                return;
                            }
                            setPaymentReference(e.target.value);
                        }}
                        onBlur={handlePaymentReferenceUpdate}
                        onKeyDown={(e) => {
                            if (!e.shiftKey && e.key === 'Enter') {
                                e.preventDefault();
                                handlePaymentReferenceUpdate();
                                return;
                            }
                        }}
                    />
                </div>

                <Modal.Actions>
                    <Button appearance="ghost" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        appearance="primary"
                        onClick={() => handleConfirm()}
                        isLoading={isLoading}
                        disabled={isUpdateLoading}
                    >
                        Confirm Payment
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default PaypalPaymentModal;
