import React, { useMemo } from 'react';
import styles from './PastPayments.module.css';
import Skeleton from 'react-loading-skeleton';
import useUrlState from '../../../../Hooks/useUrlState';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import PastPaymentsTable from './PastPaymentsTable/PastPaymentsTable';
import usePastPayments from './usePastPayments';
import PastPaymentsFilters, { PastPaymentsFiltersParams } from './PastPaymentsFilters/PastPaymentsFilters';

type UrlState = {
    page: number;
    page_size: number;
} & Partial<PastPaymentsFiltersParams>;

const initialUrlState: UrlState = {
    page: 1,
    page_size: 10,
    paypal_payment_status: 'PAID',
};

const getFilters = (urlState: Partial<Record<keyof UrlState, string>>): PastPaymentsFiltersParams => {
    return {
        paypal_payment_status: (urlState.paypal_payment_status ||
            'PAID') as PastPaymentsFiltersParams['paypal_payment_status'],
        tiktok_influencer_user_id: urlState.tiktok_influencer_user_id
            ? Number(urlState.tiktok_influencer_user_id)
            : undefined,
        instagram_influencer_user_id: urlState.instagram_influencer_user_id
            ? Number(urlState.instagram_influencer_user_id)
            : undefined,
    };
};

const PastPayments = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState);

    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const pageSize = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;
    const filters = useMemo(() => getFilters(urlState), [urlState]);

    const { fetchData, status, ...pastPaymentsData } = usePastPayments();

    useAbortableEffect(
        (signal) => {
            const isPastPaymentsDataInitialized = status === 'initialized' || status === 'error';
            if (!isPastPaymentsDataInitialized) {
                fetchData(
                    {
                        page: page,
                        page_size: pageSize,
                        ...filters,
                    },
                    { signal }
                ).catch(() => {});
            }
        },
        [fetchData, filters, page, pageSize, status]
    );

    const isTableLoading = status === 'not-initialized' || status === 'loading';

    const count = pastPaymentsData.data?.count ?? 0;
    const rowsDisplayedText = `displaying ${pastPaymentsData.data?.rows.length} out of ${count} post${
        count !== 1 ? 's' : ''
    }`;

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.titles}>
                    <h1 className={styles.title}>Paid</h1>
                    <p className={styles.rowsDisplayedText}>
                        {isTableLoading ? <Skeleton width="7rem" /> : rowsDisplayedText}
                    </p>
                </div>

                <PastPaymentsFilters
                    value={filters}
                    onChange={(val) => {
                        setUrlState({ ...val, page: 1 });
                        pastPaymentsData.reset();
                    }}
                />
            </div>

            <PastPaymentsTable
                isLoading={isTableLoading}
                data={pastPaymentsData.data?.rows ?? []}
                count={count}
                page={page}
                setPage={(page) => {
                    setUrlState({ page });
                    pastPaymentsData.reset();
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                    setUrlState({ page_size: pageSize || initialUrlState.page_size });
                    pastPaymentsData.reset();
                }}
                noDataLabel={
                    pastPaymentsData.error ? "Sorry, we're experiencing technical issues" : 'No payments found'
                }
            />
        </div>
    );
};

export default PastPayments;
