import React, { createContext, useCallback, useMemo, useReducer } from 'react';
import reducer, { MediaPlanOptionsState } from './reducer';
import {
    fetchAudienceNetworkPositionOptions,
    fetchFacebookDevicePlatformOptions,
    fetchFacebookPositionOptions,
    fetchFacebookPublisherPlatformOptions,
    fetchInstagramPositionOptions,
    fetchMessengerPositionOptions,
} from '../../api/MediaPlanOptions.api';
import {
    getCustomAudienceRefreshRequests,
    getCustomAudiences,
    requestCustomAudiencesRefresh,
} from '../../api/Facebook.api';
import { FCWithChildren } from '../../../../../utility/utility.types';
import {
    getAllBuyPlatforms,
    getAllBuyMethods,
    getAllObjectives,
    getAllChannels,
    getAllFormats,
    getAllOptimisationMetrics,
} from '@round/api';

const initialState: MediaPlanOptionsState = {
    optionsInitialized: false,
    optionsLoadingError: '',
    channelOptions: [],
    formatOptions: [],
    objectiveOptions: [],
    optimisationMetricOptions: [],
    buyMethodOptions: [],
    buyPlatformOptions: [],
    facebookDeviceOptions: [],
    facebookPositionOptions: [],
    facebookPublisherPlatformOptions: [],
    instagramPositionOptions: [],
    messengerPositionOptions: [],
    audienceNetworkPositionOptions: [],
    customAudiences: [],
    customAudiencesLoading: false,
    errorLoadingCustomAudiences: false,
    customAudienceRefreshRequests: [],
    errorLoadingCustomAudienceRefreshRequests: false,
};

type MediaPlanOptionsContextValues = MediaPlanOptionsState &
    MediaPlanOptionsContextDerivedValues &
    MediaPlanOptionsContextUtilities;

type MediaPlanOptionsContextDerivedValues = {
    customAudiencesRefreshedRecently: boolean;
};

type MediaPlanOptionsContextUtilities = {
    init: (requestInit?: RequestInit) => Promise<void>;
    refreshCustomAudiences: () => Promise<void>;
};

type ProviderProps = {
    clientId?: number | undefined;
};

export const MediaPlanOptionsContext = createContext<MediaPlanOptionsContextValues | null>(null);
export const MediaPlanOptionsProvider: FCWithChildren<ProviderProps> = ({ children, clientId }) => {
    const client = clientId;
    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchOptions = useCallback(async () => {
        try {
            await Promise.all([
                getAllChannels().then((payload) => dispatch({ type: 'loadedChannelOptions', payload })),
                getAllFormats().then((payload) => dispatch({ type: 'loadedFormatOptions', payload })),
                getAllObjectives().then((payload) => dispatch({ type: 'loadedObjectiveOptions', payload })),
                getAllOptimisationMetrics().then((payload) =>
                    dispatch({ type: 'loadedOptimisationMetricOptions', payload })
                ),
                getAllBuyMethods().then((payload) => dispatch({ type: 'loadedBuyMethodOptions', payload })),
                getAllBuyPlatforms().then((payload) => dispatch({ type: 'loadedBuyPlatformOptions', payload })),
                fetchFacebookDevicePlatformOptions().then((payload) =>
                    dispatch({ type: 'loadedFacebookDeviceOptions', payload })
                ),
                fetchFacebookPositionOptions().then((payload) =>
                    dispatch({ type: 'loadedFacebookPositionOptions', payload })
                ),
                fetchFacebookPublisherPlatformOptions().then((payload) =>
                    dispatch({ type: 'loadedFacebookPublisherPlatformOptions', payload })
                ),
                fetchInstagramPositionOptions().then((payload) =>
                    dispatch({ type: 'loadedInstagramPositionOptions', payload })
                ),
                fetchMessengerPositionOptions().then((payload) =>
                    dispatch({ type: 'loadedMessengerPositionOptions', payload })
                ),
                fetchAudienceNetworkPositionOptions().then((payload) =>
                    dispatch({ type: 'loadedAudienceNetworkPositionOptions', payload })
                ),
            ]);
        } catch (e) {
            dispatch({ type: 'setOptionsLoadingError', payload: 'Could not fetch options' });
        }
    }, []);

    const fetchCustomAudiences = useCallback(
        async (requestInit?: RequestInit) => {
            if (!client) {
                return;
            }

            try {
                dispatch({ type: 'setErrorLoadingCustomAudiences', payload: false });
                dispatch({ type: 'setLoadingCustomAudiences', payload: true });
                const customAudiences = await getCustomAudiences(client, requestInit);
                dispatch({ type: 'loadedCustomAudiences', payload: customAudiences });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'setErrorLoadingCustomAudiences', payload: true });
            } finally {
                dispatch({ type: 'setLoadingCustomAudiences', payload: false });
            }
        },
        [client]
    );

    const fetchCustomAudienceRefreshRequests = useCallback(
        async (requestInit?: RequestInit) => {
            if (!client) {
                return;
            }

            try {
                dispatch({ type: 'setErrorLoadingCustomAudienceRefreshRequests', payload: false });
                const refreshRequests = await getCustomAudienceRefreshRequests({ clientId: client }, requestInit);
                dispatch({ type: 'loadedCustomAudienceRefreshRequests', payload: refreshRequests });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'setErrorLoadingCustomAudienceRefreshRequests', payload: true });
            }
        },
        [client]
    );

    const refreshCustomAudiences = useCallback(async () => {
        if (!client) {
            throw new Error('Could not refresh custom audiences. Client not found');
        }

        await requestCustomAudiencesRefresh(client);
        await fetchCustomAudiences();
        await fetchCustomAudienceRefreshRequests();
    }, [client, fetchCustomAudienceRefreshRequests, fetchCustomAudiences]);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            await Promise.all([
                fetchOptions(),
                fetchCustomAudiences(requestInit),
                fetchCustomAudienceRefreshRequests(requestInit),
            ]).finally(() => dispatch({ type: 'setInitialized', payload: true }));
        },
        [fetchCustomAudienceRefreshRequests, fetchCustomAudiences, fetchOptions]
    );

    const customAudiencesRefreshedRecently = useMemo(() => {
        const now = Date.now();
        const latest = Math.min(
            ...state.customAudienceRefreshRequests.map((audience) => Date.parse(audience.timestamp))
        );
        return !!state.customAudienceRefreshRequests.length && now - latest <= 1000 * 60 * 60;
    }, [state.customAudienceRefreshRequests]);

    const values = useMemo<MediaPlanOptionsContextValues>(
        () => ({
            ...state,
            init,
            customAudiencesRefreshedRecently,
            refreshCustomAudiences,
        }),
        [customAudiencesRefreshedRecently, init, refreshCustomAudiences, state]
    );

    return <MediaPlanOptionsContext.Provider value={values}>{children}</MediaPlanOptionsContext.Provider>;
};
