/**
 * Sorts two numbers, desc by default. NaN is always last.
 */
export const sortNumerically = (a: number, b: number) => {
    if (isNaN(a)) {
        return 1;
    }

    if (isNaN(b)) {
        return -1;
    }

    return b - a;
};
