import React from 'react';
import { components, PlaceholderProps } from 'react-select';
import { GenericDropdownOption } from '../../../../../../../../App.types';
import TruncatedTextCell from '../../../../../../../../SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import styles from './Tags.module.css';

type Props = PlaceholderProps<GenericDropdownOption<number>, true>;

const TagsPlaceholder = (props: Props) => {
    const value = props.getValue();

    if (props.selectProps.menuIsOpen) {
        return <components.Placeholder {...props} />;
    }

    return value?.length ? (
        <TruncatedTextCell popoverClassName={styles.popover}>
            {props
                .getValue()
                ?.map((o) => o.label)
                .join(', ') ?? ''}
        </TruncatedTextCell>
    ) : (
        <components.Placeholder {...props} />
    );
};

export default TagsPlaceholder;
