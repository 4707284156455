import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './TiktokArtistList.module.css';
import { SearchBox } from '../../../../SharedComponents/SearchBox/SearchBox';
import {
    PaginatedApiResponseData,
    MonitoredTiktokArtistStats,
    OrderByAscQueryParam,
    OrderByDescQueryParam,
    OrderByQueryParam,
} from '../../../../App.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../../../helpers';
import UserModal from '../../../../SharedComponents/UserModal/UserModal';
import { ProtectedByUserGroups } from '../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import { DebounceNumberInputBox } from '../../../../SharedComponents/DebounceNumberInputBox/DebounceNumberInputBox';
import CreateMonitoredUserModal from '../../../../SharedComponents/CreateMonitoredUserModal/CreateMonitoredUserModal';
import UserListTable from '../../../../SharedComponents/UserListTable/UserListTable';
import useUrlState from '../../../../Hooks/useUrlState';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import { Primitives } from '../../../../utility/utility.types';
import { TiktokUserImage } from '@round/api';

type TikTokArtistListUrlState = {
    searchArgument: string;
    minCount: number | undefined;
    maxCount: number | undefined;
} & OrderByQueryParam;

const TiktokArtistList = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [monitoredStats, setMonitoredStats] = useState<MonitoredTiktokArtistStats[]>();
    const [profileImages, setProfileImages] = useState<TiktokUserImage[]>();
    const [monitoredStatsCount, setMonitoredStatsCount] = useState<number>();
    const [nextUrl, setNextUrl] = useState<string | null>(null);
    const [toggleFetchNextData, setToggleFetchNextData] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<number>();
    const [selectedWeeklyNewFollowers, setSelectedWeeklyNewFollowers] = useState<number>();
    const [
        { searchArgument, maxCount, minCount, order_by_desc, order_by_asc },
        setUrlState,
    ] = useUrlState<TikTokArtistListUrlState>({
        searchArgument: '',
        minCount: 100,
        maxCount: undefined,
        order_by_desc: 'follower_count_daily_change_rel',
        order_by_asc: undefined,
    });

    const queryParams = useMemo(() => {
        const params: Record<string, string | number> = {};
        if (searchArgument) {
            params['search'] = searchArgument;
        }
        if (typeof minCount !== 'undefined') {
            params['min_follower_count'] = minCount;
        }
        if (typeof maxCount !== 'undefined') {
            params['max_follower_count'] = maxCount;
        }
        if (order_by_desc) {
            params['order_by_desc'] = order_by_desc;
        }
        if (order_by_asc) {
            params['order_by_asc'] = order_by_asc;
        }

        return params;
    }, [maxCount, minCount, order_by_asc, order_by_desc, searchArgument]);

    const setMinCount = useCallback((minCount: number) => setUrlState({ minCount }), [setUrlState]);
    const setMaxCount = useCallback((maxCount: number) => setUrlState({ maxCount }), [setUrlState]);
    const setSearchArgument = useCallback((searchArgument: string) => setUrlState({ searchArgument }), [setUrlState]);

    const setOrderByQueryParam = useCallback(
        (q: OrderByQueryParam) =>
            setUrlState({
                order_by_desc: (q as OrderByDescQueryParam).order_by_desc,
                order_by_asc: (q as OrderByAscQueryParam).order_by_asc,
            }),
        [setUrlState]
    );

    const fetchData = useCallback(async (url: string, requestInit?: RequestInit) => {
        const response = await fetchWithToken(url, requestInit);
        if (response.ok) {
            return (await response.json()) as PaginatedApiResponseData<MonitoredTiktokArtistStats>;
        }
    }, []);

    const setInitialStats = useCallback(
        async (params: Record<string, Primitives>, requestInit?: RequestInit) => {
            setMonitoredStats([]);
            setIsLoadingData(true);
            const queryString = encodeUrlSearchParams(params);
            const res = await fetchData(`/api/tiktok/artist-user-stats/${queryString}`, requestInit);
            if (res !== undefined) {
                setMonitoredStats(res.results);
                setNextUrl(res.next);
                setMonitoredStatsCount(res.count);
            }
            setIsLoadingData(false);
        },
        [fetchData]
    );

    useEffect(() => {
        async function fetchMoreStats() {
            if (nextUrl !== null && toggleFetchNextData) {
                setToggleFetchNextData(false);
                setIsLoadingData(true);
                const res = await fetchData(nextUrl);
                setNextUrl(res?.next ?? null);
                setMonitoredStatsCount(res?.count);
                if (monitoredStats && res?.results) {
                    setMonitoredStats(monitoredStats.concat(res.results));
                }
                setIsLoadingData(false);
            }
        }

        fetchMoreStats();
    }, [toggleFetchNextData, monitoredStats, fetchData, nextUrl]);

    useAbortableEffect(
        (signal) => {
            async function fetchImages(users: number[]) {
                const response = await fetchWithToken(`/api/tiktok/user-image/?user_id=${users.join(',')}`, { signal });
                if (response.ok) {
                    const body = (await response.json()) as TiktokUserImage[];
                    setProfileImages(body);
                }
            }

            const users = monitoredStats?.map((element) => element.user_id);
            if (users && users.length > 0) {
                fetchImages(users);
            }
        },
        [monitoredStats]
    );

    useAbortableEffect(
        (signal) => {
            // Artist list component will have at least sorting set,
            // so we can skip invoking without any params to fix initial aborted request
            if (Object.keys(queryParams).length) {
                setInitialStats(queryParams, { signal });
            }
        },
        [setInitialStats, queryParams]
    );

    let loadData = null;
    if (nextUrl !== null && !isLoadingData) {
        loadData = function () {
            setToggleFetchNextData(true);
        };
    }

    return (
        <div className={styles.container}>
            <h1>Tiktok Artist Search</h1>
            <div className={styles.toolbarContainer}>
                <div className={styles.toolbarLeft}>
                    <div className={styles.searchBoxItem}>
                        <SearchBox initialValue={searchArgument} setValue={setSearchArgument} />
                    </div>
                    <div className={styles.countItem}>
                        <div className={styles.filterLabel}>Min follower count</div>
                        <DebounceNumberInputBox
                            initialValue={Number(minCount)}
                            setValue={setMinCount}
                            placeholder={'0'}
                        />
                    </div>
                    <div className={styles.countItem}>
                        <div className={styles.filterLabel}>Max follower count</div>
                        <DebounceNumberInputBox
                            initialValue={Number(maxCount)}
                            setValue={setMaxCount}
                            placeholder={'Unlimited'}
                        />
                    </div>
                </div>
                <div className={styles.addSongItem}>
                    <ProtectedByUserGroups groups={['explorer_tiktok_editor']}>
                        <button
                            className={styles.addSongButton}
                            onClick={() => {
                                setIsCreateModalOpen(true);
                            }}
                        >
                            Add new artist
                        </button>
                        <CreateMonitoredUserModal
                            closeModal={() => setIsCreateModalOpen(false)}
                            isModalOpen={isCreateModalOpen}
                            postUrl={'/api/tiktok/viewsets/artist-user/'}
                        />
                    </ProtectedByUserGroups>
                </div>
            </div>
            <UserListTable
                orderByState={[{ order_by_asc, order_by_desc }, setOrderByQueryParam]}
                statsCount={monitoredStatsCount}
                stats={monitoredStats}
                loadData={loadData}
                isLoadingData={isLoadingData}
                openModal={(userId: number) => {
                    setSelectedUserId(userId);
                    const weeklyNewFollowers = monitoredStats?.find((s) => s.user_id === userId)
                        ?.follower_count_weekly_change_abs;
                    setSelectedWeeklyNewFollowers(weeklyNewFollowers === null ? undefined : weeklyNewFollowers);
                    setIsUserModalOpen(true);
                }}
                profileImages={profileImages}
            />
            <UserModal
                isModalOpen={isUserModalOpen}
                closeModal={() => {
                    setIsUserModalOpen(false);
                    setSelectedUserId(undefined);
                }}
                userId={selectedUserId}
                weeklyNewFollowers={selectedWeeklyNewFollowers}
                profileImage={profileImages?.find((image) => image.user_id === selectedUserId)}
                url="/api/tiktok"
            />
        </div>
    );
};

export default TiktokArtistList;
