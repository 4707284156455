import { postPostPaymentRequest, YouTubeInfluencerPost } from '@round/api';
import { Modal, ModalTitle, Button } from '@round/ui-kit';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import useNonNullContext from 'Hooks/useNonNullContext';
import { useMemo } from 'react';
import styles from './YoutubePostPaymentRequestModal.module.css';
import { asMoney, showNotification } from 'helpers';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import PostPaymentRequestForm, { PaymentRequestFormValues } from './components/PostPaymentRequestForm';
import * as Yup from 'yup';

type Props = {
    post: YouTubeInfluencerPost | null;
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
};

const validationSchema = Yup.object().shape({
    amount: Yup.string().required('Amount is required'),
    currency: Yup.object().nullable().required('Currency is required'),
    paypal_email_address: Yup.string()
        .email('Must be a valid email address')
        .required('PayPal email address is required'),
});

const YoutubePostPaymentRequestModal = ({ post, isOpen, onClose, onSuccess }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const currencyOptions = currencies.map((c) => ({
        value: c.id,
        label: c.name,
    }));

    const initialValues: PaymentRequestFormValues = useMemo(
        () => ({
            amount: post?.cost || '',
            currency: currencyOptions.find((curr) => curr.value === post?.currency_id),
            paypal_email_address: post?.paypal_email_address || '',
        }),
        [currencyOptions, post?.paypal_email_address, post?.cost, post?.currency_id]
    );

    const handleSubmit = async (values: PaymentRequestFormValues, helpers: FormikHelpers<PaymentRequestFormValues>) => {
        if (typeof post?.id !== 'number' || !values.currency?.value) {
            return;
        }

        try {
            const response = await postPostPaymentRequest({
                paypal_email_address: values.paypal_email_address,
                amount: values.amount,
                currency: values.currency?.value,
                youtube_influencer_post: post?.id,
            });

            if (response.status === 201) {
                showNotification('Payment request created', 'info');
                onClose();
                onSuccess();
                return;
            }

            if (response.status === 400) {
                if (Array.isArray(response.data)) {
                    showNotification(response.data.join('. '), 'error');
                    return;
                }

                helpers.setErrors(mapApiErrorsToFormikErrors<PaymentRequestFormValues>(response.data));
                return;
            }

            showNotification('Could not request payment', 'error');
        } catch {
            showNotification('Could not request payment', 'error');
        }
    };

    const formikProps = useFormik<PaymentRequestFormValues>({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    const postCurrency = currencies.find((curr) => curr.id === post?.currency_id);
    const postCostDisplayValue = post?.cost && postCurrency ? asMoney(parseFloat(post.cost), postCurrency) : '-';

    return (
        <Modal closeOnOverlayClick isOpen={isOpen} onClose={onClose} className={styles.container}>
            <ModalTitle>Request PayPal Payment</ModalTitle>
            <dl className={styles.detailsContainer}>
                <div>
                    <dt>Post cost</dt>
                    <dd>{postCostDisplayValue}</dd>
                </div>
            </dl>

            <FormikProvider value={formikProps}>
                <PostPaymentRequestForm />
            </FormikProvider>

            <Button
                type="filled"
                color="black"
                loading={formikProps.isSubmitting}
                disabled={!formikProps.isValid}
                className={styles.submitButton}
                onClick={formikProps.submitForm}
            >
                Submit
            </Button>
        </Modal>
    );
};

export default YoutubePostPaymentRequestModal;
