import { OptionProps, components } from 'react-select';
import InfluencerPlanBadge, { InfluencerPlanBadgeInfo } from '../InfluencerPlanBadge/InfluencerPlanBadge';
import { GenericDropdownOption } from '../../../../../App.types';

export type InfluencerPlanOption = GenericDropdownOption<number> &
    Partial<Pick<InfluencerPlanBadgeInfo, 'releaseId' | 'imageUrl' | 'artistName'>>;

type InfluencerPlanSelectOptionProps<T extends InfluencerPlanOption, isMulti extends boolean> = OptionProps<T, isMulti>;

export const InfluencerPlanSelectOption = <T extends InfluencerPlanOption, isMulti extends boolean>(
    props: InfluencerPlanSelectOptionProps<T, isMulti>
) => {
    const optionData = props.data as InfluencerPlanOption;
    return (
        <components.Option {...props}>
            <InfluencerPlanBadge
                planInfo={{
                    releaseId: optionData.releaseId ?? undefined,
                    releaseName: optionData.label ?? '',
                    artistName: optionData.artistName ?? '',
                    imageUrl: optionData.imageUrl ?? '',
                }}
            />
        </components.Option>
    );
};
