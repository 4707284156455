import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { createFactory, makeSerializer, buildPaginatedResponse } from '../../mirage.helpers';
import { InstagramInfluencerUserStats } from './instagramInfluencerUserStats.types';

export const models = {
    instagramInfluencerUserStats: Model as ModelDefinition<InstagramInfluencerUserStats>,
};

export const factories = {
    instagramInfluencerUserStats: createFactory<InstagramInfluencerUserStats>({
        user_id(index: number) {
            return index;
        },
        influencer_user_id(index: number) {
            return `${index}`;
        },
        avg_engagement_rate: `${Math.random()}`,
        avg_posts_per_day: `${Math.random() * 2}`,
        avg_video_plays_per_post(index: number) {
            return `${index * 1000}`;
        },
        contact_details(index: number) {
            return `contact details ${index}`;
        },
        created: new Date().toISOString(),
        follower_count(index: number) {
            return index * 100;
        },
        last_used: new Date().toISOString(),
        name(index: number) {
            return `name ${index}`;
        },
        reels_currency: 1,
        feed_cost(index: number) {
            return index * (Math.random() * 10);
        },
        feed_currency: 1,
        story_cost(index: number) {
            return index * (Math.random() * 10);
        },
        story_currency: 1,
        tags: [],
        username(index: number) {
            return `username ${index}`;
        },
    }),
};

export const serializers = {
    instagramInfluencerUserStats: makeSerializer<InstagramInfluencerUserStats>([]),
};

export async function handleInstagramInfluencerUserRequests(server: AppServer) {
    server.get(
        '/api/instagram/viewsets/influencer-user-stats-v2/',
        function (this: RouteHandlerContext, schema, request) {
            const { search = '' } = request.queryParams;
            const { min_follower_count = '', max_follower_count = '' } = request.queryParams;
            const stats = schema
                .all('instagramInfluencerUserStats')
                .filter((stat) => !search || stat.name.toLowerCase().includes(search.toLowerCase()))
                .filter((stat) => !min_follower_count || stat.follower_count > Number(min_follower_count))
                .filter((stat) => !max_follower_count || stat.follower_count < Number(max_follower_count));

            return buildPaginatedResponse(stats, {
                url: '/api/instagram/influencer-user-stats-v2/',
                serialize: (resource) => this.serialize(resource, 'instagramInfluencerUserStats'),
                queryParams: request.queryParams,
            });
        }
    );
}
