import { IconProps } from '../Icon.types';

const FacebookIcon = ({ width, height, onClick, className }: IconProps) => {
    return (
        <svg
            role="img"
            aria-label="Facebook icon"
            className={className}
            onClick={onClick}
            width={width}
            height={height}
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_89_13219)">
                <path
                    d="M18.5 9.5C18.5 4.52943 14.4706 0.5 9.5 0.5C4.52943 0.5 0.5 4.52943 0.5 9.5C0.5 13.9921 3.79115 17.7155 8.09375 18.3907V12.1016H5.80859V9.5H8.09375V7.51719C8.09375 5.26156 9.43742 4.01562 11.4932 4.01562C12.4776 4.01562 13.5078 4.19141 13.5078 4.19141V6.40625H12.373C11.255 6.40625 10.9062 7.10006 10.9062 7.8125V9.5H13.4023L13.0033 12.1016H10.9062V18.3907C15.2088 17.7155 18.5 13.9921 18.5 9.5Z"
                    fill="#727F8F"
                />
                <path
                    d="M13.0033 12.1016L13.4023 9.5H10.9062V7.8125C10.9062 7.10076 11.255 6.40625 12.373 6.40625H13.5078V4.19141C13.5078 4.19141 12.4779 4.01562 11.4932 4.01562C9.43742 4.01562 8.09375 5.26156 8.09375 7.51719V9.5H5.80859V12.1016H8.09375V18.3907C9.02558 18.5364 9.97442 18.5364 10.9062 18.3907V12.1016H13.0033Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_89_13219">
                    <rect width="18" height="18" fill="white" transform="translate(0.5 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FacebookIcon;
