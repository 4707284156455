import { microwave } from '@round/api';
import { Skeleton } from '@round/ui-kit';
import { paymentStatusOptions } from 'Modules/Advertising/Microwave/constants';

import styles from './PaymentStatusCell.module.css';

type Props = {
    status: microwave.PaymentStatus;
    isLoading?: boolean;
};

const statusVerdictMap: Record<microwave.PaymentStatus, string> = {
    PAID: 'success',
    IN_PROGRESS: 'neutral',
    FAILED: 'failure',
    UNPAID: '',
};

const PaymentStatusCell = ({ status, isLoading }: Props) => {
    if (isLoading) {
        return <Skeleton />;
    }

    const statusVerdict = statusVerdictMap[status];

    return <div className={styles[statusVerdict]}>{paymentStatusOptions.find((o) => o.value === status)?.label}</div>;
};

export default PaymentStatusCell;
