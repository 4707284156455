import TiktokAudioBadge, { TiktokAudioBadgeInfo } from '../TiktokAudioBadge/TiktokAudioBadge';
import { OptionProps, OptionTypeBase, components } from 'react-select';
import styles from './AudioSelectComponents.module.css';

export type AudioOption = { value: number; label: string } & Partial<TiktokAudioBadgeInfo>;

type AudioSelectOptionProps<T extends OptionTypeBase, isMulti extends boolean> = OptionProps<T, isMulti>;

export const AudioSelectOption = <T extends AudioOption, isMulti extends boolean>(
    props: AudioSelectOptionProps<T, isMulti>
) => {
    const optionData = props.data as AudioOption;
    return (
        <components.Option {...props}>
            <div className={styles.container}>
                <TiktokAudioBadge
                    audio={{
                        tiktokId: optionData.tiktokId ?? '',
                        title: optionData.title ?? '',
                        authorName: optionData.authorName,
                        isOriginal: optionData.isOriginal,
                        imageUrl: optionData.imageUrl,
                        audioPlayUrl: optionData.audioPlayUrl,
                    }}
                />
            </div>
        </components.Option>
    );
};
