import React from 'react';
import { IconProps } from './Icon.types';
import RightArrowIcon from './RightArrowIcon';
import styles from './Icon.module.css';
import cn from 'classnames';

const LeftArrowIcon = ({ className, height, width, onClick, ariaRole, ariaLabel }: IconProps) => (
    <RightArrowIcon
        height={height}
        width={width}
        className={cn(styles.leftArrowIcon, className)}
        onClick={onClick}
        ariaRole={ariaRole}
        ariaLabel={ariaLabel}
    />
);

export default LeftArrowIcon;
