import useCampaigns from '../useCampaigns';
import { useCallback } from 'react';
import omit from 'lodash/omit';
import { makeTiktokTimeSeriesDataHook } from 'Modules/TikTok/hooks/dataHooks/useTiktokTimeSeriesDataHook';
import { PublicReportContext } from '../../PublicReportContext';
import { isNumber } from 'utility/utility';
import { influencer } from '@round/api';
import moment from 'moment';
import { makeTiktokAudiosDataHook } from 'Modules/TikTok/hooks/dataHooks/useTiktokAudiosDataHook';

const useTiktokTimeSeries = makeTiktokTimeSeriesDataHook(PublicReportContext, ([values]) => values.tiktok.timeSeries);
const useTiktokAudios = makeTiktokAudiosDataHook(PublicReportContext, ([values]) => values.tiktok.audios);

export function useTiktokCampaigns() {
    const { fetchData: fetchCampaignsData, campaignsData, ...campaignsHookData } = useCampaigns('tiktok');
    const { data: timeSeriesData, fetchData: fetchTimeSeries } = useTiktokTimeSeries();
    const { data: audiosData, fetchData: fetchTiktokAudios } = useTiktokAudios();

    const fetchData = useCallback(
        async (...[params, requestInit]: Parameters<typeof fetchCampaignsData>) => {
            const publicReportRequestInit = influencer.addPublicReportIdToRequestInit(
                params.public_report_id,
                requestInit || {}
            );
            const campaignsResponse = await fetchCampaignsData(params, publicReportRequestInit);

            if (campaignsResponse.status !== 200) {
                return campaignsResponse;
            }

            const audioIds = campaignsResponse.data.results.map((c) => c.tiktok_details?.audio_id).filter(isNumber);

            if (!audioIds.length) {
                return campaignsResponse;
            }

            Promise.allSettled([
                fetchTimeSeries(
                    {
                        type: 'audio',
                        ids: audioIds,
                        fields: ['posts_daily_change'],
                        start_date: moment().subtract(2, 'weeks').format('YYYY-MM-DD'),
                        end_date: moment().format('YYYY-MM-DD'),
                    },
                    omit(publicReportRequestInit, ['signal'])
                ),
                fetchTiktokAudios(audioIds, omit(publicReportRequestInit, ['signal'])),
            ]);

            return campaignsResponse;
        },
        [fetchCampaignsData, fetchTimeSeries, fetchTiktokAudios]
    );

    return {
        fetchData,
        campaignsData,
        timeSeriesData,
        audiosData,
        ...campaignsHookData,

        isTimeSeriesLoading: (campaignId: number) => {
            const campaign = campaignsData.data?.results.find((c) => c.id === campaignId);
            const audioId = campaign?.tiktok_details?.audio_id;
            return Boolean(campaign && audioId && timeSeriesData.data[audioId]?.status === 'loading');
        },
    };
}
