import React, { useMemo } from 'react';
import { microwave } from '@round/api';
import styles from '../../OutstandingPaymentsTable.module.css';
import { formatNumberToKNotation, showNotification } from '../../../../../../../helpers';
import DueDate from 'ui-new/DataDisplay/Date/DueDate/DueDate';
import WrapperPaginationTable, {
    WrapperPaginationTableProps,
} from 'ui/WrapperTable/WrapperPaginationTable/WrapperPaginationTable';
import {
    OutstandingPaymentsSortingKey,
    OutstandingPaymentsTableCellContext,
    OutstandingPaymentsTableRow,
} from '../../helpers';
import { ColumnDef } from '@tanstack/react-table';
import { Skeleton } from '@round/ui-kit';
import { useCheckUserGroupsAccess } from '../../../../../../Auth/hooks/useCheckUserGroupsAccess';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import useNonNullContext from 'Hooks/useNonNullContext';
import { mapOrderingToTableSorting, mapTableSortingToOrdering } from 'ui/WrapperTable/helpers';
import AccountCell from '../../../TableCells/AccountCell/AccountCell';
import CostLabel from 'ui/DataDisplay/Money/CostLabel/CostLabel';
import CostCurrencyCell from '../../../TableCells/CostCurrencyCell/CostCurrencyCell';
import ReferenceCell from '../../../TableCells/ReferenceCell/ReferenceCell';
import ReleaseCell from '../../../TableCells/ReleaseCell/ReleaseCell';
import PlatformCell from '../../../TableCells/PlatformCell/PlatformCell';
import Button from 'ui/Button/Button';
import Checkbox from 'ui/DataEntry/Checkbox/Checkbox';
import DateCell from '../../../TableCells/DateCell/DateCell';
import TableInput from 'ui-new/TableComponents/TableInput/TableInput';

type Props = Omit<WrapperPaginationTableProps<OutstandingPaymentsTableRow>, 'columns' | 'columnPinning'> & {
    totals: microwave.TiktokMicrowaveInfluencerPostTotals | null;
    ordering: OutstandingPaymentsSortingKey[];
    onOrderingChange: (ordering: OutstandingPaymentsSortingKey[]) => void;
    updatePayment: typeof microwave.patchPaymentRequest;
    onClickMakePayment: (row: OutstandingPaymentsTableRow) => void;
};

const OutstandingVenmoPaymentsTable = ({
    totals,
    ordering,
    onOrderingChange,
    updatePayment,
    onClickMakePayment,
    ...props
}: Props) => {
    const isUserMicrowaveFinance = useCheckUserGroupsAccess(['microwave_finance']);
    const { currencies } = useNonNullContext(OptionsContext);

    const columns: ColumnDef<OutstandingPaymentsTableRow, any>[] = useMemo(
        () => [
            {
                id: 'select',
                header: ({ table }) => {
                    return (
                        <div className={styles.checkboxContainer}>
                            {table.getRowModel().rows.length > 0 && (
                                <Checkbox
                                    ariaLabel="Select all"
                                    value={table.getIsAllPageRowsSelected()}
                                    indeterminateValue={table.getIsSomePageRowsSelected()}
                                    onChange={table.toggleAllPageRowsSelected}
                                />
                            )}
                        </div>
                    );
                },
                cell: ({ row }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.checkboxContainer}>
                            <Checkbox
                                ariaLabel="Select row"
                                value={row.getIsSelected()}
                                onChange={() => row.toggleSelected()}
                            />
                        </div>
                    );
                },
            },
            {
                header: 'Account',
                accessorKey: 'influencer',
                cell: ({ getValue, row: { original } }: OutstandingPaymentsTableCellContext<'influencer'>) => (
                    <AccountCell
                        influencer={getValue() || null}
                        imageUrl={original.userImageUrl}
                        postUrl={original.post_url}
                        platform={original.platform}
                        isLoading={props.isLoading}
                    />
                ),
            },
            {
                header: 'Platform',
                accessorKey: 'platform',
                cell: ({ getValue }: OutstandingPaymentsTableCellContext<'platform'>) => (
                    <PlatformCell platform={getValue()} isLoading={props.isLoading} />
                ),
            },
            {
                header: 'Posted at',
                accessorKey: 'posted_at',
                cell: ({ getValue }: OutstandingPaymentsTableCellContext<'posted_at'>) => (
                    <DateCell value={getValue()} isLoading={props.isLoading} />
                ),
            },
            {
                header: 'Due date',
                accessorKey: 'payment_deadline',
                cell: ({ getValue }: OutstandingPaymentsTableCellContext<'payment_deadline'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const date = getValue();
                    if (!date) {
                        return '-';
                    }

                    return <DueDate value={date} />;
                },
            },
            {
                header: 'Release',
                accessorKey: 'release',
                cell: ({ getValue }: OutstandingPaymentsTableCellContext<'release'>) => (
                    <ReleaseCell release={getValue()} isLoading={props.isLoading} />
                ),
            },
            {
                accessorKey: 'cost',
                header: 'Cost',
                cell: function RequestCost({
                    getValue,
                    row: { original },
                }: OutstandingPaymentsTableCellContext<'cost'>) {
                    const cost = getValue();

                    if (cost === null) {
                        return <>-</>;
                    }

                    if (props.isLoading) {
                        return <Skeleton />;
                    }
                    return (
                        <CostCurrencyCell
                            cost={cost}
                            currencyId={original.currency_id}
                            updatePayment={({ cost, currency_id }) =>
                                updatePayment(original.id, {
                                    cost,
                                    currency_id,
                                })
                            }
                        />
                    );
                },
                footer: () =>
                    Object.entries(totals?.cost || {}).map(([currencyId, amount]) => (
                        <div key={currencyId} className={styles.total}>
                            <CostLabel
                                cost={amount}
                                currencySymbol={currencies.find((curr) => curr.id === Number(currencyId))?.symbol ?? ''}
                            />
                        </div>
                    )),
            },
            {
                header: 'Post views',
                accessorKey: 'view_count',
                enableSorting: true,
                cell: ({ getValue }: OutstandingPaymentsTableCellContext<'view_count'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const viewCount = getValue();

                    return <>{viewCount ? formatNumberToKNotation(viewCount) : '-'}</>;
                },
            },
            {
                header: 'Reference',
                accessorKey: 'payment_reference',
                cell: ({ getValue, row: { original } }: OutstandingPaymentsTableCellContext<'payment_reference'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <ReferenceCell
                            reference={getValue()}
                            updatePayment={(val) => updatePayment(original.id, { payment_reference: val })}
                            isReadonly={!isUserMicrowaveFinance}
                        />
                    );
                },
            },
            {
                header: 'Venmo email',
                accessorKey: 'venmo_email_address',
                cell: ({ getValue, row: { original } }: OutstandingPaymentsTableCellContext<'venmo_email_address'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <TableInput
                            value={getValue() || '-'}
                            updateValue={async (val) => {
                                try {
                                    const response = await updatePayment(original.id, { venmo_email_address: val });

                                    if (response.status !== 200) {
                                        const errorMessage =
                                            response.status === 404
                                                ? response.data.detail
                                                : Array.isArray(response.data.venmo_email_address)
                                                ? response.data.venmo_email_address.join(', ')
                                                : response.data.venmo_email_address;
                                        showNotification(
                                            errorMessage || 'Could not update Venmo email address',
                                            'error'
                                        );

                                        return false;
                                    }

                                    showNotification('Venmo email address updated', 'info');
                                    return true;
                                } catch {
                                    showNotification('Could not update PayPal email address', 'error');
                                    return false;
                                }
                            }}
                            isReadonly={!isUserMicrowaveFinance}
                        />
                    );
                },
            },
            {
                header: 'Pay',
                id: 'payment',
                cell: ({ row: { original } }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.paymentActions}>
                            <Button appearance="outlined" onClick={() => onClickMakePayment(original)}>
                                Pay
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [props.isLoading, updatePayment, totals?.cost, currencies, isUserMicrowaveFinance, onClickMakePayment]
    );

    return (
        <WrapperPaginationTable
            {...props}
            columns={columns}
            columnPinning={{ right: ['payment'] }}
            columnVisibility={{ payment: !!isUserMicrowaveFinance }}
            shouldDisplayFooter
            enableSorting
            manualSorting
            sorting={mapOrderingToTableSorting(ordering)}
            onSortingChange={(sortingState) => onOrderingChange(mapTableSortingToOrdering(sortingState))}
        />
    );
};

export default OutstandingVenmoPaymentsTable;
