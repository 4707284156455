import React, { useCallback, useEffect, useState } from 'react';

import CreateTargetingModal from '../components/CreateTargetingModal/CreateTargetingModal';
import { TargetingTemplate } from '../../../types/MediaPlanTargeting.types';
import { showNotification } from '../../../../../../helpers';
import {
    createTargetingFromTemplate,
    createTargetingTemplateWithDefaultsSet,
    createTargetingWithDefaultsSet,
} from '../../../services/MediaPlanTargeting.services';
import { getTargetingTemplates } from '../../../api/MediaPlanTargetings.api';
import { GenericDropdownOption, MediaPlanItemRow, Targeting } from '../../../../../../App.types';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { AdvertisingContext } from '../../../contexts/AdvertisingContext/AdvertisingContext';

type GenericTargetingTemplateModalProps = {
    isModalOpen: boolean;
    mediaPlanItem: MediaPlanItemRow;
    closeModal: () => void;
    onTargetingCreated: (targeting: Targeting) => void;
    onTargetingTemplateCreated: (template: TargetingTemplate) => void;
    editTargetingTemplate: (template: TargetingTemplate) => void;
};

const GenericTargetingTemplateModal = ({
    isModalOpen,
    onTargetingCreated,
    onTargetingTemplateCreated,
    mediaPlanItem,
    closeModal,
    editTargetingTemplate,
}: GenericTargetingTemplateModalProps) => {
    const [{ mediaPlan }] = useNonNullContext(AdvertisingContext);
    const [targetingTemplateOptionsLoading, setTargetingTemplateOptionsLoading] = useState(false);
    const [errorLoadingTargetingTemplates, setErrorLoadingTargetingTemplates] = useState(false);
    const [targetingTemplateOptions, setTargetingTemplateOptions] = useState<GenericDropdownOption<number>[]>([]);
    const [targetingTemplates, setTargetingTemplates] = useState<TargetingTemplate[]>([]);

    const onCreateTargeting = useCallback(async () => {
        try {
            const targeting = await createTargetingWithDefaultsSet(mediaPlanItem.id);
            showNotification('Targeting created', 'info');
            onTargetingCreated(targeting);
            closeModal();
        } catch (e) {
            showNotification('Could not create targeting', 'error');
        }
    }, [closeModal, mediaPlanItem.id, onTargetingCreated]);

    const onCreateTargetingFromTemplate = useCallback(
        async (template: GenericDropdownOption<number>) => {
            try {
                const targeting = await createTargetingFromTemplate(mediaPlanItem.id, template.value);
                showNotification('Targeting created', 'info');
                onTargetingCreated(targeting);
                closeModal();
            } catch {
                showNotification('Could not create targeting', 'error');
            }
        },
        [closeModal, mediaPlanItem.id, onTargetingCreated]
    );

    const onCreateNewTargetingTemplate = useCallback(async () => {
        if (!mediaPlan) {
            return;
        }

        try {
            const targetingTemplate = await createTargetingTemplateWithDefaultsSet(mediaPlan.release.brand.client.id);

            showNotification('Template created', 'info');
            onTargetingTemplateCreated(targetingTemplate);
            closeModal();
        } catch {
            showNotification('Could not create template', 'error');
        }
    }, [closeModal, mediaPlan, onTargetingTemplateCreated]);

    const getTargetingTemplateOptions = useCallback(async () => {
        if (!mediaPlan) {
            return;
        }

        try {
            setTargetingTemplateOptionsLoading(true);
            setErrorLoadingTargetingTemplates(false);
            const templates = await getTargetingTemplates(mediaPlan.release.brand.client.id);
            setTargetingTemplates(templates);
            setTargetingTemplateOptions(
                templates.map((t) => ({
                    value: t.id,
                    label: t.template_name,
                }))
            );
        } catch {
            setErrorLoadingTargetingTemplates(true);
        } finally {
            setTargetingTemplateOptionsLoading(false);
        }
    }, [mediaPlan]);

    const onEditTargetingTemplate = useCallback(
        (template: GenericDropdownOption<number>) => {
            const targetingTemplate = targetingTemplates.find((t) => t.id === template.value);
            if (targetingTemplate) {
                editTargetingTemplate(targetingTemplate);
                closeModal();
            }
        },
        [closeModal, editTargetingTemplate, targetingTemplates]
    );

    useEffect(() => {
        // dependency intended to re-fetch options in case templates' edit/delete
        if (isModalOpen) {
            getTargetingTemplateOptions();
        }
    }, [getTargetingTemplateOptions, isModalOpen]);

    return (
        <CreateTargetingModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            title="Add new targeting"
            templateOptions={targetingTemplateOptions}
            templateOptionsLoading={targetingTemplateOptionsLoading}
            templateOptionsLoadingError={errorLoadingTargetingTemplates}
            createFromTemplate={onCreateTargetingFromTemplate}
            addNewTargeting={onCreateTargeting}
            addNewTemplate={onCreateNewTargetingTemplate}
            editTargetingTemplate={onEditTargetingTemplate}
        />
    );
};

export default GenericTargetingTemplateModal;
