import React, { useContext } from 'react';

export default function useNonNullContext<T>(context: React.Context<T | null>): T {
    const values = useContext(context);
    if (values === null) {
        throw new Error('Context should be used within a Provider');
    }

    return values;
}
