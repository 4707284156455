import { creatorbase, TiktokAudioAsset } from '@round/api';
import { Image, ScrollView, Skeleton, SkeletonizedValue, TableProps, getTableMetaHelper } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import styles from './TiktokTopAudiosTable.module.css';
import cn from 'classnames';
import Badge from 'ui-new/whitelabel/Badge/Badge';
import capitalize from 'lodash/capitalize';
import { buildTiktokMusicUrl, formatNumberToKNotation, numberWithCommas } from 'helpers';
import Table from 'ui-new/whitelabel/Table/Table';
import { ReactComponent as CrossCircle } from 'assets/whitelabel/CrossCircle.svg';
import { ReactComponent as AudioIcon } from 'assets/whitelabel/Audio.svg';
import MiniLineChart from 'ui-new/whitelabel/Charts/MiniLineChart/MiniLineChart';
import moment from 'moment';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import PaginationToolbar, { PaginationProps } from 'ui-new/whitelabel/Table/PaginationToolbar/PaginationToolbar';
import { toDecimalPoint } from '@round/utils';
import TruncationPopover from 'ui-new/TruncationPopover/TruncationPopover';

export type TiktokTopAudiosTableRow = creatorbase.TiktokAudio & {
    timeSeries: creatorbase.TimeSeriesResponse<'posts_daily_change'>[number] | null;
    asset: TiktokAudioAsset | null;
};

type Props = Pick<TableProps<TiktokTopAudiosTableRow>, 'data'> &
    PaginationProps & {
        ordering: creatorbase.GetTiktokAudiosOrderingParam;
        isLoading?: boolean;
        hasError: boolean;
    };

type TableCellContext<K extends keyof TiktokTopAudiosTableRow> = CellContext<
    TiktokTopAudiosTableRow,
    TiktokTopAudiosTableRow[K]
>;

type Meta = Pick<Props, 'isLoading' | 'ordering'>;
const getTableMeta = getTableMetaHelper<Meta>();

const formatVideoCountBadgeValue = (n: number, ordering: creatorbase.GetTiktokAudiosOrderingParam) => {
    if (ordering.includes('relative')) {
        return `${toDecimalPoint(n * 100, 2)}%`;
    }

    return formatNumberToKNotation(n);
};

const TiktokTopAudiosTable = ({
    data,
    isLoading,
    hasError,
    page,
    count,
    setPage,
    pageSize,
    setPageSize,
    ordering,
}: Props) => {
    const columns = useMemo<ColumnDef<TiktokTopAudiosTableRow, any>[]>(
        () => [
            {
                header: 'Audio',
                id: 'audio',
                meta: {
                    className: styles.audio,
                },
                cell: ({ row: { original }, table }) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    const titleValue = original.title || original.tiktok_id;
                    const imageUrl =
                        original.asset?.image_thumb.cached_url ?? original.asset?.image_thumb.original_url ?? '';

                    const audioUrl =
                        original.tiktok_id && original.title
                            ? buildTiktokMusicUrl(original.title, original.tiktok_id)
                            : undefined;

                    return (
                        <div className={styles.audioContainer}>
                            <Image
                                src={imageUrl}
                                alt={original.title || original.tiktok_id}
                                className={styles.audioThumbnail}
                                skeletonCircle={false}
                            />
                            <SkeletonizedValue width="10rem" isInitialized={!isLoading}>
                                <TruncationPopover content={titleValue}>
                                    {(ref) =>
                                        audioUrl ? (
                                            <a
                                                ref={ref}
                                                href={audioUrl}
                                                rel={'noopener noreferrer'}
                                                target={'_blank'}
                                                className={styles.audioTitle}
                                            >
                                                {titleValue}
                                            </a>
                                        ) : (
                                            <span ref={ref} className={styles.audioTitle}>
                                                {titleValue}
                                            </span>
                                        )
                                    }
                                </TruncationPopover>
                            </SkeletonizedValue>
                        </div>
                    );
                },
            },
            {
                header: 'Type',
                accessorKey: 'is_original',
                cell: ({ getValue, table }: TableCellContext<'is_original'>) => {
                    const { isLoading } = getTableMeta(table);
                    const type = getValue() === null ? null : !!getValue() ? 'original' : 'official';

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    if (!type) {
                        return '-';
                    }

                    return <Badge className={cn(styles.badge, styles[type])}>{capitalize(type)}</Badge>;
                },
            },
            {
                header: 'Author',
                accessorKey: 'author_name',
                cell: ({ getValue, table }: TableCellContext<'author_name'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue() || '-';
                },
            },
            {
                header: 'Videos',
                accessorKey: 'video_count',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'video_count'>) => {
                    const { isLoading, ordering } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    const orderingKey = ordering.slice(1);
                    const growthValue =
                        ordering && ordering !== '-video_count'
                            ? original[orderingKey as keyof creatorbase.TiktokAudio]
                            : null;

                    const value = getValue();
                    return (
                        <div className={styles.videoCountContainer}>
                            <span>{typeof value === 'number' ? formatNumberToKNotation(value) : '-'}</span>

                            {typeof growthValue === 'number' && (
                                <span
                                    className={cn(styles.growthValue, {
                                        [styles.positive]: growthValue >= 0,
                                        [styles.negative]: growthValue < 0,
                                    })}
                                >
                                    {growthValue > 0 && '+'}
                                    {formatVideoCountBadgeValue(growthValue, ordering)}
                                </span>
                            )}
                        </div>
                    );
                },
            },
            {
                header: 'Growth',
                id: 'dailyChange',
                accessorKey: 'timeSeries',
                meta: {
                    className: styles.dailyChange,
                },
                cell: ({ getValue, table }: TableCellContext<'timeSeries'>) => {
                    const { isLoading } = getTableMeta(table);

                    const timeSeries = getValue()?.['posts_daily_change'] ?? [];
                    const shouldDisplayTimeSeriesData = !!timeSeries.length;
                    const points = timeSeries.map((s) => ({ x: s.timestamp, y: s.value }));

                    return (
                        <div className={styles.dailyChangeContainer}>
                            <AudioIcon className={styles.audioIcon} />

                            <div className={styles.chartContainer}>
                                <MiniLineChart
                                    flatLineOnEmpty
                                    lineColor="#2A94FF"
                                    points={points}
                                    renderTooltip={
                                        shouldDisplayTimeSeriesData
                                            ? (tooltipItem) => (
                                                  <>
                                                      <Tooltip.Title>
                                                          {moment(tooltipItem.x).format('D MMM YYYY')}
                                                      </Tooltip.Title>
                                                      <Tooltip.Body>
                                                          <dl className={styles.chartTooltipStats}>
                                                              <div className={styles.chartTooltipStatsItem}>
                                                                  <dt>Posts growth</dt>
                                                                  <dd>{numberWithCommas(tooltipItem.y)}</dd>
                                                              </div>
                                                          </dl>
                                                      </Tooltip.Body>
                                                  </>
                                              )
                                            : undefined
                                    }
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    );
                },
            },
        ],
        []
    );

    const meta: Meta = {
        isLoading,
        ordering,
    };

    // skeletons are rendered in table cells,
    // so we need some rows to display skeletons on initial loading
    const initialLoading = !data.length && isLoading;
    const emptyRows = new Array(6).fill({}) as TiktokTopAudiosTableRow[];
    const rows = initialLoading ? emptyRows : data;

    return (
        <>
            <ScrollView className={styles.scrollWrapper}>
                <Table
                    className={styles.table}
                    data={rows}
                    columns={columns}
                    meta={meta}
                    noDataLabel={
                        <div className={styles.noDataLabel}>
                            {hasError ? (
                                <div className={styles.error}>
                                    <CrossCircle />
                                    <p>Failed to load audios</p>
                                </div>
                            ) : (
                                <p>No audios found</p>
                            )}
                        </div>
                    }
                />
            </ScrollView>
            <PaginationToolbar
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                count={count}
                isLoading={isLoading}
            />
        </>
    );
};

export default TiktokTopAudiosTable;
