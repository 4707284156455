import MiniLineChart from 'ui-new/whitelabel/Charts/MiniLineChart/MiniLineChart';
import styles from './ProjectSongChart.module.css';
import { useTimeSeries } from 'Modules/Plans/hooks/useTimeSeries';
import moment from 'moment';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import { numberWithCommas } from 'helpers';
import Spinner from 'ui-new/Loading/Spinner/Spinner';

const today = moment();
const twoWeeksAgo = moment().subtract(14, 'days');

type Props = {
    songId: number | null | undefined;
};

const ProjectSongChart = ({ songId }: Props) => {
    const { data, fetchData, status } = useTimeSeries();
    const isInitialized = !songId || status === 'success' || status === 'error';

    useAbortableEffect(() => {
        if (songId && !isInitialized) {
            fetchData({
                type: 'song',
                ids: [songId],
                fields: ['tiktok_daily_change'],
                start_date: twoWeeksAgo.format('YYYY-MM-DD'),
                end_date: today.format('YYYY-MM-DD'),
            }).catch(() => {});
        }
    }, [songId, isInitialized, fetchData]);

    const timeSeries = songId && data?.[songId]?.tiktok_daily_change ? data[songId].tiktok_daily_change : [];
    const chartPoints = timeSeries.map((s) => ({
        x: s.timestamp,
        y: s.value,
    }));

    return (
        <div className={styles.container}>
            {isInitialized ? (
                <MiniLineChart
                    flatLineOnEmpty
                    emptyChartLineColor="#FFFFFF50"
                    lineColor="#FFF"
                    points={chartPoints}
                    renderTooltip={
                        timeSeries?.length
                            ? (tooltipItem) => (
                                  <>
                                      <Tooltip.Title>{moment(tooltipItem.x).format('D MMM YYYY')}</Tooltip.Title>
                                      <Tooltip.Body>
                                          <dl className={styles.tooltipStats}>
                                              <div className={styles.tooltipStatsItem}>
                                                  <dt>TikTok growth</dt>
                                                  <dd>{numberWithCommas(tooltipItem.y)}</dd>
                                              </div>
                                          </dl>
                                      </Tooltip.Body>
                                  </>
                              )
                            : undefined
                    }
                />
            ) : (
                <div className={styles.spinnerContainer}>
                    <Spinner />
                </div>
            )}
        </div>
    );
};

export default ProjectSongChart;
