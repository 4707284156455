import { getTiktokUser, TiktokUser } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<TiktokUser>;

const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export function useTiktokUser() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (userId: number, requestInit?: RequestInit) => {
        try {
            setState({ data: null, error: null, status: 'loading' });

            const user = await getTiktokUser(userId, requestInit);

            setState({ data: user, error: null, status: 'success' });
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            setState({ data: null, error: 'Could not fetch tiktok user', status: 'error' });
            throw e;
        }
    }, []);

    const reset = useCallback(() => setState(initialState), []);

    return {
        ...state,
        fetchData,
        reset,
    };
}
