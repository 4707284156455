import { ApiResponse, ApiResponseError, NotFoundResponse, PaginatedApiResponseData } from '../types';
import { CreatorList, Creator } from './creatorbase.types';
import { encodeUrlSearchParams, fetchAll } from '../helpers';
import { buildApiUrl } from '../utils';

export async function getCreatorList(
    id: string,
    requestInit?: RequestInit
): Promise<ApiResponse<CreatorList, 200> | NotFoundResponse> {
    const response = await fetch(buildApiUrl(`/api/creatorbase/creator-list/${id}/`), requestInit);
    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get creator list`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetTiktokCreatorsForListParams = {
    listId: number;
    page?: number;
};

export async function getTiktokCreatorsForList(
    { listId, page }: GetTiktokCreatorsForListParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<Creator>, 200>> {
    const response = await fetch(
        buildApiUrl(`/api/creatorbase/creator-list/${listId}/tiktok-creators/${encodeUrlSearchParams({ page })}`),
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not get tiktok creators');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export function getAllTiktokCreatorsForList(listId: string, requestInit?: RequestInit): Promise<Creator[]> {
    return fetchAll(`/api/creatorbase/creator-list/${listId}/tiktok-creators/`, requestInit);
}

export async function patchTiktokCreatorAccepted(
    listId: string,
    creatorId: number,
    isAccepted: boolean | null
): Promise<ApiResponse<Creator, 200> | NotFoundResponse | ApiResponse<ApiResponseError<Creator>, 400>> {
    const response = await fetch(buildApiUrl(`/api/creatorbase/creator-list/${listId}/tiktok-creators/${creatorId}/`), {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ is_accepted: isAccepted }),
    });

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not patch ${creatorId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
