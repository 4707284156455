import reducer, { initialState, OptionsContextActions, OptionsContextState } from './reducer';
import React, { createContext, Dispatch, useCallback, useReducer } from 'react';
import useAbortableEffect from '../../Hooks/useAbortableEffect';
import { FCWithChildren } from '../../utility/utility.types';
import { getAllCurrencies } from '@round/api';

type OptionsContextValues = OptionsContextState;

export const OptionsContext = createContext<OptionsContextValues | null>(null);
export const OptionsDispatchContext = createContext<Dispatch<OptionsContextActions> | null>(null);
export const OptionsProvider: FCWithChildren = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchCurrencies = useCallback(async (requestInit?: RequestInit) => {
        try {
            getAllCurrencies(requestInit).then((currencies) =>
                dispatch({ type: 'currenciesLoaded', payload: currencies })
            );
        } catch {
            throw new Error("Couldn't load currencies");
        }
    }, []);

    const fetchOptions = useCallback(
        async (requestInit?: RequestInit) => {
            await fetchCurrencies(requestInit);
        },
        [fetchCurrencies]
    );

    useAbortableEffect(
        (signal) => {
            fetchOptions({ signal });
        },
        [fetchOptions]
    );

    return (
        <OptionsContext.Provider value={state}>
            <OptionsDispatchContext.Provider value={dispatch}>{children}</OptionsDispatchContext.Provider>
        </OptionsContext.Provider>
    );
};
