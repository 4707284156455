import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { Field, Form, FieldProps, ErrorMessage } from 'formik';
import { FormField, Select, Label } from '@round/ui-kit';
import { GenericDropdownOption } from 'App.types';
import { ValueType } from 'react-select';
import useNonNullContext from 'Hooks/useNonNullContext';
import styles from './PostPaymentRequestForm.module.css';

export type PaymentRequestFormValues = {
    paypal_email_address: string;
    amount: string;
    currency: ValueType<GenericDropdownOption<number>, false>;
};

const PostPaymentRequestForm = () => {
    const { currencies } = useNonNullContext(OptionsContext);
    const currencyOptions = currencies.map((c) => ({
        value: c.id,
        label: c.name,
    }));

    return (
        <Form className={styles.form}>
            <div className={styles.splitSection}>
                <FormField>
                    <Label htmlFor="currency">Currency</Label>
                    <Field id="currency" name="currency">
                        {(fieldProps: FieldProps<PaymentRequestFormValues['currency']>) => (
                            <Select
                                options={currencyOptions}
                                placeholder="Select currency"
                                value={fieldProps.field.value}
                                onChange={(value) => {
                                    fieldProps.form.setFieldValue(fieldProps.field.name, value);
                                }}
                            />
                        )}
                    </Field>
                    <ErrorMessage name="currency">{(msg) => <span className={styles.error}>{msg}</span>}</ErrorMessage>
                </FormField>

                <FormField>
                    <Label htmlFor="amount">Amount</Label>
                    <Field
                        type="number"
                        id="amount"
                        name="amount"
                        placeholder="Enter amount"
                        decimalsLimit={2}
                        className={styles.currencyInput}
                    />
                    <ErrorMessage name="amount">{(msg) => <span className={styles.error}>{msg}</span>}</ErrorMessage>
                </FormField>
            </div>

            <FormField>
                <Label htmlFor="paypal_email_address">PayPal Email</Label>
                <Field
                    type="email"
                    id="paypal_email_address"
                    name="paypal_email_address"
                    placeholder="Enter PayPal email address"
                />
                <ErrorMessage name="paypal_email_address">
                    {(msg) => <span className={styles.error}>{msg}</span>}
                </ErrorMessage>
            </FormField>
        </Form>
    );
};

export default PostPaymentRequestForm;
