import React, { useCallback, useContext, useMemo, useState } from 'react';
import { InfluencerPlanContext } from '../../../contexts/InfluencerPlanContext';
import PlanForm, { PlanFormValues } from '../components/PlanForm/PlanForm';
import { FormikHelpers, FormikProps } from 'formik';
import { showNotification } from 'helpers';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import Button from '../../../../../../ui/Buttons/Button/Button';
import styles from './EditPlanModal.module.css';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';

type EditPlanModalProps = {
    isModalOpen: boolean;
    onClose: () => void;
};

const EditPlanModal = ({ isModalOpen, onClose }: EditPlanModalProps) => {
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const {
        updateInfluencerPlan,
        deleteInfluencerPlan,
        influencerPlan,
        plannerUsers,
        refreshPlannerUsers,
    } = useContext(InfluencerPlanContext);

    const userHasAdmin = useCheckUserGroupsAccess(['user_admin']);

    const initialValues: PlanFormValues = useMemo(() => {
        const youtubeBudget =
            typeof influencerPlan?.youtube_budget === 'string' ? Number(influencerPlan.youtube_budget) : 0;
        return {
            currency: influencerPlan?.currency
                ? { value: influencerPlan.currency.id, label: influencerPlan.currency.name }
                : undefined,
            start_date: influencerPlan?.start_date ?? new Date().toISOString(),
            end_date: influencerPlan?.end_date ?? new Date().toISOString(),
            instagram_budget:
                typeof influencerPlan?.instagram_budget === 'string' ? Number(influencerPlan.instagram_budget) : 0,
            tiktok_budget: typeof influencerPlan?.tiktok_budget === 'string' ? Number(influencerPlan.tiktok_budget) : 0,
            youtube_budget: youtubeBudget,
            planners: plannerUsers.map((user) => ({
                value: user.id,
                label: `${user.first_name} ${user.last_name}`,
            })),
        };
    }, [
        influencerPlan?.currency,
        influencerPlan?.end_date,
        influencerPlan?.instagram_budget,
        influencerPlan?.start_date,
        influencerPlan?.tiktok_budget,
        influencerPlan?.youtube_budget,
        plannerUsers,
    ]);

    const handleSubmit = useCallback(
        async (values: PlanFormValues, helpers: FormikHelpers<PlanFormValues>) => {
            if (!influencerPlan || !values.currency) {
                return;
            }

            try {
                helpers.setSubmitting(true);
                await updateInfluencerPlan({
                    release_id: influencerPlan.release.id,
                    currency_id: values.currency.value,
                    tiktok_budget: values.tiktok_budget,
                    end_date: values.end_date,
                    start_date: values.start_date,
                    instagram_budget: values.instagram_budget,
                    youtube_budget: values.youtube_budget,
                    ...(userHasAdmin && { planners: values.planners ? values.planners.map((val) => val.value) : [] }),
                });
                if (values.planners) {
                    refreshPlannerUsers(values.planners.map((planner) => planner.value));
                }
                onClose();
                showNotification('Plan updated', 'info');
            } catch {
                showNotification('Could not update plan', 'error');
            } finally {
                helpers.setSubmitting(false);
            }
        },
        [influencerPlan, updateInfluencerPlan, userHasAdmin, onClose, refreshPlannerUsers]
    );

    const handleDelete = useCallback(async () => {
        if (!influencerPlan) {
            return;
        }

        try {
            setDeleteLoading(true);
            await deleteInfluencerPlan();
            showNotification('Plan deleted', 'info');
            setShowDeletePrompt(false);
            onClose();
        } catch {
            showNotification('Could not delete plan', 'error');
        } finally {
            setDeleteLoading(false);
        }
    }, [deleteInfluencerPlan, influencerPlan, onClose]);

    return (
        <>
            <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={onClose}>
                <ModalTitle>Edit Plan</ModalTitle>
                <ModalContent className={styles.content}>
                    <PlanForm
                        handleSubmit={handleSubmit}
                        initialValues={initialValues}
                        renderActions={({ isSubmitting, submitForm, dirty }: FormikProps<PlanFormValues>) => (
                            <div className={styles.renderActions}>
                                <Button
                                    className={styles.submitButton}
                                    type="filled"
                                    color="black"
                                    disabled={!dirty}
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Save
                                </Button>
                                <Button
                                    className={styles.deleteButton}
                                    type="filled"
                                    color="negative"
                                    onClick={() => setShowDeletePrompt(true)}
                                >
                                    Delete
                                </Button>
                            </div>
                        )}
                    />
                </ModalContent>
            </Modal>

            <Modal
                className={styles.deletePlan}
                closeOnOverlayClick
                isOpen={showDeletePrompt}
                onClose={() => setShowDeletePrompt(false)}
            >
                <ModalTitle>Delete influencer plan?</ModalTitle>
                <ModalContent className={styles.deletePlanContent}>
                    You are going to delete influencer plan for "{influencerPlan?.release.name}". This operation cannot
                    be undone
                </ModalContent>
                <ModalFooter align="center">
                    <Button type="bordered" color="negative" loading={deleteLoading} onClick={handleDelete}>
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default EditPlanModal;
