import React from 'react';
import { ReactComponent as RoundLogoShort } from '../../../../../assets/brand/LogoShort.svg';
import styles from './FormLoading.module.css';

const FormLoading = () => {
    return (
        <div className={styles.container}>
            <RoundLogoShort className={styles.logo} />
        </div>
    );
};

export default FormLoading;
