import React, { FunctionComponent } from 'react';
import styles from './LoadingDataTableRow.module.css';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const LoadingDataTableRow: FunctionComponent<{
    loadData: null | (() => void);
    isLoadingData: boolean;
    colSpan?: number;
}> = ({ loadData, isLoadingData, colSpan = 6 }) => {
    return (
        <>
            {loadData !== null && (
                <tr>
                    <td className={styles.loadDataCell} align={'center'} colSpan={colSpan} onClick={loadData}>
                        Load more data
                    </td>
                </tr>
            )}
            {isLoadingData && (
                <tr>
                    <td className={styles.loadingDataCell} align={'center'} colSpan={colSpan}>
                        <LoadingSpinner />
                    </td>
                </tr>
            )}
        </>
    );
};

export default LoadingDataTableRow;
