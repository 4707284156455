import { createContext, useState } from 'react';
import { usePaginatedFetch } from '../../../../Hooks/usePaginatedFetch';
import { getInfluencerPlans } from '../../../Advertising/InfluencerPlan/InfluencerPlan.api';
import { InfluencerPlan } from '../../../../App.types';
import { FCWithChildren } from '../../../../utility/utility.types';

type AddInfluencerToCampaignContextValues = {
    data: InfluencerPlan[];
    loading: boolean;
    count: number;
    getNextPage: () => Promise<void>;
    search: string;
    setSearch: (search: string) => void;
};

export const AddInfluencerToCampaignOptionsContext = createContext<AddInfluencerToCampaignContextValues | null>(null);
export const AddInfluencerToCampaignOptionsProvider: FCWithChildren = ({ children }) => {
    const [search, setSearch] = useState('');
    const { data, loading, count, getNextPage } = usePaginatedFetch(getInfluencerPlans, { search, page_size: 25 });

    return (
        <AddInfluencerToCampaignOptionsContext.Provider
            value={{
                data,
                loading,
                count,
                getNextPage,
                search,
                setSearch,
            }}
        >
            {children}
        </AddInfluencerToCampaignOptionsContext.Provider>
    );
};
