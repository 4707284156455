import useNonNullContext from 'Hooks/useNonNullContext';
import reducer from './reducer';
import { Dispatch, ReactNode, createContext, useCallback, useContext, useReducer } from 'react';
import { AudioTimeSeriesContextActions, AudioTimeSeriesContextState } from './reducer';
import { creatorbase } from '@round/api';

const initialState: AudioTimeSeriesContextState = {
    isInitialized: false,
    data: {},
};

const AudioTimeSeriesContext = createContext<
    [AudioTimeSeriesContextState, Dispatch<AudioTimeSeriesContextActions>] | null
>(null);

type Props = { children?: ReactNode };
export const AudioTimeSeriesProvider = ({ children }: Props) => {
    const state = useReducer(reducer, initialState);

    return <AudioTimeSeriesContext.Provider value={state}>{children}</AudioTimeSeriesContext.Provider>;
};

export function useAudioTimeSeriesData() {
    const [state, dispatch] = useNonNullContext(AudioTimeSeriesContext);
    const fetchData = useCallback(
        async (
            params: Pick<creatorbase.TimeSeriesRequestParams, 'start_date' | 'end_date' | 'ids'>,
            signal?: AbortSignal
        ) => {
            if (!params.ids.length) {
                dispatch({ type: 'audioTimeSeriesDataInitialized' });
                return;
            }

            try {
                dispatch({ type: 'audioTimeSeriesDataLoading', payload: { ids: params.ids } });

                const response = await creatorbase.postTimeSeries(
                    { type: 'tiktok_audio', fields: ['posts_daily_change'], ...params },
                    signal
                );

                if (response.status === 200) {
                    dispatch({
                        type: 'audioTimeSeriesDataSuccess',
                        payload: { ids: params.ids, data: response.data },
                    });
                    dispatch({ type: 'audioTimeSeriesDataInitialized' });
                    return;
                }

                dispatch({
                    type: 'audioTimeSeriesDataError',
                    payload: { ids: params.ids, error: `Couldn't fetch time series data` },
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'audioTimeSeriesDataIdle', payload: { ids: params.ids } });
                    return;
                }

                dispatch({
                    type: 'audioTimeSeriesDataError',
                    payload: { ids: params.ids, error: `Couldn't fetch time series data` },
                });
            }
        },
        [dispatch]
    );

    return {
        data: state.data,
        isInitialized: state.isInitialized,
        fetchData,
    };
}

export function useAudioTimeSeriesDataActions() {
    const context = useContext(AudioTimeSeriesContext);
    const [, dispatch] = context ?? [];

    const fetchData = useCallback(
        async (
            params: Pick<creatorbase.TimeSeriesRequestParams, 'start_date' | 'end_date' | 'ids'>,
            signal?: AbortSignal
        ) => {
            if (!dispatch) {
                return;
            }

            try {
                dispatch({ type: 'audioTimeSeriesDataLoading', payload: { ids: params.ids } });

                const response = await creatorbase.postTimeSeries(
                    { type: 'tiktok_audio', fields: ['posts_daily_change'], ...params },
                    signal
                );

                if (response.status === 200) {
                    dispatch({
                        type: 'audioTimeSeriesDataSuccess',
                        payload: { ids: params.ids, data: response.data },
                    });
                    return;
                }

                dispatch({
                    type: 'audioTimeSeriesDataError',
                    payload: { ids: params.ids, error: `Couldn't fetch time series data` },
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'audioTimeSeriesDataIdle', payload: { ids: params.ids } });
                    return;
                }

                dispatch({
                    type: 'audioTimeSeriesDataError',
                    payload: { ids: params.ids, error: `Couldn't fetch time series data` },
                });
            }
        },
        [dispatch]
    );

    return {
        fetchData,
    };
}
