import { creatorbase } from '@round/api';
import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { SongDataContext } from '../../SongDataContext';

export default function useTiktokCreations() {
    const songData = useNonNullContextSelector(SongDataContext, (value) => value[0].song);
    const state = useNonNullContextSelector(SongDataContext, (value) => value[0].tiktok.creations);
    const dispatch = useNonNullContextSelector(SongDataContext, (value) => value[1]);

    const fetchData = useCallback(
        async (
            { start_date, end_date }: Pick<creatorbase.TimeSeriesRequestParams, 'start_date' | 'end_date'>,
            signal?: AbortSignal
        ) => {
            const songId = songData.data?.song.id;
            if (typeof songId !== 'number') {
                return;
            }

            dispatch({ type: 'loadTiktokCreations' });

            try {
                const response = await creatorbase.postTimeSeries(
                    {
                        type: 'song',
                        fields: ['tiktok_daily_change', 'tiktok_daily_totals'],
                        ids: [songId],
                        start_date,
                        end_date,
                    },
                    signal
                );

                if (response.status === 403) {
                    dispatch({ type: 'tiktokCreationsErrorLoading', payload: response.data.detail });
                    return response;
                }

                dispatch({ type: 'tiktokCreationsInitialized', payload: response.data[songId] });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({
                    type: 'tiktokCreationsErrorLoading',
                    payload: e instanceof Error ? e.message : 'Could not load tiktok creations',
                });
                throw e;
            }
        },
        [dispatch, songData.data?.song.id]
    );

    return {
        ...state,
        fetchData,
    };
}
