import { ActionMeta, FormatOptionLabelMeta, StylesConfig, ValueType } from 'react-select';
import { ReleaseOption } from 'Modules/Advertising/Projects/components/ReleaseSelectOption/ReleaseSelectOption';
import useReleaseSelect from 'Modules/Advertising/Projects/hooks/useReleaseSelect';
import { BaseFilterObject } from 'App.types';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import { Image } from '@round/ui-kit';
import styles from './ReleaseFilter.module.css';

type Props<IsMulti extends boolean = false> = {
    isMulti?: IsMulti;
    initialReleaseId?: IsMulti extends true ? number[] : number;
    onChange: (value: ValueType<ReleaseOption, IsMulti>) => void;
    onClear: () => void;
    onRemove: () => void;
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
} & Pick<BaseFilterObject, 'name' | 'icon'>;

const selectStyles: StylesConfig = {
    option: (base) => ({
        ...base,
        padding: '0.3125rem 0',
        marginTop: '0.3125rem',
    }),
};

const ReleaseFilter = <IsMulti extends boolean = false>({
    initialReleaseId,
    onRemove,
    onChange,
    onClear,
    name,
    icon,
    isMulti = false as IsMulti,
    isOpen,
    onOpen,
    onClose,
}: Props<IsMulti>) => {
    const selectProps = useReleaseSelect<IsMulti>(initialReleaseId, { isMulti });

    const clearState = () => {
        selectProps.onChange?.(null, { action: 'clear' });
    };

    return (
        <SelectFilter
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            name={name}
            icon={icon}
            styles={selectStyles}
            onClear={() => {
                clearState();
                onClear();
            }}
            onRemove={() => {
                clearState();
                onRemove();
            }}
            {...selectProps}
            onChange={(value: ValueType<ReleaseOption, IsMulti>, action: ActionMeta<ReleaseOption>) => {
                selectProps.onChange?.(value, action);
                onChange(value);
            }}
            formatOptionLabel={(option: ReleaseOption, meta: FormatOptionLabelMeta<ReleaseOption, IsMulti>) => {
                if (meta.context === 'value') {
                    return null;
                }

                return (
                    <div className={styles.container}>
                        <Image src={option.image} className={styles.image} />
                        <div className={styles.titles}>
                            <p className={styles.title}>{option.label}</p>
                            <p className={styles.subTitle}>
                                {option.brandName} - {option.clientName}
                            </p>
                        </div>
                    </div>
                );
            }}
        />
    );
};

export default ReleaseFilter;
