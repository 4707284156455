import React from 'react';
import { Navigate, NavLink, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import styles from './CampaignDetails.module.css';
import { Release } from '@round/api';
import { ReactComponent as AdvertisingIcon } from '../../assets/advertising.svg';
import { ReactComponent as CreatorsIcon } from '../../assets/creators.svg';
import Card from '../../../../../ui/Card/Card';
import Advertising from '../../../MediaPlan/features/Advertising/Advertising';
import { MediaPlanOptionsProvider } from '../../../MediaPlan/contexts/MediaPlanOptionsContext/MediaPlanOptionsContext';
import ReleaseSelect from '../../components/ReleaseSelect/ReleaseSelect';
import Creators from '../../../InfluencerPlan/features/Creators/Creators';
import cn from 'classnames';
import { useRelease } from '../../hooks/useRelease';
import { MicroCampaignContextProvider } from '../../../Microwave/MicroCreators/contexts/MicroCampaign';
import { InstagramMicroCampaignProvider } from 'Modules/Advertising/Microwave/MicroCreators/contexts/InstagramMicroCampaign';
import { TiktokMicroCampaignStatsProvider } from 'Modules/Advertising/Microwave/MicroCreators/contexts/TiktokMicroCampaignStats';

const tabNavItems: { label: string; link: string; Icon: typeof AdvertisingIcon }[] = [
    { label: 'Advertising', link: 'advertising', Icon: AdvertisingIcon },
    { label: 'Creators', link: 'creators', Icon: CreatorsIcon },
];

const CampaignDetails = ({ releaseId }: { releaseId: number }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { release, setRelease, releaseLoading, errorLoadingRelease } = useRelease(releaseId);

    const selectRelease = (release: Release) => {
        setRelease(release);
        const urlParts = location.pathname.split('/');
        navigate(`/campaigns/${release.id}/${urlParts[urlParts.length - 1]}`);
    };

    if (errorLoadingRelease) {
        return <p>Sorry, we are experiencing technical issues</p>;
    }

    if (!release && !releaseLoading) {
        return <p>Could not find release</p>;
    }

    return (
        <>
            <div className={styles.header}>
                <div className={styles.releaseContainer}>
                    <ReleaseSelect release={release} releaseLoading={releaseLoading} onReleaseChange={selectRelease} />
                </div>

                <div className={styles.tabNavigation}>
                    {tabNavItems.map(({ link, label, Icon }) => (
                        <NavLink
                            key={link}
                            to={link}
                            className={({ isActive }) => cn(styles.tabLink, { [styles.active]: isActive })}
                        >
                            <Icon className={styles.tabLinkIcon} />
                            {label}
                        </NavLink>
                    ))}
                </div>
            </div>
            <Card className={styles.content}>
                <MediaPlanOptionsProvider clientId={release?.brand.client.id}>
                    <MicroCampaignContextProvider>
                        <TiktokMicroCampaignStatsProvider>
                            <InstagramMicroCampaignProvider>
                                <Routes>
                                    <Route path="advertising" element={<Advertising release={release} />} />
                                    <Route path="creators" element={<Creators release={release} />} />
                                    <Route path="*" element={<Navigate to="advertising" />} />
                                </Routes>
                            </InstagramMicroCampaignProvider>
                        </TiktokMicroCampaignStatsProvider>
                    </MicroCampaignContextProvider>
                </MediaPlanOptionsProvider>
            </Card>
        </>
    );
};

export default CampaignDetails;
