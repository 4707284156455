import { DependencyList, useEffect, useRef } from 'react';

function noop() {}

function createAbortController() {
    // Use the mock abort controller for envs that do not support the
    // AbortController API. This results in noop on attempts to abort.
    const mockAbortController: AbortController = {
        abort: noop,
        signal: {
            aborted: false,
            onabort: noop,
            addEventListener: noop,
            removeEventListener: noop,
            dispatchEvent: () => false,
            reason: undefined,
            throwIfAborted: noop,
        },
    };

    return typeof AbortController !== 'undefined' ? new AbortController() : mockAbortController;
}

export default function useAbortableEffect(
    effect: (signal: AbortSignal) => void | ((controller: AbortController) => void),
    deps: DependencyList
): Readonly<AbortController> {
    const firstRun = useRef(true);
    const controllerRef = useRef<AbortController>();

    // Create an abort controller for the very first run.
    if (!controllerRef.current) {
        controllerRef.current = createAbortController();
    }

    useEffect(
        () => {
            // The first run already has a controller, create a new one only for
            // subsequent effect runs.
            if (!firstRun.current) {
                controllerRef.current = createAbortController();
            } else {
                firstRun.current = false;
            }

            const controller = controllerRef.current!;
            const cleanupFn = effect(controller.signal);

            if (typeof cleanupFn === 'function') {
                return () => cleanupFn.call(null, controller);
            }

            return () => controller.abort();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps
    );

    return controllerRef.current;
}
