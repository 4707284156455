import { call, encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import { isPublicReportRequestInit } from '../../influencer';
import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { Channel } from './channels.types';

export type GetChannelsParams = Partial<
    PaginatedRequest & {
        identifier_search: string;
        id: string;
    }
>;

type GetChannelsResponse = ApiResponse<PaginatedApiResponseData<Channel>, 200> | NotFoundResponse;

export async function getChannels(params: GetChannelsParams, requestInit?: RequestInit): Promise<GetChannelsResponse> {
    const response = await call(`/api/youtube/channels/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens: isPublicReportRequestInit(requestInit),
    });

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get youtube channels');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetChannelResponse = ApiResponse<Channel, 200> | NotFoundResponse;

export async function getChannel(channelId: number, requestInit?: RequestInit): Promise<GetChannelResponse> {
    const response = await fetchWithToken(`/api/youtube/channels/${channelId}/`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get youtube channel');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PostChannelData = {
    //handle || url
    identifier: string;
};

type PostChannelResponse = ApiResponse<Channel, 201> | ApiResponse<ApiResponseError<PostChannelData>, 400>;

export async function postYoutubeChannel(data: PostChannelData): Promise<PostChannelResponse> {
    const response = await fetchWithToken('/api/youtube/channels/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create youtube channel');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}
