import { Currency } from '@round/api';
import { asMoney } from 'helpers';
import styles from './TotalCostFooterCell.module.css';

type Props = {
    costs: (number | null)[];
    currency: Currency | undefined;
};

const TotalCostFooterCell = ({ costs, currency }: Props) => {
    const showCost = costs.some((cost) => cost !== null);
    const totalCost = costs.reduce((acc, cost) => (typeof cost === 'number' ? cost + (acc ?? 0) : acc), 0);

    return <span className={styles.cell}>{showCost ? asMoney(totalCost, currency) : '-'}</span>;
};

export default TotalCostFooterCell;
