import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { GroupType } from 'react-select';
import { GenericDropdownOption } from '../../../../../../../App.types';
import styles from './MediaPlanItemGroup.module.css';
import { ReactComponent as DropdownIcon } from '../../../../../../../SharedComponents/svg/SelectDropdownIndicator.svg';
import cn from 'classnames';

type MediaPlanItemGroupProps = PropsWithChildren<{
    group: MediaPlanItemGroupedOption;
    selectedItems: ReadonlyArray<GenericDropdownOption<number>>;
    setSelectedItems: Dispatch<SetStateAction<ReadonlyArray<GenericDropdownOption<number>>>>;
}>;

export type MediaPlanItemGroupedOption = GroupType<GenericDropdownOption<number>> & {
    id: number | undefined;
    label: string | undefined;
};

const MediaPlanItemGroup = ({ group, setSelectedItems, selectedItems, children }: MediaPlanItemGroupProps) => {
    const [isGroupExpanded, setIsGroupExpanded] = useState(false);
    const selectedItemsFromGroup = selectedItems.filter(
        (item) => !!group.options.find((option) => option.value === item.value)
    );
    const allSelected = selectedItemsFromGroup.length === group.options.length;

    const onSelectAllToggled = () =>
        setSelectedItems((items) => {
            if (allSelected) {
                return items.filter((item) => !group.options.find((option) => option.value === item.value));
            }

            const notSelectedOptions = group.options.filter(
                (option) => !items.find((item) => item.value === option.value)
            );
            return items.concat(notSelectedOptions);
        });

    return (
        <div>
            <div className={styles.head} onClick={() => setIsGroupExpanded(!isGroupExpanded)}>
                <div className={styles.labels}>
                    <p className={styles.label}>{group.label}</p>

                    <div>
                        <input
                            className={styles.toggleAll}
                            type="checkbox"
                            checked={allSelected}
                            onChange={onSelectAllToggled}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <label className={styles.toggleAllLabel}>
                            {selectedItemsFromGroup.length} of {group.options.length} selected
                        </label>
                    </div>
                </div>

                <DropdownIcon className={cn(styles.dropdownIcon, { [styles.expanded]: isGroupExpanded })} />
            </div>
            {isGroupExpanded && <div>{children}</div>}
        </div>
    );
};

export default MediaPlanItemGroup;
