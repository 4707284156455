import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import styles from './RefreshButton.module.css';

import CheckIcon from '../../SharedComponents/svg/Icons/CheckIcon';
import RefreshCCWIcon from '../../SharedComponents/svg/Icons/RefreshCCWIcon';
import { resetTime } from '../../utility/utility';

export type RefreshButtonProps = {
    loading: boolean;
    disabled: boolean;
    isRefreshed: boolean;
    lastUpdated: Date;
    onClick?: () => any;
    className?: string;
};

const RefreshButton = ({ loading, disabled, isRefreshed, lastUpdated, onClick, className }: RefreshButtonProps) => {
    const Icon = useMemo(() => {
        if (loading || !isRefreshed) {
            return RefreshCCWIcon;
        }

        return CheckIcon;
    }, [isRefreshed, loading]);

    const lastUpdatedLabel = useMemo(() => {
        const justNowThreshold = 1000 * 60 * 30;
        const oneDay = 1000 * 60 * 60 * 24;
        const now = new Date();
        if (now.getTime() - lastUpdated.getTime() <= justNowThreshold) {
            return 'Just now';
        }

        const daysPassed = Math.floor(Math.abs((resetTime(lastUpdated).getTime() - resetTime(now).getTime()) / oneDay));
        return daysPassed === 0 ? 'today' : daysPassed === 1 ? 'yesterday' : `${daysPassed} days ago`;
    }, [lastUpdated]);

    const onClickHandler = useCallback(() => {
        if (loading || disabled || typeof onClick !== 'function') {
            return;
        }

        onClick();
    }, [disabled, loading, onClick]);

    return (
        <button
            className={cn(styles.container, className, { [styles.disabled]: disabled || loading })}
            onClick={onClickHandler}
        >
            <div className={cn(styles.iconContainer, { [styles.disabled]: disabled || loading })}>
                <Icon
                    width={17}
                    height={17}
                    strokeWidth={3.5}
                    className={cn(styles.icon, { [styles.loading]: loading })}
                />
            </div>

            <div className={cn(styles.labelsContainer, { [styles.disabled]: disabled || loading })}>
                <p className={styles.label}>Refresh Data</p>
                <p className={styles.subLabel}>Last updated {lastUpdatedLabel}</p>
            </div>
        </button>
    );
};

export default RefreshButton;
