import React from 'react';

import styles from './TiktokArtist.module.css';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import TiktokArtistList from './TiktokArtistList/TiktokArtistList';

const TiktokArtist = () => {
    return (
        <div className={styles.tiktokArtist}>
            <NavBar />
            <TiktokArtistList />
        </div>
    );
};

export default TiktokArtist;
