import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { useState } from 'react';
import { Select } from '@round/ui-kit';
import CurrencyInput from 'react-currency-input-field';
import { StylesConfig, ValueType } from 'react-select';
import { GenericDropdownOption } from 'App.types';
import styles from 'ui-new/TableComponents/TableMoneyInput/TableMoneyInput.module.css';
import Option from '../../Select/components/Option';
import CostLabel from 'ui/DataDisplay/Money/CostLabel/CostLabel';
import PopoutEditCell from '../PopoutEditCell/PopoutEditCell';
import cn from 'classnames';

export type Amount = {
    currency: number | null;
    amount: number;
};

type Props = {
    value: Amount;
    onChange: (newValue: Amount) => void;
    isEditing: boolean;
    onEditingChange: (isEditing: boolean) => void;
    editClassName?: string;
    isReadonly?: boolean;
};

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        border: 'none',
        boxShadow: 'none',
        width: '4.125rem',
        color: '#111111',
        fontSize: '0.875rem',
    }),
    valueContainer: (base) => ({
        ...base,
        padding: 0,
        minWidth: 'max-content',
    }),
    menu: (base) => ({
        ...base,
        marginTop: '1.25rem',
        left: '-0.875rem',
        width: '12rem',
        borderRadius: '0.75rem',
        padding: '0.875rem',
        boxShadow: `0px 3px 20px 0px rgba(87, 87, 87, 0.10),
                    0px 29px 29px 0px rgba(87, 87, 87, 0.09),
                    0px 66px 39px 0px rgba(87, 87, 87, 0.05),
                    0px 117px 47px 0px rgba(87, 87, 87, 0.01),
                    0px 183px 51px 0px rgba(87, 87, 87, 0.00)`,
    }),
    menuList: (base) => ({
        ...base,
        fontFamily: 'Inter, sans-serif',
        lineHeight: 1.25,
        color: '#111111',
        '::-webkit-scrollbar': {
            width: '0.375rem',
            height: '0.375rem',
            background: 'transparent',
        },
        '::-webkit-scrollbar-thumb': {
            background: '#D1D5DB',
            borderRadius: '0.5rem',
        },
    }),
    option: (base) => ({
        ...base,
        background: 'white',
        ':hover': {
            background: '#F3F4F6',
        },
    }),
};

const TableMoneyInput = ({ value, onChange, isReadonly, isEditing, editClassName, ...props }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const currencyOptions: GenericDropdownOption<number>[] = currencies.map((c) => ({ value: c.id, label: c.name }));
    const currency = currencies.find((c) => c.id === value.currency);

    const [isCurrencyMenuOpen, setIsCurrencyMenuOpen] = useState(false);

    const [inputValue, setInputValue] = useState(value.amount.toString());
    const [currencyValue, setCurrencyValue] = useState<ValueType<GenericDropdownOption<number>, false>>(
        currency ? { value: currency.id, label: currency.name } : null
    );

    const onEditingChange = (isEditing: boolean) => {
        if (isCurrencyMenuOpen) {
            return;
        }

        const amountHasChanged = parseFloat(inputValue) !== value.amount || currencyValue?.value !== value.currency;
        if (amountHasChanged) {
            onChange({ amount: parseFloat(inputValue), currency: currencyValue?.value ?? null });
        }

        props.onEditingChange(isEditing);
    };

    return (
        <PopoutEditCell
            target={<CostLabel cost={value.amount} currencySymbol={currency?.symbol ?? ''} />}
            isEditing={isEditing}
            onEditingChange={onEditingChange}
            editClassName={cn(editClassName, styles.editContainer)}
            isReadonly={isReadonly}
        >
            <div className={styles.container}>
                <Select
                    isSearchable={false}
                    styles={selectStyles}
                    value={currencyValue}
                    onChange={setCurrencyValue}
                    options={currencyOptions}
                    onMenuOpen={() => setIsCurrencyMenuOpen(true)}
                    onMenuClose={() => setIsCurrencyMenuOpen(false)}
                    components={{ Option }}
                />

                <CurrencyInput
                    className={styles.input}
                    value={inputValue}
                    onValueChange={(value) => setInputValue(value || '')}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onEditingChange(false);
                        }
                    }}
                    decimalScale={2}
                    decimalsLimit={2}
                    decimalSeparator="."
                />
            </div>
        </PopoutEditCell>
    );
};

export default TableMoneyInput;
