import {
    InfluencerPlan,
    InstagramInfluencerPostPut,
    InstagramUserImage,
    PaginatedApiResponseData,
    PaginatedRequest,
    TiktokInfluencerPlanAudio,
    TiktokInfluencerPlanHashtag,
} from '../../../App.types';
import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '@round/api';
import { InfluencerPlanPostsRefreshRequest, InfluencerPostManualStatus } from './InfluencerPlan.types';
import {
    InstagramInfluencerPost,
    InstagramInfluencerPostApiBody,
    InstagramInfluencerPostResult,
} from './types/Instagram.types';
import { InstagramUserStats } from '../../Instagram/Instagram.types';
import { TiktokInfluencerPostApiBody } from './types/Tiktok.types';
import { ApiResponse, ApiResponseError, MakeRequired } from '../../../utility/utility.types';
import { TiktokUserImage, TiktokInfluencerPost } from '@round/api';

type GetInfluencerPlanParams = Partial<{
    search: string;
    release_id: number;
    id: string;
}>;

export async function getAllInfluencerPlans(
    params: GetInfluencerPlanParams,
    requestInit?: RequestInit
): Promise<InfluencerPlan[]> {
    const queryParams = encodeUrlSearchParams(params);
    return (await fetchAll(`/api/advertising/viewsets/influencer-plan/${queryParams}`, requestInit)) ?? [];
}

export async function getInfluencerPlans(
    { nextUrl, ...params }: GetInfluencerPlanParams & Partial<PaginatedRequest & { nextUrl?: string | null }>,
    requestInit?: RequestInit
): Promise<PaginatedApiResponseData<InfluencerPlan>> {
    const url = nextUrl || `/api/advertising/viewsets/influencer-plan/${encodeUrlSearchParams({ ...params })}`;
    const response = await fetchWithToken(url, requestInit);
    if (!response.ok) {
        throw new Error('Could not get influencer plans');
    }

    return response.json();
}

export async function fetchInstagramInfluencerPosts(
    influencerPlanId: string | number,
    requestInit?: RequestInit
): Promise<InstagramInfluencerPost[]> {
    return fetchAll(
        `/api/advertising/viewsets/instagram-influencer-post/?plan_id=${influencerPlanId}&page_size=100`,
        requestInit
    );
}

export async function fetchDeleteInfluencerPost(platform: string, influencerPostId: number): Promise<boolean> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/${platform}-influencer-post/${influencerPostId}/`,
        {
            method: 'DELETE',
        }
    );
    return response.ok;
}

type InfluencerPlatform = 'tiktok' | 'instagram';
type InfluencerPlanPost = TiktokInfluencerPost | InstagramInfluencerPost;

export async function getInfluencerPostsByXeroInvoiceIds(
    platform: 'tiktok',
    invoice_ids: number[] | number,
    requestInit?: RequestInit
): Promise<TiktokInfluencerPost[]>;
export async function getInfluencerPostsByXeroInvoiceIds(
    platform: 'instagram',
    invoice_ids: number[] | number,
    requestInit?: RequestInit
): Promise<InstagramInfluencerPost[]>;
export async function getInfluencerPostsByXeroInvoiceIds(
    platform: InfluencerPlatform,
    invoice_ids: number[] | number,
    requestInit?: RequestInit
): Promise<InfluencerPlanPost[]> {
    const formattedIds = typeof invoice_ids !== 'number' ? invoice_ids.join() : invoice_ids;

    return fetchAll(
        `/api/advertising/viewsets/${platform}-influencer-post/?xero_invoice_id=${formattedIds}&status=live`,
        requestInit
    );
}
export type CreateInstagramInfluencerPostBody = MakeRequired<
    Partial<InstagramInfluencerPostApiBody>,
    'currency_id' | 'post_url' | 'cost'
>;
export type PostInstagramInfluencerPostResponse =
    | ApiResponse<InstagramInfluencerPost, 201>
    | ApiResponse<ApiResponseError<InstagramInfluencerPost>, 400>;
export async function fetchCreateInstagramInfluencerPost(
    influencerPlanId: string | number,
    groupId: number,
    post: CreateInstagramInfluencerPostBody
): Promise<PostInstagramInfluencerPostResponse> {
    const response = await fetchWithToken('/api/advertising/viewsets/instagram-influencer-post/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            plan_id: influencerPlanId,
            group_id: groupId,
            influencer_id: post.influencer_id,
            cost: post.cost,
            currency_id: post.currency_id,
            post_url: post.post_url,
        }),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not create instagram influencer post`);
    }
    return {
        status: 201,
        data: await response.json(),
    };
}

export async function fetchUpdateInstagramInfluencerPost(
    post: Partial<InstagramInfluencerPostPut>
): Promise<InstagramInfluencerPost | undefined> {
    const response = await fetchWithToken(`/api/advertising/viewsets/instagram-influencer-post/${post.id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(post),
    });
    if (response.ok) {
        return await response.json();
    }
}

export async function fetchInstagramInfluencerPostResults(
    params: Partial<{
        plan_id: string;
        post_id: string;
    }>
): Promise<InstagramInfluencerPostResult[]> {
    return fetchAll(`/api/advertising/viewsets/instagram-influencer-post-results/${encodeUrlSearchParams(params)}`);
}

export type CreateTiktokInfluencerPostBody = MakeRequired<
    Partial<TiktokInfluencerPostApiBody>,
    'currency_id' | 'cost' | 'post_url'
>;
export type CreateTiktokInfluencerPostResponse =
    | ApiResponse<TiktokInfluencerPost, 201>
    | ApiResponse<ApiResponseError<TiktokInfluencerPost>, 400>;
export async function fetchCreateTiktokInfluencerPost(
    influencerPlanId: string | number,
    groupId: number,
    post: CreateTiktokInfluencerPostBody
): Promise<CreateTiktokInfluencerPostResponse> {
    const response = await fetchWithToken('/api/advertising/viewsets/tiktok-influencer-post/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            plan_id: influencerPlanId,
            group_id: groupId,
            influencer_id: post.influencer_id,
            cost: post.cost,
            currency_id: post.currency_id,
            xero_invoice_id: post.xero_invoice_id,
            post_url: post.post_url,
            is_carousel: post.is_carousel,
        }),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(
            `Could not create tiktok influencer post for group ${groupId} influencer plan ${influencerPlanId}`
        );
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export async function fetchTiktokInfluencerPlanHashtag(
    influencerPlanId: string | number,
    requestInit?: RequestInit
): Promise<TiktokInfluencerPlanHashtag[]> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/tiktok-influencer-plan-hashtag/?plan_id=${influencerPlanId}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error("Sorry, we're experiencing technical issues");
    }

    const body = (await response.json()) as PaginatedApiResponseData<TiktokInfluencerPlanHashtag>;
    return body.results;
}

export async function fetchTiktokUserImages(userIds: number[]): Promise<TiktokUserImage[]> {
    const response = await fetchWithToken(`/api/tiktok/user-image/?user_id=${userIds}`);
    if (!response.ok) {
        throw new Error(`Couldn't fetch titkok user images for ${userIds.toString()}`);
    }
    return (await response.json()) as TiktokUserImage[];
}

export async function fetchTiktokInfluencerPlanAudio(
    influencerPlanId: string | number,
    requestInit?: RequestInit
): Promise<TiktokInfluencerPlanAudio[]> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/tiktok-influencer-plan-audio/?plan_id=${influencerPlanId}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not fetch tiktok audios');
    }

    const body = (await response.json()) as PaginatedApiResponseData<TiktokInfluencerPlanAudio>;
    return body.results;
}

export async function fetchInstagramUserStats(
    userIds: number[],
    requestInit?: RequestInit
): Promise<InstagramUserStats[]> {
    if (!userIds.length) {
        return [];
    }

    const stats = await fetchAll<InstagramUserStats>(
        `/api/instagram/instagram-user-stats/?user_ids=${userIds.join(',')}&latest=true`,
        requestInit
    );

    return stats ?? [];
}

export async function fetchInstagramUserImages(userIds: number[]): Promise<InstagramUserImage[]> {
    if (!userIds.length) {
        return [];
    }

    const response = await fetchWithToken(`/api/instagram/user-image/?user_id=${userIds.join(',')}`);
    if (!response.ok) {
        return [];
    }

    return response.json();
}

export async function getInfluencerPlanPostsRefreshRequests(
    influencerPlan: number,
    requestInit?: RequestInit
): Promise<InfluencerPlanPostsRefreshRequest[]> {
    return (
        (await fetchAll(
            `/api/advertising/influencer-plan-posts-refresh-request/?influencer_plan=${influencerPlan}`,
            requestInit
        )) ?? []
    );
}

/**
 * Send a request to refresh post data for given influencer plan
 * @param influencerPlan - influencer plan id
 * @return true if request was successful
 * @throws error if request was unsuccessful
 */
export async function refreshInfluencerPlanPosts(influencerPlan: number): Promise<{ to_refresh: number }> {
    const response = await fetchWithToken(`/api/advertising/refresh-influencer-plan-posts/${influencerPlan}/`);
    if (!response.ok) {
        throw new Error('Could not refresh posts');
    }
    return response.json();
}

export async function setManualStatusOnTiktokInfluencerPost(data: InfluencerPostManualStatus) {
    const response = await fetchWithToken(`/api/advertising/viewsets/tiktok-influencer-post-manual-status/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(`Could not set status for tiktok influencer post: ${data.influencer_post_id}`);
    }
}

export async function setManualStatusOnInstagramInfluencerPost(data: InfluencerPostManualStatus) {
    const response = await fetchWithToken(`/api/advertising/viewsets/instagram-influencer-post-manual-status/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(`Could not set status for instagram influencer post: ${data.influencer_post_id}`);
    }
}

export async function getInstagramInfluencerPost(postId: number): Promise<InstagramInfluencerPost> {
    const response = await fetchWithToken(`/api/advertising/viewsets/instagram-influencer-post/${postId}/`);
    if (!response.ok) {
        throw new Error(`Could not fetch instagram influencer post: ${postId}`);
    }

    return response.json();
}

type TiktokInfluencerPlanAudioParams = {
    url: string;
    plan: number;
};

export async function postTiktokInfluencerPlanAudio(
    params: TiktokInfluencerPlanAudioParams
): Promise<TiktokInfluencerPlanAudio> {
    const response = await fetchWithToken(`/api/advertising/viewsets/tiktok-influencer-plan-audio/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    });

    if (!response.ok) {
        throw new Error(`Could not create audio with url ${params.url}`);
    }

    return response.json();
}

type TiktokInfluencerPlanHashtagParams = {
    plan: number;
    hashtag: string;
};

export async function postTiktokInfluencerPlanHashtag(
    params: TiktokInfluencerPlanHashtagParams
): Promise<TiktokInfluencerPlanHashtag> {
    const response = await fetchWithToken(`/api/advertising/viewsets/tiktok-influencer-plan-hashtag/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    });

    if (!response.ok) {
        throw new Error(`Could not create hashtag ${params.hashtag}`);
    }

    return response.json();
}

type PostTiktokInfluencerPostBody = MakeRequired<
    Partial<TiktokInfluencerPostApiBody>,
    'plan_id' | 'currency_id' | 'post_url' | 'cost'
>;

type PostTiktokInfluencerPostResponse =
    | ApiResponse<TiktokInfluencerPost, 201>
    | ApiResponse<ApiResponseError<TiktokInfluencerPost>, 400>;
export async function postTiktokInfluencerPost(
    data: PostTiktokInfluencerPostBody
): Promise<PostTiktokInfluencerPostResponse> {
    const response = await fetchWithToken(`/api/advertising/viewsets/tiktok-influencer-post/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not post tiktok influencer post');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type ExchangeRateResponseType = {
    exchange_rate_multiplier: string;
};

export async function getInfluencerPlanExchangeRatetoTargetCurrency(
    planId: number,
    currencyName: string,
    requestInit?: RequestInit
): Promise<ExchangeRateResponseType> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/influencer-plan/${planId}/exchange-rate-multiplier/?currency=${currencyName}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not fetch currency conversion rate`);
    }

    return response.json();
}
