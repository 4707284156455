import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokAudioCollectionHeadlineStats } from './collectionHeadlineStats.types';
import { createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokAudioCollectionHeadlineStats: Model as ModelDefinition<TiktokAudioCollectionHeadlineStats>,
};

export const serializers = {
    tiktokAudioCollectionHeadlineStats: makeSerializer<TiktokAudioCollectionHeadlineStats>([]),
};

export const factories = {
    tiktokAudioCollectionHeadlineStats: createFactory<TiktokAudioCollectionHeadlineStats>({
        audio_count: 0,
        new_video_count_1_day: 0,
        new_video_count_7_days: 0,
        total_video_count: 0,
    }),
};

export function handleRequests(server: AppServer) {
    server.get('/api/catalogue/viewsets/catalogue/:collectionId/stats/', function (this: RouteHandlerContext, schema) {
        return schema.first('tiktokAudioCollectionHeadlineStats');
    });
}
