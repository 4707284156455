import { useSessionStorage } from '@round/utils';
import { FunctionComponent, ReactNode, SVGProps, useState } from 'react';

export type Helpers = {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    remove: () => void;
};

export type Filter<T extends string> = {
    name: T;
    label: string;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    render: (helpers: Helpers) => ReactNode;
};

export type Options<T extends string> = Partial<{
    initialFilters: T[] | ((sessionState: T[] | null) => T[]);
}>;

export default function useFilterManager<T extends string>(key: string, filters: Filter<T>[], options?: Options<T>) {
    const [selectedFilterNames, setSelectedFilterNames] = useSessionStorage(key, options?.initialFilters);
    const [openFilterName, setOpenFilterName] = useState<T | undefined>();

    const isOpen = (name: T) => openFilterName === name;

    const setOpen = (name: T, isOpen: boolean) => {
        setOpenFilterName((prev) => {
            if (isOpen) {
                return name;
            }

            return prev === name ? undefined : prev;
        });
    };

    const setSelected = (name: T, shouldBeSelected: boolean) => {
        setSelectedFilterNames((prev) => {
            const isCurrentlySelected = prev?.includes(name);
            if ((shouldBeSelected && isCurrentlySelected) || (!shouldBeSelected && !isCurrentlySelected)) {
                return prev;
            }

            if (shouldBeSelected && !isCurrentlySelected) {
                return (prev ?? []).concat(name);
            }

            return (prev ?? []).filter((n) => n !== name);
        });
    };

    return {
        selectedFilters: filters.filter((f) => selectedFilterNames?.includes(f.name)),
        unselectedFilters: filters.filter((f) => !selectedFilterNames?.includes(f.name)),
        isOpen,
        setOpen,
        setSelected,
    };
}
