import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import cn from 'classnames';
import styles from '../Dropdown.module.css';

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

export const Item = ({ children, className, icon, ...props }: Props) => {
    const Icon = icon;
    return (
        <button className={cn(styles.item, className)} {...props}>
            {Icon && <Icon className={styles.icon} />}
            {children}
        </button>
    );
};
