import { components } from 'react-select';
import styles from '../FacebookTargetingCustomAudienceSelect.module.css';
import cn from 'classnames';
import React from 'react';

const MenuList = (props: any) => {
    const filterModes = props.selectProps.filterModes ?? [];

    return (
        <components.MenuList {...props}>
            {filterModes.length && (
                <div className={styles.menuTabsContainer}>
                    <ul className={styles.menuTabList}>
                        {filterModes.map((filterMode: any) => (
                            <li
                                key={filterMode.name}
                                className={cn(styles.menuTab, {
                                    [styles.active]: filterMode.name === props.selectProps.filterMode.name,
                                })}
                                onClick={() => props.selectProps.setFilterMode(filterMode)}
                            >
                                {filterMode.label}
                            </li>
                        ))}
                    </ul>
                    <div className={styles.menuTabsIndicator} />
                </div>
            )}
            {props.children}
        </components.MenuList>
    );
};

export default MenuList;
