import React, { useCallback, useMemo, useState } from 'react';
import YoutubePlaylistReportItemForm, {
    YoutubePlaylistReportItemFormProps,
} from '../YoutubePlaylistReportItemForm/YoutubePlaylistReportItemForm';
import { PlaylistReportItem } from '../../../../../App.types';
import { showNotification } from '../../../../../helpers';
import { deleteYoutubePlaylistReportItem, patchYoutubePlaylistReportItem } from '../YoutubeReportItems.api';
import { mapApiErrorsToFormikErrors } from '../../../../../utility/utility';
import Modal, { ModalContent, ModalTitle } from '../../../../../ui/General/Modal/Modal';
import Button from '../../../../../ui/Buttons/Button/Button';
import styles from './EditYoutubePlaylistReportItem.module.css';

type EditYoutubePlaylistReportItemProps = {
    isModalOpen: boolean;
    onClose: () => void;
    reportItem: PlaylistReportItem | null;
    onUpdated: () => void;
    onDeleted: () => void;
};

const EditYoutubePlaylistReportItem = ({
    onUpdated,
    onClose,
    isModalOpen,
    reportItem,
    onDeleted,
}: EditYoutubePlaylistReportItemProps) => {
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const initialValues: YoutubePlaylistReportItemFormProps['initialValues'] = useMemo(
        () => ({
            media_plan_item_id: reportItem?.media_plan_item.id,
            amount_spent: reportItem ? Number(reportItem.amount_spent) : undefined,
        }),
        [reportItem]
    );

    const handleSubmit: YoutubePlaylistReportItemFormProps['onSubmit'] = useCallback(
        async (values, { setSubmitting, setErrors }) => {
            if (!reportItem) {
                return;
            }

            try {
                setSubmitting(true);
                const response = await patchYoutubePlaylistReportItem(reportItem.id, values);
                if (response.status === 400) {
                    setErrors(mapApiErrorsToFormikErrors(response.data));
                    return;
                }

                if (response.status === 404) {
                    showNotification("Report item doesn't exist", 'error');
                    return;
                }

                onUpdated();
                showNotification('Report item updated', 'info');
            } catch (e) {
                showNotification('Could not update report item', 'error');
            } finally {
                setSubmitting(false);
            }
        },
        [onUpdated, reportItem]
    );

    const handleDelete = useCallback(
        async (id: number) => {
            try {
                setIsDeleteLoading(true);
                const response = await deleteYoutubePlaylistReportItem(id);
                if (response.status === 404) {
                    showNotification("Report item doesn't exist", 'error');
                    return;
                }

                onDeleted();
                showNotification('Report item deleted', 'info');
            } catch {
                showNotification("Couldn't delete report item", 'error');
            } finally {
                setIsDeleteLoading(false);
            }
        },
        [onDeleted]
    );

    return (
        <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={onClose}>
            <ModalTitle>Edit Youtube Playlist Report Item</ModalTitle>
            <ModalContent>
                <YoutubePlaylistReportItemForm
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    renderActions={({ submitForm, isValid, isSubmitting }) => (
                        <div className={styles.actions}>
                            <Button
                                htmlType="button"
                                type="filled"
                                color="black"
                                disabled={!isValid}
                                loading={isSubmitting}
                                onClick={submitForm}
                            >
                                Save
                            </Button>

                            <Button
                                htmlType="button"
                                disabled={!reportItem}
                                loading={isDeleteLoading}
                                onClick={() => {
                                    if (reportItem) {
                                        handleDelete(reportItem.id);
                                    }
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                    )}
                />
            </ModalContent>
        </Modal>
    );
};

export default EditYoutubePlaylistReportItem;
