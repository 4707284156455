import React, { CSSProperties, HTMLProps } from 'react';
import { Column, Row, useTable } from 'react-table';

const tableStyles: CSSProperties = {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'left',
    tableLayout: 'fixed',
    fontSize: '12px',
};

const captionStyles: CSSProperties = {
    textAlign: 'left',
    border: '1px solid black',
    borderCollapse: 'collapse',
    borderBottom: 'none',
    fontWeight: 'bold',
    padding: '5px',
    backgroundColor: '#eeeeee',
};

const thStyles: CSSProperties = {
    fontWeight: 'bold',
    border: '1px solid black',
    padding: '5px',
    backgroundColor: '#eeeeee',
};

const tdStyles: CSSProperties = {
    border: '1px solid black',
    padding: '5px',
};

const statsStyles: CSSProperties = {
    fontWeight: 'bold',
    padding: '5px',
    textAlign: 'right',
};

const linkStyles: CSSProperties = {
    textAlign: 'center',
    display: 'block',
};

type ClipboardTableProps<T extends object> = {
    columns: Column<T>[];
    data: T[];
    caption?: string;
    renderFooter?: () => React.ReactNode;
    renderRow?: (row: Row<T>, index: number, array: Row<T>[]) => React.ReactNode;
};

const renderDefaultRow = <T extends object>(row: Row<T>) => (
    <tr {...row.getRowProps()}>
        {row.cells.map((cell, index) => (
            <td {...cell.getCellProps()} colSpan={index > 0 ? 1 : 2} style={tdStyles}>
                {cell.render('Cell')}
            </td>
        ))}
    </tr>
);

const ClipboardTable = <T extends object>({
    caption,
    data,
    columns,
    renderFooter,
    renderRow = renderDefaultRow,
}: ClipboardTableProps<T>) => {
    const { headers, rows, prepareRow } = useTable({ columns, data });

    return (
        <table style={tableStyles}>
            {caption && (
                <caption>
                    <div style={captionStyles}>{caption}</div>
                </caption>
            )}
            <thead>
                <tr>
                    {headers.map((column, index) => (
                        <th style={thStyles} {...column.getHeaderProps()} colSpan={index > 0 ? 1 : 2}>
                            {column.render('Header')}
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {rows.map((row, index, array) => {
                    prepareRow(row);
                    return renderRow(row, index, array);
                })}
            </tbody>

            {typeof renderFooter === 'function' && renderFooter()}
        </table>
    );
};

ClipboardTable.Link = (props: HTMLProps<HTMLAnchorElement>) => {
    return (
        <a {...props} style={linkStyles}>
            {props.children}
        </a>
    );
};

ClipboardTable.Stats = (props: HTMLProps<HTMLDivElement>) => {
    return (
        <div {...props} style={statsStyles}>
            {props.children}
        </div>
    );
};

export default ClipboardTable;
