import { DomainFiltersProps, getInitialFilters } from 'ui-new/Filters/helpers';
import { Filter } from 'ui-new/Filters/useFilterManager';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { ReactComponent as HashIcon } from 'assets/icons/Hash.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/Share.svg';
import styles from './Filters.module.css';
import MicrowaveInfluencerFilter from 'Modules/Advertising/Microwave/components/Filters/MicrowaveInfluencerFilter/MicrowaveInfluencerFilter';
import TextFilter from 'ui-new/Filters/TextFilter/TextFilter';
import Filters from 'ui-new/Filters/Filters';
import { getNonEmptyKeys } from 'utility/utility';
import { microwave } from '@round/api';
import PlatformFilter from 'Modules/Advertising/Microwave/components/Filters/PlatformFilter/PlatformFilter';

export type ManualPastPaymentFilterParams = Pick<
    microwave.GetPaymentRequestsParams,
    'influencer_id' | 'invite_id' | 'platform'
>;

export const getFilterValues = (
    urlState: Partial<Record<keyof ManualPastPaymentFilterParams, string>>
): ManualPastPaymentFilterParams => {
    return {
        influencer_id: urlState.influencer_id ? Number(urlState.influencer_id) : undefined,
        invite_id: urlState.invite_id,
        platform: urlState.platform,
    };
};

type Props = DomainFiltersProps<ManualPastPaymentFilterParams>;

const ManualPastPaymentFilters = ({ value, onChange }: Props) => {
    const filters: Filter<keyof ManualPastPaymentFilterParams>[] = [
        {
            name: 'influencer_id',
            label: 'Account',
            icon: UserIcon,
            render(this, helpers) {
                const Icon = this.icon;

                return (
                    <MicrowaveInfluencerFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        name={this.label}
                        icon={<Icon className={styles.icon} />}
                        initialInfluencerId={value.influencer_id}
                        onChange={(val) => onChange({ influencer_id: val?.value })}
                        onClear={() => onChange({ influencer_id: undefined })}
                        onRemove={() => {
                            onChange({ influencer_id: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'invite_id',
            label: 'Invite ID',
            icon: HashIcon,
            render(this, helpers) {
                const Icon = this.icon;

                return (
                    <TextFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        icon={<Icon className={styles.icon} />}
                        name={this.label}
                        placeholder="Enter invite ID"
                        value={value.invite_id ?? ''}
                        onChange={(val) => onChange({ invite_id: val ?? undefined })}
                        onClear={() => {
                            onChange({ invite_id: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'platform',
            label: 'Platform',
            icon: ShareIcon,
            render(this, helpers) {
                const Icon = this.icon;
                return (
                    <PlatformFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        icon={<Icon className={styles.icon} />}
                        name={this.label}
                        value={value.platform as microwave.Platform}
                        onChange={(val) => onChange({ platform: val })}
                        onClear={() => onChange({ platform: undefined })}
                        onRemove={() => {
                            onChange({ platform: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
    ];

    return (
        <Filters
            sessionKey="microwave-past-payments-manual-filters-v2"
            filters={filters}
            options={{ initialFilters: (state) => getInitialFilters(state, getNonEmptyKeys(value)) }}
        />
    );
};

export default ManualPastPaymentFilters;
