import { AudioData } from '../../../../TikTok.types';

export enum AudioTypes {
    Official = 'Official',
    OriginalOwn = 'Original (own)',
    OriginalOther = 'Original (other)',
}

export type AggregatedAudioData = AudioData & { type: AudioTypes; numberOfPosts: number };
export type AudioDataStats = Pick<
    AggregatedAudioData,
    'video_plays' | 'post_likes' | 'post_comments' | 'post_shares' | 'post_saves'
>;
export type AggregatedDataByType = AudioDataStats & Pick<AggregatedAudioData, 'type' | 'numberOfPosts'>;

export const getAudioType = (audio: AudioData): AudioTypes => {
    if (audio.audio_type === 'Official') {
        return AudioTypes.Official;
    }

    return audio.is_own_audio ? AudioTypes.OriginalOwn : AudioTypes.OriginalOther;
};

export const getAverageAudioValues = (audios: AudioData[]): AudioDataStats => {
    const averageVideoPlays = parseInt(
        String(audios.reduce((acc, { video_plays }) => acc + video_plays, 0) / audios.length)
    );
    const averagePostLike = parseInt(
        String(audios.reduce((acc, { post_likes }) => acc + post_likes, 0) / audios.length)
    );
    const averagePostComments = parseInt(
        String(audios.reduce((acc, { post_comments }) => acc + post_comments, 0) / audios.length)
    );
    const averageShares = parseInt(
        String(audios.reduce((acc, { post_shares }) => acc + post_shares, 0) / audios.length)
    );
    const averageSaves = parseInt(
        String(audios.reduce((acc, { post_saves }) => acc + (post_saves ?? 0), 0) / audios.length)
    );

    return {
        video_plays: averageVideoPlays,
        post_likes: averagePostLike,
        post_comments: averagePostComments,
        post_shares: averageShares,
        post_saves: averageSaves,
    };
};
