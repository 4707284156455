import React, { useCallback, useState } from 'react';

import styles from './AdminPage.module.css';
import { NavBar } from '../NavBar/NavBar';
import { PaginatedApiResponseData, FormField } from '../../App.types';
import { WriteModelModal } from '../WriteModelModal/WriteModelModal';
import { encodeUrlSearchParams, fetchWithToken } from '@round/api';
import Button from '../../ui/Buttons/Button/Button';
import SearchInput from '../../ui/SearchInput/SearchInput';
import useAbortableEffect from '../../Hooks/useAbortableEffect';
import useUrlState from '../../Hooks/useUrlState';

type AdminPageProps<T, U> = {
    modelName: string;
    pluralName?: string;
    url: string | ((method?: string, id?: number | null) => string);
    initialValues: U;
    instanceToFormValues: (instance: T) => U;
    formFields: FormField[];
    listDisplay: ListDisplayConfig<T>[];
    enableDeletion?: Boolean;
    toBody?: (values: U) => Record<string, any>;
    searchable?: boolean;
    deletable?: boolean;
};

export type ListDisplayConfig<T> = { heading: string; instanceToProperty: (instance: T) => React.ReactNode };

interface Entity {
    id: number;
}

export type Dictionary = Record<string, unknown>;

export function AdminPage<T extends Entity, U extends Dictionary>({
    modelName,
    pluralName,
    url,
    initialValues,
    instanceToFormValues,
    formFields,
    listDisplay,
    enableDeletion,
    toBody,
    searchable,
    deletable,
}: AdminPageProps<T, U>) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modelInstances, setModelInstances] = useState<T[]>([]);
    const [instanceToUpdate, setInstanceToUpdate] = useState<T>();
    const [isUpdateInstanceModalOpen, setIsUpdateInstanceModalOpen] = useState(false);
    const [refreshToggle, setRefreshToggle] = useState(false);
    const [nextUrl, setNextUrl] = useState<string | null>(null);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [urlState, setUrlState] = useUrlState<{ search: string }>();
    const search = urlState.search;
    const setSearch = (search: string) => setUrlState({ search });

    const onCreate = () => {
        setIsModalOpen(false);
        setRefreshToggle(!refreshToggle);
    };
    const onUpdateInstance = () => {
        setIsUpdateInstanceModalOpen(false);
        setInstanceToUpdate(undefined);
        setRefreshToggle(!refreshToggle);
    };
    const openUpdateInstanceModal = (instance: T) => {
        setInstanceToUpdate(instance);
        setIsUpdateInstanceModalOpen(true);
    };
    const onDelete = async (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
        e.stopPropagation();

        const response = await fetchWithToken(typeof url === 'function' ? url('DELETE', id) : url, {
            method: 'DELETE',
        });
        if (response.ok) {
            setModelInstances(modelInstances.filter((instance) => instance.id !== id));
        }
    };

    const loadMore = useCallback(async () => {
        if (!nextUrl) {
            return;
        }

        try {
            setLoadMoreLoading(true);
            const response = await fetchWithToken(nextUrl);
            if (response.ok) {
                const body = (await response.json()) as PaginatedApiResponseData<T>;
                setModelInstances((instances) => instances.concat(body.results.sort((a, b) => a.id - b.id)));
                setNextUrl(body.next ?? null);
            }
        } catch {
            // no-op
        } finally {
            setLoadMoreLoading(false);
        }
    }, [nextUrl]);

    useAbortableEffect(
        (signal) => {
            async function fetchData() {
                const resultingUrl = typeof url === 'function' ? url() : url;
                const params = encodeUrlSearchParams({ search: searchable ? search : undefined });
                const response = await fetchWithToken(`${resultingUrl}${params}`, { signal });
                if (response.ok) {
                    const body = (await response.json()) as PaginatedApiResponseData<T>;
                    setModelInstances(body.results.sort((a, b) => a.id - b.id));
                    setNextUrl(body.next ?? null);
                }
            }
            fetchData();
        },
        [refreshToggle, url, search, searchable]
    );

    return (
        <div className={styles.content}>
            {instanceToUpdate && (
                <WriteModelModal
                    closeModal={() => {
                        setIsUpdateInstanceModalOpen(false);
                        setInstanceToUpdate(undefined);
                    }}
                    isModalOpen={isUpdateInstanceModalOpen}
                    onSuccess={onUpdateInstance}
                    initialValues={instanceToFormValues(instanceToUpdate)}
                    url={url}
                    method={'PATCH'}
                    title={`Change ${modelName}`}
                    buttonText={`Save ${modelName}`}
                    fields={formFields}
                    instanceToUpdateId={instanceToUpdate.id}
                    toBody={toBody}
                    deletable={deletable}
                    onDelete={(deletedId) => {
                        setModelInstances((instances) => instances.filter((instance) => instance.id !== deletedId));
                        setIsUpdateInstanceModalOpen(false);
                        setInstanceToUpdate(undefined);
                    }}
                />
            )}
            <NavBar />
            <div className={styles.container}>
                <h1>{pluralName ? pluralName : `${modelName}s`}</h1>
                <div className={styles.toolbar}>
                    <div className={styles.searchContainer}>
                        {searchable && <SearchInput className={styles.search} value={search} onChange={setSearch} />}
                    </div>
                    <button
                        className={styles.addPlanButton}
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                    >{`Create new ${modelName.toLowerCase()}`}</button>
                    <WriteModelModal
                        closeModal={() => setIsModalOpen(false)}
                        isModalOpen={isModalOpen}
                        onSuccess={onCreate}
                        initialValues={initialValues}
                        url={url}
                        method={'POST'}
                        title={`Add ${modelName}`}
                        buttonText={`Create ${modelName}`}
                        fields={formFields}
                        instanceToUpdateId={null}
                        toBody={toBody}
                    />
                </div>
                <div className={styles.tableContainer}>
                    <table className={styles.table}>
                        <thead>
                            <tr className={styles.columnHeaderRow}>
                                {listDisplay.map((ld) => (
                                    <th key={ld.heading}>{ld.heading}</th>
                                ))}
                                {enableDeletion && <th />}
                            </tr>
                        </thead>
                        <tbody>
                            {modelInstances.map((instance) => (
                                <tr
                                    key={instance.id}
                                    className={styles.tableRow}
                                    onClick={() => openUpdateInstanceModal(instance)}
                                >
                                    {listDisplay.map((ld, i) => (
                                        <td key={i}>{ld.instanceToProperty(instance)}</td>
                                    ))}
                                    {enableDeletion && (
                                        <td>
                                            <button
                                                className={styles.deleteButton}
                                                onClick={(e) => onDelete(e, instance.id)}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {typeof nextUrl === 'string' && (
                        <div className={styles.loadMoreContainer}>
                            <Button loading={loadMoreLoading} className={styles.loadMoreButton} onClick={loadMore}>
                                Load More
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AdminPage;
