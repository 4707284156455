import { Context } from '@fluentui/react-context-selector';
import { creatorbase } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { Dispatch, useCallback } from 'react';

type ProjectUnion = creatorbase.Project | creatorbase.PublicProject;

export type State<T extends ProjectUnion> = {
    [projectId: number]: DataState<T | null> | undefined;
};

export const initialState = {};

export type Actions<T extends ProjectUnion> =
    | ReducerActionWithPayload<'loadProjects', number[]>
    | ReducerActionWithPayload<'errorLoadingProjects', { projectIds: number[]; error: string }>
    | ReducerActionWithPayload<'successLoadingProjects', { projectIds: number[]; projects: T[] }>
    | ReducerAction<'resetProjects'>;

export const makeProjectsReducer = <T extends ProjectUnion>() => {
    return createReducer<State<T>, Actions<T>>({
        loadProjects: (state, { payload: projectIds }) => {
            const loadingState = projectIds.reduce((acc, projectId) => {
                acc[projectId] = { status: 'loading', error: null, data: null };
                return acc;
            }, {} as State<T>);

            return { ...state, ...loadingState };
        },
        errorLoadingProjects: (state, { payload: { projectIds, error } }) => {
            const errorState = projectIds.reduce((acc, projectId) => {
                acc[projectId] = { status: 'error', error, data: null };
                return acc;
            }, {} as State<T>);

            return { ...state, ...errorState };
        },
        successLoadingProjects: (state, { payload: { projectIds, projects } }) => {
            const successState = projectIds.reduce((acc, projectId) => {
                acc[projectId] = {
                    status: 'success',
                    data: projects.find((p) => p.id === projectId) ?? null,
                    error: null,
                };

                return acc;
            }, {} as State<T>);

            return { ...state, ...successState };
        },
        resetProjects: () => initialState,
    });
};

export const makeProjectDataHook = <Value extends [any, Dispatch<Actions<P>>] | null, P extends ProjectUnion>(
    context: Context<Value>,
    selector: ContextSelector<Value, State<P>>
) => {
    return () => {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (projectIds: number[], requestInit?: RequestInit) => {
                if (!projectIds.length) {
                    dispatch({ type: 'successLoadingProjects', payload: { projectIds: [], projects: [] } });
                    return;
                }

                try {
                    dispatch({ type: 'loadProjects', payload: projectIds });
                    const response = await creatorbase.getProjects(
                        { id: projectIds.toString(), page_size: projectIds.length },
                        requestInit
                    );

                    if (response.status === 200) {
                        dispatch({
                            type: 'successLoadingProjects',
                            // type assertion is ok here because overloads of getProjects provide ProjectUnion type
                            payload: { projectIds, projects: response.data.results as P[] },
                        });
                        return response;
                    }

                    dispatch({ type: 'errorLoadingProjects', payload: { projectIds, error: response.data.detail } });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({
                        type: 'errorLoadingProjects',
                        payload: { projectIds, error: 'Could not load projects' },
                    });
                    throw e;
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
};
