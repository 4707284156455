import { MonitoredAudioTrackingTypeLabels, MonitoredAudioTrackingTypes } from '@round/api';
import { Badge, Skeleton } from '@round/ui-kit';
import styles from './TiktokAudioBadge.module.css';
import { buildTiktokMusicUrl } from '../../../../helpers';
import cn from 'classnames';
import AudioPlaybackThumbnail from 'Modules/AudioPlayer/AudioPlaybackThumbnail/AudioPlaybackThumbnail';

export type TiktokAudioBadgeInfo = {
    imageUrl?: string;
    audioPlayUrl?: string;
    tiktokId: string;
    title: string;
    authorName?: string;
    isOriginal?: boolean;
    trackingType?: MonitoredAudioTrackingTypes;
};

type Props = {
    audio: TiktokAudioBadgeInfo | null | undefined;
    loading?: boolean;
    className?: string;
};

const TiktokAudioBadge = ({ loading, audio, className }: Props) => {
    return (
        <div className={cn(styles.container, className)}>
            <AudioPlaybackThumbnail
                className={styles.thumbnail}
                loading={loading}
                audioUrl={audio?.audioPlayUrl}
                imageUrl={audio?.imageUrl}
            />
            <div className={styles.info}>
                <div className={styles.titleContainer}>
                    <div className={styles.titlesColumn}>
                        <span>
                            {loading ? (
                                <Skeleton width="10rem" />
                            ) : (
                                <a
                                    className={styles.title}
                                    onClick={(e) => e.stopPropagation()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={buildTiktokMusicUrl(audio?.title ?? 'original-sound', audio?.tiktokId ?? '')}
                                >
                                    {audio?.title}
                                </a>
                            )}
                        </span>

                        {audio?.authorName && (
                            <span>{loading ? <Skeleton width="8rem" /> : <>{audio.authorName}</>}</span>
                        )}
                    </div>
                </div>

                <div className={styles.badges}>
                    {typeof audio?.isOriginal === 'boolean' && (
                        <Badge
                            className={cn(styles.badge, {
                                [styles.original]: audio.isOriginal,
                                [styles.official]: !audio.isOriginal,
                                [styles.loading]: loading,
                            })}
                            loading={loading}
                        >
                            {audio.isOriginal ? 'Original' : 'Official'}
                        </Badge>
                    )}
                    {audio?.trackingType && audio.trackingType !== MonitoredAudioTrackingTypes.Basic && !loading && (
                        <Badge
                            className={cn(
                                styles.badge,
                                styles[MonitoredAudioTrackingTypeLabels.get(audio.trackingType)!.toLowerCase()]
                            )}
                        >
                            {MonitoredAudioTrackingTypeLabels.get(audio.trackingType)}
                        </Badge>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TiktokAudioBadge;
