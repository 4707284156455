import { call } from '../../helpers';
import { isPublicReportRequestInit } from '../../influencer';
import { ApiResponse, ForbiddenResponse } from '../../types';
import { TiktokTimeSeries, TiktokTimeSeriesField, TiktokTimeSeriesType } from './timeSeries.types';

export type TiktokTimeSeriesRequestParams<T extends TiktokTimeSeriesField = TiktokTimeSeriesField> = {
    type: TiktokTimeSeriesType;
    ids: number[];
    fields: T[];
    start_date: string;
    end_date: string;
};

export type TiktokTimeSeriesResponse<T extends TiktokTimeSeriesField = TiktokTimeSeriesField> = {
    [id: number]: {
        [field in T]: TiktokTimeSeries[];
    };
};

export async function postTiktokTimeSeries<T extends TiktokTimeSeriesField>(
    params: TiktokTimeSeriesRequestParams<T>,
    requestInit?: RequestInit
): Promise<ApiResponse<TiktokTimeSeriesResponse<T>, 200> | ForbiddenResponse> {
    const response = await call(
        '/api/tiktok/time-series/',
        {
            ...requestInit,
            method: 'POST',
            headers: {
                ...requestInit?.headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        },
        {
            shouldBypassTokens: isPublicReportRequestInit(requestInit),
        }
    );

    if (response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get time series data');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
