import styles from './HierarchicalOptionLabel.module.css';
import cn from 'classnames';

type Props = {
    labels: [string?, string?, string?];
};

const HierarchicalOptionLabel = ({ labels }: Props) => {
    const definedLabels = labels.filter((label): label is string => typeof label === 'string' && label.length > 0);
    const hasSingleLabel = definedLabels.length === 1;
    const [primary, secondary, tertiary] = definedLabels;
    return (
        <div className={styles.container}>
            <div className={cn(styles.primary, { [styles.bold]: !hasSingleLabel })}>{primary}</div>
            {secondary && <span className={styles.secondary}>{secondary}</span>}
            {secondary && tertiary && <span className={styles.tertiary}>{` • ${tertiary}`}</span>}
        </div>
    );
};

export default HierarchicalOptionLabel;
