import React, { createContext, useEffect, useState } from 'react';
import useSafeReducer from '../../../Hooks/useSafeReducer';
import { FCWithChildren, MakeUndefined } from '../../../utility/utility.types';
import {
    AuthTokenPair,
    clearTokensFromStorage,
    getCurrentUser,
    getTokensFromStorage,
    setTokensToStorage,
    User,
} from '@round/api';
import { datadogRum } from '@datadog/browser-rum';

function reducer(state: AuthContextValues, action: Action) {
    switch (action.type) {
        case 'authenticated':
            const authenticatedValues: AuthContextAuthenticated = {
                loading: false,
                authenticated: true,
                logout: action.logout,
                user: action.userData,
            };
            return authenticatedValues;
        case 'unauthenticated':
            const unauthenticatedValues: AuthContextUnauthenticated = {
                loading: false,
                authenticated: false,
                setTokens: action.setTokens,
            };
            return unauthenticatedValues;
        default:
            return state;
    }
}

const initialState: AuthContextLoading = { loading: true };

export const AuthContext = createContext<AuthContextValues>(initialState);

type AuthContextValues = AuthContextLoading | AuthContextAuthenticated | AuthContextUnauthenticated;

type AuthContextBaseValues = {
    loading: boolean;
    authenticated: boolean | undefined;
    logout: () => void | undefined;
    user: User | undefined;
    setTokens: (tokens: AuthTokenPair | null) => void | undefined;
};

type AuthContextLoading = {
    loading: true;
} & Omit<MakeUndefined<AuthContextBaseValues, 'authenticated' | 'logout' | 'user' | 'setTokens'>, 'loading'>;

type AuthContextAuthenticated = {
    loading: false;
    authenticated: true;
    logout: () => void;
    user: User;
} & Omit<MakeUndefined<AuthContextBaseValues, 'setTokens'>, 'loading' | 'authenticated' | 'user' | 'logout'>;

type AuthContextUnauthenticated = {
    loading: false;
    authenticated: false;
    setTokens: (tokens: AuthTokenPair | null) => void;
} & Omit<MakeUndefined<AuthContextBaseValues, 'user' | 'logout'>, 'loading' | 'authenticated' | 'setTokens'>;

export const AuthProvider: FCWithChildren = ({ children }) => {
    const initialTokens = getTokensFromStorage();
    const [state, dispatch] = useSafeReducer(reducer, initialState);
    const [accessToken, setAccessToken] = useState<string | null>(initialTokens.access);
    const [refreshToken, setRefreshToken] = useState<string | null>(initialTokens.refresh);

    const setTokens = (tokens: AuthTokenPair | null) => {
        if (tokens === null) {
            clearTokensFromStorage();
        } else {
            setTokensToStorage(tokens);
        }
        setRefreshToken(tokens?.refresh ?? null);
        setAccessToken(tokens?.access ?? null);
    };

    useEffect(() => {
        if (accessToken === null || refreshToken === null) {
            dispatch({ type: 'unauthenticated', setTokens });
        } else {
            const logout = () => {
                setTokens(null);
                if (process.env.NODE_ENV === 'production') {
                    datadogRum.clearUser();
                }
            };

            const checkAuthentication = async () => {
                try {
                    const user = await getCurrentUser();
                    const tokens = getTokensFromStorage();
                    setTokens(tokens);
                    dispatch({ type: 'authenticated', logout, userData: user });

                    if (process.env.NODE_ENV === 'production') {
                        datadogRum.setUser({
                            name: user.username,
                            id: user.id.toString(),
                            email: user.email,
                            groups: user.groups,
                        });
                    }
                } catch {
                    dispatch({ type: 'unauthenticated', setTokens });
                }
            };

            checkAuthentication();
        }
    }, [accessToken, dispatch, refreshToken]);

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

type Action =
    | {
          type: 'authenticated';
          logout: () => void;
          userData: User;
      }
    | {
          type: 'unauthenticated';
          setTokens: (tokens: AuthTokenPair | null) => void;
      };
