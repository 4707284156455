import React from 'react';
import cn from 'classnames';
import { ReactComponent as CheckIcon } from '../../../assets/icons/Check.svg';
import styles from './Checkbox.module.css';

export type CheckboxProps = {
    value: boolean;
    onChange?: (value: boolean, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    ariaLabel?: string;
    className?: string;
    id?: string;
    disabled?: boolean;
};

const Checkbox = ({ value, onChange, ariaLabel, className, id, disabled }: CheckboxProps) => {
    return (
        <span
            id={id}
            role="checkbox"
            aria-label={ariaLabel}
            aria-checked={value}
            className={cn(styles.checkbox, className, { [styles.checked]: value, [styles.disabled]: disabled })}
            onClick={(e) => {
                if (typeof onChange === 'function' && !disabled) {
                    onChange(!value, e);
                }
            }}
        >
            {value && <CheckIcon width={10} height={10} />}
        </span>
    );
};

export default Checkbox;
