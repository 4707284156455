import { ReactComponent as FilterIcon } from 'assets/Filter.svg';
import styles from './FilterMenuButton.module.css';
import cn from 'classnames';
import { HtmlHTMLAttributes } from 'react';

type Props = HtmlHTMLAttributes<HTMLButtonElement> & {
    className?: string;
};

const FilterMenuButton = ({ className, ...props }: Props) => {
    return (
        <button className={cn(styles.button, className)} {...props}>
            <FilterIcon />
        </button>
    );
};

export default FilterMenuButton;
