import { IconProps } from './Icon.types';

const PlayIcon = ({ width, height, className, title }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
    >
        <title>{title}</title>
        <polygon points="5 3 19 12 5 21 5 3" />
    </svg>
);

export default PlayIcon;
