import React, { useCallback } from 'react';
import YoutubePlaylistReportItemForm, {
    YoutubePlaylistReportItemFormProps,
} from '../YoutubePlaylistReportItemForm/YoutubePlaylistReportItemForm';
import Modal, { ModalContent, ModalTitle } from '../../../../../ui/General/Modal/Modal';
import { postYoutubePlaylistReportItem } from '../YoutubeReportItems.api';
import { mapApiErrorsToFormikErrors } from '../../../../../utility/utility';
import { showNotification } from '../../../../../helpers';

type CreateYoutubePlaylistReportItemProps = {
    isModalOpen: boolean;
    onClose: () => void;
    onCreated: () => void;
};

const initialValues: YoutubePlaylistReportItemFormProps['initialValues'] = {
    media_plan_item_id: undefined,
    amount_spent: undefined,
};

const CreateYoutubePlaylistReportItem = ({ isModalOpen, onCreated, onClose }: CreateYoutubePlaylistReportItemProps) => {
    const handleSubmit: YoutubePlaylistReportItemFormProps['onSubmit'] = useCallback(
        async (values, { setSubmitting, setErrors }) => {
            try {
                setSubmitting(true);
                const response = await postYoutubePlaylistReportItem({
                    media_plan_item_id: Number(values.media_plan_item_id),
                    amount_spent: Number(values.amount_spent),
                });

                if (response.status === 400) {
                    setErrors(mapApiErrorsToFormikErrors(response.data));
                    return;
                }

                showNotification('Report item created', 'info');
                onCreated();
            } catch (e) {
                showNotification('Could not create report item', 'error');
            } finally {
                setSubmitting(false);
            }
        },
        [onCreated]
    );

    return (
        <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={onClose}>
            <ModalTitle>Create Youtube Playlist Report Item</ModalTitle>
            <ModalContent>
                <YoutubePlaylistReportItemForm initialValues={initialValues} onSubmit={handleSubmit} />
            </ModalContent>
        </Modal>
    );
};

export default CreateYoutubePlaylistReportItem;
