import { TiktokInfluencerUser, TiktokUser, getTiktokInfluencerUser, getTiktokUsers } from '@round/api';
import { SelectProps } from '@round/ui-kit';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { showNotification } from 'helpers';
import { useCallback, useMemo, useState } from 'react';
import { ValueType } from 'react-select';
import { GetTiktokInfluencerUsersParams, getTiktokInfluencerUsers } from '../TikTok.api';
import debounce from 'lodash/debounce';
import { TiktokInfluencerOption } from '../components/filters/TiktokInfluencerFilter/TiktokInfluencerFilter';
import { isNumber } from 'utility/utility';

const mapInfluencerToOption = (
    influencer: TiktokInfluencerUser,
    user?: TiktokUser | undefined
): TiktokInfluencerOption => ({
    value: influencer.id,
    label: influencer.username,
    unique_id: user?.unique_id ?? '',
    avatar_thumb: user?.avatar_thumb ?? '',
});

type Return = Pick<
    SelectProps<TiktokInfluencerOption>,
    | 'options'
    | 'filterOption'
    | 'onMenuOpen'
    | 'onMenuClose'
    | 'onMenuScrollToBottom'
    | 'inputValue'
    | 'onInputChange'
    | 'isLoading'
    | 'value'
    | 'onChange'
    | 'isMulti'
    | 'noOptionsMessage'
>;

const defaultPageSize = 25;

export default function useTiktokInfluencerSelect(
    initialInfluencerUserId?: number | undefined,
    params: {
        initOptionsOnMenuOpen?: boolean;
    } = {}
): Return {
    const [value, setValue] = useState<ValueType<TiktokInfluencerOption, false>>(null);
    const [isValueInitialized, setIsValueInitialized] = useState(false);

    useAbortableEffect(
        (signal) => {
            if (isValueInitialized || typeof initialInfluencerUserId !== 'number') {
                return;
            }

            getTiktokInfluencerUser(initialInfluencerUserId, { signal })
                .then((response) => {
                    setValue(mapInfluencerToOption(response));
                    setIsValueInitialized(true);
                })
                .catch((e) => {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    setIsValueInitialized(true);
                    showNotification('Could not load initial influencer user', 'error');
                });
        },
        [initialInfluencerUserId, isValueInitialized]
    );

    const [influencers, setInfluencers] = useState<TiktokInfluencerUser[]>([]);
    const [users, setUsers] = useState<TiktokUser[]>([]);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [search, setSearch] = useState('');
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const fetchInfluencers = useCallback(
        async (params: Omit<GetTiktokInfluencerUsersParams, 'search'>, requestInit?: RequestInit) => {
            try {
                setIsLoading(true);
                const response = await getTiktokInfluencerUsers({ ...params, search }, requestInit);
                setInfluencers((prev) => prev.concat(response.data.results));
                setHasNextPage(Boolean(response.data.next));

                const userIds = response.data.results.map((inf) => inf.user).filter(isNumber);
                if (!userIds.length) {
                    setIsInitialized(true);
                    return;
                }
                const usersResponse = await getTiktokUsers({ id: userIds.toString(), page_size: userIds.length });

                if (usersResponse.status !== 200) {
                    throw new Error('Could not fetch tiktok influencer options');
                }

                setUsers((prev) => prev.concat(usersResponse.data.results));
                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setError('Could not fetch tiktok influencer options');
                setIsInitialized(true);
            } finally {
                setIsLoading(false);
                setIsSearchLoading(false);
            }
        },
        [search]
    );

    const debouncedInit = useMemo(() => debounce(fetchInfluencers, 700), [fetchInfluencers]);

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && (!params.initOptionsOnMenuOpen || isMenuOpen)) {
                debouncedInit({ page: 1, page_size: defaultPageSize }, { signal });

                return () => debouncedInit.cancel();
            }
        },
        [debouncedInit, isInitialized, isMenuOpen, params.initOptionsOnMenuOpen]
    );

    const loadNextPage = useCallback(async () => {
        fetchInfluencers({ page: page + 1, page_size: defaultPageSize }).then(() => setPage((page) => page + 1));
    }, [fetchInfluencers, page]);

    const reset = useCallback(() => {
        setInfluencers([]);
        setPage(1);
        setHasNextPage(false);
        setSearch('');
        setIsInitialized(false);
    }, []);

    const options = useMemo(
        () =>
            influencers.map((inf) =>
                mapInfluencerToOption(
                    inf,
                    users.find((u) => u.id === inf.user)
                )
            ),
        [influencers, users]
    );

    return {
        isMulti: false,
        value,
        onChange: setValue,
        options,
        filterOption: null,
        inputValue: search,
        onInputChange: (value: string) => {
            if (value === search) {
                return;
            }

            reset();
            setSearch(value);
            if (value) {
                setIsSearchLoading(true);
            }
        },
        onMenuScrollToBottom: () => {
            if (!hasNextPage) {
                return;
            }

            loadNextPage();
        },
        onMenuOpen: () => setIsMenuOpen(true),
        onMenuClose: () => {
            setIsMenuOpen(false);
        },
        isLoading: isLoading || isSearchLoading,
        noOptionsMessage: () => error ?? 'No options',
    };
}
