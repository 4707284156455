import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Song } from './songs.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';

export const models = {
    creatorbaseSong: Model as ModelDefinition<Song>,
};

export const serializers = {
    creatorbaseSong: makeSerializer<Song>([]),
};

export const factories = {
    creatorbaseSong: createFactory<Song>({
        artist_name: (index: number) => `artist name ${index}`,
        created: new Date().toISOString(),
        instagram_audios: [],
        tiktok_audios: [],
        music_song_id: null,
        status: 'unreleased',
        title: (index: number) => `title ${index}`,
    }),
};

export function handleRequests(server: AppServer) {
    const songsRootUrl = '/api/creatorbase/songs/';
    server.get(songsRootUrl, function (this: RouteHandlerContext, schema, request) {
        const songs = schema.all('creatorbaseSong');
        return buildPaginatedResponse(songs, {
            url: songsRootUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'creatorbaseSong'),
        });
    });

    server.post(songsRootUrl, (schema, request) => {
        const attrs = JSON.parse(request.requestBody);
        let musicSong;

        if (attrs.status === 'released' && attrs.spotify_url) {
            musicSong = schema.create('song', {
                spotify_song_url: attrs.spotify_url,
            });
        }

        return schema.create('creatorbaseSong', { ...attrs, music_song_id: musicSong?.id ?? null });
    });

    server.patch(`${songsRootUrl}:id`, (schema, request) => {
        const song = schema.find('creatorbaseSong', request.params.id);

        if (!song) {
            return new Response(404);
        }

        const attrs = JSON.parse(request.requestBody);
        let musicSong;

        if (attrs.status === 'released' && attrs.spotify_url !== song.spotify_url) {
            musicSong = schema.create('song', {
                spotify_song_url: attrs.spotify_url,
            });
        }

        song?.update({ ...attrs, music_song_id: musicSong?.id ?? null });
        return song;
    });

    server.get(`${songsRootUrl}:id`, (schema, request) => {
        const song = schema.find('creatorbaseSong', request.params.id);
        if (!song) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return song;
    });
}
