import { AppServer, RouteHandlerContext } from '../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { FacebookAdAccount } from './facebook.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../mirage.helpers';

export const models = {
    facebookAdAccount: Model as ModelDefinition<FacebookAdAccount>,
};

export const factories = {
    facebookAdAccount: createFactory<FacebookAdAccount>({
        name(index: number) {
            return `Facebook ad account name: ${index}`;
        },
        ad_account_id(index: number) {
            return `Facebook ad account id: ${index}`;
        },
    }),
};

export const serializers = {
    facebookAdAccount: makeSerializer<FacebookAdAccount>([]),
};

export function handleFacebookRequests(server: AppServer) {
    server.get('/api/facebook/ad-account/:clientId/', function (this: RouteHandlerContext, schema) {
        return schema.first('facebookAdAccount');
    });

    const facebookAdAccountListPath = '/api/facebook/viewsets/facebook-ad-account/';
    server.get(facebookAdAccountListPath, function (this: RouteHandlerContext, schema, request) {
        const adAccounts = schema.all('facebookAdAccount');
        return buildPaginatedResponse(adAccounts, {
            url: facebookAdAccountListPath,
            serialize: (resource) => this.serialize(resource, 'facebookAdAccount'),
            queryParams: request.queryParams,
        });
    });
}
