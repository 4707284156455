import React from 'react';
import Select, { ActionMeta, StylesConfig, ValueType } from 'react-select';
import { FieldProps } from 'formik';
import { DropdownOption } from '../../../App.types';

const selectStyle: StylesConfig = {
    control: (provided) => ({
        ...provided,
        height: '2rem',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '2rem',
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    input: (provided) => ({
        ...provided,
        marginTop: '0',
        paddingTop: '0',
        height: '2rem',
    }),
    option: (provided, state) => {
        return {
            ...provided,
            backgroundColor: state.isFocused ? '#F2F9FF !important' : 'null',
            color: '#707683',
            cursor: 'pointer',
            padding: '0.4rem 0.2rem',
            p: {
                margin: 0,
                padding: 0,
                color: '#AEAEAE',
            },
        };
    },
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
};

type ModalSelectProps<isMulti extends boolean = false> = {
    isMulti?: isMulti;
    fieldProps: FieldProps;
    options: DropdownOption[];
    onChange: (value: ValueType<DropdownOption, isMulti>, actionMeta: ActionMeta<DropdownOption>) => void;
    value?: ValueType<{ value: string; label: string }, isMulti>;
    menuPortalTarget?: HTMLElement | null | undefined;
    isDisabled?: true;
    formatOptionLabel?: (value: string, label: string) => React.ReactElement;
    clearable?: boolean;
};

export const ModalSelect = <isMultiSelect extends boolean = false>({
    fieldProps,
    options,
    onChange,
    menuPortalTarget,
    isDisabled,
    value,
    formatOptionLabel,
    clearable,
    isMulti,
}: ModalSelectProps<isMultiSelect>) => {
    return (
        <Select
            {...fieldProps.field}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            isClearable={clearable}
            menuPortalTarget={menuPortalTarget}
            options={options}
            onChange={onChange}
            styles={selectStyle}
            cacheOptions
            defaultOptions
            value={value}
            isDisabled={isDisabled}
            formatOptionLabel={({ value, label }) => (formatOptionLabel ? formatOptionLabel(value, label) : label)}
            components={{ SingleValue: ({ data: { label } }) => <div>{label}</div> }}
        />
    );
};
