import Dropdown from 'ui-new/whitelabel/Dropdown/Dropdown';
import styles from './ActionsCell.module.css';
import { ReactComponent as TrashIcon } from 'assets/whitelabel/Trash.svg';

type Props = {
    onDelete: () => void;
};

const ActionsCell = ({ onDelete }: Props) => {
    return (
        <div className={styles.container}>
            <Dropdown>
                {({ setIsOpen }) => (
                    <>
                        <Dropdown.Target />
                        <Dropdown.Menu>
                            <Dropdown.Item
                                icon={() => <TrashIcon className={styles.delete} />}
                                onClick={() => {
                                    onDelete();
                                    setIsOpen(false);
                                }}
                                className={styles.delete}
                            >
                                Delete post
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </>
                )}
            </Dropdown>
        </div>
    );
};

export default ActionsCell;
