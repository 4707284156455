import React, { useCallback, useMemo, useState } from 'react';
import useUrlState from '../../../../../../Hooks/useUrlState';
import useTiktokPostInvites, { TiktokPostInviteData } from './useTiktokPostInvites';
import ContactedTable from './ContactedTable/ContactedTable';
import styles from './Contacted.module.css';
import { Button, Card, Modal, Popover, Skeleton } from '@round/ui-kit';
import { microwave } from '@round/api';
import {
    convertOrderByToOrdering,
    convertOrderingToOrderBy,
} from '../../../../../../ui/PaginationTable/PaginationTable.helpers';
import { OrderByParam } from '../../../../../../Hooks/useReactTableSortToOrderBy';
import cn from 'classnames';
import ChaseEmailPreview from '../../EmailPreview/ChaseEmailPreview/ChaseEmailPreview';
import { PreviewUser } from '../../EmailPreview/InitialEmailPreview/InitialEmailPreview';
import Calendar from '../../../../../../ui/DataEntry/Calendar/Calendar';
import moment from 'moment/moment';
import { showNotification } from '../../../../../../helpers';
import EmailHistory from './EmailHistory/EmailHistory';
import { DebounceInput } from 'react-debounce-input';

type Props = {
    planId: number;
    audioId?: number;
};

type UrlState = {
    page: number;
    page_size: number;
} & Partial<{
    ordering: string;
    number_of_chase_emails_sent: number;
}>;

const initialUrlState: UrlState = {
    page: 1,
    page_size: 10,
    ordering: '-post_create_time',
};

const Contacted = ({ planId, audioId }: Props) => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState, { shouldSetMissingInitialValues: true });

    const [selectedCreators, setSelectedCreators] = useState<TiktokPostInviteData[]>([]);
    const [previewCreators, setPreviewCreators] = useState<TiktokPostInviteData[]>([]);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

    const [isEmailHistoryModalOpen, setIsEmailHistoryModalOpen] = useState(false);
    const [emailHistoryPostInvite, setEmailHistoryPostInvite] = useState<TiktokPostInviteData | null>(null);

    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const pageSize = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;
    const ordering = useMemo(
        () =>
            urlState.ordering && microwave.isGetTiktokPostInvitesSortingValues(urlState.ordering)
                ? urlState.ordering
                : undefined,
        [urlState.ordering]
    );
    const setOrdering = useCallback(
        (orderBy: OrderByParam<TiktokPostInviteData>) => {
            setUrlState({ ordering: convertOrderByToOrdering(orderBy) });
        },
        [setUrlState]
    );

    const { creators, loading, count, errorLoading, isInitialized, updatePostInvite } = useTiktokPostInvites({
        creator_plan: planId,
        page: page,
        page_size: pageSize,
        ordering,
        is_posted: false,
        number_of_chase_emails_sent: urlState.number_of_chase_emails_sent
            ? Number(urlState.number_of_chase_emails_sent)
            : undefined,
    });

    const [rowsDisplayed, setRowsDisplayed] = useState(0);
    const rowsDisplayedText = `displaying ${rowsDisplayed} out of ${count}`;
    const isTableLoading = loading || (!errorLoading && !isInitialized);

    const [multipleDeadlineAnchor, setMultipleDeadlineAnchor] = useState<HTMLButtonElement | null>(null);
    const [multipleDeadlineValue, setMultipleDeadlineValue] = useState(moment().format('YYYY-MM-DD'));
    const [isMultipleDeadlinesSaveLoading, setIsMultipleDeadlinesSaveLoading] = useState(false);

    return (
        <div className={styles.container}>
            <div className={styles.filters}>
                <div className={styles.filter}>
                    <label className={styles.filterLabel}># of chase emails</label>
                    <DebounceInput
                        className={styles.filterInput}
                        type="number"
                        placeholder="Enter # of chase emails..."
                        value={urlState.number_of_chase_emails_sent}
                        onChange={(e) => {
                            setUrlState({
                                number_of_chase_emails_sent: e.target.value ? Number(e.target.value) : undefined,
                                page: 1,
                            });
                        }}
                        debounceTimeout={700}
                    />
                </div>
            </div>
            <div className={styles.actionsRow}>
                <div className={cn(styles.actionButtons, { [styles.visible]: selectedCreators.length > 0 })}>
                    <Button
                        disabled={!audioId}
                        onClick={() => {
                            setPreviewCreators(selectedCreators);
                            setIsPreviewModalOpen(true);
                        }}
                    >
                        Preview {selectedCreators.length} email{selectedCreators.length !== 1 ? 's' : ''}
                    </Button>

                    <Button ref={setMultipleDeadlineAnchor}>Set multiple deadlines</Button>
                    <Popover anchorElement={multipleDeadlineAnchor} showOn="click">
                        {(setShow) => (
                            <Card className={styles.multipleDeadlinesPopover}>
                                <Calendar
                                    value={multipleDeadlineValue}
                                    onChange={setMultipleDeadlineValue}
                                    minDate={new Date()}
                                />

                                <Button
                                    className={styles.multipleDeadlinesSaveButton}
                                    loading={isMultipleDeadlinesSaveLoading}
                                    onClick={async () => {
                                        try {
                                            setIsMultipleDeadlinesSaveLoading(true);
                                            const results = await Promise.allSettled(
                                                selectedCreators.map((postInvite) =>
                                                    updatePostInvite(postInvite.id, { deadline: multipleDeadlineValue })
                                                )
                                            );

                                            const allUpdated = results.every((result) => result.status === 'fulfilled');
                                            if (allUpdated) {
                                                showNotification('all updated', 'info');
                                                setShow(false);
                                                setMultipleDeadlineValue(moment().format('YYYY-MM-DD'));
                                                return;
                                            }

                                            showNotification('Could not update some or all dates', 'error');
                                            return;
                                        } catch {
                                            showNotification('Could not save multiple deadlines', 'error');
                                        } finally {
                                            setIsMultipleDeadlinesSaveLoading(false);
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                            </Card>
                        )}
                    </Popover>
                </div>

                <p className={styles.rowsDisplayedText}>
                    {isTableLoading ? <Skeleton width="7rem" /> : rowsDisplayedText}
                </p>
            </div>

            <ContactedTable
                loading={isTableLoading}
                data={creators}
                count={count}
                page={page}
                setPage={(page) => setUrlState({ page })}
                orderBy={convertOrderingToOrderBy(ordering)}
                disableSortBy={false}
                onOrderByChange={setOrdering}
                pageSize={pageSize}
                setPageSize={(pageSize) => setUrlState({ page_size: pageSize ?? initialUrlState.page_size })}
                noDataLabel={errorLoading ? "Sorry, we're experiencing technical issues" : 'No data found'}
                onPaginationChange={(state) => setRowsDisplayed(state.pageRows.length)}
                selectedRowIds={selectedCreators.map((c) => c.user_id)}
                onRowSelect={(creator) =>
                    setSelectedCreators((creators) => {
                        if (creators.find((c) => c.user_id === creator.user_id)) {
                            return creators.filter((c) => c.user_id !== creator.user_id);
                        }

                        return creators.concat(creator);
                    })
                }
                onAllRowsSelect={(incomingCreators) =>
                    setSelectedCreators((prev) => {
                        if (!incomingCreators.every((creator) => !!prev.find((c) => c.user_id === creator.user_id))) {
                            return prev.concat(
                                incomingCreators.filter((c) => !prev.find((creator) => creator.user_id === c.user_id))
                            );
                        }

                        return prev.filter((c) => !incomingCreators.find((creator) => creator.user_id === c.user_id));
                    })
                }
                updatePostInvite={updatePostInvite}
                onEmailHistoryClicked={(row) => {
                    setEmailHistoryPostInvite(row);
                    setIsEmailHistoryModalOpen(true);
                }}
            />

            <Modal
                className={styles.previewModal}
                closeOnOverlayClick
                isOpen={isPreviewModalOpen}
                onClose={() => setIsPreviewModalOpen(false)}
            >
                {audioId && planId && previewCreators.length && (
                    <ChaseEmailPreview
                        creators={previewCreators.map<PreviewUser>((creator) => ({
                            user_id: creator.user_id,
                            nickname: creator.user?.nickname || creator.influencer_email_address || '-',
                            email: creator.influencer_email_address,
                        }))}
                        influencerPlanId={planId}
                        tiktokAudioId={audioId}
                        onEmailSent={(userIds) => {
                            setIsPreviewModalOpen(false);
                            setPreviewCreators([]);
                            setSelectedCreators((creators) => creators.filter((c) => !userIds.includes(c.user_id)));
                        }}
                    />
                )}
            </Modal>

            <Modal
                closeOnOverlayClick
                className={styles.emailHistoryModal}
                isOpen={Boolean(isEmailHistoryModalOpen && emailHistoryPostInvite)}
                onClose={() => {
                    setIsEmailHistoryModalOpen(false);
                    setEmailHistoryPostInvite(null);
                }}
            >
                {emailHistoryPostInvite && <EmailHistory contactedCreator={emailHistoryPostInvite} />}
            </Modal>
        </div>
    );
};

export default Contacted;
