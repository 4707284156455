import useUrlState from '../../../../Hooks/useUrlState';
import React, { useCallback, useMemo, useState } from 'react';
import { PlaylistReportItem } from '../../../../App.types';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import { Column } from 'react-table';
import Skeleton from '../../../../ui/Skeleton/Skeleton';
import { NavBar } from '../../../../SharedComponents/NavBar/NavBar';
import PageContentCardContainer from '../../../../ui/Layout/PageContentCardContainer/PageContentCardContainer';
import styles from './ReportItemSpotify.module.css';
import Button from '../../../../ui/Buttons/Button/Button';
import PaginationTable from '../../../../ui/PaginationTable/PaginationTable';
import { getSpotifyPlaylistReportItems } from './SpotifyReportItems.api';
import CreateSpotifyPlaylistReportItem from './CreateSpotifyPlaylistReportItem/CreateSpotifyPlaylistReportItem';
import EditSpotifyPlaylistReportItem from './EditSpotifyPlaylistReportItem/EditSpotifyPlaylistReportItem';
import { MediaPlan, getMediaPlans } from '@round/api';
import { uniq } from 'lodash';

type UrlState = {
    page: number;
    page_size: number;
};

type SpotifyReportItemRow = {
    id: number;
    mediaPlanItemId: number;
    releaseName: string;
    brandName: string;
    clientName: string;
    amountSpent: string;
};

const ReportItemSpotify = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>(
        { page: 1, page_size: 10 },
        {
            shouldSetMissingInitialValues: true,
        }
    );
    const [loading, setLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [reportItemsCount, setReportItemsCount] = useState(0);
    const [reportItems, setReportItems] = useState<PlaylistReportItem[]>([]);
    const [mediaPlans, setMediaPlans] = useState<MediaPlan[]>([]);

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [reportItemToUpdate, setReportItemToUpdate] = useState<PlaylistReportItem | null>(null);

    const closeUpdateModal = () => {
        setIsUpdateModalOpen(false);
        setReportItemToUpdate(null);
    };

    const loadData = useCallback(async (page: number, pageSize: number, requestInit?: RequestInit) => {
        try {
            setErrorLoading(false);
            setLoading(true);
            const response = await getSpotifyPlaylistReportItems({ page, page_size: pageSize }, requestInit);
            const mediaPlanIds = uniq(response.data.results.map((item) => item.media_plan_item.media_plan));
            const mediaPlanResponse = await getMediaPlans({ id: mediaPlanIds.join(',') }, requestInit);
            setReportItems(response.data.results);
            setReportItemsCount(response.data.count);
            if (mediaPlanResponse.status === 200) {
                setMediaPlans(mediaPlanResponse.data.results);
            }
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            setErrorLoading(true);
        } finally {
            setLoading(false);
        }
    }, []);

    const handleItemModified = () => {
        closeUpdateModal();
        loadData(Number(urlState.page), Number(urlState.page_size));
    };

    useAbortableEffect(
        (signal) => {
            loadData(Number(urlState.page), Number(urlState.page_size), { signal });
        },
        [urlState.page, urlState.page_size, loadData]
    );

    const rows: SpotifyReportItemRow[] = reportItems.map((item) => {
        const mediaPlan = mediaPlans.find((plan) => plan.id === item.media_plan_item.media_plan);
        return {
            id: item.id,
            mediaPlanItemId: item.media_plan_item.id,
            amountSpent: item.amount_spent,
            brandName: mediaPlan?.release.brand.name || '-',
            clientName: mediaPlan?.release.brand.client.name || '-',
            releaseName: mediaPlan?.release.name || '-',
        };
    });

    const columns: Column<SpotifyReportItemRow>[] = useMemo(
        () => [
            {
                Header: 'Media Plan Item Id',
                accessor: 'mediaPlanItemId',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Release',
                accessor: 'releaseName',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Brand',
                accessor: 'brandName',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Client',
                accessor: 'clientName',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Amount Spent',
                accessor: 'amountSpent',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
        ],
        [loading]
    );

    return (
        <>
            <NavBar />
            <PageContentCardContainer className={styles.container}>
                <div className={styles.heading}>
                    <h1 className={styles.title}>Spotify Playlist Report Items</h1>
                    <Button type="filled" color="black" onClick={() => setIsCreateModalOpen(true)}>
                        Create
                    </Button>
                </div>
                <PaginationTable
                    loading={loading}
                    data={rows}
                    columns={columns}
                    count={reportItemsCount}
                    page={Number(urlState.page)}
                    setPage={(page) => setUrlState({ page })}
                    pageSize={Number(urlState.page_size)}
                    setPageSize={(pageSize) => setUrlState({ page_size: pageSize })}
                    noDataLabel={errorLoading ? "Sorry, we're experiencing some technical issues" : 'No items'}
                    onRowClick={(row) => {
                        const reportItem = reportItems.find((item) => item.id === row.id);
                        if (reportItem) {
                            setReportItemToUpdate(reportItem);
                            setIsUpdateModalOpen(true);
                        }
                    }}
                />
            </PageContentCardContainer>

            <CreateSpotifyPlaylistReportItem
                isModalOpen={isCreateModalOpen}
                onClose={() => setIsCreateModalOpen(false)}
                onCreated={() => {
                    setIsCreateModalOpen(false);
                    loadData(Number(urlState.page), Number(urlState.page_size));
                }}
            />

            <EditSpotifyPlaylistReportItem
                isModalOpen={isUpdateModalOpen}
                onClose={closeUpdateModal}
                reportItem={reportItemToUpdate}
                onUpdated={handleItemModified}
                onDeleted={handleItemModified}
            />
        </>
    );
};

export default ReportItemSpotify;
