import React from 'react';
import cn from 'classnames';
import styles from './Checkbox.module.css';
import { ReactComponent as CheckIcon } from '../../../assets/whitelabel/Check.svg';
import { ReactComponent as MinusIcon } from '../../../assets/Minus.svg';

export type CheckboxProps = {
    value: boolean;
    indeterminateValue?: boolean;
    onChange: (value: boolean, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    ariaLabel?: string;
    className?: string;
    id?: string;
    disabled?: boolean;
};

const Checkbox = ({ value, indeterminateValue, onChange, ariaLabel, className, id, disabled }: CheckboxProps) => {
    return (
        <span
            id={id}
            role="checkbox"
            aria-label={ariaLabel}
            aria-checked={value || indeterminateValue ? 'mixed' : 'false'}
            aria-disabled={disabled}
            className={cn(styles.checkbox, className, {
                [styles.checked]: value || indeterminateValue,
                [styles.disabled]: disabled,
            })}
            onClick={(e) => {
                if (!disabled) {
                    onChange(!value, e);
                }
            }}
        >
            {indeterminateValue ? (
                <MinusIcon className={styles.indeterminate} />
            ) : value ? (
                <CheckIcon className={styles.check} />
            ) : null}
        </span>
    );
};

export default Checkbox;
