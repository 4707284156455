import React from 'react';
import ReactSelect, { OptionTypeBase, Props, StylesConfig } from 'react-select';
import { SelectComponentsConfig } from 'react-select/src/components';
import cn from 'classnames';
import styles from './Select.module.css';

type SelectProps<OptionType extends OptionTypeBase, isMulti extends boolean = false> = Props<OptionType, isMulti>;

const Select = <OptionType extends OptionTypeBase, IsMulti extends boolean = false>({
    components,
    defaultValue,
    styles: selectStyles,
    className,
    isMulti,
    closeMenuOnSelect,
    ...rest
}: SelectProps<OptionType, IsMulti>) => {
    const overriddenStyles: StylesConfig = {
        ...selectStyles,
        control: (base, props) => {
            const overriddenBase: React.CSSProperties = { ...base, minHeight: 'initial' };
            return typeof selectStyles?.control === 'function'
                ? selectStyles.control(overriddenBase, props)
                : overriddenBase;
        },
        input: (base, props) => {
            const overriddenBase: React.CSSProperties = {
                ...base,
                minHeight: 'initial',
                margin: 0,
                height: '1rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            };
            return typeof selectStyles?.input === 'function'
                ? selectStyles.input(overriddenBase, props)
                : overriddenBase;
        },
        menu: (base, props) => {
            const overriddenBase: React.CSSProperties = { ...base, zIndex: 10 };
            return typeof selectStyles?.menu === 'function' ? selectStyles.menu(overriddenBase, props) : overriddenBase;
        },
        dropdownIndicator: (base, props) => {
            const overriddenBase: React.CSSProperties = { ...base, padding: 0, paddingRight: '0.5rem' };
            return typeof selectStyles?.dropdownIndicator === 'function'
                ? selectStyles.dropdownIndicator(overriddenBase, props)
                : overriddenBase;
        },
        valueContainer: (base, props) => {
            const overriddenBase: React.CSSProperties = { ...base, padding: '0.5rem' };
            return typeof selectStyles?.valueContainer === 'function'
                ? selectStyles.valueContainer(overriddenBase, props)
                : overriddenBase;
        },
    };

    const overriddenComponents: SelectComponentsConfig<OptionType, IsMulti> = {
        IndicatorSeparator: null,
        ...components,
    };

    const props = { ...rest };
    if (defaultValue) {
        props.defaultValue = defaultValue;
    }

    return (
        <ReactSelect
            {...rest}
            className={cn(
                { [styles.select]: typeof rest.isSearchable !== 'undefined' && !rest.isSearchable },
                className
            )}
            styles={overriddenStyles}
            isMulti={isMulti}
            closeMenuOnSelect={closeMenuOnSelect || !isMulti}
            components={overriddenComponents}
        />
    );
};

export default Select;
