import React from 'react';
import useNonNullContext from '../../../../hooks/useNonNullContext';
import { TabsContext } from '../../Tabs';
import { TabChild } from '../../helpers';

type TabPanelsProps = { children?: TabChild | TabChild[] | undefined };

const TabPanels = ({ children }: TabPanelsProps) => {
    const { activeTab } = useNonNullContext(TabsContext);
    const ActiveChild = React.Children.toArray(children).find((child) => (child as TabChild).props.name === activeTab);
    return <>{ActiveChild}</>;
};

export default TabPanels;
