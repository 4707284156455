import Select from 'ui-new/Select/Select';
import { GenericDropdownOption } from 'App.types';
import range from 'lodash/range';
import { useState } from 'react';
import { ValueType } from 'react-select';

const options: GenericDropdownOption<number>[] = range(0, 100).map((i) => ({
    value: i,
    label: `Option ${i}`,
}));

const SelectPlayground = () => {
    const [selected, setSelected] = useState<ValueType<GenericDropdownOption<number>, false>>();

    return (
        <div style={{ width: '300px', marginLeft: '2rem' }}>
            <Select
                isSearchable
                isDisabled={false}
                isLoading={false}
                options={options}
                value={selected}
                onChange={setSelected}
            />
        </div>
    );
};

export default SelectPlayground;
