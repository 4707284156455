import { ApiResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { PhylloSearchLocation } from './locations.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';

export type GetPhylloLocationsParams = Partial<
    PaginatedRequest & {
        search: string;
        uuid: string;
        type: PhylloSearchLocation['type'];
    }
>;

export async function getPhylloLocations(
    params: GetPhylloLocationsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<PhylloSearchLocation>, 200>> {
    const response = await fetchWithToken(`/api/creator-data/locations/${encodeUrlSearchParams(params)}`, requestInit);

    if (!response.ok) {
        throw new Error(`Could not get locations ${encodeUrlSearchParams(params)}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
