import { CustomAudienceOption } from '../../../../../../../types/Facebook.types';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import cn from 'classnames';
import styles from '../FacebookTargetingCustomAudienceSelect.module.css';
import { formatSnakeCaseToDisplay } from '../../../../../../../../../../helpers';
import ReactDOM from 'react-dom';
import CustomAudienceDetails from './CustomAudienceDetails';

type CustomAudienceRowProps = {
    data: CustomAudienceOption;
    className?: string;
};

const CustomAudienceRow = ({ data, className }: CustomAudienceRowProps) => {
    const [showDetails, setShowDetails] = useState(false);
    const [optionRef, setOptionRef] = useState<HTMLDivElement | null>(null);
    const [detailsRef, setDetailsRef] = useState<HTMLDivElement | null>(null);
    const { attributes: popperAttributes, styles: popperStyles } = usePopper(optionRef, detailsRef, {
        placement: 'right',
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
    });

    return (
        <>
            <div
                ref={setOptionRef}
                className={cn(styles.optionContainer, className)}
                onMouseEnter={() => setShowDetails(true)}
                onMouseLeave={() => setShowDetails(false)}
            >
                {data.label}
                <span className={styles.optionSubtype}>{formatSnakeCaseToDisplay(data.subtype)}</span>
            </div>

            {showDetails &&
                ReactDOM.createPortal(
                    <div
                        ref={setDetailsRef}
                        className={styles.optionDetails}
                        style={{ ...popperStyles.popper, zIndex: 90000 }}
                        {...popperAttributes.popper}
                    >
                        <CustomAudienceDetails data={data} />
                    </div>,
                    document.body
                )}
        </>
    );
};

export default CustomAudienceRow;
