import { TiktokVideo } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import omit from 'lodash/omit';
import { PostIdData } from 'Modules/Plans/Posts/types';

export type Data = {
    [postId: string]: DataState<{ video: TiktokVideo | null }> | undefined;
};

export type State = {
    isInitialized: boolean;
    data: Data;
};

export const initialState: State = {
    isInitialized: false,
    data: {},
};

export type Actions =
    | ReducerActionWithPayload<'tiktokVideosLoading', { postIds: string[] }>
    | ReducerActionWithPayload<'tiktokVideosSuccess', { idData: PostIdData[]; videos: TiktokVideo[] }>
    | ReducerActionWithPayload<'tiktokVideosError', { postIds: string[]; error: string }>
    | ReducerActionWithPayload<'tiktokVideosIdle', { postIds: string[] }>
    | ReducerAction<'tiktokVideosInitialized'>
    | ReducerActionWithPayload<'removeTiktokVideos', string[]>;

export const reducer = createReducer<State, Actions>({
    tiktokVideosLoading: (state, { payload: { postIds } }) => {
        const incomingTiktokVideosAsLoading = postIds.reduce((acc, postId) => {
            acc[postId] = { status: 'loading', data: null, error: null };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingTiktokVideosAsLoading } };
    },
    tiktokVideosSuccess: (state, { payload: { idData, videos } }) => {
        const incomingTiktokVideosAsSuccess = idData?.reduce((acc, { postId, contentId }) => {
            const linkedVideo = videos.find((video) => video.id === contentId) || null;

            acc[postId] = {
                status: 'success',
                data: {
                    video: linkedVideo,
                },
                error: null,
            };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingTiktokVideosAsSuccess } };
    },
    tiktokVideosError: (state, { payload: { postIds, error } }) => {
        const incomingTiktokVideosAsError = postIds?.reduce((acc, postId) => {
            acc[postId] = { status: 'error', data: null, error };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingTiktokVideosAsError } };
    },
    tiktokVideosIdle: (state, { payload: { postIds } }) => {
        const incomingTiktokVideosAsIdle = postIds?.reduce((acc, postId) => {
            acc[postId] = { status: 'idle', data: null, error: null };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingTiktokVideosAsIdle } };
    },
    tiktokVideosInitialized: (state) => ({ ...state, isInitialized: true }),
    removeTiktokVideos: (state, { payload }) => ({
        ...state,
        data: omit(state.data, payload),
    }),
});
