import React from 'react';
import GeneratedForm, { GeneratedFormProps } from '../../../../../ui/DataEntry/Form/GeneratedForm';
import { YoutubePlaylistReportItemApiModel } from '../YoutubeReportItems.api';
import * as yup from 'yup';

type FormValues = Partial<YoutubePlaylistReportItemApiModel>;

const fields: GeneratedFormProps<FormValues>['fields'] = [
    { name: 'media_plan_item_id', label: 'Media Plan Item Id', type: 'text', placeholder: 'Enter media plan item id' },
    { name: 'amount_spent', label: 'Amount Spent', type: 'text', placeholder: 'Enter amount spent' },
];

export type YoutubePlaylistReportItemFormProps = Omit<GeneratedFormProps<FormValues>, 'fields' | 'validationSchema'>;

const validationSchema = yup.object({
    media_plan_item_id: yup.number().label('Media Plan Item Id').nullable().required(),
    amount_spent: yup.number().label('Amount Spent').nullable().required(),
});

const YoutubePlaylistReportItemForm = (props: YoutubePlaylistReportItemFormProps) => {
    return <GeneratedForm fields={fields} validationSchema={validationSchema} {...props} />;
};

export default YoutubePlaylistReportItemForm;
