import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { InvoiceRequest } from './invoiceRequest.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';

export const models = {
    invoiceRequest: Model as ModelDefinition<InvoiceRequest>,
};

export const serializers = {
    invoiceRequest: makeSerializer<InvoiceRequest>([]),
};

export const factories = {
    invoiceRequest: createFactory<InvoiceRequest>({
        contact: (index: number) => `contact ${index}`,
        release_id: (index: number) => index,
        invoice_id: null,
        group_budget: '0.000',
        group_name: (index: number) => `group name ${index}`,
        currency_id: 1,
        instagram_group_id: null,
        notes: (index: number) => `notes ${index}`,
        po_number: (index: number) => `po number ${index}`,
        status: 'PENDING',
        tiktok_group_id: (index: number) => index,
        plan_start_date: new Date().toISOString(),
        proportion_spent: '0.0000',
        modified: new Date().toISOString(),
    }),
};

export function handleRequests(server: AppServer) {
    const getInvoiceRequestsEndpoint = '/api/advertising/invoice-requests/';
    server.get(getInvoiceRequestsEndpoint, function (this: RouteHandlerContext, schema, request) {
        const invoiceRequests = schema.all('invoiceRequest');
        return buildPaginatedResponse(invoiceRequests, {
            url: getInvoiceRequestsEndpoint,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'invoiceRequest'),
        });
    });

    server.patch('/api/advertising/invoice-requests/:id/', function (this: RouteHandlerContext, schema, request) {
        const invoiceRequest = schema.find('invoiceRequest', request.params.id);
        if (!invoiceRequest) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        invoiceRequest.update(JSON.parse(request.requestBody));
        return invoiceRequest;
    });
}
