import React from 'react';
import { IconProps } from './Icon.types';

const CalendarIcon = ({
    width,
    height,
    title = 'Calendar',
    onClick,
    className,
    ariaRole = 'img',
    stroke,
}: IconProps) => (
    <svg
        className={className}
        onClick={onClick}
        width={width}
        height={height}
        role={ariaRole}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <path
            d="M5.33398 1.33301V3.33301"
            stroke={stroke}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.666 1.33301V3.33301"
            stroke={stroke}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.6667 2.33301C12.8867 2.45301 14 3.29967 14 6.43301V10.553C14 13.2997 13.3333 14.673 10 14.673H6C2.66667 14.673 2 13.2997 2 10.553V6.43301C2 3.29967 3.11333 2.45967 5.33333 2.33301H10.6667Z"
            stroke={stroke}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.8337 11.7334H2.16699"
            stroke={stroke}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.00065 5.5C7.18065 5.5 6.48732 5.94667 6.48732 6.81333C6.48732 7.22667 6.68065 7.54 6.97398 7.74C6.56732 7.98 6.33398 8.36667 6.33398 8.82C6.33398 9.64667 6.96732 10.16 8.00065 10.16C9.02732 10.16 9.66732 9.64667 9.66732 8.82C9.66732 8.36667 9.43398 7.97333 9.02065 7.74C9.32065 7.53333 9.50732 7.22667 9.50732 6.81333C9.50732 5.94667 8.82065 5.5 8.00065 5.5ZM8.00065 7.39333C7.65398 7.39333 7.40065 7.18667 7.40065 6.86C7.40065 6.52667 7.65398 6.33333 8.00065 6.33333C8.34732 6.33333 8.60065 6.52667 8.60065 6.86C8.60065 7.18667 8.34732 7.39333 8.00065 7.39333ZM8.00065 9.33333C7.56065 9.33333 7.24065 9.11333 7.24065 8.71333C7.24065 8.31333 7.56065 8.1 8.00065 8.1C8.44065 8.1 8.76065 8.32 8.76065 8.71333C8.76065 9.11333 8.44065 9.33333 8.00065 9.33333Z"
            fill={stroke}
        />
    </svg>
);

export default CalendarIcon;
