import { MicrosEmailPreview, previewChaseEmail, PreviewMicrosEmailParams } from '@round/api';
import { useEffect, useState } from 'react';

type State =
    | {
          status: 'not-initialized' | 'loading';
          preview: null;
          error: null;
      }
    | {
          status: 'initialized';
          preview: MicrosEmailPreview;
          error: null;
      }
    | {
          status: 'error';
          preview: null;
          error: string[];
      };

export default function useChaseEmailPreview({
    email_template,
    tiktokAudioId,
    planId,
    userId,
}: PreviewMicrosEmailParams) {
    const [previewState, setPreviewState] = useState<State>({
        status: 'not-initialized',
        error: null,
        preview: null,
    });

    const resetPreview = () => setPreviewState({ status: 'not-initialized', preview: null, error: null });
    const setLoading = () => setPreviewState({ status: 'loading', preview: null, error: null });
    const setError = (error: string[]) => setPreviewState({ status: 'error', error, preview: null });
    const setPreview = (preview: MicrosEmailPreview) =>
        setPreviewState({ status: 'initialized', error: null, preview });

    useEffect(() => {
        if (previewState.status === 'not-initialized' && email_template) {
            setLoading();
            previewChaseEmail({ tiktokAudioId, userId, planId, email_template })
                .then((response) => {
                    if (response.status === 200) {
                        setPreview(response.data);
                        return;
                    }

                    const error = response.status === 400 ? response.data : [response.data.detail];
                    setError(error);
                })
                .catch((e) => {
                    const message = e instanceof Error ? e.message : "Sorry, we're experiencing technical issues";
                    setError([message]);
                });
        }
    }, [email_template, planId, previewState.status, tiktokAudioId, userId]);

    return {
        previewState,
        resetPreview,
    };
}
