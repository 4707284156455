import React, { FunctionComponent } from 'react';
import Dialog, { DialogProps } from '../Dialog/Dialog';

type ConfirmDialogProps = Omit<DialogProps, 'confirmButtonType' | 'confirmButtonColor'>;

const ConfirmDeleteDialog: FunctionComponent<ConfirmDialogProps> = (props) => {
    return <Dialog {...props} confirmButtonColor="negative" confirmLabel={props.confirmLabel ?? 'Confirm'} />;
};

export default ConfirmDeleteDialog;
