import { MediaPlanItemRow } from '../../../../../App.types';
import React, { useCallback, useMemo, useState } from 'react';
import { showNotification } from '../../../../../helpers';
import DropdownButton from '../../../../../ui/Buttons/DropdownButton/DropdownButton';
import styles from './ExportReports.module.css';
import Button from '../../../../../ui/Buttons/Button/Button';
import { ReactComponent as DownloadDocumentIcon } from '../../assets/DownloadDocument.svg';
import { downloadMediaPlanReport, MediaPlanReportTypes } from '../../api/MediaPlan.api';
import { MediaPlan, MediaPlanItemGroup } from '@round/api';

type MediaPlanReportsProps = {
    mediaPlan: MediaPlan;
    items: MediaPlanItemRow[];
    groups: MediaPlanItemGroup[];
};

const BETA_CLIENT_IDS = [37, 38, 41, 42, 43, 44, 45, 46, 47];

const MediaPlanReports = ({ mediaPlan, items, groups }: MediaPlanReportsProps) => {
    const [reportLoading, setReportLoading] = useState<MediaPlanReportTypes | 'summary' | null>(null);

    const downloadReport = useCallback(
        async (type: MediaPlanReportTypes) => {
            setReportLoading(type);
            try {
                await downloadMediaPlanReport(mediaPlan.id, type);
            } catch (e) {
                const errorMessage = e instanceof Error ? e.message : 'Could not download report';
                showNotification(errorMessage, 'error');
            } finally {
                setReportLoading(null);
            }
        },
        [mediaPlan.id]
    );

    const downloadPdfSummary = useCallback(async () => {
        try {
            setReportLoading('summary');
            const blob = await import('@react-pdf/renderer').then(async ({ pdf }) => {
                return pdf(
                    await import('./MediaPlanItemTablePdf').then(({ default: MediaPlanItemTablePdf }) => (
                        <MediaPlanItemTablePdf mediaPlan={mediaPlan} items={items} groups={groups} />
                    ))
                ).toBlob();
            });
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.setAttribute(
                'download',
                `Round Media Plan | ${mediaPlan.release.brand.name} - ${mediaPlan.release.name}.pdf`
            );
            a.click();
        } catch {
            showNotification('Could not download summary', 'error');
        } finally {
            setReportLoading(null);
        }
    }, [groups, items, mediaPlan]);

    const isBetaClient = useMemo(() => BETA_CLIENT_IDS.includes(mediaPlan.release.brand.client.id), [
        mediaPlan.release.brand.client.id,
    ]);

    return (
        <DropdownButton
            label="Export"
            type="bordered"
            Icon={() => <DownloadDocumentIcon className={styles.icon} />}
            className={styles.button}
        >
            <div className={styles.list}>
                <Button className={styles.button} onClick={downloadPdfSummary} loading={reportLoading === 'summary'}>
                    PDF - Summary
                </Button>
                <Button
                    className={styles.button}
                    onClick={() => downloadReport('pdf')}
                    loading={reportLoading === 'pdf'}
                >
                    PDF - Detailed
                </Button>
                {isBetaClient && (
                    <Button
                        className={styles.button}
                        onClick={() => downloadReport('excel')}
                        loading={reportLoading === 'excel'}
                    >
                        Excel
                    </Button>
                )}
            </div>
        </DropdownButton>
    );
};

export default MediaPlanReports;
