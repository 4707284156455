import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    OrderingParams,
    OrderingValues,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import {
    Song,
    SongApiModel,
    SongAudioDataRefreshRequest,
    SongSimple,
    SongTiktokAudioPostStats,
    SongTiktokSearchTerm,
    SongTiktokStats,
} from './song.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import { TiktokAudioStats } from '../../tiktok';

type CreateSpotifySongByUrlResponse = ApiResponse<SongSimple, 201> | ApiResponse<{ error: string }, 400>;

export async function createSongFromSpotifyUrl(url: string): Promise<CreateSpotifySongByUrlResponse> {
    const response = await fetchWithToken('/api/music/viewsets/song/create-from-spotify/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ spotify_identifier: url }),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not POST: ${url}`);
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type GetSongResponse = ApiResponse<Song, 200> | ApiResponse<{ detail: string }, 404>;
export async function getSong(songId: number, requestInit?: RequestInit): Promise<GetSongResponse> {
    const response = await fetchWithToken(`/api/music/viewsets/song/${songId}/`, requestInit);
    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get song with id: ${songId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetSongsParams = Partial<PaginatedRequest & { tiktok_audios: string; search: string; id: string }>;
export async function getSongs(
    params: GetSongsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<Song>, 200>> {
    const queryParams = encodeUrlSearchParams(params);
    const response = await fetchWithToken(`/api/music/viewsets/song/${queryParams}`, requestInit);

    if (!response.ok) {
        throw new Error(`Couldn't get songs with params: ${queryParams}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PostSongResponse = ApiResponse<Song, 201>;
export async function postSong(data: SongApiModel): Promise<PostSongResponse> {
    const response = await fetchWithToken(`/api/music/viewsets/song/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error('Could not post a song');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type PatchSongResponse =
    | ApiResponse<Song, 201>
    | ApiResponse<ApiResponseError<SongApiModel>, 400>
    | ApiResponse<{ detail: string }, 404>;
export async function patchSong(songId: number, data: Partial<SongApiModel>): Promise<PatchSongResponse> {
    const response = await fetchWithToken(`/api/music/viewsets/song/${songId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not patch song ${songId}`);
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export async function uploadSongImage(songId: number, image: File): Promise<Song> {
    const form = new FormData();
    form.append('image', image);
    const response = await fetchWithToken(`/api/music/viewsets/song/${songId}/`, {
        method: 'PATCH',
        body: form,
    });

    if (!response.ok) {
        throw new Error(`Could not upload image to song ${songId}`);
    }

    return response.json();
}

export type GetSongTiktokStatsParams = Partial<
    { search: string } & PaginatedRequest & OrderingParams<keyof SongTiktokStats>
>;
export async function getSongTiktokStats(
    params: GetSongTiktokStatsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<SongTiktokStats>, 200>> {
    const paramsString = encodeUrlSearchParams(params);
    const response = await fetchWithToken(`/api/music/viewsets/song-tiktok-stats/${paramsString}`, requestInit);
    if (!response.ok) {
        throw new Error(`Could not get song tiktok stats with params: ${paramsString}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetSongTiktokStatsByIdResponse = ApiResponse<SongTiktokStats, 200> | ApiResponse<{ detail: string }, 404>;

export async function getSongTiktokStatsById(
    songId: number,
    requestInit?: RequestInit
): Promise<GetSongTiktokStatsByIdResponse> {
    const response = await fetchWithToken(`/api/music/viewsets/song-tiktok-stats/${songId}/`, requestInit);

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not fetch song tiktok stats for song ${songId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type AudioStatsV2SortableKeys = OrderingValues<
    Extract<
        keyof TiktokAudioStats,
        | 'video_count'
        | 'video_count_daily_change'
        | 'video_count_daily_change_relative'
        | 'video_count_weekly_change'
        | 'video_count_weekly_change_relative'
    >
>;

export type GetSongTiktokAudioStatsV2Params = Partial<
    PaginatedRequest & {
        search: string;
        ordering: AudioStatsV2SortableKeys;
    }
>;

type GetSongTiktokAudioStatsV2Response = ApiResponse<PaginatedApiResponseData<TiktokAudioStats>, 200>;

export async function getSongTiktokAudioStatsV2(
    songId: number,
    params: GetSongTiktokAudioStatsV2Params,
    requestInit?: RequestInit
): Promise<GetSongTiktokAudioStatsV2Response> {
    const response = await fetchWithToken(
        `/api/music/viewsets/song/${songId}/tiktok-audio-stats-v2/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Couldn't get tiktok audio stats for song: ${songId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type AudioPostStatsSortableKeys = keyof Pick<
    SongTiktokAudioPostStats,
    'play_count' | 'share_count' | 'create_time' | 'author_follower_count'
>;

export type GetSongTiktokAudioPostStatsParams = Partial<
    PaginatedRequest & {
        search: string;
        ordering: OrderingValues<AudioPostStatsSortableKeys>;
    }
>;

type GetSongTiktokAudioPostStatsResponse =
    | ApiResponse<PaginatedApiResponseData<SongTiktokAudioPostStats>, 200>
    | NotFoundResponse;

export async function getSongTiktokAudioPostStats(
    songId: number,
    params: GetSongTiktokAudioPostStatsParams,
    requestInit?: RequestInit
): Promise<GetSongTiktokAudioPostStatsResponse> {
    const response = await fetchWithToken(
        `/api/music/songs/${songId}/tiktok-audio-post-stats/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Couldn't get tiktok audio post stats`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type RefreshSongAudioDataResponse = ApiResponse<{ detail: string }, 404> | ApiResponse<null, 200>;

export async function refreshSongAudioData(songId: number): Promise<RefreshSongAudioDataResponse> {
    const response = await fetchWithToken(`/api/music/viewsets/song/${songId}/refresh-audio-data/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not refresh audio data for song: ${songId}`);
    }

    return {
        status: 200,
        data: null,
    };
}

export type GetSongAudioDataRefreshRequestsParams = Partial<PaginatedRequest & { song_id: number }>;

type GetSongAudioDataRefreshRequestsResponse = ApiResponse<PaginatedApiResponseData<SongAudioDataRefreshRequest>, 200>;

export async function getSongAudioDataRefreshRequests(
    params: GetSongAudioDataRefreshRequestsParams,
    requestInit?: RequestInit
): Promise<GetSongAudioDataRefreshRequestsResponse> {
    const response = await fetchWithToken(
        `/api/music/song-audio-data-refresh-requests/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not get song audio data refresh requests`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type GetSongTiktokSearchTermsParams = Partial<PaginatedRequest>;
type GetSongTiktokSearchTermsResponse = ApiResponse<PaginatedApiResponseData<SongTiktokSearchTerm>, 200>;

export async function getSongTiktokSearchTerms(
    songId: number,
    params: GetSongTiktokSearchTermsParams,
    requestInit?: RequestInit
): Promise<GetSongTiktokSearchTermsResponse> {
    const response = await fetchWithToken(
        `/api/music/viewsets/song/${songId}/tiktok-search-term/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not get song tiktok search terms');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getSongLatestTiktokSearchLog(
    songId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<{ created: string | null }>> {
    const response = await fetchWithToken(`/api/music/viewsets/song/${songId}/latest-tiktok-search-log/`, requestInit);

    if (!response.ok) {
        throw new Error('Could not get latest song tiktok search log');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type RefreshSongFromSpotifyResponse =
    | ApiResponse<{ detail: string }, 404>
    | ApiResponse<SongSimple, 200>
    | ApiResponse<{ error: string }, 400>;

export async function refreshSongFromSpotify(
    songId: number,
    spotifyUrl?: string
): Promise<RefreshSongFromSpotifyResponse> {
    const response = await fetchWithToken(`/api/music/viewsets/song/${songId}/refresh-from-spotify/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(spotifyUrl ? { spotify_identifier: spotifyUrl } : {}),
    });

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not refresh song data: ${songId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type RemoveTiktokAudioFromSongResponse =
    | ApiResponse<null, 204>
    | ApiResponse<{ error: string }, 400>
    | ApiResponse<{ detail: string }, 404>;

export async function removeTiktokAudioFromSong(
    songId: number,
    tiktokAudioId: number
): Promise<RemoveTiktokAudioFromSongResponse> {
    const response = await fetchWithToken(`/api/music/viewsets/song/${songId}/remove-tiktok-audio/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tiktok_audio_id: tiktokAudioId }),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not remove tiktok audio ${tiktokAudioId} from song ${songId}`);
    }

    return {
        status: 204,
        data: null,
    };
}
