import React from 'react';
import styles from './AuthPageLayout.module.css';
import { FCWithChildren } from '../../utility/utility.types';

type AuthPageLayoutProps = {
    LogoFull?: React.FC<React.SVGProps<SVGSVGElement>>;
    LogoShort?: React.FC<React.SVGProps<SVGSVGElement>>;
    brandingImageSrc: string;
    brandingImageMobileSrc?: string;
};

const AuthFormPageLayout: FCWithChildren<AuthPageLayoutProps> = ({
    LogoFull,
    LogoShort,
    brandingImageMobileSrc,
    brandingImageSrc,
    children,
}) => (
    <main
        className={styles.main}
        style={{
            backgroundImage: brandingImageMobileSrc ? `url(${brandingImageMobileSrc})` : `url(${brandingImageSrc})`,
        }}
    >
        <header className={styles.mobileLogoBar}>{LogoFull && <LogoFull className={styles.logoFull} />}</header>

        <div className={styles.branding} style={{ backgroundImage: `url(${brandingImageSrc})` }}>
            {LogoFull && <LogoFull />}
        </div>
        <article className={styles.content}>
            {LogoShort && <LogoShort />}
            <div>{children}</div>
        </article>
    </main>
);

export default AuthFormPageLayout;
