import { components, SingleValueProps } from 'react-select';
import { GenericDropdownOption } from 'App.types';
import { InvoiceRequestStatus } from '@round/api';
import React from 'react';
import StatusBadge from './StatusBadge';

type Props = SingleValueProps<GenericDropdownOption<InvoiceRequestStatus>>;

const StatusBadgeValue = (props: Props) => {
    return (
        <components.SingleValue {...props}>
            <StatusBadge status={props.data.value} />
        </components.SingleValue>
    );
};

export default StatusBadgeValue;
