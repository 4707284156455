import { createContext, Dispatch, useReducer } from 'react';
import reducer, { initialState, YoutubeCreatorsState, Actions } from './reducer';
import { FCWithChildren } from '../../../../../utility/utility.types';

export const YoutubeCreatorsStateContext = createContext<YoutubeCreatorsState | null>(null);
export const YoutubeCreatorsDispatchContext = createContext<Dispatch<Actions> | null>(null);

export const YoutubeCreatorsContext = createContext<YoutubeCreatorsState | null>(null);
export const YoutubeCreatorsContextProvider: FCWithChildren = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <YoutubeCreatorsStateContext.Provider value={state}>
            <YoutubeCreatorsDispatchContext.Provider value={dispatch}>
                {children}
            </YoutubeCreatorsDispatchContext.Provider>
        </YoutubeCreatorsStateContext.Provider>
    );
};
