import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { MonitoredAudioUrl } from './monitoredAudio.types';
import { createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokMonitoredAudio: Model as ModelDefinition<MonitoredAudioUrl>,
};

export const serializers = {
    tiktokMonitoredAudio: makeSerializer<MonitoredAudioUrl>([]),
};

export const factories = {
    tiktokMonitoredAudio: createFactory<MonitoredAudioUrl>({
        is_active() {
            return false;
        },
        is_enabled() {
            return false;
        },
        is_enhanced() {
            return false;
        },
    }),
};

export function handleMonitoredAudioRequests(server: AppServer) {
    server.get('/api/tiktok/viewsets/monitored-audio/:id/', function (this: RouteHandlerContext, schema, request) {
        const audio = schema.find('tiktokMonitoredAudio', request.params.id);
        if (!audio) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return audio;
    });
}
