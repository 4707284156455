import { microwave, postInstagramAudio, postTikTokAudio } from '@round/api';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { omit, uniq } from 'lodash';
import useReleaseSelect from 'Modules/Advertising/Projects/hooks/useReleaseSelect';
import SelectField from 'ui-new/formik/SelectField';
import Button from 'ui/Button/Button';
import FullScreenModal from 'ui/FullScreenModal/FullScreenModal';
import CampaignForm from '../components/CampaignForm/CampaignForm';
import styles from './CreateCampaignModal.module.css';
import moment from 'moment';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import { showNotification } from 'helpers';
import { useMemo } from 'react';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { useMicrowaveCampaigns } from '../../hooks/useMicrowaveCampaigns';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import ExistingCampaigns from '../components/ExistingCampaigns/ExistingCampaigns';
import { MICROWAVE_PLATFORMS } from '../../helpers';
import { CampaignFormValues, validationSchema } from '../components/CampaignForm/helpers';
import * as yup from 'yup';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    createCampaign: typeof microwave.postCampaign;
};

const CreateCampaignModal = ({ isOpen, onClose, createCampaign }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);

    const releaseSelectProps = useReleaseSelect();

    const initialValues: CampaignFormValues = useMemo(() => {
        const GBP = currencies.find((c) => c.name === 'GBP');
        return {
            release: null,
            currency: GBP ? { value: GBP.id, label: GBP.name } : null,
            team_members: [],
            planned_end_date: moment().format('YYYY-MM-DD'),
            planned_start_date: moment().format('YYYY-MM-DD'),
            is_instagram_audio_select_mode: true,
            is_tiktok_audio_select_mode: true,
        };
    }, [currencies]);

    const handleSubmit = async (values: CampaignFormValues, helpers: FormikHelpers<CampaignFormValues>) => {
        if (Object.values(values).some((value) => value === null || value === undefined)) {
            console.error('Some form values are missing');
            return;
        }

        try {
            helpers.setSubmitting(true);
            let instagramAudioId = values.instagram_audio?.value;
            if (
                values.platform === 'instagram' &&
                !values.is_instagram_audio_select_mode &&
                values.instagram_audio_identifier
            ) {
                const instagramAudioResponse = await postInstagramAudio({
                    identifier: values.instagram_audio_identifier,
                });

                if (instagramAudioResponse.status === 400) {
                    helpers.setFieldError(
                        'instagram_audio_identifier',
                        Array.isArray(instagramAudioResponse.data.identifier)
                            ? instagramAudioResponse.data.identifier.join('. ')
                            : instagramAudioResponse.data.identifier
                    );
                    return;
                }

                instagramAudioId = instagramAudioResponse.data.id;
            }

            let tiktokAudioId = values.tiktok_audio?.value;
            if (values.platform === 'tiktok' && !values.is_tiktok_audio_select_mode && values.tiktok_audio_identifier) {
                const tiktokAudioResponse = await postTikTokAudio({ identifier: values.tiktok_audio_identifier });

                if (tiktokAudioResponse.status === 400) {
                    helpers.setFieldError(
                        'tiktok_audio_identifier',
                        Array.isArray(tiktokAudioResponse.data.identifier)
                            ? tiktokAudioResponse.data.identifier.join('. ')
                            : tiktokAudioResponse.data.identifier
                    );
                    return;
                }

                tiktokAudioId = tiktokAudioResponse.data.id;
            }

            const response = await createCampaign({
                planned_start_date: values.planned_start_date!,
                planned_end_date: values.planned_end_date!,
                platform: values.platform!,
                target_post_count: values.target_post_count!,
                release_id: values.release?.value!,
                currency_id: values.currency?.value!,
                team_members: values.team_members?.map((member) => member.value) || [],
                budget: parseFloat(values?.budget!),
                instagram_audio_id: instagramAudioId,
                tiktok_audio_id: tiktokAudioId,
            });

            if (response.status === 201) {
                showNotification('Campaign created', 'info');
                helpers.resetForm();
                onClose();
                return;
            }

            if (Array.isArray(response.data)) {
                showNotification(response.data.join(', '), 'error');
                return;
            }

            helpers.setErrors(
                mapApiErrorsToFormikErrors({
                    target_post_count: response.data.target_post_count,
                    currency: response.data.currency_id,
                    planned_end_date: response.data.planned_end_date,
                    planned_start_date: response.data.planned_start_date,
                    budget: response.data.budget,
                    team_members: response.data.team_members,
                    release: response.data.release_id,
                    platform: response.data.platform,
                    instagram_audio: response.data.instagram_audio_id,
                    tiktok_audio: response.data.tiktok_audio_id,
                })
            );
        } catch {
            showNotification('Could not create campaign', 'error');
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const formikProps = useFormik<CampaignFormValues>({
        initialValues,
        validationSchema: validationSchema.shape({
            instagram_audio_identifier: yup.string().when(['platform', 'is_instagram_audio_select_mode'], {
                is: (platform: microwave.Platform, isSelectMode: boolean) => platform === 'instagram' && !isSelectMode,
                then: (schema) => schema.required('Audio is required'),
            }),
            instagram_audio: yup
                .object()
                .nullable()
                .when(['platform', 'is_instagram_audio_select_mode'], {
                    is: (platform: microwave.Platform, isSelectMode: boolean) =>
                        platform === 'instagram' && isSelectMode,
                    then: (schema) => schema.required('Audio is required'),
                }),
            tiktok_audio_identifier: yup.string().when(['platform', 'is_tiktok_audio_select_mode'], {
                is: (platform: microwave.Platform, isSelectMode: boolean) => platform === 'tiktok' && !isSelectMode,
                then: (schema) => schema.required('Audio is required'),
            }),
            tiktok_audio: yup
                .object()
                .nullable()
                .when(['platform', 'is_tiktok_audio_select_mode'], {
                    is: (value: microwave.Platform, isSelectMode: boolean) => value === 'tiktok' && isSelectMode,
                    then: (schema) => schema.required('Audio is required'),
                }),
        }),
        onSubmit: handleSubmit,
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
    });

    const selectedReleaseId = formikProps.values.release?.value;

    const { fetchData: fetchCampaigns, ...currentCampaigns } = useMicrowaveCampaigns();

    useAbortableEffect(
        (signal) => {
            if (!selectedReleaseId) {
                return;
            }
            fetchCampaigns({ release_id: selectedReleaseId.toString() }, { signal });
        },
        [selectedReleaseId, fetchCampaigns]
    );

    const availablePlatforms = MICROWAVE_PLATFORMS.filter(
        (platform) => !currentCampaigns.data?.results.some((campaign) => campaign.platform === platform)
    );

    const handleClose = () => {
        formikProps.resetForm();
        onClose();
    };

    const isMaxCampaignsCreated =
        uniq(currentCampaigns.data?.results.map((c) => c.platform) ?? []).length >= MICROWAVE_PLATFORMS.length;

    const showCampaignForm = selectedReleaseId && !isMaxCampaignsCreated;

    return (
        <FullScreenModal isOpen={isOpen} onClose={handleClose} closeOnEscape>
            <FullScreenModal.Header>Add Campaign</FullScreenModal.Header>
            <FullScreenModal.Content className={styles.content}>
                <FormikProvider value={formikProps}>
                    <Form className={styles.form}>
                        <SelectField
                            {...omit(releaseSelectProps, ['value', 'onChange'])}
                            label="Release"
                            name="release"
                            placeholder="Select a release"
                            isClearable
                            isSearchable
                        />

                        {showCampaignForm && (
                            <CampaignForm
                                isLoading={currentCampaigns.status === 'loading'}
                                availablePlatforms={availablePlatforms}
                            />
                        )}
                    </Form>
                </FormikProvider>

                {selectedReleaseId && (
                    <ExistingCampaigns
                        campaigns={currentCampaigns.data.results}
                        isLoading={currentCampaigns.status === 'loading'}
                    />
                )}
            </FullScreenModal.Content>

            <FullScreenModal.Footer>
                <Button appearance="ghost" onClick={handleClose}>
                    Cancel
                </Button>

                <Button
                    appearance="primary"
                    type="submit"
                    onClick={formikProps.submitForm}
                    disabled={formikProps.isSubmitting || isMaxCampaignsCreated}
                    isLoading={formikProps.isSubmitting}
                >
                    Add Campaign
                </Button>
            </FullScreenModal.Footer>
        </FullScreenModal>
    );
};

export default CreateCampaignModal;
