import { Button, Checkbox, Modal, ModalContent, ModalFooter, ModalTitle } from '@round/ui-kit';
import styles from './RoundClientUserModal.module.css';
import RoundClientUserForm, { RoundClientUserFormValues } from './RoundClientUserForm/RoundClientUserForm';
import { Formik, FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';
import { mapApiErrorsToFormikErrors } from '../../../../../../utility/utility';
import { showNotification } from '../../../../../../helpers';
import * as yup from 'yup';
import { postRoundClientUser } from '@round/api';
import { sendRoundClientUserWelcomeEmail } from '@round/api';

type CreateRoundClientUserModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    onCreated: () => void;
};

const CreateRoundClientUserModal = ({ isOpen, closeModal, onCreated }: CreateRoundClientUserModalProps) => {
    const [shouldSendWelcomeEmail, setShouldSendWelcomeEmail] = useState(false);

    const validationSchema = yup.object().shape({
        email: yup.string().email('Email is invalid').required('Email is required'),
    });

    const initialValues: RoundClientUserFormValues = {
        first_name: '',
        last_name: '',
        email: '',
        client_ids: [],
    };

    const handleCloseModal = useCallback(() => {
        setShouldSendWelcomeEmail(false);
        closeModal();
    }, [closeModal]);

    const handleSubmit = useCallback(
        async (
            values: RoundClientUserFormValues,
            helpers: FormikHelpers<RoundClientUserFormValues>,
            shouldSendWelcomeEmail?: boolean
        ) => {
            const userPostData = {
                ...values,
                username: values.email,
                client_ids: values.client_ids?.map((client) => client.value) ?? [],
            };
            try {
                helpers.setSubmitting(true);
                const response = await postRoundClientUser(userPostData);
                if (response.status !== 201) {
                    helpers.setErrors(mapApiErrorsToFormikErrors(response.data));
                    return;
                }

                showNotification('Client User created successfully', 'info');

                if (!shouldSendWelcomeEmail) {
                    onCreated();
                    handleCloseModal();
                    return;
                }

                const welcomeEmailResponse = await sendRoundClientUserWelcomeEmail(response.data.id);
                if (welcomeEmailResponse.status !== 204) {
                    showNotification('Couldnt send welcome email', 'error');
                } else {
                    showNotification('Welcome email sent successfully', 'info');
                }

                onCreated();
                handleCloseModal();
            } catch {
                showNotification('Couldnt create user', 'error');
            } finally {
                helpers.setSubmitting(false);
            }
        },
        [handleCloseModal, onCreated]
    );

    return (
        <Modal isOpen={isOpen} onClose={closeModal} className={styles.modal} closeOnOverlayClick>
            <ModalTitle>Create Client User</ModalTitle>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, formikHelpers) => handleSubmit(values, formikHelpers, shouldSendWelcomeEmail)}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {(props) => (
                    <>
                        <ModalContent>
                            <RoundClientUserForm formType="create" />
                        </ModalContent>
                        <ModalFooter className={styles.footer}>
                            <div className={styles.row}>
                                <span className={styles.label}>Send welcome email?</span>
                                <Checkbox
                                    value={shouldSendWelcomeEmail}
                                    onChange={(val) => setShouldSendWelcomeEmail(val)}
                                />
                            </div>

                            <Button
                                color="black"
                                type="filled"
                                onClick={props.submitForm}
                                disabled={props.isSubmitting || !props.isValid || !props.dirty}
                                htmlType="submit"
                            >
                                Submit
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default CreateRoundClientUserModal;
