import { InfluencerLocation } from './locations.types';
import { encodeUrlSearchParams } from '../../helpers';
import { ApiResponse, NotFoundResponse, OrderingValues, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { buildApiUrl } from '../../utils';

type GetInfluencerLocationsSortableKeys = keyof Pick<
    InfluencerLocation,
    'city' | 'state' | 'country_name' | 'country_code'
>;

export type GetInfluencerLocationsParams = Partial<
    PaginatedRequest & {
        search: string;
        type: 'country' | 'city' | 'state';
        country_code: string;
        state: string;
        ordering: OrderingValues<GetInfluencerLocationsSortableKeys>;
    }
>;

type GetInfluencerLocationsResponse = ApiResponse<PaginatedApiResponseData<InfluencerLocation>, 200> | NotFoundResponse;

export async function getInfluencerLocations(
    params: GetInfluencerLocationsParams,
    requestInit?: RequestInit
): Promise<GetInfluencerLocationsResponse> {
    const response = await fetch(
        buildApiUrl(`/api/influencer/locations/${encodeUrlSearchParams(params)}`),
        requestInit
    );

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to fetch Influencer Locations');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
