import { createContext, Dispatch, ReactNode, useCallback, useReducer } from 'react';
import { ClientSimple, getClients } from '@round/api';
import { createReducer } from '../helpers';
import { ReducerAction, ReducerActionWithPayload } from '../App.types';
import useNonNullContext from '../Hooks/useNonNullContext';

type State = {
    clients: ClientSimple[];
    state: 'not-initialized' | 'initialized' | 'error' | 'loading';
    error: string | null;
};

const initialState: State = {
    clients: [],
    error: null,
    state: 'not-initialized',
};

type Actions =
    | ReducerAction<'loadClients'>
    | ReducerActionWithPayload<'clientsInitialized', ClientSimple[]>
    | ReducerActionWithPayload<'errorLoadingClients', State['error']>
    | ReducerAction<'reset'>;

const reducer = createReducer<State, Actions>({
    clientsInitialized: (state, { payload }) => ({
        state: 'initialized',
        clients: payload,
        error: null,
    }),
    errorLoadingClients: (state, { payload }) => ({
        ...state,
        state: 'error',
        error: payload,
    }),
    loadClients: (state) => ({
        ...state,
        state: 'loading',
    }),
    reset: () => initialState,
});

const ClientsContext = createContext<State | null>(null);
const ClientsDispatchContext = createContext<Dispatch<Actions> | null>(null);

export const ClientProvider = ({ children }: { children?: ReactNode | undefined }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ClientsContext.Provider value={state}>
            <ClientsDispatchContext.Provider value={dispatch}>{children}</ClientsDispatchContext.Provider>
        </ClientsContext.Provider>
    );
};

export function useClients({ id, search, planner_id }: Parameters<typeof getClients>[0] = {}) {
    const state = useNonNullContext(ClientsContext);
    const dispatch = useNonNullContext(ClientsDispatchContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'loadClients' });
                const response = await getClients(
                    {
                        id,
                        search,
                        planner_id,
                    },
                    requestInit
                );
                dispatch({ type: 'clientsInitialized', payload: response.data.results });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'errorLoadingClients', payload: 'Could not fetch clients' });
            }
        },
        [dispatch, id, search, planner_id]
    );

    return {
        ...state,
        init,
        reset: useCallback(() => dispatch({ type: 'reset' }), [dispatch]),
    };
}
