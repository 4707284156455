import React, { useCallback, useMemo, useState } from 'react';
import styles from './InstagramInfluencerExplore.module.css';
import { ReactComponent as FilterSearchIcon } from '../../../../assets/FilterSearch.svg';
import { InstagramUserImage, TagOption } from '../../../../App.types';
import { mapContentTagsToOptions, mapInfluencerTagsToOptions } from '../../../../helpers';
import { ProtectedByUserGroups } from '../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import useUrlState from '../../../../Hooks/useUrlState';
import InstagramInfluencerUserModal from '../../../../Modules/Influencer/components/InstagramInfluencerUserModal/InstagramInfluencerUserModal';
import cn from 'classnames';
import CreateInfluencerModal from '../../../../Modules/Influencer/containers/CreateInfluencerModal/CreateInfluencerModal';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import { fetchInstagramUserImages } from '../../../../Modules/Instagram/Instagram.api';
import TagsFilter from '../../../../Modules/TikTok/containers/TiktokInfluencerExplore/components/TagsFilter/TagsFilter';
import { ValueType } from 'react-select';
import { useInfluencerTags } from '../../../../Hooks/useInfluencerTags';
import SearchInput from '../../../../ui/SearchInput/SearchInput';
import Button from '../../../../ui/Buttons/Button/Button';
import Label from '../../../../ui/DataEntry/Label/Label';
import { DebounceInput } from 'react-debounce-input';
import {
    getInfluencerUserInfluencerPlans,
    getInstagramInfluencerUserStats,
    InstagramInfluencerUserStats,
    InfluencerUserCampaigns,
} from '@round/api';
import { debounce, uniq } from 'lodash';
import InstagramInfluencersTable, {
    InstagramInfluencerTableRow,
} from '../../../../Modules/Influencer/components/InstagramInfluencersTable/InstagramInfluencersTable';
import { OrderByParam } from '../../../../Hooks/useReactTableSortToOrderBy';
import { AddInfluencerToCampaignOptionsProvider } from '../../../../Modules/Influencer/containers/AddInfluencerToCampaign/AddInfluencerToCampaignContext';
import { Checkbox } from '@round/ui-kit';
import CategoriesFilter from '../../../../Modules/Tags/components/CategoriesFilter/CategoriesFilter';
import {
    mapConditionsToApi,
    parseConditionsFromUrlString,
    stringifyConditionsToUrlString,
} from '../../../../Modules/Tags/components/CategoriesFilter/helpers';
import { useContentTags } from 'contexts/ContentTagsContext';
import useInfluencerPlans from 'Modules/Advertising/InfluencerPlan/hooks/useInfluencerPlans';

type InstagramInfluencerListUrlState = Partial<{
    search: string;
    page: number;
    pageSize: number;
    minFollowers: number | string;
    maxFollowers: number | string;
    minAvgViews: number | string;
    maxAvgViews: number | string;
    minCost: number | string;
    maxCost: number | string;
    locations: string;
    only_apex: string | undefined;
    content_tags: string | undefined;
}> &
    OrderByParam<InstagramInfluencerTableRow & { influencer_user__reels_cost: string }>;

const DEBOUNCE_TIMEOUT = 700;

const InstagramInfluencerExplore = () => {
    const [isInfluencerUserModalOpen, setIsInfluencerUserModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [selectedMonitoredUserStats, setSelectedMonitoredUserStats] = useState<InstagramInfluencerUserStats | null>(
        null
    );
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [showFilters, setShowFilters] = useState(false);

    const [influencerUserCampaigns, setInfluencerUserCampaigns] = useState<InfluencerUserCampaigns[]>([]);
    const [influencerUserCampaignsLoading, setInfluencerUserCampaignsLoading] = useState(false);
    const [tableInitialized, setTableInitialized] = useState(false);

    const [
        {
            search,
            page,
            pageSize,
            maxFollowers,
            minFollowers,
            minAvgViews,
            maxAvgViews,
            minCost,
            maxCost,
            order_by_desc,
            order_by_asc,
            locations,
            only_apex,
            content_tags,
        },
        setUrlState,
    ] = useUrlState<InstagramInfluencerListUrlState>({
        search: '',
        page: 1,
        pageSize: 25,
        minFollowers: 100,
        order_by_desc: 'created',
        locations: '',
    });

    const { influencerTags, tagsLoading, errorLoadingTags } = useInfluencerTags();

    const {
        tags: contentTags,
        isLoading: areContentTagsLoading,
        isInitialized: areContentTagsInitialized,
        init: initContentTags,
    } = useContentTags();

    const contentTagOptions = useMemo(() => mapContentTagsToOptions(contentTags), [contentTags]);

    useAbortableEffect(
        (signal) => {
            if (!areContentTagsInitialized) {
                initContentTags({ signal });
            }
        },
        [areContentTagsInitialized, initContentTags]
    );

    const { locationTagOptions } = useMemo(() => mapInfluencerTagsToOptions(influencerTags), [influencerTags]);

    const selectedLocationTags = useMemo(() => {
        const locationTagIds = locations?.split(',').map((id) => Number(id)) ?? [];
        return locationTagOptions.filter((option) => locationTagIds.includes(option.value));
    }, [locationTagOptions, locations]);

    const setLocationTagsUrlState = (val: ValueType<TagOption, true>) => {
        setUrlState({ locations: val?.map((l) => String(l.value)).join(',') ?? '' });
    };

    const setPage = (page: number) => setUrlState({ page });
    const setPageSize = (pageSize: number | undefined) => setUrlState({ pageSize });
    const setMinFollowers = (minFollowers: number | string) =>
        setUrlState({ minFollowers: minFollowers || undefined, page: 1 });
    const setMaxFollowers = (maxFollowers: number | string) =>
        setUrlState({ maxFollowers: maxFollowers || undefined, page: 1 });
    const setMinAvgViews = (minAvgViews: number | string) =>
        setUrlState({ minAvgViews: minAvgViews || undefined, page: 1 });
    const setMaxAvgViews = (maxAvgViews: number | string) =>
        setUrlState({ maxAvgViews: maxAvgViews || undefined, page: 1 });
    const setMinCost = (minCost: number | string) => setUrlState({ minCost: minCost || undefined, page: 1 });
    const setMaxCost = (maxCost: number | string) => setUrlState({ maxCost: maxCost || undefined, page: 1 });
    const setSearch = useCallback((search: string) => setUrlState({ search, page: 1 }), [setUrlState]);
    const apexOnlyFilterActive = only_apex === 'true';
    const setApexOnlyFilterActive = (isActive: boolean) => setUrlState({ only_apex: isActive.toString(), page: 1 });

    const orderBy: OrderByParam<InstagramInfluencerTableRow> = useMemo(() => {
        return {
            order_by_desc:
                order_by_desc === 'influencer_user__reels_cost'
                    ? 'reels_cost'
                    : (order_by_desc as keyof InstagramInfluencerTableRow),
            order_by_asc:
                order_by_asc === 'influencer_user__reels_cost'
                    ? 'reels_cost'
                    : (order_by_asc as keyof InstagramInfluencerTableRow),
        };
    }, [order_by_asc, order_by_desc]);
    const setOrderByParam = useCallback(
        (params: OrderByParam<InstagramInfluencerTableRow>) => {
            setUrlState({
                order_by_desc:
                    params.order_by_desc === 'reels_cost'
                        ? 'influencer_user__reels_cost'
                        : params.order_by_desc || undefined,
                order_by_asc:
                    params.order_by_asc === 'reels_cost'
                        ? 'influencer_user__reels_cost'
                        : params.order_by_asc || undefined,
            });
        },
        [setUrlState]
    );

    const { stats, userImages, statsLoading, errorLoadingStats, count, refresh } = useInstagramInfluencerTableData({
        page: Number(page),
        page_size: Number(pageSize),
        search: search,
        location_tags: selectedLocationTags.map((t) => t.value).join(),
        min_follower_count: minFollowers,
        max_follower_count: maxFollowers,
        min_avg_video_plays: minAvgViews,
        max_avg_video_plays: maxAvgViews,
        min_reels_cost: minCost,
        max_reels_cost: maxCost,
        order_by_asc: order_by_asc,
        order_by_desc: order_by_desc,
        only_apex: apexOnlyFilterActive || undefined,
        content_tags: mapConditionsToApi(parseConditionsFromUrlString(content_tags)),
    });

    const tableLoading = statsLoading || influencerUserCampaignsLoading || !tableInitialized;

    const { fetchData: fetchInfluencerPlans, ...influencerPlansData } = useInfluencerPlans();

    const setTableNotInitialized = useCallback(() => {
        setTableInitialized(false);
        influencerPlansData.reset();
    }, [influencerPlansData]);

    const rows: InstagramInfluencerTableRow[] = useMemo(
        () =>
            stats.map((stats) => {
                const image = userImages?.find((image) => image.user_id === stats.user_id);
                const campaigns = influencerUserCampaigns.find((c) => c.influencer_id === stats.influencer_user_id);
                const plans =
                    influencerPlansData.data?.results.filter(
                        (plan) => !!campaigns?.plans.find((p) => p.plan_id === plan.id)
                    ) ?? [];

                return {
                    ...stats,
                    image: image?.avatar_thumb.cached_url ?? image?.avatar_thumb.original_url,
                    campaigns,
                    plans,
                };
            }),
        [stats, userImages, influencerUserCampaigns, influencerPlansData.data?.results]
    );

    const fetchCampaigns = useCallback(async (influencerUserIds: number[], requestInit?: RequestInit) => {
        if (!influencerUserIds.length) {
            return;
        }

        try {
            setInfluencerUserCampaignsLoading(true);
            const response = await getInfluencerUserInfluencerPlans(
                { instagram_influencer_users: influencerUserIds.join() },
                requestInit
            );
            if (response.status === 200) {
                setInfluencerUserCampaigns(response.data);
                return;
            }
        } catch {
            // no-op
        } finally {
            setInfluencerUserCampaignsLoading(false);
        }
    }, []);

    const refreshCampaignsForInfluencerUser = useCallback(
        async (influencerUserId: number) => {
            try {
                const response = await getInfluencerUserInfluencerPlans({
                    instagram_influencer_users: influencerUserId.toString(),
                });
                if (response.status === 200) {
                    setInfluencerUserCampaigns((campaigns) =>
                        campaigns.map((c) => (c.influencer_id === influencerUserId ? response.data[0] : c))
                    );

                    influencerPlansData.reset();
                }
            } catch {
                // no-op
            }
        },
        [influencerPlansData]
    );

    useAbortableEffect(
        (signal) => {
            fetchCampaigns(
                stats.map((stats) => Number(stats.influencer_user_id)),
                { signal }
            ).finally(() => setTableInitialized(true));
        },
        [stats, fetchCampaigns]
    );

    useAbortableEffect(
        (signal) => {
            const plansToFetch = uniq(
                influencerUserCampaigns.map((campaign) => campaign.plans.map((p) => p.plan_id)).flat()
            );

            if (!plansToFetch.length || influencerPlansData.status === 'initialized' || tableLoading) {
                return;
            }

            fetchInfluencerPlans(
                { id: plansToFetch.toString(), page_size: plansToFetch.length },
                { signal }
            ).catch(() => {});
        },
        [fetchInfluencerPlans, influencerPlansData.status, influencerUserCampaigns, tableLoading]
    );

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Instagram Influencer Explore</h1>
            <div className={styles.row}>
                <SearchInput
                    className={styles.search}
                    value={search}
                    onChange={(search) => {
                        setSearch(search);
                        setTableNotInitialized();
                    }}
                    debounceTimout={DEBOUNCE_TIMEOUT}
                />

                <div className={styles.buttonsContainer}>
                    <Button
                        className={styles.showFiltersButton}
                        type="bordered"
                        onClick={() => setShowFilters((show) => !show)}
                    >
                        <FilterSearchIcon className={styles.showFiltersIcon} />
                        Filters
                    </Button>
                    <ProtectedByUserGroups groups={['explorer_tiktok_editor', 'influencer_editor']}>
                        <Button type="filled" color="black" onClick={() => setIsCreateModalOpen(true)}>
                            Add influencer
                        </Button>

                        <CreateInfluencerModal
                            closeModal={() => setIsCreateModalOpen(false)}
                            isModalOpen={isCreateModalOpen}
                        />
                    </ProtectedByUserGroups>
                </div>
            </div>
            {showFilters && (
                <div className={styles.filtersRow}>
                    <div className={cn(styles.filtersGroup, styles.tagsFilters)}>
                        <CategoriesFilter
                            className={styles.categoriesFilter}
                            conditions={parseConditionsFromUrlString(content_tags)}
                            onChange={(conditions) => {
                                setUrlState({ content_tags: stringifyConditionsToUrlString(conditions) });
                                setTableNotInitialized();
                            }}
                            options={contentTagOptions}
                            optionsLoading={areContentTagsLoading}
                        />

                        <TagsFilter
                            value={selectedLocationTags}
                            onChange={(val) => {
                                setLocationTagsUrlState(val);
                                setTableNotInitialized();
                            }}
                            loading={tagsLoading}
                            errorLoading={errorLoadingTags}
                            className={styles.locationsFilter}
                            placeholder="Filter by location"
                            options={locationTagOptions}
                        />
                    </div>

                    <div className={styles.filtersGroup}>
                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="minFollowers">
                                Min Followers
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                type="number"
                                id="minFollowers"
                                value={minFollowers}
                                onChange={(e) => {
                                    setMinFollowers(Number(e.target.value));
                                    setTableNotInitialized();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>

                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="maxFollowers">
                                Max Followers
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                type="number"
                                id="maxFollowers"
                                value={maxFollowers}
                                onChange={(e) => {
                                    setMaxFollowers(Number(e.target.value));
                                    setTableNotInitialized();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>
                    </div>

                    <div className={styles.filtersGroup}>
                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="minAvgViews">
                                Min Avg Views
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                type="number"
                                id="minAvgViews"
                                value={minAvgViews}
                                onChange={(e) => {
                                    setMinAvgViews(Number(e.target.value));
                                    setTableNotInitialized();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>

                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="maxAvgViews">
                                Max Avg Views
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                type="number"
                                id="maxAvgViews"
                                value={maxAvgViews}
                                onChange={(e) => {
                                    setMaxAvgViews(Number(e.target.value));
                                    setTableNotInitialized();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>
                    </div>

                    <div className={styles.filtersGroup}>
                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="minCost">
                                Min Cost
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                type="number"
                                id="minCost"
                                value={minCost}
                                onChange={(e) => {
                                    setMinCost(e.target.value);
                                    setTableNotInitialized();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>

                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="maxCost">
                                Max Cost
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                type="number"
                                id="maxCost"
                                value={maxCost}
                                onChange={(e) => {
                                    setMaxCost(e.target.value);
                                    setTableNotInitialized();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>
                        <div className={styles.apexOnlyFilterWrapper}>
                            <Label className={styles.filterLabel} htmlFor="apexOnly">
                                Apex Only
                            </Label>
                            <Checkbox
                                value={apexOnlyFilterActive}
                                onChange={(value) => {
                                    setApexOnlyFilterActive(value);
                                    setTableNotInitialized();
                                }}
                                className={styles.apexOnlyFilter}
                                id="apexOnly"
                            />
                        </div>
                    </div>
                </div>
            )}
            <AddInfluencerToCampaignOptionsProvider>
                <InstagramInfluencersTable
                    loading={tableLoading}
                    data={rows}
                    errorLoadingData={errorLoadingStats}
                    count={count}
                    page={Number(page)}
                    pageSize={Number(pageSize)}
                    onPageChange={(page) => {
                        setPage(page);
                        setTableNotInitialized();
                    }}
                    onPageSizeChange={(pageSize) => {
                        setPageSize(pageSize);
                        setTableNotInitialized();
                    }}
                    orderBy={orderBy}
                    onOrderByChange={setOrderByParam}
                    onRowClick={(row) => {
                        setSelectedMonitoredUserStats(
                            stats.find((stat) => stat.influencer_user_id === row.influencer_user_id) ?? null
                        );
                        setSelectedUserId(row.user_id);
                        setIsInfluencerUserModalOpen(true);
                    }}
                    onInfluencerAddedToCampaign={refreshCampaignsForInfluencerUser}
                />

                <InstagramInfluencerUserModal
                    isModalOpen={isInfluencerUserModalOpen}
                    closeModal={(influencerUser) => {
                        const tagsAdded = !influencerUser?.content_tags.every((tagId) =>
                            selectedMonitoredUserStats?.content_tags.includes(tagId)
                        );

                        const tagsRemoved = !selectedMonitoredUserStats?.content_tags.every((tagId) =>
                            influencerUser?.content_tags?.includes(tagId)
                        );
                        if (tagsAdded || tagsRemoved) {
                            refresh();
                        }

                        setSelectedUserId(null);
                        setSelectedMonitoredUserStats(null);
                        setIsInfluencerUserModalOpen(false);
                    }}
                    userId={selectedUserId}
                    influencerId={selectedMonitoredUserStats?.influencer_user_id}
                    monitoredUserStats={selectedMonitoredUserStats}
                    profileImage={userImages?.find((i) => i.user_id === selectedUserId)}
                    plans={influencerPlansData.data?.results.filter((plan) => {
                        const campaigns = influencerUserCampaigns.find(
                            (c) => c.influencer_id === selectedMonitoredUserStats?.influencer_user_id
                        );

                        return !!campaigns?.plans.find((p) => p.plan_id === plan.id);
                    })}
                />
            </AddInfluencerToCampaignOptionsProvider>
        </div>
    );
};

export default InstagramInfluencerExplore;

export function useInstagramInfluencerTableData({
    search,
    location_tags,
    page,
    page_size,
    min_follower_count,
    max_follower_count,
    min_reels_cost: minCost,
    max_reels_cost: maxCost,
    min_avg_video_plays: minAvgViews,
    max_avg_video_plays: maxAvgViews,
    order_by_desc,
    order_by_asc,
    only_apex,
    content_tags,
}: Parameters<typeof getInstagramInfluencerUserStats>[0]) {
    const [stats, setStats] = useState<InstagramInfluencerUserStats[]>([]);
    const [userImages, setUserImages] = useState<InstagramUserImage[]>();
    const [statsLoading, setStatsLoading] = useState(false);
    const [errorLoadingStats, setErrorLoadingStats] = useState(false);
    const [count, setCount] = useState<number>(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);

    const fetchStats = useCallback(async (...args: Parameters<typeof getInstagramInfluencerUserStats>) => {
        const [params, requestInit] = args;
        try {
            setErrorLoadingStats(false);
            setStatsLoading(true);
            const { count, results, next, previous } = (
                await getInstagramInfluencerUserStats(params, requestInit)
            ).data;
            const userIds = results.map((r) => r.user_id).filter((id): id is number => typeof id === 'number');
            const images = await fetchInstagramUserImages(userIds);
            setUserImages(images);
            setStats(results);
            setCount(count);
            setHasNextPage(Boolean(next));
            setHasPrevPage(Boolean(previous));
        } catch {
            setErrorLoadingStats(true);
        } finally {
            setStatsLoading(false);
        }
    }, []);

    const fetchStatsDebounce = useMemo(() => debounce(fetchStats, 700), [fetchStats]);
    useAbortableEffect(
        (signal) => {
            fetchStatsDebounce(
                {
                    page,
                    page_size,
                    search,
                    location_tags,
                    min_follower_count,
                    max_follower_count,
                    min_avg_video_plays: minAvgViews,
                    max_avg_video_plays: maxAvgViews,
                    min_reels_cost: minCost,
                    max_reels_cost: maxCost,
                    order_by_asc,
                    order_by_desc,
                    only_apex,
                    content_tags,
                },
                { signal }
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            search,
            page,
            page_size,
            location_tags,
            min_follower_count,
            max_follower_count,
            minAvgViews,
            maxAvgViews,
            minCost,
            maxCost,
            order_by_asc,
            order_by_desc,
            only_apex,
            fetchStatsDebounce,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            JSON.stringify(content_tags),
        ]
    );

    return {
        stats,
        userImages,
        statsLoading,
        errorLoadingStats,
        count,
        hasNextPage,
        hasPrevPage,
        refresh: () =>
            fetchStats({
                page,
                page_size,
                search,
                location_tags,
                min_follower_count,
                max_follower_count,
                min_avg_video_plays: minAvgViews,
                max_avg_video_plays: maxAvgViews,
                min_reels_cost: minCost,
                max_reels_cost: maxCost,
                order_by_asc,
                order_by_desc,
                content_tags,
            }),
    };
}
