import { Props as BaseProps, BaseCampaign } from './CampaignsTable';
import DragAndDropTable, { DragTableProps } from 'ui-new/whitelabel/Table/DragAndDropTable/DragAndDropTable';
import styles from './CampaignsTable.module.css';
import { ScrollView } from '@round/ui-kit';
import cn from 'classnames';
import { useEffect, useState } from 'react';

type Props<TRow extends BaseCampaign> = Omit<BaseProps<TRow>, 'onDragEnd'> & DragTableProps;

const DragAndDropCampaignsTable = <TRow extends BaseCampaign>({
    data,
    isLoading,
    hasError,
    className,
    onDragEnd,
    ...props
}: Props<TRow>) => {
    const emptyRows = new Array(10).fill({}) as TRow[];
    const rows = isLoading ? data.concat(emptyRows) : data;

    // The drag and drop library "Droppable" container does not support having multiple scroll parents.
    // When it detects it, the library disables scroll-dragging. This happens before it's onDragStart handler is called,
    // so we are adding a layer here to hook into the native pointer event that precedes it.
    // Since we don't need the table to scroll horizontally when the user is dragging, we can just disable the x-scroll.
    const [isDragActive, setIsDragActive] = useState(false);
    useEffect(() => {
        window.addEventListener('pointerdown', (e) => {
            const dragHandle = document.querySelector('[data-drag-handle="true"]');

            if (e.target instanceof Element && (e.target.contains(dragHandle) || e.target === dragHandle)) {
                setIsDragActive(true);
            }
        });

        window.addEventListener('pointerup', () => {
            setIsDragActive(false);
        });
    }, []);

    return (
        <ScrollView className={cn({ [styles.overflowClip]: isDragActive })}>
            <DragAndDropTable
                data={rows}
                enableExpanding
                manualExpanding
                className={cn(styles.table, className, { [styles.empty]: !data.length && !isLoading })}
                getRowClassName={(row) => cn({ [styles.expandedRow]: row.getIsExpanded() })}
                getRowId={(original, index) => original.id?.toString() ?? index.toString()}
                noDataLabel={
                    isLoading ? undefined : (
                        <div className={styles.noDataContainer} aria-label="no campaigns">
                            {hasError ? 'Could not load campaigns' : 'No campaigns yet'}
                        </div>
                    )
                }
                onDragEnd={onDragEnd}
                {...props}
            />
        </ScrollView>
    );
};

export default DragAndDropCampaignsTable;
