import { AppServer } from '../mirage';
import * as influencerCostMirage from './influencerCost/influencerCost.mirage';
import * as clientMirage from './client/client.mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { ApexClient, ApexClientUser, ApexCreatorAgency, ApexManager, CampaignBooking } from './apex.types';
import { createFactory, makeSerializer } from '../mirage.helpers';
import * as financeMirage from './finance/finance.mirage';
import * as adminMirage from './admin/admin.mirage';
import * as campaignManagerMirage from './campaignManager/campaignManager.mirage';

export const models = {
    campaignBooking: Model as ModelDefinition<CampaignBooking>,
    apexCreatorAgency: Model as ModelDefinition<ApexCreatorAgency>,
    apexClient: Model as ModelDefinition<ApexClient>,
    apexClientUser: Model as ModelDefinition<ApexClientUser>,
    apexManager: Model as ModelDefinition<ApexManager>,
    ...influencerCostMirage.models,
    ...clientMirage.models,
    ...financeMirage.models,
    ...adminMirage.models,
    ...campaignManagerMirage.models,
};

export const serializers = {
    campaignBooking: makeSerializer<CampaignBooking>([]),
    apexCreatorAgency: makeSerializer<ApexCreatorAgency>([]),
    apexClient: makeSerializer<ApexClient>([]),
    apexClientUser: makeSerializer<ApexClientUser>([]),
    apexManager: makeSerializer<ApexManager>([]),
    ...influencerCostMirage.serializers,
    ...clientMirage.serializers,
    ...financeMirage.serializers,
    ...adminMirage.serializers,
    ...campaignManagerMirage.serializers,
};

export const factories = {
    campaignBooking: createFactory<CampaignBooking>({
        brief: null,
        campaign_id(index: number) {
            return index;
        },
        status: 'offer_sent',
        cost: '100',
        finance_reference(index: number) {
            return `finance reference ${index}`;
        },
        platform: 'INSTAGRAM',
        post_url(index: number) {
            return `post url ${index}`;
        },
        tiktok_ad_code(index: number) {
            return `tiktok ad code ${index}`;
        },
        influencer_id(index: number) {
            return index;
        },
    }),
    apexCreatorAgency: createFactory<ApexCreatorAgency>({
        name(index: number) {
            return `Creator Agency ${index}`;
        },
    }),
    apexClient: createFactory<ApexClient>({
        name: (index: number) => `apex client name ${index}`,
    }),
    apexClientUser: createFactory<ApexClientUser>({
        client_name: (index: number) => `client name ${index}`,
        email: (index: number) => `email ${index}`,
    }),
    apexManager: createFactory<ApexManager>({
        username: (index: number) => `username${index}`,
    }),
    ...influencerCostMirage.factories,
    ...clientMirage.factories,
    ...financeMirage.factories,
    ...adminMirage.factories,
    ...campaignManagerMirage.factories,
};

export function handleApexRequests(server: AppServer) {
    influencerCostMirage.handleInfluencerCostRequests(server);
    clientMirage.handleClientRequests(server);
    financeMirage.handleRequests(server);
    adminMirage.handleRequests(server);
    campaignManagerMirage.handleRequests(server);
}
