import { Dispatch, ReactNode, useReducer } from 'react';
import { reducer, initialState, State, Actions } from './reducer';
import { createSelectableContext } from 'Hooks/useNonNullContextSelector';

export const PublicReportContext = createSelectableContext<[State, Dispatch<Actions>] | null>(null);

type Props = { children?: ReactNode };
export const PublicReportProvider = ({ children }: Props) => {
    return (
        <PublicReportContext.Provider value={useReducer(reducer, initialState)}>
            {children}
        </PublicReportContext.Provider>
    );
};
