import React, { useCallback, useEffect, useState } from 'react';
import { GenericDropdownOption } from '../../../../../../../App.types';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../../../ui/General/Modal/Modal';
import Label from '../../../../../../../ui/DataEntry/Label/Label';
import TargetingTemplateSelectOption from './TargetingTemplateSelectOption/TargetingTemplateSelectOption';
import Select, { StylesConfig } from 'react-select';
import styles from './CreateTargetingModal.module.css';
import Button from '../../../../../../../ui/Buttons/Button/Button';

export type CreateTargetingModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    title: string;
    templateOptions: GenericDropdownOption<number>[];
    templateOptionsLoading?: boolean;
    templateOptionsLoadingError?: boolean;
    createFromTemplate: (template: GenericDropdownOption<number>) => Promise<void>;
    addNewTargeting: () => Promise<void>;
    addNewTemplate: () => Promise<void>;
    editTargetingTemplate: (template: GenericDropdownOption<number>) => void | Promise<void>;
};

const selectStyles: StylesConfig = {
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 1000,
    }),
};

const CreateTargetingModal = ({
    isModalOpen,
    closeModal,
    editTargetingTemplate,
    createFromTemplate,
    addNewTargeting,
    templateOptions,
    templateOptionsLoading,
    templateOptionsLoadingError,
    title,
    addNewTemplate,
}: CreateTargetingModalProps) => {
    const [selectedOption, setSelectedOption] = useState<GenericDropdownOption<number> | null | undefined>();
    const [createFromTemplateLoading, setCreateFromTemplateLoading] = useState(false);

    const handleCreateFromTemplateClick = useCallback(async () => {
        setCreateFromTemplateLoading(true);
        await createFromTemplate(selectedOption!);
        setCreateFromTemplateLoading(false);
    }, [createFromTemplate, selectedOption]);

    useEffect(() => {
        if (!isModalOpen) {
            setSelectedOption(undefined);
        }
    }, [isModalOpen]);

    return (
        <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={closeModal}>
            <ModalTitle>{title}</ModalTitle>
            <ModalContent>
                <div>
                    <Label htmlFor="">Template</Label>
                    <Select
                        menuPortalTarget={document.body}
                        styles={selectStyles}
                        value={selectedOption}
                        options={templateOptions}
                        onChange={(value) => setSelectedOption(value)}
                        isLoading={templateOptionsLoading}
                        noOptionsMessage={() =>
                            templateOptionsLoadingError ? 'We are experiencing technical issues' : 'No Options'
                        }
                        components={{
                            Option: (props) => (
                                <TargetingTemplateSelectOption {...props} onEdit={editTargetingTemplate}>
                                    {props.children}
                                </TargetingTemplateSelectOption>
                            ),
                        }}
                    />
                </div>

                <div className={styles.buttonsRow}>
                    <Button className={styles.button} type="link" onClick={addNewTargeting}>
                        + Add new targeting
                    </Button>
                    <Button className={styles.button} type="link" onClick={addNewTemplate}>
                        + Add new template
                    </Button>
                </div>
            </ModalContent>

            <ModalFooter>
                <Button
                    className={styles.createFromTemplateButton}
                    type="filled"
                    color="black"
                    loading={createFromTemplateLoading}
                    onClick={handleCreateFromTemplateClick}
                    disabled={!selectedOption}
                >
                    Create from template
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CreateTargetingModal;
