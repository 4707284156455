import { components, OptionProps } from 'react-select';
import { CustomAudienceOption } from '../../../../../../../types/Facebook.types';
import CustomAudienceRow from './CustomAudienceRow';
import React from 'react';

const Option = (props: OptionProps<CustomAudienceOption, true>) => {
    const data = props.data as CustomAudienceOption;
    return (
        <>
            <components.Option {...props}>
                <CustomAudienceRow data={data} />
            </components.Option>
        </>
    );
};

export default Option;
