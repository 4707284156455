import { Creative } from '../../../../App.types';
import { fetchAll, fetchWithToken } from '../../../../helpers';
import { CreativeData } from '../types/Creative.types';

export async function getCreatives(mediaPlanItemIds: number[]): Promise<Creative[]> {
    if (!mediaPlanItemIds.length) {
        return [];
    }

    return (
        (await fetchAll(
            `/api/advertising/viewsets/media-plan-item-asset/?media_plan_item_id=${mediaPlanItemIds.join()}`
        )) ?? []
    );
}

export async function postCreative(creative: CreativeData): Promise<Creative> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item-asset/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(creative),
    });

    if (!response.ok) {
        throw new Error('Could not POST creative');
    }

    return response.json();
}

export async function patchCreative(id: number, creative: Partial<CreativeData>): Promise<Creative> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item-asset/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(creative),
    });

    if (!response.ok) {
        throw new Error('Could not POST creative');
    }

    return response.json();
}

export async function deleteCreative(id: number) {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item-asset/${id}/`, {
        method: 'DELETE',
    });

    if (!response.ok) {
        throw new Error(`Could not delete creative: ${id}`);
    }
}
