import React, { useMemo, useState } from 'react';
import styles from './LineChart.module.css';
import { ChartProps, Line } from 'react-chartjs-2';
import ReactDOM from 'react-dom';
import LoadingSpinner from '../../../../SharedComponents/LoadingSpinner/LoadingSpinner';

export type Point = { x: string; y: number };
export type LineChartProps = {
    points: Point[];
    loading?: boolean;
    height?: number;
    beginAtZero?: boolean;
    renderTooltip?: (item: Point) => React.ReactNode;
};

const TOOLTIP_WIDTH_IN_PX = 120;
/**
 * Get the exponent of a number. Exponent of 0 is -Infinity
 * @param n
 */
const getExponent = (n: number) => Math.floor(Math.log10(Math.abs(n)));
const formatYAxisLabels = (n: number, fractionDigits: number = 0) => {
    const exponent = getExponent(n);
    const format = (n: number) => {
        return parseFloat((n / Math.pow(10, exponent - (exponent % 3))).toFixed(fractionDigits));
    };

    const sliceRules = [
        {
            threshold: 3,
            exponentSign: '',
            format: (n: number) => n,
        },
        {
            threshold: 6,
            exponentSign: 'K',
            format,
        },
        {
            threshold: 9,
            exponentSign: 'M',
            format,
        },
        {
            threshold: Infinity,
            exponentSign: 'B',
            format,
        },
    ];

    const rule = sliceRules.find((r) => r.threshold > exponent);
    if (!rule) {
        return n.toString();
    }

    return `${rule.format(n)}${rule.exponentSign}`;
};

const LineChart = ({ points, height = 100, renderTooltip, beginAtZero = false, loading }: LineChartProps) => {
    const [tooltipParams, setTooltipParams] = useState<{ x: number; y: number; transformX: number } | null>(null);
    const [tooltipItem, setTooltipItem] = useState<Point | null>(null);
    const renderDefaultTooltip = () => tooltipItem && <div className={styles.tooltipContent}>{tooltipItem.y}</div>;
    const tooltip = renderTooltip ?? renderDefaultTooltip;

    const data: ChartProps<'line', Point[]>['data'] = {
        datasets: [
            {
                data: points,
                borderColor: '#7A61DF',
                fill: true,
                backgroundColor: (ctx) => {
                    const canvas = ctx.chart.canvas;
                    const gradient = canvas.getContext('2d')?.createLinearGradient(0, height * 4, 0, 0);
                    gradient?.addColorStop(0, '#AE46FF00');
                    gradient?.addColorStop(0.65, '#C338F420');
                    gradient?.addColorStop(1, '#3C38F420');
                    return gradient;
                },
                pointBackgroundColor: '#7A61DF',
                pointBorderColor: 'white',
                pointBorderWidth: 0,
                pointHoverBorderWidth: 3,
                pointHoverBorderColor: 'white',
                tension: 0.3,
            },
        ],
    };

    const options = useMemo(() => {
        const yValues = points.map((point) => point.y);
        const maxValue = Math.max(...yValues);
        const minValue = Math.min(...yValues);
        const minMaxDiff = maxValue - minValue;
        const stepSize =
            isFinite(minMaxDiff) && minMaxDiff >= 0 ? undefined : Math.pow(10, getExponent(yValues[0]) - 1);

        return {
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    position: 'nearest',
                    external({ tooltip, chart }) {
                        if (tooltip.opacity === 0) {
                            setTooltipParams(null);
                            setTooltipItem(null);
                        } else {
                            const position = chart.canvas.getBoundingClientRect();
                            const tooltipHalfWidth = TOOLTIP_WIDTH_IN_PX / 2;
                            let left = position.left + tooltip.caretX;
                            let transform = -50;

                            if (tooltip.caretX <= tooltipHalfWidth) {
                                transform = 0;
                            } else if (tooltipHalfWidth >= position.right - (tooltip.caretX + position.left)) {
                                transform = -100;
                            }

                            setTooltipParams({
                                x: left,
                                y: position.top + tooltip.caretY,
                                transformX: transform,
                            });

                            if (Array.isArray(tooltip.dataPoints) && tooltip.dataPoints.length) {
                                const point = tooltip.dataPoints[0];
                                const item = points[point.dataIndex];
                                setTooltipItem(item ?? null);
                            }
                        }
                    },
                },
            },
            elements: {
                point: {
                    radius: 6,
                    hoverRadius: 7,
                },
            },
            scales: {
                y: {
                    beginAtZero,
                    ticks: {
                        color: '#90A0B7',
                        count: 5,
                        stepSize,
                        callback: (value: number) => {
                            if (new Set(yValues).size < 2) {
                                return formatYAxisLabels(value, 1);
                            }
                            const minMaxDifferenceExponent = getExponent(minMaxDiff);
                            const maxExponent = getExponent(maxValue);
                            const fractionDigits = maxExponent - minMaxDifferenceExponent;
                            const labelsFD = fractionDigits > 0 ? (fractionDigits < 100 ? fractionDigits : 99) : 0;
                            return formatYAxisLabels(value, labelsFD);
                        },
                        precision: 0,
                    },
                    grid: {
                        display: true,
                        color: '#90A0B7',
                    },
                    border: {
                        display: false,
                        color: '#90A0B7',
                        dash: [5, 5],
                    },
                },
                x: {
                    type: 'time',
                    ticks: {
                        color: '#90A0B7',
                        autoSkip: true,
                        count: 30,
                    },
                    time: {
                        unit: 'day',
                        displayFormats: {
                            day: 'MMM D',
                        },
                    },
                    grid: {
                        display: false,
                        color: '#90A0B7',
                    },
                },
            },
        } as ChartProps<'line', Point[]>['options'];
    }, [beginAtZero, points]);

    const plugins: ChartProps<'line', Point[]>['plugins'] = [
        {
            id: 'dash_line',
            beforeDraw: (instance) => {
                const activeElements = instance.tooltip?.getActiveElements();
                if (activeElements?.length) {
                    const ctx: CanvasRenderingContext2D = instance.ctx;
                    ctx.save();
                    const activePoint = activeElements[0].element;
                    const area = instance.chartArea;
                    ctx.beginPath();
                    ctx.setLineDash([5, 5]);
                    ctx.moveTo(activePoint.x, area.bottom);
                    ctx.lineTo(activePoint.x, activePoint.y);
                    ctx.strokeStyle = '#192A3E';
                    ctx.lineWidth = 1.2;
                    ctx.stroke();
                }
            },
        },
    ];

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <Line height={height} data={data} options={options} plugins={plugins} />
            {tooltipParams &&
                tooltipItem &&
                ReactDOM.createPortal(
                    <div
                        className={styles.tooltip}
                        style={{
                            left: tooltipParams.x + 'px',
                            top: tooltipParams.y + 'px',
                            transform: `translate(${tooltipParams.transformX}%, -115%)`,
                            minWidth: TOOLTIP_WIDTH_IN_PX,
                        }}
                    >
                        {tooltip(tooltipItem)}
                    </div>,
                    document.body
                )}
        </>
    );
};

export default LineChart;
