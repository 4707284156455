import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { ReleaseType } from './releaseType.types';

export const models = {
    releaseType: Model as ModelDefinition<ReleaseType>,
};

export const serializers = {
    releaseType: makeSerializer<ReleaseType>([]),
};

export const factories = {
    releastType: createFactory<ReleaseType>({
        id(index: number) {
            return index;
        },
        name(index: number) {
            return `name ${index}`;
        },
        code(index: number) {
            return `code ${index}`;
        },
        default: Math.random() > 0.5 ? true : false,
    }),
};

export function handleReleaseTypeRequests(server: AppServer) {
    server.get('/api/advertising/viewsets/release-type/', function (this: RouteHandlerContext, schema, request) {
        const releaseTypes = schema.all('releaseType');

        return buildPaginatedResponse(releaseTypes, {
            url: '/api/advertising/viewsets/release-type/',
            serialize: (resource) => this.serialize(resource, 'releaseType'),
            queryParams: request.queryParams,
        });
    });
}
