import { SetStateAction, useCallback, useState } from 'react';

export type DataStateSuccess<T> = {
    status: 'success';
    data: T;
    error: null;
};

export type DataStateLoading<T> = {
    status: 'loading';
    data: T | null;
    error: null;
};

export type DataStateError<T, E = void> = {
    status: 'error';
    data: T | null;
    error: E extends void ? string : E;
};

export type DataStateIdle<T> = {
    status: 'idle';
    data: T | null;
    error: null;
};

export type DataState<Data, Error = void> =
    | DataStateSuccess<Data>
    | DataStateLoading<Data>
    | DataStateError<Data, Error>
    | DataStateIdle<Data>;

type GetStatusPayload<
    TStatus extends DataState<T, E>['status'],
    T = any,
    E = void
> = TStatus extends DataStateSuccess<T>['status']
    ? { data: DataStateSuccess<T>['data'] }
    : TStatus extends DataStateError<T, E>['status']
    ? { data?: DataStateError<T, E>['data']; error: DataStateError<T, E>['error'] }
    : TStatus extends DataStateIdle<T>['status']
    ? { data?: DataStateIdle<T>['data'] }
    : never;

const nullState = { data: null, error: null, status: 'idle' };

/**
 * A wrapper around `useState` paired with `DataState`.
 * @param initialState
 * @example
 * const [state, setState] = useDataState<string>('Hello, World!');
 * setState('loading');
 * // sets error to null
 * setState('success', { data: 'Set another string' | null });
 * // when leaving optional values it will keep the previous value
 * setState('error', { error: 'some error', data?: 'some string' | null });
 * // sets error to null
 * setState('idle', { data?: 'string' | null });
 */
export function useDataState<T, E = void>(
    initialState: DataState<T, E> = nullState as DataState<T, E>
): [
    DataState<T, E>,
    <TStatus extends DataState<T, E>['status']>(
        status: TStatus,
        data?: SetStateAction<GetStatusPayload<TStatus, T, E>>
    ) => void
] {
    const [state, setState] = useState<DataState<T, E>>(initialState);

    const set = useCallback(
        <TStatus extends DataState<T, E>['status']>(
            status: TStatus,
            data?: SetStateAction<GetStatusPayload<TStatus, T, E>>
        ) => {
            setState((prev) => {
                if (status === 'loading') {
                    return { ...prev, status: 'loading' } as DataState<T, E>;
                }

                if (status === 'error') {
                    // Seems like you can't narrow type parameters
                    const payload = data as GetStatusPayload<'error', T, E>;
                    return {
                        status: 'error',
                        error: payload.error,
                        data: payload.data === undefined ? prev.data : payload.data,
                    } as DataState<T, E>;
                }

                if (status === 'idle') {
                    const payload = data as GetStatusPayload<'idle', T, E>;
                    return {
                        status: 'idle',
                        error: null,
                        data: payload.data === undefined ? prev.data : payload.data,
                    } as DataState<T, E>;
                }

                const payload = data as GetStatusPayload<'success', T, E>;
                if (typeof data === 'function') {
                    return ({
                        ...data({ data: prev.data, error: prev.error } as GetStatusPayload<TStatus, T, E>),
                        status: 'success',
                    } as unknown) as DataState<T, E>;
                }
                return {
                    status: 'success',
                    error: null,
                    data: payload.data,
                } as DataState<T, E>;
            });
        },
        []
    );

    return [state, set];
}
