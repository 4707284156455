import styles from './RunDates.module.css';
import { ReactComponent as ArrowIcon } from 'assets/whitelabel/ArrowRight.svg';

type Props = {
    startDate: string;
    endDate: string;
};

const RunDates = ({ startDate, endDate }: Props) => {
    if (!startDate && !endDate) {
        return <>-</>;
    }

    return (
        <div className={styles.container}>
            <span>{startDate}</span>
            <ArrowIcon className={styles.icon} />
            <span>{endDate}</span>
        </div>
    );
};

export default RunDates;
