import { Image } from '@round/ui-kit';
import styles from './SongBadge.module.css';
import cn from 'classnames';

type Props = {
    title: string;
    artistName?: string;
    imageUrl?: string;
    direction?: 'row' | 'column';
};

const SongBadge = ({ title, artistName, imageUrl, direction = 'row' }: Props) => {
    return (
        <div className={cn(styles.container, { [styles[direction]]: !!direction })}>
            <Image src={imageUrl ?? undefined} alt={title} className={cn(styles.image)} />
            <div className={cn(styles.names)}>
                <div className={styles.title}>{title}</div>
                {!!artistName && <div className={styles.artistName}>{artistName}</div>}
            </div>
        </div>
    );
};

export default SongBadge;
