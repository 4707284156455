import { InfluencerPostStatus } from '../../../InfluencerPlan.types';
import { Release } from '@round/api';
import React, { CSSProperties, useMemo } from 'react';
import { Column } from 'react-table';
import { StatusLabels } from '../../../InfluencerPlan.helpers';
import { formatDateShort, numberWithCommas, roundTo2Dp } from '../../../../../../helpers';
import { isFinite } from 'lodash';
import ClipboardTable from 'utility/copyToClipboard/ClipboardTable/ClipboardTable';

type YoutubeReportClipboardTableRow = {
    channel: string;
    status: InfluencerPostStatus;
    postViews: number | undefined | null;
    likes: number | undefined | null;
    comments: number | undefined | null;
    link: string;
    engagementRate: number | undefined | null;
    videoUploadedDate: string | undefined | null;
};

type Totals = Pick<YoutubeReportClipboardTableRow, 'postViews' | 'likes' | 'comments' | 'engagementRate'>;

export type YoutubeReportClipboardTableProps = {
    items: YoutubeReportClipboardTableRow[];
    totals?: Totals;
    release: Release;
    groupName: string;
};

const tfootStyles: CSSProperties = {
    backgroundColor: '#eeeeee',
};

const tdStyles: CSSProperties = {
    border: '1px solid black',
};

const totalsCellStyles: CSSProperties = {
    fontWeight: 'bold',
    padding: '5px',
};

const YoutubeReportClipboardTable = ({ items, release, totals, groupName }: YoutubeReportClipboardTableProps) => {
    const columns = useMemo<Column<YoutubeReportClipboardTableRow>[]>(
        () => [
            {
                Header: () => <div style={{ textAlign: 'left' }}>{groupName}</div>,
                accessor: 'channel',
                Cell: ({ value }) => <>{value ?? '-'}</>,
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value }) => <>{StatusLabels[value]}</>,
            },
            {
                Header: 'Video Uploaded',
                accessor: 'videoUploadedDate',
                Cell: ({ value }) => <>{typeof value === 'string' ? formatDateShort(value) : '-'}</>,
            },
            {
                Header: 'Post Views',
                accessor: 'postViews',
                Cell: ({ value }) => (
                    <ClipboardTable.Stats>
                        {typeof value === 'number' ? numberWithCommas(value) : '-'}
                    </ClipboardTable.Stats>
                ),
            },
            {
                Header: 'Likes',
                accessor: 'likes',
                Cell: ({ value }) => (
                    <ClipboardTable.Stats>
                        {typeof value === 'number' ? numberWithCommas(value) : '-'}
                    </ClipboardTable.Stats>
                ),
            },
            {
                Header: 'Comments',
                accessor: 'comments',
                Cell: ({ value }) => (
                    <ClipboardTable.Stats>
                        {typeof value === 'number' ? numberWithCommas(value) : '-'}
                    </ClipboardTable.Stats>
                ),
            },
            {
                Header: 'Engagement Rate',
                accessor: 'engagementRate',
                Cell: ({ value }) => (
                    <ClipboardTable.Stats>
                        {typeof value === 'number' && isFinite(value) ? `${roundTo2Dp(value * 100)}%` : '-'}
                    </ClipboardTable.Stats>
                ),
            },
            {
                Header: 'Post Link',
                accessor: 'link',
                Cell: ({ value }) => {
                    if (!value) {
                        return null;
                    }

                    return <ClipboardTable.Link href={value}>Post Link</ClipboardTable.Link>;
                },
            },
        ],
        [groupName]
    );

    const caption = `${release.brand.name} - ${release.name}`;

    return (
        <ClipboardTable
            columns={columns}
            data={items}
            caption={caption}
            renderFooter={() => {
                if (!totals) {
                    return null;
                }

                return (
                    <tfoot style={tfootStyles}>
                        <tr>
                            <td colSpan={4} style={totalsCellStyles}>
                                Total
                            </td>
                            <td style={tdStyles}>
                                <ClipboardTable.Stats>
                                    {typeof totals.postViews === 'number' ? numberWithCommas(totals.postViews) : '-'}
                                </ClipboardTable.Stats>
                            </td>
                            <td style={tdStyles}>
                                <ClipboardTable.Stats>
                                    {typeof totals.likes === 'number' ? numberWithCommas(totals.likes) : '-'}
                                </ClipboardTable.Stats>
                            </td>
                            <td style={tdStyles}>
                                <ClipboardTable.Stats>
                                    {typeof totals.comments === 'number' ? numberWithCommas(totals.comments) : '-'}
                                </ClipboardTable.Stats>
                            </td>
                            <td style={tdStyles}>
                                <ClipboardTable.Stats>
                                    {typeof totals.engagementRate === 'number' && isFinite(totals.engagementRate)
                                        ? `${roundTo2Dp(totals.engagementRate * 100)}%`
                                        : '-'}
                                </ClipboardTable.Stats>
                            </td>
                        </tr>
                    </tfoot>
                );
            }}
        />
    );
};

export default YoutubeReportClipboardTable;
