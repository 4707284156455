import useAbortableEffect from 'Hooks/useAbortableEffect';
import useTiktokPostStats from './useTiktokTopPosts';
import { useState } from 'react';
import TiktokTopPostsTable, { TiktokTopPostsTableRow } from './TiktokTopPostsTable/TiktokTopPostsTable';
import styles from './TiktokTopPosts.module.css';
import { creatorbase, OrderingValues } from '@round/api';

const TiktokTopPosts = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [ordering, setOrdering] = useState<OrderingValues<creatorbase.PostStatsSortableKeys>>('-play_count');

    const { data, status, fetchData, reset } = useTiktokPostStats();
    const isInitialized = status === 'success' || status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                fetchData(page, pageSize, ordering, signal).catch(() => {});
            }
        },
        [fetchData, isInitialized, page, pageSize, ordering]
    );

    const rows: TiktokTopPostsTableRow[] =
        data?.postsStats.results?.map((post) => ({
            ...post,
            audio: data.audios.find((audio) => audio.id === post.audio_id) ?? null,
        })) ?? [];

    return (
        <section className={styles.container}>
            <TiktokTopPostsTable
                page={page}
                setPage={(page) => {
                    setPage(page);
                    reset();
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                    setPageSize(pageSize);
                    reset();
                }}
                ordering={ordering}
                onOrderingChange={(ordering) => {
                    setOrdering(ordering[0] || '-play_count');
                    reset();
                }}
                count={data?.postsStats.count ?? 0}
                data={rows ?? []}
                isLoading={!isInitialized}
                hasError={status === 'error'}
            />
        </section>
    );
};

export default TiktokTopPosts;
