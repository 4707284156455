import useAbortableEffect from 'Hooks/useAbortableEffect';
import useSongData from './useSongData';
import styles from './SongData.module.css';
import { Image, SkeletonizedValue } from '@round/ui-kit';
import MediaBadge from 'ui-new/whitelabel/MediaBadge/MediaBadge';

type Props = {
    songId: number | null | undefined;
};

const SongData = ({ songId }: Props) => {
    const { data, status, fetchData } = useSongData();
    const isInitialized = status === 'success' || status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && typeof songId === 'number') {
                fetchData(songId, { signal }).catch(() => {});
            }
        },
        [fetchData, isInitialized, songId]
    );

    return (
        <div className={styles.container}>
            <Image
                skeletonCircle={false}
                src={data?.musicSong?.image ?? ''}
                alt={data?.musicSong?.title || data?.song.title}
                className={styles.image}
                loading={!isInitialized}
            />

            <div className={styles.details}>
                <div>
                    <p className={styles.title}>
                        <SkeletonizedValue isInitialized={isInitialized} width="10rem">
                            {data?.musicSong?.title || data?.song.title}
                        </SkeletonizedValue>
                    </p>
                    <p className={styles.authorName}>
                        <SkeletonizedValue isInitialized={isInitialized} width="7rem">
                            {data?.musicSong?.artists.map((a) => a.name).join(', ') || data?.song.artist_name}
                        </SkeletonizedValue>
                    </p>
                </div>

                <div className={styles.badges}>
                    <MediaBadge type="song" />
                    {data?.song.status === 'released' && <MediaBadge type="released" />}
                </div>
            </div>
        </div>
    );
};

export default SongData;
