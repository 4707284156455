import { Dispatch, ReactNode, useReducer } from 'react';
import { reducer, initialState, State, Actions } from './reducer';
import { createSelectableContext } from 'Hooks/useNonNullContextSelector';

export const ProjectDetailsContext = createSelectableContext<[State, Dispatch<Actions>] | null>(null);

type Props = { children?: ReactNode };
export const ProjectDetailsProvider = ({ children }: Props) => {
    return (
        <ProjectDetailsContext.Provider value={useReducer(reducer, initialState)}>
            {children}
        </ProjectDetailsContext.Provider>
    );
};
