import { useState } from 'react';
import styles from './FollowerGrowthFilter.module.css';
import { showNotification } from 'helpers';
import useOnClickOutside from 'Hooks/useOnClickOutside';

type FollowerGrowthFilterValue = {
    percentage: number | undefined;
    interval: number | undefined;
};

type Props = {
    value: FollowerGrowthFilterValue;
    onChange: (value: FollowerGrowthFilterValue) => void;
};

const FollowerGrowthFilter = ({ value, onChange }: Props) => {
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
    const [isFocused, setIsFocused] = useState(false);

    const [percentage, setPercentage] = useState(value.percentage?.toString() ?? '');
    const [interval, setInterval] = useState(value.interval?.toString() ?? '');

    const submitChange = () => {
        const newInterval = interval ? parseInt(interval) : undefined;
        const newPercentage = percentage ? parseFloat(percentage) : undefined;

        if (newInterval === value.interval && newPercentage === value.percentage) {
            return;
        }

        if (!newInterval && !newPercentage) {
            onChange({ interval: undefined, percentage: undefined });
            return;
        }

        if (!newInterval || !newPercentage) {
            showNotification('Please, provide percentage and interval values', 'error');
            return;
        }

        if (newInterval < 1 || newInterval > 6) {
            showNotification('Interval must be within 6 months', 'error');
            return;
        }

        onChange({ interval: newInterval, percentage: newPercentage });
    };

    useOnClickOutside(containerRef, () => {
        if (isFocused) {
            submitChange();
            setIsFocused(false);
        }
    });

    return (
        <div
            ref={setContainerRef}
            onFocus={() => {
                setIsFocused(true);
            }}
            className={styles.container}
        >
            <input
                value={percentage}
                onChange={(e) => setPercentage(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        submitChange();
                    }
                }}
            />
            % in
            <input
                type="number"
                max="6"
                value={interval}
                onChange={(e) => setInterval(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        submitChange();
                    }
                }}
            />
            &nbsp;months
        </div>
    );
};

export default FollowerGrowthFilter;
