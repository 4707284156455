import { ApiResponse, OrderingParams, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import {
    SongCollection,
    SongCollectionApiModel,
    SongCollectionStats,
    SongCollectionTiktokAudioStats,
} from './songCollection.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import { SongTiktokStats } from '../song';

export async function getSongCollection(
    id: number,
    requestInit?: RequestInit
): Promise<ApiResponse<SongCollection, 200> | ApiResponse<{ detail: string }, 404>> {
    const response = await fetchWithToken(`/api/music/viewsets/song-collection/${id}/`, requestInit);

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not fetch song collection ${id}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getSongCollections(
    params: Partial<PaginatedRequest & { search: string }>,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<SongCollection>, 200>> {
    const response = await fetchWithToken(
        `/api/music/viewsets/song-collection/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not get song collections`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PostSongCollectionResponse = ApiResponse<SongCollection, 201>;
export async function postSongCollection(data: SongCollectionApiModel): Promise<PostSongCollectionResponse> {
    const response = await fetchWithToken('/api/music/viewsets/song-collection/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error('Could not post a song collection');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type PatchSongCollectionResponse = ApiResponse<{ detail: string }, 404> | ApiResponse<SongCollection, 200>;
export async function uploadSongCollectionImage(
    songCollectionId: number,
    image: File
): Promise<PatchSongCollectionResponse> {
    const formData = new FormData();
    formData.append('picture', image);
    const response = await fetchWithToken(`/api/music/viewsets/song-collection/${songCollectionId}/`, {
        method: 'PATCH',
        body: formData,
    });

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not upload an image for song collection ${songCollectionId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function patchSongCollection(
    songCollectionId: number,
    data: Partial<SongCollectionApiModel>
): Promise<PatchSongCollectionResponse> {
    const response = await fetchWithToken(`/api/music/viewsets/song-collection/${songCollectionId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not PATCH song collection ${songCollectionId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type SongCollectionSongStatsSortableKeys = keyof Pick<
    SongTiktokStats,
    | 'total_video_count'
    | 'total_video_count_daily_change'
    | 'total_video_count_daily_change_relative'
    | 'total_video_count_weekly_change'
    | 'total_video_count_weekly_change_relative'
>;

export type GetSongCollectionSongStatsParams = Partial<
    PaginatedRequest & OrderingParams<SongCollectionSongStatsSortableKeys> & { search: string }
>;

export async function getSongCollectionSongStats(
    songCollectionId: number,
    params: GetSongCollectionSongStatsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<SongTiktokStats>, 200>> {
    const response = await fetchWithToken(
        `/api/music/viewsets/song-collection/${songCollectionId}/song-stats/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not get song stats for song collection ${songCollectionId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type SongCollectionTiktokAudioStatsSortableKeys = keyof Pick<
    SongCollectionTiktokAudioStats,
    | 'video_count'
    | 'video_count_daily_change'
    | 'video_count_daily_change_relative'
    | 'video_count_weekly_change'
    | 'video_count_weekly_change_relative'
>;

export type GetSongCollectionTiktokAudioStatsParams = Partial<
    PaginatedRequest &
        OrderingParams<SongCollectionTiktokAudioStatsSortableKeys> & {
            search: string;
        }
>;

export async function getSongCollectionTiktokAudioStats(
    songCollectionId: number,
    params: GetSongCollectionTiktokAudioStatsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<SongCollectionTiktokAudioStats>, 200>> {
    const response = await fetchWithToken(
        `/api/music/viewsets/song-collection/${songCollectionId}/tiktok-audio-stats/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not get tiktok audio stats for song collection ${songCollectionId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getSongCollectionStats(
    songCollectionId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<SongCollectionStats, 200> | ApiResponse<{ detail: string }, 404>> {
    const response = await fetchWithToken(
        `/api/music/viewsets/song-collection/${songCollectionId}/stats/`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get stats for song collection ${songCollectionId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type SongCollectionSongsModifyResponse =
    | ApiResponse<null, 204>
    | ApiResponse<{ error: string }, 400>
    | ApiResponse<{ detail: string }, 404>;

export async function addSongToSongCollection(
    songCollectionId: number,
    songId: number
): Promise<SongCollectionSongsModifyResponse> {
    const response = await fetchWithToken(`/api/music/viewsets/song-collection/${songCollectionId}/add-song/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ song_id: songId }),
    });

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Couldn't add song ${songId} to song collection ${songCollectionId}`);
    }

    return {
        status: 204,
        data: null,
    };
}

export async function removeSongFromSongCollection(
    songCollectionId: number,
    songId: number
): Promise<SongCollectionSongsModifyResponse> {
    const response = await fetchWithToken(`/api/music/viewsets/song-collection/${songCollectionId}/remove-song/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ song_id: songId }),
    });

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Couldn't add song ${songId} to song collection ${songCollectionId}`);
    }

    return {
        status: 204,
        data: null,
    };
}
