import { AppServer, RouteHandlerContext } from '../../mirage';
import { belongsTo, hasMany, Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TikTokInfluencerUserStats, TiktokInfluencerUser } from './influencerUser.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { GetTiktokInfluencerUserStatsParams } from './influencerUser.api';
import { QueryParams } from '../../types';

export const models = {
    tiktokInfluencerUserStat: Model.extend({
        currency: belongsTo('currency'),
        tags: hasMany('influencerTag'),
    }) as ModelDefinition<TikTokInfluencerUserStats>,
    tiktokInfluencerUser: Model.extend({
        tags: hasMany('influencerTag'),
    }) as ModelDefinition<TiktokInfluencerUser>,
};

export const factories = {
    tiktokInfluencerUserStat: createFactory<TikTokInfluencerUserStats>({
        name(index: number) {
            return `Tiktok influencer user stats name: ${index}`;
        },
        user_id(index: number) {
            return index;
        },
        influencer_user_id(index: number) {
            return index;
        },
        avg_engagement_rate() {
            return 0.0;
        },
        avg_video_plays_per_post() {
            return 0;
        },
        client_cost() {
            return 0;
        },
        contact_details(index: number) {
            return `Tiktok influencer user stats contact: ${index}`;
        },
        follower_count() {
            return 0;
        },
        post_count_used_for_avg() {
            return 0;
        },
        last_used() {
            return new Date().toISOString();
        },
        username(index: number) {
            return `Tiktok influencer user stats username: ${index}`;
        },
        is_apex() {
            const rand = Math.random();
            return rand > 0.5;
        },
        estimated_cpm: null,
        afterCreate(stats, s) {
            const server: AppServer = s;
            if (!stats.currency) {
                stats.update({
                    currency: server.create('currency'),
                });
            }

            if (!Array.isArray(stats.tags)) {
                stats.update({
                    tags: [],
                });
            }
        },
    }),
    tiktokInfluencerUser: createFactory<TiktokInfluencerUser>({
        monitored_user(index: number) {
            return index;
        },
        username(index: number) {
            return `username: ${index}`;
        },
        notes(index: number) {
            return `notes: ${index}`;
        },
        user(index: number) {
            return index * 10;
        },
        brand_promotion_cost(index: number) {
            return index.toFixed(2);
        },
        brand_promotion_currency(index: number) {
            return index;
        },
        is_apex() {
            const rand = Math.random();
            return rand > 0.5;
        },
        afterCreate(user, s) {
            const server = s as AppServer;
            if (!user.tags) {
                user.update({
                    tags: server.createList('influencerTag', 3),
                });
            }
        },
    }),
};

export const serializers = {
    tiktokInfluencerUserStat: makeSerializer<TikTokInfluencerUserStats>(['currency', 'tags']),
    tiktokInfluencerUser: makeSerializer<TiktokInfluencerUser>(['tags']),
};

export function handleInfluencerUserRequests(server: AppServer) {
    const getTiktokInfluencerUserStatsListPath = '/api/tiktok/viewsets/influencer-user-stats/';
    server.get(getTiktokInfluencerUserStatsListPath, function (this: RouteHandlerContext, schema, request) {
        const {
            max_avg_video_plays_per_post,
            min_avg_video_plays_per_post,
            min_client_cost,
            max_client_cost,
            max_follower_count,
            min_follower_count,
            search,
        } = request.queryParams as QueryParams<GetTiktokInfluencerUserStatsParams>;

        const stats = schema
            .all('tiktokInfluencerUserStat')
            .filter(
                (s) =>
                    !max_avg_video_plays_per_post ||
                    Number(s.avg_video_plays_per_post) <= Number(max_avg_video_plays_per_post)
            )
            .filter(
                (s) =>
                    !min_avg_video_plays_per_post ||
                    Number(s.avg_video_plays_per_post) >= Number(min_avg_video_plays_per_post)
            )
            .filter((s) => !min_client_cost || Number(s.client_cost) >= Number(min_client_cost))
            .filter((s) => !max_client_cost || Number(s.client_cost) <= Number(max_client_cost))
            .filter((s) => !min_follower_count || Number(s.follower_count) >= Number(min_follower_count))
            .filter((s) => !max_follower_count || Number(s.follower_count) <= Number(max_follower_count))
            .filter((s) => !search || s.username.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(stats, {
            url: getTiktokInfluencerUserStatsListPath,
            serialize: (resource) => this.serialize(resource, 'tiktokInfluencerUserStat'),
            queryParams: request.queryParams,
        });
    });
}
