import { useMemo } from 'react';
import {
    getTotalEngagements,
    postInactiveStatuses,
    sortTiktokActivePosts,
    statusOrder,
} from '../InfluencerPlan.helpers';
import useNonNullContext from '../../../../Hooks/useNonNullContext';
import { TiktokCreatorsContext } from '../contexts/TiktokCreatorsContext/TiktokCreatorsContext';
import { TiktokUserStats, TiktokInfluencerPost } from '@round/api';
import { TiktokInfluencerPostResult } from '../../../../App.types';
import { TiktokTotalsByGroup } from '../InfluencerPlan.types';

const sum = (prev: number, next: number) => prev + next;
const getValue = (key: string, tiktokPostResults: any[]) => tiktokPostResults.map((val) => val[key]);

type UseTiktokTableDataHelperReturn = {
    sortedActiveInfluencerPosts: TiktokInfluencerPost[];
    sortedBackupInfluencerPosts: TiktokInfluencerPost[];
    totalsByGroup: TiktokTotalsByGroup;
};

export function useTiktokTableDataHelper(): UseTiktokTableDataHelperReturn {
    const {
        tiktokInfluencerPostGroups,
        tiktokInfluencerPosts,
        tiktokInfluencerUsers,
        tiktokUserStats,
        tiktokVideoStats,
    } = useNonNullContext(TiktokCreatorsContext);

    const sortedActiveInfluencerPosts = useMemo(
        () =>
            sortTiktokActivePosts(
                tiktokInfluencerPosts.filter((post) => !postInactiveStatuses.includes(post.status)),
                tiktokVideoStats
            ),
        [tiktokInfluencerPosts, tiktokVideoStats]
    );

    const sortedBackupInfluencerPosts = useMemo(
        () =>
            tiktokInfluencerPosts
                .filter((post) => postInactiveStatuses.includes(post.status))
                .sort((a, b) => statusOrder[a.status] - statusOrder[b.status]),
        [tiktokInfluencerPosts]
    );

    const totalsByGroup = useMemo(() => {
        const totalsByGroup: TiktokTotalsByGroup = {};

        if (!tiktokInfluencerPostGroups.length || !tiktokInfluencerPosts.length) {
            return totalsByGroup;
        }

        for (const group of tiktokInfluencerPostGroups) {
            const posts = sortedActiveInfluencerPosts.filter((post) => post.group_id === group.id);

            let usersStats: TiktokUserStats[] = [];
            let results: TiktokInfluencerPostResult[] = [];

            if (tiktokVideoStats.length || tiktokUserStats.length) {
                for (const post of posts) {
                    const influencer = tiktokInfluencerUsers.find((influencer) => influencer.id === post.influencer_id);
                    usersStats = [
                        ...usersStats,
                        ...tiktokUserStats.filter((userStats) => userStats.user_id === influencer?.user),
                    ];
                    results = [
                        ...results,
                        ...tiktokVideoStats.filter((result) => result.video_id === post.tiktok_post),
                    ];
                }
            }

            let followerCount = 0;
            let userFollowerCount = usersStats.map((userStats) => userStats.follower_count);

            if (userFollowerCount.length > 0) {
                followerCount = userFollowerCount.reduce(sum, 0);
            }

            totalsByGroup[group.id] = {
                followerCount,
                playCount: getValue('play_count', results).reduce(sum, 0),
                totalEngagement: results
                    .map((result) =>
                        getTotalEngagements(result?.digg_count, result?.comment_count, result?.share_count)
                    )
                    .reduce(sum, 0),
                diggCount: getValue('digg_count', results).reduce(sum, 0),
                commentCount: getValue('comment_count', results).reduce(sum, 0),
                shareCount: getValue('share_count', results).reduce(sum, 0),
                saveCount: getValue('save_count', results).reduce(sum, 0),
                estimatedViews: posts.reduce((views, post) => {
                    return views + Number(post.estimated_views_override ?? post.estimated_views ?? 0);
                }, 0),
                cost: getValue('cost', posts)
                    .map((n) => Number(n))
                    .reduce(sum, 0),
                liveCost: getValue(
                    'cost',
                    posts.filter((post) => post.status === 'live')
                )
                    .map((n) => Number(n))
                    .reduce(sum, 0),
                clientCost: getValue('client_cost', posts)
                    .map((n) => Number(n))
                    .reduce(sum, 0),
            };
        }

        return totalsByGroup;
    }, [
        tiktokInfluencerPostGroups,
        tiktokInfluencerPosts.length,
        sortedActiveInfluencerPosts,
        tiktokUserStats,
        tiktokVideoStats,
        tiktokInfluencerUsers,
    ]);

    return {
        sortedActiveInfluencerPosts,
        sortedBackupInfluencerPosts,
        totalsByGroup,
    };
}
