import {
    AudienceNetworkPosition,
    FacebookDevicePlatform,
    FacebookPosition,
    FacebookPublisherPlatform,
    InstagramPosition,
    MessengerPosition,
} from '../../../../App.types';
import { fetchWithToken } from '@round/api';

export async function fetchFacebookDevicePlatformOptions(): Promise<FacebookDevicePlatform[]> {
    const response = await fetchWithToken(`/api/facebook/targeting-device-platform-options/`);
    if (!response.ok) {
        throw new Error('Could not fetch facebook targeting device platform options');
    }

    return response.json();
}

export async function fetchFacebookPublisherPlatformOptions(): Promise<FacebookPublisherPlatform[]> {
    const response = await fetchWithToken(`/api/facebook/targeting-publisher-platform-options/`);
    if (!response.ok) {
        throw new Error(`Could not fetch facebook publisher platform options`);
    }

    return response.json();
}

export async function fetchFacebookPositionOptions(): Promise<FacebookPosition[]> {
    const response = await fetchWithToken(`/api/facebook/targeting-facebook-position-options/`);
    if (!response.ok) {
        throw new Error('Could not fetch facebook position options');
    }

    return response.json();
}

export async function fetchInstagramPositionOptions(): Promise<InstagramPosition[]> {
    const response = await fetchWithToken(`/api/facebook/targeting-instagram-position-options/`);
    if (!response.ok) {
        throw new Error('Could not fetch instagram position options');
    }

    return response.json();
}

export async function fetchMessengerPositionOptions(): Promise<MessengerPosition[]> {
    const response = await fetchWithToken(`/api/facebook/targeting-messenger-position-options/`);
    if (!response.ok) {
        throw new Error('Could not fetch messenger position options');
    }

    return response.json();
}

export async function fetchAudienceNetworkPositionOptions(): Promise<AudienceNetworkPosition[]> {
    const response = await fetchWithToken(`/api/facebook/targeting-audience-network-position-options/`);
    if (!response.ok) {
        throw new Error('Could not fetch audience network position options');
    }

    return response.json();
}
