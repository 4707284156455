import React, { FunctionComponent } from 'react';
import TiktokUserRow from './UserRow/UserRow';
import styles from './UserListTable.module.css';
import OrderByTableHeading from '../OrderByTableHeading/OrderByTableHeading';
import { MonitoredUserStats, OrderByQueryParam } from '../../App.types';
import LoadingDataTableRow from '../LoadingDataTableRow/LoadingDataTableRow';
import { TiktokUserImage } from '@round/api';

const UserListTable: FunctionComponent<{
    orderByState: [OrderByQueryParam, (orderBy: OrderByQueryParam) => void];
    statsCount?: number;
    stats?: MonitoredUserStats[];
    openModal: (userId: number) => void;
    loadData: null | (() => void);
    isLoadingData: boolean;
    profileImages: TiktokUserImage[] | undefined;
}> = ({ orderByState, statsCount, stats, openModal, loadData, isLoadingData, profileImages }) => {
    return (
        <div>
            {stats !== undefined && stats.length > 0 && (
                <div className={styles.displayCount}>
                    Displaying 1-{stats.length}
                    {statsCount !== undefined && <span> of {statsCount}</span>}
                </div>
            )}
            <table className={styles.table}>
                <thead>
                    <tr className={styles.columnHeaderRow}>
                        {profileImages && <th></th>}
                        <th>Name</th>
                        <th>Username</th>
                        <OrderByTableHeading fieldName={'follower_count'} orderByState={orderByState}>
                            Follower count
                        </OrderByTableHeading>
                        <OrderByTableHeading fieldName={'follower_count_daily_change_rel'} orderByState={orderByState}>
                            Daily change
                        </OrderByTableHeading>
                        <OrderByTableHeading fieldName={'follower_count_weekly_change_rel'} orderByState={orderByState}>
                            7 day change
                        </OrderByTableHeading>
                    </tr>
                </thead>
                <tbody>
                    {stats?.map((stat) => {
                        const profileImage = profileImages?.find((image) => image.user_id === stat.user_id);
                        return (
                            <TiktokUserRow
                                key={stat.user_id}
                                data={stat}
                                onClick={openModal}
                                profileImage={profileImage}
                            />
                        );
                    })}
                    <LoadingDataTableRow loadData={loadData} isLoadingData={isLoadingData} />
                </tbody>
            </table>
        </div>
    );
};

export default UserListTable;
