import { createBatchedParamsArrayFromIds, getTiktokVideos } from '@round/api';
import useNonNullContext from 'Hooks/useNonNullContext';
import { Dispatch, ReactNode, createContext, useCallback, useContext, useReducer } from 'react';
import {
    reducer,
    initialState,
    State as TiktokVideosByPostIdState,
    Actions as TiktokVideosByPostIdActions,
} from 'Modules/Plans/Posts/reducers/tiktokVideosByPostId';
import { PostIdData } from 'Modules/Plans/Posts/types';
import { getIdsFromPostIdData } from 'Modules/Plans/Posts/helpers';

const TiktokVideosContext = createContext<[TiktokVideosByPostIdState, Dispatch<TiktokVideosByPostIdActions>] | null>(
    null
);

type Props = { children?: ReactNode };
export const TiktokVideosProvider = ({ children }: Props) => {
    const state = useReducer(reducer, initialState);

    return <TiktokVideosContext.Provider value={state}>{children}</TiktokVideosContext.Provider>;
};

export function useTiktokVideos() {
    const [state, dispatch] = useNonNullContext(TiktokVideosContext);

    const fetchData = useCallback(
        async (idData: PostIdData[], requestInit?: RequestInit) => {
            if (!idData.length) {
                dispatch({ type: 'tiktokVideosInitialized' });
                return;
            }

            const { postIds, contentIds } = getIdsFromPostIdData(idData);

            try {
                dispatch({ type: 'tiktokVideosLoading', payload: { postIds } });

                const batchedVideosParams = createBatchedParamsArrayFromIds(contentIds, (ids) => ({
                    id: ids.join(','),
                }));

                const [videosResult] = await Promise.all(
                    batchedVideosParams.map((params) =>
                        getTiktokVideos(params).then((res) => (res.status === 200 ? res.data.results : []))
                    )
                );

                dispatch({
                    type: 'tiktokVideosSuccess',
                    payload: {
                        idData,
                        videos: videosResult,
                    },
                });

                dispatch({ type: 'tiktokVideosInitialized' });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'tiktokVideosIdle', payload: { postIds } });
                    return;
                }

                dispatch({
                    type: 'tiktokVideosError',
                    payload: { postIds, error: `Couldn't fetch tiktok post` },
                });
            }
        },
        [dispatch]
    );

    return {
        data: state.data,
        isInitialized: state.isInitialized,
        fetchData,
    };
}

export function useTiktokVideosActions() {
    const context = useContext(TiktokVideosContext);

    const [, dispatch] = context ?? [];

    const fetchData = useCallback(
        async (idData: PostIdData[], requestInit?: RequestInit) => {
            if (!dispatch) {
                return;
            }

            const { postIds, contentIds } = getIdsFromPostIdData(idData);

            try {
                dispatch({ type: 'tiktokVideosLoading', payload: { postIds } });

                const batchedVideosParams = createBatchedParamsArrayFromIds(contentIds, (ids) => ({
                    id: ids.join(','),
                }));

                const [videosResult] = await Promise.all(
                    batchedVideosParams.map((params) =>
                        getTiktokVideos(params).then((res) => (res.status === 200 ? res.data.results : []))
                    )
                );

                dispatch({
                    type: 'tiktokVideosSuccess',
                    payload: {
                        idData,
                        videos: videosResult,
                    },
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'tiktokVideosIdle', payload: { postIds } });
                    return;
                }

                dispatch({
                    type: 'tiktokVideosError',
                    payload: { postIds, error: `Couldn't fetch tiktok post` },
                });
            }
        },
        [dispatch]
    );

    const removeVideos = useCallback(
        (postIds: string[]) => {
            if (!dispatch) {
                return;
            }

            dispatch({ type: 'removeTiktokVideos', payload: postIds });
        },
        [dispatch]
    );

    return {
        fetchData,
        removeVideos,
    };
}
