import React, { useMemo, useState } from 'react';
import styles from './Tabs.module.css';
import cn from 'classnames';
import { FCWithChildren } from '../../utility/utility.types';

export type Panel = {
    name: string;
    title: string;
    render: () => JSX.Element | null;
};

export type TabsProps = {
    panels: Panel[];
    className?: string;
    headerClassName?: string;
    activeTabIndicatorClassName?: string;
};

const Tabs: FCWithChildren<TabsProps> = ({ panels, className, headerClassName, activeTabIndicatorClassName }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const tabWidthInPrc = useMemo(() => 100 / panels.length, [panels]);

    return (
        <div className={cn(styles.tabs, className)}>
            <div className={cn(styles.header, headerClassName)}>
                <ul>
                    {panels.map(({ title, name }, index) => (
                        <li
                            key={`${name}-tab-panel`}
                            className={cn({ [styles.activeTab]: activeTabIndex === index })}
                            style={{ width: `${tabWidthInPrc}%` }}
                            onClick={() => setActiveTabIndex(index)}
                        >
                            {title}
                        </li>
                    ))}
                </ul>
                <div
                    className={cn(styles.activeTabSelector, activeTabIndicatorClassName)}
                    style={{
                        width: `${tabWidthInPrc}%`,
                        left: `${activeTabIndex * tabWidthInPrc}%`,
                    }}
                />
            </div>
            <div className={styles.content}>
                <div className={styles.contentInner} style={{ transform: `translateX(-${100 * activeTabIndex}%)` }}>
                    {panels.map(({ name, render }, index) => (
                        <div
                            key={`${name}-tab-content`}
                            className={cn({ [styles.activeContent]: index === activeTabIndex })}
                        >
                            {render()}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Tabs;
