import React from 'react';

import styles from './DataUpload.module.css';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import AdvertisingResultsUpload from './AdvertisingResultsUpload/AdvertisingResultsUpload';

const DataUpload = () => {
    return (
        <div className={styles.dataUpload}>
            <NavBar />
            <AdvertisingResultsUpload />
        </div>
    );
};

export default DataUpload;
