import { AppServer, RouteHandlerContext } from '../../mirage';
import { belongsTo, Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokUserImage, TiktokUser, TiktokUserPostStats } from './tiktokUser.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokUserImage: Model.extend({
        avatar_thumb: belongsTo('cachedImage'),
    }) as ModelDefinition<TiktokUserImage>,
    tiktokUserPostStats: Model as ModelDefinition<TiktokUserPostStats>,
};

export const factories = {
    tiktokUserImage: createFactory<TiktokUserImage>({
        user_id(index: number) {
            return index;
        },
        afterCreate(image, s) {
            const server: AppServer = s;
            if (!image.avatar_thumb) {
                image.update({
                    avatar_thumb: server.create('cachedImage'),
                });
            }
        },
    }),
    tiktokUser: createFactory<TiktokUser>({
        avatar_thumb(index: number) {
            return `avatar thumb: ${index}`;
        },
        bio_link(index: number) {
            return `bio link: ${index}`;
        },
        nickname(index: number) {
            return `nickname: ${index}`;
        },
        signature(index: number) {
            return `signature: ${index}`;
        },
        unique_id(index: number) {
            return `unique id: ${index}`;
        },
    }),
    tiktokUserPostStats: createFactory<TiktokUserPostStats>({
        comment_count(index: number) {
            return index;
        },
        create_time(index: number) {
            return index;
        },
        description(index: number) {
            return `description: ${index}`;
        },
        is_ad(index: number) {
            return index % 2 === 0;
        },
        like_count(index: number) {
            return index;
        },
        location(index: number) {
            return `location: ${index}`;
        },
        play_count(index: number) {
            return index;
        },
        share_count(index: number) {
            return index;
        },
        video_tiktok_id(index: number) {
            return `video tiktok id: ${index}`;
        },
    }),
};

export const serializers = {
    tiktokUserImage: makeSerializer<TiktokUserImage>(['avatar_thumb']),
    tiktokUser: Model as ModelDefinition<TiktokUser>,
    tiktokUserPostStats: Model as ModelDefinition<TiktokUserPostStats>,
};

export function handleTiktokUserRequests(server: AppServer) {
    const getTiktokUserImagesListPath = '/api/tiktok/user-image/';
    server.get(getTiktokUserImagesListPath, function (this: RouteHandlerContext, schema, request) {
        const { user_id = '' } = request.queryParams;
        const ids = user_id.split(',');
        const images = schema.all('tiktokUserImage').filter((image) => ids.includes(String(image.id)));

        return this.serialize(images);
    });

    const getTiktokUserListPath = '/api/tiktok/viewsets/users/';
    server.get(getTiktokUserListPath, function (this: RouteHandlerContext, schema, request) {
        const { id = '' } = request.queryParams;
        const ids = id
            .split(',')
            .filter((item) => !!item)
            .map((item) => Number(item));

        const users = schema.all('tiktokUser').filter((user) => ids.includes(Number(user.id)));
        return buildPaginatedResponse(users, {
            url: getTiktokUserListPath,
            serialize: (resource) => this.serialize(resource, 'tiktokUser'),
            queryParams: request.queryParams,
        });
    });

    const getTiktokUserPostStatsListPath = '/api/tiktok/users/:userId/post-stats/';
    server.get(getTiktokUserPostStatsListPath, function (this: RouteHandlerContext, schema, request) {
        const postStats = schema.all('tiktokUserPostStats');

        return buildPaginatedResponse(postStats, {
            url: getTiktokUserPostStatsListPath,
            serialize: (resource) => this.serialize(resource, 'tiktokUserPostStats'),
            queryParams: request.queryParams,
        });
    });
}
