import { useCallback, useState } from 'react';
import { getInstagramAudioStats, InstagramAudioStats } from '@round/api';

const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-GB').replace(/\//g, '-');
};

export default function useInstagramAudioStats(audioId: number, statsTimeSeriesDateRange?: [Date, Date] | null) {
    const [stats, setStats] = useState<InstagramAudioStats | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (!audioId) {
                return;
            }

            try {
                setIsLoading(true);
                setError(null);

                const dateRangeParams =
                    statsTimeSeriesDateRange === null || statsTimeSeriesDateRange === undefined
                        ? {}
                        : {
                              reels_count_time_series_start_date: formatDate(statsTimeSeriesDateRange[0]),
                              reels_count_time_series_end_date: formatDate(statsTimeSeriesDateRange[1]),
                          };

                const response = await getInstagramAudioStats({ audioId, ...dateRangeParams }, requestInit);
                if (response.status === 404) {
                    setError(response.data.detail);
                    setIsInitialized(true);
                    return;
                }

                setStats(response.data);
                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setError(`Could not fetch instagram audio ${audioId} stats`);
                setIsInitialized(true);
            } finally {
                setIsLoading(false);
            }
        },
        [audioId, statsTimeSeriesDateRange]
    );

    const reset = useCallback(() => {
        setIsInitialized(false);
    }, []);

    return {
        stats,
        isLoading,
        isInitialized,
        error,
        init,
        reset,
    };
}
