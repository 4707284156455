import React, { FunctionComponent, useEffect } from 'react';
import { Line, ChartProps } from 'react-chartjs-2';
import { numberWithCommas } from '../../helpers';
import { ChartData } from 'chart.js';

const VERTICAL_LINE_COLOR = '#bfbfbf';
const VERTICAL_LINE_WIDTH = 1.2;

type Point = { x: Date; y: number };
type LineChartProps = ChartProps<'line', Point[]>;
const LineChart: FunctionComponent<{ data: Point[]; activeDate?: number }> = ({ data, activeDate }) => {
    const height = 550;
    const chartData: ChartData<'line', Point[]> = {
        datasets: [
            {
                data: data,
                borderColor: '#109CF1',
                borderWidth: 2,
                backgroundColor: ({ chart: { canvas } }) => {
                    const ctx = canvas.getContext('2d');
                    const gradient = ctx?.createLinearGradient(0, 0, 0, height);
                    gradient?.addColorStop(0, 'rgba(16, 156, 241, 0.43)');
                    gradient?.addColorStop(1, 'rgba(16,156,241,0)');
                    return gradient;
                },
            },
        ],
    };

    const options: LineChartProps['options'] = {
        responsive: true,
        scales: {
            x: {
                type: 'timeseries',
                time: {
                    unit: 'day',
                    tooltipFormat: 'MM/DD/YYYY HH:mm',
                    displayFormats: {
                        day: 'MMM D',
                    },
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: 12,
                        family: 'Poppins',
                    },
                    autoSkip: true,
                    maxTicksLimit: 30,
                },
            },
            y: {
                grid: {
                    display: true,
                },
                border: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: 12,
                        family: 'Poppins',
                    },
                    callback: function (value) {
                        const val = Number(value);
                        if (Math.floor(val) === val) {
                            return numberWithCommas(val);
                        }
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                titleFont: {
                    family: 'Poppins',
                },
                bodyFont: {
                    family: 'Poppins',
                },
                footerFont: {
                    family: 'Poppins',
                },
            },
        },
    };

    const plugins: LineChartProps['plugins'] = [
        {
            id: 'date_line',
            beforeDraw: (chartInstance) => {
                // @ts-ignore
                if (!window.activeDate) {
                    return;
                }

                // @ts-ignore
                const x = chartInstance.scales['x'].getPixelForValue(window.activeDate);
                const ctx = chartInstance.ctx;
                ctx.beginPath();
                ctx.moveTo(x, chartInstance.chartArea.bottom);
                ctx.lineTo(x, chartInstance.chartArea.top);
                ctx.strokeStyle = VERTICAL_LINE_COLOR;
                ctx.lineWidth = VERTICAL_LINE_WIDTH;
                ctx.stroke();
            },
        },
    ];

    useEffect(() => {
        // @ts-ignore
        window.activeDate = activeDate;
    }, [activeDate]);

    useEffect(() => {
        return () => {
            // @ts-ignore
            window.activeDate = null;
        };
    }, []);

    return <Line data={chartData} options={options} plugins={plugins} />;
};

export default LineChart;
