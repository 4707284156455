import { GenericDropdownOption } from 'App.types';
import { OrderingValues, PhylloInstagramCreatorsProfileSearchSortableFields } from '@round/api';

export const sortingOptions: GenericDropdownOption<
    OrderingValues<PhylloInstagramCreatorsProfileSearchSortableFields>
>[] = [
    { value: '-reels_views', label: 'Reels views (Desc)' },
    { value: '-engagement_rate', label: 'Engagement rate (Desc)' },
    { value: '-follower_count', label: 'Follower count (Desc)' },
    { value: '-follower_growth', label: 'Follower growth (Desc)' },
    { value: '-total_likes_growth', label: 'Total likes growth (Desc)' },
    { value: '-total_views_growth', label: 'Total views growth (Desc)' },
];
