import { ComponentType } from 'react';
import { OptionProps, components } from 'react-select';
import cn from 'classnames';
import styles from './OptionWithSubLabel.module.css';

export type DropdownOptionWithSubLabel<T> = {
    value: T;
    label: string;
    subLabel: string;
};

const OptionWithSubLabel: ComponentType<OptionProps<DropdownOptionWithSubLabel<number>, false>> = (props) => {
    const { innerProps, innerRef } = props;
    return (
        <div ref={innerRef} {...innerProps}>
            <components.Option {...props}>
                <div>{props.label}</div>
                <div className={cn(styles.subLabel, { [styles.selected]: props.isSelected })}>
                    {props.data.subLabel}
                </div>
            </components.Option>
        </div>
    );
};

export default OptionWithSubLabel;
