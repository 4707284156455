import React, { useState } from 'react';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import FormField from '../../../../../../ui/DataEntry/FormField/FormField';
import Label from '../../../../../../ui/DataEntry/Label/Label';
import Button from '../../../../../../ui/Buttons/Button/Button';
import { showNotification } from '../../../../../../helpers';
import { postTiktokInfluencerPlanAudio } from '../../../InfluencerPlan.api';
import { TiktokInfluencerPlanAudio } from '../../../../../../App.types';

type AddAudioModalProps = {
    influencerPlanId: number;
    isModalOpen: boolean;
    closeModal: () => void;
    onAudioCreated: (audio: TiktokInfluencerPlanAudio) => void;
};

const AddAudioModal = ({ isModalOpen, onAudioCreated, closeModal, influencerPlanId }: AddAudioModalProps) => {
    const [url, setUrl] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);

    const handleCreate = async () => {
        try {
            setSubmitLoading(true);
            const audio = await postTiktokInfluencerPlanAudio({ plan: influencerPlanId, url });
            onAudioCreated(audio);
            showNotification('Audio created', 'info');
            closeModal();
        } catch {
            showNotification('Could not create audio', 'error');
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={closeModal}>
            <ModalTitle>Add Audio</ModalTitle>
            <ModalContent>
                <FormField>
                    <Label htmlFor="url">Url</Label>
                    <input
                        name="url"
                        id="url"
                        placeholder="Enter URL"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                </FormField>
            </ModalContent>
            <ModalFooter align="right">
                <Button type="filled" color="black" loading={submitLoading} onClick={handleCreate}>
                    Create
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddAudioModal;
