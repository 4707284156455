import {
    GetInvoiceRequestsSortingKey,
    InvoiceRequest,
    InvoiceRequestStatus,
    OrderingValues,
    Release,
    XeroInvoiceSimple,
} from '@round/api';
import WrapperPaginationTable, {
    WrapperPaginationTableProps,
} from 'ui/WrapperTable/WrapperPaginationTable/WrapperPaginationTable';
import React, { useMemo, useState } from 'react';
import { ColumnDef, CellContext, RowSelectionState } from '@tanstack/react-table';
import { DropdownOptionWithSubLabel, Image, OptionWithSubLabel, ProgressBar, Select, Skeleton } from '@round/ui-kit';
import { Link } from 'react-router-dom';
import styles from './InvoiceRequestsTable.module.css';
import TruncatedTextCell from 'SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import { ReactComponent as InstagramIcon } from 'assets/icons/platform/InstagramSolid.svg';
import { ReactComponent as TiktokIcon } from 'assets/icons/platform/TikTokSolid.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/platform/YoutubeSolid.svg';
import moment from 'moment';
import { asMoney, numberWithCommas, roundTo2Dp, showNotification } from 'helpers';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { UseInvoiceRequestsResult } from 'Modules/Finance/InvoiceRequests/useInvoiceRequests';
import useReceivableInvoicesSelect from 'Modules/Finance/hooks/userReceivableInvoicesSelect';
import { StylesConfig, ValueType } from 'react-select';
import { invoiceRequestStatusOptions } from 'Modules/Finance/InvoiceRequests/helpers';
import StatusBadgeOption from '../StatusBadge/StatusBadgeOption';
import StatusBadgeValue from '../StatusBadge/StatusBadgeValue';
import Checkbox from 'ui/DataEntry/Checkbox/Checkbox';
import { uniqBy } from 'lodash';
import { mapOrderingToTableSorting, mapTableSortingToOrdering } from 'ui/WrapperTable/helpers';
import { GenericDropdownOption } from 'App.types';

export type InvoiceRequestsTableRow = InvoiceRequest & {
    release: Release | undefined;
    invoice: XeroInvoiceSimple | undefined;
};

type Props = {
    selectedRows: InvoiceRequestsTableRow[];
    onSelectedRowsChange: (rows: InvoiceRequestsTableRow[]) => void;
    ordering: OrderingValues<GetInvoiceRequestsSortingKey>[];
    onOrderingChange: (ordering: OrderingValues<GetInvoiceRequestsSortingKey>[]) => void;
} & Pick<
    WrapperPaginationTableProps<InvoiceRequestsTableRow>,
    'data' | 'isLoading' | 'noDataLabel' | 'page' | 'setPage' | 'pageSize' | 'setPageSize' | 'count'
> &
    Pick<UseInvoiceRequestsResult, 'updateInvoiceRequest' | 'updateInvoice'>;

type TableCellContext<K extends keyof InvoiceRequestsTableRow> = CellContext<
    InvoiceRequestsTableRow,
    InvoiceRequestsTableRow[K]
>;

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        boxShadow: 'none',
        flexWrap: 'nowrap',
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '0.25rem 0',
        fontSize: '0.7rem',
        minWidth: '4rem',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        paddingRight: 0,
    }),
    menu: (base) => ({
        ...base,
        minWidth: '15rem',
        borderRadius: '0.75rem',
        padding: '0.875rem',
        boxShadow: `0px 3px 20px 0px rgba(87, 87, 87, 0.10),
                    0px 29px 29px 0px rgba(87, 87, 87, 0.09),
                    0px 66px 39px 0px rgba(87, 87, 87, 0.05),
                    0px 117px 47px 0px rgba(87, 87, 87, 0.01),
                    0px 183px 51px 0px rgba(87, 87, 87, 0.00)`,
    }),
    menuList: (base) => ({
        ...base,
        fontFamily: 'Inter, sans-serif',
        lineHeight: 1.25,
        color: '#111111',
        '::-webkit-scrollbar': {
            width: '0.375rem',
            height: '0.375rem',
            background: 'transparent',
        },
        '::-webkit-scrollbar-thumb': {
            background: '#D1D5DB',
            borderRadius: '0.5rem',
        },
    }),
};

const InvoiceRequestsTable = ({
    updateInvoiceRequest,
    updateInvoice,
    selectedRows,
    onSelectedRowsChange,
    ordering,
    onOrderingChange,
    ...props
}: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);

    const columns = useMemo<ColumnDef<InvoiceRequestsTableRow, any>[]>(
        () => [
            {
                id: 'select',
                header: ({ table }) => {
                    return (
                        <div className={styles.checkboxContainer}>
                            {props.data.length > 0 && (
                                <Checkbox
                                    ariaLabel="Select all"
                                    value={table.getIsAllPageRowsSelected()}
                                    indeterminateValue={table.getIsSomePageRowsSelected()}
                                    onChange={table.toggleAllPageRowsSelected}
                                />
                            )}
                        </div>
                    );
                },
                cell: ({ row }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.checkboxContainer}>
                            <Checkbox
                                ariaLabel="Select row"
                                value={row.getIsSelected()}
                                onChange={() => row.toggleSelected()}
                            />
                        </div>
                    );
                },
            },
            {
                accessorKey: 'release',
                header: 'Campaign',
                cell: ({ getValue, row: { original } }: TableCellContext<'release'>) => {
                    const release = getValue();

                    const platform = original.tiktok_group_id
                        ? '#tiktok'
                        : original.instagram_group_id
                        ? '#instagram'
                        : original.youtube_group_id
                        ? '#youtube'
                        : '';

                    const campaignUrl = release ? `/campaigns/${release.id}/creators/${platform}` : '';

                    return (
                        <div className={styles.releaseContainer}>
                            <Image
                                className={styles.brandPicture}
                                loading={props.isLoading}
                                src={release?.brand.picture}
                            />
                            <div className={styles.releaseDetails}>
                                {props.isLoading ? (
                                    <Skeleton />
                                ) : (
                                    <Link className={styles.releaseName} to={campaignUrl} target="_blank">
                                        <TruncatedTextCell popoverClassName={styles.hint}>
                                            {release?.name ?? '-'}
                                        </TruncatedTextCell>
                                    </Link>
                                )}
                                {props.isLoading ? (
                                    <Skeleton />
                                ) : (
                                    <span className={styles.brandName}>
                                        <TruncatedTextCell popoverClassName={styles.hint}>
                                            {`${release?.brand.name ?? ''} - ${release?.brand.client.name ?? ''}`}
                                        </TruncatedTextCell>
                                    </span>
                                )}
                            </div>

                            {!props.isLoading && (
                                <div className={styles.platformIconContainer}>
                                    {original.instagram_group_id && <InstagramIcon className={styles.platformIcon} />}
                                    {original.tiktok_group_id && <TiktokIcon className={styles.platformIcon} />}
                                    {original.youtube_group_id && <YoutubeIcon className={styles.platformIcon} />}
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                accessorKey: 'group_name',
                header: 'Group name',
                cell: ({ getValue }: TableCellContext<'group_name'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return <TruncatedTextCell>{getValue()}</TruncatedTextCell>;
                },
            },
            {
                accessorKey: 'plan_start_date',
                header: 'Plan start date',
                cell: ({ getValue }: TableCellContext<'plan_start_date'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return moment(getValue()).format('DD MMM YYYY');
                },
            },
            {
                accessorKey: 'group_budget',
                header: 'Amount',
                cell: ({ getValue, row: { original } }: TableCellContext<'group_budget'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const value = getValue();
                    if (!value) {
                        return '-';
                    }

                    const amountSpentPercentage = parseFloat(original.proportion_spent) * 100;
                    return (
                        <div className={styles.amountContainer}>
                            <div className={styles.amountSpentLabels}>
                                <span>
                                    {asMoney(
                                        Number(value),
                                        currencies.find((c) => c.id === original.currency_id)
                                    )}
                                </span>
                                <span>{numberWithCommas(roundTo2Dp(amountSpentPercentage))}%</span>
                            </div>

                            <ProgressBar
                                progress={amountSpentPercentage}
                                barClassName={styles.amountSpentBar}
                                trackClassName={styles.amountSpentTrack}
                            />
                        </div>
                    );
                },
            },
            {
                accessorKey: 'notes',
                header: 'Notes',
                cell: function NotesCell({ getValue, row: { original } }: TableCellContext<'notes'>) {
                    const value = getValue();
                    const [notes, setNotes] = useState(value);

                    const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        if (e.shiftKey) {
                            return;
                        }

                        if (e.key === 'Enter') {
                            e.preventDefault();
                            e.currentTarget.blur();
                        }
                    };

                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const submit = async () => {
                        if (notes === value) {
                            return;
                        }

                        try {
                            const response = await updateInvoiceRequest(original.id, { notes });
                            if (response.status === 200) {
                                setNotes(response.data.notes);
                                showNotification('Updated', 'info');
                                return;
                            }

                            if (response.status === 404) {
                                setNotes(value);
                                showNotification(response.data.detail, 'error');
                                return;
                            }

                            if (response.status === 400) {
                                setNotes(value);
                                showNotification(String(response.data.notes), 'error');
                                return;
                            }
                        } catch {
                            setNotes(value);
                            showNotification('Could not update', 'error');
                        }
                    };

                    return (
                        <textarea
                            className={styles.notesInput}
                            value={notes}
                            rows={1}
                            onChange={(e) => setNotes(e.target.value)}
                            onKeyDown={handleEnterKeyDown}
                            onBlur={submit}
                            placeholder="Enter notes..."
                        />
                    );
                },
            },
            {
                accessorKey: 'po_number',
                header: 'PO number',
                cell: function PoNumber({ getValue, row: { original } }: TableCellContext<'po_number'>) {
                    const value = getValue();
                    const [poNumber, setPoNumber] = useState(value);

                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const submit = async () => {
                        if (value === poNumber) {
                            return;
                        }

                        try {
                            const response = await updateInvoiceRequest(original.id, { po_number: poNumber });
                            if (response.status === 200) {
                                setPoNumber(response.data.po_number);
                                showNotification('Updated', 'info');
                                return;
                            }

                            if (response.status === 404) {
                                setPoNumber(value);
                                showNotification(response.data.detail, 'error');
                                return;
                            }

                            if (response.status === 400) {
                                setPoNumber(value);
                                showNotification(String(response.data.po_number), 'error');
                                return;
                            }
                        } catch {
                            setPoNumber(value);
                            showNotification('Could not update', 'error');
                        }
                    };

                    return (
                        <input
                            className={styles.input}
                            value={poNumber}
                            onChange={(e) => setPoNumber(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    submit();
                                }
                            }}
                            onBlur={submit}
                            placeholder="Enter PO Number..."
                        />
                    );
                },
            },
            {
                accessorKey: 'modified',
                enableSorting: true,
                header: 'Last updated',
                cell: ({ getValue }: TableCellContext<'modified'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const modified = getValue();
                    if (!modified) {
                        return '-';
                    }

                    return moment(modified).format('DD MMM, HH:mm');
                },
            },
            {
                accessorKey: 'invoice',
                header: 'Invoice',
                cell: function Invoice({ getValue, row: { original } }: TableCellContext<'invoice'>) {
                    const value = getValue();
                    const selectProps = useReceivableInvoicesSelect();

                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const selectValue: ValueType<DropdownOptionWithSubLabel<number>, false> = value
                        ? {
                              value: value.id,
                              label: value.invoice_number,
                              subLabel: value.reference,
                          }
                        : null;

                    return (
                        <Select
                            {...selectProps}
                            isClearable
                            styles={selectStyles}
                            value={selectValue}
                            onChange={(option: ValueType<DropdownOptionWithSubLabel<number>, false>) => {
                                const invoice: XeroInvoiceSimple | null = option
                                    ? { id: option.value, invoice_number: option.label, reference: option.subLabel }
                                    : null;

                                updateInvoice(original.id, invoice)
                                    .then(() => showNotification('Updated', 'info'))
                                    .catch(() => showNotification('Could not update', 'error'));
                            }}
                            components={{ Option: OptionWithSubLabel }}
                        />
                    );
                },
            },
            {
                accessorKey: 'contact',
                header: 'Uniport contact',
                cell: function Contact({ getValue, row: { original } }: TableCellContext<'contact'>) {
                    const value = getValue();
                    const [contact, setContact] = useState(value);

                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const submit = async () => {
                        if (contact === value) {
                            return;
                        }

                        try {
                            const response = await updateInvoiceRequest(original.id, { contact });
                            if (response.status === 200) {
                                setContact(response.data.contact);
                                showNotification('Updated', 'info');
                                return;
                            }

                            if (response.status === 404) {
                                setContact(value);
                                showNotification(response.data.detail, 'error');
                                return;
                            }

                            if (response.status === 400) {
                                setContact(value);
                                showNotification(String(response.data.contact), 'error');
                                return;
                            }
                        } catch {
                            setContact(value);
                            showNotification('Could not update', 'error');
                        }
                    };

                    return (
                        <input
                            className={styles.input}
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    submit();
                                }
                            }}
                            onBlur={submit}
                            placeholder="Enter uniport contact..."
                        />
                    );
                },
            },
            {
                accessorKey: 'status',
                size: 200,
                header: 'Status',
                cell: function Status({ getValue, row: { original } }: TableCellContext<'status'>) {
                    const value = getValue();
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <Select
                            styles={selectStyles}
                            isSearchable={false}
                            isMulti={false}
                            value={invoiceRequestStatusOptions.find((o) => o.value === value)}
                            options={invoiceRequestStatusOptions}
                            onChange={(option) => {
                                updateInvoiceRequest(original.id, {
                                    status: (option as ValueType<GenericDropdownOption<InvoiceRequestStatus>, false>)
                                        ?.value,
                                })
                                    .then((response) => {
                                        if (response.status === 200) {
                                            showNotification('Updated', 'info');
                                            return;
                                        }

                                        if (response.status === 404) {
                                            showNotification(response.data.detail, 'error');
                                            return;
                                        }

                                        showNotification(String(response.data.status), 'error');
                                    })
                                    .catch(() => showNotification('Could not update', 'error'));
                            }}
                            components={{ Option: StatusBadgeOption, SingleValue: StatusBadgeValue }}
                        />
                    );
                },
            },
        ],
        [props.data.length, props.isLoading, currencies, updateInvoiceRequest, updateInvoice]
    );

    return (
        <WrapperPaginationTable
            {...props}
            enableSorting
            manualSorting
            manualPagination
            sorting={mapOrderingToTableSorting(ordering)}
            onSortingChange={(sortingState) => onOrderingChange(mapTableSortingToOrdering(sortingState))}
            className={styles.table}
            columns={columns}
            columnPinning={{
                right: ['status'],
            }}
            getRowId={(row, index) => (row.id ? row.id.toString() : `skeleton-${index}`)}
            enableRowSelection
            enableMultiRowSelection
            rowSelection={selectedRows.reduce<RowSelectionState>((acc, row) => {
                acc[row.id.toString()] = true;
                return acc;
            }, {})}
            onRowSelectionChange={(selection) => {
                const incomingRows = props.data.filter((row) => selection[row.id.toString()]);
                onSelectedRowsChange(
                    uniqBy(selectedRows.filter((row) => selection[row.id.toString()]).concat(incomingRows), 'id')
                );
            }}
        />
    );
};

export default InvoiceRequestsTable;
