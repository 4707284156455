import useNonNullContext from 'Hooks/useNonNullContext';
import reducer from './reducer';
import { Dispatch, ReactNode, createContext, useCallback, useContext, useReducer } from 'react';
import { YoutubeAccountsContextActions, YoutubeAccountsContextState } from './reducer';
import { youtube } from '@round/api';

const initialState: YoutubeAccountsContextState = {
    isInitialized: false,
    data: {},
};

const YoutubeAccountsContext = createContext<
    [YoutubeAccountsContextState, Dispatch<YoutubeAccountsContextActions>] | null
>(null);

type Props = { children?: ReactNode };
export const YoutubeAccountsProvider = ({ children }: Props) => {
    const state = useReducer(reducer, initialState);

    return <YoutubeAccountsContext.Provider value={state}>{children}</YoutubeAccountsContext.Provider>;
};

export function useYoutubeAccountsData() {
    const [state, dispatch] = useNonNullContext(YoutubeAccountsContext);

    const fetchData = useCallback(
        async (accountIds: number[], requestInit?: RequestInit) => {
            if (!accountIds.length) {
                dispatch({ type: 'setAccountsDataInitialized' });
                return;
            }

            try {
                dispatch({ type: 'setAccountsDataLoading', payload: { accountIds } });

                const response = await youtube.getChannels(
                    { id: accountIds.join(','), page_size: accountIds.length },
                    requestInit
                );

                if (response.status === 200) {
                    dispatch({
                        type: 'setAccountsDataSuccess',
                        payload: {
                            accountIds,
                            channels: response.data.results,
                        },
                    });

                    dispatch({ type: 'setAccountsDataInitialized' });
                    return;
                }

                dispatch({
                    type: 'setAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch youtube account data` },
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'setAccountsDataIdle', payload: { accountIds } });
                    return;
                }

                dispatch({
                    type: 'setAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch youtube account data` },
                });
            }
        },
        [dispatch]
    );

    return {
        data: state.data,
        isInitialized: state.isInitialized,
        fetchData,
        getIsLoading: (accountId: number) =>
            !state.data[accountId] ||
            state.data[accountId]?.status === 'idle' ||
            state.data[accountId]?.status === 'loading',
    };
}

export function useYoutubeAccountsDataActions() {
    const context = useContext(YoutubeAccountsContext);

    const [, dispatch] = context ?? [];

    const fetchData = useCallback(
        async (accountIds: number[], requestInit?: RequestInit) => {
            if (!dispatch) {
                return;
            }

            try {
                dispatch({ type: 'setAccountsDataLoading', payload: { accountIds } });

                const response = await youtube.getChannels(
                    { id: accountIds.join(','), page_size: accountIds.length },
                    requestInit
                );

                if (response.status === 200) {
                    dispatch({
                        type: 'setAccountsDataSuccess',
                        payload: {
                            accountIds,
                            channels: response.data.results,
                        },
                    });

                    return;
                }

                dispatch({
                    type: 'setAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch youtube account data` },
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'setAccountsDataIdle', payload: { accountIds } });
                    return;
                }

                dispatch({
                    type: 'setAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch youtube account data` },
                });
            }
        },
        [dispatch]
    );

    return {
        fetchData,
    };
}
