import { AppServer } from '../mirage';
import * as locationsMirage from './locations/locations.mirage';
import * as publicReportsMirage from './publicReports/publicReports.mirage';
import * as aggregatedPostStatsMirage from './aggregatedPostStats/aggregatedPostStats.mirage';

export const models = {
    ...locationsMirage.models,
    ...publicReportsMirage.models,
    ...aggregatedPostStatsMirage.models,
};

export const serializers = {
    ...locationsMirage.serializers,
    ...publicReportsMirage.serializers,
    ...aggregatedPostStatsMirage.serializers,
};

export const factories = {
    ...locationsMirage.factories,
    ...publicReportsMirage.factories,
    ...aggregatedPostStatsMirage.factories,
};

export function handleRequests(server: AppServer) {
    locationsMirage.handleRequests(server);
    publicReportsMirage.handleRequests(server);
    aggregatedPostStatsMirage.handleRequests(server);
}
