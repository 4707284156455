import React, { useCallback, useEffect, useState } from 'react';
import { isFinite } from 'lodash';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { AdvertisingContext } from '../../contexts/AdvertisingContext/AdvertisingContext';
import { applyCommission, asMoneyWithoutZeros, formatDateObjShort, showNotification } from '../../../../../helpers';
import {
    ErrorMessage,
    FacebookCampaignMediaPlanItemRelationship,
    MediaPlanItem,
    MediaPlanItemRow,
} from '../../../../../App.types';
import AdvertisingGroupBar from '../../components/AdvertisingGroupBar/AdvertisingGroupBar';
import AdvertisingTable from '../AdvertisingTable/AdvertisingTable';
import styles from './AdvertisingGroups.module.css';
import Button from '../../../../../ui/Buttons/Button/Button';
import { ReactComponent as TrashIcon } from '../../../../../assets/TrashIcon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/FacebookIcon.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/CopyIcon.svg';
import { ReactComponent as DocumentCopyIcon } from '../../assets/DocumentCopyIcon.svg';
import cn from 'classnames';
import { ProtectedByUserGroups } from '../../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import {
    isCreateFacebookCampaignAdSetError,
    isCreateFacebookCampaignCampaignError,
    isCreateFacebookCampaignNonFieldErrors,
    isFacebookCampaignError,
} from '../../helpers/Facebook.helpers';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../ui/General/Modal/Modal';
import copy from 'copy-to-clipboard';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import ExportReports from '../ExportReports/ExportReports';
import { ReactComponent as CircleRightIcon } from '../../assets/CircleRight.svg';
import MediaPlanResultsModal from '../MediaPlanResultsModal/MediaPlanResultsModal';
import useUrlState from '../../../../../Hooks/useUrlState';
import Skeleton from '../../../../../ui/Skeleton/Skeleton';
import { fetchTextToCopy } from '../../api/MediaPlan.api';
import { MediaPlan, MediaPlanItemGroup } from '@round/api';
import { toDecimalPoint } from '@round/utils';

const formatGroupDurationDate = (date: number) =>
    formatDateObjShort(new Date(date), { year: undefined, day: '2-digit', month: 'long' });

type UrlState = {
    expandedGroups: string;
    postId: string;
    resultsModalOpen?: boolean;
};

const AdvertisingGroups = () => {
    const [contextState, contextUtils] = useNonNullContext(AdvertisingContext);
    const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
    const [copyToClipboardLoading, setCopyToClipboardLoading] = useState(false);
    const [duplicateMediaPlanItemLoading, setDuplicateMediaPlanItemLoading] = useState(false);
    const [createFacebookCampaignLoading, setCreateFacebookCampaignLoading] = useState(false);
    const [createFacebookCampaignError, setCreateFacebookCampaignError] = useState<ErrorMessage | null>(null);
    const [addGroupLoading, setAddGroupLoading] = useState(false);
    const [urlState, setUrlState] = useUrlState<UrlState>({ expandedGroups: '', postId: '' });
    const expandedGroups = urlState.expandedGroups?.split(',').map((g) => Number(g)) ?? [];
    const setExpandedGroups = (groups: number[]) => setUrlState({ expandedGroups: groups.join(',') });
    const isResultsModalOpen = urlState.resultsModalOpen === 'true';

    const isFacebookBusinessManagerBuyPlatform = (id: number) =>
        contextState.mediaPlanItems.find((item) => item.id === id)?.buy_platform.name.toLocaleLowerCase() ===
        'facebook business manager';

    const sortedGroups = Array.from(contextState.groups).sort((a, b) => a.ordering_index - b.ordering_index);
    const selectedRows = contextState.mediaPlanItemRows.filter((row) => selectedRowIds.includes(row.id));
    const handleRowsSelect = useCallback((rowId: number) => {
        setSelectedRowIds((ids) => {
            if (ids.includes(rowId)) {
                return ids.filter((id) => id !== rowId);
            }

            return ids.concat(rowId);
        });
    }, []);

    const handleAllRowsSelect = useCallback((rowIds: number[]) => {
        setSelectedRowIds((ids) => {
            if (!rowIds.every((id) => ids.includes(id))) {
                return Array.from(new Set(ids.concat(rowIds)));
            }

            return ids.filter((id) => !rowIds.includes(id));
        });
    }, []);

    const handleDelete = useCallback(
        async (group: MediaPlanItemGroup) => {
            try {
                await contextUtils.deleteGroup(group.id);
                showNotification('Group deleted', 'info');
            } catch {
                showNotification('Could not delete group', 'error');
            }
        },
        [contextUtils]
    );

    const handleGroupNameChange = useCallback(
        async (groupId: number, name: string) => {
            try {
                await contextUtils.changeGroupName(groupId, name);
                showNotification('Group name changed', 'info');
            } catch {
                showNotification('Could not change group name', 'error');
            }
        },
        [contextUtils]
    );

    const handleDuplicateGroup = useCallback(
        async (groupId: number) => {
            try {
                await contextUtils.duplicateGroup(groupId);
                showNotification('Group duplicated', 'info');
            } catch {
                showNotification('Could not duplicate group', 'error');
            }
        },
        [contextUtils]
    );

    const handleCreateMediaPlanItem = useCallback(
        async (mediaPlanId: number, groupId: number) => {
            try {
                await contextUtils.createMediaPlanItem(mediaPlanId, groupId);
                showNotification('Item created', 'info');
            } catch {
                showNotification('Could not create item', 'error');
            }
        },
        [contextUtils]
    );

    const handleDeleteMediaPlanItems = useCallback(async () => {
        const plural = selectedRowIds.length > 1 ? 's' : '';
        try {
            await contextUtils.deleteMediaPlanItems(selectedRowIds);
            setSelectedRowIds([]);
            showNotification(`Item${plural} deleted`, 'info');
        } catch {
            showNotification(`Could not delete item${plural}`, 'error');
        }
    }, [contextUtils, selectedRowIds]);

    const updateMediaPlanItem = useCallback(
        async (id: number, data: Partial<MediaPlanItem>) => {
            try {
                await contextUtils.updateMediaPlanItem(id, data);
                showNotification('Media plan item updated', 'info');
            } catch {
                showNotification('Could not update media plan item', 'error');
            }
        },
        [contextUtils]
    );

    const copyToClipboard = useCallback(async (mediaPlan: MediaPlan, rows: MediaPlanItemRow[]) => {
        if (!rows.length) {
            return;
        }

        try {
            setCopyToClipboardLoading(true);
            const text = await fetchTextToCopy(rows, mediaPlan);
            copy(text);
            showNotification('Copied!', 'info');
        } catch {
            showNotification('Could not copy selected rows', 'error');
        } finally {
            setCopyToClipboardLoading(false);
        }
    }, []);

    const duplicateMediaPlanItem = useCallback(async () => {
        const mediaPlanItemId = selectedRowIds[0];
        if (!mediaPlanItemId) {
            return;
        }

        try {
            setDuplicateMediaPlanItemLoading(true);
            await contextUtils.duplicateMediaPlanItem(mediaPlanItemId);
            showNotification('Media plan item duplicated', 'info');
        } catch {
            showNotification('Could not duplicate media plan item', 'error');
        } finally {
            setDuplicateMediaPlanItemLoading(false);
        }
    }, [contextUtils, selectedRowIds]);

    const unlinkFacebookCampaign = useCallback(
        async (campaign: FacebookCampaignMediaPlanItemRelationship) => {
            try {
                await contextUtils.unlinkFacebookCampaign(campaign);
                showNotification('Unlinked', 'info');
            } catch {
                showNotification('Could not unlink', 'error');
            }
        },
        [contextUtils]
    );

    const createFBCampaign = useCallback(async () => {
        if (selectedRows.length !== 1) {
            return;
        }

        const mediaPlanItemRow = selectedRows[0];
        const { targeting, creatives } = mediaPlanItemRow;
        try {
            setCreateFacebookCampaignError(null);
            setCreateFacebookCampaignLoading(true);
            const result = await contextUtils.createFacebookCampaign(mediaPlanItemRow.id);
            const errorDialogTitle = 'Error creating Facebook campaign';
            if (isCreateFacebookCampaignNonFieldErrors(result)) {
                return setCreateFacebookCampaignError({
                    title: errorDialogTitle,
                    message: result.non_field_errors.join('\n'),
                });
            }

            if (isCreateFacebookCampaignCampaignError(result)) {
                return setCreateFacebookCampaignError({
                    title: result.campaign.error_user_title,
                    message: result.campaign.error_user_msg,
                });
            }

            if (isCreateFacebookCampaignAdSetError(result)) {
                return setCreateFacebookCampaignError({
                    title: errorDialogTitle,
                    message: (() =>
                        Object.keys(result.ad_set).map((targetingKey) => {
                            const adSetError = result.ad_set[targetingKey];
                            if (isFacebookCampaignError(adSetError)) {
                                const targetingName =
                                    targeting.find((t) => t.id === Number(targetingKey))?.name ?? targetingKey;

                                return (
                                    <div key={`targeting-error-${targetingKey}`}>
                                        <p className={styles.facebookErrorOrigin}>Targeting "{targetingName}"</p>
                                        <p className={styles.facebookErrorTitle}>{adSetError.error_user_title}</p>
                                        <p className={styles.facebookErrorMessage}>{adSetError.error_user_msg}</p>
                                    </div>
                                );
                            }

                            return Object.keys(adSetError.ad).map((adKey) => {
                                const adError = adSetError.ad[adKey];
                                const creativeName = creatives.find((c) => c.id === Number(adKey))?.name ?? adKey;

                                return (
                                    <div key={`creative-error-${adKey}`}>
                                        <p className={styles.facebookErrorOrigin}>Creative "{creativeName}"</p>
                                        <p className={styles.facebookErrorTitle}>{adError.error_user_title}</p>
                                        <p className={styles.facebookErrorMessage}>{adError.error_user_msg}</p>
                                    </div>
                                );
                            });
                        }))(),
                });
            }

            showNotification('Facebook campaign created', 'info');
        } catch (e) {
            setCreateFacebookCampaignError({
                title: 'Technical issues',
                message: e instanceof Error ? e.message : "Sorry, we're experiencing technical issues",
            });
        } finally {
            setCreateFacebookCampaignLoading(false);
        }
    }, [contextUtils, selectedRows]);

    const handleDragEnd = useCallback(
        async ({ draggableId, destination }: DropResult) => {
            if (!destination?.droppableId || !contextState.mediaPlan) {
                return;
            }

            const group = contextState.groups.find((g) => g.id.toString() === destination.droppableId);
            await updateMediaPlanItem(Number(draggableId), { group, media_plan: contextState.mediaPlan.id });
        },
        [contextState.groups, contextState.mediaPlan, updateMediaPlanItem]
    );

    const handleAddGroup = useCallback(async () => {
        if (!contextState.mediaPlan) {
            return;
        }

        try {
            setAddGroupLoading(true);
            await contextUtils.addGroup(contextState.mediaPlan.id, contextState.groups.length);
            showNotification('Group added', 'info');
        } catch {
            showNotification('Could not create group', 'error');
        } finally {
            setAddGroupLoading(false);
        }
    }, [contextState.groups.length, contextState.mediaPlan, contextUtils]);

    const handleIncreaseGroupOrderingIndex = useCallback(
        async (groupId: number) => {
            try {
                const reordered = await contextUtils.increaseGroupOrderingIndex(groupId);
                if (reordered) {
                    showNotification('Group re-ordered', 'info');
                }
            } catch {
                showNotification('Could not re-order group', 'error');
            }
        },
        [contextUtils]
    );

    const handleDecreaseGroupOrderingIndex = useCallback(
        async (groupId: number) => {
            try {
                const reordered = await contextUtils.decreaseGroupOrderingIndex(groupId);
                if (reordered) {
                    showNotification('Group re-ordered', 'info');
                }
            } catch {
                showNotification('Could not re-order group', 'error');
            }
        },
        [contextUtils]
    );

    useEffect(() => {
        if (urlState.postId && urlState.expandedGroups && contextState.initialized) {
            const id = urlState.postId;
            document.getElementById(id)?.scrollIntoView();
        }
    }, [contextState.initialized, urlState.postId, urlState.expandedGroups]);

    const postHighlightedId = Number(urlState.postId);

    return (
        <>
            <div className={styles.toolBar}>
                <div className={styles.selectedRowsToolbar}>
                    {selectedRowIds.length > 0 && (
                        <>
                            <div className={styles.selectedRows}>{selectedRowIds.length} Selected</div>
                            <ProtectedByUserGroups groups={['advertising_editor']}>
                                <Button
                                    type="bordered"
                                    className={cn(styles.button, styles.copyToClipboardButton)}
                                    Icon={DocumentCopyIcon}
                                    loading={copyToClipboardLoading}
                                    onClick={() => {
                                        if (contextState.mediaPlan) {
                                            copyToClipboard(contextState.mediaPlan, selectedRows);
                                        }
                                    }}
                                >
                                    Copy to clipboard
                                </Button>

                                {selectedRowIds.length === 1 && (
                                    <Button
                                        className={styles.button}
                                        type="bordered"
                                        Icon={CopyIcon}
                                        loading={duplicateMediaPlanItemLoading}
                                        onClick={duplicateMediaPlanItem}
                                    >
                                        Duplicate
                                    </Button>
                                )}

                                {selectedRowIds.length === 1 &&
                                    isFacebookBusinessManagerBuyPlatform(selectedRowIds[0]) && (
                                        <ProtectedByUserGroups groups={['facebook_api_editor']}>
                                            <Button
                                                className={styles.button}
                                                type="bordered"
                                                Icon={FacebookIcon}
                                                onClick={createFBCampaign}
                                                loading={createFacebookCampaignLoading}
                                            >
                                                Create fb
                                            </Button>

                                            <Modal
                                                closeOnOverlayClick
                                                isOpen={Boolean(createFacebookCampaignError)}
                                                onClose={() => setCreateFacebookCampaignError(null)}
                                            >
                                                <ModalTitle>{createFacebookCampaignError?.title}</ModalTitle>
                                                <ModalContent>{createFacebookCampaignError?.message}</ModalContent>
                                                <ModalFooter>
                                                    <Button
                                                        type="filled"
                                                        color="black"
                                                        onClick={() => setCreateFacebookCampaignError(null)}
                                                    >
                                                        OK
                                                    </Button>
                                                </ModalFooter>
                                            </Modal>
                                        </ProtectedByUserGroups>
                                    )}
                                <Button
                                    className={cn(styles.button, styles.deleteButton)}
                                    type="bordered"
                                    color="negative"
                                    Icon={TrashIcon}
                                    onClick={handleDeleteMediaPlanItems}
                                >
                                    Delete
                                </Button>
                            </ProtectedByUserGroups>
                        </>
                    )}
                </div>

                <div className={styles.actionButtons}>
                    <ProtectedByUserGroups groups={['advertising_editor']}>
                        <Button
                            type="filled"
                            color="black"
                            loading={addGroupLoading}
                            onClick={handleAddGroup}
                            disabled={!contextState.initialized}
                        >
                            Add plan group
                        </Button>
                    </ProtectedByUserGroups>

                    <Button
                        type="bordered"
                        className={styles.button}
                        onClick={() => setUrlState({ resultsModalOpen: true })}
                    >
                        View Results <CircleRightIcon className={styles.resultsIcon} />
                    </Button>

                    <ProtectedByUserGroups groups={['advertising_editor']}>
                        {contextState.mediaPlan &&
                            contextState.mediaPlanItemRows.length > 0 &&
                            contextState.groups.length > 0 && (
                                <ExportReports
                                    mediaPlan={contextState.mediaPlan}
                                    items={contextState.mediaPlanItemRows}
                                    groups={contextState.groups}
                                />
                            )}
                    </ProtectedByUserGroups>
                </div>
            </div>

            {!contextState.initialized && (
                <>
                    <div className={styles.group}>
                        <Skeleton className={styles.groupSkeleton} />
                    </div>
                    <div className={styles.group}>
                        <Skeleton className={styles.groupSkeleton} />
                    </div>
                    <div className={styles.group}>
                        <Skeleton className={styles.groupSkeleton} />
                    </div>
                </>
            )}

            {contextState.initialized && (
                <DragDropContext onDragEnd={handleDragEnd}>
                    {sortedGroups.map((group) => {
                        const groupMediaPlanItems = contextState.mediaPlanItemRows.filter((mediaPlanItem) => {
                            return mediaPlanItem.group?.id === group.id;
                        });

                        const earliestStartDate = Math.min(
                            ...groupMediaPlanItems.map((item) => {
                                return new Date(item.start_date).getTime();
                            })
                        );

                        const latestStartDate = Math.max(
                            ...groupMediaPlanItems.map((item) => {
                                return new Date(item.end_date).getTime();
                            })
                        );

                        const durationText =
                            isFinite(earliestStartDate) && isFinite(latestStartDate)
                                ? `${formatGroupDurationDate(earliestStartDate)} - ${formatGroupDurationDate(
                                      latestStartDate
                                  )}`
                                : '-';

                        const budget = groupMediaPlanItems.reduce((acc, item) => acc + Number(item.cost), 0);
                        const budgetText = contextState.mediaPlan?.currency
                            ? asMoneyWithoutZeros(budget, contextState.mediaPlan.currency)
                            : '';
                        const amountSpent = groupMediaPlanItems.reduce(
                            (total, { amount_spent_in_media_plan_currency }) =>
                                applyCommission(
                                    amount_spent_in_media_plan_currency ?? 0,
                                    Number(contextState.mediaPlan?.client_commission ?? 0)
                                ) + total,
                            0
                        );
                        const amountSpentText = contextState.mediaPlan?.currency
                            ? asMoneyWithoutZeros(amountSpent, contextState.mediaPlan.currency)
                            : '';
                        const percentSpentOfGroupBudget =
                            budget === 0 ? 0 : toDecimalPoint((amountSpent / budget) * 100, 1);

                        const groupExpanded = expandedGroups.includes(group.id);
                        const toggleGroup = () => {
                            if (groupExpanded) {
                                setExpandedGroups(expandedGroups?.filter((id) => id !== group.id) ?? []);
                                return;
                            }

                            setExpandedGroups((expandedGroups ?? []).concat(group.id));
                        };

                        return (
                            <Droppable key={group.id} droppableId={group.id.toString()}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps} className={styles.group}>
                                        <AdvertisingGroupBar
                                            key={group.id}
                                            expanded={groupExpanded}
                                            onExpandToggle={toggleGroup}
                                            group={group}
                                            numberOfMediaPlanItems={groupMediaPlanItems.length}
                                            duration={durationText}
                                            budget={budgetText}
                                            amountSpent={amountSpentText}
                                            percentSpentOfGroupBudget={percentSpentOfGroupBudget}
                                            onGroupDelete={handleDelete}
                                            onGroupNameChange={handleGroupNameChange}
                                            onDuplicateGroup={handleDuplicateGroup}
                                            onCreateItem={handleCreateMediaPlanItem}
                                            onGroupOrderingIndexDecrease={handleDecreaseGroupOrderingIndex}
                                            onGroupOrderingIndexIncrease={handleIncreaseGroupOrderingIndex}
                                        >
                                            <AdvertisingTable
                                                rows={groupMediaPlanItems}
                                                postHighlightedId={postHighlightedId}
                                                selectedRowIds={selectedRowIds}
                                                onRowSelect={handleRowsSelect}
                                                onAllRowsSelect={handleAllRowsSelect}
                                                updateMediaPlanItem={updateMediaPlanItem}
                                                unlinkCampaign={unlinkFacebookCampaign}
                                                onTargetingCreated={contextUtils.addTargeting}
                                                onTargetingUpdated={contextUtils.updateTargeting}
                                                onTargetingDeleted={contextUtils.deleteTargeting}
                                                onCreativeCreated={contextUtils.addCreative}
                                                onCreativeDeleted={contextUtils.deleteCreative}
                                                onCreativeUpdated={contextUtils.updateCreative}
                                            />
                                        </AdvertisingGroupBar>
                                    </div>
                                )}
                            </Droppable>
                        );
                    })}
                </DragDropContext>
            )}

            <MediaPlanResultsModal
                isModalOpen={isResultsModalOpen}
                closeModal={() => setUrlState({ resultsModalOpen: undefined }, { replace: true })}
            />
        </>
    );
};

export default AdvertisingGroups;
