import styles from './CampaignDetails.module.css';
import { ReactComponent as ArrowRightIcon } from 'assets/ArrowRight.svg';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import IconButton from 'ui-new/Buttons/IconButton/IconButton';
import { Image, TabNavItem, TabPanel, TabPanels, Tabs } from '@round/ui-kit';
import Skeleton from 'react-loading-skeleton';
import { microwave } from '@round/api';
import { ReactComponent as InstagramIcon } from 'assets/icons/platform/Instagram.svg';
import { ReactComponent as TikTokIcon } from 'assets/icons/platform/TikTok.svg';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { useCampaigns } from './contexts/CampaignsContext';
import { useInstagramCampaignStats } from './contexts/InstagramCampaignStatsContext';
import { isNumber } from 'utility/utility';
import Campaign from './Campaign/Campaign';
import { CampaignStats } from './helpers';
import useMicrowaveRelease from '../hooks/useMicrowaveRelease';
import useUrlState from 'Hooks/useUrlState';

type Props = {
    releaseId: number;
};

const getPlatform = (hash: string): microwave.Platform | undefined => {
    if (!hash) {
        return;
    }

    const platform = hash.slice(1);
    return microwave.isPlatform(platform) ? platform : undefined;
};

type UrlState = {
    expandedCampaigns?: string;
};

const CampaignDetails = ({ releaseId }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const activePlatform = getPlatform(location.hash);
    const setActivePlatform = (platform: microwave.Platform | undefined) => {
        navigate({ hash: `#${platform}` }, { replace: true });
    };

    const [urlState, setUrlState] = useUrlState<UrlState>();
    const expandedCampaigns =
        urlState.expandedCampaigns
            ?.split(',')
            .filter((v) => !!v)
            .map(Number) ?? [];

    const toggleCampaignExpand = (campaignId: number) => {
        if (expandedCampaigns.includes(campaignId)) {
            setUrlState({ expandedCampaigns: expandedCampaigns.filter((c) => c !== campaignId).toString() });
            return;
        }

        setUrlState({ expandedCampaigns: expandedCampaigns.concat(campaignId).toString() });
    };

    const { data: release, status: releaseStatus, fetchData: fetchRelease } = useMicrowaveRelease();
    const { fetchData: fetchCampaigns, data: campaigns, status: campaignsStatus } = useCampaigns();
    const {
        fetchData: fetchInstagramCampaignStats,
        data: instagramCampaignStats,
        status: instagramCampaignStatsStatus,
    } = useInstagramCampaignStats();

    useAbortableEffect(
        (signal) => {
            if (releaseStatus !== 'success' && releaseStatus !== 'error') {
                fetchRelease(releaseId, { signal }).catch(() => {});
            }
        },
        [fetchRelease, releaseId, releaseStatus]
    );

    useAbortableEffect(
        (signal) => {
            if (campaignsStatus !== 'error' && campaignsStatus !== 'success') {
                fetchCampaigns(releaseId, { signal }).catch(() => {});
            }
        },
        [campaignsStatus, fetchCampaigns, releaseId]
    );

    useAbortableEffect(
        (signal) => {
            const shouldInit =
                campaignsStatus === 'success' &&
                instagramCampaignStatsStatus !== 'error' &&
                instagramCampaignStatsStatus !== 'success';

            if (shouldInit) {
                fetchInstagramCampaignStats(
                    campaigns
                        ?.filter((c) => c.platform === 'instagram')
                        .map((c) => c.instagram_campaign_id)
                        .filter(isNumber) ?? [],
                    { signal }
                ).catch(() => {});
            }
        },
        [campaigns, campaignsStatus, fetchInstagramCampaignStats, instagramCampaignStatsStatus]
    );

    if (!activePlatform) {
        return <Navigate to="#instagram" replace />;
    }

    const isReleaseLoading = releaseStatus === 'idle' || releaseStatus === 'loading';
    return (
        <main className={styles.container}>
            <div className={styles.actions}>
                <IconButton className={styles.goBackButton} onClick={() => navigate(-1)}>
                    <ArrowRightIcon className={styles.icon} />
                </IconButton>
            </div>

            <section className={styles.releaseSection}>
                <Image
                    className={styles.releaseImage}
                    src={release?.brand_image}
                    loading={isReleaseLoading}
                    skeletonCircle={false}
                />

                <div className={styles.releaseInfoContainer}>
                    <div className={styles.releaseTitles}>
                        <h1 className={styles.releaseTitle}>
                            {isReleaseLoading ? <Skeleton width="15rem" /> : release?.name}
                        </h1>
                        <p className={styles.releaseSubTitle}>
                            {isReleaseLoading ? (
                                <Skeleton width="10rem" />
                            ) : (
                                `${release?.brand_name} | ${release?.client_name}`
                            )}
                        </p>
                    </div>
                </div>
            </section>

            <section className={styles.campaignsSection}>
                <Tabs activeTab={activePlatform} onChange={setActivePlatform}>
                    <div className={styles.tabNavigation}>
                        <TabNavItem
                            className={styles.tabNavItem}
                            activeClassName={styles.tabNavItemActive}
                            name="instagram"
                        >
                            <InstagramIcon className={styles.platformIcon} /> Instagram
                        </TabNavItem>
                        <TabNavItem
                            className={styles.tabNavItem}
                            activeClassName={styles.tabNavItemActive}
                            name="tiktok"
                        >
                            <TikTokIcon className={styles.platformIcon} /> TikTok
                        </TabNavItem>
                    </div>

                    <TabPanels>
                        <TabPanel name="instagram">
                            {campaignsStatus === 'success' &&
                                instagramCampaignStatsStatus === 'success' &&
                                campaigns
                                    ?.filter((campaign) => campaign.platform === 'instagram')
                                    .map((campaign) => {
                                        const stats = instagramCampaignStats?.find(
                                            (stats) => stats.instagramCampaignId === campaign.instagram_campaign_id
                                        );

                                        const campaignStats: CampaignStats | undefined = stats
                                            ? {
                                                  amountSpent: stats.amount_spent,
                                                  engagementRate: stats.engagement_rate,
                                                  postCount: stats.post_count,
                                                  totalViews: stats.total_views,
                                              }
                                            : undefined;

                                        return (
                                            <Campaign
                                                key={campaign.id}
                                                className={styles.campaign}
                                                campaign={campaign}
                                                stats={campaignStats}
                                                isExpanded={expandedCampaigns.includes(campaign.id)}
                                                onExpand={() => toggleCampaignExpand(campaign.id)}
                                            >
                                                Expanded content
                                            </Campaign>
                                        );
                                    })}
                        </TabPanel>
                        <TabPanel name="tiktok">
                            {campaigns
                                ?.filter((campaign) => campaign.platform === 'instagram')
                                .map((campaign) => (
                                    <div key={campaign.id}>{campaign.name}</div>
                                ))}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </section>
        </main>
    );
};

export default CampaignDetails;
