import { Context, useContextSelector } from '@fluentui/react-context-selector';
export { createContext as createSelectableContext } from '@fluentui/react-context-selector';

export type ContextSelector<Value, Selected> = (value: NonNullable<Value>) => Selected;

export function useNonNullContextSelector<Value, Selected>(
    context: Context<Value>,
    selector: ContextSelector<Value, Selected>
): Selected {
    const values = useContextSelector(context, (value) => {
        if (!value) {
            throw new Error('Context should be used withing a Provider');
        }

        return selector(value);
    });

    return values;
}
