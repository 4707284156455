import cn from 'classnames';
import { capitalize } from 'lodash';
import {
    buildInstagramUserUrl,
    buildTiktokUserUrl,
    formatNumberToKNotation,
    numberWithCommas,
} from '../../../../../helpers';
import ProgressBar from '../../../../../ui/ProgressBar/ProgressBar';
import { FCWithChildren } from '../../../../../utility/utility.types';
import { AdvertisingInvoicePlanItemRow, InvoicePlanItemRow } from '../../InvoiceBar/InvoiceBar';
import styles from './InvoicePlanItemTableRow.module.css';

type InvoicePlanItemTableRowProps = {
    row: InvoicePlanItemRow;
};

function isItemAdvertising(row: InvoicePlanItemRow): row is AdvertisingInvoicePlanItemRow {
    return (row as AdvertisingInvoicePlanItemRow).campaign_type === 'advertising';
}

const InvoicePlanItemTableRow: FCWithChildren<InvoicePlanItemTableRowProps> = ({ row }) => {
    const percentageSpent =
        typeof row.round_cost !== 'number'
            ? '-'
            : row.spent !== 0 && row.round_cost !== 0
            ? (row.spent / row.round_cost) * 100
            : 0;

    const linkToUserUrl = !isItemAdvertising(row)
        ? row.item_details.platform === 'tiktok'
            ? buildTiktokUserUrl(row.item_details.username)
            : buildInstagramUserUrl(row.item_details.username)
        : '';

    const followers =
        !isItemAdvertising(row) && row.item_details.followers
            ? `${formatNumberToKNotation(row.item_details.followers)} followers`
            : '-';

    return (
        <tr className={styles.row}>
            <td className={cn(styles.td, styles.idColumn)}>
                <a href={row.url} rel="noreferrer" target="_blank">
                    {row.id}
                </a>
            </td>
            <td className={cn(styles.td, styles.itemColumn)}>
                {isItemAdvertising(row) && <div>{row.name}</div>}
                {!isItemAdvertising(row) && (
                    <div className={styles.influencerDetails}>
                        {row.item_details.profile_pic_url && (
                            <img
                                className={styles.influencerThumbnail}
                                src={row.item_details.profile_pic_url}
                                alt="avatar"
                                crossOrigin="anonymous"
                            />
                        )}
                        <div>
                            <div>
                                {row.item_details.username ? (
                                    <a
                                        onClick={(e) => e.stopPropagation()}
                                        className={styles.influencerName}
                                        href={linkToUserUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        @{row.item_details.username}
                                    </a>
                                ) : (
                                    '-'
                                )}
                            </div>
                            <div className={styles.influencerFollowers}>{followers}</div>
                        </div>
                    </div>
                )}
            </td>
            <td className={styles.td}>{`${row.plan_currency?.symbol}${numberWithCommas(
                row.client_cost.toFixed(2)
            )}`}</td>
            <td className={styles.td}>
                {typeof row.round_cost === 'number'
                    ? `${row.plan_currency?.symbol}${numberWithCommas(row.round_cost.toFixed(2))}`
                    : '-'}
            </td>
            <td className={cn(styles.td, styles.spentColumn)}>
                {typeof row.round_cost === 'number' && typeof percentageSpent === 'number' ? (
                    <>
                        <div className={styles.spentInfo}>
                            <span className={styles.spentValue}>{`${row.plan_currency?.symbol}${numberWithCommas(
                                row.spent.toFixed(2)
                            )}`}</span>
                            <span>{`${percentageSpent.toFixed(0)}%`}</span>
                        </div>
                        <ProgressBar
                            progress={Math.min(100, percentageSpent)}
                            trackStyles={{ height: '0.4rem' }}
                            barStyles={{ backgroundColor: row.spent > row.round_cost * 1.05 ? '#F7685B' : '' }}
                        />
                    </>
                ) : (
                    <div className={styles.spentInfo}>
                        <span className={styles.spentValue}>{`${row.plan_currency?.symbol}${numberWithCommas(
                            row.spent.toFixed(2)
                        )}`}</span>
                        <span>-</span>
                    </div>
                )}
            </td>
            <td className={cn(styles.td)}>{row.brand}</td>
            <td className={cn(styles.td)}>{row.campaign}</td>
            <td className={cn(styles.td)}>{row.client}</td>
            <td className={cn(styles.td)}>{capitalize(row.campaign_type)}</td>
        </tr>
    );
};

export default InvoicePlanItemTableRow;
