import { ReducerActionWithPayload } from '../../App.types';
import { createReducer } from '../../helpers';
import { Currency, InfluencerTag } from '@round/api';

export type OptionsContextState = {
    currencies: Currency[];
    influencerTags: InfluencerTag[];
    tagsInitialized: boolean;
};

export type OptionsContextActions =
    | ReducerActionWithPayload<'currenciesLoaded', Currency[]>
    | ReducerActionWithPayload<'influencerTagsLoaded', InfluencerTag[]>;

export const initialState: OptionsContextState = {
    currencies: [],
    influencerTags: [],
    tagsInitialized: false,
};

export default createReducer<OptionsContextState, OptionsContextActions>({
    currenciesLoaded: (state, { payload }) => ({
        ...state,
        currencies: payload,
    }),
    influencerTagsLoaded: (state, { payload }) => ({
        ...state,
        influencerTags: payload,
        tagsInitialized: true,
    }),
});
