import { AppServer } from '../../mirage';
import * as apexInfluencerMirage from './influencer/apexInfluencer.mirage';
import * as clientCampaignMirage from './campaign/clientCampaign.mirage';
import * as clientBookingMirage from './booking/booking.mirage';

export const models = {
    ...apexInfluencerMirage.models,
    ...clientCampaignMirage.models,
    ...clientBookingMirage.models,
};

export const serializers = {
    ...apexInfluencerMirage.serializers,
    ...clientCampaignMirage.serializers,
    ...clientBookingMirage.serializers,
};

export const factories = {
    ...apexInfluencerMirage.factories,
    ...clientCampaignMirage.factories,
    ...clientBookingMirage.factories,
};

export function handleClientRequests(server: AppServer) {
    apexInfluencerMirage.handleApexInfluencerRequests(server);
    clientCampaignMirage.handleClientCampaignRequests(server);
    clientBookingMirage.handleRequests(server);
}
