import { DomainFiltersProps, getInitialFilters } from 'ui-new/Filters/helpers';
import { Filter } from 'ui-new/Filters/useFilterManager';
import { ReactComponent as StatusIcon } from 'assets/icons/Status.svg';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import styles from './PastPaymentsFilters.module.css';
import { paymentStatusOptions } from 'Modules/Advertising/Microwave/constants';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import MicrowaveInfluencerFilter from 'Modules/Advertising/Microwave/components/Filters/MicrowaveInfluencerFilter/MicrowaveInfluencerFilter';
import { ReactComponent as HashIcon } from 'assets/icons/Hash.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/Share.svg';
import TextFilter from 'ui-new/Filters/TextFilter/TextFilter';
import Filters from 'ui-new/Filters/Filters';
import { getNonEmptyKeys } from 'utility/utility';
import { microwave } from '@round/api';
import PlatformFilter from 'Modules/Advertising/Microwave/components/Filters/PlatformFilter/PlatformFilter';

export type PastPaymentsFiltersParams = Pick<
    microwave.GetPaymentRequestsParams,
    'payment_status' | 'influencer_id' | 'invite_id' | 'platform'
>;

type Props = DomainFiltersProps<PastPaymentsFiltersParams>;

const paymentStatusFilterOptions = paymentStatusOptions.filter((o) => o.value !== 'UNPAID');

export const getFilterValues = (
    urlState: Partial<Record<keyof PastPaymentsFiltersParams, string>>
): PastPaymentsFiltersParams => {
    return {
        influencer_id: urlState.influencer_id ? Number(urlState.influencer_id) : undefined,
        invite_id: urlState.invite_id,
        payment_status: (urlState.payment_status as PastPaymentsFiltersParams['payment_status']) || 'PAID',
        platform: urlState.platform,
    };
};

const PastPaymentsFilters = ({ value, onChange }: Props) => {
    const filters: Filter<keyof PastPaymentsFiltersParams>[] = [
        {
            name: 'payment_status',
            label: 'Status',
            icon: StatusIcon,
            render(this, helpers) {
                const Icon = this.icon;
                const selectedValue = paymentStatusFilterOptions.find((o) => o.value === value.payment_status);

                return (
                    <SelectFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        name={this.label}
                        icon={<Icon className={styles.icon} />}
                        isSearchable={false}
                        options={paymentStatusFilterOptions}
                        value={selectedValue}
                        onChange={(val) => onChange({ payment_status: val?.value })}
                        onClear={() => onChange({ payment_status: undefined })}
                        onRemove={() => {
                            onChange({ payment_status: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'influencer_id',
            label: 'Account',
            icon: UserIcon,
            render(this, helpers) {
                const Icon = this.icon;

                return (
                    <MicrowaveInfluencerFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        name={this.label}
                        icon={<Icon className={styles.icon} />}
                        initialInfluencerId={value.influencer_id}
                        onChange={(val) => onChange({ influencer_id: val?.value })}
                        onClear={() => onChange({ influencer_id: undefined })}
                        onRemove={() => {
                            onChange({ influencer_id: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'invite_id',
            label: 'Invite ID',
            icon: HashIcon,
            render(this, helpers) {
                const Icon = this.icon;

                return (
                    <TextFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        icon={<Icon className={styles.icon} />}
                        name={this.label}
                        placeholder="Enter invite ID"
                        value={value.invite_id ?? ''}
                        onChange={(val) => onChange({ invite_id: val ?? undefined })}
                        onClear={() => {
                            onChange({ invite_id: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'platform',
            label: 'Platform',
            icon: ShareIcon,
            render(this, helpers) {
                const Icon = this.icon;
                return (
                    <PlatformFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        icon={<Icon className={styles.icon} />}
                        name={this.label}
                        value={value.platform as microwave.Platform}
                        onChange={(val) => onChange({ platform: val })}
                        onClear={() => onChange({ platform: undefined })}
                        onRemove={() => {
                            onChange({ platform: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
    ];

    return (
        <Filters
            sessionKey="microwave-past-payments-filters-v2"
            filters={filters}
            options={{ initialFilters: (state) => getInitialFilters(state, getNonEmptyKeys(value)) }}
        />
    );
};

export default PastPaymentsFilters;
