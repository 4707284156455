import { Skeleton } from '@round/ui-kit';
import styles from './ReleaseCell.module.css';
import ReleaseBadge from 'Modules/Advertising/components/ReleaseBadge/ReleaseBadge';
import { Release } from '@round/api';

type Props = {
    release: Release | undefined;
    isLoading?: boolean;
};

const ReleaseCell = ({ release, isLoading }: Props) => {
    if (isLoading) {
        return <Skeleton />;
    }

    if (!release) {
        return <>-</>;
    }

    return (
        <div className={styles.container}>
            <ReleaseBadge
                releaseName={release.name}
                artistName={release.brand.name}
                imageUrl={release.brand.picture}
                isLoading={!!isLoading}
            />
        </div>
    );
};

export default ReleaseCell;
