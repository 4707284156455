import { AppServer, RouteHandlerContext } from '../../mirage';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TrndsClientSongTiktokAudioPostStats, TrndsClientSongTiktokAudioStats } from './song.types';

export const models = {
    trndsClientSongTiktokAudioStat: Model as ModelDefinition<TrndsClientSongTiktokAudioStats>,
    trndsClientSongTiktokAudioPostStat: Model as ModelDefinition<TrndsClientSongTiktokAudioPostStats>,
};

export const serializers = {
    trndsClientSongTiktokAudioStat: makeSerializer<TrndsClientSongTiktokAudioStats>([]),
    trndsClientSongTiktokAudioPostStat: makeSerializer<TrndsClientSongTiktokAudioPostStats>([]),
};

export const factories = {
    trndsClientSongTiktokAudioStat: createFactory<TrndsClientSongTiktokAudioStats>({
        audio_id(index: number) {
            return index;
        },
        tiktok_id(index: number) {
            return `tiktok id ${index}`;
        },
        author_name(index: number) {
            return `author name ${index}`;
        },
        title(index: number) {
            return `title ${index}`;
        },
        video_count: 0,
        is_original: false,
        video_count_daily_change: 0,
        video_count_daily_change_relative: '0.00',
        video_count_weekly_change: 0,
        video_count_weekly_change_relative: '0.00',
        standardised_data: {
            daily_video_counts: [],
        },
    }),
    trndsClientSongTiktokAudioPostStat: createFactory<TrndsClientSongTiktokAudioPostStats>({
        audio_alternate_name(index: number) {
            return `audio alternate name ${index}`;
        },
        audio_author_name(index: number) {
            return `audio author name ${index}`;
        },
        audio_is_original: false,
        audio_tiktok_id(index: number) {
            return `audio tiktok id ${index}`;
        },
        audio_title(index: number) {
            return `audio title ${index}`;
        },
        author_follower_count: 0,
        author_name(index: number) {
            return `author name ${index}`;
        },
        author_username(index: number) {
            return `author username ${index}`;
        },
        caption(index: number) {
            return `caption ${index}`;
        },
        create_time() {
            return new Date().getTime() / 1000;
        },
        location: 'UK',
        play_count: 0,
        share_count: 0,
    }),
};

export function handleRequests(server: AppServer) {
    const getClientSongTiktokStatsUrl = '/api/trnds/client/:clientId/song-tiktok-stats/';
    server.get(getClientSongTiktokStatsUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('songTiktokStat'), {
            url: getClientSongTiktokStatsUrl,
            serialize: (resource) => this.serialize(resource, 'songTiktokStat'),
            queryParams: request.queryParams,
        });
    });

    server.get(
        '/api/trnds/client/:clientId/song-tiktok-stats/:songId/',
        function (this: RouteHandlerContext, schema, request) {
            const stats = schema
                .all('songTiktokStat')
                .filter((stats) => Number(stats.song.id) === Number(request.params.songId)).models[0];

            if (!stats) {
                return new Response(404, {}, { detail: 'Not found.' });
            }

            return stats;
        }
    );

    const getClientSongTiktokAudioStats = '/api/trnds/client/:clientId/song/:songId/tiktok-audio-stats/';
    server.get(getClientSongTiktokAudioStats, function (this: RouteHandlerContext, schema, request) {
        const stats = schema.all('trndsClientSongTiktokAudioStat');
        return buildPaginatedResponse(stats, {
            url: getClientSongTiktokAudioStats,
            serialize: (resource) => this.serialize(resource, 'trndsClientSongTiktokAudioStat'),
            queryParams: request.queryParams,
        });
    });

    const getClientSongTiktokAudioPostStats = '/api/trnds/client/:clientId/song/:songId/tiktok-audio-post-stats/';
    server.get(getClientSongTiktokAudioPostStats, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const stats = schema
            .all('trndsClientSongTiktokAudioPostStat')
            .filter((stats) => !search || stats.audio_title.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(stats, {
            url: getClientSongTiktokAudioPostStats,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource),
        });
    });
}
