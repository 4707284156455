import { YouTubeInfluencerPost, YoutubeInfluencerPostPatchData } from '@round/api';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { getObjectDifference, showNotification } from 'helpers';
import useNonNullContext from 'Hooks/useNonNullContext';
import { useMemo, useState } from 'react';
import Button from 'ui/Buttons/Button/Button';
import Modal, { ModalContent, ModalTitle, ModalFooter } from 'ui/General/Modal/Modal';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import useYoutubeInfluencerPosts from '../../contexts/YoutubeCreatorsContext/hooks/useYoutubeInfluencerPosts';
import DeletePostConfirmationModal from './DeletePostConfirmationModal';
import styles from './InfluencerPlanItemModal.module.css';
import YoutubeInfluencerPostForm, { FormValues } from './InfluencerPostForm/YoutubeInfluencerPostForm';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
    post: YouTubeInfluencerPost | null;
};

const UpdateYoutubeInfluencerPostModal = ({ isOpen, closeModal, post }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const { updatePost } = useYoutubeInfluencerPosts();

    const initialValues: FormValues = useMemo(() => {
        const currency = currencies.find((c) => c.id === post?.currency_id);
        return {
            cost: post?.cost ?? '',
            currency: currency ? { value: currency.id, label: currency.name } : null,
            post_url: post?.post_url ?? '',
        };
    }, [currencies, post?.cost, post?.currency_id, post?.post_url]);

    const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        if (!post) {
            return;
        }

        const mapped: Partial<YoutubeInfluencerPostPatchData> = {
            cost: values.cost,
            currency_id: values.currency?.value,
            post_url: values.post_url,
        };

        const updatedValues = getObjectDifference(mapped, post);
        try {
            helpers.setSubmitting(true);
            const response = await updatePost(post.id, updatedValues);
            if (response.status === 200) {
                showNotification('Post updated', 'info');
                closeModal();
                return;
            }

            if (response.status === 404) {
                showNotification('Post was not found', 'error');
                return;
            }

            helpers.setErrors(
                mapApiErrorsToFormikErrors({
                    cost: response.data.cost,
                    currency: response.data.currency_id,
                    post_url: response.data.post_url,
                })
            );
            return;
        } catch {
            showNotification('Could not update post', 'error');
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const formikProps = useFormik<FormValues>({
        initialValues: initialValues,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const { deletePost } = useYoutubeInfluencerPosts();

    const handleDelete = async () => {
        if (!post) {
            return;
        }

        try {
            setIsDeleteLoading(true);
            const response = await deletePost(post.id);

            if (response.status === 204) {
                showNotification('Post deleted', 'info');
                closeModal();
                return;
            }

            const errorMessage =
                response.status === 404
                    ? 'Could not delete post. Post does not exist'
                    : response.data?.join('. ') ?? '';

            showNotification(errorMessage, 'error');
        } catch {
            showNotification('Could not delete post', 'error');
        } finally {
            setIsDeleteLoading(false);
            setShowDeletePrompt(false);
        }
    };

    const readOnlyFields: (keyof FormValues)[] = post?.payment_status === 'PAID' ? ['currency', 'cost'] : [];

    return (
        <>
            <Modal closeOnOverlayClick isOpen={isOpen} onClose={closeModal} modalClassName={styles.modal}>
                <ModalTitle>Change details</ModalTitle>
                <ModalContent className={styles.content}>
                    <FormikProvider value={formikProps}>
                        <YoutubeInfluencerPostForm readOnlyFields={readOnlyFields} />
                    </FormikProvider>
                </ModalContent>
                <ModalFooter align="left" className={styles.renderActions}>
                    <Button
                        className={styles.submitButton}
                        type="filled"
                        color="black"
                        disabled={!formikProps.dirty}
                        loading={formikProps.isSubmitting}
                        onClick={formikProps.submitForm}
                    >
                        Save
                    </Button>

                    <Button
                        className={styles.deleteButton}
                        type="filled"
                        color="negative"
                        loading={isDeleteLoading}
                        onClick={() => setShowDeletePrompt(true)}
                        disabled={post?.payment_status === 'PAID'}
                    >
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>

            <DeletePostConfirmationModal
                handleDelete={handleDelete}
                isModalOpen={showDeletePrompt}
                deleteLoading={isDeleteLoading}
                onClose={() => setShowDeletePrompt(false)}
            />
        </>
    );
};

export default UpdateYoutubeInfluencerPostModal;
