import { IconProps } from './Icon.types';

const VideoCameraIcon = ({
    width,
    height,
    title = 'Video camera',
    onClick,
    className,
    ariaRole = 'img',
    ariaLabel,
    stroke,
}: IconProps) => (
    <svg
        className={className}
        onClick={onClick}
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label={ariaLabel}
        role={ariaRole}
    >
        <title>{title}</title>
        <path
            d="M8.35337 13.6134H4.14004C2.03337 13.6134 1.33337 12.2134 1.33337 10.8067V5.19339C1.33337 3.08672 2.03337 2.38672 4.14004 2.38672H8.35337C10.46 2.38672 11.16 3.08672 11.16 5.19339V10.8067C11.16 12.9134 10.4534 13.6134 8.35337 13.6134Z"
            stroke={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.0134 11.4001L11.16 10.1001V5.89342L13.0134 4.59342C13.92 3.96009 14.6667 4.34675 14.6667 5.46009V10.5401C14.6667 11.6534 13.92 12.0401 13.0134 11.4001Z"
            stroke={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.66663 7.33398C8.21891 7.33398 8.66663 6.88627 8.66663 6.33398C8.66663 5.7817 8.21891 5.33398 7.66663 5.33398C7.11434 5.33398 6.66663 5.7817 6.66663 6.33398C6.66663 6.88627 7.11434 7.33398 7.66663 7.33398Z"
            stroke={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default VideoCameraIcon;
