import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { YoutubeVideo } from './videos.types';

export const models = {
    youtubeVideo: Model as ModelDefinition<YoutubeVideo>,
};

export const serializers = {
    youtubeVideo: makeSerializer<YoutubeVideo>([]),
};

export const factories = {
    youtubeVideo: createFactory<YoutubeVideo>({
        published_at: new Date().toISOString(),
        comment_count: null,
        like_count: null,
        view_count: null,
        url: '',
    }),
};

export function handleRequests(server: AppServer) {
    const getVideosUrl = '/api/youtube/videos/';
    server.get(getVideosUrl, function (this: RouteHandlerContext, schema, request) {
        const { id = '' } = request.queryParams;
        const ids = id
            .split(',')
            .filter((v) => !!v)
            .map(Number);
        const videos = schema.all('youtubeVideo').filter((video) => !ids.length || ids.includes(video.id));
        return buildPaginatedResponse(videos, {
            url: getVideosUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'youtubeVideo'),
        });
    });
}
