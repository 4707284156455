import { useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { makeUserHasGroupAccess } from '../../../helpers';
import { UserGroup } from '@round/api';

export function useCheckUserGroupsAccess(userGroups: UserGroup[], hasAccessWhenMatches: 'all' | 'any' = 'all') {
    const [hasAccess, setHasAccess] = useState<Boolean | null>(null);
    const authContext = useContext(AuthContext);
    const userHasGroupAccess = useMemo(() => makeUserHasGroupAccess(authContext.user?.groups ?? []), [
        authContext.user?.groups,
    ]);

    useEffect(() => {
        if (!authContext.user) {
            return;
        }

        setHasAccess(userHasGroupAccess(userGroups, hasAccessWhenMatches));
    }, [authContext.user, hasAccessWhenMatches, userGroups, userHasGroupAccess]);

    return hasAccess;
}
