import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, belongsTo, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { createFactory, makeSerializer, buildPaginatedResponse } from '../../mirage.helpers';
import { Release } from './release.types';

export const models = {
    release: Model.extend({
        type: belongsTo('releaseType'),
        brand: belongsTo('brand'),
    }) as ModelDefinition<Release>,
};

export const factories = {
    release: createFactory<Release>({
        id(index: number) {
            return index;
        },
        name(index: number) {
            return `release name: ${index}`;
        },
        song(index: number) {
            return index;
        },
        afterCreate(release, s) {
            const server = s as AppServer;
            if (!release.type) {
                release.update({
                    type: server.create('releaseType'),
                });
            }

            if (!release.brand) {
                release.update({
                    brand: server.create('brand'),
                });
            }
        },
    }),
};

export const serializers = {
    release: makeSerializer<Release>(['brand', 'type']),
};

export function handleReleaseRequests(server: AppServer) {
    const allReleasesPath = '/api/advertising/viewsets/release/';
    const releaseByIdPath = '/api/advertising/viewsets/release/:id/';

    server.get(allReleasesPath, function (this: RouteHandlerContext, schema, request) {
        const releases = schema.all('release');
        return buildPaginatedResponse(releases, {
            url: allReleasesPath,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'release'),
        });
    });

    server.get(releaseByIdPath, (schema, request) => {
        const release = schema.find('release', request.params.id);
        if (!release) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return release;
    });

    server.post(allReleasesPath, (schema, request) => {
        const body = JSON.parse(request.requestBody);
        const release = schema.create('release', {
            name: body.name,
            brand_id: body.brand.id,
            type_id: body.type.id,
            song: body.song ?? null,
        });

        return schema.find('release', release.id);
    });

    server.patch(releaseByIdPath, (schema, request) => {
        const release = schema.find('release', request.params.id);

        if (!release) {
            return new Response(404);
        }

        const body = JSON.parse(request.requestBody);

        release.update({
            name: body.name ?? release.name,
            brand: body.brand ?? release.brand,
            type: body.type ?? release.type,
            song: body.song ?? release.song ?? null,
        });
        return release;
    });

    server.delete(releaseByIdPath, (schema, request) => {
        const release = schema.find('release', request.params.id);

        if (!release) {
            return new Response(404);
        }

        release.destroy();
        return new Response(204);
    });
}
