import React, { useCallback, useMemo, useState } from 'react';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../ui/General/Modal/Modal';
import { Form, Formik, FormikHelpers, Field, FieldProps, ErrorMessage } from 'formik';
import Button from '../../../../../ui/Buttons/Button/Button';
import { StylesConfig, ValueType } from 'react-select';
import { GenericDropdownOption, TagOption } from '../../../../../App.types';
import FormField from '../../../../../ui/DataEntry/FormField/FormField';
import Label from '../../../../../ui/DataEntry/Label/Label';
import Select from '../../../../../ui/DataEntry/Select/Select';
import TextArea from '../../../../../SharedComponents/Forms/TextArea/TextArea';
import styles from './AddInfluencerModal.module.css';
import { postTiktokInfluencerUser } from '../../../TikTok.api';
import { mapApiErrorsToFormikErrors } from '../../../../../utility/utility';
import { Banner } from '@round/ui-kit';
import { Currency, TiktokInfluencerUser, TiktokInfluencerUserApiBody } from '@round/api';
import * as yup from 'yup';

type AddInfluencerModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    contentTagOptions: GenericDropdownOption<number>[];
    locationTagOptions: TagOption[];
    currencies: Currency[];
};

type FormValues = Pick<TiktokInfluencerUserApiBody, 'username' | 'notes' | 'contact_details' | 'paypal_email'> & {
    content_tags: ValueType<GenericDropdownOption<number>, true>;
    locations: ValueType<TagOption, true>;
    currency: GenericDropdownOption<number> | null;
    cost: string;
    brand_promotion_currency: GenericDropdownOption<number> | null;
    brand_promotion_cost: string;
};

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        borderColor: '#C2CFE0',
        fontSize: '0.75rem',
        borderRadius: '0.1875rem',
    }),
    placeholder: (base) => ({
        ...base,
        color: '#90A0B7',
        fontSize: '0.8125rem',
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '0 1rem',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: '0.5rem',
    }),
};

const validationSchema = yup.object({
    username: yup.string().required('Username is required'),
    content_tags: yup.array().min(1, 'Content tags field is required').typeError('Content Tags field is required'),
    contact_details: yup.string().required('Contact details is required'),
    currency: yup
        .object()
        .required('Sound Promotion Currency is required')
        .typeError('Sound Promotion Currency is required'),
    cost: yup.number().required('Sound Promotion Cost is required').typeError('You must specify a cost number'),
    brand_promotion_currency: yup
        .object()
        .required('Brand Promotion Currency is required')
        .typeError('Brand Promotion Currency is required'),
    brand_promotion_cost: yup
        .number()
        .required('Brand Promotion Cost is required')
        .typeError('You must specify a cost number'),
});

const AddInfluencerModal = ({
    isModalOpen,
    closeModal,
    contentTagOptions,
    locationTagOptions,
    currencies,
}: AddInfluencerModalProps) => {
    const [addedInfluencer, setAddedInfluencer] = useState<TiktokInfluencerUser | null>(null);
    const [hasErrorAddingInfluencer, setHasErrorAddingInfluencer] = useState(false);

    const clearBanners = () => {
        setAddedInfluencer(null);
        setHasErrorAddingInfluencer(false);
    };

    const handleClose = () => {
        clearBanners();
        closeModal();
    };

    const currencyOptions: GenericDropdownOption<number>[] = useMemo(
        () => currencies.map((c) => ({ value: c.id, label: c.name })),
        [currencies]
    );

    const initialFormValues: FormValues = useMemo(
        () => ({
            content_tags: [],
            locations: [],
            currency: currencyOptions[0] ?? null,
            brand_promotion_currency: currencyOptions[0] ?? null,
            contact_details: '',
            notes: '',
            username: '',
            cost: '',
            brand_promotion_cost: '',
            paypal_email: '',
        }),
        [currencyOptions]
    );

    const handleSubmit = useCallback(
        async (values: FormValues, { setSubmitting, setErrors, resetForm }: FormikHelpers<FormValues>) => {
            try {
                setSubmitting(true);
                const response = await postTiktokInfluencerUser({
                    ...values,
                    content_tags: (values.content_tags ?? []).map((t) => t.value) ?? [],
                    cost: values.cost ? values.cost : null,
                    currency: values.currency?.value ?? null,
                    brand_promotion_currency: values.brand_promotion_currency?.value ?? null,
                    brand_promotion_cost: values.brand_promotion_cost ?? null,
                    tags: (values.locations ?? []).map((t) => t.value) ?? [],
                    paypal_email: values.paypal_email ?? null,
                });

                if (response.status === 400) {
                    setErrors(mapApiErrorsToFormikErrors({ ...response.data }));
                    return;
                }

                setAddedInfluencer(response.data);
                resetForm();
            } catch {
                setHasErrorAddingInfluencer(true);
            } finally {
                setSubmitting(false);
            }
        },
        []
    );

    return (
        <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={handleClose}>
            <ModalTitle>New Influencer</ModalTitle>
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={initialFormValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({ isSubmitting, submitForm, isValid }) => (
                    <>
                        <ModalContent>
                            <Form className={styles.form} onChange={clearBanners}>
                                {addedInfluencer && (
                                    <Banner displayClose appearance="success" onClose={() => setAddedInfluencer(null)}>
                                        Added influencer: {addedInfluencer.username}
                                    </Banner>
                                )}

                                {hasErrorAddingInfluencer && (
                                    <Banner
                                        displayClose
                                        appearance="danger"
                                        onClose={() => setHasErrorAddingInfluencer(false)}
                                    >
                                        Error adding new influencer
                                    </Banner>
                                )}

                                <FormField>
                                    <Label htmlFor="username">Username</Label>
                                    <Field id="username" name="username" placeholder="Enter username" />
                                    <ErrorMessage name="username">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </FormField>

                                <FormField>
                                    <Label htmlFor="content_tags">Content Tags</Label>
                                    <Field id="content_tags" name="content_tags">
                                        {(props: FieldProps) => (
                                            <Select
                                                isMulti
                                                styles={selectStyles}
                                                placeholder="Select content tags"
                                                options={contentTagOptions}
                                                value={props.field.value}
                                                onChange={(value) => {
                                                    props.form.setFieldValue(props.field.name, value);
                                                    clearBanners();
                                                }}
                                                onBlur={() => {
                                                    props.form.setFieldTouched(props.field.name, true);
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="content_tags">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </FormField>

                                <FormField>
                                    <Label htmlFor="locations">Locations</Label>
                                    <Field id="locations" name="locations">
                                        {(props: FieldProps) => (
                                            <Select
                                                isMulti
                                                styles={selectStyles}
                                                placeholder="Select locations"
                                                options={locationTagOptions}
                                                value={props.field.value}
                                                onChange={(value) => {
                                                    props.form.setFieldValue('locations', value);
                                                    clearBanners();
                                                }}
                                                onBlur={() => {
                                                    props.form.setFieldTouched(props.field.name, true);
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="locations">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </FormField>

                                <FormField>
                                    <Label htmlFor="contact_details">Contact details</Label>
                                    <Field
                                        id="contact_details"
                                        name="contact_details"
                                        placeholder="Enter contact details"
                                    />
                                    <ErrorMessage name="contact_details">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </FormField>

                                <FormField>
                                    <Label htmlFor="paypal_email">PayPal Email Address</Label>
                                    <Field
                                        name="paypal_email"
                                        id="paypal_email"
                                        placeholder="Enter paypal email address"
                                    />
                                    <ErrorMessage name="paypal_email">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </FormField>

                                <FormField>
                                    <Label htmlFor="notes">Notes</Label>
                                    <TextArea id="notes" name="notes" placeholder="Enter notes" />
                                </FormField>

                                <div className={styles.section}>
                                    <Label htmlFor="cost">Sound Promotion Cost</Label>
                                    <div className={styles.row}>
                                        <FormField>
                                            <Field id="currency" name="currency">
                                                {(props: FieldProps) => (
                                                    <Select
                                                        styles={selectStyles}
                                                        placeholder="Select currency"
                                                        options={currencyOptions}
                                                        value={props.field.value}
                                                        onChange={(value) => {
                                                            props.form.setFieldValue('currency', value);
                                                            clearBanners();
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </FormField>

                                        <FormField>
                                            <Field id="cost" name="cost" placeholder="Enter cost" type="number" />
                                        </FormField>
                                    </div>
                                    <ErrorMessage name="cost">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                    <ErrorMessage name="currency">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </div>

                                <div className={styles.section}>
                                    <Label htmlFor="brand_promotion_cost">Brand Promotion Cost</Label>
                                    <div className={styles.row}>
                                        <FormField>
                                            <Field id="brand_promotion_currency" name="brand_promotion_currency">
                                                {(props: FieldProps) => (
                                                    <Select
                                                        styles={selectStyles}
                                                        placeholder="currency"
                                                        options={currencyOptions}
                                                        value={props.field.value}
                                                        onChange={(value) => {
                                                            props.form.setFieldValue('brand_promotion_currency', value);
                                                            clearBanners();
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </FormField>

                                        <FormField>
                                            <Field
                                                id="brand_promotion_cost"
                                                name="brand_promotion_cost"
                                                placeholder="Enter cost"
                                                type="number"
                                            />
                                        </FormField>
                                    </div>
                                    <ErrorMessage name="brand_promotion_cost">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                    <ErrorMessage name="brand_promotion_currency">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </div>
                            </Form>
                        </ModalContent>
                        <ModalFooter>
                            <Button
                                type="filled"
                                color="black"
                                loading={isSubmitting}
                                onClick={submitForm}
                                disabled={!isValid}
                            >
                                Add influencer
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default AddInfluencerModal;
