import { combineReducers } from 'helpers';
import { reducer as reportReducer, initialState as reportInitialState } from './ReportData/reducer';
import { reducer as campaignsReducer, initialState as campaignsInitialState } from './Campaigns/reducer';
import { makePostsReducer, initialState as postsInitialState } from 'Modules/Plans/Posts/reducers/postsByCampaign';
import {
    reducer as campaignStatsReducer,
    initialState as campaignStatsInitialState,
} from '../../AggregatedPostStats/reducers/aggregatedPostStatsByCampaign';

import { reducer as teamsReducer, initialState as teamsInitialState } from '../../Team/reducers/reducer';
import { reducer as assigneesReducer, initialState as assigneesInitialState } from './ReportData/assigneesReducer';

import {
    reducer as instagramPostStatsReducer,
    initialState as instagramPostStatsInitialState,
} from '../../Posts/reducers/instagramPostStatsByPostId';
import {
    reducer as platformAggregatedPostStatsReducer,
    initialState as platformAggregatedPostStatsInitialState,
} from 'Modules/Plans/AggregatedPostStats/reducers/aggregatedPostStatsByPlatform';
import {
    reducer as tiktokVideosReducer,
    initialState as tiktokVideosInitialState,
} from 'Modules/TikTok/hooks/dataHooks/useTiktokVideosDataHook';
import {
    reducer as tiktokVideoStatsReducer,
    initialState as tiktokVideoStatsInitialState,
} from 'Modules/TikTok/hooks/dataHooks/useTiktokVideoStatsDataHook';
import {
    reducer as tiktokAudiosReducer,
    initialState as tiktokAudiosInitialState,
} from '../../TiktokAudios/reducers/reducer';

import { reducer as audioTimeSeriesReducer, initialState as audioTimeSeriesState } from '../../audioTimeSeries/reducer';
import {
    reducer as titkokAccountsReducer,
    initialState as tiktokAccountsInitialState,
} from 'Modules/TikTok/hooks/dataHooks/useTiktokAccountsDataHook';
import {
    reducer as youtubeChannelsReducer,
    initialState as youtubeChannelsInitialState,
} from 'Modules/Youtube/reducers/youtubeChannelsReducer';
import {
    reducer as instagramUserImagesReducer,
    initialState as instagramUserImagesInitialState,
} from 'Modules/Instagram/reducers/instagramUserImagesReducer';
import { creatorbase } from '@round/api';
import {
    makeProjectsReducer,
    initialState as projectsInitialState,
} from 'Modules/Plans/Project/hooks/dataHooks/useProjectsDataHook';
import { makeBrandsReducer, initialState as brandsInitialState } from '../../Brand/hooks/dataHooks/useBrandsDataHook';
import {
    reducer as instagramUsersReducer,
    initialState as instagramUsersInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramUsersDataHook';
import {
    reducer as instagramUserStatsReducer,
    initialState as instagramUserStatsInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramUserStatsDataHook';

export type State = ReturnType<typeof reducer>;
export type Actions = Parameters<typeof reducer>[1];

const postsReducer = makePostsReducer<creatorbase.Post>();

export const initialState: State = {
    report: {
        report: reportInitialState,
        assignees: assigneesInitialState,
    },
    audioTimeSeries: audioTimeSeriesState,
    teams: teamsInitialState,
    tiktokAudios: tiktokAudiosInitialState,
    campaigns: campaignsInitialState,
    campaignStats: campaignStatsInitialState,
    projects: projectsInitialState,
    brands: brandsInitialState,
    posts: postsInitialState,
    tiktokVideoStats: tiktokVideoStatsInitialState,
    tiktokVideos: tiktokVideosInitialState,
    instagramPostStats: instagramPostStatsInitialState,
    platformAggregatedPostStats: platformAggregatedPostStatsInitialState,
    tiktokAccounts: tiktokAccountsInitialState,
    youtubeChannels: youtubeChannelsInitialState,
    instagramUserImages: instagramUserImagesInitialState,
    instagramUsers: instagramUsersInitialState,
    instagramUserStats: instagramUserStatsInitialState,
};

export const reducer = combineReducers({
    report: combineReducers({
        report: reportReducer,
        assignees: assigneesReducer,
    }),
    audioTimeSeries: audioTimeSeriesReducer,
    teams: teamsReducer,
    tiktokAudios: tiktokAudiosReducer,
    campaigns: campaignsReducer,
    campaignStats: campaignStatsReducer,
    projects: makeProjectsReducer<creatorbase.Project>(),
    brands: makeBrandsReducer<creatorbase.Brand>(),
    posts: postsReducer,
    tiktokVideoStats: tiktokVideoStatsReducer,
    tiktokVideos: tiktokVideosReducer,
    instagramPostStats: instagramPostStatsReducer,
    platformAggregatedPostStats: platformAggregatedPostStatsReducer,
    tiktokAccounts: titkokAccountsReducer,
    youtubeChannels: youtubeChannelsReducer,
    instagramUserImages: instagramUserImagesReducer,
    instagramUsers: instagramUsersReducer,
    instagramUserStats: instagramUserStatsReducer,
});
