import { IconProps } from '../../../../../SharedComponents/svg/Icons/Icon.types';

const CompletedIcon = ({ width, height, className, onClick, title }: IconProps) => {
    return (
        <svg
            role="img"
            aria-label={title}
            className={className}
            onClick={onClick}
            width={width}
            height={height}
            viewBox="0 0 15 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_94_13343)">
                <path
                    d="M10.3084 4.73019C10.0174 4.41634 9.54332 4.41238 9.24778 4.72137L3.70402 10.5657L1.2941 7.90454C1.00667 7.585 0.530821 7.57451 0.231278 7.8811C-0.0682978 8.18769 -0.0781306 8.69527 0.2093 9.01478C0.210603 9.0162 0.211872 9.01763 0.213175 9.01905L3.15156 12.2672C3.29218 12.4202 3.48438 12.5068 3.68527 12.5077H3.69354C3.89062 12.507 4.07959 12.4238 4.21972 12.276L10.3016 5.86154C10.5955 5.55071 10.5985 5.04501 10.3084 4.73019Z"
                    fill="#109CF1"
                />
                <path
                    d="M7.66169 12.2672C7.80195 12.4199 7.99353 12.5064 8.1939 12.5078H8.20217C8.39925 12.5071 8.58822 12.4239 8.72835 12.276L14.8103 5.86159C15.086 5.53048 15.0578 5.02371 14.7474 4.72965C14.466 4.46307 14.0432 4.45956 13.7579 4.72142L8.21643 10.5658L8.01873 10.3469C7.73133 10.0273 7.25548 10.0168 6.9559 10.3234C6.65633 10.63 6.64646 11.1375 6.93389 11.4571C6.9352 11.4585 6.9365 11.46 6.9378 11.4614L7.66169 12.2672Z"
                    fill="#109CF1"
                />
            </g>
            <defs>
                <clipPath id="clip0_94_13343">
                    <rect width="15" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CompletedIcon;
