import React from 'react';
import styles from './ProgressBar.module.css';
import cn from 'classnames';

type ProgressBarProps = {
    /**
     * Progress in range of 0-100
     */
    progress: number;
    trackStyles?: React.CSSProperties;
    barStyles?: React.CSSProperties;
    barClassName?: string;
    trackClassName?: string;
};

const ProgressBar = ({ progress, trackStyles, barStyles, trackClassName, barClassName }: ProgressBarProps) => {
    return (
        <div className={cn(styles.track, trackClassName)} style={trackStyles}>
            <div className={cn(styles.bar, barClassName)} style={{ width: `${progress}%`, ...barStyles }} />
        </div>
    );
};

export default ProgressBar;
