import { AuthContext } from 'Modules/Auth/contexts/AuthContext';
import { CREATORBASE_ROOT_PATH } from 'Modules/Navigation/whitelabel/routes';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

const RedirectToDefault = () => {
    const authContext = useContext(AuthContext);

    if (authContext.loading) {
        return null;
    }

    if (!authContext.user) {
        return <Navigate to="/login/" replace />;
    }

    if (!!authContext.user?.creator_base_access) {
        return <Navigate to={`/${CREATORBASE_ROOT_PATH}/projects/`} replace />;
    }

    return <Navigate to="/campaigns/" replace />;
};

export default RedirectToDefault;
