import styles from './FileUpload.module.css';
import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as UploadIcon } from '../../assets/Upload.svg';
import cn from 'classnames';
import LoadingSpinner from '../../SharedComponents/LoadingSpinner/LoadingSpinner';
import { FCWithChildren } from '../../utility/utility.types';

const DROPZONE_OPTIONS = {
    noClick: true,
    noKeyboard: true,
};

export enum FileType {
    Image = 'image',
    Video = 'video',
}

interface FileUploadProps {
    fileType?: FileType;
    maxFiles?: number;
    initialPreviews?: string[];
    onUpload: (files: File[]) => void;
    accept?: string[];
}

const FileUpload: FCWithChildren<FileUploadProps> = ({ fileType, maxFiles, initialPreviews, onUpload, accept }) => {
    const [previews, setPreviews] = useState(initialPreviews?.filter((initialPreview) => initialPreview));
    const [loading, setLoading] = useState(false);

    const uploadLabel = useMemo(() => {
        if (fileType === FileType.Image) {
            return 'Drop an image here';
        }

        if (fileType === FileType.Video) {
            return 'Drop a video here';
        }

        return 'Drop a file here';
    }, [fileType]);

    const { getRootProps, getInputProps } = useDropzone({
        ...DROPZONE_OPTIONS,
        maxFiles,
        accept: accept ?? `${fileType}/*`,
        onDrop: async (files: File[]) => {
            setLoading(true);

            if (fileType === FileType.Image && files.length) {
                setPreviews(files.map((file) => URL.createObjectURL(file)));
            }
            await onUpload(files);
            setLoading(false);
        },
    });

    return (
        <div className={cn(styles.upload, { [styles.uploadHasPreview]: previews?.length })} {...getRootProps()}>
            {loading && <LoadingSpinner />}
            {!loading && (
                <>
                    <div className={cn(styles.uploadArea, { [styles.uploadAreaHasPreview]: previews?.length })}>
                        <UploadIcon />
                        <p>
                            {uploadLabel}
                            <br />
                            <span>or</span>
                        </p>
                        <div className={styles.browse}>
                            Browse
                            <input type="file" {...getInputProps()} />
                        </div>
                    </div>
                    {previews?.length ? (
                        <div className={styles.previews}>
                            {previews.map((preview, index) => (
                                <img key={index} src={preview} alt="" />
                            ))}
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
};

export default FileUpload;
