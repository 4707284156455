import { youtube } from '@round/api';
import { GenericDropdownOption } from 'App.types';
import { OptionsParams, UseSingleValueSelectParams, useSelect } from 'Hooks/useSelect';

export type YoutubeChannelOption = GenericDropdownOption<number>;
export const mapYoutubeChannelToOption = (channel: youtube.Channel): YoutubeChannelOption => ({
    value: channel.id,
    label: channel.title ?? channel.youtube_id,
});

const fetchOptions = async (params: OptionsParams, requestInit?: RequestInit) => {
    const response = await youtube.getChannels(
        { page: params.page, page_size: params.page_size, identifier_search: params.search },
        requestInit
    );

    if (response.status === 404) {
        throw new Error(response.data.detail);
    }

    return {
        ...response.data,
        results: response.data.results.map(mapYoutubeChannelToOption),
    };
};

type Params = Omit<UseSingleValueSelectParams<YoutubeChannelOption>, 'fetchOptions' | 'isMulti'>;

export default function useYoutubeChannelSelect(params: Params) {
    return useSelect<YoutubeChannelOption>({ fetchOptions, isMulti: false, ...params });
}
