import { Skeleton } from '@round/ui-kit';

type Props = {
    value: string | null;
    isLoading?: boolean;
};

const PaypalTransactionIdCell = ({ value, isLoading }: Props) => {
    if (isLoading) {
        return <Skeleton />;
    }

    return <>{value || '-'}</>;
};

export default PaypalTransactionIdCell;
