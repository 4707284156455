import countries from './countries';
import { ApiResponseError } from './utility.types';
import { FormikErrors } from 'formik';
import { OrderingValues } from '@round/api';

export function mapApiErrorsToFormikErrors<T extends Record<string, any>>(
    apiErrors: ApiResponseError<T>
): FormikErrors<T> {
    return Object.keys(apiErrors).reduce((formikErrors, key) => {
        const value = apiErrors[key as keyof ApiResponseError<T>];
        return {
            ...formikErrors,
            [key]: typeof value === 'string' ? value : value?.join(''),
        };
    }, {} as FormikErrors<T>);
}

export function resetTime(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
}

const ONE_DAY = 24 * 60 * 60 * 1000;

export function getDateDiff(startDateInMs: number, endDateInMs: number) {
    return Math.ceil(Math.abs((endDateInMs - startDateInMs) / ONE_DAY)) + 1;
}

export function isNumber(x: any): x is number {
    return typeof x === 'number';
}

export function formatToIsoDateString(date: Date) {
    // Adjusting date to UTC to avoid timezone issues
    const adjustedDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes())
    );
    return adjustedDate.toISOString().split('T')[0];
}

export type Country = {
    label: string;
    code: string;
};

export const getCountry = (countryCode: string | undefined | null): Country | null => {
    if (!countryCode) {
        return null;
    }

    const label = countries[countryCode] || null;

    if (!label) {
        return null;
    }

    return {
        label,
        code: countryCode,
    };
};

export function isOrderingParam<T extends string>(
    str: string,
    typeGuard: (str: any) => str is T
): str is OrderingValues<T> {
    const sliced = str.charAt(0) === '-' ? str.slice(1) : str;
    return typeGuard(sliced);
}

export const getNumbersArrayFromString = (numbers: string) =>
    numbers
        .split(',')
        .filter((item) => !!item)
        .map(Number);

export const transformSnakeCaseToCapitalizedString = (str: string) =>
    str
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

export const getNonEmptyKeys = <T extends object, K extends keyof T>(obj: T): K[] =>
    Object.entries(obj)
        .filter((entry) => !!entry[1])
        .map((entry) => entry[0]) as K[];

export const filterByVisibility = <T extends {}>(items: (T & { isVisible?: boolean })[]) => {
    return items.filter((item) => item.isVisible === undefined || item.isVisible);
};

export const filterKeysByValue = <T extends object, K extends keyof T>(
    obj: T,
    condition: (value: T[K]) => boolean
): Partial<T> => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (condition(value)) {
            acc[key as K] = value;
        }

        return acc;
    }, {} as Partial<T>);
};
