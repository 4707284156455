import { ReactNode, useMemo, useState } from 'react';
import { Point } from '../types';
import { ChartProps, Line } from 'react-chartjs-2';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import tooltipStyles from 'ui-new/whitelabel/Tooltip/Tooltip.module.css';
import commonStyles from '../Charts.module.css';
import { formatYAxisLabels, getExponent } from '../helpers';
import LoadingSpinner from 'SharedComponents/LoadingSpinner/LoadingSpinner';

type Props = {
    points: Point[];
    height?: number | string;
    width?: number | string;
    lineColor?: string;
    renderTooltip?: (item: Point) => ReactNode;
    isLoading?: boolean;
};

const TOOLTIP_WIDTH_IN_PX = 225;
const LineChart = ({ points, height, width, lineColor, renderTooltip, isLoading }: Props) => {
    const [tooltipParams, setTooltipParams] = useState<{ x: number; y: number; transformX: number } | null>(null);
    const [tooltipItem, setTooltipItem] = useState<Point | null>(null);

    const data: ChartProps<'line', Point[]>['data'] = {
        datasets: [
            {
                data: points,
                borderColor: lineColor,
                tension: 0.2,
                borderWidth: 4,
            },
        ],
    };

    const options = useMemo(() => {
        const yValues = points.map((point) => point.y);
        const maxValue = Math.max(...yValues);
        const minValue = Math.min(...yValues);
        const minMaxDiff = maxValue - minValue;
        const stepSize =
            isFinite(minMaxDiff) && minMaxDiff >= 0 ? undefined : Math.pow(10, getExponent(yValues[0]) - 1);

        return {
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    position: 'nearest',
                    external({ tooltip, chart }) {
                        if (tooltip.opacity === 0) {
                            setTooltipParams(null);
                            setTooltipItem(null);
                        } else {
                            const position = chart.canvas.getBoundingClientRect();
                            let left = position.left + tooltip.caretX;
                            let transform = -75;

                            setTooltipParams({
                                x: left,
                                y: position.top + tooltip.caretY,
                                transformX: transform,
                            });

                            if (Array.isArray(tooltip.dataPoints) && tooltip.dataPoints.length) {
                                const point = tooltip.dataPoints[0];
                                const item = points[point.dataIndex];
                                setTooltipItem(item ?? null);
                            }
                        }
                    },
                },
            },
            elements: {
                point: {
                    hoverRadius: 6,
                    hitRadius: 4,
                },
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        color: '#90A0B7',
                        stepSize,
                        callback: (value: number) => {
                            if (new Set(yValues).size < 2) {
                                return formatYAxisLabels(value, 1);
                            }
                            const minMaxDifferenceExponent = getExponent(minMaxDiff);
                            const maxExponent = getExponent(maxValue);
                            const fractionDigits = maxExponent - minMaxDifferenceExponent;
                            const labelsFD = fractionDigits > 0 ? (fractionDigits < 100 ? fractionDigits : 99) : 0;
                            return formatYAxisLabels(value, labelsFD);
                        },
                        precision: 0,
                    },
                    grid: {
                        display: true,
                        color: '#90A0B7',
                        drawTicks: false,
                    },
                    border: {
                        display: false,
                        color: '#90A0B7',
                        dash: [5, 5],
                    },
                },
                x: {
                    type: 'time',
                    ticks: {
                        color: '#90A0B7',
                        autoSkip: true,
                        count: 30,
                    },
                    time: {
                        unit: 'day',
                        displayFormats: {
                            day: 'MMM D',
                        },
                    },
                    grid: {
                        display: false,
                        color: '#90A0B7',
                    },
                },
            },
            maintainAspectRatio: false,
        } as ChartProps<'line', Point[]>['options'];
    }, [points]);

    if (isLoading) {
        return <LoadingSpinner containerClassName={commonStyles.loadingSpinnerContainer} />;
    }

    return (
        <>
            <Line height={height} width={width} data={data} options={options} />
            {tooltipParams &&
                tooltipItem &&
                typeof renderTooltip === 'function' &&
                createPortal(
                    <div
                        className={cn(tooltipStyles.tooltip, commonStyles.root)}
                        style={{
                            position: 'fixed',
                            zIndex: 20,
                            left: tooltipParams.x + 'px',
                            top: tooltipParams.y + 'px',
                            transform: `translate(${tooltipParams.transformX}%, -115%)`,
                            minWidth: TOOLTIP_WIDTH_IN_PX,
                            width: 'max-content',
                        }}
                    >
                        {renderTooltip(tooltipItem)}
                    </div>,
                    document.body
                )}
        </>
    );
};

export default LineChart;
