import React, { useState } from 'react';
import styles from './FacebookDetailedTargetingListItem.module.css';
import classNames from 'classnames';
import CloseIcon from '../../../../../../../../../../SharedComponents/svg/Icons/CloseIcon';
import { createPortal } from 'react-dom';
import FacebookDetailedTargetingSelectionDescription from '../FacebookDetailedTargetingSelectionDescription/FacebookDetailedTargetingSelectionDescription';
import { usePopper } from 'react-popper';
import { FacebookTargetingAudienceSelectionOption } from '../../../../../../../../../../App.types';

type FacebookDetailedTargetingListItemProps = {
    selection: FacebookTargetingAudienceSelectionOption;
    onDelete: (selection: FacebookTargetingAudienceSelectionOption) => void;
};

const FacebookDetailedTargetingListItem = ({ selection, onDelete }: FacebookDetailedTargetingListItemProps) => {
    const [showDescription, setShowDescription] = useState(false);
    const [listItemRef, setListItemRef] = useState<HTMLDivElement | null>(null);
    const [popperElementRef, setPopperElementRef] = useState<HTMLDivElement | null>(null);
    const { styles: popperStyles, attributes: popperAttributes } = usePopper(listItemRef, popperElementRef, {
        placement: 'right',
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
    });

    return (
        <div key={selection.value} className={styles.selectionItemContainer}>
            <div className={styles.selectionPath}>{selection.path.join(' > ')}</div>
            <div
                ref={setListItemRef}
                className={classNames([styles.row, styles.selectionItem])}
                onMouseEnter={() => setShowDescription(true)}
                onMouseLeave={() => setShowDescription(false)}
            >
                <p className={styles.selectionLabel}>{selection.label}</p>
                <button className={styles.closeButton} onClick={() => onDelete(selection)}>
                    <CloseIcon width={10} height={10} />
                </button>

                {showDescription &&
                    createPortal(
                        <div
                            ref={setPopperElementRef}
                            className={styles.selectionDescriptionContainer}
                            style={{ ...popperStyles.popper, zIndex: 100000 }}
                            {...popperAttributes.popper}
                        >
                            <FacebookDetailedTargetingSelectionDescription selection={selection} />
                        </div>,
                        document.body
                    )}
            </div>
        </div>
    );
};

export default FacebookDetailedTargetingListItem;
