import { ModalBase, ModalBaseProps } from '@round/ui-kit';
import styles from './Modal.module.css';
import cn from 'classnames';
import { CSSProperties, ReactNode } from 'react';
import { ReactComponent as CloseIcon } from 'assets/Close.svg';

type Props = ModalBaseProps;

const Modal = ({ className, overlayClassName, ...props }: Props) => {
    return (
        <ModalBase
            className={cn(styles.modal, className)}
            overlayClassName={cn(styles.overlay, overlayClassName)}
            {...props}
        >
            <>
                <CloseIcon className={styles.closeIcon} onClick={props.onClose} />
                {props.children}
            </>
        </ModalBase>
    );
};

type ComponentProps = {
    className?: string;
    children?: ReactNode | undefined;
    style?: CSSProperties;
};

const Header = ({ children, className, style }: ComponentProps) => (
    <header style={style} className={cn(styles.header, className)}>
        {children}
    </header>
);

const Body = ({ children, className, style }: ComponentProps) => (
    <div style={style} className={cn(styles.body, className)}>
        {children}
    </div>
);

const Actions = ({ children, className, style }: ComponentProps) => (
    <menu style={style} className={cn(styles.actions, className)}>
        {children}
    </menu>
);

const Title = ({ children, style, className }: ComponentProps) => (
    <h2 style={style} className={cn(styles.title, className)}>
        {children}
    </h2>
);

Modal.Header = Header;
Modal.Body = Body;
Modal.Actions = Actions;
Modal.Title = Title;

export default Modal;
