import { combineReducers } from 'helpers';
import {
    reducer as campaignsReducer,
    initialState as campaignsInitialState,
} from 'Modules/Plans/Campaigns/hooks/dataHooks/useCampaignsDataHook';

export type State = ReturnType<typeof reducer>;
export type Actions = Parameters<typeof reducer>[1];

export const initialState: State = {
    campaigns: campaignsInitialState,
};

export const reducer = combineReducers({
    campaigns: campaignsReducer,
});
