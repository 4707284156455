import { InstagramAudioMonitoringInfo } from '@round/api';
import styles from './ReliabilityStatus.module.css';
import { Card, Popover } from '@round/ui-kit';
import { useState } from 'react';
import cn from 'classnames';
import { ReactComponent as QuestionMarkIcon } from 'assets/QuestionMark.svg';

type Props = {
    monitoringInfo: InstagramAudioMonitoringInfo;
};

const ReliabilityStatus = ({ monitoringInfo }: Props) => {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    return (
        <>
            <div ref={setAnchor} className={styles.card}>
                <div className={styles.iconContainer}>
                    <QuestionMarkIcon className={styles.icon} />
                </div>

                <p
                    className={cn(styles.title, {
                        [styles.positive]: monitoringInfo.is_enabled && monitoringInfo.is_active,
                        [styles.negative]: !monitoringInfo.is_active,
                        [styles.warning]: monitoringInfo.is_active && !monitoringInfo.is_enabled,
                    })}
                >
                    {!monitoringInfo.is_active
                        ? 'No longer available'
                        : monitoringInfo.is_active && !monitoringInfo.is_enabled
                        ? 'Potential issues'
                        : 'No issues'}
                </p>
                <p className={styles.subtitle}>Reliability Status</p>
            </div>

            <Popover
                anchorElement={anchor}
                showOn="hover"
                options={{ placement: 'bottom-start', modifiers: [{ name: 'offset', options: { offset: [0, 5] } }] }}
            >
                <Card className={styles.tooltip}>
                    <dl className={styles.statusDescriptions}>
                        <div className={styles.statusDescription}>
                            <dt className={styles.positive}>No issues</dt>
                            <dd>Audio data is being retrieved daily without any issues. </dd>
                        </div>

                        <div className={styles.statusDescription}>
                            <dt className={styles.warning}>Potential issues</dt>
                            <dd>Data is not being retrieved daily, possible the audio has been removed.</dd>
                        </div>

                        <div className={styles.statusDescription}>
                            <dt className={styles.negative}>No longer available</dt>
                            <dd>The audio is no longer available on Instagram.</dd>
                        </div>
                    </dl>
                </Card>
            </Popover>
        </>
    );
};

export default ReliabilityStatus;
