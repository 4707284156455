import React from 'react';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import styles from './PaymentsPage.module.css';
import TiktokPayments from '../../../Modules/Finance/Payments/TiktokPayments';

const PaymentsPage = () => (
    <>
        <NavBar />
        <div className={styles.container}>
            <TiktokPayments />
        </div>
    </>
);

export default PaymentsPage;
