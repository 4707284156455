import React from 'react';
import cn from 'classnames';
import styles from './FormField.module.css';

type FormFieldProps = {
    className?: string;
    children?: React.ReactNode | undefined;
};

const FormField = ({ children, className }: FormFieldProps) => (
    <div className={cn(styles.field, className)}>{children}</div>
);

export default FormField;
