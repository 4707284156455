import { AppServer } from '../../mirage';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TrndsClientTiktokAudio, TrndsClientTiktokAudioViewStats } from './tiktokAudio.types';

export const models = {
    trndsClientTiktokAudio: Model as ModelDefinition<TrndsClientTiktokAudio>,
    tiktokAudioViewStats: Model as ModelDefinition<TrndsClientTiktokAudioViewStats>,
};

export const serializers = {
    trndsClientTiktokAudio: makeSerializer<TrndsClientTiktokAudio>([]),
    tiktokAudioViewStats: makeSerializer<TrndsClientTiktokAudioViewStats>([]),
};

export const factories = {
    trndsClientTiktokAudio: createFactory<TrndsClientTiktokAudio>({
        id(index: number) {
            return index;
        },
        tiktok_id(index: number) {
            return `tiktok id ${index}`;
        },
        author_name(index: number) {
            return `author name ${index}`;
        },
        title(index: number) {
            return `title ${index}`;
        },
        cover_thumb(index: number) {
            return `cover thumb ${index}`;
        },
        play_url(index: number) {
            return `play url ${index}`;
        },
        duration() {
            return Math.random() * 100;
        },
        monitored_audio_id: [],
    }),
    trndsClientTiktokAudioViewStats: createFactory<TrndsClientTiktokAudioViewStats>({
        locations: {
            countries: [],
        },
        location_post_count(index: number) {
            return index * 100;
        },
        total_views(index: number) {
            return index * 100;
        },
        total_views_post_count(index: number) {
            return index * 100;
        },
    }),
};

export function handleRequests(server: AppServer) {
    server.get(`/api/trnds/client/:clientId/tiktok-audio/:audioId/`, (schema, request) => {
        const audio = schema
            .all('trndsClientTiktokAudio')
            .find((audio) => Number(audio.id) === Number(request.params.audioId));

        if (!audio) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return audio;
    });

    server.get('/api/tiktok/viewsets/audio/:id/stats/', (schema, request) => {
        return schema.create('trndsClientTiktokAudioViewStats');
    });
}
