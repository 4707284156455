import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { AggregatedPostStats } from './aggregatedPostStats.types';

export const models = {
    aggregatedPostStats: Model as ModelDefinition<AggregatedPostStats>,
};

export const serializers = {
    aggregatedPostStats: makeSerializer<AggregatedPostStats>([]),
};

export const factories = {
    aggregatedPostStats: createFactory<AggregatedPostStats>({
        view_count: 0,
        post_count: 0,
        engagement_count: 0,
        engagement_rate: 0,
        account_count: 0,
        follower_count: null,
        total_spent: {},
        cpm: {},
    }),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/creatorbase/aggregated-post-stats/';
    //TODO: reflect BE logic when posts API is ready
    server.post(rootUrl, async function (this: RouteHandlerContext, schema, request) {
        return schema.create('aggregatedPostStats');
    });
}
