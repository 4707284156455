import { Popover } from '@round/ui-kit';
import { useState } from 'react';
import Toggle from 'ui/DataEntry/Toggle/Toggle';
import Button from 'ui/Button/Button';
import { ReactComponent as FlagIcon } from 'assets/icons/Flag.svg';
import styles from './FeatureFlagsMenu.module.css';
import { useFeatureFlags } from '../FeatureFlagsContext';

// The only manual local storage flag for simplicity
const isFeatureFlagsManagerEnabled = localStorage.getItem('is_feature_flags_manager_enabled') === 'true';

const FeatureFlagsMenu = () => {
    const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
    const flags = useFeatureFlags();

    if (!isFeatureFlagsManagerEnabled) {
        return null;
    }

    return (
        <>
            <Button ref={setButtonRef} appearance="primary" className={styles.button} title="Feature toggles">
                <FlagIcon className={styles.icon} />
            </Button>
            <Popover showOn="click" anchorElement={buttonRef} options={{ placement: 'top-end' }}>
                <ul className={styles.menu}>
                    {flags.getAll().map((flag) => (
                        <li key={flag} className={styles.flag}>
                            <span>{flag.split('_').join(' ')}</span>
                            <Toggle
                                value={flags.isEnabled(flag)}
                                onChange={(value) => {
                                    flags.setEnabled(flag, value);
                                }}
                            />
                        </li>
                    ))}
                </ul>
            </Popover>
        </>
    );
};

export default FeatureFlagsMenu;
