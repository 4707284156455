import { creatorbase } from '@round/api';
import { CellContext } from '@tanstack/react-table';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { ProjectTableMeta } from '../../types';
import CircleBadge from '../../../../components/CircleBadge/CircleBadge';
import { getUserNameInitials } from '../../../../User/user.helpers';
import styles from './UsersCell.module.css';

type Row = creatorbase.Project & {
    users: creatorbase.User[];
};

type Context<T extends Row> = CellContext<T, T['users']>;

const getTableMeta = getTableMetaHelper<ProjectTableMeta<Row>>();

const UsersCell = <T extends Row>({ getValue, row: { original }, table }: Context<T>) => {
    const { getIsRowLoading } = getTableMeta(table);
    const users = getValue();

    if (getIsRowLoading(original)) {
        return <Skeleton width="10rem" />;
    }

    return (
        <div className={styles.teamContainer}>
            {users.map((user, index) => (
                <CircleBadge key={index} tooltip={user.name}>
                    {getUserNameInitials(user.name)}
                </CircleBadge>
            ))}
        </div>
    );
};

export default UsersCell;
