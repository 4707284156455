import { useFormikContext } from 'formik';
import styles from './ErrorMessage.module.css';
import cn from 'classnames';

type Props = {
    name: string;
};

const ErrorMessage = ({ name }: Props) => {
    const { getFieldMeta } = useFormikContext<any>();
    const { error, touched } = getFieldMeta(name);

    const hasError = !!error && !!touched;

    return <p className={cn(styles.error, { [styles.visible]: hasError })}>{error}</p>;
};

export default ErrorMessage;
