import useAbortableEffect from 'Hooks/useAbortableEffect';
import usePublicReportData from './usePublicReportData';
import styles from './PublicReportData.module.css';
import { SkeletonizedValue } from '@round/ui-kit';
import HeaderSummary from 'Modules/Plans/components/HeaderSummary/HeaderSummary';
import RunDates from 'Modules/Plans/components/RunDates/RunDates';
import moment from 'moment';
import CircleBadge from 'Modules/Plans/components/CircleBadge/CircleBadge';
import { Image } from '@round/ui-kit';
import { ReactComponent as RoundLogoShort } from 'assets/brand/LogoShort.svg';

type Props = {
    publicReportId: string | undefined;
};

const PublicReportData = ({ publicReportId }: Props) => {
    const {
        fetchData,
        data,
        status,
        team: { data: team, status: teamsStatus },
    } = usePublicReportData();
    const isInitialized = status === 'success' || status === 'error';
    const isTeamInitialized = teamsStatus === 'success' || teamsStatus === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && publicReportId) {
                fetchData(publicReportId, { signal }).catch(() => {});
            }
        },
        [fetchData, isInitialized, publicReportId]
    );

    const runDatesDuration =
        data?.first_post_date && data?.last_post_date
            ? moment.duration(moment(data.last_post_date).diff(moment(data.first_post_date))).asDays()
            : null;

    const runDatesDurationDisplayValue = runDatesDuration
        ? `${runDatesDuration.toFixed()} day${runDatesDuration === 1 ? '' : 's'}`
        : '-';

    return (
        <>
            <div className={styles.goBackContainer}>
                <div className={styles.team}>
                    <CircleBadge>
                        <Image loading={!isTeamInitialized} className={styles.teamLogo} src={team?.logo || ''} />
                    </CircleBadge>
                    {team?.name}
                </div>
                <RoundLogoShort className={styles.roundLogo} />
            </div>
            <div className={styles.container}>
                <div>
                    <h1 className={styles.title}>
                        <SkeletonizedValue isInitialized={isInitialized}>{data?.name || '-'}</SkeletonizedValue>
                    </h1>
                    <p className={styles.description}>
                        <SkeletonizedValue isInitialized={isInitialized}>{data?.description || '-'}</SkeletonizedValue>
                    </p>
                </div>

                <HeaderSummary>
                    <HeaderSummary.Item>
                        <HeaderSummary.Label>Run Dates</HeaderSummary.Label>
                        <HeaderSummary.Value className={styles.runDatesContainer} isLoading={!isInitialized}>
                            {data?.first_post_date && data?.last_post_date ? (
                                <>
                                    <RunDates
                                        startDate={moment(data.first_post_date).format('DD MMM YYYY')}
                                        endDate={moment(data.last_post_date).format('DD MMM YYYY')}
                                    />
                                    {runDatesDuration && runDatesDuration > 0 && (
                                        <span className={styles.duration}>{runDatesDurationDisplayValue}</span>
                                    )}
                                </>
                            ) : (
                                '-'
                            )}
                        </HeaderSummary.Value>
                    </HeaderSummary.Item>
                </HeaderSummary>
            </div>
        </>
    );
};

export default PublicReportData;
