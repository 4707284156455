import { MediaPlanPlatform } from '../types/MediaPlan.types';
import { downloadFileFromResponse, fetchAll, fetchWithToken } from '../../../../helpers';
import {
    FacebookDailyReportItem,
    SnapchatDailyReportItem,
    TiktokDailyReportItem,
    TwitterDailyReportItem,
    YoutubeDailyReportItem,
} from '../types/MediaPlanResults.types';

export type DailyReportItem =
    | FacebookDailyReportItem
    | SnapchatDailyReportItem
    | TiktokDailyReportItem
    | TwitterDailyReportItem
    | YoutubeDailyReportItem;

export async function getReports(
    mediaPlanId: number,
    platform: 'facebook',
    requestInit?: RequestInit
): Promise<FacebookDailyReportItem[]>;
export async function getReports(
    mediaPlanId: number,
    platform: 'snapchat',
    requestInit?: RequestInit
): Promise<SnapchatDailyReportItem[]>;
export async function getReports(
    mediaPlanId: number,
    platform: 'tiktok',
    requestInit?: RequestInit
): Promise<TiktokDailyReportItem[]>;
export async function getReports(
    mediaPlanId: number,
    platform: 'twitter',
    requestInit?: RequestInit
): Promise<TwitterDailyReportItem[]>;
export async function getReports(
    mediaPlanId: number,
    platform: 'youtube',
    requestInit?: RequestInit
): Promise<YoutubeDailyReportItem[]>;
export async function getReports(
    mediaPlanId: number,
    platform: MediaPlanPlatform,
    requestInit?: RequestInit
): Promise<DailyReportItem[]> {
    return (
        (await fetchAll<DailyReportItem>(
            `/api/advertising/media-plan-${platform}-items/${mediaPlanId}/`,
            requestInit
        )) ?? []
    );
}

type DownloadMediaPlanResultsFilters = {
    media_plan_item_groups?: { [MediaPlanItemGroupId: number]: string[] };
    media_plan_items?: { [MediaPlanItemId: number]: string[] };
};

export async function downloadMediaPlanResults(mediaPlanId: number, filters: DownloadMediaPlanResultsFilters = {}) {
    const response = await fetchWithToken(`/api/advertising/media-plan-results-pdf/${mediaPlanId}/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(filters),
    });

    if (!response.ok) {
        throw new Error('Could not download file');
    }

    await downloadFileFromResponse(response, 'application/pdf');
}
