import { Dispatch, ReactNode, useReducer } from 'react';
import { reducer, initialState, State, Actions } from './reducer';
import { createSelectableContext } from 'Hooks/useNonNullContextSelector';

export const ReportContext = createSelectableContext<[State, Dispatch<Actions>] | null>(null);

type Props = { children?: ReactNode };
export const ReportProvider = ({ children }: Props) => {
    return <ReportContext.Provider value={useReducer(reducer, initialState)}>{children}</ReportContext.Provider>;
};
