import styles from './LoadingSpinner.module.css';
import cn from 'classnames';

type Props = {
    className?: string;
};

const LoadingSpinner = ({ className }: Props) => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={cn(styles.spinner, className)}
            style={{ transformOrigin: 'center' }}
        >
            <circle cx="9" cy="10" r="8.5" fill="white" stroke="currentColor" />
            <mask id="path-2-inside-1_794_11596" fill="white">
                <path d="M9.00039 1.77356C10.0641 1.77356 11.1174 1.98307 12.1001 2.39014C13.0829 2.7972 13.9758 3.39384 14.728 4.146C15.4801 4.89815 16.0768 5.79109 16.4838 6.77383C16.8909 7.75657 17.1004 8.80986 17.1004 9.87357L9.00039 9.87356V1.77356Z" />
            </mask>
            <path
                d="M9.00039 1.77356C10.0641 1.77356 11.1174 1.98307 12.1001 2.39014C13.0829 2.7972 13.9758 3.39384 14.728 4.146C15.4801 4.89815 16.0768 5.79109 16.4838 6.77383C16.8909 7.75657 17.1004 8.80986 17.1004 9.87357L9.00039 9.87356V1.77356Z"
                fill="currentColor"
                stroke="currentColor"
                stroke-width="2"
                mask="url(#path-2-inside-1_794_11596)"
            />
        </svg>
    );
};

export default LoadingSpinner;
