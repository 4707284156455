import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './DropdownButton.module.css';

import { ReactComponent as SelectDropdownIndicatorIcon } from '../../../SharedComponents/svg/SelectDropdownIndicator.svg';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import Button, { ButtonProps } from '../Button/Button';
import { FCWithChildren } from '../../../utility/utility.types';

export type DropdownButtonProps = ButtonProps & {
    label: string;
    dropdownClassName?: string;
    persist?: boolean;
};

const DropdownButton: FCWithChildren<DropdownButtonProps> = ({
    label,
    children,
    dropdownClassName,
    persist,
    ...buttonProps
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
    const [dropdownRef, setDropdownRef] = useState<HTMLDivElement | null>(null);
    const { styles: popperStyles, attributes } = usePopper(buttonRef, dropdownRef, {
        modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
    });

    const iconStyles = useMemo(() => classNames(styles.dropdownIcon, { [styles.isOpen]: isDropdownOpen }), [
        isDropdownOpen,
    ]);

    const clickOutsideHandler = useCallback(
        (e: MouseEvent) => {
            if (!buttonRef || !dropdownRef || !e.target) {
                return;
            }

            if (!buttonRef.contains(e.target as Node) && !dropdownRef.contains(e.target as Node)) {
                setIsDropdownOpen(false);
            }
        },
        [buttonRef, dropdownRef]
    );

    useEffect(() => {
        if (!persist) {
            document.addEventListener('click', clickOutsideHandler);
            return () => document.removeEventListener('click', clickOutsideHandler);
        }
    }, [clickOutsideHandler, persist]);

    return (
        <>
            <Button ref={setButtonRef} {...buttonProps} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                {label} <SelectDropdownIndicatorIcon className={iconStyles} />
            </Button>

            {isDropdownOpen && (
                <div
                    ref={setDropdownRef}
                    className={classNames(styles.dropdown, dropdownClassName)}
                    style={popperStyles.popper}
                    {...attributes}
                >
                    {children}
                </div>
            )}
        </>
    );
};

export default DropdownButton;
