import React from 'react';
import cn from 'classnames';
import styles from './Card.module.css';

type Props = {
    children?: React.ReactNode | undefined;
    className?: string;
};

const Card = ({ children, className }: Props) => <div className={cn(styles.card, className)}>{children}</div>;

export default Card;
