import React, { memo, useEffect, useMemo, useState } from 'react';
import styles from './PlatformFormatBreakdown.module.css';
import { Table } from '@round/ui-kit';
import { ChannelFormatBreakdownRow, TableList } from '../MediaPlanResultsModal';
import { FCWithChildren } from '../../../../../../utility/utility.types';
import { presetOptions, SelectableMetrics } from '../PresetsSelect/presets';
import PresetsSelect from '../PresetsSelect/PresetsSelect';
import { CellContext, ColumnDef, SortingState } from '@tanstack/react-table';
import { ReactComponent as OrderByDefault } from '../../../../../../SharedComponents/svg/OrderByDefault.svg';
import { ReactComponent as OrderByDesc } from '../../../../../../SharedComponents/svg/OrderByDesc.svg';
import { ReactComponent as OrderByAsc } from '../../../../../../SharedComponents/svg/OrderByAsc.svg';
import {
    asMoney,
    asMoneyWithoutZeros,
    displayOptionalNumericTableValue,
    formatDateShort,
    numberWithCommas,
    roundTo2Dp,
} from 'helpers';
import ProgressBar from 'ui/ProgressBar/ProgressBar';
import { Currency } from '@round/api';
import PlatformIcon from 'Modules/Advertising/Projects/components/ProjectsTable/PlatformIcon';
import cn from 'classnames';
import { toDecimalPoint } from '@round/utils';

const ALWAYS_SHOWN_COLUMNS = ['format', 'channel', 'objective'];

interface MetricsTableProps {
    table: TableList;
    currency: Currency | undefined;
    onSelectedMetricsChange: (groupId: number, metrics: string[]) => void;
}

type TableCellContext<K extends keyof ChannelFormatBreakdownRow> = CellContext<
    ChannelFormatBreakdownRow,
    ChannelFormatBreakdownRow[K]
>;

const PlatformFormatBreakdown: FCWithChildren<MetricsTableProps> = ({ table, currency, onSelectedMetricsChange }) => {
    const [selectedPreset, setSelectedPreset] = useState(
        presetOptions.find((option) => option.value === 'performance')
    );
    const [selectedOrdering, setSelectedOrdering] = useState<SortingState>([{ desc: true, id: 'amount_spent' }]);

    const columns: ColumnDef<ChannelFormatBreakdownRow, any>[] = useMemo(
        () =>
            currency
                ? [
                      {
                          id: 'mediaPlanItemId',
                          header: 'ID',
                          accessorKey: 'mediaPlanItemId',
                          cell: ({ getValue }: TableCellContext<'mediaPlanItemId'>) => getValue(),
                      },
                      {
                          id: 'start_date',
                          accessorKey: 'startDate',
                          header: 'Start Date',
                          cell: ({ getValue }: TableCellContext<'startDate'>) => {
                              const startDate = getValue();
                              return startDate ? formatDateShort(startDate) : '-';
                          },
                      },
                      {
                          id: 'end_date',
                          accessorKey: 'endDate',
                          header: 'End Date',
                          cell: ({ getValue }: TableCellContext<'endDate'>) => {
                              const endDate = getValue();

                              return endDate ? formatDateShort(endDate) : '-';
                          },
                      },
                      {
                          id: 'channel',
                          accessorKey: 'channel',
                          header: 'Channel',
                          cell: ({ getValue }: TableCellContext<'channel'>) => {
                              const channel = getValue();
                              return (
                                  <div className={styles.channelContent}>
                                      <PlatformIcon name={channel.toLowerCase()} width={14} height={14} />
                                      <span>{channel}</span>
                                  </div>
                              );
                          },
                      },
                      {
                          id: 'format',
                          accessorKey: 'format',
                          header: 'Format',
                          cell: ({ getValue }: TableCellContext<'format'>) => (
                              <div className={styles.formatCell}>{getValue()}</div>
                          ),
                      },
                      {
                          id: 'objective',
                          accessorKey: 'objective',
                          header: 'Objective',
                          cell: ({ getValue }: TableCellContext<'objective'>) => getValue(),
                      },
                      {
                          id: 'amount_spent',
                          accessorKey: 'amount_spent',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'Amount Spent',
                          cell: ({ getValue, row: { original } }: TableCellContext<'amount_spent'>) => {
                              const amountSpent = getValue();
                              if (isNaN(amountSpent)) {
                                  return '-';
                              }

                              const cost = Number(original.cost);
                              const percentage = cost !== 0 ? (amountSpent / cost) * 100 : 0;
                              return (
                                  <div className={cn(styles.amountSpentContainer)}>
                                      <p className={styles.amountSpent}>
                                          {asMoneyWithoutZeros(amountSpent, currency)}
                                          <span className={styles.amountSpentPercentage}>
                                              {toDecimalPoint(percentage, 1)}%
                                          </span>
                                      </p>
                                      <ProgressBar
                                          progress={Math.min(100, percentage)}
                                          trackStyles={{ height: '0.2rem' }}
                                          barStyles={{
                                              backgroundColor: amountSpent > cost * 1.05 ? '#F7685B' : '',
                                          }}
                                      />
                                  </div>
                              );
                          },
                      },
                      {
                          id: 'impressions',
                          accessorKey: 'impressions',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'Impressions',
                          cell: ({ getValue }: TableCellContext<'impressions'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpm',
                          accessorKey: 'cpm',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'CPM',
                          cell: ({ getValue }: TableCellContext<'cpm'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'clicks',
                          accessorKey: 'clicks',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'Clicks',
                          cell: ({ getValue }: TableCellContext<'clicks'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpc',
                          accessorKey: 'cpc',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'CPC',
                          cell: ({ getValue }: TableCellContext<'cpc'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'views',
                          accessorKey: 'views',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'Views',
                          cell: ({ getValue }: TableCellContext<'views'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpv',
                          accessorKey: 'cpv',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'CPV',
                          cell: ({ getValue }: TableCellContext<'cpv'>) => asMoney(getValue(), currency, 3),
                      },
                      {
                          id: 'six_sec_views',
                          accessorKey: 'six_sec_views',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'Views (6sec)',
                          cell: ({ getValue }: TableCellContext<'six_sec_views'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'six_sec_cpv',
                          accessorKey: 'six_sec_cpv',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'CPV (6sec)',
                          cell: ({ getValue }: TableCellContext<'six_sec_cpv'>) => asMoney(getValue(), currency, 3),
                      },

                      {
                          id: 'leads',
                          accessorKey: 'leads',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'Leads',
                          cell: ({ getValue }: TableCellContext<'leads'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpl',
                          accessorKey: 'cpl',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'CPL',
                          cell: ({ getValue }: TableCellContext<'cpl'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'view_rate',
                          accessorKey: 'view_rate',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'View Rate',
                          cell: ({ getValue }: TableCellContext<'view_rate'>) => {
                              const viewRate = getValue();
                              return typeof viewRate === 'number' && !isNaN(viewRate)
                                  ? `${numberWithCommas(viewRate)}%`
                                  : '-';
                          },
                      },
                      {
                          id: 'purchases',
                          accessorKey: 'purchases',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'Purchases',
                          cell: ({ getValue }: TableCellContext<'purchases'>) =>
                              displayOptionalNumericTableValue(getValue()),
                      },
                      {
                          id: 'cpa',
                          accessorKey: 'cpa',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'CPA',
                          cell: ({ getValue }: TableCellContext<'cpa'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'revenue',
                          accessorKey: 'revenue',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'Revenue',
                          cell: ({ getValue }: TableCellContext<'revenue'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'roas',
                          accessorKey: 'roas',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'ROAS',
                          cell: ({ getValue }: TableCellContext<'roas'>) => asMoney(getValue(), currency),
                      },
                      {
                          id: 'ctr',
                          accessorKey: 'ctr',
                          enableSorting: true,
                          sortUndefined: -1,
                          header: 'CTR',
                          cell: ({ getValue }: TableCellContext<'ctr'>) => {
                              const ctr = getValue();
                              return typeof ctr === 'number' && !isNaN(ctr) ? `${roundTo2Dp(ctr * 100)}%` : '-';
                          },
                      },
                  ]
                : [],
        [currency]
    );

    const columnVisibility = selectedPreset
        ? columns.reduce((acc, column) => {
              if (!column.id) {
                  return acc;
              }

              const metricName = column.id as SelectableMetrics;

              if (![...selectedPreset?.metrics, ...ALWAYS_SHOWN_COLUMNS].includes(metricName)) {
                  acc[metricName] = false;
              }

              return acc;
          }, {} as Record<SelectableMetrics, boolean>)
        : {};

    useEffect(() => {
        if (table.id) {
            onSelectedMetricsChange(table.id, ALWAYS_SHOWN_COLUMNS.concat(selectedPreset?.metrics ?? []));
        }
    }, [onSelectedMetricsChange, selectedPreset?.metrics, table.id]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.tableHeader}>
                <h2>{table.title}</h2>
                <PresetsSelect value={selectedPreset} onChange={setSelectedPreset} />
            </div>
            <div className={styles.tableWrapper}>
                <Table
                    className={styles.table}
                    columns={columns}
                    columnVisibility={columnVisibility}
                    data={table.data}
                    sorting={selectedOrdering.filter((order) =>
                        selectedPreset?.metrics.includes(order.id as SelectableMetrics)
                    )}
                    onSortingChange={setSelectedOrdering}
                    enableSorting
                    renderSortingControls={(col) => {
                        const sortOrder = col.getIsSorted();
                        return !sortOrder ? (
                            <OrderByDefault className={styles.sortingIcon} />
                        ) : sortOrder === 'desc' ? (
                            <OrderByDesc className={styles.sortingIcon} />
                        ) : (
                            <OrderByAsc className={styles.sortingIcon} />
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default memo(PlatformFormatBreakdown);
