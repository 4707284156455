import { Image } from '@round/ui-kit';
import { MicrowaveInfluencerOption } from 'Modules/Advertising/Microwave/useMicrowaveInfluencersSelect';
import styles from './MicrowaveInfluencerOptionLabel.module.css';
import { ReactComponent as TiktokIcon } from 'assets/icons/platform/TikTok.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/platform/Instagram.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/platform/Youtube.svg';
import cn from 'classnames';

type Props = MicrowaveInfluencerOption & {
    context: 'value' | 'menu';
};

const MicrowaveInfluencerOptionLabel = ({
    label,
    tiktokUniqueId,
    instagramUniqueId,
    imageUrl,
    context,
    youtubeChannelTitle,
}: Props) => {
    return (
        <div className={styles.container}>
            {context === 'menu' && <Image src={imageUrl} alt={label} className={styles.image} />}
            <div className={cn(styles.platformsContainer, { [styles.row]: context === 'value' })}>
                {tiktokUniqueId && (
                    <div className={styles.platform}>
                        <TiktokIcon />
                        <span className={styles.username}>{tiktokUniqueId}</span>
                    </div>
                )}
                {instagramUniqueId && (
                    <div className={styles.platform}>
                        <InstagramIcon />
                        <span className={styles.username}>{instagramUniqueId}</span>
                    </div>
                )}
                {youtubeChannelTitle && (
                    <div className={styles.platform}>
                        <YoutubeIcon />
                        <span className={styles.username}>{youtubeChannelTitle}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MicrowaveInfluencerOptionLabel;
