import { Context } from '@fluentui/react-context-selector';
import { getInstagramUsers, InstagramUser } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { makeErrorStateSlice, makeLoadingStateSlice, makeSuccessStateSlice } from 'utility/dataState';

type Data = InstagramUser | null;

export type State = {
    [userId: number]: DataState<Data> | undefined;
};

export const initialState: State = {};

export type Actions =
    | ReducerActionWithPayload<'loadInstagramUsers', number[]>
    | ReducerActionWithPayload<'instagramUsersSuccess', { userIds: number[]; users: InstagramUser[] }>
    | ReducerActionWithPayload<'instagramUsersErrorLoading', { userIds: number[]; error: string }>;

export const reducer = createReducer<State, Actions>({
    loadInstagramUsers: (state, { payload: userIds }) => {
        const loadingData = makeLoadingStateSlice<number, Data>(userIds);

        return { ...state, ...loadingData };
    },
    instagramUsersSuccess: (state, { payload: { userIds, users } }) => {
        const successData = makeSuccessStateSlice<number, Data>(
            userIds,
            (id) => users.find((user) => user.id === id) ?? null
        );

        return { ...state, ...successData };
    },
    instagramUsersErrorLoading: (state, { payload: { userIds, error } }) => {
        const errorData = makeErrorStateSlice<number, Data>(userIds, error);

        return { ...state, ...errorData };
    },
});

export function makeInstagramUsersDataHook<TValue extends BaseDataHookContextValue<Actions>>(
    context: Context<TValue>,
    selector: ContextSelector<TValue, State>
) {
    return function useInstagramUsers() {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (userIds: number[], requestInit?: RequestInit) => {
                if (!userIds.length) {
                    dispatch({ type: 'instagramUsersSuccess', payload: { userIds: [], users: [] } });
                    return;
                }

                try {
                    dispatch({ type: 'loadInstagramUsers', payload: userIds });
                    const response = await getInstagramUsers(
                        { id: userIds.join(), page_size: userIds.length },
                        requestInit
                    );

                    if (response.status === 200) {
                        dispatch({
                            type: 'instagramUsersSuccess',
                            payload: { userIds, users: response.data.results },
                        });
                        return response;
                    }

                    dispatch({
                        type: 'instagramUsersErrorLoading',
                        payload: { userIds, error: 'Could not load Instagram users' },
                    });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({
                        type: 'instagramUsersErrorLoading',
                        payload: { userIds, error: 'Could not load Instagram users' },
                    });
                    throw e;
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
}
