import React from 'react';
import styles from './Label.module.css';
import { FCWithChildren } from '../../../utility/utility.types';

export const Label: FCWithChildren<{ htmlFor: string }> = ({ htmlFor, children }) => {
    return (
        <label className={styles.label} htmlFor={htmlFor}>
            {children}
        </label>
    );
};
