import React from 'react';

interface IIcon {
    className?: string;
    height?: number;
    width?: number;
    onClick?: (e: React.MouseEvent<SVGElement, MouseEvent>) => void;
    strokeColor?: string;
}

const XCircleIcon = ({ className, height, width, onClick, strokeColor = 'currentColor' }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={strokeColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
            onClick={onClick}
        >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
        </svg>
    );
};

export default XCircleIcon;
