import React from 'react';
import { FacebookTargetingAudienceSelectionOption } from '../../../../../../../../../../App.types';
import styles from './FacebookDetailedTargetingSelectionDescription.module.css';
import { FCWithChildren } from '../../../../../../../../../../utility/utility.types';

type FacebookDetailedTargetingSelectionDescriptionProps = {
    selection: FacebookTargetingAudienceSelectionOption;
};

const FacebookDetailedTargetingSelectionDescription: FCWithChildren<FacebookDetailedTargetingSelectionDescriptionProps> = ({
    selection,
}) => {
    const pathLabel = selection.path.length > 1 ? `> ${selection.path.slice(1).join(' > ')}` : '';

    return (
        <div className={styles.container}>
            <p>
                <span className={styles.label}>Size:</span>{' '}
                {new Intl.NumberFormat('en-GB').format(selection.audience_size)}
            </p>

            <p>
                <span className={styles.label}>{selection.path[0]}</span> {pathLabel}
            </p>

            {selection.description && (
                <p>
                    <span className={styles.label}>Description: </span> {selection.description}
                </p>
            )}
        </div>
    );
};

export default FacebookDetailedTargetingSelectionDescription;
