import { IconProps } from '../Icon.types';

const TwitterIcon = ({ width, height, className, onClick }: IconProps) => {
    return (
        <svg
            role="img"
            aria-label="Twitter icon"
            onClick={onClick}
            className={className}
            width={width}
            height={height}
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_89_13222)">
                <path
                    d="M6.16064 16.8131C12.9531 16.8131 16.6683 11.1856 16.6683 6.30539C16.6683 6.14555 16.6683 5.98643 16.6575 5.82803C17.3803 5.30525 18.0042 4.65794 18.5 3.91643C17.826 4.21523 17.111 4.4111 16.3789 4.49747C17.1499 4.03599 17.7269 3.31006 18.0025 2.45483C17.2776 2.88504 16.4845 3.1882 15.6574 3.35123C15.1006 2.75916 14.3642 2.36711 13.5621 2.23574C12.7601 2.10438 11.9371 2.24102 11.2205 2.62452C10.5039 3.00802 9.93367 3.61701 9.59806 4.35724C9.26245 5.09747 9.18016 5.92768 9.36392 6.71939C7.89567 6.64574 6.45932 6.26416 5.14809 5.59943C3.83686 4.9347 2.68007 4.00168 1.7528 2.86091C1.28055 3.67391 1.1359 4.63633 1.34832 5.55223C1.56075 6.46812 2.11426 7.26863 2.89616 7.79075C2.30842 7.77353 1.73349 7.61498 1.22 7.32851V7.37531C1.22023 8.22795 1.51539 9.05426 2.0554 9.71409C2.59542 10.3739 3.34705 10.8266 4.1828 10.9955C3.63911 11.1438 3.06866 11.1654 2.51528 11.0588C2.75136 11.7926 3.21082 12.4342 3.82943 12.894C4.44804 13.3539 5.19487 13.6089 5.96552 13.6235C5.19983 14.2253 4.32299 14.6703 3.38516 14.933C2.44733 15.1956 1.46691 15.2708 0.5 15.1542C2.18887 16.238 4.15394 16.8128 6.16064 16.8102"
                    fill="#727F8F"
                />
            </g>
            <defs>
                <clipPath id="clip0_89_13222">
                    <rect width="18" height="18" fill="white" transform="translate(0.5 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default TwitterIcon;
