import { microwave } from '@round/api';
import { useCallback, useState } from 'react';

type State =
    | {
          data: null;
          status: 'not-initialized';
          error: null;
      }
    | {
          data: null;
          status: 'error';
          error: string;
      }
    | {
          data: microwave.MicrowaveInfluencer[];
          status: 'success';
          error: null;
      };

const initialState: State = {
    data: null,
    status: 'not-initialized',
    error: null,
};

export function useMicrowaveInfluencers({ page, page_size, id }: microwave.GetMicrowaveInfluencersParams) {
    const [state, setState] = useState<State>(initialState);

    const fetchInfluencers = async (params: microwave.GetMicrowaveInfluencersParams, requestInit?: RequestInit) => {
        try {
            const response = await microwave.getMicrowaveInfluencers(params, requestInit);
            setState({
                data: response.data.results,
                status: 'success',
                error: null,
            });
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            const errorMessage = e instanceof Error ? e.message : 'Could not fetch microwave influencers';
            setState({
                data: null,
                status: 'error',
                error: errorMessage,
            });
        }
    };

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            await fetchInfluencers({ page, page_size, id }, requestInit);
        },
        [page, page_size, id]
    );

    const reset = () => setState(initialState);

    return {
        state,
        init,
        reset,
    };
}
