import { InfluencerPlan } from '../../../../App.types';
import { InfluencerPlanDto } from '../types/InfluencerPlan.types';
import { ApiResponse, NotFoundResponse, fetchWithToken } from '@round/api';

export async function fetchCreateInfluencerPlan(influencerPlan: InfluencerPlanDto): Promise<InfluencerPlan> {
    const response = await fetchWithToken(`/api/advertising/viewsets/influencer-plan/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(influencerPlan),
    });

    if (!response.ok) {
        throw new Error('Could not create influencer plan');
    }

    return response.json();
}

export async function fetchDeleteInfluencerPlan(influencerPlanId: number) {
    const response = await fetchWithToken(`/api/advertising/viewsets/influencer-plan/${influencerPlanId}/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Could not delete influencer plan: ${influencerPlanId}`);
    }
}

export async function fetchUpdateInfluencerPlan(
    id: number,
    influencerPlanDto: InfluencerPlanDto
): Promise<InfluencerPlan> {
    const response = await fetchWithToken(`/api/advertising/viewsets/influencer-plan/${id}/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(influencerPlanDto),
    });

    if (!response.ok) {
        throw new Error(`Could not update influencer plan: ${id}`);
    }

    return response.json();
}

export async function patchInfluencerPlan(
    planId: number,
    data: Partial<InfluencerPlanDto>
): Promise<ApiResponse<InfluencerPlan, 200> | NotFoundResponse> {
    const response = await fetchWithToken(`/api/advertising/viewsets/influencer-plan/${planId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not update influencer plan: ${planId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
