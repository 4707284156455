import { SetStateAction } from 'react';
import Filters from 'ui-new/Filters/Filters';
import { Filter } from 'ui-new/Filters/useFilterManager';
import styles from './MicroCampaignsFilters.module.css';
import ExpandableSearchInput from 'ui/ExpandableSearchInput/ExpandableSearchInput';
import { ReactComponent as StatusIcon } from 'assets/icons/MinusCircle.svg';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import { microwaveCampaignStatusOptions } from '../helpers';
import { getNonEmptyKeys } from 'utility/utility';
import { microwave } from '@round/api';
import { getInitialFilters } from 'ui-new/Filters/helpers';

export type MicroCampaignsFilterValues = Pick<microwave.GetReleasesParams, 'search' | 'status'>;

type Props = {
    value: MicroCampaignsFilterValues;
    onChange: (setter: SetStateAction<MicroCampaignsFilterValues>) => void;
};

export const getFilterValues = (
    urlState: Partial<Record<keyof MicroCampaignsFilterValues, string>>
): MicroCampaignsFilterValues => {
    return {
        search: urlState.search,
        status: urlState.status as MicroCampaignsFilterValues['status'],
    };
};

const MicroCampaignsFilters = ({ value, onChange }: Props) => {
    const filters: Filter<keyof MicroCampaignsFilterValues>[] = [
        {
            name: 'status',
            label: 'Status',
            icon: StatusIcon,
            render(this, helpers) {
                const Icon = this.icon;
                return (
                    <SelectFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        name={this.label}
                        icon={<Icon className={styles.icon} />}
                        onClear={() => onChange({ status: undefined })}
                        onRemove={() => {
                            onChange({ status: undefined });
                            helpers.remove();
                        }}
                        value={microwaveCampaignStatusOptions.find((o) => value.status === o.value)}
                        onChange={(o) => onChange({ status: o?.value })}
                        options={microwaveCampaignStatusOptions}
                    />
                );
            },
        },
    ];

    return (
        <div className={styles.container}>
            <Filters
                sessionKey="microwave-campaigns-filters"
                className={styles.filters}
                filters={filters}
                options={{ initialFilters: (state) => getInitialFilters(state, getNonEmptyKeys(value)) }}
            />

            <ExpandableSearchInput
                persistIfValuePresent
                value={value.search}
                onChange={(value) => {
                    onChange({ search: value });
                }}
            />
        </div>
    );
};

export default MicroCampaignsFilters;
