import React, { useMemo, useState } from 'react';
import PaginationTable, { PaginationTableProps } from '../../../../../../../ui/PaginationTable/PaginationTable';
import { TiktokUserImage, UserCreator } from '@round/api';
import { CellProps, Column } from 'react-table';
import { Badge, Checkbox, Image, Skeleton } from '@round/ui-kit';
import { buildTiktokUserUrl, formatNumberToKNotation, numberWithCommas } from '../../../../../../../helpers';
import { getCountry } from '../../../../../../../utility/utility';
import TruncatedTextCell from '../../../../../../../SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import styles from './OutreachTable.module.css';
import { ReactComponent as CheckIcon } from '../../../../../../../assets/Check.svg';
import GenreAffinityCell from '../../../components/GenreAffinityCell/GenreAffinityCell';
import cn from 'classnames';

export type OutreachTableRow = UserCreator & {
    image: TiktokUserImage | undefined;
};

type OutreachTableColumn = Column<OutreachTableRow> & {
    isHidden?: boolean;
};

type Props = Omit<PaginationTableProps<OutreachTableRow>, 'columns'> & {
    selectedRowIds: number[];
    onRowSelect: (row: OutreachTableRow) => void;
    onAllRowsSelect: (rows: OutreachTableRow[]) => void;
    shouldShowGenreAffinity: boolean;
};

const OutreachTable = ({ onRowSelect, onAllRowsSelect, selectedRowIds, shouldShowGenreAffinity, ...props }: Props) => {
    const [multiSelectAnchorRowId, setMultiSelectAnchorRowId] = useState<number | null>(null);

    const columns: OutreachTableColumn[] = useMemo(
        () => [
            {
                Header: () => (
                    <div>
                        <Checkbox
                            ariaLabel="Select all"
                            value={props.data.length > 0 && props.data.every((row) => selectedRowIds.includes(row.id))}
                            onChange={() => onAllRowsSelect(props.data)}
                        />
                    </div>
                ),
                id: 'select',
                Cell: ({ row: { original } }: CellProps<OutreachTableRow>) => {
                    return (
                        <Checkbox
                            className={cn({ [styles.anchor]: original.id === multiSelectAnchorRowId })}
                            value={selectedRowIds.includes(original.id)}
                            onChange={(value, event) => {
                                if (event.shiftKey || event.ctrlKey || event.metaKey) {
                                    return;
                                }

                                onRowSelect(original);
                                if (!selectedRowIds.includes(original.id)) {
                                    setMultiSelectAnchorRowId(original.id);
                                }
                            }}
                        />
                    );
                },
            },
            {
                Header: 'Account',
                accessor: 'tiktok_id',
                Cell: ({ row: { original } }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.account}>
                            <Image
                                className={styles.avatar}
                                src={original.image?.avatar_thumb.cached_url ?? ''}
                                alt={original.nickname}
                            />
                            <div className={styles.accountDetails}>
                                <div className={styles.nickname}>
                                    {original.nickname}
                                    {original.verified && (
                                        <div className={styles.verifiedIcon}>
                                            <CheckIcon />
                                        </div>
                                    )}
                                    {original.private_account && (
                                        <Badge className={styles.privateAccountBadge}>private</Badge>
                                    )}
                                </div>
                                <a
                                    className={styles.uniqueId}
                                    onClick={(e) => e.stopPropagation()}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={buildTiktokUserUrl(original.unique_id)}
                                >
                                    @{original.unique_id}
                                </a>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Follower count',
                accessor: 'follower_count',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return <>{formatNumberToKNotation(value)}</>;
                },
            },
            {
                Header: 'Location',
                accessor: 'location',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }

                    return (
                        <div className={styles.location}>
                            <span className={`fi fi-${value.toLowerCase()}`} />
                            {getCountry(value)?.label}
                        </div>
                    );
                },
            },
            {
                Header: 'Signature',
                accessor: 'signature',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return <TruncatedTextCell>{value}</TruncatedTextCell>;
                },
            },
            {
                Header: 'Average Post Views',
                accessor: 'avg_tiktok_post_views',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return <>{value ? numberWithCommas(value) : '-'}</>;
                },
            },
            {
                Header: 'Genre Affinity',
                accessor: 'genre_affinity',
                disableSortBy: true,
                isHidden: !shouldShowGenreAffinity,
                Cell: ({ value: genreAffinityStats }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!genreAffinityStats) {
                        return <>-</>;
                    }

                    return <GenreAffinityCell genreAffinityStats={genreAffinityStats} />;
                },
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return <TruncatedTextCell>{value}</TruncatedTextCell>;
                },
            },
        ],
        [
            multiSelectAnchorRowId,
            onAllRowsSelect,
            onRowSelect,
            props.data,
            props.loading,
            selectedRowIds,
            shouldShowGenreAffinity,
        ]
    );

    const relevantColumns = useMemo(() => columns.filter((column) => !column.isHidden), [columns]);
    return (
        <PaginationTable
            tableClassName={styles.table}
            columns={relevantColumns}
            {...props}
            onContextMenu={(e) => {
                // MacOS ctrl+click is context menu action
                if (e.ctrlKey) {
                    e.preventDefault();
                }
            }}
            onRowClick={(row, page, event) => {
                if (event.shiftKey && !multiSelectAnchorRowId) {
                    onRowSelect(row);
                    setMultiSelectAnchorRowId(row.id);
                    return;
                }

                if (event.shiftKey) {
                    const anchorIndex = page.findIndex((row) => row.original.id === multiSelectAnchorRowId);
                    const targetIndex = page.findIndex((tableRow) => tableRow.original.id === row.id);

                    const rowsInRange = page.slice(
                        Math.min(anchorIndex, targetIndex),
                        Math.max(anchorIndex, targetIndex) + 1
                    );

                    rowsInRange.forEach((row) => {
                        if (!selectedRowIds.includes(row.original.id)) {
                            onRowSelect(row.original);
                        }
                    });
                    return;
                }

                if (event.ctrlKey || event.metaKey) {
                    onRowSelect(row);
                    setMultiSelectAnchorRowId(row.id);
                }
            }}
        />
    );
};

export default OutreachTable;
