import { PhylloTiktokCreatorProfile } from '@round/api';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { CellProps, Column } from 'react-table';
import styles from './TiktokInfluencerWideSearchTable.module.css';
import PaginationTable from '../../../../../ui/PaginationTable/PaginationTable';
import { Image } from '@round/ui-kit';
import { formatNumberToKNotation, roundTo2Dp } from 'helpers';
import PhylloContactDetailsBadge from './components/ContactDetailBadge/PhylloContactDetailsBadge';
import { ReactComponent as VerifiedIcon } from '../../../../../assets/Verified.svg';
import TruncatedTextCell from '../../../../../SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';

type TiktokInfluencerWideSearchTableRow = PhylloTiktokCreatorProfile;

type TiktokInfluencerWideSearchTableProps = {
    rows: TiktokInfluencerWideSearchTableRow[];
    isLoading: boolean;
    hasError: boolean;
    page: number;
    pageSize: number;
    setPage: (page: number) => void;
    setPageSize: (pageSize: number) => void;
    count: number;
};

const TiktokInfluencerWideSearchTable = ({
    rows,
    isLoading,
    hasError,
    page,
    pageSize,
    count,
    setPage,
    setPageSize,
}: TiktokInfluencerWideSearchTableProps) => {
    const columns = useMemo<Column<TiktokInfluencerWideSearchTableRow>[]>(
        () => [
            {
                Header: 'User',
                id: 'user',
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerWideSearchTableRow>) => {
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    const { username, image_url, url } = original;
                    return (
                        <div className={styles.userDetails}>
                            <Image src={image_url} alt={username} className={styles.profileImage} />
                            <div>
                                <div>
                                    <a href={url} target="_blank" rel="noreferrer">
                                        {username}
                                    </a>
                                    {original.is_verified && <VerifiedIcon className={styles.verifiedIcon} />}
                                </div>
                                <span>{original.fullname}</span>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Bio',
                accessor: 'bio',
                Cell: ({ value }) => {
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }

                    return <TruncatedTextCell className={styles.bio}>{value}</TruncatedTextCell>;
                },
            },
            {
                Header: 'Followers',
                accessor: 'follower_count',
                Cell: ({ value: followerCount }) => {
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return <>{followerCount ? formatNumberToKNotation(followerCount) : '-'}</>;
                },
            },
            {
                Header: 'Engagement',
                accessor: 'engagement_rate',
                Cell: ({ value: engagementRate }) => {
                    if (isLoading) {
                        return <Skeleton />;
                    }
                    const engagementRateDisplayValue = engagementRate ? `${roundTo2Dp(engagementRate)}%` : '-';

                    return <>{engagementRateDisplayValue}</>;
                },
            },
            {
                Header: 'Average Views',
                accessor: 'average_views',
                Cell: ({ value: averageViews }) => {
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return <>{averageViews ? formatNumberToKNotation(averageViews) : '-'}</>;
                },
            },
            {
                Header: 'Location',
                accessor: 'creator_location',
                Cell: ({ value: creatorLocation }) => {
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    if (!creatorLocation) {
                        return <>-</>;
                    }

                    const { country, city, state } = creatorLocation;
                    const subLocation = `${city ? city + ', ' : ''}${state ? state : ''}`;

                    return (
                        <div className={styles.location}>
                            <span className={styles.country}>{country}</span>
                            <span className={styles.subLocation}>{subLocation}</span>
                        </div>
                    );
                },
            },
            {
                Header: 'Age Group',
                accessor: 'age_group',
                Cell: ({ value }) => {
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Contact',
                accessor: 'contact_details',
                Cell: ({ value: contactDetails }) => {
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    if (!contactDetails?.length) {
                        return <>-</>;
                    }

                    return (
                        <div className={styles.contactDetails}>
                            {contactDetails?.map((contact) => (
                                <PhylloContactDetailsBadge contactDetails={contact} key={contact.value} />
                            ))}
                        </div>
                    );
                },
            },
        ],
        [isLoading]
    );

    const isTrialUser = useCheckUserGroupsAccess(['wide_search_trial']);
    const filteredColumns = useMemo(
        () => (isTrialUser ? columns.filter((column) => column.accessor !== 'contact_details') : columns),
        [columns, isTrialUser]
    );

    return (
        <PaginationTable
            manualPagination
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={(pageSize) => setPageSize(pageSize ?? 10)}
            count={count}
            loading={isLoading}
            columns={filteredColumns}
            data={rows}
            noDataLabel={hasError ? 'Something went wrong' : 'No influencers found'}
        />
    );
};

export default TiktokInfluencerWideSearchTable;
