import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import { ApiResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { TiktokAudioStats, TiktokAudioViewStats } from './audioStats.types';

type GetTiktokAudioStatsParams = Partial<
    PaginatedRequest & {
        search: string;
        min_video_count: number;
        max_video_count: number;
        is_original: boolean;
        ordering: string;
    }
>;

export async function getTiktokAudioStats(
    params: GetTiktokAudioStatsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<TiktokAudioStats>, 200>> {
    const response = await fetchWithToken(
        `/api/tiktok/audio-stats-cache/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not get tiktok audio stats`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getTiktokAudioViewStats(
    audioId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<TiktokAudioViewStats, 200>> {
    const response = await fetchWithToken(`/api/tiktok/viewsets/audio/${audioId}/stats/`, requestInit);

    if (!response.ok) {
        throw new Error(`Could not get tiktok audio views stats`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
