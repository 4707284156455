import { PaypalTransactionStatus } from '@round/api';
import styles from './PaypalTransactionStatusCell.module.css';
import cn from 'classnames';
import { Skeleton } from '@round/ui-kit';

const paypalTransactionStatusLabelMap: Record<PaypalTransactionStatus, string> = {
    BLOCKED: 'Blocked',
    FAILED: 'Failed',
    ONHOLD: 'On Hold',
    PENDING: 'Pending',
    RETURNED: 'Returned',
    REFUNDED: 'Refunded',
    REVERSED: 'Reversed',
    SUCCESS: 'Success',
    UNCLAIMED: 'Unclaimed',
};

const statusVerdictMap: Record<string, PaypalTransactionStatus[]> = {
    success: ['SUCCESS'],
    neutral: ['ONHOLD', 'PENDING', 'UNCLAIMED', 'RETURNED', 'REVERSED', 'REFUNDED'],
    failure: ['BLOCKED', 'FAILED'],
};

type Props = {
    status: PaypalTransactionStatus | null | undefined;
    isLoading?: boolean;
};

const PaypalTransactionStatusCell = ({ status, isLoading }: Props) => {
    if (isLoading) {
        return <Skeleton />;
    }

    if (!status) {
        return <div>-</div>;
    }

    const statusLabel = paypalTransactionStatusLabelMap[status];
    const statusVerdict = Object.keys(statusVerdictMap).find((verdict) => statusVerdictMap[verdict].includes(status));

    return <div className={cn(styles.status, styles[statusVerdict || ''])}>{statusLabel}</div>;
};

export default PaypalTransactionStatusCell;
