import { creatorbase } from '@round/api';
import { PostIdData } from './types';
import { numberWithCommas } from 'helpers';
import moment from 'moment';

export const getIdsFromPostIdData = (idData: PostIdData[]) => {
    return {
        postIds: idData.map(({ postId }) => postId),
        contentIds: idData.map(({ contentId }) => contentId),
    };
};

export const getPostContentId = (post: creatorbase.Post) => {
    if (post.platform === 'youtube') {
        return post.youtube_details?.content_id;
    }

    if (post.platform === 'tiktok') {
        return post.tiktok_details?.content_id;
    }

    if (post.platform === 'instagram') {
        return post.instagram_details?.content_id;
    }
};

export const formatPostStatsCellValue = (value: number | null | undefined) => {
    if (typeof value !== 'number') {
        return '-';
    }

    return numberWithCommas(value);
};

export const formatPostUploadDate = (date: string | Date) => moment(date).format('DD MMM YYYY');
