import { Brand } from './brand.types';
import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';
import { ApiResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';

export async function getAllBrands(requestInit?: RequestInit): Promise<Brand[]> {
    return fetchAll('/api/advertising/viewsets/brand/', requestInit);
}

export type GetBrandsParams = Partial<PaginatedRequest & { search: string }>;
type GetBrandsResponse = ApiResponse<PaginatedApiResponseData<Brand>, 200>;
export async function getBrands(params: GetBrandsParams, requestInit?: RequestInit): Promise<GetBrandsResponse> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/brand/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not get brands`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetBrandResponse = ApiResponse<Brand, 200> | ApiResponse<{ detail: string }, 404>;

export async function getBrand(brandId: number, requestInit?: RequestInit): Promise<GetBrandResponse> {
    const response = await fetchWithToken(`/api/advertising/viewsets/brand/${brandId}/`, requestInit);

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not fetch brand with id ${brandId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
