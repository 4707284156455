import React, { DetailedHTMLProps, HTMLAttributes, useState } from 'react';
import { usePopper } from 'react-popper';
import styles from './TruncatedTextCell.module.css';
import cn from 'classnames';
import { createPortal } from 'react-dom';

type TruncatedTextCellProps = {
    children: string;
    className?: string;
    popoverClassName?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const TruncatedTextCell = ({ children, className, popoverClassName, ...props }: TruncatedTextCellProps) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [anchorRef, setAnchorRef] = useState<HTMLSpanElement | null>(null);
    const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
    const { attributes, styles: popperStyles } = usePopper(anchorRef, tooltipRef, {
        placement: 'top',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 15],
                },
            },
        ],
    });

    const onMouseEnter = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        const { clientWidth, scrollWidth, clientHeight, scrollHeight } = e.currentTarget;
        setShowTooltip(clientWidth < scrollWidth || clientHeight < scrollHeight);
    };

    return (
        <>
            <span
                ref={setAnchorRef}
                className={cn(styles.text, className)}
                onMouseEnter={onMouseEnter}
                onMouseLeave={() => setShowTooltip(false)}
                {...props}
            >
                {children}
            </span>
            {showTooltip &&
                createPortal(
                    <div ref={setTooltipRef} style={{ ...popperStyles.popper, pointerEvents: 'none' }} {...attributes}>
                        <div className={cn(styles.content, popoverClassName)}>{children}</div>
                        <div className={styles.arrow} />
                    </div>,
                    document.body
                )}
        </>
    );
};

export default TruncatedTextCell;
