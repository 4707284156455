import { fetchWithToken } from '../../helpers';
import { AssetsLibraryPlatform, Video, VideoBodyPut, VideosBodyGet } from './AssetsLibraryModal.types';

export const getVideos = async (platform: AssetsLibraryPlatform, planId: number): Promise<VideosBodyGet> => {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/${platform}-influencer-plan-video/?plan=${planId}`
    );
    return response.json();
};

export const postVideo = async (platform: AssetsLibraryPlatform, planId: number, file: File): Promise<Video> => {
    const formData = new FormData();
    formData.append('plan', String(planId));
    formData.append('name', file.name);

    const response = await fetchWithToken(`/api/advertising/viewsets/${platform}-influencer-plan-video/`, {
        method: 'POST',
        body: formData,
    });
    return response.json();
};

export const putVideo = async (platform: AssetsLibraryPlatform, video: VideoBodyPut): Promise<Video> => {
    const response = await fetchWithToken(`/api/advertising/viewsets/${platform}-influencer-plan-video/${video.id}/`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(video),
    });
    return response.json();
};

export const deleteVideo = async (platform: AssetsLibraryPlatform, id: number): Promise<void> => {
    await fetchWithToken(`/api/advertising/viewsets/${platform}-influencer-plan-video/${id}/`, {
        method: 'DELETE',
    });
};

export const putVideoToS3 = async (fileUrl: string, file: File): Promise<void> => {
    await fetch(fileUrl, {
        method: 'PUT',
        body: file,
    });
};
