import { useCallback, useState } from 'react';
import { getNumberOfActiveCampaigns, getProjects } from '../Projects.api';
import { Project } from '../Projects.types';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';

export function useCampaigns({
    page,
    page_size,
    search,
    status,
    creator_planner,
    campaign_type,
}: Parameters<typeof getProjects>[0]) {
    const [campaigns, setCampaigns] = useState<Project[]>([]);
    const [campaignsCount, setCampaignsCount] = useState(0);
    const [errorLoadingCampaigns, setErrorLoadingCampaigns] = useState(false);
    const [campaignsLoading, setCampaignsLoading] = useState(false);
    const [numberOfActiveCampaigns, setNumberOfActiveCampaigns] = useState<number>();

    const fetchCampaigns = useCallback(async (...params: Parameters<typeof getProjects>) => {
        try {
            setErrorLoadingCampaigns(false);
            setCampaignsLoading(true);
            const campaigns = await getProjects(...params);
            setCampaigns(campaigns.results);
            setCampaignsCount(campaigns.count);
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            setErrorLoadingCampaigns(true);
        } finally {
            setCampaignsLoading(false);
        }
    }, []);

    const fetchNumberOfActiveCampaigns = useCallback(async (requestInit?: RequestInit) => {
        try {
            const number = await getNumberOfActiveCampaigns(requestInit);
            setNumberOfActiveCampaigns(number);
        } catch {
            // ignore it as for now the same api is used for campaigns
            // so we'll show error on campaigns fetch
        }
    }, []);

    useAbortableEffect(
        (signal) => {
            fetchCampaigns({ page, page_size, search, status, creator_planner, campaign_type }, { signal });
        },
        [fetchCampaigns, page, page_size, search, status, creator_planner, campaign_type]
    );

    useAbortableEffect(
        (signal) => {
            fetchNumberOfActiveCampaigns({ signal });
        },
        [fetchNumberOfActiveCampaigns]
    );

    return {
        campaigns,
        campaignsCount,
        errorLoadingCampaigns,
        campaignsLoading,
        numberOfActiveCampaigns,
    };
}
