import React from 'react';
import { UserGroup } from '@round/api';
import { useCheckUserGroupsAccess } from '../../Modules/Auth/hooks/useCheckUserGroupsAccess';
import { FCWithChildren } from '../../utility/utility.types';

type ProtectedByUserGroupsProps = {
    groups: UserGroup[];
    fallback?: React.ReactElement;
    shouldMatch?: 'all' | 'any';
};

export const ProtectedByUserGroups: FCWithChildren<ProtectedByUserGroupsProps> = ({
    children,
    groups,
    fallback = null,
    shouldMatch = 'all',
}) => {
    const hasAccess = useCheckUserGroupsAccess(groups, shouldMatch);
    if (typeof hasAccess !== 'boolean') {
        return null;
    }

    if (!hasAccess) {
        return fallback;
    }

    return <>{children}</>;
};
