import { creatorbase } from '@round/api';
import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReportContext } from '../ReportContext';
import { useCallback } from 'react';

export default function usePlatformHeadlineStats(platform: creatorbase.Platform) {
    const state = useNonNullContextSelector(ReportContext, ([values]) => values.platformAggregatedPostStats[platform]);
    const dispatch = useNonNullContextSelector(ReportContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (reportId: string, signal?: AbortSignal) => {
            dispatch({ type: 'loadPlatformAggregatedPostStats', payload: platform });

            try {
                const response = await creatorbase.postGetAggregatedPostStats(
                    { platform, report_id: reportId },
                    signal
                );

                if (response.status === 200) {
                    dispatch({
                        type: 'platformAggregatedPostStatsLoaded',
                        payload: { platform, stats: response.data },
                    });
                    return response;
                }

                const errorMessage =
                    response.status === 400 ? Object.values(response.data).flat().join('. ') : response.data.detail;
                dispatch({ type: 'platformAggregatedPostStatsError', payload: { platform, error: errorMessage } });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({
                    type: 'platformAggregatedPostStatsError',
                    payload: { platform, error: 'Could not load stats' },
                });
                throw e;
            }
        },
        [dispatch, platform]
    );

    return {
        ...state,
        fetchData,
    };
}
