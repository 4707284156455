import React, { ChangeEventHandler, InputHTMLAttributes } from 'react';
import styles from './InputWithIcons.module.css';
import cn from 'classnames';

type InputProps = {
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    renderIconLeft?: () => React.ReactNode;
    renderIconRight?: () => React.ReactNode;
} & InputHTMLAttributes<HTMLInputElement>;

const InputWithIcons = ({ renderIconLeft, renderIconRight, className, ...props }: InputProps) => {
    return (
        <div className={cn(styles.container, className)}>
            {!!renderIconLeft && renderIconLeft()}
            <input className={styles.input} {...props} />
            {!!renderIconRight && renderIconRight()}
        </div>
    );
};

export default InputWithIcons;
