import { StylesConfig } from 'react-select';

export const baseSelectStyles: StylesConfig = {
    control: (base, props) => ({
        ...base,
        fontWeight: 400,
        fontSize: '0.8125rem',
        borderColor: '#C2CFE0',
        backgroundColor: props.isDisabled ? '#C2CFE050' : base.backgroundColor,
        minHeight: 'auto',
        padding: '0',
        ':hover': {
            borderColor: '#C2CFE0',
            padding: '0',
        },
    }),
    valueContainer: (base, props) => ({
        ...base,
        padding: props.isMulti ? '0.625rem 1rem 0.4375rem 1rem' : '0.625rem 1rem',
        'input[readonly]': {
            padding: 0,
        },
    }),
    multiValue: (base) => ({
        ...base,
        margin: '0 3px 3px 0',
    }),
    singleValue: (base) => ({
        ...base,
        lineHeight: '1.5',
    }),
    option: (base, props) => ({
        ...base,
        fontWeight: 400,
        fontSize: '0.8125rem',
        cursor: 'pointer',
        background: props.isSelected ? '#EEEEEE' : props.isFocused ? '#F5F6F8' : 'white',
        color: '#333333',
        ':hover': {
            background: props.isSelected ? '#EEEEEE' : '#F5F6F8',
        },
    }),
    noOptionsMessage: (base) => ({
        ...base,
        fontWeight: 400,
        fontSize: '0.8125rem',
    }),
    loadingMessage: (base) => ({
        ...base,
        fontWeight: 400,
        fontSize: '0.8125rem',
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 1000,
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 0,
        paddingRight: '0.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '1rem',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }),
};
