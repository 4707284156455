import NavSidebar from 'ui-new/whitelabel/navigation/NavSidebar/NavSidebar';
import styles from './WhiteLabelLayout.module.css';
import { FCWithChildren } from 'utility/utility.types';
import { isNavItemGroup, NavElement, routes } from 'Modules/Navigation/whitelabel/routes';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import ErrorBoundary from 'utility/ErrorBoundary/ErrorBoundary';
import ErrorFallback from 'ui-new/whitelabel/ErrorFallback/ErrorFallback';
import { useGetScreenDimensions } from 'Hooks/useGetScreenDimensions';
import MobileNavbar from 'ui-new/whitelabel/navigation/MobileNavbar/MobileNavbar';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';

const WhiteLabelLayout: FCWithChildren = ({ children }) => {
    const featureFlags = useFeatureFlags();
    const location = useLocation();

    const title = useMemo(() => {
        const flatRoutes = routes.map((route) => (isNavItemGroup(route) ? route.items : route)).flat();
        const route = flatRoutes.find((route) => location.pathname.includes(route.url));

        if (route) {
            return `${route.title}`;
        }
    }, [location.pathname]);

    const { screenWidth } = useGetScreenDimensions();
    const shouldRenderMobileNavMenu = screenWidth <= 700 && !!routes.length;
    const NavigationMenu = shouldRenderMobileNavMenu ? MobileNavbar : NavSidebar;

    const routesToDisplay = routes
        .map((item) => {
            if (isNavItemGroup(item)) {
                const subItemsToDisplay = item.items.filter((item) => {
                    return !item.featureFlag || featureFlags.isEnabled(item.featureFlag);
                });

                return subItemsToDisplay.length > 0
                    ? {
                          ...item,
                          items: subItemsToDisplay,
                      }
                    : null;
            }

            return !item.featureFlag || featureFlags.isEnabled(item.featureFlag) ? item : null;
        })
        .filter((el): el is NavElement => Boolean(el));

    return (
        <div className={styles.container}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <NavigationMenu items={routesToDisplay} />
            <main className={styles.main}>
                <ErrorBoundary fallbackRender={ErrorFallback}>{children}</ErrorBoundary>
            </main>
        </div>
    );
};

export default WhiteLabelLayout;
