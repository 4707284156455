import { Field, FieldHookConfig, useField } from 'formik';
import styles from './FormField.module.css';
import cn from 'classnames';
import { Label, SubLabel } from './Label/Label';
import ErrorMessage from './ErrorMessage/ErrorMessage';

type Props = FieldHookConfig<string> & { placeholder?: string; className?: string; label?: string; subLabel?: string };

const FormField = ({ label, className, placeholder, disabled, type, subLabel, ...props }: Props) => {
    const [field, meta] = useField(props);

    const hasError = !!meta.error && !!meta.touched;

    return (
        <div className={cn(styles.field, className)}>
            <Label htmlFor={props.name}>{label}</Label>
            {subLabel && <SubLabel>{subLabel}</SubLabel>}
            <Field
                id={props.name}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                className={cn(styles.input, { [styles.error]: hasError })}
                component={props.component}
                {...field}
            />

            <ErrorMessage name={props.name} />
        </div>
    );
};

export default FormField;
