import { creatorbase, PaginatedApiResponseData } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

const platforms = ['instagram', 'tiktok', 'youtube'] as const;

type State = Record<
    creatorbase.Platform,
    DataState<PaginatedApiResponseData<creatorbase.PublicCampaign>> & {
        isInitialized: boolean;
    }
>;

type Actions =
    | ReducerActionWithPayload<'campaignsIdle', creatorbase.Platform>
    | ReducerActionWithPayload<'loadCampaigns', creatorbase.Platform>
    | ReducerActionWithPayload<
          'campaignsSuccess',
          {
              platform: creatorbase.Platform;
              data: PaginatedApiResponseData<creatorbase.PublicCampaign>;
          }
      >
    | ReducerActionWithPayload<'errorLoadingCampaigns', { platform: creatorbase.Platform; message: string }>
    | ReducerActionWithPayload<'campaignsInitialized', creatorbase.Platform>;

export const initialState: State = platforms.reduce((acc, platform) => {
    acc[platform] = {
        data: null,
        error: null,
        status: 'idle',
        isInitialized: false,
    };
    return acc;
}, {} as State);

export const reducer = createReducer<State, Actions>({
    campaignsIdle: (state, { payload: platform }) => ({
        ...state,
        [platform]: {
            ...state[platform],
            status: 'idle',
        },
    }),
    loadCampaigns: (state, { payload: platform }) => ({
        ...state,
        [platform]: {
            ...state[platform],
            status: 'loading',
        },
    }),
    errorLoadingCampaigns: (state, { payload: { platform, message } }) => ({
        ...state,
        [platform]: {
            ...state[platform],
            error: message,
            status: 'error',
        },
    }),
    campaignsSuccess: (state, { payload: { platform, data } }) => {
        const currentResults = state[platform].data?.results || [];
        return {
            ...state,
            [platform]: {
                data: {
                    ...data,
                    results: currentResults.concat(data.results),
                },
                error: null,
                status: 'success',
            },
        };
    },
    campaignsInitialized: (state, { payload: platform }) => ({
        ...state,
        [platform]: {
            ...state[platform],
            isInitialized: true,
        },
    }),
});
