import { PaginatedRequest, PaginatedApiResponseData } from '../App.types';
import { useCallback, useState } from 'react';
import useAbortableEffect from './useAbortableEffect';
import { Primitives } from '../utility/utility.types';

export type PaginatedFetchParams = Partial<PaginatedRequest & { nextUrl?: string | null }>;
type FetchFunction<T> = (
    options: PaginatedFetchParams,
    requestInit?: RequestInit
) => Promise<PaginatedApiResponseData<T>>;
type Options = PaginatedRequest & Record<string, Primitives | any[]>;
type Result<T> = {
    data: T[];
    page: T[];
    loading: boolean;
    count: number;
    getNextPage: () => Promise<void>;
    refresh: () => Promise<void>;
};

type Configs = {
    beforeFetch?: () => void;
};

export function usePaginatedFetch<T>(
    fetchFunction: FetchFunction<T>,
    { page = 1, page_size, ...restOptions }: Partial<Options> = {},
    { beforeFetch }: Configs = {}
): Result<T> {
    const [nextUrl, setNextUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const [items, setItems] = useState<T[]>([]);
    const [itemsCount, setItemsCount] = useState(0);
    const [currentPageItems, setCurrentPageItems] = useState<T[]>([]);

    const fetchData = useCallback(
        async (...params: Parameters<FetchFunction<T>>) => {
            if (typeof beforeFetch === 'function') {
                beforeFetch();
            }

            try {
                setLoading(true);
                const response = await fetchFunction(...params);
                setCurrentPageItems(response.results);
                setItems(response.results);
                setItemsCount(response.count);
                setNextUrl(response.next);
            } catch {
                // no-op
            } finally {
                setLoading(false);
            }
        },
        [beforeFetch, fetchFunction]
    );

    const fetchNextPage = useCallback(async () => {
        if (!nextUrl) {
            return;
        }

        if (typeof beforeFetch === 'function') {
            beforeFetch();
        }

        try {
            setLoading(true);
            const response = await fetchFunction({ nextUrl });
            setCurrentPageItems(response.results);
            setItems((items) => items.concat(response.results));
            setItemsCount(response.count);
            setNextUrl(response.next);
        } catch {
            // no-op
        } finally {
            setLoading(false);
        }
    }, [beforeFetch, fetchFunction, nextUrl]);

    useAbortableEffect(
        (signal) => {
            fetchData({ page, page_size, ...restOptions }, { signal });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetchData, page, page_size, JSON.stringify(restOptions)]
    );

    return {
        getNextPage: fetchNextPage,
        data: items,
        count: itemsCount,
        page: currentPageItems,
        loading,
        refresh: () => fetchData({ page, page_size, ...restOptions }),
    };
}
