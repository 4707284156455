import React from 'react';
import { OptionProps, components } from 'react-select';
import { GenericDropdownOption } from '../../App.types';
import { Checkbox } from '@round/ui-kit';
import Rating from '../Rating/Rating';
import styles from './RatingSelect.module.css';

type Props = OptionProps<GenericDropdownOption<number>, true>;

const RatingSelectOption = (props: Props) => {
    // This relies on all rating options' "value" property to represent actual rating.
    const maxRating = Math.max(...props.options.map((o) => o.value));
    return (
        <components.Option {...props} className={styles.option}>
            <Checkbox value={props.isSelected} />
            <Rating rating={props.data.value} maxRating={maxRating} />
        </components.Option>
    );
};

export default RatingSelectOption;
