import React, { useMemo, useState } from 'react';
import PaginationTable from '../../../../../../ui/PaginationTable/PaginationTable';
import { Column } from 'react-table';
import Skeleton from '../../../../../../ui/Skeleton/Skeleton';
import { displayOptionalNumericTableValue, formatDateShort } from '../../../../../../helpers';
import { InstagramTopVideo } from '@round/api';
import styles from './InstagramUserTopPosts.module.css';
import TruncatedTextCell from '../../../../../../SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';

type InstagramUserTopPostsProps = {
    topVideos: InstagramTopVideo[];
    loading: boolean;
    errorLoadingTopVideos: boolean;
};

const InstagramUserTopPosts = ({ topVideos, loading, errorLoadingTopVideos }: InstagramUserTopPostsProps) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const columns = useMemo<Column<InstagramTopVideo>[]>(
        () => [
            {
                Header: 'Description',
                accessor: 'description',
                Cell: ({ value, row: { original } }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <a className={styles.link} href={original.post_url} target="_blank" rel="noopener noreferrer">
                            <TruncatedTextCell>{value}</TruncatedTextCell>
                        </a>
                    );
                },
            },
            {
                Header: 'Date posted',
                accessor: 'create_time',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{formatDateShort(value)}</>;
                },
            },
            {
                Header: 'Views',
                accessor: 'view_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{displayOptionalNumericTableValue(value)}</>;
                },
            },
            {
                Header: 'Likes',
                accessor: 'like_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{displayOptionalNumericTableValue(value)}</>;
                },
            },
            {
                Header: 'Comments',
                accessor: 'comment_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{displayOptionalNumericTableValue(value)}</>;
                },
            },
        ],
        [loading]
    );

    return (
        <div>
            <p>Top Posts</p>
            <PaginationTable
                tableClassName={styles.table}
                theadClassName={styles.thead}
                loading={loading}
                disableSortBy
                manualPagination={false}
                data={topVideos}
                columns={columns}
                count={topVideos.length}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={(pageSize) => setPageSize(pageSize ?? 10)}
                noDataLabel={errorLoadingTopVideos ? 'Sorry, we are experiencing technical issues' : 'No top posts'}
            />
        </div>
    );
};

export default InstagramUserTopPosts;
