import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import ModalRight from '../../../../SharedComponents/ModalRight/ModalRight';
import CloseIcon from '../../../../SharedComponents/svg/Icons/CloseIcon';
import styles from './TiktokAudioModal.module.css';
import MetricCardWithChart from '../../../../ui/DataDisplay/Statistics/MetricCardWithChart/MetricCardWithChart';
import MusicNoteIcon from '../../../../SharedComponents/svg/Icons/MusicNoteIcon';
import CalendarIcon from '../../../../SharedComponents/svg/Icons/CalendarIcon';
import {
    buildTiktokMusicUrl,
    formatNumberToCommaNotation,
    formatNumberToKNotation,
    numberWithCommas,
    roundTo2Dp,
    showNotification,
} from '../../../../helpers';
import ViewIcon from '../../../../SharedComponents/svg/Icons/ViewIcon';
import DateRangeLineChart, { Point } from '../../../../ui/DataDisplay/Charts/DateRangeLineChart/DateRangeLineChart';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import { TiktokAudiosTableRow } from '../../TikTok.types';
import { triggerTikTokAudioScraping } from '../../TikTok.api';
import Tabs from '../../../../ui/Tabs/Tabs';
import TopPostsTable from './TopPostsTable/TopPostsTable';
import { useCheckUserGroupsAccess } from '../../../Auth/hooks/useCheckUserGroupsAccess';
import AudioPlaybackThumbnail from 'Modules/AudioPlayer/AudioPlaybackThumbnail/AudioPlaybackThumbnail';
import ExploreRefreshButton from '../../../Explore/components/ExploreRefreshButton/ExploreRefreshButton';
import { ProtectedByUserGroups } from '../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import { updateTikTokAudioAlternateName } from '../../TikTok.services';
import Skeleton from '../../../../ui/Skeleton/Skeleton';
import {
    getTiktokAudio,
    TiktokAudio,
    MonitoredAudioUrl,
    getMonitoredAudio,
    getTiktokAudioDailyStats,
    TiktokAudioDailyStats,
} from '@round/api';
import TooltipCard from '../../../../ui/DataDisplay/TooltipCard/TooltipCard';
import useTopPosts from './TopPostsTable/useTopPosts';
import { useAudioViewStats } from './hooks/useAudioViewStats';
import ProgressBar from '../../../../ui/ProgressBar/ProgressBar';
import countries from '../../../../utility/countries';

type TiktokAudioModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    audioId: number | undefined;
    monitoredAudioStats?: TiktokAudiosTableRow | null;
    onAlternateNameUpdated?: (value: string) => void;
};

const now = new Date();
now.setHours(23, 59, 59);
const twoWeeksAgo = new Date(now.getTime() - 1000 * 60 * 60 * 24 * 7 * 2);
twoWeeksAgo.setHours(0, 0, 0, 0);

const TiktokAudioModal = ({
    isModalOpen,
    closeModal,
    audioId,
    monitoredAudioStats,
    onAlternateNameUpdated,
}: TiktokAudioModalProps) => {
    const hasUserAdminPermissions = useCheckUserGroupsAccess(['user_admin']);
    const [monitoredAudio, setMonitoredAudio] = useState<MonitoredAudioUrl | null>(null);

    const [tiktokAudio, setTikTokAudio] = useState<TiktokAudio | null>(null);
    const [tiktokAudioLoading, setTiktokAudioLoading] = useState(false);
    const [errorLoadingTiktokAudio, setErrorLoadingTiktokAudio] = useState(false);

    const monitoredAudioIds: number[] = useMemo(() => {
        if (!monitoredAudioStats) {
            return tiktokAudio?.monitored_audio_id ?? [];
        }

        return typeof monitoredAudioStats.monitored_music_id === 'number'
            ? [monitoredAudioStats.monitored_music_id]
            : [];
    }, [monitoredAudioStats, tiktokAudio?.monitored_audio_id]);

    const audioInfoLoading = tiktokAudioLoading && !monitoredAudioStats;
    const audioImage = monitoredAudioStats?.image_url ?? tiktokAudio?.cover_thumb;
    const audioPlayUrl = monitoredAudioStats?.audioUrl ?? tiktokAudio?.play_url;
    const audioTiktokId = monitoredAudioStats?.tiktok_id ?? tiktokAudio?.tiktok_id;
    const audioTitle = monitoredAudioStats?.title ?? tiktokAudio?.title;
    const isOriginalAudio = monitoredAudioStats?.is_original ?? tiktokAudio?.is_original;
    const audioAlternateName = monitoredAudioStats?.alternate_name ?? tiktokAudio?.alternate_name;

    const [alternateName, setAlternateName] = useState(audioAlternateName);
    const [dailyStats, setDailyStats] = useState<TiktokAudioDailyStats[]>([]);
    const [dailyStatsLoading, setDailyStatsLoading] = useState(false);
    const [dailyStatsLoadingError, setDailyStatsLoadingError] = useState(false);
    const [dailyStatsDateFrom, setDailyStatsDateFrom] = useState<Date>(twoWeeksAgo);
    const [dailyStatsDateTo, setDailyStatsDateTo] = useState<Date>(now);

    const [refreshLoading, setRefreshLoading] = useState(false);
    const statsLastUpdatedDate = useMemo(() => {
        const dates = dailyStats?.map((stats) => Date.parse(stats.timestamp)) ?? [];
        return new Date(Math.max(...dates));
    }, [dailyStats]);

    const {
        topPosts,
        count: topPostsCount,
        abortController: topPostsAbortController,
        refresh: refreshTopPosts,
        reset: resetTopPosts,
        hasErrorLoading: errorLoadingTopPosts,
        isLoading: isTopPostsLoading,
        parameterHandlers: topPostsParameterHandlers,
    } = useTopPosts(audioId);

    const topPostsNoDataLabel = errorLoadingTopPosts
        ? 'Sorry, we are experiencing technical issues'
        : 'No top posts found';

    const topPostsRecordsShowingLabel = `displaying ${topPosts.length} post${
        topPosts.length === 1 ? '' : 's'
    } out of ${numberWithCommas(topPostsCount)} analysed`;

    const dailyStatsInRange = useMemo(
        () =>
            dailyStats.filter((stats) => {
                const date = Date.parse(stats.timestamp);
                return date >= dailyStatsDateFrom.getTime() && date <= dailyStatsDateTo.getTime();
            }),
        [dailyStats, dailyStatsDateFrom, dailyStatsDateTo]
    );

    const totalCreationsPoints = useMemo<{ x: string; y: number }[]>(
        () =>
            dailyStatsInRange.map((s) => ({
                x: s.timestamp,
                y: s.video_count,
            })),
        [dailyStatsInRange]
    );

    const dailyCreationsPoints = useMemo(
        () =>
            dailyStatsInRange.map(
                (s) => ({
                    x: s.timestamp,
                    y: s.change,
                }),
                []
            ),
        [dailyStatsInRange]
    );

    const totalCreations = useMemo(() => {
        if (monitoredAudioStats) {
            return monitoredAudioStats.video_count;
        }

        return totalCreationsPoints[totalCreationsPoints.length - 1]?.y ?? null;
    }, [totalCreationsPoints, monitoredAudioStats]);

    const dailyCreations = useMemo(() => {
        if (monitoredAudioStats) {
            return monitoredAudioStats.daily_change_standardised_absolute;
        }

        return dailyCreationsPoints[dailyCreationsPoints.length - 1]?.y ?? null;
    }, [dailyCreationsPoints, monitoredAudioStats]);

    const renderTooltip = useCallback(({ total, daily }: { total?: number; daily?: number }) => {
        return (
            <div className={styles.tooltipContent}>
                <span className={styles.tooltipDot} />
                <div>
                    {typeof total === 'number' && (
                        <p>
                            Total Creations:
                            <span className={styles.tooltipValue}>{formatNumberToCommaNotation(total)}</span>
                        </p>
                    )}
                    {typeof daily === 'number' && (
                        <p>
                            Daily Creations:
                            <span className={styles.tooltipValue}>{formatNumberToCommaNotation(daily)}</span>
                        </p>
                    )}
                </div>
            </div>
        );
    }, []);

    const renderTotalCreationsTooltip = useCallback(
        (point: Point) => {
            const dailyCreations = dailyCreationsPoints.find((d) => d.x === point.x);
            return renderTooltip({ total: point.y, daily: dailyCreations?.y });
        },
        [dailyCreationsPoints, renderTooltip]
    );

    const renderDailyCreationsTooltip = useCallback(
        (point: Point) => {
            const totalCreations = totalCreationsPoints.find((t) => t.x === point.x);
            return renderTooltip({ total: totalCreations?.y, daily: point.y });
        },
        [renderTooltip, totalCreationsPoints]
    );

    const {
        viewStats,
        isLoading: isViewStatsLoading,
        isInitialized: isViewStatsInitialized,
        init: initViewStats,
        reset: resetViewStats,
        refresh: refreshViewStats,
    } = useAudioViewStats(audioId);

    const viewStatsAbortController = useAbortableEffect(
        (signal) => {
            if (audioId && !isViewStatsInitialized) {
                initViewStats({ signal });
            }
        },
        [initViewStats, isViewStatsInitialized, audioId]
    );

    const totalViews = viewStats?.total_views || null;
    const totalViewsPostCount = viewStats?.total_views_post_count || null;
    const totalViewsPostCountLabel = totalViewsPostCount ? `Based on ${totalViewsPostCount} posts` : undefined;

    const topLocations = !!viewStats?.locations.countries.length ? viewStats?.locations.countries : null;
    const topLocationsPostCount = viewStats?.location_post_count || 100;
    const topLocationsPostCountLabel = topLocationsPostCount ? `Based on ${topLocationsPostCount} posts` : undefined;

    const fetchTiktokAudio = useCallback(
        async (requestInit?: RequestInit) => {
            if (!audioId) {
                return;
            }

            try {
                setErrorLoadingTiktokAudio(false);
                setTiktokAudioLoading(true);
                const response = await getTiktokAudio(audioId, requestInit);
                if (response.status === 404) {
                    setErrorLoadingTiktokAudio(true);
                    return;
                }

                setTikTokAudio(response.data);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setErrorLoadingTiktokAudio(true);
            } finally {
                setTiktokAudioLoading(false);
            }
        },
        [audioId]
    );

    const fetchStats = useCallback(
        async (requestInit?: RequestInit) => {
            if (!audioId) {
                return;
            }

            try {
                setDailyStatsLoadingError(false);
                setDailyStatsLoading(true);
                const {
                    data: { results: stats },
                } = await getTiktokAudioDailyStats(audioId, requestInit);
                setDailyStats(stats);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setDailyStatsLoadingError(true);
            } finally {
                setDailyStatsLoading(false);
            }
        },
        [audioId]
    );

    const refreshStats = useCallback(async () => {
        if (!audioId || !monitoredAudioIds.length) {
            return;
        }
        try {
            setRefreshLoading(true);
            await Promise.all(
                monitoredAudioIds.map((monitoredAudioId) => triggerTikTokAudioScraping(monitoredAudioId))
            );
            await Promise.all([fetchStats(), refreshTopPosts(), refreshViewStats()]);
            showNotification('Refresh successful', 'info');
        } catch {
            showNotification('Refresh unsuccessful', 'error');
        } finally {
            setRefreshLoading(false);
        }
    }, [audioId, fetchStats, monitoredAudioIds, refreshTopPosts, refreshViewStats]);

    const fetchMonitoredAudio = useCallback(async (monitoredAudioId: number, requestInit?: RequestInit) => {
        try {
            const response = await getMonitoredAudio(monitoredAudioId, requestInit);
            if (response.status === 200) {
                setMonitoredAudio(response.data);
            }
        } catch {
            // no-op
        }
    }, []);

    const initAbortController = useAbortableEffect(
        (signal) => {
            if (!monitoredAudioStats) {
                fetchTiktokAudio({ signal });
            }

            fetchStats({ signal });
        },
        [fetchStats, monitoredAudioStats, fetchTiktokAudio]
    );

    const monitoringAbortController = useAbortableEffect(
        (signal) => {
            if (hasUserAdminPermissions && monitoredAudioIds.length > 0) {
                const [monitoredAudioId] = monitoredAudioIds;
                fetchMonitoredAudio(monitoredAudioId, { signal });
            }
        },
        [hasUserAdminPermissions, fetchMonitoredAudio, monitoredAudioIds.length, monitoredAudioIds]
    );

    const handleClose = useCallback(() => {
        setTikTokAudio(null);

        setDailyStats([]);
        setDailyStatsDateFrom(twoWeeksAgo);
        setDailyStatsDateTo(now);

        resetTopPosts();
        resetViewStats();

        initAbortController.abort();
        monitoringAbortController.abort();
        topPostsAbortController.abort();
        viewStatsAbortController.abort();
        closeModal();
    }, [
        closeModal,
        initAbortController,
        monitoringAbortController,
        resetTopPosts,
        resetViewStats,
        topPostsAbortController,
        viewStatsAbortController,
    ]);

    const onAlternateNameInputBlur = useCallback(
        async (e: React.FocusEvent<HTMLInputElement>) => {
            const { value } = e.target;
            if (value === audioAlternateName || !audioId) {
                return;
            }

            try {
                await updateTikTokAudioAlternateName(audioId, value.trim());
                showNotification('Updated', 'info');
                if (typeof onAlternateNameUpdated === 'function') {
                    onAlternateNameUpdated(value);
                }
            } catch {
                showNotification('Could not update custom name', 'error');
            }
        },
        [audioAlternateName, audioId, onAlternateNameUpdated]
    );

    useEffect(() => {
        setAlternateName(audioAlternateName);
    }, [audioAlternateName]);

    return (
        <ModalRight isModalOpen={isModalOpen} closeModal={handleClose} className={styles.modal}>
            <article className={styles.container}>
                <button className={styles.closeButton} title="close modal" onClick={handleClose}>
                    <CloseIcon width={16} height={16} />
                </button>

                <header className={styles.header}>
                    <AudioPlaybackThumbnail
                        loading={audioInfoLoading}
                        className={styles.audioCover}
                        audioUrl={audioPlayUrl}
                        imageUrl={audioImage}
                    />

                    <div className={styles.titleContainer}>
                        {errorLoadingTiktokAudio && <span>Sorry, we couldn't get audio</span>}
                        <div>
                            {audioInfoLoading ? (
                                <Skeleton className={styles.title} />
                            ) : (
                                <a
                                    className={styles.title}
                                    href={buildTiktokMusicUrl(audioTitle ?? 'original-sound', audioTiktokId ?? '')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {audioTitle}
                                </a>
                            )}
                            {audioInfoLoading ? (
                                <Skeleton className={styles.alternateName} />
                            ) : (
                                alternateName && (
                                    <input
                                        className={styles.alternateName}
                                        value={alternateName}
                                        placeholder="Add custom name"
                                        onChange={(e) => setAlternateName(e.target.value)}
                                        onBlur={onAlternateNameInputBlur}
                                        disabled={true}
                                    />
                                )
                            )}
                        </div>
                        {typeof isOriginalAudio === 'boolean' && (
                            <span
                                className={cn(styles.badge, {
                                    [styles.original]: isOriginalAudio,
                                    [styles.official]: !isOriginalAudio,
                                })}
                            >
                                {isOriginalAudio ? 'Original' : 'Official'}
                            </span>
                        )}
                    </div>

                    <ProtectedByUserGroups groups={['user_admin']}>
                        {monitoredAudio && (
                            <>
                                <TooltipCard
                                    className={styles.statusCard}
                                    tooltipContent={
                                        <dl className={styles.statusDescriptions}>
                                            <div className={styles.statusDescription}>
                                                <dt className={styles.positive}>No issues</dt>
                                                <dd>Audio data is being retrieved daily without any issues. </dd>
                                            </div>

                                            <div className={styles.statusDescription}>
                                                <dt className={styles.warning}>Potential issues</dt>
                                                <dd>
                                                    Data is not being retrieved daily, possible the audio has been
                                                    removed.
                                                </dd>
                                            </div>

                                            <div className={styles.statusDescription}>
                                                <dt className={styles.negative}>No longer available</dt>
                                                <dd>The audio is no longer available on TikTok.</dd>
                                            </div>
                                        </dl>
                                    }
                                >
                                    <p
                                        className={cn(styles.statusCardTitle, {
                                            [styles.positive]: monitoredAudio.is_enabled && monitoredAudio.is_active,
                                            [styles.negative]: !monitoredAudio.is_active,
                                            [styles.warning]: monitoredAudio.is_active && !monitoredAudio.is_enabled,
                                        })}
                                    >
                                        {!monitoredAudio.is_active
                                            ? 'No longer available'
                                            : monitoredAudio.is_active && !monitoredAudio.is_enabled
                                            ? 'Potential issues'
                                            : 'No issues'}
                                    </p>
                                    <p className={styles.statusCardSubtitle}>Reliability Status</p>
                                </TooltipCard>

                                <TooltipCard className={styles.statusCard} disabled>
                                    <p
                                        className={cn(styles.statusCardTitle, {
                                            [styles.positive]: monitoredAudio.is_enhanced,
                                        })}
                                    >
                                        {monitoredAudio.is_enhanced ? 'Enhanced' : 'Normal'}
                                    </p>
                                    <p className={styles.statusCardSubtitle}>Audio Tracking Type</p>
                                </TooltipCard>
                            </>
                        )}

                        {monitoredAudioIds.length > 0 && audioId && (
                            <div className={styles.refreshContainer}>
                                <ExploreRefreshButton
                                    loading={refreshLoading}
                                    lastUpdated={statsLastUpdatedDate}
                                    onClick={refreshStats}
                                />
                            </div>
                        )}
                    </ProtectedByUserGroups>
                </header>

                <section data-test-id="headline-stats" className={styles.statsContainer}>
                    <MetricCardWithChart
                        loading={audioInfoLoading}
                        baseColor="#4CC8D9"
                        value={totalCreations}
                        label="Total creations"
                        Icon={MusicNoteIcon}
                    />

                    <MetricCardWithChart
                        loading={audioInfoLoading}
                        baseColor="#0090FF"
                        value={dailyCreations}
                        label="Daily creations"
                        Icon={CalendarIcon}
                        format={(value) => `${value > 0 ? '+' : ''}${formatNumberToKNotation(value)}`}
                    />

                    <MetricCardWithChart
                        loading={isViewStatsLoading}
                        baseColor="#48D98A"
                        value={totalViews}
                        subtitle={totalViewsPostCountLabel}
                        label="Total views (est.)"
                        Icon={ViewIcon}
                    />
                </section>

                <section className={styles.chartsContainer}>
                    <Tabs
                        headerClassName={styles.tabsHeader}
                        panels={[
                            {
                                name: 'total-creations',
                                title: 'Total creations',
                                render: () => (
                                    <DateRangeLineChart
                                        beginAtZero={Math.max(...totalCreationsPoints.map((i) => i.y)) <= 100}
                                        loading={dailyStatsLoading}
                                        error={dailyStatsLoadingError}
                                        title="Total creations"
                                        points={totalCreationsPoints}
                                        dateFrom={dailyStatsDateFrom}
                                        onDateFromChange={setDailyStatsDateFrom}
                                        dateTo={dailyStatsDateTo}
                                        onDateToChange={setDailyStatsDateTo}
                                        renderTooltip={renderTotalCreationsTooltip}
                                    />
                                ),
                            },
                            {
                                name: 'daily-creations',
                                title: 'Daily creations',
                                render: () => (
                                    <DateRangeLineChart
                                        beginAtZero={Math.max(...dailyCreationsPoints.map((i) => i.y)) <= 100}
                                        title="Daily creations"
                                        points={dailyCreationsPoints}
                                        loading={dailyStatsLoading}
                                        error={dailyStatsLoadingError}
                                        dateFrom={dailyStatsDateFrom}
                                        onDateFromChange={setDailyStatsDateFrom}
                                        dateTo={dailyStatsDateTo}
                                        onDateToChange={setDailyStatsDateTo}
                                        renderTooltip={renderDailyCreationsTooltip}
                                    />
                                ),
                            },
                        ]}
                    />
                </section>

                {topLocations && (
                    <section>
                        <div className={styles.sectionHeader}>
                            <p className={styles.sectionTitle}>Top Locations</p>
                            <p className={styles.sectionSubtitle}>{topLocationsPostCountLabel}</p>
                        </div>
                        <div className={styles.topLocationsContainer}>
                            {topLocations
                                .sort((a, b) => b.percentage - a.percentage)
                                .map((country) => (
                                    <div key={country.code} className={styles.topLocation}>
                                        <div className={styles.locationLabel}>
                                            <span
                                                className={cn(`fi fi-${country.code.toLowerCase()}`, styles.flagIcon)}
                                            />
                                            <span className={styles.countryName}>{countries[country.code]}</span>
                                            <span>{numberWithCommas(roundTo2Dp(country.percentage * 100))}%</span>
                                        </div>

                                        <ProgressBar
                                            barStyles={{ backgroundColor: 'var(--color-primary)' }}
                                            progress={roundTo2Dp(country.percentage * 100)}
                                        />
                                    </div>
                                ))}
                        </div>
                    </section>
                )}

                <section>
                    <div className={styles.sectionHeader}>
                        <p className={styles.sectionTitle}>Top posts</p>
                        <p className={styles.sectionSubtitle}>
                            {isTopPostsLoading ? <Skeleton width="20rem" /> : topPostsRecordsShowingLabel}
                        </p>
                    </div>
                    <TopPostsTable
                        loading={isTopPostsLoading}
                        rows={topPosts}
                        count={topPostsCount}
                        noDataLabel={topPostsNoDataLabel}
                        {...topPostsParameterHandlers}
                    />
                </section>
            </article>
        </ModalRight>
    );
};

export default TiktokAudioModal;
