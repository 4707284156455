import styles from './SubmissionTracker.module.css';
import cn from 'classnames';
import { formatDateShort } from '../../../../../../helpers';
import { ReactComponent as RejectedIcon } from '../../../../../../assets/RejectedCircle.svg';
import { ReactComponent as TickCircle } from '../../../../../../assets/TickCircle.svg';
import { ReactComponent as VenmoIcon } from '../../../../../../assets/Venmo.svg';
import { ReactComponent as PaypalIcon } from '../../../../../../assets/PayPal.svg';
import { ReactComponent as BankTransferIcon } from '../../../../../../assets/PaymentCard.svg';
import LoadingSpinner from '../../../../../../ui/Loading/LoadingSpinner/LoadingSpinner';
import { microwave } from '@round/api';
import React from 'react';

const paymentMethodIconMap: Record<microwave.PaymentMethod, React.ReactElement> = {
    BANK_TRANSFER: (
        <div className={styles.bankTransfer}>
            <BankTransferIcon />
            <div>
                Bank
                <br />
                Account
            </div>
        </div>
    ),
    PAYPAL: <PaypalIcon className={styles.paypal} />,
    VENMO: <VenmoIcon className={styles.venmo} />,
};

type Stage = {
    name: string;
    render: (data?: microwave.TiktokPostInviteForm) => React.ReactNode;
    shouldRenderStage: (data?: microwave.TiktokPostInviteForm) => boolean;
};

export const submissionTrackerStages: Stage[] = [
    {
        name: 'Submitted',
        render: (data) => (
            <div className={styles.row}>
                <div className={styles.pointContainer}>
                    <div className={cn(styles.dot, styles.green)} />
                </div>

                <div>
                    Post submitted{' '}
                    {data?.submission_date && (
                        <>
                            on <b>{formatDateShort(data.submission_date)}</b>
                        </>
                    )}
                </div>
            </div>
        ),
        shouldRenderStage: () => true,
    },
    {
        name: 'In Review',
        render: () => (
            <div className={styles.row}>
                <LoadingSpinner className={styles.spinner} />

                <div className={styles.currentStatus}>
                    <div>
                        <strong>Post In Review</strong>
                        <div>
                            <b>Please be patient, it will take up to 3 days.</b>
                        </div>
                    </div>
                </div>
            </div>
        ),
        shouldRenderStage: (data) => data?.payment_request_status === 'PENDING',
    },
    {
        name: 'Approved',
        render: (data) => (
            <div className={styles.row}>
                <div className={styles.pointContainer}>
                    <div className={cn(styles.dot, styles.green)} />
                </div>

                <div>
                    Post approved{' '}
                    {data?.post_decision_date && (
                        <>
                            on <b>{formatDateShort(data.post_decision_date)}</b>
                        </>
                    )}
                </div>
            </div>
        ),
        shouldRenderStage: (data) => data?.payment_request_status === 'APPROVED',
    },
    {
        name: 'Rejected',
        render: () => (
            <div className={styles.row}>
                <RejectedIcon />

                <div className={styles.currentStatus}>
                    <div>
                        <strong>Rejected</strong>
                        <div>
                            <b>Post didn't match the brief or was submitted after the deadline</b>
                        </div>
                    </div>
                </div>
            </div>
        ),
        shouldRenderStage: (data) => data?.payment_request_status === 'REJECTED',
    },
    {
        name: 'Payment Pending',
        render: (data) => (
            <div className={styles.row}>
                <LoadingSpinner className={styles.spinner} />

                <div className={styles.currentStatus}>
                    <div>
                        <strong>Payment in progress</strong>
                        <div>
                            <b>
                                Payment due{' '}
                                {data?.payment_deadline ? `on ${formatDateShort(data.payment_deadline)}` : 'soon'}
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        ),
        shouldRenderStage: (data) => data?.payment_request_status === 'APPROVED' && !data?.payment_date,
    },
    {
        name: 'Paid',
        render: (data) => (
            <div className={styles.row}>
                <div className={styles.pointContainer}>
                    <div className={cn(styles.dot, styles.green)} />
                </div>

                <div className={styles.paymentRow}>
                    <span>
                        Paid{' '}
                        {data?.payment_date && (
                            <>
                                on <b>{formatDateShort(data.payment_date)}</b>
                            </>
                        )}
                    </span>

                    {data?.payment_method ? (
                        <div className={styles.paymentMethodContainer}>{paymentMethodIconMap[data.payment_method]}</div>
                    ) : null}
                </div>
            </div>
        ),
        shouldRenderStage: (data) => !!data?.payment_date,
    },
    {
        name: 'Submission Incomplete',
        render: () => (
            <div className={styles.row}>
                <div className={styles.pointContainer}>
                    <div className={cn(styles.dot, styles.grey)} />
                </div>
                <div>Completion</div>
            </div>
        ),
        shouldRenderStage: (data) => !data?.payment_date,
    },
    {
        name: 'Submission Completed',
        render: () => (
            <div className={styles.row}>
                <TickCircle className={styles.tick} />

                <div className={styles.currentStatus}>
                    <strong>Thank you for taking part, we look forward to working with you again.</strong>
                </div>
            </div>
        ),
        shouldRenderStage: (data) => !!data?.payment_date,
    },
];
