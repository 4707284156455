import { PaginatedApiResponseData, TiktokInfluencerPostResult } from '../../App.types';
import {
    encodeUrlSearchParams,
    fetchAll,
    fetchWithToken,
    TikTokInfluencerUserStats,
    TiktokAudio,
    TiktokInfluencerUser,
    TiktokInfluencerUserApiBody,
} from '@round/api';
import { AudioData, TiktokHashtagStats } from './TikTok.types';
import { ApiResponse, ApiResponseError } from '../../utility/utility.types';
import { TiktokDailyReportItem } from '../Advertising/MediaPlan/types/MediaPlanResults.types';
import { TiktokVideo } from '../../App.types';

export async function patchTikTokAudio(musicId: number, music: Partial<TiktokAudio>): Promise<TiktokAudio> {
    const response = await fetchWithToken(`/api/tiktok/viewsets/audio/${musicId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(music),
    });

    if (!response.ok) {
        throw new Error(`Could not PATCH TikTok music with id ${musicId}`);
    }

    return response.json();
}

export async function triggerTikTokUserScraping(monitoredUserId: number) {
    const response = await fetchWithToken(`/api/tiktok/trigger-user-scraping/${monitoredUserId}/`, {
        method: 'POST',
    });

    if (!response.ok) {
        throw new Error(`trigger for tiktok monitored user with id ${monitoredUserId} failed`);
    }
}

export async function triggerTikTokAudioScraping(monitoredMusicUrlId: number) {
    const response = await fetchWithToken(`/api/tiktok/trigger-audio-scraping/${monitoredMusicUrlId}/`, {
        method: 'POST',
    });

    if (!response.ok) {
        throw new Error(`trigger for tiktok audio with monitored music url id ${monitoredMusicUrlId} failed`);
    }
}

export async function getTikTokHashtagStats(
    hashtagId: number,
    requestInit?: RequestInit
): Promise<TiktokHashtagStats[]> {
    return (await fetchAll(`/api/tiktok/hashtag-stats/?hashtag_id=${hashtagId}`, requestInit)) ?? [];
}

export type GetTiktokInfluencerUsersParams = Partial<{ page: number; page_size: number; search: string; id: string }>;

export async function getTiktokInfluencerUsers(
    params: GetTiktokInfluencerUsersParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<TiktokInfluencerUser>, 200>> {
    const response = await fetchWithToken(
        `/api/tiktok/viewsets/influencer-user/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not get tiktok influencer users');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetTiktokInfluencerUserStatsResponse =
    | ApiResponse<TikTokInfluencerUserStats, 200>
    | ApiResponse<{ detail: string }, 404>;

export async function getTiktokInfluencerUserStats(
    influencerUserId: number
): Promise<GetTiktokInfluencerUserStatsResponse> {
    const response = await fetchWithToken(`/api/tiktok/viewsets/influencer-user-stats/${influencerUserId}/`);
    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not fetch influencer user stats for influencer user with id: ${influencerUserId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PostTiktokInfluencerUserResponse =
    | ApiResponse<TiktokInfluencerUser, 201>
    | ApiResponse<ApiResponseError<TiktokInfluencerUserApiBody>, 400>;

export async function postTiktokInfluencerUser(
    data: Partial<Omit<TiktokInfluencerUserApiBody, 'username'>> & Pick<TiktokInfluencerUserApiBody, 'username'>
): Promise<PostTiktokInfluencerUserResponse> {
    const response = await fetchWithToken(`/api/tiktok/viewsets/influencer-user/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not post tiktok influencer user');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type GetTiktokInfluencerUserPostAudioDataParams = {
    influencerUserId: number;
    limit?: number;
};

export async function getTiktokInfluencerUserPostAudioData(
    { influencerUserId, limit = 20 }: GetTiktokInfluencerUserPostAudioDataParams,
    requestInit?: RequestInit
): Promise<AudioData[]> {
    const response = await fetchWithToken(
        `/api/tiktok/influencer-user-post-audio-data/${influencerUserId}/${encodeUrlSearchParams({ limit })}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not fetch tiktok influencer user post audio data`);
    }

    return response.json();
}

export async function getAllTiktokArtistDailyReportItems(
    artistUserId: number,
    requestInit?: RequestInit
): Promise<TiktokDailyReportItem[]> {
    return (await fetchAll(`/api/tiktok/artist-daily-report-items/${artistUserId}/`, requestInit)) ?? [];
}

type GetTiktokVideoStatsParams = {
    videoIds: number[];
    latest?: boolean;
};
export async function getTiktokVideoStats(
    { videoIds, latest }: GetTiktokVideoStatsParams,
    requestInit?: RequestInit
): Promise<TiktokInfluencerPostResult[]> {
    return (
        (await fetchAll(
            `/api/tiktok/video-stats/${encodeUrlSearchParams({ video_ids: videoIds.toString(), latest })}`,
            requestInit
        )) ?? []
    );
}

type GetTiktokVideosParams = {
    videoIds: number[];
};

export async function getTiktokVideos(
    { videoIds }: GetTiktokVideosParams,
    requestInit?: RequestInit
): Promise<TiktokVideo[]> {
    return await fetchAll(
        `/api/tiktok/viewsets/video/${encodeUrlSearchParams({ id: videoIds.toString() })}`,
        requestInit
    );
}
