import { ReactNode } from 'react';
import { ContextState, TabsContext } from '../../Tabs';
import useNonNullContext from '../../../../hooks/useNonNullContext';
import cn from 'classnames';
import { getPanelId, getTabId } from '../../helpers';

export type TabProps = {
    children?: ReactNode;
    name: ContextState['activeTab'];
    className?: string;
    activeClassName?: string;
};

const Tab = ({ children, name, className, activeClassName = '' }: TabProps) => {
    const { activeTab, onChange } = useNonNullContext(TabsContext);
    const tabId = getTabId(name);
    const tabPanelId = getPanelId(name);
    const isSelected = activeTab === name;

    return (
        <button
            role="tab"
            aria-label={tabId}
            id={tabId}
            aria-selected={isSelected}
            aria-controls={tabPanelId}
            tabIndex={isSelected ? 0 : -1}
            onClick={() => onChange(name)}
            className={cn(className, { [activeClassName]: isSelected })}
        >
            {children}
        </button>
    );
};

export default Tab;
