import { combineReducers } from 'helpers';
import { reducer as creationsReducer, initialState as creationsInitialState } from './TiktokCreations/reducer';
import { reducer as topAudiosReducer, initialState as topAudiosInitialState } from './TiktokTopAudios/reducer';
import { reducer as indicatorsReducer, initialState as indicatorsInitialState } from './TiktokIndicators/reducer';
import { reducer as topPostsReducer, initialState as topPostsInitialState } from './TiktokTopPosts/reducer';

export const tiktokInitialState = {
    creations: creationsInitialState,
    topAudios: topAudiosInitialState,
    indicators: indicatorsInitialState,
    topPosts: topPostsInitialState,
};

export const tiktokReducer = combineReducers({
    creations: creationsReducer,
    topAudios: topAudiosReducer,
    indicators: indicatorsReducer,
    topPosts: topPostsReducer,
});
