import React, { useLayoutEffect, useState } from 'react';
import Popover, { Options } from '../../Popover/Popover';

type PopoverOnOverflowProps = {
    popoverContent: React.ReactNode;
    popoverOptions?: Options;
    isDisabled?: boolean;
    children: (setRef: (instance: HTMLElement | SVGSVGElement | null) => void) => React.ReactNode | undefined;
};

const PopoverOnOverflow = ({ popoverOptions, popoverContent, isDisabled, children }: PopoverOnOverflowProps) => {
    const [element, setElement] = useState<HTMLElement | SVGSVGElement | null>(null);
    const [contentOverflows, setContentOverflows] = useState(false);

    useLayoutEffect(() => {
        if (!element) {
            return;
        }

        const { scrollWidth, clientWidth } = element;
        setContentOverflows(scrollWidth > clientWidth);
    }, [element]);

    return (
        <>
            {children(setElement)}
            <Popover
                disabled={isDisabled || !contentOverflows}
                anchorElement={element}
                options={popoverOptions}
                showOn="hover"
            >
                {popoverContent}
            </Popover>
        </>
    );
};

export default PopoverOnOverflow;
