import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { User } from './users.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';

export const models = {
    creatorbaseUser: Model as ModelDefinition<User>,
};

export const serializers = {
    creatorbaseUser: makeSerializer<User>([]),
};

export const factories = {
    creatorbaseUser: createFactory<User>({
        name: (index: number) => `name ${index}`,
    }),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/creatorbase/users/';
    server.get(rootUrl, function (this: RouteHandlerContext, schema, request) {
        const ids = request.queryParams.id?.split(',').map(Number) || [];
        const users = schema
            .all('creatorbaseUser')
            .filter((user) => ids.includes(Number(user.id)) || !request.queryParams.id);

        return buildPaginatedResponse(users, {
            url: rootUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'creatorbaseBrand'),
        });
    });
}
