import React, { useMemo, useState } from 'react';
import {
    PostPaymentRequest,
    TiktokInfluencerPost,
    TiktokUser,
    InfluencerPlan,
    patchPostPaymentRequest,
    InstagramUser,
    InstagramUserImage,
    YouTubeInfluencerPost,
} from '@round/api';
import { Skeleton } from '@round/ui-kit';
import styles from './OutstandingPaymentsTable.module.css';
import { showNotification } from 'helpers';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { ReactComponent as PaypalIcon } from 'assets/PayPalShort.svg';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';
import Button from 'ui/Button/Button';
import { MAX_PAYPAL_API_PAYMENT_AMOUNT } from 'utility/constants';
import WrapperPaginationTable, {
    WrapperPaginationTableProps,
} from 'ui/WrapperTable/WrapperPaginationTable/WrapperPaginationTable';
import { CellContext, ColumnDef, RowSelectionState } from '@tanstack/react-table';
import TableMoneyInput from 'ui-new/TableComponents/TableMoneyInput/TableMoneyInput';
import TruncatedTextCell from 'SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import Campaign from 'Modules/Finance/Payments/components/Campaign/Campaign';
import Account from 'Modules/Finance/Payments/components/Account/Account';
import Checkbox from 'ui/DataEntry/Checkbox/Checkbox';
import { uniqBy } from 'lodash';
import CostLabel from 'ui/DataDisplay/Money/CostLabel/CostLabel';
import { InstagramInfluencerPost } from 'Modules/Advertising/InfluencerPlan/types/Instagram.types';
import { mapInstagramUserToAccountData, mapTiktokUserToAccountData } from '../../components/Account/helpers';

export type OutstandingPaymentsTableRow = Omit<
    PostPaymentRequest,
    'tiktok_influencer_post' | 'instagram_influencer_post' | 'youtube_influencer_post'
> & {
    plan: InfluencerPlan | undefined;
    tiktokUser: TiktokUser | undefined;
    instagramUser: InstagramUser | undefined;
    tiktok_influencer_post: TiktokInfluencerPost | undefined;
    instagram_influencer_post: InstagramInfluencerPost | undefined;
    youtubeInfluencerPost: YouTubeInfluencerPost | undefined;
    instagramUserImage: InstagramUserImage | undefined;
};

type TableCellContext<K extends keyof OutstandingPaymentsTableRow> = CellContext<
    OutstandingPaymentsTableRow,
    OutstandingPaymentsTableRow[K]
>;

type Props = {
    selectedRows: OutstandingPaymentsTableRow[];
    onSelectedRowsChange: (rows: OutstandingPaymentsTableRow[]) => void;
} & Pick<
    WrapperPaginationTableProps<OutstandingPaymentsTableRow>,
    'isLoading' | 'data' | 'noDataLabel' | 'page' | 'setPage' | 'pageSize' | 'setPageSize' | 'count'
> & {
        onClickPaypalPayment: (row: OutstandingPaymentsTableRow) => void;
        onClickManualPaypalPayment: (row: OutstandingPaymentsTableRow) => void;
        updatePostPaymentRequest: typeof patchPostPaymentRequest;
    };

const OutstandingPaymentsTable = ({
    onClickPaypalPayment,
    onClickManualPaypalPayment,
    updatePostPaymentRequest,
    selectedRows,
    onSelectedRowsChange,
    ...props
}: Props) => {
    const isUserFinance = useCheckUserGroupsAccess(['round_finance']);

    const { currencies } = useNonNullContext(OptionsContext);
    const columns: ColumnDef<OutstandingPaymentsTableRow, any>[] = useMemo(
        () => [
            {
                id: 'select',
                header: ({ table }) => {
                    return (
                        <div className={styles.checkboxContainer}>
                            {table.getRowModel().rows.length > 0 && (
                                <Checkbox
                                    ariaLabel="Select all"
                                    value={table.getIsAllPageRowsSelected()}
                                    indeterminateValue={table.getIsSomePageRowsSelected()}
                                    onChange={table.toggleAllPageRowsSelected}
                                />
                            )}
                        </div>
                    );
                },
                cell: ({ row }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.checkboxContainer}>
                            <Checkbox
                                ariaLabel="Select row"
                                value={row.getIsSelected()}
                                onChange={() => row.toggleSelected()}
                            />
                        </div>
                    );
                },
            },
            {
                id: 'account',
                header: 'Account',
                cell: ({ row: { original } }) => {
                    if (
                        !props.isLoading &&
                        !original.tiktokUser &&
                        !original.instagramUser &&
                        !original.youtubeInfluencerPost
                    ) {
                        return '-';
                    }

                    const accountData = original.tiktokUser
                        ? mapTiktokUserToAccountData(original.tiktokUser)
                        : original.instagramUser
                        ? mapInstagramUserToAccountData(original.instagramUser, original.instagramUserImage)
                        : original.youtubeInfluencerPost
                        ? //using channel name from post until we can fetch channels
                          {
                              imageUrl: '',
                              nickname: original.youtubeInfluencerPost.channel_title || '-',
                              username: '',
                              profileUrl: '',
                          }
                        : undefined;

                    return <Account isLoading={props.isLoading} user={accountData} />;
                },
            },
            {
                id: 'postUrl',
                header: 'Post',
                cell: ({ row: { original } }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const postUrl =
                        original.tiktok_influencer_post?.post_url ||
                        original.instagram_influencer_post?.post_url ||
                        original.youtubeInfluencerPost?.post_url;

                    return (
                        <a href={postUrl} target="_blank" rel="noreferrer" className={styles.postLink}>
                            Post link ↗
                        </a>
                    );
                },
            },
            {
                accessorKey: 'plan',
                header: 'Campaign',
                cell: ({ getValue }: TableCellContext<'plan'>) => (
                    <Campaign isLoading={props.isLoading} plan={getValue()} />
                ),
            },
            {
                id: 'postCost',
                header: 'Post Cost',
                cell: ({ row: { original } }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const post =
                        original.tiktok_influencer_post ||
                        original.instagram_influencer_post ||
                        original.youtubeInfluencerPost;

                    if (!post || typeof post.cost !== 'string') {
                        return '-';
                    }

                    return (
                        <CostLabel
                            cost={post.cost}
                            currencySymbol={currencies.find((curr) => curr.id === post.currency_id)?.symbol ?? ''}
                        />
                    );
                },
            },
            {
                accessorKey: 'amount',
                header: 'Request Cost',
                cell: function RequestCost({ getValue, row: { original } }: TableCellContext<'amount'>) {
                    const initialCost = Number(getValue());
                    const [isCostUpdateLoading, setIsCostUpdateLoading] = useState(false);
                    const [isEditing, setIsEditing] = useState(false);

                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    if (initialCost === null) {
                        return '-';
                    }

                    return (
                        <TableMoneyInput
                            key={`${original.currency}-${initialCost}`}
                            isReadonly={isCostUpdateLoading}
                            value={{
                                currency: original.currency,
                                amount: initialCost,
                            }}
                            onChange={async ({ amount, currency }) => {
                                if (!currency) {
                                    return;
                                }

                                setIsCostUpdateLoading(true);
                                try {
                                    const response = await updatePostPaymentRequest(original.id, {
                                        amount: amount.toString(),
                                        currency: currency,
                                    });

                                    if (response.status !== 200) {
                                        const errorMessage =
                                            response.status === 400
                                                ? Object.values(mapApiErrorsToFormikErrors(response.data)).toString()
                                                : response.data.detail;
                                        showNotification(errorMessage, 'error');
                                        return;
                                    }

                                    showNotification('Cost updated', 'info');
                                } catch {
                                    showNotification('Could not update cost', 'error');
                                } finally {
                                    setIsCostUpdateLoading(false);
                                }
                            }}
                            isEditing={isEditing}
                            onEditingChange={setIsEditing}
                        />
                    );
                },
            },
            {
                accessorKey: 'paypal_email_address',
                header: 'PayPal Email',
                cell: ({ getValue }: TableCellContext<'paypal_email_address'>) => {
                    const value = getValue();
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return value || '-';
                },
            },
            {
                accessorKey: 'payment_reference',
                header: 'Payment Reference',
                cell: ({ getValue }: TableCellContext<'payment_reference'>) => {
                    const value = getValue();
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return <TruncatedTextCell className={styles.reference}>{value || '-'}</TruncatedTextCell>;
                },
            },
            {
                header: 'Pay',
                id: 'pay',
                size: 280,
                cell: ({ row: { original } }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const isPaymentAboveThreshold =
                        !!original.amount && Number(original.amount) > MAX_PAYPAL_API_PAYMENT_AMOUNT;

                    return (
                        <div className={styles.paymentCell}>
                            <Button
                                appearance="outlined"
                                onClick={() => onClickPaypalPayment(original)}
                                disabled={isPaymentAboveThreshold}
                                rightIcon={<PaypalIcon />}
                            >
                                <span>Pay</span>
                            </Button>
                            <Button appearance="primary" onClick={() => onClickManualPaypalPayment(original)}>
                                <span>Pay Manually</span>
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [props.isLoading, currencies, updatePostPaymentRequest, onClickPaypalPayment, onClickManualPaypalPayment]
    );

    return (
        <WrapperPaginationTable
            className={styles.table}
            manualPagination
            columns={columns}
            columnVisibility={{ pay: Boolean(isUserFinance), select: Boolean(isUserFinance) }}
            columnPinning={{
                right: ['pay'],
            }}
            getRowId={(row, index) => (row.id ? row.id.toString() : `skeleton-${index}`)}
            enableRowSelection
            enableMultiRowSelection
            rowSelection={selectedRows.reduce<RowSelectionState>((acc, row) => {
                acc[row.id.toString()] = true;
                return acc;
            }, {})}
            onRowSelectionChange={(selection) => {
                const incomingRows = props.data.filter((row) => selection[row.id.toString()]);
                onSelectedRowsChange(
                    uniqBy(selectedRows.filter((row) => selection[row.id.toString()]).concat(incomingRows), 'id')
                );
            }}
            {...props}
        />
    );
};

export default OutstandingPaymentsTable;
