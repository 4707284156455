import React, { useMemo } from 'react';
import PaginationTable, { PaginationTableProps } from '../../../../../../../ui/PaginationTable/PaginationTable';
import { TiktokPostInviteData } from '../useTiktokPostInvites';
import { Column } from 'react-table';
import styles from './PostedTable.module.css';
import { Button, Skeleton } from '@round/ui-kit';
import TiktokUserBadge from '../../../../../../TikTok/components/TiktokUserBadge/TiktokUserBadge';
import { ReactComponent as LinkIcon } from 'assets/icons/Link.svg';
import { ReactComponent as TimerIcon } from 'assets/Timer.svg';

type Props = Omit<PaginationTableProps<TiktokPostInviteData>, 'columns' | 'loading'> & {
    isLoading: boolean;
    onEmailHistoryClicked: (row: TiktokPostInviteData) => void;
};

const formatDateTime = (datetime: string) =>
    new Date(datetime).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    });

const PostedTable = ({ onEmailHistoryClicked, ...props }: Props) => {
    const columns: Column<TiktokPostInviteData>[] = useMemo(
        () => [
            {
                Header: 'Account',
                accessor: 'user_id',
                disableSortBy: true,
                Cell: ({
                    row: {
                        original: { user, userImage },
                    },
                }) => <TiktokUserBadge isLoading={props.isLoading} user={user} image={userImage} />,
            },
            {
                Header: 'Post',
                accessor: 'post_create_time',
                Cell: ({ value: postCreateTime, row }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    if (!postCreateTime || !row.original.post_url) {
                        return <>-</>;
                    }

                    const postUrl = row.original.post_url;
                    const postCreateTimeDateString = formatDateTime(postCreateTime);

                    return (
                        <div className={styles.postStatus}>
                            <span>{postCreateTimeDateString}</span>
                            <a href={postUrl} target="_blank" rel="noreferrer">
                                <LinkIcon className={styles.linkIcon} />
                            </a>
                        </div>
                    );
                },
            },
            {
                Header: 'Audio',
                accessor: 'audio_id',
                disableSortBy: true,
                Cell: ({
                    row: {
                        original: { audio },
                    },
                }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return <>{audio?.title}</>;
                },
            },
            {
                Header: 'Last contacted at',
                accessor: 'last_contacted_at',
                Cell: ({ value, row: { original } }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.lastContactedAtContainer}>
                            {value ? formatDateTime(value) : '-'}
                            <Button className={styles.historyButton} onClick={() => onEmailHistoryClicked(original)}>
                                Email history
                                <TimerIcon className={styles.historyIcon} />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [onEmailHistoryClicked, props.isLoading]
    );

    return <PaginationTable columns={columns} {...props} loading={props.isLoading} />;
};

export default PostedTable;
