import React from 'react';
import styles from './TableValidation.module.css';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import TableRow from '../../../SharedComponents/TableRow/TableRow';

const TableValidation = () => {
    return (
        <div className={styles.page}>
            <NavBar />
            <div className={styles.content}>
                <h1>Table Validations</h1>
                <div>
                    <table className={styles.table}>
                        <thead>
                            <tr className={styles.columnHeaderRow}>
                                <th className={styles.cell}>Table Validation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRow linkTo="channel">Channel</TableRow>
                            <TableRow linkTo="optimisation-metric">Optimisation Metric</TableRow>
                            <TableRow linkTo="format">Format</TableRow>
                            <TableRow linkTo="objective">Objective</TableRow>
                            <TableRow linkTo="buy-platform">Buy Platform</TableRow>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TableValidation;
