import { ApiResponse, ContentTag, PaginatedApiResponseData } from '../../types';
import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';

type GetContentTagsParams = Partial<{ search: string }>;

export async function getContentTags(
    params: GetContentTagsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<ContentTag>, 200>> {
    const response = await fetchWithToken(
        `/api/advertising/content-tags/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not fetch content tags');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
