import React, { useCallback, useMemo, useState } from 'react';
import useUrlState from '../../../../Hooks/useUrlState';
import { InstagramInfluencerUser, usePaginatedFetch } from '@round/api';
import { getInstagramInfluencerPosts } from '../../../../Modules/Advertising/InfluencerPlan/api/Instagram.api';
import OutstandingInfluencerPostsTable, { OutstandingInfluencerPostRow } from './OutstandingInfluencerPostsTable';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import { InstagramInfluencerPost } from '../../../../Modules/Advertising/InfluencerPlan/types/Instagram.types';
import { InfluencerPlan } from '../../../../App.types';
import debounce from 'lodash/debounce';
import { isNumber } from '../../../../utility/utility';
import { uniq } from 'lodash';
import { withPrevent } from './helpers';
import { getInstagramInfluencerUsers } from '../../../../Modules/Instagram/Instagram.api';
import { getInfluencerPlans } from '../../../../Modules/Advertising/InfluencerPlan/InfluencerPlan.api';
import { asMoney, openInNewTab } from '../../../../helpers';
import useNonNullContext from '../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../contexts/OptionsContext/OptionsContext';
import Card from '../../../../ui/Card/Card';
import { NavBar } from '../../../../SharedComponents/NavBar/NavBar';
import styles from './OutstandingInfluencerPosts.module.css';
import SearchInput from '../../../../ui/SearchInput/SearchInput';

type UrlState = Partial<{
    page: number;
    page_size: number;
    search: string;
}>;

const OutstandingInstagramInfluencerPosts = () => {
    const { currencies } = useNonNullContext(OptionsContext);
    const [urlState, setUrlState] = useUrlState<UrlState>(
        {
            page: 1,
            page_size: 10,
            search: '',
        },
        {
            shouldSetMissingInitialValues: true,
        }
    );

    const [tableLoading, setTableLoading] = useState(false);
    const [influencerUsers, setInfluencerUsers] = useState<InstagramInfluencerUser[]>([]);
    const [influencerPlans, setInfluencerPlans] = useState<InfluencerPlan[]>([]);
    const { count, page: data, getCurrentPage } = usePaginatedFetch(getInstagramInfluencerPosts, {
        page: Number(urlState.page),
        page_size: Number(urlState.page_size),
        search: urlState.search ?? '',
        no_invoice: true,
    });

    const debouncedGetCurrentPage = useMemo(() => debounce(getCurrentPage, 700), [getCurrentPage]);
    const fetchPostsData = useCallback(async (posts: InstagramInfluencerPost[], requestInit?: RequestInit) => {
        try {
            const influencerUserIds = uniq(posts.map((post) => post.influencer_id).filter(isNumber));
            const influencerPlanIds = uniq(posts.map((post) => post.plan_id).filter(isNumber));
            const [influencerUsersResponse, influencerPlansResponse] = await Promise.all([
                withPrevent(() =>
                    getInstagramInfluencerUsers(
                        {
                            id: influencerUserIds.join(','),
                            page_size: influencerUserIds.length,
                        },
                        requestInit
                    )
                )(!influencerUserIds.length),
                withPrevent(() =>
                    getInfluencerPlans(
                        { id: influencerPlanIds.join(','), page_size: influencerPlanIds.length },
                        requestInit
                    )
                )(!influencerPlanIds.length),
            ]);

            if (influencerUsersResponse?.status === 200) {
                setInfluencerUsers(influencerUsersResponse.data.results);
            }

            if (influencerPlansResponse) {
                setInfluencerPlans(influencerPlansResponse.results);
            }
        } catch {
            // no-op
        } finally {
            setTableLoading(false);
        }
    }, []);

    useAbortableEffect(
        (signal) => {
            setTableLoading(true);
            debouncedGetCurrentPage({ signal })?.catch((e) => {
                if (e.name === 'AbortError') {
                    return;
                }

                setTableLoading(false);
            });
        },
        [debouncedGetCurrentPage]
    );

    useAbortableEffect(
        (signal) => {
            fetchPostsData(data, { signal });
        },
        [fetchPostsData, data]
    );

    const rows: OutstandingInfluencerPostRow[] = useMemo(
        () =>
            data.map((post) => {
                const plan = influencerPlans.find((plan) => plan.id === post.plan_id);
                const influencer = influencerUsers.find((influencer) => influencer.id === post.influencer_id);

                return {
                    id: post.id,
                    client: plan?.release.brand.client.name ?? '',
                    influencerName: `@${influencer?.username ?? '-'}`,
                    campaignName: `${plan?.release.brand.name} - ${plan?.release.name}`,
                    cost: asMoney(
                        Number(post.cost),
                        currencies.find((currency) => currency.id === post.currency_id)
                    ),
                    release_id: plan?.release.id,
                };
            }),
        [currencies, data, influencerPlans, influencerUsers]
    );

    return (
        <>
            <NavBar />
            <Card className={styles.container}>
                <h1 className={styles.title}>Outstanding Instagram Influencer Posts</h1>
                <SearchInput
                    className={styles.searchInput}
                    value={urlState.search ?? ''}
                    onChange={(search) => setUrlState({ search })}
                />
                <OutstandingInfluencerPostsTable
                    noDataLabel="No data found"
                    loading={tableLoading}
                    data={rows}
                    count={count}
                    page={Number(urlState.page)}
                    setPage={(page) => setUrlState({ page })}
                    pageSize={Number(urlState.page_size)}
                    setPageSize={(pageSize) => setUrlState({ page_size: pageSize ?? 10 })}
                    onRowClick={(row) =>
                        openInNewTab(`${window.location.origin}/campaigns/${row.release_id}/creators#instagram`)
                    }
                />
            </Card>
        </>
    );
};

export default OutstandingInstagramInfluencerPosts;
