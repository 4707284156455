import { AppServer } from '../mirage';
import * as invoiceMirage from './invoice/invoice.mirage';
import * as apexInvoiceMirage from './apexInvoice/apexInvoice.mirage';

export const models = {
    ...invoiceMirage.models,
    ...apexInvoiceMirage.models,
};

export const factories = {
    ...invoiceMirage.factories,
    ...apexInvoiceMirage.factories,
};

export const serializers = {
    ...invoiceMirage.serializers,
    ...apexInvoiceMirage.serializers,
};

export function handleXeroRequests(server: AppServer) {
    invoiceMirage.handleInvoiceRequests(server);
    apexInvoiceMirage.handleInvoiceRequests(server);
}
