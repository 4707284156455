import LibImageUpload, { FileUploadProps } from '../../FileUpload/ImageUpload/ImageUpload';
import cn from 'classnames';
import styles from './ImageUpload.module.css';
import { ReactComponent as CameraIcon } from 'assets/whitelabel/Camera.svg';

const ImageUpload = (props: FileUploadProps) => (
    <LibImageUpload
        {...props}
        className={cn(styles.container, props.className)}
        renderIcon={() => <CameraIcon className={styles.icon} />}
    />
);

export default ImageUpload;
