import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { Post } from './posts.types';

export const models = {
    microwavePost: Model as ModelDefinition<Post>,
};

export const serializers = {
    microwavePost: makeSerializer<Post>([]),
};

export const factories = {
    microwavePost: createFactory<Post>({
        campaign_id: (index: number) => index,
        cost: '10',
        currency_id: 1,
        influencer_id: (index: number) => index,
        instagram_post_id: null,
        tiktok_post_id: null,
        youtube_post_id: null,
    }),
};

export function handleRequests(server: AppServer) {
    server.get('/api/microwave/posts/', function (this: RouteHandlerContext, schema, request) {
        const posts = schema
            .all('microwavePost')
            .filter(
                (post) =>
                    !request.queryParams.campaign_id || post.campaign_id === Number(request.queryParams.campaign_id)
            );

        return buildPaginatedResponse(posts, {
            url: '/api/microwave/posts/',
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'microwavePost'),
        });
    });

    server.post('/api/microwave/posts/', (schema, request) => {
        const attrs = JSON.parse(request.requestBody);
        const post = schema.create('microwavePost', attrs);

        return post;
    });
}
