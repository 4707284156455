import React from 'react';
import { Targeting } from '../../../../../../App.types';
import Badge from '../../../components/Badge/Badge';
import styles from './TargetingBadges.module.css';
import cn from 'classnames';

type TargetingBadgesProps = {
    items: Targeting[];
    onClick?: (targeting: Targeting) => void;
    className?: string;
    disabled?: boolean;
};

const TargetingBadges = ({ items, className, onClick, disabled }: TargetingBadgesProps) => {
    return (
        <div className={cn(styles.badges, className)}>
            {items.map((t) => (
                <Badge disabled={disabled} key={t.id} label={t.name} onClick={onClick ? () => onClick(t) : undefined} />
            ))}
        </div>
    );
};

export default TargetingBadges;
