import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './FilterBadge.module.css';
import cn from 'classnames';

export type FilterBadgeProps = {
    children: React.ReactNode;
    renderLeft?: React.ReactNode;
    renderRight?: React.ReactNode;
    className?: string;
} & Pick<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onClick'>;

const FilterBadge = ({ children, renderLeft, renderRight, className, onClick }: FilterBadgeProps) => {
    return (
        <div className={cn(styles.container, className)} onClick={onClick}>
            {renderLeft}
            {children}
            {renderRight}
        </div>
    );
};

export default FilterBadge;
