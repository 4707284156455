import { getReleases, GetReleasesParams, Release } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<{
    releases: Release[];
    count: number;
}>;

const initialState: State = {
    status: 'idle',
    data: null,
    error: null,
};

export function useReleases() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (params: GetReleasesParams, requestInit?: RequestInit) => {
        try {
            setState((prev) => ({ status: 'loading', data: prev.data, error: null }));
            const response = await getReleases(params, requestInit);

            setState((prev) => ({
                status: 'success',
                data: {
                    releases: [...(prev.data?.releases ?? []), ...response.data.results],
                    count: response.data.count,
                },
                error: null,
            }));

            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                setState((prev) => ({ status: 'idle', data: prev.data, error: null }));
                throw e;
            }

            setState({
                status: 'error',
                error: e instanceof Error ? e.message : 'Could not load releases',
                data: null,
            });
            throw e;
        }
    }, []);

    const reset = () => setState(initialState);

    return {
        fetchData,
        reset,
        ...state,
    };
}
