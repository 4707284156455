import { forwardRef } from 'react';
import cn from 'classnames';
import styles from './TableInput.module.css';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

type Props = Omit<CurrencyInputProps, 'decimalsLimit' | 'decimalScale' | 'decimalSeparator' | 'onChange'>;

const TableCurrencyInput = forwardRef<HTMLInputElement, Props>(({ value, onValueChange, className, ...props }, ref) => {
    return (
        <CurrencyInput
            value={value}
            onValueChange={onValueChange}
            className={cn(styles.input, className)}
            decimalScale={2}
            decimalsLimit={2}
            decimalSeparator="."
            ref={ref}
            tabIndex={props.readOnly ? -1 : props.tabIndex || 0}
            {...props}
        />
    );
});

export default TableCurrencyInput;
