import { Targeting } from '../../../../App.types';
import { fetchAll, fetchWithToken } from '../../../../helpers';
import { TargetingTemplate, TargetingTemplateSimplified } from '../types/MediaPlanTargeting.types';

export async function getTargetings(mediaPlanItemIds: number[], requestInit?: RequestInit): Promise<Targeting[]> {
    if (!mediaPlanItemIds.length) {
        return [];
    }

    return (
        (await fetchAll(
            `/api/advertising/viewsets/media-plan-item-targeting/?media_plan_item_id=${mediaPlanItemIds.join()}`,
            requestInit
        )) ?? []
    );
}

type PostTargetingBody = Pick<Targeting, 'media_plan_item'> &
    Partial<Omit<Targeting, 'media_plan_item'>> & { template?: number };
export async function createTargeting(targeting: PostTargetingBody): Promise<Targeting> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item-targeting/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(targeting),
    });

    if (!response.ok) {
        throw new Error(`Could not create targeting for media plan item with id ${targeting.media_plan_item}`);
    }

    return response.json();
}

export async function updateTargeting(targeting: Targeting): Promise<Targeting> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item-targeting/${targeting.id}/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(targeting),
    });

    if (!response.ok) {
        throw new Error(`Could not update targeting ${targeting.id}`);
    }

    return response.json();
}

type PostTargetingTemplateBody = Pick<TargetingTemplate, 'client'> & Partial<Omit<TargetingTemplate, 'client'>>;
export async function createTargetingTemplate(template: PostTargetingTemplateBody) {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item-targeting-template/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(template),
    });

    if (!response.ok) {
        throw new Error(`Could not create generic targeting template for client with id ${template.client}`);
    }

    return response.json();
}

export async function getTargetingTemplates(clientId: number, simplified: true): Promise<TargetingTemplateSimplified[]>;
export async function getTargetingTemplates(clientId: number, simplified?: false): Promise<TargetingTemplate[]>;
export async function getTargetingTemplates(
    clientId: number,
    simplified: boolean = false
): Promise<(TargetingTemplateSimplified | TargetingTemplate)[]> {
    const simplifiedParam = simplified ? `&simplified=${simplified}` : '';
    return (
        (await fetchAll<TargetingTemplateSimplified>(
            `/api/advertising/viewsets/media-plan-item-targeting-template?client_id=${clientId}${simplifiedParam}`
        )) ?? []
    );
}

export async function deleteTargetingTemplate(id: number): Promise<void> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item-targeting-template/${id}/`, {
        method: 'DELETE',
    });

    if (!response.ok) {
        throw new Error(`Could not delete targeting template with id ${id}`);
    }
}

type PatchTargetingTemplateBody = Pick<TargetingTemplate, 'client' | 'id'> &
    Partial<Omit<TargetingTemplate, 'client' | 'id'>>;
export async function updateTargetingTemplate(template: PatchTargetingTemplateBody): Promise<TargetingTemplate> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/media-plan-item-targeting-template/${template.id}/`,
        {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(template),
        }
    );

    if (!response.ok) {
        throw new Error(`Could not update targeting template with id ${template.id}`);
    }

    return response.json();
}

export async function fetchDeleteFacebookTargeting(targetingId: number): Promise<boolean> {
    const response = await fetchWithToken(`/api/facebook/viewsets/facebook-campaign-targeting/${targetingId}/`, {
        method: 'DELETE',
    });
    return response.ok;
}

export async function fetchDeleteMediaPlanItemTargeting(targetingId: number): Promise<boolean> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item-targeting/${targetingId}/`, {
        method: 'DELETE',
    });
    return response.ok;
}
