import { influencer } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

export type ErrorType = influencer.GetPublicReportResponse | string;

export type State = DataState<influencer.PublicReport, ErrorType>;
export type Actions =
    | ReducerAction<'loadPublicReport'>
    | ReducerActionWithPayload<'publicReportInitialized', influencer.PublicReport>
    | ReducerActionWithPayload<'errorLoadingPublicReport', ErrorType>;

export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export const reducer = createReducer<State, Actions>({
    loadPublicReport: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    errorLoadingPublicReport: (state, { payload }) => ({
        ...state,
        status: 'error',
        error: payload,
    }),
    publicReportInitialized: (state, { payload }) => ({
        ...state,
        data: payload,
        error: null,
        status: 'success',
    }),
});
