import { Label } from 'ui-new/whitelabel/formik';
import Input from 'ui-new/whitelabel/Input/Input';
import styles from './SongFormTiktokAudios.module.css';
import Button from 'ui-new/whitelabel/Button/Button';
import TiktokAudiosTable, { TiktokAudiosTableRow } from './Table/TiktokAudiosTable';
import { useState } from 'react';
import { showNotification } from 'helpers';
import { SongFormValues } from '../SongForm';
import { useFormikContext } from 'formik';
import { useSongFormTiktokAudios } from './useSongFormTiktokAudios';
import { ReactComponent as PlusIcon } from 'assets/whitelabel/Plus.svg';
import useAbortableEffect from 'Hooks/useAbortableEffect';

type Props = {
    staticTiktokAudios?: number[];
};

const SongFormTiktokAudios = ({ staticTiktokAudios }: Props) => {
    const { values, initialValues, setFieldValue } = useFormikContext<SongFormValues>();

    const { data: audios, status, fetchAudios, addAudio, filterAudiosByIds } = useSongFormTiktokAudios();
    const isInitialized = status === 'success' || status === 'error';

    useAbortableEffect(
        (signal) => {
            const audiosToFetch = [...values.tiktok_audios, ...(staticTiktokAudios ?? [])];

            if (!isInitialized) {
                fetchAudios(audiosToFetch, { signal });
            }
        },
        [isInitialized, staticTiktokAudios, values.tiktok_audios, fetchAudios]
    );

    const [tiktokAudioUrl, setTiktokAudioUrl] = useState('');
    const [isAddAudioLoading, setIsAddAudioLoading] = useState(false);

    const handleAddAudio = async () => {
        if (!tiktokAudioUrl) {
            return;
        }

        try {
            setIsAddAudioLoading(true);
            const audio = await addAudio(tiktokAudioUrl);

            if ([...values.tiktok_audios, ...(staticTiktokAudios || [])].includes(audio.id)) {
                showNotification('Audio already added', 'error');
                return;
            }

            setTiktokAudioUrl('');
            setFieldValue('tiktok_audios', [audio.id, ...values.tiktok_audios]);
            showNotification('Audio added successfully', 'info');
        } catch (e) {
            const message = e instanceof Error ? e.message : `Couldn't add audio`;
            showNotification(message, 'error');
        } finally {
            setIsAddAudioLoading(false);
        }
    };

    const handleRemoveAudio = (audioId: number) => {
        setFieldValue(
            'tiktok_audios',
            values.tiktok_audios.filter((id) => id !== audioId)
        );
    };

    const handleResetChanges = () => {
        setFieldValue('tiktok_audios', initialValues.tiktok_audios);
        const initialAudioIds = [...initialValues.tiktok_audios, ...(staticTiktokAudios ?? [])];
        filterAudiosByIds(initialAudioIds);
    };

    const rows = (() => {
        let rows: TiktokAudiosTableRow[] = [];

        //if the song is changed to unreleased, we will hide the static audios
        if (values.status === 'released') {
            rows =
                staticTiktokAudios?.reduce<TiktokAudiosTableRow[]>((acc, id) => {
                    const audio = audios?.find((audio) => audio.id === id);
                    return audio ? acc.concat({ ...audio, isStatic: true }) : acc;
                }, []) ?? [];
        }

        return rows.concat(
            values.tiktok_audios
                .filter((id) => !staticTiktokAudios?.includes(id))
                .reduce<TiktokAudiosTableRow[]>((acc, id) => {
                    const audio = audios?.find((audio) => audio.id === id);
                    return audio ? acc.concat({ ...audio, isStatic: false }) : acc;
                }, []) ?? []
        );
    })();

    const audiosRemoved = initialValues.tiktok_audios.filter((id) => !values.tiktok_audios.includes(id));
    const audiosAdded = values.tiktok_audios.filter((id) => !initialValues.tiktok_audios.includes(id));

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <Label>TikTok Audio</Label>
                <div className={styles.audioInputRow}>
                    <Input
                        placeholder="Enter TikTok audio URL"
                        className={styles.audioInput}
                        value={tiktokAudioUrl}
                        onChange={(e) => setTiktokAudioUrl(e.target.value)}
                    />
                    <Button
                        appearance="primary"
                        onClick={handleAddAudio}
                        disabled={isAddAudioLoading || !isInitialized}
                        isLoading={isAddAudioLoading}
                        type="button"
                    >
                        <PlusIcon className={styles.plusIcon} />
                    </Button>
                </div>
            </div>

            <TiktokAudiosTable data={rows} isLoading={!isInitialized} onRemoveAudio={handleRemoveAudio} />

            {(!!audiosAdded.length || !!audiosRemoved.length) && (
                <div className={styles.changedLabelContainer}>
                    <div>
                        {!!audiosAdded.length && (
                            <span>
                                <strong>{audiosAdded.length}</strong> TikTok audios added
                            </span>
                        )}

                        {!!audiosAdded.length && !!audiosRemoved.length && ' • '}

                        {!!audiosRemoved.length && (
                            <span>
                                <strong>{audiosRemoved.length}</strong> {audiosAdded.length ? ' ' : 'TikTok audios '}
                                removed
                            </span>
                        )}
                    </div>

                    <Button appearance="ghost" className={styles.undoButton} onClick={handleResetChanges}>
                        Reset changes?
                    </Button>
                </div>
            )}
        </div>
    );
};

export default SongFormTiktokAudios;
