import {
    getInstagramUserImages,
    getTiktokUserImages,
    InstagramUserImage,
    microwave,
    TiktokUserImage,
    youtube,
} from '@round/api';
import { isNumber } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import { MicrowaveInfluencersTableRow } from './MicrowaveInfluencersTable/MicrowaveInfluencersTable';

export default function useAdminMicrowaveInfluencers({ page, page_size, search }: microwave.GetAdminInfluencersParams) {
    const [influencers, setInfluencers] = useState<microwave.AdminInfluencer[]>([]);
    const [count, setCount] = useState(0);
    const [error, setError] = useState<string | null>(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [tiktokImages, setTiktokImages] = useState<TiktokUserImage[]>([]);
    const [instagramImages, setInstagramImages] = useState<InstagramUserImage[]>([]);
    const [youtubeChannels, setYoutubeChannels] = useState<youtube.Channel[]>([]);

    const fetchCurrentPage = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                setError(null);
                const response = await microwave.getAdminInfluencers({ page, page_size, search }, requestInit);
                setCount(response.data.count);
                setInfluencers(response.data.results);
                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setError("Sorry, we're experiencing technical issues");
            }
        },
        [page, page_size, search]
    );

    useAbortableEffect(
        (signal) => {
            const tiktokUserIds = influencers.map((inf) => inf.tiktok_user_id).filter(isNumber);
            const tiktokImagesToFetch = tiktokUserIds.filter(
                (userId) => !tiktokImages.find((image) => image.user_id === userId)
            );
            const instagramUserIds = influencers.map((inf) => inf.instagram_user_id).filter(isNumber);
            const instagramImagesToFetch = instagramUserIds.filter(
                (userId) => !instagramImages.find((image) => image.user_id === userId)
            );

            const youtubeChannelIds = influencers.map((inf) => inf.youtube_channel_id).filter(isNumber);
            const youtubeChannelsToFetch = youtubeChannelIds.filter(
                (channelId) => !youtubeChannels.find((channel) => channel.id === channelId)
            );

            Promise.allSettled([
                () =>
                    tiktokImagesToFetch.length
                        ? getTiktokUserImages(tiktokImagesToFetch, { signal }).then((images) =>
                              setTiktokImages((prev) => prev.concat(images))
                          )
                        : Promise.resolve(),
                instagramImagesToFetch.length
                    ? getInstagramUserImages(instagramImagesToFetch, { signal }).then((res) =>
                          setInstagramImages((prev) => prev.concat(res.data))
                      )
                    : Promise.resolve(),
                youtubeChannelsToFetch.length
                    ? youtube
                          .getChannels(
                              { id: youtubeChannelsToFetch.join(','), page_size: youtubeChannelsToFetch.length },
                              { signal }
                          )
                          .then((res) =>
                              res.status === 200
                                  ? setYoutubeChannels((prev) => prev.concat(res.data.results))
                                  : Promise.resolve()
                          )
                    : Promise.resolve(),
            ]);
        },
        [tiktokImages, instagramImages, influencers, youtubeChannels]
    );

    return {
        init: fetchCurrentPage,
        reset: () => {
            setInfluencers([]);
            setCount(0);
            setError(null);
            setIsInitialized(false);
        },
        rows: useMemo(
            () =>
                influencers.map<MicrowaveInfluencersTableRow>((inf) => ({
                    ...inf,
                    tiktokImage: tiktokImages.find((image) => image.user_id === inf.tiktok_user_id),
                    instagramImage: instagramImages.find((image) => image.user_id === inf.instagram_user_id),
                    youtubeChannel: youtubeChannels.find((channel) => channel.id === inf.youtube_channel_id),
                })),
            [tiktokImages, instagramImages, influencers, youtubeChannels]
        ),
        influencers,
        count,
        error,
        isInitialized,
    };
}
