import React from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import Select, { StylesConfig, ValueType } from 'react-select';
import { fetchFacebookApi, isFacebookError } from '../../../../../../../../helpers';
import { mapLocationSearchResultToOption } from './helpers';
import {
    FacebookErrorResponse,
    FacebookTargetingLocationSearchOption,
    GenericDropdownOption,
    LocationSearchResponse,
} from '../../../../../../../../App.types';

import FacebookTargetingLocationsList from './FacebookTargetingLocationsList';
import FacebookTargetingLocationOption from './FacebookTargetingLocationOption';

import styles from './FacebookTargetingLocations.module.css';

const locationSelectStyleConfig: StylesConfig = {
    control: (provided) => ({
        ...provided,
        borderColor: '#C2CFE0',
        fontSize: '0.75rem',
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingTop: '0.3rem',
        paddingBottom: '0.3rem',
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    input: (provided) => ({
        ...provided,
        marginTop: '0',
        paddingTop: '0',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#90A0B7',
        fontSize: '0.75rem',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 1000,
    }),
};

const shouldIncludeSelectStyleConfig: StylesConfig = {
    control: (provided) => ({
        ...provided,
        borderColor: '#C2CFE0',
        fontSize: '0.75rem',
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingTop: '0.3rem',
        paddingBottom: '0.3rem',
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    input: (provided) => ({
        ...provided,
        marginTop: '0',
        paddingTop: '0',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#90A0B7',
        fontSize: '0.75rem',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 1000,
    }),
};

type State = {
    query: string;
    locationOptions: FacebookTargetingLocationSearchOption[];
    searchLoading: boolean;
    facebookError: FacebookErrorResponse | null;
    includeOptions: GenericDropdownOption<boolean>[];
    shouldIncludeSelectValue: GenericDropdownOption<boolean>;
};

type Props = {
    locations: FacebookTargetingLocationSearchOption[];
    onChange: (locations: FacebookTargetingLocationSearchOption[]) => void;
    error?: string;
};

// Using class component because it's easier to debounce methods than hooks on functional
export default class FacebookTargetingLocationsInput extends React.Component<Props, State> {
    abortController: AbortController = new AbortController();

    constructor(props: Props) {
        super(props);
        this.state = {
            query: '',
            locationOptions: [],
            searchLoading: false,
            facebookError: null,
            includeOptions: [
                { label: 'Include', value: true },
                { label: 'Exclude', value: false },
            ],
            shouldIncludeSelectValue: {
                label: 'Include',
                value: true,
            },
        };

        this.onSelectInputChange = this.onSelectInputChange.bind(this);
        this.searchLocations = debounce(this.searchLocations.bind(this), 500, { leading: true });
        this.displayNoOptionsMessage = this.displayNoOptionsMessage.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onShouldIncludeSelect = this.onShouldIncludeSelect.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (!this.state.query && this.state.locationOptions.length) {
            this.setState({ locationOptions: [] });
        }

        if (this.state.query && this.state.query !== prevState.query) {
            this.setState({ searchLoading: true });
            this.searchLocations();
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    onSelectInputChange(query: string) {
        this.setState({ query });
    }

    searchLocations() {
        fetchFacebookApi<LocationSearchResponse>(
            '/search',
            { type: 'adgeolocation', q: this.state.query },
            { signal: this.abortController.signal }
        )
            .then((locationsResponse) => {
                if (isFacebookError(locationsResponse)) {
                    this.setState({ facebookError: locationsResponse, searchLoading: false });
                    return;
                }

                const locationOptions = locationsResponse.data
                    .map(mapLocationSearchResultToOption)
                    .filter(
                        (location) =>
                            !this.props.locations.find(
                                (selected) => selected.value === location.value && selected.type === location.type
                            )
                    );

                this.setState({ locationOptions, searchLoading: false });
            })
            .catch(() => {});
    }

    displayNoOptionsMessage() {
        return this.state.facebookError ? 'Sorry, we are experiencing some technical issues' : 'No Options';
    }

    onSelect(value: ValueType<FacebookTargetingLocationSearchOption, false>) {
        if (value) {
            this.props.onChange([
                ...this.props.locations,
                { ...value, isIncluded: this.state.shouldIncludeSelectValue.value },
            ]);
        }
    }

    onShouldIncludeSelect(value: ValueType<GenericDropdownOption<boolean>, false>) {
        if (value) {
            this.setState({ shouldIncludeSelectValue: value });
        }
    }

    onDelete(toDelete: FacebookTargetingLocationSearchOption) {
        this.props.onChange(
            this.props.locations.filter(
                (location) => location.value !== toDelete.value || location.type !== toDelete.type
            )
        );
    }

    onUpdate(updated: FacebookTargetingLocationSearchOption) {
        const indexOfUpdate = this.props.locations.findIndex(
            (l) => l.value === updated.value && l.type === updated.type
        );
        const updatedLocations = [...this.props.locations];
        updatedLocations.splice(indexOfUpdate, 1, updated);
        this.props.onChange(updatedLocations);
    }

    render() {
        return (
            <>
                <div className={classNames([styles.row, styles.searchContainer])}>
                    <Select
                        className={classNames([styles.select, styles.includeLocationSelect])}
                        styles={shouldIncludeSelectStyleConfig}
                        options={this.state.includeOptions}
                        value={this.state.shouldIncludeSelectValue}
                        onChange={this.onShouldIncludeSelect}
                        components={{ IndicatorSeparator: null }}
                        isSearchable={false}
                        menuPortalTarget={document.body}
                    />

                    <div className={styles.search}>
                        <Select
                            value={null}
                            className={classNames([styles.select])}
                            styles={locationSelectStyleConfig}
                            isLoading={this.state.searchLoading}
                            inputValue={this.state.query}
                            onInputChange={this.onSelectInputChange}
                            options={this.state.locationOptions}
                            components={{ Option: FacebookTargetingLocationOption }}
                            noOptionsMessage={this.displayNoOptionsMessage}
                            onChange={this.onSelect}
                            menuPortalTarget={document.body}
                        />
                        {this.props.error && <span className={styles.errorMessage}>{this.props.error}</span>}
                    </div>
                </div>
                {this.props.locations.length > 0 && (
                    <FacebookTargetingLocationsList
                        locations={this.props.locations}
                        onDelete={this.onDelete}
                        onUpdate={this.onUpdate}
                    />
                )}
            </>
        );
    }
}
