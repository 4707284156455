import { DataState, ReducerActionWithPayload } from '../../../../../App.types';
import { creatorbase } from '@round/api';
import { BaseDataHookContextValue } from '../../../../../utility/dataHook';
import { Context } from '@fluentui/react-context-selector';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { createReducer } from 'helpers';
import { makeErrorStateSlice, makeLoadingStateSlice, makeSuccessStateSlice } from '../../../../../utility/dataState';

type State = {
    [userId: number]: DataState<creatorbase.User | null> | undefined;
};

export const initialState = {};

type Actions =
    | ReducerActionWithPayload<'loadUsers', number[]>
    | ReducerActionWithPayload<'errorLoadingUsers', { userIds: number[]; error: string }>
    | ReducerActionWithPayload<'successLoadingUsers', { userIds: number[]; users: creatorbase.User[] }>;

export const reducer = createReducer<State, Actions>({
    loadUsers: (state, { payload: userIds }) => ({
        ...state,
        ...makeLoadingStateSlice(userIds),
    }),
    errorLoadingUsers: (state, { payload: { userIds, error } }) => ({
        ...state,
        ...makeErrorStateSlice(userIds, error),
    }),
    successLoadingUsers: (state, { payload: { users, userIds } }) => ({
        ...state,
        ...makeSuccessStateSlice(userIds, (userId) => users.find((u) => u.id === userId) ?? null),
    }),
});

export const makeUsersDataHook = <TValue extends BaseDataHookContextValue<Actions>>(
    context: Context<TValue>,
    selector: ContextSelector<TValue, State>
) => {
    return () => {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (userIds: number[], requestInit?: RequestInit) => {
                if (!userIds.length) {
                    dispatch({ type: 'successLoadingUsers', payload: { userIds: [], users: [] } });
                    return;
                }

                try {
                    dispatch({ type: 'loadUsers', payload: userIds });
                    const response = await creatorbase.getUsers(
                        { id: userIds.toString(), page_size: userIds.length },
                        requestInit
                    );

                    if (response.status === 200) {
                        dispatch({ type: 'successLoadingUsers', payload: { userIds, users: response.data.results } });
                        return response;
                    }

                    dispatch({ type: 'errorLoadingUsers', payload: { userIds, error: response.data.detail } });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({ type: 'errorLoadingUsers', payload: { userIds, error: 'Could not load users' } });
                    throw e;
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
};
