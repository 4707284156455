import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import '@round/ui-kit/dist/esm/index.css';
import '@round/auth/dist/esm/index.css';
import './chartjs-setup';
import 'flag-icons/css/flag-icons.min.css';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import packageJson from '../package.json';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { FeatureFlagsProvider } from 'utility/featureFlags/FeatureFlagsContext';

const datadog_token = process.env.REACT_APP_DATADOG_TOKEN || '';
if (datadog_token) {
    datadogLogs.init({
        clientToken: datadog_token,
        site: process.env.REACT_APP_DATADOG_SITE_NAME || '',
        forwardErrorsToLogs: true,
        sampleRate: 100,
    });
}

if (process.env.NODE_ENV === 'production') {
    datadogRum.init({
        applicationId: process.env.REACT_APP_DD_ROUND_FRONTEND_APPLICATION_ID || '',
        clientToken: process.env.REACT_APP_DD_ROUND_FRONTEND_CLIENT_TOKEN || '',
        site: process.env.REACT_APP_DD_ROUND_FRONTEND_SITE || '',
        service: process.env.REACT_APP_DD_ROUND_FRONTEND_SERVICE || '',
        env: process.env.REACT_APP_DD_ROUND_FRONTEND_ENV || '',
        version: packageJson.version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
}

const root = createRoot(document.getElementById('root')!);

root.render(
    <FeatureFlagsProvider>
        <App />
    </FeatureFlagsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
