import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TimeSeries, TimeSeriesField } from './timeSeries.types';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer } from '../../mirage';
import { TimeSeriesRequestParams, TimeSeriesResponse } from './timeSeries.api';

export const models = {
    creatorbaseTimeSeries: Model as ModelDefinition<TimeSeries>,
};

export const serializer = {
    creatorbaseTimeSeries: makeSerializer<TimeSeries>([]),
};

export const factories = {
    creatorbaseTimeSeries: createFactory<TimeSeries>({
        timestamp: new Date().toISOString(),
        value: 0,
    }),
};

export function handleRequests(server: AppServer) {
    server.post('/api/creatorbase/time-series/', (schema, request) => {
        const params = JSON.parse(request.requestBody) as TimeSeriesRequestParams;

        const response = params.ids.reduce((acc, id) => {
            acc[id] = params.fields.reduce((acc, field) => {
                acc[field as TimeSeriesField] = [{ timestamp: new Date().toISOString(), value: 0 }];
                return acc;
            }, {} as TimeSeriesResponse[0]);

            return acc;
        }, {} as TimeSeriesResponse);

        return new Response(200, {}, JSON.stringify(response));
    });
}
