import React, { useMemo } from 'react';
import styles from './MobileAllCampaigns.module.css';
import useUrlState from '../../../../../Hooks/useUrlState';
import { SearchBox } from '../../../../../SharedComponents/SearchBox/SearchBox';
import SearchIcon from '../../../../../SharedComponents/svg/Icons/SearchIcon';
import ActiveCampaignsIcon from '../../components/icons/ActiveCampaignsIcon';
import { useCampaigns } from '../../hooks/useCampaigns';
import { Column } from 'react-table';
import { Project } from '../../Projects.types';
import TitleCell from '../../../../../SharedComponents/TableComponents/TitleCell/TitleCell';
import ProjectsTable from '../../components/ProjectsTable/ProjectsTable';

type UrlState = {
    page: number;
    pageSize: number;
    search?: string;
};

const initialUrlState: UrlState = {
    page: 1,
    pageSize: 10,
};

const MobileAllCampaigns = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState, { shouldSetMissingInitialValues: true });

    const page = Number(urlState.page) || initialUrlState.page;
    const pageSize = Number(urlState.pageSize) || initialUrlState.pageSize;
    const search = urlState.search ?? '';

    const setPage = (page: number) => setUrlState({ page });
    const setPageSize = (size: number | undefined) => setUrlState({ pageSize: size ?? initialUrlState.pageSize });
    const setSearch = (search: string) => setUrlState({ search, page: 1 });
    const {
        campaigns,
        campaignsCount,
        campaignsLoading,
        errorLoadingCampaigns,
        numberOfActiveCampaigns,
    } = useCampaigns({
        page: page,
        page_size: pageSize,
        search,
    });

    const noDataLabel = errorLoadingCampaigns ? 'Sorry, we are experiencing technical issues' : 'No campaigns found';

    const columns = useMemo<Column<Project>[]>(
        () => [
            {
                Header: 'Brand',
                accessor: 'brand_name',
                Cell: (props) => (
                    <div className={styles.brandContainer}>
                        <TitleCell
                            loading={campaignsLoading}
                            title={props.value}
                            subtitle={props.row.original.client_name}
                        />
                    </div>
                ),
            },
            {
                Header: 'Project',
                accessor: 'release_name',
                Cell: (props) => (
                    <TitleCell
                        loading={campaignsLoading}
                        title={props.value}
                        subtitle={props.row.original.release_type}
                    />
                ),
            },
        ],
        [campaignsLoading]
    );

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1 className={styles.title}>All Campaigns</h1>
                {typeof numberOfActiveCampaigns === 'number' && (
                    <div className={styles.activeCampaigns}>
                        <span className={styles.activeCampaignsBadge}>
                            <ActiveCampaignsIcon className={styles.activeCampaignsBadgeIcon} width={16} height={16} />
                            {numberOfActiveCampaigns} active
                        </span>
                    </div>
                )}
            </div>

            <div className={styles.tableContainer}>
                <div className={styles.searchContainer}>
                    <SearchIcon fill="#90A0B7" width={16} height={16} />
                    <SearchBox className={styles.search} initialValue={search} setValue={setSearch} />
                </div>
                <ProjectsTable
                    loading={campaignsLoading}
                    projects={campaigns}
                    columns={columns}
                    noDataLabel={noDataLabel}
                    page={page}
                    setPage={setPage}
                    count={campaignsCount}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </div>
        </div>
    );
};

export default MobileAllCampaigns;
