import { creatorbase } from '@round/api';
import { CellContext } from '@tanstack/react-table';
import cn from 'classnames';
import styles from './ExpandOrDragCell.module.css';
import IconButton from 'ui-new/Buttons/IconButton/IconButton';
import { ReactComponent as ChevronRightIcon } from 'assets/whitelabel/ChevronRight.svg';
import { ReactComponent as DragIcon } from 'assets/whitelabel/Drag.svg';
import { ReactComponent as DragDotsIcon } from 'assets/whitelabel/DragDots.svg';
import useNonNullContext from 'Hooks/useNonNullContext';
import { DraggableContext } from 'ui-new/whitelabel/Table/DragAndDropTable/DragAndDropTable';

type Row = Pick<creatorbase.Campaign, 'id'>;
type Context<TRow extends Row> = CellContext<TRow, TRow['id']>;

const ExpandOrDragCell = <TRow extends Row>({ row, table }: Context<TRow>) => {
    const { provided, snapshot, isDragActive } = useNonNullContext(DraggableContext);

    return (
        <div className={styles.container}>
            {table.getRowModel().rows.length > 1 && (
                <div {...provided.dragHandleProps} className={styles.mobileDragHandle}>
                    <DragDotsIcon />
                </div>
            )}

            <IconButton
                onClick={(e) => {
                    //since the drag handle is a child of the button, we should ignore clicks on it
                    if (e.target instanceof Element && e.target.hasAttribute('data-drag-handle')) {
                        return;
                    }

                    row.toggleExpanded();
                }}
                className={styles.expandButton}
            >
                <ChevronRightIcon className={cn({ [styles.expandIconExpanded]: row.getIsExpanded() })} />
                {table.getRowModel().rows.length > 1 && (
                    <div
                        className={cn(styles.dragHandle, {
                            [styles.isDragging]: snapshot.isDragging,
                            [styles.isOtherDragging]: isDragActive && !snapshot.isDragging,
                        })}
                        {...provided.dragHandleProps}
                        onMouseDown={(e) => {
                            //return on right click
                            if (e.button === 2) {
                                return;
                            }
                            //close the row before dragging
                            row.toggleExpanded(false);
                        }}
                        data-drag-handle
                    >
                        <DragIcon className={styles.dragIcon} data-drag-handle />
                    </div>
                )}
            </IconButton>
        </div>
    );
};

export default ExpandOrDragCell;
