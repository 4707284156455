import React, { FunctionComponent } from 'react';
import styles from './UserRow.module.css';
import { MonitoredUserStats } from '../../../App.types';
import { buildTiktokUserUrl, numberWithCommas, roundTo2Dp } from '../../../helpers';
import { TiktokUserImage } from '@round/api';

const TiktokUserRow = ({
    data,
    onClick,
    profileImage,
}: {
    data: MonitoredUserStats;
    onClick: (userId: number) => void;
    profileImage: TiktokUserImage | undefined;
}) => {
    function handleChildClick(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation();
    }
    return (
        <>
            <tr onClick={() => onClick(data.user_id)} className={styles.row}>
                {profileImage && (
                    <td className={styles.imageCell}>
                        <div className={styles.imageContainer}>
                            <img
                                src={profileImage?.avatar_thumb.cached_url}
                                alt="User"
                                className={styles.image}
                                crossOrigin={'anonymous'}
                            />
                        </div>
                    </td>
                )}
                <td className={`${styles.artistName} ${styles.nameCell}`}>{data.name}</td>
                <td className={styles.nameCell}>
                    <a
                        onClick={handleChildClick}
                        href={buildTiktokUserUrl(data.username)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        @{data.username}
                    </a>
                </td>
                <td>{numberWithCommas(data.follower_count)}</td>
                <StatsDeltaCell
                    absolute={data.follower_count_daily_change_abs}
                    relative={data.follower_count_daily_change_rel}
                />
                <StatsDeltaCell
                    absolute={data.follower_count_weekly_change_abs}
                    relative={data.follower_count_weekly_change_rel}
                />
            </tr>
        </>
    );
};

const StatsDeltaCell: FunctionComponent<{ absolute: number | null; relative: number | null }> = ({
    absolute,
    relative,
}) => {
    return absolute === null || relative === null ? (
        <td>-</td>
    ) : (
        <td>
            {numberWithCommas(absolute)}
            <span
                className={`${styles.relativeStatsChange} ${relative > 0 && styles.green} ${
                    relative < 0 && styles.red
                }`}
            >
                {roundTo2Dp(relative * 100)}%
            </span>
        </td>
    );
};

export default TiktokUserRow;
