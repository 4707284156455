import React, { useCallback, useState } from 'react';
import { Creative } from '../../../../../../App.types';
import { Formik, FormikHelpers } from 'formik';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import CreativeForm from '../CreativeForm/CreativeForm';
import Button from '../../../../../../ui/Buttons/Button/Button';
import { deleteCreative, patchCreative } from '../../../api/MediaPlanCreatives.api';
import { showNotification } from '../../../../../../helpers';
import styles from './EditCreativeModal.module.css';

type EditCreativeModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    creative: Creative;
    onUpdated: (creative: Creative) => void;
    onDeleted: (creative: Creative) => void;
};

const EditCreativeModal = ({ isModalOpen, closeModal, onUpdated, onDeleted, creative }: EditCreativeModalProps) => {
    const [showConfirmDeletePrompt, setShowConfirmDeletePrompt] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const handleSubmit = useCallback(
        async (values: Creative, { setSubmitting }: FormikHelpers<Creative>) => {
            try {
                setSubmitting(true);
                const creative = await patchCreative(values.id, values);
                showNotification('Asset updated', 'info');
                onUpdated(creative);
                closeModal();
            } catch {
                setHasError(true);
            } finally {
                setSubmitting(false);
            }
        },
        [closeModal, onUpdated]
    );

    const handleDelete = useCallback(async () => {
        try {
            setDeleteLoading(true);
            await deleteCreative(creative.id);
            onDeleted(creative);
            closeModal();
            showNotification('Asset deleted', 'info');
        } catch {
            showNotification('Could not delete asset', 'error');
        } finally {
            setShowConfirmDeletePrompt(true);
            setDeleteLoading(false);
        }
    }, [closeModal, creative, onDeleted]);

    return (
        <>
            <Modal
                className={styles.deleteAsset}
                closeOnOverlayClick
                isOpen={showConfirmDeletePrompt}
                onClose={() => setShowConfirmDeletePrompt(false)}
            >
                <ModalTitle>Delete "{creative.name}" asset?</ModalTitle>
                <ModalContent>
                    <p>You're going to delete "{creative.name}" asset. This operation cannot be undone.</p>
                </ModalContent>
                <ModalFooter align="center">
                    <Button type="bordered" color="negative" loading={deleteLoading} onClick={handleDelete}>
                        Delete asset
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={closeModal}>
                <ModalTitle>Edit Asset</ModalTitle>

                <Formik initialValues={creative} onSubmit={handleSubmit}>
                    {({ isSubmitting, submitForm }) => (
                        <>
                            <ModalContent>
                                {hasError && <div>Error creating assets</div>}
                                <CreativeForm />
                            </ModalContent>
                            <ModalFooter>
                                <Button
                                    className={styles.submit}
                                    type="filled"
                                    color="black"
                                    loading={isSubmitting}
                                    onClick={() => submitForm()}
                                >
                                    Save asset
                                </Button>

                                <Button type="filled" color="negative" onClick={() => setShowConfirmDeletePrompt(true)}>
                                    Delete
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default EditCreativeModal;
