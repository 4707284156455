import Select from '../../../ui/DataEntry/Select/Select';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import Card from '../../../ui/Card/Card';
import styles from './DataDownload.module.css';
import InfluencerPostReportDownload from './InfluencerPostReportDownload/InfluencerPostReportDownload';
import { GenericDropdownOption } from '../../../App.types';
import { useState } from 'react';
import { StylesConfig } from 'react-select';

type DownloadOptions = 'influencer_post_report';

const downloadOptions: GenericDropdownOption<DownloadOptions>[] = [
    { value: 'influencer_post_report', label: 'Influencer Post Report' },
];

type DownloadOptionsMap = {
    [key: string]: JSX.Element;
};

const downloadOptionsMap: DownloadOptionsMap = {
    influencer_post_report: <InfluencerPostReportDownload />,
};

const selectStyles: StylesConfig = {
    control: (provided) => ({
        ...provided,
        fontWeight: 500,
        width: '40%',
        minWidth: '15rem',
    }),
};

const DataDownload = () => {
    const [selectedOption, setSelectedOption] = useState<DownloadOptions | null>('influencer_post_report');

    return (
        <div className={styles.page}>
            <NavBar />
            <Card className={styles.container}>
                <h1 className={styles.title}>Data Download</h1>
                <Select
                    options={downloadOptions}
                    value={downloadOptions.find((option) => option.value === selectedOption)}
                    onChange={(option) => setSelectedOption(option?.value ?? null)}
                    styles={selectStyles}
                    placeholder="Select download type..."
                    menuPortalTarget={document.body}
                />

                {selectedOption && downloadOptionsMap[selectedOption]}
            </Card>
        </div>
    );
};

export default DataDownload;
