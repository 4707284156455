import React, { PropsWithChildren } from 'react';
import { default as SkeletonComponent, SkeletonProps } from 'react-loading-skeleton';

const Skeleton = ({ containerTestId, ...props }: SkeletonProps) => (
    <SkeletonComponent
        wrapper={({ children }: PropsWithChildren) => <span data-test-id="skeleton">{children}</span>}
        {...props}
    />
);

export default Skeleton;
