import React, { FunctionComponent, useLayoutEffect, useRef } from 'react';

import { FacebookTargetingLocationSearchOption } from '../../../../../../../../App.types';
import styles from './FacebookTargetingLocations.module.css';

import FacebookLocationListItem from './FacebookLocationListItem';
import useScrolled from '../../../../../../../../Hooks/useScrolled';

type Props = {
    locations: FacebookTargetingLocationSearchOption[];
    onDelete: (value: FacebookTargetingLocationSearchOption) => void;
    onUpdate: (value: FacebookTargetingLocationSearchOption) => void;
};

const FacebookTargetingLocationsList: FunctionComponent<Props> = ({ locations, onDelete, onUpdate }) => {
    const listElementRef = useRef<HTMLDivElement>(null);
    const scrolled = useScrolled(listElementRef);

    useLayoutEffect(() => {
        if (listElementRef.current && !scrolled) {
            // scroll down list on length change
            listElementRef.current.scrollTop = listElementRef.current.scrollHeight;
        }
    }, [locations.length, scrolled]);

    return (
        <div className={styles.locationsList} ref={listElementRef}>
            {locations.map((location) => (
                <FacebookLocationListItem
                    key={location.value}
                    location={location}
                    onDelete={onDelete}
                    onUpdate={onUpdate}
                />
            ))}
        </div>
    );
};

export default FacebookTargetingLocationsList;
