import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { creatorbase, influencer } from '@round/api';
import { PublicReportContext } from '../../../PublicReportContext';
import { isNumber } from 'utility/utility';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import { makeInstagramPostDataHook } from 'Modules/Instagram/hooks/dataHooks/useInstagramPostsDataHook';
import { makeInstagramUserImagesDataHook } from 'Modules/Instagram/hooks/dataHooks/useInstagramUserImagesDataHook';
import { makeInstagramUsersDataHook } from 'Modules/Instagram/hooks/dataHooks/useInstagramUsersDataHook';
import { makeInstagramUserStatsDataHook } from 'Modules/Instagram/hooks/dataHooks/useInstagramUserStatsDataHook';

export const useInstagramPostStats = makeInstagramPostDataHook(
    PublicReportContext,
    ([values]) => values.instagram.postStats
);
export const useInstagramUserImages = makeInstagramUserImagesDataHook(
    PublicReportContext,
    ([values]) => values.instagram.userImages
);
const useInstagramUsers = makeInstagramUsersDataHook(PublicReportContext, ([values]) => values.instagram.users);
const useInstagramStats = makeInstagramUserStatsDataHook(PublicReportContext, ([values]) => values.instagram.userStats);

export function usePublicReportInstagramPosts(campaignId: number) {
    const postsData = useNonNullContextSelector(PublicReportContext, ([values]) => values.posts[campaignId]);
    const dispatch = useNonNullContextSelector(PublicReportContext, ([, dispatch]) => dispatch);

    const { data: postStatsData, fetchData: fetchPostStats } = useInstagramPostStats();
    const { data: userImagesData, fetchData: fetchUserImages } = useInstagramUserImages();
    const { data: usersData, fetchData: fetchUsers } = useInstagramUsers();
    const { data: userStatsData, fetchData: fetchStats } = useInstagramStats();

    const fetchData = useCallback(
        async (publicReportId: string, requestInit?: RequestInit) => {
            if (!campaignId) {
                return;
            }

            const publicIdRequestInit = influencer.addPublicReportIdToRequestInit(publicReportId, requestInit || {});

            try {
                dispatch({ type: 'loadPosts', payload: campaignId });
                const response = await creatorbase.getPosts(
                    { campaign_id: campaignId.toString(), page_size: 1000, ordering: '-view_count' },
                    publicIdRequestInit
                );

                if (response.status === 200) {
                    dispatch({
                        type: 'postsSuccess',
                        payload: { campaignId: campaignId, posts: response.data.results },
                    });

                    const contentIds = response.data.results
                        .map((post) => post.instagram_details?.content_id)
                        .filter(isNumber);

                    const userIds = uniq(
                        response.data.results.map((post) => post.instagram_details?.account_id).filter(isNumber)
                    );
                    const userIdsToFetch = userIds.filter(
                        (userId) =>
                            !userImagesData[userId]?.data && !usersData[userId]?.data && !userStatsData[userId]?.data
                    );

                    fetchPostStats(contentIds, omit(publicIdRequestInit, ['signal'])).catch(() => {});
                    fetchUserImages(userIdsToFetch, omit(publicIdRequestInit, ['signal'])).catch(() => {});
                    fetchUsers(userIdsToFetch, omit(publicIdRequestInit, ['signal'])).catch(() => {});
                    fetchStats(userIds, omit(publicIdRequestInit, ['signal'])).catch(() => {});

                    return response;
                }

                dispatch({
                    type: 'errorLoadingPosts',
                    payload: { campaignId: campaignId, message: response.data.detail },
                });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'postsIdle', payload: campaignId });
                    throw e;
                }
                dispatch({
                    type: 'errorLoadingPosts',
                    payload: { campaignId: campaignId, message: 'Could not get posts' },
                });
                throw e;
            }
        },
        [
            campaignId,
            dispatch,
            fetchPostStats,
            fetchUserImages,
            userImagesData,
            usersData,
            userStatsData,
            fetchUsers,
            fetchStats,
        ]
    );

    return {
        postsData,
        postStatsData,
        userImagesData,
        usersData,
        userStatsData,
        fetchData,

        getAreStatsLoading: (contentId: number | undefined) => {
            return Boolean(
                contentId &&
                    postStatsData[contentId]?.status !== 'success' &&
                    postStatsData[contentId]?.status !== 'error'
            );
        },
        getIsAccountDataLoading: (userId: number | null | undefined) => {
            return (
                !!userId &&
                Boolean(
                    (userImagesData[userId]?.status !== 'success' && userImagesData[userId]?.status !== 'error') ||
                        (usersData[userId]?.status !== 'success' && usersData[userId]?.status !== 'error') ||
                        (userStatsData[userId]?.status !== 'success' && userStatsData[userId]?.status !== 'error')
                )
            );
        },
    };
}
