import React from 'react';
import styles from './FilterHeader.module.css';

type Props = {
    name: string;
    icon: React.ReactNode;
    hasValue: boolean;
    onReset: () => void;
};

const FilterHeader = ({ name, icon, hasValue, onReset }: Props) => {
    return (
        <div className={styles.container}>
            {icon}
            <div>{name}</div>
            {hasValue && (
                <button
                    className={styles.clearButton}
                    onClickCapture={(e) => {
                        e.stopPropagation();
                        onReset();
                    }}
                >
                    Reset
                </button>
            )}
        </div>
    );
};

export default FilterHeader;
