import { AppServer, RouteHandlerContext } from '../../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { CampaignManagerBooking } from './booking.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';
import { BookingStatus } from '../../apex.types';

export const models = {
    campaignManagerBooking: Model as ModelDefinition<CampaignManagerBooking>,
};

export const serializers = {
    campaignManagerBooking: makeSerializer<CampaignManagerBooking>([]),
};

export const factories = {
    campaignManagerBooking: createFactory<CampaignManagerBooking>({
        currency: 1,
        booker_email(index: number) {
            return `bookerEmail${index}@example.com`;
        },
        campaign_name(index: number) {
            return `campaign name ${index}`;
        },
        campaign_type() {
            return 'AUDIO';
        },
        client_name(index: number) {
            return `client name ${index}`;
        },
        created() {
            return new Date().toISOString();
        },
        date_approved() {
            return new Date().toISOString();
        },
        date_influencer_paid() {
            return null;
        },
        finance_reference(index: number) {
            return `finance reference ${index}`;
        },
        influencer_name(index: number) {
            return `influencer name ${index}`;
        },
        influencer_id(index: number) {
            return index;
        },
        payment_terms() {
            return `client_terms`;
        },
        influencer_payment_amount(index: number) {
            return index * 100;
        },
        is_invoice_paid: false,
        is_created_by_client: false,
        platform() {
            return Math.random() > 0.5 ? 'INSTAGRAM' : 'TIKTOK';
        },
        post_url(index: number) {
            return `post url ${index}`;
        },
        price(index: number) {
            return index * 100;
        },
        status() {
            return 'offer_sent';
        },
        tiktok_ad_code(index: number) {
            return `tiktok ad code ${index}`;
        },
        reasons_to_block_approval_to_bill: [],
        manager_commission_amount: 0.2,
        manager_email: null,
        is_manager_paid: false,
    }),
};

export function handleRequests(server: AppServer) {
    const CampaignManagerBookingsListUrl = '/api/apex/campaign-manager/booking/';
    server.get(CampaignManagerBookingsListUrl, function (this: RouteHandlerContext, schema, request) {
        const { included_statuses = '', excluded_statuses = '' } = request.queryParams;
        const includedStatuses: BookingStatus[] = included_statuses
            .split(',')
            .filter((status): status is BookingStatus => Boolean(status.length));
        const excludedStatuses: BookingStatus[] = excluded_statuses
            .split(',')
            .filter((status): status is BookingStatus => Boolean(status.length));

        const bookings = schema
            .all('campaignManagerBooking')
            .filter((booking) => !includedStatuses.length || includedStatuses.includes(booking.status))
            .filter((booking) => !excludedStatuses.length || excludedStatuses.includes(booking.status));

        return buildPaginatedResponse(bookings, {
            url: CampaignManagerBookingsListUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'campaignManagerBooking'),
        });
    });

    server.get('/api/apex/campaign-manager/booking/:id/', function (this: RouteHandlerContext, schema, request) {
        return schema.find('campaignManagerBooking', request.params.id);
    });

    server.post('/api/apex/campaign-manager/booking/', function (this: RouteHandlerContext, schema, request) {
        const booking = schema.create('campaignManagerBooking', JSON.parse(request.requestBody));
        return booking;
    });

    server.patch('/api/apex/campaign-manager/booking/:id/', function (this: RouteHandlerContext, schema, request) {
        const booking = schema.find('campaignManagerBooking', request.params.id);
        if (!booking) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        booking.update(JSON.parse(request.requestBody));
        return booking;
    });

    server.put('/api/apex/campaign-manager/booking/:id/confirm/', function (schema, request) {
        const booking = schema.find('campaignManagerBooking', request.params.id);
        if (!booking) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        booking.update({ status: 'confirmed' });
        return new Response(204);
    });

    server.put('/api/apex/campaign-manager/booking/:id/decline/', function (schema, request) {
        const booking = schema.find('campaignManagerBooking', request.params.id);
        if (!booking) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        booking.update({ status: 'declined' });
        return new Response(204);
    });

    server.put(
        '/api/apex/campaign-manager/booking/:id/approve-to-bill/',
        function (this: RouteHandlerContext, schema, request) {
            const booking = schema.find('campaignManagerBooking', request.params.id);
            if (!booking) {
                return new Response(404, {}, { detail: 'Not found.' });
            }

            booking.update({ date_approved: new Date().toISOString() });
            return new Response(204);
        }
    );

    server.post(
        '/api/apex/campaign-manager/booking/:id/submit-status/',
        function (this: RouteHandlerContext, schema, request) {
            const booking = schema.find('campaignManagerBooking', request.params.id);
            if (!booking) {
                return new Response(404, {}, { detail: 'Not found.' });
            }

            booking.update(JSON.parse(request.requestBody));
            return new Response(201);
        }
    );
}
