import { fetchAll, fetchWithToken } from '../helpers';
import { FacebookAdAccount } from './facebook.types';

export async function getFacebookAdAccountId(clientId: number) {
    type AdAccountResponse = {
        ad_account_id: number | null;
    };

    return fetchWithToken(`/api/facebook/ad-account/${clientId}/`)
        .then((response) => response.json() as Promise<AdAccountResponse>)
        .then((result) => result.ad_account_id);
}

export async function getAdAccounts(): Promise<FacebookAdAccount[]> {
    return fetchAll('/api/facebook/viewsets/facebook-ad-account/');
}
