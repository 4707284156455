import { creatorbase, music } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { ReducerAction } from 'App.types';
import { createReducer } from 'helpers';

type Payload = {
    song: creatorbase.Song;
    musicSong?: music.Song | undefined;
};

export type SongState = DataState<Payload>;

export const songDataInitialState: SongState = {
    data: null,
    error: null,
    status: 'idle',
};

export type SongActions =
    | ReducerAction<'setSongDataLoading'>
    | ReducerActionWithPayload<'initSongData', Payload>
    | ReducerActionWithPayload<'songDataLoadingError', string>;

export default createReducer<SongState, SongActions>({
    setSongDataLoading: (state) => ({
        ...state,
        error: null,
        status: 'loading',
    }),
    initSongData: (state, { payload }) => ({
        ...state,
        data: payload,
        error: null,
        status: 'success',
    }),
    songDataLoadingError: (state, { payload }) => ({
        ...state,
        status: 'error',
        error: payload,
    }),
});
