import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { TiktokMicroPostInvite } from './tiktokPostInvites.types';

export const models = {
    tiktokPostInvite: Model as ModelDefinition<TiktokMicroPostInvite>,
};

export const serializers = {
    tiktokPostInvite: makeSerializer<TiktokMicroPostInvite>([]),
};

export const factories = {
    tiktokPostInvite: createFactory<TiktokMicroPostInvite>({
        audio_id: (index: number) => index,
        user_id: (index: number) => index,
        post_create_time: null,
        post_url: null,
        influencer_email_address: (index: number) => `email${index}@example.com`,
        deadline: '2023-11-02',
        last_contacted_at: null,
        number_of_chase_emails_sent: 0,
    }),
};

export function handleRequests(server: AppServer) {
    server.get('/api/microwave/tiktok-post-invites/', function (this: RouteHandlerContext, schema, request) {
        const creators = schema.all('tiktokPostInvite');
        return buildPaginatedResponse(creators, {
            url: '/api/microwave/tiktok-post-invites/',
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'tiktokPostInvite'),
        });
    });

    server.patch('/api/microwave/tiktok-post-invites/:id/', function (this: RouteHandlerContext, schema, request) {
        const invite = schema.find('tiktokPostInvite', request.params.id);
        if (!invite) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        invite.update(JSON.parse(request.requestBody));
        return invite;
    });
}
