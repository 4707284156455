import { AppServer, RouteHandlerContext } from '../../mirage';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { ClientTiktokTopVideo } from './topVideos.types';

export const models = {
    clientTiktokTopVideo: Model as ModelDefinition<ClientTiktokTopVideo>,
};

export const serializers = {
    clientTiktokTopVideo: makeSerializer<ClientTiktokTopVideo>([]),
};

export const factories = {
    clientTiktokTopVideo: createFactory<ClientTiktokTopVideo>({
        video_tiktok_id(index: number) {
            return `video tiktok id ${index}`;
        },
        author_unique_id(index: number) {
            return `author unique id ${index}`;
        },
        play_count: 0,
        create_time: Date.now(),
        comment_count: 0,
        description(index: number) {
            return `description ${index}`;
        },
        is_ad: false,
        like_count: 0,
        share_count: 0,
        author_follower_count: 0,
        location: null,
    }),
};

export function handleRequests(server: AppServer) {
    const getClientTiktokTopVideosUrl = '/api/trnds/client/:clientId/tiktok-audio/:audioId/post-stats/';
    server.get(getClientTiktokTopVideosUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('clientTiktokTopVideo'), {
            url: getClientTiktokTopVideosUrl,
            serialize: (resource) => this.serialize(resource, 'clientTiktokTopVideo'),
            queryParams: request.queryParams,
        });
    });
}
