import { microwave } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<microwave.Release>;
const initialState: State = { data: null, status: 'idle', error: null };

export default function useMicrowaveRelease() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (releaseId: number, requestInit?: RequestInit) => {
        setState({ status: 'loading', data: null, error: null });

        try {
            const response = await microwave.getRelease(releaseId, requestInit);
            if (response.status === 404) {
                setState({ status: 'error', data: null, error: response.data.detail });
                return response;
            }

            setState({ status: 'success', data: response.data, error: null });
            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            setState({
                status: 'error',
                data: null,
                error: e instanceof Error ? e.message : 'Could not fetch release',
            });
            throw e;
        }
    }, []);

    const reset = useCallback(() => setState(initialState), []);

    return {
        ...state,
        fetchData,
        reset,
    };
}
