import { microwave, PaginatedApiResponseData } from '@round/api';
import { useCallback, useState } from 'react';

type State =
    | {
          data: null;
          status: 'not-initialized';
          error: null;
      }
    | {
          data: null;
          status: 'loading';
          error: null;
      }
    | {
          data: Pick<PaginatedApiResponseData<microwave.Campaign>, 'results' | 'count'>;
          status: 'initialized';
          error: null;
      }
    | {
          data: null;
          status: 'error';
          error: string;
      };

const initialState: State = {
    status: 'not-initialized',
    data: null,
    error: null,
};

export function useMicrowaveCampaigns() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (params: microwave.GetCampaignsParams, requestInit?: RequestInit) => {
        try {
            setState({ status: 'loading', data: null, error: null });
            const response = await microwave.getCampaigns(params, requestInit);
            if (response.status === 404) {
                setState({ status: 'error', error: response.data.detail, data: null });
                return response;
            }

            setState({
                status: 'initialized',
                data: {
                    results: response.data.results,
                    count: response.data.count,
                },
                error: null,
            });
            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            const errorMessage = e instanceof Error ? e.message : 'Could not fetch microwave campaign';
            setState({ status: 'error', error: errorMessage, data: null });
            throw e;
        }
    }, []);

    const reset = () => {
        setState(initialState);
    };

    const createCampaign = useCallback(async (data: microwave.CampaignApiBody) => microwave.postCampaign(data), []);

    const updateCampaign = useCallback(async (id: number, data: Partial<microwave.CampaignApiBody>) => {
        const response = await microwave.patchCampaign(id, data);
        if (response.status === 200) {
            setState((prev) => ({
                status: 'initialized',
                data: {
                    results: (prev.data?.results ?? []).map((campaign) =>
                        campaign.id === id ? { ...campaign, ...data } : campaign
                    ),
                    count: prev.data?.count ?? 0,
                },
                error: null,
            }));
        }
        return response;
    }, []);

    return {
        data: {
            results: state.data?.results ?? [],
            count: state.data?.count ?? 0,
        },
        status: state.status,
        error: state.error,
        fetchData,
        createCampaign,
        updateCampaign,
        reset,
    };
}
