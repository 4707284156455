import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
import reducer, { AdvertisingContextState, initialState } from './reducer';
import {
    patchMediaPlan,
    MediaPlan,
    postMediaPlanItemGroup,
    deleteMediaPlanItemGroup,
    getAllMediaPlans,
    getFacebookAdAccountId,
    getAllMediaPlanItemGroups,
    patchMediaPlanItemGroup,
    MediaPlanItemGroup,
} from '@round/api';
import {
    buildMediaPlanItem,
    deleteMediaPlanItem,
    getAllMediaPlanItems,
    postMediaPlanItem,
    patchMediaPlanItem,
    getMediaPlanItemFacebookCampaignRelationship,
    deleteMediaPlanItemFacebookCampaignRelationship,
} from '../../api/MediaPlanItems.api';
import {
    Creative,
    FacebookCampaignMediaPlanItemRelationship,
    FacebookTargeting,
    MediaPlanItem,
    MediaPlanItemRow,
    Targeting,
} from '../../../../../App.types';
import { createTargeting, getTargetings } from '../../api/MediaPlanTargetings.api';
import { createFacebookTargeting, fetchFacebookCampaignCreate, getFacebookTargetings } from '../../api/Facebook.api';
import { getCreatives } from '../../api/MediaPlanCreatives.api';
import {
    isCreateFacebookCampaignError,
    isFacebookItemRow,
    isFacebookTargeting,
    mapFacebookTargetingToDto,
} from '../../helpers/Facebook.helpers';
import { postCreative } from '../../api/MediaPlanCreatives.api';
import { mapMediaPlanItemToApi, mapPartialMediaPlanItemToApi } from '../../helpers/MediaPlanItem.helpers';
import { FCWithChildren } from '../../../../../utility/utility.types';

type ProviderProps = { releaseId: number };
type AdvertisingContextValues = AdvertisingContextState &
    ProviderProps & {
        mediaPlanItemRows: MediaPlanItemRow[];
    };

type AdvertisingContextUtils = {
    init: (requestInit?: RequestInit) => Promise<void>;
    addGroup: (mediaPlanId: number, orderingIndex: number) => Promise<void>;
    deleteGroup: (groupId: number) => Promise<void>;
    changeGroupName: (groupId: number, name: string) => Promise<void>;
    duplicateGroup: (groupId: number) => Promise<void>;
    createMediaPlanItem: (mediaPlanId: number, groupId: number) => Promise<void>;
    deleteMediaPlanItems: (ids: number[]) => Promise<void>;
    updateMediaPlanItem: (mediaPlanItemId: number, data: Partial<MediaPlanItem>) => Promise<void>;
    unlinkFacebookCampaign: (campaign: FacebookCampaignMediaPlanItemRelationship) => Promise<void>;
    createFacebookCampaign: (mediaPlanItemId: number) => ReturnType<typeof fetchFacebookCampaignCreate>;
    duplicateMediaPlanItem: (mediaPlanItemId: number) => Promise<void>;
    addTargeting: (targeting: Targeting | FacebookTargeting) => void;
    updateTargeting: (targeting: Targeting | FacebookTargeting) => void;
    deleteTargeting: (targeting: Targeting | FacebookTargeting) => void;
    addCreative: (creative: Creative) => void;
    updateCreative: (creative: Creative) => void;
    deleteCreative: (creative: Creative) => void;
    setPlan: (plan: MediaPlan) => void;
    deletePlan: () => void;
    updateMediaPlanNotes: (notes: string) => Promise<void>;
    increaseGroupOrderingIndex: (groupId: number) => Promise<boolean>;
    decreaseGroupOrderingIndex: (groupId: number) => Promise<boolean>;
};

export const AdvertisingContext = createContext<[AdvertisingContextValues, AdvertisingContextUtils] | null>(null);
export const AdvertisingContextProvider: FCWithChildren<ProviderProps> = ({ releaseId, children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const mediaPlanItemRows: MediaPlanItemRow[] = useMemo(
        () =>
            state.mediaPlanItems.map<MediaPlanItemRow>((item) => {
                return {
                    ...item,
                    targeting: isFacebookItemRow(item)
                        ? state.facebookTargeting.filter((t) => t.media_plan_item === item.id)
                        : state.targeting.filter((t) => t.media_plan_item === item.id),
                    creatives: state.creatives.filter((c) => c.media_plan_item === item.id),
                    campaigns: state.facebookCampaignRelationships.filter((c) => c.media_plan_item === item.id),
                };
            }),
        [
            state.creatives,
            state.facebookCampaignRelationships,
            state.facebookTargeting,
            state.mediaPlanItems,
            state.targeting,
        ]
    );

    const hasFacebookItemRows = useMemo(() => state.mediaPlanItems.some((item) => isFacebookItemRow(item)), [
        state.mediaPlanItems,
    ]);

    const fetchMediaPlan = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'loadMediaPlan' });
                const [mediaPlan] = await getAllMediaPlans({ release_id: releaseId }, requestInit);
                dispatch({ type: 'loadMediaPlanSucceeded', payload: mediaPlan ?? null });
                return mediaPlan;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'loadMediaPlanFailed' });
            }
        },
        [releaseId]
    );

    const fetchMediaPlanItemRows = useCallback(async (mediaPlanId: number, requestInit?: RequestInit) => {
        try {
            dispatch({ type: 'loadMediaPlanItems' });
            const mediaPlanItems = await getAllMediaPlanItems({ media_plan_id: mediaPlanId }, requestInit);
            dispatch({ type: 'loadMediaPlanItemsSucceed', payload: mediaPlanItems });
            if (!mediaPlanItems.length) {
                return;
            }

            const itemIds = mediaPlanItems.map((i) => i.id);
            const targetings = await getTargetings(itemIds, requestInit);
            dispatch({ type: 'targetingLoaded', payload: targetings });
            const facebookTargetings = await getFacebookTargetings(
                { mediaPlanId, mediaPlanItemIds: itemIds },
                requestInit
            );
            dispatch({ type: 'facebookTargetingLoaded', payload: facebookTargetings });
            const creatives = await getCreatives(itemIds);
            dispatch({ type: 'creativesLoaded', payload: creatives });
            const facebookCampaignRelationships = await getMediaPlanItemFacebookCampaignRelationship(itemIds);
            dispatch({ type: 'facebookCampaignsLoaded', payload: facebookCampaignRelationships });
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            dispatch({ type: 'loadMediaPlanItemsFailed' });
        }
    }, []);

    const duplicateMediaPlanItem = useCallback(
        async (mediaPlanItemId: number, group?: MediaPlanItemGroup) => {
            const originalMediaPlanItem = mediaPlanItemRows.find((item) => item.id === mediaPlanItemId);
            if (!originalMediaPlanItem) {
                throw new Error(`Could not find media plan item with id ${mediaPlanItemId}`);
            }
            const duplicate = await postMediaPlanItem(
                mapMediaPlanItemToApi({ ...originalMediaPlanItem, group: group ?? originalMediaPlanItem.group })
            );

            dispatch({ type: 'duplicateMediaPlanItem', payload: duplicate });
            const creativePromises = originalMediaPlanItem.creatives.map((creative) =>
                postCreative({ ...creative, media_plan_item: duplicate.id }).then((creative) =>
                    dispatch({ type: 'addCreative', payload: creative })
                )
            );

            const targetingPromises = originalMediaPlanItem.targeting.map((targeting) => {
                const data = { ...targeting, media_plan_item: duplicate.id };
                return isFacebookItemRow(originalMediaPlanItem)
                    ? createFacebookTargeting(mapFacebookTargetingToDto(data as FacebookTargeting)).then((targeting) =>
                          dispatch({ type: 'addFacebookTargeting', payload: targeting })
                      )
                    : createTargeting(data).then((targeting) => dispatch({ type: 'addTargeting', payload: targeting }));
            });

            await Promise.all([...creativePromises, ...targetingPromises]);
        },
        [mediaPlanItemRows]
    );

    const deleteMediaPlanItems = useCallback(async (ids: number[], requestInit?: RequestInit) => {
        await Promise.all(ids.map((id) => deleteMediaPlanItem(id, requestInit)));
        dispatch({ type: 'deleteMediaPlanItems', payload: ids });
    }, []);

    const createMediaPlanItem = useCallback(async (mediaPlanId: number, groupId: number) => {
        const mediaPlanItem = await buildMediaPlanItem(mediaPlanId, groupId);
        dispatch({ type: 'createMediaPlanItem', payload: mediaPlanItem });
    }, []);

    const updateMediaPlanItem = useCallback(async (mediaPlanItemId: number, data: Partial<MediaPlanItem>) => {
        const updated = await patchMediaPlanItem(mediaPlanItemId, mapPartialMediaPlanItemToApi(data));
        dispatch({ type: 'updateMediaPlanItem', payload: updated });
    }, []);

    const createFacebookCampaign = useCallback(
        async (mediaPlanItemId: number) => {
            const mediaPlanItem = state.mediaPlanItems.find((item) => item.id === mediaPlanItemId);
            if (!mediaPlanItem) {
                throw new Error("Could not create Facebook campaign. Media plan item doesn't exist");
            }

            const result = await fetchFacebookCampaignCreate({ media_plan_item_id: mediaPlanItemId });
            if (!isCreateFacebookCampaignError(result)) {
                dispatch({
                    type: 'facebookCampaignCreated',
                    payload: {
                        campaign_id: result.campaign_id,
                        id: result.id,
                        media_plan_item: result.media_plan_item_id,
                    },
                });
            }

            return result;
        },
        [state.mediaPlanItems]
    );

    const unlinkFacebookCampaign = useCallback(
        async (campaign: FacebookCampaignMediaPlanItemRelationship) => {
            const item = state.mediaPlanItems.find((item) => item.id === campaign.media_plan_item);
            if (!item) {
                throw new Error('No media plan item found');
            }

            await deleteMediaPlanItemFacebookCampaignRelationship(campaign.id);
            dispatch({ type: 'unlinkFacebookCampaign', payload: campaign });
        },
        [state.mediaPlanItems]
    );

    const fetchGroups = useCallback(async (mediaPlanId: number, requestInit?: RequestInit) => {
        try {
            dispatch({ type: 'loadGroups' });
            const groups = await getAllMediaPlanItemGroups({ media_plan_id: mediaPlanId }, requestInit);
            dispatch({ type: 'groupsLoaded', payload: groups });
        } catch (e) {
            // noop
        }
    }, []);

    const addGroup = useCallback(async (mediaPlanId: number, orderingIndex: number) => {
        const response = await postMediaPlanItemGroup({
            name: 'Campaign Stage 1',
            media_plan: mediaPlanId,
            ordering_index: orderingIndex,
        });

        if (response.status === 201) {
            dispatch({ type: 'addGroup', payload: response.data });
        }
    }, []);

    const deleteGroup = useCallback(async (groupId: number) => {
        const response = await deleteMediaPlanItemGroup(groupId);
        if (response.status === 204) {
            dispatch({ type: 'deleteGroup', payload: groupId });
        }
    }, []);

    const changeGroupName = useCallback(async (groupId: number, name: string) => {
        const response = await patchMediaPlanItemGroup(groupId, { name });
        if (response.status === 200) {
            dispatch({ type: 'updateGroup', payload: response.data });
        }
    }, []);

    const increaseGroupOrderingIndex = useCallback(
        async (groupId: number) => {
            const orderedGroups = Array.from(state.groups).sort((a, b) => a.ordering_index - b.ordering_index);
            const currentGroupIndex = orderedGroups.findIndex((group) => group.id === groupId);
            const group = orderedGroups[currentGroupIndex];
            const succeedingGroup = orderedGroups[currentGroupIndex + 1];
            if (!group || !succeedingGroup) {
                return false;
            }

            const orderingIndexesAreEqual = succeedingGroup.ordering_index === group.ordering_index;
            const updateGroupResponse = await patchMediaPlanItemGroup(group.id, {
                ordering_index: orderingIndexesAreEqual ? group.ordering_index + 1 : succeedingGroup.ordering_index,
            });

            if (updateGroupResponse.status === 200) {
                dispatch({ type: 'updateGroup', payload: updateGroupResponse.data });
            }

            if (!orderingIndexesAreEqual) {
                const updatedSucceedingGroupResponse = await patchMediaPlanItemGroup(succeedingGroup.id, {
                    ordering_index: group.ordering_index,
                });

                if (updatedSucceedingGroupResponse.status === 200) {
                    dispatch({ type: 'updateGroup', payload: updatedSucceedingGroupResponse.data });
                }
            }
            return true;
        },
        [state.groups]
    );

    const decreaseGroupOrderingIndex = useCallback(
        async (groupId: number) => {
            const orderedGroups = Array.from(state.groups).sort((a, b) => a.ordering_index - b.ordering_index);
            const currentGroupIndex = orderedGroups.findIndex((group) => group.id === groupId);
            const group = orderedGroups[currentGroupIndex];
            const precedingGroup = orderedGroups[currentGroupIndex - 1];
            if (!group || !precedingGroup) {
                return false;
            }

            const orderingIndexesAreEqual = precedingGroup.ordering_index === group.ordering_index;
            const updateGroupResponse = await patchMediaPlanItemGroup(group.id, {
                ordering_index: orderingIndexesAreEqual ? group.ordering_index - 1 : precedingGroup.ordering_index,
            });

            if (updateGroupResponse.status === 200) {
                dispatch({ type: 'updateGroup', payload: updateGroupResponse.data });
            }
            if (!orderingIndexesAreEqual) {
                const updatedPrecedingGroupResponse = await patchMediaPlanItemGroup(precedingGroup.id, {
                    ordering_index: group.ordering_index,
                });

                if (updatedPrecedingGroupResponse.status === 200) {
                    dispatch({ type: 'updateGroup', payload: updatedPrecedingGroupResponse.data });
                }
            }
            return true;
        },
        [state.groups]
    );

    const duplicateGroup = useCallback(
        async (groupId: number) => {
            const originalGroup = state.groups.find((group) => group.id === groupId);
            if (!originalGroup) {
                throw new Error(`Could not find group with id ${groupId}`);
            }

            const response = await postMediaPlanItemGroup({
                name: originalGroup.name,
                ordering_index: state.groups.length,
                media_plan: originalGroup.media_plan,
            });

            if (response.status === 201) {
                dispatch({ type: 'duplicateMediaPlanGroup', payload: response.data });

                const originalGroupsMediaPlanItemsIds = state.mediaPlanItems
                    .filter((item) => item.group?.id === originalGroup.id)
                    .map((item) => item.id);

                await Promise.all(
                    originalGroupsMediaPlanItemsIds.map((id) => duplicateMediaPlanItem(id, response.data))
                );
            }
        },
        [duplicateMediaPlanItem, state.groups, state.mediaPlanItems]
    );

    const addTargeting = useCallback(
        (targeting: Targeting | FacebookTargeting) => {
            const mediaPlanItem = state.mediaPlanItems.find((item) => item.id === targeting.media_plan_item);
            if (!mediaPlanItem) {
                return;
            }

            if (isFacebookItemRow(mediaPlanItem)) {
                dispatch({ type: 'addFacebookTargeting', payload: targeting as FacebookTargeting });
                return;
            }

            dispatch({ type: 'addTargeting', payload: targeting });
        },
        [state.mediaPlanItems]
    );

    const updateTargeting = useCallback((targeting: Targeting | FacebookTargeting) => {
        if (isFacebookTargeting(targeting)) {
            dispatch({ type: 'updateFacebookTargeting', payload: targeting });
            return;
        }

        dispatch({ type: 'updateTargeting', payload: targeting });
    }, []);

    const deleteTargeting = useCallback((targeting: Targeting | FacebookTargeting) => {
        if (isFacebookTargeting(targeting)) {
            dispatch({ type: 'deleteFacebookTargeting', payload: targeting });
            return;
        }

        dispatch({ type: 'deleteTargeting', payload: targeting });
    }, []);

    const fetchFacebookAdAccountId = useCallback(async (clientId: number, hasFacebookItemRows: boolean) => {
        if (!hasFacebookItemRows) {
            return;
        }

        const adAccountId = await getFacebookAdAccountId(clientId);
        if (adAccountId) {
            dispatch({ type: 'loadedFacebookAdAccountId', payload: adAccountId });
        }
    }, []);

    const updateMediaPlanNotes = useCallback(
        async (notes: string) => {
            if (!state.mediaPlan) {
                return;
            }

            const response = await patchMediaPlan(state.mediaPlan.id, { notes });
            if (response.status === 200) {
                dispatch({ type: 'setPlan', payload: response.data });
            }
        },
        [state.mediaPlan]
    );

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            const mediaPlan = await fetchMediaPlan(requestInit);
            if (mediaPlan) {
                await Promise.all([
                    fetchMediaPlanItemRows(mediaPlan.id, requestInit),
                    fetchGroups(mediaPlan.id, requestInit),
                ]);
            }
            dispatch({ type: 'initialized' });
        },
        [fetchGroups, fetchMediaPlan, fetchMediaPlanItemRows]
    );

    useEffect(() => {
        if (state.mediaPlan && hasFacebookItemRows) {
            fetchFacebookAdAccountId(state.mediaPlan.release.brand.client.id, hasFacebookItemRows);
        }
    }, [fetchFacebookAdAccountId, hasFacebookItemRows, state.mediaPlan]);

    useEffect(() => {
        dispatch({ type: 'reset' });
    }, [releaseId]);

    const providerValues: AdvertisingContextValues = useMemo(
        () => ({
            ...state,
            releaseId,
            mediaPlanItemRows,
        }),
        [mediaPlanItemRows, releaseId, state]
    );

    const utils: AdvertisingContextUtils = useMemo(
        () => ({
            init,
            addGroup,
            deleteGroup,
            changeGroupName,
            duplicateGroup,
            createMediaPlanItem,
            deleteMediaPlanItems,
            updateMediaPlanItem,
            unlinkFacebookCampaign,
            createFacebookCampaign,
            duplicateMediaPlanItem,
            addTargeting,
            updateTargeting,
            deleteTargeting,
            updateMediaPlanNotes,
            increaseGroupOrderingIndex,
            decreaseGroupOrderingIndex,
            addCreative: (creative) => dispatch({ type: 'addCreative', payload: creative }),
            updateCreative: (creative) => dispatch({ type: 'updateCreative', payload: creative }),
            deleteCreative: (creative) => dispatch({ type: 'deleteCreative', payload: creative }),
            setPlan: (plan) => dispatch({ type: 'setPlan', payload: plan }),
            deletePlan: () => dispatch({ type: 'deletePlan' }),
        }),
        [
            init,
            addGroup,
            deleteGroup,
            changeGroupName,
            duplicateGroup,
            createMediaPlanItem,
            deleteMediaPlanItems,
            updateMediaPlanItem,
            unlinkFacebookCampaign,
            createFacebookCampaign,
            duplicateMediaPlanItem,
            addTargeting,
            updateTargeting,
            deleteTargeting,
            updateMediaPlanNotes,
            increaseGroupOrderingIndex,
            decreaseGroupOrderingIndex,
        ]
    );
    return <AdvertisingContext.Provider value={[providerValues, utils]}>{children}</AdvertisingContext.Provider>;
};
