import { microwave } from '@round/api';
import { GenericDropdownOption } from '../../../App.types';

export const microPaymentRequestStatusOptions: GenericDropdownOption<microwave.PaymentRequestStatus>[] = [
    {
        value: 'PENDING',
        label: 'Pending',
    },
    {
        value: 'APPROVED',
        label: 'Approved',
    },
    {
        value: 'REJECTED',
        label: 'Rejected',
    },
];

export const paymentStatusOptions: GenericDropdownOption<microwave.PaymentStatus>[] = [
    { value: 'UNPAID', label: 'Unpaid' },
    { value: 'PAID', label: 'Paid' },
    { value: 'IN_PROGRESS', label: 'In Progress' },
    { value: 'FAILED', label: 'Failed' },
];

export const preferredPaymentMethodOptions: GenericDropdownOption<microwave.PaymentMethod>[] = [
    { value: 'PAYPAL', label: 'PayPal' },
    { value: 'VENMO', label: 'Venmo' },
    { value: 'BANK_TRANSFER', label: 'Bank Transfer' },
];

export const platformOptions: GenericDropdownOption<microwave.Platform>[] = [
    { value: 'tiktok', label: 'TikTok' },
    { value: 'instagram', label: 'Instagram' },
    { value: 'youtube', label: 'YouTube' },
];
