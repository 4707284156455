import { call } from '../../helpers';
import { isPublicReportRequestInit } from '../../influencer';
import { ApiResponse } from '../../types';
import { InstagramUserImage } from './instagramUserImage.types';

export async function getInstagramUserImages(
    userIds: number[],
    requestInit?: RequestInit
): Promise<ApiResponse<InstagramUserImage[], 200>> {
    const response = await call(`/api/instagram/user-image/?user_id=${userIds.join()}`, requestInit, {
        shouldBypassTokens: isPublicReportRequestInit(requestInit),
    });
    if (!response.ok) {
        throw new Error(`Could not get images`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
