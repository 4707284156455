import styles from '../../PaymentModalTable.module.css';
import WrapperTable from 'ui/WrapperTable/WrapperTable';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import ReferenceCell from '../../../TableCells/ReferenceCell/ReferenceCell';
import StatusCell from '../../TableCells/StatusCell/StatusCell';
import { RequestStatus } from '../../helpers';
import { OutstandingPaymentsTableRow } from '../../../OutstandingPayments/helpers';
import AccountCell from '../../../TableCells/AccountCell/AccountCell';
import { microwave } from '@round/api';
import CostCurrencyCell from '../../../TableCells/CostCurrencyCell/CostCurrencyCell';
import PlatformCell from '../../../TableCells/PlatformCell/PlatformCell';

type TableRow = OutstandingPaymentsTableRow & {
    paymentRequestStatus: RequestStatus | undefined;
};

type Props = {
    data: TableRow[];
    updatePayment: typeof microwave.patchPaymentRequest;
    arePaymentsLoading: boolean;
    arePaymentsComplete: boolean;
};

type TableCellContext<K extends keyof TableRow> = CellContext<TableRow, TableRow[K]>;

const PaymentsTable = ({ data, updatePayment, arePaymentsLoading, arePaymentsComplete }: Props) => {
    const columns: ColumnDef<TableRow, any>[] = [
        {
            header: 'Account',
            accessorKey: 'influencer',
            cell: ({ getValue, row: { original } }: TableCellContext<'influencer'>) => (
                <AccountCell
                    influencer={getValue() || null}
                    imageUrl={original.userImageUrl}
                    platform={original.platform}
                />
            ),
        },
        {
            header: 'Platform',
            accessorKey: 'platform',
            cell: ({ getValue }: TableCellContext<'platform'>) => <PlatformCell platform={getValue()} />,
        },
        {
            header: 'Cost',
            accessorKey: 'cost',
            cell: ({ getValue, row: { original } }: TableCellContext<'cost'>) => {
                return (
                    <CostCurrencyCell
                        cost={getValue()}
                        currencyId={original.currency_id}
                        updatePayment={({ cost, currency_id }) =>
                            updatePayment(original.id, {
                                cost,
                                currency_id,
                            })
                        }
                        isReadonly={arePaymentsLoading || arePaymentsComplete}
                    />
                );
            },
        },
        {
            header: 'Paypal Email Address',
            accessorKey: 'paypal_email_address',
            cell: ({ getValue }: TableCellContext<'paypal_email_address'>) => <span>{getValue()}</span>,
        },
        {
            header: 'Reference',
            accessorKey: 'payment_reference',
            cell: ({ getValue, row: { original } }: TableCellContext<'payment_reference'>) => (
                <ReferenceCell
                    reference={getValue()}
                    updatePayment={(value) => updatePayment(original.id, { payment_reference: value })}
                    isReadonly={arePaymentsLoading || arePaymentsComplete}
                />
            ),
        },
        {
            header: 'Status',
            accessorKey: 'paymentRequestStatus',
            cell: ({ getValue }: TableCellContext<'paymentRequestStatus'>) => (
                <StatusCell status={getValue()} isRequestLoading={arePaymentsLoading} />
            ),
        },
    ];

    return <WrapperTable data={data} columns={columns} className={styles.table} />;
};

export default PaymentsTable;
