import { InstagramPost } from '@round/api';
import { Skeleton } from '@round/ui-kit';
import { numberWithCommas } from 'helpers';
import { useMemo } from 'react';
import { Column } from 'react-table';
import TruncatedTextCell from 'SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import PaginationTable, { PaginationTableProps } from 'ui/PaginationTable/PaginationTable';
import { formatToIsoDateString } from 'utility/utility';
import styles from './TopPostsTable.module.css';

type Props = Omit<PaginationTableProps<InstagramPost>, 'columns'>;

const TopPostsTable = ({ loading, ...props }: Props) => {
    const columns: Column<InstagramPost>[] = useMemo(
        () => [
            {
                Header: 'Username',
                accessor: 'owner_username',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }

                    return <TruncatedTextCell className={styles.username}>{value}</TruncatedTextCell>;
                },
            },
            {
                Header: 'Followers',
                accessor: 'owner_follower_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value === null ? '-' : numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Location',
                accessor: 'location',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Description',
                accessor: 'text',
                disableSortBy: true,
                Cell: ({ value, row: { original } }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <a
                            className={styles.description}
                            href={original.post_url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <TruncatedTextCell>{value || 'No Caption'}</TruncatedTextCell>
                        </a>
                    );
                },
            },
            {
                Header: 'Date posted',
                accessor: 'taken_at',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    if (!value) {
                        return <>-</>;
                    }

                    return <div className={styles.date}>{formatToIsoDateString(new Date(value))}</div>;
                },
            },
            {
                Header: 'Boosted',
                accessor: 'is_ad',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value ? 'Yes' : 'No'}</>;
                },
            },
            {
                Header: 'Plays',
                accessor: 'play_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    if (value === null) {
                        return <>-</>;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Views',
                accessor: 'view_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    if (value === null) {
                        return <>-</>;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Likes',
                accessor: 'like_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    if (value === null) {
                        return <>-</>;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Comments',
                accessor: 'comment_count',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    if (value === null) {
                        return <>-</>;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
        ],
        [loading]
    );

    return <PaginationTable {...props} tableClassName={styles.table} columns={columns} loading={loading} />;
};

export default TopPostsTable;
