import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Channel } from './channels.types';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    youtubeChannel: Model as ModelDefinition<Channel>,
};

export const serializers = {
    youtubeChannel: makeSerializer<Channel>([]),
};

export const factories = {
    youtubeChannel: createFactory<Channel>({
        country: 'UK',
        custom_url: 'https://youtube.com/@MrBeast',
        default_language: 'en',
        description: (index: number) => `Description ${index}`,
        published_at: new Date().toISOString(),
        thumbnail: '',
        title: (index: number) => `Title ${index}`,
        youtube_id: (index: number) => `id ${index}`,
    }),
};

export function handleRequests(server: AppServer) {
    const channelsUrl = '/api/youtube/channels/';
    server.get(channelsUrl, function (this: RouteHandlerContext, schema, request) {
        const channels = schema
            .all('youtubeChannel')
            .filter(
                (channel) =>
                    !request.queryParams.identifier_search ||
                    channel.youtube_id
                        .toLocaleLowerCase()
                        .includes(request.queryParams.identifier_search.toLocaleLowerCase())
            );

        return buildPaginatedResponse(channels, {
            url: channelsUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'youtubeChannel'),
        });
    });

    server.get('/api/youtube/channels/:id/', (schema, request) => {
        const channel = schema.find('youtubeChannel', request.params.id);

        if (!channel) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return channel;
    });

    server.post('/api/youtube/channels/', (schema, request) => {
        const attrs = JSON.parse(request.requestBody);
        const channel = schema.create('youtubeChannel', {
            youtube_id: attrs.identifier,
        });

        return channel;
    });
}
