import { GetInvoiceRequestsParams } from '@round/api';
import { DomainFiltersProps, getInitialFilters } from 'ui-new/Filters/helpers';
import { Filter } from 'ui-new/Filters/useFilterManager';
import { ReactComponent as MusicPlayIcon } from 'assets/icons/MusicPlay.svg';
import { ReactComponent as DollarSquareIcon } from 'assets/icons/DollarSquare.svg';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/Note.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/Calendar.svg';
import { ReactComponent as DocumentTextIcon } from 'assets/icons/DocumentText.svg';
import ReleaseFilter from 'Modules/Advertising/Projects/components/ReleaseFilter/ReleaseFilter';
import styles from './InvoiceRequestsFilters.module.css';
import Filters from 'ui-new/Filters/Filters';
import { formatToIsoDateString, getNonEmptyKeys } from 'utility/utility';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import { invoiceRequestStatusOptions, notesFilterOptions } from '../helpers';
import ClientsFilter from '../ClientsFilter/ClientsFilter';
import { mapStringListToArray } from 'helpers';
import PlannerUsersFilter from 'Modules/Auth/components/PlannerUsersFilter/PlannerUsersFilter';
import { DateRangeFilter } from 'ui-new/Filters/DateRangeFilter/DateRangeFilter';

export type InvoiceRequestsFilterParams = Pick<
    GetInvoiceRequestsParams,
    | 'client_id'
    | 'planner_id'
    | 'release_id'
    | 'status'
    | 'with_notes'
    | 'plan_start_date_start'
    | 'plan_start_date_end'
>;

type Props = DomainFiltersProps<InvoiceRequestsFilterParams>;

const InvoiceRequestsFilters = ({ value, onChange }: Props) => {
    const filters: Filter<keyof InvoiceRequestsFilterParams>[] = [
        {
            name: 'release_id',
            label: 'Release',
            icon: MusicPlayIcon,
            render(this, helpers) {
                const Icon = this.icon;
                return (
                    <ReleaseFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        icon={<Icon className={styles.icon} />}
                        name={this.label}
                        initialReleaseId={value.release_id}
                        onChange={(value) => {
                            onChange({ release_id: value?.value });
                        }}
                        onClear={() => onChange({ release_id: undefined })}
                        onRemove={() => {
                            onChange({ release_id: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'status',
            label: 'Status',
            icon: DollarSquareIcon,
            render(this, helpers) {
                const Icon = this.icon;
                const selectedValues = invoiceRequestStatusOptions.filter((option) =>
                    value.status
                        ?.split(',')
                        .filter((v) => !!v)
                        .includes(option.value)
                );

                return (
                    <SelectFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        name={this.label}
                        icon={<Icon className={styles.icon} />}
                        isMulti
                        isSearchable={false}
                        options={invoiceRequestStatusOptions}
                        value={selectedValues}
                        onChange={(val) => onChange({ status: val?.map((o) => o.value).join() })}
                        onClear={() => onChange({ status: undefined })}
                        onRemove={() => {
                            onChange({ status: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'client_id',
            label: 'Client',
            icon: UserIcon,
            render(this, helpers) {
                const Icon = this.icon;

                return (
                    <ClientsFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        icon={<Icon className={styles.icon} />}
                        name={this.label}
                        selectedClientIds={mapStringListToArray(value.client_id ?? '').map(Number)}
                        plannerIds={mapStringListToArray(value.planner_id ?? '').map(Number)}
                        onChange={(val) => onChange({ client_id: val?.map((v) => v.value).toString() })}
                        onClear={() => onChange({ client_id: undefined })}
                        onRemove={() => {
                            onChange({ client_id: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'planner_id',
            label: 'Planners',
            icon: NoteIcon,
            render(this, helpers) {
                const Icon = this.icon;

                return (
                    <PlannerUsersFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        name={this.label}
                        icon={<Icon className={styles.icon} />}
                        initialPlannerUserIds={mapStringListToArray(value.planner_id ?? '').map(Number)}
                        onChange={(val) => onChange({ planner_id: val?.map((v) => v.value).toString() })}
                        onClear={() => onChange({ planner_id: undefined })}
                        onRemove={() => {
                            onChange({ planner_id: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'plan_start_date_start',
            label: 'Plan Start Date Range',
            icon: CalendarIcon,
            render(this, helpers) {
                const dateRange: [Date] | [Date, Date] | null =
                    value.plan_start_date_start && value.plan_start_date_end
                        ? [new Date(value.plan_start_date_start), new Date(value.plan_start_date_end)]
                        : null;

                return (
                    <DateRangeFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        name={this.label}
                        value={dateRange}
                        onChange={(dates) =>
                            onChange({
                                plan_start_date_start: dates?.[0] ? formatToIsoDateString(dates[0]) : undefined,
                                plan_start_date_end: dates?.[1] ? formatToIsoDateString(dates[1]) : undefined,
                            })
                        }
                        onClear={() => {
                            onChange({ plan_start_date_end: undefined, plan_start_date_start: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'with_notes',
            label: 'Notes',
            icon: DocumentTextIcon,
            render(this, helpers) {
                const Icon = this.icon;

                return (
                    <SelectFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        icon={<Icon className={styles.icon} />}
                        name={this.label}
                        isSearchable={false}
                        options={notesFilterOptions}
                        value={notesFilterOptions.find((o) => String(o.value) === String(value.with_notes))}
                        onChange={(val) => onChange({ with_notes: val?.value })}
                        onClear={() => onChange({ with_notes: undefined })}
                        onRemove={() => {
                            onChange({ with_notes: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
    ];

    return (
        <Filters
            sessionKey="invoice-requests-filters"
            filters={filters}
            options={{ initialFilters: (state) => getInitialFilters(state, getNonEmptyKeys(value)) }}
        />
    );
};

export default InvoiceRequestsFilters;
