import ProjectForm, { ProjectFormValues } from '../ProjectForm/ProjectForm';
import { Formik, FormikHelpers } from 'formik';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import { creatorbase } from '@round/api';
import { showNotification } from 'helpers';
import {
    convertPostProjectFormApiErrorsToFormikErrors,
    projectFormValidationSchema,
} from '../ProjectForm/ProjectForm.helpers';
import Button from 'ui-new/whitelabel/Button/Button';
import styles from './CreateProjectModal.module.css';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    createProject: typeof creatorbase.postProject;
};

const initialValues: ProjectFormValues = {
    name: '',
    brand: null,
    song: null,
};

const CreateProjectModal = ({ isOpen, onClose, createProject }: Props) => {
    const handleSubmit = async (values: ProjectFormValues, helpers: FormikHelpers<ProjectFormValues>) => {
        const data: creatorbase.PostProjectData = {
            name: values.name,
            brand_id: values.brand!.value,
            song_id: values.song?.value ?? null,
        };

        try {
            const response = await createProject(data);

            if (response.status === 201) {
                helpers.resetForm();
                onClose();
                return;
            }

            if (Array.isArray(response.data)) {
                showNotification(response.data.join('. '), 'error');
                return;
            }

            if (response.status === 400) {
                helpers.setErrors(convertPostProjectFormApiErrorsToFormikErrors(response.data));
                return;
            }

            showNotification('Could not create project', 'error');
        } catch {
            showNotification('Could not create project', 'error');
        }
    };

    return (
        <Modal
            className={styles.modal}
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick
            ariaLabel="create project modal"
        >
            <Modal.Header>
                <Modal.Title>Create project</Modal.Title>
            </Modal.Header>
            <Formik
                validationSchema={projectFormValidationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ isValid, isSubmitting, submitForm, dirty }) => (
                    <>
                        <Modal.Body>
                            <ProjectForm />
                        </Modal.Body>

                        <Modal.Actions>
                            <Button
                                appearance="primary"
                                onClick={submitForm}
                                disabled={!isValid || isSubmitting || !dirty}
                            >
                                Create project
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default CreateProjectModal;
