import React from 'react';
import { useFormikContext } from 'formik';
import styles from './FormField.module.css';

type ErrorMessageProps = { name: string };

// [BP-2]: Replace error messages in field components with ErrorMessage component
const ErrorMessage = ({ name }: ErrorMessageProps) => {
    const error = useFormikContext<any>().getFieldMeta(name).error;

    if (!error) {
        return null;
    }

    return <span className={styles.errorMessage}>{error}</span>;
};

export default ErrorMessage;
