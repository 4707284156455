import { Image } from '@round/ui-kit';
import styles from './BrandBadge.module.css';

type Props = {
    name: string;
    teamName?: string;
    imageUrl?: string | null;
};

const BrandBadge = ({ name, teamName, imageUrl }: Props) => {
    return (
        <div className={styles.container}>
            <Image src={imageUrl ?? undefined} alt={`${name} image`} className={styles.image} />
            <div className={styles.name}>{name}</div>
            {!!teamName && <div className={styles.teamName}>{teamName}</div>}
        </div>
    );
};

export default BrandBadge;
