import { ModelDefinition } from 'miragejs/-types';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { hasMany, Model } from 'miragejs';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { InfluencerUserCampaign, InfluencerUserCampaigns, YoutubeInfluencerPlanStats } from './influencerPlan.types';

export const models = {
    influencerUserCampaigns: Model.extend({
        plans: hasMany('instagramInfluencerUserCampaign'),
    }) as ModelDefinition<InfluencerUserCampaigns>,
    influencerUserCampaign: Model as ModelDefinition<InfluencerUserCampaign>,
    youtubeInfluencerPlanStats: Model as ModelDefinition<YoutubeInfluencerPlanStats>,
};

export const factories = {
    influencerUserCampaign: createFactory<InfluencerUserCampaign>({
        plan_id(index: number) {
            return index;
        },
        group_ids(index: number) {
            return [index];
        },
        status() {
            return 'planned';
        },
    }),
    influencerUserCampaigns: createFactory<InfluencerUserCampaigns>({
        influencer_id(index: number) {
            return index;
        },
        afterCreate(c) {
            if (!Array.isArray(c.plans)) {
                c.update({
                    plans: [],
                });
            }
        },
    }),
    youtubeInfluencerPlanStats: createFactory<YoutubeInfluencerPlanStats>({
        post_count() {
            return 1;
        },
        total_spend() {
            return 100;
        },
        total_views() {
            return 1000;
        },
        engagement_rate() {
            return 0.1;
        },
        total_cpm() {
            return 10;
        },
    }),
};

export const serializers = {
    influencerUserCampaigns: makeSerializer<InfluencerUserCampaigns>(['plans']),
    youtubeInfluencerPlanStats: makeSerializer<YoutubeInfluencerPlanStats>([]),
};

export function handleInfluencerPlanRequests(server: AppServer) {
    server.get('/api/advertising/influencer-plans/', function (this: RouteHandlerContext, schema, request) {
        const { instagram_influencer_users, tiktok_influencer_users } = request.queryParams;
        const influencerUserIds =
            instagram_influencer_users.split(',').map((id) => Number(id)) ||
            tiktok_influencer_users.split(',').map((id) => Number(id));

        const campaigns = schema
            .all('influencerUserCampaigns')
            .filter((c) => influencerUserIds.includes(c.influencer_id));

        return this.serialize(campaigns);
    });
    server.get('/api/advertising/influencer-plans/:id/youtube-live-stats', (schema, request) => {
        return schema.create('youtubeInfluencerPlanStats');
    });
}
