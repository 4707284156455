import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AppServer, RouteHandlerContext } from '../../../mirage';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';
import { ApexInfluencer } from './apexInfluencer.types';

export const models = {
    apexInfluencer: Model as ModelDefinition<ApexInfluencer>,
};

export const factories = {
    apexInfluencer: createFactory<ApexInfluencer>({
        location: (index: number) => index,
        manager_email: (index: number) => `manager.email${index}@email.com`,
        name: (index: number) => `name ${index}`,
        content_tags: [],
        cost_instagram_audio: Math.random() * 1000,
        cost_instagram_brand: Math.random() * 1000,
        cost_tiktok_audio: Math.random() * 1000,
        cost_tiktok_brand: Math.random() * 1000,
        currency: 1,
        id(index: number) {
            return index;
        },
        instagram_display_name(index: number) {
            return `instagram_display_name ${index}`;
        },
        instagram_follower_count(index: number) {
            return index * 1000;
        },
        instagram_user_id(index: number) {
            return index;
        },
        instagram_username(index: number) {
            return `instagram_username ${index}`;
        },
        tiktok_display_name(index: number) {
            return `tiktok_display_name ${index}`;
        },
        tiktok_follower_count(index: number) {
            return index * 1000;
        },
        tiktok_user_id(index: number) {
            return index;
        },
        tiktok_username(index: number) {
            return `tiktok_username ${index}`;
        },
    }),
};

export const serializers = {
    apexInfluencer: makeSerializer<ApexInfluencer>([]),
};

export async function handleApexInfluencerRequests(server: AppServer) {
    server.get('/api/apex/client/influencer/', function (this: RouteHandlerContext, schema, request) {
        const { currency_id } = request.queryParams;
        const influencers = schema
            .all('apexInfluencer')
            .filter((influencer) => !currency_id || influencer.currency === Number(currency_id));

        return buildPaginatedResponse(influencers, {
            url: '/api/apex/client/influencer/',
            serialize: (resource) => this.serialize(resource, 'apexInfluencer'),
            queryParams: request.queryParams,
        });
    });

    server.get('/api/apex/influencer/:id/', function (this: RouteHandlerContext, schema, request) {
        const influencer = schema.find('apexInfluencer', request.params.id);
        if (!influencer) {
            return new Response(404, {}, { detail: 'Not found' });
        }

        return influencer;
    });
}
