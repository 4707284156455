import { creatorbase } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

type State = DataState<creatorbase.Team[]>;
type Actions =
    | ReducerAction<'loadTeams'>
    | ReducerActionWithPayload<'teamsLoadSuccess', creatorbase.Team[]>
    | ReducerActionWithPayload<'teamsLoadingError', string>;

export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export const reducer = createReducer<State, Actions>({
    loadTeams: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    teamsLoadingError: (state, { payload: errorMessage }) => ({
        ...state,
        status: 'error',
        error: errorMessage,
    }),
    teamsLoadSuccess: (state, { payload: teams }) => ({
        status: 'success',
        data: state.data?.concat(teams) ?? teams,
        error: null,
    }),
});
