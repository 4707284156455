import useAbortableEffect from 'Hooks/useAbortableEffect';
import useCampaigns from '../useCampaigns';
import PublicReportCampaignsTable, { CampaignTableRow } from '../CampaignsTable/PublicReportCampaignTable';
import useUrlState from 'Hooks/useUrlState';
import { ExpandedState } from '@tanstack/react-table';
import { useMemo } from 'react';
import PublicReportInstagramPostsTable from '../../Posts/InstagramPosts/PublicReportInstagramPostsTable';

type Props = {
    publicReportId: string | undefined;
};

type UrlState = Partial<{
    expanded: string;
}>;

const ReportInstagramCampaigns = ({ publicReportId }: Props) => {
    const [urlState, setUrlState] = useUrlState<UrlState>();
    const {
        campaignsData,
        campaignStatsData,
        projectsData,
        brandsData,
        teamsData,
        fetchData,
        isProjectLoading,
        isTeamLoading,
        isBrandLoading,
        isCampaignStatsLoading,
    } = useCampaigns('instagram');
    const areCampaignsInitialized = campaignsData.isInitialized || campaignsData.status === 'error';
    const areCampaignsLoading = campaignsData.status !== 'error' && campaignsData.status !== 'success';

    useAbortableEffect(
        (signal) => {
            if (areCampaignsInitialized || !publicReportId) {
                return;
            }

            fetchData(
                {
                    public_report_id: publicReportId,
                    platform: 'instagram',
                },
                { signal }
            ).catch(() => {});
        },
        [areCampaignsInitialized, publicReportId, fetchData]
    );

    const expandedState = useMemo(() => {
        const expanded = urlState?.expanded;

        if (!expanded) {
            return {};
        }

        return expanded.split(',').reduce((acc, campaignId) => {
            acc[campaignId] = true;
            return acc;
        }, {} as Record<string, boolean>);
    }, [urlState]);

    const setExpandedState = (expanded: ExpandedState) => {
        const expandedKeys = Object.keys(expanded).filter((key) => expanded[key as keyof ExpandedState]);
        setUrlState({ expanded: expandedKeys.join(',') });
    };

    const rows = useMemo(
        () =>
            campaignsData.data?.results.map<CampaignTableRow>((c) => {
                const stats = campaignStatsData[c.id]?.data ?? null;
                const project = projectsData[c.project_id]?.data ?? null;
                const brand = project ? brandsData[project.brand_id]?.data ?? null : null;
                const team = project ? teamsData[project.team_id]?.data ?? null : null;
                return { ...c, project, brand, team, stats };
            }) ?? [],
        [brandsData, campaignStatsData, campaignsData.data?.results, projectsData, teamsData]
    );

    return (
        <PublicReportCampaignsTable
            data={rows}
            isLoading={areCampaignsLoading}
            hasError={campaignsData.status === 'error'}
            expandedState={expandedState}
            onExpandedChange={setExpandedState}
            meta={{
                isLoading: areCampaignsLoading,
                getIsProjectLoading: (row) => isProjectLoading(row.id),
                getIsTeamLoading: (row) => isTeamLoading(row.id),
                getIsBrandLoading: (row) => isBrandLoading(row.id),
                getIsCampaignStatsLoading: (row) => isCampaignStatsLoading(row.id),
            }}
            renderSubComponent={(row) => (
                <PublicReportInstagramPostsTable campaign={row.original} publicReportId={publicReportId} />
            )}
        />
    );
};

export default ReportInstagramCampaigns;
