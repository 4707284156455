import { GetPostPaymentsParams } from '@round/api';
import { DomainFiltersProps, getInitialFilters } from 'ui-new/Filters/helpers';
import { Filter } from 'ui-new/Filters/useFilterManager';
import { ReactComponent as StatusIcon } from 'assets/icons/MinusCircle.svg';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import styles from './PastPaymentsFilters.module.css';
import { tiktokPostPaypalPaymentStatusOptions } from 'Modules/Advertising/constants';
import Filters from 'ui-new/Filters/Filters';
import { getNonEmptyKeys } from 'utility/utility';
import TiktokInfluencerFilter from 'Modules/TikTok/components/filters/TiktokInfluencerFilter/TiktokInfluencerFilter';
import InstagramInfluencerUsersFilter from 'Modules/Instagram/components/Filters/InstagramInfluencerUserFilter/InstagramInfluencerUserFilter';

export type PastPaymentsFiltersParams = Pick<
    GetPostPaymentsParams,
    'paypal_payment_status' | 'tiktok_influencer_user_id' | 'instagram_influencer_user_id'
>;

type Props = DomainFiltersProps<PastPaymentsFiltersParams>;

const paymentStatusFilterOptions = tiktokPostPaypalPaymentStatusOptions.filter((o) => o.value !== 'UNPAID');

const PastPaymentsFilters = ({ value, onChange }: Props) => {
    const filters: Filter<keyof PastPaymentsFiltersParams>[] = [
        {
            name: 'paypal_payment_status',
            label: 'Payment Status',
            icon: StatusIcon,
            render(this, helpers) {
                const Icon = this.icon;
                const selectValue = paymentStatusFilterOptions.find(
                    (o) => o.value === (value.paypal_payment_status || 'PAID')
                );

                return (
                    <SelectFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        name={this.label}
                        icon={<Icon className={styles.icon} />}
                        options={paymentStatusFilterOptions}
                        value={selectValue}
                        onChange={(val) => onChange({ paypal_payment_status: val?.value })}
                        onClear={() => onChange({ paypal_payment_status: undefined })}
                        onRemove={() => {
                            onChange({ paypal_payment_status: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'tiktok_influencer_user_id',
            label: 'TikTok Creator',
            icon: UserIcon,
            render(this, helpers) {
                const Icon = this.icon;

                return (
                    <TiktokInfluencerFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        name={this.label}
                        icon={<Icon className={styles.icon} />}
                        initialInfluencerUserId={value.tiktok_influencer_user_id}
                        onChange={(val) => onChange({ tiktok_influencer_user_id: val?.value })}
                        onClear={() => onChange({ tiktok_influencer_user_id: undefined })}
                        onRemove={() => {
                            onChange({ tiktok_influencer_user_id: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
        {
            name: 'instagram_influencer_user_id',
            label: 'Instagram Creator',
            icon: UserIcon,
            render(this, helpers) {
                const Icon = this.icon;

                return (
                    <InstagramInfluencerUsersFilter
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        name={this.label}
                        icon={<Icon className={styles.icon} />}
                        initialInfluencerUserId={value.instagram_influencer_user_id}
                        onChange={(val) => onChange({ instagram_influencer_user_id: val?.value })}
                        onClear={() => onChange({ instagram_influencer_user_id: undefined })}
                        onRemove={() => {
                            onChange({ instagram_influencer_user_id: undefined });
                            helpers.remove();
                        }}
                    />
                );
            },
        },
    ];

    return (
        <Filters
            sessionKey="round-past-payments-filters"
            filters={filters}
            options={{ initialFilters: (state) => getInitialFilters(state, getNonEmptyKeys(value)) }}
        />
    );
};

export default PastPaymentsFilters;
