import React from 'react';
import InstagramAudioDetails, { InstagramAudioDetailsProps } from './InstagramAudioDetails';
import ModalRight from '../../../SharedComponents/ModalRight/ModalRight';
import { AudioPlayerProvider } from '../../AudioPlayer/AudioPlayerContext';
import styles from './InstagramAudioDetails.module.css';
import CloseIcon from '../../../SharedComponents/svg/Icons/CloseIcon';

type Props = {
    isModalOpen: boolean;
    closeModal: () => void;
} & InstagramAudioDetailsProps;

const InstagramAudioModal = ({ isModalOpen, closeModal, ...props }: Props) => {
    return (
        <ModalRight className={styles.modal} isModalOpen={isModalOpen} closeModal={closeModal}>
            <div className={styles.modalContent}>
                <button className={styles.closeButton} title="close modal" onClick={closeModal}>
                    <CloseIcon width={16} height={16} />
                </button>
                <AudioPlayerProvider>
                    <InstagramAudioDetails key={props.audioId} {...props} />
                </AudioPlayerProvider>
            </div>
        </ModalRight>
    );
};

export default InstagramAudioModal;
