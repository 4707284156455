import { DropzoneOptions, FileError, FileRejection, useDropzone } from 'react-dropzone';
import styles from './ImageUpload.module.css';
import cn from 'classnames';
import { ReactComponent as CameraIcon } from 'assets/whitelabel/CameraAdd.svg';
import { Image } from '@round/ui-kit';
import { ReactNode } from 'react';

export type FileUploadProps = {
    file: File | null;
    onUpload: (file: File) => void;
    onReject?: (reasons: FileError[]) => void;
    options?: Pick<DropzoneOptions, 'maxSize' | 'minSize' | 'accept'>;
    name?: string;
    className?: string;
    renderIcon?: () => ReactNode;
};

const defaultIcon = () => <CameraIcon className={styles.icon} />;

const ImageUpload = ({
    name,
    file,
    onUpload: onUploadProp,
    onReject,
    options,
    className,
    renderIcon = defaultIcon,
}: FileUploadProps) => {
    const onUpload = (files: File[]) => onUploadProp(files[0]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onUpload,
        onDropRejected: onReject
            ? (fileRejections: FileRejection[]) => {
                  const rejection = fileRejections[0];
                  onReject(rejection.errors);
              }
            : undefined,
        ...options,
    });

    return (
        <div className={cn(styles.container, className)} {...getRootProps()}>
            {file ? (
                <Image src={URL.createObjectURL(file)} alt="Preview" className={styles.imagePreview} />
            ) : (
                renderIcon()
            )}

            <input className={styles.hidden} type="file" name={name} hidden {...getInputProps()} />
        </div>
    );
};

export default ImageUpload;
