import { AppServer, RouteHandlerContext } from '../../mirage';
import { belongsTo, Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Client, ClientSimple } from './client.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    client: Model.extend({
        facebook_ad_account: belongsTo('facebookAdAccount'),
    }) as ModelDefinition<Client>,
    clientSimple: Model as ModelDefinition<ClientSimple>,
};

export const factories = {
    client: createFactory<Client>({
        name(index: number) {
            return `Client name: ${index}`;
        },
        media_commission_multiplier() {
            return '0.000';
        },
        instagram_influencer_commission_multiplier() {
            return '0.000';
        },
        tiktok_influencer_commission_multiplier() {
            return '0.000';
        },
    }),
    clientSimple: createFactory<ClientSimple>({
        name(index: number) {
            return `Client name: ${index}`;
        },
    }),
};

export const serializers = {
    client: makeSerializer<Client>(['facebook_ad_account']),
    clientSimple: makeSerializer<ClientSimple>([]),
};

export function handleClientRequests(server: AppServer) {
    const getAllClientsPath = '/api/advertising/viewsets/client/';
    server.get(getAllClientsPath, function (this: RouteHandlerContext, schema, request) {
        const clients = schema.all('clientSimple');
        return buildPaginatedResponse(clients, {
            url: getAllClientsPath,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'clientSimple'),
        });
    });
}
