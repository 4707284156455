import React from 'react';
import cn from 'classnames';
import styles from './Label.module.css';

type LabelProps = {
    htmlFor: string;
    className?: string;
    children?: React.ReactNode | undefined;
};

const Label = ({ children, htmlFor, className }: LabelProps) => (
    <label className={cn(styles.label, className)} htmlFor={htmlFor}>
        {children}
    </label>
);

export default Label;
