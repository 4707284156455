import { fetchWithToken } from '../../helpers';
import { ApiResponse, ApiResponseError } from '../../types';
import { PaymentRequestStatus } from '../paymentRequests';
import { TiktokPostPaymentRequest } from './tiktokPostPaymentRequests.types';

export async function patchTiktokPostPaymentRequestStatus(
    id: number,
    status: PaymentRequestStatus,
    requestInit?: RequestInit
): Promise<ApiResponse<TiktokPostPaymentRequest, 200> | ApiResponse<ApiResponseError<{ status: string }>, 400>> {
    const response = await fetchWithToken(`/api/microwave/tiktok-post-payment-requests/${id}/`, {
        ...requestInit,
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to patch TiktokPostPaymentRequestStatus');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
