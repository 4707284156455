import { fetchWithToken } from '../../helpers';
import { User } from './user.types';

export async function getCurrentUser(): Promise<User> {
    const response = await fetchWithToken('/api/users/current-user/');
    if (!response.ok) {
        throw new Error('Could not fetch user');
    }

    return response.json();
}
