import { ReactNode } from 'react';
import styles from './Badge.module.css';
import cn from 'classnames';

type Props = {
    children: ReactNode;
    className?: string;
};

const Badge = ({ children, className }: Props) => {
    return <span className={cn(styles.badge, className)}>{children}</span>;
};

export default Badge;
