import React, { useCallback, useMemo, useState } from 'react';
import useAbortableEffect from '../../../../../../Hooks/useAbortableEffect';
import { Formik, FieldProps } from 'formik';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import { Form, Field } from 'formik';
import FormField from '../../../../../../ui/DataEntry/FormField/FormField';
import Label from '../../../../../../ui/DataEntry/Label/Label';
import Button from '../../../../../../ui/Buttons/Button/Button';
import { usePaginatedFetch, getXeroInvoice, getXeroReceivableInvoices } from '@round/api';
import styles from './SelectInvoiceModal.module.css';
import { debounce } from 'lodash';
import OptionWithSubLabel, {
    DropdownOptionWithSubLabel,
} from '../../../../../../ui/DataEntry/Select/OptionWithSubLabel/OptionWithSubLabel';
import Select from '../../../../../../ui/DataEntry/Select/Select';

type SelectInvoiceModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    onSelected: (invoiceId: number | null) => void;
    initialInvoiceId: number | null;
};

type FormValues = {
    xero_invoice_id: DropdownOptionWithSubLabel<number> | null;
};

const SelectInvoiceModal = ({ isModalOpen, closeModal, onSelected, initialInvoiceId }: SelectInvoiceModalProps) => {
    const [invoicesPage, setInvoicesPage] = useState(1);
    const [invoicesSearch, setInvoicesSearch] = useState('');
    const {
        data: invoices,
        getCurrentPage: getCurrentInvoicesPage,
        hasNextPage: hasNextInvoicesPage,
        loading: invoicesLoading,
    } = usePaginatedFetch(getXeroReceivableInvoices, {
        page: invoicesPage,
        search: invoicesSearch,
    });

    const [initialInvoiceOption, setInitialInvoiceOption] = useState<DropdownOptionWithSubLabel<number> | null>(null);
    const [initialInvoiceLoading, setInitialInvoiceLoading] = useState(false);

    const fetchInvoices = useMemo(() => debounce(getCurrentInvoicesPage, 700), [getCurrentInvoicesPage]);

    const invoiceOptions: DropdownOptionWithSubLabel<number>[] = useMemo(
        () =>
            invoices.map((i) => ({
                value: i.id,
                label: i.invoice_number,
                subLabel: i.reference,
            })),
        [invoices]
    );

    useAbortableEffect(
        (signal) => {
            fetchInvoices({ signal });
        },
        [fetchInvoices]
    );

    useAbortableEffect(
        (signal) => {
            async function fetchInitialInvoice() {
                if (!initialInvoiceId) {
                    setInitialInvoiceOption(null);
                    return;
                }
                try {
                    setInitialInvoiceLoading(true);
                    const response = await getXeroInvoice(initialInvoiceId, { signal });
                    if (response.status === 404) {
                        return;
                    }
                    const invoice = response.data;
                    setInitialInvoiceOption({
                        value: invoice.id,
                        label: invoice.invoice_number,
                        subLabel: invoice.reference,
                    });
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }
                } finally {
                    setInitialInvoiceLoading(false);
                }
            }
            fetchInitialInvoice();
        },
        [initialInvoiceId]
    );

    const initialValues: FormValues = useMemo(
        () => ({
            xero_invoice_id: initialInvoiceOption,
        }),
        [initialInvoiceOption]
    );

    const handleSubmit = useCallback(
        async (values: FormValues) => {
            if (values.xero_invoice_id) {
                onSelected(values.xero_invoice_id.value);
            } else {
                onSelected(null);
            }
            closeModal();
        },
        [onSelected, closeModal]
    );

    return (
        <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={closeModal}>
            <ModalTitle>Select Invoice</ModalTitle>

            <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                {({ isSubmitting, submitForm }) => (
                    <>
                        <ModalContent className={styles.modal}>
                            <Form>
                                <FormField className={styles.field}>
                                    <Label htmlFor="xero_invoice_id">Invoice</Label>
                                    <Field name="xero_invoice_id">
                                        {(props: FieldProps) => (
                                            <Select
                                                isClearable
                                                components={{ Option: OptionWithSubLabel }}
                                                inputValue={invoicesSearch}
                                                onInputChange={(search: string) => {
                                                    setInvoicesSearch(search);
                                                    setInvoicesPage(1);
                                                }}
                                                filterOption={null}
                                                isLoading={invoicesLoading || initialInvoiceLoading}
                                                options={invoiceOptions}
                                                formatOptionLabel={(option, { context }) =>
                                                    context === 'value'
                                                        ? `${option.label} - ${option.subLabel}`
                                                        : option.label
                                                }
                                                onChange={(option) => {
                                                    props.form.setFieldValue(props.field.name, option);
                                                }}
                                                value={props.field.value}
                                                onMenuScrollToBottom={() => {
                                                    if (hasNextInvoicesPage) {
                                                        setInvoicesPage((page) => page + 1);
                                                    }
                                                }}
                                            />
                                        )}
                                    </Field>
                                </FormField>
                            </Form>
                        </ModalContent>
                        <ModalFooter>
                            <Button
                                className={styles.submit}
                                type="filled"
                                color="black"
                                loading={isSubmitting}
                                onClick={() => submitForm()}
                            >
                                Select Invoice
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default SelectInvoiceModal;
