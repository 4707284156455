import styles from '../PaymentModal.module.css';
import { useEffect, useState } from 'react';
import { OutstandingPaymentsTableRow } from '../../OutstandingPayments/helpers';
import { microwave } from '@round/api';
import PaymentsTable from './PaymentsTable/PaymentsTable';
import FullScreenModal from 'ui/FullScreenModal/FullScreenModal';
import Button from 'ui/Button/Button';
import { RequestStatus, getSuccessfulPaymentsMessage, getFailedPaymentsMessage } from '../helpers';

type Props = {
    rows: OutstandingPaymentsTableRow[];
    isOpen: boolean;
    onClose: () => void;
    makePayment: typeof microwave.makePaypalPayment;
    updatePayment: typeof microwave.patchPaymentRequest;
    onComplete: () => void;
};

const PaypalPaymentsModal = ({ rows: initialRows, isOpen, onClose, onComplete, makePayment, updatePayment }: Props) => {
    const [rows, setRows] = useState(initialRows);
    const [arePaymentRequestsLoading, setArePaymentRequestsLoading] = useState(false);
    const [areRequestsComplete, setAreRequestsComplete] = useState(false);

    const [paymentResults, setPaymentResults] = useState<{
        [key: number]: RequestStatus;
    }>({});

    useEffect(() => {
        if (isOpen) {
            setRows(Array.from(initialRows));
        } else {
            setRows([]);
        }
    }, [initialRows, isOpen]);

    const handleMakePayment = async (id: number): Promise<RequestStatus> => {
        try {
            const response = await makePayment(id);

            if (response.status === 204) {
                return {
                    success: true,
                    error: null,
                };
            }

            const errorMessage = Array.isArray(response.data)
                ? response.data.join(', ')
                : response.status === 404
                ? response.data.detail
                : "Couldn't make payment";

            return {
                success: false,
                error: errorMessage,
            };
        } catch {
            return {
                success: false,
                error: `Couldn't make payment`,
            };
        }
    };

    const handleConfirm = async () => {
        if (!rows.length) {
            return;
        }

        setArePaymentRequestsLoading(true);
        await Promise.all(
            rows.map(async (row) => {
                const result = await handleMakePayment(row.id);

                setPaymentResults((prev) => ({
                    ...prev,
                    [row.id]: result,
                }));
            })
        );

        setArePaymentRequestsLoading(false);
        setAreRequestsComplete(true);
    };

    const handleClose = () => {
        const areSomeRequestsSuccessful = Object.values(paymentResults).some((r) => r.success);
        if (areRequestsComplete && areSomeRequestsSuccessful) {
            onComplete();
        }

        setPaymentResults({});
        setAreRequestsComplete(false);
        onClose();
    };

    const handleUpdatePayment: typeof updatePayment = async (id, data) => {
        const response = await updatePayment(id, data);

        if (response.status === 200) {
            setRows((prev) =>
                prev.map((row) => {
                    if (row.id === id) {
                        return {
                            ...row,
                            ...data,
                        };
                    }

                    return row;
                })
            );
        }

        return response;
    };

    const successfulPaymentsCount = Object.values(paymentResults).filter((r) => r.success).length;
    const failedPaymentsCount = Object.values(paymentResults).filter((r) => !r.success).length;

    const successfulPaymentsDisplayMessage = getSuccessfulPaymentsMessage(successfulPaymentsCount, rows.length);
    const failedPaymentsDisplayMessage = getFailedPaymentsMessage(failedPaymentsCount, rows.length);

    return (
        <>
            <FullScreenModal isOpen={isOpen} onClose={handleClose}>
                <FullScreenModal.Header>Payment confirmation</FullScreenModal.Header>
                <FullScreenModal.Content className={styles.content}>
                    <PaymentsTable
                        data={rows.map((row) => ({
                            ...row,
                            paymentRequestStatus: paymentResults[row.id],
                        }))}
                        updatePayment={handleUpdatePayment}
                        arePaymentsLoading={arePaymentRequestsLoading}
                        arePaymentsComplete={areRequestsComplete}
                    />
                </FullScreenModal.Content>

                <FullScreenModal.Footer>
                    <div className={styles.buttons}>
                        {areRequestsComplete ? (
                            <>
                                <div className={styles.results}>
                                    {successfulPaymentsCount > 0 && (
                                        <span className={styles.success}>{successfulPaymentsDisplayMessage}</span>
                                    )}
                                    {failedPaymentsCount > 0 && (
                                        <span className={styles.failure}>{failedPaymentsDisplayMessage}</span>
                                    )}
                                </div>
                                <Button appearance="primary" onClick={handleClose}>
                                    Close
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button appearance="ghost" onClick={handleClose} disabled={arePaymentRequestsLoading}>
                                    Cancel
                                </Button>
                                <Button
                                    appearance="primary"
                                    onClick={() => handleConfirm()}
                                    isLoading={arePaymentRequestsLoading}
                                >
                                    Confirm payment
                                </Button>
                            </>
                        )}
                    </div>
                </FullScreenModal.Footer>
            </FullScreenModal>
        </>
    );
};

export default PaypalPaymentsModal;
