import { ButtonShell, ControlledPopover } from '@round/ui-kit';
import { SongOption, useSongSelect } from 'Modules/Plans/Songs/hooks/useSongSelect';
import { useState } from 'react';
import cn from 'classnames';
import styles from './ProjectSongDropdown.module.css';
import { ReactComponent as EditIcon } from 'assets/whitelabel/Pencil.svg';
import Select from 'ui-new/whitelabel/Select/Select';
import { useProjectDetails } from 'Modules/Plans/Project/contexts/ProjectContext';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import { StylesConfig } from 'react-select';
import Button from 'ui-new/whitelabel/Button/Button';
import SongBadge from 'Modules/Plans/Songs/components/SongBadge/SongBadge';
import { showNotification } from 'helpers';

type Props = {
    songId: number | null | undefined;
    updateProject: ReturnType<typeof useProjectDetails>['updateProject'];
};

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        margin: '0 0.5rem',
    }),
    menu: (base) => ({
        ...base,
        boxShadow: 'none',
        position: 'static',
        border: 'none',
        borderTop: '1px solid #D1D5DB',
        borderBottom: '1px solid #D1D5DB',
        borderRadius: '0',
        width: '100%',
    }),
};

const ProjectSongDropdown = ({ songId, updateProject }: Props) => {
    const [targetRef, setTargetRef] = useState<HTMLButtonElement | null>(null);
    const [popoutRef, setPopoutRef] = useState<HTMLDivElement | null>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const { props: songSelectProps, resetValue } = useSongSelect({
        initOn: 'mount',
        initialValueId: songId || undefined,
    });

    const handleUpdateProjectSong = async () => {
        setIsUpdating(true);
        try {
            const response = await updateProject({
                song_id: (songSelectProps.value as SongOption | null)?.value || null,
            });

            if (response?.status === 200) {
                showNotification('Song updated', 'info');
                setIsOpen(false);
                return;
            }

            showNotification('Failed to update song', 'error');
        } catch (e) {
            showNotification('Failed to update song', 'error');
        } finally {
            setIsUpdating(false);
        }
    };

    const handleCloseWithoutSaving = () => {
        setIsOpen(false);
        if (songId !== (songSelectProps.value as SongOption | null)?.value) {
            resetValue();
        }
    };

    useOnClickOutside(popoutRef, () => handleCloseWithoutSaving());

    const addOrUpdate = !!songId ? 'Update' : 'Add';

    return (
        <>
            <ButtonShell
                ref={setTargetRef}
                onClick={() => setIsOpen((prev) => !prev)}
                className={cn(styles.targetButton, { [styles.active]: isOpen })}
            >
                <EditIcon />
            </ButtonShell>

            <ControlledPopover
                anchorElement={targetRef}
                isOpen={isOpen}
                options={{ placement: 'bottom', modifiers: [{ name: 'offset', options: { offset: [0, 8] } }] }}
                overlayClassName={styles.overlay}
            >
                <div className={styles.container} ref={setPopoutRef}>
                    <div className={styles.selectContainer}>
                        <label htmlFor="postUrl" className={styles.label}>
                            {addOrUpdate} Song
                        </label>
                        <Select
                            {...songSelectProps}
                            styles={selectStyles}
                            menuIsOpen={!songSelectProps.value || !!songSelectProps.inputValue}
                            placeholder="Search song"
                            isClearable
                            isSearchable
                            menuPortalTarget={null}
                            menuPlacement="bottom"
                            components={{ DropdownIndicator: null }}
                            formatOptionLabel={(option, meta) =>
                                meta.context === 'menu' ? (
                                    <SongBadge
                                        title={option.label}
                                        artistName={option.artistName}
                                        imageUrl={option.image}
                                        direction="column"
                                    />
                                ) : (
                                    option.label
                                )
                            }
                            isLoading={isUpdating || songSelectProps.isLoading}
                            isDisabled={isUpdating}
                        />
                    </div>
                    <div className={styles.actions}>
                        <Button
                            appearance="primary"
                            disabled={(songSelectProps.value as SongOption | null)?.value === songId}
                            onClick={() => handleUpdateProjectSong()}
                        >
                            Save
                        </Button>
                        <Button appearance="ghost" onClick={() => handleCloseWithoutSaving()}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </ControlledPopover>
        </>
    );
};

export default ProjectSongDropdown;
