import { ModelDefinition } from 'miragejs/-types';
import { InfluencerLocation } from './locations.types';
import { Model } from 'miragejs';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    influencerLocation: Model as ModelDefinition<InfluencerLocation>,
};

export const serializers = {
    influencerLocation: makeSerializer<InfluencerLocation>([]),
};

export const factories = {
    influencerLocation: createFactory<InfluencerLocation>({
        country_code: (index: number) => `CODE ${index}`,
        country_name: (index: number) => `Country ${index}`,
        city: (index: number) => `City ${index}`,
        state: (index: number) => `State ${index}`,
        type: 'city',
    }),
};

export function handleRequests(server: AppServer) {
    server.get('/api/influencer/locations/', function (this: RouteHandlerContext, schema, request) {
        const locations = schema.all('influencerLocation');
        return buildPaginatedResponse(locations, {
            url: '/api/influencer/locations/',
            serialize: (resource) => this.serialize(resource, 'influencerLocation'),
            queryParams: request.queryParams,
        });
    });
}
