import { creatorbase } from '@round/api';
import { TimeSeriesResponse } from '@round/api/dist/esm/types/src/creatorbase';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<TimeSeriesResponse>;

const initialState: State = {
    status: 'idle',
    data: null,
    error: null,
};

export function useTimeSeries() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(
        async (params: creatorbase.TimeSeriesRequestParams, signal?: AbortSignal) => {
            setState({ status: 'loading', data: null, error: null });
            try {
                const response = await creatorbase.postTimeSeries(params, signal);

                if (response.status === 200) {
                    setState({ status: 'success', data: response.data, error: null });
                    return response;
                }

                setState({ status: 'error', data: null, error: response.data.detail });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    setState(initialState);
                    return;
                }
                setState({ status: 'error', data: null, error: 'Could not get time series data' });
                throw e;
            }
        },
        [setState]
    );

    const reset = useCallback(() => {
        setState(initialState);
    }, [setState]);

    return {
        ...state,
        fetchData,
        reset,
    };
}
