import React, { FunctionComponent } from 'react';
import styles from './DebounceNumberInputBox.module.css';
import { DebounceInput } from 'react-debounce-input';

export const DebounceNumberInputBox: FunctionComponent<{
    initialValue?: number;
    setValue: (value: number) => void;
    placeholder?: string;
}> = ({ initialValue, setValue, placeholder }) => {
    return (
        <DebounceInput
            debounceTimeout={750}
            className={styles.box}
            value={initialValue}
            type="number"
            placeholder={placeholder}
            onChange={(event) => setValue(Number(event.target.value))}
        />
    );
};
