import {
    FacebookTargetingAudienceSelection,
    FacebookTargetingAudienceSelectionDto,
    FacebookTargetingAudienceSelectionOption,
    FacebookTargetingSegmentSpecs,
} from '../../../../../../../../App.types';
import { formatSnakeCaseToDisplay } from '../../../../../../../../helpers';

export function mapSelectionToOption(
    selection: FacebookTargetingAudienceSelection
): FacebookTargetingAudienceSelectionOption {
    return {
        audience_size: selection.audience_size_upper_bound,
        description: selection.description,
        label: selection.name,
        path: selection.path,
        type: selection.type,
        value: selection.id,
        typeLabel: formatSnakeCaseToDisplay(selection.type),
    };
}

function mapOptionToSelectionDto(
    option: FacebookTargetingAudienceSelectionOption
): FacebookTargetingAudienceSelectionDto {
    return {
        audience_size: option.audience_size,
        description: option.description,
        id: option.value,
        name: option.label,
        path: option.path,
        type: option.type,
    };
}

function mapSelectionDtoToOption(dto: FacebookTargetingAudienceSelectionDto): FacebookTargetingAudienceSelectionOption {
    return {
        audience_size: dto.audience_size,
        description: dto.description,
        label: dto.name,
        path: dto.path,
        type: dto.type,
        value: dto.id,
        typeLabel: formatSnakeCaseToDisplay(dto.type),
    };
}

export function mapSelectionOptionsToSpecs(
    selections: FacebookTargetingAudienceSelectionOption[]
): Partial<FacebookTargetingSegmentSpecs> | undefined {
    if (!selections.length) {
        return;
    }

    return selections.reduce<Partial<FacebookTargetingSegmentSpecs>>((specs, selection) => {
        const selections = specs[selection.type];
        const mapped = mapOptionToSelectionDto(selection);
        specs[selection.type] = Array.isArray(selections) ? [...selections, mapped] : [mapped];
        return specs;
    }, {});
}

export function mapSpecsToOptions(
    specs: FacebookTargetingSegmentSpecs | undefined
): FacebookTargetingAudienceSelectionOption[] {
    if (!specs) {
        return [];
    }

    return Object.values(specs).flat().map(mapSelectionDtoToOption);
}
