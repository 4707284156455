import { useMemo } from 'react';
import { AdvertisingContext } from '../../contexts/AdvertisingContext/AdvertisingContext';
import styles from './MobileAdvertising.module.css';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import MobileAdvertisingGroupBar from './MobileAdvertisingGroupBar/MobileAdvertisingGroupBar';
import useUrlState from '../../../../../Hooks/useUrlState';
import range from 'lodash/range';
import { Skeleton } from '@round/ui-kit';
import EmptyPlan from '../../../components/EmptyPlan/EmptyPlan';

export const MobileAdvertising = () => {
    const [urlState, setUrlState] = useUrlState<{
        expandedGroups: string;
    }>({ expandedGroups: '' });

    const expandedGroupIds = useMemo(
        () => urlState.expandedGroups?.split(',').map((groupId) => Number(groupId)) ?? [],
        [urlState.expandedGroups]
    );

    const [contextState] = useNonNullContext(AdvertisingContext);

    const sortedGroups = Array.from(contextState.groups).sort((a, b) => a.ordering_index - b.ordering_index);

    if (!contextState.initialized && !contextState.errorLoadingMediaPlanItems) {
        return (
            <div className={styles.container}>
                {range(0, 3).map(() => (
                    <Skeleton className={styles.groupSkeleton} />
                ))}
            </div>
        );
    }

    if (!contextState.mediaPlan) {
        return <EmptyPlan readOnly />;
    }

    return (
        <div className={styles.container}>
            {sortedGroups.map((group) => {
                const groupMediaPlanItems = contextState.mediaPlanItemRows.filter((mediaPlanItem) => {
                    return mediaPlanItem.group?.id === group.id;
                });

                const isExpanded = expandedGroupIds.includes(group.id);
                const onClickToggleExpanded = () => {
                    if (isExpanded) {
                        setUrlState({ expandedGroups: expandedGroupIds.filter((g) => group.id !== g).join(',') });
                        return;
                    }
                    setUrlState({ expandedGroups: expandedGroupIds.concat(group.id).join(',') });
                };

                return (
                    <MobileAdvertisingGroupBar
                        key={group.id}
                        group={group}
                        numberOfMediaPlanItems={groupMediaPlanItems.length}
                        isExpanded={isExpanded}
                        onExpandToggle={onClickToggleExpanded}
                    />
                );
            })}
        </div>
    );
};
