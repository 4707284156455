import { YouTubeInfluencerPost, youtube, YoutubeInfluencerPostGroup } from '@round/api';
import { YoutubeVideo } from '@round/api/dist/esm/types/src/youtube';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import {
    asMoney,
    displayOptionalNumericTableValue,
    numberWithCommas,
    openInNewTab,
    roundTo2Dp,
    showNotification,
} from 'helpers';
import useNonNullContext from 'Hooks/useNonNullContext';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Column } from 'react-table';
import Table from 'ui/NewTable/Table';
import { filterByVisibility } from 'utility/utility';
import styles from './YoutubeInfluencerPostsTable.module.css';
import cn from 'classnames';
import CurrencyInput from 'react-currency-input-field';
import { InfluencerPlanContext } from 'Modules/Advertising/InfluencerPlan/contexts/InfluencerPlanContext';
import { cpm } from 'Modules/Advertising/Metrics/Metrics';
import { EDITABLE_STATUSES, paymentStatusOptions } from 'Modules/Advertising/InfluencerPlan/InfluencerPlan.helpers';
import InfluencerPostStatus from 'Modules/Advertising/InfluencerPlan/components/InfluencerPostStatus/InfluencerPostStatus';
import { ReactComponent as LinkIcon } from 'assets/icons/Link.svg';
import { ProtectedByUserGroups } from 'SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import TruncatedTextCell from 'SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import useYoutubeInfluencerPosts from 'Modules/Advertising/InfluencerPlan/contexts/YoutubeCreatorsContext/hooks/useYoutubeInfluencerPosts';
import capitalize from 'lodash/capitalize';
import { Button } from '@round/ui-kit';
import { ReactComponent as PaymentIcon } from 'assets/PaymentCard.svg';
import TableSelect from 'Modules/Advertising/components/TableSelect/TableSelect';
import PaymentStatusSelectValue from 'Modules/Advertising/InfluencerPlan/components/PaymentStatusSelectValue/PaymentStatusSelectValue';

export type YoutubeInfluencerPostTableRow = YouTubeInfluencerPost & {
    video: youtube.YoutubeVideo | undefined;
};

type Props = {
    data: YoutubeInfluencerPostTableRow[];
    isLoading: boolean;
    renderTotals?: boolean;
    group: YoutubeInfluencerPostGroup | undefined;
    onRowClick: (post: YoutubeInfluencerPostTableRow) => void;
    onRequestPayment: (post: YoutubeInfluencerPostTableRow) => void;
    noDataLabel?: string;
};

const getTotalEngagements = (video: YoutubeVideo | undefined | null) => {
    if (!video?.like_count && !video?.comment_count) {
        return null;
    }

    return (video.like_count ?? 0) + (video.comment_count ?? 0);
};

const editorCells = [
    'cost',
    'client_cost',
    'estimated_views',
    'estimatedCPM',
    'CPM',
    'notes',
    'payment_request_status',
    'payment_status',
];
const editableCells = ['cost', 'estimated_views', 'notes', 'status', 'payment_request_status', 'payment_status'];

const YoutubeInfluencerPostsTable = ({
    data,
    isLoading,
    renderTotals = false,
    group,
    onRowClick,
    onRequestPayment,
    noDataLabel,
}: Props) => {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const { currencies } = useNonNullContext(OptionsContext);
    const isInfluencerEditor = useCheckUserGroupsAccess(['influencer_editor'], 'all');
    const isRoundPlanner = useCheckUserGroupsAccess(['round_planner'], 'all');
    const { updatePost } = useYoutubeInfluencerPosts();

    const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.shiftKey) {
            return;
        }

        if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.blur();
        }
    };

    const columns: Column<YoutubeInfluencerPostTableRow>[] = useMemo(
        () =>
            filterByVisibility([
                {
                    Header: 'ID',
                    accessor: 'id',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        return <>{value}</>;
                    },
                },
                {
                    Header: 'Channel',
                    accessor: 'channel_title',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        return <TruncatedTextCell>{value ?? '-'}</TruncatedTextCell>;
                    },
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    Cell: ({ value, row: { original } }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const isStatusEditable = EDITABLE_STATUSES.includes(value) && Boolean(isInfluencerEditor);
                        return (
                            <div
                                className={styles.statusContainer}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (value === 'live') {
                                        openInNewTab(original.post_url);
                                        return;
                                    }

                                    if (!isStatusEditable) {
                                        return;
                                    }
                                }}
                            >
                                <button className={cn(styles.statusButton, styles.readonly)}>
                                    <InfluencerPostStatus
                                        className={styles.status}
                                        status={value}
                                        draftExpectedBy={null}
                                    />
                                </button>
                                {value === 'live' && <LinkIcon className={styles.linkIcon} />}
                            </div>
                        );
                    },
                },
                {
                    Header: 'Cost',
                    accessor: 'cost',
                    isVisible: !!isInfluencerEditor,
                    Cell: ({
                        value,
                        row: {
                            original: { id, currency_id, payment_status },
                        },
                    }) => {
                        const [cost, setCost] = useState(value);
                        const currency = currencies.find((c) => c.id === currency_id);

                        useEffect(() => {
                            setCost(value);
                        }, [value]);

                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const submit = () => {
                            if (Number(value) !== Number(cost)) {
                                updatePost(id, { cost })
                                    .then((response) => {
                                        if (response.status === 200) {
                                            showNotification('Cost updated', 'info');
                                            return;
                                        }

                                        const message =
                                            response.status === 404 ? 'Post not found' : String(response.data.cost);
                                        showNotification(message, 'error');
                                        setCost(value);
                                    })
                                    .catch(() => {
                                        showNotification('Could not update cost', 'error');
                                        setCost(value);
                                    });
                            }
                        };

                        return (
                            <CurrencyInput
                                className={cn({
                                    [styles.invalidInputValue]: currency_id !== influencerPlan?.currency.id,
                                })}
                                prefix={currency?.symbol}
                                groupSeparator=","
                                decimalSeparator="."
                                allowDecimals={false}
                                decimalScale={0}
                                decimalsLimit={0}
                                value={cost}
                                onValueChange={(newVal) => setCost(newVal ?? value)}
                                onClick={(e) => e.stopPropagation()}
                                onKeyDown={handleEnterKeyDown}
                                onBlur={submit}
                                disabled={payment_status === 'PAID'}
                            />
                        );
                    },
                },
                {
                    Header: 'Client Cost',
                    accessor: 'client_cost',
                    isVisible: !!isInfluencerEditor,
                    Cell: ({ value, row: { original } }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const currency = currencies.find((c) => c.id === original.currency_id);
                        return <>{asMoney(value, currency)}</>;
                    },
                },
                {
                    Header: 'Payment',
                    accessor: 'payment_status',
                    isVisible: !!isInfluencerEditor,
                    Cell: ({ value, row: { original } }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        return (
                            <div onClick={(e) => e.stopPropagation()}>
                                <TableSelect
                                    options={paymentStatusOptions}
                                    value={paymentStatusOptions.find((o) => o.value === value)}
                                    onChange={(option) =>
                                        option && updatePost(original.id, { payment_status: option.value })
                                    }
                                    components={{ SingleValue: PaymentStatusSelectValue }}
                                />
                            </div>
                        );
                    },
                },
                {
                    Header: 'Payment Request',
                    accessor: 'payment_request_status',
                    isVisible: !!isRoundPlanner,
                    Cell: ({ row: { original } }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const status =
                            original.paypal_payment_status !== 'UNPAID'
                                ? original.paypal_payment_status
                                : original.payment_request_status;

                        if (!status) {
                            return <>-</>;
                        }

                        const isPaymentRequestAvailable =
                            status === 'UNREQUESTED' && original.payment_status !== 'PAID';

                        const displayStatus = status
                            .split('_')
                            .map((word) => capitalize(word.toLowerCase()))
                            .join(' ');

                        return (
                            <div onClick={(e) => e.stopPropagation()} className={styles.paymentRequestStatus}>
                                <span
                                    className={cn(styles.status, {
                                        [styles.paid]: status === 'PAID',
                                        [styles.inProgress]: status === 'IN_PROGRESS',
                                        [styles.requested]: status === 'REQUESTED',
                                        [styles.unrequested]: status === 'UNREQUESTED',
                                        [styles.failed]: status === 'FAILED',
                                    })}
                                >
                                    {displayStatus}
                                </span>
                                {isPaymentRequestAvailable && (
                                    <Button onClick={() => onRequestPayment(original)} className={styles.button}>
                                        <PaymentIcon className={styles.paymentIcon} />
                                    </Button>
                                )}
                            </div>
                        );
                    },
                },
                {
                    Header: 'Est. views',
                    accessor: 'estimated_views',
                    isVisible: !!isInfluencerEditor,
                    Cell: ({
                        value,
                        row: {
                            original: { id, estimated_views_override },
                        },
                    }) => {
                        const [estimatedViews, setEstimatedViews] = useState(estimated_views_override ?? value);

                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const submit = () => {
                            if (
                                (estimated_views_override === null && estimatedViews === value) ||
                                estimatedViews === estimated_views_override
                            ) {
                                return;
                            }

                            updatePost(id, { estimated_views_override: estimatedViews })
                                .then((response) => {
                                    if (response.status === 200) {
                                        showNotification('Est. views updated', 'info');
                                        return;
                                    }

                                    const message =
                                        response.status === 404
                                            ? 'Post not found'
                                            : String(response.data.estimated_views_override);
                                    showNotification(message, 'error');
                                    setEstimatedViews(estimated_views_override ?? value);
                                })
                                .catch(() => {
                                    showNotification('Could not update est. views', 'error');
                                    setEstimatedViews(estimated_views_override ?? value);
                                });
                        };

                        return (
                            <CurrencyInput
                                placeholder="Enter est. views"
                                groupSeparator=","
                                decimalSeparator="."
                                allowDecimals={false}
                                decimalScale={0}
                                decimalsLimit={0}
                                value={estimatedViews ?? undefined}
                                onValueChange={(value) =>
                                    setEstimatedViews(typeof value === 'undefined' ? null : Number(value))
                                }
                                onClick={(e) => e.stopPropagation()}
                                onBlur={submit}
                                onKeyDown={handleEnterKeyDown}
                            />
                        );
                    },
                },
                {
                    Header: 'Est. CPM',
                    accessor: 'estimated_views',
                    id: 'estimatedCPM',
                    isVisible: !!isInfluencerEditor,
                    Cell: ({ row: { original } }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const estimatedViews = original.estimated_views_override ?? original.estimated_views ?? 0;
                        const currency = currencies.find((c) => c.id === original.currency_id);
                        return <>{asMoney(cpm(Number(original.client_cost), estimatedViews), currency, 2)}</>;
                    },
                },
                {
                    Header: 'CPM',
                    accessor: 'video',
                    id: 'CPM',
                    isVisible: !!isInfluencerEditor,
                    Cell: ({ row: { original } }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const currency = currencies.find((c) => c.id === original.currency_id);
                        return <>{asMoney(cpm(Number(original.cost), original.video?.view_count ?? 0), currency, 2)}</>;
                    },
                },
                {
                    Header: 'Notes',
                    accessor: 'notes',
                    isVisible: !!isInfluencerEditor,
                    Cell: ({
                        value,
                        row: {
                            original: { id },
                        },
                    }) => {
                        const [notes, setNotes] = useState(value);

                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const submit = async (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
                            if (notes === value) {
                                return;
                            }

                            updatePost(id, { notes })
                                .then((response) => {
                                    if (response.status === 200) {
                                        showNotification('Notes updated', 'info');
                                        return;
                                    }

                                    const message =
                                        response.status === 404 ? 'Post not found' : String(response.data.notes);
                                    showNotification(message, 'error');
                                    setNotes(value);
                                })
                                .catch(() => {
                                    showNotification('Could not update notes', 'error');
                                    setNotes(value);
                                });

                            e.currentTarget.scrollTop = 0;
                        };

                        return (
                            <textarea
                                rows={2}
                                className={styles.notesInput}
                                placeholder="-"
                                value={notes}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => setNotes(e.target.value)}
                                onBlur={submit}
                                onKeyDown={handleEnterKeyDown}
                            />
                        );
                    },
                },
                {
                    Header: 'Upload date',
                    accessor: 'video',
                    id: 'uploadDate',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        return <>{value?.published_at ? moment(value.published_at).format('DD MMM YY') : '-'}</>;
                    },
                },
                {
                    Header: 'Views',
                    accessor: 'video',
                    id: 'views',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        return <>{value?.view_count ? numberWithCommas(value.view_count) : '-'}</>;
                    },
                },
                {
                    Header: 'Total engagements',
                    accessor: 'video',
                    id: 'totalEngagements',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const totalEngagements = getTotalEngagements(value);
                        return <>{totalEngagements ? numberWithCommas(totalEngagements) : '-'}</>;
                    },
                },
                {
                    Header: 'Engagement rate',
                    accessor: 'video',
                    id: 'engagementRate',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        const totalEngagements = getTotalEngagements(value);
                        return (
                            <>
                                {value && totalEngagements && value?.view_count
                                    ? `${roundTo2Dp((totalEngagements / value.view_count) * 100)}%`
                                    : '-'}
                            </>
                        );
                    },
                },
                {
                    Header: 'Likes',
                    accessor: 'video',
                    id: 'likes',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        return <>{value?.like_count ? numberWithCommas(value.like_count) : '-'}</>;
                    },
                },
                {
                    Header: 'Comments',
                    accessor: 'video',
                    id: 'comments',
                    Cell: ({ value }) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }

                        return <>{value?.comment_count ? numberWithCommas(value.comment_count) : '-'}</>;
                    },
                },
            ]),
        [
            currencies,
            influencerPlan?.currency.id,
            isInfluencerEditor,
            isRoundPlanner,
            onRequestPayment,
            isLoading,
            updatePost,
        ]
    );

    return (
        <Table
            tableClassName={styles.table}
            disableSortBy
            columns={columns}
            data={data}
            onRowClick={isRoundPlanner ? onRowClick : undefined}
            noDataLabel={noDataLabel}
            renderHeaderRow={(headers) => (
                <tr>
                    {headers.map((column) => (
                        <th key={column.id} className={cn({ [styles.editorColumn]: editorCells.includes(column.id) })}>
                            {column.render('Header')}
                        </th>
                    ))}
                </tr>
            )}
            renderRow={({ row, rowProps, cellProps }) => (
                <tr {...rowProps} {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                        return (
                            <td
                                {...cellProps}
                                {...cell.getCellProps()}
                                className={cn({
                                    [styles.editorCell]: editorCells.includes(cell.column.id),
                                    [styles.editableCell]: editableCells.includes(cell.column.id),
                                })}
                            >
                                {cell.render('Cell')}
                            </td>
                        );
                    })}
                </tr>
            )}
            renderFooter={() => {
                if (!renderTotals) {
                    return null;
                }

                const currencySymbol = influencerPlan?.currency.symbol;
                const posts = data;
                const cost = posts.some((row) => !!row.cost)
                    ? posts.reduce((cost, row) => Number(cost) + (row.cost ? Number(row.cost) : 0), 0)
                    : undefined;

                const clientCost = posts.some((row) => !!row.client_cost)
                    ? posts.reduce((clientCost, row) => clientCost + row.client_cost, 0)
                    : undefined;

                const estimatedViews = posts.reduce(
                    (views, row) => views + Number(row.estimated_views_override ?? row.estimated_views ?? 0),
                    0
                );

                const estimatedCPM = Number(cpm(clientCost, estimatedViews).toFixed(2));

                const livePosts = posts.filter((row) => row.status === 'live');
                const liveCost = livePosts.some((row) => !!row.cost)
                    ? livePosts.reduce((cost, row) => cost + (row.cost ? Number(row.cost) : 0), 0)
                    : undefined;
                const views = posts.some((row) => !!row.video?.view_count)
                    ? posts.reduce((plays, row) => plays + (row.video?.view_count ?? 0), 0)
                    : undefined;

                const liveCPM = Number(cpm(liveCost, views).toFixed(2));

                const likes = posts.some((row) => !!row.video?.like_count)
                    ? posts.reduce((likes, row) => likes + (row.video?.like_count ?? 0), 0)
                    : undefined;

                const comments = posts.some((row) => !!row.video?.comment_count)
                    ? posts.reduce((comments, row) => comments + (row.video?.comment_count ?? 0), 0)
                    : undefined;

                const totalEngagements =
                    typeof likes === 'number' || typeof comments === 'number'
                        ? (likes ?? 0) + (comments ?? 0)
                        : undefined;

                const engagementRate =
                    typeof totalEngagements === 'number' && typeof views === 'number'
                        ? totalEngagements / views
                        : undefined;

                const isCostOverBudget =
                    isInfluencerEditor && clientCost && group ? clientCost > parseFloat(group.budget) : undefined;

                return (
                    <tfoot>
                        <tr>
                            <td className={styles.td} colSpan={3}>
                                Total
                            </td>
                            <ProtectedByUserGroups groups={['influencer_editor']}>
                                <td className={styles.editorCell}>
                                    <CurrencyInput
                                        className={styles.costTotal}
                                        readOnly
                                        prefix={currencySymbol}
                                        value={cost}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        allowDecimals={false}
                                        decimalScale={0}
                                        decimalsLimit={0}
                                    />
                                </td>

                                <td
                                    className={cn(styles.editorCell, {
                                        [styles.negativeCell]: isCostOverBudget === true,
                                        [styles.positiveCell]: isCostOverBudget === false,
                                    })}
                                >
                                    {currencySymbol}
                                    {numberWithCommas(roundTo2Dp(clientCost ?? 0))}
                                </td>

                                <td />

                                {!!isRoundPlanner && <td />}

                                <td className={styles.editorCell}>
                                    {displayOptionalNumericTableValue(estimatedViews)}
                                </td>
                                <td className={styles.editorCell}>
                                    {currencySymbol}
                                    {displayOptionalNumericTableValue(estimatedCPM)}
                                </td>
                                <td className={styles.editorCell}>
                                    {currencySymbol}
                                    {displayOptionalNumericTableValue(liveCPM)}
                                </td>

                                <td />
                            </ProtectedByUserGroups>

                            <td />

                            <td>{typeof views === 'number' ? numberWithCommas(views) : '-'}</td>
                            <td>{typeof totalEngagements === 'number' ? numberWithCommas(totalEngagements) : '-'}</td>
                            <td>
                                {typeof engagementRate === 'number' && isFinite(engagementRate)
                                    ? `${roundTo2Dp(engagementRate * 100)}%`
                                    : '-'}
                            </td>
                            <td>{typeof likes === 'number' ? numberWithCommas(likes) : '-'}</td>
                            <td>{typeof comments === 'number' ? numberWithCommas(comments) : '-'}</td>
                        </tr>
                    </tfoot>
                );
            }}
        />
    );
};

export default YoutubeInfluencerPostsTable;
