import { Currency, creatorbase } from '@round/api';
import { useState } from 'react';
import { showNotification } from 'helpers';
import TableCurrencyInput from 'ui-new/whitelabel/TableInput/TableCurrencyInput';

type Props = {
    cost: number | null;
    currency: Currency | null;
    isReadonly?: boolean;
    updateCost?: (cost: number | null) => ReturnType<typeof creatorbase.patchPost>;
};

const formatCost = (cost: number | null) => (cost === null ? '-' : cost.toString());

const CostCell = ({ cost: initialCost, currency, isReadonly, updateCost }: Props) => {
    const [isFocused, setIsFocused] = useState(false);

    const [cost, setCost] = useState<string>(formatCost(initialCost));
    const [isCostUpdating, setIsCostUpdating] = useState(false);

    const handleUpdateCost = async () => {
        const costAsNumberOrNull = cost === '' || cost === '-' || cost === undefined ? null : parseFloat(cost);
        if (!updateCost || costAsNumberOrNull === initialCost) {
            return;
        }

        let errorMessage = 'Could not update cost';

        try {
            setIsCostUpdating(true);

            if (typeof costAsNumberOrNull === 'number' && (isNaN(costAsNumberOrNull) || costAsNumberOrNull < 0)) {
                showNotification('Cost must be a valid number', 'error');
                setCost(formatCost(initialCost));
                return;
            }

            const response = await updateCost(costAsNumberOrNull);

            if (response.status === 200) {
                setCost(formatCost(response.data.cost));
                showNotification('Cost updated', 'info');
                return;
            }

            if (response.status === 400 && 'cost' in response.data) {
                const apiErrorMessage = Array.isArray(response.data.cost)
                    ? response.data.cost.join(', ')
                    : response.data.cost;
                errorMessage = apiErrorMessage || errorMessage;
            }

            showNotification(errorMessage, 'error');
            setCost(formatCost(initialCost));
        } catch {
            showNotification(errorMessage, 'error');
            setCost(formatCost(initialCost));
        } finally {
            setIsCostUpdating(false);
        }
    };

    const showPrefix = (isReadonly || !isFocused) && cost !== '-';

    return (
        <TableCurrencyInput
            prefix={showPrefix ? currency?.symbol : undefined}
            value={cost}
            onValueChange={(value) => {
                setCost(value || '');
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    e.currentTarget.blur();
                }
            }}
            onFocus={() => {
                setIsFocused(true);
            }}
            onBlur={() => {
                if (cost === '' || cost === undefined) {
                    setCost('-');
                }
                handleUpdateCost();
                setIsFocused(false);
            }}
            readOnly={isReadonly}
            disabled={isCostUpdating}
        />
    );
};

export default CostCell;
