import React from 'react';
import useMicrowaveInfluencersSelect, { MicrowaveInfluencerOption } from '../../../useMicrowaveInfluencersSelect';
import { StylesConfig, ValueType } from 'react-select';
import styles from './MicrowaveInfluencerFilter.module.css';
import { BaseFilterObject } from 'App.types';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import MicrowaveInfluencerOptionLabel from './components/OptionLabel/MicrowaveInfluencerOptionLabel';

type Props = {
    initialInfluencerId?: number;
    onChange: (value: ValueType<MicrowaveInfluencerOption, false>) => void;
    onClear: () => void;
    onRemove: () => void;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
} & Pick<BaseFilterObject, 'name' | 'icon'>;

const selectStyles: StylesConfig = {
    option: (base) => ({
        ...base,
        padding: '0.3125rem 0',
        marginTop: '0.3125rem',
    }),
};

const MicrowaveInfluencerFilter = ({
    initialInfluencerId,
    onRemove,
    onChange,
    onClear,
    name,
    icon,
    isOpen,
    onOpen,
    onClose,
}: Props) => {
    const selectProps = useMicrowaveInfluencersSelect(initialInfluencerId);

    const clearState = () => {
        selectProps.onChange?.(null, { action: 'clear' });
    };

    return (
        <SelectFilter
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            name={name}
            icon={icon}
            renderValue={(value, props) => {
                if (!value) {
                    return 'Account: none';
                }
                return (
                    <>
                        <span className={styles.countBadge}>{1}</span>
                        <span>{props.name}</span>
                    </>
                );
            }}
            styles={selectStyles}
            onClear={() => {
                clearState();
                onClear();
            }}
            onRemove={() => {
                clearState();
                onRemove();
            }}
            {...selectProps}
            onChange={(value, action) => {
                selectProps.onChange?.(value, action);
                onChange(value);
            }}
            formatOptionLabel={(option, { context }) => {
                if (context === 'value') {
                    return null;
                }
                return <MicrowaveInfluencerOptionLabel {...option} context={context} />;
            }}
        />
    );
};

export default MicrowaveInfluencerFilter;
