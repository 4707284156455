import { useEffect, useState } from 'react';

const MOBILE_CREATORS_SCREEN_WIDTH_BREAKPOINT = 768;

const isWidthMobile = (width: number) => width <= MOBILE_CREATORS_SCREEN_WIDTH_BREAKPOINT;

export function useIsViewportMobileDimensions() {
    const [isCurrentViewMobile, setIsCurrentViewMobile] = useState(isWidthMobile(window.innerWidth));

    useEffect(() => {
        function handleResize() {
            const isNewViewMobile = isWidthMobile(window.innerWidth);
            if (isNewViewMobile === isCurrentViewMobile) {
                return;
            }
            setIsCurrentViewMobile(isNewViewMobile);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isCurrentViewMobile]);

    return isCurrentViewMobile;
}
