import { SortingRule } from 'react-table';
import { useMemo } from 'react';

export type OrderByParam<T extends Record<string, any>> = {
    order_by_asc?: keyof T;
    order_by_desc?: keyof T;
};

export function useReactTableSortToOrderBy<T extends Record<string, any>>(
    sortBy: Array<SortingRule<T>>
): OrderByParam<T> {
    return useMemo(() => {
        if (!sortBy.length) {
            return {};
        }

        const first = sortBy[0];
        const ascOrDesc = first.desc ? 'desc' : 'asc';
        return {
            [`order_by_${ascOrDesc}`]: first.id,
        };
    }, [sortBy]);
}

export function useOrderByToReactTableSortBy<T extends Record<string, any>>(
    orderByParam: OrderByParam<T>
): Array<SortingRule<T>> {
    return useMemo<Array<SortingRule<T>>>(() => {
        const orderByDesc = orderByParam['order_by_desc'];
        const orderByAsc = orderByParam['order_by_asc'];

        if (orderByDesc) {
            return [{ id: orderByDesc as string, desc: true }];
        }

        if (orderByAsc) {
            return [{ id: orderByAsc as string, desc: false }];
        }

        return [];
    }, [orderByParam]);
}
