import React from 'react';
import { ReactComponent as ArrowIcon } from '../../../../../assets/ArrowIcon.svg';
import styles from './ExpandBar.module.css';
import cn from 'classnames';

type ExpandBarProps = {
    isOpen: boolean;
    children: React.ReactNode;
    title: string;
    onExpandClicked: () => void;
};

const ExpandBar = ({ isOpen, children, title, onExpandClicked }: ExpandBarProps) => {
    return (
        <>
            <div className={styles.bar}>
                <button className={styles.button} onClick={onExpandClicked}>
                    <ArrowIcon className={cn(styles.expandIcon, { [styles.open]: isOpen })} width={14} height={14} />
                </button>

                <p className={styles.title}>{title}</p>
            </div>
            {isOpen && children}
        </>
    );
};

export default ExpandBar;
