import styles from './WhiteLabelPublicLayout.module.css';
import { FCWithChildren } from 'utility/utility.types';
import ErrorBoundary from 'utility/ErrorBoundary/ErrorBoundary';
import ErrorFallback from 'ui-new/whitelabel/ErrorFallback/ErrorFallback';

const WhiteLabelPublicLayout: FCWithChildren = ({ children }) => {
    return (
        <div className={styles.container}>
            <ErrorBoundary fallbackRender={ErrorFallback}>{children}</ErrorBoundary>
        </div>
    );
};

export default WhiteLabelPublicLayout;
