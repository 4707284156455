import { ApiResponse, NotFoundResponse, OrderingValues, PaginatedApiResponseData } from '../../../types';
import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../../helpers';
import { InstagramCampaignPost, InstagramCampaignStats } from './instagramCampaigns.types';
import { PaymentRequestStatus } from '../../paymentRequests';

type GetInstagramCampaignStatsResponse = ApiResponse<InstagramCampaignStats, 200> | NotFoundResponse;

export async function getInstagramCampaignStats(
    campaignId: number,
    requestInit?: RequestInit
): Promise<GetInstagramCampaignStatsResponse> {
    const response = await fetchWithToken(`/api/microwave/instagram-campaigns/${campaignId}/stats/`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not fetch stats');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetInstagramCampaignPostsParams = {
    campaignId: number;
} & Partial<{
    payment_request_status: PaymentRequestStatus;
    ordering?: OrderingValues<keyof Pick<InstagramCampaignPost, 'reel_views'>>;
}>;

type GetInstagramCampaignPostsResponse =
    | ApiResponse<PaginatedApiResponseData<InstagramCampaignPost>, 200>
    | NotFoundResponse;

export async function getInstagramCampaignPosts(
    { campaignId, ...params }: GetInstagramCampaignPostsParams,
    requestInit?: RequestInit
): Promise<GetInstagramCampaignPostsResponse> {
    const response = await fetchWithToken(
        `/api/microwave/instagram-campaigns/${campaignId}/posts/${encodeUrlSearchParams({ ...params })}`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not fetch posts');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getAllInstagramCampaignPosts(
    { campaignId, ...params }: GetInstagramCampaignPostsParams,
    requestInit?: RequestInit
) {
    return fetchAll<InstagramCampaignPost>(
        `/api/microwave/instagram-campaigns/${campaignId}/posts/${encodeUrlSearchParams({ ...params })}`,
        requestInit
    );
}
