import React, { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styles from './ModalBase.module.css';
import cn from 'classnames';

export type ModalBaseProps = {
    isOpen: boolean;
    closeOnOverlayClick?: boolean;
    closeOnEscape?: boolean;
    onClose: () => void;
    className?: string;
    overlayClassName?: string;
    ariaLabel?: string;
    children?: ReactNode | undefined;
};

const ModalBase = ({
    isOpen,
    closeOnOverlayClick,
    closeOnEscape,
    onClose,
    children,
    className,
    overlayClassName,
    ariaLabel,
}: ModalBaseProps) => {
    const [overlayRef, setOverlayRef] = React.useState<HTMLDivElement | null>(null);

    const onOverlayClick = (e: React.MouseEvent) => {
        if (!closeOnOverlayClick || e.target !== overlayRef) {
            return;
        }

        e.stopPropagation();
        e.preventDefault();
        onClose();
    };

    useEffect(() => {
        if (!closeOnEscape) {
            return;
        }

        const onEscapePressed = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', onEscapePressed);
        return () => {
            document.removeEventListener('keydown', onEscapePressed);
        };
    }, [closeOnEscape, onClose]);

    if (!isOpen) {
        return null;
    }

    return createPortal(
        <div
            className={cn(styles.overlay, overlayClassName)}
            ref={setOverlayRef}
            onClick={onOverlayClick}
            aria-label={ariaLabel}
            role="dialog"
        >
            <div className={className}>{children}</div>
        </div>,
        document.body
    );
};

export default ModalBase;
