import { GetInfluencerPlansParams, getInfluencerPlans, PaginatedApiResponseData, InfluencerPlan } from '@round/api';
import { useCallback, useState } from 'react';

type Params = GetInfluencerPlansParams;

type Data = Pick<PaginatedApiResponseData<InfluencerPlan>, 'results' | 'count'>;
type State =
    | { status: 'not-initialized'; data: null; error: null }
    | { status: 'loading'; data: Data | null; error: null }
    | { status: 'initialized'; data: Data; error: null }
    | { status: 'error'; data: Data | null; error: string };

const initialState: State = {
    status: 'not-initialized',
    data: null,
    error: null,
};

export default function useInfluencerPlans() {
    const [state, setState] = useState(initialState);

    const fetchData = useCallback(async (params: Params, requestInit?: RequestInit) => {
        try {
            setState((prev) => ({ status: 'loading', error: null, data: prev.data }));
            const response = await getInfluencerPlans(params, requestInit);
            if (response.status === 404) {
                setState((prev) => ({ status: 'error', data: prev.data, error: response.data.detail }));
                return response;
            }

            setState((prev) => {
                const prevPlans = prev.data?.results ?? [];
                return {
                    status: 'initialized',
                    data: {
                        count: response.data.count,
                        results: prevPlans.concat(response.data.results),
                    },
                    error: null,
                };
            });

            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            setState((prev) => ({ status: 'error', data: prev.data, error: 'Could not fetch plans' }));
            throw e;
        }
    }, []);

    const reset = () => setState(initialState);

    return {
        ...state,
        fetchData,
        reset,
    };
}
