import {
    getPostPaymentRequests,
    makePayPalPayment,
    GetPostPaymentRequestsParams,
    PaginatedApiResponseData,
    PostPaymentRequest,
    patchPostPaymentRequest,
    PatchPostPaymentRequestData,
    MakePaymentData,
    makeManualPayPalPayment,
} from '@round/api';
import { useCallback, useState } from 'react';

type Params = GetPostPaymentRequestsParams;

type State =
    | {
          data: null;
          status: 'not-initialized';
          error: null;
      }
    | {
          data: null;
          status: 'loading';
          error: null;
      }
    | {
          data: Pick<PaginatedApiResponseData<PostPaymentRequest>, 'results' | 'count'>;
          status: 'initialized';
          error: null;
      }
    | {
          data: null;
          status: 'error';
          error: string;
      };

const initialState: State = {
    status: 'not-initialized',
    data: null,
    error: null,
};

export default function usePostPaymentRequests() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (params: Params, requestInit?: RequestInit) => {
        try {
            setState({ status: 'loading', data: null, error: null });
            const response = await getPostPaymentRequests(params, requestInit);
            if (response.status === 404) {
                setState({ status: 'error', error: response.data.detail, data: null });
                return response;
            }

            setState({
                status: 'initialized',
                data: {
                    count: response.data.count,
                    results: response.data.results,
                },
                error: null,
            });

            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            setState({ status: 'error', error: 'Could not fetch payment requests', data: null });
            throw e;
        }
    }, []);

    const reset = () => setState(initialState);

    const update = useCallback(async (id: number, data: Partial<PatchPostPaymentRequestData>) => {
        const response = await patchPostPaymentRequest(id, data);
        if (response.status === 200) {
            setState((prev) => {
                if (prev.status === 'initialized') {
                    return {
                        ...prev,
                        data: {
                            count: prev.data.count,
                            results: prev.data.results.map((req) =>
                                req.id === response.data.id ? response.data : req
                            ),
                        },
                    };
                }

                return prev;
            });
        }

        return response;
    }, []);

    const payPayPal = useCallback(async (data: MakePaymentData) => {
        return makePayPalPayment(data);
    }, []);

    const payPayPalManually = useCallback(async (data: MakePaymentData) => {
        return makeManualPayPalPayment(data);
    }, []);

    const remove = useCallback((paymentRequestId: number) => {
        setState((state) => {
            if (state.status === 'initialized') {
                return {
                    ...state,
                    data: {
                        count: state.data.count - 1,
                        results: state.data.results.filter((req) => req.id !== paymentRequestId),
                    },
                };
            }

            return state;
        });
    }, []);

    return {
        ...state,
        fetchData,
        reset,
        update,
        remove,
        payPayPal,
        payPayPalManually,
    };
}
