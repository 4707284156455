import { ApiResponse, PaginatedApiResponseData, Primitives } from './types';
import { useCallback, useEffect, useMemo, useState } from 'react';

type BaseOptions = Record<string, Primitives>;
type PaginatedFetchFunctionResponse<SuccessResponseBody extends PaginatedApiResponseData<any>> =
    | ApiResponse<SuccessResponseBody, 200>
    | ApiResponse<any>;

export type PaginatedFetchFunction<
    Options extends BaseOptions,
    Response extends PaginatedFetchFunctionResponse<PaginatedApiResponseData<any>>
> = (options: Partial<Options>, requestInit?: RequestInit) => Promise<Response>;

export type InferPaginatedFetchFunctionResponse<
    Function extends PaginatedFetchFunction<any, any>
> = ReturnType<Function> extends Promise<infer Response> ? Response : never;

export type InferPaginatedFetchFunctionModel<
    Function extends PaginatedFetchFunction<any, any>
> = InferPaginatedFetchFunctionResponse<Function> extends
    | ApiResponse<PaginatedApiResponseData<infer T>, 200>
    | ApiResponse<any>
    ? T
    : never;

export type InferPaginatedFetchFunctionErrorResponse<
    Function extends PaginatedFetchFunction<any, any>
> = InferPaginatedFetchFunctionResponse<Function> extends
    | ApiResponse<PaginatedApiResponseData<any>, 200>
    | ApiResponse<infer E>
    ? E extends PaginatedApiResponseData<any>
        ? null
        : E
    : unknown;

export type UsePaginatedFetchResult<Function extends PaginatedFetchFunction<any, any>> = {
    data: InferPaginatedFetchFunctionModel<Function>[];
    page: InferPaginatedFetchFunctionModel<Function>[];
    loading: boolean;
    count: number;
    error: InferPaginatedFetchFunctionErrorResponse<Function> | null;
    hasNextPage: boolean;
    hasPrevPage: boolean;
    getCurrentPage: (requestInit?: RequestInit) => Promise<void>;
    reset: () => void;
    response: InferPaginatedFetchFunctionResponse<Function> | null;
};

export function usePaginatedFetch<Function extends PaginatedFetchFunction<any, any>>(
    fetchFunction: Function,
    options: Partial<Parameters<Function>[0]> = {}
): UsePaginatedFetchResult<Function> {
    const [nextUrl, setNextUrl] = useState<string | null>(null);
    const [prevUrl, setPrevUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<UsePaginatedFetchResult<Function>['data']>([]);
    const [itemsCount, setItemsCount] = useState(0);
    const [currentPageItems, setCurrentPageItems] = useState<UsePaginatedFetchResult<Function>['page']>([]);
    const [error, setError] = useState<UsePaginatedFetchResult<Function>['error']>(null);
    const [response, setResponse] = useState<UsePaginatedFetchResult<Function>['response'] | null>(null);

    const getCurrentPage = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                setLoading(true);
                setError(null);

                const response = await fetchFunction(options, requestInit);
                setResponse(response);
                if (response.status === 200) {
                    setNextUrl(response.data.next);
                    setPrevUrl(response.data.previous);
                    setItemsCount(response.data.count);
                    setCurrentPageItems(response.data.results);
                    setItems((items) => items.concat(response.data.results));
                    return;
                }

                setNextUrl(null);
                setPrevUrl(null);
                setItemsCount(0);
                setCurrentPageItems([]);
                setError(response.data);
            } catch (e) {
                if (e.name === 'AbortError') {
                    return;
                }

                throw e;
            } finally {
                setLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetchFunction, JSON.stringify(options)]
    );

    const reset = useCallback(() => {
        setNextUrl(null);
        setPrevUrl(null);
        setItems([]);
        setItemsCount(0);
        setCurrentPageItems([]);
        setError(null);
        setLoading(false);
    }, []);

    useEffect(() => {
        if (typeof options.search === 'string') {
            reset();
        }
    }, [options.search, reset]);

    return useMemo(
        () => ({
            count: itemsCount,
            page: currentPageItems,
            loading: loading,
            data: items,
            error: error,
            getCurrentPage,
            hasNextPage: typeof nextUrl === 'string' && nextUrl.length > 0,
            hasPrevPage: typeof prevUrl === 'string' && prevUrl.length > 0,
            reset,
            response,
        }),
        [itemsCount, currentPageItems, loading, items, error, getCurrentPage, nextUrl, prevUrl, reset, response]
    );
}
