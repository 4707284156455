import styles from './Dropdown.module.css';
import { ControlledPopover } from '@round/ui-kit';
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import { useRectObserver } from 'Hooks/useRectObserver';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    control: React.ReactNode;
    children: React.ReactNode;
    className?: string;
};

const Dropdown = ({ isOpen, onClose, control, children, className }: Props) => {
    const [controlRef, setControlRef] = useState<HTMLDivElement | null>(null);
    const [popoverContainer, setPopoverContainer] = useState<HTMLDivElement | null>(null);
    const [dropdownRef, setDropdownRef] = useState<HTMLDivElement | null>(null);
    const dropdownRect = useRectObserver(dropdownRef);
    const recalcDependency = dropdownRect ? [dropdownRect.width, dropdownRect.height] : [];

    const addClickEvents = useCallback(
        (anchor: HTMLElement | SVGSVGElement) => {
            const handleClick = () => {
                if (isOpen) {
                    onClose();
                }
            };

            anchor.addEventListener('click', handleClick);
            return () => anchor.removeEventListener('click', handleClick);
        },
        [isOpen, onClose]
    );

    useEffect(() => {
        if (controlRef) {
            return addClickEvents(controlRef);
        }
    }, [addClickEvents, controlRef]);

    useOnClickOutside([controlRef, popoverContainer], () => {
        if (isOpen) {
            onClose();
        }
    });

    return (
        <>
            <div ref={setControlRef}>{control}</div>
            <ControlledPopover
                ref={setPopoverContainer}
                isOpen={isOpen}
                anchorElement={controlRef}
                options={{
                    placement: 'bottom-end',
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 8] } },
                        { name: 'recalcDependency', options: recalcDependency },
                    ],
                }}
            >
                <div className={cn(styles.dropdown, className)} ref={setDropdownRef}>
                    {children}
                </div>
            </ControlledPopover>
        </>
    );
};

export default Dropdown;
