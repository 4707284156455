import React, { useMemo } from 'react';
import styles from './MicrowaveInfluencersTable.module.css';
import { InstagramUserImage, microwave, TiktokUserImage, youtube } from '@round/api';
import { Skeleton } from '@round/ui-kit';
import { getCountry } from '../../../../../utility/utility';
import WrapperPaginationTable, {
    WrapperPaginationTableProps,
} from 'ui/WrapperTable/WrapperPaginationTable/WrapperPaginationTable';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import {
    getMicrowaveInfluencerPlatformId,
    getMicrowaveInfluencerPlatformUsername,
    MICROWAVE_PLATFORMS,
} from '../../helpers';
import AccountBadge from '../../components/AccountBadge/AccountBadge';

export type MicrowaveInfluencersTableRow = microwave.AdminInfluencer & {
    tiktokImage: TiktokUserImage | undefined;
    instagramImage: InstagramUserImage | undefined;
    youtubeChannel: youtube.Channel | undefined;
};
type Context<Key extends keyof MicrowaveInfluencersTableRow> = CellContext<
    MicrowaveInfluencersTableRow,
    MicrowaveInfluencersTableRow[Key]
>;

type Props = Omit<WrapperPaginationTableProps<MicrowaveInfluencersTableRow>, 'columns'>;

const MicrowaveInfluencersTable = (props: Props) => {
    const columns: ColumnDef<MicrowaveInfluencersTableRow, any>[] = useMemo(
        () => [
            {
                header: 'email',
                accessorKey: 'email',
                cell: ({ getValue }: Context<'email'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    return <>{getValue() || '-'}</>;
                },
            },
            {
                header: 'Accounts',
                id: 'accounts',
                cell: ({ row: { original } }) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const images = {
                        tiktok:
                            original.tiktokImage?.avatar_thumb.cached_url ||
                            original.tiktokImage?.avatar_thumb.original_url,
                        instagram:
                            original.instagramImage?.avatar_thumb.cached_url ||
                            original.instagramImage?.avatar_thumb.original_url,
                        youtube: original.youtubeChannel?.thumbnail || undefined,
                    };

                    return (
                        <div className={styles.account}>
                            {MICROWAVE_PLATFORMS.map((platform) => {
                                const username = getMicrowaveInfluencerPlatformUsername(original, platform);
                                const platformId = getMicrowaveInfluencerPlatformId(original, platform);
                                if (!username || !platformId) {
                                    return null;
                                }

                                return (
                                    <AccountBadge
                                        key={platform}
                                        platform={platform}
                                        username={username}
                                        imageUrl={images[platform]}
                                        platformId={platformId}
                                    />
                                );
                            })}
                        </div>
                    );
                },
            },
            {
                header: 'Location',
                accessorKey: 'location',
                cell: ({ getValue }: Context<'location'>) => {
                    if (props.isLoading) {
                        return <Skeleton />;
                    }

                    const country = getCountry(getValue());
                    if (!country) {
                        return <>-</>;
                    }

                    return (
                        <div className={styles.location}>
                            <span className={`fi fi-${country.code.toLowerCase()}`} />
                            {country.label}
                        </div>
                    );
                },
            },
        ],
        [props.isLoading]
    );

    return <WrapperPaginationTable columns={columns} className={styles.table} {...props} />;
};

export default MicrowaveInfluencersTable;
