import React, { useCallback, useMemo, useState } from 'react';
import Tabs, { TabNav, TabNavItem, TabPanel, TabPanels } from '../../../../../../../ui/DataDisplay/Tabs/Tabs';
import styles from './EngagementChart.module.css';
import Button from '../../../../../../../ui/Buttons/Button/Button';
import LineChart, { Point } from '../../../../../../../ui/DataDisplay/Charts/LineChart/LineChart';
import { TiktokUserStats } from '@round/api';
import cn from 'classnames';
import { formatDate, formatNumberToKNotation } from '../../../../../../../helpers';
import { useTiktokUserStats } from '../../TiktokUserDataContext/TiktokUserDataContext';

type EngagementChartProps = {
    loading?: boolean;
    tiktokUserId: number | undefined;
};

const dateRangeOptions: { days: number; label: string }[] = [
    { days: 14, label: '2 weeks' },
    { days: 42, label: '6 weeks' },
    { days: -1, label: 'All time' },
];

const EngagementChart = ({ loading, tiktokUserId }: EngagementChartProps) => {
    const { tiktokUserStats, tiktokUserStatsLoading } = useTiktokUserStats(tiktokUserId);
    const chartLoading = loading || tiktokUserStatsLoading;

    const [activeTab, setActiveTab] = useState('followers');
    const [dateRange, setDateRange] = useState<number>(dateRangeOptions[0].days);
    const statsWithinDateRange: TiktokUserStats[] = useMemo(
        () =>
            tiktokUserStats.filter((s) => {
                const thresholdDateTime = Date.now() - dateRange * 24 * 60 * 60 * 1000;
                return dateRange < 0 || Date.parse(s.timestamp) > thresholdDateTime;
            }),
        [dateRange, tiktokUserStats]
    );

    const followers: Point[] = useMemo(
        () => statsWithinDateRange.map((s) => ({ x: new Date(s.timestamp).toISOString(), y: s.follower_count })),
        [statsWithinDateRange]
    );

    const likes: Point[] = useMemo(
        () => statsWithinDateRange.map((s) => ({ x: new Date(s.timestamp).toISOString(), y: s.heart })),
        [statsWithinDateRange]
    );

    const renderTooltip = useCallback((label: string, item: Point) => {
        return (
            <div className={styles.tooltip}>
                <div>
                    <span className={styles.tooltipLabel}>{label}:</span>
                    <span className={styles.tooltipValue}>{formatNumberToKNotation(item.y)}</span>
                </div>
                <span className={styles.tooltipDateTime}>{formatDate(item.x)}</span>
            </div>
        );
    }, []);

    const renderTotalFollowersTooltip = useCallback((item: Point) => renderTooltip('Followers', item), [renderTooltip]);
    const renderTotalLikesTooltip = useCallback((item: Point) => renderTooltip('Likes', item), [renderTooltip]);

    return (
        <div className={styles.container}>
            <Tabs activeTab={activeTab} onChange={setActiveTab}>
                <div className={styles.chartHeader}>
                    <TabNav>
                        <TabNavItem className={styles.chartNavItem} name="followers">
                            Total followers
                        </TabNavItem>
                        <TabNavItem className={styles.chartNavItem} name="likes">
                            Total likes
                        </TabNavItem>
                    </TabNav>

                    <div className={styles.dateRangeContainer}>
                        {dateRangeOptions.map((o) => (
                            <Button
                                key={o.days}
                                className={cn(styles.dateRangeOption, { [styles.active]: o.days === dateRange })}
                                onClick={() => setDateRange(o.days)}
                            >
                                {o.label}
                            </Button>
                        ))}
                        <div className={styles.activeIndicator} />
                    </div>
                </div>

                <TabPanels>
                    <TabPanel name="followers">
                        <LineChart
                            beginAtZero={Math.max(...followers.map((i) => i.y)) <= 100}
                            loading={chartLoading}
                            points={followers}
                            renderTooltip={renderTotalFollowersTooltip}
                        />
                    </TabPanel>

                    <TabPanel name="likes">
                        <LineChart
                            beginAtZero={Math.max(...likes.map((i) => i.y)) <= 100}
                            loading={chartLoading}
                            points={likes}
                            renderTooltip={renderTotalLikesTooltip}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    );
};

export default EngagementChart;
