import { fetchWithToken } from '../../helpers';
import { ApiResponse, ApiResponseError, ForbiddenResponse, NotFoundResponse } from '../../types';
import { PublicReport } from './publicReport.types';

export type PostPublicReportData = {
    report_id: string;
};

type PostPublicReportResponse =
    | ApiResponse<PublicReport, 201>
    | ApiResponse<string[], 400>
    | ApiResponse<ApiResponseError<PostPublicReportData>, 400>
    | ForbiddenResponse;

export async function postPublicReport(data: PostPublicReportData): Promise<PostPublicReportResponse> {
    const response = await fetchWithToken('/api/creatorbase/public-reports/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 403 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create public report');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type DeletePublicReportResponse = ApiResponse<null, 204> | ForbiddenResponse | NotFoundResponse;

export async function deletePublicReport(reportId: string): Promise<DeletePublicReportResponse> {
    const response = await fetchWithToken(`/api/creatorbase/public-reports/${reportId}/`, {
        method: 'DELETE',
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not delete post');
    }

    return {
        status: 204,
        data: null,
    };
}
