import { AppServer, RouteHandlerContext } from '../../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AdminCampaign } from './campaign.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';

export const models = {
    adminCampaign: Model as ModelDefinition<AdminCampaign>,
};

export const serializers = {
    adminCampaign: makeSerializer<AdminCampaign>([]),
};

export const factories = {
    adminCampaign: createFactory<AdminCampaign>({
        currency: 1,
        client_name(index: number) {
            return `client name ${index}`;
        },
        client_user(index: number) {
            return index;
        },
        client_user_email(index: number) {
            return `client user email ${index}`;
        },
        name(index: number) {
            return `name ${index}`;
        },
        type: 'AUDIO',
    }),
};

export function handleRequests(server: AppServer) {
    const getAdminCampaignsListUrl = '/api/apex/admin/campaign/';
    server.get(getAdminCampaignsListUrl, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const campaigns = schema
            .all('adminCampaign')
            .filter((c) => !search || c.name.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(campaigns, {
            url: getAdminCampaignsListUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'adminCampaign'),
        });
    });

    server.post('/api/apex/admin/campaign/', function (this: RouteHandlerContext, schema, request) {
        return schema.create('adminCampaign', JSON.parse(request.requestBody));
    });
}
