import { InfluencerPlan } from '@round/api';
import styles from './Campaign.module.css';
import { Image, Skeleton } from '@round/ui-kit';
import { Link } from 'react-router-dom';
import TruncatedTextCell from 'SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import { buildInfluencerPlanUrl } from 'Modules/Advertising/InfluencerPlan/InfluencerPlan.helpers';
import cn from 'classnames';

type Props = {
    isLoading?: boolean;
    plan: InfluencerPlan | undefined;
    className?: string;
};

const Campaign = ({ plan, isLoading, className }: Props) => (
    <div className={cn(styles.container, className)}>
        <Image loading={isLoading} className={styles.image} src={plan?.release.brand.picture} />
        <div className={styles.titles}>
            {isLoading ? (
                <Skeleton width="7rem" />
            ) : (
                <Link className={styles.title} target="_blank" to={buildInfluencerPlanUrl(plan?.release.id!)}>
                    <TruncatedTextCell>{plan?.release.name ?? '-'}</TruncatedTextCell>
                </Link>
            )}

            {isLoading ? (
                <Skeleton width="5rem" />
            ) : (
                <TruncatedTextCell className={styles.subtitle}>{plan?.release.brand.name ?? '-'}</TruncatedTextCell>
            )}
        </div>
    </div>
);

export default Campaign;
