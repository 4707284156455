import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react';
import cn from 'classnames';
import styles from './Button.module.css';
import { ReactComponent as SpinnerIcon } from 'ui/Button/Spinner.svg';

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    appearance: 'primary' | 'critical' | 'outline' | 'ghost';
    isLoading?: boolean;
    iconRight?: React.ReactNode;
    iconLeft?: React.ReactNode;
};

const Button = forwardRef<HTMLButtonElement, Props>(({ appearance, iconRight, iconLeft, isLoading, ...props }, ref) => {
    return (
        <button {...props} ref={ref} className={cn(styles.button, styles[appearance], props.className)}>
            {iconLeft}
            {props.children}
            {/* Placeholder loading state until we have one designed */}
            {isLoading ? <SpinnerIcon className={styles.spinner} /> : iconRight}
        </button>
    );
});

export default Button;
