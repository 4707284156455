import { ApiResponse, NotFoundResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { TiktokPostInviteEmail } from './tiktokPostInviteEmails.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';

type GetTiktokPostInviteEmailsParams = Partial<
    PaginatedRequest & {
        tiktok_post_invite: string;
    }
>;

export async function getTiktokPostInviteEmails(
    params: GetTiktokPostInviteEmailsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<TiktokPostInviteEmail>, 200> | NotFoundResponse> {
    const response = await fetchWithToken(
        `/api/microwave/tiktok-post-invite-emails/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get tiktok post invite emails ${encodeUrlSearchParams(params)}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
