import React from 'react';
import { CellProps } from 'react-table';
import { CreatorsTableRow } from '../CreatorsTable';
import { Skeleton } from '@round/ui-kit';
import { StylesConfig, ValueType } from 'react-select';
import { GenericDropdownOption } from '../../../../../../../../App.types';
import GroupedSelectionsMultiSelect from '../../../../../../../../ui/DataEntry/Select/GroupedSelectionsMultiSelect/GroupedSelectionsMultiSelect';
import styles from '../CreatorsTable.module.css';
import { useMicrowaveContentTags } from '../../../../contexts/MicrowaveContentTags';
import TagsPlaceholder from './TagsPlaceholder';

type Props = CellProps<CreatorsTableRow, number[]> & {
    isLoading: boolean;
    onChange: (influencerId: number, tags: number[]) => Promise<void>;
};

const tagsSelectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        border: 'none',
        boxShadow: 'none',
        cursor: 'pointer',
    }),
    valueContainer: (base) => ({
        ...base,
        padding: 0,
        flexWrap: 'nowrap',
    }),
    menuList: (base) => ({
        ...base,
        minWidth: 'max-content',
    }),
};

const Tags = ({ isLoading, onChange, ...props }: Props) => {
    const { tags: allContentTags } = useMicrowaveContentTags();
    const selectedTags = allContentTags
        .filter((t) => props.value?.includes(t.id))
        .map((t) => ({ value: t.id, label: t.name }));

    if (isLoading) {
        return <Skeleton />;
    }

    const handleChange = async (tags: ValueType<GenericDropdownOption<number>, true>) => {
        const incomingTagIds = tags?.map((t) => t.value) ?? [];
        const currentTagIds = props.value ?? [];
        const hasChanged =
            !incomingTagIds.every((t) => currentTagIds.includes(t)) ||
            !currentTagIds.every((t) => incomingTagIds.includes(t));

        if (!hasChanged) {
            return;
        }

        await onChange(props.row.original.id, incomingTagIds);
    };

    return (
        <GroupedSelectionsMultiSelect
            className={styles.tagsSelect}
            styles={tagsSelectStyles}
            options={allContentTags.map((t) => ({ value: t.id, label: t.name }))}
            value={selectedTags}
            onChange={handleChange}
            components={{ Placeholder: TagsPlaceholder }}
        />
    );
};

export default Tags;
