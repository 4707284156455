import { Context } from '@fluentui/react-context-selector';
import { creatorbase } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { makeErrorStateSlice, makeLoadingStateSlice, makeSuccessStateSlice } from 'utility/dataState';

type BrandUnion = creatorbase.Brand | creatorbase.PublicBrand;

export type State<T extends BrandUnion> = {
    [brandId: number]: DataState<T | null> | undefined;
};

export const initialState = {};

export type Actions<T extends BrandUnion> =
    | ReducerActionWithPayload<'loadBrands', number[]>
    | ReducerActionWithPayload<'errorLoadingBrands', { brandIds: number[]; error: string }>
    | ReducerActionWithPayload<'successLoadingBrands', { brandIds: number[]; brands: T[] }>;

export const makeBrandsReducer = <T extends BrandUnion>() => {
    return createReducer<State<T>, Actions<T>>({
        loadBrands: (state, { payload: brandIds }) => ({
            ...state,
            ...makeLoadingStateSlice(brandIds),
        }),
        errorLoadingBrands: (state, { payload: { brandIds, error } }) => ({
            ...state,
            ...makeErrorStateSlice(brandIds, error),
        }),
        successLoadingBrands: (state, { payload: { brandIds, brands } }) => ({
            ...state,
            ...makeSuccessStateSlice(brandIds, (brandId) => brands.find((b) => b.id === brandId) ?? null),
        }),
    });
};

export const makeBrandsDataHook = <TValue extends BaseDataHookContextValue<Actions<TBrand>>, TBrand extends BrandUnion>(
    context: Context<TValue>,
    selector: ContextSelector<TValue, State<TBrand>>
) => {
    return () => {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (brandIds: number[], requestInit?: RequestInit) => {
                if (!brandIds.length) {
                    dispatch({ type: 'successLoadingBrands', payload: { brandIds: [], brands: [] } });
                    return;
                }

                try {
                    dispatch({ type: 'loadBrands', payload: brandIds });
                    const response = await creatorbase.getBrands(
                        { id: brandIds.toString(), page_size: brandIds.length },
                        requestInit
                    );
                    if (response.status === 200) {
                        dispatch({
                            type: 'successLoadingBrands',
                            payload: { brandIds, brands: response.data.results as TBrand[] },
                        });
                        return response;
                    }

                    dispatch({ type: 'errorLoadingBrands', payload: { brandIds, error: response.data.detail } });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({ type: 'errorLoadingBrands', payload: { brandIds, error: 'Could not load brands' } });
                    throw e;
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
};
