import useNonNullContext from 'Hooks/useNonNullContext';
import {
    reducer,
    initialState,
    State as TiktokAccountDataState,
    Actions as TiktokAccountDataActions,
} from 'Modules/TikTok/hooks/dataHooks/useTiktokAccountsDataHook';
import { Dispatch, ReactNode, createContext, useCallback, useContext, useReducer } from 'react';
import { createBatchedParamsArrayFromIds, fetchTiktokUserStats, getTiktokUserImages, getTiktokUsers } from '@round/api';

const TiktokAccountsContext = createContext<[TiktokAccountDataState, Dispatch<TiktokAccountDataActions>] | null>(null);

type Props = { children?: ReactNode };
export const TiktokAccountsProvider = ({ children }: Props) => {
    const state = useReducer(reducer, initialState);

    return <TiktokAccountsContext.Provider value={state}>{children}</TiktokAccountsContext.Provider>;
};

export function useTiktokAccountsData() {
    const [state, dispatch] = useNonNullContext(TiktokAccountsContext);
    const fetchData = useCallback(
        async (accountIds: number[], requestInit?: RequestInit) => {
            if (!accountIds.length) {
                dispatch({ type: 'setTiktokAccountsDataInitialized' });
                return;
            }

            try {
                dispatch({ type: 'setTiktokAccountsDataLoading', payload: { accountIds } });

                // Since we aren't paginating yet, we need to batch the requests to avoid exceeding the URL length limit
                const batchedUsersParams = createBatchedParamsArrayFromIds(accountIds, (ids) => ({
                    id: ids.join(','),
                }));

                const batchedStatsParams = createBatchedParamsArrayFromIds(accountIds, (ids) => ({
                    user_ids: ids,
                    latest: true,
                }));

                const batchedImagesParams = createBatchedParamsArrayFromIds(accountIds, (ids) => ({
                    ids,
                }));

                const [usersResponse, userStatsResponse, userImagesResponse] = await Promise.allSettled([
                    Promise.all(
                        batchedUsersParams.map((params) =>
                            getTiktokUsers(params, requestInit).then((res) =>
                                res.status === 200 ? res.data.results : []
                            )
                        )
                    ),
                    Promise.all(
                        batchedStatsParams.map((params) =>
                            fetchTiktokUserStats(params, requestInit).then((res) =>
                                res.status === 200 ? res.data.results : []
                            )
                        )
                    ),
                    //user images fetch has non-standard params, so we'll just use the ids
                    Promise.all(batchedImagesParams.map((params) => getTiktokUserImages(params.ids, requestInit))),
                ]);

                if (usersResponse.status === 'fulfilled') {
                    dispatch({
                        type: 'setTiktokAccountsDataSuccess',
                        payload: {
                            accountIds,
                            users: usersResponse.status === 'fulfilled' ? usersResponse.value.flat() : [],
                            userStats: userStatsResponse.status === 'fulfilled' ? userStatsResponse.value.flat() : [],
                            userImages:
                                userImagesResponse.status === 'fulfilled' ? userImagesResponse.value.flat() : [],
                        },
                    });

                    dispatch({ type: 'setTiktokAccountsDataInitialized' });
                    return;
                }

                dispatch({
                    type: 'setTiktokAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch tiktok account data` },
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'setTiktokAccountsDataIdle', payload: { accountIds } });
                    return;
                }

                dispatch({
                    type: 'setTiktokAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch tiktok account data` },
                });
            }
        },
        [dispatch]
    );

    return {
        data: state.data,
        isInitialized: state.isInitialized,
        fetchData,
        getIsLoading: (accountId: number) =>
            !state.data[accountId] ||
            state.data[accountId]?.status === 'idle' ||
            state.data[accountId]?.status === 'loading',
    };
}

export function useTiktokAccountsDataActions() {
    const context = useContext(TiktokAccountsContext);

    const [, dispatch] = context ?? [];

    const fetchData = useCallback(
        async (accountIds: number[], requestInit?: RequestInit) => {
            if (!dispatch) {
                return;
            }

            try {
                dispatch({ type: 'setTiktokAccountsDataLoading', payload: { accountIds } });

                const batchedUsersParams = createBatchedParamsArrayFromIds(accountIds, (ids) => ({
                    id: ids.join(','),
                }));

                const batchedStatsParams = createBatchedParamsArrayFromIds(accountIds, (ids) => ({
                    user_ids: ids,
                    latest: true,
                }));

                const batchedImagesParams = createBatchedParamsArrayFromIds(accountIds, (ids) => ({
                    ids,
                }));

                const [usersResponse, userStatsResponse, userImagesResponse] = await Promise.allSettled([
                    Promise.all(
                        batchedUsersParams.map((params) =>
                            getTiktokUsers(params, requestInit).then((res) =>
                                res.status === 200 ? res.data.results : []
                            )
                        )
                    ),
                    Promise.all(
                        batchedStatsParams.map((params) =>
                            fetchTiktokUserStats(params, requestInit).then((res) =>
                                res.status === 200 ? res.data.results : []
                            )
                        )
                    ),
                    //user images fetch has non-standard params, so we'll just use the ids
                    Promise.all(batchedImagesParams.map((params) => getTiktokUserImages(params.ids, requestInit))),
                ]);

                if (usersResponse.status === 'fulfilled') {
                    dispatch({
                        type: 'setTiktokAccountsDataSuccess',
                        payload: {
                            accountIds,
                            users: usersResponse.status === 'fulfilled' ? usersResponse.value.flat() : [],
                            userStats: userStatsResponse.status === 'fulfilled' ? userStatsResponse.value.flat() : [],
                            userImages:
                                userImagesResponse.status === 'fulfilled' ? userImagesResponse.value.flat() : [],
                        },
                    });

                    dispatch({ type: 'setTiktokAccountsDataInitialized' });
                    return;
                }

                dispatch({
                    type: 'setTiktokAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch tiktok account data` },
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'setTiktokAccountsDataIdle', payload: { accountIds } });
                    return;
                }

                dispatch({
                    type: 'setTiktokAccountsDataError',
                    payload: { accountIds, error: `Couldn't fetch tiktok account data` },
                });
            }
        },
        [dispatch]
    );

    return {
        fetchData,
    };
}
