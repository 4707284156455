import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Channel } from './channel.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    channel: Model as ModelDefinition<Channel>,
};

export const serializers = {
    channel: makeSerializer<Channel>([]),
};

export const factories = {
    channel: createFactory<Channel>({
        name(index: number) {
            return `name ${index}`;
        },
        code(index: number) {
            return `code ${index}`;
        },
        buy_platforms: [],
    }),
};

export function handleChannelRequests(server: AppServer) {
    const getChannelsListUrl = '/api/advertising/viewsets/channel/';
    server.get(getChannelsListUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('channel'), {
            url: getChannelsListUrl,
            serialize: (resource) => this.serialize(resource, 'channel'),
            queryParams: request.queryParams,
        });
    });
}
