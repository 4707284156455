import { useState } from 'react';
import FullScreenModal from '../../../ui/FullScreenModal/FullScreenModal';

const FullScreenModalPlayground = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <button onClick={() => setIsModalOpen(true)}>Open Modal</button>
            <FullScreenModal closeOnEscape isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <FullScreenModal.Header>Modal Title</FullScreenModal.Header>
                <FullScreenModal.Content>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eros leo, elementum eu dui sit amet,
                    scelerisque aliquet orci. Proin eleifend lacus facilisis lacus bibendum, at commodo sem semper.
                    Donec consectetur mauris quis nibh porttitor, vel placerat nisi interdum. Donec aliquet at tortor
                    vitae pretium. Nulla lorem erat, vestibulum ut dictum blandit, dapibus ut risus. Mauris et elementum
                    massa. Cras venenatis rhoncus augue, a porta nulla blandit et. Vivamus vestibulum, risus sed tempus
                    luctus, diam est pretium velit, ac pretium est odio et ex. Nam tempor id metus a aliquam. Nulla
                    euismod erat ut nibh feugiat sodales. Aliquam vel quam maximus, mollis enim nec, feugiat urna. Nunc
                    scelerisque quis mi in ultrices. Quisque luctus quam id efficitur pretium. Nam felis nulla, sodales
                    eget luctus in, pellentesque eget nulla. Praesent mollis eros nec massa ultricies suscipit. Donec
                    odio lorem, ornare sed diam eget, gravida posuere lacus. Nulla et tortor quis nunc vestibulum
                    ornare. Sed sodales purus leo, nec dictum metus vehicula in. Ut hendrerit auctor quam, eget egestas
                    magna aliquet sodales. Sed eleifend vulputate risus, at ultrices orci. Phasellus eu nibh est. Cras
                    volutpat augue pretium, malesuada sapien eget, pretium odio. Sed ac massa ex. Sed porta purus vel
                    metus pellentesque aliquam. Praesent faucibus, arcu eget sollicitudin venenatis, dolor nisi placerat
                    sem, et consequat erat velit vel lacus. Suspendisse orci massa, ultrices eu fermentum nec, interdum
                    volutpat elit. Maecenas ac diam ornare, fermentum mauris et, vehicula ipsum. Donec metus erat,
                    hendrerit et eros vel, varius ultrices lacus. Praesent imperdiet fringilla metus. Vestibulum ut odio
                    sed nibh interdum fermentum. Nunc a urna iaculis nulla semper fringilla. Nulla at condimentum nisi.
                    Pellentesque purus libero, imperdiet in odio ac, finibus rutrum purus. Class aptent taciti sociosqu
                    ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam faucibus viverra neque, eu
                    sodales velit venenatis a. Sed commodo libero sit amet lacinia venenatis. Ut quis ultricies arcu, at
                    hendrerit metus. Aenean lobortis quam imperdiet metus gravida, eu mollis odio vestibulum. Quisque in
                    tellus eu leo aliquet vehicula eget sed sem. Pellentesque dignissim lorem a elit condimentum
                    suscipit. Proin fermentum augue vel lacus venenatis pharetra. Proin hendrerit est vel ligula
                    aliquet, eget lacinia lectus varius. Morbi et augue velit. Integer eu porttitor nisl, sit amet
                    tincidunt sapien. In a mauris nisl. Sed nisi augue, molestie tristique odio vitae, vulputate
                    suscipit urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eros leo, elementum eu
                    dui sit amet, scelerisque aliquet orci. Proin eleifend lacus facilisis lacus bibendum, at commodo
                    sem semper. Donec consectetur mauris quis nibh porttitor, vel placerat nisi interdum. Donec aliquet
                    at tortor vitae pretium. Nulla lorem erat, vestibulum ut dictum blandit, dapibus ut risus. Mauris et
                    elementum massa. Cras venenatis rhoncus augue, a porta nulla blandit et. Vivamus vestibulum, risus
                    sed tempus luctus, diam est pretium velit, ac pretium est odio et ex. Nam tempor id metus a aliquam.
                    Nulla euismod erat ut nibh feugiat sodales. Aliquam vel quam maximus, mollis enim nec, feugiat urna.
                    Nunc scelerisque quis mi in ultrices. Quisque luctus quam id efficitur pretium. Nam felis nulla,
                    sodales eget luctus in, pellentesque eget nulla. Praesent mollis eros nec massa ultricies suscipit.
                    Donec odio lorem, ornare sed diam eget, gravida posuere lacus. Nulla et tortor quis nunc vestibulum
                    ornare. Sed sodales purus leo, nec dictum metus vehicula in. Ut hendrerit auctor quam, eget egestas
                    magna aliquet sodales. Sed eleifend vulputate risus, at ultrices orci. Phasellus eu nibh est. Cras
                    volutpat augue pretium, malesuada sapien eget, pretium odio. Sed ac massa ex. Sed porta purus vel
                    metus pellentesque aliquam. Praesent faucibus, arcu eget sollicitudin venenatis, dolor nisi placerat
                    sem, et consequat erat velit vel lacus. Suspendisse orci massa, ultrices eu fermentum nec, interdum
                    volutpat elit. Maecenas ac diam ornare, fermentum mauris et, vehicula ipsum. Donec metus erat,
                    hendrerit et eros vel, varius ultrices lacus. Praesent imperdiet fringilla metus. Vestibulum ut odio
                    sed nibh interdum fermentum. Nunc a urna iaculis nulla semper fringilla. Nulla at condimentum nisi.
                    Pellentesque purus libero, imperdiet in odio ac, finibus rutrum purus. Class aptent taciti sociosqu
                    ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam faucibus viverra neque, eu
                    sodales velit venenatis a. Sed commodo libero sit amet lacinia venenatis. Ut quis ultricies arcu, at
                    hendrerit metus. Aenean lobortis quam imperdiet metus gravida, eu mollis odio vestibulum. Quisque in
                    tellus eu leo aliquet vehicula eget sed sem. Pellentesque dignissim lorem a elit condimentum
                    suscipit. Proin fermentum augue vel lacus venenatis pharetra. Proin hendrerit est vel ligula
                    aliquet, eget lacinia lectus varius. Morbi et augue velit. Integer eu porttitor nisl, sit amet
                    tincidunt sapien. In a mauris nisl. Sed nisi augue, molestie tristique odio vitae, vulputate
                    suscipit urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eros leo, elementum eu
                    dui sit amet, scelerisque aliquet orci. Proin eleifend lacus facilisis lacus bibendum, at commodo
                    sem semper. Donec consectetur mauris quis nibh porttitor, vel placerat nisi interdum. Donec aliquet
                    at tortor vitae pretium. Nulla lorem erat, vestibulum ut dictum blandit, dapibus ut risus. Mauris et
                    elementum massa. Cras venenatis rhoncus augue, a porta nulla blandit et. Vivamus vestibulum, risus
                    sed tempus luctus, diam est pretium velit, ac pretium est odio et ex. Nam tempor id metus a aliquam.
                    Nulla euismod erat ut nibh feugiat sodales. Aliquam vel quam maximus, mollis enim nec, feugiat urna.
                    Nunc scelerisque quis mi in ultrices. Quisque luctus quam id efficitur pretium. Nam felis nulla,
                    sodales eget luctus in, pellentesque eget nulla. Praesent mollis eros nec massa ultricies suscipit.
                    Donec odio lorem, ornare sed diam eget, gravida posuere lacus. Nulla et tortor quis nunc vestibulum
                    ornare. Sed sodales purus leo, nec dictum metus vehicula in. Ut hendrerit auctor quam, eget egestas
                    magna aliquet sodales. Sed eleifend vulputate risus, at ultrices orci. Phasellus eu nibh est. Cras
                    volutpat augue pretium, malesuada sapien eget, pretium odio. Sed ac massa ex. Sed porta purus vel
                    metus pellentesque aliquam. Praesent faucibus, arcu eget sollicitudin venenatis, dolor nisi placerat
                    sem, et consequat erat velit vel lacus. Suspendisse orci massa, ultrices eu fermentum nec, interdum
                    volutpat elit. Maecenas ac diam ornare, fermentum mauris et, vehicula ipsum. Donec metus erat,
                    hendrerit et eros vel, varius ultrices lacus. Praesent imperdiet fringilla metus. Vestibulum ut odio
                    sed nibh interdum fermentum. Nunc a urna iaculis nulla semper fringilla. Nulla at condimentum nisi.
                    Pellentesque purus libero, imperdiet in odio ac, finibus rutrum purus. Class aptent taciti sociosqu
                    ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam faucibus viverra neque, eu
                    sodales velit venenatis a. Sed commodo libero sit amet lacinia venenatis. Ut quis ultricies arcu, at
                    hendrerit metus. Aenean lobortis quam imperdiet metus gravida, eu mollis odio vestibulum. Quisque in
                    tellus eu leo aliquet vehicula eget sed sem. Pellentesque dignissim lorem a elit condimentum
                    suscipit. Proin fermentum augue vel lacus venenatis pharetra. Proin hendrerit est vel ligula
                    aliquet, eget lacinia lectus varius. Morbi et augue velit. Integer eu porttitor nisl, sit amet
                    tincidunt sapien. In a mauris nisl. Sed nisi augue, molestie tristique odio vitae, vulputate
                    suscipit urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eros leo, elementum eu
                    dui sit amet, scelerisque aliquet orci. Proin eleifend lacus facilisis lacus bibendum, at commodo
                    sem semper. Donec consectetur mauris quis nibh porttitor, vel placerat nisi interdum. Donec aliquet
                    at tortor vitae pretium. Nulla lorem erat, vestibulum ut dictum blandit, dapibus ut risus. Mauris et
                    elementum massa. Cras venenatis rhoncus augue, a porta nulla blandit et. Vivamus vestibulum, risus
                    sed tempus luctus, diam est pretium velit, ac pretium est odio et ex. Nam tempor id metus a aliquam.
                    Nulla euismod erat ut nibh feugiat sodales. Aliquam vel quam maximus, mollis enim nec, feugiat urna.
                    Nunc scelerisque quis mi in ultrices. Quisque luctus quam id efficitur pretium. Nam felis nulla,
                    sodales eget luctus in, pellentesque eget nulla. Praesent mollis eros nec massa ultricies suscipit.
                    Donec odio lorem, ornare sed diam eget, gravida posuere lacus. Nulla et tortor quis nunc vestibulum
                    ornare. Sed sodales purus leo, nec dictum metus vehicula in. Ut hendrerit auctor quam, eget egestas
                    magna aliquet sodales. Sed eleifend vulputate risus, at ultrices orci. Phasellus eu nibh est. Cras
                    volutpat augue pretium, malesuada sapien eget, pretium odio. Sed ac massa ex. Sed porta purus vel
                    metus pellentesque aliquam. Praesent faucibus, arcu eget sollicitudin venenatis, dolor nisi placerat
                    sem, et consequat erat velit vel lacus. Suspendisse orci massa, ultrices eu fermentum nec, interdum
                    volutpat elit. Maecenas ac diam ornare, fermentum mauris et, vehicula ipsum. Donec metus erat,
                    hendrerit et eros vel, varius ultrices lacus. Praesent imperdiet fringilla metus. Vestibulum ut odio
                    sed nibh interdum fermentum. Nunc a urna iaculis nulla semper fringilla. Nulla at condimentum nisi.
                    Pellentesque purus libero, imperdiet in odio ac, finibus rutrum purus. Class aptent taciti sociosqu
                    ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam faucibus viverra neque, eu
                    sodales velit venenatis a. Sed commodo libero sit amet lacinia venenatis. Ut quis ultricies arcu, at
                    hendrerit metus. Aenean lobortis quam imperdiet metus gravida, eu mollis odio vestibulum. Quisque in
                    tellus eu leo aliquet vehicula eget sed sem. Pellentesque dignissim lorem a elit condimentum
                    suscipit. Proin fermentum augue vel lacus venenatis pharetra. Proin hendrerit est vel ligula
                    aliquet, eget lacinia lectus varius. Morbi et augue velit. Integer eu porttitor nisl, sit amet
                    tincidunt sapien. In a mauris nisl. Sed nisi augue, molestie tristique odio vitae, vulputate
                    suscipit urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eros leo, elementum eu
                    dui sit amet, scelerisque aliquet orci. Proin eleifend lacus facilisis lacus bibendum, at commodo
                    sem semper. Donec consectetur mauris quis nibh porttitor, vel placerat nisi interdum. Donec aliquet
                    at tortor vitae pretium. Nulla lorem erat, vestibulum ut dictum blandit, dapibus ut risus. Mauris et
                    elementum massa. Cras venenatis rhoncus augue, a porta nulla blandit et. Vivamus vestibulum, risus
                    sed tempus luctus, diam est pretium velit, ac pretium est odio et ex. Nam tempor id metus a aliquam.
                    Nulla euismod erat ut nibh feugiat sodales. Aliquam vel quam maximus, mollis enim nec, feugiat urna.
                    Nunc scelerisque quis mi in ultrices. Quisque luctus quam id efficitur pretium. Nam felis nulla,
                    sodales eget luctus in, pellentesque eget nulla. Praesent mollis eros nec massa ultricies suscipit.
                    Donec odio lorem, ornare sed diam eget, gravida posuere lacus. Nulla et tortor quis nunc vestibulum
                    ornare. Sed sodales purus leo, nec dictum metus vehicula in. Ut hendrerit auctor quam, eget egestas
                    magna aliquet sodales. Sed eleifend vulputate risus, at ultrices orci. Phasellus eu nibh est. Cras
                    volutpat augue pretium, malesuada sapien eget, pretium odio. Sed ac massa ex. Sed porta purus vel
                    metus pellentesque aliquam. Praesent faucibus, arcu eget sollicitudin venenatis, dolor nisi placerat
                    sem, et consequat erat velit vel lacus. Suspendisse orci massa, ultrices eu fermentum nec, interdum
                    volutpat elit. Maecenas ac diam ornare, fermentum mauris et, vehicula ipsum. Donec metus erat,
                    hendrerit et eros vel, varius ultrices lacus. Praesent imperdiet fringilla metus. Vestibulum ut odio
                    sed nibh interdum fermentum. Nunc a urna iaculis nulla semper fringilla. Nulla at condimentum nisi.
                    Pellentesque purus libero, imperdiet in odio ac, finibus rutrum purus. Class aptent taciti sociosqu
                    ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam faucibus viverra neque, eu
                    sodales velit venenatis a. Sed commodo libero sit amet lacinia venenatis. Ut quis ultricies arcu, at
                    hendrerit metus. Aenean lobortis quam imperdiet metus gravida, eu mollis odio vestibulum. Quisque in
                    tellus eu leo aliquet vehicula eget sed sem. Pellentesque dignissim lorem a elit condimentum
                    suscipit. Proin fermentum augue vel lacus venenatis pharetra. Proin hendrerit est vel ligula
                    aliquet, eget lacinia lectus varius. Morbi et augue velit. Integer eu porttitor nisl, sit amet
                    tincidunt sapien. In a mauris nisl. Sed nisi augue, molestie tristique odio vitae, vulputate
                    suscipit urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eros leo, elementum eu
                    dui sit amet, scelerisque aliquet orci. Proin eleifend lacus facilisis lacus bibendum, at commodo
                    sem semper. Donec consectetur mauris quis nibh porttitor, vel placerat nisi interdum. Donec aliquet
                    at tortor vitae pretium. Nulla lorem erat, vestibulum ut dictum blandit, dapibus ut risus. Mauris et
                    elementum massa. Cras venenatis rhoncus augue, a porta nulla blandit et. Vivamus vestibulum, risus
                    sed tempus luctus, diam est pretium velit, ac pretium est odio et ex. Nam tempor id metus a aliquam.
                    Nulla euismod erat ut nibh feugiat sodales. Aliquam vel quam maximus, mollis enim nec, feugiat urna.
                    Nunc scelerisque quis mi in ultrices. Quisque luctus quam id efficitur pretium. Nam felis nulla,
                    sodales eget luctus in, pellentesque eget nulla. Praesent mollis eros nec massa ultricies suscipit.
                    Donec odio lorem, ornare sed diam eget, gravida posuere lacus. Nulla et tortor quis nunc vestibulum
                    ornare. Sed sodales purus leo, nec dictum metus vehicula in. Ut hendrerit auctor quam, eget egestas
                    magna aliquet sodales. Sed eleifend vulputate risus, at ultrices orci. Phasellus eu nibh est. Cras
                    volutpat augue pretium, malesuada sapien eget, pretium odio. Sed ac massa ex. Sed porta purus vel
                    metus pellentesque aliquam. Praesent faucibus, arcu eget sollicitudin venenatis, dolor nisi placerat
                    sem, et consequat erat velit vel lacus. Suspendisse orci massa, ultrices eu fermentum nec, interdum
                    volutpat elit. Maecenas ac diam ornare, fermentum mauris et, vehicula ipsum. Donec metus erat,
                    hendrerit et eros vel, varius ultrices lacus. Praesent imperdiet fringilla metus. Vestibulum ut odio
                    sed nibh interdum fermentum. Nunc a urna iaculis nulla semper fringilla. Nulla at condimentum nisi.
                    Pellentesque purus libero, imperdiet in odio ac, finibus rutrum purus. Class aptent taciti sociosqu
                    ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam faucibus viverra neque, eu
                    sodales velit venenatis a. Sed commodo libero sit amet lacinia venenatis. Ut quis ultricies arcu, at
                    hendrerit metus. Aenean lobortis quam imperdiet metus gravida, eu mollis odio vestibulum. Quisque in
                    tellus eu leo aliquet vehicula eget sed sem. Pellentesque dignissim lorem a elit condimentum
                    suscipit. Proin fermentum augue vel lacus venenatis pharetra. Proin hendrerit est vel ligula
                    aliquet, eget lacinia lectus varius. Morbi et augue velit. Integer eu porttitor nisl, sit amet
                    tincidunt sapien. In a mauris nisl. Sed nisi augue, molestie tristique odio vitae, vulputate
                    suscipit urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eros leo, elementum eu
                    dui sit amet, scelerisque aliquet orci. Proin eleifend lacus facilisis lacus bibendum, at commodo
                    sem semper. Donec consectetur mauris quis nibh porttitor, vel placerat nisi interdum. Donec aliquet
                    at tortor vitae pretium. Nulla lorem erat, vestibulum ut dictum blandit, dapibus ut risus. Mauris et
                    elementum massa. Cras venenatis rhoncus augue, a porta nulla blandit et. Vivamus vestibulum, risus
                    sed tempus luctus, diam est pretium velit, ac pretium est odio et ex. Nam tempor id metus a aliquam.
                    Nulla euismod erat ut nibh feugiat sodales. Aliquam vel quam maximus, mollis enim nec, feugiat urna.
                    Nunc scelerisque quis mi in ultrices. Quisque luctus quam id efficitur pretium. Nam felis nulla,
                    sodales eget luctus in, pellentesque eget nulla. Praesent mollis eros nec massa ultricies suscipit.
                    Donec odio lorem, ornare sed diam eget, gravida posuere lacus. Nulla et tortor quis nunc vestibulum
                    ornare. Sed sodales purus leo, nec dictum metus vehicula in. Ut hendrerit auctor quam, eget egestas
                    magna aliquet sodales. Sed eleifend vulputate risus, at ultrices orci. Phasellus eu nibh est. Cras
                    volutpat augue pretium, malesuada sapien eget, pretium odio. Sed ac massa ex. Sed porta purus vel
                    metus pellentesque aliquam. Praesent faucibus, arcu eget sollicitudin venenatis, dolor nisi placerat
                    sem, et consequat erat velit vel lacus. Suspendisse orci massa, ultrices eu fermentum nec, interdum
                    volutpat elit. Maecenas ac diam ornare, fermentum mauris et, vehicula ipsum. Donec metus erat,
                    hendrerit et eros vel, varius ultrices lacus. Praesent imperdiet fringilla metus. Vestibulum ut odio
                    sed nibh interdum fermentum. Nunc a urna iaculis nulla semper fringilla. Nulla at condimentum nisi.
                    Pellentesque purus libero, imperdiet in odio ac, finibus rutrum purus. Class aptent taciti sociosqu
                    ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam faucibus viverra neque, eu
                    sodales velit venenatis a. Sed commodo libero sit amet lacinia venenatis. Ut quis ultricies arcu, at
                    hendrerit metus. Aenean lobortis quam imperdiet metus gravida, eu mollis odio vestibulum. Quisque in
                    tellus eu leo aliquet vehicula eget sed sem. Pellentesque dignissim lorem a elit condimentum
                    suscipit. Proin fermentum augue vel lacus venenatis pharetra. Proin hendrerit est vel ligula
                    aliquet, eget lacinia lectus varius. Morbi et augue velit. Integer eu porttitor nisl, sit amet
                    tincidunt sapien. In a mauris nisl. Sed nisi augue, molestie tristique odio vitae, vulputate
                    suscipit urna.
                </FullScreenModal.Content>
                <FullScreenModal.Footer>
                    <button>Cancel</button>
                    <button>Confirm</button>
                </FullScreenModal.Footer>
            </FullScreenModal>
        </>
    );
};

export default FullScreenModalPlayground;
