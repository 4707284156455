import { fetchWithToken } from '../../../helpers';
import { ApiResponse, NotFoundResponse } from '../../../types';

type AddInstagramAudioToCreatorPlanResponse =
    | ApiResponse<{ audio_id: number }, 201>
    | ApiResponse<{ instagram_url: string }, 400>
    | NotFoundResponse;

export async function addInstagramAudioToCreatorPlan(
    planId: number,
    audioUrl: string
): Promise<AddInstagramAudioToCreatorPlanResponse> {
    const response = await fetchWithToken(`/api/advertising/viewsets/creator-plans/${planId}/instagram-audios/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ instagram_url: audioUrl }),
    });

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not add instagram audio ${audioUrl} to plan ${planId}`);
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export async function deleteInstagramAudioFromCreatorPlan(
    planId: number,
    audioId: number
): Promise<ApiResponse<null, 204> | NotFoundResponse> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/creator-plans/${planId}/instagram-audios/${audioId}/`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not delete audio ${audioId} from plan ${planId}`);
    }

    return {
        status: 204,
        data: null,
    };
}
