import React from 'react';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';

type Props = {
    header: string;
    tooltipTitle?: string;
    tooltipBody: string;
};

const TooltipHeader = ({ header, tooltipTitle, tooltipBody }: Props) => {
    return (
        <Tooltip.Root>
            {(anchor, setAnchor, underlineClassName) => (
                <>
                    <span ref={setAnchor} className={underlineClassName}>
                        {header}
                    </span>
                    <Tooltip anchorElement={anchor}>
                        <Tooltip.Title>{tooltipTitle || header}</Tooltip.Title>
                        <Tooltip.Body>{tooltipBody}</Tooltip.Body>
                    </Tooltip>
                </>
            )}
        </Tooltip.Root>
    );
};

export default TooltipHeader;
