import { PhylloUserAnalyticsData, getInstagramUserAnalytics } from '@round/api';
import { useCallback, useState } from 'react';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';

export default function useInstagramUserAnalyticsData(userId: number | undefined | null) {
    const [analytics, setAnalytics] = useState<PhylloUserAnalyticsData | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const fetchUserAnalyticsData = useCallback(async (userId: number, requestInit?: RequestInit) => {
        try {
            setIsLoading(true);
            const response = await getInstagramUserAnalytics(userId, requestInit);
            if (response.status === 404) {
                setError(response.data.detail);
                return;
            }

            setAnalytics(response.data.data);
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            const errorMessage = e instanceof Error ? e.message : 'Could not fetch user analytics data';
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useAbortableEffect(
        (signal) => {
            if (!userId || analytics) {
                return;
            }

            fetchUserAnalyticsData(userId, { signal });
        },
        [userId, fetchUserAnalyticsData, analytics]
    );

    return {
        analytics,
        isLoading,
        error,
    };
}
