import { ModelDefinition } from 'miragejs/-types';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { Model, Response } from 'miragejs';
import { InstagramInfluencerUser } from './instagramInfluencerUser.types';
import { AppServer } from '../../mirage';

export const models = {
    instagramInfluencerUser: Model as ModelDefinition<InstagramInfluencerUser>,
};

export const factories = {
    instagramInfluencerUser: createFactory<InstagramInfluencerUser>({
        id(index: number) {
            return index;
        },
        monitored_user(index: number) {
            return index * 10;
        },
        notes(index: number) {
            return `notes ${index}`;
        },
        user(index: number) {
            return index * 10;
        },
        username(index: number) {
            return `username${index}`;
        },
        contact_details(index: number) {
            return `contact details ${index}`;
        },
        reels_cost(index: number) {
            return index * (Math.random() * 10);
        },
        reels_currency: 1,
        feed_cost(index: number) {
            return index * (Math.random() * 10);
        },
        feed_currency: 1,
        story_cost(index: number) {
            return index * (Math.random() * 10);
        },
        story_currency: 1,
    }),
};

export const serializers = {
    instagramInfluencerUser: makeSerializer<InstagramInfluencerUser>([]),
};

export function handleInstagramInfluencerUserRequests(server: AppServer) {
    server.get('/api/instagram/viewsets/influencer-user/:user_id/', (schema, request) => {
        const { user_id } = request.params;
        const influencerUser = schema.find('instagramInfluencerUser', user_id);
        if (!influencerUser) {
            return new Response(404, {}, { detail: 'Not found.' });
        }
        return influencerUser;
    });

    server.patch('/api/instagram/viewsets/influencer-user/:user_id/', (schema, request) => {
        const { user_id } = request.params;
        const influencerUser = schema.find('instagramInfluencerUser', user_id);
        if (!influencerUser) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        influencerUser.update({ ...JSON.parse(request.requestBody) });
        return influencerUser;
    });

    server.post('/api/instagram/viewsets/influencer-user/', (schema, request) => {
        const body = JSON.parse(request.body);
        const influencerUser = schema.create('instagramInfluencerUser', {
            notes: body.notes,
            username: body.username,
            tags: body.tags,
            contact_details: body.contact_details,
            reels_cost: body.reels_cost,
            reels_currency: body.reels_currency,
            feed_cost: body.feed_cost,
            feed_currency: body.feed_currency,
            story_cost: body.story_cost,
            story_currency: body.story_currency,
        });
        if (!influencerUser) {
            return new Response(400);
        }
        return influencerUser;
    });
}
