import {
    Chart,
    Filler,
    PointElement,
    LineElement,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,
    BarElement,
    BarController,
    LineController,
} from 'chart.js';
import 'chartjs-adapter-moment';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(
    Filler,
    LinearScale,
    TimeScale,
    TimeSeriesScale,
    PointElement,
    LineElement,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    BarElement,
    BarController,
    LineController,
    ChartDataLabels
);

Chart.defaults.set('plugins.datalabels', {
    display: false,
});
