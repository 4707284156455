import React from 'react';
import cn from 'classnames';
import styles from './Badge.module.css';

export type BadgeProps = {
    label: string;
    className?: string;
    icon?: () => React.ReactNode;
};

const Badge = ({ label, className, icon }: BadgeProps) => {
    return (
        <div className={cn(styles.badge, className)}>
            {icon && icon()}
            <span>{label}</span>
        </div>
    );
};

export default Badge;
