import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import Select, { StylesConfig, ValueType } from 'react-select';
import debounce from 'lodash/debounce';

import {
    FacebookErrorResponse,
    FacebookTargetingAudienceSearchSelection,
    FacebookTargetingAudienceSelectionOption,
} from '../../../../../../../../App.types';
import FacebookTargetingSelectionOption from './FacebookTargetingSelectionOption';
import styles from './FacebookDetailedTargeting.module.css';
import { fetchFacebookApi, isFacebookError } from '../../../../../../../../helpers';
import { mapSelectionToOption } from './helpers';
import FacebookTargetingList from './FacebookTargetingList';
import FacebookTargetingSelectionFormIndicatorsContainer from './FacebookTargetingSelectionFormIndicatorsContainer';
import useAbortableEffect from '../../../../../../../../Hooks/useAbortableEffect';
import { AdvertisingContext } from '../../../../../contexts/AdvertisingContext/AdvertisingContext';
import useNonNullContext from '../../../../../../../../Hooks/useNonNullContext';

const selectStylesConfig: StylesConfig = {
    control: (provided) => ({
        ...provided,
        borderColor: '#C2CFE0',
        fontSize: '0.75rem',
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingTop: '0.3rem',
        paddingBottom: '0.3rem',
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    input: (provided) => ({
        ...provided,
        marginTop: '0',
        paddingTop: '0',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#90A0B7',
        fontSize: '0.75rem',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 1000,
    }),
};

type FacebookTargetingSelectionFormProps = {
    selections: FacebookTargetingAudienceSelectionOption[];
    onChange: (selections: FacebookTargetingAudienceSelectionOption[]) => void;
    title: string;
    hideSuggestions?: boolean;
};

const FacebookTargetingSelectionForm: FunctionComponent<FacebookTargetingSelectionFormProps> = ({
    selections,
    onChange,
    title,
    hideSuggestions,
}) => {
    const selectRef = useRef<Select>(null);
    const [selectLoading, setSelectLoading] = useState(false);
    const [selectOptions, setSelectOptions] = useState<FacebookTargetingAudienceSelectionOption[]>([]);
    const [selectError, setSelectError] = useState<FacebookErrorResponse | null>(null);
    const [query, setQuery] = useState('');
    const [{ facebookAdAccountId }] = useNonNullContext(AdvertisingContext);

    const debouncedSearch = useMemo(
        () =>
            debounce(async (query: string, requestInit?: RequestInit) => {
                try {
                    const response = await fetchFacebookApi<{ data: FacebookTargetingAudienceSearchSelection[] }>(
                        `/act_${facebookAdAccountId}/targetingsearch`,
                        { q: query },
                        requestInit
                    );

                    if (isFacebookError(response)) {
                        setSelectError(response);
                        setSelectLoading(false);
                        return;
                    }

                    const selectOptions = response.data.map(mapSelectionToOption);
                    setSelectOptions((existing) =>
                        selectOptions.filter(
                            (selection) =>
                                !existing.find((existingSelection) => existingSelection.value === selection.value)
                        )
                    );
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }
                } finally {
                    setSelectLoading(false);
                }
            }, 500),
        [facebookAdAccountId]
    );

    const onSelect = (value: ValueType<FacebookTargetingAudienceSelectionOption, false>) => {
        if (value) {
            onChange([...selections, value]);
        }
    };

    const onDelete = (toDelete: FacebookTargetingAudienceSelectionOption) => {
        onChange(selections.filter((selection) => selection.value !== toDelete.value));
    };

    const onSuggestionsClicked = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault();
            setSelectLoading(true);
            setSelectOptions([]);

            const response = await fetchFacebookApi<{ data: FacebookTargetingAudienceSearchSelection[] }>(
                `/act_${facebookAdAccountId}/targetingsuggestions`,
                {
                    targeting_list: JSON.stringify(
                        selections.map((selection) => ({ type: selection.type, id: selection.value }))
                    ),
                }
            );

            if (isFacebookError(response)) {
                setSelectError(response);
                setSelectLoading(false);
                return;
            }

            const suggestions = response.data.map(mapSelectionToOption);
            setSelectOptions(suggestions);
            setSelectLoading(false);
            selectRef.current?.focus();
        },
        [facebookAdAccountId, selections]
    );

    useAbortableEffect(
        (signal) => {
            if (!facebookAdAccountId) {
                return;
            }

            if (!query) {
                setSelectOptions([]);
                return;
            }

            setSelectLoading(true);
            debouncedSearch(query, { signal });
        },
        [facebookAdAccountId, debouncedSearch, query]
    );

    return (
        <div className={styles.selectionForm}>
            <p className={styles.selectionFormTitle}>{title}</p>
            {selections.length > 0 && <FacebookTargetingList selections={selections} onDelete={onDelete} />}
            <Select
                value={null}
                menuPortalTarget={document.body}
                styles={selectStylesConfig}
                className={styles.select}
                isLoading={selectLoading}
                options={selectOptions}
                onBlur={() => setSelectOptions([])}
                components={{
                    Option: FacebookTargetingSelectionOption,
                    IndicatorsContainer: FacebookTargetingSelectionFormIndicatorsContainer,
                }}
                noOptionsMessage={() =>
                    selectError || !facebookAdAccountId
                        ? 'Sorry, we are experiencing some technical issue'
                        : 'No Options'
                }
                inputValue={query}
                onInputChange={setQuery}
                onChange={onSelect}
                onSuggestionsClicked={onSuggestionsClicked}
                hideSuggestions={hideSuggestions}
            />
        </div>
    );
};

export default FacebookTargetingSelectionForm;
