import { genreAffinity } from '@round/api';
import { useCallback, useState } from 'react';

export function useGenres() {
    const [genres, setGenres] = useState<genreAffinity.Genre[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [hasError, setHasError] = useState(false);

    const init = useCallback(async (requestInit?: RequestInit) => {
        try {
            setIsLoading(true);
            setHasError(false);
            const response = await genreAffinity.getAllGenres(requestInit);

            if (response.status !== 200) {
                setHasError(true);
                throw new Error(response.data.detail);
            }

            setGenres(response.data.results);
            setIsLoading(false);
            setIsInitialized(true);
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }
            setHasError(true);
            throw new Error('Failed to fetch Genres');
        } finally {
            setIsLoading(false);
        }
    }, []);

    return {
        genres,
        isLoading: isLoading || (!isInitialized && !hasError),
        isInitialized,
        init,
    };
}
