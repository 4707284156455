import React, { useState } from 'react';
import { CellProps } from 'react-table';
import { Button, Card, Popover, Skeleton } from '@round/ui-kit';
import { formatDate, showNotification } from '../../../../../../../../helpers';
import styles from './DeadlineCell.module.css';
import Calendar from '../../../../../../../../ui/DataEntry/Calendar/Calendar';
import { microwave } from '@round/api';
import { resetTime } from '../../../../../../../../utility/utility';
import moment from 'moment/moment';
import { TiktokPostInviteData } from '../../useTiktokPostInvites';

type Props = CellProps<TiktokPostInviteData, string> & {
    isLoading: boolean;
    updatePostInvite: typeof microwave.patchTiktokMicroPostInvite;
};

const ONE_DAY = 1000 * 60 * 60 * 24;

const DeadlineCell = ({ value, isLoading, updatePostInvite, row: { original } }: Props) => {
    const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
    const [deadline, setDeadline] = useState(value);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    if (isLoading) {
        return <Skeleton />;
    }

    return (
        <>
            <div className={styles.dateCell} ref={setAnchor}>
                {formatDate(value)}
            </div>
            <Popover
                anchorElement={anchor}
                showOn="click"
                onShowPopoverChange={(show) => {
                    if (!show) {
                        setDeadline(value);
                    }
                }}
            >
                {(setShow) => (
                    <Card className={styles.popover}>
                        <div className={styles.quickActions}>
                            <Button
                                className={styles.action}
                                onClick={() => {
                                    const deadlineDate = new Date(deadline);
                                    const now = resetTime(new Date());
                                    if (now.getTime() > deadlineDate.getTime()) {
                                        setDeadline(moment(now).format('YYYY-MM-DD'));
                                        return;
                                    }

                                    setDeadline(moment(deadlineDate.getTime() + ONE_DAY).format('YYYY-MM-DD'));
                                }}
                            >
                                +1 day
                            </Button>
                            <Button
                                className={styles.action}
                                onClick={() => {
                                    const deadlineDate = new Date(deadline);
                                    const now = resetTime(new Date());
                                    const dateStart = now.getTime() > deadlineDate.getTime() ? now : deadlineDate;
                                    const daysMultiplier = now.getTime() > deadlineDate.getTime() ? 2 : 3;

                                    setDeadline(
                                        moment(dateStart.getTime() + ONE_DAY * daysMultiplier).format('YYYY-MM-DD')
                                    );
                                }}
                            >
                                +3 days
                            </Button>
                            <Button
                                className={styles.action}
                                disabled={value === deadline}
                                loading={isSaveLoading}
                                onClick={async () => {
                                    try {
                                        setIsSaveLoading(true);
                                        const response = await updatePostInvite(original.id, { deadline });
                                        if (response.status === 200) {
                                            showNotification('Updated', 'info');
                                            setShow(false);
                                            return;
                                        }

                                        if (response.status === 404) {
                                            showNotification(response.data.detail, 'error');
                                            setDeadline(value);
                                            return;
                                        }

                                        showNotification(String(response.data.deadline), 'error');
                                        setDeadline(value);
                                    } catch {
                                        showNotification('Could not update', 'error');
                                        setDeadline(value);
                                    } finally {
                                        setIsSaveLoading(false);
                                    }
                                }}
                            >
                                Save
                            </Button>
                        </div>

                        <Calendar value={deadline} onChange={setDeadline} minDate={new Date()} />
                    </Card>
                )}
            </Popover>
        </>
    );
};

export default DeadlineCell;
