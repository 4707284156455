import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styles from './Dialog.module.css';
import Button, { ButtonProps } from '../../Buttons/Button/Button';
import CloseIcon from '../../../SharedComponents/svg/Icons/CloseIcon';

export type DialogProps = {
    show: boolean;
    title: string;
    body?: ReactNode;
    confirmLabel?: string;
    loading?: boolean;
    /**
     * invoke `onCancel` on overlay clicked
     * @default false
     */
    closeOnOverlayClick?: boolean;
    onConfirm: () => void;
    onClose: () => void;
    confirmButtonColor?: ButtonProps['color'];
    confirmButtonType?: ButtonProps['type'];
};

const Dialog: FunctionComponent<DialogProps> = ({
    title = 'Are you sure?',
    body,
    show,
    loading,
    confirmLabel = 'Ok',
    onConfirm,
    onClose,
    closeOnOverlayClick,
    confirmButtonColor,
    confirmButtonType = 'bordered',
}) => {
    const onOverlayClickHandler = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();

            if (closeOnOverlayClick) {
                onClose();
            }
        },
        [closeOnOverlayClick, onClose]
    );

    if (!show) {
        return null;
    }

    return createPortal(
        <div>
            <div className={styles.overlay} onClick={onOverlayClickHandler} />
            <div className={styles.dialog}>
                <CloseIcon className={styles.closeButton} width={9} height={9} onClick={onClose} />

                <p className={styles.dialogTitle}>{title}</p>
                {body && (
                    <div>
                        <p className={styles.dialogBody}>{body}</p>
                    </div>
                )}

                <div className={styles.buttonContainer}>
                    <Button type={confirmButtonType} color={confirmButtonColor} loading={loading} onClick={onConfirm}>
                        {confirmLabel}
                    </Button>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default Dialog;
