import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokSongSuggestion } from './songSuggestion.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokSongSuggestion: Model as ModelDefinition<TiktokSongSuggestion>,
};

export const factories = {
    tiktokSongSuggestion: createFactory<TiktokSongSuggestion>({
        song(index: number) {
            return index;
        },
        audio(index: number) {
            return index;
        },
        audio_alternate_name(index: number) {
            return `audio alternate name: ${index}`;
        },
        audio_author_name(index: number) {
            return `audio author name: ${index}`;
        },
        audio_id(index: number) {
            return index;
        },
        audio_tiktok_id(index: number) {
            return `audio tiktok id: ${index}`;
        },
        audio_is_original() {
            return true;
        },
        created() {
            return new Date().toISOString();
        },
        audio_title(index: number) {
            return `audio title: ${index}`;
        },
        status() {
            return 'SUGGESTED';
        },
    }),
};

export const serializers = {
    tiktokSongSuggestion: makeSerializer<TiktokSongSuggestion>([]),
};

export function handleSongSuggestionRequests(server: AppServer) {
    const getTiktokSongSuggestionPath = '/api/tiktok/viewsets/song-suggestion/';
    server.get(getTiktokSongSuggestionPath, function (this: RouteHandlerContext, schema, request) {
        const tiktokSongSuggestions = schema
            .all('tiktokSongSuggestion')
            .filter(
                (s) =>
                    !request.queryParams.search ||
                    s.audio_title.toLowerCase().includes(request.queryParams.search.toLowerCase())
            );
        return buildPaginatedResponse(tiktokSongSuggestions, {
            url: getTiktokSongSuggestionPath,
            serialize: (resource) => this.serialize(resource, 'tiktokSongSuggestion'),
            queryParams: request.queryParams,
        });
    });
}
