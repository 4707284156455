import { AppServer } from '../mirage';
import * as roundClientUserMirage from './roundClientUser/roundClientUser.mirage';
import * as userMirage from './user/user.mirage';
import * as plannerUserMirage from './plannerUser/plannerUser.mirage';

export const models = {
    ...roundClientUserMirage.models,
    ...userMirage.models,
    ...plannerUserMirage.models,
};

export const serializers = {
    ...roundClientUserMirage.serializers,
    ...userMirage.serializers,
    ...plannerUserMirage.serializers,
};

export const factories = {
    ...roundClientUserMirage.factories,
    ...userMirage.factories,
    ...plannerUserMirage.factories,
};

export function handleRequests(server: AppServer) {
    roundClientUserMirage.handleRequests(server);
    userMirage.handleRequests(server);
    plannerUserMirage.handleRequests(server);
}
