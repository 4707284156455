import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokVideo } from './video.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokVideo: Model as ModelDefinition<TiktokVideo>,
};

export const serializers = {
    tiktokVideo: makeSerializer<TiktokVideo>([]),
};

export const factories = {
    tiktokVideo: createFactory<TiktokVideo>({
        tiktok_id(index: number) {
            return `tiktok id ${index}`;
        },
        author(index: number) {
            return index;
        },
        cover: '',
        desc(index: number) {
            return `desc ${index}`;
        },
        create_time: new Date().getTime() / 1000,
        duration: 0,
        download_address: null,
        effects: [],
        hashtags: [],
        dynamic_cover: null,
        is_ad: null,
        music(index: number) {
            return index;
        },
        location: null,
        origin_cover: '',
        play_address: null,
    }),
};

export function handleRequests(server: AppServer) {
    const listUrl = '/api/tiktok/viewsets/video/';
    server.get(listUrl, function (this: RouteHandlerContext, schema, request) {
        const videos = schema.all('tiktokVideo');
        return buildPaginatedResponse(videos, {
            url: listUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'tiktokVideo'),
        });
    });
}
