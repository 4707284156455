import { ReactComponent as AudioIcon } from 'assets/whitelabel/Audio.svg';
import { numberWithCommas, openInNewTab } from 'helpers';
import styles from './AudioGraph.module.css';
import cn from 'classnames';
import MiniLineChart from 'ui-new/whitelabel/Charts/MiniLineChart/MiniLineChart';
import moment from 'moment';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import { Point } from 'ui-new/whitelabel/Charts/types';

type Props = {
    audioUrl?: string;
    points: Point[];
    shouldDisplayTimeSeriesData: boolean;
    isTimeSeriesLoading: boolean;
};

const AudioGraph = ({ audioUrl, points, shouldDisplayTimeSeriesData, isTimeSeriesLoading }: Props) => {
    return (
        <div
            className={cn(styles.dailyChangeContainer, { [styles.pointer]: !!audioUrl })}
            onClick={() => {
                if (!audioUrl) {
                    return;
                }
                openInNewTab(audioUrl);
            }}
        >
            <AudioIcon className={styles.audioIcon} />

            <div className={styles.chartContainer}>
                <MiniLineChart
                    flatLineOnEmpty
                    lineColor="#2A94FF"
                    points={points}
                    renderTooltip={
                        shouldDisplayTimeSeriesData
                            ? (tooltipItem) => (
                                  <>
                                      <Tooltip.Title>{moment(tooltipItem.x).format('D MMM YYYY')}</Tooltip.Title>
                                      <Tooltip.Body>
                                          <dl className={styles.chartTooltipStats}>
                                              <div className={styles.chartTooltipStatsItem}>
                                                  <dt>Posts growth</dt>
                                                  <dd>{numberWithCommas(tooltipItem.y)}</dd>
                                              </div>
                                          </dl>
                                      </Tooltip.Body>
                                  </>
                              )
                            : undefined
                    }
                    isLoading={isTimeSeriesLoading}
                />
            </div>
        </div>
    );
};

export default AudioGraph;
