import { Platform } from '../../App.types';

export type AssetsLibraryPlatform = Extract<Platform, 'tiktok' | 'instagram'>;
export const isAssetsLibraryPlatform = (platform: string): platform is AssetsLibraryPlatform =>
    platform === 'tiktok' || platform === 'instagram';

export type Video = {
    id: number;
    name: string;
    notes: string;
    get_file_url: string;
    put_file_url: string;
    video_file: string | null;
    plan: number;
    is_approved: boolean;
};

export type VideoBodyPut = {
    id: number;
    name?: string;
    notes?: string;
    plan: number;
    is_approved?: boolean;
};

export type VideosBodyGet = {
    results: Video[];
};
