import { getTiktokUserImages, getTiktokUsers, TiktokUser, TiktokUserImage, microwave } from '@round/api';
import { useCallback, useMemo, useState } from 'react';
import useAbortableEffect from '../../../../../../Hooks/useAbortableEffect';
import { showNotification } from '../../../../../../helpers';
import useCreatorPlanTiktokAudios from '../../../../InfluencerPlan/contexts/TiktokCreatorsContext/useCreatorPlanTiktokAudios';
import { TiktokInfluencerPlanAudio } from '../../../../../../App.types';

type Params = Parameters<typeof microwave.getTiktokPostInvites>[0];
export type TiktokPostInviteData = microwave.TiktokMicroPostInvite & {
    user: TiktokUser | undefined;
    userImage: TiktokUserImage | undefined;
    audio: TiktokInfluencerPlanAudio | undefined;
};

export default function useTiktokPostInvites({
    page,
    page_size,
    creator_plan,
    ordering,
    is_posted,
    number_of_chase_emails_sent,
}: Params) {
    const { influencerPlanAudios } = useCreatorPlanTiktokAudios();

    const [contactedCreators, setContactedCreators] = useState<microwave.TiktokMicroPostInvite[]>([]);
    const [tiktokUsers, setTiktokUsers] = useState<TiktokUser[]>([]);
    const [tiktokUserImages, setTiktokUserImages] = useState<TiktokUserImage[]>([]);
    const [isInitialized, setIsInitialized] = useState(false);
    const [creatorsCount, setCreatorsCount] = useState(0);
    const [creatorsLoading, setCreatorsLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);

    useAbortableEffect(
        (signal) => {
            async function fetchData() {
                try {
                    setErrorLoading(false);
                    setCreatorsLoading(true);
                    const response = await microwave.getTiktokPostInvites(
                        { creator_plan, page, page_size, ordering, is_posted, number_of_chase_emails_sent },
                        { signal }
                    );

                    setContactedCreators(response.data.results);
                    setCreatorsCount(response.data.count);
                    setIsInitialized(true);
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    setErrorLoading(true);
                } finally {
                    setCreatorsLoading(false);
                }
            }

            fetchData();
        },
        [page, page_size, creator_plan, ordering, is_posted, number_of_chase_emails_sent]
    );

    useAbortableEffect(
        (signal) => {
            async function fetchUsers() {
                try {
                    const userIds = contactedCreators.map((item) => item.user_id);
                    const fetchedUsersIds = tiktokUsers.map((user) => user.id);
                    const usersToFetch = userIds.filter((id) => !fetchedUsersIds.includes(id));

                    const fetchedImagesUserIds = tiktokUserImages.map((image) => image.user_id);
                    const imagesToFetch = userIds.filter((id) => !fetchedImagesUserIds.includes(id));

                    if (usersToFetch.length) {
                        const usersResponse = await getTiktokUsers(
                            { id: userIds.join(), page_size: userIds.length },
                            { signal }
                        );
                        setTiktokUsers((users) =>
                            users.concat(usersResponse.status === 200 ? usersResponse.data.results : [])
                        );
                    }

                    if (imagesToFetch.length) {
                        const tiktokUserImages = await getTiktokUserImages(userIds, { signal });
                        setTiktokUserImages((images) => images.concat(tiktokUserImages));
                    }
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    showNotification('Could not fetch users data', 'error');
                }
            }

            fetchUsers();
        },
        [contactedCreators, tiktokUserImages, tiktokUsers]
    );

    const creators = useMemo(
        () =>
            contactedCreators.map<TiktokPostInviteData>((creator) => ({
                ...creator,
                user: tiktokUsers.find((user) => user.id === creator.user_id),
                userImage: tiktokUserImages.find((image) => image.user_id === creator.user_id),
                audio: influencerPlanAudios.find((audio) => audio.audio_id === creator.audio_id),
            })),
        [influencerPlanAudios, contactedCreators, tiktokUserImages, tiktokUsers]
    );

    const updatePostInvite = useCallback(async (id: string, data: microwave.PatchTiktokMicroPostInviteData) => {
        const response = await microwave.patchTiktokMicroPostInvite(id, data);
        if (response.status === 200) {
            setContactedCreators((creators) =>
                creators.map((creator) => {
                    if (creator.id === response.data.id) {
                        return response.data;
                    }

                    return creator;
                })
            );
        }

        return response;
    }, []);

    return {
        creators,
        count: creatorsCount,
        loading: creatorsLoading,
        errorLoading,
        isInitialized,
        updatePostInvite,
        reset: () => {
            setContactedCreators([]);
            setCreatorsCount(0);
            setCreatorsLoading(false);
            setErrorLoading(false);
            setIsInitialized(false);
        },
    };
}
