import React from 'react';
import { TiktokUser, TiktokUserImage } from '@round/api';
import { Image, Skeleton } from '@round/ui-kit';
import styles from './TiktokUserBadge.module.css';
import { buildTiktokUserUrl } from '../../../../helpers';

type Props = {
    isLoading: boolean;
    user: TiktokUser | null | undefined;
    image?: TiktokUserImage;
};

const TiktokUserBadge = ({ user, isLoading, image }: Props) => {
    const imageUrl = image?.avatar_thumb.cached_url || image?.avatar_thumb.original_url;
    const profileUrl = user?.unique_id ? buildTiktokUserUrl(user.unique_id) : undefined;
    const nickname = user?.nickname ?? '-';
    const username = user?.unique_id ? `@${user.unique_id}` : '-';

    return (
        <div className={styles.container}>
            <Image loading={isLoading} className={styles.image} src={imageUrl} alt={user?.nickname} />
            <div className={styles.details}>
                <span className={styles.nickname}>{isLoading ? <Skeleton width="100%" /> : nickname}</span>
                <a
                    className={styles.username}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (!profileUrl) {
                            return false;
                        }
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={profileUrl}
                >
                    {isLoading ? <Skeleton width="70%" /> : username}
                </a>
            </div>
        </div>
    );
};

export default TiktokUserBadge;
