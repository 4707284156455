import React, { HTMLProps, useState } from 'react';
import { ReactComponent as GalleryIcon } from '../../assets/Gallery.svg';
import styles from './Image.module.css';
import cn from 'classnames';
import Skeleton from '../Skeleton/Skeleton';

type ImageProps = HTMLProps<HTMLImageElement> & {
    Fallback?: (() => JSX.Element) | typeof GalleryIcon;
    loading?: boolean;
};

const Image = ({ loading, alt, src, crossOrigin, className, Fallback = GalleryIcon, ...props }: ImageProps) => {
    const [hasError, setHasError] = useState(false);

    if (loading) {
        return (
            <div className={cn(styles.container, className)}>
                <Skeleton circle />
            </div>
        );
    }

    if (!src || hasError) {
        return (
            <div className={cn(styles.container, className)}>
                <Fallback />
            </div>
        );
    }

    return (
        <img
            src={src}
            alt={alt}
            onError={() => {
                setHasError(true);
            }}
            className={cn(styles.image, className)}
            {...props}
        />
    );
};

export default Image;
