import styles from './OutstandingMediaPlanItems.module.css';
import { NavBar } from '../../../../SharedComponents/NavBar/NavBar';
import React, { useMemo, useState } from 'react';
import { MediaPlanItem } from '../../../../App.types';
import { asMoney, openInNewTab } from '../../../../helpers';
import { uniq } from 'lodash';
import { getMediaPlans, MediaPlan, usePaginatedFetch } from '@round/api';
import useUrlState from '../../../../Hooks/useUrlState';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import { getMediaPlanItems } from '../../../../Modules/Advertising/MediaPlan/api/MediaPlanItems.api';
import { CellProps, Column } from 'react-table';
import Skeleton from '../../../../ui/Skeleton/Skeleton';
import PaginationTable from '../../../../ui/PaginationTable/PaginationTable';
import Card from '../../../../ui/Card/Card';
import SearchInput from '../../../../ui/SearchInput/SearchInput';
import debounce from 'lodash/debounce';

type UrlState = Partial<{
    search: string;
    page: number;
    page_size: number;
}>;

const OutstandingMediaPlanItems = () => {
    const [loading, setLoading] = useState(false);
    const [mediaPlans, setMediaPlans] = useState<MediaPlan[]>([]);
    const [urlState, setUrlState] = useUrlState<UrlState>(
        {
            page: 1,
            page_size: 10,
            search: '',
        },
        {
            shouldSetMissingInitialValues: true,
        }
    );

    const { page: data, count, getCurrentPage } = usePaginatedFetch(getMediaPlanItems, {
        page: Number(urlState.page),
        page_size: Number(urlState.page_size),
        search: urlState.search ?? '',
        has_spend: true,
        no_invoice: true,
        excluded_clients: ['18', '38', '41', '42', '43', '44', '45', '46', '47', '48', '49'].join(','),
    });

    const getCurrentPageDebounced = useMemo(() => debounce(getCurrentPage, 700), [getCurrentPage]);

    useAbortableEffect(
        (signal) => {
            setLoading(true);
            getCurrentPageDebounced({ signal })?.catch(() => setLoading(false));
        },
        [getCurrentPageDebounced]
    );

    useAbortableEffect(
        (signal) => {
            const mediaPlanIds = uniq(data.map((item) => item.media_plan));
            if (!mediaPlanIds.length) {
                setLoading(false);
                return;
            }

            getMediaPlans({ id: mediaPlanIds.join(','), page_size: mediaPlanIds.length }, { signal })
                .then((response) => {
                    if (response.status === 200) {
                        setMediaPlans(response.data.results);
                    }
                })
                .catch(() => {})
                .finally(() => setLoading(false));
        },
        [data]
    );

    const columns: Column<MediaPlanItem>[] = useMemo(
        () => [
            {
                Header: 'Post ID',
                accessor: 'id',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Post Value',
                accessor: 'cost',
                Cell: ({ value, row: { original } }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    const mediaPlan = mediaPlans.find((plan) => plan.id === original.media_plan);
                    return <>{asMoney(Number(value), mediaPlan?.currency)}</>;
                },
            },
            {
                Header: 'Client Name',
                id: 'clientName',
                Cell: ({ row: { original } }: CellProps<MediaPlanItem>) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    const mediaPlan = mediaPlans.find((plan) => plan.id === original.media_plan);
                    return <>{mediaPlan?.release.brand.client.name ?? '-'}</>;
                },
            },
            {
                Header: 'Campaign Name',
                id: 'campaignName',
                Cell: ({ row: { original } }: CellProps<MediaPlanItem>) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    const mediaPlan = mediaPlans.find((plan) => plan.id === original.media_plan);
                    const campaignName = mediaPlan
                        ? `${mediaPlan.release.brand.name} - ${mediaPlan.release.name}`
                        : '-';
                    return <>{campaignName}</>;
                },
            },
            {
                Header: 'Platform & Format',
                accessor: 'format',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <>
                            {value.channel.name} - {value.name}
                        </>
                    );
                },
            },
        ],
        [loading, mediaPlans]
    );

    return (
        <>
            <NavBar />
            <Card className={styles.container}>
                <h1 className={styles.title}>Outstanding Media Plan Items</h1>
                <SearchInput
                    className={styles.searchInput}
                    value={urlState.search}
                    onChange={(search) => setUrlState({ search, page: 1 })}
                />
                <div>
                    <PaginationTable
                        noDataLabel="No data found"
                        loading={loading}
                        data={data}
                        columns={columns}
                        count={count}
                        page={Number(urlState.page)}
                        setPage={(page) => setUrlState({ page })}
                        pageSize={Number(urlState.page_size)}
                        setPageSize={(pageSize) => setUrlState({ page_size: pageSize ?? 10 })}
                        onRowClick={(row) => {
                            const mediaPlan = mediaPlans.find((plan) => plan.id === row.media_plan);
                            if (mediaPlan) {
                                openInNewTab(
                                    `${window.location.origin}/campaigns/${mediaPlan.release.id}/advertising/`
                                );
                            }
                        }}
                    />
                </div>
            </Card>
        </>
    );
};

export default OutstandingMediaPlanItems;
