import styles from './TextArea.module.css';
import React, { ChangeEvent, CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { FCWithChildren } from '../../utility/utility.types';

interface TextAreaProps {
    className?: string;
    disabled?: boolean;
    placeholder?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    autogrow?: boolean;
    name?: string;
    id?: string;
}

const MIN_HEIGHT_IN_PIXELS = 36;
const LINE_HEIGHT = 24;
const AUTO_RESIZE_UPPER_LIMIT = 300;

const TextArea: FCWithChildren<TextAreaProps> = ({
    className,
    placeholder,
    value,
    disabled,
    onChange,
    onBlur,
    name,
    autogrow,
    id,
}) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [textAreaHeightInPixels, setTextAreaHeightInPixels] = useState<number | null>(null);
    const [hasBeenResized, setHasBeenResized] = useState(false);

    const calculateHeight = useCallback((value: string) => {
        const numberOfLineBreaks = (value.match(/\n/g) || []).length;
        return MIN_HEIGHT_IN_PIXELS + numberOfLineBreaks * LINE_HEIGHT;
    }, []);

    const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const suggestedTextAreaHeight = calculateHeight(e.target.value);
        const shouldAutoResize =
            autogrow &&
            !hasBeenResized &&
            suggestedTextAreaHeight <= AUTO_RESIZE_UPPER_LIMIT &&
            e.target.offsetHeight <= suggestedTextAreaHeight;

        if (shouldAutoResize) {
            setTextAreaHeightInPixels(suggestedTextAreaHeight);
        }

        onChange(e);
    };

    const onMouseDownHandler = (e: React.MouseEvent<HTMLTextAreaElement>) => {
        if (e.currentTarget.offsetHeight !== textAreaHeightInPixels) {
            setHasBeenResized(true);
        }
    };

    useEffect(() => {
        if (textAreaRef.current && autogrow) {
            setTextAreaHeightInPixels(calculateHeight(textAreaRef.current.value));
        }
    }, [autogrow, calculateHeight]);

    const textAreaStyle: CSSProperties | undefined = useMemo(() => {
        if (autogrow) {
            return {
                height: textAreaHeightInPixels ? `${textAreaHeightInPixels}px` : 'auto',
                minHeight: `${MIN_HEIGHT_IN_PIXELS}px`,
                resize: 'vertical',
                boxSizing: 'border-box',
            };
        }
    }, [autogrow, textAreaHeightInPixels]);

    return (
        <textarea
            id={id}
            name={name}
            ref={textAreaRef}
            className={cn(styles.textarea, className)}
            style={textAreaStyle}
            onChange={onChangeHandler}
            onMouseDown={onMouseDownHandler}
            onBlur={onBlur}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
        />
    );
};

export default TextArea;
