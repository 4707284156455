import { useCallback, useState } from 'react';
import { getInstagramAudioMonitoring, InstagramAudioMonitoringInfo } from '@round/api';

export default function useInstagramAudioMonitoring(audioId: number) {
    const [monitoringInfo, setMonitoringInfo] = useState<InstagramAudioMonitoringInfo | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                setIsLoading(true);
                setError(null);
                const response = await getInstagramAudioMonitoring(audioId, requestInit);
                if (response.status === 404 || response.status === 403) {
                    setError(response.data.detail);
                    setIsInitialized(true);
                    return;
                }

                setMonitoringInfo(response.data);
                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setError(`Could not fetch monitoring info for audio ${audioId}`);
                setIsInitialized(true);
            } finally {
                setIsLoading(false);
            }
        },
        [audioId]
    );

    return {
        monitoringInfo,
        isLoading,
        isInitialized,
        error,
        init,
    };
}
