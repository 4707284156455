import { createContext, Dispatch, ReactNode, useCallback, useReducer } from 'react';
import { ContentTag, getContentTags } from '@round/api';
import { ReducerAction, ReducerActionWithPayload } from '../App.types';
import { createReducer } from '../helpers';
import useNonNullContext from '../Hooks/useNonNullContext';

type State = {
    contentTags: ContentTag[];
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
};

type Actions =
    | ReducerActionWithPayload<'initialized', ContentTag[]>
    | ReducerAction<'load'>
    | ReducerActionWithPayload<'error', string | null>;

const reducer = createReducer<State, Actions>({
    initialized: (state, { payload }) => ({
        ...state,
        contentTags: payload,
        error: null,
        isInitialized: true,
        isLoading: false,
    }),
    error: (state, { payload }) => ({
        ...state,
        error: payload,
        isInitialized: true,
        isLoading: false,
    }),
    load: (state) => ({
        ...state,
        isLoading: true,
    }),
});

const ContentTagsContext = createContext<State | null>(null);
const ContentTagsDispatchContext = createContext<Dispatch<Actions> | null>(null);

type Props = {
    children?: ReactNode | undefined;
};

export const ContentTagsProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, {
        contentTags: [],
        error: null,
        isInitialized: false,
        isLoading: false,
    });

    return (
        <ContentTagsContext.Provider value={state}>
            <ContentTagsDispatchContext.Provider value={dispatch}>{children}</ContentTagsDispatchContext.Provider>
        </ContentTagsContext.Provider>
    );
};

export function useContentTags() {
    const state = useNonNullContext(ContentTagsContext);
    const dispatch = useNonNullContext(ContentTagsDispatchContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'load' });
                const response = await getContentTags({}, requestInit);
                dispatch({ type: 'initialized', payload: response.data.results });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'error', payload: 'Could not fetch content tags' });
            }
        },
        [dispatch]
    );

    return {
        tags: state.contentTags,
        isLoading: state.isLoading,
        isInitialized: state.isInitialized,
        error: state.error,
        init,
    };
}
