import React from 'react';

import Modal from 'react-modal';

import styles from './Modal.module.css';
import { FCWithChildren } from '../../utility/utility.types';

type Props = {
    closeModal?: (event?: React.MouseEvent | any) => void;
    isModalOpen: boolean;
    customStyles?: string;
};

const RoundModal: FCWithChildren<Props> = ({ closeModal, isModalOpen, children, customStyles }) => {
    return (
        <Modal
            className={`${styles.Modal} ${customStyles}`}
            overlayClassName={styles.Overlay}
            isOpen={isModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={closeModal}
            ariaHideApp={false}
        >
            {children}
        </Modal>
    );
};

export default RoundModal;
