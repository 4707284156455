import React from 'react';
import Dropdown from 'ui-new/Dropdown/Dropdown';
import FilterBadgeClearable from 'ui/Filters/FilterBadge/FilterBadgeClearable/FilterBadgeClearable';
import FilterHeader from 'ui/Filters/FilterHeader/FilterHeader';
import styles from './TextFilter.module.css';
import { DebounceInput } from 'react-debounce-input';

const DEFAULT_DEBOUNCE_TIMEOUT = 700;

type Props = {
    name: string;
    icon: React.ReactNode;
    value: string;
    onChange: (value: string | null) => void;
    onClear: () => void;
    renderValue?: (value: string) => React.ReactNode;
    placeholder: string;
    debounceTimeout?: number;
    type?: 'text' | 'number' | 'email';
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
};

const defaultRenderValue = (value: string, name: string) => {
    return (
        <div className={styles.renderValue}>
            <span>{name}:</span>
            {value ? <span className={styles.value}>{value}</span> : <span>None</span>}
        </div>
    );
};

const TextFilter = ({
    name,
    icon,
    value,
    onChange,
    onClear,
    renderValue,
    debounceTimeout,
    placeholder,
    type,
    isOpen,
    onOpen,
    onClose,
}: Props) => (
    <Dropdown
        isOpen={isOpen}
        onClose={onClose}
        control={
            <FilterBadgeClearable onClick={onOpen} onClear={onClear}>
                {renderValue ? renderValue(value) : defaultRenderValue(value, name)}
            </FilterBadgeClearable>
        }
        className={styles.container}
    >
        <FilterHeader name={name} icon={icon} hasValue={!!value} onReset={() => onChange(null)} />
        <DebounceInput
            autoFocus
            type={type || 'text'}
            className={styles.input}
            debounceTimeout={debounceTimeout || DEFAULT_DEBOUNCE_TIMEOUT}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    </Dropdown>
);

export default TextFilter;
