import React from 'react';
import styles from './PaginationToolbar.module.css';
import range from 'lodash/range';
import cn from 'classnames';
import { StylesConfig } from 'react-select';
import { DEFAULT_INITIAL_PAGE_SIZE_OPTION, defaultPageSizeOptions as pageSizeOptions } from 'utility/constants';
import { ReactComponent as ArrowLeft } from 'assets/ChevronLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/ChevronRight.svg';
import Select from 'ui-new/whitelabel/Select/Select';
import Button from 'ui-new/whitelabel/Button/Button';

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        height: '2rem',
        minWidth: '5rem',
        borderRadius: '0.25rem',
        color: '#242424',
        fontSize: '0.75rem',
        fontWeight: 600,
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '0',
    }),
    option: (base) => ({
        ...base,
        fontSize: '0.75rem',
        color: '#242424',
    }),
};

type Props = {
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    page: number;
    setPage: (page: number) => void;
    count: number;
    isLoading?: boolean;
    className?: string;
};

export type PaginationProps = Pick<Props, 'page' | 'pageSize' | 'setPage' | 'setPageSize' | 'count'>;

function getPageRange(pageCount: number, page: number): [number, number] {
    if (!pageCount) {
        return [1, 1];
    }

    if (pageCount <= 5) {
        return [1, pageCount];
    }

    if (page <= 2) {
        return [1, 5];
    }

    if (page >= pageCount - 2) {
        return [pageCount - 4, pageCount];
    }

    return [page - 2, page + 2];
}

const PaginationToolbar = ({ setPage, pageSize = 10, setPageSize, page, count, isLoading, className }: Props) => {
    const pageCount = Math.ceil(count / pageSize);
    const [pageRangeStart, pageRangeEnd] = getPageRange(pageCount, page);
    const pageRange = range(pageRangeStart, pageRangeEnd + 1);

    const hasNextPage = page < pageCount;
    const hasPreviousPage = page > 1;

    return (
        <article className={cn(styles.container, className)}>
            <Select
                options={pageSizeOptions}
                value={pageSizeOptions.find((option) => option.value === pageSize) || DEFAULT_INITIAL_PAGE_SIZE_OPTION}
                onChange={(option) => setPageSize(option?.value || DEFAULT_INITIAL_PAGE_SIZE_OPTION.value)}
                styles={selectStyles}
                isSearchable={false}
                isDisabled={isLoading}
                isLoading={isLoading}
                menuPlacement="auto"
            />

            <div className={styles.pageControls}>
                <Button
                    appearance="primary"
                    className={styles.button}
                    disabled={!hasPreviousPage || isLoading}
                    onClick={() => setPage(page - 1)}
                >
                    <ArrowLeft />
                </Button>

                {!pageRange.includes(1) && (
                    <>
                        <Button
                            appearance="ghost"
                            className={styles.button}
                            onClick={() => setPage(1)}
                            disabled={isLoading || page === 1}
                        >
                            1
                        </Button>
                        {pageRangeStart !== 2 && <span>...</span>}
                    </>
                )}

                {pageRange.map((pageNumber) => (
                    <Button
                        appearance="ghost"
                        key={pageNumber}
                        className={styles.button}
                        onClick={() => setPage(pageNumber)}
                        disabled={isLoading || pageNumber === page}
                    >
                        {pageNumber}
                    </Button>
                ))}

                {pageCount > 1 && !pageRange.includes(pageCount) && (
                    <>
                        {!pageRange.includes(pageCount - 1) && <span>...</span>}
                        <Button
                            appearance="ghost"
                            className={styles.button}
                            onClick={() => setPage(pageCount)}
                            disabled={isLoading}
                        >
                            {pageCount}
                        </Button>
                    </>
                )}

                <Button
                    appearance="primary"
                    className={styles.button}
                    disabled={!hasNextPage || isLoading}
                    onClick={() => setPage(page + 1)}
                >
                    <ArrowRight />
                </Button>
            </div>
        </article>
    );
};

export default PaginationToolbar;
