import useNonNullContext from 'Hooks/useNonNullContext';
import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { FeatureFlagName, FeatureFlags, FEATURE_FLAGS, getFlags, saveFlags } from './featureFlags';

type State = FeatureFlags;

const FeatureFlagsContext = createContext<[State, Dispatch<SetStateAction<State>>] | null>(null);

type Props = { children?: ReactNode };
export const FeatureFlagsProvider = ({ children }: Props) => {
    const [flagsState, setFlagsState] = useState<FeatureFlags>(getFlags());

    const setFlags: typeof setFlagsState = (flags) => {
        setFlagsState(flags);
        saveFlags(typeof flags === 'function' ? flags(flagsState) : flags);
    };

    return <FeatureFlagsContext.Provider value={[flagsState, setFlags]}>{children}</FeatureFlagsContext.Provider>;
};

export function useFeatureFlags() {
    const [flags, setFlags] = useNonNullContext(FeatureFlagsContext);

    const isEnabled = (flag: FeatureFlagName) => {
        return Boolean(flags[flag]);
    };

    const setEnabled = (flag: FeatureFlagName, isEnabled: boolean) => {
        setFlags((prev) => ({ ...prev, [flag]: isEnabled }));
    };

    const getAll = () => FEATURE_FLAGS;

    return {
        isEnabled,
        setEnabled,
        getAll,
    };
}
