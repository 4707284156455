import { getTiktokMicroInfluencerPostLiveStats, microwave, TiktokMicroInfluencerPostLiveStats } from '@round/api';
import { ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer, showNotification } from 'helpers';
import useNonNullContext from 'Hooks/useNonNullContext';
import { Dispatch, createContext, ReactNode, useReducer, useCallback } from 'react';

type State = {
    isContactedCountInitialized: boolean;
    postedCount: number | null;
    unPostedCount: number | null;
    isMicroCampaignStatsInitialized: boolean;
    isMicroCampaignStatsLoading: boolean;
    hasMicroCampaignStatsError: boolean;
    microCampaignStats: TiktokMicroInfluencerPostLiveStats | null;
    pendingPostCount: number;
};

const initialState: State = {
    isContactedCountInitialized: false,
    postedCount: null,
    unPostedCount: null,
    microCampaignStats: null,
    hasMicroCampaignStatsError: false,
    isMicroCampaignStatsInitialized: false,
    isMicroCampaignStatsLoading: false,
    pendingPostCount: 0,
};

type Actions =
    | ReducerActionWithPayload<'setContactedCounts', Pick<State, 'postedCount' | 'unPostedCount'>>
    | ReducerAction<'errorFetchingContactedCounts'>
    | ReducerActionWithPayload<'microCampaignStatsInitialized', Pick<State, 'pendingPostCount' | 'microCampaignStats'>>
    | ReducerAction<'loadMicroCampaignStats'>
    | ReducerAction<'setHasMicroCampaignStatsError'>
    | ReducerAction<'refreshMicroCampaignStats'>;

const reducer = createReducer<State, Actions>({
    setContactedCounts: (state, { payload }) => ({
        ...state,
        isContactedCountInitialized: true,
        ...payload,
    }),
    errorFetchingContactedCounts: (state) => ({
        ...state,
        isContactedCountInitialized: true,
    }),
    microCampaignStatsInitialized: (state, { payload }) => ({
        ...state,
        ...payload,
        isMicroCampaignStatsLoading: false,
        isMicroCampaignStatsInitialized: true,
    }),
    loadMicroCampaignStats: (state) => ({
        ...state,
        isMicroCampaignStatsLoading: true,
        hasMicroCampaignStatsError: false,
        isMicroCampaignStatsInitialized: false,
    }),
    setHasMicroCampaignStatsError: (state) => ({
        ...state,
        isMicroCampaignStatsLoading: false,
        hasMicroCampaignStatsError: true,
        isMicroCampaignStatsInitialized: false,
    }),
    refreshMicroCampaignStats: (state) => ({
        ...state,
        isMicroCampaignStatsLoading: true,
        isMicroCampaignStatsInitialized: false,
        hasMicroCampaignStatsError: false,
    }),
});

const TiktokMicroCampaignStatsContext = createContext<[State, Dispatch<Actions>] | null>(null);

type Props = { children?: ReactNode | undefined };
export const TiktokMicroCampaignStatsProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <TiktokMicroCampaignStatsContext.Provider value={[state, dispatch]}>
            {children}
        </TiktokMicroCampaignStatsContext.Provider>
    );
};

export function useTikTokContactedCounts(planId: number | undefined) {
    const [state, dispatch] = useNonNullContext(TiktokMicroCampaignStatsContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (!planId) {
                return;
            }

            try {
                const [posted, unPosted] = await Promise.all([
                    microwave.getTiktokPostInvites(
                        { page: 1, page_size: 0, creator_plan: planId, is_posted: true },
                        requestInit
                    ),
                    microwave.getTiktokPostInvites(
                        { page: 1, page_size: 0, creator_plan: planId, is_posted: false },
                        requestInit
                    ),
                ]);

                dispatch({
                    type: 'setContactedCounts',
                    payload: { postedCount: posted.data.count, unPostedCount: unPosted.data.count },
                });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'errorFetchingContactedCounts' });
                throw e;
            }
        },
        [dispatch, planId]
    );

    return {
        postedCount: state.postedCount,
        unPostedCount: state.unPostedCount,
        isContactedCountInitialized: state.isContactedCountInitialized,
        init,
    };
}

export function useTiktokMicroCampaignStats(planId: number | undefined) {
    const [state, dispatch] = useNonNullContext(TiktokMicroCampaignStatsContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (!planId) {
                return;
            }

            try {
                dispatch({ type: 'loadMicroCampaignStats' });
                const [response, pendingPostsResponse] = await Promise.allSettled([
                    getTiktokMicroInfluencerPostLiveStats(planId, requestInit),
                    microwave.getTiktokMicrowaveInfluencerPosts({
                        plan_id: planId,
                        page: 1,
                        page_size: 0,
                        payment_request_status: 'PENDING',
                    }),
                ]);

                dispatch({
                    type: 'microCampaignStatsInitialized',
                    payload: {
                        microCampaignStats: response.status === 'fulfilled' ? response.value.data[0] : null,
                        pendingPostCount:
                            pendingPostsResponse.status === 'fulfilled' ? pendingPostsResponse.value.data.count : 0,
                    },
                });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                showNotification('Could not load micro campaign stats', 'error');
                dispatch({ type: 'setHasMicroCampaignStatsError' });
            }
        },
        [dispatch, planId]
    );

    const refresh = useCallback(() => dispatch({ type: 'refreshMicroCampaignStats' }), [dispatch]);

    return {
        microsStats: state.microCampaignStats,
        pendingPostCount: state.pendingPostCount,
        isInitialized: state.isMicroCampaignStatsInitialized,
        isLoading: state.isMicroCampaignStatsLoading,
        hasError: state.hasMicroCampaignStatsError,
        init,
        refresh,
    };
}
