import {
    Creative,
    FacebookCampaignMediaPlanItemRelationship,
    FacebookTargeting,
    MediaPlanItem,
    ReducerAction,
    ReducerActionWithPayload,
    Targeting,
} from '../../../../../App.types';
import { createReducer } from '../../../../../helpers';
import { MediaPlan, MediaPlanItemGroup } from '@round/api';

export type AdvertisingContextState = MediaPlanState & {
    initialized: boolean;
    mediaPlanItems: MediaPlanItem[];
    mediaPlanItemsLoading: boolean;
    errorLoadingMediaPlanItems: boolean;
    groups: MediaPlanItemGroup[];
    groupsLoading: boolean;
    targeting: Targeting[];
    facebookTargeting: FacebookTargeting[];
    creatives: Creative[];
    facebookCampaignRelationships: FacebookCampaignMediaPlanItemRelationship[];
    facebookAdAccountId: number | null;
};

export const initialState: AdvertisingContextState = {
    initialized: false,
    mediaPlan: null,
    mediaPlanLoading: true,
    errorLoadingMediaPlan: false,
    mediaPlanItems: [],
    mediaPlanItemsLoading: false,
    errorLoadingMediaPlanItems: false,
    groups: [],
    groupsLoading: false,
    targeting: [],
    facebookTargeting: [],
    creatives: [],
    facebookCampaignRelationships: [],
    facebookAdAccountId: null,
};

type AdvertisingActions =
    | ReducerAction<'initialized'>
    | ReducerAction<'reset'>
    | ReducerAction<'loadMediaPlan'>
    | ReducerAction<'loadMediaPlanFailed'>
    | ReducerActionWithPayload<'loadMediaPlanSucceeded', MediaPlan | null>
    | ReducerAction<'loadMediaPlanItems'>
    | ReducerAction<'loadMediaPlanItemsFailed'>
    | ReducerActionWithPayload<'loadMediaPlanItemsSucceed', MediaPlanItem[]>
    | ReducerAction<'loadGroups'>
    | ReducerActionWithPayload<'groupsLoaded', MediaPlanItemGroup[]>
    | ReducerActionWithPayload<'addGroup', MediaPlanItemGroup>
    | ReducerActionWithPayload<'deleteGroup', number>
    | ReducerActionWithPayload<'duplicateMediaPlanItem', MediaPlanItem>
    | ReducerActionWithPayload<'duplicateMediaPlanGroup', MediaPlanItemGroup>
    | ReducerActionWithPayload<'targetingLoaded', Targeting[]>
    | ReducerActionWithPayload<'facebookTargetingLoaded', FacebookTargeting[]>
    | ReducerActionWithPayload<'creativesLoaded', Creative[]>
    | ReducerActionWithPayload<'createMediaPlanItem', MediaPlanItem>
    | ReducerActionWithPayload<'deleteMediaPlanItems', number[]>
    | ReducerActionWithPayload<'updateMediaPlanItem', MediaPlanItem>
    | ReducerActionWithPayload<'facebookCampaignsLoaded', FacebookCampaignMediaPlanItemRelationship[]>
    | ReducerActionWithPayload<'unlinkFacebookCampaign', FacebookCampaignMediaPlanItemRelationship>
    | ReducerActionWithPayload<'facebookCampaignCreated', FacebookCampaignMediaPlanItemRelationship>
    | ReducerActionWithPayload<'addCreative', Creative>
    | ReducerActionWithPayload<'addFacebookTargeting', FacebookTargeting>
    | ReducerActionWithPayload<'addTargeting', Targeting>
    | ReducerActionWithPayload<'updateTargeting', Targeting>
    | ReducerActionWithPayload<'deleteTargeting', Targeting>
    | ReducerActionWithPayload<'updateFacebookTargeting', FacebookTargeting>
    | ReducerActionWithPayload<'deleteFacebookTargeting', FacebookTargeting>
    | ReducerActionWithPayload<'loadedFacebookAdAccountId', number>
    | ReducerActionWithPayload<'updateCreative', Creative>
    | ReducerActionWithPayload<'deleteCreative', Creative>
    | ReducerActionWithPayload<'setPlan', MediaPlan>
    | ReducerAction<'deletePlan'>
    | ReducerActionWithPayload<'updateGroup', MediaPlanItemGroup>;

export default createReducer<AdvertisingContextState, AdvertisingActions>({
    reset: () => initialState,
    initialized: (state) => ({
        ...state,
        initialized: true,
    }),
    loadMediaPlan: (state) => ({
        ...state,
        mediaPlanLoading: true,
        errorLoadingMediaPlan: false,
        mediaPlan: null,
    }),
    loadMediaPlanFailed: (state) => ({
        ...state,
        mediaPlanLoading: false,
        errorLoadingMediaPlan: true,
        mediaPlan: null,
    }),
    loadMediaPlanSucceeded: (state, action) => ({
        ...state,
        mediaPlanLoading: false,
        errorLoadingMediaPlan: false,
        mediaPlan: action.payload,
    }),
    loadMediaPlanItems: (state) => ({
        ...state,
        mediaPlanItemsLoading: true,
        errorLoadingMediaPlanItems: false,
    }),
    loadMediaPlanItemsFailed: (state) => ({
        ...state,
        mediaPlanItemsLoading: false,
        errorLoadingMediaPlanItems: true,
    }),
    loadMediaPlanItemsSucceed: (state, action) => ({
        ...state,
        mediaPlanItems: action.payload,
        mediaPlanItemsLoading: false,
    }),
    duplicateMediaPlanItem: (state, { payload }) => ({
        ...state,
        mediaPlanItems: state.mediaPlanItems.concat(payload),
    }),
    loadGroups: (state) => ({
        ...state,
        groupsLoading: true,
    }),
    groupsLoaded: (state, { payload }) => ({
        ...state,
        groupsLoading: false,
        groups: payload,
    }),
    addGroup: (state, { payload }) => ({
        ...state,
        groups: [...state.groups, payload],
    }),
    deleteGroup: (state, { payload }) => ({
        ...state,
        groups: state.groups.filter((group) => group.id !== payload),
    }),
    updateGroup: (state, { payload }) => ({
        ...state,
        groups: state.groups.map((group) => {
            if (group.id === payload.id) {
                return payload;
            }

            return group;
        }),
    }),
    duplicateMediaPlanGroup: (state, { payload }) => ({
        ...state,
        groups: state.groups.concat(payload),
    }),
    targetingLoaded: (state, { payload }) => ({
        ...state,
        targeting: payload,
    }),
    facebookTargetingLoaded: (state, { payload }) => ({
        ...state,
        facebookTargeting: payload,
    }),
    creativesLoaded: (state, { payload }) => ({
        ...state,
        creatives: payload,
    }),
    createMediaPlanItem: (state, { payload }) => ({
        ...state,
        mediaPlanItems: state.mediaPlanItems.concat(payload),
    }),
    deleteMediaPlanItems: (state, { payload }) => ({
        ...state,
        mediaPlanItems: state.mediaPlanItems.filter((item) => !payload.includes(item.id)),
    }),
    updateMediaPlanItem: (state, { payload }) => ({
        ...state,
        mediaPlanItems: state.mediaPlanItems.map((item) => (item.id === payload.id ? payload : item)),
    }),
    facebookCampaignsLoaded: (state, { payload }) => ({
        ...state,
        facebookCampaignRelationships: payload,
    }),
    unlinkFacebookCampaign: (state, { payload }) => ({
        ...state,
        facebookCampaignRelationships: state.facebookCampaignRelationships.filter((c) => c.id !== payload.id),
    }),
    facebookCampaignCreated: (state, { payload }) => ({
        ...state,
        facebookCampaignRelationships: state.facebookCampaignRelationships.concat(payload),
    }),
    addCreative: (state, { payload }) => ({
        ...state,
        creatives: state.creatives.concat(payload),
    }),
    addFacebookTargeting: (state, { payload }) => ({
        ...state,
        facebookTargeting: state.facebookTargeting.concat(payload),
    }),
    addTargeting: (state, { payload }) => ({
        ...state,
        targeting: state.targeting.concat(payload),
    }),
    updateTargeting: (state, { payload }) => ({
        ...state,
        targeting: state.targeting.map((targeting) => (targeting.id === payload.id ? payload : targeting)),
    }),
    deleteTargeting: (state, { payload }) => ({
        ...state,
        targeting: state.targeting.filter((targeting) => targeting.id !== payload.id),
    }),
    updateFacebookTargeting: (state, { payload }) => ({
        ...state,
        facebookTargeting: state.facebookTargeting.map((t) => (t.id === payload.id ? payload : t)),
    }),
    deleteFacebookTargeting: (state, { payload }) => ({
        ...state,
        facebookTargeting: state.facebookTargeting.filter((t) => t.id !== payload.id),
    }),
    loadedFacebookAdAccountId: (state, { payload }) => ({
        ...state,
        facebookAdAccountId: payload,
    }),
    updateCreative: (state, { payload }) => ({
        ...state,
        creatives: state.creatives.map((c) => (c.id === payload.id ? payload : c)),
    }),
    deleteCreative: (state, { payload }) => ({
        ...state,
        creatives: state.creatives.filter((c) => c.id !== payload.id),
    }),
    setPlan: (state, { payload }) => ({
        ...state,
        mediaPlanLoading: false,
        errorLoadingMediaPlan: false,
        mediaPlan: payload,
    }),
    deletePlan: () => ({
        ...initialState,
        mediaPlan: null,
        mediaPlanLoading: false,
        errorLoadingMediaPlan: false,
    }),
});

type MediaPlanLoading = { mediaPlan: null; mediaPlanLoading: true; errorLoadingMediaPlan: false };
type MediaPlanLoadingError = { mediaPlan: null; mediaPlanLoading: false; errorLoadingMediaPlan: true };
type MediaPlanLoaded = { mediaPlan: MediaPlan; mediaPlanLoading: false; errorLoadingMediaPlan: false };
type NoMediaPlan = { mediaPlan: null; mediaPlanLoading: false; errorLoadingMediaPlan: false };
type MediaPlanState = MediaPlanLoading | MediaPlanLoadingError | MediaPlanLoaded | NoMediaPlan;
