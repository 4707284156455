import { DropdownOptionWithSubLabel, Popover, Select } from '@round/ui-kit';
import { ValueType } from 'react-select';
import styles from './PhylloAudienceLocationsSelect.module.css';
import { useState } from 'react';
import { ReactComponent as DropdownIcon } from '../../../../../assets/ReactSelectDropdownArrow.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/Close.svg';
import { DebounceInput } from 'react-debounce-input';

export type PhylloLocationPercentageOption = DropdownOptionWithSubLabel<string> & {
    percentage: number | null;
};

type Props = {
    options: PhylloLocationPercentageOption[];
    value: PhylloLocationPercentageOption[];
    onChange: (value: PhylloLocationPercentageOption[]) => void;
    className: string;
    inputValue: string;
    onInputChange: (val: string) => void;
    onMenuScrollToBottom: () => void;
    isLoading: boolean;
    noOptionsMessage: () => string;
};

const PhylloAudienceLocationsMultiSelect = ({ options, value, onChange, className, ...selectProps }: Props) => {
    const [popoverRef, setPopoverRef] = useState<HTMLButtonElement | null>(null);
    const availableOptions = options.filter((opt) => !value?.map((opt) => opt.value).includes(opt.value));
    const selectedOptions = value ?? [];

    const handleAddNewLocation = (val: ValueType<PhylloLocationPercentageOption, false>) => {
        onChange(selectedOptions.concat(val as PhylloLocationPercentageOption));
    };

    const handleRemoveSelection = (value: string) => {
        onChange(selectedOptions.filter((opt) => opt.value !== value));
    };

    const handleChangePercentage = (percentage: number | null, option: PhylloLocationPercentageOption) => {
        onChange(
            selectedOptions.map((opt) => {
                if (opt.value === option.value) {
                    return {
                        ...option,
                        percentage,
                    };
                }
                return opt;
            })
        );
    };

    const selectedLabel = `${selectedOptions.length} location${selectedOptions.length > 1 ? 's' : ''} selected`;

    return (
        <>
            <button id="audienceLocations" ref={setPopoverRef} className={className}>
                {selectedOptions?.length > 0 ? (
                    <span>{selectedLabel}</span>
                ) : (
                    <span className={styles.placeholder}>Select audience locations...</span>
                )}
                <DropdownIcon />
            </button>
            <Popover
                anchorElement={popoverRef}
                showOn="click"
                options={{
                    placement: 'bottom-start',
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 8] } },
                        { name: 'recalcDependency', options: selectedOptions },
                    ],
                }}
            >
                <div className={styles.dropdown}>
                    {!!selectedOptions.length && (
                        <table>
                            <tr>
                                <th>Location</th>
                                <th>Min Percentage</th>
                                <th>
                                    <CloseIcon onClick={() => onChange([])} className={styles.removeAllIcon} />
                                </th>
                            </tr>
                            {selectedOptions.map((currentOption, index) => (
                                <tr>
                                    <td className={styles.labels}>
                                        <div>{currentOption.label}</div>
                                        <div>{currentOption.subLabel}</div>
                                    </td>

                                    <td>
                                        <DebounceInput
                                            type="number"
                                            onChange={(e) =>
                                                handleChangePercentage(
                                                    parseFloat(e.target.value) || null,
                                                    currentOption
                                                )
                                            }
                                            value={currentOption.percentage ?? ''}
                                            debounceTimeout={700}
                                        />
                                    </td>
                                    <td>
                                        <CloseIcon
                                            onClick={() => handleRemoveSelection(currentOption.value)}
                                            className={styles.removeIcon}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </table>
                    )}
                    <Select
                        options={availableOptions}
                        value={null}
                        onChange={(val) => handleAddNewLocation(val)}
                        menuPortalTarget={null}
                        {...selectProps}
                        placeholder="Add location..."
                    />
                </div>
            </Popover>
        </>
    );
};

export default PhylloAudienceLocationsMultiSelect;
