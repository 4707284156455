import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TrndsClient } from './clients.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    trndsClient: Model as ModelDefinition<TrndsClient>,
};

export const serializers = {
    trndsClient: makeSerializer<TrndsClient>([]),
};

export const factories = {
    trndsClient: createFactory<TrndsClient>({
        name(index: number) {
            return `name ${index}`;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const getUserClients = '/api/trnds/user-clients/';
    server.get(getUserClients, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('trndsClient'), {
            url: getUserClients,
            serialize: (resource) => this.serialize(resource, 'trndsClient'),
            queryParams: request.queryParams,
        });
    });
}
