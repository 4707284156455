import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { creatorbase, influencer } from '@round/api';
import { PublicReportContext } from '../../../PublicReportContext';
import { isNumber } from 'utility/utility';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import { makeTiktokAccountsDataHook } from 'Modules/TikTok/hooks/dataHooks/useTiktokAccountsDataHook';

const useTiktokAccountsData = makeTiktokAccountsDataHook(PublicReportContext, ([values]) => values.tiktok.accounts);

export function usePublicReportTiktokPosts(campaignId: number) {
    const postsState = useNonNullContextSelector(PublicReportContext, ([values]) => values.posts[campaignId]);
    const dispatch = useNonNullContextSelector(PublicReportContext, ([, dispatch]) => dispatch);

    const { data: accountsData, fetchData: fetchAccountsData } = useTiktokAccountsData();

    const fetchData = useCallback(
        async (publicReportId: string, requestInit?: RequestInit) => {
            if (!campaignId) {
                return;
            }

            const publicIdRequestInit = influencer.addPublicReportIdToRequestInit(publicReportId, requestInit || {});
            const publicIdRequestInitWithoutSignal = omit(publicIdRequestInit, ['signal']);

            try {
                dispatch({ type: 'loadPosts', payload: campaignId });
                const response = await creatorbase.getPosts(
                    { campaign_id: campaignId.toString(), page_size: 1000, ordering: '-view_count' },
                    publicIdRequestInit
                );

                if (response.status === 200) {
                    dispatch({
                        type: 'postsSuccess',
                        payload: { campaignId: campaignId, posts: response.data.results },
                    });

                    const userIds = uniq(
                        response.data.results.map((post) => post.tiktok_details?.account_id).filter(isNumber)
                    );
                    const userIdsToFetch = userIds.filter((userId) => !accountsData.data[userId]?.data);

                    fetchAccountsData(userIdsToFetch, publicIdRequestInitWithoutSignal);

                    return response;
                }

                dispatch({
                    type: 'errorLoadingPosts',
                    payload: { campaignId: campaignId, message: response.data.detail },
                });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'postsIdle', payload: campaignId });
                    throw e;
                }
                dispatch({
                    type: 'errorLoadingPosts',
                    payload: { campaignId: campaignId, message: 'Could not get posts' },
                });
                throw e;
            }
        },
        [campaignId, dispatch, fetchAccountsData, accountsData]
    );

    return {
        postsData: postsState,
        accountsData: accountsData.data,
        fetchData,
    };
}
