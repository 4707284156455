import { Genre } from './genres.types';
import { ModelDefinition } from 'miragejs/-types';
import { Model } from 'miragejs';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    genre: Model as ModelDefinition<Genre>,
};

export const serializers = {
    genre: makeSerializer<Genre>([]),
};

export const factories = {
    genre: createFactory<Genre>({
        label: (index: number) => `Label ${index}`,
        value: (index: number) => `Value ${index}`,
    }),
};

export function handleRequests(server: AppServer) {
    const baseUrl = '/api/genre-affinity/genres/';
    server.get(baseUrl, function (this: RouteHandlerContext, schema, request) {
        const genres = schema.all('genre');
        return { results: genres };
    });
}
