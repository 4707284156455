import { ApiResponse, NotFoundResponse } from '../../types';
import { fetchWithToken } from '../../helpers';
import { PhylloUserAnalyticsData } from '../phyllo.types';

export type GetInstagramUserAnalyticsResponse =
    | NotFoundResponse
    | ApiResponse<{ created: string; data: PhylloUserAnalyticsData; user_id: number }, 200>;
export async function getInstagramUserAnalytics(
    userId: number,
    requestInit?: RequestInit
): Promise<GetInstagramUserAnalyticsResponse> {
    const response = await fetchWithToken(`/api/creator-data/instagram-user-analytics/${userId}/`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not fetch instagram user analytics');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
