import { ModelDefinition } from 'miragejs/-types';
import { Model } from 'miragejs';
import { AppServer } from '../../mirage';
import { TiktokUserStats } from './tiktokUserStats.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokUserStats: Model as ModelDefinition<TiktokUserStats>,
};

export const serializers = {
    tiktokUserStats: makeSerializer<TiktokUserStats>([]),
};

export const factories = {
    tiktokUserStats: createFactory<TiktokUserStats>({
        id(index: number) {
            return index;
        },
        user_id(index: number) {
            return index * 10;
        },
        timestamp() {
            return new Date().toISOString();
        },
        follower_count(index: number) {
            return index * 100;
        },
        following_count(index: number) {
            return index * 10;
        },
        heart(index: number) {
            return index * 1000;
        },
        heart_count(index: number) {
            return index * 1000;
        },
        video_count(index: number) {
            return index * 100;
        },
        digg_count(index: number) {
            return index * 1000;
        },
    }),
};

export function handleTiktokUserStatsRequests(server: AppServer) {
    server.get(`/api/tiktok/user-stats/`, function (this: RouteHandlerContext, schema, request) {
        const { user_ids } = request.queryParams;
        const TiktokUserStats = schema.all('tiktokUserStats').filter((stat) =>
            user_ids
                .split(',')
                .map((id) => Number(id))
                .includes(stat.user_id)
        );
        return buildPaginatedResponse(TiktokUserStats, {
            url: `/api/tiktok/user-stats/`,
            serialize: (resource) => this.serialize(resource, 'tiktokUserStats'),
            queryParams: request.queryParams,
        });
    });
}
