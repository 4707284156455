import { Filter } from 'ui-new/Filters/useFilterManager';
import { ReactComponent as PlanIcon } from 'assets/icons/DocumentText.svg';
import Filters from 'ui-new/Filters/Filters';
import Dropdown from 'ui-new/Dropdown/Dropdown';
import React from 'react';
import InfluencerPlanBadge from 'Modules/Advertising/InfluencerPlan/components/InfluencerPlanBadge/InfluencerPlanBadge';
import FilterBadgeClearable from 'ui/Filters/FilterBadge/FilterBadgeClearable/FilterBadgeClearable';
import FilterHeader from 'ui/Filters/FilterHeader/FilterHeader';
import { Select } from '@round/ui-kit';
import useUrlState from 'Hooks/useUrlState';
import { StylesConfig } from 'react-select';
import { usePlanSelect } from 'Modules/Advertising/InfluencerPlan/hooks/usePlanSelect';
import { getInitialFilters } from 'ui-new/Filters/helpers';
import { getNonEmptyKeys } from 'utility/utility';

type UrlState = {
    plan: number;
};

const selectStyles: StylesConfig = {
    menu: (provided) => ({
        ...provided,
        position: 'unset',
        boxShadow: 'none',
    }),
    control: (provided) => ({
        ...provided,
        borderRadius: '0.75rem',
        border: '1px solid #D1D5DB',
        cursor: 'text',
        minWidth: '14rem',
    }),
};

const FiltersPlayground = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>();
    const selectedPlanId = urlState.plan ? Number(urlState.plan) : undefined;
    const planSelectProps = usePlanSelect({ initialPlanIdData: selectedPlanId, isMulti: false });

    const filters: Filter<keyof UrlState>[] = [
        {
            name: 'plan',
            label: 'Plan',
            icon: PlanIcon,
            render(this, helpers) {
                return (
                    <Dropdown
                        isOpen={helpers.isOpen}
                        onClose={helpers.close}
                        control={
                            <FilterBadgeClearable
                                onClick={helpers.open}
                                onClear={() => {
                                    planSelectProps.onChange(null);
                                    helpers.remove();
                                    setUrlState({ plan: undefined });
                                }}
                            >
                                <div>
                                    <span>{this.label}: </span>
                                    <span>{planSelectProps.value?.label || 'None'}</span>
                                </div>
                            </FilterBadgeClearable>
                        }
                    >
                        <FilterHeader
                            name={this.name}
                            icon={<PlanIcon />}
                            hasValue={!!planSelectProps.value}
                            onReset={() => {
                                planSelectProps.onChange(null);
                                setUrlState({ plan: undefined });
                            }}
                        />
                        <Select
                            {...planSelectProps}
                            onChange={(value) => {
                                planSelectProps.onChange(value);
                                setUrlState({ plan: value?.value });
                            }}
                            styles={selectStyles}
                            menuIsOpen={!!planSelectProps.inputValue.length}
                            menuPortalTarget={null}
                            placeholder="Search plan"
                            components={{
                                IndicatorSeparator: null,
                                DropdownIndicator: null,
                            }}
                            formatOptionLabel={(option) => (
                                <InfluencerPlanBadge
                                    planInfo={{
                                        releaseId: option.releaseId ?? undefined,
                                        releaseName: option.label ?? '',
                                        artistName: option.artistName ?? '',
                                        imageUrl: option.imageUrl ?? '',
                                    }}
                                />
                            )}
                            controlShouldRenderValue={false}
                        />
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <Filters
            sessionKey="filters-playground"
            filters={filters}
            options={{ initialFilters: (state) => getInitialFilters(state, getNonEmptyKeys(urlState)) }}
        />
    );
};

export default FiltersPlayground;
