import { DataStateError, DataStateIdle, DataStateLoading, DataStateSuccess } from 'App.types';

function makeSuccessState<T>(data: T): DataStateSuccess<T> {
    return { status: 'success', error: null, data };
}

function makeLoadingState<T>(data?: T): DataStateLoading<T> {
    return { status: 'loading', error: null, data: data ?? null };
}

function makeErrorState<T>(error: string, data?: T): DataStateError<T> {
    return { status: 'error', data: data ?? null, error };
}

function makeIdleState<T>(data?: T): DataStateIdle<T> {
    return { status: 'idle', data: data ?? null, error: null };
}

type Keys = string | number | symbol;
export function makeLoadingStateSlice<K extends Keys, Data>(
    keys: K[],
    getData?: (key: K) => Data
): Record<K, DataStateLoading<Data>> {
    return keys.reduce((state, key) => {
        state[key] = makeLoadingState(getData?.(key));
        return state;
    }, {} as Record<K, DataStateLoading<Data>>);
}

export function makeSuccessStateSlice<K extends Keys, Data>(
    keys: K[],
    getData: (key: K) => Data
): Record<K, DataStateSuccess<Data>> {
    return keys.reduce((state, key) => {
        state[key] = makeSuccessState(getData(key));
        return state;
    }, {} as Record<K, DataStateSuccess<Data>>);
}

export function makeErrorStateSlice<K extends Keys, Data>(
    keys: K[],
    error: ((key: K) => string) | string,
    getData?: (key: K) => Data
): Record<K, DataStateError<Data>> {
    return keys.reduce((state, key) => {
        state[key] = makeErrorState(typeof error === 'function' ? error(key) : error, getData?.(key));
        return state;
    }, {} as Record<K, DataStateError<Data>>);
}

export function makeIdleStateSlice<K extends Keys, Data>(
    keys: K[],
    getData?: (key: K) => Data
): Record<K, DataStateIdle<Data>> {
    return keys.reduce((state, key) => {
        state[key] = makeIdleState(getData?.(key));
        return state;
    }, {} as Record<K, DataStateIdle<Data>>);
}
