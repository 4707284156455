import LibSelect, { mergeStyles, OptionTypeBase, Props, SelectComponentsConfig } from 'react-select';
import { baseSelectStyles } from './helpers';

export type SelectProps<T extends OptionTypeBase, isMulti extends boolean = false> = Props<T, isMulti>;

/**
 * A wrapper component around `react-select` library to provide some default styles and behavior.
 *
 * <blockquote>NOTE: Needs `react-select@3.1.1` and `@types/react-select@3.0.27` installed.</blockquote>
 */
const Select = <T extends OptionTypeBase, IsMulti extends boolean = false>({
    components,
    defaultValue,
    styles: selectStyles,
    className,
    menuPortalTarget = document.body,
    isMulti,
    closeMenuOnSelect,
    ...rest
}: SelectProps<T, IsMulti>) => {
    const styles = selectStyles ? mergeStyles(baseSelectStyles, selectStyles) : baseSelectStyles;
    const overriddenComponents: SelectComponentsConfig<T, IsMulti> = {
        IndicatorSeparator: null,
        ...components,
    };

    return (
        <LibSelect
            menuPortalTarget={menuPortalTarget}
            {...rest}
            styles={styles}
            className={className}
            components={overriddenComponents}
            isMulti={isMulti}
            closeMenuOnSelect={closeMenuOnSelect || !isMulti}
        />
    );
};

export default Select;
