import { ClientSimple, RoundClientUser, getClients, getRoundClientUsers } from '@round/api';
import { useCallback, useState } from 'react';
import uniq from 'lodash/uniq';
import { showNotification } from '../../../../helpers';

type UseRoundClientUsersParams = Parameters<typeof getRoundClientUsers>[0];

export function useRoundClientUsers({ page, page_size, search, client_id }: UseRoundClientUsersParams) {
    const [roundClientUsers, setRoundClientUsers] = useState<RoundClientUser[]>([]);
    const [clients, setClients] = useState<ClientSimple[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [count, setCount] = useState(0);

    const fetchRoundClientUsersData = useCallback(
        async (params: UseRoundClientUsersParams, requestInit?: RequestInit) => {
            setIsLoading(true);
            setHasError(false);
            try {
                const {
                    data: { results, count, next },
                    status,
                } = await getRoundClientUsers(params, requestInit);

                if (status !== 200) {
                    throw new Error();
                }

                setRoundClientUsers(results);
                setCount(count);
                setHasNextPage(Boolean(next));

                const clientIds = uniq(results.map((user) => user.client_ids).flat());
                if (!clientIds.length) {
                    setIsInitialized(true);
                    return;
                }
                const {
                    data: { results: clientResults },
                } = await getClients({ id: clientIds.join(','), page_size: clientIds.length }, requestInit);
                setClients(clientResults);
                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }
                setHasError(true);
                showNotification("couldn't load round client users", 'error');
                throw e;
            } finally {
                setIsLoading(false);
            }
        },
        []
    );

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            fetchRoundClientUsersData({ page_size, page, search, client_id }, requestInit);
        },
        [page, page_size, search, client_id, fetchRoundClientUsersData]
    );

    const reset = useCallback(() => {
        setRoundClientUsers([]);
        setClients([]);
        setIsLoading(false);
        setIsInitialized(false);
        setHasNextPage(false);
        setHasError(false);
        setCount(0);
    }, []);

    const setClientUsers = useCallback((user: RoundClientUser, clients: ClientSimple[]) => {
        if (clients.length) {
            setClients((prev) =>
                prev.concat(clients.filter((client) => !prev.find((prevClient) => prevClient.id === client.id)))
            );
        }
        setRoundClientUsers((prev) => prev.map((prevUser) => (prevUser.id === user.id ? user : prevUser)));
    }, []);

    const removeClientUser = useCallback((id: number) => {
        setRoundClientUsers((prev) => prev.filter((prevUser) => prevUser.id !== id));
    }, []);

    return {
        roundClientUsers,
        clients,
        isLoading,
        isInitialized,
        hasNextPage,
        hasError,
        count,

        init,
        reset,
        setClientUsers,
        removeClientUser,
    };
}
