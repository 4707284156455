import React, { FunctionComponent, useMemo, useState } from 'react';
import styles from './CreateMonitoredUserModal.module.css';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import Modal from '../Modal/Modal';
import ModalContent from '../Modal/ModalContent/ModalContent';
import ModalHeading from '../Modal/ModalHeading/ModalHeading';
import ModalBody from '../Modal/ModalBody/ModalBody';
import { fetchWithToken } from '../../helpers';
import { MonitoredUser } from '../../App.types';
import ModalFormField from '../Modal/ModalFormField/ModalFormField';
import CreateSubmitButton from '../CreateSubmitButton/CreateSubmitButton';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { Label } from '../Forms/Label/Label';
import TextArea from '../Forms/TextArea/TextArea';

type FormProps = {
    username: string;
    notes?: string;
};

const CreateMonitoredUserModal: FunctionComponent<{
    closeModal: () => void;
    isModalOpen: boolean;
    postUrl: string;
    showNotesField?: boolean;
}> = ({ closeModal, isModalOpen, postUrl, showNotesField = false }) => {
    const [statusMessage, setStatusMessage] = useState<string>();
    const initialValues = useMemo(() => {
        const values: FormProps = {
            username: '',
        };

        if (showNotesField) {
            values.notes = '';
        }

        return values;
    }, [showNotesField]);

    return (
        <Modal
            isModalOpen={isModalOpen}
            closeModal={() => {
                setStatusMessage(undefined);
                closeModal();
            }}
        >
            <ModalContent>
                <ModalHeading>New Account</ModalHeading>
                <ModalBody>
                    {statusMessage !== undefined && <div className={styles.status}>{statusMessage}</div>}
                    <Formik
                        initialValues={initialValues}
                        onSubmit={async (values: FormProps, { setSubmitting }: FormikHelpers<FormProps>) => {
                            setSubmitting(true);
                            setStatusMessage(undefined);

                            const response = await fetchWithToken(postUrl, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(values),
                            });
                            if (response.ok) {
                                const resBody = (await response.json()) as MonitoredUser;
                                setStatusMessage(`Successfully added account: ${resBody.username}`);
                                setSubmitting(false);
                            } else {
                                const errorMessage = await response.json();
                                setStatusMessage(`Error adding new account: ${JSON.stringify(errorMessage)}`);
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ isSubmitting }) => {
                            return (
                                <Form>
                                    <ModalFormField>
                                        <Label htmlFor="username">Username</Label>
                                        <Field id="username" name="username" />
                                    </ModalFormField>

                                    {showNotesField && (
                                        <ModalFormField>
                                            <Label htmlFor="notes">Notes</Label>
                                            <TextArea name="notes" />
                                        </ModalFormField>
                                    )}

                                    <CreateSubmitButton disabled={isSubmitting}>
                                        {isSubmitting ? (
                                            <LoadingSpinner containerClassName={styles.loadingSpinnerContainer} />
                                        ) : (
                                            'Add account'
                                        )}
                                    </CreateSubmitButton>
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreateMonitoredUserModal;
