import { NavElement, NavItemGroup, isNavItemGroup } from 'Modules/Navigation/whitelabel/routes';
import styles from './NavSidebar.module.css';
import { useContext, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as RoundLogoShort } from 'assets/brand/LogoShort.svg';
import { ReactComponent as SonyMusic } from 'assets/brand/SonyMusic.svg';
import { ReactComponent as ArrowIcon } from 'assets/ChevronRight.svg';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from 'Modules/Auth/contexts/AuthContext';
import { ReactComponent as LogoutIcon } from 'assets/Logout.svg';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';

type Props = {
    items: NavElement[];
};

const NavSidebar = ({ items }: Props) => {
    const authContext = useContext(AuthContext);
    const location = useLocation();
    const isCurrentPath = (path: string) => location.pathname.includes(path);

    const [isExpanded, setIsExpanded] = useState(false);
    const [leafItemsIndex, setLeafItemsIndex] = useState<number | null>(null);
    const [delay, setDelay] = useState<NodeJS.Timeout | null>(null);

    const withDelay = (callback: () => void, timeout: number) => {
        setDelay(setTimeout(callback, timeout));
    };

    const selectedLeafItems = (items.find((item, index) => isNavItemGroup(item) && index === leafItemsIndex) as
        | NavItemGroup
        | undefined)?.items;

    const shouldShowRoundLink = useCheckUserGroupsAccess(['round_product']);

    return (
        <nav
            className={styles.container}
            onMouseLeave={() => {
                setIsExpanded(false);
                setLeafItemsIndex(null);
            }}
        >
            {/* This dictates how much width the navbar takes up from the main page. It's hidden, and allows us
                to expand the nav container with absolute positioning without disrupting the page layout */}
            <div className={styles.spine} />

            <div className={cn(styles.navContainer, { [styles.isExpanded]: isExpanded })}>
                <div className={styles.logoContainer}>
                    <div className={styles.logoInline}>
                        <RoundLogoShort className={styles.roundLogo} /> {isExpanded ? 'Round' : ''}
                    </div>
                    <hr />
                    <div className={styles.logoInline}>
                        <SonyMusic className={styles.sonyLogo} /> {isExpanded ? 'Sony Music' : ''}
                    </div>
                </div>

                <ul className={styles.navList}>
                    {items.map((item, index) => {
                        const Icon = item.icon;

                        if (isNavItemGroup(item)) {
                            return (
                                <li
                                    className={cn(styles.navItem, {
                                        [styles.active]:
                                            item.items.some((item) => isCurrentPath(item.url)) ||
                                            index === leafItemsIndex,
                                    })}
                                    key={item.title}
                                    onMouseEnter={() => {
                                        withDelay(() => {
                                            setIsExpanded(true);
                                            setLeafItemsIndex(index);
                                        }, 100);
                                    }}
                                    onMouseLeave={() => {
                                        if (delay) {
                                            clearTimeout(delay);
                                        }
                                    }}
                                >
                                    <Icon className={styles.navIcon} />

                                    {isExpanded && (
                                        <>
                                            <span className={styles.navTitle}>{item.title}</span>
                                            <ArrowIcon className={styles.arrowIcon} />
                                        </>
                                    )}
                                </li>
                            );
                        }

                        return (
                            <Link to={item.url} key={item.url}>
                                <li
                                    className={cn(styles.navItem, {
                                        [styles.active]: isCurrentPath(item.url),
                                    })}
                                    onMouseEnter={() => {
                                        withDelay(() => {
                                            setLeafItemsIndex(null);
                                            setIsExpanded(true);
                                        }, 100);
                                    }}
                                    onMouseLeave={() => {
                                        if (delay) {
                                            clearTimeout(delay);
                                        }
                                    }}
                                >
                                    <Icon className={styles.navIcon} />
                                    {isExpanded && <span className={styles.navTitle}>{item.title}</span>}
                                </li>
                            </Link>
                        );
                    })}
                </ul>

                <ul className={styles.staticList}>
                    {shouldShowRoundLink && (
                        <Link to={'/campaigns/'}>
                            <li
                                className={cn(styles.navItem, styles.logout)}
                                onMouseEnter={() => {
                                    withDelay(() => {
                                        setLeafItemsIndex(null);
                                        setIsExpanded(true);
                                    }, 100);
                                }}
                                onMouseLeave={() => {
                                    if (delay) {
                                        clearTimeout(delay);
                                    }
                                }}
                            >
                                <RoundLogoShort className={styles.navIcon} />
                                {isExpanded && <span className={styles.navTitle}>Round Dashboard</span>}
                            </li>
                        </Link>
                    )}

                    <li
                        className={cn(styles.navItem, styles.logout)}
                        onClick={() => authContext.logout?.()}
                        onMouseEnter={() => {
                            withDelay(() => {
                                setLeafItemsIndex(null);
                                setIsExpanded(true);
                            }, 100);
                        }}
                        onMouseLeave={() => {
                            if (delay) {
                                clearTimeout(delay);
                            }
                        }}
                    >
                        <LogoutIcon className={styles.navIcon} />
                        {isExpanded && <span className={styles.navTitle}>Logout</span>}
                    </li>
                </ul>
            </div>

            {!!selectedLeafItems?.length && (
                <div className={styles.leaf}>
                    <ul className={styles.navList}>
                        {selectedLeafItems.map((item) => {
                            return (
                                <a href={item.url} key={item.url}>
                                    <li
                                        className={cn(styles.navItem, {
                                            [styles.active]: isCurrentPath(item.url),
                                        })}
                                        key={item.url}
                                    >
                                        <span>{item.title}</span>
                                    </li>
                                </a>
                            );
                        })}
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default NavSidebar;
