import { hasMany, Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokMicroInfluencerPost, TiktokMicroInfluencerPostLiveStats } from './liveStats.types';
import { createFactory, makeSerializer } from '../../../mirage.helpers';
import { AppServer } from '../../../mirage';

export const models = {
    tiktokMicroInfluencerPost: Model as ModelDefinition<TiktokMicroInfluencerPost>,
    tiktokMicroInfluencerPostLiveStats: Model.extend({
        posts: hasMany('tiktokMicroInfluencerPost'),
    }) as ModelDefinition<TiktokMicroInfluencerPostLiveStats>,
};

export const serializers = {
    tiktokMicroInfluencerPost: makeSerializer<TiktokMicroInfluencerPost>([]),
    tiktokMicroInfluencerPostLiveStats: makeSerializer<TiktokMicroInfluencerPostLiveStats>(['posts']),
};

export const factories = {
    tiktokMicroInfluencerPost: createFactory<TiktokMicroInfluencerPost>({
        author_username: (index: number) => `username ${index}`,
        url: (index: number) => `url ${index}`,
        comment_count: (index: number) => index,
        digg_count: (index: number) => index,
        play_count: (index: number) => index,
        share_count: (index: number) => index,
    }),
    tiktokMicroInfluencerPostLiveStats: createFactory<TiktokMicroInfluencerPostLiveStats>({
        name: (index: number) => `name ${index}`,
        post_count: (index: number) => index * 10,
        cpm: (index: number) => index,
        engagement_rate: (index: number) => index,
        budget: (index: number) => index * 100,
        amount_spent: (index: number) => index * 10,
        posts: [],
        post_count_target: (index: number) => index,
        total_views: (index: number) => index,
    }),
};

export function handleRequests(server: AppServer) {
    server.get('/api/advertising/creator-plans/:planId/tiktok-micro-influencer-post-live-stats', (schema) => {
        return schema.all('tiktokMicroInfluencerPostLiveStats');
    });
}
