import { GenericDropdownOption } from 'App.types';
import { OrderingValues, PhylloTiktokCreatorProfilesSearchSortableFields } from '@round/api';

export const sortableOptions: GenericDropdownOption<
    OrderingValues<PhylloTiktokCreatorProfilesSearchSortableFields>
>[] = [
    { value: '-average_views', label: 'Average views (Desc)' },
    { value: '-follower_count', label: 'Follower count (Desc)' },
    { value: '-engagement_rate', label: 'Engagement rate (Desc)' },
    { value: '-follower_growth', label: 'Follower growth (Desc)' },
    { value: '-total_likes_growth', label: 'Total likes growth (Desc)' },
    { value: '-total_views_growth', label: 'Total views growth (Desc)' },
];
