import { microwave, youtube } from '@round/api';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import uniq from 'lodash/uniq';
import { isNumber } from 'utility/utility';
import { useMicrowaveInfluencers } from '../hooks/useMicrowaveInfluencers';
import { useTiktokUserImages } from 'Modules/Influencer/hooks/useTiktokUserImages';
import useInstagramUserImages from 'Modules/Instagram/hooks/useInstagramUserImages';
import { useMicrowavePaymentRequests } from '../hooks/useMicrowavePaymentRequests';
import { useCallback, useMemo, useState } from 'react';
import { useReleases } from 'Modules/Advertising/Projects/hooks/useReleases';
import { showNotification } from 'helpers';

export type UseMicrowavePaymentsReturn = ReturnType<typeof useOutstandingPayments>;

export default function useOutstandingPayments() {
    const [status, setStatus] = useState<'not-initialized' | 'initialized' | 'error'>('not-initialized');
    const { fetchData: fetchPaymentRequests, ...paymentRequests } = useMicrowavePaymentRequests();

    const fetchData = useCallback(
        async (params: microwave.GetPaymentRequestsParams, requestInit?: RequestInit) => {
            try {
                const response = await fetchPaymentRequests(params, requestInit);

                if (response.status === 404) {
                    setStatus('error');
                }
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setStatus('error');
                throw e;
            }
        },
        [fetchPaymentRequests]
    );

    const influencerIds = uniq(paymentRequests.data?.paymentRequests.map((p) => p.influencer_id).filter(isNumber));

    const { state: influencerFetchState, init: initInfluencers, reset: resetInfluencers } = useMicrowaveInfluencers({
        id: influencerIds.join(','),
        page: 1,
        page_size: influencerIds.length,
    });

    useAbortableEffect(
        (signal) => {
            const areInfluencersInitialized =
                influencerFetchState.status === 'success' || influencerFetchState.status === 'error';
            if (paymentRequests.status !== 'success' || areInfluencersInitialized) {
                return;
            }

            if (!influencerIds.length) {
                setStatus('initialized');
                return;
            }

            initInfluencers({ signal });
        },
        [influencerIds.length, influencerFetchState.status, paymentRequests.status, initInfluencers]
    );

    const { fetchData: fetchTiktokImages, ...tiktokUserImages } = useTiktokUserImages();
    const { fetchData: fetchInstagramImages, ...instagramUserImages } = useInstagramUserImages();
    const [youtubeChannels, setYoutubeChannels] = useState<youtube.Channel[]>([]);

    useAbortableEffect(
        (signal) => {
            if (influencerFetchState.status !== 'success') {
                return;
            }

            const fetchImages = async () => {
                try {
                    const tiktokUserIds = influencerFetchState.data?.map((inf) => inf.tiktok_user_id).filter(isNumber);
                    const instagramUserIds = influencerFetchState.data
                        ?.map((inf) => inf.instagram_user_id)
                        .filter(isNumber);
                    const youtubeChannelIds = influencerFetchState.data
                        ?.map((inf) => inf.youtube_channel_id)
                        .filter(isNumber);

                    const tiktokImagesToFetch = tiktokUserIds.filter(
                        (id) => !tiktokUserImages.data?.find((img) => img.user_id === id)
                    );
                    const instagramImagesToFetch = instagramUserIds.filter(
                        (id) => !instagramUserImages.data?.images?.find((img) => img.user_id === id)
                    );
                    const youtubeChannelIdsToFetch = youtubeChannelIds.filter(
                        (id) => !youtubeChannels.find((channel) => channel.id === id)
                    );

                    await Promise.allSettled([
                        instagramImagesToFetch.length ? fetchInstagramImages(instagramImagesToFetch, { signal }) : null,
                        tiktokImagesToFetch.length ? fetchTiktokImages(tiktokImagesToFetch, { signal }) : null,
                        youtubeChannelIdsToFetch.length
                            ? youtube
                                  .getChannels({
                                      id: youtubeChannelIdsToFetch.join(','),
                                      page_size: youtubeChannelIdsToFetch.length,
                                  })
                                  .then((res) =>
                                      res.status === 200
                                          ? setYoutubeChannels((prev) => prev.concat(res.data.results))
                                          : Promise.resolve()
                                  )
                            : Promise.resolve(),
                    ]);
                } finally {
                    setStatus('initialized');
                }
            };

            fetchImages();
        },
        [
            fetchTiktokImages,
            influencerFetchState.status,
            influencerFetchState.data,
            tiktokUserImages.data,
            fetchInstagramImages,
            instagramUserImages.data,
            youtubeChannels,
        ]
    );

    const { fetchData: fetchReleases, ...releases } = useReleases();

    const releaseIds = useMemo(
        () => uniq(paymentRequests.data?.paymentRequests.map((p) => p.release_id).filter(isNumber)),
        [paymentRequests.data]
    );

    useAbortableEffect(
        (signal) => {
            async function fetchReleaseData(ids: number[]) {
                try {
                    await fetchReleases({ id: ids.join(',') }, { signal });
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }
                    showNotification('Could not fetch releases', 'error');
                }
            }

            const releaseIdsToFetch = releaseIds.filter((id) => !releases.data?.releases.find((r) => r.id === id));

            if (paymentRequests.status !== 'success' || !releaseIdsToFetch.length) {
                return;
            }

            fetchReleaseData(releaseIdsToFetch);
        },
        [fetchReleases, releases.data, paymentRequests.status, releaseIds]
    );

    const reset = () => {
        paymentRequests.reset();
        resetInfluencers();
        setStatus('not-initialized');
    };

    const rows = (paymentRequests.data?.paymentRequests ?? []).map((post) => {
        const influencer = influencerFetchState.data?.find((influencer) => influencer.id === post.influencer_id);

        const images = {
            tiktok: (() => {
                const image = tiktokUserImages.data?.find((img) => img.user_id === influencer?.tiktok_user_id);
                return image?.avatar_thumb.cached_url || image?.avatar_thumb.original_url;
            })(),
            instagram: (() => {
                const image = instagramUserImages.data?.images?.find(
                    (img) => img.user_id === influencer?.instagram_user_id
                );
                return image?.avatar_thumb.cached_url || image?.avatar_thumb.original_url;
            })(),
            youtube:
                youtubeChannels.find((channel) => channel.id === influencer?.youtube_channel_id)?.thumbnail ||
                undefined,
        };

        const userImageUrl = images[post.platform] || Object.values(images).find(Boolean);

        const release = releases.data?.releases.find((r) => r.id === post.release_id);

        return {
            ...post,
            influencer,
            release,
            userImageUrl: userImageUrl,
        };
    });

    return {
        rows,
        totals: paymentRequests.data?.totals ?? null,
        count: paymentRequests.data?.count ?? 0,
        status,

        fetchData,
        updatePayment: paymentRequests.update,
        makePaypalPayment: paymentRequests.makePaypalPayment,
        markAsPaidManually: paymentRequests.markAsPaidManually,
        makeVenmoPayment: paymentRequests.makeVenmoPayment,
        reset,
    };
}
