import { PaginatedApiResponseData, creatorbase } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<PaginatedApiResponseData<creatorbase.Project>>;

const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export function useProjects() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (params: creatorbase.GetProjectsParams, requestInit?: RequestInit) => {
        setState((prevState) => ({
            data: prevState.data,
            error: null,
            status: 'loading',
        }));

        try {
            const response = await creatorbase.getProjects(params, requestInit);

            if (response.status === 404 || response.status === 403) {
                setState((prevState) => ({
                    data: prevState.data,
                    error: response.data.detail,
                    status: 'error',
                }));
                return response;
            }

            setState((prev) => ({
                data: {
                    ...response.data,
                    results: (prev.data?.results ?? []).concat(response.data.results),
                },
                error: null,
                status: 'success',
            }));
            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                setState((prevState) => ({
                    data: prevState.data,
                    error: null,
                    status: 'idle',
                }));
                throw e;
            }

            const errorMessage = e instanceof Error ? e.message : 'Could not get projects';
            setState((prevState) => ({
                data: prevState.data,
                error: errorMessage,
                status: 'error',
            }));
            throw e;
        }
    }, []);

    const reset = useCallback(() => {
        setState(initialState);
    }, []);

    return {
        ...state,
        fetchData,
        reset,
    };
}
