import React from 'react';

interface IIcon {
    className?: string;
    height?: number;
    width?: number;
    onClick?: () => void;
    strokeColor?: string;
}

const CheckCircleIcon = ({ className, height, width, onClick, strokeColor = 'currentColor' }: IIcon) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            stroke={strokeColor}
            className={className}
            onClick={onClick}
        >
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
            <polyline points="22 4 12 14.01 9 11.01" />
        </svg>
    );
};

export default CheckCircleIcon;
