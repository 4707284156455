import React from 'react';
import { InvoiceRequestStatus } from '@round/api';
import { invoiceRequestStatusOptions } from 'Modules/Finance/InvoiceRequests/helpers';
import styles from 'Modules/Finance/InvoiceRequests/InvoiceRequestStatusBadge/InvoiceRequestStatusBadge.module.css';
import { Badge } from '@round/ui-kit';
import cn from 'classnames';

type Props = {
    status: InvoiceRequestStatus;
};

// It's an old design component. Keeping it because new design doesn't look good on influencer group bar
const InvoiceRequestStatusBadge = ({ status }: Props) => (
    <Badge className={cn(styles.badge, styles[status.toLowerCase()])}>
        {invoiceRequestStatusOptions.find((o) => o.value === status)?.label}
    </Badge>
);

export default InvoiceRequestStatusBadge;
