import { Context } from '@fluentui/react-context-selector';
import { creatorbase } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { makeErrorStateSlice, makeLoadingStateSlice, makeSuccessStateSlice } from 'utility/dataState';

type TeamUnion = creatorbase.Team | creatorbase.PublicTeam;

export type State<T extends TeamUnion> = {
    [teamId: number]: DataState<T | null> | undefined;
};

export const initialState = {};

export type Actions<T extends TeamUnion> =
    | ReducerActionWithPayload<'loadTeams', number[]>
    | ReducerActionWithPayload<'errorLoadingTeams', { teamIds: number[]; error: string }>
    | ReducerActionWithPayload<'successLoadingTeams', { teamIds: number[]; teams: T[] }>;

export const makeTeamsReducer = <T extends TeamUnion>() =>
    createReducer<State<T>, Actions<T>>({
        loadTeams: (state, { payload: teamIds }) => ({
            ...state,
            ...makeLoadingStateSlice(teamIds),
        }),
        errorLoadingTeams: (state, { payload: { teamIds, error } }) => ({
            ...state,
            ...makeErrorStateSlice(teamIds, error),
        }),
        successLoadingTeams: (state, { payload: { teamIds, teams } }) => ({
            ...state,
            ...makeSuccessStateSlice(teamIds, (teamId) => teams.find((t) => t.id === teamId) ?? null),
        }),
    });

export const makeTeamsDataHook = <TValue extends BaseDataHookContextValue<Actions<T>>, T extends TeamUnion>(
    context: Context<TValue>,
    selector: ContextSelector<TValue, State<T>>
) => {
    return () => {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (teamIds: number[], requestInit?: RequestInit) => {
                if (!teamIds.length) {
                    dispatch({ type: 'successLoadingTeams', payload: { teamIds, teams: [] } });
                    return;
                }

                try {
                    dispatch({ type: 'loadTeams', payload: teamIds });
                    const response = await creatorbase.getTeams(
                        { id: teamIds.toString(), page_size: teamIds.length },
                        requestInit
                    );

                    if (response.status === 200) {
                        dispatch({
                            type: 'successLoadingTeams',
                            payload: { teamIds, teams: response.data.results as T[] },
                        });
                        return response;
                    }

                    dispatch({ type: 'errorLoadingTeams', payload: { teamIds, error: response.data.detail } });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({ type: 'errorLoadingTeams', payload: { teamIds, error: 'Could not load teams' } });
                    throw e;
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
};
