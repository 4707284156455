import React, { useEffect, useMemo, useState } from 'react';

import Modal from '../Modal/Modal';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { FormField, ImageFileField, PaginatedSelectFormField, SelectFormField } from '../../App.types';
import { Dictionary } from '../AdminPage/AdminPage';
import { fetchWithToken } from '@round/api';
import { Label } from '../Forms/Label/Label';
import { ModalSelect } from '../Forms/ModalSelect/ModalSelect';
import ModalContent from '../Modal/ModalContent/ModalContent';
import ModalHeading from '../Modal/ModalHeading/ModalHeading';
import ModalBody from '../Modal/ModalBody/ModalBody';
import CreateSubmitButton from '../CreateSubmitButton/CreateSubmitButton';
import ModalFormField from '../Modal/ModalFormField/ModalFormField';
import FileUpload, { ImagePreview } from '../../ui/DataEntry/FileUpload/FileUpload';
import useDownloadImage from '../../Hooks/useDownloadImage';
import * as yup from 'yup';
import styles from './WriteModelModal.module.css';
import PaginatedSelect from '../../ui/DataEntry/PaginatedSelect/PaginatedSelect';
import Button from '../../ui/Buttons/Button/Button';
import { ConfirmationModal } from '@round/ui-kit';
import { showNotification } from '../../helpers';
import { OptionTypeBase, ValueType } from 'react-select';

/* eslint-disable no-eval */

type ModalProps<T> = {
    closeModal: () => void;
    isModalOpen: boolean;
    onSuccess: () => void;
    initialValues: T;
    url: string | ((method?: string, id?: number | null) => string);
    method: string;
    title: string;
    buttonText: string;
    fields: FormField[];
    instanceToUpdateId: number | null;
    toBody?: (values: T) => Record<string, any>;
    deletable?: boolean;
    onDelete?: (instanceId: number) => void;
};

function omit(obj: Dictionary, props: string[]) {
    return eval(`(({${props.join(',')}, ...o}) => o)(obj)`);
}

function addIdToUrl(url: string, id: number | null) {
    if (id === null) {
        return url;
    } else {
        return `${url}${id}/`;
    }
}

export function WriteModelModal<T extends Dictionary>({
    closeModal,
    isModalOpen,
    onSuccess,
    initialValues,
    url,
    method,
    title,
    buttonText,
    fields,
    instanceToUpdateId,
    toBody = (values) => values,
    deletable,
    onDelete,
}: ModalProps<T>) {
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const imageFieldName = fields.find((f) => isImageFileField(f))?.name ?? '';
    const imageUrl = initialValues[imageFieldName] as string | undefined;
    const { image, loading } = useDownloadImage(imageUrl);

    const [selectFieldValues, setSelectFieldValues] = useState<Record<string, any> | null>(null);

    const formValues = useMemo(() => {
        let values = { ...initialValues, ...selectFieldValues };
        if (imageFieldName && typeof initialValues[imageFieldName] === 'string') {
            values = {
                ...values,
                [imageFieldName]: image,
            };
        }

        return values;
    }, [image, imageFieldName, initialValues, selectFieldValues]);

    useEffect(() => {
        fields
            .filter((f): f is PaginatedSelectFormField => f.type === 'paginated-select')
            .forEach((field) => {
                const value = initialValues[field.name];
                if (typeof value === 'number') {
                    field
                        .fetchValue(value)
                        .then((result) => {
                            setSelectFieldValues((values) => ({
                                ...values,
                                [field.name]: field.mapToOption(result),
                            }));
                        })
                        .catch(() => {});
                }

                if (Array.isArray(value)) {
                    Promise.all(value.map((id) => field.fetchValue(id).then((result) => field.mapToOption(result))))
                        .then((results) => {
                            setSelectFieldValues((values) => ({
                                ...values,
                                [field.name]: results,
                            }));
                        })
                        .catch(() => {});
                }
            });
    }, [fields, initialValues]);

    return (
        <>
            <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
                <ModalContent className={styles.modalContent}>
                    <ModalHeading>{title}</ModalHeading>
                    <ModalBody>
                        {hasError && <div>Error during request</div>}
                        <Formik
                            enableReinitialize
                            initialValues={formValues}
                            validationSchema={yup.object({
                                ...fields.reduce((acc, field) => {
                                    if (field.validation) {
                                        acc[field.name] = field.validation;
                                    }

                                    return acc;
                                }, {} as Record<string, yup.AnySchema>),
                            })}
                            onSubmit={async (values: T, { setSubmitting }: FormikHelpers<T>) => {
                                const fileFields = fields.filter((f) => isImageFileField(f)) as ImageFileField[];
                                let valuesWithoutFiles = values;
                                if (fileFields.length > 0) {
                                    valuesWithoutFiles = omit(
                                        values,
                                        fileFields.map((f) => f.name)
                                    );
                                }

                                setSubmitting(true);
                                setHasError(false);
                                const response = await fetchWithToken(
                                    typeof url === 'function'
                                        ? url(method, instanceToUpdateId)
                                        : addIdToUrl(url, instanceToUpdateId),
                                    {
                                        method: method,
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify(toBody(valuesWithoutFiles)),
                                    }
                                );
                                if (response.ok) {
                                    if (instanceToUpdateId === null) {
                                        const js = await response.json();
                                        instanceToUpdateId = js.id;
                                    }
                                    for (let i = 0; i < fileFields.length; i++) {
                                        const fieldName = fileFields[i].name;
                                        const value = values[fieldName];
                                        if (value !== formValues[fieldName]) {
                                            const formData = new FormData();
                                            //@ts-ignore
                                            formData.append(fieldName, value);
                                            await fetchWithToken(`${fileFields[i].url}${instanceToUpdateId}/`, {
                                                method: 'PATCH',
                                                body: formData,
                                            });
                                        }
                                    }
                                    onSuccess();
                                    setSubmitting(false);
                                } else {
                                    setHasError(true);
                                }
                            }}
                        >
                            {({ isSubmitting }) => {
                                return (
                                    <Form>
                                        {fields.map((field) => {
                                            if (isSelectField(field)) {
                                                return (
                                                    <ModalFormField>
                                                        <Label htmlFor={field.name}>{field.label}</Label>
                                                        <Field name={field.name}>
                                                            {(props: FieldProps) => (
                                                                <ModalSelect
                                                                    isMulti={field.isMulti}
                                                                    fieldProps={props}
                                                                    options={field.options}
                                                                    onChange={(option) => {
                                                                        props.form.setFieldValue(
                                                                            props.field.name,
                                                                            option
                                                                        );

                                                                        const isMultiValue = <T extends OptionTypeBase>(
                                                                            value: ValueType<T, boolean>
                                                                        ): value is ValueType<T, true> =>
                                                                            Array.isArray(value);

                                                                        props.form.setFieldValue(
                                                                            `${props.field.name}_id`,
                                                                            isMultiValue(option)
                                                                                ? option?.map((o) => o.value)
                                                                                : option?.value
                                                                        );
                                                                    }}
                                                                    value={field.options.find((option) => {
                                                                        return (
                                                                            option.value ===
                                                                            props.form.getFieldProps(props.field.name)
                                                                                ?.value?.value
                                                                        );
                                                                    })}
                                                                    formatOptionLabel={field.formatOptionLabel}
                                                                    clearable={field.clearable}
                                                                />
                                                            )}
                                                        </Field>
                                                        <ErrorMessage name={field.name}>
                                                            {(msg) => (
                                                                <span className={styles.errorMessage}>{msg}</span>
                                                            )}
                                                        </ErrorMessage>
                                                    </ModalFormField>
                                                );
                                            }

                                            if (isImageFileField(field)) {
                                                return (
                                                    <>
                                                        <Label htmlFor={field.name}>{field.label}</Label>
                                                        <Field name={field.name} id={field.name}>
                                                            {(props: FieldProps) => (
                                                                <FileUpload
                                                                    loading={loading}
                                                                    files={[props.field.value]}
                                                                    onUpload={([file]) =>
                                                                        props.form.setFieldValue(field.name, file)
                                                                    }
                                                                >
                                                                    {({ files: [imageFile] }) => (
                                                                        <ImagePreview
                                                                            file={imageFile}
                                                                            className={styles.imagePreview}
                                                                        />
                                                                    )}
                                                                </FileUpload>
                                                            )}
                                                        </Field>
                                                    </>
                                                );
                                            }

                                            if (isPaginatedSelectField(field)) {
                                                return (
                                                    <ModalFormField>
                                                        <Label htmlFor={field.name}>{field.label}</Label>
                                                        <Field name={field.name} id={field.name}>
                                                            {(props: FieldProps) => (
                                                                <PaginatedSelect
                                                                    isMulti={field.isMulti}
                                                                    isClearable={field.isClearable}
                                                                    fetchOptions={field.fetchOptions}
                                                                    mapToOption={field.mapToOption}
                                                                    value={props.field.value}
                                                                    onChange={(value) =>
                                                                        props.form.setFieldValue(field.name, value)
                                                                    }
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: (base) => ({
                                                                            ...base,
                                                                            zIndex: 1000,
                                                                        }),
                                                                        input: (base) => ({
                                                                            ...base,
                                                                            height: '1rem',
                                                                        }),
                                                                    }}
                                                                    components={field.components}
                                                                    filterOption={field.filterOption}
                                                                />
                                                            )}
                                                        </Field>
                                                    </ModalFormField>
                                                );
                                            }

                                            return (
                                                <ModalFormField>
                                                    <Label htmlFor={field.name}>{field.label}</Label>
                                                    <Field id={field.name} name={field.name} type={field.type} />
                                                    <ErrorMessage name={field.name}>
                                                        {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                                                    </ErrorMessage>
                                                </ModalFormField>
                                            );
                                        })}
                                        <div className={styles.actionsContainer}>
                                            <CreateSubmitButton disabled={isSubmitting}>
                                                {buttonText}
                                            </CreateSubmitButton>
                                            {deletable && (
                                                <Button
                                                    htmlType="button"
                                                    type="filled"
                                                    color="negative"
                                                    onClick={() => setIsConfirmDeleteModalOpen(true)}
                                                >
                                                    Delete
                                                </Button>
                                            )}
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <ConfirmationModal
                isOpen={isConfirmDeleteModalOpen}
                confirmActionLoading={isDeleteLoading}
                handleConfirm={async () => {
                    if (typeof instanceToUpdateId !== 'number') {
                        return;
                    }

                    try {
                        setIsDeleteLoading(true);
                        const response = await fetchWithToken(
                            typeof url === 'function'
                                ? url('DELETE', instanceToUpdateId)
                                : addIdToUrl(url, instanceToUpdateId),
                            { method: 'DELETE' }
                        );

                        if (response.ok) {
                            if (typeof onDelete === 'function') {
                                onDelete(instanceToUpdateId);
                            }

                            showNotification('Success', 'info');
                            setIsConfirmDeleteModalOpen(false);
                            return;
                        }

                        showNotification('Could not perform operation', 'error');
                    } catch (e) {
                        showNotification('Could not perform operation', 'error');
                    } finally {
                        setIsDeleteLoading(false);
                    }
                }}
                onClose={() => setIsConfirmDeleteModalOpen(false)}
                modalTitle="Are you sure?"
                modalText=""
                confirmButtonText="Confirm"
            />
        </>
    );
}

function isSelectField(field: FormField): field is SelectFormField {
    return field.type === 'select';
}

function isImageFileField(field: FormField): field is ImageFileField {
    return field.type === 'image-file';
}

function isPaginatedSelectField(field: FormField): field is PaginatedSelectFormField {
    return field.type === 'paginated-select';
}
