import React, { useMemo, useState } from 'react';
import { Currency } from '@round/api';
import { Column } from 'react-table';
import PaginationTable from '../../../../../../../ui/PaginationTable/PaginationTable';
import Skeleton from '../../../../../../../ui/Skeleton/Skeleton';
import { asMoney, formatDateObjShort, numberWithCommas } from '../../../../../../../helpers';
import styles from './TiktokUserBoostedPosts.module.css';
import { useTiktokUserBoostedPosts } from '../../TiktokUserDataContext/TiktokUserDataContext';

type TiktokUserBoostedPostsProps = {
    tiktokUserId: number | undefined;
    loading?: boolean;
};

export type BoostedPostsRow = {
    adName: string;
    startDate: string;
    endDate: string;
    amountSpent: number;
    impressions: number;
    cpm: number;
    paidFollowers: number;
    costPerFollower: number;
    currency: Currency | undefined;
};

/*
 * Boosted Posts displays the data on Tiktok advertising (media plan item).
 * We display boosted posts when tiktok user is actually a brand from advertising
 * module.
 * */
const TiktokUserBoostedPosts = ({ tiktokUserId, loading }: TiktokUserBoostedPostsProps) => {
    const { boostedPosts, boostedPostsLoading, errorLoadingBoostedPosts } = useTiktokUserBoostedPosts(tiktokUserId);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const tableLoading = Boolean(loading || boostedPostsLoading);

    const columns = useMemo<Column<BoostedPostsRow>[]>(
        () => [
            {
                Header: 'Ad name',
                accessor: 'adName',
                Cell: ({ value }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    const nameParts = value.split(' | ');
                    return <>{nameParts.length > 4 ? nameParts[3] : value}</>;
                },
            },
            {
                Header: 'Start date',
                accessor: 'startDate',
                Cell: ({ value }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    return <>{formatDateObjShort(new Date(value))}</>;
                },
            },
            {
                Header: 'End date',
                accessor: 'endDate',
                Cell: ({ value }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    return <>{formatDateObjShort(new Date(value))}</>;
                },
            },
            {
                Header: 'Spend',
                accessor: 'amountSpent',
                Cell: ({ value, row: { original } }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    if (!original.currency) {
                        return <>{'-'}</>;
                    }

                    return <>{asMoney(value, original.currency)}</>;
                },
            },
            {
                Header: 'Impressions',
                accessor: 'impressions',
                Cell: ({ value }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'CPM',
                accessor: 'cpm',
                Cell: ({ value, row: { original } }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    if (!original.currency) {
                        return <>{'-'}</>;
                    }

                    return <>{asMoney(value, original.currency)}</>;
                },
            },
            {
                Header: 'Paid followers',
                accessor: 'paidFollowers',
                Cell: ({ value }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Cost per follower',
                accessor: 'costPerFollower',
                Cell: ({ value, row: { original } }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    if (!original.currency) {
                        return <>{'-'}</>;
                    }

                    return <>{asMoney(value, original.currency)}</>;
                },
            },
        ],
        [tableLoading]
    );

    return (
        <div>
            <p>Boosted Posts</p>
            <PaginationTable
                tableClassName={styles.table}
                disableSortBy
                manualPagination={false}
                loading={tableLoading}
                data={boostedPosts}
                columns={columns}
                count={boostedPosts.length}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={(pageSize) => setPageSize(pageSize ?? 10)}
                noDataLabel={
                    errorLoadingBoostedPosts ? 'Sorry, we are experiencing technical issues' : 'No boosted posts'
                }
            />
        </div>
    );
};

export default TiktokUserBoostedPosts;
