import { CSSProperties, ReactNode, useEffect } from 'react';
import { Modal } from '@round/ui-kit';
import styles from './FullScreenModal.module.css';
import cn from 'classnames';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    children?: ReactNode | undefined;
    closeOnEscape?: boolean;
};

const FullScreenModal = ({ isOpen, onClose, children, closeOnEscape }: Props) => {
    useEffect(() => {
        //prevents document body scrolling when modal is open
        //we cannot use overscroll-behavior: contain; because the modal's container element is usually not overflown/scrollable
        const doc = document.documentElement;
        if (isOpen) {
            doc.style.overflow = 'hidden';
        } else {
            doc.style.overflow = '';
        }

        return () => {
            doc.style.overflow = '';
        };
    }, [isOpen]);

    useEffect(() => {
        if (!closeOnEscape) {
            return;
        }

        const onEscapePressed = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', onEscapePressed);
        return () => {
            document.removeEventListener('keydown', onEscapePressed);
        };
    }, [closeOnEscape, onClose]);

    return (
        <Modal className={styles.container} isOpen={isOpen} onClose={onClose}>
            {children}
        </Modal>
    );
};

type ComponentProps = {
    className?: string;
    children?: ReactNode | undefined;
    style?: CSSProperties;
};

const FullScreenModalHeader = ({ children, className, style }: ComponentProps) => (
    <header style={style} className={cn(styles.header, className)}>
        {children}
    </header>
);

const FullScreenModalContent = ({ children, className, style }: ComponentProps) => (
    <main style={style} className={cn(styles.main, className)}>
        {children}
    </main>
);

const FullScreenModalFooter = ({ children, className, style }: ComponentProps) => (
    <footer style={style} className={cn(styles.footer, className)}>
        {children}
    </footer>
);

FullScreenModal.Header = FullScreenModalHeader;
FullScreenModal.Content = FullScreenModalContent;
FullScreenModal.Footer = FullScreenModalFooter;

export default FullScreenModal;
