import { creatorbase } from '@round/api';
import PlatformHeadlineStats, { Stats } from 'Modules/Plans/components/PlatformHeadlineStats/PlatformHeadlineStats';
import usePlatformHeadlineStats from './usePlatformHeadlineStats';
import useReportData from '../ReportData/useReportData';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { asMoney, formatNumberToKNotation } from 'helpers';
import { toDecimalPoint } from '@round/utils';
import { tooltipCopy } from 'Modules/Plans/helpers';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';

type Props = {
    platform: creatorbase.Platform;
};

const AggregatedPlatformStats = ({ platform }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const { data: reportData, status: reportStatus } = useReportData();
    const { data, fetchData, status } = usePlatformHeadlineStats(platform);
    const isInitialized = status === 'success' || status === 'error';
    const isReportInitialized = reportStatus === 'success' || reportStatus === 'error';
    const shouldInitialize = !!reportData && reportData.platforms.includes(platform);
    const isLoading = !isReportInitialized || (!isInitialized && shouldInitialize);

    useAbortableEffect(
        (signal) => {
            if (isInitialized || !shouldInitialize) {
                return;
            }

            fetchData(reportData.id, signal).catch(() => {});
        },
        [fetchData, isInitialized, reportData?.id, shouldInitialize]
    );

    //there should only be one currency in the data, so we can just take the first entry
    const cpmCurrencyValuePair = Object.entries(data?.cpm || {})[0] || [];
    const cpm = cpmCurrencyValuePair.length
        ? asMoney(
              cpmCurrencyValuePair[1],
              currencies.find((curr) => curr.id === Number(cpmCurrencyValuePair[0]))
          )
        : '-';

    const totalSpentCurrencyValuePair = Object.entries(data?.total_spent || {})[0] || [];
    const totalSpent = totalSpentCurrencyValuePair.length
        ? asMoney(
              totalSpentCurrencyValuePair[1],
              currencies.find((curr) => curr.id === Number(totalSpentCurrencyValuePair[0]))
          )
        : '-';

    const stats: Stats = {
        totalPosts: {
            label: 'Total Posts',
            value: data?.post_count,
        },
        totalFollowers: {
            label: 'Total Followers',
            value: typeof data?.follower_count === 'number' ? formatNumberToKNotation(data?.follower_count) : '-',
        },
        totalViews: {
            label: 'Total Views',
            value: typeof data?.view_count === 'number' ? formatNumberToKNotation(data?.view_count) : '-',
        },
        engagementRate: {
            label: 'Engagement Rate',
            value:
                typeof data?.engagement_rate === 'number' ? `${toDecimalPoint(data?.engagement_rate * 100, 2)}%` : '-',
            tooltip: tooltipCopy.engagementRate,
        },
        totalSpend: {
            label: 'Total spend',
            value: totalSpent,
        },
        cpm: {
            label: 'CPM',
            value: cpm,
            tooltip: tooltipCopy.cpm,
        },
    };

    return <PlatformHeadlineStats stats={stats} isLoading={isLoading} />;
};

export default AggregatedPlatformStats;
