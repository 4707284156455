import React, { useMemo } from 'react';
import PaginationTable, { PaginationTableProps } from '../../../../ui/PaginationTable/PaginationTable';
import { Column } from 'react-table';
import Skeleton from '../../../../ui/Skeleton/Skeleton';

export type OutstandingInfluencerPostRow = {
    id: number;
    cost: string;
    client: string;
    campaignName: string;
    influencerName: string;
    release_id: number | undefined;
};

type OutstandingInfluencerPostsTableProps = Omit<PaginationTableProps<OutstandingInfluencerPostRow>, 'columns'>;

const OutstandingInfluencerPostsTable = (props: OutstandingInfluencerPostsTableProps) => {
    const columns: Column<OutstandingInfluencerPostRow>[] = useMemo(
        () => [
            {
                Header: 'Plan item ID',
                accessor: 'id',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Plan item value',
                accessor: 'cost',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Client',
                accessor: 'client',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Campaign Name',
                accessor: 'campaignName',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Influencer Name',
                accessor: 'influencerName',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
        ],
        [props.loading]
    );

    return <PaginationTable columns={columns} {...props} />;
};

export default OutstandingInfluencerPostsTable;
