import React from 'react';
import styles from './PaginationToolbar.module.css';
import { Select } from '@round/ui-kit';
import range from 'lodash/range';
import cn from 'classnames';
import { StylesConfig } from 'react-select';
import { DEFAULT_INITIAL_PAGE_SIZE_OPTION, defaultPageSizeOptions as pageSizeOptions } from 'utility/constants';
import { ReactComponent as ArrowLeft } from 'assets/ChevronLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/ChevronRight.svg';
import { showNotification } from 'helpers';

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        borderRadius: '0.625rem',
        color: '#111',
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '0.25rem 1rem',
    }),
};

type Props = {
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    page: number;
    setPage: (page: number) => void;
    count: number;
    isLoading?: boolean;
    className?: string;
};

function getPageRange(pageCount: number, page: number): [number, number] {
    if (!pageCount) {
        return [1, 1];
    }

    if (pageCount <= 5) {
        return [1, pageCount];
    }

    if (page <= 2) {
        return [1, 5];
    }

    if (page >= pageCount - 2) {
        return [pageCount - 4, pageCount];
    }

    return [page - 2, page + 2];
}

const PaginationToolbar = ({ setPage, pageSize = 10, setPageSize, page, count, isLoading, className }: Props) => {
    const pageCount = Math.ceil(count / pageSize);
    const [pageRangeStart, pageRangeEnd] = getPageRange(pageCount, page);
    const pageRange = range(pageRangeStart, pageRangeEnd + 1);

    const hasNextPage = page < pageCount;
    const hasPreviousPage = page > 1;

    return (
        <div className={cn(styles.container, className)}>
            <article>
                <Select
                    options={pageSizeOptions}
                    value={
                        pageSizeOptions.find((option) => option.value === pageSize) || DEFAULT_INITIAL_PAGE_SIZE_OPTION
                    }
                    onChange={(option) => setPageSize(option?.value || DEFAULT_INITIAL_PAGE_SIZE_OPTION.value)}
                    styles={selectStyles}
                    isSearchable={false}
                    isDisabled={isLoading}
                    menuPlacement="auto"
                />
            </article>

            <article className={styles.pageControls}>
                <button
                    className={cn(styles.button, styles.arrow)}
                    disabled={!hasPreviousPage || isLoading}
                    onClick={() => setPage(page - 1)}
                >
                    <ArrowLeft />
                </button>

                {!pageRange.includes(1) && (
                    <>
                        <button
                            className={cn(styles.button, styles.page)}
                            onClick={() => setPage(1)}
                            disabled={isLoading}
                        >
                            1
                        </button>
                        {pageRangeStart !== 2 && <span>...</span>}
                    </>
                )}

                {pageRange.map((pageNumber) => (
                    <button
                        key={pageNumber}
                        className={cn(styles.button, styles.page, {
                            [styles.active]: pageNumber === page,
                        })}
                        onClick={() => setPage(pageNumber)}
                        disabled={isLoading}
                    >
                        {pageNumber}
                    </button>
                ))}

                {pageCount > 1 && !pageRange.includes(pageCount) && (
                    <>
                        {!pageRange.includes(pageCount - 1) && <span>...</span>}
                        <button
                            className={cn(styles.button, styles.page)}
                            onClick={() => setPage(pageCount)}
                            disabled={isLoading}
                        >
                            {pageCount}
                        </button>
                    </>
                )}

                <div className={styles.goToPage}>
                    <input
                        className={styles.goToPageInput}
                        type="number"
                        min="1"
                        max={pageCount}
                        placeholder="Page #"
                        onKeyDown={(e) => {
                            if (e.key !== 'Enter') {
                                return;
                            }

                            const value = e.currentTarget.value ? Number(e.currentTarget.value) : undefined;
                            if (typeof value !== 'number') {
                                return;
                            }

                            if (1 <= value && pageCount >= value) {
                                setPage(value);
                                return;
                            }

                            showNotification('Invalid page', 'error');
                        }}
                    />
                    <ArrowRight className={styles.goToPageIcon} />
                </div>

                <button
                    className={cn(styles.button, styles.arrow)}
                    disabled={!hasNextPage || isLoading}
                    onClick={() => setPage(page + 1)}
                >
                    <ArrowRight />
                </button>
            </article>
        </div>
    );
};

export default PaginationToolbar;
