import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { YouTubeInfluencerPost } from './youtubeInfluencerPosts.types';

export type GetYouTubeInfluencerPostsParams = Partial<
    PaginatedRequest & {
        id: string;
        plan_id: number;
    }
>;

type GetYouTubeInfluencerPostsResponse =
    | ApiResponse<PaginatedApiResponseData<YouTubeInfluencerPost>, 200>
    | NotFoundResponse;

export async function getYouTubeInfluencerPosts(
    params: GetYouTubeInfluencerPostsParams,
    requestInit?: RequestInit
): Promise<GetYouTubeInfluencerPostsResponse> {
    const response = await fetchWithToken(
        `/api/advertising/youtube-influencer-posts/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404) {
        return { status: response.status, data: await response.json() };
    }

    if (!response.ok) {
        throw new Error('Could not fetch youtube influencer posts');
    }

    return { status: 200, data: await response.json() };
}

export async function getAllYoutubeInfluencerPosts(params: GetYouTubeInfluencerPostsParams, requestInit?: RequestInit) {
    return fetchAll<YouTubeInfluencerPost>(
        `/api/advertising/youtube-influencer-posts/${encodeUrlSearchParams(params)}`,
        requestInit
    );
}

export type YoutubeInfluencerPostBody = Pick<
    YouTubeInfluencerPost,
    'plan_id' | 'group_id' | 'cost' | 'currency_id' | 'post_url'
>;

type PostYoutubeInfluencerPostResponse =
    | ApiResponse<YouTubeInfluencerPost, 201>
    | ApiResponse<ApiResponseError<YoutubeInfluencerPostBody>, 400>;

export async function postYoutubeInfluencerPost(
    data: YoutubeInfluencerPostBody
): Promise<PostYoutubeInfluencerPostResponse> {
    const response = await fetchWithToken('/api/advertising/youtube-influencer-posts/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return { status: response.status, data: await response.json() };
    }

    if (!response.ok) {
        throw new Error('Could not create youtube influencer post');
    }

    return { status: 201, data: await response.json() };
}

type DeleteYoutubeInfluencerPost = ApiResponse<null, 204> | ApiResponse<string[], 400> | NotFoundResponse;

export async function deleteYoutubeInfluencerPost(postId: number): Promise<DeleteYoutubeInfluencerPost> {
    const response = await fetchWithToken(`/api/advertising/youtube-influencer-posts/${postId}/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not delete youtube influencer post');
    }

    return {
        status: 204,
        data: null,
    };
}

export type YoutubeInfluencerPostPatchData = Pick<
    YouTubeInfluencerPost,
    'group_id' | 'cost' | 'currency_id' | 'post_url' | 'estimated_views_override' | 'notes' | 'payment_status'
>;

type PatchYoutubeInfluencerPostResponse =
    | ApiResponse<YouTubeInfluencerPost, 200>
    | NotFoundResponse
    | ApiResponse<ApiResponseError<YoutubeInfluencerPostPatchData>, 400>;

export async function patchYoutubeInfluencerPost(
    postId: number,
    data: Partial<YoutubeInfluencerPostPatchData>
): Promise<PatchYoutubeInfluencerPostResponse> {
    const response = await fetchWithToken(`/api/advertising/youtube-influencer-posts/${postId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not update youtube influencer post');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
