import React, { useState } from 'react';
import { PhylloContactDetails } from '@round/api';
import { Badge, Popover } from '@round/ui-kit';
import styles from './PhylloContactDetailsBadge.module.css';

type Props = {
    contactDetails: PhylloContactDetails;
};

const PhylloContactDetailsBadge = ({ contactDetails }: Props) => {
    const [ref, setRef] = useState<HTMLDivElement | null>(null);

    return (
        <>
            <Badge ref={setRef} className={styles.badge}>
                {contactDetails.type}
            </Badge>
            <Popover showOn="click" anchorElement={ref}>
                <div className={styles.popover}>
                    <span className={styles.value}>{contactDetails.value}</span>
                    <span className={styles.subValue}>{contactDetails.label}</span>
                </div>
            </Popover>
        </>
    );
};

export default PhylloContactDetailsBadge;
