import React, { useState } from 'react';
import { GetInstagramPostsSortableKeys, InstagramAudio, OrderingValues } from '@round/api';
import useAbortableEffect from '../../../Hooks/useAbortableEffect';
import useInstagramAudio from '../hooks/useInstagramAudio';
import styles from './InstagramAudioDetails.module.css';
import AudioPlaybackThumbnail from '../../AudioPlayer/AudioPlaybackThumbnail/AudioPlaybackThumbnail';
import {
    buildInstagramAudioUrl,
    buildInstagramUserUrl,
    formatNumberToKNotation,
    numberWithCommas,
} from '../../../helpers';
import { Skeleton } from '@round/ui-kit';
import useInstagramUser from '../hooks/useInstagramUser';
import useInstagramAudioStats from '../hooks/useInstagramAudioStats';
import MetricCardWithChart from '../../../ui/DataDisplay/Statistics/MetricCardWithChart/MetricCardWithChart';
import MusicNoteIcon from '../../../SharedComponents/svg/Icons/MusicNoteIcon';
import CalendarIcon from '../../../SharedComponents/svg/Icons/CalendarIcon';
import CreationsChart from './CreationsChart/CreationsChart';
import useInstagramAudioMonitoring from '../hooks/useInstagramAudioMonitoring';
import { useCheckUserGroupsAccess } from '../../Auth/hooks/useCheckUserGroupsAccess';
import { ProtectedByUserGroups } from '../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import ReliabilityStatus from './ReliabilityStatus/ReliabilityStatus';
import { useInstagramPosts } from '../hooks/useInstagramPosts';
import TopPostsTable from './TopPostsTable/TopPostsTable';
import { convertOrderByToOrdering, convertOrderingToOrderBy } from 'ui/PaginationTable/PaginationTable.helpers';

export type InstagramAudioDetailsProps = {
    audioId: number;
    audio?: InstagramAudio | undefined;
};

const DEFAULT_POSTS_ORDERING: OrderingValues<GetInstagramPostsSortableKeys> = '-view_count';

const InstagramAudioDetails = (props: InstagramAudioDetailsProps) => {
    const isUserAdmin = useCheckUserGroupsAccess(['user_admin']);

    const {
        audio,
        init: initAudio,
        isInitialized: isAudioInitialized,
        isLoading: isAudioLoading,
        error: audioError,
    } = useInstagramAudio(props.audioId);

    useAbortableEffect(
        (signal) => {
            if (!isAudioInitialized && !props.audio && isFinite(props.audioId)) {
                initAudio({ signal });
            }
        },
        [initAudio, isAudioInitialized, props.audio, props.audioId]
    );

    const {
        user: author,
        init: initAuthor,
        isLoading: isAuthorLoading,
        isInitialized: isAuthorInitialized,
        error: authorError,
    } = useInstagramUser(props.audio?.author || audio?.author);

    useAbortableEffect(
        (signal) => {
            if (!isAuthorInitialized) {
                initAuthor({ signal });
            }
        },
        [initAuthor, isAuthorInitialized]
    );

    const [statsTimeSeriesDateRange, setStatsTimeSeriesDateRange] = useState<[Date, Date] | null>(null);

    const {
        stats,
        init: initStats,
        reset: resetStats,
        isLoading: isStatsLoading,
        isInitialized: isStatsInitialized,
    } = useInstagramAudioStats(props.audioId, statsTimeSeriesDateRange);

    //we're getting the default date range from the last date in the time series from the initial stats fetch
    const defaultStatsTimeSeriesDateRange: [Date, Date] = stats?.reels_count_time_series.length
        ? [new Date(stats?.reels_count_time_series[stats?.reels_count_time_series.length - 1].time), new Date()]
        : [new Date(), new Date()];

    useAbortableEffect(
        (signal) => {
            if (!isStatsInitialized) {
                initStats({ signal });
            }
        },
        [initStats, isStatsInitialized]
    );

    const {
        monitoringInfo,
        isInitialized: isMonitoringInitialized,
        init: initMonitoring,
    } = useInstagramAudioMonitoring(props.audioId);

    useAbortableEffect(
        (signal) => {
            if (!isMonitoringInitialized && isUserAdmin) {
                initMonitoring({ signal });
            }
        },
        [initMonitoring, isMonitoringInitialized, isUserAdmin]
    );

    const [postsPage, setPostsPage] = useState(1);
    const [postsPageSize, setPostsPageSize] = useState(10);
    const [postsOrdering, setPostsOrdering] = useState<OrderingValues<GetInstagramPostsSortableKeys> | undefined>(
        DEFAULT_POSTS_ORDERING
    );

    const {
        posts,
        count: postsCount,
        isLoading: isPostsLoading,
        isInitialized: isPostsInitialized,
        hasError: postsHasError,
        init: initPosts,
        reset: resetPosts,
    } = useInstagramPosts({
        audio_id: props.audioId,
        page: postsPage,
        page_size: postsPageSize,
        ordering: postsOrdering,
    });

    useAbortableEffect(
        (signal) => {
            if (!isPostsInitialized && isFinite(props.audioId)) {
                initPosts({ signal });
            }
        },
        [isPostsInitialized, initPosts, props.audioId]
    );

    const isAudioInfoLoading = !props.audio && (!isAudioInitialized || isAudioLoading);
    const isAuthorInfoLoading = !isAuthorInitialized || isAuthorLoading;
    const isStatsInfoLoading = !isStatsInitialized || isStatsLoading;
    //The time series and "headline" stats come from the same API call, but once we have fetched the stats initially without a range,
    //we can consider the headline stats as loaded, since they won't change based on range params.
    const areHeadlineStatsLoading = isStatsInfoLoading && !statsTimeSeriesDateRange;

    const audioUrl = props.audio?.audio_file_url || audio?.audio_file_url || '';
    const imageUrl = props.audio?.thumbnail_url || audio?.thumbnail_url || '';
    const audioInstagramId = props.audio?.instagram_id || audio?.instagram_id || '';
    const audioTitle = props.audio?.title || audio?.title || '-';

    const topPostsRecordsShowingLabel = `displaying ${posts.length} post${
        posts.length === 1 ? '' : 's'
    } out of ${numberWithCommas(postsCount)} analysed`;
    const postsNoDataLabel = postsHasError ? 'Something went wrong' : 'No posts';

    return (
        <article className={styles.container}>
            <header className={styles.header}>
                <AudioPlaybackThumbnail
                    className={styles.audioCover}
                    loading={isAudioInfoLoading}
                    audioUrl={audioUrl}
                    imageUrl={imageUrl}
                />

                <div className={styles.titleContainer}>
                    {audioError && <span>{audioError}</span>}
                    {isAudioInfoLoading ? (
                        <Skeleton width="10rem" />
                    ) : (
                        <a
                            className={styles.title}
                            href={buildInstagramAudioUrl(audioInstagramId)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {audioTitle}
                        </a>
                    )}

                    {isAuthorInfoLoading ? (
                        <Skeleton width="7rem" />
                    ) : authorError ? null : (
                        <a
                            className={styles.author}
                            href={buildInstagramUserUrl(author?.username)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            @{author?.username}
                        </a>
                    )}
                </div>

                <ProtectedByUserGroups groups={['user_admin']}>
                    {monitoringInfo && <ReliabilityStatus monitoringInfo={monitoringInfo} />}
                </ProtectedByUserGroups>
            </header>

            <div className={styles.headlineStats}>
                <MetricCardWithChart
                    loading={areHeadlineStatsLoading}
                    baseColor="#4CC8D9"
                    value={stats?.reels_count}
                    label="Total creations"
                    Icon={MusicNoteIcon}
                />

                <MetricCardWithChart
                    loading={areHeadlineStatsLoading}
                    baseColor="#0090FF"
                    value={stats?.reels_count_daily_change}
                    label="Daily creations"
                    Icon={CalendarIcon}
                    format={(value) => `${value > 0 ? '+' : ''}${formatNumberToKNotation(value)}`}
                />
            </div>

            <CreationsChart
                isLoading={isStatsInfoLoading}
                stats={stats}
                dateRange={statsTimeSeriesDateRange || defaultStatsTimeSeriesDateRange}
                setStatsDateRange={(dates) => {
                    setStatsTimeSeriesDateRange(dates);
                    resetStats();
                }}
            />

            <div className={styles.topPostsContainer}>
                <div className={styles.topPostsHeader}>
                    <div className={styles.heading}>Top Posts</div>
                    <p>{isPostsLoading ? <Skeleton /> : <>{topPostsRecordsShowingLabel}</>}</p>
                </div>

                <TopPostsTable
                    data={posts}
                    loading={isPostsLoading}
                    page={postsPage}
                    setPage={(page) => {
                        setPostsPage(page);
                        resetPosts();
                    }}
                    setPageSize={(pageSize) => {
                        setPostsPageSize(pageSize ?? 10);
                        resetPosts();
                    }}
                    pageSize={postsPageSize}
                    orderBy={convertOrderingToOrderBy(postsOrdering)}
                    onOrderByChange={(orderBy) => {
                        setPostsOrdering(
                            orderBy
                                ? (convertOrderByToOrdering(orderBy) as OrderingValues<GetInstagramPostsSortableKeys>)
                                : undefined
                        );
                        resetPosts();
                    }}
                    count={postsCount}
                    noDataLabel={postsNoDataLabel}
                    manualPagination
                    manualSortBy
                    disableSortBy={false}
                />
            </div>
        </article>
    );
};

export default InstagramAudioDetails;
