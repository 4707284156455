import { useCallback, useEffect, useMemo, useState } from 'react';

export default function useDownloadImage(
    imageUrl: string | null | undefined
): { image: File | null; loading: boolean } {
    const [image, setImage] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);

    const downloadImage = useCallback(async (imageUrl: string) => {
        try {
            setLoading(true);
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            setImage(new File([blob], `image.${blob.type.split('/')[1]}`, { type: blob.type }));
        } catch {
            // no-op
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        setImage(null);
        if (typeof imageUrl === 'string' && imageUrl.length > 0) {
            downloadImage(imageUrl);
        }
    }, [downloadImage, imageUrl]);

    return useMemo(() => ({ image, loading }), [image, loading]);
}
