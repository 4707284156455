import { Table, TableProps } from '@round/ui-kit';
import { RowData } from '@tanstack/react-table';
import styles from './WrapperTable.module.css';
import cn from 'classnames';
import { ReactComponent as ArrowsInactiveIcon } from 'assets/icons/sorting/ArrowsInactive.svg';
import { ReactComponent as ArrowsAscendingIcon } from 'assets/icons/sorting/ArrowsAscending.svg';
import { ReactComponent as ArrowsDescendingIcon } from 'assets/icons/sorting/ArrowsDescending.svg';

const WrapperTable = <T extends RowData>(props: TableProps<T>) => {
    const noDataLabel = props.noDataLabel ? <div className={styles.noDataLabel}>{props.noDataLabel}</div> : undefined;

    return (
        <Table
            {...props}
            className={cn(styles.table, props.className)}
            noDataLabel={noDataLabel}
            renderSortingControls={(column) => {
                if (props.renderSortingControls) {
                    return props.renderSortingControls(column);
                }

                const isSorted = column.getIsSorted();
                const Icon = !isSorted
                    ? ArrowsInactiveIcon
                    : isSorted === 'asc'
                    ? ArrowsAscendingIcon
                    : ArrowsDescendingIcon;

                return <Icon className={cn(styles.sortingIcon, { [styles.sortingActive]: Boolean(isSorted) })} />;
            }}
        />
    );
};

export default WrapperTable;
