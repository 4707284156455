import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    OrderingValues,
    PaginatedApiResponseData,
    PaginatedFetchParams,
    PaginatedRequest,
} from '../../types';
import { PhylloInstagramCreatorProfile, PhylloTiktokCreatorProfile } from './profiles.types';

export type PhylloTiktokCreatorProfilesSearchSortableFields =
    | keyof Pick<PhylloTiktokCreatorProfile, 'average_views' | 'follower_count' | 'engagement_rate'>
    | 'follower_growth'
    | 'total_views_growth'
    | 'total_likes_growth';

export const isPhylloTiktokCreatorProfilesSearchSortableField = (
    field: any
): field is PhylloTiktokCreatorProfilesSearchSortableFields => {
    const sortableFields: PhylloTiktokCreatorProfilesSearchSortableFields[] = [
        'average_views',
        'follower_count',
        'engagement_rate',
        'follower_growth',
        'total_views_growth',
        'total_likes_growth',
    ];

    return (
        typeof field === 'string' && sortableFields.includes(field as PhylloTiktokCreatorProfilesSearchSortableFields)
    );
};

type GetPhylloTiktokCreatorProfilesParams = Partial<
    PaginatedFetchParams &
        Partial<{
            min_follower_count: number;
            max_follower_count: number;
            min_average_views: number;
            max_average_views: number;
            bio_phrase: string;
            has_contact_details: boolean;
            engagement_rate: number;
            audience_lookalikes: string;
            creator_lookalikes: string;
            // [[locationUuid, percentage], [...]]
            min_audience_locations: string;
            hashtags: string;
            ordering: OrderingValues<PhylloTiktokCreatorProfilesSearchSortableFields>;
            location: string;
            follower_growth_interval: number;
            follower_growth_percentage_value: number;
        }>
>;
type GetPhylloTiktokCreatorProfilesResponse = ApiResponse<PaginatedApiResponseData<PhylloTiktokCreatorProfile>, 200>;

export async function getPhylloTiktokCreatorProfiles(
    params: GetPhylloTiktokCreatorProfilesParams,
    requestInit?: RequestInit
): Promise<GetPhylloTiktokCreatorProfilesResponse> {
    const response = await fetchWithToken(
        `/api/creator-data/profiles/search/tiktok/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not get creator profiles');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PhylloInstagramCreatorsProfileSearchSortableFields =
    | keyof Pick<PhylloInstagramCreatorProfile, 'engagement_rate' | 'follower_count' | 'reels_views'>
    | 'follower_growth'
    | 'total_views_growth'
    | 'total_likes_growth';

export function isPhylloInstagramCreatorsProfileSearchSortableFields(
    field: any
): field is PhylloInstagramCreatorsProfileSearchSortableFields {
    const fields: PhylloInstagramCreatorsProfileSearchSortableFields[] = [
        'follower_count',
        'engagement_rate',
        'reels_views',
        'follower_growth',
        'total_views_growth',
        'total_likes_growth',
    ];
    return typeof field === 'string' && fields.includes(field as PhylloInstagramCreatorsProfileSearchSortableFields);
}

export type ParsedPhylloAudienceLocationsQueryParamItem = [locationUuid: string, minPercentage: number | null];

export type GetPhylloInstagramCreatorProfilesParams = Partial<
    PaginatedRequest & {
        min_follower_count: number;
        max_follower_count: number;
        min_reels_views: number;
        max_reels_views: number;
        engagement_rate: number;
        has_contact_details: boolean;
        bio_phrase: string;
        audience_lookalikes: string;
        creator_lookalikes: string;
        audience_interests: string;
        creator_interests: string;
        // [[locationUuid, percentage], [...]]
        min_audience_locations: string;
        location: string;
        hashtags: string;
        ordering: OrderingValues<PhylloInstagramCreatorsProfileSearchSortableFields>;
        follower_growth_interval: number;
        follower_growth_percentage_value: number;
    }
>;

type GetPhylloInstagramCreatorProfilesResponse = ApiResponse<
    PaginatedApiResponseData<PhylloInstagramCreatorProfile>,
    200
>;
export async function getPhylloInstagramCreatorProfiles(
    params: GetPhylloInstagramCreatorProfilesParams,
    requestInit?: RequestInit
): Promise<GetPhylloInstagramCreatorProfilesResponse> {
    const response = await fetchWithToken(
        `/api/creator-data/profiles/search/instagram/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not get creator profiles');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
