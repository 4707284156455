import React from 'react';
import cn from 'classnames';
import styles from './DateRangeLineChart.module.css';
import CustomDatePicker from '../../../../SharedComponents/CustomDatePicker/CustomDatePicker';
import LineChart, { LineChartProps } from '../LineChart/LineChart';
import Skeleton from '../../../Skeleton/Skeleton';

export type { Point } from '../LineChart/LineChart';
type DateRangeLineChartProps = {
    title?: string;
    error?: boolean;
    containerClassName?: string;
    dateFrom?: Date;
    onDateFromChange?: (date: Date) => void;
    dateTo?: Date;
    onDateToChange?: (date: Date) => void;
} & LineChartProps;

const noop = () => {};
const now = new Date();
const DateRangeLineChart = ({
    title,
    containerClassName,
    loading,
    error,
    dateFrom,
    onDateFromChange = noop,
    dateTo,
    onDateToChange = noop,
    ...lineChartProps
}: DateRangeLineChartProps) => {
    return (
        <div className={cn(styles.container, containerClassName)}>
            <div className={styles.heading}>
                <div>
                    {title && <p className={styles.title}>{loading ? <Skeleton width="40%" /> : title}</p>}
                    {!loading && error && (
                        <p className={styles.errorMessage}>Sorry, we're experiencing technical issues</p>
                    )}
                </div>

                <div className={styles.dateRangeContainer}>
                    <label>From</label>
                    <CustomDatePicker
                        className={styles.dateInput}
                        selectsStart
                        value={dateFrom}
                        onChange={onDateFromChange}
                        startDate={dateFrom}
                        endDate={dateTo}
                        maxDate={now}
                        dateFormat="MMM dd, yy"
                    />

                    <label>To</label>
                    <CustomDatePicker
                        className={styles.dateInput}
                        selectsEnd
                        value={dateTo}
                        onChange={onDateToChange}
                        startDate={dateFrom}
                        endDate={dateTo}
                        minDate={dateFrom}
                        maxDate={now}
                        dateFormat="MMM dd, yy"
                    />
                </div>
            </div>

            <LineChart loading={loading} {...lineChartProps} />
        </div>
    );
};

export default DateRangeLineChart;
