import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

export function useCurrentCreatorbaseUser() {
    const authContext = useContext(AuthContext);

    return {
        hasAccess: authContext.user?.creator_base_access,
        creatorbaseUserId: authContext.user?.creatorbase_user_id,
    };
}
