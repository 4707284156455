import React, { useCallback, useMemo } from 'react';
import Modal, { ModalContent, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import { FormikHelpers } from 'formik';
import PlanForm, { PlanFormValues } from '../components/PlanForm/PlanForm';
import styles from './CreatePlanModal.module.css';
import { postMediaPlan, Release } from '@round/api';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { showNotification } from '../../../../../../helpers';
import { AdvertisingContext } from '../../../contexts/AdvertisingContext/AdvertisingContext';
import { OptionsContext } from '../../../../../../contexts/OptionsContext/OptionsContext';

type CreatePlanModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    release: Release;
};

const CreatePlanModal = ({ isModalOpen, closeModal, release }: CreatePlanModalProps) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const [, contextUtils] = useNonNullContext(AdvertisingContext);

    const initialValues: PlanFormValues = useMemo(
        () => ({
            currency: currencies.length > 0 ? { value: currencies[0].id, label: currencies[0].name } : undefined,
            budget: '0',
            start_date: new Date().toISOString(),
            end_date: new Date().toISOString(),
            objective_description: '',
        }),
        [currencies]
    );

    const handleSubmit = useCallback(
        async (values: PlanFormValues, { setSubmitting }: FormikHelpers<PlanFormValues>) => {
            if (!values.currency) {
                return;
            }

            try {
                setSubmitting(true);
                const response = await postMediaPlan({
                    currency_id: values.currency.value,
                    release_id: release.id,
                    budget: values.budget.toString(),
                    end_date: values.end_date,
                    start_date: values.start_date,
                    objective_description: values.objective_description,
                });

                if (response.status === 200) {
                    contextUtils.setPlan(response.data);
                    closeModal();
                    showNotification('Plan created', 'info');
                    return;
                }

                showNotification('Could not create plan', 'error');
            } catch {
                showNotification('Could not create plan', 'error');
            } finally {
                setSubmitting(false);
            }
        },
        [closeModal, contextUtils, release.id]
    );

    return (
        <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={closeModal}>
            <ModalTitle>Create Plan</ModalTitle>
            <ModalContent className={styles.content}>
                <PlanForm handleSubmit={handleSubmit} initialValues={initialValues} />
            </ModalContent>
        </Modal>
    );
};

export default CreatePlanModal;
