import { Targeting } from '../../../../App.types';
import { createTargeting, createTargetingTemplate } from '../api/MediaPlanTargetings.api';
import { TargetingTemplate } from '../types/MediaPlanTargeting.types';

export async function createTargetingWithDefaultsSet(mediaPlanItemId: number): Promise<Targeting> {
    return createTargeting({ media_plan_item: mediaPlanItemId });
}

export async function createTargetingFromTemplate(mediaPlanItemId: number, templateId: number): Promise<Targeting> {
    return createTargeting({ media_plan_item: mediaPlanItemId, template: templateId });
}

export async function createTargetingTemplateWithDefaultsSet(clientId: number): Promise<TargetingTemplate> {
    return createTargetingTemplate({ client: clientId });
}
