import React from 'react';
import styles from './TikTokHashtagList.module.css';
import OrderByTableHeading from '../../../../SharedComponents/OrderByTableHeading/OrderByTableHeading';
import LoadingDataTableRow from '../../../../SharedComponents/LoadingDataTableRow/LoadingDataTableRow';
import { OrderByQueryParam } from '../../../../App.types';
import { MonitoredTiktokHashtagStats } from '../../TikTok.types';
import TikTokHashtagRow from './TikTokHashtagRow/TikTokHashtagRow';

type TikTokHashtagsListProps = {
    orderByState: [OrderByQueryParam, (orderBy: OrderByQueryParam) => void];
    statsCount?: number;
    stats: MonitoredTiktokHashtagStats[];
    openModal: (hashtagId: number) => void;
    loadData: null | (() => any);
    isLoadingData: boolean;
};

const TikTokHashtagsList = ({
    orderByState,
    statsCount,
    stats,
    openModal,
    loadData,
    isLoadingData,
}: TikTokHashtagsListProps) => {
    return (
        <div>
            {stats !== undefined && stats.length > 0 && (
                <div className={styles.displayCount}>
                    Displaying 1-{stats.length}
                    {statsCount !== undefined && <span> of {statsCount}</span>}
                </div>
            )}
            <table className={styles.table}>
                <thead>
                    <tr className={styles.columnHeaderRow}>
                        <th />
                        <th>Name</th>
                        <OrderByTableHeading fieldName={'video_count'} orderByState={orderByState}>
                            Video count
                        </OrderByTableHeading>
                        <OrderByTableHeading fieldName={'daily_change_video_relative'} orderByState={orderByState}>
                            Daily change videos
                        </OrderByTableHeading>
                        <OrderByTableHeading fieldName={'weekly_change_video_relative'} orderByState={orderByState}>
                            7 day change videos
                        </OrderByTableHeading>

                        <OrderByTableHeading fieldName={'view_count'} orderByState={orderByState}>
                            View count
                        </OrderByTableHeading>
                        <OrderByTableHeading fieldName={'daily_change_view_relative'} orderByState={orderByState}>
                            Daily change views
                        </OrderByTableHeading>
                        <OrderByTableHeading fieldName={'weekly_change_view_relative'} orderByState={orderByState}>
                            7 day change views
                        </OrderByTableHeading>
                    </tr>
                </thead>
                <tbody>
                    {stats.map((stat) => (
                        <TikTokHashtagRow key={stat.tiktok_id} data={stat} onClick={openModal} />
                    ))}
                    <LoadingDataTableRow loadData={loadData} isLoadingData={isLoadingData} colSpan={8} />
                </tbody>
            </table>
        </div>
    );
};

export default TikTokHashtagsList;
