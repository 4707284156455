import React, { useMemo } from 'react';
import styles from './InvoiceBar.module.css';
import cn from 'classnames';
import { FCWithChildren } from '../../../../utility/utility.types';
import {
    Currency,
    TiktokInfluencerUser,
    TiktokUserImage,
    XeroInvoice,
    TiktokUserStats,
    MediaPlan,
    InstagramInfluencerUser,
    TiktokInfluencerPost,
} from '@round/api';
import { InfluencerPlan, InstagramUserImage, MediaPlanItem } from '../../../../App.types';
import { InstagramInfluencerPost } from '../../../Advertising/InfluencerPlan/types/Instagram.types';
import { numberWithCommas, roundTo2Dp } from '../../../../helpers';
import InvoicePlanItemTable from '../InvoicePlanItemTable/InvoicePlanItemTable';
import { ReactComponent as ArrowIcon } from '../../../../assets/ArrowIcon.svg';
import useNonNullContext from '../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../contexts/OptionsContext/OptionsContext';
import Skeleton from '../../../../ui/Skeleton/Skeleton';
import { InstagramUserStats } from '../../../Instagram/Instagram.types';
import { PlanToInvoiceCurrencyExchangeRate } from '../useInvoiceData';

interface InvoiceBarProps {
    loading: boolean;
    invoice: XeroInvoice;
    mediaPlanItems: MediaPlanItem[];
    instagramInfluencerPosts: InstagramInfluencerPost[];
    instagramInfluencerUsers: InstagramInfluencerUser[];
    tiktokInfluencerPosts: TiktokInfluencerPost[];
    tiktokInfluencerUsers: TiktokInfluencerUser[];
    tiktokUserStats: TiktokUserStats[];
    instagramUserStats: InstagramUserStats[];
    tiktokUserImages: TiktokUserImage[];
    instagramUserImages: InstagramUserImage[];
    isExpanded: boolean;
    toggleIsExpanded: () => void;
    influencerPlans: InfluencerPlan[];
    exchangeRates: PlanToInvoiceCurrencyExchangeRate[];
    errorLoadingExchangeRates: boolean;
    mediaPlans: MediaPlan[];
}

type InfluencerDetails = {
    username: string;
    followers: number | undefined;
    profile_pic_url: string;
    platform: 'tiktok' | 'instagram';
};

export type CreatorInvoicePlanItemRow = InvoicePlanItemRowCommon & {
    campaign_type: 'creator';
    item_details: InfluencerDetails;
};

export type AdvertisingInvoicePlanItemRow = InvoicePlanItemRowCommon & {
    campaign_type: 'advertising';
    name: string;
};

export type InvoicePlanItemRow = CreatorInvoicePlanItemRow | AdvertisingInvoicePlanItemRow;

type InvoicePlanItemRowCommon = {
    id: number;
    plan_id: number;
    plan_currency: Currency | undefined;
    url: string;
    round_cost: number | string;
    client_cost: number;
    spent: number;
    brand: string;
    campaign: string;
    client: string;
};

export const InvoiceBar: FCWithChildren<InvoiceBarProps> = ({
    loading,
    invoice,
    mediaPlanItems,
    instagramInfluencerPosts,
    tiktokInfluencerPosts,
    tiktokUserStats,
    instagramUserStats,
    tiktokUserImages,
    instagramUserImages,
    isExpanded,
    toggleIsExpanded,
    tiktokInfluencerUsers,
    instagramInfluencerUsers,
    influencerPlans,
    exchangeRates,
    errorLoadingExchangeRates,
    mediaPlans,
}) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const invoiceCurrency = currencies.find((curr) => curr.name === invoice.currency_code);

    const MediaPlanItemRows: InvoicePlanItemRow[] = useMemo(() => {
        const rows: AdvertisingInvoicePlanItemRow[] = mediaPlanItems.map((item) => {
            const mediaPlan = mediaPlans.find((plan) => plan.id === item.media_plan);
            return {
                id: item.id,
                plan_id: item.media_plan,
                plan_currency: mediaPlan?.currency,
                url: `/campaigns/${mediaPlan?.release.id}/advertising?postId=${item.id}&expandedGroups=${item.group?.id}`,
                name: `${item.channel.name} - ${item.format.name}`,
                round_cost:
                    typeof mediaPlan?.client_commission === 'string'
                        ? roundTo2Dp(Number(item.cost) * (1 - Number(mediaPlan.client_commission)))
                        : '-',
                client_cost: roundTo2Dp(Number(item.cost)),
                spent: roundTo2Dp(item.amount_spent_in_media_plan_currency ?? 0),
                brand: mediaPlan?.release.brand.name || '',
                campaign: mediaPlan?.release.name || '',
                client: mediaPlan?.release.brand.client.name || '',
                campaign_type: 'advertising',
            };
        });
        return rows;
    }, [mediaPlanItems, mediaPlans]);

    const tiktokPostRows: InvoicePlanItemRow[] = useMemo(() => {
        const rows: CreatorInvoicePlanItemRow[] = tiktokInfluencerPosts.map((post) => {
            const influencer = tiktokInfluencerUsers.find((influencer) => influencer.id === post.influencer_id);
            const plan = influencerPlans.find((plan) => plan.id === post.plan_id);
            return {
                id: post.id,
                plan_id: post.plan_id,
                plan_currency: plan?.currency,
                url: `/campaigns/${plan?.release.id}/creators?postId=${post.id}&expandedGroups=${post.group_id}#tiktok`,
                item_details: {
                    username: influencer?.username || '-',
                    followers: tiktokUserStats.find((stat) => stat.user_id === influencer?.user)?.follower_count,
                    profile_pic_url:
                        tiktokUserImages.find((img) => img.user_id === influencer?.user)?.avatar_thumb.cached_url || '',
                    platform: 'tiktok',
                },
                round_cost: roundTo2Dp(Number(post.cost)),
                client_cost: roundTo2Dp(post.client_cost),
                spent: post.status === 'live' ? roundTo2Dp(Number(post.cost)) : 0,
                brand: plan?.release.brand.name ?? '-',
                campaign: plan?.release.name ?? '-',
                client: plan?.release.brand.client.name ?? '-',
                campaign_type: 'creator',
            };
        });
        return rows;
    }, [tiktokInfluencerPosts, tiktokInfluencerUsers, influencerPlans, tiktokUserStats, tiktokUserImages]);

    const instagramPostRows: InvoicePlanItemRow[] = useMemo(() => {
        const rows: CreatorInvoicePlanItemRow[] = instagramInfluencerPosts.map((post) => {
            const influencer = instagramInfluencerUsers.find((influencer) => influencer.id === post.influencer_id);
            const plan = influencerPlans.find((plan) => post.plan_id === plan.id);
            return {
                id: post.id,
                plan_id: post.plan_id,
                plan_currency: plan?.currency,
                url: `/campaigns/${plan?.release.id}/creators?postId=${post.id}&expandedGroups=${post.group_id}#instagram`,
                item_details: {
                    username: influencer?.username || '',
                    followers: instagramUserStats.find((stat) => stat.instagram_user === influencer?.user)
                        ?.follower_count,
                    profile_pic_url:
                        instagramUserImages.find((img) => img.user_id === influencer?.user)?.avatar_thumb.cached_url ||
                        '',
                    platform: 'instagram',
                },
                round_cost: roundTo2Dp(Number(post.cost)),
                client_cost: roundTo2Dp(post.client_cost),
                spent: post.status === 'live' ? roundTo2Dp(Number(post.cost)) : 0,
                brand: plan?.release.brand.name || '-',
                campaign: plan?.release.name || '-',
                client: plan?.release.brand.client.name || '-',
                campaign_type: 'creator',
            };
        });
        return rows;
    }, [instagramInfluencerPosts, instagramInfluencerUsers, influencerPlans, instagramUserStats, instagramUserImages]);

    const allPlanRows = useMemo(() => {
        return [...MediaPlanItemRows, ...tiktokPostRows, ...instagramPostRows];
    }, [MediaPlanItemRows, tiktokPostRows, instagramPostRows]);

    const plansHaveDiffCurrency = useMemo(() => {
        return !allPlanRows.map((row) => row.plan_currency?.id).every((id) => id === invoiceCurrency?.id);
    }, [allPlanRows, invoiceCurrency?.id]);

    const plansHaveMultipleDiffCurrencies = useMemo(() => {
        return Array.from(new Set(allPlanRows.map((row) => row.plan_currency?.id))).length > 1;
    }, [allPlanRows]);

    const plansHaveNullCommission = useMemo(() => {
        return allPlanRows.some((row) => typeof row.round_cost !== 'number');
    }, [allPlanRows]);

    const displayTotalValues =
        (errorLoadingExchangeRates && !plansHaveMultipleDiffCurrencies && !plansHaveNullCommission) ||
        (!errorLoadingExchangeRates && !plansHaveNullCommission);

    const InvoiceTotalAsInvoiceCurrency = invoice.amount_sub_total;

    const InvoiceTotalAsPlanCurrency = useMemo(() => {
        if (plansHaveDiffCurrency && !plansHaveMultipleDiffCurrencies) {
            const exchangeRate = exchangeRates[0]?.exchange_rate;
            return InvoiceTotalAsInvoiceCurrency / Number(exchangeRate);
        }
    }, [plansHaveDiffCurrency, plansHaveMultipleDiffCurrencies, InvoiceTotalAsInvoiceCurrency, exchangeRates]);

    const totalRoundCostAsPlanCurrency = useMemo(() => {
        const costsWithCommission = allPlanRows
            .map((row) => row.round_cost)
            .filter((c): c is number => typeof c === 'number');
        const total = costsWithCommission.reduce((prev, curr) => {
            return prev + curr;
        }, 0);
        return roundTo2Dp(total);
    }, [allPlanRows]);

    const totalRoundCostAsInvoiceCurrency = useMemo(() => {
        const costsWithCommission = allPlanRows
            .map((row) => row.round_cost)
            .filter((c): c is number => typeof c === 'number');
        if (!plansHaveDiffCurrency) {
            const nativeTotal = costsWithCommission.reduce((prev, curr) => {
                return prev + curr;
            }, 0);
            return roundTo2Dp(nativeTotal);
        }

        const convertedTotal = allPlanRows.reduce((prev, curr) => {
            if (typeof curr.round_cost !== 'number' || typeof prev !== 'number') {
                return prev;
            }
            if (curr.plan_currency?.id === invoiceCurrency?.id) {
                return prev + curr.round_cost;
            }
            const exchangeRate = exchangeRates.find(
                (rate) => rate.plan_id === curr.plan_id && rate.plan_type === curr.campaign_type
            )?.exchange_rate;
            if (exchangeRate) {
                return prev + curr.round_cost * Number(exchangeRate);
            }
            return prev + curr.round_cost;
        }, 0);
        return roundTo2Dp(convertedTotal);
    }, [allPlanRows, invoiceCurrency?.id, plansHaveDiffCurrency, exchangeRates]);

    const totalSpentAsInvoiceCurrency = useMemo(() => {
        if (!plansHaveDiffCurrency) {
            const nativeTotal = allPlanRows.reduce((prev, curr) => {
                if (typeof curr.round_cost !== 'number') {
                    return prev;
                }
                return prev + curr.spent;
            }, 0);
            return roundTo2Dp(nativeTotal);
        }

        const convertedTotal = allPlanRows.reduce((prev, curr) => {
            if (typeof curr.round_cost !== 'number') {
                return prev;
            }
            if (curr.plan_currency?.id !== invoiceCurrency?.id) {
                const exchangeRate = exchangeRates.find(
                    (rate) => rate.plan_id === curr.plan_id && rate.plan_type === curr.campaign_type
                )?.exchange_rate;
                if (exchangeRate) {
                    return prev + curr.spent * Number(exchangeRate);
                }
            }
            return prev + curr.spent;
        }, 0);

        return roundTo2Dp(convertedTotal);
    }, [allPlanRows, invoiceCurrency?.id, exchangeRates, plansHaveDiffCurrency]);
    //eslint-disable-next-line -- we are keeping but not using this calculation.
    const totalClientCostAsPlanCurrency = useMemo(() => {
        const total = allPlanRows
            .map((row) => row.client_cost)
            .reduce((prev, curr) => {
                return prev + curr;
            }, 0);
        return roundTo2Dp(total);
    }, [allPlanRows]);
    //eslint-disable-next-line -- we are keeping but not using this calculation.
    const totalClientCostAsInvoiceCurrency = useMemo(() => {
        if (!plansHaveDiffCurrency) {
            const nativeTotal = allPlanRows
                .map((row) => row.client_cost)
                .reduce((prev, curr) => {
                    return prev + curr;
                }, 0);
            return roundTo2Dp(nativeTotal);
        }

        const convertedTotal = allPlanRows.reduce((prev, curr) => {
            if (curr.plan_currency?.id !== invoiceCurrency?.id) {
                const exchangeRate = exchangeRates.find(
                    (rate) => rate.plan_id === curr.plan_id && rate.plan_type === curr.campaign_type
                )?.exchange_rate;
                if (exchangeRate) {
                    return prev + curr.client_cost * Number(exchangeRate);
                }
            }
            return prev + curr.client_cost;
        }, 0);

        return roundTo2Dp(convertedTotal);
    }, [allPlanRows, plansHaveDiffCurrency, exchangeRates, invoiceCurrency?.id]);

    const totalRoundRevenueAsPlanCurrency = useMemo(() => {
        if (!InvoiceTotalAsPlanCurrency) {
            return;
        }
        const total = InvoiceTotalAsPlanCurrency - totalRoundCostAsPlanCurrency;
        return roundTo2Dp(total);
    }, [totalRoundCostAsPlanCurrency, InvoiceTotalAsPlanCurrency]);

    const totalRoundRevenueAsInvoiceCurrency = useMemo(() => {
        const total = InvoiceTotalAsInvoiceCurrency - totalRoundCostAsInvoiceCurrency;
        return roundTo2Dp(total);
    }, [InvoiceTotalAsInvoiceCurrency, totalRoundCostAsInvoiceCurrency]);

    //eslint-disable-next-line -- we are keeping but not using this calculation.
    const percentageSpentAsInvoiceCurrency = useMemo(() => {
        const percentage = (totalSpentAsInvoiceCurrency / totalRoundCostAsInvoiceCurrency) * 100;
        if (!isFinite(percentage) || !percentage) {
            return 0;
        }
        return percentage;
    }, [totalRoundCostAsInvoiceCurrency, totalSpentAsInvoiceCurrency]);

    const planCurrency = useMemo(() => {
        return allPlanRows[0] ? allPlanRows[0].plan_currency : null;
    }, [allPlanRows]);

    const revenueOnInvoice = useMemo(() => {
        return (totalRoundRevenueAsInvoiceCurrency / InvoiceTotalAsInvoiceCurrency) * 100;
    }, [totalRoundRevenueAsInvoiceCurrency, InvoiceTotalAsInvoiceCurrency]);

    const canDisplayRevenueOnInvoice =
        displayTotalValues && ((plansHaveDiffCurrency && !errorLoadingExchangeRates) || !plansHaveDiffCurrency);
    const displayedRevenueOnInvoice = canDisplayRevenueOnInvoice ? `${revenueOnInvoice.toFixed(1)}%` : '-';
    const isRevenueLow = canDisplayRevenueOnInvoice && revenueOnInvoice < 15;

    const isInvoicePaid = invoice.status === 'PAID';

    const brandNames = Array.from(new Set(allPlanRows.map((row) => row.brand))).join(', ') || '-';
    const clientNames = Array.from(new Set(allPlanRows.map((row) => row.client))).join(', ') || '-';

    return (
        <>
            {loading && (
                <div className={styles.barSkeletonWrapper}>
                    <Skeleton className={styles.barSkeleton} />
                </div>
            )}
            {!loading && (
                <section className={styles.wrapper}>
                    <div className={styles.dropdownIconContainer}>
                        <button
                            className={cn(styles.expandButton, {
                                [styles.expanded]: isExpanded,
                            })}
                            onClick={toggleIsExpanded}
                        >
                            <ArrowIcon />
                        </button>
                    </div>

                    <div className={styles.statsWrapper}>
                        <div className={styles.invoiceDetailsContainer}>
                            <div className={cn(styles.groupInfoColumn, styles.invoiceNumberColumn)}>
                                <div className={styles.groupInfoColumnValue}>{invoice.invoice_number}</div>
                            </div>

                            <div className={cn(styles.groupInfoColumn, styles.brandsColumn)}>
                                <div className={cn(styles.groupInfoColumnValue, styles.ellipsedValue)}>
                                    {brandNames}
                                </div>
                                <div className={cn(styles.groupInfoColumnSubValue, styles.ellipsedValue)}>
                                    {clientNames}
                                </div>
                            </div>

                            <div className={cn(styles.groupInfoColumn, styles.referenceColumn)}>
                                <div className={cn(styles.groupInfoColumnValue, styles.referenceValue)}>
                                    {invoice.reference}
                                </div>
                            </div>
                        </div>
                        <div className={styles.invoiceStatsContainer}>
                            <div className={cn(styles.groupInfoColumn, styles.invoiceStat)}>
                                <div className={styles.groupInfoColumnValue}>
                                    {InvoiceTotalAsInvoiceCurrency
                                        ? `${invoiceCurrency?.symbol}${numberWithCommas(
                                              InvoiceTotalAsInvoiceCurrency.toFixed(2)
                                          )}`
                                        : '-'}
                                </div>
                                <div className={styles.groupInfoColumnSubValue}>Invoice Total</div>
                            </div>

                            <div className={cn(styles.groupInfoColumn, styles.invoiceStat)}>
                                {!displayTotalValues && <div className={styles.groupInfoColumnValue}>-</div>}

                                {displayTotalValues && (!plansHaveDiffCurrency || plansHaveMultipleDiffCurrencies) && (
                                    <div className={styles.groupInfoColumnValue}>
                                        {`${invoiceCurrency?.symbol}${numberWithCommas(
                                            totalRoundCostAsInvoiceCurrency.toFixed(2)
                                        )}`}
                                    </div>
                                )}
                                {displayTotalValues && plansHaveDiffCurrency && !plansHaveMultipleDiffCurrencies && (
                                    <div className={styles.groupInfoColumnValue}>
                                        <div>{`${planCurrency?.symbol ? planCurrency.symbol : ''}${numberWithCommas(
                                            totalRoundCostAsPlanCurrency.toFixed(2)
                                        )}`}</div>

                                        {!errorLoadingExchangeRates && (
                                            <div className={styles.groupInfoColumnSubValue}>
                                                {`(${invoiceCurrency?.symbol}${numberWithCommas(
                                                    totalRoundCostAsInvoiceCurrency.toFixed(2)
                                                )})`}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className={styles.groupInfoColumnSubValue}>round Cost</div>
                            </div>

                            <div className={cn(styles.groupInfoColumn, styles.invoiceStat)}>
                                {!displayTotalValues && <div className={styles.groupInfoColumnValue}>-</div>}

                                {displayTotalValues && (!plansHaveDiffCurrency || plansHaveMultipleDiffCurrencies) && (
                                    <div className={styles.groupInfoColumnValue}>
                                        {`${invoiceCurrency?.symbol}${numberWithCommas(
                                            totalRoundRevenueAsInvoiceCurrency.toFixed(2)
                                        )}`}
                                    </div>
                                )}
                                {displayTotalValues && plansHaveDiffCurrency && !plansHaveMultipleDiffCurrencies && (
                                    <div className={styles.groupInfoColumnValue}>
                                        <div>{`${planCurrency?.symbol ? planCurrency.symbol : ''}${
                                            totalRoundRevenueAsPlanCurrency
                                                ? numberWithCommas(totalRoundRevenueAsPlanCurrency.toFixed(2))
                                                : '-'
                                        }`}</div>

                                        {!errorLoadingExchangeRates && (
                                            <div className={styles.groupInfoColumnSubValue}>
                                                {`(${invoiceCurrency?.symbol}${numberWithCommas(
                                                    totalRoundRevenueAsInvoiceCurrency.toFixed(2)
                                                )})`}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className={styles.groupInfoColumnSubValue}>round Rev.</div>
                            </div>

                            <div className={cn(styles.groupInfoColumn, styles.invoiceStat)}>
                                <div
                                    className={cn(styles.groupInfoColumnValue, {
                                        [styles.revenueWarning]: isRevenueLow,
                                    })}
                                >
                                    {displayedRevenueOnInvoice}
                                </div>
                                <div className={styles.groupInfoColumnSubValue}>Revenue on Invoice</div>
                            </div>

                            <div className={cn(styles.groupInfoColumn, styles.invoiceStat)}>
                                <div className={styles.groupInfoColumnValue}>Status</div>
                                <div
                                    className={cn(styles.groupInfoColumnSubValue, {
                                        [styles.invoicePaid]: isInvoicePaid,
                                    })}
                                >
                                    {`Invoice ${isInvoicePaid ? 'Paid' : 'Created'}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {isExpanded && loading && (
                <div>
                    <Skeleton className={styles.tableRowSkeleton} />
                    <Skeleton className={styles.tableRowSkeleton} />
                    <Skeleton className={styles.tableRowSkeleton} />
                </div>
            )}

            {isExpanded && !loading && <InvoicePlanItemTable rows={allPlanRows} />}
        </>
    );
};
