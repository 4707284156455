import { ErrorInfo } from 'react';
import { ErrorBoundary as LibErrorBoundary, ErrorBoundaryPropsWithRender } from 'react-error-boundary';
import { datadogRum } from '@datadog/browser-rum';

export const mapUnknownError = (error: unknown, errorInfo?: ErrorInfo): Error => {
    const e = new Error();
    e.name = 'ReactRenderingError';
    e.message = error instanceof Error ? error.message : String(error);
    e.stack = errorInfo?.componentStack ?? undefined;
    e.cause = error;

    return e;
};

const handleLogErrors = (error: Error, errorInfo: ErrorInfo): void => {
    const e = mapUnknownError(error, errorInfo);

    if (process.env.NODE_ENV === 'production') {
        datadogRum.addError(e);
    } else {
        console.error(e.message, e);
    }
};

const ErrorBoundary = (props: ErrorBoundaryPropsWithRender) => {
    return (
        <LibErrorBoundary
            {...props}
            onError={(error, errorInfo) => {
                handleLogErrors(error, errorInfo);
                props.onError?.(error, errorInfo);
            }}
        >
            {props.children}
        </LibErrorBoundary>
    );
};

export default ErrorBoundary;
