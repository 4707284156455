import React, { useContext, useEffect, useMemo, useState } from 'react';
import Table, { TableProps } from '../../../../../../ui/NewTable/Table';
import { CellProps, Column } from 'react-table';
import { Button, Image, Skeleton } from '@round/ui-kit';
import {
    InfluencerPostGroup,
    TiktokInfluencerUser,
    TikTokInfluencerUserStats,
    TiktokUser,
    TiktokUserImage,
    TiktokUserStats,
    TiktokVideo,
    TiktokVideoStats,
    UserGroup,
    TiktokInfluencerPost,
} from '@round/api';
import styles from './TiktokInfluencerPostTable.module.css';
import {
    asMoney,
    buildTiktokUserUrl,
    displayOptionalNumericTableValue,
    formatDateObjShort,
    formatNumberToKNotation,
    numberWithCommas,
    openInNewTab,
    roundTo2Dp,
    showNotification,
} from '../../../../../../helpers';
import { ProtectedByUserGroups } from '../../../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import { ReactComponent as DiagramIcon } from '../../../../../../assets/Diagram.svg';
import { ReactComponent as PaymentIcon } from '../../../../../../assets/PaymentCard.svg';
import cn from 'classnames';
import InfluencerPostStatus from '../../../components/InfluencerPostStatus/InfluencerPostStatus';
import { ReactComponent as LinkIcon } from 'assets/icons/Link.svg';
import { EDITABLE_STATUSES, paymentStatusOptions } from '../../../InfluencerPlan.helpers';
import { useCheckUserGroupsAccess } from '../../../../../Auth/hooks/useCheckUserGroupsAccess';
import useUserHasGroupAccess from '../../../../../Auth/hooks/useUserHasGroupAccess';
import CurrencyInput from 'react-currency-input-field';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../../../contexts/OptionsContext/OptionsContext';
import TableSelect from '../../../../components/TableSelect/TableSelect';
import PaymentStatusSelectValue from '../../../components/PaymentStatusSelectValue/PaymentStatusSelectValue';
import { cpm } from '../../../../Metrics/Metrics';
import { InfluencerPlanContext } from '../../../contexts/InfluencerPlanContext';
import useCreatorPlanTiktokInfluencerPosts from '../../../contexts/TiktokCreatorsContext/useCreatorPlanTiktokInfluencerPosts';
import InfluencerPostStatusModal from '../../InfluencerPostStatusModal/InfluencerPostStatusModal';
import { AddInfluencerToCampaignOptionsProvider } from '../../../../../Influencer/containers/AddInfluencerToCampaign/AddInfluencerToCampaignContext';
import { TiktokUserDataProvider } from '../../../../../TikTok/containers/TiktokInfluencerExplore/TiktokInfluencerUserModal/TiktokUserDataContext/TiktokUserDataContext';
import TiktokInfluencerUserModal from '../../../../../TikTok/containers/TiktokInfluencerExplore/TiktokInfluencerUserModal/TiktokInfluencerUserModal';
import { getTiktokInfluencerUserStats } from '../../../../../TikTok/TikTok.api';
import UpdateTiktokInfluencerPostModal from '../../InfluencerPlanItemModal/UpdateTiktokInfluencerPostModal';
import { Draggable } from 'react-beautiful-dnd';
import capitalize from 'lodash/capitalize';
import TiktokInfluencerPostPaymentRequestModal from '../../InfluencerPostPaymentRequestModal/TiktokInfluencerPostPaymentRequestModal';

export type TiktokInfluencerPostRow = TiktokInfluencerPost & {
    influencer: TiktokInfluencerUser | undefined;
    user: TiktokUser | undefined;
    userStats: TiktokUserStats | undefined;
    userImage: TiktokUserImage | undefined;
    tiktokVideo: TiktokVideo | undefined;
    tiktokVideoStats: TiktokVideoStats | undefined;
};

type TiktokInfluencerPostsTableColumn = Column<TiktokInfluencerPostRow> & {
    groups?: UserGroup[];
    editable?: boolean;
};

type Props = Pick<TableProps<TiktokInfluencerPostRow>, 'data' | 'loading'> & {
    renderTotals?: boolean;
    highlightedPost?: number;
    group?: InfluencerPostGroup;
};

const TiktokInfluencerPostsTable = ({ renderTotals = false, highlightedPost, group, ...props }: Props) => {
    const isUserInfluencerEditor = useCheckUserGroupsAccess(['influencer_editor']);
    const userHasGroupAccess = useUserHasGroupAccess();
    const { currencies } = useNonNullContext(OptionsContext);
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const {
        updateInfluencerPost,
        updateInfluencerPostStatus,
        updateInfluencerUser,
        setInfluencerUser,
        setInfluencerPost,
    } = useCreatorPlanTiktokInfluencerPosts();

    const [selectedPost, setSelectedPost] = useState<TiktokInfluencerPostRow | null>(null);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isPaymentRequestModalOpen, setIsPaymentRequestModalOpen] = useState(false);

    const [isTiktokInfluencerUserModalOpen, setIsTiktokInfluencerUserModalOpen] = useState(false);
    const [tiktokInfluencerUserStats, setTiktokInfluencerUserStats] = useState<TikTokInfluencerUserStats | null>(null);

    const columns: TiktokInfluencerPostsTableColumn[] = useMemo(() => {
        const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (e.shiftKey) {
                return;
            }

            if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.blur();
            }
        };

        const columns: TiktokInfluencerPostsTableColumn[] = [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({ value }) => <>{value}</>,
            },
            {
                Header: 'Account',
                accessor: 'influencer_id',
                Cell: ({ row: { original } }) => {
                    const [
                        isOpenInfluencerUserModalButtonLoading,
                        setIsOpenInfluencerUserModalButtonLoading,
                    ] = useState(false);

                    if (props.loading) {
                        return <Skeleton />;
                    }

                    const username = original.influencer?.username ?? original.user?.unique_id;
                    return (
                        <div className={styles.accountContainer}>
                            <Image
                                className={styles.avatar}
                                src={
                                    original.userImage?.avatar_thumb.cached_url ||
                                    original.userImage?.avatar_thumb.original_url
                                }
                                alt={original.user?.nickname}
                            />

                            <div className={styles.accountDetails}>
                                <span>
                                    {username ? (
                                        <a
                                            onClick={(e) => e.stopPropagation()}
                                            className={styles.username}
                                            href={buildTiktokUserUrl(username)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            @{username}
                                        </a>
                                    ) : (
                                        '-'
                                    )}
                                </span>

                                <span>
                                    {original.userStats
                                        ? `${formatNumberToKNotation(original.userStats.follower_count)} followers`
                                        : '-'}
                                </span>
                            </div>

                            {typeof original.influencer_id === 'number' && (
                                <ProtectedByUserGroups groups={['user_admin', 'explorer_tiktok_viewer']}>
                                    <Button
                                        className={styles.tiktokInfluencerUserModalButton}
                                        loading={isOpenInfluencerUserModalButtonLoading}
                                        onClick={(e) => {
                                            if (typeof original.influencer_id !== 'number') {
                                                return;
                                            }

                                            e.stopPropagation();
                                            setIsOpenInfluencerUserModalButtonLoading(true);
                                            getTiktokInfluencerUserStats(original.influencer_id)
                                                .then((response) => {
                                                    if (response.status === 200) {
                                                        setTiktokInfluencerUserStats(response.data);
                                                        setIsTiktokInfluencerUserModalOpen(true);
                                                        return;
                                                    }

                                                    showNotification(response.data.detail, 'error');
                                                })
                                                .catch(() =>
                                                    showNotification(
                                                        'Could not open tiktok influencer user modal',
                                                        'error'
                                                    )
                                                )
                                                .finally(() => setIsOpenInfluencerUserModalButtonLoading(false));
                                        }}
                                    >
                                        <DiagramIcon />
                                    </Button>
                                </ProtectedByUserGroups>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                editable: true,
                Cell: ({ value, row: { original } }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    const isStatusEditable = EDITABLE_STATUSES.includes(value) && Boolean(isUserInfluencerEditor);
                    return (
                        <div
                            className={styles.statusContainer}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (value === 'live') {
                                    openInNewTab(original.post_url);
                                    return;
                                }

                                if (!isStatusEditable) {
                                    return;
                                }

                                setSelectedPost(original);
                                setIsStatusModalOpen(true);
                            }}
                        >
                            <button className={cn(styles.statusButton)}>
                                <InfluencerPostStatus
                                    className={styles.status}
                                    status={value}
                                    draftExpectedBy={original.draft_expected_by}
                                />
                            </button>
                            {value === 'live' && <LinkIcon className={styles.linkIcon} />}
                        </div>
                    );
                },
            },
            {
                Header: 'Contact',
                id: 'contactDetails',
                groups: ['influencer_editor'],
                editable: true,
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerPostRow>) => {
                    const [contactDetails, setContactDetails] = useState(original.influencer?.contact_details ?? '');

                    useEffect(() => {
                        setContactDetails(original.influencer?.contact_details ?? '');
                    }, [original.influencer?.contact_details]);

                    if (props.loading) {
                        return <Skeleton />;
                    }

                    const submit = () => {
                        if (contactDetails === original.influencer?.contact_details || !original.influencer) {
                            return;
                        }

                        updateInfluencerUser(original.influencer.id, { contact_details: contactDetails })
                            .then((response) => {
                                if (response.status === 200) {
                                    showNotification('Updated', 'info');
                                    return;
                                }

                                const message = response.data.contact_details
                                    ? String(response.data.contact_details)
                                    : 'Could not update contact details';

                                showNotification(message, 'error');
                                setContactDetails(original.influencer?.contact_details ?? '');
                            })
                            .catch(() => {
                                showNotification('Could not update contact details', 'error');
                                setContactDetails(original.influencer?.contact_details ?? '');
                            });
                    };

                    return (
                        <input
                            placeholder="Enter contact details"
                            className={styles.contactDetailsInput}
                            disabled={!original.influencer}
                            value={contactDetails}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => setContactDetails(e.target.value)}
                            onKeyDown={handleEnterKeyDown}
                            onBlur={submit}
                        />
                    );
                },
            },
            {
                Header: 'Cost',
                accessor: 'cost',
                groups: ['influencer_editor'],
                editable: true,
                Cell: ({ value, row: { original } }) => {
                    const [cost, setCost] = useState(value);
                    const currency = currencies.find((c) => c.id === original.currency_id);

                    useEffect(() => {
                        setCost(value);
                    }, [value]);

                    if (props.loading) {
                        return <Skeleton />;
                    }

                    const submit = () => {
                        if (Number(value) !== Number(cost)) {
                            updateInfluencerPost(original.id, { cost })
                                .then((response) => {
                                    if (response.status === 200) {
                                        showNotification('Updated', 'info');
                                        return;
                                    }

                                    const message = response.data.cost
                                        ? String(response.data.cost)
                                        : 'Could not update cost';

                                    showNotification(message, 'error');
                                    setCost(value);
                                })
                                .catch(() => {
                                    showNotification('Could not update cost', 'error');
                                    setCost(value);
                                });
                        }
                    };

                    return (
                        <CurrencyInput
                            className={cn({
                                [styles.invalidInputValue]: original.currency_id !== influencerPlan?.currency.id,
                            })}
                            prefix={currency?.symbol}
                            groupSeparator=","
                            decimalSeparator="."
                            allowDecimals={false}
                            decimalScale={0}
                            decimalsLimit={0}
                            value={cost}
                            onValueChange={(newVal) => setCost(newVal ?? value)}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={handleEnterKeyDown}
                            onBlur={submit}
                            disabled={original.payment_status === 'PAID'}
                        />
                    );
                },
            },
            {
                Header: 'Client Cost',
                accessor: 'client_cost',
                groups: ['influencer_editor'],
                Cell: ({ value, row: { original } }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    const currency = currencies.find((c) => c.id === original.currency_id);
                    return <>{asMoney(value, currency)}</>;
                },
            },
            {
                Header: 'Payment',
                accessor: 'payment_status',
                groups: ['influencer_editor'],
                editable: true,
                Cell: ({ value, row: { original } }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return (
                        <div onClick={(e) => e.stopPropagation()}>
                            <TableSelect
                                options={paymentStatusOptions}
                                value={paymentStatusOptions.find((o) => o.value === value)}
                                onChange={(option) =>
                                    option && updateInfluencerPost(original.id, { payment_status: option.value })
                                }
                                components={{ SingleValue: PaymentStatusSelectValue }}
                            />
                        </div>
                    );
                },
            },
            {
                Header: 'Payment Request',
                accessor: 'payment_request_status',
                groups: ['round_planner'],
                editable: true,
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerPostRow>) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    const status =
                        original.paypal_payment_status !== 'UNPAID'
                            ? original.paypal_payment_status
                            : original.payment_request_status;

                    if (!status) {
                        return <>-</>;
                    }

                    const isPaymentRequestAvailable = status === 'UNREQUESTED' && original.payment_status !== 'PAID';

                    const displayStatus = status
                        .split('_')
                        .map((word) => capitalize(word.toLowerCase()))
                        .join(' ');

                    return (
                        <div onClick={(e) => e.stopPropagation()} className={styles.paymentRequestStatus}>
                            <span
                                className={cn(styles.status, {
                                    [styles.paid]: status === 'PAID',
                                    [styles.inProgress]: status === 'IN_PROGRESS',
                                    [styles.requested]: status === 'REQUESTED',
                                    [styles.unrequested]: status === 'UNREQUESTED',
                                    [styles.failed]: status === 'FAILED',
                                })}
                            >
                                {displayStatus}
                            </span>
                            {isPaymentRequestAvailable && (
                                <Button
                                    onClick={() => {
                                        setSelectedPost(original);
                                        setIsPaymentRequestModalOpen(true);
                                    }}
                                    className={styles.button}
                                >
                                    <PaymentIcon className={styles.paymentIcon} />
                                </Button>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: 'Est. views',
                accessor: 'estimated_views_override',
                groups: ['influencer_editor'],
                editable: true,
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerPostRow>) => {
                    const [estimatedViews, setEstimatedViews] = useState(
                        original.estimated_views_override ?? original.estimated_views
                    );

                    if (props.loading) {
                        return <Skeleton />;
                    }

                    const submit = () => {
                        if (
                            (original.estimated_views_override === null &&
                                estimatedViews === original.estimated_views) ||
                            estimatedViews === original.estimated_views_override
                        ) {
                            return;
                        }

                        updateInfluencerPost(original.id, { estimated_views_override: estimatedViews })
                            .then((response) => {
                                if (response.status === 200) {
                                    showNotification('Updated', 'info');
                                    return;
                                }

                                const message = response.data.estimated_views_override
                                    ? String(response.data.estimated_views_override)
                                    : 'Could not update estimated views';

                                showNotification(message, 'error');
                                setEstimatedViews(original.estimated_views_override ?? original.estimated_views);
                            })
                            .catch(() => {
                                setEstimatedViews(original.estimated_views_override ?? original.estimated_views);
                                showNotification('Could not update estimated views', 'error');
                            });
                    };

                    return (
                        <CurrencyInput
                            placeholder="Enter est. views"
                            groupSeparator=","
                            decimalSeparator="."
                            allowDecimals={false}
                            decimalScale={0}
                            decimalsLimit={0}
                            value={estimatedViews ?? undefined}
                            onValueChange={(value) =>
                                setEstimatedViews(typeof value === 'undefined' ? null : Number(value))
                            }
                            onClick={(e) => e.stopPropagation()}
                            onBlur={submit}
                            onKeyDown={handleEnterKeyDown}
                        />
                    );
                },
            },
            {
                Header: 'Est. CPM',
                id: 'estimatedCPM',
                groups: ['influencer_editor'],
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerPostRow>) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    const estimatedViews = original.estimated_views_override ?? original.estimated_views ?? 0;
                    const currency = currencies.find((c) => c.id === original.currency_id);
                    return <>{asMoney(cpm(Number(original.client_cost), estimatedViews), currency, 2)}</>;
                },
            },
            {
                Header: 'CPM',
                id: 'cpm',
                groups: ['influencer_editor'],
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerPostRow>) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    const currency = currencies.find((c) => c.id === original.currency_id);
                    return (
                        <>
                            {asMoney(
                                cpm(Number(original.cost), original.tiktokVideoStats?.play_count ?? 0),
                                currency,
                                2
                            )}
                        </>
                    );
                },
            },
            {
                Header: 'Notes',
                accessor: 'notes',
                groups: ['influencer_editor'],
                editable: true,
                Cell: ({ value, row: { original } }) => {
                    const [notes, setNotes] = useState(value);

                    if (props.loading) {
                        return <Skeleton />;
                    }

                    const submit = async (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
                        if (notes === value) {
                            return;
                        }

                        updateInfluencerPost(original.id, { notes })
                            .then((response) => {
                                if (response.status === 200) {
                                    showNotification('Updated', 'info');
                                    return;
                                }

                                const message = response.data.notes
                                    ? String(response.data.notes)
                                    : 'Could not update notes';

                                showNotification(message, 'error');
                                setNotes(value);
                            })
                            .catch(() => {
                                setNotes(value);
                                showNotification('Could not update notes', 'error');
                            });

                        e.currentTarget.scrollTop = 0;
                    };

                    return (
                        <textarea
                            rows={2}
                            className={styles.notesInput}
                            placeholder="-"
                            value={notes}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => setNotes(e.target.value)}
                            onBlur={submit}
                            onKeyDown={handleEnterKeyDown}
                        />
                    );
                },
            },
            {
                Header: 'Video uploaded',
                id: 'videoUploaded',
                Cell: ({
                    row: {
                        original: { tiktokVideo },
                    },
                }: CellProps<TiktokInfluencerPostRow>) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return (
                        <>
                            {(tiktokVideo?.create_time &&
                                formatDateObjShort(new Date(tiktokVideo?.create_time * 1000))) ||
                                '-'}
                        </>
                    );
                },
            },
            {
                Header: 'Video plays',
                id: 'videoPlays',
                Cell: ({
                    row: {
                        original: { tiktokVideoStats },
                    },
                }: CellProps<TiktokInfluencerPostRow>) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    const videoPlays = tiktokVideoStats?.play_count;
                    return <>{typeof videoPlays === 'number' ? numberWithCommas(videoPlays) : '-'}</>;
                },
            },
            {
                Header: 'Total engagements',
                id: 'totalEngagements',
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerPostRow>) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!original.tiktokVideoStats) {
                        return <>-</>;
                    }

                    const { digg_count, comment_count, share_count } = original.tiktokVideoStats;
                    return <>{numberWithCommas(digg_count + comment_count + share_count)}</>;
                },
            },
            {
                Header: 'Engagement rate',
                id: 'engagementRate',
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerPostRow>) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!original.tiktokVideoStats) {
                        return <>-</>;
                    }

                    const {
                        digg_count,
                        comment_count,
                        share_count,
                        play_count,
                        save_count,
                    } = original.tiktokVideoStats;
                    const totalEngagement = digg_count + comment_count + share_count + (save_count ?? 0);
                    const engagementRate = totalEngagement / play_count;
                    return <>{isFinite(engagementRate) ? `${roundTo2Dp(engagementRate * 100)}%` : '-'}</>;
                },
            },
            {
                Header: 'Likes',
                id: 'likes',
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerPostRow>) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!original.tiktokVideoStats) {
                        return <>-</>;
                    }

                    return <>{numberWithCommas(original.tiktokVideoStats.digg_count)}</>;
                },
            },
            {
                Header: 'Comments',
                id: 'comments',
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerPostRow>) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!original.tiktokVideoStats) {
                        return <>-</>;
                    }

                    return <>{numberWithCommas(original.tiktokVideoStats.comment_count)}</>;
                },
            },
            {
                Header: 'Shares',
                id: 'shares',
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerPostRow>) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!original.tiktokVideoStats) {
                        return <>-</>;
                    }

                    return <>{numberWithCommas(original.tiktokVideoStats.share_count)}</>;
                },
            },
            {
                Header: 'Saves',
                id: 'saves',
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerPostRow>) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!original.tiktokVideoStats || original.tiktokVideoStats.save_count === null) {
                        return <>-</>;
                    }

                    return <>{numberWithCommas(original.tiktokVideoStats.save_count)}</>;
                },
            },
            {
                Header: 'Ad code',
                accessor: 'ad_code',
                editable: true,
                Cell: ({ value, row: { original } }) => {
                    const [adCode, setAdCode] = useState(value);

                    if (props.loading) {
                        return <Skeleton />;
                    }

                    if (!isUserInfluencerEditor) {
                        return <>{value}</>;
                    }

                    const submit = () => {
                        if (adCode === value) {
                            return;
                        }

                        updateInfluencerPost(original.id, { ad_code: adCode })
                            .then((response) => {
                                if (response.status === 200) {
                                    showNotification('Updated', 'info');
                                    return;
                                }

                                const message = response.data.ad_code
                                    ? String(response.data.ad_code)
                                    : 'Could not update ad code';
                                showNotification(message, 'error');
                                setAdCode(value);
                            })
                            .catch(() => {
                                setAdCode(value);
                                showNotification('Could not update ad code', 'error');
                            });
                    };

                    return (
                        <input
                            placeholder="Enter ad code"
                            value={adCode}
                            onChange={(e) => setAdCode(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            onBlur={submit}
                            onKeyDown={handleEnterKeyDown}
                        />
                    );
                },
            },
        ];

        return columns.filter((col) => !col.groups?.length || userHasGroupAccess(col.groups ?? [], 'all'));
    }, [
        props.loading,
        isUserInfluencerEditor,
        updateInfluencerUser,
        currencies,
        influencerPlan?.currency.id,
        updateInfluencerPost,
        userHasGroupAccess,
    ]);

    // skeletons are rendered in table cells,
    // so we need some rows to display skeletons on initial loading
    const initialLoading = !props.data.length && props.loading;
    const emptyRows = new Array(5).fill({}) as TiktokInfluencerPostRow[];
    const rows = initialLoading ? emptyRows : props.data;

    const handleRowClick = isUserInfluencerEditor
        ? (row: TiktokInfluencerPostRow) => {
              setSelectedPost(row);
              setIsEditModalOpen(true);
          }
        : undefined;

    return (
        <>
            <Table
                disableSortBy
                columns={columns}
                tableClassName={styles.table}
                theadClassName={styles.thead}
                {...props}
                data={rows}
                onRowClick={handleRowClick}
                renderHeaderRow={(headers) => (
                    <tr>
                        {headers.map((column) => (
                            <th
                                key={column.id}
                                className={cn({
                                    [styles.editorColumn]: !!(column as TiktokInfluencerPostsTableColumn)?.groups?.filter(
                                        (group) => group === 'influencer_editor' || group === 'round_planner'
                                    ).length,
                                })}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                )}
                renderRow={({ row, rowProps, cellProps }) => (
                    <Draggable
                        key={row.original.id}
                        draggableId={String(row.original.id)}
                        index={row.original.id}
                        isDragDisabled={!isUserInfluencerEditor}
                    >
                        {(provided) => (
                            <tr
                                {...rowProps}
                                {...row.getRowProps()}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                className={cn({
                                    [styles.highlighted]:
                                        typeof highlightedPost === 'number' && highlightedPost === row.original.id,
                                })}
                            >
                                {row.cells.map((cell) => {
                                    const column = cell.column as TiktokInfluencerPostsTableColumn;
                                    return (
                                        <td
                                            {...cellProps}
                                            {...cell.getCellProps()}
                                            className={cn({
                                                [styles.editorCell]: !!column.groups?.filter(
                                                    (group) =>
                                                        group === 'influencer_editor' || group === 'round_planner'
                                                ).length,
                                                [styles.editableCell]: isUserInfluencerEditor && column.editable,
                                            })}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        )}
                    </Draggable>
                )}
                renderFooter={() => {
                    if (!renderTotals) {
                        return null;
                    }

                    const currencySymbol = influencerPlan?.currency.symbol;
                    const posts = props.data;
                    const cost = posts.some((row) => !!row.cost)
                        ? posts.reduce((cost, row) => Number(cost) + (row.cost ? Number(row.cost) : 0), 0)
                        : undefined;

                    const clientCost = posts.some((row) => !!row.client_cost)
                        ? posts.reduce((clientCost, row) => clientCost + row.client_cost, 0)
                        : undefined;

                    const livePosts = posts.filter((row) => row.status === 'live');
                    const liveCost = livePosts.some((row) => !!row.cost)
                        ? livePosts.reduce((cost, row) => cost + (row.cost ? Number(row.cost) : 0), 0)
                        : undefined;

                    const estimatedViews = posts.reduce(
                        (views, row) => views + Number(row.estimated_views_override ?? row.estimated_views ?? 0),
                        0
                    );

                    const videoPlays = posts.some((row) => !!row.tiktokVideoStats?.play_count)
                        ? posts.reduce((plays, row) => plays + (row.tiktokVideoStats?.play_count ?? 0), 0)
                        : undefined;

                    const likes = posts.some((row) => !!row.tiktokVideoStats?.digg_count)
                        ? posts.reduce((likes, row) => likes + (row.tiktokVideoStats?.digg_count ?? 0), 0)
                        : undefined;

                    const comments = posts.some((row) => !!row.tiktokVideoStats?.comment_count)
                        ? posts.reduce((comments, row) => comments + (row.tiktokVideoStats?.comment_count ?? 0), 0)
                        : undefined;

                    const shares = posts.some((row) => !!row.tiktokVideoStats?.share_count)
                        ? posts.reduce((shares, row) => shares + (row.tiktokVideoStats?.share_count ?? 0), 0)
                        : undefined;

                    const saves = posts.some((row) => !!row.tiktokVideoStats?.save_count)
                        ? posts.reduce((saves, row) => saves + (row.tiktokVideoStats?.save_count ?? 0), 0)
                        : undefined;

                    const estimatedCPM = Number(cpm(clientCost, estimatedViews).toFixed(2));
                    const liveCPM = Number(cpm(liveCost, videoPlays).toFixed(2));

                    const totalEngagements =
                        typeof likes === 'number' ||
                        typeof comments === 'number' ||
                        typeof shares === 'number' ||
                        typeof saves === 'number'
                            ? (likes ?? 0) + (comments ?? 0) + (shares ?? 0) + (saves ?? 0)
                            : undefined;

                    const engagementRate =
                        typeof totalEngagements === 'number' && typeof videoPlays === 'number'
                            ? totalEngagements / videoPlays
                            : undefined;

                    const isCostOverBudget =
                        isUserInfluencerEditor && clientCost && group
                            ? clientCost > parseFloat(group.budget)
                            : undefined;

                    return (
                        <tfoot>
                            <tr>
                                <td className={styles.td} colSpan={isUserInfluencerEditor ? 4 : 2}>
                                    Total
                                </td>
                                <ProtectedByUserGroups groups={['influencer_editor']}>
                                    <td className={styles.editorCell}>
                                        <CurrencyInput
                                            className={styles.costTotal}
                                            readOnly
                                            prefix={currencySymbol}
                                            value={cost}
                                            groupSeparator=","
                                            decimalSeparator="."
                                            allowDecimals={false}
                                            decimalScale={0}
                                            decimalsLimit={0}
                                        />
                                    </td>

                                    <td
                                        className={cn(styles.editorCell, {
                                            [styles.negativeCell]: isCostOverBudget === true,
                                            [styles.positiveCell]: isCostOverBudget === false,
                                        })}
                                    >
                                        {currencySymbol}
                                        {numberWithCommas(roundTo2Dp(clientCost ?? 0))}
                                    </td>

                                    <td colSpan={2} />

                                    <td className={styles.editorCell}>
                                        {displayOptionalNumericTableValue(estimatedViews)}
                                    </td>
                                    <td className={styles.editorCell}>
                                        {currencySymbol}
                                        {displayOptionalNumericTableValue(estimatedCPM)}
                                    </td>
                                    <td className={styles.editorCell}>
                                        {currencySymbol}
                                        {displayOptionalNumericTableValue(liveCPM)}
                                    </td>
                                </ProtectedByUserGroups>

                                <td colSpan={2} />

                                <td>{typeof videoPlays === 'number' ? numberWithCommas(videoPlays) : '-'}</td>
                                <td>
                                    {typeof totalEngagements === 'number' ? numberWithCommas(totalEngagements) : '-'}
                                </td>
                                <td>
                                    {typeof engagementRate === 'number' && isFinite(engagementRate)
                                        ? `${roundTo2Dp(engagementRate * 100)}%`
                                        : -''}
                                </td>
                                <td>{typeof likes === 'number' ? numberWithCommas(likes) : '-'}</td>
                                <td>{typeof comments === 'number' ? numberWithCommas(comments) : '-'}</td>
                                <td>{typeof shares === 'number' ? numberWithCommas(shares) : '-'}</td>
                                <td>{typeof saves === 'number' ? numberWithCommas(saves) : '-'}</td>
                                <td colSpan={1} />
                            </tr>
                        </tfoot>
                    );
                }}
            />

            <InfluencerPostStatusModal
                status={selectedPost?.status}
                draftExpectedBy={selectedPost?.draft_expected_by}
                isModalOpen={isStatusModalOpen}
                onClose={() => setIsStatusModalOpen(false)}
                onChange={(status, draftExpectedBy) => {
                    if (!selectedPost) {
                        return;
                    }

                    updateInfluencerPostStatus({
                        influencer_post_id: selectedPost.id,
                        status,
                        draft_expected_by: draftExpectedBy,
                    })
                        .then(() => {
                            showNotification('Updated', 'info');
                            setSelectedPost(null);
                            setIsStatusModalOpen(false);
                        })
                        .catch(() => {
                            showNotification('Could not update status', 'error');
                        });
                }}
            />

            <UpdateTiktokInfluencerPostModal
                isModalOpen={isEditModalOpen}
                closeModal={() => setIsEditModalOpen(false)}
                onUpdated={() => {
                    setIsEditModalOpen(false);
                    setSelectedPost(null);
                    showNotification('Updated', 'info');
                }}
                onDeleted={() => {
                    setIsEditModalOpen(false);
                    setSelectedPost(null);
                    showNotification('Deleted', 'info');
                }}
                post={selectedPost}
            />

            <ProtectedByUserGroups groups={['user_admin', 'explorer_tiktok_viewer']}>
                <AddInfluencerToCampaignOptionsProvider>
                    <TiktokUserDataProvider>
                        <TiktokInfluencerUserModal
                            isModalOpen={isTiktokInfluencerUserModalOpen}
                            closeModal={() => {
                                setTiktokInfluencerUserStats(null);
                                setIsTiktokInfluencerUserModalOpen(false);
                            }}
                            influencerUserStats={tiktokInfluencerUserStats}
                            influencerUserId={tiktokInfluencerUserStats?.influencer_user_id}
                            onInfluencerUpdated={setInfluencerUser}
                        />
                    </TiktokUserDataProvider>
                </AddInfluencerToCampaignOptionsProvider>
            </ProtectedByUserGroups>

            <ProtectedByUserGroups groups={['round_planner']}>
                <TiktokInfluencerPostPaymentRequestModal
                    influencerPost={selectedPost}
                    influencer={selectedPost?.influencer ?? null}
                    isOpen={isPaymentRequestModalOpen}
                    onClose={() => {
                        setIsPaymentRequestModalOpen(false);
                        setSelectedPost(null);
                    }}
                    onSuccess={() => {
                        if (!selectedPost) {
                            return;
                        }
                        setInfluencerPost({ ...selectedPost, payment_request_status: 'REQUESTED' });
                    }}
                />
            </ProtectedByUserGroups>
        </>
    );
};

export default TiktokInfluencerPostsTable;
