import {
    TiktokUser,
    InfluencerPlan,
    PostPayment,
    TiktokInfluencerPost,
    InstagramUser,
    InstagramUserImage,
    YouTubeInfluencerPost,
} from '@round/api';
import styles from './PastPaymentsTable.module.css';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import useNonNullContext from 'Hooks/useNonNullContext';
import capitalize from 'lodash/capitalize';
import cn from 'classnames';
import WrapperPaginationTable, {
    WrapperPaginationTableProps,
} from 'ui/WrapperTable/WrapperPaginationTable/WrapperPaginationTable';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import Account from 'Modules/Finance/Payments/components/Account/Account';
import Campaign from 'Modules/Finance/Payments/components/Campaign/Campaign';
import CostLabel from 'ui/DataDisplay/Money/CostLabel/CostLabel';
import { mapInstagramUserToAccountData, mapTiktokUserToAccountData } from '../../components/Account/helpers';
import { InstagramInfluencerPost } from 'Modules/Advertising/InfluencerPlan/types/Instagram.types';

export type PastPaymentsTableRow = PostPayment & {
    plan: InfluencerPlan | undefined;
    tiktokUser: TiktokUser | undefined;
    tiktokInfluencerPost: TiktokInfluencerPost | undefined;
    instagramInfluencerPost: InstagramInfluencerPost | undefined;
    instagramUser: InstagramUser | undefined;
    instagramUserImage: InstagramUserImage | undefined;
    youtubeInfluencerPost: YouTubeInfluencerPost | undefined;
};

type Props = Omit<WrapperPaginationTableProps<PastPaymentsTableRow>, 'columns'>;

type TableCellContext<K extends keyof PastPaymentsTableRow> = CellContext<
    PastPaymentsTableRow,
    PastPaymentsTableRow[K]
>;

const PastPaymentsTable = ({ ...props }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const columns: ColumnDef<PastPaymentsTableRow, any>[] = [
        {
            id: 'account',
            header: 'Account',
            cell: ({ row: { original } }) => {
                if (
                    !props.isLoading &&
                    !original.tiktokUser &&
                    !original.instagramUser &&
                    !original.youtubeInfluencerPost
                ) {
                    return '-';
                }

                const accountData = original.tiktokUser
                    ? mapTiktokUserToAccountData(original.tiktokUser)
                    : original.instagramUser
                    ? mapInstagramUserToAccountData(original.instagramUser, original.instagramUserImage)
                    : original.youtubeInfluencerPost
                    ? //using channel name from post until we can fetch channels
                      {
                          imageUrl: '',
                          nickname: original.youtubeInfluencerPost.channel_title || '-',
                          username: '',
                          profileUrl: '',
                      }
                    : undefined;

                return <Account isLoading={props.isLoading} user={accountData} />;
            },
        },
        {
            id: 'postUrl',
            header: 'Post',
            cell: ({ row: { original } }) => {
                if (props.isLoading) {
                    return <Skeleton />;
                }

                const postUrl =
                    original.tiktokInfluencerPost?.post_url ||
                    original.instagramInfluencerPost?.post_url ||
                    original.youtubeInfluencerPost?.post_url;

                return (
                    <a href={postUrl} target="_blank" rel="noreferrer" className={styles.postLink}>
                        Post link ↗
                    </a>
                );
            },
        },
        {
            accessorKey: 'plan',
            header: 'Campaign',
            cell: ({ getValue }: TableCellContext<'plan'>) => (
                <Campaign isLoading={props.isLoading} plan={getValue()} />
            ),
        },
        {
            id: 'postCost',
            header: 'Post Cost',
            cell: ({ row: { original } }) => {
                if (props.isLoading) {
                    return <Skeleton />;
                }

                const post =
                    original.tiktokInfluencerPost || original.instagramInfluencerPost || original.youtubeInfluencerPost;

                if (!post || typeof post.cost !== 'string') {
                    return '-';
                }

                return (
                    <CostLabel
                        cost={post.cost}
                        currencySymbol={currencies.find((curr) => curr.id === post.currency_id)?.symbol ?? ''}
                    />
                );
            },
        },
        {
            accessorKey: 'amount',
            header: 'Amount Paid',
            cell: ({ getValue, row: { original } }: TableCellContext<'amount'>) => {
                if (props.isLoading) {
                    return <Skeleton />;
                }

                const amount = getValue();

                if (amount === null) {
                    return '-';
                }

                return (
                    <CostLabel
                        cost={amount}
                        currencySymbol={currencies.find((curr) => curr.id === original.currency_id)?.symbol ?? ''}
                    />
                );
            },
        },
        {
            header: 'Status',
            accessorKey: 'paypal_payment_status',
            cell: ({ getValue }: TableCellContext<'paypal_payment_status'>) => {
                const value = getValue();
                if (props.isLoading) {
                    return <Skeleton />;
                }

                if (!value) {
                    return <>-</>;
                }

                const displayValue = value
                    .split('_')
                    .map((word) => capitalize(word.toLowerCase()))
                    .join(' ');

                return (
                    <span
                        className={cn({
                            [styles.paid]: value === 'PAID',
                            [styles.inProgress]: value === 'IN_PROGRESS',
                            [styles.failed]: value === 'FAILED',
                        })}
                    >
                        {displayValue}
                    </span>
                );
            },
        },
    ];

    return <WrapperPaginationTable className={styles.table} manualPagination columns={columns} {...props} />;
};

export default PastPaymentsTable;
