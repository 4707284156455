import React, { useCallback, useContext, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { Formik, Field, FormikHelpers, Form } from 'formik';
import styles from './SignIn.module.css';
import FormField from '../../../../ui/DataEntry/FormField/FormField';
import Label from '../../../../ui/DataEntry/Label/Label';
import Button from '../../../../ui/Buttons/Button/Button';
import { signIn } from '@round/api';
import { AuthContext } from '../../contexts/AuthContext';
import { Helmet } from 'react-helmet';

type FormValues = {
    email: string;
    password: string;
};

const initialValues: FormValues = {
    email: '',
    password: '',
};

const SignIn = () => {
    const authContext = useContext(AuthContext);
    const [errorSigningIn, setErrorSigningIn] = useState(false);
    const location = useLocation();
    const nextUrl = new URLSearchParams(location.search).get('next') ?? '/';

    const onSubmit = useCallback(
        async ({ email, password }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
            if (typeof authContext.setTokens !== 'function') {
                return;
            }

            try {
                setErrorSigningIn(false);
                setSubmitting(true);
                const tokens = await signIn(email, password);
                authContext.setTokens(tokens);
            } catch {
                setErrorSigningIn(true);
            } finally {
                setSubmitting(false);
            }
        },
        [authContext]
    );

    if (authContext.loading) {
        return null;
    }

    if (authContext.authenticated) {
        return <Navigate to={nextUrl} replace />;
    }

    return (
        <>
            <Helmet title="Sign in | round" />
            <div className={styles.container}>
                <h1 className={styles.heading}>Sign in</h1>
                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <FormField>
                                <Label htmlFor="email">Email</Label>
                                <Field
                                    tabIndex={1}
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    className={styles.input}
                                />
                            </FormField>
                            <FormField>
                                <div className={styles.row}>
                                    <Label htmlFor="password">Password</Label>
                                    <Link className={styles.link} to="/forgot-password/">
                                        Forgot Password?
                                    </Link>
                                </div>
                                <Field
                                    tabIndex={2}
                                    id="password"
                                    name="password"
                                    type="password"
                                    placeholder="Enter your password"
                                    className={styles.input}
                                />
                            </FormField>

                            {errorSigningIn && <span className={styles.error}>Could not authenticate</span>}

                            <Button
                                tabIndex={3}
                                loading={isSubmitting}
                                className={styles.submit}
                                htmlType="submit"
                                type="filled"
                                color="black"
                            >
                                Sign in
                            </Button>
                            <span className={styles.createAccountCopy}>
                                Don't have account yet?&nbsp;
                                <a className={styles.link} href="mailto:info@thisisround.co.uk">
                                    Request an Account.
                                </a>
                            </span>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default SignIn;
