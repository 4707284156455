import React, { FunctionComponent, useState } from 'react';
import FacebookTargetingSelectionForm from './FacebookTargetingSelectionForm';
import {
    FacebookTargetingAudienceSelectionOption,
    FacebookTargetingAudienceSelectionValues,
} from '../../../../../../../../App.types';
import styles from './FacebookDetailedTargeting.module.css';
import Button from '../../../../../../../../ui/Buttons/Button/Button';

type FacebookDetailedTargetingInputProps = {
    value: FacebookTargetingAudienceSelectionValues;
    onChange: (value: FacebookTargetingAudienceSelectionValues) => void;
    error: string | undefined;
};

const FacebookDetailedTargetingInput: FunctionComponent<FacebookDetailedTargetingInputProps> = ({
    value,
    onChange,
}) => {
    const [showNarrowAudienceForm, setShowNarrowAudienceForm] = useState(value.narrow.length > 0);
    const [showExcludeAudienceForm, setShowExcludeAudienceForm] = useState(value.exclude.length > 0);

    const onIncludedSelectionsChange = (selections: FacebookTargetingAudienceSelectionOption[]) =>
        onChange({ ...value, include: selections });
    const onNarrowedSelectionsChange = (selections: FacebookTargetingAudienceSelectionOption[]) =>
        onChange({ ...value, narrow: selections });
    const onExcludeSelectionsChange = (selections: FacebookTargetingAudienceSelectionOption[]) =>
        onChange({ ...value, exclude: selections });

    const showNarrowAudienceFormHandler = (e: React.MouseEvent) => {
        e.preventDefault();
        setShowNarrowAudienceForm(true);
    };

    const showExcludeAudienceFormHandler = (e: React.MouseEvent) => {
        e.preventDefault();
        setShowExcludeAudienceForm(true);
    };

    return (
        <div>
            <FacebookTargetingSelectionForm
                title="Include people who match"
                selections={value.include}
                onChange={onIncludedSelectionsChange}
            />

            {value.include.length > 0 && !showNarrowAudienceForm && (
                <Button
                    type="filled"
                    color="black"
                    className={styles.showButton}
                    onClick={showNarrowAudienceFormHandler}
                >
                    Narrow audience
                </Button>
            )}

            {showNarrowAudienceForm && (
                <FacebookTargetingSelectionForm
                    selections={value.narrow}
                    onChange={onNarrowedSelectionsChange}
                    title="and must also match"
                />
            )}

            {!showExcludeAudienceForm && (
                <Button
                    type="filled"
                    color="black"
                    className={styles.showButton}
                    onClick={showExcludeAudienceFormHandler}
                >
                    Exclude
                </Button>
            )}

            {showExcludeAudienceForm && (
                <FacebookTargetingSelectionForm
                    hideSuggestions
                    selections={value.exclude}
                    onChange={onExcludeSelectionsChange}
                    title="Exclude people who match"
                />
            )}
        </div>
    );
};

export default FacebookDetailedTargetingInput;
