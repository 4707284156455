import { useCallback, useState } from 'react';
import { getInstagramAudio, InstagramAudio } from '@round/api';

export default function useInstagramAudio(audioId?: number | undefined | null) {
    const [audio, setAudio] = useState<InstagramAudio | null>(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (typeof audioId !== 'number') {
                return;
            }

            try {
                setIsLoading(true);
                setError(null);
                const response = await getInstagramAudio(audioId, requestInit);
                if (response.status === 404) {
                    setError(response.data.detail);
                    setIsInitialized(true);
                    return;
                }

                setAudio(response.data);
                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setError('Could not fetch instagram audio');
                setIsInitialized(true);
            } finally {
                setIsLoading(false);
            }
        },
        [audioId]
    );

    const fetchData = useCallback(async (audioId: number, requestInit?: RequestInit) => {
        try {
            setIsLoading(true);
            setError(null);
            const response = await getInstagramAudio(audioId, requestInit);
            if (response.status === 404) {
                setError(response.data.detail);
                setIsInitialized(true);
                return response;
            }

            setAudio(response.data);
            setIsInitialized(true);
            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            setError('Could not fetch instagram audio');
            setIsInitialized(true);
            throw e;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const reset = useCallback(() => {
        setIsInitialized(false);
        setIsLoading(false);
        setError(null);
        setAudio(null);
    }, []);

    return {
        audio,
        isLoading,
        isInitialized,
        error,
        init,
        fetchData,
        reset,
    };
}
