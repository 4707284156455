import { Context } from '@fluentui/react-context-selector';
import { getTiktokAudios, TiktokAudio } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { makeErrorStateSlice, makeLoadingStateSlice, makeSuccessStateSlice } from 'utility/dataState';

export type State = {
    [audioId: number]: DataState<TiktokAudio | null> | undefined;
};

export const initialState: State = {};

export type Actions =
    | ReducerActionWithPayload<'loadTiktokAudios', number[]>
    | ReducerActionWithPayload<'tiktokAudiosSuccess', { audioIds: number[]; audios: TiktokAudio[] }>
    | ReducerActionWithPayload<'tiktokAudiosErrorLoading', { audioIds: number[]; error: string }>;

export const reducer = createReducer<State, Actions>({
    loadTiktokAudios: (state, { payload: audioIds }) => {
        const loadingAudios = makeLoadingStateSlice<number, TiktokAudio | null>(audioIds);

        return { ...state, ...loadingAudios };
    },
    tiktokAudiosSuccess: (state, { payload: { audioIds, audios } }) => {
        const initialized = makeSuccessStateSlice<number, TiktokAudio | null>(
            audioIds,
            (id) => audios.find((v) => v.id === id) ?? null
        );

        return { ...state, ...initialized };
    },
    tiktokAudiosErrorLoading: (state, { payload: { audioIds, error } }) => {
        const errors = makeErrorStateSlice<number, TiktokAudio | null>(audioIds, `Couldn't load tiktok audios`);

        return { ...state, ...errors };
    },
});

export function makeTiktokAudiosDataHook<Value extends BaseDataHookContextValue<Actions> | null>(
    context: Context<Value>,
    selector: ContextSelector<Value, State>
) {
    return function () {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (audioIds: number[], requestInit?: RequestInit) => {
                if (!audioIds.length) {
                    dispatch({ type: 'tiktokAudiosSuccess', payload: { audioIds: [], audios: [] } });
                    return;
                }

                try {
                    dispatch({ type: 'loadTiktokAudios', payload: audioIds });
                    const response = await getTiktokAudios(
                        { id: audioIds.join(','), page_size: audioIds.length },
                        requestInit
                    );

                    if (response.status === 200) {
                        dispatch({
                            type: 'tiktokAudiosSuccess',
                            payload: { audioIds, audios: response.data.results },
                        });
                        return response;
                    }

                    dispatch({
                        type: 'tiktokAudiosErrorLoading',
                        payload: { audioIds, error: 'Could not get tiktok audios' },
                    });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({
                        type: 'tiktokAudiosErrorLoading',
                        payload: { audioIds, error: 'Could not get tiktok audios' },
                    });
                    throw e;
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
}
