import { AppServer, RouteHandlerContext } from '../../mirage';
import { belongsTo, Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { MediaPlan } from './mediaPlan.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    mediaPlan: Model.extend({
        release: belongsTo('release'),
        currency: belongsTo('currency'),
    }) as ModelDefinition<MediaPlan>,
};

export const serializers = {
    mediaPlan: makeSerializer<MediaPlan>(['release', 'currency']),
};

export const factories = {
    mediaPlan: createFactory<MediaPlan>({
        start_date() {
            return new Date().toISOString();
        },
        end_date() {
            return new Date().toISOString();
        },
        updated_at() {
            return new Date().toISOString();
        },
        budget(index: number) {
            return index.toString();
        },
        client_commission: null,
        currency_exchange_rate: null,
        objective_description: '',
        notes: '',
        afterCreate(mediaPlan, s) {
            const server: AppServer = s;
            if (!mediaPlan.release) {
                mediaPlan.update({
                    release: server.create('release'),
                });
            }

            if (!mediaPlan.currency) {
                mediaPlan.update({
                    currency: server.create('currency'),
                });
            }
        },
    }),
};

export function handleMediaPlanRequests(server: AppServer) {
    const getMediaPlansPath = '/api/advertising/viewsets/media-plan/';
    server.get(getMediaPlansPath, function (this: RouteHandlerContext, schema, request) {
        const { release_id, search = '' } = request.queryParams;
        const mediaPlans = schema
            .all('mediaPlan')
            .filter((plan) => !release_id || Number(plan.release.id) === Number(release_id))
            .filter((plan) => !search || plan.release.name.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(mediaPlans, {
            url: getMediaPlansPath,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'mediaPlan'),
        });
    });

    server.patch('/api/advertising/viewsets/media-plan/:id/', function (this: RouteHandlerContext, schema, request) {
        const { id } = request.params;
        const mediaPlan = schema.find('mediaPlan', id);
        if (!mediaPlan) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        mediaPlan.update({ ...JSON.parse(request.requestBody) });
        return mediaPlan;
    });

    server.post('/api/advertising/viewsets/media-plan/', function (this: RouteHandlerContext, schema, request) {
        return schema.create('mediaPlan', JSON.parse(request.requestBody));
    });

    server.delete('/api/advertising/viewsets/media-plan/:id/', function (this: RouteHandlerContext, schema, request) {
        const mediaPlan = schema.find('mediaPlan', request.params.id);
        if (!mediaPlan) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        mediaPlan.destroy();
        return new Response(204);
    });
}
