import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { RoundClientUser } from './roundClientUser.types';

export async function getRoundClientUsers(
    params: Partial<
        PaginatedRequest & {
            search: string;
            client_id: string;
        }
    >,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<RoundClientUser>, 200>> {
    const response = await fetchWithToken(
        `/api/users/viewsets/round-client-user/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not fetch user');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getRoundClientUser(
    id: number,
    requestInit?: RequestInit
): Promise<ApiResponse<RoundClientUser, 200> | NotFoundResponse> {
    const response = await fetchWithToken(`/api/users/viewsets/round-client-user/${id}/`, requestInit);

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not fetch user');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PatchRoundClientUserApiBody = Partial<
    Pick<RoundClientUser, 'client_ids' | 'first_name' | 'last_name' | 'is_active'>
>;

export async function patchRoundClientUser(
    id: number,
    body: PatchRoundClientUserApiBody
): Promise<
    | ApiResponse<RoundClientUser, 200>
    | NotFoundResponse
    | ApiResponse<ApiResponseError<PatchRoundClientUserApiBody>, 400>
> {
    const response = await fetchWithToken(`/api/users/viewsets/round-client-user/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not patch user');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PostRoundClientUserApiBody = Pick<
    RoundClientUser,
    'email' | 'username' | 'client_ids' | 'first_name' | 'last_name'
>;

export async function postRoundClientUser(
    data: PostRoundClientUserApiBody
): Promise<ApiResponse<RoundClientUser, 201> | ApiResponse<ApiResponseError<PostRoundClientUserApiBody>, 400>> {
    const response = await fetchWithToken(`/api/users/viewsets/round-client-user/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not post user');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export async function sendRoundClientUserWelcomeEmail(
    userId: number
): Promise<ApiResponse<null, 204> | ApiResponse<{ message: string }, 400>> {
    const response = await fetchWithToken(`/api/users/viewsets/round-client-user/${userId}/send-welcome-email/`, {
        method: 'POST',
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not send welcome email');
    }

    return {
        status: 204,
        data: null,
    };
}
