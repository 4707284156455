import { useCallback, useState } from 'react';
import { getInstagramUser, InstagramUser } from '@round/api';

export default function useInstagramUser(userId?: number | undefined | null) {
    const [user, setUser] = useState<InstagramUser | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (typeof userId !== 'number') {
                return;
            }

            try {
                setIsLoading(true);
                setError(null);
                const response = await getInstagramUser(userId, requestInit);
                if (response.status === 404) {
                    setError(response.data.detail);
                    setIsInitialized(true);
                    return;
                }

                setUser(response.data);
                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setError('Could not fetch instagram user');
                setIsInitialized(true);
            } finally {
                setIsLoading(false);
            }
        },
        [userId]
    );

    const reset = () => {
        setUser(null);
        setIsLoading(false);
        setIsInitialized(false);
        setError(null);
    };

    return {
        user,
        isLoading,
        isInitialized,
        error,
        init,
        reset,
    };
}
