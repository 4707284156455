import React from 'react';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/SearchIcon.svg';
import { DebounceInput } from 'react-debounce-input';
import styles from './SearchInput.module.css';
import cn from 'classnames';

export type SearchInputProps = {
    onChange: (value: string) => void;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    debounceTimout?: number;
};

const SearchInput = ({
    debounceTimout = 750,
    placeholder = 'Search',
    value,
    className,
    disabled,
    onChange,
}: SearchInputProps) => (
    <div className={cn(styles.container, className)}>
        <SearchIcon className={styles.icon} />
        <DebounceInput
            className={styles.input}
            type="text"
            disabled={disabled}
            debounceTimeout={debounceTimout}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    </div>
);

export default SearchInput;
