import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReportContext } from '../ReportContext';

export function useRefreshReportProjects() {
    const dispatch = useNonNullContextSelector(ReportContext, ([, dispatch]) => dispatch);

    return () => {
        dispatch({ type: 'resetCampaigns' });
        dispatch({ type: 'resetPlatformAggregatedPostStats' });
        dispatch({ type: 'resetCampaignStats' });
        dispatch({ type: 'resetProjects' });
        dispatch({ type: 'resetPosts' });
        dispatch({ type: 'resetTiktokVideoStats' });
        dispatch({ type: 'resetTiktokVideos' });
        dispatch({ type: 'resetTiktokAudios' });
        dispatch({ type: 'resetAudioTimeSeriesData' });
        dispatch({ type: 'resetInstagramPostStats' });
        dispatch({ type: 'resetTiktokAccountsData' });
        dispatch({ type: 'resetYoutubeChannels' });
        dispatch({ type: 'resetInstagramUserImages' });
    };
}
