import React from 'react';
import styles from './Toggle.module.css';
import cn from 'classnames';

type ToggleProps = {
    value: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    className?: string;
    ariaLabel?: string;
};

const Toggle = ({ value, onChange, disabled, className, ariaLabel }: ToggleProps) => {
    return (
        <div
            role="button"
            aria-pressed={value}
            aria-label={ariaLabel}
            className={cn(styles.track, className, { [styles.on]: value, [styles.disabled]: disabled })}
            onClick={() => !disabled && onChange(!value)}
        >
            <div className={cn(styles.thumb, { [styles.on]: value })} />
        </div>
    );
};

export default Toggle;
