import React from 'react';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import TikTokHashtagsExplore from '../../../Modules/TikTok/containers/TikTokHashtagsExplore/TikTokHashtagsExplore';
import styles from './ExploreTikTokHashtagsPage.module.css';

const ExploreTikTokHashtagsPage = () => (
    <div>
        <NavBar />
        <div className={styles.content}>
            <TikTokHashtagsExplore />
        </div>
    </div>
);

export default ExploreTikTokHashtagsPage;
