import React, { ButtonHTMLAttributes, forwardRef, Ref } from 'react';
import styles from './ButtonShell.module.css';
import cn from 'classnames';

const ButtonShell: React.ForwardRefRenderFunction<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>> = (
    { children, className, ...props },
    ref: Ref<HTMLButtonElement>
) => {
    return (
        <button ref={ref} className={cn(styles.button, className)} {...props}>
            {children}
        </button>
    );
};

export default forwardRef(ButtonShell);
