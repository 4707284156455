import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { RoundClientUser } from './roundClientUser.types';
import { AppServer, RouteHandlerContext } from '../mirage';

export const models = {
    roundClientUser: Model as ModelDefinition<RoundClientUser>,
};

export const serializers = {
    roundClientUser: makeSerializer<RoundClientUser>([]),
};

export const factories = {
    roundClientUser: createFactory<RoundClientUser>({
        username(index: number) {
            return `user${index}@example.com`;
        },
        first_name(index: number) {
            return `first name ${index}`;
        },
        last_name(index: number) {
            return `last name ${index}`;
        },
        email(index: number) {
            return `user${index}@example.com`;
        },
        client_ids: [],
    }),
};

export function handleRequests(server: AppServer) {
    const clientUserPath = '/api/users/viewsets/round-client-user/';
    server.get(clientUserPath, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const roundClientUsers = schema
            .all('roundClientUser')
            .filter(
                (user) =>
                    !search ||
                    user.first_name.toLowerCase().includes(search.toLowerCase()) ||
                    user.last_name.toLowerCase().includes(search.toLowerCase())
            );

        return buildPaginatedResponse(roundClientUsers, {
            url: clientUserPath,
            serialize: (resource) => this.serialize(resource, 'roundClientUser'),
            queryParams: request.queryParams,
        });
    });
}
