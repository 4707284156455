import { ControlProps, OptionTypeBase, components } from 'react-select';
import styles from './ExternalMultiValuesControl.module.css';
import useOverflowingChildren from 'Hooks/useOverflowingChildren';
import { useState } from 'react';
import cn from 'classnames';
import { ReactComponent as CloseIcon } from 'assets/Close.svg';

type Props<Option extends OptionTypeBase> = ControlProps<Option, true>;

const ExternalMultiValuesControl = <Option extends OptionTypeBase>(props: Props<Option>) => {
    const { ref, overflowingChildren } = useOverflowingChildren<HTMLDivElement>();
    const overflowingChildrenIds = overflowingChildren.map((item) => item.id);

    const [isExpanded, setIsExpanded] = useState(false);
    const values = props.getValue();

    return (
        <div>
            <components.Control {...props} />

            <div
                className={cn(styles.container, {
                    [styles.expanded]: isExpanded,
                    [styles.noValues]: !values?.length,
                })}
            >
                <div ref={ref} className={cn(styles.values, { [styles.expanded]: isExpanded })}>
                    {values?.map((val, index, array) => {
                        return (
                            <div
                                key={val.value}
                                id={val.value}
                                className={cn(styles.value, {
                                    [styles.hidden]:
                                        (!isExpanded && overflowingChildrenIds.includes(String(val.value))) ||
                                        // Remove last visible value badge to make space for "show more" button
                                        (!isExpanded && index === array.length - overflowingChildrenIds.length),
                                })}
                            >
                                {val.label}

                                <button
                                    type="button"
                                    className={styles.removeValueButton}
                                    onClick={() => {
                                        props.setValue(
                                            values?.filter((v) => v.value !== val.value),
                                            'remove-value'
                                        );
                                    }}
                                >
                                    <CloseIcon className={styles.removeValueIcon} />
                                </button>
                            </div>
                        );
                    })}
                </div>

                <div className={styles.actions}>
                    {(!overflowingChildren.length || isExpanded) && (
                        <button
                            type="button"
                            className={styles.actionButton}
                            onClick={() => {
                                props.clearValue();
                                setIsExpanded(false);
                            }}
                        >
                            Remove all
                        </button>
                    )}

                    {(!!overflowingChildren.length || isExpanded) && (
                        <button
                            type="button"
                            className={cn(styles.actionButton, { [styles.showMoreButton]: !isExpanded })}
                            onClick={() => setIsExpanded((exp) => !exp)}
                        >
                            {isExpanded ? 'Show less' : `Show +${overflowingChildren.length}`}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ExternalMultiValuesControl;
