import { getInstagramUserImages, InstagramUserImage } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<{ images: InstagramUserImage[] }>;

const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export default function useInstagramUserImages() {
    const [state, setState] = useState(initialState);

    const fetchData = useCallback(async (...params: Parameters<typeof getInstagramUserImages>) => {
        setState((prev) => ({ ...prev, status: 'loading', error: null }));

        try {
            const response = await getInstagramUserImages(...params);
            setState((prev) => ({
                status: 'success',
                error: null,
                data: {
                    images: prev.data?.images.concat(response.data) ?? response.data,
                },
            }));

            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            setState((prev) => ({ status: 'error', error: 'Could not fetch instagram user images', data: prev.data }));
            throw e;
        }
    }, []);

    const reset = useCallback(() => setState(initialState), []);

    return {
        ...state,
        fetchData,
        reset,
    };
}
