import { MediaPlanItemGroup, MediaPlanItemGroupApiModel } from './mediaPlanItemGroup.types';
import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';
import { ApiResponse, ApiResponseError } from '../../types';

type GetMediaPlanItemGroupsParams = Partial<{
    media_plan_id: number;
}>;

export async function getAllMediaPlanItemGroups(params: GetMediaPlanItemGroupsParams, requestInit?: RequestInit) {
    return fetchAll<MediaPlanItemGroup>(
        `/api/advertising/viewsets/media-plan-item-group/${encodeUrlSearchParams(params)}`,
        requestInit
    );
}

export async function postMediaPlanItemGroup(
    data: MediaPlanItemGroupApiModel
): Promise<ApiResponse<MediaPlanItemGroup, 201>> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item-group/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error('Could not POST media plan item group');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type DeleteMediaPlanItemGroupResponse = ApiResponse<null, 204> | ApiResponse<{ detail: string }, 404>;
export async function deleteMediaPlanItemGroup(groupId: number): Promise<DeleteMediaPlanItemGroupResponse> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item-group/${groupId}/`, {
        method: 'DELETE',
    });

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not delete media plan item group ${groupId}`);
    }

    return {
        status: 204,
        data: null,
    };
}

type PatchMediaPlanItemGroupResponse =
    | ApiResponse<MediaPlanItemGroup, 200>
    | ApiResponse<{ detail: string }, 404>
    | ApiResponse<ApiResponseError<MediaPlanItemGroupApiModel>, 400>;

export async function patchMediaPlanItemGroup(
    groupId: number,
    data: Partial<MediaPlanItemGroupApiModel>
): Promise<PatchMediaPlanItemGroupResponse> {
    const response = await fetchWithToken(`/api/advertising/viewsets/media-plan-item-group/${groupId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not PATCH media plan item group ${groupId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
