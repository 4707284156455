import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { TiktokAudioStats, TiktokAudioViewStats } from './audioStats.types';
import { MonitoredAudioTrackingTypes } from '../../utils';

export const models = {
    tiktokAudioStats: Model as ModelDefinition<TiktokAudioStats>,
    tiktokAudioViewStats: Model as ModelDefinition<TiktokAudioViewStats>,
};

export const serializers = {
    tiktokAudioStats: makeSerializer<TiktokAudioStats>([]),
    tiktokAudioViewStats: makeSerializer<TiktokAudioViewStats>([]),
};

export const factories = {
    tiktokAudioStats: createFactory<TiktokAudioStats>({
        alternate_name(index: number) {
            return `alternate name ${index}`;
        },
        author_name(index: number) {
            return `author name ${index}`;
        },
        catalogue_ids: [],
        is_original() {
            return Math.random() > 0.5;
        },
        monitored_music_id(index: number) {
            return index;
        },
        music_id(index: number) {
            return index;
        },
        standardised_data: {
            daily_video_counts: [],
        },
        tiktok_id(index: number) {
            return `tiktok id ${index}`;
        },
        title(index: number) {
            return `title ${index}`;
        },
        video_count(index: number) {
            return index * 100;
        },
        video_count_daily_change() {
            return Math.random() * 100;
        },
        video_count_daily_change_relative() {
            return Math.random() * 10;
        },
        video_count_weekly_change() {
            return Math.random() * 100;
        },
        video_count_weekly_change_relative() {
            return Math.random() * 10;
        },
        tracking_type: MonitoredAudioTrackingTypes.Basic,
    }),
    tiktokAudioViewStats: createFactory<TiktokAudioViewStats>({
        locations: {
            countries: [],
        },
        location_post_count(index: number) {
            return index * 100;
        },
        total_views(index: number) {
            return index * 100;
        },
        total_views_post_count(index: number) {
            return index * 100;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const getTiktokAudioStatsUrl = '/api/tiktok/audio-stats-cache/';
    server.get(getTiktokAudioStatsUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('tiktokAudioStats'), {
            url: getTiktokAudioStatsUrl,
            serialize: (resource) => this.serialize(resource, 'tiktokAudioStats'),
            queryParams: request.queryParams,
        });
    });

    server.get('/api/tiktok/viewsets/audio/:id/stats/', (schema, request) => {
        return schema.create('tiktokAudioViewStats');
    });
}
