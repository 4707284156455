import {
    getInvoiceRequests,
    GetInvoiceRequestsParams,
    getRelease,
    getXeroInvoice,
    InvoiceRequest,
    patchInvoiceRequest,
    PatchInvoiceRequestData,
    Release,
    XeroInvoiceSimple,
} from '@round/api';
import { useCallback, useState } from 'react';
import { InvoiceRequestsTableRow } from 'Modules/Finance/InvoiceRequests/InvoiceRequestsTable/InvoiceRequestsTable';
import uniq from 'lodash/uniq';
import { isNumber } from 'utility/utility';
import { getAllItems } from 'utility/api';

type Params = GetInvoiceRequestsParams;
export type UseInvoiceRequestsResult = {
    rows: InvoiceRequestsTableRow[];
    count: number;
    error: string | null;
    isLoading: boolean;
    isInitialized: boolean;
    init: (requestInit?: RequestInit) => Promise<void>;
    reset: () => void;
    updateInvoiceRequest: typeof patchInvoiceRequest;
    updateInvoice: (id: number, invoice: XeroInvoiceSimple | null) => ReturnType<typeof patchInvoiceRequest>;
};

export default function useInvoiceRequests({
    page,
    page_size,
    client_id,
    status,
    release_id,
    planner_id,
    plan_start_date_start,
    plan_start_date_end,
    with_notes,
    ordering,
}: Params): UseInvoiceRequestsResult {
    const [invoiceRequests, setInvoiceRequests] = useState<InvoiceRequest[]>([]);
    const [releases, setReleases] = useState<Release[]>([]);
    const [invoices, setInvoices] = useState<XeroInvoiceSimple[]>([]);
    const [invoiceRequestsCount, setInvoiceRequestsCount] = useState(0);
    const [isInitialized, setIsInitialized] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                const response = await getInvoiceRequests(
                    {
                        page,
                        page_size,
                        client_id,
                        status,
                        release_id,
                        planner_id,
                        plan_start_date_start,
                        plan_start_date_end,
                        with_notes,
                        ordering,
                    },
                    requestInit
                );
                if (response.status === 404) {
                    setError(response.data.detail);
                    setIsInitialized(true);
                    return;
                }

                setInvoiceRequests(response.data.results);
                setInvoiceRequestsCount(response.data.count);

                const releaseIds = uniq(response.data.results.map((request) => request.release_id));
                const releaseIdsToFetch = releaseIds.filter((id) => !releases.find((release) => release.id === id));
                const invoiceIds = uniq(response.data.results.map((request) => request.invoice_id).filter(isNumber));
                const invoiceIdsToFetch = invoiceIds.filter((id) => !invoices.find((invoice) => invoice.id === id));

                const [releasesResponse, invoicesResponse] = await Promise.all([
                    getAllItems(getRelease, releaseIdsToFetch),
                    getAllItems(getXeroInvoice, invoiceIdsToFetch),
                ]);

                setReleases((prev) => prev.concat(releasesResponse));
                setInvoices((prev) => prev.concat(invoicesResponse));
                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setError('Could not get invoice requests');
                setIsInitialized(true);
            }
        },
        [
            client_id,
            invoices,
            ordering,
            page,
            page_size,
            plan_start_date_end,
            plan_start_date_start,
            planner_id,
            release_id,
            releases,
            status,
            with_notes,
        ]
    );

    const reset = useCallback(() => {
        setIsInitialized(false);
        setInvoiceRequests([]);
        setError(null);
    }, []);

    const updateInvoiceRequest = useCallback(async (id: number, data: PatchInvoiceRequestData) => {
        const response = await patchInvoiceRequest(id, data);
        if (response.status === 200) {
            setInvoiceRequests((prev) =>
                prev.map((request) => (request.id === response.data.id ? response.data : request))
            );
            return response;
        }

        return response;
    }, []);

    const updateInvoice = useCallback(
        async (id: number, invoice: XeroInvoiceSimple | null) => {
            const response = await updateInvoiceRequest(id, { invoice_id: invoice?.id ?? null });
            if (invoice) {
                setInvoices((prev) => {
                    const invoiceFound = !!prev.find((i) => i.id === invoice.id);
                    if (!invoiceFound) {
                        return prev.concat(invoice);
                    }

                    return prev;
                });
            }

            return response;
        },
        [updateInvoiceRequest]
    );

    return {
        rows: invoiceRequests.map((request) => ({
            ...request,
            release: releases.find((release) => release.id === request.release_id),
            invoice: invoices.find((invoice) => invoice.id === request.invoice_id),
        })),
        count: invoiceRequestsCount,
        error,
        isInitialized,
        isLoading: !error && !isInitialized,
        init,
        reset,
        updateInvoiceRequest,
        updateInvoice,
    };
}
