import { TiktokAudioViewStats, getTiktokAudioViewStats } from '@round/api';
import { useCallback, useState } from 'react';

export function useAudioViewStats(audioId: number | undefined) {
    const [viewStats, setViewStats] = useState<TiktokAudioViewStats | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);

    const fetchData = useCallback(
        async (requestInit?: RequestInit) => {
            if (!audioId) {
                return;
            }

            setIsLoading(true);
            try {
                const response = await getTiktokAudioViewStats(audioId, requestInit);
                setViewStats(response.data);
                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setHasError(true);
                throw e;
            } finally {
                setIsLoading(false);
            }
        },
        [audioId]
    );

    return {
        viewStats,
        isLoading: isLoading || (!isInitialized && !hasError),
        hasError,
        isInitialized,
        refresh: fetchData,
        init: fetchData,
        reset: () => {
            setViewStats(null);
            setIsInitialized(false);
            setIsLoading(false);
            setHasError(false);
        },
    };
}
