import { AppServer, RouteHandlerContext } from '../../mirage';
import { belongsTo, Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Brand } from './brand.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    brand: Model.extend({
        client: belongsTo('clientSimple'),
    }) as ModelDefinition<Brand>,
};

export const factories = {
    brand: createFactory<Brand>({
        name(index: number) {
            return `Brand name: ${index}`;
        },
        picture(index: number) {
            return `Brand picture: ${index}`;
        },
        artist() {
            return null;
        },
        afterCreate(brand, s) {
            const server = s as AppServer;
            if (!brand.client) {
                brand.update({
                    client: server.create('clientSimple'),
                });
            }
        },
    }),
};

export const serializers = {
    brand: makeSerializer<Brand>(['client']),
};

export function handleBrandRequests(server: AppServer) {
    const getAllBrandsPath = '/api/advertising/viewsets/brand/';
    server.get(getAllBrandsPath, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const brands = schema
            .all('brand')
            .filter((brand) => !search || brand.name.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(brands, {
            url: getAllBrandsPath,
            serialize: (resource) => this.serialize(resource, 'brand'),
            queryParams: request.queryParams,
        });
    });

    server.get('/api/advertising/viewsets/brand/:id/', function (this: RouteHandlerContext, schema, request) {
        const brand = schema.find('brand', request.params.id);
        if (!brand) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return brand;
    });
}
