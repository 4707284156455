import { PhylloInterest, getPhylloInterests } from '@round/api';
import { useCallback, useState } from 'react';
import { showNotification } from '../helpers';
import useAbortableEffect from './useAbortableEffect';

const usePhylloInterests = () => {
    const [interests, setInterests] = useState<PhylloInterest[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);

    const fetchInterests = useCallback(async (requestInit?: RequestInit) => {
        setIsLoading(true);
        try {
            const response = await getPhylloInterests(requestInit);
            setInterests(response.data.results);
            setIsInitialized(true);
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            const errorMessage = e instanceof Error ? e.message : 'Could not fetch phyllo interests';
            showNotification(errorMessage, 'error');
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                fetchInterests({ signal });
            }
        },
        [fetchInterests, isInitialized]
    );

    return {
        interests,
        isLoading: isLoading || (!isInitialized && !hasError),
    };
};

export default usePhylloInterests;
