import moment from 'moment';
import useTiktokCreations from './useTiktokCreations';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import styles from './TiktokCreations.module.css';
import LineChart from 'ui-new/whitelabel/Charts/LineChart/LineChart';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import { numberWithCommas } from 'helpers';

const today = moment();
const twoWeeksAgo = moment().subtract(2, 'weeks');

const TiktokCreations = () => {
    const { data, status, fetchData } = useTiktokCreations();
    const isInitialized = status === 'success' || status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                fetchData(
                    { start_date: twoWeeksAgo.format('YYYY-MM-DD'), end_date: today.format('YYYY-MM-DD') },
                    signal
                ).catch(() => {});
            }
        },
        [fetchData, isInitialized]
    );

    return (
        <div className={styles.container}>
            <div className={styles.chartTile}>
                <p className={styles.title}>Total creations</p>
                <div className={styles.chartContainer}>
                    <LineChart
                        isLoading={!isInitialized}
                        lineColor="#2A94FF"
                        points={data?.tiktok_daily_totals.map((item) => ({ x: item.timestamp, y: item.value })) ?? []}
                        renderTooltip={(tooltipItem) => (
                            <>
                                <Tooltip.Title>{moment(tooltipItem.x).format('D MMM YYYY')}</Tooltip.Title>
                                <Tooltip.Body>
                                    <dl className={styles.chartTooltipStats}>
                                        <div className={styles.chartTooltipStatsItem}>
                                            <dt>Total creations</dt>
                                            <dd>{numberWithCommas(tooltipItem.y)}</dd>
                                        </div>
                                    </dl>
                                </Tooltip.Body>
                            </>
                        )}
                    />
                </div>
            </div>

            <div className={styles.chartTile}>
                <p className={styles.title}>Daily creations</p>
                <div className={styles.chartContainer}>
                    <LineChart
                        isLoading={!isInitialized}
                        lineColor="#2A94FF"
                        points={data?.tiktok_daily_change.map((item) => ({ x: item.timestamp, y: item.value })) ?? []}
                        renderTooltip={(tooltipItem) => (
                            <>
                                <Tooltip.Title>{moment(tooltipItem.x).format('D MMM YYYY')}</Tooltip.Title>
                                <Tooltip.Body>
                                    <dl className={styles.chartTooltipStats}>
                                        <div className={styles.chartTooltipStatsItem}>
                                            <dt>Daily creations</dt>
                                            <dd>{numberWithCommas(tooltipItem.y)}</dd>
                                        </div>
                                    </dl>
                                </Tooltip.Body>
                            </>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default TiktokCreations;
