import React, { FunctionComponent } from 'react';
import styles from './SearchBox.module.css';
import { DebounceInput } from 'react-debounce-input';
import cn from 'classnames';

export const SearchBox: FunctionComponent<{
    setValue: (value: string) => void;
    initialValue?: string;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
}> = ({ setValue, initialValue, disabled, className, placeholder = 'Search' }) => {
    return (
        <DebounceInput
            disabled={disabled}
            debounceTimeout={750}
            className={cn(styles.searchBox, className)}
            value={initialValue}
            placeholder={placeholder}
            type="text"
            onChange={(event) => setValue(event.target.value)}
        />
    );
};
