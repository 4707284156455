import { creatorbase } from '@round/api';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { CellContext } from '@tanstack/react-table';
import { CampaignsTableMeta } from 'Modules/Plans/Campaigns/types';

type Row = creatorbase.PublicCampaign;

type Context<T> = CellContext<T, number | string | undefined>;
const getTableMeta = getTableMetaHelper<CampaignsTableMeta>();

const CampaignStatsCell = <T extends Row>({ getValue, table, row: { original } }: Context<T>) => {
    const { isLoading, getIsCampaignStatsLoading } = getTableMeta(table);
    if (isLoading || getIsCampaignStatsLoading?.(original)) {
        return <Skeleton />;
    }

    return getValue();
};

export default CampaignStatsCell;
