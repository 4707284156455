import { fetchWithToken } from '../../helpers';
import { ApiResponse } from '../../types';
import { PhylloInterest } from './interests.types';

type GetPhylloInterestsResponse = {
    results: PhylloInterest[];
};

export async function getPhylloInterests(
    requestInit?: RequestInit
): Promise<ApiResponse<GetPhylloInterestsResponse, 200>> {
    const response = await fetchWithToken('/api/creator-data/interests/', requestInit);

    if (!response.ok) {
        throw new Error(`Failed to fetch interests`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
