import { AppServer } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { InstagramTopVideo } from './instagramTopVideo.types';

export const models = {
    instagramTopVideo: Model as ModelDefinition<InstagramTopVideo>,
};

export const factories = {
    instagramTopVideo: createFactory<InstagramTopVideo>({
        author_unique_id(index: number) {
            return `author unique id ${index}`;
        },
        post_url(index: number) {
            return `post url ${index}`;
        },
        description(index: number) {
            return `description ${index}`;
        },
        create_time() {
            return new Date().toISOString();
        },
        view_count(index: number) {
            return index * (Math.random() * 1000);
        },
        play_count(index: number) {
            return index * (Math.random() * 1000);
        },
        like_count(index: number) {
            return index * (Math.random() * 1000);
        },
        comment_count(index: number) {
            return index * (Math.random() * 1000);
        },
    }),
};

export const serializers = {
    instagramTopVideo: makeSerializer<InstagramTopVideo>([]),
};

export function handleInstagramTopVideoRequests(server: AppServer) {
    server.get('/api/instagram/top-user-videos/:userId/', function (this: RouteHandlerContext, schema, request) {
        const { userId } = request.params;
        if (!userId) {
            return new Response(404);
        }

        return {
            results: this.serialize(schema.all('instagramTopVideo'), 'instagramTopVideo'),
        };
    });
}
