import { makeProjectsListDataHook } from 'Modules/Plans/Project/hooks/dataHooks/useProjectsListDataHook';
import { ProjectSelectContext } from './ProjectSelectContext';
import { ContextType, useCallback } from 'react';
import { creatorbase } from '@round/api';
import { makeTeamsDataHook } from '../../../Team/hooks/dataHooks/useTeamsDataHook';
import { uniq } from 'lodash';
import { makeBrandsDataHook } from '../../../Brand/hooks/dataHooks/useBrandsDataHook';
import { makeUsersDataHook } from '../../../User/hooks/dataHooks/useUsersDataHook';
import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';

type ContextValue = ContextType<typeof ProjectSelectContext>;

const useProjectsList = makeProjectsListDataHook<ContextValue, creatorbase.Project>(
    ProjectSelectContext,
    ([state]) => state.projectsList
);

const useTeams = makeTeamsDataHook<ContextValue, creatorbase.Team>(ProjectSelectContext, ([state]) => state.teams);
const useBrands = makeBrandsDataHook<ContextValue, creatorbase.Brand>(ProjectSelectContext, ([state]) => state.brands);
const useAssignees = makeUsersDataHook(ProjectSelectContext, ([state]) => state.assignees);

export function useProjectSelect() {
    const { state: projectsState, fetchData: fetchProjects } = useProjectsList();
    const { data: teamsData, fetchData: fetchTeamsData } = useTeams();
    const { data: brandsData, fetchData: fetchBrandsData } = useBrands();
    const { data: assigneesData, fetchData: fetchAssigneesData } = useAssignees();
    const dispatch = useNonNullContextSelector(ProjectSelectContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (params: creatorbase.GetProjectsParams, requestInit?: RequestInit) => {
            const response = await fetchProjects(params, requestInit);
            if (response.status !== 200) {
                return response;
            }

            const projects = response.data.results;
            const teamIds = uniq(projects.map((p) => p.team_id)).filter((id) => !teamsData[id]?.data);
            const brandIds = uniq(projects.map((p) => p.brand_id)).filter((id) => !brandsData[id]?.data);
            const assigneeIds = uniq(projects.map((p) => p.users_assigned_to_campaigns).flat()).filter(
                (id) => !assigneesData[id]?.data
            );

            Promise.allSettled([fetchTeamsData(teamIds), fetchBrandsData(brandIds), fetchAssigneesData(assigneeIds)]);

            return response;
        },
        [fetchAssigneesData, fetchBrandsData, fetchProjects, fetchTeamsData, assigneesData, brandsData, teamsData]
    );

    const resetList = useCallback(() => {
        dispatch({ type: 'resetProjectsList' });
    }, [dispatch]);

    return { projectsState, teamsData, brandsData, assigneesData, fetchData, resetList };
}
