import { getInstagramUser } from '@round/api';
import { GenericDropdownOption } from 'App.types';
import { showNotification } from 'helpers';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { ValueType } from 'react-select';
import { useInstagramUsers } from '../useInstagramUsers';

const PAGE_SIZE = 25;

type Params = {
    initOn?: 'menuOpen' | 'mount';
    initialId?: number;
};

export function useInstagramUsersSelect({ initOn = 'menuOpen', initialId }: Params = {}) {
    const [isOptionsInitialized, setIsOptionsInitialized] = useState(false);
    const [isInitialValueInitialized, setIsInitialValueInitialized] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const { state, fetchData: fetchUsers, reset: resetUsers } = useInstagramUsers();

    const handleFetchData = useCallback(
        async (...params: Parameters<typeof fetchUsers>) => {
            try {
                const response = await fetchUsers(...params);
                if (response?.status === 404) {
                    showNotification(response.data.detail, 'error');
                    return false;
                }

                if (response?.status === 200) {
                    return true;
                }
            } catch (e) {
                const errorMessage = e instanceof Error ? e.message : 'Could not fetch data';
                showNotification(errorMessage, 'error');
            }
        },
        [fetchUsers]
    );

    const debouncedHandleFetchData = useMemo(() => debounce(handleFetchData, 700), [handleFetchData]);

    useAbortableEffect(
        (signal) => {
            const initOptions = () =>
                handleFetchData({ page: 1, page_size: PAGE_SIZE, search }, { signal }).then((success) => {
                    if (success) {
                        setIsOptionsInitialized(true);
                    }
                });

            if (!isOptionsInitialized && (initOn !== 'menuOpen' || isMenuOpen)) {
                initOptions();
            }
        },
        [handleFetchData, search, isOptionsInitialized, initOn, isMenuOpen]
    );

    const loadNextPage = () => {
        handleFetchData({ page: page + 1, page_size: PAGE_SIZE, search }).then((success) => {
            if (success) {
                setPage((page) => page + 1);
            }
        });
    };

    const options = useMemo(() => state.data?.users.map((user) => ({ value: user.id, label: user.username })), [
        state.data,
    ]);

    const [value, setValue] = useState<ValueType<GenericDropdownOption<number>, false>>(null);

    useAbortableEffect(
        (signal) => {
            const getInitialValue = (id: number) => {
                getInstagramUser(id, { signal })
                    .then((res) => {
                        if (res.status === 200) {
                            setValue({ value: res.data.id, label: res.data.username });
                            setIsInitialValueInitialized(true);
                            return;
                        }

                        showNotification("Couldn't fetch instagram user option", 'error');
                    })
                    .catch(() => {
                        showNotification("Couldn't fetch instagram user option", 'error');
                    });
            };
            if (initialId && !isInitialValueInitialized) {
                getInitialValue(initialId);
            }
        },
        [initialId, isInitialValueInitialized]
    );

    return {
        props: {
            value,
            onChange: (value: ValueType<GenericDropdownOption<number>, false>) => {
                setValue(value);
            },
            options,
            inputValue: search,
            onInputChange: (value: string) => {
                if (value === search) {
                    return;
                }

                resetUsers();
                debouncedHandleFetchData({ page: 1, page_size: PAGE_SIZE, search: value });
                setPage(1);
                setSearch(value);
            },
            onMenuScrollToBottom: () => {
                if (!isOptionsInitialized || state.status === 'loading' || state.data?.hasNextPage === false) {
                    return;
                }
                loadNextPage();
            },
            isLoading: state.status === 'loading' || (!!search.length && state.status === 'idle'),
            isMenuOpen,
            onMenuOpen: () => setIsMenuOpen(true),
            onMenuClose: () => setIsMenuOpen(false),
            filterOption: null,
        },
        resetValue: () => {
            setValue(null);
            setIsInitialValueInitialized(false);
        },
    };
}
