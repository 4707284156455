import { microwave, UserPlanner } from '@round/api';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { asMoney } from 'helpers';
import useNonNullContext from 'Hooks/useNonNullContext';
import { getPlatformIcon } from 'Modules/Advertising/Microwave/helpers';
import WrapperTable from 'ui/WrapperTable/WrapperTable';
import styles from './ExistingCampaignsTable.module.css';
import moment from 'moment';
import { Skeleton } from '@round/ui-kit';

type TableRow = microwave.Campaign & {
    planners?: UserPlanner[];
};

type Context<Key extends keyof TableRow> = CellContext<TableRow, TableRow[Key]>;

type Props = {
    campaigns: TableRow[];
    isLoading: boolean;
};

const ExistingCampaignsTable = ({ campaigns, isLoading }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const columns: ColumnDef<TableRow, any>[] = [
        {
            header: 'Platform',
            accessorKey: 'platform',
            cell: ({ getValue }: Context<'platform'>) => {
                const Icon = getPlatformIcon(getValue());
                return <Icon className={styles.platformIcon} />;
            },
        },
        {
            header: 'Start Date',
            accessorKey: 'planned_start_date',
            cell: ({ getValue }: Context<'planned_start_date'>) => moment(getValue()).format('DD MMM YYYY'),
        },
        {
            header: 'End Date',
            accessorKey: 'planned_end_date',
            cell: ({ getValue }: Context<'planned_end_date'>) => moment(getValue()).format('DD MMM YYYY'),
        },
        {
            header: 'Post Target',
            accessorKey: 'target_post_count',
            cell: ({ getValue }: Context<'target_post_count'>) => getValue() ?? '-',
        },
        {
            header: 'Budget',
            accessorKey: 'budget',
            cell: ({ getValue, row: { original } }: Context<'budget'>) => {
                const currencyId = original.currency_id;
                const amount = getValue();

                const currency = currencies.find((c) => c.id === currencyId);
                return <span>{asMoney(amount, currency)}</span>;
            },
        },
        {
            header: 'Account Team',
            accessorKey: 'planners',
            cell: ({ getValue }: Context<'planners'>) => {
                const planners = getValue();
                if (isLoading) {
                    return <Skeleton />;
                }

                return (
                    <div className={styles.planners}>
                        {planners?.length ? planners.map((p) => `${p.first_name} ${p.last_name}`).join(', ') : '-'}
                    </div>
                );
            },
        },
    ];

    return <WrapperTable data={campaigns} columns={columns} className={styles.table} />;
};

export default ExistingCampaignsTable;
