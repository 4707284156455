import React, { forwardRef, FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import LoadingSpinner from '../../../SharedComponents/LoadingSpinner/LoadingSpinner';

import styles from './Button.module.css';
import ReactTooltip from 'react-tooltip';

export type ButtonProps = {
    loading?: boolean;
    disabled?: boolean;
    className?: string;
    onClick?: (e: React.MouseEvent) => void | Promise<void>;
    type?: 'bordered' | 'elevated' | 'link' | 'filled';
    color?: 'negative' | 'primary' | 'orange' | 'black';
    htmlType?: 'submit' | 'button' | 'reset';
    spinnerClassName?: string;
    Icon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
    hint?: string;
    tabIndex?: number;
};

/**
 * @deprecated Not used in new designs
 */
const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
    (
        {
            children,
            className,
            loading,
            onClick,
            type,
            color,
            disabled,
            htmlType,
            spinnerClassName,
            Icon,
            tabIndex,
            hint,
        },
        ref
    ) => {
        const buttonStyles = useMemo(
            () =>
                classNames(
                    styles.button,
                    {
                        [styles.disabled]: disabled,
                        [styles.typeBordered]: type === 'bordered',
                        [styles.typeElevated]: type === 'elevated',
                        [styles.typeLink]: type === 'link',
                        [styles.typeFilled]: type === 'filled',
                        [styles.colorNegative]: color === 'negative',
                        [styles.colorPrimary]: color === 'primary',
                        [styles.colorOrange]: color === 'orange',
                        [styles.colorBlack]: color === 'black',
                    },
                    className
                ),
            [className, color, disabled, type]
        );

        const iconStyles = useMemo(() => classNames(styles.icon, { [styles.colorNegative]: color === 'negative' }), [
            color,
        ]);

        const spinnerStyles = useMemo(
            () =>
                classNames(styles.spinnerContainer, spinnerClassName, {
                    [styles.hide]: !loading,
                    [styles.colorNegative]: color === 'negative',
                }),
            [color, loading, spinnerClassName]
        );
        const contentStyles = useMemo(() => classNames(styles.content, { [styles.hide]: loading }), [loading]);
        const handleClick = useCallback(
            (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if (!loading && !disabled && typeof onClick === 'function') {
                    onClick(e);
                }
            },
            [disabled, loading, onClick]
        );

        useEffect(() => {
            ReactTooltip.rebuild();
        }, [hint]);

        return (
            <button
                tabIndex={tabIndex}
                ref={ref}
                type={htmlType}
                className={buttonStyles}
                onClick={handleClick}
                disabled={disabled}
                data-tip={hint}
                data-for="global"
            >
                <LoadingSpinner containerClassName={spinnerStyles} />
                {Icon && <Icon className={iconStyles} />}
                <div className={contentStyles}>{children}</div>
            </button>
        );
    }
);

export default Button;
