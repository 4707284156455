import { Link, useParams } from 'react-router-dom';
import ReportData from './ReportData/ReportData';
import { TabPanel, Tabs } from '@round/ui-kit';
import usePlatformHashSelect, { getDefaultPlatform } from 'Modules/Plans/hooks/usePlatformHashSelect';
import PlatformTabs from 'Modules/Plans/components/PlatformTabs/PlatformTabs';
import styles from './Report.module.css';
import ReportTiktokCampaigns from './Campaigns/TiktokCampaigns/ReportTiktokCampaigns';
import ReportInstagramCampaigns from './Campaigns/InstagramCampaigns/ReportInstagramCampaigns';
import ReportYoutubeCampaigns from './Campaigns/YoutubeCampaigns/ReportYoutubeCampaigns';
import PlatformHeadlineStats from './PlatformHeadlineStats/PlatformHeadlineStats';
import useReportData from './ReportData/useReportData';
import { ReactComponent as ChevronDownFilledIcon } from 'assets/whitelabel/ChevronDownFilled.svg';
import { creatorbase } from '@round/api';
import { ProjectSelectProvider } from './ProjectSelect/ProjectSelectContext';
import ProjectSelectModal from './ProjectSelect/ProjectSelectModal';
import useUrlState from 'Hooks/useUrlState';

const platforms: creatorbase.Platform[] = ['instagram', 'tiktok', 'youtube'];

const platformCampaignsComponentMap = {
    instagram: ReportInstagramCampaigns,
    tiktok: ReportTiktokCampaigns,
    youtube: ReportYoutubeCampaigns,
};

type UrlState = {
    isEdit: string | undefined;
};

const Report = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>();

    const { id } = useParams<{ id: string }>();
    const { data: report, status: reportStatus } = useReportData();

    const [activeTab, setActiveTab] = usePlatformHashSelect((goToPlatform) => {
        if (reportStatus !== 'success') {
            return;
        }

        goToPlatform(getDefaultPlatform(report.platforms), true);
    });

    return (
        <Tabs activeTab={activeTab} onChange={setActiveTab}>
            <header className={styles.header}>
                <div className={styles.goBackContainer}>
                    <Link className={styles.goBackButton} to="/c/reports/">
                        <ChevronDownFilledIcon className={styles.goBackButtonIcon} />
                        Reports
                    </Link>
                </div>

                <ReportData
                    className={styles.reportData}
                    reportId={id}
                    onClickEditReport={() => setUrlState({ isEdit: 'true' })}
                />

                <PlatformTabs className={styles.tabsNav}>
                    {platforms.map((platform) => (
                        <PlatformTabs.Tab key={platform} name={platform} platform={platform} />
                    ))}
                </PlatformTabs>
            </header>

            <main>
                {platforms.map((platform) => {
                    const ReportPlatformCampaigns = platformCampaignsComponentMap[platform];

                    return (
                        <TabPanel key={platform} name={platform}>
                            <PlatformHeadlineStats platform={platform} />

                            <section className={styles.campaigns}>
                                <ReportPlatformCampaigns reportId={id} />
                            </section>
                        </TabPanel>
                    );
                })}
            </main>

            <ProjectSelectProvider>
                <ProjectSelectModal
                    isOpen={urlState.isEdit === 'true'}
                    onClose={() => setUrlState({ isEdit: undefined })}
                    onSuccess={(updated) => {
                        if (!report?.platforms.length && updated.platforms.length) {
                            // this code exists, because closing a modal and updating platform
                            // conflicts (overrides each other) at URL level
                            setUrlState({ isEdit: undefined, hash: getDefaultPlatform(updated.platforms) });
                            return;
                        }

                        setUrlState({ isEdit: undefined });
                    }}
                />
            </ProjectSelectProvider>
        </Tabs>
    );
};

export default Report;
