import { microwave, contact } from '@round/api';

export const emailTypeLabels: Record<contact.NotificationType, string> = {
    SONG_PROMO_CHASE: 'Chase email',
    SONG_PROMO_INITIAL_UNREGISTERED: 'Initial email (unregistered)',
    SONG_PROMO_INITIAL_REGISTERED: 'Initial email (registered)',
};

export const statusLabels: Record<contact.NotificationStatus, string> = {
    PENDING: 'Email Requested',
    CANCELLED: 'Email Cancelled',
    FAILURE: 'Email Failed',
    SUCCESS: 'Email Sent',
};

type Event = {
    status: string;
    timestamp: string | null;
    countryCode?: string;
    city?: string;
    link?: string;
};

type DeliveryStage = {
    getEvent: (email: microwave.TiktokPostInviteEmail) => Event;
    hasOcurred: (email: microwave.TiktokPostInviteEmail) => boolean;
};

const deliveryStages: DeliveryStage[] = [
    {
        hasOcurred: () => true,
        getEvent: (email: microwave.TiktokPostInviteEmail) => ({
            status: statusLabels.PENDING,
            timestamp: email.requested_at,
        }),
    },
    {
        hasOcurred: (email: microwave.TiktokPostInviteEmail) => email.status === 'SUCCESS',
        getEvent: (email: microwave.TiktokPostInviteEmail) => ({
            status: statusLabels[email.status],
            timestamp: email.sent_at,
        }),
    },
    {
        hasOcurred: (email: microwave.TiktokPostInviteEmail) =>
            email.status === 'CANCELLED' || email.status === 'FAILURE',
        getEvent: (email: microwave.TiktokPostInviteEmail) => ({
            status: statusLabels[email.status],
            timestamp: null,
        }),
    },
];

const getDeliveryEvents = (email: microwave.TiktokPostInviteEmail): Event[] => {
    return deliveryStages.reduce((events, deliveryStage) => {
        if (deliveryStage.hasOcurred(email)) {
            return [...events, deliveryStage.getEvent(email)];
        }

        return events;
    }, [] as Event[]);
};

const getOpenedEvents = (openRecords: microwave.EmailOpenRecord[]): Event[] => {
    return openRecords.map((openRecord) => ({
        status: 'Email Opened',
        timestamp: openRecord.received_at,
        countryCode: openRecord.country,
        city: openRecord.city,
    }));
};

const getClickEvents = (clickRecords: microwave.EmailClickRecord[]): Event[] => {
    return clickRecords.map((clickRecord) => ({
        status: 'Link Clicked',
        timestamp: clickRecord.received_at,
        countryCode: clickRecord.country,
        city: clickRecord.city,
        link: clickRecord.link,
    }));
};

export const getEmailEvents = (email: microwave.TiktokPostInviteEmail): Event[] => {
    const deliveryEvents = getDeliveryEvents(email);
    const openedEvents = getOpenedEvents(email.open_records);
    const clickEvents = getClickEvents(email.click_records);

    return [...deliveryEvents, ...openedEvents, ...clickEvents];
};
