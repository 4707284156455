import { useEffect, useState } from 'react';
import Image from '../../../Image/Image';
import styles from './FileUploadImagePreview.module.css';
import cn from 'classnames';

type FileUploadImagePreviewProps = {
    file: File | undefined | null;
    className?: string;
};

const FileUploadImagePreview = ({ file, className }: FileUploadImagePreviewProps) => {
    const [src, setSrc] = useState('');
    useEffect(() => {
        if (file) {
            setSrc(URL.createObjectURL(file));
        }
    }, [file]);

    useEffect(() => {
        if (src) {
            return () => URL.revokeObjectURL(src);
        }
    }, [src]);

    if (!src) {
        return null;
    }

    return <Image src={src} className={cn(styles.image, className)} />;
};

export default FileUploadImagePreview;
