import { ClientSimple, RoundClientUser, patchRoundClientUser, sendRoundClientUserWelcomeEmail } from '@round/api';
import { Button, Modal, ModalContent, ModalFooter, ModalTitle } from '@round/ui-kit';
import styles from './RoundClientUserModal.module.css';
import RoundClientUserForm, { RoundClientUserFormValues } from './RoundClientUserForm/RoundClientUserForm';
import { Formik, FormikHelpers } from 'formik';
import { RoundClientUserTableRow } from '../RoundClientUserTable/RoundClientUserTable';
import { useCallback, useMemo, useState } from 'react';
import { mapApiErrorsToFormikErrors } from '../../../../../../utility/utility';
import { getObjectDifference, getReadableDateTimeString, showNotification } from '../../../../../../helpers';

type EditRoundClientUserModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    onUpdate: (user: RoundClientUser, clients: ClientSimple[]) => void;
    clientUserTableRow: RoundClientUserTableRow | null;
};

type EditRoundClientUserFormValues = Partial<
    Pick<RoundClientUserFormValues, 'first_name' | 'last_name' | 'client_ids'>
>;

const EditRoundClientUserModal = ({
    isOpen,
    closeModal,
    onUpdate,
    clientUserTableRow,
}: EditRoundClientUserModalProps) => {
    const [isWelcomeEmailLoading, setIsWelcomeEmailLoading] = useState(false);
    const [isWelcomeEmailSent, setIsWelcomeEmailSent] = useState(false);

    const initialValues: EditRoundClientUserFormValues = useMemo(
        () => ({
            first_name: clientUserTableRow?.first_name,
            last_name: clientUserTableRow?.last_name,
            client_ids: clientUserTableRow?.clients.map((client) => ({
                value: client.id,
                label: client.name,
            })),
        }),
        [clientUserTableRow]
    );

    const handleCloseModal = useCallback(() => {
        setIsWelcomeEmailSent(false);
        closeModal();
    }, [closeModal]);

    const handleSubmit = useCallback(
        async (values: EditRoundClientUserFormValues, helpers: FormikHelpers<EditRoundClientUserFormValues>) => {
            if (!clientUserTableRow?.id) {
                return;
            }

            helpers.setSubmitting(true);

            const mappedValues = {
                ...values,
                client_ids: values.client_ids?.map((client) => client.value) ?? [],
            };

            const changedFields = getObjectDifference(mappedValues, initialValues);

            try {
                const response = await patchRoundClientUser(clientUserTableRow.id, changedFields);
                if (response.status === 400) {
                    helpers.setErrors(mapApiErrorsToFormikErrors(response.data));
                    return;
                }

                if (response.status === 404) {
                    showNotification('Client User not found', 'error');
                    return;
                }

                showNotification('Client User updated successfully', 'info');
                onUpdate(
                    response.data,
                    values.client_ids?.map((client) => ({
                        id: client.value,
                        name: client.label,
                    })) ?? []
                );
                handleCloseModal();
            } catch {
                showNotification('Couldnt update user', 'error');
            } finally {
                helpers.setSubmitting(false);
            }
        },
        [clientUserTableRow?.id, initialValues, onUpdate, handleCloseModal]
    );

    const handleSendWelcomeEmail = useCallback(async (userId: number | undefined) => {
        if (!userId) {
            return;
        }

        try {
            setIsWelcomeEmailLoading(true);
            const response = await sendRoundClientUserWelcomeEmail(userId);
            if (response.status !== 204) {
                throw new Error(response.data.message);
            }
            showNotification('Welcome email sent successfully', 'info');
            setIsWelcomeEmailSent(true);
        } catch (e) {
            const errorMessage = e instanceof Error ? e.message : 'Could not send welcome email';
            showNotification(errorMessage, 'error');
        } finally {
            setIsWelcomeEmailLoading(false);
        }
    }, []);

    const lastSentEmailDate = clientUserTableRow?.last_sent_round_welcome_email
        ? getReadableDateTimeString(new Date(clientUserTableRow.last_sent_round_welcome_email))
        : 'never';

    return (
        <Modal isOpen={isOpen} onClose={handleCloseModal} className={styles.modal} closeOnOverlayClick>
            <ModalTitle>Edit Client User</ModalTitle>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                {(props) => (
                    <>
                        <ModalContent>
                            <RoundClientUserForm formType="edit" />
                        </ModalContent>
                        <ModalFooter className={styles.footer}>
                            <div className={styles.column}>
                                <Button
                                    color="black"
                                    type="filled"
                                    loading={isWelcomeEmailLoading}
                                    onClick={() => handleSendWelcomeEmail(clientUserTableRow?.id)}
                                    disabled={
                                        isWelcomeEmailLoading ||
                                        !clientUserTableRow?.id ||
                                        props.dirty ||
                                        props.isSubmitting ||
                                        isWelcomeEmailSent
                                    }
                                    htmlType="button"
                                >
                                    Send Welcome Email
                                </Button>
                                <span className={styles.message}>Last sent: {lastSentEmailDate}</span>
                            </div>

                            <Button
                                color="black"
                                type="filled"
                                loading={props.isSubmitting}
                                onClick={props.submitForm}
                                disabled={props.isSubmitting || !props.isValid || !props.dirty}
                                htmlType="submit"
                            >
                                Submit
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default EditRoundClientUserModal;
