import React from 'react';
import cn from 'classnames';
import styles from './FormField.module.css';
import { FCWithChildren } from '../../../utility/utility.types';

type FormFieldProps = {
    className?: string;
};

const FormField: FCWithChildren<FormFieldProps> = ({ children, className }) => (
    <div className={cn(styles.field, className)}>{children}</div>
);

export default FormField;
