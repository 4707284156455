import React, { useContext, useEffect } from 'react';
import styles from './MobileCampaignDetails.module.css';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import { InfluencerPlanContext } from '../../../InfluencerPlan/contexts/InfluencerPlanContext';
import { useRelease } from '../../hooks/useRelease';
import Skeleton from 'react-loading-skeleton';
import Image from '../../../../../ui/Image/Image';
import DataCard from '../../../../../ui/DataDisplay/DataCard/DataCard';
import { asMoney, formatDateLong } from '../../../../../helpers';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { InstagramCreatorsContext } from '../../../InfluencerPlan/contexts/InstagramCreatorsContext/InstagramCreatorsContext';
import MobileCreators from '../../../InfluencerPlan/features/MobileCreators/MobileCreators';
import { ReactComponent as AdvertisingIcon } from '../../assets/advertising.svg';
import { ReactComponent as CreatorsIcon } from '../../assets/creators.svg';
import { NavLink, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { AdvertisingContext } from '../../../MediaPlan/contexts/AdvertisingContext/AdvertisingContext';
import { MobileAdvertising } from '../../../MediaPlan/features/MobileAdvertising/MobileAdvertising';
import useTiktokInfluencerPostGroups from '../../../InfluencerPlan/contexts/TiktokCreatorsContext/useTiktokInfluencerPostGroups';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';

const MobileCampaignDetails = ({ releaseId }: { releaseId: number }) => {
    const featureFlags = useFeatureFlags();
    const location = useLocation();
    const activeTab = featureFlags.isEnabled('mobile_advertising') ? location.pathname.split('/').pop() : 'creators';

    const { release, releaseLoading, errorLoadingRelease } = useRelease(releaseId);
    const {
        init: initInfluencerPlan,
        initialized: isInfluencerPlanInitialized,
        loading: isInfluencerPlanLoading,
        influencerPlan,
    } = useContext(InfluencerPlanContext);

    const [
        { mediaPlan, mediaPlanItems, initialized: isMediaPlanInitialized },
        { init: initMediaPlan },
    ] = useNonNullContext(AdvertisingContext);

    const tabNavItems: { label: string; link: string; Icon: typeof AdvertisingIcon }[] = [
        { label: 'Advertising', link: 'advertising', Icon: AdvertisingIcon },
        { label: 'Creators', link: 'creators', Icon: CreatorsIcon },
    ];

    const {
        init: initTiktokInfluencerPostGroups,
        groupsInitialized: tiktokInfluencerPostGroupsInitialized,
        groups: tiktokInfluencerPostGroups,
    } = useTiktokInfluencerPostGroups();

    const {
        isPrefetchCompleted: isInstagramPrefetchCompleted,
        prefetch: prefetchInstagram,
        instagramInfluencerPostGroups,
    } = useNonNullContext(InstagramCreatorsContext);

    useAbortableEffect(
        (signal) => {
            if (!isInfluencerPlanInitialized && releaseId && activeTab === 'creators') {
                initInfluencerPlan({ signal });
            }
        },
        [isInfluencerPlanInitialized, initInfluencerPlan, releaseId, activeTab]
    );

    useAbortableEffect(
        (signal) => {
            if (!isMediaPlanInitialized && releaseId && activeTab === 'advertising') {
                initMediaPlan({ signal });
            }
        },
        [isMediaPlanInitialized, initMediaPlan, releaseId, activeTab]
    );

    useEffect(() => {
        if (!tiktokInfluencerPostGroupsInitialized) {
            initTiktokInfluencerPostGroups();
        }
    }, [initTiktokInfluencerPostGroups, tiktokInfluencerPostGroupsInitialized]);

    useEffect(() => {
        if (!isInstagramPrefetchCompleted) {
            prefetchInstagram();
        }
    }, [isInstagramPrefetchCompleted, prefetchInstagram]);

    const influencerPlanRunDates = influencerPlan
        ? `${formatDateLong(influencerPlan.start_date)} - ${formatDateLong(influencerPlan.end_date)}`
        : '-';

    const influencerPlanTotalBudget = influencerPlan
        ? asMoney(
              Number(influencerPlan.instagram_budget) + Number(influencerPlan.tiktok_budget),
              influencerPlan.currency
          )
        : '-';

    const influencerPlanPlannedCost = instagramInfluencerPostGroups
        .concat(tiktokInfluencerPostGroups)
        .reduce((budget, group) => budget + Number(group.budget), 0);

    const influencerPlanPlannedCostLabel = influencerPlan
        ? `${asMoney(influencerPlanPlannedCost, influencerPlan?.currency)} planned cost`
        : '';

    const mediaPlanRunDates = mediaPlan
        ? `${formatDateLong(mediaPlan.start_date)} - ${formatDateLong(mediaPlan.end_date)}`
        : '-';

    const mediaPlanTotalBudget = mediaPlan ? asMoney(Number(mediaPlan.budget), mediaPlan.currency) : '-';

    const mediaPlanPlannedCost = mediaPlanItems.reduce((acc, item) => acc + Number(item.cost), 0);
    const mediaPlanPlannedCostLabel = mediaPlan
        ? `${asMoney(mediaPlanPlannedCost, mediaPlan.currency)} planned cost`
        : undefined;

    const displayedRunDates = activeTab === 'advertising' ? mediaPlanRunDates : influencerPlanRunDates;
    const displayedTotalBudget = activeTab === 'advertising' ? mediaPlanTotalBudget : influencerPlanTotalBudget;
    const displayedPlannedCostLabel =
        activeTab === 'advertising' ? mediaPlanPlannedCostLabel : influencerPlanPlannedCostLabel;

    const isDataCardStatsLoading = activeTab === 'advertising' ? !isMediaPlanInitialized : isInfluencerPlanLoading;

    if (errorLoadingRelease) {
        return <p>Sorry, we are experiencing technical issues</p>;
    }

    if (!release && !releaseLoading) {
        return <p>Could not find release</p>;
    }

    return (
        <>
            <header className={styles.header}>
                <div className={styles.brandImageWrapper}>
                    {releaseLoading ? (
                        <Skeleton className={styles.brandImage} enableAnimation={false} />
                    ) : (
                        <Image className={styles.brandImage} src={release?.brand.picture} alt="Brand" />
                    )}
                </div>
                <div className={styles.releaseDetailsContainer}>
                    <div className={styles.detailsHeader}>
                        <div className={styles.titlesColumn}>
                            <span className={styles.title}>{releaseLoading ? <Skeleton /> : release?.name}</span>
                            <span className={styles.subTitle}>
                                {releaseLoading ? <Skeleton /> : release?.brand.name}
                            </span>
                        </div>

                        {featureFlags.isEnabled('mobile_advertising') && (
                            <div className={styles.links}>
                                {tabNavItems.map(({ link, label, Icon }) => (
                                    <NavLink
                                        key={link}
                                        to={link}
                                        className={({ isActive }) => cn(styles.tabLink, { [styles.active]: isActive })}
                                    >
                                        <Icon className={styles.tabLinkIcon} />
                                    </NavLink>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className={styles.dataCardContainer}>
                        <DataCard loading={isDataCardStatsLoading} title="Run Dates" value={displayedRunDates} />
                        <DataCard
                            loading={isDataCardStatsLoading}
                            title="Total Budget"
                            value={displayedTotalBudget}
                            additionalValue={displayedPlannedCostLabel}
                        />
                    </div>
                </div>
            </header>

            {featureFlags.isEnabled('mobile_advertising') ? (
                <Routes>
                    <Route path="advertising" element={<MobileAdvertising />} />
                    <Route path="creators" element={<MobileCreators />} />
                    <Route path="*" element={<Navigate to="advertising" />} />
                </Routes>
            ) : (
                <MobileCreators />
            )}
        </>
    );
};

export default MobileCampaignDetails;
