import React, { FunctionComponent, useCallback } from 'react';
import { useField, ErrorMessage, FieldHookConfig } from 'formik';
import TextAreaField from '../../../ui/TextArea/TextArea';
import formStyles from '../Forms.module.css';

type TextAreaProps = FieldHookConfig<string> & { placeholder?: string; className?: string; id?: string };

const TextArea: FunctionComponent<TextAreaProps> = (props) => {
    const fieldHook = useField(props);
    const field = fieldHook[0];
    const helpers = fieldHook[2];

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            helpers.setValue(e.target.value);
        },
        [helpers]
    );

    return (
        <>
            <TextAreaField
                autogrow
                id={props.id}
                name={field.name}
                value={field.value}
                onChange={handleChange}
                placeholder={props.placeholder}
                className={props.className}
            />
            <ErrorMessage name={field.name}>
                {(message) => <span className={formStyles.errorMessage}>{message}</span>}
            </ErrorMessage>
        </>
    );
};

export default TextArea;
