import React, { useCallback, useContext, useMemo, useState } from 'react';
import styles from './CreateInstagramInfluencerPostModal.module.css';
import { GenericDropdownOption } from '../../../../../../App.types';
import Modal, { ModalContent, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import InstagramInfluencerPostForm, {
    InstagramInfluencerPostFormValues,
    InstagramInfluencerPostResultsFields,
} from '../InfluencerPostForm/InstagramInfluencerPostForm';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { InstagramCreatorsContext } from '../../../contexts/InstagramCreatorsContext/InstagramCreatorsContext';
import { FormikHelpers } from 'formik';
import { InfluencerPlanContext } from '../../../contexts/InfluencerPlanContext';
import { OptionsContext } from '../../../../../../contexts/OptionsContext/OptionsContext';
import { pick } from 'lodash';
import * as Yup from 'yup';
import { postInstagramInfluencerPostResults } from '../../../api/Instagram.api';
import { mapApiErrorsToFormikErrors } from '../../../../../../utility/utility';
import { showNotification } from '../../../../../../helpers';

type CreateTiktokInfluencerPostModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onCreated: () => void;
    groupId: number | null;
};

const getResultsData = (
    values: InstagramInfluencerPostFormValues
): Pick<InstagramInfluencerPostFormValues, InstagramInfluencerPostResultsFields> =>
    pick(values, [
        'page_followers',
        'feed_views',
        'likes',
        'comments',
        'story_views',
    ] as InstagramInfluencerPostResultsFields[]);

const CreateInstagramInfluencerPostModal = ({
    isOpen,
    onClose,
    onCreated,
    groupId,
}: CreateTiktokInfluencerPostModalProps) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const { createInstagramInfluencerPost, setInstagramInfluencerPostResult } = useNonNullContext(
        InstagramCreatorsContext
    );
    const { initialized, influencerPlan } = useContext(InfluencerPlanContext);

    const [errorMessage, setErrorMessage] = useState<string>('');

    const currencyOptions: GenericDropdownOption<number>[] = useMemo(
        () =>
            currencies.map((c) => ({
                value: c.id,
                label: c.name,
            })),
        [currencies]
    );

    const initialValues: InstagramInfluencerPostFormValues = {
        influencer_id: null,
        currency_id: currencyOptions[0],
        cost: '',
        xero_invoice_id: null,
        post_url: '',
        page_followers: undefined,
        feed_views: undefined,
        story_views: undefined,
        likes: undefined,
        comments: undefined,
    };

    const validationSchema = Yup.object({
        cost: Yup.number().integer().required('a valid number is required'),
        story_views: Yup.number().when(['page_followers', 'likes', 'comments', 'feed_views'], {
            is: (
                followers: string | undefined,
                likes: string | undefined,
                comments: string | undefined,
                feed_views: string | undefined
            ) =>
                (followers !== undefined && followers !== '') ||
                (likes !== undefined && likes !== '') ||
                (comments !== undefined && comments !== '') ||
                (feed_views !== undefined && feed_views !== ''),
            then: (schema) => schema.required('this field is required'),
            otherwise: (schema) => schema.notRequired(),
        }),
    });

    const submitInstagramInfluencerPostResults = useCallback(
        async (postId: number | undefined, values: InstagramInfluencerPostFormValues) => {
            const resultsData = getResultsData(values);
            if (
                Object.values(resultsData).length === 0 ||
                Object.values(resultsData).every((val) => val === undefined)
            ) {
                return;
            }

            if (typeof postId === 'number') {
                return postInstagramInfluencerPostResults({
                    post: postId,
                    ...resultsData,
                });
            }
        },
        []
    );

    const handleSubmit = useCallback(
        async (
            values: InstagramInfluencerPostFormValues,
            { setSubmitting, setErrors }: FormikHelpers<InstagramInfluencerPostFormValues>
        ) => {
            if (!initialized || !influencerPlan || !groupId) {
                return;
            }

            try {
                setSubmitting(true);
                const influencerPostResponse = await createInstagramInfluencerPost(groupId, {
                    influencer_id: values.influencer_id?.value ?? null,
                    currency_id: values.currency_id?.value ?? currencyOptions[0].value,
                    cost: values.cost ?? '0',
                    post_url: values.post_url ?? '',
                    xero_invoice_id: values.xero_invoice_id ? values.xero_invoice_id.value : null,
                });
                if (!influencerPostResponse) {
                    showNotification("Could't create post. No associated influencer plan", 'error');
                    return;
                }
                if (influencerPostResponse.status === 400) {
                    setErrors(mapApiErrorsToFormikErrors(influencerPostResponse.data));
                    return;
                }

                const influencerPostResultsResponse = await submitInstagramInfluencerPostResults(
                    influencerPostResponse.data.id,
                    values
                );

                if (influencerPostResultsResponse?.status === 400) {
                    setErrorMessage('Post has been created, but there was an error submitting Post Results');
                    return;
                }

                if (influencerPostResultsResponse && influencerPostResultsResponse?.status === 200) {
                    setInstagramInfluencerPostResult(influencerPostResultsResponse.data);
                }

                onCreated();
            } catch (e) {
                //no-op
            } finally {
                setSubmitting(false);
            }
        },
        [
            onCreated,
            createInstagramInfluencerPost,
            submitInstagramInfluencerPostResults,
            initialized,
            groupId,
            currencyOptions,
            influencerPlan,
            setInstagramInfluencerPostResult,
        ]
    );

    return (
        <Modal modalClassName={styles.modal} isOpen={isOpen} onClose={onClose} closeOnOverlayClick>
            <ModalTitle>Create Post</ModalTitle>
            <ModalContent className={styles.content}>
                {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
                <InstagramInfluencerPostForm
                    handleSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                />
            </ModalContent>
        </Modal>
    );
};

export default CreateInstagramInfluencerPostModal;
