import { ApiResponse, ApiResponseError } from '../../types';
import { MonitoredAudioUrl } from './monitoredAudio.types';
import { fetchWithToken } from '../../helpers';
import { MonitoredAudioTrackingTypes } from '../../utils';

type GetMonitoredAudioResponse = ApiResponse<MonitoredAudioUrl, 200> | ApiResponse<{ detail: 'Not found' }, 404>;

export async function getMonitoredAudio(
    monitoredAudioId: number,
    requestInit?: RequestInit
): Promise<GetMonitoredAudioResponse> {
    const response = await fetchWithToken(`/api/tiktok/viewsets/monitored-audio/${monitoredAudioId}/`, requestInit);

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get monitored audio ${monitoredAudioId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PatchTrackingTypeResponseObject = {
    id: number;
    tracking_type: MonitoredAudioTrackingTypes;
    is_enhanced: boolean;
};

type PatchTrackingTypeResponse =
    | ApiResponse<PatchTrackingTypeResponseObject, 200>
    | ApiResponse<{ detail: string }, 404>
    | ApiResponse<ApiResponseError<PatchTrackingTypeResponseObject>, 400>;

export async function patchMonitoredAudioTrackingType(
    monitoredAudioId: number,
    trackingType: MonitoredAudioTrackingTypes
): Promise<PatchTrackingTypeResponse> {
    const response = await fetchWithToken(`/api/tiktok/viewsets/monitored-audio/${monitoredAudioId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tracking_type: trackingType,
        }),
    });

    if (response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not patch monitored audio ${monitoredAudioId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
