import { call, encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    ForbiddenResponse,
    NotFoundResponse,
    OrderingParams,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { isPublicReportRequestInit, PublicReportRequestInit } from '../../influencer/utils';
import { InstagramPostDetails, Post, PublicPost, TiktokPostDetails } from './posts.types';

export type GetPostsParams = Partial<
    PaginatedRequest & {
        campaign_id: string;
    } & OrderingParams<'view_count'>
>;
type BasePostsResponse<T extends Post | PublicPost> =
    | ApiResponse<PaginatedApiResponseData<T>, 200>
    | ForbiddenResponse
    | NotFoundResponse;

export async function getPosts(
    params: GetPostsParams,
    requestInit: PublicReportRequestInit
): Promise<BasePostsResponse<PublicPost>>;
export async function getPosts(params: GetPostsParams, requestInit?: RequestInit): Promise<BasePostsResponse<Post>>;
export async function getPosts(
    params: GetPostsParams,
    requestInit?: RequestInit | PublicReportRequestInit
): Promise<BasePostsResponse<Post> | BasePostsResponse<PublicPost>> {
    const shouldBypassTokens = requestInit && isPublicReportRequestInit(requestInit);
    const response = await call(`/api/creatorbase/posts/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens,
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get posts');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getAllPosts(params: Omit<GetPostsParams, 'page' | 'page_size'>, requestInit?: RequestInit) {
    return fetchAll<Post>(`/api/creatorbase/posts/${encodeUrlSearchParams(params)}`, requestInit);
}

export type PostPostData = {
    campaign_id: number;
    cost?: number | null;
} & ({ identifier: string } | { influencer_account_identifier: string });

type PostPostResponse = ApiResponse<Post, 201> | ApiResponse<ApiResponseError<PostPostData>, 400> | ForbiddenResponse;

export async function postPost(data: PostPostData): Promise<PostPostResponse> {
    const response = await fetchWithToken(`/api/creatorbase/posts/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 403 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create post');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type PatchableTiktokDetails = Pick<TiktokPostDetails, 'ad_code'>;
type PatchableInstagramDetails = Partial<Pick<InstagramPostDetails, 'ad_code' | 'story_view_count'>>;

export type PatchPostData = {
    cost: number | null;
    identifier: string;
    influencer_account_identifier: string;
    notes: string;
} & ({ tiktok_details: PatchableTiktokDetails } | { instagram_details: PatchableInstagramDetails });

type PatchPostResponse =
    | ApiResponse<Post, 200>
    | ApiResponse<ApiResponseError<PatchPostData>, 400>
    | NotFoundResponse
    | ForbiddenResponse;

export async function patchPost(postId: string, data: Partial<PatchPostData>): Promise<PatchPostResponse> {
    const response = await fetchWithToken(`/api/creatorbase/posts/${postId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404 || response.status === 403 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not update post');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type DeletePostResponse = ApiResponse<null, 204> | ForbiddenResponse | NotFoundResponse;

export async function deletePost(postId: string): Promise<DeletePostResponse> {
    const response = await fetchWithToken(`/api/creatorbase/posts/${postId}/`, {
        method: 'DELETE',
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not delete post');
    }

    return {
        status: 204,
        data: null,
    };
}
