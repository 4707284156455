import React, { HTMLProps, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { Column, ColumnInstance, Row, usePagination, useSortBy, useTable } from 'react-table';
import styles from './Table.module.css';
import LoadingSpinner from '../../SharedComponents/LoadingSpinner/LoadingSpinner';
import cn from 'classnames';
import {
    OrderByParam,
    useOrderByToReactTableSortBy,
    useReactTableSortToOrderBy,
} from '../../Hooks/useReactTableSortToOrderBy';
import { ReactComponent as OrderByDefault } from '../../SharedComponents/svg/OrderByDefault.svg';
import { ReactComponent as OrderByDesc } from '../../SharedComponents/svg/OrderByDesc.svg';
import { ReactComponent as OrderByAsc } from '../../SharedComponents/svg/OrderByAsc.svg';

export type PaginationToolbarProps = {
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    page: number;
    setPage: (page: number) => void;
    count: number;
};

export type PaginationState<T extends object> = {
    pageRows: Row<T>[];
};

export type TableProps<T extends object> = {
    data: T[];
    columns: Column<T>[];
    loading?: boolean;
    noDataLabel?: string;
    onRowClick?: (item: T, page: Row<T>[], e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
    pageSize?: number;
    manualPagination?: boolean;
    count?: number;
    onPageIndexChange?: (pageIndex: number) => void;
    autoResetPage?: boolean;
    manualSortBy?: boolean;
    orderBy?: OrderByParam<T>;
    onOrderByChange?: (item: OrderByParam<T>) => void;
    disableSortBy?: boolean;
    pageIndex?: number;
    tableClassName?: string;
    theadClassName?: string;
    tbodyClassName?: string;
    tfootClassName?: string;
    renderFooter?: (() => React.ReactNode) | React.ReactNode;
    renderPaginationToolbar?: (props: PaginationToolbarProps) => React.ReactNode;
    onPaginationChange?: (state: PaginationState<T>) => void;
    renderRow?: (props: {
        row: Row<T>;
        rowProps: HTMLProps<HTMLTableRowElement>;
        cellProps: HTMLProps<HTMLTableCellElement>;
        rows: Row<T>[];
    }) => ReactElement<any, any>;
    renderHeaderRow?: (headers: ColumnInstance<T>[]) => React.ReactNode;
    renderSortingIcons?: (column: ColumnInstance<T>) => React.ReactNode | undefined;
    onToggleSortBy?: (column: ColumnInstance<T>) => void;
    onContextMenu?: (e: React.MouseEvent<HTMLTableElement, MouseEvent>) => void;
};

const defaultRenderRow: TableProps<any>['renderRow'] = ({ row, rowProps, cellProps }) => (
    <tr {...rowProps} {...row.getRowProps()}>
        {row.cells.map((cell) => (
            <td {...cellProps} {...cell.getCellProps()}>
                {cell.render('Cell')}
            </td>
        ))}
    </tr>
);

const defaultSortingIcons: TableProps<any>['renderSortingIcons'] = (column) => (
    <>
        {typeof column.isSortedDesc === 'undefined' ? (
            <OrderByDefault className={styles.sortingIcon} />
        ) : column.isSortedDesc ? (
            <OrderByDesc className={styles.sortingIcon} />
        ) : (
            <OrderByAsc className={styles.sortingIcon} />
        )}
    </>
);

const Table = <T extends object>({
    data,
    columns,
    noDataLabel,
    loading,
    onRowClick,
    count,
    pageSize = data.length || 10,
    manualPagination,
    autoResetPage,
    onPageIndexChange,
    manualSortBy,
    onOrderByChange,
    orderBy = {},
    disableSortBy,
    pageIndex,
    theadClassName,
    tbodyClassName,
    tfootClassName,
    renderFooter,
    renderPaginationToolbar,
    tableClassName,
    onPaginationChange,
    renderRow = defaultRenderRow,
    renderSortingIcons = defaultSortingIcons,
    renderHeaderRow,
    onToggleSortBy,
    onContextMenu,
}: TableProps<T>) => {
    const sortBy = useOrderByToReactTableSortBy(orderBy);
    const {
        getTableProps,
        headers,
        rows,
        page,
        prepareRow,
        state: { pageIndex: statePageIndex, sortBy: stateSortBy },
        gotoPage,
        setPageSize,
    } = useTable(
        {
            data,
            columns,
            autoResetPage,
            manualPagination,
            pageCount: manualPagination && typeof count === 'number' ? Math.ceil(count / pageSize) : -1,
            manualSortBy,
            disableSortBy: !data.length || disableSortBy,
            disableMultiSort: true,
            initialState: {
                pageSize,
                sortBy,
            },
            useControlledState: (state) =>
                useMemo(() => {
                    if (manualPagination && typeof pageIndex === 'number') {
                        state.pageIndex = pageIndex;
                    }

                    if (manualSortBy) {
                        state.sortBy = sortBy;
                    }

                    if (!manualPagination && typeof pageSize === 'number') {
                        state.pageSize = pageSize;
                    }

                    return state;
                    // eslint-disable-next-line
                }, [state, manualPagination, pageIndex, sortBy, pageSize]),
        },
        useSortBy,
        usePagination
    );

    const orderByParam = useReactTableSortToOrderBy(stateSortBy);

    const handleChangePage = useCallback(
        (newPage: number) => {
            if (manualPagination && typeof onPageIndexChange === 'function') {
                onPageIndexChange(newPage - 1);
                return;
            }

            gotoPage(newPage - 1);
        },
        [onPageIndexChange, gotoPage, manualPagination]
    );

    const defaultToggleSortBy = useCallback(
        (column: ColumnInstance<T>) => {
            if (typeof onOrderByChange !== 'function') {
                return;
            }

            const orderBy: OrderByParam<T> = {};
            if (typeof column.isSortedDesc === 'undefined') {
                orderBy['order_by_asc'] = column.id as keyof T;
            }

            if (typeof column.isSortedDesc === 'boolean' && !column.isSortedDesc) {
                orderBy['order_by_desc'] = column.id as keyof T;
            }

            onOrderByChange(orderBy);
        },
        [onOrderByChange]
    );

    useEffect(() => {
        if (typeof onOrderByChange === 'function' && !manualSortBy) {
            onOrderByChange(orderByParam);
        }
    }, [manualSortBy, onOrderByChange, orderByParam]);

    useEffect(() => {
        if (typeof onPageIndexChange === 'function' && !manualPagination) {
            onPageIndexChange(statePageIndex);
        }
    }, [manualPagination, onPageIndexChange, statePageIndex]);

    useEffect(() => {
        if (typeof onPaginationChange === 'function') {
            onPaginationChange({ pageRows: page });
        }
    }, [onPaginationChange, page]);

    return (
        <>
            <div className={styles.tableContainer}>
                <table className={cn(styles.table, tableClassName)} {...getTableProps()} onContextMenu={onContextMenu}>
                    <thead className={theadClassName}>
                        {typeof renderHeaderRow === 'function' ? (
                            renderHeaderRow(headers)
                        ) : (
                            <tr className={styles.headerRow}>
                                {headers.map((column) => (
                                    <th
                                        className={styles.headerCell}
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps(
                                                // override to make controlled
                                                manualSortBy
                                                    ? {
                                                          onClick: () => {
                                                              if (!column.disableSortBy) {
                                                                  if (typeof onToggleSortBy === 'function') {
                                                                      onToggleSortBy(column);
                                                                      return;
                                                                  }

                                                                  defaultToggleSortBy(column);
                                                              }
                                                          },
                                                      }
                                                    : undefined
                                            )
                                        )}
                                    >
                                        {column.render('Header')}
                                        {column.canSort && renderSortingIcons(column)}
                                    </th>
                                ))}
                            </tr>
                        )}
                    </thead>
                    <tbody className={tbodyClassName}>
                        {!rows.length && (
                            <tr>
                                <td colSpan={columns.length}>
                                    {loading ? (
                                        <LoadingSpinner />
                                    ) : (
                                        <div className={styles.noDataLabel}>{noDataLabel}</div>
                                    )}
                                </td>
                            </tr>
                        )}
                        {page.map((row) => {
                            prepareRow(row);
                            const rowProps: HTMLProps<HTMLTableRowElement> = {
                                className: cn(styles.tableRow, {
                                    [styles.clickable]: typeof onRowClick === 'function',
                                    [styles.loading]: loading,
                                }),
                                onClick: (e) =>
                                    typeof onRowClick === 'function' && !loading && onRowClick(row.original, page, e),
                            };

                            const cellProps: HTMLProps<HTMLTableCellElement> = { className: styles.bodyCell };
                            return renderRow({ row, rowProps, cellProps, rows: page });
                        })}
                    </tbody>
                    {typeof renderFooter === 'function' && renderFooter()}
                </table>
            </div>
            {typeof renderPaginationToolbar === 'function' &&
                renderPaginationToolbar({
                    setPageSize,
                    setPage: handleChangePage,
                    pageSize,
                    page: statePageIndex + 1,
                    count: count ?? rows.length,
                })}
        </>
    );
};

export default Table;
