import { AuthContext } from 'Modules/Auth/contexts/AuthContext';
import { useContext } from 'react';
import NoAccess from './NoAccess/NoAccess';
import { FCWithChildren } from 'utility/utility.types';

const WhiteLabelAuthWrapper: FCWithChildren = ({ children }) => {
    const authContext = useContext(AuthContext);
    const hasAccess = !!authContext.user?.creator_base_access;

    if (!hasAccess) {
        return <NoAccess />;
    }

    return <>{children}</>;
};

export default WhiteLabelAuthWrapper;
