import { TiktokInfluencerPlanHashtag } from '../../../../../../App.types';
import { useState } from 'react';
import { showNotification } from '../../../../../../helpers';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import FormField from '../../../../../../ui/DataEntry/FormField/FormField';
import Label from '../../../../../../ui/DataEntry/Label/Label';
import Button from '../../../../../../ui/Buttons/Button/Button';
import { postTiktokInfluencerPlanHashtag } from '../../../InfluencerPlan.api';

type AddHashtagModalProps = {
    influencerPlanId: number;
    isModalOpen: boolean;
    closeModal: () => void;
    onHashtagCreated: (hashtag: TiktokInfluencerPlanHashtag) => void;
};

const AddHashtagModal = ({ onHashtagCreated, closeModal, isModalOpen, influencerPlanId }: AddHashtagModalProps) => {
    const [hashtag, setHashtag] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);

    const handleCreate = async () => {
        try {
            setSubmitLoading(true);
            const createdHashtag = await postTiktokInfluencerPlanHashtag({ plan: influencerPlanId, hashtag });
            onHashtagCreated(createdHashtag);
            showNotification('Hashtag created', 'info');
            closeModal();
        } catch {
            showNotification('Could not create hashtag', 'error');
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={closeModal}>
            <ModalTitle>Add Hashtag</ModalTitle>
            <ModalContent>
                <FormField>
                    <Label htmlFor="hashtag">Hashtag</Label>
                    <input
                        name="hashtag"
                        id="hashtag"
                        placeholder="Enter hashtag"
                        value={hashtag}
                        onChange={(e) => setHashtag(e.target.value)}
                    />
                </FormField>
            </ModalContent>
            <ModalFooter align="right">
                <Button type="filled" color="black" loading={submitLoading} onClick={handleCreate}>
                    Create
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddHashtagModal;
