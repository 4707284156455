import { AppServer } from '../mirage';
import * as videosMirage from './videos/videos.mirage';
import * as channelsMirage from './channels/channels.mirage';

export const models = {
    ...videosMirage.models,
    ...channelsMirage.models,
};

export const serializers = {
    ...videosMirage.serializers,
    ...channelsMirage.serializers,
};

export const factories = {
    ...videosMirage.factories,
    ...channelsMirage.factories,
};

export function handleRequests(server: AppServer) {
    videosMirage.handleRequests(server);
    channelsMirage.handleRequests(server);
}
