import { InstagramInfluencerUser, postPostPaymentRequest } from '@round/api';
import { FormField, Modal, ModalTitle, Select, Button, Label } from '@round/ui-kit';
import { GenericDropdownOption } from 'App.types';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { Field, Form, Formik, FieldProps, ErrorMessage, FormikHelpers } from 'formik';
import useNonNullContext from 'Hooks/useNonNullContext';
import { InstagramInfluencerPost } from 'Modules/Advertising/InfluencerPlan/types/Instagram.types';
import { useMemo } from 'react';
import { ValueType } from 'react-select';
import styles from './PostPaymentRequestModal.module.css';
import * as yup from 'yup';
import { asMoney, showNotification } from 'helpers';
import { mapApiErrorsToFormikErrors } from 'utility/utility';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    instagramInfluencerPost: InstagramInfluencerPost | undefined | null;
    influencerUser: InstagramInfluencerUser | undefined | null;
    onSuccess: () => void;
};

type FormValues = {
    paypal_email_address: string;
    amount: string;
    currency: ValueType<GenericDropdownOption<number>, false>;
};

const hasNumericalMismatch = (a: number | undefined | null, b: number | undefined | null) => {
    if (typeof a !== 'number' || typeof b !== 'number') {
        return false;
    }

    return a !== b;
};

const validationSchema = yup.object().shape({
    amount: yup.string().required('Amount is required'),
    currency: yup.object().nullable().required('Currency is required'),
    paypal_email_address: yup
        .string()
        .email('Must be a valid email address')
        .required('PayPal email address is required'),
});

const PostPaymentRequestModal = ({ isOpen, onClose, influencerUser, instagramInfluencerPost, onSuccess }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const currencyOptions = currencies.map((c) => ({
        value: c.id,
        label: c.name,
    }));

    const initialValues: FormValues = useMemo(
        () => ({
            amount: instagramInfluencerPost?.cost || '',
            currency: currencyOptions.find((curr) => curr.value === instagramInfluencerPost?.currency_id),
            paypal_email_address: influencerUser?.paypal_email || '',
        }),
        [
            currencyOptions,
            influencerUser?.paypal_email,
            instagramInfluencerPost?.cost,
            instagramInfluencerPost?.currency_id,
        ]
    );

    const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        if (typeof instagramInfluencerPost?.id !== 'number' || !values.currency?.value) {
            return;
        }

        helpers.setSubmitting(true);
        try {
            const response = await postPostPaymentRequest({
                paypal_email_address: values.paypal_email_address,
                amount: values.amount,
                currency: values.currency?.value,
                instagram_influencer_post: instagramInfluencerPost?.id,
            });

            if (response.status === 201) {
                showNotification('Payment request created', 'info');
                onClose();
                onSuccess();
                return;
            }

            if (response.status === 400) {
                if (Array.isArray(response.data)) {
                    showNotification(response.data.join('. '), 'error');
                    return;
                }

                helpers.setErrors(mapApiErrorsToFormikErrors<FormValues>(response.data));
                return;
            }

            showNotification(response.data.detail, 'error');
        } catch {
            showNotification('Could not request payment', 'error');
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const reelsCurrency = currencies.find((curr) => curr.id === influencerUser?.reels_currency);
    const reelsCostDisplayValue =
        influencerUser?.reels_cost && reelsCurrency ? asMoney(Number(influencerUser.reels_cost), reelsCurrency) : '-';

    const feedCurrency = currencies.find((curr) => curr.id === influencerUser?.feed_currency);
    const feedCostDisplayValue =
        influencerUser?.feed_cost && feedCurrency ? asMoney(Number(influencerUser.feed_cost), feedCurrency) : '-';

    const storyCurrency = currencies.find((curr) => curr.id === influencerUser?.story_currency);
    const storyCostDisplayValue =
        influencerUser?.story_cost && storyCurrency ? asMoney(Number(influencerUser.story_cost), storyCurrency) : '-';

    const postCurrency = currencies.find((curr) => curr.id === instagramInfluencerPost?.currency_id);
    const postCostDisplayValue =
        instagramInfluencerPost?.cost && postCurrency
            ? asMoney(parseFloat(instagramInfluencerPost.cost), postCurrency)
            : '-';

    return (
        <Modal closeOnOverlayClick isOpen={isOpen} onClose={onClose} className={styles.container}>
            <ModalTitle>Request PayPal Payment</ModalTitle>
            <dl className={styles.detailsContainer}>
                <div>
                    <dt>Reels rate</dt>
                    <dd>{reelsCostDisplayValue}</dd>
                </div>

                <div>
                    <dt>Feed rate</dt>
                    <dd>{feedCostDisplayValue}</dd>
                </div>

                <div>
                    <dt>Story rate</dt>
                    <dd>{storyCostDisplayValue}</dd>
                </div>

                <div>
                    <dt>Post cost</dt>
                    <dd>{postCostDisplayValue}</dd>
                </div>
            </dl>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {(props) => {
                    const currencyFieldValue = props.values.currency?.value;
                    const hasCurrencyMismatch = hasNumericalMismatch(
                        currencyFieldValue,
                        influencerUser?.reels_currency
                    );

                    return (
                        <Form className={styles.form}>
                            <div className={styles.splitSection}>
                                <FormField>
                                    <Label htmlFor="currency">Currency</Label>
                                    <Field id="currency" name="currency">
                                        {(fieldProps: FieldProps<FormValues['currency']>) => (
                                            <Select
                                                options={currencyOptions}
                                                placeholder="Select currency"
                                                value={fieldProps.field.value}
                                                onChange={(value) => {
                                                    fieldProps.form.setFieldValue(fieldProps.field.name, value);
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="currency">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </FormField>

                                <FormField>
                                    <Label htmlFor="amount">Amount</Label>
                                    <Field
                                        type="number"
                                        id="amount"
                                        name="amount"
                                        placeholder="Enter amount"
                                        decimalsLimit={2}
                                        className={styles.currencyInput}
                                    />
                                    <ErrorMessage name="amount">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </FormField>
                            </div>

                            <FormField>
                                <Label htmlFor="paypal_email_address">PayPal Email</Label>
                                <Field
                                    type="email"
                                    id="paypal_email_address"
                                    name="paypal_email_address"
                                    placeholder="Enter PayPal email address"
                                />
                                <ErrorMessage name="paypal_email_address">
                                    {(msg) => <span className={styles.error}>{msg}</span>}
                                </ErrorMessage>
                            </FormField>

                            <Button
                                type="filled"
                                color="black"
                                htmlType="submit"
                                loading={props.isSubmitting}
                                disabled={!props.isValid}
                                className={styles.submitButton}
                            >
                                Submit
                            </Button>

                            {hasCurrencyMismatch && (
                                <p className={styles.warning}>
                                    The selected currency does not match the influencer's requested currency.
                                </p>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default PostPaymentRequestModal;
