import { useCallback, useEffect, useMemo } from 'react';
import { InstagramInfluencerPost, InstagramTablePostStats } from '../types/Instagram.types';
import {
    getInstagramPostStats,
    getInstagramPostsStatsTotals,
    postInactiveStatuses,
    statusOrder,
} from '../InfluencerPlan.helpers';
import useNonNullContext from '../../../../Hooks/useNonNullContext';
import { InstagramCreatorsContext } from '../contexts/InstagramCreatorsContext/InstagramCreatorsContext';

type UseInstagramTableDataHelperReturn = {
    sortedActiveInfluencerPosts: InstagramInfluencerPost[];
    sortedBackupInfluencerPosts: InstagramInfluencerPost[];
    tablePostStatsByPost: { [postId: number]: InstagramTablePostStats };
    totalsByGroup: { [groupId: number]: InstagramTablePostStats };
};

export function useInstagramTableDataHelper(): UseInstagramTableDataHelperReturn {
    const {
        instagramInfluencerPostGroups,
        instagramInfluencerPosts,
        instagramInfluencerPostResults,
        instagramPosts,
        instagramUserStats,
        instagramInfluencerUsers,

        init,
        isInitCompleted,
    } = useNonNullContext(InstagramCreatorsContext);

    useEffect(() => {
        if (!isInitCompleted) {
            init();
        }
    }, [init, isInitCompleted]);

    const getInstagramPostStatsForPost = useCallback(
        (influencerPost: InstagramInfluencerPost) => {
            const postsInstagramUserId =
                instagramInfluencerUsers.find((influencer) => influencer.id === influencerPost.influencer_id)?.user ??
                influencerPost.instagram_user;
            const scrapedPost = instagramPosts.find((p) => p.id === influencerPost.post);
            const results = instagramInfluencerPostResults.find((r) => r.post === influencerPost.id);
            const userStats = instagramUserStats.find((s) => s.instagram_user === postsInstagramUserId);
            return getInstagramPostStats(results, scrapedPost, userStats);
        },
        [instagramInfluencerPostResults, instagramPosts, instagramUserStats, instagramInfluencerUsers]
    );

    const tablePostStatsByPost = useMemo(
        () =>
            instagramInfluencerPosts.reduce((acc, post) => {
                acc[post.id] = getInstagramPostStatsForPost(post);
                return acc;
            }, {} as { [postId: number]: InstagramTablePostStats }),
        [getInstagramPostStatsForPost, instagramInfluencerPosts]
    );

    const sortedActiveInfluencerPosts = useMemo(
        () =>
            instagramInfluencerPosts
                .filter((post) => !postInactiveStatuses.includes(post.status))
                .sort((a, b) => {
                    const orderByStatus = statusOrder[a.status] - statusOrder[b.status];
                    const totalViewsDescending =
                        (getInstagramPostStatsForPost(b).totalViews ?? 0) -
                        (getInstagramPostStatsForPost(a).totalViews ?? 0);
                    const estimatedViewsDescending =
                        (b.estimated_views_override ?? b.estimated_views ?? 0) -
                        (a.estimated_views_override ?? a.estimated_views ?? 0);

                    if (orderByStatus !== 0) {
                        return orderByStatus;
                    }

                    if (totalViewsDescending !== 0) {
                        return totalViewsDescending;
                    }

                    return estimatedViewsDescending;
                }),
        [getInstagramPostStatsForPost, instagramInfluencerPosts]
    );

    const sortedBackupInfluencerPosts = useMemo(
        () =>
            instagramInfluencerPosts
                .filter((post) => postInactiveStatuses.includes(post.status))
                .sort((a, b) => statusOrder[a.status] - statusOrder[b.status]),
        [instagramInfluencerPosts]
    );

    const totalsByGroup: { [groupId: string]: InstagramTablePostStats } = useMemo(() => {
        const totalsByGroup: { [groupId: string]: InstagramTablePostStats } = {};

        if (!instagramInfluencerPostGroups.length || !sortedActiveInfluencerPosts.length) {
            return totalsByGroup;
        }

        for (const group of instagramInfluencerPostGroups) {
            const influencerPosts = sortedActiveInfluencerPosts.filter((post) => post.group_id === group.id);
            const results = instagramInfluencerPostResults.filter(
                (r) => !!influencerPosts.find((p) => p.id === r.post)
            );
            const userStats = instagramUserStats.filter(
                (s) =>
                    !!influencerPosts.find((p) => {
                        const instagramUserId =
                            instagramInfluencerUsers.find((i) => i.id === p.influencer_id)?.user ?? p.instagram_user;
                        return instagramUserId === s.instagram_user;
                    })
            );
            const scrapedPosts = instagramPosts.filter((p) => influencerPosts.find((post) => post.post === p.id));
            const influencers = instagramInfluencerUsers.filter((influencer) =>
                Boolean(influencerPosts.find((p) => p.influencer_id === influencer.id))
            );

            const groupStats = getInstagramPostsStatsTotals(
                influencerPosts,
                results,
                userStats,
                scrapedPosts,
                influencers
            );
            totalsByGroup[group.id] = {
                ...groupStats,
                engagementRate: Number(groupStats.totalEngagements) / Number(groupStats.engagementRateViews),
                cost: influencerPosts.reduce((acc, p) => acc + Number(p.cost), 0),
                clientCost: influencerPosts.reduce((acc, p) => acc + Number(p.client_cost), 0),
                estimatedViews: influencerPosts.reduce(
                    (acc, post) => acc + Number(post.estimated_views_override ?? post.estimated_views ?? 0),
                    0
                ),
            };
        }

        return totalsByGroup;
    }, [
        instagramInfluencerPostGroups,
        sortedActiveInfluencerPosts,
        instagramInfluencerPostResults,
        instagramPosts,
        instagramUserStats,
        instagramInfluencerUsers,
    ]);

    return {
        sortedActiveInfluencerPosts,
        sortedBackupInfluencerPosts,
        tablePostStatsByPost,
        totalsByGroup,
    };
}
