import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';

import styles from './ModalRight.module.css';

type Props = {
    closeModal?: () => void;
    isModalOpen: boolean;
    children: React.ReactElement;
    className?: string;
};

const ModalRight: FunctionComponent<Props> = ({ closeModal, isModalOpen, children, className }) => {
    return (
        <Modal
            className={cn(styles.Modal, className, { [styles.showModal]: isModalOpen })}
            overlayClassName={cn(styles.Overlay, { [styles.showOverlay]: isModalOpen })}
            closeTimeoutMS={300}
            isOpen={isModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={closeModal}
            ariaHideApp={false}
        >
            <div data-test-id="modal-right" className={styles.modalContent}>
                {children}
            </div>
        </Modal>
    );
};

export default ModalRight;
