import React, { useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import RefreshCCWIcon from '../../SharedComponents/svg/Icons/RefreshCCWIcon';
import styles from './RefreshButtonSm.module.css';
import cn from 'classnames';

export type RefreshButtonSmProps = {
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    loading?: boolean;
    disabled?: boolean;
    hint?: string;
    iconWidth?: number;
    iconHeight?: number;
    className?: string;
};

const RefreshButtonSm = ({
    hint,
    onClick,
    loading,
    disabled,
    iconHeight = 12,
    iconWidth = 12,
    className,
}: RefreshButtonSmProps) => {
    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (disabled || loading || typeof onClick !== 'function') {
                return;
            }

            onClick(e);
        },
        [disabled, loading, onClick]
    );

    return (
        <>
            <button
                onMouseLeave={() => ReactTooltip.hide()}
                className={cn(styles.button, className, {
                    [styles.disabled]: disabled && !loading,
                    [styles.loading]: loading,
                })}
                data-tip={hint}
                onClick={handleClick}
            >
                <RefreshCCWIcon
                    className={cn(styles.icon, { [styles.disabled]: disabled && !loading, [styles.loading]: loading })}
                    height={iconHeight}
                    width={iconWidth}
                />
            </button>
            {hint && <ReactTooltip />}
        </>
    );
};

export default RefreshButtonSm;
