import { PaginatedApiResponseData, PaginatedRequest, PlaylistReportItem } from '../../../../App.types';
import { ApiResponse } from '../../../../utility/utility.types';
import { ApiResponseError, encodeUrlSearchParams, fetchWithToken } from '@round/api';

type GetSpotifyPlaylistReportItemsParams = Partial<PaginatedRequest>;
export async function getSpotifyPlaylistReportItems(
    params: GetSpotifyPlaylistReportItemsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<PlaylistReportItem>, 200>> {
    const response = await fetchWithToken(
        `/api/spotify/viewsets/playlist-report-item/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not get spotify playlist report items');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type SpotifyPlaylistReportItemApiModel = {
    media_plan_item_id: number;
    amount_spent: number;
};

type PostSpotifyPlaylistReportItemResponse =
    | ApiResponse<PlaylistReportItem, 200>
    | ApiResponse<ApiResponseError<SpotifyPlaylistReportItemApiModel>, 400>;

export async function postSpotifyPlaylistReportItem(
    data: SpotifyPlaylistReportItemApiModel
): Promise<PostSpotifyPlaylistReportItemResponse> {
    const response = await fetchWithToken('/api/spotify/viewsets/playlist-report-item/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create spotify playlist report item');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PatchSpotifyPlaylistReportItemResponse =
    | ApiResponse<PlaylistReportItem, 200>
    | ApiResponse<ApiResponseError<SpotifyPlaylistReportItemApiModel>, 400>
    | ApiResponse<{ detail: string }, 404>;

export async function patchSpotifyPlaylistReportItem(
    id: number,
    data: Partial<SpotifyPlaylistReportItemApiModel>
): Promise<PatchSpotifyPlaylistReportItemResponse> {
    const response = await fetchWithToken(`/api/spotify/viewsets/playlist-report-item/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not patch spotify playlist report item ${id}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type DeleteSpotifyPlaylistReportItemResponse = ApiResponse<null, 204> | ApiResponse<{ detail: string }, 404>;
export async function deleteSpotifyPlaylistReportItem(id: number): Promise<DeleteSpotifyPlaylistReportItemResponse> {
    const response = await fetchWithToken(`/api/spotify/viewsets/playlist-report-item/${id}/`, { method: 'DELETE' });

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not delete spotify playlist report item ${id}`);
    }

    return {
        status: 204,
        data: null,
    };
}
