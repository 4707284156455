import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { BuyPlatform } from './buyPlatform.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    buyPlatform: Model as ModelDefinition<BuyPlatform>,
};

export const serializers = {
    buyPlatform: makeSerializer<BuyPlatform>([]),
};

export const factories = {
    buyPlatform: createFactory<BuyPlatform>({
        name(index: number) {
            return `name ${index}`;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const getBuyPlatformsListUrl = '/api/advertising/viewsets/buy-platform/';
    server.get(getBuyPlatformsListUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('buyPlatform'), {
            url: getBuyPlatformsListUrl,
            serialize: (resource) => this.serialize(resource, 'buyPlatform'),
            queryParams: request.queryParams,
        });
    });
}
