import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReportContext } from '../../ReportContext';
import { useCallback } from 'react';
import { creatorbase } from '@round/api';

export function useReportCampaignStats() {
    const state = useNonNullContextSelector(ReportContext, ([values]) => values.campaignStats);
    const dispatch = useNonNullContextSelector(ReportContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (campaignIds: number[], abortSignal?: AbortSignal) => {
            dispatch({ type: 'setStatsLoading', payload: { campaignIds } });

            const results = await Promise.allSettled(
                campaignIds.map(async (campaignId) => {
                    try {
                        const response = await creatorbase.postGetAggregatedPostStats(
                            {
                                campaign_id: campaignId,
                            },
                            abortSignal
                        );

                        if (response.status !== 200) {
                            dispatch({
                                type: 'setStatsError',
                                payload: { campaignId, error: `Couldn't get aggregated stats` },
                            });
                            return response;
                        }
                        dispatch({
                            type: 'setStatsSuccess',
                            payload: { campaignId, data: response.data },
                        });
                        return response;
                    } catch (error) {
                        if (error instanceof Error && error.name === 'AbortError') {
                            dispatch({
                                type: 'setStatsIdle',
                                payload: { campaignId },
                            });
                            return;
                        }

                        dispatch({
                            type: 'setStatsError',
                            payload: { campaignId, error: `Couldn't get aggregated stats` },
                        });
                        throw error;
                    }
                })
            );

            //this ensures that the abort signal is thrown and bubbles up, rather than just being caught in the allSettled result.
            abortSignal?.throwIfAborted();

            return results;
        },
        [dispatch]
    );

    return {
        data: state.data,
        fetchData,

        getAreCampaignStatsLoading: (campaignId: number) => state.data[campaignId]?.status === 'loading',
    };
}
