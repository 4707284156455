import { OptionProps, components } from 'react-select';
import styles from './OptionWithSubLabel.module.css';

export type DropdownOptionWithSubLabel<T> = {
    value: T;
    label: string;
    subLabel: string;
};

const OptionWithSubLabel = <T extends any, IsMulti extends boolean = false>(
    props: OptionProps<DropdownOptionWithSubLabel<T>, IsMulti>
) => {
    return (
        <components.Option {...props}>
            <div>{props.label}</div>
            <div className={styles.subLabel}>{props.data.subLabel}</div>
        </components.Option>
    );
};

export default OptionWithSubLabel;
