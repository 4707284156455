import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AdminBooking } from './booking.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../../mirage';

export const models = {
    adminBooking: Model as ModelDefinition<AdminBooking>,
};

export const serializers = {
    adminBooking: makeSerializer<AdminBooking>([]),
};

export const factories = {
    adminBooking: createFactory<AdminBooking>({
        currency: 1,
        booker_email(index: number) {
            return `bookerEmail${index}@example.com`;
        },
        campaign_name(index: number) {
            return `campaign name ${index}`;
        },
        campaign_type() {
            return 'AUDIO';
        },
        client_name(index: number) {
            return `client name ${index}`;
        },
        created() {
            return new Date().toISOString();
        },
        date_approved() {
            return new Date().toISOString();
        },
        finance_reference(index: number) {
            return `finance reference ${index}`;
        },
        influencer_name(index: number) {
            return `influencer name ${index}`;
        },
        payment_terms() {
            return `client_terms`;
        },
        is_invoice_paid: false,
        is_manager_paid: false,
        manager_commission_amount(index: number) {
            return index;
        },
        manager_email(index: number) {
            return `managerEmail${index}@example.com`;
        },
        platform() {
            return 'INSTAGRAM';
        },
        post_url(index: number) {
            return `post url ${index}`;
        },
        price(index: number) {
            return index * 100;
        },
        status() {
            return 'offer_sent';
        },
        tiktok_ad_code(index: number) {
            return `tiktok ad code ${index}`;
        },
        reasons_to_block_approval_to_bill: [],
    }),
};

export function handleRequests(server: AppServer) {
    const getAdminBookingsListUrl = '/api/apex/admin/booking/';
    server.get(getAdminBookingsListUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('adminBooking'), {
            url: getAdminBookingsListUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'adminBooking'),
        });
    });

    server.get('/api/apex/admin/booking/:id/', function (this: RouteHandlerContext, schema, request) {
        const booking = schema.find('adminBooking', request.params.id);
        if (!booking) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return booking;
    });

    server.post('/api/apex/admin/booking/', function (this: RouteHandlerContext, schema, request) {
        return schema.create('adminBooking', JSON.parse(request.requestBody));
    });

    server.patch('/api/apex/admin/booking/:id/', function (this: RouteHandlerContext, schema, request) {
        const booking = schema.find('adminBooking', request.params.id);
        if (!booking) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        booking.update(JSON.parse(request.requestBody));
        return booking;
    });

    server.put('/api/apex/admin/booking/:id/confirm/', function (this: RouteHandlerContext, schema, request) {
        const booking = schema.find('adminBooking', request.params.id);
        if (!booking) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        booking.update({ status: 'confirmed' });
        return new Response(204);
    });

    server.put('/api/apex/admin/booking/:id/decline/', function (this: RouteHandlerContext, schema, request) {
        const booking = schema.find('adminBooking', request.params.id);
        if (!booking) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        booking.update({ status: 'declined' });
        return new Response(204);
    });

    server.put('/api/apex/admin/booking/:id/approve-to-bill/', function (this: RouteHandlerContext, schema, request) {
        const booking = schema.find('adminBooking', request.params.id);
        if (!booking) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        booking.update({ date_approved: new Date().toISOString() });
        return new Response(204);
    });

    server.post('/api/apex/admin/booking/:id/submit-status/', function (this: RouteHandlerContext, schema, request) {
        const booking = schema.find('adminBooking', request.params.id);
        if (!booking) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        booking.update(JSON.parse(request.requestBody));
        return new Response(201);
    });
}
