import { DetailedHTMLProps, HTMLAttributes, LabelHTMLAttributes } from 'react';
import styles from './Label.module.css';
import cn from 'classnames';

type LabelProps = DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;

export const Label = ({ children, ...props }: LabelProps) => (
    <label {...props} className={cn(styles.label, props.className)}>
        {children}
    </label>
);

type SubLabelProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
export const SubLabel = ({ children, ...props }: SubLabelProps) => (
    <span {...props} className={cn(styles.subLabel, props.className)}>
        {children}
    </span>
);
