import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import { ApiResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { UserPlanner } from './plannerUser.types';

export async function getPlannerUsers(
    params: Partial<
        PaginatedRequest & {
            search: string;
            id: string;
        }
    >,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<UserPlanner>, 200>> {
    const response = await fetchWithToken(`/api/users/planner-users/${encodeUrlSearchParams(params)}`, requestInit);

    if (!response.ok) {
        throw new Error('Could not fetch user');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
