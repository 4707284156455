import React, { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import PaginationTable from '../../../../ui/PaginationTable/PaginationTable';
import Image from '../../../../ui/Image/Image';
import Skeleton from '../../../../ui/Skeleton/Skeleton';
import {
    asMoney,
    buildInstagramUserUrl,
    displayOptionalNumericTableValue,
    formatDate,
    formatNumberToKNotation,
    roundTo2Dp,
} from '../../../../helpers';
import Badge from '../../../../ui/Badge/Badge';
import { cpm } from '../../../Advertising/Metrics/Metrics';
import styles from './InstagramInfluencersTable.module.css';
import cn from 'classnames';
import { OrderByParam } from '../../../../Hooks/useReactTableSortToOrderBy';
import { InfluencerPlan, InfluencerUserCampaigns, InstagramInfluencerUserStats } from '@round/api';
import useNonNullContext from '../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../contexts/OptionsContext/OptionsContext';
import AddInstagramInfluencerToCampaign from '../../containers/AddInfluencerToCampaign/AddInstagramInfluencerToCampaign';
import { useCheckUserGroupsAccess } from '../../../Auth/hooks/useCheckUserGroupsAccess';
import { useContentTags } from 'contexts/ContentTagsContext';
import useAbortableEffect from 'Hooks/useAbortableEffect';

export type InstagramInfluencerTableRow = InstagramInfluencerUserStats & {
    image: string | undefined;
    campaigns: InfluencerUserCampaigns | undefined;
    plans: InfluencerPlan[];
};

export const isInstagramInfluencerTableRow = (
    stats: InstagramInfluencerUserStats
): stats is InstagramInfluencerTableRow => {
    return typeof (stats as InstagramInfluencerTableRow).campaigns === 'object';
};

type InstagramInfluencersTableProps = {
    loading: boolean;
    data: InstagramInfluencerTableRow[];
    errorLoadingData: boolean;
    count: number;
    page: number;
    pageSize: number;
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number | undefined) => void;
    orderBy: OrderByParam<InstagramInfluencerTableRow>;
    onOrderByChange: (param: OrderByParam<InstagramInfluencerTableRow>) => void;
    onRowClick: (row: InstagramInfluencerTableRow) => void;
    onInfluencerAddedToCampaign: (influencerUserId: number) => void;
};

const today = new Date();
const thirtyDaysAgo = new Date(new Date().setDate(today.getDate() - 30));

const InstagramInfluencersTable = ({
    onPageChange,
    onPageSizeChange,
    pageSize,
    page,
    data,
    errorLoadingData,
    loading,
    count,
    orderBy,
    onOrderByChange,
    onRowClick,
    onInfluencerAddedToCampaign,
}: InstagramInfluencersTableProps) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const { tags: contentTags, init, isInitialized } = useContentTags();

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                init({ signal });
            }
        },
        [isInitialized, init]
    );
    const hasInfluencerEditorPermissions = useCheckUserGroupsAccess(['influencer_editor']);

    const columns = useMemo<Column<InstagramInfluencerTableRow>[]>(
        () => [
            {
                Header: 'Account',
                accessor: 'name',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    const createdThisMonth = new Date(original.created).getTime() > thirtyDaysAgo.getTime();
                    return (
                        <div className={styles.account}>
                            <Image
                                className={styles.accountImage}
                                src={original.image}
                                alt="Influencer"
                                loading={loading}
                            />
                            <div className={styles.accountDetails}>
                                <div className={styles.accountNameRow}>
                                    <p className={styles.accountName}>{loading ? <Skeleton /> : original.name}</p>
                                    {!loading && createdThisMonth && <Badge label="New" className={styles.badge} />}
                                </div>
                                <p>
                                    {loading ? (
                                        <Skeleton />
                                    ) : (
                                        <a
                                            className={styles.accountUsername}
                                            onClick={(e) => e.stopPropagation()}
                                            href={buildInstagramUserUrl(original.username)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            @{original.username}
                                        </a>
                                    )}
                                </p>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Follower count',
                accessor: 'follower_count',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    if (typeof value !== 'number') {
                        return <>-</>;
                    }

                    return <>{formatNumberToKNotation(value)}</>;
                },
            },
            {
                Header: 'Tags',
                id: 'content_tags',
                disableSortBy: true,
                Cell: ({ row: { original } }: CellProps<InstagramInfluencerTableRow>) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.tagsContainer}>
                            {contentTags
                                .filter((t) => original.content_tags.includes(t.id))
                                .map((tag) => (
                                    <Badge
                                        key={tag.name}
                                        label={tag.name}
                                        className={cn(styles.tag, styles.categoryTag)}
                                    />
                                ))}
                        </div>
                    );
                },
            },
            {
                Header: 'Location',
                id: 'location',
                disableSortBy: true,
                Cell: ({ row: { original } }: CellProps<InstagramInfluencerTableRow>) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.tagsContainer}>
                            {original.tags
                                .filter((tag) => tag.type === 'LOCATION')
                                .map((tag) => (
                                    <Badge
                                        key={tag.name}
                                        label={tag.name}
                                        className={cn(styles.tag, styles.locationTag)}
                                    />
                                ))}
                        </div>
                    );
                },
            },
            {
                Header: 'Engagement rate',
                accessor: 'avg_engagement_rate',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{typeof value === 'number' ? `${roundTo2Dp(value * 100)}%` : '-'}</>;
                },
            },
            {
                Header: 'Cost',
                accessor: 'reels_cost',
                Cell: ({ row: { original } }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <>
                            {typeof original.reels_cost === 'number' && original.reels_currency
                                ? asMoney(
                                      original.reels_cost,
                                      currencies.find((curr) => curr.id === original.reels_currency)
                                  )
                                : '-'}
                        </>
                    );
                },
            },
            {
                Header: 'Avg. video plays',
                accessor: 'avg_video_plays_per_post',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{displayOptionalNumericTableValue(value)}</>;
                },
            },
            {
                Header: 'Est. CPM',
                id: 'cpm',
                disableSortBy: true,
                Cell: ({ row: { original } }: CellProps<InstagramInfluencerTableRow>) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <>
                            {typeof original.reels_cost === 'number' &&
                            typeof original.avg_video_plays_per_post === 'number' &&
                            original.reels_currency
                                ? asMoney(
                                      cpm(original.reels_cost, original.avg_video_plays_per_post),
                                      currencies.find((curr) => curr.id === original.reels_currency)
                                  )
                                : '-'}
                        </>
                    );
                },
            },
            {
                Header: 'Last used',
                accessor: 'last_used',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <>
                            {typeof value === 'string'
                                ? formatDate(value, { month: 'short', year: '2-digit', day: '2-digit' })
                                : '-'}
                        </>
                    );
                },
            },
            {
                Header: 'Date Added',
                accessor: 'created',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <>
                            {typeof value === 'string'
                                ? formatDate(value, { month: 'short', year: '2-digit', day: '2-digit' })
                                : '-'}
                        </>
                    );
                },
            },
            {
                Header: 'Contact',
                accessor: 'contact_details',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value || '-'}</>;
                },
            },
            {
                Header: 'Add to campaign',
                accessor: 'campaigns',
                disableSortBy: true,
                Cell: ({ value, row: { original } }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    const numberOfActiveCampaigns = value?.plans.filter((p) => p.status === 'live').length ?? 0;
                    return (
                        <AddInstagramInfluencerToCampaign
                            readonly={!hasInfluencerEditorPermissions}
                            influencerCampaigns={value}
                            plans={original.plans}
                            influencerUserStats={original}
                            onInfluencerAddedToCampaign={(post) => {
                                if (typeof post.influencer_id === 'number') {
                                    onInfluencerAddedToCampaign(post.influencer_id);
                                }
                            }}
                        >
                            {numberOfActiveCampaigns} active campaign{numberOfActiveCampaigns !== 1 ? 's' : ''}
                        </AddInstagramInfluencerToCampaign>
                    );
                },
            },
        ],
        [currencies, contentTags, loading, onInfluencerAddedToCampaign, hasInfluencerEditorPermissions]
    );

    return (
        <PaginationTable
            manualSortBy
            disableSortBy={false}
            tableClassName={styles.table}
            loading={loading}
            data={data}
            columns={columns}
            count={count}
            page={page}
            setPage={onPageChange}
            pageSize={pageSize}
            setPageSize={onPageSizeChange}
            onRowClick={onRowClick}
            orderBy={orderBy}
            onOrderByChange={onOrderByChange}
            noDataLabel={errorLoadingData ? 'Something went wrong' : undefined}
        />
    );
};

export default InstagramInfluencersTable;
