import React from 'react';
import cn from 'classnames';
import styles from './AudioPlaybackThumbnail.module.css';
import PauseIcon from '../../../SharedComponents/svg/Icons/PauseIcon';
import PlayIcon from '../../../SharedComponents/svg/Icons/PlayIcon';
import LoadingSpinner from '../../../SharedComponents/LoadingSpinner/LoadingSpinner';
import useNonNullContext from '../../../Hooks/useNonNullContext';
import { AudioPlayerContext } from '../AudioPlayerContext';
import { Image } from '@round/ui-kit';

type AudioPlaybackThumbnailProps = {
    loading?: boolean;
    imageUrl?: string;
    audioUrl?: string;
    className?: string;
};

const AudioPlaybackThumbnail = ({ audioUrl, imageUrl, className }: AudioPlaybackThumbnailProps) => {
    const { playing, togglePlayPause, audio, load, loading } = useNonNullContext(AudioPlayerContext);
    const currentSrc = audio?.src;
    const isSelectedAudio = audioUrl && audioUrl === currentSrc;

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (!audioUrl) {
            return;
        }

        if (currentSrc !== audioUrl) {
            load({
                src: audioUrl,
                autoplay: true,
            });
            return;
        }

        togglePlayPause();
    };

    const renderOverlay = () => {
        if (isSelectedAudio && loading) {
            return <LoadingSpinner containerClassName={styles.spinner} />;
        }

        return isSelectedAudio && playing ? <PauseIcon width={14} height={14} /> : <PlayIcon width={14} height={14} />;
    };

    return (
        <div className={cn(styles.container, className)} onClick={handleClick}>
            <Image className={styles.image} src={imageUrl} alt="Audio" loading={loading} />
            {audioUrl && !loading && (
                <div className={cn(styles.overlay, { [styles.hidden]: !isSelectedAudio && (!loading || !playing) })}>
                    {renderOverlay()}
                </div>
            )}
        </div>
    );
};

export default AudioPlaybackThumbnail;
