import React, { useCallback, useMemo, useState } from 'react';
import { Creative } from '../../../../../../App.types';
import { Formik, FormikHelpers } from 'formik';
import { CreativeData } from '../../../types/Creative.types';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import CreativeForm from '../CreativeForm/CreativeForm';
import Button from '../../../../../../ui/Buttons/Button/Button';
import { postCreative } from '../../../api/MediaPlanCreatives.api';
import { showNotification } from '../../../../../../helpers';
import styles from './CreateCreativeModal.module.css';

type CreateCreativeModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    onCreated: (creative: Creative) => void;
    mediaPlanItemId: number;
};

const CreateCreativeModal = ({ isModalOpen, closeModal, onCreated, mediaPlanItemId }: CreateCreativeModalProps) => {
    const [hasError, setHasError] = useState(false);
    const initialValues: CreativeData = useMemo(
        () => ({
            name: '',
            copy: '',
            notes: '',
            media_plan_item: mediaPlanItemId,
            creative_url: '',
            ad_url: '',
            cta_text: '',
            description: '',
            headline: '',
        }),
        [mediaPlanItemId]
    );

    const handleSubmit = useCallback(
        async (values: CreativeData, { setSubmitting }: FormikHelpers<CreativeData>) => {
            try {
                setSubmitting(true);
                const creative = await postCreative(values);
                showNotification('Asset created', 'info');
                onCreated(creative);
                closeModal();
            } catch {
                setHasError(true);
            } finally {
                setSubmitting(false);
            }
        },
        [closeModal, onCreated]
    );

    return (
        <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={closeModal}>
            <ModalTitle>Add Asset</ModalTitle>

            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ isSubmitting, submitForm }) => (
                    <>
                        <ModalContent>
                            {hasError && <div>Error creating assets</div>}
                            <CreativeForm />
                        </ModalContent>
                        <ModalFooter>
                            <Button
                                className={styles.submit}
                                type="filled"
                                color="black"
                                loading={isSubmitting}
                                onClick={() => submitForm()}
                            >
                                Create asset
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default CreateCreativeModal;
