import { useCallback, useMemo, useState } from 'react';
import {
    getXeroInvoices,
    TiktokInfluencerUser,
    TiktokUserImage,
    XeroInvoice,
    fetchTiktokUserStats,
    TiktokUserStats,
    getMediaPlans,
    MediaPlan,
    InstagramInfluencerUser,
    TiktokInfluencerPost,
} from '@round/api';
import { InfluencerPlan, InstagramUserImage, MediaPlanItem } from '../../../App.types';
import { InstagramInfluencerPost } from '../../Advertising/InfluencerPlan/types/Instagram.types';
import { getAllMediaPlanItems } from '../../Advertising/MediaPlan/api/MediaPlanItems.api';
import { InstagramUserStats } from '../../Instagram/Instagram.types';
import {
    fetchInstagramUserImages,
    fetchInstagramUserStats,
    fetchTiktokUserImages,
    getInfluencerPlanExchangeRatetoTargetCurrency,
    getInfluencerPlans,
    getInfluencerPostsByXeroInvoiceIds,
} from '../../Advertising/InfluencerPlan/InfluencerPlan.api';
import { debounce, uniq } from 'lodash';
import { getMediaPlanExchangeRatetoTargetCurrency } from '../../Advertising/MediaPlan/api/MediaPlan.api';
import { isNumber } from '../../../utility/utility';
import useAbortableEffect from '../../../Hooks/useAbortableEffect';
import { showNotification } from '../../../helpers';
import { getTiktokInfluencerUsers } from '../../TikTok/TikTok.api';
import { getInstagramInfluencerUsers } from '../../Instagram/Instagram.api';

export type PlanToInvoiceCurrencyExchangeRate = {
    plan_id: number;
    invoice_id: number;
    plan_type: 'creator' | 'advertising';
    exchange_rate: string;
};

type UseInvoiceDataResult = {
    invoices: XeroInvoice[];
    invoicesCount: number;
    hasNextInvoicesPage: boolean;
    hasPreviousInvoicesPage: boolean;
    invoiceDataLoading: boolean;
    errorLoadingInvoiceData: boolean;
    mediaPlanItems: MediaPlanItem[];
    tiktokInfluencerPosts: TiktokInfluencerPost[];
    instagramInfluencerPosts: InstagramInfluencerPost[];
    influencerPlans: InfluencerPlan[];
    mediaPlans: MediaPlan[];
    exchangeRates: PlanToInvoiceCurrencyExchangeRate[];
    invoiceIdsWithExchangeRateLoadingError: number[];
    tiktokInfluencerUsers: TiktokInfluencerUser[];
    tiktokUserStats: TiktokUserStats[];
    tiktokUserImages: TiktokUserImage[];
    instagramInfluencerUsers: InstagramInfluencerUser[];
    instagramUserStats: InstagramUserStats[];
    instagramUserImages: InstagramUserImage[];
};

export function useInvoiceData({
    exclude_if_no_plan_items,
    search,
    brand_id,
    client_id,
    page_size,
    page,
    start_date,
    end_date,
}: Parameters<typeof getXeroInvoices>[0]): UseInvoiceDataResult {
    const [invoices, setInvoices] = useState<XeroInvoice[]>([]);
    const [invoicesCount, setInvoicesCount] = useState(0);
    const [hasNextInvoicesPage, setHasNextInvoicesPage] = useState(false);
    const [hasPreviousInvoicesPage, setPreviousInvoicesPage] = useState(false);
    const [invoiceDataLoading, setInvoiceDataLoading] = useState(false);
    const [errorLoadingInvoiceData, setErrorLoadingInvoiceData] = useState(false);

    const [mediaPlanItems, setMediaPlanItems] = useState<MediaPlanItem[]>([]);
    const [tiktokInfluencerPosts, setTiktokInfluencerPosts] = useState<TiktokInfluencerPost[]>([]);
    const [instagramInfluencerPosts, setInstagramInfluencerPosts] = useState<InstagramInfluencerPost[]>([]);
    const [influencerPlans, setInfluencerPlans] = useState<InfluencerPlan[]>([]);
    const [mediaPlans, setMediaPlans] = useState<MediaPlan[]>([]);
    const [exchangeRates, setExchangeRates] = useState<PlanToInvoiceCurrencyExchangeRate[]>([]);
    const [invoiceIdsWithExchangeRateLoadingError, setInvoiceIdsWithExchangeRateLoadingError] = useState<number[]>([]);

    const [tiktokInfluencerUsers, setTiktokInfluencerUsers] = useState<TiktokInfluencerUser[]>([]);
    const [tiktokUserStats, setTiktokUserStats] = useState<TiktokUserStats[]>([]);
    const [tiktokUserImages, setTiktokUserImages] = useState<TiktokUserImage[]>([]);
    const [instagramInfluencerUsers, setInstagramInfluencerUsers] = useState<InstagramInfluencerUser[]>([]);
    const [instagramUserStats, setInstagramUserStats] = useState<InstagramUserStats[]>([]);
    const [instagramUserImages, setInstagramUserImages] = useState<InstagramUserImage[]>([]);

    const fetchInvoiceData = useCallback(async (...args: Parameters<typeof getXeroInvoices>) => {
        const [params, requestInit] = args;
        try {
            setErrorLoadingInvoiceData(false);
            setInvoiceDataLoading(true);
            const {
                data: { next, previous, results: invoices, count },
            } = await getXeroInvoices(params, requestInit);

            if (!invoices.length) {
                setInvoices([]);
                setInvoicesCount(count);
                setHasNextInvoicesPage(!!next);
                setPreviousInvoicesPage(!!previous);
                return;
            }

            const invoiceIds = invoices.map((inv) => inv.id);
            const [mediaPlanItems, tiktokInfluencerPosts, instagramInfluencerPosts] = await Promise.all([
                getAllMediaPlanItems({ xero_invoice_id: invoiceIds.join(',') }, requestInit),
                getInfluencerPostsByXeroInvoiceIds('tiktok', invoiceIds, requestInit),
                getInfluencerPostsByXeroInvoiceIds('instagram', invoiceIds, requestInit),
            ]);

            const mediaPlanIds = uniq(mediaPlanItems.map((item) => item.media_plan));
            const influencerPlanIds = uniq([
                ...tiktokInfluencerPosts.map((p) => p.plan_id),
                ...instagramInfluencerPosts.map((p) => p.plan_id),
            ]);

            const [influencerPlans, mediaPlans] = await Promise.all([
                withPreventEmptyParamsCall((ids, requestInit) =>
                    getInfluencerPlans(
                        {
                            id: ids.toString(),
                            page_size: ids.length,
                        },
                        requestInit
                    ).then((response) => response.results)
                )(influencerPlanIds, requestInit),
                withPreventEmptyParamsCall((ids, requestInit) =>
                    getMediaPlans({ id: ids.join(',') }, requestInit).then((response) => {
                        if (response.status === 200) {
                            return response.data.results;
                        }

                        return [];
                    })
                )(mediaPlanIds, requestInit),
            ]);

            const exchangeRates = await Promise.all(
                invoices.reduce((exchangeRates, invoice) => {
                    const mediaPlanIdsWithNonInvoiceCurrency = uniq(
                        mediaPlanItems
                            .filter((item) => {
                                const mediaPlan = mediaPlans.find((plan) => plan.id === item.media_plan);
                                return (
                                    item.xero_invoice_id === invoice.id &&
                                    mediaPlan &&
                                    mediaPlan.currency.name !== invoice.currency_code
                                );
                            })
                            .map((item) => item.media_plan)
                    );

                    const invoiceTiktokInfluencerPostsPlanIds = tiktokInfluencerPosts
                        .filter((p) => p.xero_invoice_id === invoice.id)
                        .map((p) => p.plan_id);

                    const invoiceInstagramInfluencerPostsPlanIds = instagramInfluencerPosts
                        .filter((p) => p.xero_invoice_id === invoice.id)
                        .map((p) => p.plan_id);

                    const invoiceInfluencerPlanIds = uniq([
                        ...invoiceTiktokInfluencerPostsPlanIds,
                        ...invoiceInstagramInfluencerPostsPlanIds,
                    ]);

                    const influencerPlanIdsWithNonInvoiceCurrency = influencerPlans
                        .filter((plan) => invoiceInfluencerPlanIds.includes(plan.id))
                        .filter((plan) => plan.currency.name !== invoice.currency_code)
                        .map((plan) => plan.id);

                    const mediaPlanExchangeRateRequests = mediaPlanIdsWithNonInvoiceCurrency.map(
                        getCurrencyLoader(invoice, 'advertising', getMediaPlanExchangeRatetoTargetCurrency, requestInit)
                    );

                    const influencerPlanExchangeRateRequests = influencerPlanIdsWithNonInvoiceCurrency.map(
                        getCurrencyLoader(
                            invoice,
                            'creator',
                            getInfluencerPlanExchangeRatetoTargetCurrency,
                            requestInit
                        )
                    );

                    return exchangeRates.concat(mediaPlanExchangeRateRequests, influencerPlanExchangeRateRequests);
                }, [] as Promise<PlanToInvoiceCurrencyExchangeRate | number>[])
            );

            setInvoices(invoices);
            setInvoicesCount(count);
            setHasNextInvoicesPage(Boolean(next));
            setPreviousInvoicesPage(Boolean(previous));
            setMediaPlanItems(mediaPlanItems);
            setTiktokInfluencerPosts(tiktokInfluencerPosts);
            setInstagramInfluencerPosts(instagramInfluencerPosts);
            setInfluencerPlans(influencerPlans);
            setMediaPlans(mediaPlans);
            setExchangeRates(
                exchangeRates.filter((rate): rate is PlanToInvoiceCurrencyExchangeRate => typeof rate !== 'number')
            );
            setInvoiceIdsWithExchangeRateLoadingError(exchangeRates.filter(isNumber));
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }

            setErrorLoadingInvoiceData(true);
        } finally {
            setInvoiceDataLoading(false);
        }
    }, []);

    const debouncedFetchInvoiceData = useMemo(() => debounce(fetchInvoiceData, 700), [fetchInvoiceData]);

    useAbortableEffect(
        (signal) => {
            debouncedFetchInvoiceData(
                {
                    exclude_if_no_plan_items,
                    search,
                    brand_id,
                    client_id,
                    page_size,
                    page,
                    start_date,
                    end_date,
                },
                { signal }
            );
        },
        [
            brand_id,
            client_id,
            debouncedFetchInvoiceData,
            end_date,
            exclude_if_no_plan_items,
            fetchInvoiceData,
            page,
            page_size,
            search,
            start_date,
        ]
    );

    const fetchCreatorsData = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                const tiktokInfluencerIds = uniq(tiktokInfluencerPosts.map((p) => p.influencer_id).filter(isNumber));
                const instagramInfluencerIds = uniq(
                    instagramInfluencerPosts.map((p) => p.influencer_id).filter(isNumber)
                );

                const [tiktokInfluencerUsers, instagramInfluencerUsers] = await Promise.all([
                    withPreventEmptyParamsCall((ids, requestInit) =>
                        getTiktokInfluencerUsers({ id: ids.toString(), page_size: ids.length }, requestInit).then(
                            (response) => response.data.results
                        )
                    )(tiktokInfluencerIds, requestInit),
                    withPreventEmptyParamsCall((ids, requestInit) =>
                        getInstagramInfluencerUsers({ id: ids.toString(), page_size: ids.length }, requestInit).then(
                            (response) => response.data.results
                        )
                    )(instagramInfluencerIds, requestInit),
                ]);

                const tiktokUserIds = uniq(
                    tiktokInfluencerPosts
                        .map((p) => p.tiktok_user)
                        .concat(tiktokInfluencerUsers.map((influencer) => influencer.user))
                        .filter(isNumber)
                );

                const instagramUserIds = uniq(
                    instagramInfluencerPosts
                        .map((p) => p.instagram_user)
                        .concat(instagramInfluencerUsers.map((influencer) => influencer.user))
                        .filter(isNumber)
                );

                const [tiktokUserStats, tiktokUserImages, instagramUserStats, instagramUserImages] = await Promise.all([
                    withPreventEmptyParamsCall((ids, requestInit) =>
                        fetchTiktokUserStats(
                            { user_ids: tiktokUserIds, latest: true, page_size: tiktokUserIds.length },
                            requestInit
                        ).then((response) => (response.status === 200 ? response.data.results : []))
                    )(tiktokUserIds, requestInit),
                    withPreventEmptyParamsCall(fetchTiktokUserImages)(tiktokUserIds, requestInit),
                    withPreventEmptyParamsCall(fetchInstagramUserStats)(instagramUserIds, requestInit),
                    withPreventEmptyParamsCall(fetchInstagramUserImages)(instagramUserIds, requestInit),
                ]);

                setTiktokInfluencerUsers(tiktokInfluencerUsers);
                setTiktokUserStats(tiktokUserStats);
                setTiktokUserImages(tiktokUserImages);
                setInstagramInfluencerUsers(instagramInfluencerUsers);
                setInstagramUserStats(instagramUserStats);
                setInstagramUserImages(instagramUserImages);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                showNotification('Could not fetch creators data', 'error');
            }
        },
        [instagramInfluencerPosts, tiktokInfluencerPosts]
    );

    useAbortableEffect(
        (signal) => {
            fetchCreatorsData({ signal });
        },
        [fetchCreatorsData]
    );

    return {
        invoices,
        invoicesCount,
        hasNextInvoicesPage,
        hasPreviousInvoicesPage,
        errorLoadingInvoiceData,
        invoiceDataLoading,
        mediaPlanItems,
        tiktokInfluencerPosts,
        instagramInfluencerPosts,
        exchangeRates,
        influencerPlans,
        instagramInfluencerUsers,
        instagramUserImages,
        instagramUserStats,
        invoiceIdsWithExchangeRateLoadingError,
        tiktokInfluencerUsers,
        tiktokUserImages,
        tiktokUserStats,
        mediaPlans,
    };
}

function withPreventEmptyParamsCall<T>(loader: (ids: number[], requestInit?: RequestInit) => Promise<T[]>) {
    return async (...args: Parameters<typeof loader>) => {
        const ids = args[0];
        if (!ids.length) {
            return [];
        }

        return loader(...args);
    };
}

function getCurrencyLoader(
    invoice: XeroInvoice,
    planType: 'creator' | 'advertising',
    loader: typeof getInfluencerPlanExchangeRatetoTargetCurrency,
    requestInit?: RequestInit
) {
    return async (planId: number): Promise<PlanToInvoiceCurrencyExchangeRate | number> => {
        try {
            return {
                invoice_id: invoice.id,
                plan_id: planId,
                plan_type: planType,
                exchange_rate: (await loader(planId, invoice.currency_code, requestInit)).exchange_rate_multiplier,
            };
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            return invoice.id;
        }
    };
}
