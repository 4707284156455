export const getPostCountDisplayValue = (posts: number | null | undefined, target: number | null | undefined) => {
    if (!posts && !target) {
        return '0 posts';
    }
    const countDisplayValue = posts && target ? `${posts}/${target}` : `${posts}`;
    const countIsPlural = target ? target !== 1 : posts !== 1;

    return `${countDisplayValue} post${countIsPlural ? 's' : ''} live`;
};

export const getTotalValue = <T, K extends keyof T>(posts: T[], property: K) => {
    if (posts.every((item) => item[property] === null)) {
        return null;
    }

    return posts.reduce((acc, item) => {
        const currentValue = (item[property] as unknown) as number | null;
        return acc + (currentValue ?? 0);
    }, 0);
};
