import { creatorbase } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

type AudioTimeSeriesData = DataState<creatorbase.TimeSeriesResponse<'posts_daily_change'>[number]>;

type AudioTimeSeriesContextData = {
    [audioId: number]: AudioTimeSeriesData | undefined;
};

type AudioTimeSeriesContextState = {
    isInitialized: boolean;
    data: AudioTimeSeriesContextData;
};

type AudioTimeSeriesContextActions =
    | ReducerActionWithPayload<'audioTimeSeriesDataLoading', { ids: number[] }>
    | ReducerActionWithPayload<
          'audioTimeSeriesDataSuccess',
          { ids: number[]; data: creatorbase.TimeSeriesResponse<'posts_daily_change'> }
      >
    | ReducerActionWithPayload<'audioTimeSeriesDataError', { ids: number[]; error: string }>
    | ReducerActionWithPayload<'audioTimeSeriesDataIdle', { ids: number[] }>
    | ReducerAction<'audioTimeSeriesDataInitialized'>
    | ReducerAction<'resetAudioTimeSeriesData'>;

export const initialState: AudioTimeSeriesContextState = {
    isInitialized: false,
    data: {},
};

export const reducer = createReducer<AudioTimeSeriesContextState, AudioTimeSeriesContextActions>({
    audioTimeSeriesDataLoading: (state, { payload: { ids } }) => {
        const incomingAudioTimeSeriesDataAsLoading = ids.reduce((acc, audioId) => {
            acc[audioId] = { status: 'loading', data: null, error: null };
            return acc;
        }, {} as AudioTimeSeriesContextData);

        return { ...state, data: { ...state.data, ...incomingAudioTimeSeriesDataAsLoading } };
    },
    audioTimeSeriesDataSuccess: (state, { payload: { ids, data } }) => {
        const incomingAudioTimeSeriesDataAsSuccess = ids.reduce((acc, audioId) => {
            acc[audioId] = {
                status: 'success',
                data: data[audioId],
                error: null,
            };
            return acc;
        }, {} as AudioTimeSeriesContextData);

        return { ...state, data: { ...state.data, ...incomingAudioTimeSeriesDataAsSuccess } };
    },
    audioTimeSeriesDataError: (state, { payload: { ids, error } }) => {
        const incomingAudioTimeSeriesDataAsError = ids.reduce((acc, audioId) => {
            acc[audioId] = { status: 'error', data: null, error };
            return acc;
        }, {} as AudioTimeSeriesContextData);

        return { ...state, data: { ...state.data, ...incomingAudioTimeSeriesDataAsError } };
    },
    audioTimeSeriesDataIdle: (state, { payload: { ids } }) => {
        const incomingAudioTimeSeriesDataAsIdle = ids.reduce((acc, audioId) => {
            acc[audioId] = { status: 'idle', data: null, error: null };
            return acc;
        }, {} as AudioTimeSeriesContextData);

        return { ...state, data: { ...state.data, ...incomingAudioTimeSeriesDataAsIdle } };
    },
    audioTimeSeriesDataInitialized: (state) => ({ ...state, isInitialized: true }),
    resetAudioTimeSeriesData: () => initialState,
});
