import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import { ApiResponse, ApiResponseError, PaginatedApiResponseData, PaginatedFetchParams } from '../../types';
import { TikTokInfluencerUserStats, TiktokInfluencerUser, TiktokInfluencerUserApiBody } from './influencerUser.types';

export type GetTiktokInfluencerUserStatsParams = PaginatedFetchParams &
    Partial<{
        min_follower_count: number;
        max_follower_count: number;
        min_client_cost: number;
        max_client_cost: number;
        min_avg_video_plays_per_post: number;
        max_avg_video_plays_per_post: number;
        location: string;
        search: string;
        only_apex: boolean;
        content_tags: number[][];
    }>;

export async function getTiktokInfluencerUser(id: number, requestInit?: RequestInit): Promise<TiktokInfluencerUser> {
    const response = await fetchWithToken(`/api/tiktok/viewsets/influencer-user/${id}/`, requestInit);
    if (!response.ok) {
        throw new Error(`Could not fetch tiktok influencer user: ${id}`);
    }

    return response.json();
}

type PatchTiktokInfluencerUserResponse =
    | ApiResponse<TiktokInfluencerUser, 200>
    | ApiResponse<ApiResponseError<TiktokInfluencerUserApiBody>, 400>;
export async function patchTiktokInfluencerUser(
    id: number,
    data: Partial<TiktokInfluencerUserApiBody>
): Promise<PatchTiktokInfluencerUserResponse> {
    const response = await fetchWithToken(`/api/tiktok/viewsets/influencer-user/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not PATCH tiktok influencer user: ${id}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getTiktokInfluencerUserStatsList(
    { nextUrl, ...params }: GetTiktokInfluencerUserStatsParams,
    requestInit?: RequestInit
): Promise<PaginatedApiResponseData<TikTokInfluencerUserStats>> {
    const searchParams = encodeUrlSearchParams({
        ...params,
        tags: JSON.stringify(params.tags),
        content_tags: JSON.stringify(params.content_tags),
    });
    const response = await fetchWithToken(
        nextUrl ?? `/api/tiktok/viewsets/influencer-user-stats/${searchParams}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not get tiktok influencer user stats');
    }

    return response.json();
}
