import React, { useState } from 'react';
import { FacebookCampaignMediaPlanItemRelationship } from '../../../../../../../App.types';
import Card from '../../../../../../../ui/Card/Card';
import styles from './LinkedCampaigns.module.css';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../../../ui/General/Modal/Modal';
import Button from '../../../../../../../ui/Buttons/Button/Button';

type LinkedCampaignsProps = {
    readonly?: boolean;
    campaigns: FacebookCampaignMediaPlanItemRelationship[];
    unlinkCampaign: (campaign: FacebookCampaignMediaPlanItemRelationship) => Promise<void>;
};

const LinkedCampaigns = ({ campaigns, readonly, unlinkCampaign }: LinkedCampaignsProps) => {
    const [campaignToDelete, setCampaignToDelete] = useState<FacebookCampaignMediaPlanItemRelationship>();
    const [campaignDeleteLoading, setCampaignDeleteLoading] = useState(false);

    return (
        <>
            <Card className={styles.linkStatusCampaignContainer}>
                <p className={styles.linkStatusCampaignContainerTitle}>Campaign IDs:</p>
                {campaigns.map((campaign) => (
                    <div className={styles.linkStatusCampaign} key={campaign.campaign_id}>
                        {campaign.campaign_id}
                        {!readonly && (
                            <button
                                className={styles.unlinkCampaignButton}
                                onClick={() => setCampaignToDelete(campaign)}
                            >
                                unlink
                            </button>
                        )}
                    </div>
                ))}
            </Card>

            <Modal
                closeOnOverlayClick
                isOpen={Boolean(campaignToDelete)}
                onClose={() => setCampaignToDelete(undefined)}
            >
                <ModalTitle>Do you want to delete campaign {campaignToDelete?.campaign_id}?</ModalTitle>
                <ModalContent>This operation cannot be undone</ModalContent>
                <ModalFooter className={styles.modalFooter}>
                    <Button onClick={() => setCampaignToDelete(undefined)}>Cancel</Button>
                    <Button
                        type="filled"
                        color="black"
                        loading={campaignDeleteLoading}
                        onClick={async () => {
                            if (!campaignToDelete) {
                                return;
                            }

                            try {
                                setCampaignDeleteLoading(true);
                                await unlinkCampaign(campaignToDelete);
                                setCampaignToDelete(undefined);
                            } finally {
                                setCampaignDeleteLoading(false);
                            }
                        }}
                    >
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default LinkedCampaigns;
