import { patchTikTokAudio } from './TikTok.api';

export async function updateTikTokAudioAlternateName(musicId: number, alternateName: string) {
    try {
        return await patchTikTokAudio(musicId, { alternate_name: alternateName });
    } catch (e) {
        if (e instanceof Error && e.name === 'AbortError') {
            throw e;
        }

        throw new Error('Could not set custom name');
    }
}
