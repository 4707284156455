import React, { useMemo, useState } from 'react';
import { PaginatedFetchFunction, usePaginatedFetch, InferPaginatedFetchFunctionModel } from '@round/api';
import { GenericDropdownOption } from '../../../App.types';
import { Select } from '@round/ui-kit';
import debounce from 'lodash/debounce';
import { StylesConfig, ValueType } from 'react-select';
import useAbortableEffect from '../../../Hooks/useAbortableEffect';
import { SelectComponentsConfig } from 'react-select/src/components';
import { Option } from 'react-select/src/filters';

type PaginatedSelectProps<
    FetchFunction extends PaginatedFetchFunction<any, any>,
    Multi extends boolean,
    OptionType extends GenericDropdownOption<number>
> = {
    fetchOptions: FetchFunction;
    mapToOption: (item: InferPaginatedFetchFunctionModel<FetchFunction>) => OptionType;
    value: ValueType<OptionType, Multi>;
    onChange: (value: ValueType<OptionType, Multi>) => void;
    isMulti?: Multi;
    isClearable?: boolean;
    styles?: StylesConfig;
    menuPortalTarget?: HTMLElement | null | undefined;
    components?: SelectComponentsConfig<any, Multi>;
    placeholder?: string;
    className?: string;
    filterOption?: ((option: Option, rawInput: string) => boolean) | null | undefined;
    closeMenuOnSelect?: boolean;
};

const PaginatedSelect = <
    FetchFunction extends PaginatedFetchFunction<any, any>,
    Multi extends boolean,
    OptionType extends GenericDropdownOption<number>
>({
    fetchOptions,
    mapToOption,
    value,
    onChange,
    isMulti,
    styles,
    menuPortalTarget,
    components,
    isClearable,
    placeholder,
    className,
    filterOption,
    closeMenuOnSelect,
}: PaginatedSelectProps<FetchFunction, Multi, OptionType>) => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const { data, hasNextPage, getCurrentPage, loading } = usePaginatedFetch(fetchOptions, {
        page: page,
        search,
        page_size: 10,
    });

    const options = useMemo(() => data.map(mapToOption), [data, mapToOption]);
    const fetchDebounced = useMemo(
        () =>
            debounce((requestInit?: RequestInit) => {
                getCurrentPage(requestInit).then(() => setSearchLoading(false));
            }, 700),
        [getCurrentPage]
    );

    useAbortableEffect(
        (signal) => {
            fetchDebounced({ signal });
        },
        [fetchDebounced]
    );

    return (
        <Select
            isSearchable
            className={className}
            isMulti={isMulti}
            isClearable={isClearable}
            styles={styles}
            isLoading={loading || searchLoading}
            inputValue={search}
            onInputChange={(searchValue) => {
                if (searchValue === search) {
                    return;
                }
                setSearchLoading(true);
                setSearch(searchValue);
                setPage(1);
            }}
            options={options}
            value={value}
            onChange={onChange}
            onMenuScrollToBottom={() => {
                if (hasNextPage) {
                    setPage((page) => page + 1);
                }
            }}
            menuPortalTarget={menuPortalTarget}
            components={components}
            placeholder={placeholder ?? undefined}
            filterOption={filterOption}
            closeMenuOnSelect={closeMenuOnSelect || !isMulti}
        />
    );
};

export default PaginatedSelect;
