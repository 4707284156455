import { microwave, UserPlanner } from '@round/api';
import styles from './ExistingCampaigns.module.css';
import LoadingSpinner from 'SharedComponents/LoadingSpinner/LoadingSpinner';
import { ReactComponent as DocumentIcon } from 'assets/DocumentText.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/Warning.svg';
import usePlannerUsers from 'Modules/Auth/hooks/usePlannerUsers';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import ExistingCampaignsTable from './table/ExistingCampaignsTable';
import { useMemo } from 'react';
import { MICROWAVE_PLATFORMS } from 'Modules/Advertising/Microwave/helpers';
import uniq from 'lodash/uniq';

type ExistingCampaign = microwave.Campaign & { planners?: UserPlanner[] };

type Props = {
    campaigns: ExistingCampaign[];
    isLoading?: boolean;
};

const ExistingCampaigns = ({ campaigns, isLoading }: Props) => {
    const isMaxCampaignsCreated = uniq(campaigns.map((c) => c.platform)).length >= MICROWAVE_PLATFORMS.length;

    const plannerIdsToFetch = useMemo(
        () => campaigns.filter((campaign) => !campaign.planners).flatMap((campaign) => campaign.team_members),
        [campaigns]
    );

    const { fetchData: fetchPlanners, ...plannersData } = usePlannerUsers();

    useAbortableEffect(
        (signal) => {
            if (plannerIdsToFetch.length === 0) {
                return;
            }
            fetchPlanners({ id: plannerIdsToFetch.join(','), page_size: plannerIdsToFetch.length }, { signal });
        },
        [fetchPlanners, plannerIdsToFetch]
    );

    const rows = campaigns.map((campaign) => {
        return {
            ...campaign,
            planners:
                campaign.planners ??
                plannersData.planners.filter((planner) => campaign.team_members?.includes(planner.id)),
        };
    });

    if (isLoading) {
        return (
            <div className={styles.messageContainer}>
                <LoadingSpinner containerClassName={styles.spinnerContainer} spinnerClassName={styles.spinner} />
                <span>Checking existing campaigns</span>
            </div>
        );
    }

    if (!campaigns.length) {
        return (
            <div className={styles.messageContainer}>
                <DocumentIcon className={styles.icon} />
                <span>No other campaigns have been created for this release yet</span>
            </div>
        );
    }

    return (
        <>
            {isMaxCampaignsCreated && (
                <div className={styles.warningContainer}>
                    <WarningIcon className={styles.icon} />
                    <span>You cannot create more than one campaign per platform</span>
                </div>
            )}

            <div className={styles.tableContainer}>
                <span>Existing Campaigns</span>

                <ExistingCampaignsTable campaigns={rows} isLoading={plannersData.isLoading} />
            </div>
        </>
    );
};

export default ExistingCampaigns;
