import React from 'react';
import styles from './CostLabel.module.css';
import cn from 'classnames';
import { formatNumberToCommaNotation } from 'helpers';

type Props = {
    cost: string | number;
    currencySymbol: string;
} & React.HTMLAttributes<HTMLSpanElement>;

const CostLabel: React.FC<Props> = ({ cost, currencySymbol, className, ...props }) => {
    const formattedCost = typeof cost === 'number' ? formatNumberToCommaNotation(cost.toFixed(2)) : cost;
    if (!formattedCost) {
        return (
            <span className={cn(styles.container, className)} {...props}>
                -
            </span>
        );
    }
    const [integerPart, decimalPart] = formattedCost.split('.');

    return (
        <span className={cn(styles.container, className)} {...props}>
            <span>{`${currencySymbol}${integerPart}`}</span>
            {decimalPart && (
                <>
                    .<span className={styles.decimal}>{decimalPart}</span>
                </>
            )}
        </span>
    );
};

export default CostLabel;
