import {
    CreateFacebookCampaignAdSetError,
    CreateFacebookCampaignCampaignError,
    CreateFacebookCampaignError,
    CreateFacebookCampaignNonFieldErrors,
    FacebookCampaignError,
    FacebookTargetingDto,
} from '../types/Facebook.types';
import {
    FacebookMediaPlanItemRow,
    FacebookTargeting,
    FacebookTargetingAudienceSelectionDto,
    FacebookTargetingAudienceSelectionOption,
    FacebookTargetingCitySearchResult,
    FacebookTargetingCountryGroupSearchResult,
    FacebookTargetingCountrySearchResult,
    FacebookTargetingLocation,
    FacebookTargetingLocationOptionsWithRadius,
    FacebookTargetingLocationRadius,
    FacebookTargetingLocations,
    FacebookTargetingLocationSearchOption,
    FacebookTargetingLocationSearchResult,
    FacebookTargetingLocationsResponse,
    FacebookTargetingRegionSearchResult,
    FacebookTargetingSegmentSpecs,
    FacebookTargetingZIPSearchResult,
    MediaPlanItem,
} from '../../../../App.types';
import { fetchFacebookApi, formatSnakeCaseToDisplay, isFacebookError } from '../../../../helpers';

export function isFacebookCampaignError(obj: any): obj is FacebookCampaignError {
    return Boolean(
        obj &&
            typeof obj === 'object' &&
            typeof obj.error_user_title === 'string' &&
            typeof obj.error_user_msg === 'string'
    );
}

export function isCreateFacebookCampaignError(obj: any): obj is CreateFacebookCampaignError {
    return (
        isCreateFacebookCampaignAdSetError(obj) ||
        isCreateFacebookCampaignCampaignError(obj) ||
        isCreateFacebookCampaignNonFieldErrors(obj)
    );
}

export function isCreateFacebookCampaignCampaignError(obj: any): obj is CreateFacebookCampaignCampaignError {
    return Boolean(obj && typeof obj === 'object' && isFacebookCampaignError(obj.campaign));
}

export function isCreateFacebookCampaignNonFieldErrors(obj: any): obj is CreateFacebookCampaignNonFieldErrors {
    return Boolean(
        obj &&
            typeof obj === 'object' &&
            Array.isArray(obj.non_field_errors) &&
            obj.non_field_errors.every((entry: any) => typeof entry === 'string')
    );
}

export function isCreateFacebookCampaignAdSetError(obj: any): obj is CreateFacebookCampaignAdSetError {
    return Boolean(obj && typeof obj === 'object' && obj.ad_set);
}

export function mapFacebookTargetingToDto(targeting: FacebookTargeting): FacebookTargetingDto {
    return {
        ...targeting,
        custom_audiences: targeting.custom_audiences.map((a) => a.id),
        excluded_custom_audiences: targeting.excluded_custom_audiences.map((a) => a.id),
    };
}

export function isFacebookTargeting(targeting: any): targeting is FacebookTargeting {
    if (typeof targeting !== 'object') {
        return false;
    }

    return (
        typeof targeting.id === 'number' &&
        typeof targeting.geo_locations === 'object' &&
        typeof targeting.excluded_geo_locations === 'object' &&
        typeof targeting.min_age === 'number' &&
        typeof targeting.max_age === 'number' &&
        typeof targeting.is_male === 'boolean' &&
        typeof targeting.is_female === 'boolean' &&
        typeof targeting.inclusions === 'object' &&
        Array.isArray(targeting.flexible_spec) &&
        typeof targeting.exclusions === 'object' &&
        Array.isArray(targeting.device_platforms) &&
        Array.isArray(targeting.publisher_platforms) &&
        Array.isArray(targeting.facebook_positions) &&
        Array.isArray(targeting.instagram_positions) &&
        Array.isArray(targeting.audience_network_positions) &&
        Array.isArray(targeting.messenger_positions) &&
        Array.isArray(targeting.custom_audiences) &&
        Array.isArray(targeting.excluded_custom_audiences) &&
        typeof targeting.estimated_reach === 'object' &&
        typeof targeting.location === 'string' &&
        typeof targeting.remarketing_audience === 'string' &&
        typeof targeting.keywords === 'string' &&
        typeof targeting.notes === 'string' &&
        typeof targeting.name === 'string' &&
        typeof targeting.media_plan_item === 'number'
    );
}

export function isFacebookTargetingTemplate(template: any): template is FacebookTargeting {
    if (typeof template !== 'object') {
        return false;
    }

    return (
        typeof template.id === 'number' &&
        typeof template.template_name === 'string' &&
        typeof template.geo_locations === 'object' &&
        typeof template.excluded_geo_locations === 'object' &&
        typeof template.min_age === 'number' &&
        typeof template.max_age === 'number' &&
        typeof template.is_male === 'boolean' &&
        typeof template.is_female === 'boolean' &&
        typeof template.inclusions === 'object' &&
        Array.isArray(template.flexible_spec) &&
        typeof template.exclusions === 'object' &&
        Array.isArray(template.device_platforms) &&
        Array.isArray(template.publisher_platforms) &&
        Array.isArray(template.facebook_positions) &&
        Array.isArray(template.instagram_positions) &&
        Array.isArray(template.audience_network_positions) &&
        Array.isArray(template.messenger_positions) &&
        Array.isArray(template.custom_audiences) &&
        Array.isArray(template.excluded_custom_audiences) &&
        typeof template.location === 'string' &&
        typeof template.remarketing_audience === 'string' &&
        typeof template.keywords === 'string' &&
        typeof template.notes === 'string' &&
        typeof template.template_name === 'string' &&
        typeof template.client === 'number'
    );
}

function mapSelectionDtoToOption(dto: FacebookTargetingAudienceSelectionDto): FacebookTargetingAudienceSelectionOption {
    return {
        audience_size: dto.audience_size,
        description: dto.description,
        label: dto.name,
        path: dto.path,
        type: dto.type,
        value: dto.id,
        typeLabel: formatSnakeCaseToDisplay(dto.type),
    };
}

export function mapSpecsToOptions(
    specs: FacebookTargetingSegmentSpecs | undefined
): FacebookTargetingAudienceSelectionOption[] {
    if (!specs) {
        return [];
    }

    return Object.values(specs).flat().map(mapSelectionDtoToOption);
}

export const radiusUnitOptions: Array<FacebookTargetingLocationRadius['unit']> = [
    {
        label: 'km',
        value: 'kilometer',
    },
    {
        label: 'ml',
        value: 'mile',
    },
];
export const cityTypes = ['city', 'subcity', 'large_geo_area', 'medium_geo_area', 'small_geo_area', 'neighborhood'];

export function isFacebookTargetingLocationOptionsWithRadius(
    location: FacebookTargetingLocationSearchOption
): location is FacebookTargetingLocationOptionsWithRadius {
    return cityTypes.includes(location.type);
}

export function isFacebookTargetingLocation(l: any): l is FacebookTargetingLocation {
    return (
        l &&
        typeof l !== 'string' &&
        typeof l.key === 'string' &&
        typeof l.name === 'string' &&
        typeof l.type === 'string'
    );
}

export function mapFacebookTargetingLocationsToBody(
    locations: FacebookTargetingLocationSearchOption[]
): FacebookTargetingLocations {
    return {
        country_objects: locations
            .filter((location) => location.type === 'country')
            .map(mapFacebookTargetingLocationOption),
        regions: locations.filter((location) => location.type === 'region').map(mapFacebookTargetingLocationOption),
        cities: locations.filter(isFacebookTargetingLocationOptionsWithRadius).map(mapFacebookTargetingLocationOption),
        zips: locations.filter((location) => location.type === 'zip').map(mapFacebookTargetingLocationOption),
        geo_markets: locations
            .filter((location) => location.type === 'geo_market')
            .map(mapFacebookTargetingLocationOption),
        electoral_districts: locations
            .filter((location) => location.type === 'electoral_district')
            .map(mapFacebookTargetingLocationOption),
        country_groups: locations
            .filter((location) => location.type === 'country_group')
            .map(mapFacebookTargetingLocationOption),
    };
}

function mapFacebookTargetingLocationOption(
    location: FacebookTargetingLocationSearchOption
): FacebookTargetingLocation {
    const mapped: FacebookTargetingLocation = {
        key: location.value,
        type: location.type,
        name: location.name,
        country_code: location.country_code,
    };

    if (location.region) {
        mapped.region = location.region;
    }

    if (isFacebookTargetingLocationOptionsWithRadius(location)) {
        mapped.radius = location.radius.distance;
        mapped.distance_unit = location.radius.unit.value;
    }

    return mapped;
}

export async function mapFacebookTargetingLocationsToOptions(
    locations?: FacebookTargetingLocationsResponse,
    requestInit?: RequestInit
): Promise<FacebookTargetingLocationSearchOption[]> {
    if (!locations) {
        return [];
    }

    const locationsArray = Object.values(locations).flat();

    // locations that have display data
    const locationOptions = locationsArray.filter(isFacebookTargetingLocation).map((location) => {
        const { region = '', country_code = '' } = location;
        const mapped: FacebookTargetingLocationSearchOption = {
            value: location.key,
            type: location.type,
            name: location.name,
            region: region,
            country_code: country_code,
            label: [location.name, region, country_code].filter((value) => !!value).join(', '),
            isIncluded: true,
            typeLabel: formatSnakeCaseToDisplay(location.type),
            radius: undefined,
        };

        if (location.radius && location.distance_unit && isFacebookTargetingLocationOptionsWithRadius(mapped)) {
            mapped.radius = {
                distance: location.radius,
                unit: radiusUnitOptions.find((o) => o.value === 'kilometer')!,
            };
        }

        return mapped;
    });

    // get locations that don't have display data (key only locations)
    const locationsToFetch = Object.keys(locations).reduce(
        (acc, key) => {
            // @ts-ignore
            acc[key] = acc[key].filter((location) => !isFacebookTargetingLocation(location));
            return acc;
        },
        { ...locations }
    );

    let fetched: FacebookTargetingLocationSearchOption[] = [];
    if (shouldFetchLocationsFromFacebook(locationsToFetch)) {
        fetched = await mapLocationsToOptionsFromFacebook(locationsToFetch, requestInit);
    }

    return [...locationOptions, ...fetched];
}

function shouldFetchLocationsFromFacebook(locationsToFetch: FacebookTargetingLocationsResponse): boolean {
    const values = Object.values(locationsToFetch);
    return values.some((locations) => locations?.length);
}

async function mapLocationsToOptionsFromFacebook(
    locations: FacebookTargetingLocationsResponse,
    requestInit?: RequestInit
): Promise<FacebookTargetingLocationSearchOption[]> {
    type Payload = {
        data: {
            countries: Record<string, FacebookTargetingCountrySearchResult>;
            regions: Record<string, FacebookTargetingRegionSearchResult>;
            cities: Record<string, FacebookTargetingCitySearchResult>;
            zips: Record<string, FacebookTargetingZIPSearchResult>;
            country_groups: Record<string, FacebookTargetingCountryGroupSearchResult>;
        };
    };

    const response = await fetchFacebookApi<Payload>(
        '/search',
        {
            type: 'adgeolocationmeta',
            countries: JSON.stringify(locations.country_objects?.map((c) => c.key) ?? []),
            cities: JSON.stringify(locations.cities?.map((city) => city.key) ?? []),
            regions: JSON.stringify(locations.regions?.map((region) => region.key) ?? []),
            zips: JSON.stringify(locations.zips?.map((zip) => zip.key) ?? []),
            country_group: JSON.stringify(locations.zips ?? []),
        },
        requestInit
    );

    if (isFacebookError(response)) {
        throw response.error;
    }

    return Object.values(response.data)
        .map((locations) => Object.values(locations))
        .flat()
        .map(mapLocationSearchResultToOption)
        .map((option) => {
            switch (option.type) {
                case 'country':
                    return { ...option, country_code: option.value };
                case 'region':
                    return { ...option, ...(locations.regions?.find((region) => region.key === option.value) || {}) };
                case 'geo_market':
                    return {
                        ...option,
                        ...(locations.geo_markets?.find((market) => market.key === option.value) || {}),
                    };
                case 'city':
                case 'subcity':
                case 'large_geo_area':
                case 'medium_geo_area':
                case 'small_geo_area':
                case 'neighborhood':
                    const city = locations.cities?.find((city) => city.key === option.value);
                    return city
                        ? {
                              ...option,
                              radius: {
                                  distance: city.radius,
                                  unit: radiusUnitOptions.find((u) => u.value === city.distance_unit)!,
                              },
                          }
                        : option;
                case 'zip':
                    return { ...option, ...(locations.zips?.find((zip) => zip.key === option.value) || {}) };
                case 'electoral_district':
                    return {
                        ...option,
                        ...(locations.electoral_districts?.find((district) => district.key === option.value) || {}),
                    };
                default:
                    return option;
            }
        });
}

// some default values are set, but usually are rewritten afterwards
// (for example mapping already saved locations from backend)
export function mapLocationSearchResultToOption(
    location: FacebookTargetingLocationSearchResult
): FacebookTargetingLocationSearchOption {
    const { region = '', country_code = '' } = location as FacebookTargetingCitySearchResult;
    const label = [location.name, region, country_code].filter((value) => !!value).join(', ');
    const locationOption: FacebookTargetingLocationSearchOption = {
        type: location.type,
        typeLabel: formatSnakeCaseToDisplay(location.type),
        label: label,
        value: location.key,
        isIncluded: true,
        name: location.name,
        country_code,
        region,
    };

    if (isFacebookTargetingLocationOptionsWithRadius(locationOption)) {
        locationOption.radius = {
            distance: 18,
            unit: radiusUnitOptions.find((o) => o.value === 'kilometer')!,
        };
    }

    return locationOption;
}

function mapOptionToSelectionDto(
    option: FacebookTargetingAudienceSelectionOption
): FacebookTargetingAudienceSelectionDto {
    return {
        audience_size: option.audience_size,
        description: option.description,
        id: option.value,
        name: option.label,
        path: option.path,
        type: option.type,
    };
}

export function mapSelectionOptionsToSpecs(
    selections: FacebookTargetingAudienceSelectionOption[]
): Partial<FacebookTargetingSegmentSpecs> | undefined {
    if (!selections.length) {
        return;
    }

    return selections.reduce<Partial<FacebookTargetingSegmentSpecs>>((specs, selection) => {
        const selections = specs[selection.type];
        const mapped = mapOptionToSelectionDto(selection);
        specs[selection.type] = Array.isArray(selections) ? [...selections, mapped] : [mapped];
        return specs;
    }, {});
}

export function isFacebookItemRow(itemRow: MediaPlanItem): itemRow is FacebookMediaPlanItemRow {
    return ['Facebook', 'Instagram', 'FBIG'].includes(itemRow.channel.name);
}
