import { UserGroup } from '../users';
import { AuthTokenPair } from './auth.types';

export function getTokensFromStorage(): AuthTokenPair {
    return {
        access: localStorage.getItem('access'),
        refresh: localStorage.getItem('refresh'),
    };
}

export function setTokensToStorage(tokens: AuthTokenPair) {
    Object.entries(tokens).forEach(([key, value]) => {
        if (typeof value === 'string') {
            localStorage.setItem(key, value);
        }
    });
}

export function clearTokensFromStorage() {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
}

export function getRoundProductGroups(groups: UserGroup[]): UserGroup[] {
    return Array.from(
        new Set<UserGroup>(['round_product', ...groups])
    );
}

export function getTrndsProductGroups(groups: UserGroup[]): UserGroup[] {
    return Array.from(
        new Set<UserGroup>(['trnds_product', ...groups])
    ).filter((group) => group !== 'round_product');
}
