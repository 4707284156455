import useBrandSelect, { BrandOption, mapBrandToOption } from 'Modules/Plans/Brand/hooks/useBrandSelect';
import { Form, useFormikContext } from 'formik';
import { FormField, SelectField } from 'ui-new/whitelabel/formik';
import omit from 'lodash/omit';
import { useState } from 'react';
import CreateBrandModal from 'Modules/Plans/Brand/CreateBrandModal/CreateBrandModal';
import { creatorbase } from '@round/api';
import styles from './ProjectForm.module.css';
import Button from 'ui-new/whitelabel/Button/Button';
import BrandBadge from 'Modules/Plans/Brand/BrandBadge/BrandBadge';
import { FormatOptionLabelMeta } from 'react-select';
import { SongOption, useSongSelect } from 'Modules/Plans/Songs/hooks/useSongSelect';
import SongBadge from 'Modules/Plans/Songs/components/SongBadge/SongBadge';

export type ProjectFormValues = Pick<creatorbase.PostProjectData, 'name'> & {
    brand: BrandOption | null;
    song: SongOption | null;
};

const ProjectForm = () => {
    const [isCreateBrandModalOpen, setIsCreateBrandModalOpen] = useState(false);

    const formikContext = useFormikContext<ProjectFormValues>();

    const handleCreateBrand = async (data: creatorbase.PostBrandData) => {
        const response = await creatorbase.postBrand(data);

        if (response.status === 201) {
            formikContext.setFieldValue('brand', mapBrandToOption(response.data));
            return response;
        }

        return response;
    };

    const { props: brandSelectProps } = useBrandSelect({
        initOn: 'menuOpen',
    });

    const { props: songSelectProps } = useSongSelect({
        initOn: 'menuOpen',
    });

    return (
        <>
            <Form className={styles.container}>
                <FormField
                    type="text"
                    name="name"
                    label="Name"
                    placeholder="Enter project name"
                    className={styles.field}
                />

                <div className={styles.brandField}>
                    <SelectField
                        name="brand"
                        label="Artist"
                        {...omit(brandSelectProps, ['value', 'onChange'])}
                        className={styles.field}
                        formatOptionLabel={(option: BrandOption, meta: FormatOptionLabelMeta<BrandOption, false>) =>
                            meta.context === 'menu' ? (
                                <BrandBadge
                                    name={option.label}
                                    teamName={option.subLabel}
                                    imageUrl={option.image ?? undefined}
                                />
                            ) : (
                                option.label
                            )
                        }
                    />

                    <Button
                        appearance="ghost"
                        className={styles.createBrandButton}
                        onClick={() => setIsCreateBrandModalOpen(true)}
                    >
                        Create artist
                    </Button>
                </div>

                <SelectField
                    name="song"
                    label="Song"
                    {...omit(songSelectProps, ['value', 'onChange'])}
                    className={styles.field}
                    formatOptionLabel={(option: SongOption, meta: FormatOptionLabelMeta<SongOption, false>) =>
                        meta.context === 'menu' ? (
                            <SongBadge title={option.label} artistName={option.artistName} imageUrl={option.image} />
                        ) : (
                            option.label
                        )
                    }
                />
            </Form>

            <CreateBrandModal
                isOpen={isCreateBrandModalOpen}
                onClose={() => setIsCreateBrandModalOpen(false)}
                createBrand={handleCreateBrand}
            />
        </>
    );
};

export default ProjectForm;
