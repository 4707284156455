import FullScreenModal from 'ui/FullScreenModal/FullScreenModal';
import BulkPaymentTable from './BulkPaymentTable/BulkPaymentTable';
import { OutstandingPaymentsTableRow } from '../../OutstandingPaymentsTable/OutstandingPaymentsTable';
import styles from './BulkPaymentsModal.module.css';
import { useEffect, useState } from 'react';
import Button from 'ui/Button/Button';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { asMoney } from 'helpers';
import isEqual from 'lodash/isEqual';
import Modal from 'ui-new/Modals/Modal/Modal';
import { UseOutstandingPaymentsReturn } from '../../useOutstandingPayments';
import omit from 'lodash/omit';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
    data: OutstandingPaymentsTableRow[];
    onComplete: () => void;
    makePayment: UseOutstandingPaymentsReturn['payPayPalBulk'];
    updatePaymentRequest: UseOutstandingPaymentsReturn['update'];
};

export type PaymentResult = { success: boolean; error?: string };
export type PaymentResults = Record<number, PaymentResult>;

const BulkPaymentModal = ({ isOpen, closeModal, data, onComplete, makePayment, updatePaymentRequest }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);

    const [rows, setRows] = useState<OutstandingPaymentsTableRow[]>([]);
    const totals = rows.reduce<Record<number, number>>((acc, row) => {
        if (typeof acc[row.currency] !== 'number') {
            acc[row.currency] = Number(row.amount) || 0;
            return acc;
        }

        acc[row.currency] += Number(row.amount) || 0;
        return acc;
    }, {});

    useEffect(() => {
        if (isOpen) {
            setRows(Array.from(data));
        }
    }, [data, isOpen]);

    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const [paymentResults, setPaymentResults] = useState<PaymentResults>({});
    const [isConfirmDiscardChangesModalOpen, setIsConfirmDiscardChangesModalOpen] = useState(false);
    const isPaymentMade = Object.values(paymentResults).length > 0;

    const confirmPayment = async () => {
        if (!rows.length) {
            return;
        }

        setIsPaymentLoading(true);
        const results = await makePayment(
            rows.map((row) => ({
                amount: Number(row.amount),
                currency: row.currency,
                payment_request_id: row.id,
            }))
        );

        setPaymentResults((prev) =>
            results.reduce((acc, current) => {
                if (current.status === 'rejected') {
                    return acc;
                }

                const [id, response] = current.value;
                if (response instanceof Error) {
                    acc[id] = { success: false, error: response.message };
                    return acc;
                }

                if (response.status === 400) {
                    acc[id] = {
                        success: false,
                        error: Array.isArray(response.data)
                            ? response.data.join(', ')
                            : Object.values(response.data).join(', '),
                    };
                    return acc;
                }

                acc[id] = { success: true };
                return acc;
            }, prev)
        );

        setIsPaymentLoading(false);
    };

    const close = () => {
        setPaymentResults({});
        setRows([]);
        closeModal();
    };

    const handleClose = () => {
        const hasDataChanged = !isEqual(data, rows);
        if (hasDataChanged && !isPaymentMade) {
            setIsConfirmDiscardChangesModalOpen(true);
            return;
        }

        if (isPaymentMade) {
            onComplete();
        }

        close();
    };

    const successfulPaymentsCount = Object.values(paymentResults).filter((r) => r.success).length;
    const failedPaymentsCount = Object.values(paymentResults).filter((r) => !r.success).length;

    const successfulPaymentsDisplayMessage = `${
        successfulPaymentsCount === rows.length ? 'All ' : ''
    }${successfulPaymentsCount} payment${successfulPaymentsCount !== 1 ? 's' : ''} successful`;

    const failedPaymentsDisplayMessage = `${
        failedPaymentsCount === rows.length ? 'All ' : ''
    } ${failedPaymentsCount} payment${failedPaymentsCount !== 1 ? 's' : ''} failed`;

    return (
        <>
            <FullScreenModal closeOnEscape isOpen={isOpen} onClose={handleClose}>
                <FullScreenModal.Header>Payment confirmation</FullScreenModal.Header>
                <FullScreenModal.Content className={styles.content}>
                    <BulkPaymentTable
                        data={rows}
                        updatePaymentRequest={async (id, data) => {
                            const response = await updatePaymentRequest(id, data);
                            if (response.status === 200) {
                                setRows((rows) => {
                                    return rows.map((r) => {
                                        return r.id === response.data.id
                                            ? {
                                                  ...r,
                                                  ...omit(response.data, [
                                                      'tiktok_influencer_post',
                                                      'instagram_influencer_post',
                                                  ]),
                                              }
                                            : r;
                                    });
                                });
                            }

                            return response;
                        }}
                        isPaymentLoading={isPaymentLoading}
                        paymentResults={paymentResults}
                    />
                </FullScreenModal.Content>
                <FullScreenModal.Footer className={styles.footer}>
                    <div className={styles.totals}>
                        <p>Totals:</p>
                        <div>
                            {Object.entries(totals).map(([currencyId, amount]) => (
                                <p key={currencyId}>
                                    {asMoney(
                                        amount,
                                        currencies.find((c) => c.id === Number(currencyId))
                                    )}
                                </p>
                            ))}
                        </div>
                    </div>

                    <div className={styles.buttons}>
                        {isPaymentMade ? (
                            <>
                                <div className={styles.results}>
                                    {successfulPaymentsCount > 0 && <span>{successfulPaymentsDisplayMessage}</span>}
                                    {failedPaymentsCount > 0 && <span>{failedPaymentsDisplayMessage}</span>}
                                </div>
                                <Button appearance="primary" onClick={handleClose}>
                                    Close
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button appearance="ghost" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button appearance="primary" isLoading={isPaymentLoading} onClick={confirmPayment}>
                                    Confirm payment
                                </Button>
                            </>
                        )}
                    </div>
                </FullScreenModal.Footer>
            </FullScreenModal>

            <Modal isOpen={isConfirmDiscardChangesModalOpen} onClose={() => setIsConfirmDiscardChangesModalOpen(false)}>
                <Modal.Header>Discard changes</Modal.Header>
                <p>Are you sure you want to discard your changes?</p>
                <Modal.Actions>
                    <Button appearance="ghost" onClick={() => setIsConfirmDiscardChangesModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        appearance="primary"
                        onClick={() => {
                            close();
                            setIsConfirmDiscardChangesModalOpen(false);
                        }}
                    >
                        Discard changes
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default BulkPaymentModal;
