import { Select } from '@round/ui-kit';
import { SingleValueProps, StylesConfig, components } from 'react-select';
import { countryOptions } from '../../../../../../../../utility/constants';
import { GenericDropdownOption } from '../../../../../../../../App.types';
import styles from './Location.module.css';
import cn from 'classnames';

type Props = {
    location: string | null;
    onChange: (location: string | null) => void;
    isDisabled?: boolean;
    isClearable?: boolean;
};

const selectStyles: StylesConfig = {
    container: (provided) => ({
        ...provided,
        minWidth: '10rem',
        border: 'none',
    }),
    control: (provided) => ({
        ...provided,
        border: 'none',
        boxShadow: 'none',
        minHeight: 'auto',
        backgroundColor: 'transparent',
        cursor: 'pointer',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
    }),
};

const LocationValue = ({ ...props }: SingleValueProps<GenericDropdownOption<string>>) => {
    return (
        <div className={styles.valueContainer}>
            <span className={cn(`fi fi-${props.data.value?.toLowerCase()}`, styles.flag)} />
            {props.data.label}
        </div>
    );
};

const Location = ({ location, onChange, isDisabled, isClearable }: Props) => {
    const value = countryOptions.find((option) => option.value === location) ?? null;

    return (
        <Select
            value={value}
            onChange={(option) => {
                onChange(option?.value ?? null);
            }}
            options={countryOptions}
            menuPortalTarget={document.body}
            isClearable={isClearable}
            backspaceRemovesValue={false}
            placeholder="Search country"
            noOptionsMessage={() => 'No countries found'}
            isDisabled={isDisabled}
            styles={selectStyles}
            components={{
                SingleValue: LocationValue,
                DropdownIndicator: (props) =>
                    props.selectProps.isDisabled ? null : <components.DropdownIndicator {...props} />,
            }}
        />
    );
};

export default Location;
