import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { TiktokAudio } from './tiktokAudios.types';

export type GetTiktokAudiosOrderingParam = `-${keyof Pick<
    TiktokAudio,
    | 'video_count'
    | 'video_count_daily_change_relative'
    | 'video_count_daily_change'
    | 'video_count_weekly_change_relative'
    | 'video_count_weekly_change'
>}`;

export type GetTiktokAudiosParams = {
    ordering?: GetTiktokAudiosOrderingParam;
} & Partial<PaginatedRequest> &
    (
        | {
              song_id: number;
          }
        | {
              id: string;
          }
    );

type GetTiktokAudiosResponse =
    | ApiResponse<PaginatedApiResponseData<TiktokAudio>, 200>
    //response when song_id is not provided
    | ApiResponse<{ song_id: string | string[] }, 400>
    | ForbiddenResponse
    | NotFoundResponse;

export async function getTiktokAudios(
    params: GetTiktokAudiosParams,
    requestInit?: RequestInit
): Promise<GetTiktokAudiosResponse> {
    const response = await fetchWithToken(
        `/api/creatorbase/tiktok-audios/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404 || response.status === 403 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get tiktok audios');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
