import { AppServer } from '../../mirage';
import * as microsMirage from './micros/micros.mirage';
import * as liveStatsMirage from './liveStats/liveStats.mirage';

export const models = {
    ...microsMirage.models,
    ...liveStatsMirage.models,
};

export const serializers = {
    ...microsMirage.serializers,
    ...liveStatsMirage.serializers,
};

export const factories = {
    ...microsMirage.factories,
    ...liveStatsMirage.factories,
};

export function handleRequests(server: AppServer) {
    microsMirage.handleRequests(server);
    liveStatsMirage.handleRequests(server);
}
