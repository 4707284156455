import React, { useContext, useState } from 'react';
import ProjectsTable from '../../components/ProjectsTable/ProjectsTable';
import styles from './AllCampaigns.module.css';
import { CampaignType, Project } from '../../Projects.types';
import useUrlState from '../../../../../Hooks/useUrlState';
import { SearchBox } from '../../../../../SharedComponents/SearchBox/SearchBox';
import SearchIcon from '../../../../../SharedComponents/svg/Icons/SearchIcon';
import Select, { StylesConfig, ValueType } from 'react-select';
import { GenericDropdownOption } from '../../../../../App.types';
import ActiveCampaignsIcon from '../../components/icons/ActiveCampaignsIcon';
import { Checkbox } from '@round/ui-kit';
import { AuthContext } from '../../../../Auth/contexts/AuthContext';
import { ProtectedByUserGroups } from '../../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import { useCampaigns } from '../../hooks/useCampaigns';
import { projectTypeOptions } from '../../helpers';

type UrlState = {
    page: number;
    pageSize: number;
    search?: string;
    status?: Project['status'];
    campaign_type?: CampaignType;
};

const statusFilterOptions: GenericDropdownOption<Project['status']>[] = [
    {
        label: 'No plans campaigns',
        value: 'no_plans',
    },
    {
        label: 'Planned campaigns',
        value: 'planned',
    },
    {
        label: 'Active campaigns',
        value: 'live',
    },
    {
        label: 'Ended campaigns',
        value: 'completed',
    },
];

const statusFilterStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        minHeight: 'auto',
        borderRadius: '6px',
        borderColor: '#c2cfe0',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#c2cfe0',
        },
    }),
    placeholder: (base) => ({
        ...base,
        color: '#c2cfe0',
    }),
    valueContainer: (base) => ({
        ...base,
        width: '12rem',
        padding: '0.5rem 1rem',
        fontSize: '0.8rem',
    }),
    input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
    }),
    indicatorSeparator: () => ({
        margin: 0,
        display: 'none',
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: 0,
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: '0 0.2rem',
    }),
    menu: (base) => ({
        ...base,
        fontSize: '0.8rem',
        color: '#707683',
    }),
};

const initialUrlState: UrlState = {
    page: 1,
    pageSize: 10,
};

const AllCampaigns = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState, { shouldSetMissingInitialValues: true });

    const page = Number(urlState.page) || initialUrlState.page;
    const pageSize = Number(urlState.pageSize) || initialUrlState.pageSize;
    const search = urlState.search ?? '';
    const status = statusFilterOptions.find((option) => option.value === urlState.status);

    const { user } = useContext(AuthContext);
    const [filterPlansByCurrentUser, setFilterPlansByCurrentUser] = useState(false);

    const setPage = (page: number) => setUrlState({ page });
    const setPageSize = (size: number | undefined) => setUrlState({ pageSize: size || initialUrlState.pageSize });
    const setSearch = (search: string) => setUrlState({ search, page: 1 });
    const setStatus = (status: ValueType<GenericDropdownOption<Project['status']>, false>) =>
        setUrlState({ status: status?.value, page: 1 });

    const {
        campaigns,
        campaignsCount,
        campaignsLoading,
        errorLoadingCampaigns,
        numberOfActiveCampaigns,
    } = useCampaigns({
        page: page,
        page_size: pageSize,
        search,
        status: status?.value,
        creator_planner: filterPlansByCurrentUser && user ? user.id.toString() : null,
        campaign_type: projectTypeOptions.find((o) => o.value === urlState.campaign_type)?.value,
    });

    const noDataLabel = errorLoadingCampaigns ? 'Sorry, we are experiencing technical issues' : 'No campaigns found';

    return (
        <div>
            <div className={styles.header}>
                <div>
                    <p className={styles.title}>All Campaigns</p>
                    {typeof numberOfActiveCampaigns === 'number' && (
                        <div className={styles.activeCampaigns}>
                            <span className={styles.activeCampaignsBadge}>
                                <ActiveCampaignsIcon
                                    className={styles.activeCampaignsBadgeIcon}
                                    width={16}
                                    height={16}
                                />
                                {numberOfActiveCampaigns} active
                            </span>
                            Advertising or Influencer campaigns currently running
                        </div>
                    )}
                </div>

                <div>
                    <div className={styles.headerRightTop}>
                        <div className={styles.searchContainer}>
                            <SearchIcon fill="#90A0B7" width={16} height={16} />
                            <SearchBox className={styles.search} initialValue={search} setValue={setSearch} />
                        </div>

                        <div data-test-id="status-filter-container">
                            <Select
                                styles={statusFilterStyles}
                                isClearable
                                placeholder="All campaigns"
                                options={statusFilterOptions}
                                value={status}
                                onChange={setStatus}
                            />
                        </div>

                        <Select
                            styles={statusFilterStyles}
                            isClearable
                            placeholder="All campaign types"
                            options={projectTypeOptions}
                            value={projectTypeOptions.find((o) => o.value === urlState.campaign_type)}
                            onChange={(o) => setUrlState({ campaign_type: o?.value, page: 1 })}
                        />
                    </div>
                    <ProtectedByUserGroups groups={['round_planner']}>
                        <div className={styles.currentUserPlansFilter}>
                            <Checkbox
                                id="filterPlansByCurrentUser"
                                disabled={campaignsLoading}
                                value={filterPlansByCurrentUser}
                                onChange={(value) => {
                                    setPage(1);
                                    setFilterPlansByCurrentUser(value);
                                }}
                            />
                            <label
                                htmlFor="filterPlansByCurrentUser"
                                onClick={() => {
                                    if (!campaignsLoading) {
                                        setPage(1);
                                        setFilterPlansByCurrentUser(!filterPlansByCurrentUser);
                                    }
                                }}
                            >
                                My Plans
                            </label>
                        </div>
                    </ProtectedByUserGroups>
                </div>
            </div>
            <ProjectsTable
                loading={campaignsLoading}
                projects={campaigns}
                noDataLabel={noDataLabel}
                page={page}
                setPage={setPage}
                count={campaignsCount}
                pageSize={pageSize}
                setPageSize={setPageSize}
            />
        </div>
    );
};

export default AllCampaigns;
