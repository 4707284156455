import { OptionsParams, useSelect, UseSingleValueSelectParams } from 'Hooks/useSelect';
import { GenericDropdownOption } from 'App.types';
import { instagram } from '@round/api';
import { isNumber } from 'utility/utility';
import uniq from 'lodash/uniq';

export type InstagramAudioOption = GenericDropdownOption<number> & {
    author: instagram.InstagramUser | null;
    thumbnailUrl: string | null;
    audioFileUrl: string | null;
    instagramId: string;
};

export const mapInstagramAudioToOption = (
    audio: instagram.InstagramAudio,
    author?: instagram.InstagramUser
): InstagramAudioOption => ({
    value: audio.id,
    // at least audio id might help identify the audio
    label: audio.title ?? audio.id.toString(),
    author: author ?? null,
    audioFileUrl: audio.audio_file_url,
    thumbnailUrl: audio.thumbnail_url,
    instagramId: audio.instagram_id,
});

const fetchOptions = async (params: OptionsParams, requestInit?: RequestInit) => {
    const response = await instagram.getInstagramAudios(
        {
            page: params.page,
            page_size: params.page_size,
            identifier_search: params.search,
        },
        requestInit
    );

    if (response.status === 404) {
        throw new Error(response.data.detail);
    }

    const authorIds = uniq(response.data.results.map((audio) => audio.author).filter(isNumber));

    let authors: instagram.InstagramUser[] = [];
    if (!!authorIds.length) {
        const authorsResponse = await instagram.getInstagramUsers({
            id: authorIds.toString(),
            page_size: authorIds.length,
        });
        authors = authorsResponse.status === 200 ? authorsResponse.data.results : [];
    }

    return {
        ...response.data,
        results: response.data.results.map((audio) => {
            const author = authors.find((author) => author.id === audio.author);
            return mapInstagramAudioToOption(audio, author);
        }),
    };
};

const fetchInitialValue = async (id: number, requestInit?: RequestInit) => {
    const response = await instagram.getInstagramAudio(id, requestInit);

    if (response.status === 404) {
        throw new Error(response.data.detail);
    }

    let author: instagram.InstagramUser | undefined = undefined;

    if (response.data.author) {
        const authorResponse = await instagram.getInstagramUser(response.data.author);
        author = authorResponse.status === 200 ? authorResponse.data : undefined;
    }

    return mapInstagramAudioToOption(response.data, author);
};

type SingleSelectParams = Omit<UseSingleValueSelectParams<InstagramAudioOption>, 'fetchOptions' | 'isMulti'>;

export function useInstagramAudiosSelect(params: SingleSelectParams) {
    return useSelect({ fetchOptions, fetchInitialValue, isMulti: false, ...params });
}
