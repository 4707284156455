import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { MediaPlanItemGroup } from './mediaPlanItemGroup.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    mediaPlanItemGroup: Model as ModelDefinition<MediaPlanItemGroup>,
};

export const serializers = {
    mediaPlanItemGroup: makeSerializer<MediaPlanItemGroup>([]),
};

export const factories = {
    mediaPlanItemGroup: createFactory<MediaPlanItemGroup>({
        media_plan(index: number) {
            return index;
        },
        name(index: number) {
            return `name ${index}`;
        },
        ordering_index(index: number) {
            return index;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const getMediaPlanItemGroupsListUrl = '/api/advertising/viewsets/media-plan-item-group/';
    server.get(getMediaPlanItemGroupsListUrl, function (this: RouteHandlerContext, schema, request) {
        const { media_plan_id = '' } = request.queryParams;
        const mediaPlanItemGroups = schema
            .all('mediaPlanItemGroup')
            .filter((group) => !media_plan_id || group.media_plan === Number(media_plan_id));

        return buildPaginatedResponse(mediaPlanItemGroups, {
            url: getMediaPlanItemGroupsListUrl,
            serialize: (resource) => this.serialize(resource, 'mediaPlanItemGroup'),
            queryParams: request.queryParams,
        });
    });

    server.post(
        '/api/advertising/viewsets/media-plan-item-group/',
        function (this: RouteHandlerContext, schema, request) {
            return schema.create('mediaPlanItemGroup', JSON.parse(request.requestBody));
        }
    );

    server.delete(
        '/api/advertising/viewsets/media-plan-item-group/:groupId/',
        function (this: RouteHandlerContext, schema, request) {
            const group = schema.find('mediaPlanItemGroup', request.params.groupId);
            if (!group) {
                return new Response(404, {}, { detail: 'Not found.' });
            }

            group.destroy();
            return new Response(204);
        }
    );

    server.patch(
        '/api/advertising/viewsets/media-plan-item-group/:groupId/',
        function (this: RouteHandlerContext, schema, request) {
            const group = schema.find('mediaPlanItemGroup', request.params.groupId);
            if (!group) {
                return new Response(404, {}, { detail: 'Not found.' });
            }

            group.update({ ...JSON.parse(request.requestBody) });
            return group;
        }
    );
}
