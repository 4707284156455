import { useState } from 'react';
import PopoutEditCell from 'ui-new/TableComponents/PopoutEditCell/PopoutEditCell';
import Spinner from 'ui-new/Loading/Spinner/Spinner';
import styles from './TableTextArea.module.css';
import cn from 'classnames';

type Props = {
    value: string;
} & (
    | {
          updateValue: (value: string) => Promise<boolean>;
          isReadonly?: false;
      }
    | {
          updateValue?: (value: string) => Promise<boolean>;
          isReadonly: true;
      }
);

const TableTextArea = ({ value: initialValue, isReadonly, updateValue }: Props) => {
    const [value, setValue] = useState(initialValue);
    const [isEditing, setIsEditing] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const handleUpdate = async (value: string) => {
        if (!updateValue) {
            return;
        }

        setIsUpdating(true);
        try {
            const success = await updateValue(value);

            if (!success) {
                setValue(initialValue);
                return;
            }
        } catch {
            setValue(initialValue);
        } finally {
            setIsUpdating(false);
        }
    };

    const onEditingChange = async (isEditingValue: boolean) => {
        const didValueChange = value !== initialValue;
        if (didValueChange) {
            await handleUpdate(value);
        }
        setIsEditing(isEditingValue);
    };

    return (
        <PopoutEditCell
            target={<div className={cn(styles.target, { [styles.invisible]: isEditing })}>{initialValue || '-'}</div>}
            isEditing={isEditing}
            onEditingChange={onEditingChange}
            editClassName={styles.editContainer}
            isReadonly={isReadonly}
        >
            <div className={styles.container}>
                <textarea
                    autoFocus
                    className={styles.input}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={() => onEditingChange(false)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.currentTarget.blur();
                        }
                    }}
                    disabled={isUpdating}
                />
                {isUpdating && <Spinner />}
            </div>
        </PopoutEditCell>
    );
};

export default TableTextArea;
