import { useEffect, useRef } from 'react';

export function useAbortSignal({ abortOnUnmount = false } = {}) {
    const abortControllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        return () => {
            if (abortOnUnmount) {
                abortControllerRef.current?.abort();
            }
        };
    }, [abortOnUnmount]);

    const getSignal = () => {
        if (!abortControllerRef.current) {
            abortControllerRef.current = new AbortController();
        }
        return abortControllerRef.current?.signal;
    };

    const resetSignal = () => {
        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();
    };

    return { getSignal, resetSignal };
}
