import React, { useCallback, useMemo, useState } from 'react';
import { InstagramAudioStats } from '@round/api';
import { Point } from '../../../../ui/DataDisplay/Charts/LineChart/LineChart';
import { TabNav, TabNavItem, TabPanel, TabPanels, Tabs } from '@round/ui-kit';
import styles from './CreationsChart.module.css';
import { formatNumberToCommaNotation } from '../../../../helpers';
import DateRangeLineChart from 'ui/DataDisplay/Charts/DateRangeLineChart/DateRangeLineChart';

type Props = {
    stats: InstagramAudioStats | undefined | null;
    dateRange: [Date, Date];
    setStatsDateRange: (dateRange: [Date, Date]) => void;
    isLoading?: boolean;
};

const CreationsChart = ({ isLoading, stats, dateRange, setStatsDateRange }: Props) => {
    const [activeTab, setActiveTab] = useState('total');

    const totalCreationsPoints: Point[] = useMemo(
        () =>
            stats?.reels_count_time_series.map((i) => ({
                x: i.time,
                y: i.value,
            })) ?? [],
        [stats?.reels_count_time_series]
    );

    const dailyCreationsPoints: Point[] = useMemo(
        () =>
            stats?.reels_count_time_series
                .filter((i) => typeof i.change === 'number')
                .map((i) => ({
                    x: i.time,
                    y: i.change!,
                }))
                .slice(1) ?? [],
        [stats?.reels_count_time_series]
    );

    const renderTooltip = useCallback(({ total, daily }: { total?: number; daily?: number }) => {
        return (
            <div className={styles.tooltipContent}>
                <span className={styles.tooltipDot} />
                <div>
                    {typeof total !== 'undefined' && (
                        <p>
                            Total Creations:
                            <span className={styles.tooltipValue}>{formatNumberToCommaNotation(total)}</span>
                        </p>
                    )}
                    {typeof daily !== 'undefined' && (
                        <p>
                            Daily Creations:
                            <span className={styles.tooltipValue}>{formatNumberToCommaNotation(daily)}</span>
                        </p>
                    )}
                </div>
            </div>
        );
    }, []);

    const renderTotalCreationsTooltip = useCallback(
        (point: Point) => {
            const dailyCreations = dailyCreationsPoints.find((d) => d.x === point.x);
            return renderTooltip({ total: point.y, daily: dailyCreations?.y });
        },
        [dailyCreationsPoints, renderTooltip]
    );

    const renderDailyCreationsTooltip = useCallback(
        (point: Point) => {
            const totalCreations = totalCreationsPoints.find((t) => t.x === point.x);
            return renderTooltip({ total: totalCreations?.y, daily: point.y });
        },
        [renderTooltip, totalCreationsPoints]
    );

    return (
        <Tabs activeTab={activeTab} onChange={setActiveTab}>
            <TabNav className={styles.chartTabs}>
                <TabNavItem name="total">Total creations</TabNavItem>
                <TabNavItem name="daily">Daily creations</TabNavItem>
            </TabNav>

            <TabPanels>
                <TabPanel name="total">
                    <DateRangeLineChart
                        dateFrom={dateRange[0]}
                        dateTo={dateRange[1]}
                        onDateFromChange={(date) => setStatsDateRange([date, dateRange[1]])}
                        onDateToChange={(date) => setStatsDateRange([dateRange[0], date])}
                        loading={isLoading}
                        points={totalCreationsPoints}
                        renderTooltip={renderTotalCreationsTooltip}
                    />
                </TabPanel>

                <TabPanel name="daily">
                    <DateRangeLineChart
                        dateFrom={dateRange[0]}
                        dateTo={dateRange[1]}
                        onDateFromChange={(date) => setStatsDateRange([date, dateRange[1]])}
                        onDateToChange={(date) => setStatsDateRange([dateRange[0], date])}
                        loading={isLoading}
                        points={dailyCreationsPoints}
                        renderTooltip={renderDailyCreationsTooltip}
                    />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default CreationsChart;
