import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { UserPlanner } from './plannerUser.types';

export const models = {
    userPlanner: Model as ModelDefinition<UserPlanner>,
};

export const serializers = {
    userPlanner: makeSerializer<UserPlanner>([]),
};

export const factories = {
    userPlanner: createFactory<UserPlanner>({
        id(index: number) {
            return index;
        },
        first_name(index: number) {
            return `first_name ${index}`;
        },
        last_name(index: number) {
            return `last_name ${index}`;
        },
    }),
};

export function handleRequests(server: AppServer) {
    server.get('/api/users/planner-users/', function (this: RouteHandlerContext, schema, request) {
        const { search = '', ids } = request.queryParams;
        const plannerUsers = schema
            .all('userPlanner')
            .filter(
                (user) =>
                    !search ||
                    user.first_name.toLowerCase().includes(search.toLowerCase()) ||
                    user.last_name.toLowerCase().includes(search.toLowerCase())
            )
            .filter((user) => !ids || ids.split(',').includes(user.id));

        return buildPaginatedResponse(plannerUsers, {
            url: '/api/users/planner-users/',
            serialize: (resource) => this.serialize(resource, 'userPlanner'),
            queryParams: request.queryParams,
        });
    });
}
