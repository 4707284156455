import { AppServer } from '../mirage';
import * as collectionMirage from './collection/collection.mirage';
import * as collectionTiktokHeadlineStatsMirage from './collectionHeadlineStats/collectionHeadlineStats.mirage';
import * as collectionAudioStatsMirage from './collectionAudioStats/collectionAudioStats.mirage';
import * as collectionPostStatsMirage from './collectionPostStats/collectionPostStats.mirage';
import * as collectionAudioRelationshipMirage from './collectionAudioRelationship/collectionAudioRelationship.mirage';

export const models = {
    ...collectionMirage.models,
    ...collectionTiktokHeadlineStatsMirage.models,
    ...collectionPostStatsMirage.models,
    ...collectionAudioRelationshipMirage.models,
};

export const serializers = {
    ...collectionMirage.serializers,
    ...collectionTiktokHeadlineStatsMirage.serializers,
    ...collectionPostStatsMirage.serializers,
    ...collectionAudioRelationshipMirage.serializers,
};

export const factories = {
    ...collectionMirage.factories,
    ...collectionTiktokHeadlineStatsMirage.factories,
    ...collectionPostStatsMirage.factories,
    ...collectionAudioRelationshipMirage.factories,
};

export function handleRequests(server: AppServer) {
    collectionMirage.handleRequests(server);
    collectionTiktokHeadlineStatsMirage.handleRequests(server);
    collectionAudioStatsMirage.handleRequests(server);
    collectionPostStatsMirage.handleRequests(server);
    collectionAudioRelationshipMirage.handleRequests(server);
}
