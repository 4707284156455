import { TiktokAudioDailyStats } from './audioStatsDaily.types';
import { fetchWithToken } from '../../helpers';
import { ApiResponse } from '../../types';

type GetTiktokAudioDailyStatsResponse = {
    results: TiktokAudioDailyStats[];
};

export async function getTiktokAudioDailyStats(
    audioId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<GetTiktokAudioDailyStatsResponse, 200>> {
    const response = await fetchWithToken(`/api/tiktok/music-stats-daily/?music_id=${audioId}`, requestInit);

    if (!response.ok) {
        throw new Error(`Could not fetch stats for music: ${audioId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
