import React, { useMemo, useState } from 'react';
import { GenericDropdownOption } from '../../../../../../App.types';
import FormField from '../../../../../../ui/DataEntry/FormField/FormField';
import Label from '../../../../../../ui/DataEntry/Label/Label';
import Select from '../../../../../../ui/DataEntry/Select/Select';
import Button from '../../../../../../ui/Buttons/Button/Button';
import Toggle from '../../../../../../ui/DataEntry/Toggle/Toggle';
import styles from './TiktokInfluencerPostForm.module.css';
import { ErrorMessage, Field, FieldProps, FormikProps, Form, Formik, FormikHelpers } from 'formik';
import { Banner } from '@round/ui-kit';
import { usePaginatedFetch, getXeroReceivableInvoices } from '@round/api';
import { TiktokInfluencerPostApiBody } from '../../../types/Tiktok.types';
import { ChangeValueTypes } from '../../../../../../utility/utility.types';
import { getTiktokInfluencerUsers } from '../../../../../TikTok/TikTok.api';
import cn from 'classnames';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../../../contexts/OptionsContext/OptionsContext';
import useAbortableEffect from '../../../../../../Hooks/useAbortableEffect';
import debounce from 'lodash/debounce';
import OptionWithSubLabel, {
    DropdownOptionWithSubLabel,
} from '../../../../../../ui/DataEntry/Select/OptionWithSubLabel/OptionWithSubLabel';
import { StylesConfig } from 'react-select';

const selectStyles: StylesConfig = {
    control: (provided, props) => ({
        ...provided,
        cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        fontWeight: 400,
        fontSize: '0.8125rem',
        backgroundColor: props.isDisabled ? '#C2CFE050' : provided.backgroundColor,
        borderColor: '#C2CFE0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.625rem 1rem',
    }),
    singleValue: (provided, props) => ({
        ...provided,
        color: props.isDisabled ? '#323C4780' : '#323C47',
    }),
    input: (provided) => ({
        ...provided,
        height: '1rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }),
};

export type TiktokInfluencerPostFormValues = Partial<
    ChangeValueTypes<
        Pick<
            TiktokInfluencerPostApiBody,
            'influencer_id' | 'currency_id' | 'cost' | 'xero_invoice_id' | 'post_url' | 'is_carousel'
        >,
        'influencer_id' | 'currency_id' | 'xero_invoice_id',
        GenericDropdownOption<number> | DropdownOptionWithSubLabel<number> | null
    >
>;

export type TiktokInfluencerPostFormProps = {
    handleSubmit: (
        values: TiktokInfluencerPostFormValues,
        helpers: FormikHelpers<TiktokInfluencerPostFormValues>
    ) => void;
    initialValues: TiktokInfluencerPostFormValues;
    disabledValues?: { [Property in keyof TiktokInfluencerPostFormValues]: boolean };
    renderActions?: (props: FormikProps<TiktokInfluencerPostFormValues>) => React.ReactNode;
};

const defaultRenderActions = ({ isSubmitting, submitForm, dirty }: FormikProps<TiktokInfluencerPostFormValues>) => (
    <div className={styles.defaultRenderActions}>
        <Button
            className={styles.defaultSubmitButton}
            type="filled"
            color="black"
            disabled={!dirty}
            loading={isSubmitting}
            onClick={submitForm}
        >
            Save
        </Button>
    </div>
);

const TiktokInfluencerPostForm = ({
    initialValues,
    handleSubmit,
    renderActions = defaultRenderActions,
    disabledValues = {},
}: TiktokInfluencerPostFormProps) => {
    const { currencies } = useNonNullContext(OptionsContext);

    const [influencerUsersPage, setInfluencerUsersPage] = useState(1);
    const [influencerUsersSearch, setInfluencerUsersSearch] = useState('');
    const {
        data: tiktokInfluencerUsers,
        getCurrentPage: getCurrentUserPage,
        hasNextPage: hasNextUserPage,
        loading: tiktokInfluencerUsersLoading,
    } = usePaginatedFetch(getTiktokInfluencerUsers, {
        page: influencerUsersPage,
        search: influencerUsersSearch,
    });

    const fetchInfluencerUsers = useMemo(() => debounce(getCurrentUserPage, 700), [getCurrentUserPage]);
    useAbortableEffect(
        (signal) => {
            fetchInfluencerUsers({ signal });
        },
        [fetchInfluencerUsers]
    );

    const tiktokInfluencerUserOptions: GenericDropdownOption<number>[] = useMemo(
        () =>
            tiktokInfluencerUsers.map((u) => ({
                value: u.id,
                label: u.username,
            })),
        [tiktokInfluencerUsers]
    );

    const [invoicesPage, setInvoicesPage] = useState(1);
    const [invoicesSearch, setInvoicesSearch] = useState('');
    const {
        data: invoices,
        getCurrentPage: getCurrentInvoicesPage,
        hasNextPage: hasNextInvoicesPage,
        loading: invoicesLoading,
    } = usePaginatedFetch(getXeroReceivableInvoices, {
        page: invoicesPage,
        search: invoicesSearch,
    });

    const fetchInvoices = useMemo(() => debounce(getCurrentInvoicesPage, 700), [getCurrentInvoicesPage]);
    useAbortableEffect(
        (signal) => {
            fetchInvoices({ signal });
        },
        [fetchInvoices]
    );

    const invoiceOptions: DropdownOptionWithSubLabel<number>[] = useMemo(
        () =>
            invoices.map((i) => ({
                value: i.id,
                label: i.invoice_number,
                subLabel: i.reference,
            })),
        [invoices]
    );

    const currencyOptions: GenericDropdownOption<number>[] = useMemo(
        () =>
            currencies.map((c) => ({
                value: c.id,
                label: c.name,
            })),
        [currencies]
    );

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
            {(props) => {
                const { influencer_id, post_url } = props.values;
                const showInfluencerUsernameNotUsedInPostUrlWarning =
                    typeof post_url === 'string' &&
                    post_url &&
                    influencer_id &&
                    !post_url.toLowerCase().includes(`@${influencer_id.label.toLowerCase()}`);
                return (
                    <>
                        <Form className={styles.form}>
                            {showInfluencerUsernameNotUsedInPostUrlWarning && (
                                <Banner appearance="warning">Post url doesn't contain influencer username</Banner>
                            )}
                            <div className={styles.row}>
                                <div className={styles.col}>
                                    <FormField className={styles.field}>
                                        <Label htmlFor="influencer_id">Influencer</Label>
                                        <Field name="influencer_id">
                                            {(props: FieldProps) => (
                                                <Select
                                                    styles={selectStyles}
                                                    inputValue={influencerUsersSearch}
                                                    onInputChange={(search: string) => {
                                                        setInfluencerUsersSearch(search);
                                                        setInfluencerUsersPage(1);
                                                    }}
                                                    isLoading={tiktokInfluencerUsersLoading}
                                                    isDisabled={disabledValues['influencer_id']}
                                                    options={tiktokInfluencerUserOptions}
                                                    onChange={(option) => {
                                                        props.form.setFieldValue(props.field.name, option);
                                                        const influencer = tiktokInfluencerUsers.find(
                                                            (influencer) => option?.value === influencer.id
                                                        );
                                                        if (influencer && influencer.cost && influencer.currency) {
                                                            props.form.setFieldValue('cost', influencer.cost);
                                                            props.form.setFieldValue(
                                                                'currency_id',
                                                                currencyOptions.find(
                                                                    (currency) => currency.value === influencer.currency
                                                                )
                                                            );
                                                        } else {
                                                            props.form.setFieldValue('cost', initialValues.cost);
                                                            props.form.setFieldValue(
                                                                'currency_id',
                                                                initialValues.currency_id
                                                            );
                                                        }
                                                    }}
                                                    value={props.field.value}
                                                    onMenuScrollToBottom={() => {
                                                        if (hasNextUserPage) {
                                                            setInfluencerUsersPage((page) => page + 1);
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="influencer_id">
                                            {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                                        </ErrorMessage>
                                    </FormField>
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col4}>
                                    <FormField className={styles.field}>
                                        <Label htmlFor="currency_id">Currency</Label>
                                        <Field name="currency_id">
                                            {(props: FieldProps) => (
                                                <Select
                                                    styles={selectStyles}
                                                    options={currencyOptions}
                                                    onChange={(option) => {
                                                        props.form.setFieldValue(props.field.name, option);
                                                    }}
                                                    isDisabled={disabledValues['currency_id']}
                                                    value={props.field.value}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="currency_id">
                                            {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                                        </ErrorMessage>
                                    </FormField>
                                </div>
                                <div className={styles.col5}>
                                    <FormField className={styles.field}>
                                        <Label htmlFor="cost">Cost</Label>
                                        <Field name="cost" type="number" disabled={disabledValues['cost']} />
                                        <ErrorMessage name="cost">
                                            {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                                        </ErrorMessage>
                                    </FormField>
                                </div>
                                <div className={styles.col}>
                                    <FormField className={styles.field}>
                                        <Label htmlFor="xero_invoice_id">Invoice</Label>
                                        <Field name="xero_invoice_id">
                                            {(props: FieldProps) => (
                                                <Select
                                                    isClearable
                                                    components={{ Option: OptionWithSubLabel }}
                                                    styles={selectStyles}
                                                    inputValue={invoicesSearch}
                                                    onInputChange={(search: string) => {
                                                        setInvoicesSearch(search);
                                                        setInvoicesPage(1);
                                                    }}
                                                    filterOption={null}
                                                    isLoading={invoicesLoading}
                                                    isDisabled={disabledValues['xero_invoice_id']}
                                                    options={invoiceOptions}
                                                    formatOptionLabel={(option, { context }) =>
                                                        context === 'value'
                                                            ? `${option.label} - ${option.subLabel}`
                                                            : option.label
                                                    }
                                                    onChange={(option) => {
                                                        props.form.setFieldValue(props.field.name, option);
                                                    }}
                                                    value={props.field.value}
                                                    onMenuScrollToBottom={() => {
                                                        if (hasNextInvoicesPage) {
                                                            setInvoicesPage((page) => page + 1);
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="xero_invoice_id">
                                            {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                                        </ErrorMessage>
                                    </FormField>
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.col}>
                                    <FormField className={styles.field}>
                                        <Label htmlFor="post_url">Post URL</Label>
                                        <Field name="post_url" disabled={disabledValues['post_url']} />
                                        <ErrorMessage name="post_url">
                                            {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                                        </ErrorMessage>
                                    </FormField>
                                </div>
                                <div className={styles.col4}>
                                    <FormField className={cn(styles.field, styles.carouselField)}>
                                        <Label htmlFor="is_carousel">Carousel?</Label>
                                        <Field name="is_carousel" className={styles.carouselField}>
                                            {(props: FieldProps) => (
                                                <Toggle
                                                    className={styles.carouselToggle}
                                                    value={props.field.value}
                                                    onChange={(enabled) =>
                                                        props.form.setFieldValue(props.field.name, enabled)
                                                    }
                                                    disabled={disabledValues['is_carousel']}
                                                />
                                            )}
                                        </Field>
                                    </FormField>
                                </div>
                            </div>
                        </Form>
                        {renderActions(props)}
                    </>
                );
            }}
        </Formik>
    );
};

export default TiktokInfluencerPostForm;
