import { DetailedHTMLProps, HTMLAttributes, ReactNode, useState } from 'react';
import styles from './CircleBadge.module.css';
import cn from 'classnames';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';

type Props = {
    tooltip?: ReactNode;
} & DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const CircleBadge = ({ children, className, tooltip, ...props }: Props) => {
    const [anchor, setAnchor] = useState<HTMLSpanElement | null>(null);

    return (
        <>
            <span ref={setAnchor} className={cn(styles.badge, className)} {...props}>
                {children}
            </span>
            {tooltip && (
                <Tooltip anchorElement={anchor}>
                    <Tooltip.Body>{tooltip}</Tooltip.Body>
                </Tooltip>
            )}
        </>
    );
};

export default CircleBadge;
