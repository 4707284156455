import { DataState } from 'App.types';
import { Dispatch } from 'react';

// For data hooks Actions are more important than a state.
// We need to be able to dispatch specific actions on a context.
// But we don't really need to have a specific state shape
// because Selector would return required state type
export type BaseDataHookContextValue<Actions = any> = [any, Dispatch<Actions>] | null;

export const getSuccessfulStateIds = <T>(state: { [id: number | string | symbol]: DataState<T> | undefined }) => {
    return Object.entries(state)
        .filter(([, data]) => data?.status === 'success')
        .map(([key]) => key);
};
