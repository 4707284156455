import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { XeroApexInvoiceSimple } from './apexInvoice.types';

export const models = {
    xeroApexInvoiceSimple: Model as ModelDefinition<XeroApexInvoiceSimple>,
};

export const factories = {
    xeroApexInvoiceSimple: createFactory<XeroApexInvoiceSimple>({
        id(index: number) {
            return index;
        },
        invoice_number(index: number) {
            return `invoice number ${index}`;
        },
        reference(index: number) {
            return `reference ${index}`;
        },
    }),
};
export const serializers = {
    xeroApexInvoiceSimple: makeSerializer<XeroApexInvoiceSimple>([]),
};

export function handleInvoiceRequests(server: AppServer) {
    const getInvoiceListPath = '/api/xero/apex-receivable-invoices/';
    server.get(getInvoiceListPath, function (this: RouteHandlerContext, schema, request) {
        const { search, id } = request.queryParams;
        const invoices = schema
            .all('xeroApexInvoiceSimple')
            .filter((i) => !id || i.id === id)
            .filter((i) => !search || i.invoice_number.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(invoices, {
            url: getInvoiceListPath,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'xeroApexInvoiceSimple'),
        });
    });
}
