import { useContext } from 'react';
import { InfluencerPlanContext } from '../InfluencerPlanContext';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { TiktokCreatorsDispatchContext, TiktokCreatorsStateContext } from './TiktokCreatorsContext';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import { fetchTiktokInfluencerPlanAudio } from '../../InfluencerPlan.api';
import { getTiktokAudios } from '@round/api';

export default function useCreatorPlanTiktokAudios() {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const state = useNonNullContext(TiktokCreatorsStateContext);
    const dispatch = useNonNullContext(TiktokCreatorsDispatchContext);

    useAbortableEffect(
        (signal) => {
            async function fetchAudios() {
                if (
                    !influencerPlan ||
                    state.tiktokInfluencerPlanAudiosInitialized ||
                    state.tiktokInfluencerPlanAudiosLoadingError
                ) {
                    return;
                }

                try {
                    dispatch({ type: 'loadTiktokInfluencerPlanAudios' });
                    const response = await fetchTiktokInfluencerPlanAudio(influencerPlan.id, { signal });
                    dispatch({ type: 'tiktokInfluencerPlanAudiosInitialized', payload: response });
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    dispatch({
                        type: 'setTiktokInfluencerPlanAudiosLoadingError',
                        payload: "Sorry, we're experiencing technical issues",
                    });
                }
            }

            fetchAudios();
        },
        [
            dispatch,
            influencerPlan,
            state.tiktokInfluencerPlanAudiosInitialized,
            state.tiktokInfluencerPlanAudiosLoading,
            state.tiktokInfluencerPlanAudiosLoadingError,
        ]
    );

    useAbortableEffect(
        (signal) => {
            async function fetchTiktokAudios() {
                const influencerPlanAudioIds = state.tiktokInfluencerPlanAudios
                    .map((audio) => audio.audio_id)
                    .filter((id): id is number => typeof id === 'number');

                const existingAudioIds = state.tiktokAudios.map((a) => a.id);
                const audiosToFetch = influencerPlanAudioIds.filter((id) => !existingAudioIds.includes(id));
                if (!audiosToFetch.length) {
                    return;
                }

                try {
                    dispatch({ type: 'loadTiktokAudios' });
                    const response = await getTiktokAudios(
                        { id: audiosToFetch.join(), page_size: audiosToFetch.length },
                        { signal }
                    );
                    dispatch({ type: 'tiktokAudiosInitialized', payload: response.data.results });
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    dispatch({
                        type: 'setTiktokAudiosLoadingError',
                        payload: "Sorry, we're experiencing technical issues",
                    });
                }
            }

            fetchTiktokAudios();
        },
        [dispatch, state.tiktokAudios, state.tiktokInfluencerPlanAudios]
    );

    return {
        initialized: state.tiktokInfluencerPlanAudiosInitialized,
        loading: state.tiktokInfluencerPlanAudiosLoading,
        error: state.tiktokInfluencerPlanAudiosLoadingError,
        influencerPlanAudios: state.tiktokInfluencerPlanAudios,
        tiktokAudios: state.tiktokAudios,
        tiktokAudiosLoading: state.tiktokAudiosLoading,
        tiktokAudiosLoadingError: state.tiktokAudiosLoadingError,
    };
}
