import React, { PropsWithChildren } from 'react';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { ContextState, TabsContext } from '../../Tabs';
import styles from './TabNavItem.module.css';
import cn from 'classnames';
import Button from '../../../../Buttons/Button/Button';

type TabNavItemProps = PropsWithChildren<{
    name: ContextState['activeTab'];
    className?: string;
}>;

const TabNavItem = ({ name, children, className }: TabNavItemProps) => {
    const { activeTab, onChange } = useNonNullContext(TabsContext);

    return (
        <Button
            className={cn(styles.button, className, { [styles.active]: activeTab === name })}
            onClick={() => onChange(name)}
        >
            {children}
        </Button>
    );
};

export default TabNavItem;
