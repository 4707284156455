import { AppServer, RouteHandlerContext } from '../../mirage';
import { hasMany, Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { MicrowaveInfluencer } from './influencers.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    microwaveInfluencer: Model.extend({
        tags: hasMany('microwaveInfluencerTag'),
    }) as ModelDefinition<MicrowaveInfluencer>,
};

export const serializers = {
    microwaveInfluencer: makeSerializer<MicrowaveInfluencer>([]),
};

export const factories = {
    microwaveInfluencer: createFactory<MicrowaveInfluencer>({
        email: (index: number) => `email${index}`,
        follower_count: (index: number) => index,
        location: null,
        rating: 1,
        tiktok_post_count: (index: number) => index,
        tiktok_post_invite_count: (index: number) => index,
        tiktok_post_rate: '0.00',
        content_tags: [],
    }),
};

export function handleRequests(server: AppServer) {
    server.get('/api/microwave/influencers/', function (this: RouteHandlerContext, schema, request) {
        const creators = schema.all('microwaveInfluencer');
        return buildPaginatedResponse(creators, {
            url: '/api/microwave/influencers/',
            serialize: (resource) => this.serialize(resource, 'microwaveInfluencer'),
            queryParams: request.queryParams,
        });
    });

    server.get('/api/microwave/influencers/:id/', function (this: RouteHandlerContext, schema, request) {
        const creator = schema.find('microwaveInfluencer', request.params.id);

        if (!creator) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return creator;
    });

    server.patch('/api/microwave/influencers/:id/', function (this: RouteHandlerContext, schema, request) {
        const creator = schema.find('microwaveInfluencer', request.params.id);
        if (!creator) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        creator.update(JSON.parse(request.requestBody));
        return creator;
    });
}
