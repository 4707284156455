import { ModalBase, ModalBaseProps } from '@round/ui-kit';
import styles from './FullscreenModal.module.css';
import cn from 'classnames';

type Props = ModalBaseProps;

const FullscreenModal = ({ className, overlayClassName, ...props }: Props) => {
    return (
        <ModalBase
            className={cn(styles.modal, className)}
            overlayClassName={cn(styles.overlay, overlayClassName)}
            {...props}
        >
            {props.children}
        </ModalBase>
    );
};

export default FullscreenModal;
