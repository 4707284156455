import { influencer } from '@round/api';
import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { PublicReportContext } from '../PublicReportContext';
import { useCallback } from 'react';

export default function usePlatformHeadlineStats(platform: influencer.Platform) {
    const stats = useNonNullContextSelector(PublicReportContext, ([values]) => values.platformHeadlineStats[platform]);
    const dispatch = useNonNullContextSelector(PublicReportContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (publicReportId: string, requestInit?: RequestInit) => {
            dispatch({ type: 'loadInfluencerAggregatedPostStatsByPlatform', payload: platform });

            try {
                const response = await influencer.postAggregatedPostStats(
                    {
                        platform,
                        public_report_id: publicReportId,
                    },
                    influencer.addPublicReportIdToRequestInit(publicReportId, requestInit || {})
                );

                if (response.status === 201) {
                    dispatch({
                        type: 'influencerAggregatedPostStatsByPlatformLoaded',
                        payload: { platform, stats: response.data },
                    });
                    return response;
                }

                const errorMessage = Object.values(response.data).flat().join('. ');
                dispatch({
                    type: 'errorLoadingInfluencerAggregatedPostStatsByPlatform',
                    payload: { platform, error: errorMessage },
                });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({
                    type: 'errorLoadingInfluencerAggregatedPostStatsByPlatform',
                    payload: { platform, error: 'Could not load stats' },
                });
                throw e;
            }
        },
        [dispatch, platform]
    );

    return {
        ...stats,
        fetchData,
    };
}
