import { call } from '../../helpers';
import { ApiResponse, ApiResponseError } from '../../types';
import { AgeRange, EmailLanguage, Gender } from '../types';

type AccountIdentifierData = {
    tiktok_user_identifier: string;
    instagram_user_identifier: string;
    youtube_channel_identifier: string;
};

type PostCampaignContestantData = {
    age_range: AgeRange;
    campaign_id: string;
    email_address: string;
    gender: Gender;
    language: EmailLanguage;
    location_id: number;
} & Partial<AccountIdentifierData>;

type PostCampaignContestantResponse =
    | ApiResponse<null, 201>
    | ApiResponse<
          ApiResponseError<
              PostCampaignContestantData & {
                  non_field_errors?: string[];
              }
          >,
          400
      >
    | ApiResponse<string[], 400>;

export async function postCampaignContestant(
    data: PostCampaignContestantData
): Promise<PostCampaignContestantResponse> {
    const response = await call(
        `/api/fandom/campaign-contestants/`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        },
        {
            shouldBypassTokens: true,
        }
    );

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to create campaign contestant');
    }

    return {
        status: 201,
        data: null,
    };
}
