import { Context } from '@fluentui/react-context-selector';
import { InstagramPost, getInstagramPosts } from '@round/api';
import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { makeErrorStateSlice, makeLoadingStateSlice, makeSuccessStateSlice } from 'utility/dataState';

export type State = {
    [videoId: number]: DataState<InstagramPost | null> | undefined;
};

export const initialState: State = {};

export type Actions =
    | ReducerActionWithPayload<'loadInstagramPost', number[]>
    | ReducerActionWithPayload<'instagramPostsSuccess', { postIds: number[]; posts: InstagramPost[] }>
    | ReducerActionWithPayload<'instagramPostsErrorLoading', { postIds: number[]; error: string }>;

export const reducer = createReducer<State, Actions>({
    loadInstagramPost: (state, { payload: postIds }) => {
        const loadingData = makeLoadingStateSlice<number, InstagramPost | null>(postIds);

        return { ...state, ...loadingData };
    },
    instagramPostsSuccess: (state, { payload: { postIds, posts } }) => {
        const successData = makeSuccessStateSlice<number, InstagramPost | null>(
            postIds,
            (id) => posts.find((post) => post.id === id) || null
        );

        return { ...state, ...successData };
    },
    instagramPostsErrorLoading: (state, { payload: { postIds, error } }) => {
        const errorData = makeErrorStateSlice<number, InstagramPost | null>(postIds, error);

        return { ...state, ...errorData };
    },
});

export function makeInstagramPostDataHook<Value extends BaseDataHookContextValue<Actions>>(
    context: Context<Value>,
    selector: ContextSelector<Value, State>
) {
    return function useInstagramPost() {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (postIds: number[], requestInit?: RequestInit) => {
                if (!postIds.length) {
                    dispatch({ type: 'instagramPostsSuccess', payload: { postIds: [], posts: [] } });
                    return;
                }

                try {
                    dispatch({ type: 'loadInstagramPost', payload: postIds });
                    const response = await getInstagramPosts(
                        { id: postIds.toString(), page_size: postIds.length },
                        requestInit
                    );

                    if (response.status === 200) {
                        dispatch({
                            type: 'instagramPostsSuccess',
                            payload: { postIds, posts: response.data.results },
                        });
                        return response;
                    }

                    dispatch({
                        type: 'instagramPostsErrorLoading',
                        payload: { postIds, error: 'Could not get instagram posts' },
                    });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({
                        type: 'instagramPostsErrorLoading',
                        payload: { postIds, error: 'Could not get instagram posts' },
                    });
                    throw e;
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
}
