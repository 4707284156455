import styles from './ReportNotFound.module.css';
import { ReactComponent as RoundLogoShort } from 'assets/brand/LogoShort.svg';
import { ReactComponent as LinkBroken } from 'assets/whitelabel/LinkBroken.svg';

const ReportNotFound = () => {
    return (
        <>
            <header className={styles.header}>
                <RoundLogoShort className={styles.roundLogo} />
            </header>

            <main className={styles.main}>
                <LinkBroken className={styles.linkIcon} />
                <h1 className={styles.title}>We couldn't find this report</h1>
                <p className={styles.copy}>Please double check the link</p>
            </main>
        </>
    );
};

export default ReportNotFound;
