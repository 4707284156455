import { fetchWithToken } from '../../helpers';
import { ApiResponse, NotFoundResponse } from '../../types';
import { Genre } from './genres.types';

type GetGenresResponse = ApiResponse<{ results: Genre[] }, 200> | NotFoundResponse;

export async function getAllGenres(requestInit?: RequestInit): Promise<GetGenresResponse> {
    const response = await fetchWithToken(`/api/genre-affinity/genres/`, requestInit);

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to fetch Genres');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
