import React from 'react';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import Card from '../../../ui/Card/Card';
import styles from './TiktokInfluencerWideSearchPage.module.css';
import TiktokInfluencerWideSearch from '../../../Modules/TikTok/containers/TiktokInfluencerWideSearch/TiktokInfluencerWideSearch';

const TiktokInfluencerWideSearchPage = () => (
    <>
        <NavBar />
        <Card className={styles.container}>
            <TiktokInfluencerWideSearch />
        </Card>
    </>
);

export default TiktokInfluencerWideSearchPage;
