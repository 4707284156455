import { useState } from 'react';
import styles from './ReferenceCell.module.css';

type Props = {
    initialReference: string;
    readonly: boolean;
    onChange: (value: string) => void;
};

const ReferenceCell = ({ initialReference, readonly, onChange }: Props) => {
    const [reference, setReference] = useState(initialReference);
    const [isEditing, setIsEditing] = useState(false);

    if (!isEditing) {
        return (
            <span
                onDoubleClick={() => {
                    if (!readonly) {
                        setIsEditing(true);
                    }
                }}
            >
                {reference ?? '-'}
            </span>
        );
    }

    const save = () => {
        setIsEditing(false);
        onChange(reference);
    };

    return (
        <textarea
            autoFocus
            className={styles.reference}
            value={reference}
            onChange={(e) => setReference(e.target.value)}
            onBlur={save}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    save();
                }
            }}
        />
    );
};

export default ReferenceCell;
