import { getYoutubeInfluencerPlanStats } from '@round/api';
import useNonNullContext from 'Hooks/useNonNullContext';
import { useCallback, useContext } from 'react';
import { InfluencerPlanContext } from '../../InfluencerPlanContext';
import { YoutubeCreatorsDispatchContext, YoutubeCreatorsStateContext } from '../YoutubeCreatorsContext';

export function useYoutubePlanStats() {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const state = useNonNullContext(YoutubeCreatorsStateContext);
    const dispatch = useNonNullContext(YoutubeCreatorsDispatchContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (!influencerPlan?.id) {
                return;
            }

            try {
                dispatch({ type: 'loadYoutubeInfluencerPlanStats' });
                const response = await getYoutubeInfluencerPlanStats(influencerPlan.id, requestInit);
                if (response.status === 404) {
                    dispatch({ type: 'setYoutubeInfluencerPlanStatsLoadingError', payload: response.data.detail });
                    return;
                }

                dispatch({ type: 'setIsYoutubeInfluencerPlanStatsInitialized', payload: response.data });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'setYoutubeInfluencerPlanStatsLoadingError',
                    payload: "Sorry, we're experiencing technical issues",
                });
            }
        },
        [dispatch, influencerPlan?.id]
    );

    return {
        init,
        isInitialized: state.isYoutubeInfluencerPlanStatsInitialized,
        loadingError: state.youtubeInfluencerPlanStatsLoadingError,
        isLoading: state.isYoutubeInfluencerPlanStatsLoading,
        stats: state.youtubeInfluencerPlanStats,
    };
}
