import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './TableRow.module.css';
import { FCWithChildren } from '../../utility/utility.types';

const TableRow: FCWithChildren<{ linkTo: string }> = (props) => {
    const navigate = useNavigate();

    return (
        <tr className={styles.row} onClick={() => navigate(`/admin/${props.linkTo}`)}>
            <td className={styles.cell}>{props.children}</td>
        </tr>
    );
};

export default TableRow;
