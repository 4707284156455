import React, { useCallback, useMemo, useState } from 'react';
import useNonNullContext from '../../../../../../../../Hooks/useNonNullContext';
import { MediaPlanOptionsContext } from '../../../../../contexts/MediaPlanOptionsContext/MediaPlanOptionsContext';
import { CustomAudienceOption } from '../../../../../types/Facebook.types';
import { showNotification } from '../../../../../../../../helpers';
import FacebookTargetingCustomAudienceSelect from './FacebookTargetingCustomAudienceSelect/FacebookTargetingCustomAudienceSelect';
import RefreshButtonSm from '../../../../../../../../ui/RefreshButtonSm/RefreshButtonSm';
import Button from '../../../../../../../../ui/Buttons/Button/Button';
import styles from './FacebookTargetingCustomAudience.module.css';
import { AdvertisingContext } from '../../../../../contexts/AdvertisingContext/AdvertisingContext';
import { TargetingFormProps } from '../FacebookTargetingModalForm/FacebookTargetingModalForm';
import { FCWithChildren } from '../../../../../../../../utility/utility.types';

type FacebookTargetingCustomAudienceProps = {
    onChange: (customAudiences: TargetingFormProps['customAudiences']) => void;
    value: TargetingFormProps['customAudiences'];
    error: any;
};

const FacebookTargetingCustomAudience: FCWithChildren<FacebookTargetingCustomAudienceProps> = ({ value, onChange }) => {
    const [displayExcludedAudiences, setDisplayExcludedAudiences] = useState(value.excluded.length > 0);
    const [refreshCustomAudiencesLoading, setRefreshCustomAudiencesLoading] = useState(false);
    const [mediaPlanContext] = useNonNullContext(AdvertisingContext);
    const mediaPlanOptionsContext = useNonNullContext(MediaPlanOptionsContext);
    const clientId = mediaPlanContext.mediaPlan?.release.brand.client.id;
    const refreshDisabled = useMemo(
        () =>
            mediaPlanOptionsContext.customAudiencesRefreshedRecently ||
            mediaPlanOptionsContext.errorLoadingCustomAudienceRefreshRequests ||
            !clientId,
        [
            clientId,
            mediaPlanOptionsContext.customAudiencesRefreshedRecently,
            mediaPlanOptionsContext.errorLoadingCustomAudienceRefreshRequests,
        ]
    );

    const refreshButtonHint = useMemo(() => {
        if (mediaPlanOptionsContext.errorLoadingCustomAudienceRefreshRequests) {
            return 'Cannot refresh right now';
        }

        return mediaPlanOptionsContext.customAudiencesRefreshedRecently
            ? 'Currently refreshing'
            : 'Refresh custom audiences';
    }, [
        mediaPlanOptionsContext.customAudiencesRefreshedRecently,
        mediaPlanOptionsContext.errorLoadingCustomAudienceRefreshRequests,
    ]);

    const customAudiencesOptions = useMemo<CustomAudienceOption[]>(
        () =>
            mediaPlanOptionsContext.customAudiences
                .map((a) => ({
                    value: a.id,
                    description: a.description,
                    subtype: a.subtype,
                    label: a.name,
                }))
                .filter(
                    (customAudience) =>
                        !value.excluded.find((excludedAudience) => excludedAudience.value === customAudience.value)
                )
                .filter((o) => !value.included.find((i) => i.value === o.value)),
        [mediaPlanOptionsContext.customAudiences, value.excluded, value.included]
    );

    const onIncludedChange = useCallback(
        (customAudiences: CustomAudienceOption[]) => onChange({ ...value, included: customAudiences }),
        [onChange, value]
    );

    const onExcludedChange = useCallback(
        (customAudiences: CustomAudienceOption[]) => onChange({ ...value, excluded: customAudiences }),
        [onChange, value]
    );

    const onRefreshCustomAudiences = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            e.preventDefault();
            if (!clientId) {
                return;
            }

            try {
                setRefreshCustomAudiencesLoading(true);
                await mediaPlanOptionsContext.refreshCustomAudiences();
                showNotification('Successfully refreshed custom audiences', 'info');
            } catch {
                showNotification('Refresh custom audiences was not successful', 'error');
            } finally {
                setRefreshCustomAudiencesLoading(false);
            }
        },
        [clientId, mediaPlanOptionsContext]
    );

    return (
        <div className={styles.customAudiencesFieldContainer}>
            <FacebookTargetingCustomAudienceSelect
                options={customAudiencesOptions}
                loading={mediaPlanOptionsContext.customAudiencesLoading}
                errorLoadingCustomAudiences={mediaPlanOptionsContext.errorLoadingCustomAudiences}
                value={value.included}
                onChange={onIncludedChange}
                label="INCLUDE people who are in at least ONE of the following"
                iconRight={() => (
                    <RefreshButtonSm
                        disabled={refreshDisabled}
                        loading={refreshCustomAudiencesLoading}
                        onClick={onRefreshCustomAudiences}
                        hint={refreshButtonHint}
                    />
                )}
            />

            {!displayExcludedAudiences && (
                <div>
                    <Button
                        className={styles.button}
                        type="filled"
                        color="black"
                        onClick={() => setDisplayExcludedAudiences(true)}
                    >
                        Exclude
                    </Button>
                </div>
            )}

            {displayExcludedAudiences && (
                <FacebookTargetingCustomAudienceSelect
                    options={customAudiencesOptions}
                    loading={mediaPlanOptionsContext.customAudiencesLoading}
                    errorLoadingCustomAudiences={mediaPlanOptionsContext.errorLoadingCustomAudiences}
                    label="EXCLUDE people who are in at least ONE of the following"
                    value={value.excluded}
                    onChange={onExcludedChange}
                />
            )}
        </div>
    );
};

export default FacebookTargetingCustomAudience;
