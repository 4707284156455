import React from 'react';
import styles from './Badge.module.css';
import cn from 'classnames';

type BadgeProps = { label: string; disabled?: boolean; onClick?: () => void };

const Badge = (props: BadgeProps) => {
    const handleClick = () => {
        if (typeof props.onClick === 'function' && !props.disabled) {
            props.onClick();
        }
    };

    return (
        <div
            onClick={handleClick}
            className={cn(styles.badge, { [styles.clickable]: typeof props.onClick === 'function' && !props.disabled })}
        >
            {props.label}
        </div>
    );
};

export default Badge;
