import { PaginatedApiResponseData, creatorbase } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<Pick<PaginatedApiResponseData<creatorbase.Brand>, 'results'>>;

const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export function useBrands() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (params: creatorbase.GetBrandsParams, requestInit?: RequestInit) => {
        setState((prevState) => ({
            data: prevState.data,
            error: null,
            status: 'loading',
        }));

        try {
            const response = await creatorbase.getBrands(params, requestInit);

            if (response.status === 404 || response.status === 403) {
                setState((prevState) => ({
                    data: prevState.data,
                    error: response.data.detail,
                    status: 'error',
                }));
                return response;
            }

            setState((prevState) => ({
                data: {
                    results: [...(prevState.data?.results ?? []), ...response.data.results],
                },
                error: null,
                status: 'success',
            }));
            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                setState((prevState) => ({
                    data: prevState.data,
                    error: null,
                    status: 'idle',
                }));
                throw e;
            }

            const errorMessage = e instanceof Error ? e.message : 'Could not get artists';
            setState((prevState) => ({
                data: prevState.data,
                error: errorMessage,
                status: 'error',
            }));
            throw e;
        }
    }, []);

    const reset = useCallback(() => {
        setState(initialState);
    }, []);

    return { fetchData, reset, ...state };
}
