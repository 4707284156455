import { TiktokMicroInfluencerPostLiveStats } from './liveStats.types';
import { ApiResponse } from '../../../types';
import { fetchWithToken } from '../../../helpers';

export async function getTiktokMicroInfluencerPostLiveStats(
    planId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<TiktokMicroInfluencerPostLiveStats[], 200>> {
    const response = await fetchWithToken(
        `/api/advertising/creator-plans/${planId}/tiktok-micro-influencer-post-live-stats/`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Could not fetch tiktok micro influencer post live stats');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
