import Button from 'ui/Button/Button';

const ButtonPlayground = () => {
    // isLoading is false for easier override from React DevTools
    return (
        <div style={{ display: 'flex', columnGap: '1rem' }}>
            <Button appearance="primary" isLoading={false}>
                Primary
            </Button>
            <Button appearance="contrast" isLoading={false}>
                Contrast
            </Button>
            <Button appearance="outlined" isLoading={false}>
                Outlined
            </Button>
            <Button appearance="ghost" isLoading={false}>
                Ghost
            </Button>
        </div>
    );
};

export default ButtonPlayground;
