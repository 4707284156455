import { AppServer } from '../mirage';
import * as emailTemplateMirage from './emailTemplates/emailTemplates.mirage';

export const models = {
    ...emailTemplateMirage.models,
};

export const serializers = {
    ...emailTemplateMirage.serializers,
};

export const factories = {
    ...emailTemplateMirage.factories,
};

export function handleRequests(server: AppServer) {
    emailTemplateMirage.handleRequests(server);
}
