import { IconProps } from '../Icon.types';

const GoogleIcon = ({ width, height, onClick, className }: IconProps) => {
    return (
        <svg
            role="img"
            aria-label="Google icon"
            onClick={onClick}
            className={className}
            width={width}
            height={height}
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.5 18.5C14.4706 18.5 18.5 14.4706 18.5 9.5C18.5 4.52944 14.4706 0.5 9.5 0.5C4.52944 0.5 0.5 4.52944 0.5 9.5C0.5 14.4706 4.52944 18.5 9.5 18.5Z"
                fill="#727F8F"
            />
            <path
                d="M15.0999 8.44996H9.61654V10.6083H12.8249C12.7665 11.1333 12.4165 11.95 11.6582 12.475C11.1915 12.825 10.4915 13.0583 9.61654 13.0583C8.09987 13.0583 6.7582 12.0666 6.29154 10.6083C6.17487 10.2583 6.11654 9.84996 6.11654 9.44163C6.11654 9.03329 6.17487 8.62496 6.29154 8.27496C6.34987 8.15829 6.34987 8.04163 6.4082 7.98329C6.9332 6.75829 8.1582 5.88329 9.61654 5.88329C10.7249 5.88329 11.4249 6.34996 11.8915 6.75829L13.5249 5.12496C12.5332 4.24996 11.1915 3.66663 9.61654 3.66663C7.34154 3.66663 5.3582 4.94996 4.42487 6.87496C4.01654 7.69163 3.7832 8.56663 3.7832 9.49996C3.7832 10.4333 4.01654 11.3083 4.42487 12.125C5.3582 14.05 7.34154 15.3333 9.61654 15.3333C11.1915 15.3333 12.5332 14.8083 13.4665 13.9333C14.5749 12.9416 15.2165 11.425 15.2165 9.61663C15.2165 9.14996 15.1582 8.79996 15.0999 8.44996Z"
                fill="white"
            />
        </svg>
    );
};

export default GoogleIcon;
