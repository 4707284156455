import { AppServer, RouteHandlerContext } from '../../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { CampaignManagerCampaign } from './campaign.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';

export const models = {
    campaignManagerCampaign: Model as ModelDefinition<CampaignManagerCampaign>,
};

export const serializers = {
    campaignManagerCampaign: makeSerializer<CampaignManagerCampaign>([]),
};

export const factories = {
    campaignManagerCampaign: createFactory<CampaignManagerCampaign>({
        currency: 1,
        client_name(index: number) {
            return `client name ${index}`;
        },
        client_user(index: number) {
            return index;
        },
        client_user_email(index: number) {
            return `client user email ${index}`;
        },
        name(index: number) {
            return `name ${index}`;
        },
        type: 'AUDIO',
    }),
};

export function handleRequests(server: AppServer) {
    const getCampaignsUrl = '/api/apex/campaign-manager/campaign/';
    server.get(getCampaignsUrl, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const campaigns = schema
            .all('campaignManagerCampaign')
            .filter((c) => !search || c.name.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(campaigns, {
            url: getCampaignsUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'campaignManagerCampaign'),
        });
    });

    server.get(`${getCampaignsUrl}/:id/`, function (this: RouteHandlerContext, schema, request) {
        const campaign = schema.find('campaignManagerCampaign', request.params.id);
        if (!campaign) {
            return new Response(404, {}, {});
        }

        return campaign;
    });
}
