import { call, encodeUrlSearchParams } from '../../helpers';
import { isPublicReportRequestInit } from '../../influencer';
import {
    ApiResponse,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { YoutubeVideo } from './videos.types';

export type GetYoutubeVideosParams = Partial<PaginatedRequest & { id: string }>;
type GetYoutubeVideosResponse =
    | ApiResponse<PaginatedApiResponseData<YoutubeVideo>, 200>
    | NotFoundResponse
    | ForbiddenResponse;
export async function getYoutubeVideos(
    params: GetYoutubeVideosParams,
    requestInit?: RequestInit
): Promise<GetYoutubeVideosResponse> {
    const response = await call(`/api/youtube/videos/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens: isPublicReportRequestInit(requestInit),
    });

    if (response.status === 404 || response.status === 403) {
        return { status: response.status, data: await response.json() };
    }

    if (!response.ok) {
        throw new Error('Could not fetch youtube videos');
    }

    return { status: 200, data: await response.json() };
}
