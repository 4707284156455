import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FCWithChildren } from '../../../../utility/utility.types';

const AuthWrapper: FCWithChildren = ({ children }: { children?: ReactNode }) => {
    const authContext = useContext(AuthContext);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    let nextUrl = useMemo(() => {
        const pathname = encodeURIComponent(location.pathname + location.search + location.hash);
        return !pathname.includes('/login/') ? `?next=${pathname}` : '';
    }, [location.hash, location.pathname, location.search]);

    useEffect(() => {
        if (authContext.loading) {
            return;
        } else if (
            !authContext.authenticated &&
            !location.pathname.includes('/confirm-password/') &&
            !location.pathname.includes('/forgot-password/') &&
            !location.pathname.includes('/login/')
        ) {
            navigate(`/login/${nextUrl}`);
        } else {
            setIsAuthenticated(true);
        }
    }, [authContext, location, nextUrl, navigate]);

    return isAuthenticated ? <>{children}</> : null;
};

export default AuthWrapper;
