import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { PaymentMethod } from '../microwave.types';
import { PaymentRequestStatus } from '../paymentRequests';
import { Post } from './posts.types';

export type GetMicrowavePostsParams = Partial<
    PaginatedRequest & {
        campaign_id: number;
        payment_request_status: PaymentRequestStatus;
    }
>;

type GetMicrowavePostsResponse = ApiResponse<PaginatedApiResponseData<Post>, 200> | NotFoundResponse;

export async function getPosts(
    params: GetMicrowavePostsParams,
    requestInit?: RequestInit
): Promise<GetMicrowavePostsResponse> {
    const response = await fetchWithToken(`/api/microwave/posts/${encodeUrlSearchParams(params)}`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get microwave posts');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getAllPosts(params: GetMicrowavePostsParams, requestInit?: RequestInit) {
    return fetchAll<Post>(`/api/microwave/posts/${encodeUrlSearchParams(params)}`, requestInit);
}

export type PostMicroPostData = Pick<Post, 'campaign_id' | 'influencer_id'> & {
    post_url: string;
    preferred_payment_method: PaymentMethod;
    paypal_email_address?: string;
    venmo_email_address?: string;
    payment_details?: string;
};

type PostMicroPostResponse =
    | ApiResponse<Post, 201>
    | ApiResponse<ApiResponseError<PostMicroPostData | string[]>, 400>
    | NotFoundResponse;

export async function postMicroPost(data: PostMicroPostData): Promise<PostMicroPostResponse> {
    const response = await fetchWithToken('/api/microwave/posts/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create post');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}
