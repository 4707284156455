import styles from './CampaignDetails.module.css';
import { ReactComponent as Arrow } from '../../../../../assets/ArrowRight.svg';

type Props = {
    releaseName: string;
    artistName: string;
    imageUrl: string;
    audioUrl: string;
};

const CampaignDetails = ({ releaseName, artistName, imageUrl, audioUrl }: Props) => {
    return (
        <article className={styles.container} style={{ backgroundImage: `url(${imageUrl})` }}>
            <div>Campaign Song</div>
            <div>
                <div className={styles.row}>
                    <span className={styles.title}>{artistName}</span>
                    <a href={audioUrl} target="_blank" rel="noreferrer">
                        <Arrow className={styles.linkIcon} />
                    </a>
                </div>
                <div className={styles.subtitle}>{releaseName}</div>
            </div>
        </article>
    );
};

export default CampaignDetails;
