import { DataState } from 'App.types';
import { useCallback, useState } from 'react';
import { getInstagramInfluencerPosts, GetInstagramInfluencerPostsParams } from '../api/Instagram.api';
import { InstagramInfluencerPost } from '../types/Instagram.types';

type State = DataState<{ data: InstagramInfluencerPost[]; count: number }>;

const initialState: State = { data: null, error: null, status: 'idle' };

export default function useInstagramInfluencerPosts() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (params: GetInstagramInfluencerPostsParams, requestInit?: RequestInit) => {
        setState((prev) => ({ data: prev.data, error: null, status: 'loading' }));

        try {
            const response = await getInstagramInfluencerPosts(params, requestInit);
            setState((prev) => ({
                data: {
                    data: prev.data?.data.concat(response.data.results) ?? response.data.results,
                    count: response.data.count,
                },
                error: null,
                status: 'success',
            }));
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            setState((prev) => ({
                data: prev.data,
                error: 'Could not fetch instagram influencer posts',
                status: 'error',
            }));

            throw e;
        }
    }, []);

    const reset = useCallback(() => setState(initialState), []);

    return {
        ...state,
        fetchData,
        reset,
    };
}
