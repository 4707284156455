import React, { useMemo, useState } from 'react';
import { AggregatedAudioData } from '../helpers';
import PaginationTable from '../../../../../../../ui/PaginationTable/PaginationTable';
import { Column } from 'react-table';
import Skeleton from '../../../../../../../ui/Skeleton/Skeleton';
import { numberWithCommas } from '../../../../../../../helpers';
import styles from './AudiosUsedTable.module.css';

type AudiosUsedTableProps = {
    data: AggregatedAudioData[];
    loading: boolean;
    errorLoading: boolean;
};

const AudiosUsedTable = ({ errorLoading, loading, data }: AudiosUsedTableProps) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const columns = useMemo<Column<AggregatedAudioData>[]>(
        () => [
            {
                Header: 'Audio name',
                accessor: 'audio_name',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Audio type',
                accessor: 'type',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Number of posts',
                accessor: 'numberOfPosts',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Average video plays',
                accessor: 'video_plays',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Average post likes',
                accessor: 'post_likes',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Average post comments',
                accessor: 'post_comments',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Average post shares',
                accessor: 'post_shares',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Average post saves',
                accessor: 'post_saves',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{typeof value === 'number' ? numberWithCommas(value) : '-'}</>;
                },
            },
        ],
        [loading]
    );

    return (
        <PaginationTable
            tableClassName={styles.table}
            disableSortBy
            manualPagination={false}
            loading={loading}
            data={data}
            columns={columns}
            count={data.length}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={(pageSize) => setPageSize(pageSize ?? 10)}
            noDataLabel={errorLoading ? 'Sorry, we are experiencing some technical issues' : 'No Data'}
        />
    );
};

export default AudiosUsedTable;
