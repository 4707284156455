import { creatorbase } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<creatorbase.AggregatedPostStats>;

const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export function useAggregatedPostStats() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (params: creatorbase.AggregatedPostStatsPostData) => {
        try {
            const response = await creatorbase.postGetAggregatedPostStats(params);

            if (response.status !== 200) {
                setState((prevState) => ({
                    data: prevState.data,
                    error: 'Could not get stats',
                    status: 'error',
                }));
                return response;
            }

            setState({
                data: response.data,
                error: null,
                status: 'success',
            });
            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                setState((prevState) => ({
                    data: prevState.data,
                    error: null,
                    status: 'idle',
                }));
                throw e;
            }

            const errorMessage = e instanceof Error ? e.message : 'Could not get stats';
            setState((prevState) => ({
                data: prevState.data,
                error: errorMessage,
                status: 'error',
            }));
            throw e;
        }
    }, []);

    return {
        ...state,
        fetchData,
    };
}
