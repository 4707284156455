import { Select as BaseSelect, SelectProps } from '@round/ui-kit';
import { mergeStyles, OptionTypeBase, SelectComponentsConfig, StylesConfig } from 'react-select';
import { ReactComponent as ArrowIcon } from 'assets/ArrowIcon.svg';
import Option from './components/Option';

type Props<T extends OptionTypeBase, IsMulti extends boolean = false> = SelectProps<T, IsMulti>;

const baseStyles: StylesConfig = {
    control: (base, { isFocused, isDisabled, isMulti, hasValue }) => ({
        ...base,
        color: isDisabled ? '#8D8D8D' : '#111111',
        background: isDisabled ? '#F3F4F6' : 'white',
        borderRadius: '0.75rem',
        border: isFocused ? '1px solid #FE002B' : '1px solid #D1D5DB',
        boxShadow: isFocused ? '0 0 0 3px rgba(254, 0, 43, 0.10)' : 'none',
        padding: isMulti && hasValue ? '0.375rem 0.875rem' : '0.625rem 0.875rem',
        ':hover': {
            border: isFocused ? '1px solid #FE002B' : '1px solid #D1D5DB',
            boxShadow: isFocused ? '0 0 0 3px rgba(254, 0, 43, 0.10)' : '0 0 0 3px rgba(156, 163, 175, 0.10)',
        },
    }),
    valueContainer: (base, { isMulti }) => ({
        ...base,
        padding: 0,
        flexWrap: isMulti ? 'nowrap' : 'wrap',
        overflow: isMulti ? 'auto' : 'hidden',
        columnGap: isMulti ? '0.375rem' : 'unset',
        overflowY: 'hidden',
        '::-webkit-scrollbar': {
            width: '0.375rem',
            height: '0.375rem',
            background: 'transparent',
        },
        '::-webkit-scrollbar-thumb': {
            background: '#D1D5DB',
            borderRadius: '0.5rem',
        },
    }),
    menu: (base) => ({
        ...base,
        borderRadius: '0.75rem',
        padding: '0.875rem',
        boxShadow: `0px 3px 20px 0px rgba(87, 87, 87, 0.10),
                    0px 29px 29px 0px rgba(87, 87, 87, 0.09),
                    0px 66px 39px 0px rgba(87, 87, 87, 0.05),
                    0px 117px 47px 0px rgba(87, 87, 87, 0.01),
                    0px 183px 51px 0px rgba(87, 87, 87, 0.00)`,
    }),
    menuList: (base) => ({
        ...base,
        fontFamily: 'Inter, sans-serif',
        lineHeight: 1.25,
        color: '#111111',
        '::-webkit-scrollbar': {
            width: '0.375rem',
            height: '0.375rem',
            background: 'transparent',
        },
        '::-webkit-scrollbar-thumb': {
            background: '#D1D5DB',
            borderRadius: '0.5rem',
        },
    }),
    indicatorsContainer: (base, state) => {
        const { menuIsOpen } = state.selectProps;
        return {
            ...base,
            alignSelf: 'unset',
            width: '0.875rem',
            height: '0.875rem',
            color: '#9CA3AF',
            transform: menuIsOpen ? 'rotate(0deg)' : 'rotate(180deg)',
            justifyContent: 'center',
        };
    },
    option: (base) => ({
        ...base,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: '0.5rem',
        background: 'white',
        ':hover': {
            background: '#F3F4F6',
        },
    }),
    multiValue: (base) => ({
        ...base,
        borderRadius: '20px',
        color: '#111111',
        padding: '4px 6px 4px 10px',
        background: '#E5E7EB',
        margin: 0,
        minWidth: 'max-content',
    }),
    multiValueRemove: (base) => ({
        ...base,
        cursor: 'pointer',
        ':hover': {
            background: 'inherit',
        },
    }),
    multiValueLabel: (base) => ({
        ...base,
        padding: 0,
        paddingLeft: 0,
    }),
};

const DropdownIndicator = () => <ArrowIcon />;

const Select = <T extends OptionTypeBase, IsMulti extends boolean = false>({
    styles,
    components,
    ...props
}: Props<T, IsMulti>) => {
    const mergedStyles = styles ? mergeStyles(baseStyles, styles) : baseStyles;
    const overriddenComponents: SelectComponentsConfig<T, IsMulti> = {
        DropdownIndicator,
        Option,
        ...components,
    };

    return <BaseSelect styles={mergedStyles} components={overriddenComponents} {...props} />;
};

export default Select;
