import PlannedIcon from '../icons/PlannedIcon';
import { Project } from '../../Projects.types';
import LiveIcon from '../icons/LiveIcon';
import CompletedIcon from '../icons/CompletedIcon';

const icons: Record<Project['status'], typeof PlannedIcon | null> = {
    live: LiveIcon,
    completed: CompletedIcon,
    planned: PlannedIcon,
    no_plans: null,
};

type StatusIconProps = {
    status: Project['status'];
    title: string;
};

const StatusIcon = ({ status, title }: StatusIconProps) => {
    const Icon = icons[status];
    if (!Icon) {
        return null;
    }

    const width = status === 'planned' ? 17 : 20;
    const height = status === 'planned' ? 17 : 20;

    return <Icon width={width} height={height} title={title} />;
};

export default StatusIcon;
