import React, { useMemo, useState } from 'react';
import { StylesConfig, ValueType } from 'react-select';
import {
    AdvancedFilterCondition,
    AdvancedFilterConditionTypeOption,
    conditionTypeOptions,
} from './AdvancedFilter.helpers';
import { GenericDropdownOption } from '../../utility/utility.types';
import { ReactComponent as FilterIcon } from '../../assets/icons/Filter.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';
import styles from './AdvancedFilter.module.css';
import cn from 'classnames';
import Popover from '../Popover/Popover';
import Card from '../Card/Card';
import Select from '../DataEntry/Select/Select';
import Button from '../Buttons/Button/Button';

const typeSelectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        height: '100%',
    }),
    valueContainer: (base) => ({
        ...base,
        paddingLeft: '0.25rem',
        paddingRight: '0.25rem',
        minWidth: '6.25rem',
    }),
};

const tagsSelectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        height: '100%',
    }),
    valueContainer: (base) => ({
        ...base,
        flexWrap: 'nowrap',
        padding: '0 1rem',
    }),
    multiValue: (base) => ({
        ...base,
        minWidth: 'unset',
        margin: '0 0.1875rem 0 0',
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: 0,
    }),
};

type Props<T extends GenericDropdownOption<any> = GenericDropdownOption<any>> = {
    conditions: AdvancedFilterCondition<ValueType<T, true>>[];
    onChange: (value: AdvancedFilterCondition<ValueType<T, true>>[]) => void;
    conditionOptions: AdvancedFilterConditionTypeOption[];
    options: T[];
    optionsLoading?: boolean;
    getLabel: (isFiltered: boolean) => string;
    className?: string;
};

const AdvancedFilter = <T extends GenericDropdownOption<any> = GenericDropdownOption<any>>({
    options,
    optionsLoading,
    onChange,
    conditions,
    getLabel,
    className,
    conditionOptions = conditionTypeOptions,
}: Props<T>) => {
    const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
    const filters = useMemo(() => {
        return Array.from(new Set(conditions.reduce<T[]>((acc, condition) => acc.concat(condition.value ?? []), [])));
    }, [conditions]);

    const isFiltered = filters.length > 0;
    return (
        <>
            <div ref={setAnchor} className={cn(styles.filter, className)}>
                <FilterIcon className={cn(styles.filterIcon, { [styles.active]: isFiltered })} />
                {getLabel(isFiltered)}
                <button
                    className={cn(styles.clearFilterButton, { [styles.hidden]: !isFiltered })}
                    onClickCapture={(e) => {
                        e.stopPropagation();
                        onChange([]);
                    }}
                >
                    <CloseIcon width="70%" height="70%" />
                </button>
            </div>
            <Popover
                anchorElement={anchor}
                showOn="click"
                options={{ placement: 'bottom-start', modifiers: [{ name: 'offset', options: { offset: [0, 10] } }] }}
            >
                <Card className={styles.card}>
                    <div className={styles.conditionsList}>
                        {conditions.map((condition, index) => (
                            <div key={index} className={styles.conditionContainer}>
                                <Select
                                    styles={typeSelectStyles}
                                    isSearchable={false}
                                    menuPortalTarget={null}
                                    className={styles.typeSelect}
                                    options={conditionOptions}
                                    value={conditionOptions.find((o) => o.value === condition.type)}
                                    onChange={(value: ValueType<AdvancedFilterConditionTypeOption, false>) => {
                                        const newValue = conditions.map((item, i) => {
                                            if (i === index) {
                                                return {
                                                    type: value?.value!,
                                                    value: condition.value,
                                                };
                                            }

                                            return item;
                                        });

                                        onChange(newValue);
                                    }}
                                />

                                <Select
                                    styles={tagsSelectStyles}
                                    className={styles.tagsSelect}
                                    menuPortalTarget={null}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    isLoading={optionsLoading}
                                    options={options}
                                    value={condition.value}
                                    onChange={(value: ValueType<T, true>) => {
                                        const newValue = conditions.map((item, i) => {
                                            if (i === index) {
                                                return {
                                                    type: condition.type,
                                                    value: value,
                                                };
                                            }

                                            return item;
                                        });

                                        onChange(newValue);
                                    }}
                                />

                                <Button
                                    className={styles.removeConditionButton}
                                    onClick={() => {
                                        onChange(conditions.filter((item, i) => i !== index));
                                    }}
                                >
                                    <CloseIcon width={12} height={12} />
                                </Button>
                            </div>
                        ))}
                    </div>

                    <Button
                        className={styles.addConditionButton}
                        onClick={() => onChange(conditions.concat({ type: 'any', value: [] }))}
                    >
                        + Add condition
                    </Button>
                </Card>
            </Popover>
        </>
    );
};

export default AdvancedFilter;
