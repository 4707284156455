import React, { useState } from 'react';
import useInstagramInfluencerPlanAudios from '../../../contexts/InstagramCreatorsContext/useInstagramInfluencerPlanAudios';
import { showNotification } from '../../../../../../helpers';
import { Button, FormField, Label, Modal, ModalContent, ModalFooter, ModalTitle } from '@round/ui-kit';
import styles from '../InstagramInfluencerPlanAudios.module.css';

type AddAudioModalProps = {
    isOpen: boolean;
    closeModal: () => void;
};

const AddAudioModal = ({ isOpen, closeModal }: AddAudioModalProps) => {
    const { addAudio } = useInstagramInfluencerPlanAudios();
    const [url, setUrl] = useState('');
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const handleClose = () => {
        setIsSubmitLoading(false);
        setUrl('');
        closeModal();
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitLoading(true);
            await addAudio(url);
            handleClose();
            showNotification('Audio added', 'info');
        } catch (e) {
            if (e instanceof Error) {
                showNotification(e.message, 'error');
                return;
            }

            showNotification('Could not add audio to plan', 'error');
        } finally {
            setIsSubmitLoading(false);
        }
    };

    return (
        <Modal className={styles.modal} closeOnOverlayClick isOpen={isOpen} onClose={handleClose}>
            <ModalTitle>Add Audio</ModalTitle>
            <ModalContent>
                <FormField>
                    <Label htmlFor="url">Url</Label>
                    <input
                        name="url"
                        id="url"
                        placeholder="Enter URL"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                    />
                </FormField>
            </ModalContent>
            <ModalFooter align="right">
                <Button type="filled" color="black" loading={isSubmitLoading} onClick={handleSubmit}>
                    Create
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddAudioModal;
