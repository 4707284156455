import React from 'react';
import { default as ReactCalendar, CalendarProps as ReactCalendarProps } from 'react-calendar';
import styles from './Calendar.module.css';
import { ReactComponent as ArrowIcon } from '../../../assets/ArrowIcon.svg';
import moment from 'moment';

export type CalendarProps = {
    value: string | null | undefined;
    onChange: (date: string) => void;
} & Pick<ReactCalendarProps, 'minDate'>;

const Calendar = ({ value, onChange, ...props }: CalendarProps) => {
    const date = typeof value === 'string' ? new Date(value) : new Date();
    return (
        <ReactCalendar
            locale="en-GB"
            maxDetail="month"
            minDetail="year"
            prevLabel={<ArrowIcon className={styles.prevLabel} />}
            nextLabel={<ArrowIcon className={styles.nextLabel} />}
            prev2Label={null}
            next2Label={null}
            value={date}
            onChange={(date: Date) => onChange(moment(date).format('YYYY-MM-DD'))}
            {...props}
        />
    );
};

export default Calendar;
