import { Currency } from '@round/api';
import { FieldHookConfig, useField } from 'formik';
import styles from './FormField.module.css';
import cn from 'classnames';
import { Label, SubLabel } from './Label/Label';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';

type Props = {
    currency?: Currency | number;
    label?: string;
    subLabel?: string;
    placeholder?: string;
    className?: string;
} & FieldHookConfig<string> &
    Pick<
        CurrencyInputProps,
        'groupSeparator' | 'decimalSeparator' | 'allowDecimals' | 'decimalScale' | 'decimalsLimit'
    >;

const CurrencyField = ({
    label,
    subLabel,
    className,
    currency,
    placeholder,
    disabled,
    groupSeparator,
    decimalScale,
    decimalSeparator,
    allowDecimals,
    decimalsLimit,
    ...props
}: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const prefix =
        typeof currency === 'number' ? currencies.find((c) => c.id === currency)?.symbol ?? '' : currency?.symbol ?? '';

    const [field, meta, helpers] = useField(props);

    const hasError = !!meta.error && !!meta.touched;

    return (
        <div className={cn(styles.field, className)}>
            <Label htmlFor={props.name}>{label}</Label>
            {subLabel && <SubLabel>{subLabel}</SubLabel>}
            <CurrencyInput
                id={props.name}
                disabled={disabled}
                placeholder={placeholder}
                className={cn(styles.input, { [styles.error]: hasError })}
                groupSeparator={groupSeparator}
                decimalScale={decimalScale}
                decimalSeparator={decimalSeparator}
                allowDecimals={allowDecimals}
                decimalsLimit={decimalsLimit}
                prefix={prefix}
                value={field.value}
                onValueChange={(value) => {
                    helpers.setValue(value ?? '');
                    helpers.setTouched(true);
                }}
            />

            <ErrorMessage name={props.name} />
        </div>
    );
};

export default CurrencyField;
