import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokAudioCollection } from './collection.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokAudioCollection: Model as ModelDefinition<TiktokAudioCollection>,
};

export const serializers = {
    tiktokAudioCollection: makeSerializer<TiktokAudioCollection>([]),
};

export const factories = {
    tiktokAudioCollection: createFactory<TiktokAudioCollection>({
        client(index: number) {
            return index;
        },
        name(index: number) {
            return `name ${index}`;
        },
        tiktok_audio_count: 0,
        users: [],
        picture: null,
    }),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/catalogue/viewsets/catalogue/';
    server.get(rootUrl, function (this: RouteHandlerContext, schema, request) {
        const collections = schema.all('tiktokAudioCollection');
        return buildPaginatedResponse(collections, {
            url: rootUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'tiktokAudioCollection'),
        });
    });

    server.get(`${rootUrl}/:id/`, function (this: RouteHandlerContext, schema, request) {
        const collection = schema.find('tiktokAudioCollection', request.params.id);
        if (!collection) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return collection;
    });

    server.post(rootUrl, function (this: RouteHandlerContext, schema, request) {
        return schema.create('tiktokAudioCollection', JSON.parse(request.requestBody));
    });

    server.patch(`${rootUrl}/:id/`, function (this: RouteHandlerContext, schema, request) {
        const collection = schema.find('tiktokAudioCollection', request.params.id);
        if (!collection) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        collection.update(JSON.parse(request.requestBody));
        return collection;
    });
}
