import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { XeroInvoiceSimple } from './invoice.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';

export const models = {
    xeroInvoice: Model as ModelDefinition<XeroInvoiceSimple>,
};

export const factories = {
    xeroInvoice: createFactory<XeroInvoiceSimple>({
        id(index: number) {
            return index;
        },
        invoice_number(index: number) {
            return `invoice number ${index}`;
        },
        reference(index: number) {
            return `reference ${index}`;
        },
    }),
};
export const serializers = {
    xeroInvoice: makeSerializer<XeroInvoiceSimple>([]),
};

export function handleInvoiceRequests(server: AppServer) {
    const getInvoiceListPath = '/api/xero/receivable-invoices/';
    server.get(getInvoiceListPath, function (this: RouteHandlerContext, schema, request) {
        const { search } = request.queryParams;
        const invoices = schema
            .all('xeroInvoice')
            .filter((i) => !search || i.invoice_number.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(invoices, {
            url: getInvoiceListPath,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'xeroInvoice'),
        });
    });

    server.get(`/api/xero/viewsets/invoice/`, (schema, request) => {
        const { id } = request.params;
        const invoice = schema.find('xeroInvoice', id);

        if (!invoice) {
            return new Response(404, {}, { detail: 'not found' });
        }

        return invoice;
    });
}
