import React, { useState } from 'react';
import {
    GroupProps,
    NamedProps,
    GroupType,
    IndicatorProps,
    OptionTypeBase,
    PlaceholderProps,
    SelectComponentsConfig,
    StylesConfig,
    ValueType,
    components,
    mergeStyles,
} from 'react-select';
import styles from './GroupedSelectionsMultiSelect.module.css';
import { ReactComponent as DropdownIcon } from '../../../../assets/ReactSelectDropdownArrow.svg';
import cn from 'classnames';
import { Select } from '@round/ui-kit';

type GroupLabel = 'selected' | 'unselected';
type IsSelectedOptionGroup<T extends OptionTypeBase> = GroupType<T> & { label: GroupLabel };

export const groupedBySelectedSelectStyles: StylesConfig = {
    menuList: (provided) => ({
        ...provided,
        padding: 0,
    }),
    group: (provided) => ({
        ...provided,
        padding: 0,
    }),
    option: (base, state) => ({
        ...base,
        color: '#252a31',
        backgroundColor: state.isSelected ? '#f1f1f1' : 'transparent',
        ':active': {
            backgroundColor: '#fafafa',
        },
        ':hover': {
            backgroundColor: '#fafafa',
        },
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: 0,
    }),
};

const Group = <OptionType extends OptionTypeBase>(props: GroupProps<OptionType, true>) => {
    const isGroupOpen = props.selectProps.selectedOptionsGroupIsOpen as boolean;
    const isGroupSelectedOptions = (props.label as GroupLabel) === 'selected';

    const toggleIsGroupOpen = () => {
        props.selectProps.setSelectedOptionsGroupIsOpen((prevIsGroupOpen: boolean) => !prevIsGroupOpen);
    };

    if (!isGroupSelectedOptions) {
        return <components.Group {...props} />;
    }

    return (
        <div className={styles.container}>
            <div
                className={cn(styles.groupHeader, { [styles.isOpen]: !isGroupOpen })}
                onClick={() => toggleIsGroupOpen()}
            >
                <div className={styles.labelsContainer}>
                    <span>Selected Options</span>
                    <span className={styles.countLabel}>{props.options.length}</span>
                    <DropdownIcon className={cn({ [styles.rotated]: isGroupOpen })} />
                </div>

                <button className={styles.clearButton} onClick={() => props.clearValue()}>
                    Clear
                </button>
            </div>
            {isGroupOpen && (
                <div className={styles.groupContainer}>
                    <components.Group {...props} />
                </div>
            )}
        </div>
    );
};

const Placeholder = <OptionType extends OptionTypeBase>(props: PlaceholderProps<OptionType, true>) => {
    if (props.selectProps.menuIsOpen) {
        return <components.Placeholder {...props} />;
    }
    const valueLabel = props.selectProps.valueLabel ?? `${props.selectProps.value?.length} selected`;

    return props.selectProps.value?.length ? (
        <div className={styles.valueLabel}>{valueLabel}</div>
    ) : (
        <components.Placeholder {...props} />
    );
};

const ClearIndicator = <OptionType extends OptionTypeBase>(props: IndicatorProps<OptionType, true>) => {
    if (!props.selectProps.menuIsOpen) {
        return <components.ClearIndicator {...props} />;
    }
    return null;
};

type UseReactSelectGroupOptionsBySelectedReturn<T extends OptionTypeBase> = {
    options: IsSelectedOptionGroup<T>[];
};

export const useReactSelectGroupOptionsBySelected = <T extends OptionTypeBase>(
    options: T[] | readonly T[],
    selectedOptions: T[] | ValueType<T, true>
): UseReactSelectGroupOptionsBySelectedReturn<T> => {
    const groupedOptions = React.useMemo(() => {
        const isSelectedOptionsGroup: IsSelectedOptionGroup<T> = {
            label: 'selected',
            options: selectedOptions ?? [],
        };

        const unselectedOptionsGroup: IsSelectedOptionGroup<T> = {
            label: 'unselected',
            options: options?.filter(
                (option) => !(selectedOptions ?? []).map((opt) => opt.value).includes(option.value)
            ),
        };

        return [isSelectedOptionsGroup, unselectedOptionsGroup];
    }, [options, selectedOptions]);

    return {
        options: groupedOptions,
    };
};

type GroupedOptionMultiSelectProps<T extends OptionTypeBase> = Omit<
    NamedProps<T, true>,
    | 'controlShouldRenderValue'
    | 'hideSelectedOptions'
    | 'closeMenuOnSelect'
    | 'isMulti'
    | 'backspaceRemovesValue'
    | 'components'
    | 'options'
    | 'isMenuOpen'
    | 'onMenuClose'
    | 'onMenuOpen'
> & {
    options: T[] | readonly T[];
    components?: Omit<SelectComponentsConfig<T, true>, 'ClearIndicator' | 'Group'>;
    valueLabel?: string;
    [key: string]: any;
};

const GroupedOptionMultiSelect = <T extends OptionTypeBase>({
    options,
    components: componentsProp,
    styles: styleProp,
    valueLabel,
    ...props
}: GroupedOptionMultiSelectProps<T>) => {
    const [selectedOptionsGroupIsOpen, setSelectedOptionsGroupIsOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const baseComponents: Pick<
        SelectComponentsConfig<T, true>,
        'Group' | 'Placeholder' | 'GroupHeading' | 'ClearIndicator' | 'IndicatorSeparator'
    > = {
        Group,
        Placeholder,
        ClearIndicator,
        GroupHeading: () => null,
        IndicatorSeparator: () => null,
    };

    const mergedComponents = { ...baseComponents, ...componentsProp };
    const mergedStyles = styleProp
        ? mergeStyles(groupedBySelectedSelectStyles, styleProp)
        : groupedBySelectedSelectStyles;

    const { options: groupedOptions } = useReactSelectGroupOptionsBySelected(options, props.value);

    return (
        <Select
            options={groupedOptions}
            selectedOptionsGroupIsOpen={selectedOptionsGroupIsOpen}
            setSelectedOptionsGroupIsOpen={setSelectedOptionsGroupIsOpen}
            isMulti={true}
            backspaceRemovesValue={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            controlShouldRenderValue={false}
            components={mergedComponents}
            styles={mergedStyles}
            onMenuClose={() => {
                setSelectedOptionsGroupIsOpen(false);
                setIsMenuOpen(false);
            }}
            onMenuOpen={() => {
                setIsMenuOpen(true);
            }}
            menuIsOpen={isMenuOpen}
            valueLabel={valueLabel}
            {...props}
        />
    );
};

export default GroupedOptionMultiSelect;
