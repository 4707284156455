import React from 'react';
import styles from './MicrowaveInfluencerForm.module.css';
import FormField from 'ui-new/formik/FormField';
import SelectField from 'ui-new/formik/SelectField';
import { Form, useFormikContext } from 'formik';
import { ValueType } from 'react-select';
import { GenericDropdownOption } from '../../../../../App.types';
import * as yup from 'yup';
import { useInstagramUsersSelect } from 'Modules/Instagram/hooks/selectHooks/useInstagramUsersSelect';
import useTiktokUserSelect, { TiktokUserOption } from 'Modules/TikTok/hooks/useTiktokUserSelect';
import omit from 'lodash/omit';
import Button from 'ui/Button/Button';
import useYoutubeChannelSelect, { YoutubeChannelOption } from 'Modules/Youtube/hooks/useYoutubeChannelSelect';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';

export type MicrowaveInfluencerFormValues = {
    tiktokUser: ValueType<TiktokUserOption, false>;
    instagramUser: ValueType<GenericDropdownOption<number>, false>;
    youtubeChannel: ValueType<YoutubeChannelOption, false>;
    email: string;
    instagramUserIdentifier?: string;
    isInstagramUserCreateMode?: boolean;
    youtubeChannelIdentifier?: string;
    isYoutubeChannelCreateMode?: boolean;
};

export const microwaveInfluencerFormValidationSchema = yup.object().shape({
    email: yup.string().email().required(),
    tiktokUser: yup
        .object({
            value: yup.number(),
            label: yup.string(),
        })
        .nullable()
        .required(),
    instagramUser: yup
        .object({
            value: yup.number(),
            label: yup.string(),
        })
        .nullable(),
    youtubeChannel: yup
        .object({
            value: yup.number(),
            label: yup.string(),
        })
        .nullable(),
});

const MicrowaveInfluencerForm = () => {
    const featureFlags = useFeatureFlags();
    const { values, setFieldValue, setFieldError } = useFormikContext<MicrowaveInfluencerFormValues>();

    const { props: tiktokUserSelectProps } = useTiktokUserSelect(undefined, {
        initOptionsOnMenuOpen: true,
    });

    const { props: instagramUserSelectProps } = useInstagramUsersSelect({
        initOn: 'menuOpen',
    });

    const { props: youtubeChannelSelectProps } = useYoutubeChannelSelect({
        initOn: 'menuOpen',
    });

    return (
        <Form className={styles.form}>
            <SelectField
                name="tiktokUser"
                label="Tiktok User"
                placeholder="Select tiktok user"
                {...omit(tiktokUserSelectProps, ['value', 'onChange'])}
                isClearable
            />

            <div>
                {values.isInstagramUserCreateMode ? (
                    <FormField
                        className={styles.identifierField}
                        name="instagramUserIdentifier"
                        label="Instagram User"
                        placeholder="Enter instagram username or URL"
                    />
                ) : (
                    <SelectField
                        name="instagramUser"
                        label="Instagram User"
                        placeholder="Select instagram user"
                        {...omit(instagramUserSelectProps, ['value', 'onChange'])}
                        isClearable
                    />
                )}

                <Button
                    className={styles.switchUserInputButton}
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                        setFieldValue('isInstagramUserCreateMode', !values.isInstagramUserCreateMode);
                        setFieldError('instagramUser', undefined);
                        setFieldError('instagramUserIdentifier', undefined);
                    }}
                >
                    {values.isInstagramUserCreateMode ? 'Select user' : 'Add user via username/URL'}
                </Button>
            </div>

            <div>
                {values.isYoutubeChannelCreateMode ? (
                    <FormField
                        className={styles.identifierField}
                        name="youtubeChannelIdentifier"
                        label="YouTube Channel"
                        placeholder="Enter youtube channel URL/handle"
                    />
                ) : (
                    <SelectField
                        name="youtubeChannel"
                        label="YouTube Channel"
                        placeholder="Select youtube channel"
                        {...omit(youtubeChannelSelectProps, ['value', 'onChange'])}
                        isClearable
                    />
                )}

                <Button
                    className={styles.switchUserInputButton}
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                        setFieldValue('isYoutubeChannelCreateMode', !values.isYoutubeChannelCreateMode);
                        setFieldError('youtubeChannel', undefined);
                        setFieldError('youtubeChannelIdentifier', undefined);
                    }}
                >
                    {values.isYoutubeChannelCreateMode ? 'Select channel' : 'Add channel via URL/handle'}
                </Button>
            </div>

            <FormField name="email" label="Email" placeholder="Enter email" />
        </Form>
    );
};

export default MicrowaveInfluencerForm;
