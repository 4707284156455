import { ReactNode } from 'react';

type TabListProps = { children?: ReactNode; ariaLabel?: string; className?: string };

const TabList = ({ children, ariaLabel, className }: TabListProps) => (
    <div role="tablist" aria-label={ariaLabel} className={className}>
        {children}
    </div>
);

export default TabList;
