import { Context } from '@fluentui/react-context-selector';
import { TiktokVideoStats, createBatchedParamsArrayFromIds, getTiktokVideoStats } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { Dispatch, useCallback } from 'react';

export type State = {
    [videoId: number]: DataState<TiktokVideoStats | null> | undefined;
};

export const initialState: State = {};

export type Actions =
    | ReducerActionWithPayload<'loadTiktokVideoStats', number[]>
    | ReducerActionWithPayload<'tiktokVideoStatsSuccess', { videoIds: number[]; stats: TiktokVideoStats[] }>
    | ReducerActionWithPayload<'tiktokVideoStatsErrorLoading', { videoIds: number[]; error: string }>
    | ReducerAction<'resetTiktokVideoStats'>;

export const reducer = createReducer<State, Actions>({
    loadTiktokVideoStats: (state, { payload: videoIds }) => {
        const loadingVideos = videoIds.reduce((acc, id) => {
            acc[id] = { status: 'loading', data: null, error: null };
            return acc;
        }, {} as State);

        return { ...state, ...loadingVideos };
    },
    tiktokVideoStatsSuccess: (state, { payload: { videoIds, stats } }) => {
        const initialized = videoIds.reduce((acc, id) => {
            acc[id] = { status: 'success', data: stats.find((stat) => stat.video_id === id) ?? null, error: null };
            return acc;
        }, {} as State);

        return { ...state, ...initialized };
    },
    tiktokVideoStatsErrorLoading: (state, { payload: { videoIds, error } }) => {
        const errors = videoIds.reduce((acc, id) => {
            acc[id] = { status: 'error', error, data: null };
            return acc;
        }, {} as State);

        return { ...state, ...errors };
    },
    resetTiktokVideoStats: () => initialState,
});

export function makeTiktokVideoStatsDataHook<Value extends [any, Dispatch<Actions>] | null>(
    context: Context<Value>,
    selector: ContextSelector<Value, State>
) {
    return function useTiktokVideoStats() {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (videoIds: number[], requestInit?: RequestInit) => {
                if (!videoIds.length) {
                    dispatch({ type: 'tiktokVideoStatsSuccess', payload: { videoIds: [], stats: [] } });
                    return;
                }

                try {
                    dispatch({ type: 'loadTiktokVideoStats', payload: videoIds });

                    // Since we aren't paginating yet, we need to batch the requests to avoid exceeding the URL length limit
                    const batchedParams = createBatchedParamsArrayFromIds(videoIds, (ids) => ({
                        video_ids: ids.toString(),
                        latest: true,
                    }));

                    const results = await Promise.all(
                        batchedParams.map((params) =>
                            getTiktokVideoStats(params, requestInit).then((res) => {
                                if (res.status === 200) {
                                    return res.data.results;
                                }
                                throw new Error('Could not get tiktok video stats');
                            })
                        )
                    );

                    dispatch({
                        type: 'tiktokVideoStatsSuccess',
                        payload: { videoIds, stats: results.flat() },
                    });
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({
                        type: 'tiktokVideoStatsErrorLoading',
                        payload: { videoIds, error: 'Could not get tiktok video stats' },
                    });
                    throw e;
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
}
