import { useEffect, useRef } from 'react';

export default function useComponentWillUnmount(callback: () => void) {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    useEffect(() => {
        return () => callbackRef.current();
    }, []);
}
