import React, { PropsWithChildren, useState } from 'react';
import { MediaPlanItemRow } from '../../../../../../App.types';
import styles from './TargetingCreativeExpandBar.module.css';
import { asMoney, formatDateShort } from '../../../../../../helpers';
import cn from 'classnames';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/ArrowIcon.svg';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { AdvertisingContext } from '../../../contexts/AdvertisingContext/AdvertisingContext';

type TargetingCreativeExpandBarProps = PropsWithChildren<{
    mediaPlanItem: MediaPlanItemRow;
    renderToolbar?: () => React.ReactNode;
}>;

const formatDate = (d: string) => formatDateShort(d, { timeZone: 'Europe/London' });
const TargetingCreativeExpandBar = ({ mediaPlanItem, children, renderToolbar }: TargetingCreativeExpandBarProps) => {
    const [{ mediaPlan }] = useNonNullContext(AdvertisingContext);
    const [isExpanded, setIsExpanded] = useState(true);
    const expandButtonLabel = isExpanded ? 'Collapse bar' : 'Expand bar';

    return (
        <div>
            <div className={styles.bar}>
                <div className={styles.infoColumns}>
                    <div className={cn(styles.infoColumn, styles.name)}>
                        <span className={styles.infoColumnTitle}>
                            {mediaPlanItem.channel.name} - {mediaPlanItem.format.name}
                        </span>
                        <span className={styles.infoColumnValue}>{mediaPlanItem.group?.name}</span>
                    </div>

                    <div className={styles.infoColumn}>
                        <span className={styles.infoColumnTitle}>Run Date</span>
                        <span className={styles.infoColumnValue}>
                            {formatDate(mediaPlanItem.start_date)} - {formatDate(mediaPlanItem.end_date)}
                        </span>
                    </div>

                    <div className={styles.infoColumn}>
                        <span className={styles.infoColumnTitle}>Budget</span>
                        <span className={styles.infoColumnValue}>
                            {mediaPlan ? asMoney(Number(mediaPlanItem.cost), mediaPlan.currency) : '-'}
                        </span>
                    </div>
                </div>

                <div className={styles.toolbar}>
                    {typeof renderToolbar === 'function' && renderToolbar()}

                    <button
                        className={cn(styles.barButton, styles.expandButton, { [styles.expanded]: isExpanded })}
                        aria-label={expandButtonLabel}
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        <ArrowIcon />
                    </button>
                </div>
            </div>

            {isExpanded && children}
        </div>
    );
};

export default TargetingCreativeExpandBar;
