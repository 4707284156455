import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';
import { ApiResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { EmailTemplate, NotificationType } from './emailTemplates.types';

type GetEmailTemplatesParams = Partial<
    PaginatedRequest & {
        is_active: boolean;
    }
> & {
    notification_type: NotificationType;
};

export async function getEmailTemplates(
    params: GetEmailTemplatesParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<EmailTemplate>, 200>> {
    const response = await fetchWithToken(`/api/contact/email-templates/${encodeUrlSearchParams(params)}`, requestInit);

    if (!response.ok) {
        throw new Error('Could not get email templates');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getAllEmailTemplates(
    params: Pick<GetEmailTemplatesParams, 'notification_type' | 'is_active'>,
    requestInit?: RequestInit
): Promise<EmailTemplate[]> {
    return fetchAll(`/api/contact/email-templates/${encodeUrlSearchParams(params)}`, requestInit);
}
