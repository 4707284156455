import styles from './RoundClientUserTable.module.css';
import { ClientSimple, RoundClientUser } from '@round/api';
import PaginationTable, { PaginationTableProps } from '../../../../../../ui/PaginationTable/PaginationTable';
import { Column } from 'react-table';
import { Button, Skeleton } from '@round/ui-kit';
import { useMemo } from 'react';
import Badge from '../../../../../../ui/Badge/Badge';
import { getReadableDateTimeString } from '../../../../../../helpers';

export type RoundClientUserTableRow = RoundClientUser & {
    clients: ClientSimple[];
};

type RoundClientUserTableProps = Omit<
    PaginationTableProps<RoundClientUserTableRow>,
    'columns' | 'orderBy' | 'onOrderByChange'
> & {
    onClickDeactivateUser: (user: RoundClientUserTableRow) => void;
};

const RoundClientUserTable = ({ loading, onClickDeactivateUser, ...props }: RoundClientUserTableProps) => {
    const columns: Column<RoundClientUserTableRow>[] = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'first_name',
                Cell: ({ value: firstName, row }) =>
                    loading ? <Skeleton /> : <div>{`${firstName} ${row.original.last_name}`}</div>,
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: ({ value: email }) => (loading ? <Skeleton /> : <div>{email}</div>),
            },
            {
                Header: 'Clients',
                accessor: 'clients',
                Cell: ({ value: clients }) =>
                    loading ? (
                        <Skeleton />
                    ) : (
                        <div className={styles.clientBadgeContainer}>
                            {(clients ?? []).map((client) => (
                                <Badge key={client.id} label={client.name} className={styles.clientBadge} />
                            ))}
                        </div>
                    ),
            },
            {
                Header: 'Last login',
                accessor: 'last_login',
                Cell: ({ value: lastLogin }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    if (!lastLogin) {
                        return <div>-</div>;
                    }

                    return <>{getReadableDateTimeString(new Date(lastLogin))}</>;
                },
            },
            {
                Header: 'Deactivate',
                accessor: 'is_active',
                Cell: ({ value: isActive, row }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <Button
                            type="filled"
                            color="negative"
                            className={styles.deactivateButton}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClickDeactivateUser(row.original);
                            }}
                        >
                            {isActive ? 'Deactivate' : 'Activate'}
                        </Button>
                    );
                },
            },
        ],
        [loading, onClickDeactivateUser]
    );

    return <PaginationTable columns={columns} loading={loading} tableClassName={styles.table} {...props} />;
};

export default RoundClientUserTable;
