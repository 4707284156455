import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { showNotification } from 'helpers';
import useNonNullContext from 'Hooks/useNonNullContext';
import useYoutubeInfluencerPosts from 'Modules/Advertising/InfluencerPlan/contexts/YoutubeCreatorsContext/hooks/useYoutubeInfluencerPosts';
import Button from 'ui/Buttons/Button/Button';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import Modal, { ModalContent, ModalTitle, ModalFooter } from '../../../../../../ui/General/Modal/Modal';
import YoutubeInfluencerPostForm, { FormValues } from '../InfluencerPostForm/YoutubeInfluencerPostForm';
import styles from './CreateYoutubeInfluencerPostModal.module.css';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
    groupId: number | null;
};

const CreateYoutubeInfluencerPostModal = ({ isOpen, closeModal, groupId }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const { createPost } = useYoutubeInfluencerPosts();

    const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        if (!values.currency || !groupId) {
            return;
        }

        try {
            const response = await createPost({
                group_id: groupId,
                cost: values.cost,
                currency_id: values.currency?.value,
                post_url: values.post_url,
            });

            if (response?.status === 201) {
                showNotification('Post created', 'info');
                helpers.resetForm();
                closeModal();
                return;
            }

            if (response?.status === 400) {
                helpers.setErrors(
                    mapApiErrorsToFormikErrors({
                        cost: response.data.cost,
                        currency: response.data.currency_id,
                        post_url: response.data.post_url,
                    })
                );
                return;
            }
        } catch {
            showNotification('Could not add post', 'error');
        }
    };

    const formikProps = useFormik<FormValues>({
        initialValues: {
            cost: '',
            currency: currencies.length ? { value: currencies[0].id, label: currencies[0].name } : null,
            post_url: '',
        },
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    const handleClose = () => {
        formikProps.resetForm();
        closeModal();
    };

    return (
        <Modal closeOnOverlayClick modalClassName={styles.modal} isOpen={isOpen} onClose={handleClose}>
            <ModalTitle>Create Post</ModalTitle>
            <ModalContent className={styles.content}>
                <FormikProvider value={formikProps}>
                    <YoutubeInfluencerPostForm />
                </FormikProvider>
            </ModalContent>
            <ModalFooter align="left">
                <Button
                    className={styles.submitButton}
                    type="filled"
                    color="black"
                    onClick={formikProps.submitForm}
                    loading={formikProps.isSubmitting}
                    disabled={!formikProps.dirty}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CreateYoutubeInfluencerPostModal;
