import React, { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import PaginationTable from '../../../../../../ui/PaginationTable/PaginationTable';
import Image from '../../../../../../ui/Image/Image';
import Skeleton from '../../../../../../ui/Skeleton/Skeleton';
import {
    asMoney,
    buildTiktokUserUrl,
    displayOptionalNumericTableValue,
    formatDate,
    roundTo2Dp,
    formatNumberToKNotation,
} from '../../../../../../helpers';
import Badge from '../../../../../../ui/Badge/Badge';
import styles from './TiktokInfluencersTable.module.css';
import cn from 'classnames';
import { OrderByParam } from '../../../../../../Hooks/useReactTableSortToOrderBy';
import AddTiktokInfluencerToCampaign from '../../../../../Influencer/containers/AddInfluencerToCampaign/AddTiktokInfluencerToCampaign';
import { useCheckUserGroupsAccess } from '../../../../../Auth/hooks/useCheckUserGroupsAccess';
import { TiktokInfluencerTableRow } from './TiktokInfluencersTable.types';
import { getCountry } from '../../../../../../utility/utility';
import { useContentTags } from 'contexts/ContentTagsContext';
import useAbortableEffect from 'Hooks/useAbortableEffect';

type TiktokInfluencersTableProps = {
    loading: boolean;
    data: TiktokInfluencerTableRow[];
    count: number;
    page: number;
    pageSize: number;
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number | undefined) => void;
    orderBy: OrderByParam<TiktokInfluencerTableRow>;
    onOrderByChange: (param: OrderByParam<TiktokInfluencerTableRow>) => void;
    onRowClick: (row: TiktokInfluencerTableRow) => void;
    onInfluencerAddedToCampaign: (influencerUserId: number) => void;
};

const today = new Date();
const thirtyDaysAgo = new Date(new Date().setDate(today.getDate() - 30));

const TiktokInfluencersTable = ({
    onPageChange,
    onPageSizeChange,
    pageSize,
    page,
    data,
    loading,
    count,
    orderBy,
    onOrderByChange,
    onRowClick,
    onInfluencerAddedToCampaign,
}: TiktokInfluencersTableProps) => {
    const hasInfluencerEditorPermissions = useCheckUserGroupsAccess(['influencer_editor']);
    const { tags: contentTags, isInitialized: areContentTagsInitialized, init: initContentTags } = useContentTags();

    useAbortableEffect(
        (signal) => {
            if (!areContentTagsInitialized) {
                initContentTags({ signal });
            }
        },
        [areContentTagsInitialized, initContentTags]
    );

    const columns = useMemo<Column<TiktokInfluencerTableRow>[]>(
        () => [
            {
                Header: 'Account',
                accessor: 'name',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    const createdThisMonth = new Date(original.created).getTime() > thirtyDaysAgo.getTime();
                    const username = original.unique_id ?? original.username;
                    return (
                        <div className={styles.account}>
                            <Image
                                className={styles.accountImage}
                                src={original.image}
                                alt="Influencer"
                                loading={loading}
                            />
                            <div className={styles.accountDetails}>
                                <div className={styles.accountNameRow}>
                                    <p className={styles.accountName}>
                                        {loading ? <Skeleton /> : original.name ?? original.username}
                                    </p>
                                    {!loading && createdThisMonth && <Badge label="New" className={styles.badge} />}
                                </div>
                                <p>
                                    {loading ? (
                                        <Skeleton />
                                    ) : (
                                        <a
                                            className={styles.accountUsername}
                                            onClick={(e) => e.stopPropagation()}
                                            href={buildTiktokUserUrl(username)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            @{username}
                                        </a>
                                    )}
                                </p>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Follower count',
                accessor: 'follower_count',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{typeof value === 'number' ? formatNumberToKNotation(value) : '-'}</>;
                },
            },
            {
                Header: 'Tags',
                id: 'tags',
                disableSortBy: true,
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerTableRow>) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.tagsContainer}>
                            {contentTags
                                .filter((t) => original.content_tags.includes(t.id))
                                .map((tag) => (
                                    <Badge
                                        key={tag.name}
                                        label={tag.name}
                                        className={cn(styles.tag, styles.categoryTag)}
                                    />
                                ))}
                        </div>
                    );
                },
            },
            {
                Header: 'Location',
                id: 'location',
                disableSortBy: true,
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerTableRow>) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    const creatorLocation = getCountry(original.location);

                    return (
                        <div>
                            {creatorLocation ? (
                                <span className={styles.location}>
                                    <span
                                        className={cn(`fi fi-${creatorLocation.code.toLowerCase()}`, styles.flagIcon)}
                                    />
                                    {creatorLocation.label}
                                </span>
                            ) : null}
                        </div>
                    );
                },
            },
            {
                Header: 'Engagement rate',
                accessor: 'avg_engagement_rate',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{typeof value === 'number' ? `${roundTo2Dp(value * 100)}%` : '-'}</>;
                },
            },
            {
                Header: 'Cost',
                accessor: 'client_cost',
                Cell: ({ row: { original } }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <>
                            {typeof original.client_cost === 'number' && original.currency
                                ? asMoney(original.client_cost, original.currency)
                                : '-'}
                        </>
                    );
                },
            },
            {
                Header: 'Avg. video plays',
                accessor: 'avg_video_plays_per_post',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{displayOptionalNumericTableValue(value)}</>;
                },
            },
            {
                Header: 'Est. CPM',
                accessor: 'estimated_cpm',
                Cell: ({ row: { original } }: CellProps<TiktokInfluencerTableRow>) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <>
                            {original.currency && typeof original.estimated_cpm
                                ? asMoney(original.estimated_cpm, original.currency)
                                : '-'}
                        </>
                    );
                },
            },
            {
                Header: 'Last used',
                accessor: 'last_used',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <>
                            {typeof value === 'string'
                                ? formatDate(value, { month: 'short', year: '2-digit', day: '2-digit' })
                                : '-'}
                        </>
                    );
                },
            },
            {
                Header: 'Date Added',
                accessor: 'created',
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return (
                        <>
                            {typeof value === 'string'
                                ? formatDate(value, { month: 'short', year: '2-digit', day: '2-digit' })
                                : '-'}
                        </>
                    );
                },
            },
            {
                Header: 'Contact',
                accessor: 'contact_details',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    return <>{value || '-'}</>;
                },
            },
            {
                Header: 'Add to campaign',
                accessor: 'campaigns',
                disableSortBy: true,
                Cell: ({ value, row: { original } }) => {
                    if (loading) {
                        return <Skeleton />;
                    }

                    const numberOfActiveCampaigns = value?.plans.filter((p) => p.status === 'live').length ?? 0;
                    return (
                        <AddTiktokInfluencerToCampaign
                            readonly={!hasInfluencerEditorPermissions}
                            influencerCampaigns={value}
                            plans={original.plans}
                            influencerUserStats={original}
                            onInfluencerAddedToCampaign={(post) => {
                                if (typeof post.influencer_id === 'number') {
                                    onInfluencerAddedToCampaign(post.influencer_id);
                                }
                            }}
                        >
                            {numberOfActiveCampaigns} active campaign{numberOfActiveCampaigns !== 1 ? 's' : ''}
                        </AddTiktokInfluencerToCampaign>
                    );
                },
            },
        ],
        [hasInfluencerEditorPermissions, loading, onInfluencerAddedToCampaign, contentTags]
    );

    return (
        <PaginationTable
            manualSortBy
            disableSortBy={false}
            tableClassName={styles.table}
            loading={loading}
            data={data}
            columns={columns}
            count={count}
            page={page}
            setPage={onPageChange}
            pageSize={pageSize}
            setPageSize={onPageSizeChange}
            onRowClick={onRowClick}
            orderBy={orderBy}
            onOrderByChange={onOrderByChange}
        />
    );
};

export default TiktokInfluencersTable;
