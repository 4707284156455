import { AppServer } from '../mirage';
import * as songMirage from './song/song.mirage';
import * as artistMirage from './artist/artist.mirage';
import * as songCollectionMirage from './songCollection/songCollection.mirage';

export const models = {
    ...songMirage.models,
    ...artistMirage.models,
    ...songCollectionMirage.models,
};

export const factories = {
    ...songMirage.factories,
    ...artistMirage.factories,
    ...songCollectionMirage.factories,
};

export const serializers = {
    ...songMirage.serializers,
    ...artistMirage.serializers,
    ...songCollectionMirage.serializers,
};

export function handleMusicRequests(server: AppServer) {
    songMirage.handleSongRequests(server);
    artistMirage.handleArtistRequests(server);
    songCollectionMirage.handleSongCollectionRequests(server);
}
