import { omit } from 'lodash';
import { SongFormValues } from './SongForm';
import { creatorbase } from '@round/api';

export const formatSongFormValuesToApiData = (values: SongFormValues): creatorbase.PostSongData => {
    if (values.status === 'released') {
        return omit(values, ['artist_name', 'title']);
    }

    return omit(values, ['spotify_url']);
};
