import styles from './MobileNavMenu.module.css';
import { Link } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { ReactComponent as MenuIcon } from '../../../../assets/Menu.svg';
import cn from 'classnames';
import { NavBarItem, isNavBarItemGroup } from '../../Routes';
import useOnClickOutside from '../../../../Hooks/useOnClickOutside';

const NavMenu = ({ navItems }: { navItems: NavBarItem[] }) => {
    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [openNavGroup, setOpenNavGroup] = useState<number | null>(null);
    const handleSetOpenNavGroup = (index: number | null) => {
        if (openNavGroup === index) {
            setOpenNavGroup(null);
        } else {
            setOpenNavGroup(index);
        }
    };

    useOnClickOutside(ref, () => setIsMenuOpen(false));

    return (
        <>
            <div ref={setRef} className={styles.menuWrapper}>
                <MenuIcon
                    className={cn(styles.navMenuIcon, { [styles.menuIsOpen]: isMenuOpen })}
                    onClick={() => {
                        setIsMenuOpen((prev) => !prev);
                        handleSetOpenNavGroup(null);
                    }}
                />
                <div className={cn(styles.menuDropdownList, { [styles.isNavGroupOpen]: isMenuOpen })}>
                    {navItems.map((item, index) => {
                        if (isNavBarItemGroup(item)) {
                            const isNavGroupOpen = openNavGroup === index;

                            return (
                                <Fragment key={item.title}>
                                    <div className={styles.navItem} onClick={() => handleSetOpenNavGroup(index)}>
                                        <span
                                            className={cn(styles.label, {
                                                [styles.activeRouteNavItem]: isNavGroupOpen,
                                            })}
                                        >
                                            {item.title}
                                        </span>
                                    </div>
                                    {isNavGroupOpen && (
                                        <div className={styles.dropdown}>
                                            {item.items.map((link) => (
                                                <Link
                                                    key={link.title}
                                                    to={link.url}
                                                    onClick={() => {
                                                        setIsMenuOpen(false);
                                                        handleSetOpenNavGroup(null);
                                                    }}
                                                    className={styles.navItem}
                                                >
                                                    <span className={styles.label}>{link.title}</span>
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                </Fragment>
                            );
                        }

                        return (
                            <Link
                                key={item.title}
                                to={item.url}
                                className={styles.navItem}
                                onClick={() => setIsMenuOpen(false)}
                            >
                                <span className={styles.label}>{item.title}</span>
                            </Link>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default NavMenu;
