import React, { createContext, useCallback, useReducer } from 'react';
import { ContentTag, microwave } from '@round/api';
import { createReducer } from '../../../../../helpers';
import { ReducerActionWithPayload } from '../../../../../App.types';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';

type MicrowaveContentTagsState = {
    state: 'not-initialized' | 'initialized' | 'error';
    tags: ContentTag[];
    error: string | null;
};

type Actions = ReducerActionWithPayload<'initialized', ContentTag[]> | ReducerActionWithPayload<'error', string | null>;

const initialState: MicrowaveContentTagsState = {
    state: 'not-initialized',
    tags: [],
    error: null,
};

const reducer = createReducer<MicrowaveContentTagsState, Actions>({
    initialized: (state, { payload }) => ({
        state: 'initialized',
        tags: payload,
        error: null,
    }),
    error: (state, { payload }) => ({
        state: 'error',
        tags: [],
        error: payload,
    }),
});

const MicrowaveContentTagsStateContext = createContext<MicrowaveContentTagsState | null>(null);
const MicrowaveContentTagsDispatchContext = createContext<React.Dispatch<Actions> | null>(null);

export const MicrowaveContentTagsProvider = ({ children }: { children?: React.ReactNode | undefined }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <MicrowaveContentTagsStateContext.Provider value={state}>
            <MicrowaveContentTagsDispatchContext.Provider value={dispatch}>
                {children}
            </MicrowaveContentTagsDispatchContext.Provider>
        </MicrowaveContentTagsStateContext.Provider>
    );
};

type Params = Pick<microwave.GetContentTagsParams, 'search'>;

export function useMicrowaveContentTags({ search }: Params = {}) {
    const state = useNonNullContext(MicrowaveContentTagsStateContext);
    const dispatch = useNonNullContext(MicrowaveContentTagsDispatchContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                const tags = await microwave.getAllContentTags({ search }, requestInit);
                dispatch({ type: 'initialized', payload: tags });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'error', payload: 'Could not load tags' });
            }
        },
        [dispatch, search]
    );

    return {
        init,
        loading: state.state === 'not-initialized',
        initialized: state.state === 'initialized',
        tags: state.tags,
        error: state.error,
    };
}
