import { useCallback, useMemo, useState } from 'react';
import { getRelease, getReleases, Release } from '@round/api';
import debounce from 'lodash/debounce';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { showNotification } from 'helpers';
import { ReleaseOption } from 'Modules/Advertising/Projects/components/ReleaseSelectOption/ReleaseSelectOption';
import { SelectProps } from '@round/ui-kit';
import { ValueType } from 'react-select';
import { getAllItems } from 'utility/api';

type GetReleasesParams = Parameters<typeof getReleases>[0];

const defaultPageSize = 25;

type Return<IsMulti extends boolean = false> = Pick<
    SelectProps<ReleaseOption, IsMulti>,
    | 'options'
    | 'filterOption'
    | 'onMenuOpen'
    | 'onMenuClose'
    | 'onMenuScrollToBottom'
    | 'inputValue'
    | 'onInputChange'
    | 'isLoading'
    | 'value'
    | 'onChange'
    | 'isMulti'
>;

const mapReleaseToOption = (r: Release): ReleaseOption => ({
    value: r.id,
    label: r.name,
    clientName: r.brand.client.name,
    brandName: r.brand.name,
    image: r.brand.picture,
});

export default function useReleaseSelect<IsMulti extends boolean = false>(
    initialReleaseId?: IsMulti extends true ? number[] : number,
    params: {
        isMulti?: IsMulti;
        initOptionsOnMenuOpen?: boolean;
    } = {}
): Return<IsMulti> {
    const [value, setValue] = useState<ValueType<ReleaseOption, IsMulti>>(null);
    const [isValueInitialized, setIsValueInitialized] = useState(false);

    useAbortableEffect(
        (signal) => {
            if (isValueInitialized) {
                return;
            }

            if (typeof initialReleaseId === 'number' && !params.isMulti) {
                getRelease(initialReleaseId, { signal })
                    .then((response) => {
                        if (response.status === 404) {
                            showNotification('Initial release select value not found', 'error');
                            return;
                        }

                        setValue(mapReleaseToOption(response.data) as ValueType<ReleaseOption, IsMulti>);
                        setIsValueInitialized(true);
                    })
                    .catch((e) => {
                        if (e instanceof Error && e.name === 'AbortError') {
                            return;
                        }

                        showNotification('Could not initialize release select value', 'error');
                    });
                return;
            }

            if (Array.isArray(initialReleaseId) && initialReleaseId.length && params.isMulti) {
                getAllItems(getRelease, initialReleaseId as number[])
                    .then((releases) => {
                        setValue((releases.map(mapReleaseToOption) as unknown) as ValueType<ReleaseOption, IsMulti>);
                        setIsValueInitialized(true);
                    })
                    .catch((e) => {
                        if (e instanceof Error && e.name === 'AbortError') {
                            return;
                        }

                        showNotification('Could not initialize release select value', 'error');
                    });
            }
        },
        [params.isMulti, initialReleaseId, isValueInitialized]
    );

    const [releases, setReleases] = useState<Release[]>([]);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [search, setSearch] = useState('');
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const fetchReleases = useCallback(
        async (params: Omit<GetReleasesParams, 'search'>, requestInit?: RequestInit) => {
            try {
                setIsLoading(true);
                const {
                    data: { results, next },
                } = await getReleases({ search, ...params }, requestInit);
                setReleases((prev) => prev.concat(results));
                setHasNextPage(Boolean(next));
            } finally {
                setIsLoading(false);
                setIsSearchLoading(false);
            }
        },
        [search]
    );

    const debouncedInit = useMemo(() => debounce(fetchReleases, 700), [fetchReleases]);

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && (!params.initOptionsOnMenuOpen || isMenuOpen)) {
                debouncedInit({ page: 1, page_size: defaultPageSize }, { signal })
                    ?.then(() => setIsInitialized(true))
                    .catch((e) => {
                        if (e instanceof Error && e.name === 'AbortError') {
                            return;
                        }

                        showNotification('Could not fetch releases', 'error');
                    });

                return () => debouncedInit.cancel();
            }
        },
        [debouncedInit, isInitialized, isMenuOpen, params.initOptionsOnMenuOpen]
    );

    const loadNextPage = useCallback(async () => {
        fetchReleases({ page: page + 1, page_size: defaultPageSize })
            .then(() => setPage((page) => page + 1))
            .catch((e) => {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                showNotification(e.message, 'error');
            });
    }, [fetchReleases, page]);

    const reset = useCallback(() => {
        setReleases([]);
        setPage(1);
        setHasNextPage(false);
        setSearch('');
        setIsInitialized(false);
    }, []);

    const options: ReleaseOption[] = useMemo(() => releases.map(mapReleaseToOption), [releases]);

    return {
        isMulti: params.isMulti,
        value,
        onChange: (value) => {
            setValue(value);
        },
        options,
        filterOption: null,
        inputValue: search,
        onInputChange: (value: string) => {
            if (value === search) {
                return;
            }

            reset();
            setSearch(value);
            if (value) {
                setIsSearchLoading(true);
            }
        },
        onMenuScrollToBottom: () => {
            if (!hasNextPage) {
                return;
            }

            loadNextPage();
        },
        onMenuOpen: () => setIsMenuOpen(true),
        onMenuClose: () => {
            setIsMenuOpen(false);
        },
        isLoading: isLoading || isSearchLoading,
    };
}
