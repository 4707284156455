import React, { useMemo, useState } from 'react';
import PaginationTable from '../../../../../../../ui/PaginationTable/PaginationTable';
import { Column } from 'react-table';
import Skeleton from '../../../../../../../ui/Skeleton/Skeleton';
import { buildTiktokPostUrl, formatDateObjShort, numberWithCommas } from '../../../../../../../helpers';
import styles from './TiktokUserTopPosts.module.css';
import TruncatedTextCell from '../../../../../../../SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';
import { useTiktokUserTopPosts } from '../../TiktokUserDataContext/TiktokUserDataContext';
import { GetTiktokUserPostStatsOrderingKeys, OrderingValues, TiktokUser, TiktokUserPostStats } from '@round/api';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { convertOrderByToOrdering, convertOrderingToOrderBy } from 'ui/PaginationTable/PaginationTable.helpers';

type TiktokUserTopPostsProps = {
    user: TiktokUser | null;
    loading?: boolean;
};

const TiktokUserTopPosts = ({ user, loading }: TiktokUserTopPostsProps) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [ordering, setOrdering] = useState<OrderingValues<GetTiktokUserPostStatsOrderingKeys>>('-create_time');

    const {
        fetchPostStats,
        resetPostStats,
        postStats,
        postStatsCount,
        postStatsLoading,
        errorLoadingPostStats,
        postStatsInitialized,
    } = useTiktokUserTopPosts();

    useAbortableEffect(() => {
        if (!postStatsInitialized && user) {
            fetchPostStats({ user_id: user.id, page: page, page_size: pageSize, ordering });
        }
    }, [user, page, pageSize, postStatsInitialized, fetchPostStats, ordering]);

    const tableLoading = Boolean(postStatsLoading || loading);

    const columns = useMemo<Column<TiktokUserPostStats>[]>(
        () => [
            {
                Header: 'Description',
                accessor: 'description',
                disableSortBy: true,
                Cell: ({ value, row: { original } }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    if (!user) {
                        return null;
                    }

                    return (
                        <a
                            className={styles.link}
                            href={buildTiktokPostUrl(user.unique_id, original.video_tiktok_id)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <TruncatedTextCell>{value}</TruncatedTextCell>
                        </a>
                    );
                },
            },
            {
                Header: 'Date posted',
                accessor: 'create_time',
                Cell: ({ value }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    return <>{formatDateObjShort(new Date(value * 1000))}</>;
                },
            },
            {
                Header: 'Location',
                accessor: 'location',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    return <>{value ?? '-'}</>;
                },
            },
            {
                Header: 'Boosted',
                accessor: 'is_ad',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    return <>{value ? 'Yes' : 'No'}</>;
                },
            },
            {
                Header: 'Views',
                accessor: 'play_count',
                Cell: ({ value }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Likes',
                accessor: 'like_count',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Comments',
                accessor: 'comment_count',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Shares',
                accessor: 'share_count',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    return <>{numberWithCommas(value)}</>;
                },
            },
            {
                Header: 'Saves',
                accessor: 'save_count',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (tableLoading) {
                        return <Skeleton />;
                    }

                    return <>{typeof value === 'number' ? numberWithCommas(value) : '-'}</>;
                },
            },
        ],
        [tableLoading, user]
    );

    return (
        <div>
            <p>Top Posts</p>
            <PaginationTable
                tableClassName={styles.table}
                theadClassName={styles.thead}
                loading={tableLoading}
                manualPagination={true}
                manualSortBy
                disableSortBy={false}
                orderBy={convertOrderingToOrderBy(ordering)}
                onOrderByChange={(orderBy) => {
                    setOrdering(
                        convertOrderByToOrdering(orderBy) as OrderingValues<GetTiktokUserPostStatsOrderingKeys>
                    );
                    resetPostStats();
                }}
                data={postStats}
                columns={columns}
                count={postStatsCount}
                page={page}
                setPage={(page) => {
                    setPage(page);
                    resetPostStats();
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                    setPageSize(pageSize ?? 10);
                    resetPostStats();
                }}
                noDataLabel={errorLoadingPostStats ? 'Sorry, we are experiencing technical issues' : 'No top posts'}
            />
        </div>
    );
};

export default TiktokUserTopPosts;
