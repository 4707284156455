import YoutubeIcon from '../../../../../SharedComponents/svg/Icons/PlatformIcons/YoutubeIcon';
import FacebookIcon from '../../../../../SharedComponents/svg/Icons/PlatformIcons/FacebookIcon';
import Tiktok from '../../../../../SharedComponents/svg/Icons/PlatformIcons/Tiktok';
import SnapchatIcon from '../../../../../SharedComponents/svg/Icons/PlatformIcons/SnapchatIcon';
import TwitterIcon from '../../../../../SharedComponents/svg/Icons/PlatformIcons/TwitterIcon';
import InstagramIcon from '../../../../../SharedComponents/svg/Icons/PlatformIcons/InstagramIcon';
import SpotifyIcon from '../../../../../SharedComponents/svg/Icons/PlatformIcons/SpotifyIcon';
import GoogleIcon from '../../../../../SharedComponents/svg/Icons/PlatformIcons/GoogleIcon';
import { ReactComponent as MicrowaveIcon } from '../../assets/microwave.svg';

const icons: Record<string, typeof YoutubeIcon | typeof MicrowaveIcon> = {
    youtube: YoutubeIcon,
    facebook: FacebookIcon,
    tiktok: Tiktok,
    snapchat: SnapchatIcon,
    twitter: TwitterIcon,
    instagram: InstagramIcon,
    spotify: SpotifyIcon,
    google: GoogleIcon,
    microwave: MicrowaveIcon,
};

type PlatformIconProps = {
    name: string;
    width?: number;
    height?: number;
};

const PlatformIcon = ({ name, width = 20, height = 20 }: PlatformIconProps) => {
    const Icon = icons[name];
    if (!Icon) {
        return null;
    }

    return <Icon width={width} height={height} />;
};

export default PlatformIcon;
