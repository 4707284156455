import React, { useCallback, useEffect, useState } from 'react';
import { FacebookMediaPlanItemRow, FacebookTargeting, GenericDropdownOption } from '../../../../../../App.types';
import { FacebookTargetingTemplate } from '../../../types/Facebook.types';
import {
    createFacebookTargetingFromTemplate,
    createFacebookTargetingTemplateWithDefaultsSet,
    createFacebookTargetingWithDefaultsSet,
} from '../../../services/Facebook.services';
import { showNotification } from '../../../../../../helpers';
import { getFacebookTargetingTemplates } from '../../../api/Facebook.api';
import CreateTargetingModal from '../components/CreateTargetingModal/CreateTargetingModal';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { AdvertisingContext } from '../../../contexts/AdvertisingContext/AdvertisingContext';

type CreateFacebookTargetingProps = {
    isModalOpen: boolean;
    mediaPlanItem: FacebookMediaPlanItemRow;
    closeModal: () => void;
    onTargetingCreated: (targeting: FacebookTargeting) => void;
    onTargetingTemplateCreated: (template: FacebookTargetingTemplate) => void;
    editTargetingTemplate: (template: FacebookTargetingTemplate) => void;
};

const CreateFacebookTargeting = ({
    isModalOpen,
    onTargetingCreated,
    onTargetingTemplateCreated,
    editTargetingTemplate,
    mediaPlanItem,
    closeModal,
}: CreateFacebookTargetingProps) => {
    const [{ mediaPlan }] = useNonNullContext(AdvertisingContext);
    const [targetingTemplateOptionsLoading, setTargetingTemplateOptionsLoading] = useState(false);
    const [errorLoadingTargetingTemplates, setErrorLoadingTargetingTemplates] = useState(false);
    const [targetingTemplateOptions, setTargetingTemplateOptions] = useState<GenericDropdownOption<number>[]>([]);
    const [templates, setTemplates] = useState<FacebookTargetingTemplate[]>([]);

    const onCreateTargeting = useCallback(async () => {
        try {
            const targeting = await createFacebookTargetingWithDefaultsSet(mediaPlanItem.id);
            showNotification('Facebook targeting created', 'info');
            onTargetingCreated(targeting);
            closeModal();
        } catch {
            showNotification('Could not create facebook targeting', 'error');
        }
    }, [closeModal, mediaPlanItem.id, onTargetingCreated]);

    const onEditTargetingTemplate = useCallback(
        (option: GenericDropdownOption<number>) => {
            const template = templates.find((t) => t.id === option.value);
            if (template) {
                editTargetingTemplate(template);
                closeModal();
            }
        },
        [closeModal, editTargetingTemplate, templates]
    );

    const onCreateTargetingFromTemplate = useCallback(
        async (template: GenericDropdownOption<number>) => {
            try {
                const targeting = await createFacebookTargetingFromTemplate(mediaPlanItem.id, template.value);
                showNotification('Targeting created', 'info');
                onTargetingCreated(targeting);
                closeModal();
            } catch {
                showNotification('Could not create targeting', 'error');
            }
        },
        [closeModal, mediaPlanItem.id, onTargetingCreated]
    );

    const onCreateNewTargetingTemplate = useCallback(async () => {
        if (!mediaPlan) {
            return;
        }

        try {
            const targetingTemplate = await createFacebookTargetingTemplateWithDefaultsSet(
                mediaPlan.release.brand.client.id
            );
            showNotification('Template created', 'info');
            onTargetingTemplateCreated(targetingTemplate);
            closeModal();
        } catch {
            showNotification('Could not create template', 'error');
        }
    }, [closeModal, mediaPlan, onTargetingTemplateCreated]);

    const getTargetingTemplateOptions = useCallback(async () => {
        if (!mediaPlan) {
            return;
        }

        try {
            setTargetingTemplateOptionsLoading(true);
            setErrorLoadingTargetingTemplates(false);
            const templates = await getFacebookTargetingTemplates(mediaPlan.release.brand.client.id);
            setTemplates(templates);
            setTargetingTemplateOptions(
                templates.map((t) => ({
                    value: t.id,
                    label: t.template_name,
                }))
            );
        } catch {
            setErrorLoadingTargetingTemplates(true);
        } finally {
            setTargetingTemplateOptionsLoading(false);
        }
    }, [mediaPlan]);

    useEffect(() => {
        // dependency intended to re-fetch options in case templates' edit/delete
        if (isModalOpen) {
            getTargetingTemplateOptions();
        }
    }, [getTargetingTemplateOptions, isModalOpen]);

    return (
        <CreateTargetingModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            title="Add Facebook Targeting"
            templateOptionsLoading={targetingTemplateOptionsLoading}
            templateOptionsLoadingError={errorLoadingTargetingTemplates}
            templateOptions={targetingTemplateOptions}
            createFromTemplate={onCreateTargetingFromTemplate}
            addNewTargeting={onCreateTargeting}
            addNewTemplate={onCreateNewTargetingTemplate}
            editTargetingTemplate={onEditTargetingTemplate}
        />
    );
};

export default CreateFacebookTargeting;
