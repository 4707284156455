import { ControlProps, components, OptionTypeBase } from 'react-select';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/SearchIcon.svg';
import styles from './ControlWithSearchIcon.module.css';

const ControlWithSearchIcon = <OptionType extends OptionTypeBase, isMulti extends boolean>({
    children,
    ...props
}: ControlProps<OptionType, isMulti>) => (
    <components.Control {...props}>
        <SearchIcon className={styles.searchIcon} />
        {children}
    </components.Control>
);

export default ControlWithSearchIcon;
