// Some statistics could be undefined
// as we can't be sure that every report item has corresponding values/mappings
export type Metrics = { mediaPlanItemId: number; amount_spent: number } & Partial<{
    impressions: number;
    cpm: number;
    clicks: number;
    cpc: number;
    ctr: number;
    views: number;
    cpv: number;
    purchases: number;
    cpa: number;
    revenue: number;
    roas: number;
    six_sec_views: number;
    six_sec_cpv: number;
    leads: number;
    cpl: number;
    view_rate: number;
}>;

export type GenericReportItem = { targeting: string | null; creative: string | null; day: string } & Metrics;
export type TwitterReportItem = {
    tweetId: string;
    tweetText: string;
    tweetUsername: string;
} & Omit<GenericReportItem, 'creative'>;

export type ReportItem = GenericReportItem | TwitterReportItem;

export function isTwitterReportItem(row: GenericReportItem | TwitterReportItem): row is TwitterReportItem {
    return (
        Object.prototype.hasOwnProperty.call(row, 'tweetId') &&
        Object.prototype.hasOwnProperty.call(row, 'tweetText') &&
        Object.prototype.hasOwnProperty.call(row, 'tweetUsername')
    );
}

export type FacebookDailyReportItem = {
    media_plan_item_id: number;
    day: string;
    campaign_name: string;
    ad_set_name: string;
    ad_name: string;
    amount_spent: string;
    impressions: number;
    link_clicks: number;
    video_plays_3_sec: number;
    purchases: number;
    purchases_conversion_value: string;
    amount_spent_in_media_plan_currency: number;
    leads: number;
};

export type YoutubeDailyReportItem = {
    media_plan_item_id: number;
    day: string;
    campaign: string;
    ad_group: string;
    image_ad_name: string;
    cost: string;
    impressions: number;
    clicks: number;
    views: number;
    all_conversions: number;
    all_conversion_value: string;
    amount_spent_in_media_plan_currency: number;
};

export type SnapchatDailyReportItem = {
    media_plan_item_id: number;
    day: string;
    campaign_name: string;
    ad_set_name: string;
    ad_name: string;
    amount_spent: string;
    paid_impressions: number;
    swipe_ups: number;
    video_views_2_sec: number;
    amount_spent_in_media_plan_currency: number;
};

export type TwitterDailyReportItem = {
    media_plan_item_id: number;
    day: string;
    campaign_name: string;
    ad_group_name: string;
    tweet_id: string;
    tweet_text: string;
    tweet_username: string;
    amount_spent: string;
    impressions: number;
    link_clicks: number;
    video_views: number;
    amount_spent_in_media_plan_currency: number;
};

export type TiktokDailyReportItem = {
    media_plan_item_id: number;
    day: string;
    campaign_name: string;
    ad_group_name: string;
    ad_name: string;
    amount_spent: string;
    impressions: number;
    clicks: number;
    video_views: number;
    complete_payment: number;
    total_complete_payment_value: string;
    paid_followers: number;
    amount_spent_in_media_plan_currency: number;
    conversions: number;
    video_views_6_sec: number;
    currency: number;
};
