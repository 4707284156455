import { ReactComponent as TikTokIcon } from 'assets/whitelabel/platform/TiktokSolid.svg';
import { ReactComponent as InstagramIcon } from 'assets/whitelabel/platform/InstagramSolid.svg';
import { ReactComponent as YoutubeIcon } from 'assets/whitelabel/platform/YoutubeSolid.svg';
import { ReactComponent as TikTokActiveIcon } from 'assets/whitelabel/platform/TiktokColor.svg';
import { ReactComponent as InstagramActiveIcon } from 'assets/whitelabel/platform/InstagramColor.svg';
import { ReactComponent as YoutubeActiveIcon } from 'assets/whitelabel/platform/YoutubeColor.svg';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { useAggregatedPostStats } from '../../../hooks/useAggregatedPostStats';
import { useState } from 'react';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import capitalize from 'lodash/capitalize';
import styles from './PlatformStatsBadge.module.css';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { SkeletonizedValue } from '@round/ui-kit';
import { asMoney, formatNumberToKNotation } from 'helpers';
import cn from 'classnames';
import { toDecimalPoint } from '@round/utils';
import { useNavigate } from 'react-router-dom';

type Props = {
    projectId: number;
    platform: string;
    isActive: boolean;
};

const platformIcons = {
    tiktok: TikTokIcon,
    instagram: InstagramIcon,
    youtube: YoutubeIcon,
};

const activePlatformIcons = {
    tiktok: TikTokActiveIcon,
    instagram: InstagramActiveIcon,
    youtube: YoutubeActiveIcon,
};

const PlatformStatsBadge = ({ projectId, platform, isActive }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const navigate = useNavigate();

    const [anchorElement, setAnchorElement] = useState<SVGSVGElement | null>(null);
    const [delay, setDelay] = useState<NodeJS.Timeout | null>(null);
    const handleWithDelay = (callback: () => void) => {
        setDelay(setTimeout(() => callback(), 300));
    };

    const { data, status, fetchData } = useAggregatedPostStats();

    const [shouldFetch, setShouldFetch] = useState(false);
    const isInitialized = status === 'success' || status === 'error';

    useAbortableEffect(() => {
        if (isInitialized || !shouldFetch || !isActive) {
            return;
        }

        fetchData({ project_id: projectId, platform }).catch(() => {});
    }, [projectId, platform, isInitialized, shouldFetch, isActive, fetchData]);

    const PlatformIcon = isActive
        ? activePlatformIcons[platform as keyof typeof activePlatformIcons]
        : platformIcons[platform as keyof typeof platformIcons];

    const formatValuesMappedByCurrency = (data: Record<string, number>) => {
        return Object.entries(data).map(([curr, value]) => {
            const currency = currencies.find((currency) => currency.id === Number(curr));
            return asMoney(value, currency);
        });
    };

    const formattedSpendValues = formatValuesMappedByCurrency(data?.total_spent || {});
    const formattedCpmValues = formatValuesMappedByCurrency(data?.cpm || {});
    const engagementRate =
        typeof data?.engagement_rate === 'number' ? `${toDecimalPoint(data.engagement_rate * 100, 2)}%` : '-';
    const formattedViewCount = typeof data?.view_count === 'number' ? formatNumberToKNotation(data?.view_count) : '-';

    if (!isActive) {
        return (
            <PlatformIcon
                role="img"
                title={`Inactive ${platform}`}
                className={cn(styles.platformIcon, styles.ghostIcon)}
            />
        );
    }

    return (
        <>
            <PlatformIcon
                role="img"
                title={platform}
                onClick={(e) => {
                    if (!isActive) {
                        return;
                    }

                    e.stopPropagation();
                    navigate(`${projectId}/#${platform}`);
                }}
                className={styles.platformIcon}
                // To prevent fetching data on quick mouse-overs
                onMouseEnter={() => handleWithDelay(() => setShouldFetch(true))}
                onMouseLeave={() => (delay ? clearTimeout(delay) : undefined)}
                ref={setAnchorElement}
            />
            <Tooltip anchorElement={anchorElement} className={styles.tooltip}>
                <Tooltip.Title>{capitalize(platform)}</Tooltip.Title>
                <Tooltip.Body>
                    {status === 'error' ? (
                        <>Something went wrong</>
                    ) : (
                        <dl className={styles.stats}>
                            <div className={styles.item}>
                                <dt>Posts</dt>

                                <SkeletonizedValue
                                    className={styles.skeleton}
                                    containerClassName={styles.skeletonContainer}
                                    isInitialized={isInitialized}
                                >
                                    <dd>{data?.post_count ?? '-'}</dd>
                                </SkeletonizedValue>
                            </div>

                            <div className={styles.item}>
                                <dt>Total spent</dt>

                                <SkeletonizedValue
                                    className={styles.skeleton}
                                    containerClassName={styles.skeletonContainer}
                                    isInitialized={isInitialized}
                                >
                                    <dd>
                                        {formattedSpendValues.length
                                            ? formattedSpendValues.map((value, index) => <div key={index}>{value}</div>)
                                            : '-'}
                                    </dd>
                                </SkeletonizedValue>
                            </div>

                            <div className={styles.item}>
                                <dt>Total views</dt>

                                <SkeletonizedValue
                                    isInitialized={isInitialized}
                                    className={styles.skeleton}
                                    containerClassName={styles.skeletonContainer}
                                >
                                    <dd>{formattedViewCount}</dd>
                                </SkeletonizedValue>
                            </div>

                            <div className={styles.item}>
                                <dt>CPM</dt>

                                <SkeletonizedValue
                                    className={styles.skeleton}
                                    containerClassName={styles.skeletonContainer}
                                    isInitialized={isInitialized}
                                >
                                    <dd>
                                        {formattedCpmValues.length
                                            ? formattedCpmValues.map((value, index) => <div key={index}>{value}</div>)
                                            : '-'}
                                    </dd>
                                </SkeletonizedValue>
                            </div>

                            <div className={styles.item}>
                                <dt>Engagement rate</dt>

                                <SkeletonizedValue
                                    className={styles.skeleton}
                                    containerClassName={styles.skeletonContainer}
                                    isInitialized={isInitialized}
                                >
                                    <dd>{engagementRate}</dd>
                                </SkeletonizedValue>
                            </div>
                        </dl>
                    )}
                </Tooltip.Body>
            </Tooltip>
        </>
    );
};

export default PlatformStatsBadge;
