import { ReactComponent as SongIcon } from 'assets/whitelabel/Song.svg';
import { ReactComponent as AudioIcon } from 'assets/whitelabel/Audio.svg';
import { ReactComponent as SpotifyIcon } from 'assets/whitelabel/platform/SpotifyColor.svg';
import styles from './MediaBadge.module.css';

type Props = {
    type: 'song' | 'audio' | 'released';
};

type Mapping<T> = { [type in Props['type']]: T };

const icons: Mapping<typeof SongIcon> = {
    song: SongIcon,
    audio: AudioIcon,
    released: SpotifyIcon,
};

const labels: Mapping<string> = {
    audio: 'Audio',
    released: 'Released',
    song: 'Song',
};

const MediaBadge = ({ type }: Props) => {
    const Icon = icons[type];
    const label = labels[type];

    return (
        <div className={styles.container}>
            <Icon className={styles.icon} />
            <span>{label}</span>
        </div>
    );
};

export default MediaBadge;
