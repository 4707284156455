import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { TiktokInfluencerPostResult } from 'App.types';
import { InfluencerPlanContext } from '../../contexts/InfluencerPlanContext';
import styles from './TiktokTable.module.css';
import { showNotification } from 'helpers';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { InfluencerPlanGroupBar } from '../InfluencerPlanGroupBar/InfluencerPlanGroupBar';
import { ProtectedByUserGroups } from 'SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../ui/General/Modal/Modal';
import Button from '../../../../../ui/Buttons/Button/Button';
import useUrlState from '../../../../../Hooks/useUrlState';
import Skeleton from '../../../../../ui/Skeleton/Skeleton';
import { FCWithChildren } from 'utility/utility.types';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import { getTiktokAudio, TiktokAudio, TiktokUserStats, InfluencerPostGroup } from '@round/api';
import {
    EDITABLE_STATUSES,
    getTotalEngagements,
    postInactiveStatuses,
    statusOrder,
} from '../../InfluencerPlan.helpers';
import ExpandBar from '../../components/ExpandBar/ExpandBar';
import copyComponentRenderToClipboard from 'utility/copyToClipboard/copyComponentRenderToClipboard';
import TiktokReportClipboardTable from '../copyToClipboard/TiktokReportClipboardTable/TiktokReportClipboardTable';
import TiktokProposalClipboardTable, {
    TiktokProposalClipboardTableRow,
} from '../copyToClipboard/TiktokProposalClipboardTable/TiktokProposalClipboardTable';
import copy from 'copy-to-clipboard';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { TiktokCreatorsContext } from '../../contexts/TiktokCreatorsContext/TiktokCreatorsContext';
import EditBriefModal from '../EditBriefModal/EditBriefModal';
import { formatTiktokBriefDataToWhatsAppMarkdown } from '../copyToClipboard/copyToClipboard.helpers';
import CreateTiktokInfluencerPostModal from '../InfluencerPlanItemModal/CreateTiktokInfluencerPostModal/CreateTiktokInfluencerPostModal';
import { TiktokTotalsByGroup } from '../../InfluencerPlan.types';
import TiktokMicrosStatsBar from '../MicrosStatsBar/TiktokMicrosStatsBar/TiktokMicrosStatsBar';
import MicroCreatorsPostsTable from '../MicrosStatsBar/MicroCreatorsPostsTable/MicroCreatorsPostsTable';
import useTiktokInfluencerPostGroups from '../../contexts/TiktokCreatorsContext/useTiktokInfluencerPostGroups';
import useCreatorPlanTiktokInfluencerPosts from '../../contexts/TiktokCreatorsContext/useCreatorPlanTiktokInfluencerPosts';
import TiktokInfluencerPostsTable from './TiktokInfluencerPostsTable/TiktokInfluencerPostsTable';
import { getCountry } from 'utility/utility';
import CreateTiktokMicroPostModal from '../../../Microwave/CreateTiktokMicroPostModal/CreateTiktokMicroPostModal';
import { useMicroCampaigns } from '../../../Microwave/MicroCreators/contexts/MicroCampaign';
import { useContentTags } from 'contexts/ContentTagsContext';
import { useTiktokMicroCampaignStats } from 'Modules/Advertising/Microwave/MicroCreators/contexts/TiktokMicroCampaignStats';

const sum = (prev: number, next: number) => prev + next;
const getValue = (key: string, tiktokPostResults: any[]) => tiktokPostResults.map((val) => val[key]);

type UrlState = {
    expandedGroups: string;
    expandedBackupTables: string;
    postId: string;
    microsExpanded: boolean;
};

const TiktokTable: FCWithChildren = () => {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const {
        initStats: initTiktokInfluencerPostGroupStats,
        groupsInitialized: tiktokInfluencerPostGroupsInitialized,
        groupStatsInitialized: tiktokInfluencerPostGroupStatsInitialized,
        groups: tiktokInfluencerPostGroups,
        groupStats: tiktokInfluencerPostGroupStats,
    } = useTiktokInfluencerPostGroups();

    const {
        updateTiktokInfluencerPostGroup,
        deleteTiktokInfluencerPostGroup,
        increaseTiktokInfluencerPostGroupOrder,
        decreaseTiktokInfluencerPostGroupOrder,
        tiktokInfluencerPlanAudios,
    } = useNonNullContext(TiktokCreatorsContext);

    const {
        campaigns: microCampaigns,
        isLoading: isMicroCampaignsLoading,
        isInitialized: isMicroCampaignsInitialized,
        hasError: hasMicroCampaignsLoadingError,
    } = useMicroCampaigns(influencerPlan?.release.id);

    const tiktokMicroCampaign = microCampaigns.find((c) => c.platform === 'tiktok');

    const {
        microsStats,
        pendingPostCount,
        isLoading: isMicroStatsFetchLoading,
        hasError: microStatsHasError,
        isInitialized: isMicrosStatsInitialized,
        refresh: refreshMicrosPosts,
    } = useTiktokMicroCampaignStats(influencerPlan?.id);
    const isMicroStatsLoading = isMicroStatsFetchLoading || (!isMicrosStatsInitialized && !microStatsHasError);

    const {
        init: initInfluencerPosts,
        initialized: influencerPostsInitialized,
        loading: influencerPostsLoading,
        error: influencerPostsLoadingError,
        rows: tiktokInfluencerPostRows,
        posts: influencerPosts,
        videos,
        videoStats,
        userStats,
        influencerUsers,
        updateInfluencerPost,
        updateInfluencerPostStatus,
    } = useCreatorPlanTiktokInfluencerPosts();

    const { tags: contentTags, init: initContentTags, isInitialized } = useContentTags();

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                initContentTags({ signal });
            }
        },
        [isInitialized, initContentTags]
    );

    const [isEditBriefModalOpen, setIsEditBriefModalOpen] = useState(false);
    const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
    const [isCreateMicroPostModalOpen, setIsCreateMicroPostModalOpen] = useState(false);
    const [groupToEditBrief, setGroupToEditBrief] = useState<InfluencerPostGroup | null>(null);
    const [groupToAddPost, setGroupToAddPost] = useState<number | null>(null);
    const [groupToDelete, setGroupToDelete] = useState<InfluencerPostGroup | null>(null);
    const [groupDeleteLoading, setGroupDeleteLoading] = useState(false);
    const showDeleteGroupConfirmationDialog = useMemo(() => !!groupToDelete, [groupToDelete]);
    const [currentTiktokAudio, setCurrentTiktokAudio] = useState<TiktokAudio | null>(null);

    const [urlState, setUrlState] = useUrlState<UrlState>();
    const expandedGroups = urlState.expandedGroups?.split(',').map((g) => Number(g)) ?? [];
    const setExpandedGroups = (group: number) => {
        if (expandedGroups.includes(group)) {
            setUrlState({ expandedGroups: expandedGroups.filter((g) => g !== group).join(',') });
            return;
        }

        setUrlState({ expandedGroups: expandedGroups.concat(group).join(',') });
    };

    const activePosts = useMemo(() => {
        const activePosts = tiktokInfluencerPostRows.filter((post) => !postInactiveStatuses.includes(post.status));
        return activePosts.sort((a, b) => {
            const orderByStatus = statusOrder[a.status] - statusOrder[b.status];
            const viewsDescending = (b.tiktokVideoStats?.play_count ?? 0) - (a.tiktokVideoStats?.play_count ?? 0);
            const estimatedViewsDescending =
                (b.estimated_views_override ?? b.estimated_views ?? 0) -
                (a.estimated_views_override ?? a.estimated_views ?? 0);

            if (orderByStatus !== 0) {
                return orderByStatus;
            }

            if (viewsDescending !== 0) {
                return viewsDescending;
            }

            return estimatedViewsDescending;
        });
    }, [tiktokInfluencerPostRows]);

    const expandedBackupTables = urlState.expandedBackupTables?.split(',').map((g) => Number(g)) ?? [];
    const setExpandedBackupTables = (group: number) => {
        if (expandedBackupTables.includes(group)) {
            setUrlState({ expandedBackupTables: expandedBackupTables.filter((g) => g !== group).join(',') });
            return;
        }

        setUrlState({ expandedBackupTables: expandedBackupTables.concat(group).join(',') });
    };

    const showMicrosBar = microCampaigns.some((c) => c.platform === 'tiktok');
    const totalsByGroup = useMemo(() => {
        const totalsByGroup: TiktokTotalsByGroup = {};

        if (!tiktokInfluencerPostGroups.length || !influencerPosts.length) {
            return totalsByGroup;
        }

        for (const group of tiktokInfluencerPostGroups) {
            const posts = activePosts.filter((post) => post.group_id === group.id);

            let usersStats: TiktokUserStats[] = [];
            let results: TiktokInfluencerPostResult[] = [];

            if (videoStats.length || userStats.length) {
                for (const post of posts) {
                    const influencer = influencerUsers.find((influencer) => influencer.id === post.influencer_id);
                    usersStats = [
                        ...usersStats,
                        ...userStats.filter((userStats) => userStats.user_id === influencer?.user),
                    ];
                    results = [...results, ...videoStats.filter((result) => result.video_id === post.tiktok_post)];
                }
            }

            let followerCount = 0;
            let userFollowerCount = usersStats.map((userStats) => userStats.follower_count);

            if (userFollowerCount.length > 0) {
                followerCount = userFollowerCount.reduce(sum, 0);
            }

            totalsByGroup[group.id] = {
                followerCount,
                playCount: getValue('play_count', results).reduce(sum, 0),
                totalEngagement: results
                    .map((result) =>
                        getTotalEngagements(result?.digg_count, result?.comment_count, result?.share_count)
                    )
                    .reduce(sum, 0),
                diggCount: getValue('digg_count', results).reduce(sum, 0),
                commentCount: getValue('comment_count', results).reduce(sum, 0),
                shareCount: getValue('share_count', results).reduce(sum, 0),
                saveCount: getValue('save_count', results).reduce(sum, 0),
                estimatedViews: posts.reduce((views, post) => {
                    return views + Number(post.estimated_views_override ?? post.estimated_views ?? 0);
                }, 0),
                cost: getValue('cost', posts)
                    .map((n) => Number(n))
                    .reduce(sum, 0),
                liveCost: getValue(
                    'cost',
                    posts.filter((post) => post.status === 'live')
                )
                    .map((n) => Number(n))
                    .reduce(sum, 0),
                clientCost: getValue('client_cost', posts)
                    .map((n) => Number(n))
                    .reduce(sum, 0),
            };
        }

        return totalsByGroup;
    }, [tiktokInfluencerPostGroups, influencerPosts.length, activePosts, videoStats, userStats, influencerUsers]);

    const askDeleteGroupConfirmation = useCallback((groupToDelete: InfluencerPostGroup) => {
        setGroupToDelete(groupToDelete);
    }, []);

    const resetGroupDeletion = useCallback(() => {
        setGroupToDelete(null);
    }, []);

    const onDeleteGroup = useCallback(async () => {
        if (!groupToDelete) {
            return;
        }

        try {
            setGroupDeleteLoading(true);
            await deleteTiktokInfluencerPostGroup(groupToDelete.id);
            resetGroupDeletion();
            showNotification('Group deleted', 'info');
        } catch {
            showNotification('Could not delete group', 'error');
        } finally {
            setGroupDeleteLoading(false);
        }
    }, [deleteTiktokInfluencerPostGroup, groupToDelete, resetGroupDeletion]);

    const onDragEnd = useCallback(
        async (dropResult: DropResult) => {
            const { source, destination } = dropResult;
            const post = influencerPosts.find((post) => post.id === source.index);

            const sourceGroup = source.droppableId.split('-')[0];
            const destinationGroup = destination?.droppableId.split('-')[0];

            if (!destinationGroup || !post || !destination) {
                return;
            }

            if (sourceGroup !== destinationGroup) {
                await updateInfluencerPost(post.id, { group_id: Number(destinationGroup) });
            }

            if (!EDITABLE_STATUSES.includes(post.status)) {
                return;
            }

            try {
                const toActive = source.droppableId.includes('backup') && !destination.droppableId.includes('backup');
                if (toActive) {
                    await updateInfluencerPostStatus({
                        status: 'planned',
                        influencer_post_id: post.id,
                        draft_expected_by: null,
                    });
                    return;
                }

                const toBackup = destination.droppableId.includes('backup') && !source.droppableId.includes('backup');
                if (toBackup) {
                    await updateInfluencerPostStatus({
                        status: 'backup',
                        influencer_post_id: post.id,
                        draft_expected_by: null,
                    });
                    return;
                }
            } catch {
                showNotification('Could not update post status', 'error');
            }
        },
        [influencerPosts, updateInfluencerPost, updateInfluencerPostStatus]
    );

    const copyReportTableToClipboard = useCallback(
        (group: InfluencerPostGroup) => {
            if (!influencerPlan) {
                return;
            }

            const totals = totalsByGroup[group.id];
            const items =
                activePosts
                    .filter((p) => p.group_id === group.id)
                    .map((post) => {
                        const influencer = influencerUsers.find((influencer) => influencer.id === post.influencer_id);
                        const postUserId = influencer?.user ?? post.tiktok_user;
                        const stats = videoStats.find((item) => post.tiktok_post === item.video_id);
                        const tiktokUserStats = userStats?.find((userStats) => userStats.user_id === postUserId);
                        const video = videos?.find((video) => post.tiktok_post === video.id);
                        const totalEngagement = getTotalEngagements(
                            stats?.digg_count,
                            stats?.comment_count,
                            stats?.share_count
                        );

                        return {
                            status: post.status,
                            postViews: stats?.play_count,
                            likes: stats?.digg_count,
                            comments: stats?.comment_count,
                            shares: stats?.share_count,
                            saves: stats?.save_count,
                            account: influencer?.username ?? '',
                            accountFollowers: tiktokUserStats?.follower_count,
                            link: post.post_url,
                            engagementRate: Number(totalEngagement) / Number(stats?.play_count),
                            videoUploadedDate:
                                typeof video?.create_time === 'number'
                                    ? new Date(video?.create_time * 1000).toISOString()
                                    : null,
                        };
                    }) ?? [];

            const success = copyComponentRenderToClipboard(
                {
                    release: influencerPlan.release,
                    groupName: group.name,
                    totals: {
                        likes: totals.diggCount,
                        comments: totals.commentCount,
                        shares: totals.shareCount,
                        saves: totals.saveCount,
                        postViews: totals.playCount,
                        engagementRate: Number(totals.totalEngagement) / Number(totals.playCount),
                    },
                    items,
                },
                TiktokReportClipboardTable
            );

            if (success) {
                showNotification('Copied!', 'info');
            } else {
                showNotification('Could not copy', 'error');
            }
        },
        [influencerPlan, totalsByGroup, activePosts, influencerUsers, videoStats, userStats, videos]
    );

    const copyProposalTableToClipboard = useCallback(
        (group: InfluencerPostGroup) => {
            if (!influencerPlan) {
                return;
            }

            const items: TiktokProposalClipboardTableRow[] = activePosts
                .filter((p) => p.status !== 'live')
                .filter((p) => p.group_id === group.id)
                .map<TiktokProposalClipboardTableRow>((p) => {
                    const influencer = influencerUsers.find((influencer) => influencer.id === p.influencer_id);
                    const postUserId = influencer?.user ?? p.tiktok_user;
                    const tiktokUserStats = userStats?.find((s) => s.user_id === postUserId);
                    return {
                        account: influencer?.username ?? '',
                        contentTags: contentTags.filter((tag) => influencer?.content_tags.includes(tag.id)) ?? [],
                        location: getCountry(influencer?.location)?.label ?? '-',
                        followerCount: tiktokUserStats?.follower_count ?? null,
                        estimatedViews: p.estimated_views_override ?? p.estimated_views,
                    };
                });

            const success = copyComponentRenderToClipboard(
                {
                    release: influencerPlan.release,
                    groupName: group.name,
                    items,
                    brief: group.brief ?? '',
                },
                TiktokProposalClipboardTable
            );

            if (success) {
                showNotification('Copied!', 'info');
            } else {
                showNotification('Could not copy', 'error');
            }
        },
        [influencerPlan, activePosts, influencerUsers, userStats, contentTags]
    );

    const fetchTiktokAudio = useCallback(
        async (requestInit: RequestInit) => {
            const audioId = tiktokInfluencerPlanAudios.find((audio) => audio.plan === influencerPlan?.id)?.audio_id;
            if (!audioId) {
                return;
            }
            try {
                const response = await getTiktokAudio(audioId, requestInit);
                if (response.status === 404) {
                    return;
                }

                setCurrentTiktokAudio(response.data);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }
            }
        },
        [influencerPlan?.id, tiktokInfluencerPlanAudios]
    );

    useAbortableEffect(
        (signal) => {
            fetchTiktokAudio({ signal });
        },
        [fetchTiktokAudio]
    );

    const copyBriefToClipboard = useCallback(
        (group: InfluencerPostGroup) => {
            if (!influencerPlan) {
                return;
            }

            const formattedBriefData = formatTiktokBriefDataToWhatsAppMarkdown({
                release: influencerPlan.release,
                sound: currentTiktokAudio,
                brief: group.brief,
                turnaround: group.brief_turnaround,
            });

            const success = copy(formattedBriefData, { format: 'text/plain' });

            if (success) {
                showNotification('Copied!', 'info');
            } else {
                showNotification('Could not copy', 'error');
            }
        },
        [influencerPlan, currentTiktokAudio]
    );

    const onClickEditBriefModal = useCallback((group: InfluencerPostGroup) => {
        setGroupToEditBrief(group);
        setIsEditBriefModalOpen(true);
    }, []);

    const onCloseEditBriefModal = useCallback(() => {
        setGroupToEditBrief(null);
        setIsEditBriefModalOpen(false);
    }, []);

    const updateBrief = useCallback(
        async (groupId: number, data: Partial<Pick<InfluencerPostGroup, 'brief' | 'brief_turnaround'>>) => {
            try {
                await updateTiktokInfluencerPostGroup(groupId, data);
            } catch (e) {
                throw new Error();
            }
        },
        [updateTiktokInfluencerPostGroup]
    );

    const onCreatePost = useCallback((groupId: number) => {
        setGroupToAddPost(groupId);
        setIsCreatePostModalOpen(true);
    }, []);

    useAbortableEffect(
        (signal) => {
            if (!influencerPostsInitialized) {
                initInfluencerPosts({ signal });
            }
        },
        [influencerPostsInitialized, initInfluencerPosts]
    );

    useEffect(() => {
        if (urlState.postId && urlState.expandedGroups) {
            const id = urlState.postId;
            document.getElementById(id)?.scrollIntoView();
        }
    }, [urlState.postId, urlState.expandedGroups]);

    useAbortableEffect(
        (signal) => {
            if (!tiktokInfluencerPostGroupStatsInitialized) {
                initTiktokInfluencerPostGroupStats({ signal });
            }
        },
        [initTiktokInfluencerPostGroupStats, tiktokInfluencerPostGroupStatsInitialized]
    );

    const influencerPostTablesLoading =
        influencerPostsLoading || (!influencerPostsInitialized && !influencerPostsLoadingError);

    return (
        <>
            <Modal
                closeOnOverlayClick
                className={styles.alignCenter}
                isOpen={showDeleteGroupConfirmationDialog}
                onClose={resetGroupDeletion}
            >
                <ModalTitle>Are you sure?</ModalTitle>
                <ModalContent>
                    You are going to delete {groupToDelete?.name} group. <br />
                    This will also remove all items in the group
                </ModalContent>
                <ModalFooter align="center">
                    <Button onClick={resetGroupDeletion}>Cancel</Button>
                    <Button type="filled" color="black" loading={groupDeleteLoading} onClick={onDeleteGroup}>
                        Delete group
                    </Button>
                </ModalFooter>
            </Modal>

            <ProtectedByUserGroups groups={['influencer_editor']}>
                <EditBriefModal
                    isModalOpen={isEditBriefModalOpen}
                    group={groupToEditBrief}
                    closeModal={onCloseEditBriefModal}
                    updateBrief={updateBrief}
                />

                <CreateTiktokInfluencerPostModal
                    isOpen={isCreatePostModalOpen}
                    onClose={() => {
                        setIsCreatePostModalOpen(false);
                        setGroupToAddPost(null);
                    }}
                    onCreated={() => {
                        setIsCreatePostModalOpen(false);
                        setGroupToAddPost(null);
                        showNotification('Post created', 'info');
                    }}
                    groupId={groupToAddPost}
                />
            </ProtectedByUserGroups>

            <ProtectedByUserGroups groups={['microwave_admin']}>
                <CreateTiktokMicroPostModal
                    isOpen={isCreateMicroPostModalOpen}
                    onClose={() => {
                        setIsCreateMicroPostModalOpen(false);
                    }}
                    onCreated={() => {
                        setIsCreateMicroPostModalOpen(false);
                        refreshMicrosPosts();
                    }}
                    campaignId={tiktokMicroCampaign?.id}
                />
            </ProtectedByUserGroups>

            <section className={styles.groupsContainer}>
                {(!tiktokInfluencerPostGroupsInitialized || !tiktokInfluencerPostGroupStatsInitialized) && (
                    <>
                        <Skeleton className={styles.groupSkeleton} />
                        <Skeleton className={styles.groupSkeleton} />
                        <Skeleton className={styles.groupSkeleton} />
                    </>
                )}

                {tiktokInfluencerPostGroupsInitialized && tiktokInfluencerPostGroupStatsInitialized && (
                    <>
                        <DragDropContext onDragEnd={onDragEnd}>
                            {tiktokInfluencerPostGroups
                                .sort((a, b) => a.ordering_index - b.ordering_index)
                                .map((group, i) => {
                                    const groupPosts = tiktokInfluencerPostRows.filter((p) => p.group_id === group.id);
                                    const groupActivePosts = activePosts.filter((p) => p.group_id === group.id);
                                    const groupBackups = groupPosts
                                        .filter((p) => postInactiveStatuses.includes(p.status))
                                        .sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);

                                    return (
                                        <div className={styles.groupItem} key={group.id}>
                                            <InfluencerPlanGroupBar
                                                platform="tiktok"
                                                name={group.name}
                                                group={group}
                                                index={i}
                                                groupQuantity={tiktokInfluencerPostGroups.length || 1}
                                                groups={tiktokInfluencerPostGroups}
                                                expandedGroups={expandedGroups}
                                                items={groupPosts}
                                                groupStats={tiktokInfluencerPostGroupStats.find(
                                                    (g) => g.group_id === group.id
                                                )}
                                                addPost={() => onCreatePost(group.id)}
                                                updateGroup={(platform, data) =>
                                                    updateTiktokInfluencerPostGroup(group.id, data)
                                                }
                                                deleteGroup={askDeleteGroupConfirmation}
                                                increaseGroupOrder={(platform, group) =>
                                                    increaseTiktokInfluencerPostGroupOrder(group.id)
                                                }
                                                decreaseGroupOrder={(platform, group) =>
                                                    decreaseTiktokInfluencerPostGroupOrder(group.id)
                                                }
                                                checkGroup={setExpandedGroups}
                                                currencySymbol={influencerPlan?.currency.symbol}
                                                copyReportTable={() => copyReportTableToClipboard(group)}
                                                copyProposalTable={() => copyProposalTableToClipboard(group)}
                                                copyBrief={() => copyBriefToClipboard(group)}
                                                onClickEditBrief={onClickEditBriefModal}
                                            />
                                            {expandedGroups.includes(group.id) && (
                                                <div className={styles.tableContainer}>
                                                    <div className={styles.tableContainerContent}>
                                                        <Droppable
                                                            key={String(group.id)}
                                                            droppableId={String(group.id)}
                                                        >
                                                            {(provided) => (
                                                                <div
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    <TiktokInfluencerPostsTable
                                                                        renderTotals
                                                                        highlightedPost={Number(urlState.postId)}
                                                                        loading={influencerPostTablesLoading}
                                                                        data={groupActivePosts}
                                                                        group={group}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Droppable>

                                                        <ProtectedByUserGroups groups={['influencer_editor']}>
                                                            <Droppable
                                                                key={`${group.id}-backup`}
                                                                droppableId={`${group.id}-backup`}
                                                            >
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        <ExpandBar
                                                                            isOpen={expandedBackupTables.includes(
                                                                                group.id
                                                                            )}
                                                                            title={`${
                                                                                groupBackups.length
                                                                            } backup creator${
                                                                                groupBackups.length !== 1 ? 's' : ''
                                                                            }`}
                                                                            onExpandClicked={() =>
                                                                                setExpandedBackupTables(group.id)
                                                                            }
                                                                        >
                                                                            <TiktokInfluencerPostsTable
                                                                                loading={influencerPostTablesLoading}
                                                                                data={groupBackups}
                                                                            />
                                                                        </ExpandBar>
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </ProtectedByUserGroups>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                        </DragDropContext>
                        {showMicrosBar && (
                            <>
                                {isMicroStatsLoading || !isMicroCampaignsInitialized || isMicroCampaignsLoading ? (
                                    <Skeleton className={styles.groupSkeleton} />
                                ) : (
                                    !microStatsHasError &&
                                    !hasMicroCampaignsLoadingError && (
                                        <div>
                                            <TiktokMicrosStatsBar
                                                campaign={tiktokMicroCampaign}
                                                microsStats={microsStats}
                                                isExpanded={Boolean(urlState.microsExpanded)}
                                                onExpandedChange={(expanded) => {
                                                    setUrlState({ microsExpanded: expanded || undefined });
                                                }}
                                                onClickAddMicroPost={() => setIsCreateMicroPostModalOpen(true)}
                                                toApprovePostCount={pendingPostCount ?? null}
                                            >
                                                <MicroCreatorsPostsTable posts={microsStats?.posts ?? []} />
                                            </TiktokMicrosStatsBar>
                                        </div>
                                    )
                                )}
                            </>
                        )}
                    </>
                )}
            </section>
        </>
    );
};

export default TiktokTable;
