import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReportContext } from '../../ReportContext';
import { useCallback } from 'react';
import { creatorbase } from '@round/api';
import { isNumber } from 'lodash';
import useYoutubeChannels from './useYoutubeChannels';

export function useYoutubeReportCampaignPosts(campaignId: number) {
    const postsState = useNonNullContextSelector(ReportContext, ([values]) => values.posts[campaignId]);
    const dispatch = useNonNullContextSelector(ReportContext, ([, dispatch]) => dispatch);

    const { data: channelsData, fetchData: fetchChannels } = useYoutubeChannels();

    const fetchData = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'loadPosts', payload: campaignId });
                const response = await creatorbase.getPosts(
                    { campaign_id: campaignId.toString(), page_size: 1000, ordering: '-view_count' },
                    requestInit
                );
                if (response.status === 200) {
                    dispatch({
                        type: 'postsSuccess',
                        payload: { campaignId: campaignId, posts: response.data.results },
                    });

                    const channelIds = response.data.results
                        .filter((p): p is creatorbase.YoutubePost => p.platform === 'youtube')
                        .map((post) => post.youtube_details?.account_id)
                        .filter(isNumber);

                    await fetchChannels(channelIds);

                    return response;
                }

                dispatch({
                    type: 'errorLoadingPosts',
                    payload: { campaignId: campaignId, message: response.data.detail },
                });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'postsIdle', payload: campaignId });
                    throw e;
                }

                dispatch({
                    type: 'errorLoadingPosts',
                    payload: { campaignId: campaignId, message: 'Could not get posts' },
                });
                throw e;
            }
        },
        [dispatch, campaignId, fetchChannels]
    );

    return {
        postsData: postsState ?? null,
        channelsData,
        fetchData,

        getIsAccountDataLoading: (accountId: number | null | undefined) =>
            Boolean(accountId && channelsData[accountId]?.status === 'loading'),
    };
}
