import { creatorbase } from '@round/api';

export const getAccountId = (post: creatorbase.Post) => {
    if (post.platform === 'youtube') {
        return post.youtube_details?.account_id;
    }

    if (post.platform === 'tiktok') {
        return post.tiktok_details?.account_id;
    }

    if (post.platform === 'instagram') {
        return post.instagram_details?.account_id;
    }
};
