import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Objective } from './objective.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    objective: Model as ModelDefinition<Objective>,
};

export const serializers = {
    objective: makeSerializer<Objective>([]),
};

export const factories = {
    objective: createFactory<Objective>({
        name(index: number) {
            return `name ${index}`;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const getObjectivesListUrl = '/api/advertising/viewsets/objective/';
    server.get(getObjectivesListUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('objective'), {
            url: getObjectivesListUrl,
            serialize: (resource) => this.serialize(resource, 'objective'),
            queryParams: request.queryParams,
        });
    });
}
