import React, { useMemo, useState } from 'react';
import useOutreachCreators from './useOutreachCreators';
import useUrlState from '../../../../../../Hooks/useUrlState';
import OutreachTable, { OutreachTableRow } from './OutreachTable/OutreachTable';
import styles from './Outreach.module.css';
import { Button, Checkbox, Modal, SearchInput, Select, Skeleton } from '@round/ui-kit';
import { countryOptions } from '../../../../../../utility/constants';
import { useAudioSelect } from '../../../../../TikTok/hooks/useAudioSelect';
import {
    AudioOption,
    AudioSelectOption,
} from '../../../../../TikTok/components/AudioSelectComponents/AudioSelectComponents';
import { AudioPlayerProvider } from '../../../../../AudioPlayer/AudioPlayerContext';
import { DebounceInput } from 'react-debounce-input';
import { GenericDropdownOption } from '../../../../../../App.types';
import { StylesConfig, ValueType } from 'react-select';
import InitialEmailPreview, { PreviewUser } from '../../EmailPreview/InitialEmailPreview/InitialEmailPreview';
import cn from 'classnames';
import GroupedOptionMultiSelect from '../../../../../../ui/DataEntry/Select/GroupedSelectionsMultiSelect/GroupedSelectionsMultiSelect';
import { getNumbersArrayFromString } from '../../../../../../utility/utility';
import useHashtagsSelect from '../Creators/useHashtagsSelect';
import useComponentWillUnmount from '../../../../../../Hooks/useComponentWillUnmount';
import { SearchMicrosParams } from '@round/api';
import { ReactComponent as MusicIcon } from 'assets/MusicNote.svg';
import { ReactComponent as CloseIcon } from 'assets/Close.svg';
import Dropdown from 'ui/Dropdown/Dropdown';
import { useGenres } from 'Hooks/useGenres';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { showNotification } from 'helpers';

type UrlState = Required<Pick<SearchMicrosParams, 'page' | 'page_size'>> &
    Partial<SearchMicrosParams> & {
        should_exclude_dashboard_influencers: string;
    };

type Props = {
    audioId: number;
    planId: number;
};

const initialUrlState: UrlState = {
    page: 1,
    page_size: 10,
    min_follower_count: 1000,
    max_follower_count: 50_000,
    should_exclude_dashboard_influencers: 'true',
};

const selectStyles: StylesConfig = {
    container: (base) => ({
        ...base,
        flex: 1,
    }),
    control: (base) => ({
        ...base,
        height: '100%',
        borderRadius: '0.375rem',
    }),
    menu: (base) => ({
        ...base,
        minWidth: 'max-content',
        overflow: 'hidden',
    }),
    menuList: (base) => ({
        ...base,
        minWidth: 'max-content',
    }),
};

const DEBOUNCE_TIMEOUT = 700;

const Outreach = ({ audioId, planId }: Props) => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState);

    useComponentWillUnmount(() => {
        sessionStorage.setItem('micro_outreach_url_state', JSON.stringify(urlState));
    });

    const [selectedCreators, setSelectedCreators] = useState<OutreachTableRow[]>([]);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [previewCreators, setPreviewCreators] = useState<OutreachTableRow[]>([]);

    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const page_size = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;
    const min_follower_count = urlState.min_follower_count ? Number(urlState.min_follower_count) : undefined;
    const max_follower_count = urlState.max_follower_count ? Number(urlState.max_follower_count) : undefined;
    const location = countryOptions.find((country) => country.value === urlState.location);
    const isExcludeDashboardInfluencerFilterEnabled = urlState.should_exclude_dashboard_influencers === 'true';

    const onMinFollowersCountChange = (inputValue: string) => {
        const value = parseInt(inputValue);
        setUrlState({
            min_follower_count: inputValue && isFinite(value) && !isNaN(value) ? value : undefined,
            page: 1,
        });
        resetCreators();
    };

    const onMaxFollowersCountChange = (inputValue: string) => {
        const value = parseInt(inputValue);
        setUrlState({
            max_follower_count: inputValue && isFinite(value) && !isNaN(value) ? value : undefined,
            page: 1,
        });
        resetCreators();
    };

    const { value: similarAudios, options: similarAudiosOptions, ...similarAudiosSelectProps } = useAudioSelect({
        initialAudioIdData: useMemo(() => getNumbersArrayFromString(urlState.similar_audio_ids ?? ''), [
            urlState.similar_audio_ids,
        ]),
        isMulti: true,
        searchOnly: true,
    });

    const { hashtagSelectProps, setSelectedHashtags, selectedHashtags } = useHashtagsSelect(
        useMemo(() => getNumbersArrayFromString(urlState.hashtag_ids ?? ''), [urlState.hashtag_ids])
    );

    const {
        genres: genreOptions,
        init: initGenres,
        isInitialized: isGenresInitialized,
        isLoading: isGenresLoading,
    } = useGenres();

    const selectedGenreOptions = genreOptions.filter((option) =>
        (urlState.with_genre_affinity?.split(',') ?? []).includes(option.value)
    );

    useAbortableEffect(
        (signal) => {
            if (!isGenresInitialized) {
                initGenres({ signal }).catch(() => showNotification('Could not fetch genre options', 'error'));
            }
        },
        [initGenres, isGenresInitialized]
    );

    const {
        creators,
        loading,
        creatorsCount,
        error,
        reset: resetCreators,
        removeCreators,
        refresh,
        isInitialized,
    } = useOutreachCreators({
        planId,
        tiktokAudioId: audioId,
        page,
        page_size,
        location: location?.value,
        similar_audio_ids: similarAudios?.map((option) => option.value).toString(),
        search: urlState.search,
        min_follower_count,
        max_follower_count,
        has_influencer_user: isExcludeDashboardInfluencerFilterEnabled ? 'false' : undefined,
        hashtag_ids: urlState.hashtag_ids,
        with_genre_affinity: urlState.with_genre_affinity,
        min_relevant_post_count: urlState.min_relevant_post_count
            ? Number(urlState.min_relevant_post_count)
            : undefined,
        ordering: urlState.with_genre_affinity ? '-genre_affinity' : undefined,
    });

    const [rowsDisplayed, setRowsDisplayed] = useState(0);
    const total = creatorsCount <= page_size ? rowsDisplayed : creatorsCount - (page_size - rowsDisplayed);
    const rowsDisplayedText = `displaying ${rowsDisplayed} out of ${total}`;
    const isTableLoading = loading || (!error && !isInitialized);

    return (
        <div className={styles.container}>
            <div className={styles.filters}>
                <div className={styles.filter}>
                    <label className={styles.filterLabel}>Similar audios</label>
                    <AudioPlayerProvider>
                        <GroupedOptionMultiSelect
                            {...similarAudiosSelectProps}
                            filterOption={null}
                            options={similarAudiosOptions.filter((o) => o.value !== audioId)}
                            value={similarAudios}
                            onChange={(options: ValueType<AudioOption, true>) => {
                                setUrlState({
                                    similar_audio_ids: options?.length
                                        ? options.map((opt) => opt.value).toString()
                                        : undefined,
                                    page: 1,
                                });
                                similarAudiosSelectProps.onChange(options);
                                resetCreators();
                            }}
                            components={{ Option: AudioSelectOption }}
                            styles={selectStyles}
                            placeholder="Search by tiktok id..."
                        />
                    </AudioPlayerProvider>
                </div>

                <div className={styles.filter}>
                    <span className={styles.filterLabel}>Genre Affinity</span>
                    <Dropdown
                        control={
                            <div className={cn(styles.filterButton)}>
                                <MusicIcon
                                    className={cn(styles.filterIcon, {
                                        [styles.active]: !!urlState.with_genre_affinity,
                                    })}
                                />
                                <span>Genre Affinity</span>
                                {!!urlState.with_genre_affinity && (
                                    <button
                                        className={styles.clearButton}
                                        onClickCapture={(e) => {
                                            e.stopPropagation();
                                            setUrlState({
                                                with_genre_affinity: undefined,
                                                min_relevant_post_count: undefined,
                                                page: 1,
                                            });
                                            resetCreators();
                                        }}
                                    >
                                        <CloseIcon />
                                    </button>
                                )}
                            </div>
                        }
                        className={styles.popoutDropdown}
                    >
                        <article>
                            <span className={styles.filterLabel}>Genre Affinity</span>
                            <GroupedOptionMultiSelect
                                options={genreOptions}
                                value={selectedGenreOptions}
                                onChange={(options) => {
                                    setUrlState({
                                        with_genre_affinity: options?.length
                                            ? options.map((opt) => opt.value).toString()
                                            : undefined,
                                        page: 1,
                                    });
                                    resetCreators();
                                }}
                                styles={selectStyles}
                                className={styles.genreSelect}
                                placeholder="Select genres"
                                isLoading={isGenresLoading}
                                isDisabled={isGenresLoading}
                                menuPortalTarget={null}
                            />
                        </article>

                        <article>
                            <span className={styles.filterLabel}>Min Rel. Post Count</span>
                            <DebounceInput
                                className={cn(styles.filterInput, styles.numberFilter)}
                                type="number"
                                placeholder="Enter min relevant posts..."
                                disabled={!urlState.with_genre_affinity}
                                value={urlState.min_relevant_post_count}
                                onChange={(e) => {
                                    setUrlState({
                                        min_relevant_post_count: e.target.value ? Number(e.target.value) : undefined,
                                        page: 1,
                                    });
                                    resetCreators();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </article>
                    </Dropdown>
                </div>

                <div className={styles.filter}>
                    <label className={styles.filterLabel}>Hashtags</label>
                    <GroupedOptionMultiSelect
                        value={selectedHashtags}
                        onChange={(options: ValueType<GenericDropdownOption<number>, true>) => {
                            setSelectedHashtags(options);
                            setUrlState({
                                hashtag_ids: options?.length ? options.map((option) => option.value).join() : undefined,
                            });
                            resetCreators();
                        }}
                        styles={selectStyles}
                        {...hashtagSelectProps}
                    />
                </div>

                <div className={cn(styles.filter, styles.numberFilter)}>
                    <label className={styles.filterLabel}>Min followers</label>
                    <DebounceInput
                        type="number"
                        className={styles.filterInput}
                        placeholder="Enter min follower count..."
                        value={urlState.min_follower_count}
                        onChange={(e) => {
                            onMinFollowersCountChange(e.target.value);
                        }}
                        debounceTimeout={700}
                    />
                </div>

                <div className={cn(styles.filter, styles.numberFilter)}>
                    <label className={styles.filterLabel}>Max followers</label>
                    <DebounceInput
                        type="number"
                        className={styles.filterInput}
                        placeholder="Enter max follower count..."
                        value={urlState.max_follower_count}
                        onChange={(e) => {
                            onMaxFollowersCountChange(e.target.value);
                        }}
                        debounceTimeout={700}
                    />
                </div>

                <div className={styles.filter}>
                    <label className={styles.filterLabel}>Location</label>
                    <Select
                        isClearable
                        placeholder="Select location..."
                        styles={selectStyles}
                        value={location}
                        options={countryOptions}
                        onChange={(location) => {
                            setUrlState({ location: location?.value ?? undefined, page: 1 });
                            resetCreators();
                        }}
                    />
                </div>

                <div className={styles.filter}>
                    <label className={styles.filterLabel}>Search</label>
                    <SearchInput
                        className={styles.filterInput}
                        placeholder="Search..."
                        value={urlState.search}
                        onChange={(search) => {
                            setUrlState({ search, page: 1 });
                            resetCreators();
                        }}
                    />
                </div>

                <div className={cn(styles.filter, styles.flexUnset)}>
                    <label className={styles.filterLabel}>
                        Exclude Dashboard <br /> Influencers
                    </label>
                    <div className={styles.filterCheckboxContainer}>
                        <Checkbox
                            value={isExcludeDashboardInfluencerFilterEnabled}
                            onChange={(value) => {
                                setUrlState({
                                    should_exclude_dashboard_influencers: value.toString(),
                                    page: 1,
                                });
                                resetCreators();
                            }}
                            className={styles.filterCheckbox}
                        />
                    </div>
                </div>

                <Button className={styles.refreshTableButton} disabled={loading} onClick={() => refresh()}>
                    Refresh table
                </Button>
            </div>

            <div className={styles.actionsRow}>
                <div className={cn(styles.actionButtons, { [styles.visible]: selectedCreators.length > 0 })}>
                    <Button
                        onClick={() => {
                            setPreviewCreators(selectedCreators);
                            setIsPreviewModalOpen(true);
                        }}
                    >
                        Preview {selectedCreators.length} email{selectedCreators.length !== 1 ? 's' : ''}
                    </Button>
                </div>

                <span className={styles.rowsCountLabel}>
                    {isTableLoading ? <Skeleton width="7rem" /> : rowsDisplayedText}
                </span>
            </div>

            <OutreachTable
                loading={isTableLoading}
                data={creators}
                count={creatorsCount}
                page={page}
                setPage={(page) => setUrlState({ page })}
                pageSize={page_size}
                setPageSize={(pageSize) => setUrlState({ page_size: pageSize ?? 10 })}
                noDataLabel={error ?? 'No data found.'}
                selectedRowIds={selectedCreators.map((c) => c.id)}
                onAllRowsSelect={(incomingCreators) =>
                    setSelectedCreators((prev) => {
                        if (!incomingCreators.every((creator) => !!prev.find((c) => c.id === creator.id))) {
                            return prev.concat(
                                incomingCreators.filter((c) => !prev.find((creator) => creator.id === c.id))
                            );
                        }

                        return prev.filter((c) => !incomingCreators.find((creator) => creator.id === c.id));
                    })
                }
                onRowSelect={(creator) =>
                    setSelectedCreators((creators) => {
                        if (creators.find((c) => c.id === creator.id)) {
                            return creators.filter((c) => c.id !== creator.id);
                        }

                        return creators.concat(creator);
                    })
                }
                onPaginationChange={(state) => setRowsDisplayed(state.pageRows.length)}
                shouldShowGenreAffinity={!!urlState.with_genre_affinity}
            />

            <Modal
                className={styles.previewModal}
                closeOnOverlayClick
                isOpen={isPreviewModalOpen}
                onClose={() => setIsPreviewModalOpen(false)}
            >
                {audioId && planId && previewCreators.length && (
                    <InitialEmailPreview
                        creators={previewCreators.map<PreviewUser>((creator) => ({
                            user_id: creator.id,
                            email: creator.email,
                            nickname: creator.nickname || creator.email || '-',
                        }))}
                        influencerPlanId={planId}
                        tiktokAudioId={audioId}
                        onEmailSent={(userIds) => {
                            removeCreators(userIds);
                            setIsPreviewModalOpen(false);
                            setPreviewCreators([]);
                            setSelectedCreators((creators) => creators.filter((c) => !userIds.includes(c.id)));
                        }}
                        notificationType={'SONG_PROMO_INITIAL_UNREGISTERED'}
                    />
                )}
            </Modal>
        </div>
    );
};

export default Outreach;
