import { useCallback, useMemo, useState } from 'react';
import { getPlannerUsers, UserPlanner } from '@round/api';
import debounce from 'lodash/debounce';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { showNotification } from 'helpers';
import { GenericDropdownOption } from 'App.types';
import { ValueType } from 'react-select';
type GetPlannerUsersParams = Parameters<typeof getPlannerUsers>[0];

const defaultPageSize = 25;

export default function usePlannerUsersSelect(
    initialUserIds?: number[],
    params: { initOptionsOnMenuOpen?: boolean } = {}
) {
    const [value, setValue] = useState<ValueType<any, any>>();
    const [isValueInitialized, setIsValueInitialized] = useState(false);

    useAbortableEffect(
        (signal) => {
            if (initialUserIds?.length && !isValueInitialized) {
                getPlannerUsers(
                    {
                        id: initialUserIds.join(','),
                        page_size: initialUserIds.length,
                    },
                    {
                        signal,
                    }
                )
                    .then((response) => {
                        setValue(
                            response.data.results.map((planner) => ({
                                value: planner.id,
                                label: `${planner.first_name} ${planner.last_name}`,
                            }))
                        );
                        setIsValueInitialized(true);
                    })
                    .catch((e) => {
                        if (e instanceof Error && e.name === 'AbortError') {
                            return;
                        }

                        showNotification('Could not init planner values', 'error');
                    });
            }
        },
        [initialUserIds, isValueInitialized]
    );

    const [planners, setPlanners] = useState<UserPlanner[]>([]);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [search, setSearch] = useState('');
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const fetchPlanners = useCallback(
        async (params: Omit<GetPlannerUsersParams, 'search'>, requestInit?: RequestInit) => {
            try {
                setIsLoading(true);
                const {
                    data: { results, next },
                } = await getPlannerUsers({ ...params, search }, requestInit);
                setPlanners((prev) => prev.concat(results));
                setHasNextPage(Boolean(next));
            } finally {
                setIsLoading(false);
                setIsSearchLoading(false);
            }
        },
        [search]
    );

    const debouncedInit = useMemo(() => debounce(fetchPlanners, 700), [fetchPlanners]);

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && (!params.initOptionsOnMenuOpen || isMenuOpen)) {
                debouncedInit({ page: 1, page_size: defaultPageSize }, { signal })
                    ?.then(() => setIsInitialized(true))
                    .catch((e) => {
                        if (e instanceof Error && e.name === 'AbortError') {
                            return;
                        }

                        showNotification('Could not fetch releases', 'error');
                    });

                return () => debouncedInit.cancel();
            }
        },
        [debouncedInit, isInitialized, isMenuOpen, params.initOptionsOnMenuOpen]
    );

    const loadNextPage = useCallback(async () => {
        fetchPlanners({ page: page + 1, page_size: defaultPageSize })
            .then(() => setPage((page) => page + 1))
            .catch((e) => {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                showNotification(e.message, 'error');
            });
    }, [fetchPlanners, page]);

    const reset = useCallback(() => {
        setPlanners([]);
        setPage(1);
        setHasNextPage(false);
        setSearch('');
        setIsInitialized(false);
    }, []);

    const options: GenericDropdownOption<number>[] = useMemo(
        () =>
            planners.map((p) => ({
                value: p.id,
                label: `${p.first_name} ${p.last_name}`,
            })),
        [planners]
    );

    return {
        value,
        onChange: setValue,
        options,
        filterOption: null,
        inputValue: search,
        onInputChange: (value: string) => {
            if (value === search) {
                return;
            }

            reset();
            setSearch(value);
            if (value) {
                setIsSearchLoading(true);
            }
        },
        onMenuScrollToBottom: () => {
            if (!hasNextPage) {
                return;
            }

            loadNextPage();
        },
        onMenuOpen: () => setIsMenuOpen(true),
        onMenuClose: () => {
            setIsMenuOpen(false);
        },
        isLoading: isLoading || isSearchLoading,
    };
}
