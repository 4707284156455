import { PaginatedRequest } from './types';

export enum MonitoredAudioTrackingTypes {
    Basic = 1,
    Enhanced = 2,
    Engagement = 3,
}

export const MonitoredAudioTrackingTypeLabels = new Map([
    [MonitoredAudioTrackingTypes.Basic, 'Normal'],
    [MonitoredAudioTrackingTypes.Engagement, 'Engagement'],
    [MonitoredAudioTrackingTypes.Enhanced, 'Enhanced'],
]);

export function buildApiUrl(url: string) {
    let resultUrl = url;
    const origin = process.env.REACT_APP_API_DOMAIN || '';

    if (!resultUrl.startsWith(origin)) {
        resultUrl = `${origin}${url}`;
    }

    return resultUrl;
}

export const createBatchedParamsArrayFromIds = <K extends number | string, P extends {}>(
    ids: K[],
    getParams: (batch: K[]) => P extends Partial<PaginatedRequest> ? never : P,
    batchSize: number = 50
): (P & {
    page_size: number;
})[] => {
    let idBatches = [];
    for (var i = 0; i < ids.length; i += batchSize) {
        idBatches.push(ids.slice(i, i + batchSize));
    }

    return idBatches.map((batch) => {
        return { ...getParams(batch), page_size: batch.length };
    });
};
