import React, { HTMLProps } from 'react';

type LockedCellProps = HTMLProps<HTMLTableCellElement> & {
    isDragging?: boolean;
};

type Snapshot = {
    width: number;
    height: number;
};

// This component is used to lock the dimensions of a cell while dragging a row
// Without this, the cell would resize to fit the content of the cell being dragged, because the table layout is lost during the drag
export class LockedCell extends React.Component<LockedCellProps> {
    ref: HTMLTableCellElement | null = null;

    // Captures the dimensions of the cell before the drag starts
    getSnapshotBeforeUpdate(prevProps: LockedCellProps) {
        if (!this.ref) {
            return null;
        }

        const isDragStarting = this.props.isDragging && !prevProps.isDragging;

        if (!isDragStarting) {
            return null;
        }

        const { width, height } = this.ref.getBoundingClientRect();

        const snapshot = {
            width,
            height,
        };

        return snapshot;
    }

    // Dimensions are applied to the cell, or removed if the drag is finished
    componentDidUpdate(prevProps: LockedCellProps, state: {}, snapshot: Snapshot | null) {
        if (!this.ref) {
            return;
        }

        if (snapshot) {
            if (this.ref.style.width === `${snapshot.width}px`) {
                return;
            }
            this.ref.style.width = `${snapshot.width}px`;
            this.ref.style.height = `${snapshot.height}px`;
            return;
        }

        if (this.props.isDragging || (!this.ref.style.width && !this.ref.style.height)) {
            return;
        }

        // no snapshot and drag is finished - clear the inline styles
        this.ref.style.removeProperty('height');
        this.ref.style.removeProperty('width');
    }

    setRef = (ref: HTMLTableCellElement | null) => {
        this.ref = ref;
    };

    render() {
        return (
            <td ref={this.setRef} {...this.props}>
                {this.props.children}
            </td>
        );
    }
}
