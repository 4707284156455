import { useCallback, useMemo, useState } from 'react';
import { getXeroReceivableInvoices, XeroInvoiceSimple } from '@round/api';
import { showNotification } from 'helpers';
import { DropdownOptionWithSubLabel } from '@round/ui-kit';
import useAbortableEffect from '../../../Hooks/useAbortableEffect';
import debounce from 'lodash/debounce';

export default function useReceivableInvoicesSelect() {
    const [invoices, setInvoices] = useState<XeroInvoiceSimple[]>([]);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [search, setSearch] = useState('');
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const fetchInvoices = useCallback(
        async (params: Parameters<typeof getXeroReceivableInvoices>[0], requestInit?: RequestInit) => {
            try {
                setIsLoading(true);
                const response = await getXeroReceivableInvoices(params, requestInit);
                setInvoices((prev) => prev.concat(response.data.results));
                setHasNextPage(Boolean(response.data.next));
                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                showNotification('Could not fetch invoices', 'error');
                setIsInitialized(true);
            } finally {
                setIsLoading(false);
                setIsSearchLoading(false);
            }
        },
        []
    );

    const debouncedInit = useMemo(() => debounce(fetchInvoices, 700), [fetchInvoices]);

    useAbortableEffect(
        (signal) => {
            if (isMenuOpen && !isInitialized) {
                debouncedInit({ page: 1, search }, { signal });
            }
        },
        [debouncedInit, isInitialized, isMenuOpen, search]
    );

    const loadNextPage = useCallback(async () => {
        fetchInvoices({ page: page + 1, search }).then(() => setPage((page) => page + 1));
    }, [fetchInvoices, page, search]);

    const reset = useCallback(() => {
        setInvoices([]);
        setPage(1);
        setHasNextPage(false);
        setSearch('');
        setIsInitialized(false);
    }, []);

    const options: DropdownOptionWithSubLabel<number>[] = useMemo(
        () =>
            invoices.map((inv) => ({
                value: inv.id,
                label: inv.invoice_number,
                subLabel: inv.reference,
            })),
        [invoices]
    );

    return {
        options,
        filterOption: null,
        inputValue: search,
        onInputChange: (value: string) => {
            if (value === search) {
                return;
            }

            reset();
            setSearch(value);
            if (value) {
                setIsSearchLoading(true);
            }
        },
        onMenuScrollToBottom: () => {
            if (!hasNextPage) {
                return;
            }

            loadNextPage();
        },
        onMenuOpen: () => setIsMenuOpen(true),
        onMenuClose: () => {
            setIsMenuOpen(false);
        },
        isLoading: (isMenuOpen && !isInitialized) || isLoading || isSearchLoading,
    };
}
