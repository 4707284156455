import { TiktokUser } from '@round/api';
import { BaseFilterObject, GenericDropdownOption } from 'App.types';
import useTiktokInfluencerSelect from 'Modules/TikTok/hooks/useTiktokInfluencerSelect';
import { StylesConfig, ValueType } from 'react-select';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import { Image } from '@round/ui-kit';
import styles from './TiktokInfluencerFilter.module.css';

export type TiktokInfluencerOption = GenericDropdownOption<number> & Pick<TiktokUser, 'unique_id' | 'avatar_thumb'>;

type Props = {
    initialInfluencerUserId?: number | undefined;
    onChange: (value: ValueType<TiktokInfluencerOption, false>) => void;
    onClear: () => void;
    onRemove: () => void;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
} & Pick<BaseFilterObject, 'name' | 'icon'>;

const selectStyles: StylesConfig = {
    option: (base) => ({
        ...base,
        padding: '0.3125rem 0',
        marginTop: '0.3125rem',
    }),
};

const TiktokInfluencerFilter = ({
    initialInfluencerUserId,
    onChange,
    onRemove,
    onClear,
    name,
    icon,
    isOpen,
    onOpen,
    onClose,
}: Props) => {
    const selectProps = useTiktokInfluencerSelect(initialInfluencerUserId);

    const clearState = () => {
        selectProps.onChange?.(null, { action: 'clear' });
    };

    return (
        <SelectFilter
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            name={name}
            icon={icon}
            styles={selectStyles}
            onClear={() => {
                onClear();
                clearState();
            }}
            onRemove={() => {
                clearState();
                onRemove();
            }}
            {...selectProps}
            onChange={(value: ValueType<TiktokInfluencerOption, false>, action) => {
                selectProps.onChange?.(value, action);
                onChange(value);
            }}
            formatOptionLabel={(option, meta) => {
                if (meta.context === 'value') {
                    return option.label;
                }

                return (
                    <div className={styles.container}>
                        <Image src={option.avatar_thumb} className={styles.image} />
                        <div className={styles.titles}>
                            <p className={styles.title}>{option.label}</p>
                            <p className={styles.subTitle}>@{option.unique_id}</p>
                        </div>
                    </div>
                );
            }}
        />
    );
};

export default TiktokInfluencerFilter;
