import { ReducerAction, ReducerActionWithPayload, TiktokInfluencerPostResult } from '../../../../../../App.types';
import { PhylloUserAnalyticsData, TiktokUserStats, TiktokInfluencerPost, TiktokUserPostStats } from '@round/api';
import { createReducer } from '../../../../../../helpers';
import { AudioData } from '../../../../TikTok.types';
import { TiktokDailyReportItem } from '../../../../../Advertising/MediaPlan/types/MediaPlanResults.types';

export type TiktokUserDataState = {
    tiktokUserStats: TiktokUserStats[];
    tiktokUserStatsLoading: boolean;
    errorLoadingTiktokUserStats: boolean;
    tiktokUserStatsInitialized: boolean;
    postStats: TiktokUserPostStats[];
    postStatsCount: number;
    postStatsLoading: boolean;
    errorLoadingPostStats: boolean;
    postStatsInitialized: boolean;
    promotionsPostStats: TiktokUserPostStats[];
    promotionsPostStatsLoading: boolean;
    promotionsPostStatsInitialized: boolean;
    errorLoadingPromotionsPostStats: boolean;
    tiktokUserDailyReportItems: TiktokDailyReportItem[];
    tiktokUserDailyReportItemsLoading: boolean;
    errorLoadingTiktokUserDailyReportItems: boolean;
    tiktokUserDailyReportItemsInitialized: boolean;
    influencerPosts: TiktokInfluencerPost[];
    influencerPostsLoading: boolean;
    errorLoadingInfluencerPosts: boolean;
    influencerPostsInitialized: boolean;
    influencerPostsVideoStats: TiktokInfluencerPostResult[];
    influencerPostsVideoStatsLoading: boolean;
    errorLoadingInfluencerPostsVideoStats: boolean;
    influencerPostsVideoStatsInitialized: boolean;
    influencerUserPostsAudioData: AudioData[];
    influencerUserPostsAudioDataLoading: boolean;
    errorLoadingInfluencerUserPostsAudioData: boolean;
    influencerUserPostsAudioDataInitialized: boolean;
    tiktokUserAnalytics: PhylloUserAnalyticsData | null;
    tiktokUserAnalyticsLoading: boolean;
    tiktokUserAnalyticsLoadingError: string | null;
};

export const initialState: TiktokUserDataState = {
    tiktokUserStats: [],
    tiktokUserStatsLoading: false,
    errorLoadingTiktokUserStats: false,
    tiktokUserStatsInitialized: false,
    postStats: [],
    postStatsCount: 0,
    postStatsLoading: false,
    errorLoadingPostStats: false,
    postStatsInitialized: false,
    promotionsPostStats: [],
    promotionsPostStatsLoading: false,
    promotionsPostStatsInitialized: false,
    errorLoadingPromotionsPostStats: false,
    tiktokUserDailyReportItems: [],
    tiktokUserDailyReportItemsLoading: false,
    errorLoadingTiktokUserDailyReportItems: false,
    tiktokUserDailyReportItemsInitialized: false,
    influencerPosts: [],
    influencerPostsLoading: false,
    errorLoadingInfluencerPosts: false,
    influencerPostsInitialized: false,
    influencerPostsVideoStats: [],
    influencerPostsVideoStatsLoading: false,
    errorLoadingInfluencerPostsVideoStats: false,
    influencerPostsVideoStatsInitialized: false,
    influencerUserPostsAudioData: [],
    influencerUserPostsAudioDataLoading: false,
    errorLoadingInfluencerUserPostsAudioData: false,
    influencerUserPostsAudioDataInitialized: false,
    tiktokUserAnalytics: null,
    tiktokUserAnalyticsLoading: false,
    tiktokUserAnalyticsLoadingError: null,
};

export type TiktokUserDataActions =
    | ReducerAction<'loadTiktokUserStats'>
    | ReducerAction<'errorLoadingTiktokUserStats'>
    | ReducerActionWithPayload<'loadedTiktokUserStats', TiktokUserStats[]>
    | ReducerAction<'resetTiktokUserStats'>
    | ReducerAction<'loadPostStats'>
    | ReducerAction<'errorLoadingPostStats'>
    | ReducerActionWithPayload<'loadedPostStats', { data: TiktokUserPostStats[]; count: number }>
    | ReducerAction<'resetPostStats'>
    | ReducerAction<'loadPromotionsPostStats'>
    | ReducerActionWithPayload<'loadedPromotionsPostStats', TiktokUserPostStats[]>
    | ReducerAction<'resetPromotionsPostStats'>
    | ReducerAction<'errorLoadingPromotionsPostStats'>
    | ReducerAction<'loadDailyReportItems'>
    | ReducerAction<'errorLoadingDailyReportItems'>
    | ReducerActionWithPayload<'loadedDailyReportItems', TiktokDailyReportItem[]>
    | ReducerAction<'resetDailyReportItems'>
    | ReducerAction<'loadInfluencerPosts'>
    | ReducerAction<'errorLoadingInfluencerPosts'>
    | ReducerActionWithPayload<'loadedInfluencerPosts', TiktokInfluencerPost[]>
    | ReducerAction<'resetInfluencerPosts'>
    | ReducerAction<'loadInfluencerPostsVideoStats'>
    | ReducerAction<'errorLoadingInfluencerPostsVideoStats'>
    | ReducerActionWithPayload<'loadedInfluencerPostsVideoStats', TiktokInfluencerPostResult[]>
    | ReducerAction<'resetInfluencerPostsVideoStats'>
    | ReducerAction<'loadInfluencerUserPostsAudioData'>
    | ReducerAction<'errorLoadingInfluencerUserPostsAudioData'>
    | ReducerActionWithPayload<'loadedInfluencerUserPostsAudioData', AudioData[]>
    | ReducerAction<'resetInfluencerUserPostsAudioData'>
    | ReducerAction<'loadTiktokUserAnalyticsData'>
    | ReducerActionWithPayload<'tiktokUserAnalyticsDataLoaded', PhylloUserAnalyticsData>
    | ReducerActionWithPayload<'errorLoadingTiktokUserAnalyticsData', string | null>
    | ReducerAction<'resetTiktokUserAnalytics'>;

export default createReducer<TiktokUserDataState, TiktokUserDataActions>({
    loadTiktokUserStats: (state) => ({
        ...state,
        errorLoadingTiktokUserStats: false,
        tiktokUserStatsLoading: true,
    }),
    errorLoadingTiktokUserStats: (state) => ({
        ...state,
        errorLoadingTiktokUserStats: true,
        tiktokUserStatsLoading: false,
    }),
    loadedTiktokUserStats: (state, { payload }) => ({
        ...state,
        tiktokUserStatsLoading: false,
        errorLoadingTiktokUserStats: false,
        tiktokUserStats: payload,
        tiktokUserStatsInitialized: true,
    }),
    resetTiktokUserStats: (state) => ({
        ...state,
        tiktokUserStats: [],
        tiktokUserStatsLoading: false,
        errorLoadingTiktokUserStats: false,
        tiktokUserStatsInitialized: false,
    }),
    loadPostStats: (state) => ({
        ...state,
        errorLoadingPostStats: false,
        postStatsLoading: true,
    }),
    errorLoadingPostStats: (state) => ({
        ...state,
        errorLoadingPostStats: true,
        postStatsLoading: false,
    }),
    loadedPostStats: (state, { payload }) => ({
        ...state,
        errorLoadingPostStats: false,
        postStatsLoading: false,
        postStats: payload.data,
        postStatsCount: payload.count,
        postStatsInitialized: true,
    }),
    resetPostStats: (state) => ({
        ...state,
        errorLoadingPostStats: false,
        postStatsLoading: false,
        postStats: [],
        postStatsCount: 0,
        postStatsInitialized: false,
    }),
    loadPromotionsPostStats: (state) => ({
        ...state,
        promotionsPostStatsLoading: true,
    }),
    loadedPromotionsPostStats: (state, { payload }) => ({
        ...state,
        promotionsPostStats: payload,
        promotionsPostStatsLoading: false,
        promotionsPostStatsInitialized: true,
    }),
    resetPromotionsPostStats: (state) => ({
        ...state,
        promotionsPostStats: [],
        promotionsPostStatsLoading: false,
        promotionsPostStatsInitialized: false,
    }),
    errorLoadingPromotionsPostStats: (state) => ({
        ...state,
        promotionsPostStatsLoading: false,
        errorLoadingPromotionsPostStats: true,
    }),
    loadDailyReportItems: (state) => ({
        ...state,
        errorLoadingTiktokUserDailyReportItems: false,
        tiktokUserDailyReportItemsLoading: true,
    }),
    errorLoadingDailyReportItems: (state) => ({
        ...state,
        errorLoadingTiktokUserDailyReportItems: true,
        tiktokUserDailyReportItemsLoading: false,
    }),
    loadedDailyReportItems: (state, { payload }) => ({
        ...state,
        errorLoadingTiktokUserDailyReportItems: false,
        tiktokUserDailyReportItemsLoading: false,
        tiktokUserDailyReportItems: payload,
        tiktokUserDailyReportItemsInitialized: true,
    }),
    resetDailyReportItems: (state) => ({
        ...state,
        errorLoadingTiktokUserDailyReportItems: false,
        tiktokUserDailyReportItemsLoading: false,
        tiktokUserDailyReportItems: [],
        tiktokUserDailyReportItemsInitialized: false,
    }),
    loadInfluencerPosts: (state) => ({
        ...state,
        influencerPostsLoading: true,
        errorLoadingInfluencerPosts: false,
    }),
    errorLoadingInfluencerPosts: (state) => ({
        ...state,
        influencerPostsLoading: false,
        errorLoadingInfluencerPosts: true,
    }),
    loadedInfluencerPosts: (state, { payload }) => ({
        ...state,
        influencerPosts: payload,
        influencerPostsLoading: false,
        errorLoadingInfluencerPosts: false,
        influencerPostsInitialized: true,
    }),
    resetInfluencerPosts: (state) => ({
        ...state,
        influencerPosts: [],
        influencerPostsLoading: false,
        errorLoadingInfluencerPosts: false,
        influencerPostsInitialized: false,
    }),
    loadInfluencerPostsVideoStats: (state) => ({
        ...state,
        influencerPostsVideoStatsLoading: true,
        errorLoadingInfluencerPostsVideoStats: false,
    }),
    errorLoadingInfluencerPostsVideoStats: (state) => ({
        ...state,
        influencerPostsVideoStatsLoading: false,
        errorLoadingInfluencerPostsVideoStats: true,
    }),
    loadedInfluencerPostsVideoStats: (state, { payload }) => ({
        ...state,
        influencerPostsVideoStats: payload,
        influencerPostsVideoStatsLoading: false,
        errorLoadingInfluencerPostsVideoStats: false,
        influencerPostsVideoStatsInitialized: true,
    }),
    resetInfluencerPostsVideoStats: (state) => ({
        ...state,
        influencerPostsVideoStats: [],
        influencerPostsVideoStatsLoading: false,
        errorLoadingInfluencerPostsVideoStats: false,
        influencerPostsVideoStatsInitialized: false,
    }),
    loadInfluencerUserPostsAudioData: (state) => ({
        ...state,
        influencerUserPostsAudioDataLoading: true,
        errorLoadingInfluencerUserPostsAudioData: false,
    }),
    errorLoadingInfluencerUserPostsAudioData: (state) => ({
        ...state,
        influencerUserPostsAudioDataLoading: false,
        errorLoadingInfluencerUserPostsAudioData: true,
    }),
    loadedInfluencerUserPostsAudioData: (state, { payload }) => ({
        ...state,
        influencerUserPostsAudioData: payload,
        influencerUserPostsAudioDataLoading: false,
        errorLoadingInfluencerUserPostsAudioData: false,
        influencerUserPostsAudioDataInitialized: true,
    }),
    resetInfluencerUserPostsAudioData: (state) => ({
        ...state,
        influencerUserPostsAudioData: [],
        influencerUserPostsAudioDataLoading: false,
        errorLoadingInfluencerUserPostsAudioData: false,
        influencerUserPostsAudioDataInitialized: false,
    }),
    loadTiktokUserAnalyticsData: (state) => ({
        ...state,
        tiktokUserAnalyticsLoadingError: null,
        tiktokUserAnalytics: null,
        tiktokUserAnalyticsLoading: true,
    }),
    tiktokUserAnalyticsDataLoaded: (state, { payload }) => ({
        ...state,
        tiktokUserAnalytics: payload,
        tiktokUserAnalyticsLoadingError: null,
        tiktokUserAnalyticsLoading: false,
    }),
    errorLoadingTiktokUserAnalyticsData: (state, { payload }) => ({
        ...state,
        tiktokUserAnalytics: null,
        tiktokUserAnalyticsLoadingError: payload,
        tiktokUserAnalyticsLoading: false,
    }),
    resetTiktokUserAnalytics: (state) => ({
        ...state,
        tiktokUserAnalytics: null,
        tiktokUserAnalyticsLoading: false,
        tiktokUserAnalyticsLoadingError: null,
    }),
});
