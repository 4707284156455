import { Table, TableProps } from '@round/ui-kit';
import { RowData } from '@tanstack/react-table';
import styles from './Table.module.css';
import cn from 'classnames';
import { Fragment, useRef } from 'react';
import { PartialKeys, useVirtualizer, VirtualizerOptions } from '@tanstack/react-virtual';

export type Props<T extends RowData> = TableProps<T> & {
    virtualizerOptions: Omit<
        PartialKeys<
            VirtualizerOptions<HTMLDivElement, HTMLTableRowElement>,
            'observeElementRect' | 'observeElementOffset' | 'scrollToFn'
        >,
        'getScrollElement' | 'measureElement'
    >;
    onEndReached?: () => void;
    containerClassName?: string;
    endReachedThreshold?: number;
};

const VirtualizedTable = <T extends RowData>({
    virtualizerOptions,
    onEndReached,
    containerClassName,
    endReachedThreshold = 500,
    ...props
}: Props<T>) => {
    const parentRef = useRef<HTMLDivElement | null>(null);
    const rowVirtualizer = useVirtualizer({
        ...virtualizerOptions,
        count: virtualizerOptions.count,
        getScrollElement: () => parentRef.current,
        //measure dynamic row height, except in firefox because it measures table border height incorrectly
        measureElement:
            typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
                ? (element) => element?.getBoundingClientRect().height
                : undefined,
    });
    const virtualItems = rowVirtualizer.getVirtualItems();

    return (
        <div
            ref={parentRef}
            className={cn(styles.container, containerClassName)}
            onScroll={(e) => {
                const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
                if (scrollHeight - scrollTop - clientHeight < endReachedThreshold) {
                    onEndReached?.();
                }
            }}
        >
            <Table
                {...props}
                className={cn(styles.table, styles.virtual, props.className)}
                noDataLabel={props.noDataLabel}
                renderBody={({
                    table,
                    getPinnedEdgeClassName,
                    getPinnedStyles,
                    spannedRowsData,
                    getRowClassName,
                    onRowClick,
                    renderSubComponent,
                    rowSpanHelper,
                    renderCell,
                }) => {
                    return (
                        <tbody
                            style={{
                                height: `${rowVirtualizer.getTotalSize()}px`,
                                position: 'relative',
                            }}
                        >
                            {!table.getRowModel().rows.length && props.noDataLabel && (
                                <tr>
                                    <td colSpan={table.getLeafHeaders().length}>{props.noDataLabel}</td>
                                </tr>
                            )}

                            {virtualItems.map((item) => {
                                const row = table.getRowModel().rows[item.index];
                                return (
                                    <Fragment key={row?.id}>
                                        <tr
                                            style={{
                                                height: `${item.size}px`,
                                                transform: `translateY(${item.start}px)`,
                                                position: 'absolute',
                                                width: '100%',
                                            }}
                                            onClick={() => onRowClick?.(row)}
                                            className={getRowClassName?.(row)}
                                        >
                                            {row?.getVisibleCells().map((cell) => {
                                                return renderCell?.({
                                                    cell,
                                                    getPinnedEdgeClassName,
                                                    getPinnedStyles,
                                                    spannedRowsData,
                                                    rowSpanHelper,
                                                });
                                            })}
                                        </tr>
                                        {row?.getIsExpanded() && (
                                            <tr>
                                                <td colSpan={row.getVisibleCells().length}>
                                                    {renderSubComponent?.(row)}
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </tbody>
                    );
                }}
            />
        </div>
    );
};

export default VirtualizedTable;
