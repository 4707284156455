import { CustomAudienceOption, FacebookCustomAudience } from '../../../../../../types/Facebook.types';
import Select, { StylesConfig } from 'react-select';
import React, { useMemo, useState } from 'react';
import styles from './FacebookTargetingCustomAudienceSelect.module.css';
import CustomAudienceRow from './components/CustomAudienceRow';
import CloseIcon from '../../../../../../../../../SharedComponents/svg/Icons/CloseIcon';
import MenuList from './components/MenuList';
import Option from './components/Option';

export type FilterMode = {
    name: 'all' | 'lookalike' | 'custom';
    label: string;
    filter: (c: CustomAudienceOption) => boolean;
};

const selectStylesConfig: StylesConfig = {
    control: (provided) => ({
        ...provided,
        borderColor: '#C2CFE0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingTop: '0.3rem',
        paddingBottom: '0.3rem',
        fontSize: '0.75rem',
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '0.75rem',
        color: '#90A0B7',
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    input: (provided) => ({
        ...provided,
        marginTop: '0',
        paddingTop: '0',
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 1000,
    }),
};

const LOOKALIKE_SUBTYPES: Array<FacebookCustomAudience['subtype']> = ['LOOKALIKE'];

type FacebookTargetingCustomAudienceSelectProps = {
    label: string;
    value: CustomAudienceOption[];
    onChange: (customAudiences: CustomAudienceOption[]) => void;
    options: CustomAudienceOption[];
    iconRight?: () => React.ReactNode;
    loading: boolean;
    errorLoadingCustomAudiences: boolean;
};

const FacebookTargetingCustomAudienceSelect = ({
    value,
    onChange,
    label,
    iconRight,
    options,
    loading,
    errorLoadingCustomAudiences,
}: FacebookTargetingCustomAudienceSelectProps) => {
    const filterModes = useMemo<FilterMode[]>(
        () => [
            { name: 'all', label: 'All', filter: () => true },
            {
                name: 'lookalike',
                label: 'Lookalike Audience',
                filter: (c: CustomAudienceOption) => LOOKALIKE_SUBTYPES.includes(c.subtype),
            },
            {
                name: 'custom',
                label: 'Custom Audience',
                filter: (c: CustomAudienceOption) => !LOOKALIKE_SUBTYPES.includes(c.subtype),
            },
        ],
        []
    );

    const [filterMode, setFilterMode] = useState<FilterMode>(filterModes[0]);
    const customAudiencesOptions = useMemo<CustomAudienceOption[]>(() => options.filter(filterMode.filter), [
        filterMode.filter,
        options,
    ]);

    return (
        <>
            <span className={styles.fieldLabel}>{label}</span>

            {value.length > 0 && (
                <div className={styles.customAudienceList}>
                    {value.map((a) => (
                        <div key={a.value} className={styles.customAudienceListItem}>
                            <CustomAudienceRow className={styles.customAudienceListItemValue} data={a} />
                            <CloseIcon
                                className={styles.removeCustomAudienceIcon}
                                width={11}
                                height={12}
                                onClick={() => onChange(value.filter((v) => v.value !== a.value))}
                            />
                        </div>
                    ))}
                </div>
            )}

            <div className={styles.selectContainer}>
                <Select
                    isMulti
                    menuPortalTarget={document.body}
                    className={styles.select}
                    value={null}
                    onChange={(v) => onChange(v ? [...value, ...v] : [])}
                    options={customAudiencesOptions}
                    isLoading={loading}
                    filterMode={filterMode}
                    setFilterMode={setFilterMode}
                    filterModes={filterModes}
                    styles={selectStylesConfig}
                    placeholder="Search existing audiences"
                    noOptionsMessage={() =>
                        errorLoadingCustomAudiences ? 'Sorry, we are experiencing technical issues' : 'No Options'
                    }
                    components={{
                        MenuList,
                        Option,
                        IndicatorSeparator: () => null,
                    }}
                    closeMenuOnSelect={false}
                />

                {typeof iconRight === 'function' && iconRight()}
            </div>
        </>
    );
};

export default FacebookTargetingCustomAudienceSelect;
