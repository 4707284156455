import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { ClientMonitoredTiktokAudioRelationship } from './clientMonitoredTiktokAudioRelationship.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    clientMonitoredTiktokAudioRelationship: Model as ModelDefinition<ClientMonitoredTiktokAudioRelationship>,
};

export const serializers = {
    clientMonitoredTiktokAudioRelationship: makeSerializer<ClientMonitoredTiktokAudioRelationship>([]),
};

export const factories = {
    clientMonitoredTiktokAudioRelationship: createFactory<ClientMonitoredTiktokAudioRelationship>({
        monitored_audio_id(index: number) {
            return index;
        },
        client_id(index: number) {
            return index;
        },
        custom_name(index: number) {
            return `custom name ${index}`;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/advertising/viewsets/client-monitored-tiktok-audio-relationship/';
    server.get(rootUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('clientMonitoredTiktokAudioRelationship'), {
            url: rootUrl,
            serialize: (resource) => this.serialize(resource, 'clientMonitoredTiktokAudioRelationship'),
            queryParams: request.queryParams,
        });
    });

    server.post(rootUrl, function (this: RouteHandlerContext, schema, request) {
        return schema.create('clientMonitoredTiktokAudioRelationship', JSON.parse(request.requestBody));
    });

    server.patch(`${rootUrl}/:id/`, function (this: RouteHandlerContext, schema, request) {
        const relationship = schema.find('clientMonitoredTiktokAudioRelationship', request.params.id);
        if (!relationship) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        relationship.update(JSON.parse(request.requestBody));
        return relationship;
    });
}
