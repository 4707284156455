import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { creatorbase } from '@round/api';
import { createReducer } from 'helpers';

export type TiktokIndicators = creatorbase.AggregatedAudioStats;
export type State = DataState<TiktokIndicators>;
export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export type Actions =
    | ReducerAction<'loadTiktokIndicators'>
    | ReducerActionWithPayload<'tiktokIndicatorsInitialized', TiktokIndicators>
    | ReducerActionWithPayload<'tiktokIndicatorsErrorLoading', string>;

export const reducer = createReducer<State, Actions>({
    loadTiktokIndicators: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    tiktokIndicatorsErrorLoading: (state, { payload }) => ({
        ...state,
        status: 'error',
        error: payload,
    }),
    tiktokIndicatorsInitialized: (_, { payload }) => ({
        data: payload,
        error: null,
        status: 'success',
    }),
});
