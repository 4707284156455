import React from 'react';
import styles from './ModalBody.module.css';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { FCWithChildren } from '../../../utility/utility.types';

type Props = {
    loading?: boolean;
};

const ModalBody: FCWithChildren<Props> = ({ children, loading }) => {
    return <div className={styles.modalBody}>{loading ? <LoadingSpinner /> : children}</div>;
};

export default ModalBody;
