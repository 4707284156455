import { Context } from '@fluentui/react-context-selector';
import { postTiktokTimeSeries, TiktokTimeSeriesRequestParams, TiktokTimeSeriesResponse } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { BaseDataHookContextValue } from 'utility/dataHook';
import {
    makeErrorStateSlice,
    makeIdleStateSlice,
    makeLoadingStateSlice,
    makeSuccessStateSlice,
} from 'utility/dataState';

export type Data = {
    [id: number]: DataState<TiktokTimeSeriesResponse[number]>;
};

export type State = {
    isInitialized: boolean;
    data: Data;
};

export const initialState: State = {
    isInitialized: false,
    data: {},
};

export type Actions =
    | ReducerActionWithPayload<'tiktokTimeSeriesDataLoading', { ids: number[] }>
    | ReducerActionWithPayload<'tiktokTimeSeriesDataSuccess', { ids: number[]; data: TiktokTimeSeriesResponse }>
    | ReducerActionWithPayload<'tiktokTimeSeriesDataError', { ids: number[]; error: string }>
    | ReducerActionWithPayload<'tiktokTimeSeriesDataIdle', { ids: number[] }>
    | ReducerAction<'tiktokTimeSeriesDataInitialized'>;

export const reducer = createReducer<State, Actions>({
    tiktokTimeSeriesDataLoading: (state, { payload: { ids } }) => {
        const incomingAsLoading = makeLoadingStateSlice<number, TiktokTimeSeriesResponse[number]>(ids);

        return { ...state, data: { ...state.data, ...incomingAsLoading } };
    },
    tiktokTimeSeriesDataSuccess: (state, { payload: { ids, data } }) => {
        const incomingAsSuccess = makeSuccessStateSlice<number, TiktokTimeSeriesResponse[number]>(
            ids,
            (id) => data[id]
        );

        return { ...state, data: { ...state.data, ...incomingAsSuccess } };
    },
    tiktokTimeSeriesDataError: (state, { payload: { ids, error } }) => {
        const incomingAsError = makeErrorStateSlice<number, TiktokTimeSeriesResponse[number]>(
            ids,
            `Couldn't load time series data`
        );

        return { ...state, data: { ...state.data, ...incomingAsError } };
    },
    tiktokTimeSeriesDataIdle: (state, { payload: { ids } }) => {
        const incomingAsIdle = makeIdleStateSlice<number, TiktokTimeSeriesResponse[number]>(ids);

        return { ...state, data: { ...state.data, ...incomingAsIdle } };
    },
    tiktokTimeSeriesDataInitialized: (state) => ({ ...state, isInitialized: true }),
});

export const makeTiktokTimeSeriesDataHook = <V extends BaseDataHookContextValue<Actions>>(
    context: Context<V>,
    selector: ContextSelector<V, State>
) => {
    return function () {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (params: TiktokTimeSeriesRequestParams, requestInit?: RequestInit) => {
                if (!dispatch) {
                    return;
                }

                try {
                    dispatch({ type: 'tiktokTimeSeriesDataLoading', payload: { ids: params.ids } });

                    const response = await postTiktokTimeSeries(params, requestInit);

                    if (response.status === 200) {
                        dispatch({
                            type: 'tiktokTimeSeriesDataSuccess',
                            payload: { ids: params.ids, data: response.data },
                        });
                        return;
                    }

                    dispatch({
                        type: 'tiktokTimeSeriesDataError',
                        payload: { ids: params.ids, error: `Couldn't fetch time series data` },
                    });
                } catch (error) {
                    if (error instanceof Error && error.name === 'AbortError') {
                        dispatch({ type: 'tiktokTimeSeriesDataIdle', payload: { ids: params.ids } });
                        return;
                    }

                    dispatch({
                        type: 'tiktokTimeSeriesDataError',
                        payload: { ids: params.ids, error: `Couldn't fetch time series data` },
                    });
                }
            },
            [dispatch]
        );

        return {
            data: state,
            fetchData,
        };
    };
};
