import React, { FunctionComponent, useState } from 'react';
import { fetchWithToken, showNotification } from '../../../../helpers';
import FileUpload from '../../../../ui/FileUpload/FileUpload';
import Card from '../../../../ui/Card/Card';
import styles from './AdvertisingResultsUpload.module.css';
import Button from '../../../../ui/Buttons/Button/Button';
import { ReactComponent as DocumentTextIcon } from '../../../../assets/DocumentText.svg';

type DataUploadResult = {
    updated: number;
    created: number;
    problem_rows: number[];
    error: string;
};

type FileDataUploadResult = { file: File; result: DataUploadResult };

const AdvertisingResultsUpload: FunctionComponent = () => {
    const [fileList, setFileList] = useState<File[] | null>(null);
    const [uploadResults, setUploadResults] = useState<FileDataUploadResult[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const uploadFile = async (file: File): Promise<FileDataUploadResult> => {
        const formData = new FormData();
        formData.append('file', file);
        const response = await fetchWithToken('/api/advertising/advertising_data/', {
            method: 'PUT',
            body: formData,
        });

        return {
            file,
            result: await response.json(),
        };
    };

    const onFileUpload = async () => {
        if (!fileList) {
            return;
        }

        try {
            setIsSubmitting(true);
            const results = await Promise.allSettled(fileList.map((file) => uploadFile(file)));
            setUploadResults(
                results.reduce<FileDataUploadResult[]>((acc, result) => {
                    if (result.status === 'fulfilled') {
                        return acc.concat(result.value);
                    }

                    return acc;
                }, [])
            );
            setFileList([]);
        } catch {
            showNotification('Could not upload data', 'error');
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <div>
            <Card className={styles.container}>
                <div className={styles.header}>
                    <h1 className={styles.title}>Data upload</h1>
                    <div className={styles.buttons}>
                        <Button
                            type="filled"
                            color="black"
                            disabled={!fileList?.length}
                            loading={isSubmitting}
                            onClick={onFileUpload}
                        >
                            Upload
                        </Button>
                    </div>
                </div>

                <FileUpload
                    onUpload={(list) => {
                        setUploadResults([]);
                        setFileList(fileList?.concat(list) ?? list);
                    }}
                    accept={['.xls', '.xlsx', '.csv']}
                />

                <div className={styles.files}>
                    {fileList?.map((file) => (
                        <div className={styles.file}>
                            <button
                                className={styles.delete}
                                onClick={() =>
                                    setFileList((fileList) => fileList?.filter((item) => item !== file) ?? null)
                                }
                            >
                                x
                            </button>
                            <DocumentTextIcon className={styles.fileIcon} />
                            <span className={styles.fileName}>{file.name}</span>
                        </div>
                    ))}
                </div>

                <div className={styles.results}>
                    {uploadResults.map(({ result, file }) => (
                        <div className={styles.result}>
                            <span className={styles.fileName}>{file.name}</span>
                            <div className={styles.resultContent}>
                                {result.created !== undefined && (
                                    <div className={styles.resultText}>Items created: {result.created}</div>
                                )}
                                {result.updated !== undefined && (
                                    <div className={styles.resultText}>Items updated: {result.updated}</div>
                                )}
                                {result.problem_rows && result.problem_rows.length > 0 && (
                                    <div className={styles.resultText}>
                                        Rows we had problems with: {result.problem_rows.toString()}
                                    </div>
                                )}
                                {result.error && <div className={styles.resultText}>Error: {result.error}</div>}
                            </div>
                        </div>
                    ))}
                </div>
            </Card>
        </div>
    );
};

export default AdvertisingResultsUpload;
