import React from 'react';
import { Currency, InstagramInfluencerPlanStats } from '@round/api';
import styles from './HeadlineCalculations.module.css';
import { ReactComponent as TotalAccountsIcon } from '../../assets/TotalAccounts.svg';
import { ReactComponent as TotalFollowersIcon } from '../../assets/TotalFollowers.svg';
import { ReactComponent as TotalViewsIcon } from '../../assets/TotalViews.svg';
import { ReactComponent as TotalLikesIcon } from '../../assets/TotalLikes.svg';
import { ReactComponent as TotalSpendIcon } from '../../assets/TotalSpend.svg';
import { ReactComponent as CPMIcon } from '../../assets/CPM.svg';
import MetricCardWithChart from '../../../../../ui/DataDisplay/Statistics/MetricCardWithChart/MetricCardWithChart';
import { asMoney, roundTo2Dp } from '../../../../../helpers';
import { FCWithChildren } from '../../../../../utility/utility.types';

interface IHeadlineInstagramCalculationsProps {
    currency?: Currency;
    loading?: boolean;
    planStats: InstagramInfluencerPlanStats | undefined;
}

export const InstagramHeadlineCalculations: FCWithChildren<IHeadlineInstagramCalculationsProps> = ({
    currency,
    loading,
    planStats,
}: IHeadlineInstagramCalculationsProps) => {
    const engagementRateText =
        typeof planStats?.engagement_rate === 'number' && isFinite(planStats.engagement_rate)
            ? `${roundTo2Dp(planStats.engagement_rate * 100)}%`
            : '-';

    return (
        <div className={styles.headlineCalculations}>
            <MetricCardWithChart
                loading={loading}
                baseColor="#0090FF"
                label="Total accounts"
                value={planStats?.post_count}
                Icon={() => <TotalAccountsIcon />}
            />

            <MetricCardWithChart
                loading={loading}
                baseColor="#FFA114"
                label="Total followers"
                value={planStats?.total_followers}
                Icon={() => <TotalFollowersIcon />}
            />

            <MetricCardWithChart
                loading={loading}
                baseColor="#F7685B"
                value={planStats?.total_views}
                label="Total views"
                Icon={() => <TotalViewsIcon />}
            />

            <MetricCardWithChart
                loading={loading}
                baseColor="#48D98A"
                value={engagementRateText}
                label="Engagement rate"
                Icon={() => <TotalLikesIcon />}
            />

            <MetricCardWithChart
                loading={loading}
                baseColor="#F7685B"
                value={asMoney(planStats?.total_spend, currency)}
                label="Total spend"
                Icon={() => <TotalSpendIcon />}
            />

            <MetricCardWithChart
                loading={loading}
                baseColor="#f5d400"
                value={asMoney(planStats?.total_cpm, currency)}
                label="Live CPM"
                Icon={() => <CPMIcon />}
            />
        </div>
    );
};
