import { TiktokInfluencerPost, getTiktokInfluencerPosts } from '@round/api';
import { useCallback, useState } from 'react';

export type Params = Parameters<typeof getTiktokInfluencerPosts>[0];

export default function useTiktokInfluencerPosts({
    page,
    page_size,
    paypal_payment_status,
    payment_request_status,
    plan_id,
    user_id,
}: Params = {}) {
    const [posts, setPosts] = useState<TiktokInfluencerPost[]>([]);
    const [isInitialized, setIsInitialized] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [count, setCount] = useState(0);

    const fetchData = useCallback(async (params: Params, requestInit?: RequestInit) => {
        setHasError(false);
        try {
            const response = await getTiktokInfluencerPosts(params, requestInit);
            setPosts((prev) => prev.concat(response.data.results));
            setCount(response.data.count);
            setIsInitialized(true);
            return response;
        } catch (e) {
            setHasError(true);
            throw e;
        }
    }, []);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            setHasError(false);
            try {
                const response = await getTiktokInfluencerPosts(
                    { page, page_size, paypal_payment_status, payment_request_status, plan_id, user_id },
                    requestInit
                );

                setPosts(response.data.results);
                setCount(response.data.count);
                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setHasError(true);
            }
        },
        [page, page_size, paypal_payment_status, payment_request_status, plan_id, user_id]
    );

    const removePost = useCallback(
        (postId: number) => {
            setPosts((prev) => prev.filter((p) => p.id !== postId));
            setCount((prev) => prev - 1);
        },
        [setPosts, setCount]
    );

    const setPost = useCallback(
        (post: TiktokInfluencerPost) => {
            setPosts((prev) => prev.map((p) => (p.id === post.id ? post : p)));
        },
        [setPosts]
    );

    const reset = useCallback(() => {
        setPosts([]);
        setIsInitialized(false);
        setHasError(false);
    }, []);

    return {
        fetchData,
        posts,
        count,
        isInitialized,
        hasError,
        isLoading: !isInitialized && !hasError,
        init,
        reset,
        removePost,
        setPost,
    };
}
