import { NavBar } from 'SharedComponents/NavBar/NavBar';
import CampaignDetails from 'Modules/Advertising/Microwave/CampaignDetails/CampaignDetails';
import styles from './CampaignDetailsPage.module.css';
import { useParams } from 'react-router-dom';
import { CampaignsProvider } from 'Modules/Advertising/Microwave/CampaignDetails/contexts/CampaignsContext';
import { InstagramCampaignStatsProvider } from 'Modules/Advertising/Microwave/CampaignDetails/contexts/InstagramCampaignStatsContext';

const CampaignDetailsPage = () => {
    const { releaseId } = useParams<{ releaseId: string }>();
    return (
        <>
            <NavBar />
            <div className={styles.content}>
                <CampaignsProvider>
                    <InstagramCampaignStatsProvider>
                        <CampaignDetails releaseId={Number(releaseId)} />
                    </InstagramCampaignStatsProvider>
                </CampaignsProvider>
            </div>
        </>
    );
};

export default CampaignDetailsPage;
