import React, { PropsWithChildren } from 'react';
export { default as TabNav } from './components/TabNav/TabNav';
export { default as TabNavItem } from './components/TabNavItem/TabNavItem';
export { default as TabPanels } from './components/TabPanels/TabPanels';
export { default as TabPanel } from './components/TabPanel/TabPanel';

export type ContextState<T = any> = {
    activeTab: T;
    onChange: (tab: T) => void;
};

export const TabsContext = React.createContext<ContextState | null>(null);

type TabsProps<T> = PropsWithChildren<ContextState<T>>;
function Tabs<T>({ activeTab, onChange, children }: TabsProps<T>) {
    return <TabsContext.Provider value={{ activeTab, onChange }}>{children}</TabsContext.Provider>;
}

export default Tabs;
