import React from 'react';
import { createPortal } from 'react-dom';
import styles from './Modal.module.css';
import cn from 'classnames';
import LoadingSpinner from '../../Indicators/Spinner/Spinner';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import { FCWithChildren } from '../../../utility/utility.types';

export type ModalProps = {
    isOpen: boolean;
    closeOnOverlayClick?: boolean;
    onClose?: () => void;
    className?: string;
    wrapperClassName?: string;
    role?: string;
    ariaLabel?: string;
};

const Modal: FCWithChildren<ModalProps> = ({
    isOpen,
    closeOnOverlayClick,
    onClose,
    children,
    className,
    wrapperClassName,
    role,
    ariaLabel,
}) => {
    const onOverlayClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if (closeOnOverlayClick && typeof onClose === 'function') {
            onClose();
        }
    };

    if (!isOpen) {
        return null;
    }

    return createPortal(
        <div className={wrapperClassName} role={role} aria-label={ariaLabel}>
            <div className={styles.overlay} onClick={onOverlayClick} />
            <div className={cn(styles.modal, className)}>
                <CloseIcon className={styles.closeIcon} onClick={onClose} />
                {children}
            </div>
        </div>,
        document.body
    );
};

export const ModalTitle: FCWithChildren<{ className?: string }> = ({ children, className }) => (
    <p className={cn(styles.title, className)}>{children}</p>
);

export const ModalFooter: FCWithChildren<{ align?: 'right' | 'center' | 'left'; className?: string }> = ({
    children,
    align = 'right',
    className,
}) => (
    <div
        className={cn(styles.footer, className, {
            [styles.right]: align === 'right',
            [styles.center]: align === 'center',
            [styles.left]: align === 'left',
        })}
    >
        {children}
    </div>
);

export const ModalContent: FCWithChildren<{ className?: string; loading?: boolean }> = ({
    children,
    className,
    loading,
}) => <div className={cn(styles.content, className)}>{loading ? <LoadingSpinner /> : children}</div>;

export default Modal;
