import { Button, FormField, Label, Select } from '@round/ui-kit';
import styles from './MicrosPaymentRequestForm.module.css';
import { Formik, Form, Field, FieldProps, ErrorMessage, FormikHelpers } from 'formik';
import cn from 'classnames';
import { isValidTikTokPostUrl, sanitizeTikTokPostUrl } from '../../../../../utility/tiktokPostValidation';
import { useState } from 'react';
import { microwave } from '@round/api';
import * as Yup from 'yup';
import { ErrorIcon } from './ErrorIcon/ErrorIcon';
import { ReactComponent as PayPalIcon } from '../../../../../assets/PayPal.svg';
import { ReactComponent as PaymentCardIcon } from '../../../../../assets/PaymentCard.svg';
import { ReactComponent as InfoIcon } from '../../../../../assets/InfoCircle.svg';
import { ReactComponent as VenmoIcon } from '../../../../../assets/Venmo.svg';
import InputWithIcons from '../../../../../ui/DataEntry/Input/InputWithIcons/InputWithIcons';
import RenderPostUrlIcon from './RenderPostUrlIcon/RenderPostUrlIcon';
import { StylesConfig } from 'react-select';
import { mapApiErrorsToFormikErrors } from '../../../../../utility/utility';
import { showNotification } from '../../../../../helpers';
import { useInfluencerLocationSelect } from '../../../../../Hooks/selectOptions/useInfluencerLocationSelect';
import { HierarchicalOptionLabel } from '@round/ui-kit';

type FormValues = Omit<
    microwave.PostTiktokPostInviteFormApiBody,
    'location' | 'location_id' | 'preferred_payment_method' | 'payment_details'
> & {
    country_location_id: number | null;
    city_location_id: number | null;
    preferred_payment_method: microwave.PaymentMethod | null;
    account_name: string;
    sort_code: string;
    account_number: string;
};

type Props = {
    uuid: string;
    hasLocation: boolean | undefined;
    onSuccess: (data: Partial<microwave.TiktokPostInviteForm>) => void;
};

const selectStyles: StylesConfig = {
    container: (base) => ({
        ...base,
        margin: 0,
    }),
    control: (base) => ({
        ...base,
        border: 'solid 1px #D2D2D2',
        borderRadius: '2rem',
        boxShadow: 'none',
    }),
    placeholder: (base) => ({
        ...base,
        color: '#90a0b7',
        fontSize: '0.8125rem',
    }),
};

const MicrosPaymentRequestForm = ({ uuid, onSuccess, hasLocation }: Props) => {
    const [postUrlValidationMessage, setPostUrlValidationMessage] = useState<string | null>(null);
    const [postUrlInputStatus, setPostUrlInputStatus] = useState<Parameters<typeof RenderPostUrlIcon>[0]['status']>(
        'PENDING'
    );

    const { selectProps: countrySelectProps } = useInfluencerLocationSelect({
        type: 'country',
        ordering: 'country_name',
    });
    const { selectProps: citySelectProps, reset: resetCitySelect } = useInfluencerLocationSelect({
        type: 'city',
        ordering: 'city',
        country_code: countrySelectProps.value?.countryCode,
    });

    const handleValidatePostUrl = async (value: string) => {
        if (postUrlInputStatus !== 'PENDING') {
            return;
        }

        const sanitizedValue = sanitizeTikTokPostUrl(value);
        const localValidationResult = isValidTikTokPostUrl(sanitizedValue);
        if (!localValidationResult.isValid) {
            setPostUrlInputStatus('INVALID');
            setPostUrlValidationMessage(localValidationResult.message);
            return;
        }

        try {
            setPostUrlInputStatus('LOADING');
            const response = await microwave.validateTiktokPostUrl(uuid, value);
            if (response.status === 400) {
                setPostUrlInputStatus('INVALID');
                setPostUrlValidationMessage(response.data.post_url?.[0] || 'Invalid TikTok Post URL');
                return;
            }

            setPostUrlInputStatus('VALID');
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }
            setPostUrlValidationMessage(`Couldn't validate the url. Please try again later.`);
            setPostUrlInputStatus('INVALID');
        }
    };

    const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        try {
            if (!values.preferred_payment_method) {
                helpers.setFieldError('preferred_payment_method', 'At least one payment method is required');
                return;
            }

            const { account_name, sort_code, account_number } = values;
            const paymentDetails = `${account_name} ${sort_code} ${account_number}`;

            helpers.setSubmitting(true);
            const response = await microwave.postTiktokPostInviteFormSubmission(uuid, {
                post_url: values.post_url,
                location_id: values.city_location_id ?? values.country_location_id ?? undefined,
                preferred_payment_method: values.preferred_payment_method,
                payment_details: paymentDetails,
                paypal_email_address: values.paypal_email_address,
                venmo_email_address: values.venmo_email_address,
            });

            if (response.status === 201) {
                onSuccess({ ...response.data, submission_date: new Date().toISOString().split('T')[0] });
                return;
            }

            if (Array.isArray(response.data)) {
                showNotification(response.data.join('. '), 'error');
                return;
            }

            /* currently payment details field is split in the UI, but in the API it's a single field,
            so we're mapping the error to an appropriate field in the UI
            */
            const errors = mapApiErrorsToFormikErrors<FormValues & { payment_details: string }>(response.data);
            const errorsWithPaymentDetails = {
                ...errors,
                account_number: errors.payment_details,
            };

            helpers.setErrors(errorsWithPaymentDetails);
        } catch {
            showNotification(`Couldn't submit the form. Please try again later.`, 'error');
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const validationSchema = Yup.object().shape(
        {
            post_url: Yup.string().required('TikTok Post URL is required'),
            preferred_payment_method: Yup.string().required('At least one payment method is required'),
            paypal_email_address: Yup.string()
                .when('preferred_payment_method', {
                    is: (value: microwave.PaymentMethod) => value === 'PAYPAL',
                    then: Yup.string().required('Payment details are required'),
                    otherwise: Yup.string(),
                })
                .email('Must be a valid email address'),
            account_name: Yup.string().when('preferred_payment_method', {
                is: (value: microwave.PaymentMethod) => value === 'BANK_TRANSFER',
                then: Yup.string().required('Account name is required'),
                otherwise: Yup.string(),
            }),
            sort_code: Yup.string().when('preferred_payment_method', {
                is: (value: microwave.PaymentMethod) => value === 'BANK_TRANSFER',
                then: Yup.string().required('Sort code is required'),
                otherwise: Yup.string(),
            }),
            account_number: Yup.string().when('preferred_payment_method', {
                is: (value: microwave.PaymentMethod) => value === 'BANK_TRANSFER',
                then: Yup.string().required('Account number is required'),
                otherwise: Yup.string(),
            }),
            venmo_email_address: Yup.string()
                .email('Must be a valid email address')
                .when('preferred_payment_method', {
                    is: (value: microwave.PaymentMethod) => value === 'VENMO',
                    then: Yup.string().required('Payment details are required'),
                    otherwise: Yup.string(),
                }),
        },
        [['paypal_email_address', 'payment_details']]
    );

    const initialValues: FormValues = {
        post_url: '',
        country_location_id: null,
        city_location_id: null,
        preferred_payment_method: null,
        paypal_email_address: '',
        account_name: '',
        sort_code: '',
        account_number: '',
        venmo_email_address: '',
    };

    const handleScrollIntoView = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!(e.currentTarget instanceof HTMLButtonElement)) {
            return;
        }
        e.currentTarget.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
        >
            {(props) => {
                const selectedPaymentMethod = props.values.preferred_payment_method;

                const handlePaymentMethodChange = (value: FormValues['preferred_payment_method']) => {
                    if (selectedPaymentMethod === value) {
                        return;
                    }

                    props.setErrors({
                        ...props.errors,
                        preferred_payment_method: '',
                        account_name: '',
                        sort_code: '',
                        account_number: '',
                        paypal_email_address: '',
                        venmo_email_address: '',
                    });
                    props.setValues({
                        ...props.values,
                        paypal_email_address: '',
                        account_name: '',
                        sort_code: '',
                        account_number: '',
                        venmo_email_address: '',
                    });

                    props.setFieldValue('preferred_payment_method', value);
                };

                return (
                    <Form className={styles.form}>
                        <FormField>
                            <Label htmlFor="post_url">Post URL</Label>
                            <Field name="post_url">
                                {(props: FieldProps<FormValues['post_url']>) => (
                                    <InputWithIcons
                                        name="post_url"
                                        className={cn(styles.field, styles.postUrl, {
                                            [styles.invalid]: postUrlInputStatus === 'INVALID',
                                        })}
                                        value={props.field.value}
                                        onChange={(e) => {
                                            setPostUrlValidationMessage(null);
                                            setPostUrlInputStatus('PENDING');
                                            props.form.setFieldValue('post_url', e.target.value);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleValidatePostUrl(props.field.value);
                                            }
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value === '') {
                                                return;
                                            }
                                            handleValidatePostUrl(props.field.value);
                                        }}
                                        placeholder="Enter TikTok Post link"
                                        renderIconRight={() => (
                                            <RenderPostUrlIcon
                                                status={postUrlInputStatus}
                                                onSubmit={() => handleValidatePostUrl(props.field.value)}
                                            />
                                        )}
                                    />
                                )}
                            </Field>
                            {(!!postUrlValidationMessage || !!props.errors.post_url) && (
                                <div className={styles.errorContainer}>
                                    <ErrorIcon />
                                    <span>{postUrlValidationMessage || props.errors.post_url}</span>
                                </div>
                            )}
                        </FormField>

                        {postUrlInputStatus === 'VALID' && (
                            <>
                                <div className={styles.paymentOptionsContainer}>
                                    <Label htmlFor="preferred_payment_method">Please select payment method</Label>
                                    <article className={styles.scrollContainer}>
                                        <div className={styles.optionsContainer}>
                                            <Field name="preferred_payment_method">
                                                {(fieldProps: FieldProps<FormValues['preferred_payment_method']>) => (
                                                    <>
                                                        <button
                                                            type="button"
                                                            onClick={(e) => {
                                                                handlePaymentMethodChange('PAYPAL');
                                                                handleScrollIntoView(e);
                                                            }}
                                                            className={cn(styles.paymentButton, {
                                                                [styles.selected]: selectedPaymentMethod === 'PAYPAL',
                                                            })}
                                                        >
                                                            <PayPalIcon />
                                                        </button>

                                                        <button
                                                            type="button"
                                                            onClick={(e) => {
                                                                handlePaymentMethodChange('VENMO');
                                                                handleScrollIntoView(e);
                                                            }}
                                                            className={cn(styles.paymentButton, {
                                                                [styles.selected]: selectedPaymentMethod === 'VENMO',
                                                            })}
                                                        >
                                                            <VenmoIcon />
                                                        </button>

                                                        <button
                                                            type="button"
                                                            onClick={(e) => {
                                                                handlePaymentMethodChange('BANK_TRANSFER');
                                                                handleScrollIntoView(e);
                                                            }}
                                                            className={cn(styles.paymentButton, {
                                                                [styles.selected]:
                                                                    selectedPaymentMethod === 'BANK_TRANSFER',
                                                            })}
                                                        >
                                                            <PaymentCardIcon />
                                                            <div>Bank Account</div>
                                                        </button>
                                                    </>
                                                )}
                                            </Field>
                                        </div>
                                    </article>

                                    <ErrorMessage name="preferred_payment_method">
                                        {(msg) => (
                                            <div className={styles.errorContainer}>
                                                <ErrorIcon />
                                                <span>{msg}</span>
                                            </div>
                                        )}
                                    </ErrorMessage>
                                </div>

                                {!!selectedPaymentMethod && !hasLocation && (
                                    <>
                                        <div className={styles.rowContainer}>
                                            <FormField>
                                                <Label htmlFor="country_location_id">Country</Label>
                                                <Field name="country_location_id" className={styles.field}>
                                                    {(props: FieldProps<FormValues['country_location_id']>) => (
                                                        <Select
                                                            name="country_location_id"
                                                            {...countrySelectProps}
                                                            onChange={(option) => {
                                                                props.form.setFieldValue(
                                                                    'country_location_id',
                                                                    option?.value
                                                                );

                                                                countrySelectProps.onChange(option);
                                                                citySelectProps.onChange(null);
                                                                resetCitySelect();
                                                            }}
                                                            styles={selectStyles}
                                                            placeholder="Select country"
                                                            isClearable
                                                            getOptionLabel={(option) => option.country}
                                                            captureMenuScroll={true}
                                                        />
                                                    )}
                                                </Field>
                                            </FormField>

                                            <FormField>
                                                <Label htmlFor="city_location_id">City</Label>
                                                <Field name="city_location_id" className={styles.field}>
                                                    {(props: FieldProps<FormValues['city_location_id']>) => (
                                                        <Select
                                                            name="city_location_id"
                                                            {...citySelectProps}
                                                            onChange={(option) => {
                                                                props.form.setFieldValue(
                                                                    'city_location_id',
                                                                    option?.value
                                                                );
                                                                citySelectProps.onChange(option);
                                                            }}
                                                            styles={selectStyles}
                                                            placeholder="Select city"
                                                            isClearable
                                                            isDisabled={!props.form.values.country_location_id}
                                                            getOptionLabel={(option) => option.city}
                                                            formatOptionLabel={(option, meta) => {
                                                                if (meta.context === 'menu') {
                                                                    return (
                                                                        <HierarchicalOptionLabel
                                                                            labels={[option.city, option.state]}
                                                                        />
                                                                    );
                                                                }
                                                                return option.city;
                                                            }}
                                                            captureMenuScroll={true}
                                                        />
                                                    )}
                                                </Field>
                                            </FormField>
                                        </div>
                                    </>
                                )}

                                {selectedPaymentMethod === 'PAYPAL' && (
                                    <div>
                                        <FormField>
                                            <Label htmlFor="paypal_email_address">PayPal Email Address</Label>
                                            <Field
                                                name="paypal_email_address"
                                                className={styles.field}
                                                placeholder="Your PayPal Email Address"
                                            />
                                            <ErrorMessage name="paypal_email_address">
                                                {(msg) => (
                                                    <div className={styles.errorContainer}>
                                                        <ErrorIcon />
                                                        <span>{msg}</span>
                                                    </div>
                                                )}
                                            </ErrorMessage>
                                        </FormField>
                                    </div>
                                )}

                                {selectedPaymentMethod === 'VENMO' && (
                                    <div>
                                        <FormField>
                                            <Label htmlFor="venmo_email_address">Venmo Email Address</Label>
                                            <Field
                                                name="venmo_email_address"
                                                className={styles.field}
                                                placeholder="Your Venmo Email Address"
                                            />
                                            <ErrorMessage name="venmo_email_address">
                                                {(msg) => (
                                                    <div className={styles.errorContainer}>
                                                        <ErrorIcon />
                                                        <span>{msg}</span>
                                                    </div>
                                                )}
                                            </ErrorMessage>
                                        </FormField>
                                    </div>
                                )}

                                {selectedPaymentMethod === 'BANK_TRANSFER' && (
                                    <>
                                        <div>
                                            <div className={cn(styles.rowContainer, styles.stack)}>
                                                <FormField>
                                                    <Label htmlFor="account_name">Account holder full name</Label>
                                                    <Field
                                                        name="account_name"
                                                        className={styles.field}
                                                        placeholder="Full name"
                                                    />
                                                    <ErrorMessage name="account_name">
                                                        {(msg) => (
                                                            <div className={styles.errorContainer}>
                                                                <ErrorIcon />
                                                                <span>{msg}</span>
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </FormField>

                                                <FormField>
                                                    <Label htmlFor="sort_code">Sort code</Label>
                                                    <Field
                                                        name="sort_code"
                                                        className={styles.field}
                                                        placeholder="Sort code"
                                                    />
                                                    <ErrorMessage name="sort_code">
                                                        {(msg) => (
                                                            <div className={styles.errorContainer}>
                                                                <ErrorIcon />
                                                                <span>{msg}</span>
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </FormField>
                                            </div>
                                        </div>

                                        <div>
                                            <FormField>
                                                <Label htmlFor="account_number">Account number</Label>
                                                <Field
                                                    name="account_number"
                                                    className={styles.field}
                                                    placeholder="Account number"
                                                />
                                                <ErrorMessage name="account_number">
                                                    {(msg) => (
                                                        <div className={styles.errorContainer}>
                                                            <ErrorIcon />
                                                            <span>{msg}</span>
                                                        </div>
                                                    )}
                                                </ErrorMessage>
                                            </FormField>

                                            <div>
                                                <span className={styles.infoContainer}>
                                                    <InfoIcon />
                                                    <span>
                                                        Important: Only UK banks are eligible for bank transfer.
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {!!selectedPaymentMethod && (
                                    <Button
                                        className={styles.submit}
                                        htmlType="submit"
                                        loading={props.isSubmitting}
                                        color="black"
                                        type="filled"
                                    >
                                        Submit Form
                                    </Button>
                                )}
                            </>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default MicrosPaymentRequestForm;
