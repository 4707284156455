import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ProjectDetailsContext } from '../ProjectDetailsContext';
import { useProjectActions, useProjectDetails } from '../../contexts/ProjectContext';
import { useRefreshPlatformStats } from '../../contexts/PlatformStatsContext';
import { useCampaignStatsActions } from '../../contexts/CampaignStatsContext/CampaignsStatsContext';
import { usePostsActions } from '../../contexts/PostsContext';
import { useTiktokVideosActions } from '../../contexts/PostStatsContexts/TiktokVideosContext/TiktokVideosContext';
import { useInstagramPostStatsActions } from '../../contexts/PostStatsContexts/InstagramPostStatsContext/InstagramPostStatsContext';
import { useAudioTimeSeriesDataActions } from '../../contexts/AudioTimeSeriesContext/AudioTimeSeriesContext';
import { useTiktokAudiosActions } from '../../contexts/TiktokAudiosContext';
import { useCallback } from 'react';
import { creatorbase } from '@round/api';
import { DistributiveOmit } from 'utility/utility.types';
import moment from 'moment';
import { getCampaign } from 'Modules/Plans/Campaigns/hooks/dataHooks/useCampaignsDataHook';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';

const today = moment();
const twoWeeksAgo = moment().subtract(14, 'days');

const getCampaignAudioId = (campaign: creatorbase.Campaign): number | null => {
    if (campaign.platform === 'youtube') {
        return null;
    }

    return campaign[`${campaign.platform}_details`]?.audio_id ?? null;
};

export type UseCampaigns = ReturnType<typeof useCampaigns>;

export function useCampaigns() {
    const featureFlags = useFeatureFlags();
    const isCampaignOrderingFlagActive = featureFlags.isEnabled('cb-release-nov-24');

    const campaignsState = useNonNullContextSelector(ProjectDetailsContext, ([values]) => values.campaigns);
    const dispatch = useNonNullContextSelector(ProjectDetailsContext, ([, dispatch]) => dispatch);

    const { data: projectData } = useProjectDetails();

    const { refreshProjectStats } = useProjectActions();
    const refreshPlatformStats = useRefreshPlatformStats();
    const { refreshCampaignStats, removeCampaignStats } = useCampaignStatsActions();
    const { removeCampaignPosts } = usePostsActions();

    const { removeVideos: removeTiktokVideos } = useTiktokVideosActions();
    const { removeStats: removeInstagramPostStats } = useInstagramPostStatsActions();
    const { fetchData: fetchAudioTimeSeriesData } = useAudioTimeSeriesDataActions();
    const { fetchData: fetchTiktokAudios } = useTiktokAudiosActions();

    const getRemovePostStatsHandler = useCallback(
        (platform: 'instagram' | 'tiktok' | 'youtube') => {
            switch (platform) {
                case 'tiktok':
                    return removeTiktokVideos;
                case 'instagram':
                    return removeInstagramPostStats;
                case 'youtube':
                    return () => Promise.resolve();
            }
        },
        [removeInstagramPostStats, removeTiktokVideos]
    );

    // we're not using project data to fetch campaigns and expecting id
    // because we want to fetch campaigns in parallel with project data
    const fetchData = useCallback(
        async (
            params: Pick<Required<creatorbase.GetCampaignsParams>, 'platform' | 'project_id'>,
            requestInit?: RequestInit
        ) => {
            try {
                dispatch({ type: 'loadCampaigns', payload: params.platform });
                const response = await creatorbase.getCampaigns(
                    { ...params, ordering: isCampaignOrderingFlagActive ? 'project-platform-order' : 'team,id' },
                    requestInit
                );
                if (response.status !== 200) {
                    dispatch({
                        type: 'errorLoadingCampaigns',
                        payload: { platform: params.platform, message: response.data.detail },
                    });
                    return response;
                }

                dispatch({
                    type: 'campaignsSuccess',
                    payload: {
                        platform: params.platform,
                        data: response.data,
                    },
                });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'campaignsIdle', payload: params.platform });
                    throw e;
                }

                dispatch({
                    type: 'errorLoadingCampaigns',
                    payload: { platform: params.platform, message: 'Could not get campaigns' },
                });
                throw e;
            }
        },
        [dispatch, isCampaignOrderingFlagActive]
    );

    const createCampaign = useCallback(
        async (data: DistributiveOmit<creatorbase.PostCampaignBody, 'project_id'>) => {
            if (!projectData?.project.id) {
                return;
            }

            const response = await creatorbase.postCampaign({
                project_id: projectData.project.id,
                ...data,
            });

            if (response.status === 201) {
                const audioId = getCampaignAudioId(response.data);

                dispatch({
                    type: 'addCampaign',
                    payload: {
                        data: response.data,
                    },
                });
                refreshPlatformStats();
                refreshCampaignStats(response.data.id).catch(() => {});
                refreshProjectStats().catch(() => {});

                if (audioId && response.data.platform === 'tiktok') {
                    fetchAudioTimeSeriesData({
                        ids: [audioId],
                        start_date: twoWeeksAgo.format('YYYY-MM-DD'),
                        end_date: today.format('YYYY-MM-DD'),
                    });
                    fetchTiktokAudios([audioId]);
                }
            }

            return response;
        },
        [
            projectData?.project.id,
            refreshPlatformStats,
            refreshCampaignStats,
            refreshProjectStats,
            fetchAudioTimeSeriesData,
            fetchTiktokAudios,
            dispatch,
        ]
    );

    const updateCampaign = useCallback(
        async (campaignId: number, data: Partial<creatorbase.PatchCampaignBody>) => {
            const response = await creatorbase.patchCampaign(campaignId, data);

            if (response.status === 200) {
                dispatch({
                    type: 'updateCampaign',
                    payload: {
                        data: response.data,
                    },
                });
                refreshPlatformStats();
                refreshCampaignStats(response.data.id).catch(() => {});
                refreshProjectStats().catch(() => {});
            }

            return response;
        },
        [dispatch, refreshPlatformStats, refreshCampaignStats, refreshProjectStats]
    );

    const deleteCampaign = useCallback(
        async (campaignId: number) => {
            const campaign = getCampaign(campaignsState, campaignId);

            const response = await creatorbase.deleteCampaign(campaignId);
            if (response.status === 204) {
                dispatch({
                    type: 'removeCampaign',
                    payload: {
                        campaignId,
                    },
                });
                refreshPlatformStats();
                removeCampaignStats(campaignId);
                refreshProjectStats().catch(() => {});

                const removedPostIds = removeCampaignPosts(campaignId);

                if (campaign && removedPostIds?.length) {
                    const removePostStats = getRemovePostStatsHandler(campaign.platform);
                    removePostStats(removedPostIds);
                }
            }

            return response;
        },
        [
            dispatch,
            refreshPlatformStats,
            removeCampaignStats,
            refreshProjectStats,
            removeCampaignPosts,
            getRemovePostStatsHandler,
            campaignsState,
        ]
    );

    const refetchCampaign = useCallback(
        async (campaignId: number, requestInit?: RequestInit) => {
            const response = await creatorbase.getCampaign(campaignId, requestInit);

            if (response.status === 200) {
                dispatch({
                    type: 'updateCampaign',
                    payload: {
                        data: response.data,
                    },
                });
            }

            return response;
        },
        [dispatch]
    );

    return {
        data: campaignsState,
        fetchData,
        createCampaign,
        updateCampaign,
        deleteCampaign,
        refetchCampaign,
        getCampaign: (campaignId: number) => getCampaign(campaignsState, campaignId),
        reorderCampaigns: (platform: creatorbase.Platform, order: number[]) => {
            dispatch({
                type: 'reorderCampaigns',
                payload: {
                    platform,
                    order,
                },
            });
        },
    };
}
