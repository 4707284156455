import React, { useMemo } from 'react';
import RefreshButton from '../../../../ui/RefreshButton/RefreshButton';
import { resetTime } from '../../../../utility/utility';

type ExploreRefreshButtonProps = {
    loading: boolean;
    lastUpdated: Date;
    onClick?: () => any;
    className?: string;
};

const ExploreRefreshButton = ({ lastUpdated, onClick, loading, className }: ExploreRefreshButtonProps) => {
    const statsRefreshedToday = useMemo(() => resetTime(new Date()).getTime() === resetTime(lastUpdated).getTime(), [
        lastUpdated,
    ]);

    const statsRefreshDisabled = useMemo(() => Date.now() - lastUpdated.getTime() <= 1000 * 60 * 30, [lastUpdated]);

    return (
        <RefreshButton
            className={className}
            loading={loading}
            disabled={statsRefreshDisabled}
            isRefreshed={statsRefreshedToday}
            lastUpdated={lastUpdated}
            onClick={onClick}
        />
    );
};

export default ExploreRefreshButton;
