import { microwave } from '@round/api';
import { ReactNode } from 'react';
import styles from '../TiktokMicrosStatsBar/TiktokMicrosStatsBar.module.css';
import cn from 'classnames';
import { ReactComponent as ArrowIcon } from 'assets/ArrowIcon.svg';
import { getPostCountDisplayValue } from '../helpers';
import ProgressBar from 'ui/ProgressBar/ProgressBar';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { asMoney, formatNumberToKNotation, roundTo2Dp } from 'helpers';
import { ProtectedByUserGroups } from 'SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import { isNumber } from 'utility/utility';

type Props = {
    campaign: microwave.Campaign | undefined;
    campaignStats: microwave.CampaignStats | undefined;
    isExpanded: boolean;
    onExpandedChange: (isExpanded: boolean) => void;
    children?: ReactNode | undefined;
    onAddPost: () => void;
};

const YoutubeMicrosStatsBar = ({
    campaign,
    campaignStats,
    children,
    isExpanded,
    onExpandedChange,
    onAddPost,
}: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const campaignCurrency = currencies.find((c) => c.id === campaign?.currency_id);

    const postCountDisplayValue = getPostCountDisplayValue(campaignStats?.post_count ?? 0, campaign?.target_post_count);

    const budgetDisplayValue = asMoney(campaign?.budget, campaignCurrency);
    const amountSpentPercent =
        isNumber(campaignStats?.amount_spent) && campaign?.budget
            ? (campaignStats!.amount_spent / campaign.budget) * 100
            : null;
    const amountSpentPercentDisplayValue = isNumber(amountSpentPercent) ? `${amountSpentPercent.toFixed(2)}%` : '-';

    const totalViewsDisplayValue = isNumber(campaignStats?.total_views)
        ? formatNumberToKNotation(campaignStats!.total_views)
        : '-';
    const engagementRateDisplayValue = isNumber(campaignStats?.engagement_rate)
        ? `${roundTo2Dp(campaignStats!.engagement_rate * 100)}%`
        : '-';
    const cpmDisplayValue = isNumber(campaignStats?.cpm) ? asMoney(campaignStats!.cpm, campaignCurrency) : '-';

    return (
        <>
            <section className={styles.container}>
                <button
                    className={cn(styles.expandButton, { [styles.expanded]: isExpanded })}
                    onClick={() => onExpandedChange(!isExpanded)}
                >
                    <ArrowIcon />
                </button>

                <div className={styles.statsContainer}>
                    <div className={cn(styles.statColumn, styles.name)}>
                        <div className={styles.statHeading}>{campaign?.name ?? 'Micros'}</div>
                        <div className={styles.statValue}>{postCountDisplayValue}</div>
                    </div>

                    <div className={cn(styles.statColumn, styles.costStats)}>
                        <div className={styles.budgetRow}>
                            <div className={cn(styles.statHeading, styles.budget)}>{budgetDisplayValue}</div>
                            <div className={styles.statValue}>{amountSpentPercentDisplayValue}</div>
                        </div>

                        <ProgressBar
                            trackStyles={{
                                width: '100%',
                                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                                height: '0.33rem',
                                marginTop: '0.33rem',
                            }}
                            barStyles={{
                                backgroundColor: 'white',
                            }}
                            progress={amountSpentPercent ?? 0}
                        />
                    </div>

                    <div className={styles.statColumn}>
                        <div className={styles.statHeading}>Total Views</div>
                        <div className={styles.statValue}>{totalViewsDisplayValue}</div>
                    </div>

                    <div className={styles.statColumn}>
                        <div className={styles.statHeading}>CPM</div>
                        <div className={styles.statValue}>{cpmDisplayValue}</div>
                    </div>

                    <div className={cn(styles.statColumn, styles.engagementRate)}>
                        <div className={styles.statHeading}>Engagement Rate</div>
                        <div className={styles.statValue}>{engagementRateDisplayValue}</div>
                    </div>
                </div>

                <div className={styles.actionsContainer}>
                    <ProtectedByUserGroups groups={['microwave_admin']}>
                        <button className={styles.actionsContainerButton} onClick={onAddPost}>
                            Add Post
                        </button>
                    </ProtectedByUserGroups>
                </div>
            </section>

            {isExpanded && children}
        </>
    );
};

export default YoutubeMicrosStatsBar;
