import React, { useMemo } from 'react';
import styles from './HeadlineStatsCard.module.css';
import ReactTooltip from 'react-tooltip';
import { formatTo2dp, numberWithCommas } from '../../../../../../helpers';
import Skeleton from '../../../../../../ui/Skeleton/Skeleton';

type HeadlineStatsCardProps = {
    title: string;
    value: string;
    rawValue: string | number | null | undefined;
    subtitle?: string;
    loading?: boolean;
};

const HeadlineStatsCard = ({ title, value, subtitle, rawValue, loading }: HeadlineStatsCardProps) => {
    const tooltipId = useMemo(() => `${title}-${subtitle}-${rawValue}`, [rawValue, subtitle, title]);

    return (
        <div className={styles.container} onMouseLeave={() => ReactTooltip.hide()}>
            <p className={styles.title}>{loading ? <Skeleton /> : title}</p>
            <p className={styles.value} data-tip="React-tooltip" data-for={tooltipId}>
                {loading ? <Skeleton /> : value ?? '-'}
                {!loading && <span className={styles.subtitle}>{subtitle}</span>}
            </p>

            <ReactTooltip id={tooltipId} place="right" className={styles.tooltip}>
                <span>{rawValue ? numberWithCommas(formatTo2dp(rawValue)) : '-'}</span>
            </ReactTooltip>
        </div>
    );
};

export default HeadlineStatsCard;
