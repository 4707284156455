import { encodeUrlSearchParams, fetchAll } from '@round/api';
import { InstagramInfluencerPost } from '../Advertising/InfluencerPlan/types/Instagram.types';

type GetInstagramInfluencerPostsParams = {
    influencerId: number;
};
export const getAllInstagramInfluencerPosts = async (
    params: GetInstagramInfluencerPostsParams,
    requestInit?: RequestInit
): Promise<InstagramInfluencerPost[]> => {
    const queryParams = encodeUrlSearchParams({
        influencer_id: params.influencerId,
    });

    return await fetchAll(`/api/advertising/viewsets/instagram-influencer-post/${queryParams}`, requestInit);
};
