import React, { FunctionComponent } from 'react';
import { IndicatorContainerProps } from 'react-select';
import { FacebookTargetingAudienceSelectionOption } from '../../../../../../../../App.types';

import styles from './FacebookDetailedTargeting.module.css';

const FacebookTargetingSelectionFormIndicatorsContainer: FunctionComponent<
    IndicatorContainerProps<FacebookTargetingAudienceSelectionOption, false>
> = (props) => {
    return (
        <div>
            {!props.selectProps.hideSuggestions && (
                <button className={styles.infieldButton} onClick={props.selectProps.onSuggestionsClicked}>
                    Suggestions
                </button>
            )}
        </div>
    );
};

export default FacebookTargetingSelectionFormIndicatorsContainer;
