import { creatorbase } from '@round/api';
import { Tab, TabList, TabProps, TabsContext } from '@round/ui-kit';
import PlatformIcon from '../PlatformIcon';
import cn from 'classnames';
import styles from './PlatformTabs.module.css';
import useNonNullContext from 'Hooks/useNonNullContext';
import { ReactNode } from 'react';

type Props = { children?: ReactNode; className?: string };
const PlatformTabs = ({ children, className }: Props) => {
    return <TabList className={cn(styles.tabsNav, className)}>{children}</TabList>;
};

const labels: { [key in creatorbase.Platform]?: string } = {
    instagram: 'Instagram',
    tiktok: 'TikTok',
    youtube: 'YouTube',
};

type PlatformTabProps = Pick<TabProps, 'name' | 'className' | 'activeClassName'> & {
    platform: creatorbase.Platform;
};

const PlatformTab = ({ platform, className, activeClassName, name }: PlatformTabProps) => {
    const { activeTab } = useNonNullContext(TabsContext);
    const isActive = activeTab === name;

    return (
        <Tab name={name} className={cn(styles.tab, className)} activeClassName={cn(styles.active, activeClassName)}>
            <PlatformIcon
                colored={isActive}
                platform={platform}
                className={cn(styles.platformIcon, {
                    [styles.inactiveIcon]: !isActive,
                })}
            />
            <span>{labels[platform]}</span>
        </Tab>
    );
};

PlatformTabs.Tab = PlatformTab;

export default PlatformTabs;
