import React, { useCallback } from 'react';
import { OptionProps } from 'react-select';
import { GenericDropdownOption } from '../../../../../../../../App.types';
import styles from './TargetingTemplateSelectOption.module.css';
import EditIcon from '../../../../../../../../SharedComponents/svg/Icons/EditIcon';
import cn from 'classnames';

type TargetingTemplateSelectOptionProps = OptionProps<GenericDropdownOption<number>, false> & {
    onEdit: (template: GenericDropdownOption<number>) => void | Promise<void>;
};

const TargetingTemplateSelectOption = (props: TargetingTemplateSelectOptionProps) => {
    const onEditClicked = useCallback(
        (e: React.MouseEvent<SVGElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();
            props.onEdit(props.data);
        },
        [props]
    );

    return (
        <div
            style={props.getStyles('option', props)}
            className={props.cx(
                {
                    option: true,
                    'option--is-disabled': props.isDisabled,
                    'option--is-focused': props.isFocused,
                    'option--is-selected': props.isSelected,
                },
                props.className
            )}
            ref={props.innerRef}
            {...props.innerProps}
        >
            <div className={styles.row}>
                <span className={styles.label}>{props.label}</span>

                <EditIcon
                    className={cn(styles.button, styles.editButton)}
                    width={14}
                    height={14}
                    onClick={onEditClicked}
                />
            </div>
        </div>
    );
};

export default TargetingTemplateSelectOption;
