import { AppServer, RouteHandlerContext } from '../../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { UserCreator } from './micros.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';
import { SearchMicrosParams } from './micros.api';

export const models = {
    userCreator: Model as ModelDefinition<UserCreator>,
};

export const serializers = {
    userCreator: makeSerializer<UserCreator>([]),
};

export const factories = {
    userCreator: createFactory<UserCreator>({
        email(index: number) {
            return `email${index}@example.com`;
        },
        bio_link(index: number) {
            return `bio link ${index}`;
        },
        follower_count(index: number) {
            return index;
        },
        location: 'UK',
        nickname(index: number) {
            return `nickname ${index}`;
        },
        private_account: false,
        verified: false,
        signature(index: number) {
            return `signature ${index}`;
        },
        tiktok_id(index: number) {
            return `tiktok id ${index}`;
        },
        unique_id(index: number) {
            return `unique id ${index}`;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const searchUrl = '/api/advertising/creator-plans/:planId/tiktok-audios/:audioId/micro-search/';
    server.get(searchUrl, function (this: RouteHandlerContext, schema, request) {
        const {
            location = '',
            max_follower_count,
            min_follower_count,
            search = '',
        } = (request.queryParams as unknown) as SearchMicrosParams;

        const creators = schema
            .all('userCreator')
            .filter((creator) => !search || creator.nickname.toLowerCase().includes(search.toLowerCase()))
            .filter((creator) => !location || creator.location === location)
            .filter((creator) => !min_follower_count || creator.follower_count >= Number(min_follower_count))
            .filter((creator) => !max_follower_count || creator.follower_count <= Number(max_follower_count));

        return buildPaginatedResponse(creators, {
            url: searchUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'userCreator'),
        });
    });
}
