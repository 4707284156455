import { microwave } from '@round/api';
import { BaseFilterObject } from 'App.types';
import { platformOptions } from 'Modules/Advertising/Microwave/constants';
import { getPlatformIcon } from 'Modules/Advertising/Microwave/helpers';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import styles from './PlatformFilter.module.css';

type Props = {
    value: microwave.Platform | undefined;
    onChange: (platform: microwave.Platform | undefined) => void;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onClear: () => void;
    onRemove: () => void;
} & Pick<BaseFilterObject, 'name' | 'icon'>;

const PlatformFilter = ({ value, onChange, isOpen, onOpen, onClear, onClose, onRemove, icon, name }: Props) => {
    return (
        <SelectFilter
            icon={icon}
            name={name}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            isSearchable={false}
            options={platformOptions}
            value={platformOptions.find((o) => o.value === value)}
            onChange={(value) => onChange(value?.value)}
            onClear={onClear}
            onRemove={onRemove}
            formatOptionLabel={(option, props) => {
                if (props.context === 'value') {
                    return option.label;
                }

                const Icon = getPlatformIcon(option.value);
                return (
                    <div className={styles.option}>
                        <Icon className={styles.icon} />
                        <span>{option.label}</span>
                    </div>
                );
            }}
        />
    );
};

export default PlatformFilter;
