import React from 'react';
import copy from 'copy-to-clipboard';
import { renderToStaticMarkup } from 'react-dom/server';

export default function copyComponentRenderToClipboard<Props extends {}>(
    props: Props,
    Component: React.FunctionComponent<Props>
) {
    const rendered = renderToStaticMarkup(<Component {...props} />);
    return copy(rendered, { format: 'text/html' });
}
