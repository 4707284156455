import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    OrderingValues,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { TiktokMicroPostInvite } from './tiktokPostInvites.types';

export type GetTiktokPostInvitesSortingValues =
    | 'post_create_time'
    | 'number_of_chase_emails_sent'
    | 'last_contacted_at';
export function isGetTiktokPostInvitesSortingValues(ordering: string): ordering is GetTiktokPostInvitesSortingValues {
    const validValues = ['post_create_time', 'last_contacted_at', 'number_of_chase_emails_sent'];
    return !!validValues.find((validValue) => validValue === ordering || `-${validValue}` === ordering);
}

export type GetTiktokPostInvitesParams = { creator_plan: number } & Partial<
    PaginatedRequest & {
        is_posted: boolean;
        ordering: OrderingValues<GetTiktokPostInvitesSortingValues>;
        number_of_chase_emails_sent: number;
    }
>;

export async function getTiktokPostInvites(
    params: GetTiktokPostInvitesParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<TiktokMicroPostInvite>, 200>> {
    const planId = params.creator_plan;

    const response = await fetchWithToken(
        `/api/microwave/tiktok-post-invites/${encodeUrlSearchParams({
            ...params,
            'creator-plan': planId,
        })}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not get contacted creators`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PatchTiktokMicroPostInviteData = Partial<Pick<TiktokMicroPostInvite, 'audio_id' | 'user_id' | 'deadline'>>;
type PatchTiktokMicroPostInviteResponse =
    | ApiResponse<TiktokMicroPostInvite, 200>
    | NotFoundResponse
    | ApiResponse<ApiResponseError<PatchTiktokMicroPostInviteData>, 400>;

export async function patchTiktokMicroPostInvite(
    id: string,
    data: PatchTiktokMicroPostInviteData
): Promise<PatchTiktokMicroPostInviteResponse> {
    const response = await fetchWithToken(`/api/microwave/tiktok-post-invites/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not patch tiktok post invite ${id}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
