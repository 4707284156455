import { ColumnDef } from '@tanstack/react-table';
import WrapperTable from '../../../ui/WrapperTable/WrapperTable';
import { ScrollView } from '@round/ui-kit';

type Person = {
    firstName: string;
    lastName: string;
    age: number;
    visits: number;
    status: string;
    progress: number;
    occupation: string;
    email: string;
    companyName: string;
    hobby: string;
};

export const defaultData: Person[] = [
    {
        firstName: 'tanner',
        lastName: 'linsley',
        age: 24,
        visits: 100,
        status: 'In Relationship',
        progress: 50,
        companyName: 'Company',
        email: 'example@gmail.com',
        occupation: 'Accountant',
        hobby: 'hiking',
    },
    {
        firstName: 'tandy',
        lastName: 'miller',
        age: 40,
        visits: 40,
        status: 'Single',
        progress: 80,
        companyName: 'Company',
        email: 'example@gmail.com',
        occupation: 'Accountant',
        hobby: 'hiking',
    },
    {
        firstName: 'joe',
        lastName: 'dirte',
        age: 45,
        visits: 20,
        status: 'Complicated',
        progress: 10,
        companyName: 'Company',
        email: 'example@gmail.com',
        occupation: 'Accountant',
        hobby: 'hiking',
    },
];

const TablePlayground = () => {
    const columns: ColumnDef<Person>[] = [
        {
            accessorKey: 'firstName',
            header: 'First name',
        },
        {
            accessorKey: 'lastName',
            header: 'Last name',
        },
        {
            accessorKey: 'age',
        },
        {
            accessorKey: 'visits',
            footer: ({ table }) => table.getRowModel().rows.reduce((acc, row) => row.original.visits + acc, 0),
        },
        {
            accessorKey: 'status',
        },
        {
            accessorKey: 'progress',
        },
        {
            accessorKey: 'email',
        },
        {
            accessorKey: 'occupation',
        },
        {
            accessorKey: 'companyName',
            header: 'Company name',
        },
        {
            accessorKey: 'hobby',
        },
    ];

    return (
        <ScrollView>
            <WrapperTable
                columns={columns}
                data={defaultData}
                columnPinning={{
                    left: ['firstName', 'lastName'],
                    right: ['status'],
                }}
            />
        </ScrollView>
    );
};

export default TablePlayground;
