import { OrderByParam } from '../../Hooks/useReactTableSortToOrderBy';

export function convertOrderByToOrdering<T extends Record<string, any>>(
    orderByParam: OrderByParam<T>
): string | undefined {
    const orderByDesc = orderByParam['order_by_desc'] ? String(orderByParam['order_by_desc']) : undefined;
    const orderByAsc = orderByParam['order_by_asc'] ? String(orderByParam['order_by_asc']) : undefined;

    if (orderByDesc) {
        return `-${orderByDesc}`;
    }

    if (orderByAsc) {
        return orderByAsc;
    }

    return undefined;
}

export function convertOrderingToOrderBy<T extends Record<string, any>>(ordering: string | undefined): OrderByParam<T> {
    if (!ordering) {
        return {};
    }
    if (ordering.startsWith('-')) {
        return {
            order_by_desc: ordering.slice(1),
        };
    }
    return {
        order_by_asc: ordering,
    };
}
