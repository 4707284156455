import { ErrorMessage, Field, FieldProps, Form } from 'formik';
import styles from './RoundClientUserForm.module.css';
import { FormField, Label } from '@round/ui-kit';
import { RoundClientUser, getClients } from '@round/api';
import { StylesConfig, ValueType } from 'react-select';
import { GenericDropdownOption } from '../../../../../../../App.types';
import PaginatedSelect from '../../../../../../../ui/DataEntry/PaginatedSelect/PaginatedSelect';

export type RoundClientUserFormValues = Pick<RoundClientUser, 'first_name' | 'last_name' | 'email'> & {
    client_ids: ValueType<GenericDropdownOption<number>, true>;
};

const selectStyles: StylesConfig = {
    control: (provided) => ({
        ...provided,
        border: '1px solid #c2cfe0',
        borderRadius: '0.1875rem',
        minHeight: '2.5rem',
        fontSize: '0.8125rem',
    }),
};

const RoundClientUserForm = ({ formType }: { formType: 'edit' | 'create' }) => {
    return (
        <Form className={styles.form}>
            <FormField>
                <Label htmlFor="first_name">First Name</Label>
                <Field name="first_name" id="first_name" placeholder="Enter first name..." />
                <ErrorMessage name="first_name">
                    {(message) => <span className={styles.errorMessage}>{message}</span>}
                </ErrorMessage>
            </FormField>

            <FormField>
                <Label htmlFor="last_name">Last Name</Label>
                <Field name="last_name" id="last_name" placeholder="Enter last name..." />
                <ErrorMessage name="last_name">
                    {(message) => <span className={styles.errorMessage}>{message}</span>}
                </ErrorMessage>
            </FormField>

            {formType === 'create' && (
                <FormField>
                    <Label htmlFor="email">Email</Label>
                    <Field is={'email'} name="email" id="email" placeholder="Enter email..." />
                    <ErrorMessage name="email">
                        {(message) => <span className={styles.errorMessage}>{message}</span>}
                    </ErrorMessage>
                </FormField>
            )}

            <FormField>
                <Label htmlFor="client_ids">Clients</Label>
                <Field name="client_ids" id="client_ids">
                    {(props: FieldProps<RoundClientUserFormValues['client_ids']>) => (
                        <PaginatedSelect
                            isMulti
                            placeholder="Select clients..."
                            value={props.field.value}
                            onChange={(option) => {
                                props.form.setFieldValue(props.field.name, option);
                            }}
                            fetchOptions={getClients}
                            mapToOption={(client) => ({
                                value: client.id,
                                label: client.name,
                            })}
                            styles={selectStyles}
                        />
                    )}
                </Field>
                <ErrorMessage name="client_ids">
                    {(message) => <span className={styles.errorMessage}>{message}</span>}
                </ErrorMessage>
            </FormField>
        </Form>
    );
};

export default RoundClientUserForm;
