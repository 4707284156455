import React from 'react';
import { TiktokMicroInfluencerPost } from '@round/api';
import Table from '../../../../../../ui/NewTable/Table';
import { CellProps, Column } from 'react-table';
import { buildTiktokUserUrl, formatDate, numberWithCommas } from 'helpers';
import styles from './MicroCreatorsPostsTable.module.css';
import { getTotalValue } from '../helpers';
import { filterByVisibility } from 'utility/utility';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';

type Props = {
    posts: TiktokMicroInfluencerPost[];
};

type PostStats = Pick<
    TiktokMicroInfluencerPost,
    'digg_count' | 'comment_count' | 'share_count' | 'play_count' | 'save_count'
>;

const getTotalEngagements = ({ digg_count, comment_count, share_count, save_count }: PostStats) => {
    if (digg_count === null && comment_count === null && share_count === null && save_count === null) {
        return null;
    }

    return (digg_count ?? 0) + (comment_count ?? 0) + (share_count ?? 0) + (save_count ?? 0);
};

const getEngagementRate = (post: PostStats) => {
    const totalEngagements = getTotalEngagements(post);

    if (!post.play_count || totalEngagements === null) {
        return null;
    }

    return totalEngagements / post.play_count;
};

const MicroCreatorsPostsTable = ({ posts }: Props) => {
    const isStaff = useCheckUserGroupsAccess(['influencer_editor']);

    const columns: Column<TiktokMicroInfluencerPost>[] = filterByVisibility([
        {
            Header: 'Account',
            accessor: 'author_username',
            Cell: ({ value }) => (
                <a rel="noopener noreferrer" target="_blank" href={buildTiktokUserUrl(value)}>
                    @{value}
                </a>
            ),
        },
        {
            Header: 'Post',
            accessor: 'url',
            Cell: ({ value }) => (
                <a href={value} target="_blank" rel="noopener noreferrer">
                    {value}
                </a>
            ),
        },
        {
            Header: 'Post date',
            accessor: 'create_time',
            isVisible: !!isStaff,
            Cell: ({ value }) => <>{value ? formatDate(new Date(value * 1000).toISOString()) : '-'}</>,
        },
        {
            Header: 'Plays',
            accessor: 'play_count',
            Cell: ({ value }) => <>{typeof value === 'number' ? numberWithCommas(value) : '-'}</>,
        },
        {
            Header: 'Total engagements',
            id: 'total_engagement',
            Cell: ({ row: { original } }: CellProps<TiktokMicroInfluencerPost>) => {
                const totalEngagements = getTotalEngagements(original);
                return <>{typeof totalEngagements === 'number' ? numberWithCommas(totalEngagements) : '-'}</>;
            },
        },
        {
            Header: 'Engagement rate',
            id: 'engagement_rate',
            Cell: ({ row: { original } }: CellProps<TiktokMicroInfluencerPost>) => {
                const rate = getEngagementRate(original);
                if (typeof rate !== 'number' || isNaN(rate) || !isFinite(rate)) {
                    return <>-</>;
                }

                return <>{numberWithCommas((rate * 100).toFixed(2))}%</>;
            },
        },
        {
            Header: 'Likes',
            accessor: 'digg_count',
            Cell: ({ value }) => <>{typeof value === 'number' ? numberWithCommas(value) : '-'}</>,
        },
        {
            Header: 'Comments',
            accessor: 'comment_count',
            Cell: ({ value }) => <>{typeof value === 'number' ? numberWithCommas(value) : '-'}</>,
        },
        {
            Header: 'Shares',
            accessor: 'share_count',
            Cell: ({ value }) => <>{typeof value === 'number' ? numberWithCommas(value) : '-'}</>,
        },
        {
            Header: 'Saves',
            accessor: 'save_count',
            Cell: ({ value }) => <>{typeof value === 'number' ? numberWithCommas(value) : '-'}</>,
        },
    ]);

    return (
        <Table
            tableClassName={styles.table}
            disableSortBy
            data={posts}
            columns={columns}
            renderFooter={() => {
                const totalPostStats: PostStats = {
                    digg_count: getTotalValue(posts, 'digg_count'),
                    share_count: getTotalValue(posts, 'share_count'),
                    comment_count: getTotalValue(posts, 'comment_count'),
                    play_count: getTotalValue(posts, 'play_count'),
                    save_count: getTotalValue(posts, 'save_count'),
                };

                const totalEngagements = getTotalEngagements(totalPostStats);
                const engagementRate = getEngagementRate(totalPostStats);

                return (
                    <tfoot>
                        <tr>
                            <td colSpan={isStaff ? 3 : 2} />
                            <td>
                                {typeof totalPostStats.play_count === 'number'
                                    ? numberWithCommas(totalPostStats.play_count)
                                    : '-'}
                            </td>
                            <td>{typeof totalEngagements === 'number' ? numberWithCommas(totalEngagements) : '-'}</td>
                            <td>
                                {typeof engagementRate !== 'number' ||
                                isNaN(engagementRate) ||
                                !isFinite(engagementRate)
                                    ? '-'
                                    : `${numberWithCommas((engagementRate * 100).toFixed(2))}%`}
                            </td>
                            <td>
                                {typeof totalPostStats.digg_count === 'number'
                                    ? numberWithCommas(totalPostStats.digg_count)
                                    : '-'}
                            </td>
                            <td>
                                {typeof totalPostStats.comment_count === 'number'
                                    ? numberWithCommas(totalPostStats.comment_count)
                                    : '-'}
                            </td>
                            <td>
                                {typeof totalPostStats.share_count === 'number'
                                    ? numberWithCommas(totalPostStats.share_count)
                                    : '-'}
                            </td>
                            <td>
                                {typeof totalPostStats.save_count === 'number'
                                    ? numberWithCommas(totalPostStats.save_count)
                                    : '-'}
                            </td>
                        </tr>
                    </tfoot>
                );
            }}
        />
    );
};

export default MicroCreatorsPostsTable;
