import { InstagramInfluencerUser } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';
import { getInstagramInfluencerUsers } from '../Instagram.api';

type State = DataState<{ influencerUsers: InstagramInfluencerUser[]; count: number; next: string | null }>;

const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export default function useInstagramInfluencerUsers() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (...[params, requestInit]: Parameters<typeof getInstagramInfluencerUsers>) => {
        setState((prev) => ({ data: prev.data, error: null, status: 'loading' }));

        try {
            const response = await getInstagramInfluencerUsers(params, requestInit);
            setState((prev) => ({
                status: 'success',
                data: {
                    influencerUsers: prev.data?.influencerUsers.concat(response.data.results) ?? response.data.results,
                    count: response.data.count,
                    next: response.data.next,
                },
                error: null,
            }));

            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            setState((prev) => ({ ...prev, error: 'Could not fetch instagram influencer users', status: 'error' }));
            throw e;
        }
    }, []);

    const reset = useCallback(() => setState(initialState), []);

    return {
        ...state,
        fetchData,
        reset,
    };
}
