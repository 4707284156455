import React, { HTMLProps } from 'react';
import { Formik, Form as FormikForm, FormikConfig, Field, ErrorMessage, FormikProps } from 'formik';
import FormField from '../FormField/FormField';
import Label from '../Label/Label';
import Button from '../../Buttons/Button/Button';
import styles from './GeneratedForm.module.css';

export type GeneratedFormProps<FormValues extends Record<string, any>> = {
    fields: FieldConfig<FormValues>[];
    initialValues: FormValues;
    onSubmit: FormikConfig<FormValues>['onSubmit'];
    validationSchema?: FormikConfig<FormValues>['validationSchema'];
    enableReinitialize?: boolean;
    renderActions?: (props: FormikProps<FormValues>) => React.ReactNode;
};

const renderDefaultActions = <FormValues extends Record<string, any>>(props: FormikProps<FormValues>) => (
    <div className={styles.actions}>
        <Button
            htmlType="button"
            type="filled"
            color="black"
            loading={props.isSubmitting}
            disabled={!props.isValid}
            onClick={props.submitForm}
        >
            Submit
        </Button>
    </div>
);

const GeneratedForm = <T extends Record<string, any>>({
    validationSchema,
    initialValues,
    fields,
    enableReinitialize,
    onSubmit,
    renderActions = renderDefaultActions,
}: GeneratedFormProps<T>) => {
    return (
        <Formik
            enableReinitialize={enableReinitialize}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {(props) => (
                <FormikForm>
                    {fields.map(({ name, ...field }) => (
                        <FormField key={name}>
                            <Label htmlFor={name}>{field.label}</Label>
                            <Field name={name} id={name} {...field} />
                            <ErrorMessage name={name}>
                                {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                            </ErrorMessage>
                        </FormField>
                    ))}
                    {renderActions(props)}
                </FormikForm>
            )}
        </Formik>
    );
};

type BaseFieldConfig<FormValues extends Record<string, any>> = {
    name: keyof FormValues extends string ? keyof FormValues : never;
    label: string;
};

type TextField<FormValues extends Record<string, any>> = BaseFieldConfig<FormValues> & {
    type: 'text';
} & Exclude<HTMLProps<HTMLInputElement>, BaseFieldConfig<FormValues>>;

type NumberField<FormValues extends Record<string, any>> = BaseFieldConfig<FormValues> & {
    type: 'number';
} & Exclude<HTMLProps<HTMLInputElement>, BaseFieldConfig<FormValues>>;

type FieldConfig<FormValues extends Record<string, any>> = TextField<FormValues> | NumberField<FormValues>;

export default GeneratedForm;
