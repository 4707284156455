import { microwave } from '@round/api';
import { ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { createContext, Dispatch, ReactNode, useCallback, useReducer } from 'react';
import useNonNullContext from 'Hooks/useNonNullContext';

type State = {
    stats: microwave.InstagramCampaignStats | null;
    isStatsLoading: boolean;
    isStatsInitialized: boolean;
    hasErrorLoadingStats: boolean;
    posts: microwave.InstagramCampaignPost[];
    arePostsLoading: boolean;
    arePostsInitialized: boolean;
    hasErrorLoadingPosts: boolean;
};

const initialState: State = {
    stats: null,
    isStatsInitialized: false,
    isStatsLoading: false,
    hasErrorLoadingStats: false,
    posts: [],
    arePostsLoading: false,
    arePostsInitialized: false,
    hasErrorLoadingPosts: false,
};

type Actions =
    | ReducerAction<'loadStats'>
    | ReducerActionWithPayload<'statsInitialized', State['stats']>
    | ReducerAction<'errorLoadingStats'>
    | ReducerAction<'resetStats'>
    | ReducerAction<'loadPosts'>
    | ReducerActionWithPayload<'postsInitialized', State['posts']>
    | ReducerAction<'errorLoadingPosts'>
    | ReducerAction<'resetPosts'>;

const reducer = createReducer<State, Actions>({
    loadStats: (state) => ({
        ...state,
        isStatsLoading: true,
    }),
    errorLoadingStats: (state) => ({
        ...state,
        isStatsLoading: false,
        hasErrorLoadingStats: true,
        isStatsInitialized: true,
    }),
    statsInitialized: (state, { payload }) => ({
        ...state,
        isStatsLoading: false,
        hasErrorLoadingStats: false,
        isStatsInitialized: true,
        stats: payload,
    }),
    resetStats: (state) => ({
        ...state,
        stats: null,
        isStatsInitialized: false,
        isStatsLoading: false,
        hasErrorLoadingStats: false,
    }),
    loadPosts: (state) => ({
        ...state,
        arePostsLoading: true,
    }),
    postsInitialized: (state, { payload }) => ({
        ...state,
        arePostsLoading: false,
        arePostsInitialized: true,
        hasErrorLoadingPosts: false,
        posts: payload,
    }),
    errorLoadingPosts: (state) => ({
        ...state,
        arePostsLoading: false,
        hasErrorLoadingPosts: true,
        arePostsInitialized: true,
    }),
    resetPosts: (state) => ({
        ...state,
        posts: [],
        arePostsInitialized: false,
        arePostsLoading: false,
        hasErrorLoadingPosts: false,
    }),
});

const InstagramMicroCampaignContext = createContext<State | null>(null);
const InstagramMicroCampaignDispatchContext = createContext<Dispatch<Actions> | null>(null);

export const InstagramMicroCampaignProvider = ({ children }: { children?: ReactNode | undefined }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <InstagramMicroCampaignContext.Provider value={state}>
            <InstagramMicroCampaignDispatchContext.Provider value={dispatch}>
                {children}
            </InstagramMicroCampaignDispatchContext.Provider>
        </InstagramMicroCampaignContext.Provider>
    );
};

export function useInstagramMicroCampaignStats(campaignId?: number | undefined) {
    const state = useNonNullContext(InstagramMicroCampaignContext);
    const dispatch = useNonNullContext(InstagramMicroCampaignDispatchContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (typeof campaignId !== 'number') {
                return;
            }

            try {
                dispatch({ type: 'loadStats' });
                const response = await microwave.getInstagramCampaignStats(campaignId, requestInit);
                if (response.status === 404) {
                    dispatch({ type: 'errorLoadingStats' });
                    return;
                }

                dispatch({ type: 'statsInitialized', payload: response.data });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'errorLoadingStats' });
            }
        },
        [campaignId, dispatch]
    );

    const reset = useCallback(() => dispatch({ type: 'resetStats' }), [dispatch]);

    return {
        stats: state.stats,
        isLoading: state.isStatsLoading,
        isInitialized: state.isStatsInitialized,
        hasError: state.hasErrorLoadingStats,
        init,
        reset,
    };
}

export function useInstagramMicroCampaignPosts(campaignId?: number | undefined) {
    const state = useNonNullContext(InstagramMicroCampaignContext);
    const dispatch = useNonNullContext(InstagramMicroCampaignDispatchContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (typeof campaignId !== 'number') {
                return;
            }

            try {
                dispatch({ type: 'loadPosts' });
                const posts = await microwave.getAllInstagramCampaignPosts(
                    {
                        campaignId,
                        payment_request_status: 'APPROVED',
                        ordering: '-reel_views',
                    },
                    requestInit
                );

                dispatch({ type: 'postsInitialized', payload: posts });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: 'errorLoadingPosts' });
            }
        },
        [campaignId, dispatch]
    );

    const reset = useCallback(() => dispatch({ type: 'resetPosts' }), [dispatch]);

    return {
        posts: state.posts,
        arePostsLoading: state.arePostsLoading,
        arePostsInitialized: state.arePostsInitialized,
        hasError: state.hasErrorLoadingPosts,
        init,
        reset,
    };
}
