import { useCallback, useContext } from 'react';
import { InfluencerPlanContext } from '../InfluencerPlanContext';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { TiktokCreatorsDispatchContext, TiktokCreatorsStateContext } from './TiktokCreatorsContext';
import { setManualStatusOnTiktokInfluencerPost } from '../../InfluencerPlan.api';
import { isNumber } from '../../../../../utility/utility';
import {
    fetchTiktokUserStats,
    getTiktokInfluencerUser,
    getTiktokUserImages,
    getTiktokUsers,
    patchTiktokInfluencerUser,
    TiktokInfluencerUser,
    TiktokInfluencerUserApiBody,
    TiktokUser,
    TiktokUserStats,
    getAllTiktokInfluencerPosts,
    getTiktokInfluencerPost,
} from '@round/api';
import { getTiktokInfluencerUsers, getTiktokVideos, getTiktokVideoStats } from '../../../../TikTok/TikTok.api';
import { TiktokInfluencerPostResult } from '../../../../../App.types';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import { showNotification } from '../../../../../helpers';
import { TiktokInfluencerPostRow } from '../../features/TiktokTable/TiktokInfluencerPostsTable/TiktokInfluencerPostsTable';
import { TiktokInfluencerPostApiBody } from '../../types/Tiktok.types';
import { patchTiktokInfluencerPost } from '../../api/Tiktok.api';
import { InfluencerPostManualStatus } from '../../InfluencerPlan.types';
import { TiktokInfluencerPost } from '@round/api';

export default function useCreatorPlanTiktokInfluencerPosts() {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const state = useNonNullContext(TiktokCreatorsStateContext);
    const dispatch = useNonNullContext(TiktokCreatorsDispatchContext);

    const fetchInfluencerUsers = useCallback(async (ids: number[], requestInit?: RequestInit): Promise<
        TiktokInfluencerUser[]
    > => {
        if (!ids.length) {
            return [];
        }

        return getTiktokInfluencerUsers({ id: ids.toString(), page_size: ids.length }, requestInit).then(
            (r) => r.data.results
        );
    }, []);

    const fetchVideoStats = useCallback(async (ids: number[], requestInit?: RequestInit): Promise<
        TiktokInfluencerPostResult[]
    > => {
        if (!ids.length) {
            return [];
        }

        return getTiktokVideoStats({ videoIds: ids, latest: true }, requestInit);
    }, []);

    const fetchVideos = useCallback(async (ids: number[], requestInit?: RequestInit) => {
        if (!ids.length) {
            return [];
        }

        return getTiktokVideos({ videoIds: ids }, requestInit);
    }, []);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (!influencerPlan?.id) {
                return;
            }

            dispatch({ type: 'loadTiktokInfluencerPostData' });
            try {
                const influencerPosts = await getAllTiktokInfluencerPosts({ plan_id: influencerPlan.id }, requestInit);
                const influencerUserIds = Array.from(
                    new Set(influencerPosts.map((p) => p.influencer_id).filter(isNumber))
                );
                const videoIds = influencerPosts.map((p) => p.tiktok_post).filter(isNumber);
                const [influencerUsers, videos, videoStats] = await Promise.all([
                    fetchInfluencerUsers(influencerUserIds, requestInit),
                    fetchVideos(videoIds, requestInit),
                    fetchVideoStats(videoIds, requestInit),
                ]);

                let userStats: TiktokUserStats[] = [];
                let users: TiktokUser[] = [];
                const userIds = influencerUsers.map((influencer) => influencer.user).filter(isNumber);
                if (userIds.length) {
                    const [userStatsResponse, usersResponse] = await Promise.all([
                        fetchTiktokUserStats({ user_ids: userIds, latest: true }, requestInit),
                        getTiktokUsers({ id: userIds.join(), page_size: userIds.length }, requestInit),
                    ]);

                    userStats = userStatsResponse.status === 200 ? userStatsResponse.data.results : [];
                    users = usersResponse.status === 200 ? usersResponse.data.results : [];
                }

                dispatch({
                    type: 'tiktokInfluencerPostDataInitialized',
                    payload: {
                        tiktokInfluencerPosts: influencerPosts,
                        tiktokInfluencerUsers: influencerUsers,
                        tiktokUserStats: userStats,
                        tiktokVideos: videos,
                        tiktokVideoStats: videoStats,
                        tiktokUsers: users,
                    },
                });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'setTiktokInfluencerPostLoadingError',
                    payload: "Sorry, we're experiencing technical issues",
                });
            }
        },
        [dispatch, fetchInfluencerUsers, fetchVideoStats, fetchVideos, influencerPlan?.id]
    );

    useAbortableEffect(
        (signal) => {
            const userIds = state.tiktokInfluencerUsers.map((influencer) => influencer.user).filter(isNumber);
            if (!userIds.length) {
                return;
            }

            getTiktokUserImages(userIds, { signal })
                .then((images) => {
                    dispatch({ type: 'tiktokUserImagesInitialized', payload: images });
                })
                .catch((e) => {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    showNotification('Could not fetch user images', 'error');
                });
        },
        [dispatch, state.tiktokInfluencerUsers]
    );

    const updateInfluencerPost = useCallback(
        async (postId: number, data: Partial<TiktokInfluencerPostApiBody>) => {
            const response = await patchTiktokInfluencerPost(postId, data);

            if (response.status === 200) {
                dispatch({ type: 'influencerPostUpdated', payload: response.data });

                const influencer = state.tiktokInfluencerUsers.find(
                    (influencer) => influencer.id === response.data.influencer_id
                );

                if (!influencer && response.data.influencer_id) {
                    const influencerUser = await getTiktokInfluencerUser(response.data.influencer_id);
                    dispatch({ type: 'addTiktokInfluencerUser', payload: influencerUser });
                }

                return response;
            }

            return response;
        },
        [dispatch, state.tiktokInfluencerUsers]
    );

    const updateInfluencerUser = useCallback(
        async (influencerId: number, data: Partial<TiktokInfluencerUserApiBody>) => {
            const response = await patchTiktokInfluencerUser(influencerId, data);
            if (response.status === 200) {
                dispatch({ type: 'influencerUserUpdated', payload: response.data });
                return response;
            }

            return response;
        },
        [dispatch]
    );

    const updateInfluencerPostStatus = useCallback(
        async (data: InfluencerPostManualStatus) => {
            await setManualStatusOnTiktokInfluencerPost(data);
            const response = await getTiktokInfluencerPost(data.influencer_post_id);
            if (response.status === 200) {
                dispatch({ type: 'influencerPostUpdated', payload: response.data });
                return;
            }
            throw new Error('Could not update influencer post status');
        },
        [dispatch]
    );

    return {
        init,
        initialized: state.tiktokInfluencerPostDataInitialized,
        error: state.tiktokInfluencerPostDataLoadingError,
        loading: state.tiktokInfluencerPostDataLoading,
        rows: state.tiktokInfluencerPosts.map<TiktokInfluencerPostRow>((post) => {
            const influencer = state.tiktokInfluencerUsers.find((inf) => inf.id === post.influencer_id);
            const postTiktokUserId = influencer?.user ?? post.tiktok_user;
            return {
                ...post,
                influencer,
                user: state.tiktokUsers.find((user) => user.id === postTiktokUserId),
                userStats: state.tiktokUserStats.find((stats) => stats.user_id === postTiktokUserId),
                userImage: state.tiktokUserImages.find((image) => image.user_id === postTiktokUserId),
                tiktokVideo: state.tiktokVideos.find((video) => video.id === post.tiktok_post),
                tiktokVideoStats: state.tiktokVideoStats.find((stats) => stats.video_id === post.tiktok_post),
            };
        }),
        posts: state.tiktokInfluencerPosts,
        influencerUsers: state.tiktokInfluencerUsers,
        users: state.tiktokUsers,
        userStats: state.tiktokUserStats,
        userImages: state.tiktokUserImages,
        videos: state.tiktokVideos,
        videoStats: state.tiktokVideoStats,
        updateInfluencerPost,
        updateInfluencerUser,
        updateInfluencerPostStatus,
        setInfluencerUser: (influencer: TiktokInfluencerUser) =>
            dispatch({ type: 'influencerUserUpdated', payload: influencer }),
        setInfluencerPost: (post: TiktokInfluencerPost) => dispatch({ type: 'influencerPostUpdated', payload: post }),
    };
}
