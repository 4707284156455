import { InstagramInfluencerUser, InstagramUserImage } from '@round/api';
import { InstagramInfluencerPost } from '../../../../types/Instagram.types';
import styles from './MobileInstagramTable.module.css';
import { buildInstagramUserUrl, openInNewTab } from '../../../../../../../helpers';
import Table, { TableProps } from '../../../../../../../ui/NewTable/Table';
import InfluencerPostStatus from '../../../../components/InfluencerPostStatus/InfluencerPostStatus';
import { ReactComponent as LinkIcon } from 'assets/icons/Link.svg';
import Image from '../../../../../../../ui/Image/Image';
import { ColumnWithLooseAccessor } from 'react-table';
import useNonNullContext from '../../../../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../../../../contexts/OptionsContext/OptionsContext';
import cn from 'classnames';
import { useContext, useMemo, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { InfluencerPlanContext } from '../../../../contexts/InfluencerPlanContext';
import { useCheckUserGroupsAccess } from '../../../../../../Auth/hooks/useCheckUserGroupsAccess';
import { EDITABLE_STATUSES } from '../../../../InfluencerPlan.helpers';

export type MobileInstagramTableRow = {
    post: InstagramInfluencerPost;
    influencer: InstagramInfluencerUser | null;
    profileImage: InstagramUserImage | null;
};

type TableColumn = ColumnWithLooseAccessor<MobileInstagramTableRow> & { editorCell?: boolean };

type MobileInstagramTableProps = {
    data: MobileInstagramTableRow[];
    updateCost: (post: InstagramInfluencerPost, cost: string) => Promise<void>;
    updateNotes: (post: InstagramInfluencerPost, notes: string) => Promise<void>;
    updateContactDetails: (influencer: InstagramInfluencerUser, contactDetails: string) => Promise<void>;
    onEditStatusClick: (post: InstagramInfluencerPost) => void;
};

const renderRow: TableProps<any>['renderRow'] = ({ row, rowProps, cellProps }) => (
    <tr {...rowProps} {...row.getRowProps()}>
        {row.cells.map((cell) => (
            <td
                {...cellProps}
                className={cn({
                    [styles.editorCell]: (cell.column as TableColumn).editorCell,
                })}
            >
                {cell.render('Cell')}
            </td>
        ))}
    </tr>
);

const handleEnterKeyDown = (e: any) => {
    if (e.key === 'Enter') {
        e.currentTarget.blur();
    }
};

const MobileInstagramTable = ({
    data,
    updateContactDetails,
    updateCost,
    updateNotes,
    onEditStatusClick,
}: MobileInstagramTableProps) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const { influencerPlan } = useContext(InfluencerPlanContext);

    const canEdit = useCheckUserGroupsAccess(['influencer_editor']);

    const columns: TableColumn[] = useMemo(
        () => [
            {
                Header: 'Account',
                Cell: ({ row }) => {
                    const influencer = row.original.influencer;
                    const profileImage = row.original.profileImage;
                    return (
                        <div className={styles.accountContainer}>
                            {profileImage && (
                                <Image
                                    className={styles.influencerThumbnail}
                                    src={
                                        profileImage?.avatar_thumb.cached_url ||
                                        profileImage?.avatar_thumb.original_url ||
                                        ''
                                    }
                                    alt="avatar"
                                    crossOrigin="anonymous"
                                />
                            )}
                            <div className={styles.accountDetails}>
                                <span>
                                    {!!influencer?.username ? (
                                        <a
                                            onClick={(e) => e.stopPropagation()}
                                            className={styles.influencer}
                                            href={buildInstagramUserUrl(influencer.username)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            @{influencer.username}
                                        </a>
                                    ) : (
                                        '-'
                                    )}
                                </span>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Status',
                Cell: ({ row }) => {
                    const post = row.original.post;
                    const postStatusEditable = EDITABLE_STATUSES.includes(post.status) && Boolean(canEdit);
                    return (
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                if (post.status === 'live') {
                                    openInNewTab(post.post_url);
                                }

                                if (!postStatusEditable) {
                                    return;
                                }

                                onEditStatusClick(post);
                            }}
                        >
                            <div className={styles.statusContainer}>
                                <button className={styles.statusButton}>
                                    <InfluencerPostStatus
                                        className={styles.status}
                                        status={post.status}
                                        draftExpectedBy={post.draft_expected_by}
                                    />
                                </button>
                                {post.status === 'live' && <LinkIcon className={styles.linkIcon} />}
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Contact',
                editorCell: true,
                Cell: ({ row }) => {
                    const influencer = row.original.influencer;
                    const [contactDetails, setContactDetails] = useState(influencer?.contact_details || '');

                    return (
                        <input
                            placeholder="Enter contact details"
                            className={cn(styles.input, styles.inputWide)}
                            disabled={!influencer}
                            value={contactDetails}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={handleEnterKeyDown}
                            onChange={(e) => setContactDetails(e.target.value)}
                            onBlur={() => {
                                if (!influencer || influencer.contact_details === contactDetails) {
                                    return;
                                }

                                updateContactDetails(influencer, contactDetails).catch(() => {
                                    setContactDetails(influencer.contact_details || '');
                                });
                            }}
                        />
                    );
                },
            },
            {
                Header: 'Cost',
                editorCell: true,
                Cell: ({ row }) => {
                    const post = row.original.post;
                    const [cost, setCost] = useState(post.cost);

                    const currency = currencies.find((curr) => curr.id === post.currency_id);

                    return (
                        <CurrencyInput
                            className={cn(styles.input, styles.costInput, {
                                [styles.invalid]: post.currency_id !== influencerPlan?.currency.id,
                            })}
                            prefix={currency?.symbol}
                            value={cost}
                            onValueChange={setCost}
                            onKeyDown={handleEnterKeyDown}
                            onBlur={() => {
                                if (Number(cost) === Number(post.cost)) {
                                    return;
                                }

                                updateCost(post, cost ?? '0').catch(() => {
                                    setCost(post.cost);
                                });
                            }}
                            groupSeparator=","
                            decimalSeparator="."
                            allowDecimals={false}
                            decimalScale={0}
                            decimalsLimit={0}
                        />
                    );
                },
            },
            {
                Header: 'Notes',
                editorCell: true,
                Cell: ({ row }) => {
                    const post = row.original.post;
                    const [notes, setNotes] = useState(post.notes || '');
                    return (
                        <textarea
                            rows={2}
                            className={cn(styles.input, styles.inputWide)}
                            placeholder={'-'}
                            value={notes}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => setNotes(e.target.value)}
                            onBlur={(e) => {
                                if (post.notes === notes) {
                                    return;
                                }

                                updateNotes(row.original.post, notes).catch(() => {
                                    setNotes(post.notes || '');
                                });
                            }}
                            onKeyDown={handleEnterKeyDown}
                        />
                    );
                },
            },
        ],
        [canEdit, currencies, influencerPlan, onEditStatusClick, updateContactDetails, updateCost, updateNotes]
    );

    const influencerViewerColumns = columns.filter((column) => !column.editorCell);
    const columnsToUse = canEdit ? columns : influencerViewerColumns;

    return (
        <Table columns={columnsToUse} data={data} tableClassName={styles.table} disableSortBy renderRow={renderRow} />
    );
};

export default MobileInstagramTable;
