import { ButtonHTMLAttributes, DetailedHTMLProps, useState } from 'react';
import styles from './IconButton.module.css';
import cn from 'classnames';
import { Popover } from '@round/ui-kit';

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    hint?: string;
};

const IconButton = (props: Props) => {
    const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);

    return (
        <>
            <button {...props} ref={setButtonRef} className={cn(styles.button, props.className)}>
                {props.children}
            </button>

            <Popover
                disabled={!props.hint}
                anchorElement={buttonRef}
                showOn="hover"
                options={{ placement: 'top', modifiers: [{ name: 'offset', options: { offset: [0, 10] } }] }}
            >
                {() => (
                    <div className={styles.hint}>
                        {props.hint}
                        <div className={styles.arrow} />
                    </div>
                )}
            </Popover>
        </>
    );
};

export default IconButton;
