import React from 'react';
import { Column } from './Table.types';
import styles from './Table.module.css';

type TableProps<T> = {
    columns: Array<Column<T>>;
    data: Array<T>;
    className?: string;
};

function Table<T>({ columns, data, className }: TableProps<T>) {
    return (
        <div className={styles.wrapper}>
            <table className={className}>
                <thead>
                    <tr>
                        {columns.map(({ name, label, className }) => (
                            <th key={name} className={className}>
                                {label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index, array) => (
                        <tr key={index}>
                            {columns.map(({ name, value, renderCell, className }) => {
                                if (typeof renderCell === 'function') {
                                    return renderCell(item, index, array);
                                }

                                return (
                                    <td key={name} className={className}>
                                        <>
                                            {typeof value === 'function' && value(item)}
                                            {typeof value !== 'function' && item[value]}
                                        </>
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Table;
