import { IconProps } from '../Icon.types';

const SpotifyIcon = ({ height, width, onClick, className }: IconProps) => {
    return (
        <svg
            role="img"
            aria-label="Spotify icon"
            onClick={onClick}
            className={className}
            width={width}
            height={height}
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.5 18.5C14.4706 18.5 18.5 14.4706 18.5 9.5C18.5 4.52944 14.4706 0.5 9.5 0.5C4.52944 0.5 0.5 4.52944 0.5 9.5C0.5 14.4706 4.52944 18.5 9.5 18.5Z"
                fill="#727F8F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2708 8.96501C11.0383 7.43019 8.18036 7.08372 5.6764 8.05748C5.3547 8.18259 4.99248 8.02322 4.86737 7.70151C4.74226 7.3798 4.90164 7.01759 5.22335 6.89248C8.11939 5.76624 11.4114 6.16977 13.979 7.93496C14.2634 8.13051 14.3355 8.51962 14.1399 8.80406C13.9443 9.0885 13.5552 9.16056 13.2708 8.96501Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0535 11.0526C10.5154 9.9157 8.49044 9.70145 6.64205 10.386C6.31836 10.5059 5.95877 10.3407 5.83888 10.017C5.719 9.69333 5.88421 9.33374 6.2079 9.21386C8.4095 8.39845 10.8846 8.63421 12.7965 10.0473C13.0741 10.2525 13.1328 10.6439 12.9276 10.9214C12.7224 11.199 12.3311 11.2577 12.0535 11.0526Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.2531 13.095C10.0603 12.2998 8.62567 12.1127 7.33189 12.6302C7.01141 12.7584 6.64767 12.6026 6.51948 12.2821C6.39128 11.9616 6.54717 11.5978 6.86766 11.4697C8.57388 10.7872 10.4392 11.0501 11.9465 12.0549C12.2337 12.2464 12.3113 12.6344 12.1198 12.9216C11.9283 13.2088 11.5403 13.2865 11.2531 13.095Z"
                fill="white"
            />
        </svg>
    );
};

export default SpotifyIcon;
