import Popover from '../../../Popover/Popover';
import { useState } from 'react';
import DatePicker, { DatePickerProps } from '../DatePicker/DatePicker';
import { formatToIsoDateString } from '../../../../utility/utility';
import styles from './DatePickerSelect.module.css';
import { ReactComponent as Close } from '../../../../assets/icons/Close.svg';
import cn from 'classnames';

const formatDisplayDate = (date: DatePickerProps<boolean>['value']) => {
    if (!date) {
        return '';
    }

    if (Array.isArray(date)) {
        return date.map((date) => formatToIsoDateString(date)).join(' - ');
    }

    return formatToIsoDateString(date);
};

type DatePickerSelectProps = DatePickerProps<boolean> & {
    onClear: () => void;
    className?: string;
    placeholder?: string;
};

const DatePickerSelect = ({ className, placeholder, onClear, ...DatePickerProps }: DatePickerSelectProps) => {
    const [element, setElement] = useState<HTMLElement | null>(null);

    const hasValue = !!DatePickerProps.value;
    const selectedDateDisplayValue = formatDisplayDate(DatePickerProps.value);
    const placeholderValue = placeholder || (DatePickerProps.selectRange ? 'Select date range...' : 'Select date...');

    return (
        <>
            <div ref={setElement} className={cn(styles.select, className)}>
                {hasValue ? (
                    <span className={styles.value}>{selectedDateDisplayValue}</span>
                ) : (
                    <span className={styles.placeholder}>{placeholderValue}</span>
                )}

                {hasValue && (
                    <Close
                        className={styles.closeIcon}
                        onClickCapture={(e) => {
                            e.nativeEvent.stopImmediatePropagation();
                            onClear();
                        }}
                    />
                )}
            </div>
            <Popover anchorElement={element} showOn="click">
                <DatePicker {...DatePickerProps} />
            </Popover>
        </>
    );
};

export default DatePickerSelect;
