import React from 'react';
import styles from './InvoiceTrackerPage.module.css';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import InvoiceTracker from '../../../Modules/Finance/InvoiceTracker/InvoiceTracker';

const InvoiceTrackerPage = () => {
    return (
        <div className={styles.page}>
            <NavBar />
            <InvoiceTracker />
        </div>
    );
};

export default InvoiceTrackerPage;
