import React from 'react';
import styles from './Spinner.module.css';
import cn from 'classnames';

type SpinnerProps = {
    className?: string;
};

const Spinner = ({ className }: SpinnerProps) => {
    //This prevents the spinner from starting at the same point in the animation every time it is rendered
    const mountTime = React.useRef(Date.now());
    const animationDuration = 1000;
    const mountDelay = -(mountTime.current % animationDuration);

    return (
        <div className={cn(styles.container, className)}>
            <div
                className={styles.spinner}
                style={{
                    animationDuration: `${animationDuration}ms`,
                    animationDelay: `${mountDelay}ms`,
                }}
            />
        </div>
    );
};

export default Spinner;
