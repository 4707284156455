import { ModalBaseProps, TabPanel, Tabs } from '@round/ui-kit';
import FullscreenModal from 'ui-new/whitelabel/FullscreenModal/FullscreenModal';
import SongData from './SongData/SongData';
import { SongDataProvider } from './SongDataContext';
import styles from './SongDataModal.module.css';
import { ReactComponent as CloseIcon } from 'assets/whitelabel/Cross.svg';
import { useState } from 'react';
import TiktokData from './Tiktok/TiktokData';

type Props = Pick<ModalBaseProps, 'isOpen' | 'onClose'> & {
    songId: number | undefined | null;
};

type PlatformTabs = 'tiktok';

const SongDataModal = ({ isOpen, onClose, songId }: Props) => {
    const [activePlatform, setActivePlatform] = useState<PlatformTabs>('tiktok');

    return (
        <FullscreenModal isOpen={isOpen} onClose={onClose} closeOnOverlayClick closeOnEscape>
            <SongDataProvider key={songId}>
                <div className={styles.header}>
                    <SongData key={songId} songId={songId} />

                    <button className={styles.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </button>
                </div>

                <Tabs activeTab={activePlatform} onChange={setActivePlatform}>
                    <div className={styles.platformTabContent}>
                        <TabPanel name="tiktok">
                            <TiktokData />
                        </TabPanel>
                    </div>
                </Tabs>
            </SongDataProvider>
        </FullscreenModal>
    );
};

export default SongDataModal;
