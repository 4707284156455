import ExpandableSearchInput from '../../../ui/ExpandableSearchInput/ExpandableSearchInput';
import { useState } from 'react';

const SearchPlayground = () => {
    const [value, setValue] = useState('');

    return (
        <ExpandableSearchInput
            persistIfValuePresent
            value={value}
            onChange={setValue}
            style={{ maxWidth: '300px', width: 'max-content' }}
        />
    );
};

export default SearchPlayground;
