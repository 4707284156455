import { useCallback, useMemo, useState } from 'react';
import {
    getTiktokInfluencerPosts,
    TiktokInfluencerPost,
    TiktokAudioPostStats,
    getTiktokAudioPostStats,
} from '@round/api';
import { GetTiktokAudioPostStatsSortableKeys } from '@round/api';
import { TiktokTopPostsRow } from './TopPostsTable';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';

const DEFAULT_ORDERING = '-play_count';

export default function useTopPosts(audioId: number | undefined) {
    const [postStats, setPostStats] = useState<TiktokAudioPostStats[]>([]);
    const [influencerPlanPosts, setInfluencerPlanPosts] = useState<TiktokInfluencerPost[]>([]);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [count, setCount] = useState(0);
    const [ordering, setOrdering] = useState<GetTiktokAudioPostStatsSortableKeys | undefined>(DEFAULT_ORDERING);

    const [isLoading, setIsLoading] = useState(false);
    const [hasErrorLoading, setErrorLoading] = useState<string | null>(null);

    const fetchData = useCallback(
        async (params: Parameters<typeof getTiktokAudioPostStats>[0], requestInit?: RequestInit) => {
            try {
                setErrorLoading(null);
                setIsLoading(true);
                const {
                    data: { results, count },
                    status,
                } = await getTiktokAudioPostStats(params, requestInit);
                if (status !== 200) {
                    setErrorLoading('Something went wrong');
                    return;
                }

                setPostStats(results);
                setCount(count);

                const {
                    data: { results: influencerPlanPosts },
                } = await getTiktokInfluencerPosts({ music_id: params.audio_id }, requestInit);
                setInfluencerPlanPosts(influencerPlanPosts);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                const errorMessage = e instanceof Error ? e.message : 'Could not fetch TikTok audio post stats';
                setErrorLoading(errorMessage);
            } finally {
                setIsLoading(false);
            }
        },
        []
    );

    const abortController = useAbortableEffect(
        (signal) => {
            if (!audioId) {
                return;
            }
            fetchData(
                {
                    audio_id: audioId,
                    page,
                    page_size: pageSize,
                    ordering,
                },
                { signal }
            );
        },
        [fetchData, audioId, page, pageSize, ordering]
    );

    const refresh = useCallback(
        async (requestInit?: RequestInit) => {
            if (!audioId) {
                return;
            }

            fetchData(
                {
                    audio_id: audioId,
                    page,
                    page_size: pageSize,
                    ordering,
                },
                requestInit
            );
        },
        [audioId, page, pageSize, ordering, fetchData]
    );

    const reset = useCallback(() => {
        setPostStats([]);
        setInfluencerPlanPosts([]);
        setPage(1);
        setOrdering(DEFAULT_ORDERING);
        setCount(0);
        setErrorLoading(null);
        setIsLoading(false);
    }, []);

    const topPosts: TiktokTopPostsRow[] = useMemo(() => {
        return postStats.map((postStat) => {
            return {
                ...postStat,
                sponsored: influencerPlanPosts.some(
                    (post) => post.tiktok_post_tiktok_id === postStat.video_tiktok_id && !!post.plan_id
                ),
            };
        });
    }, [influencerPlanPosts, postStats]);

    return {
        isLoading,
        hasErrorLoading,
        topPosts,
        count,
        refresh,
        abortController,
        reset,
        parameterHandlers: useMemo(
            () => ({
                page,
                pageSize,
                setPage,
                setPageSize,
                ordering,
                setOrdering,
            }),
            [page, pageSize, setPage, setPageSize, ordering, setOrdering]
        ),
    };
}
