import React from 'react';
import { GenericDropdownOption } from '../../../../App.types';
import { components as DefaultComponents, StylesConfig, ValueType } from 'react-select';
import styles from './TableSelect.module.css';
import Select from '../../../../ui/DataEntry/Select/Select';
import { ReactComponent as ChevronDownIcon } from '../../../../assets/ChevronDown.svg';
import { SelectComponents } from 'react-select/src/components';

type TableSelectProps<T extends GenericDropdownOption<any>> = {
    options: T[];
    value: ValueType<T, false>;
    onChange: (value: ValueType<T, false>) => void;
    disabled?: boolean;
    hint?: string;
    components?: Partial<SelectComponents<T, false>> | undefined;
};

const selectStyles: StylesConfig = {
    control: (provided) => ({
        ...provided,
        background: 'transparent',
        borderRadius: 0,
        borderStyle: 'none',
        boxShadow: 'none',
        cursor: 'pointer',
        padding: '0.625rem',
        minWidth: '5rem',
    }),
    valueContainer: (provided) => ({ ...provided, padding: 0 }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '0.75rem',
        letterSpacing: '0.1px',
        color: '#152E4A',
    }),
    menu: (provided) => ({
        ...provided,
        margin: '0.5rem 0 0',
        zIndex: 9999,
        width: '8rem',
        borderRadius: 0,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#F2F9FF !important' : 'null',
        color: '#152E4A',
        cursor: 'pointer',
        fontSize: '0.75rem',
        padding: '0.4rem 0.2rem',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        display: state.isDisabled ? 'none' : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
};

const TableSelect = <T extends GenericDropdownOption<any>>({
    value,
    options,
    disabled,
    onChange,
    hint,
    components,
}: TableSelectProps<T>) => {
    return (
        <Select
            styles={selectStyles}
            className={styles.container}
            isSearchable={false}
            isDisabled={disabled}
            options={options}
            value={value}
            onChange={onChange}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            components={{
                DropdownIndicator: () => <ChevronDownIcon className={styles.dropdownIcon} />,
                MenuList: (props) => (
                    <DefaultComponents.MenuList {...props}>
                        {props.children}
                        {hint && <div className={styles.rowSelectHint}>{hint}</div>}
                    </DefaultComponents.MenuList>
                ),
                ...components,
            }}
        />
    );
};

export default TableSelect;
