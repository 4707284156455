import { ApiResponse, ApiResponseError } from '../../types';
import { buildApiUrl } from '../../utils';
import { Platform } from '../influencer.types';
import { AggregatedPostStats } from './aggregatedPostStats.types';

export type AggregatedPostStatsParams = { campaign_id: number } | { platform: Platform; public_report_id: string };

type PostAggregatedPostStatsResponse =
    | ApiResponse<AggregatedPostStats, 201>
    | ApiResponse<{ details: string[] }, 400>
    | ApiResponse<ApiResponseError<AggregatedPostStatsParams>, 400>;

export async function postAggregatedPostStats(
    params: AggregatedPostStatsParams,
    requestInit?: RequestInit
): Promise<PostAggregatedPostStatsResponse> {
    const response = await fetch(buildApiUrl(`/api/influencer/aggregated-post-stats/`), {
        ...requestInit,
        method: 'POST',
        headers: {
            ...requestInit?.headers,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not load stats');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}
