import React from 'react';
import styles from './CreateSubmitButton.module.css';
import { FCWithChildren } from '../../utility/utility.types';

const CreateSubmitButton: FCWithChildren<{ disabled: boolean }> = ({ children, disabled }) => {
    return (
        <button disabled={disabled} className={styles.button} type="submit">
            {children}
        </button>
    );
};

export default CreateSubmitButton;
