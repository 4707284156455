import { creatorbase } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

type State = DataState<creatorbase.Report>;
type Actions =
    | ReducerAction<'loadReport'>
    | ReducerActionWithPayload<'reportInitialized', creatorbase.Report>
    | ReducerActionWithPayload<'errorLoadingReport', string>
    | ReducerActionWithPayload<'updateReport', Partial<creatorbase.Report>>;

export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export const reducer = createReducer<State, Actions>({
    loadReport: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    errorLoadingReport: (state, { payload }) => ({
        ...state,
        status: 'error',
        error: payload,
    }),
    reportInitialized: (state, { payload }) => ({
        ...state,
        data: payload,
        error: null,
        status: 'success',
    }),
    updateReport: (state, { payload }) => ({
        ...state,
        data: {
            ...state.data!,
            ...payload,
        },
    }),
});
