import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Currency } from './currency.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    currency: Model as ModelDefinition<Currency>,
};

export const serializers = {
    currency: makeSerializer<Currency>([]),
};

export const factories = {
    currency: createFactory<Currency>({
        name() {
            return 'GBP';
        },
        symbol() {
            return '£';
        },
    }),
};

export function handleCurrencyRequests(server: AppServer) {
    const getAllCurrenciesPath = '/api/advertising/viewsets/currency/';
    server.get(getAllCurrenciesPath, function (this: RouteHandlerContext, schema, request) {
        const currencies = schema.all('currency');
        return buildPaginatedResponse(currencies, {
            url: getAllCurrenciesPath,
            serialize: (resource) => this.serialize(resource, 'currency'),
            queryParams: request.queryParams,
        });
    });
}
