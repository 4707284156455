import Spinner from 'ui-new/Loading/Spinner/Spinner';
import { ReactComponent as SucessIcon } from 'assets/TickCircle.svg';
import { ReactComponent as FailureIcon } from 'assets/CloseCircle.svg';
import styles from './StatusCell.module.css';
import { RequestStatus } from '../../helpers';

type Props = {
    status: RequestStatus | undefined;
    isRequestLoading: boolean;
};

const StatusCell = ({ status, isRequestLoading }: Props) => {
    if (isRequestLoading && !status) {
        return <Spinner />;
    }

    return (
        <div className={styles.container}>
            {!status ? (
                <>
                    <span>Unpaid</span>
                </>
            ) : status.success ? (
                <>
                    <SucessIcon className={styles.success} />
                    <span>Success</span>
                </>
            ) : (
                <>
                    <FailureIcon className={styles.failure} />
                    <span>{status.error}</span>
                </>
            )}
        </div>
    );
};

export default StatusCell;
