import { Search, SearchProps } from '@round/ui-kit';
import cn from 'classnames';
import styles from './SearchInput.module.css';
import { ReactComponent as SearchIcon } from 'assets/whitelabel/SearchIcon.svg';

const SearchInput = ({ className, ...props }: SearchProps) => (
    <div className={cn(styles.searchContainer, className)}>
        <SearchIcon/>
        <Search className={styles.search} {...props} />
    </div>
);
export default SearchInput;
