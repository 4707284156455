import { GenericDropdownOption, TiktokInfluencerPostResult } from '../../../App.types';
import {
    InfluencerPostStatus as InfluencerPostStatusType,
    InfluencerPostStatus,
    PaymentStatus,
} from './InfluencerPlan.types';
import {
    InstagramInfluencerPost,
    InstagramInfluencerPostResult,
    InstagramTablePostStats,
} from './types/Instagram.types';
import { InstagramUserStats } from '../../Instagram/Instagram.types';
import { InstagramInfluencerUser, InstagramPost, TiktokInfluencerPost } from '@round/api';

const withinTwoDays = (timestamp: string) => {
    const now = Date.now();
    const twoDays = 1000 * 60 * 60 * 24 * 2;
    return now - Date.parse(timestamp) <= twoDays;
};

export const getTotalEngagements = (likes: number = 0, comments: number = 0, shares: number = 0): number =>
    likes + comments + shares;

const getPostDataFromInstagramPostStats = (
    scrapedPost: InstagramPost | undefined,
    results: InstagramInfluencerPostResult | undefined
): { likes: number | null; comments: number | null } => {
    if (scrapedPost?.post_stats_timestamp && withinTwoDays(scrapedPost.post_stats_timestamp)) {
        return { comments: scrapedPost.comment_count, likes: scrapedPost.like_count };
    }

    if (results) {
        return { likes: results.likes, comments: results.comments };
    }

    return {
        likes: scrapedPost?.like_count ?? null,
        comments: scrapedPost?.comment_count ?? null,
    };
};

const getDataFromInstagramUserStats = (
    userStats: InstagramUserStats | undefined,
    results: InstagramInfluencerPostResult | undefined
): { followers: number | null } => {
    if (userStats && withinTwoDays(userStats.timestamp)) {
        return { followers: userStats.follower_count };
    }

    if (typeof results?.page_followers === 'number') {
        return { followers: results.page_followers };
    }

    return { followers: userStats?.follower_count ?? null };
};

// We have data source priorities.
// Which data source should set the stats value is declared here
export const getInstagramPostStats = (
    result?: InstagramInfluencerPostResult,
    scrapedPost?: InstagramPost,
    scrapedUserStats?: InstagramUserStats
): InstagramTablePostStats => {
    if (!result && !scrapedPost && !scrapedUserStats) {
        return {};
    }

    const { likes, comments } = getPostDataFromInstagramPostStats(scrapedPost, result);
    const { followers } = getDataFromInstagramUserStats(scrapedUserStats, result);

    const plays = scrapedPost?.play_count ?? null;
    let feedViews;

    if (scrapedPost && withinTwoDays(scrapedPost?.post_stats_timestamp ?? '')) {
        feedViews = scrapedPost.view_count;
    } else if (result) {
        feedViews = result.feed_views;
    } else {
        feedViews = scrapedPost?.view_count ?? null;
    }

    const sumOptional = (a: number | null | undefined, b: number | null | undefined) =>
        typeof a === 'number' || typeof b === 'number' ? (a ?? 0) + (b ?? 0) : undefined;

    const playsOrFeedViews = typeof plays === 'number' ? plays : feedViews;
    const totalViews = sumOptional(playsOrFeedViews, result?.story_views);
    const totalEngagements = sumOptional(likes, comments);
    const engagementRateViews = [plays, feedViews, result?.story_views].find((v) => typeof v === 'number');

    return {
        likes,
        comments,
        totalViews,
        totalEngagements,
        followers,
        feedViews,
        plays,
        storyViews: result?.story_views,
        engagementRateViews,
        engagementRate: Number(totalEngagements) / Number(engagementRateViews),
    };
};

export const getInstagramPostsStatsTotals = (
    influencerPosts: InstagramInfluencerPost[],
    results: InstagramInfluencerPostResult[],
    scrapedUserStats: InstagramUserStats[],
    scrapedPosts: InstagramPost[],
    influencerUsers: InstagramInfluencerUser[]
): InstagramTablePostStats => {
    return influencerPosts
        .map((influencerPost) => {
            const postsInstagramUserId =
                influencerUsers.find((influencer) => influencerPost.influencer_id === influencer.id)?.user ??
                influencerPost.instagram_user;
            const result = results.find((r) => r.post === influencerPost.id);
            const scrapedPost = scrapedPosts.find((s) => s.id === influencerPost.post);
            const scrapedUserStat = scrapedUserStats.find((s) => s.instagram_user === postsInstagramUserId);
            return getInstagramPostStats(result, scrapedPost, scrapedUserStat);
        })
        .reduce(sumInstagramPostStats, {});
};

export const sumInstagramPostStats = (
    a: InstagramTablePostStats,
    b: InstagramTablePostStats
): InstagramTablePostStats => {
    const base: InstagramTablePostStats = {
        shares: undefined,
        comments: undefined,
        feedViews: undefined,
        followers: undefined,
        likes: undefined,
        storyViews: undefined,
        totalEngagements: undefined,
        totalViews: undefined,
        plays: undefined,
        engagementRateViews: undefined,
    };

    return Object.keys(base).reduce((accumulator, key) => {
        const k = key as keyof InstagramTablePostStats;
        const aValue = a[k];
        const bValue = b[k];

        if (typeof aValue !== 'number' && typeof bValue !== 'number') {
            return accumulator;
        }

        accumulator[k] = (aValue ?? 0) + (bValue ?? 0);
        return accumulator;
    }, base);
};

export function sortTiktokActivePosts(posts: TiktokInfluencerPost[], videoStats: TiktokInfluencerPostResult[]) {
    return posts.sort((a, b) => {
        const orderByStatus = statusOrder[a.status] - statusOrder[b.status];
        const viewsDescending =
            (videoStats.find((item) => b.tiktok_post === item.video_id)?.play_count ?? 0) -
            (videoStats.find((item) => a.tiktok_post === item.video_id)?.play_count ?? 0);
        const estimatedViewsDescending =
            (b.estimated_views_override ?? b.estimated_views ?? 0) -
            (a.estimated_views_override ?? a.estimated_views ?? 0);

        if (orderByStatus !== 0) {
            return orderByStatus;
        }

        if (viewsDescending !== 0) {
            return viewsDescending;
        }

        return estimatedViewsDescending;
    });
}

export const EDITABLE_STATUSES: InfluencerPostStatus[] = [
    'planned',
    'briefed',
    'draft_pending',
    'draft_received',
    'redrafting',
    'draft_approved',
    'to_replace',
    'backup',
    'rejected_or_declined',
];

export const postInactiveStatuses: InfluencerPostStatus[] = ['rejected_or_declined', 'backup'];

export const postGroupIsHiddenWarningStatuses: InfluencerPostStatus[] = ['draft_approved', 'live'];

export const paymentStatusOptions: GenericDropdownOption<PaymentStatus>[] = [
    { value: 'PAID', label: 'Paid' },
    { value: 'UNPAID', label: 'Unpaid' },
    { value: 'INVOICE_RECEIVED', label: 'Invoice' },
];

export const StatusLabels: Record<InfluencerPostStatusType, string> = {
    briefed: 'Briefed',
    draft_approved: 'Draft approved',
    draft_pending: 'Expecting draft',
    draft_received: 'Draft received',
    redrafting: 'Re-drafting',
    live: 'Live',
    planned: 'Planned',
    to_replace: 'To replace',
    backup: 'Backup',
    rejected_or_declined: 'Rejected/Declined',
};

export const statusOrder: Record<InfluencerPostStatusType, number> = {
    live: 1,
    draft_approved: 2,
    draft_received: 3,
    redrafting: 4,
    draft_pending: 5,
    briefed: 6,
    planned: 7,
    to_replace: 8,
    backup: 9,
    rejected_or_declined: 10,
};

export const buildInfluencerPlanUrl = (releaseId: number) => `/campaigns/${releaseId}/creators`;

export const getPostCountText = (postCount: number, livePostCount: number) =>
    postCount === 0 ? '0 posts' : `${livePostCount}/${postCount} post${postCount !== 1 ? 's' : ''} live`;
