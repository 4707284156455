import { UserGroup, getRoundProductGroups } from '@round/api';
import { FeatureFlagName } from 'utility/featureFlags';

const TRNDS_URL = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_TRNDS_URL : 'https://app.trnds.xyz/';

export type NavBarItemGroup = {
    title: string;
    items: NavBarLink[];
};

export type NavBarLink = {
    title: string;
    groups: UserGroup[];
    url: string;
    shouldMatch?: 'all' | 'any';
    featureFlag?: FeatureFlagName;
};

export type NavBarItem = NavBarItemGroup | NavBarLink;

export const isNavBarItemGroup = (item: NavBarItem): item is NavBarItemGroup =>
    Array.isArray((item as NavBarItemGroup)?.items);

export const NAVBAR_ITEMS: NavBarItemGroup[] = [
    {
        title: 'Advertising',
        items: [
            {
                title: 'Plans',
                shouldMatch: 'any',
                groups: getRoundProductGroups(['plan_viewer']),
                url: '/campaigns/',
            },
        ],
    },
    {
        title: 'Explore',
        items: [
            {
                title: 'Tiktok Influencer Wide Search',
                groups: ['user_admin', 'wide_search_trial'],
                shouldMatch: 'any',
                url: '/influencer/tiktok/search/',
            },
            {
                title: 'Tiktok Artist',
                groups: getRoundProductGroups(['explorer_tiktok_viewer', 'user_admin']),
                url: '/artist/tiktok/list/',
            },
            {
                title: 'Tiktok Influencer',
                groups: getRoundProductGroups(['explorer_tiktok_viewer', 'plan_viewer', 'user_admin']),
                url: '/influencer/tiktok/list/',
            },
            {
                title: 'Tiktok Audio [TRNDS]',
                groups: ['trnds_product', 'user_admin', 'explorer_tiktok_viewer'],
                url: `${TRNDS_URL}explorer/tiktok/audio/`,
            },
            {
                title: 'Tiktok Hashtag',
                groups: getRoundProductGroups(['explorer_tiktok_viewer', 'user_admin']),
                url: '/explorer/tiktok/hashtag/',
            },
            {
                title: 'Instagram Influencer Wide Search',
                groups: ['user_admin', 'wide_search_trial'],
                shouldMatch: 'any',
                url: '/influencer/instagram/search/',
            },
            {
                title: 'Instagram Artist',
                groups: getRoundProductGroups(['explorer_instagram_viewer', 'user_admin']),
                url: '/artist/instagram/list/',
            },
            {
                title: 'Instagram Influencer',
                groups: getRoundProductGroups(['explorer_instagram_viewer', 'plan_viewer', 'user_admin']),
                url: '/influencer/instagram/list/',
            },
        ],
    },
    {
        title: 'Microwave',
        items: [
            {
                title: 'Campaigns',
                url: '/microwave/campaigns/',
                groups: ['microwave_admin'],
                shouldMatch: 'all',
            },
            {
                title: 'Influencers',
                groups: ['microwave_admin'],
                url: '/microwave/influencers/',
            },
            {
                title: 'Post Approval',
                groups: ['microwave_admin'],
                url: '/microwave/approve-posts/',
                shouldMatch: 'any',
            },
            {
                title: 'Payments',
                groups: ['microwave_finance', 'microwave_admin'],
                shouldMatch: 'any',
                url: '/microwave/payments/',
            },
        ],
    },
    {
        title: 'Finance',
        items: [
            {
                title: 'Invoice Requests',
                groups: ['round_planner'],
                url: '/finance/invoice-requests/',
            },
            {
                title: 'Invoice Tracker',
                groups: getRoundProductGroups(['user_admin', 'finance_viewer']),
                url: '/finance/invoice-tracker/',
            },
            {
                title: 'Post Payments',
                groups: ['round_finance', 'round_planner'],
                shouldMatch: 'any',
                url: '/finance/payments/',
            },
        ],
    },
    {
        title: 'Admin',
        items: [
            {
                title: 'Clients',
                groups: getRoundProductGroups(['user_admin']),
                url: '/admin/client/',
            },
            {
                title: 'Client Users',
                groups: getRoundProductGroups(['round_super_admin']),
                url: '/admin/round-client-users/',
            },
            {
                title: 'Facebook Ad Accounts',
                groups: getRoundProductGroups(['user_admin', 'facebook_api_editor']),
                url: '/admin/facebook-ad-account/',
            },
            {
                title: 'Brands',
                groups: getRoundProductGroups(['advertising_editor']),
                url: '/admin/brand/',
            },
            {
                title: 'Releases',
                groups: getRoundProductGroups(['advertising_editor']),
                url: '/admin/release/',
            },
            {
                title: 'Currencies',
                groups: getRoundProductGroups(['user_admin']),
                url: '/admin/currency/',
            },
            {
                title: 'Table Validations',
                groups: getRoundProductGroups(['user_admin']),
                url: '/admin/table-validation/',
            },
            {
                title: 'Data Upload',
                groups: getRoundProductGroups(['user_admin']),
                url: '/admin/data-upload/',
            },
            {
                title: 'Data Download',
                groups: ['round_super_admin'],
                url: '/admin/data-download/',
            },
            {
                title: 'Outstanding Items',
                groups: getRoundProductGroups(['user_admin']),
                url: '/admin/outstanding-items/',
            },
            {
                title: 'Report Items',
                groups: getRoundProductGroups(['user_admin']),
                url: '/admin/report-items/',
            },
        ],
    },
];
