import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GenericDropdownOption } from '../../../../../App.types';
import Modal, { ModalContent, ModalTitle } from '../../../../../ui/General/Modal/Modal';
import DeletePostConfirmationModal from './DeletePostConfirmationModal';
import { FormikProps, FormikHelpers } from 'formik';
import styles from './InfluencerPlanItemModal.module.css';

import Button from '../../../../../ui/Buttons/Button/Button';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../../contexts/OptionsContext/OptionsContext';
import { mapApiErrorsToFormikErrors } from '../../../../../utility/utility';
import { showNotification } from '../../../../../helpers';
import { getXeroInvoice, getTiktokInfluencerUser, TiktokInfluencerPost } from '@round/api';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import { DropdownOptionWithSubLabel } from '../../../../../ui/DataEntry/Select/OptionWithSubLabel/OptionWithSubLabel';
import { TiktokCreatorsContext } from '../../contexts/TiktokCreatorsContext/TiktokCreatorsContext';
import TiktokInfluencerPostForm from './InfluencerPostForm/TiktokInfluencerPostForm';
import { TiktokInfluencerPostFormValues } from './InfluencerPostForm/TiktokInfluencerPostForm';

type UpdateTiktokInfluencerPostModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    onUpdated: () => void;
    onDeleted: () => void;
    post: TiktokInfluencerPost | null;
};

const UpdateTiktokInfluencerPostModal = ({
    post,
    isModalOpen,
    onUpdated,
    closeModal,
    onDeleted,
}: UpdateTiktokInfluencerPostModalProps) => {
    const [hasError, setHasError] = useState(false);

    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { currencies } = useNonNullContext(OptionsContext);
    const { updateTiktokInfluencerPost, deleteTiktokInfluencerPost } = useNonNullContext(TiktokCreatorsContext);

    const currencyOptions: GenericDropdownOption<number>[] = useMemo(
        () =>
            currencies.map((c) => ({
                value: c.id,
                label: c.name,
            })),
        [currencies]
    );

    useEffect(() => {
        if (isModalOpen) {
            return;
        }
        setInitialInfluencerUser(null);
        setInitialInvoiceOption(null);
    }, [isModalOpen]);

    const [initialInfluencerUser, setInitialInfluencerUser] = useState<GenericDropdownOption<number> | null>(null);

    useAbortableEffect(
        (signal) => {
            if (!post || typeof post.influencer_id !== 'number') {
                return;
            }

            getTiktokInfluencerUser(post.influencer_id, { signal })
                .then((influencer) => setInitialInfluencerUser({ value: influencer.id, label: influencer.username }))
                .catch(() => {});
        },
        [post, post?.influencer_id]
    );

    const [initialInvoiceOption, setInitialInvoiceOption] = useState<DropdownOptionWithSubLabel<number> | null>(null);
    useAbortableEffect(
        (signal) => {
            async function fetchInitialInvoice() {
                if (!post?.xero_invoice_id) {
                    return;
                }
                const response = await getXeroInvoice(post.xero_invoice_id, { signal });
                if (response.status === 404) {
                    return;
                }
                const invoice = response.data;
                setInitialInvoiceOption({
                    value: invoice.id,
                    label: invoice.invoice_number,
                    subLabel: invoice.reference,
                });
            }
            fetchInitialInvoice();
        },
        [post?.xero_invoice_id]
    );

    const initialValues: TiktokInfluencerPostFormValues = useMemo(
        () => ({
            currency_id: currencyOptions.find((o) => o.value === post?.currency_id),
            influencer_id: initialInfluencerUser,
            post_url: post?.post_url,
            cost: post?.cost,
            xero_invoice_id: initialInvoiceOption ?? null,
            is_carousel: post?.is_carousel,
        }),
        [
            currencyOptions,
            initialInfluencerUser,
            post?.post_url,
            post?.cost,
            post?.currency_id,
            initialInvoiceOption,
            post?.is_carousel,
        ]
    );

    const disabledValues = useMemo(
        () => ({
            currency_id: post?.payment_status === 'PAID',
            cost: post?.payment_status === 'PAID',
        }),
        [post?.payment_status]
    );

    const handleSubmit = useCallback(
        async (
            values: TiktokInfluencerPostFormValues,
            { setSubmitting, setErrors }: FormikHelpers<TiktokInfluencerPostFormValues>
        ) => {
            if (!post) {
                return;
            }

            try {
                setHasError(false);
                setSubmitting(true);
                const response = await updateTiktokInfluencerPost(post.id, {
                    influencer_id: values.influencer_id?.value,
                    currency_id: values.currency_id?.value,
                    cost: values.cost,
                    post_url: values.post_url,
                    xero_invoice_id: values.xero_invoice_id ? values.xero_invoice_id.value : null,
                    is_carousel: values.is_carousel,
                });

                if (response.status === 400) {
                    setErrors(mapApiErrorsToFormikErrors(response.data));
                    return;
                }

                onUpdated();
            } catch {
                setHasError(true);
            } finally {
                setSubmitting(false);
            }
        },
        [onUpdated, post, updateTiktokInfluencerPost]
    );

    const handleDelete = useCallback(async () => {
        if (!post) {
            return;
        }

        try {
            setDeleteLoading(true);
            await deleteTiktokInfluencerPost(post.id);
            onDeleted();
            showNotification('Post deleted', 'info');
        } catch {
            showNotification('Could not delete post', 'error');
        } finally {
            setDeleteLoading(false);
            setShowDeletePrompt(false);
        }
    }, [onDeleted, deleteTiktokInfluencerPost, post]);

    return (
        <>
            <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={closeModal} modalClassName={styles.modal}>
                <ModalTitle>Change details</ModalTitle>
                <ModalContent className={styles.content}>
                    {hasError && <div>Error writing data</div>}
                    <TiktokInfluencerPostForm
                        handleSubmit={handleSubmit}
                        initialValues={initialValues}
                        disabledValues={disabledValues}
                        renderActions={({
                            isSubmitting,
                            submitForm,
                            dirty,
                        }: FormikProps<TiktokInfluencerPostFormValues>) => (
                            <div className={styles.renderActions}>
                                <Button
                                    className={styles.submitButton}
                                    type="filled"
                                    color="black"
                                    disabled={!dirty}
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Save
                                </Button>
                                <Button
                                    className={styles.deleteButton}
                                    type="filled"
                                    color="negative"
                                    loading={deleteLoading}
                                    disabled={post?.payment_status === 'PAID'}
                                    onClick={() => setShowDeletePrompt(true)}
                                >
                                    Delete
                                </Button>
                            </div>
                        )}
                    />
                </ModalContent>
            </Modal>
            <DeletePostConfirmationModal
                handleDelete={handleDelete}
                isModalOpen={showDeletePrompt}
                deleteLoading={deleteLoading}
                onClose={() => setShowDeletePrompt(false)}
            />
        </>
    );
};

export default UpdateTiktokInfluencerPostModal;
