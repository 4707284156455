import React from 'react';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import TiktokInfluencerExplore from '../../../Modules/TikTok/containers/TiktokInfluencerExplore/TiktokInfluencerExplore';
import Card from '../../../ui/Card/Card';
import styles from './TiktokInfluencerExplorePage.module.css';

const TiktokInfluencerExplorePage = () => (
    <>
        <NavBar />
        <Card className={styles.container}>
            <TiktokInfluencerExplore />
        </Card>
    </>
);

export default TiktokInfluencerExplorePage;
