import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { BuyMethod } from './buyMethod.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    buyMethod: Model as ModelDefinition<BuyMethod>,
};

export const serializers = {
    buyMethod: makeSerializer<BuyMethod>([]),
};

export const factories = {
    buyMethod: createFactory<BuyMethod>({
        name(index: number) {
            return `name ${index}`;
        },
        channel(index: number) {
            return index;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const getBuyMethodsListUrl = '/api/advertising/viewsets/buy-method/';
    server.get(getBuyMethodsListUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('buyMethod'), {
            url: getBuyMethodsListUrl,
            serialize: (resource) => this.serialize(resource, 'buyMethod'),
            queryParams: request.queryParams,
        });
    });
}
