import { IconProps } from '../Icon.types';

const Tiktok = ({ width, height, className, onClick }: IconProps) => {
    return (
        <svg
            role="img"
            aria-label="Tiktok icon"
            onClick={onClick}
            className={className}
            width={width}
            height={height}
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.5714 0.5V13.7857C11.5714 16.1526 9.65265 18.0714 7.28571 18.0714C4.91878 18.0714 3 16.1526 3 13.7857C3 11.4188 4.91878 9.5 7.28571 9.5M18 7.35714C14.4496 7.35714 11.5714 4.47897 11.5714 0.928571"
                stroke="#727F8F"
                strokeWidth="3"
            />
        </svg>
    );
};

export default Tiktok;
