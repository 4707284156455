import styles from './StatusCell.module.css';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';
import PostUrlPopout from '../PostUrlPopout/PostUrlPopout';
import { creatorbase } from '@round/api';
import { useFeatureFlags } from '../../../../utility/featureFlags/FeatureFlagsContext';

type Props = {
    post: creatorbase.Post;
    updatePost?: (data: Partial<creatorbase.PatchPostData>) => ReturnType<typeof creatorbase.patchPost>;
};

const StatusCell = ({ post, updatePost }: Props) => {
    const featureFlags = useFeatureFlags();
    //eventually we will use status from the API. For now, we will use the post_url to determine the status
    //so status doesn't break when post_url becomes optional.
    const hardCodedStatus = post.post_url ? 'live' : 'planned';
    const status = featureFlags.isEnabled('cb-release-nov-24') ? post.status : hardCodedStatus;

    const adCode = post.platform !== 'youtube' ? post[`${post.platform}_details`]?.ad_code : '';

    return (
        <div className={styles.container}>
            <div className={cn(styles.statusBadge, styles[status])}>
                <span>{capitalize(status)}</span>
            </div>

            <PostUrlPopout postUrl={post.post_url} adCode={adCode} updatePost={updatePost} platform={post.platform} />
        </div>
    );
};

export default StatusCell;
