import React, { FunctionComponent, useMemo, useState } from 'react';
import styles from './CreateInfluencerModal.module.css';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { GenericDropdownOption } from '../../../../App.types';
import TextArea from '../../../../SharedComponents/Forms/TextArea/TextArea';
import useNonNullContext from '../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../contexts/OptionsContext/OptionsContext';
import { Button, FormField, ModalFooter, ModalTitle, ModalContent, Select, Modal, Label } from '@round/ui-kit';
import { useInfluencerTags } from '../../../../Hooks/useInfluencerTags';
import { StylesConfig, ValueType } from 'react-select';
import * as Yup from 'yup';
import { InstagramInfluencerUserApiBody, postInstagramInfluencerUser } from '@round/api';
import { mapApiErrorsToFormikErrors } from '../../../../utility/utility';
import { ChangeValueTypes } from '../../../../utility/utility.types';
import { useContentTags } from 'contexts/ContentTagsContext';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { mapContentTagsToOptions } from 'helpers';

type CreateInstagramInfluencerUserFormValues = ChangeValueTypes<
    Omit<InstagramInfluencerUserApiBody, 'content_tags' | 'tags'> & {
        locations: ValueType<GenericDropdownOption<number>, true>;
        content_tags: ValueType<GenericDropdownOption<number>, true>;
    },
    'reels_currency' | 'story_currency' | 'feed_currency',
    GenericDropdownOption<number> | null
>;

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        borderColor: '#C2CFE0',
        fontSize: '0.75rem',
        borderRadius: '0.1875rem',
        height: '2.55rem',
    }),
    placeholder: (base) => ({
        ...base,
        color: '#90A0B7',
        fontSize: '0.8125rem',
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '0 1rem',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: '0.5rem',
    }),
};

const CreateInfluencerModal: FunctionComponent<{
    closeModal: () => void;
    isModalOpen: boolean;
}> = ({ closeModal, isModalOpen }) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const currencyOptions: GenericDropdownOption<number>[] = currencies.map((curr) => ({
        label: curr.name,
        value: curr.id,
    }));

    const { influencerTags } = useInfluencerTags();
    const locationTagOptions = useMemo(
        () =>
            influencerTags
                .filter((tag) => tag.type === 'LOCATION')
                .map((tag) => ({
                    label: tag.name,
                    value: tag.id,
                })),
        [influencerTags]
    );

    const { tags: contentTags, init, isInitialized } = useContentTags();

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                init({ signal });
            }
        },
        [isInitialized, init]
    );

    const [statusMessage, setStatusMessage] = useState<string>();
    const initialValues: CreateInstagramInfluencerUserFormValues = useMemo(
        () => ({
            username: '',
            notes: '',
            content_tags: [],
            locations: [],
            reels_cost: null,
            reels_currency: null,
            story_cost: null,
            story_currency: null,
            feed_cost: null,
            feed_currency: null,
            contact_details: '',
            paypal_email: '',
        }),
        []
    );

    const validationSchema = Yup.object({
        username: Yup.string().required('Username is required'),
        content_tags: Yup.array().min(1, 'Content Tags field is required').typeError('Content Tags field is required'),
        locations: Yup.array().min(1, 'Location field is required').typeError('Location field is required'),
        contact_details: Yup.string().required('Contact details is required'),
        reels_currency: Yup.object().required('Reels Currency is required').typeError('Reels Currency is required'),
        reels_cost: Yup.number().required('Reels Cost is required').typeError('you must specify a cost number'),
    });

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={() => {
                setStatusMessage(undefined);
                closeModal();
            }}
            closeOnOverlayClick
            className={styles.modal}
        >
            <ModalTitle className={styles.title}>New Influencer</ModalTitle>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (
                    values: CreateInstagramInfluencerUserFormValues,
                    { setSubmitting, setErrors }: FormikHelpers<CreateInstagramInfluencerUserFormValues>
                ) => {
                    setSubmitting(true);
                    setStatusMessage(undefined);
                    try {
                        const response = await postInstagramInfluencerUser({
                            ...values,
                            content_tags: (values.content_tags ?? []).map((t) => t.value),
                            tags: (values.locations ?? []).map((t) => t.value),
                            reels_currency: values.reels_currency?.value ?? null,
                            feed_currency: values.feed_currency?.value ?? null,
                            story_currency: values.story_currency?.value ?? null,
                            paypal_email: values.paypal_email ?? null,
                        });
                        if (response.status === 201) {
                            setStatusMessage(`Successfully added influencer: ${response.data.username}`);
                        } else {
                            setStatusMessage(`Error adding new account ${response.data.username}`);
                            setErrors(mapApiErrorsToFormikErrors(response.data));
                        }
                    } catch {
                        //no op
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting, submitForm, isValid }) => (
                    <>
                        <ModalContent>
                            {statusMessage !== undefined && <div className={styles.status}>{statusMessage}</div>}
                            <Form className={styles.form}>
                                <FormField>
                                    <Label htmlFor="username">Username</Label>
                                    <Field id="username" name="username" placeholder="Enter username" />
                                    <ErrorMessage name="username">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </FormField>

                                <FormField>
                                    <Label htmlFor="content_tags">Content Tags</Label>
                                    <Field id="content_tags" name="content_tags">
                                        {(props: FieldProps) => (
                                            <Select
                                                styles={selectStyles}
                                                isMulti
                                                placeholder="Select content tags"
                                                options={mapContentTagsToOptions(contentTags)}
                                                value={props.field.value}
                                                onChange={(value) => {
                                                    props.form.setFieldValue(props.field.name, value);
                                                }}
                                                onBlur={() => {
                                                    props.form.setFieldTouched(props.field.name, true);
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="content_tags">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </FormField>

                                <FormField>
                                    <Label htmlFor="locations">Location</Label>
                                    <Field id="locations" name="locations">
                                        {(props: FieldProps) => (
                                            <Select
                                                styles={selectStyles}
                                                isMulti
                                                placeholder="Select location"
                                                options={locationTagOptions}
                                                value={props.field.value}
                                                onChange={(value) => {
                                                    props.form.setFieldValue('locations', value);
                                                }}
                                                onBlur={() => {
                                                    props.form.setFieldTouched(props.field.name, true);
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="locations">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </FormField>

                                <FormField>
                                    <Label htmlFor="contact_details">Contact details</Label>
                                    <Field
                                        name="contact_details"
                                        id="contact_details"
                                        placeholder="Enter contact details"
                                    />
                                    <ErrorMessage name="contact_details">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </FormField>

                                <FormField>
                                    <Label htmlFor="paypal_email">PayPal Email Address</Label>
                                    <Field
                                        name="paypal_email"
                                        id="paypal_email"
                                        placeholder="Enter paypal email address"
                                    />
                                    <ErrorMessage name="paypal_email">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </FormField>

                                <FormField>
                                    <Label htmlFor="notes">Notes</Label>
                                    <TextArea name="notes" placeholder="Enter notes" />
                                </FormField>

                                <div className={styles.section}>
                                    <Label htmlFor="reels_cost">Reels Cost</Label>
                                    <div className={styles.row}>
                                        <FormField>
                                            <Field id="reels_currency" name="reels_currency">
                                                {(props: FieldProps) => (
                                                    <Select
                                                        styles={selectStyles}
                                                        placeholder="currency"
                                                        options={currencyOptions}
                                                        value={props.field.value}
                                                        onChange={(value) => {
                                                            props.form.setFieldValue('reels_currency', value);
                                                        }}
                                                        onBlur={() => {
                                                            props.form.setFieldTouched(props.field.name, true);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </FormField>

                                        <FormField>
                                            <Field
                                                id="reels_cost"
                                                name="reels_cost"
                                                placeholder="Enter cost"
                                                type="number"
                                            />
                                        </FormField>
                                    </div>
                                    <ErrorMessage name="reels_cost">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                    <ErrorMessage name="reels_currency">
                                        {(msg) => <span className={styles.error}>{msg}</span>}
                                    </ErrorMessage>
                                </div>

                                <div className={styles.section}>
                                    <Label htmlFor="story_cost">Story Cost</Label>
                                    <div className={styles.row}>
                                        <FormField>
                                            <Field id="story_currency" name="story_currency">
                                                {(props: FieldProps) => (
                                                    <Select
                                                        styles={selectStyles}
                                                        placeholder="currency"
                                                        options={currencyOptions}
                                                        value={props.field.value}
                                                        onChange={(value) => {
                                                            props.form.setFieldValue('story_currency', value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </FormField>

                                        <FormField>
                                            <Field
                                                id="story_cost"
                                                name="story_cost"
                                                placeholder="Enter cost"
                                                type="number"
                                            />
                                        </FormField>
                                    </div>
                                </div>

                                <div className={styles.section}>
                                    <Label htmlFor="feed_cost">Feed Cost</Label>
                                    <div className={styles.row}>
                                        <FormField>
                                            <Field id="feed_currency" name="feed_currency">
                                                {(props: FieldProps) => (
                                                    <Select
                                                        styles={selectStyles}
                                                        placeholder="currency"
                                                        options={currencyOptions}
                                                        value={props.field.value}
                                                        onChange={(value) => {
                                                            props.form.setFieldValue('feed_currency', value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </FormField>

                                        <FormField>
                                            <Field
                                                id="feed_cost"
                                                name="feed_cost"
                                                placeholder="Enter cost"
                                                type="number"
                                            />
                                        </FormField>
                                    </div>
                                </div>
                            </Form>
                        </ModalContent>
                        <ModalFooter>
                            <Button
                                type="filled"
                                color="black"
                                loading={isSubmitting}
                                onClick={submitForm}
                                disabled={!isValid}
                            >
                                Add influencer
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default CreateInfluencerModal;
