import React, { FunctionComponent, useState } from 'react';
import Select, { StylesConfig, ValueType } from 'react-select';
import { FacebookTargetingLocationSearchOption, GenericDropdownOption } from '../../../../../../../../App.types';
import {
    convertDistance,
    getMinMax,
    isFacebookTargetingLocationOptionsWithRadius,
    isRadiusValid,
    radiusUnitOptions,
} from './helpers';

import { ReactComponent as TrashIcon } from '../../../../../../../../assets/TrashIcon.svg';
import CheckCircleIcon from '../../../../../../../../SharedComponents/svg/Icons/CheckCircleIcon';
import XCircleIcon from '../../../../../../../../SharedComponents/svg/Icons/XCircleIcon';
import styles from './FacebookTargetingLocations.module.css';

const radiusSelectStyleConfig: StylesConfig = {
    control: (provided, { isFocused }) => ({
        ...provided,
        border: 'none',
        boxShadow: 'none',
        backgroundColor: isFocused ? '#eeeeee' : '',
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        fontSize: '0.75rem',
        fontWeight: 500,
        width: '2.5rem',
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    input: (provided) => ({
        ...provided,
        marginTop: '0',
        paddingTop: '0',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
    }),
};

type Props = {
    location: FacebookTargetingLocationSearchOption;
    onDelete: (location: FacebookTargetingLocationSearchOption) => void;
    onUpdate: (location: FacebookTargetingLocationSearchOption) => void;
};

const FacebookLocationListItem: FunctionComponent<Props> = ({ location, onDelete, onUpdate }) => {
    const [errorMessage, setErrorMessage] = useState('');

    const renderIcon = (isIncluded: boolean) => {
        if (isIncluded) {
            return <CheckCircleIcon height={14} width={14} strokeColor="#48D98A" />;
        }

        return <XCircleIcon height={14} width={14} strokeColor="red" />;
    };

    const onUnitChange = (unit: ValueType<GenericDropdownOption<'kilometer' | 'mile'>, false>) => {
        if (unit && isFacebookTargetingLocationOptionsWithRadius(location)) {
            setErrorMessage('');
            const distance = convertDistance(location.radius.distance, unit.value);
            onUpdate({ ...location, radius: { distance, unit } });
        }
    };

    const onDistanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isFacebookTargetingLocationOptionsWithRadius(location)) {
            return;
        }

        setErrorMessage('');
        const radius = { distance: +event.target.value, unit: location.radius.unit };
        if (!isRadiusValid(radius)) {
            const { min, max } = getMinMax(location.radius.unit.value);
            setErrorMessage(`Distance should be between ${min} and ${max} ${location.radius.unit.label}`);
        }

        onUpdate({ ...location, radius });
    };

    return (
        <div className={styles.locationContainer}>
            <div className={styles.row}>
                {renderIcon(location.isIncluded)}
                <p className={styles.locationLabel}>
                    {location.label} <span className={styles.locationType}>({location.typeLabel})</span>
                </p>

                {isFacebookTargetingLocationOptionsWithRadius(location) && (
                    <div className={styles.row}>
                        <input
                            type="number"
                            value={location.radius.distance.toString(10)}
                            className={styles.radiusFormDistanceInput}
                            onInput={onDistanceChange}
                        />
                        <Select
                            className={styles.select}
                            styles={radiusSelectStyleConfig}
                            value={location.radius.unit}
                            options={radiusUnitOptions}
                            onChange={onUnitChange}
                            menuPortalTarget={document.body}
                            components={{ IndicatorSeparator: () => null }}
                        />
                    </div>
                )}

                <button onClick={() => onDelete(location)} className={styles.locationDeleteIcon}>
                    <TrashIcon />
                </button>
            </div>

            {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
        </div>
    );
};

export default FacebookLocationListItem;
