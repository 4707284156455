import React, { HTMLProps, useState } from 'react';
import { ReactComponent as GalleryIcon } from '../../../assets/icons/Gallery.svg';
import styles from './Image.module.css';
import cn from 'classnames';
import Skeleton from '../../Indicators/Skeleton/Skeleton';

export type ImageProps = HTMLProps<HTMLImageElement> & {
    Fallback?: (() => JSX.Element) | React.FC<React.SVGProps<SVGSVGElement>>;
    loading?: boolean;
    skeletonClassName?: string;
    // TODO: Resolve the issue with image skeleton circle by default
    skeletonCircle?: boolean;
};

const Image = ({
    loading,
    alt,
    src,
    crossOrigin,
    className,
    Fallback = GalleryIcon,
    skeletonClassName,
    skeletonCircle = true,
    ...props
}: ImageProps) => {
    const [hasError, setHasError] = useState(false);

    if (loading) {
        return (
            <div className={className}>
                <Skeleton circle={skeletonCircle} className={cn(styles.skeleton, skeletonClassName)} />
            </div>
        );
    }

    if (!src || hasError) {
        return (
            <div className={cn(styles.container, className)}>
                <Fallback />
            </div>
        );
    }

    return (
        <img
            src={src}
            alt={alt}
            onError={() => {
                setHasError(true);
            }}
            className={cn(styles.image, className)}
            {...props}
        />
    );
};

export default Image;
