import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { PostStats } from './postStats.types';

export const models = {
    postStats: Model as ModelDefinition<PostStats>,
};

export const serializers = {
    postStats: makeSerializer<PostStats>([]),
};

export const factories = {
    postStats: createFactory<PostStats>({
        author_follower_count: null,
        author_username: (index: number) => `username ${index}`,
        author_name: (index: number) => `author_name ${index}`,
        create_time: (index: number) => index,
        caption: 'caption',
        location: null,
        play_count: 0,
        save_count: 0,
        share_count: 0,
        tiktok_id: (index: number) => `tiktok-id-${index}`,
    }),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/tiktok/post-stats/';
    server.post(rootUrl, function (this: RouteHandlerContext, schema, request) {
        const postStats = schema.all('postStats');
        return buildPaginatedResponse(postStats, {
            url: rootUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'postStats'),
        });
    });
}
