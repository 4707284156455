import React from 'react';
import { GenericDropdownOption } from '../../../../../../../App.types';
import styles from './MediaPlanItemOption.module.css';

type MediaPlanItemOptionProps = {
    option: GenericDropdownOption<number>;
    selected: boolean;
    onSelectedChange: () => void;
};

const MediaPlanItemOption = ({ option, selected, onSelectedChange }: MediaPlanItemOptionProps) => {
    return (
        <div className={styles.container} onClick={onSelectedChange}>
            <input className={styles.checkbox} type="checkbox" checked={selected} readOnly />
            <p className={styles.label}>
                {option.label}
                <br />
                <span className={styles.id}>ID: {option.value}</span>
            </p>
        </div>
    );
};

export default MediaPlanItemOption;
