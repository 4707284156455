import { AppServer, RouteHandlerContext } from '../../mirage';
import { belongsTo, Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Format } from './format.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    format: Model.extend({
        channel: belongsTo('channel'),
    }) as ModelDefinition<Format>,
};

export const serializers = {
    format: makeSerializer<Format>(['channel']),
};

export const factories = {
    format: createFactory<Format>({
        code(index: number) {
            return `code ${index}`;
        },
        name(index: number) {
            return `name ${index}`;
        },
        afterCreate(format, s) {
            const server: AppServer = s;
            if (!format.channel) {
                format.update({ channel: server.create('channel') });
            }
        },
    }),
};

export function handleFormatRequests(server: AppServer) {
    server.get('/api/advertising/viewsets/format/', function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('format'), {
            url: '/api/advertising/viewsets/format/',
            serialize: (resource) => this.serialize(resource, 'format'),
            queryParams: request.queryParams,
        });
    });
}
