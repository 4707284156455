import React from 'react';
import { DebounceInput, DebounceInputProps } from 'react-debounce-input';

export type SearchProps = DebounceInputProps<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>;

const Search = ({ debounceTimeout = 750, placeholder = 'Search', ...props }: SearchProps) => (
    <DebounceInput debounceTimeout={debounceTimeout} placeholder={placeholder} {...props} />
);

export default Search;
