import { creatorbase, getTiktokAudioAssets } from '@round/api';
import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { SongDataContext } from '../../SongDataContext';
import moment from 'moment';

const today = moment();
const twoWeeksAgo = moment().subtract(14, 'days');

export default function useTiktokTopAudios() {
    const songData = useNonNullContextSelector(SongDataContext, (value) => value[0].song);
    const state = useNonNullContextSelector(SongDataContext, (value) => value[0].tiktok.topAudios);
    const dispatch = useNonNullContextSelector(SongDataContext, (value) => value[1]);

    const fetchData = useCallback(
        async (params: Omit<creatorbase.GetTiktokAudiosParams, 'song_id'>, requestInit?: RequestInit) => {
            const songId = songData.data?.song.id;
            if (typeof songId !== 'number') {
                return;
            }

            dispatch({ type: 'loadTiktokTopAudios' });

            try {
                const response = await creatorbase.getTiktokAudios(
                    {
                        ...params,
                        song_id: songId,
                    },
                    requestInit
                );

                if (response.status !== 200) {
                    dispatch({ type: 'tiktokTopAudiosErrorLoading', payload: 'Could not load tiktok audios' });
                    return response;
                }

                const audioIds = response.data.results.map((audio) => audio.id);

                if (!audioIds.length) {
                    dispatch({
                        type: 'tiktokTopAudiosSuccess',
                        payload: { audios: response.data, timeSeries: null, assets: [] },
                    });
                    return response;
                }

                const [timeSeriesResponse, assetsResponse] = await Promise.allSettled([
                    creatorbase.postTimeSeries(
                        {
                            ids: audioIds,
                            type: 'tiktok_audio',
                            fields: ['posts_daily_change'],
                            start_date: twoWeeksAgo.format('YYYY-MM-DD'),
                            end_date: today.format('YYYY-MM-DD'),
                        },
                        requestInit?.signal ?? undefined
                    ),
                    getTiktokAudioAssets(audioIds, requestInit),
                ]);

                const timeSeries =
                    timeSeriesResponse.status === 'fulfilled' && timeSeriesResponse.value.status === 200
                        ? timeSeriesResponse.value.data
                        : null;
                const assets = assetsResponse.status === 'fulfilled' ? assetsResponse.value : [];

                dispatch({ type: 'tiktokTopAudiosSuccess', payload: { audios: response.data, timeSeries, assets } });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'tiktokTopAudiosIdle' });
                    throw e;
                }

                dispatch({
                    type: 'tiktokTopAudiosErrorLoading',
                    payload: e instanceof Error ? e.message : 'Could not load tiktok audios',
                });
                throw e;
            }
        },
        [dispatch, songData.data?.song.id]
    );

    const reset = useCallback(() => {
        dispatch({ type: 'tiktokTopAudiosIdle' });
    }, [dispatch]);

    return {
        ...state,
        fetchData,
        reset,
    };
}
