import { microwave } from '@round/api';
import { showNotification } from 'helpers';
import { useState } from 'react';
import TableMoneyInput from 'ui-new/TableComponents/TableMoneyInput/TableMoneyInput';
import { mapApiErrorsToFormikErrors } from 'utility/utility';

type Props = {
    cost: number;
    currencyId: number;
    updatePayment: ({
        cost,
        currency_id,
    }: Pick<microwave.PatchPaymentRequestBody, 'cost' | 'currency_id'>) => ReturnType<
        typeof microwave.patchPaymentRequest
    >;
    isReadonly?: boolean;
};

const CostCurrencyCell = ({ cost, currencyId, updatePayment, isReadonly = false }: Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isCostUpdateLoading, setIsCostUpdateLoading] = useState(false);

    return (
        <TableMoneyInput
            key={`${currencyId}-${cost}`}
            isReadonly={isCostUpdateLoading || isReadonly}
            value={{
                currency: currencyId,
                amount: cost,
            }}
            onChange={async ({ amount, currency }) => {
                setIsCostUpdateLoading(true);
                try {
                    const response = await updatePayment({
                        cost: amount,
                        currency_id: currency ?? currencyId,
                    });

                    if (response.status !== 200) {
                        const errorMessage =
                            response.status === 400
                                ? Object.values(mapApiErrorsToFormikErrors(response.data)).toString()
                                : response.data.detail;
                        showNotification(errorMessage, 'error');
                        return;
                    }

                    showNotification('Cost updated', 'info');
                } catch {
                    showNotification('Could not update cost', 'error');
                } finally {
                    setIsCostUpdateLoading(false);
                }
            }}
            isEditing={isEditing}
            onEditingChange={setIsEditing}
        />
    );
};

export default CostCurrencyCell;
