import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import useNonNullContext from '../../Hooks/useNonNullContext';
import { Currency } from '@round/api';

export type CurrencyOption = {
    value: number;
    label: string;
};

export const mapCurrencyToOption = (currency: Currency): CurrencyOption => ({
    value: currency.id,
    label: currency.name,
});

export function useCurrencies() {
    const { currencies } = useNonNullContext(OptionsContext);

    return {
        currencies,
        options: currencies.map(mapCurrencyToOption),
    };
}
