import React, { useCallback, useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import { StylesConfig } from 'react-select';
import {
    FacebookPositions,
    FacebookTargetingAudienceSelectionValues,
    FacebookTargetingLocationSearchOption,
    FacebookTargetingSegmentSpecs,
} from '../../../../../../App.types';
import { CustomAudienceOption, FacebookTargetingTemplate } from '../../../types/Facebook.types';
import {
    mapFacebookTargetingLocationsToBody,
    mapFacebookTargetingLocationsToOptions,
} from '../FacebookTargetingModal/components/FacebookTargetingLocations/helpers';
import {
    mapSelectionOptionsToSpecs,
    mapSpecsToOptions,
} from '../FacebookTargetingModal/components/FacebookDetailedTargeting/helpers';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { MediaPlanOptionsContext } from '../../../contexts/MediaPlanOptionsContext/MediaPlanOptionsContext';
import { deleteFacebookTargetingTemplate } from '../../../api/Facebook.api';
import { fetchWithToken, showNotification } from '../../../../../../helpers';
import useAbortableEffect from '../../../../../../Hooks/useAbortableEffect';
import MultiSelect from '../../../../../../SharedComponents/Forms/MultiSelect/MultiSelect';
import TextArea from '../../../../../../SharedComponents/Forms/TextArea/TextArea';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import Button from '../../../../../../ui/Buttons/Button/Button';
import FormField from '../../../../../../ui/DataEntry/FormField/FormField';
import styles from './FacebookTargetingTemplateModal.module.css';
import Label from '../../../../../../ui/DataEntry/Label/Label';
import FacebookTargetingCustomAudience from '../FacebookTargetingModal/components/FacebookTargetingCustomAudience/FacebookTargetingCustomAudience';
import FacebookTargetingLocationsInput from '../FacebookTargetingModal/components/FacebookTargetingLocations/FacebookTargetingLocationsInput';
import FacebookDetailedTargetingInput from '../FacebookTargetingModal/components/FacebookDetailedTargeting/FacebookDetailedTargetingInput';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';

const multiSelectStyles: StylesConfig = {
    control: (provided) => ({
        ...provided,
        borderColor: '#C2CFE0',
        fontSize: '0.75rem',
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingTop: '0.3rem',
        paddingBottom: '0.3rem',
    }),
    multiValue: (provided) => ({
        ...provided,
        color: '#192A3E',
        backgroundColor: '#E7EEF6',
    }),
    input: (provided) => ({
        ...provided,
        marginTop: '0',
        paddingTop: '0',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#90A0B7',
        fontSize: '0.75rem',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 1000,
    }),
};

export type TargetingFormProps = {
    templateName: string;
    targetingName: string;
    minAge: number;
    maxAge: number;
    location: string;
    isMale: boolean;
    isFemale: boolean;
    remarketingAudience: string;
    keywords: string;
    devicePlatforms: number[];
    publisherPlatforms: number[];
    facebookPositions: number[];
    instagramPositions: number[];
    messengerPositions: number[];
    audienceNetworkPositions: number[];
    geoLocations: FacebookTargetingLocationSearchOption[];
    detailedTargeting: FacebookTargetingAudienceSelectionValues;
    notes: string;
    customAudiences: {
        included: CustomAudienceOption[];
        excluded: CustomAudienceOption[];
    };
};

export function getTemplateToBody(clientId: number) {
    return (values: TargetingFormProps) => ({
        client: clientId,
        template_name: values.templateName,
        targeting_name: values.targetingName,
        min_age: values.minAge,
        max_age: values.maxAge,
        location: values.location,
        geo_locations: mapFacebookTargetingLocationsToBody(
            values.geoLocations.filter((location) => location.isIncluded)
        ),
        excluded_geo_locations: mapFacebookTargetingLocationsToBody(
            values.geoLocations.filter((location) => !location.isIncluded)
        ),
        is_male: values.isMale,
        is_female: values.isFemale,
        remarketing_audience: values.remarketingAudience,
        keywords: values.keywords,
        device_platforms: values.devicePlatforms,
        publisher_platforms: values.publisherPlatforms,
        facebook_positions: values.facebookPositions,
        instagram_positions: values.instagramPositions,
        messenger_positions: values.messengerPositions,
        audience_network_positions: values.audienceNetworkPositions,
        notes: values.notes,
        flexible_spec: [
            mapSelectionOptionsToSpecs(values.detailedTargeting.include),
            mapSelectionOptionsToSpecs(values.detailedTargeting.narrow),
        ].filter((selection): selection is FacebookTargetingSegmentSpecs => !!selection),
        exclusions: mapSelectionOptionsToSpecs(values.detailedTargeting.exclude) ?? {},
        custom_audiences: values.customAudiences.included.map((a) => a.value),
        excluded_custom_audiences: values.customAudiences.excluded.map((a) => a.value),
    });
}

type ModalProps = {
    closeModal: () => void;
    isModalOpen: boolean;
    targetingTemplate: FacebookTargetingTemplate;
};

export default function FacebookTargetingTemplateModal({ closeModal, isModalOpen, targetingTemplate }: ModalProps) {
    const featureFlags = useFeatureFlags();

    const [hasError, setHasError] = useState(false);
    const [showDiscardChangesPrompt, setShowDiscardChangesPrompt] = useState(false);
    const {
        facebookDeviceOptions,
        facebookPublisherPlatformOptions,
        facebookPositionOptions,
        instagramPositionOptions,
        messengerPositionOptions,
        audienceNetworkPositionOptions,
    } = useNonNullContext(MediaPlanOptionsContext);

    const mapToTargetingFormProps = useCallback(
        (targeting: FacebookTargetingTemplate): TargetingFormProps => ({
            templateName: targeting.template_name,
            targetingName: targeting.targeting_name,
            minAge: targeting.min_age,
            maxAge: targeting.max_age,
            location: targeting.location,
            isMale: targeting.is_male,
            isFemale: targeting.is_female,
            remarketingAudience: targeting.remarketing_audience,
            keywords: targeting.keywords,
            devicePlatforms: targeting.device_platforms,
            publisherPlatforms: targeting.publisher_platforms,
            facebookPositions: targeting.facebook_positions ?? [],
            instagramPositions: targeting.instagram_positions ?? [],
            messengerPositions: targeting.messenger_positions ?? [],
            audienceNetworkPositions: targeting.audience_network_positions ?? [],
            geoLocations: [],
            detailedTargeting: {
                include: Array.isArray(targeting.flexible_spec)
                    ? mapSpecsToOptions(targeting.flexible_spec.length > 0 ? targeting.flexible_spec[0] : undefined)
                    : [],
                exclude: mapSpecsToOptions(targeting.exclusions),
                narrow: Array.isArray(targeting.flexible_spec)
                    ? mapSpecsToOptions(targeting.flexible_spec.length > 1 ? targeting.flexible_spec[1] : undefined)
                    : [],
            },
            notes: targeting.notes ?? '',
            customAudiences: {
                included: targeting.custom_audiences.map<CustomAudienceOption>((audience) => ({
                    value: audience.id,
                    description: audience.description,
                    subtype: audience.subtype,
                    label: audience.name,
                })),
                excluded: targeting.excluded_custom_audiences.map<CustomAudienceOption>((audience) => ({
                    value: audience.id,
                    description: audience.description,
                    subtype: audience.subtype,
                    label: audience.name,
                })),
            },
        }),
        []
    );

    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState<TargetingFormProps>(mapToTargetingFormProps(targetingTemplate));
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const onConfirmDelete = useCallback(async () => {
        try {
            setDeleteLoading(true);
            await deleteFacebookTargetingTemplate(targetingTemplate.id);
            closeModal();
            showNotification('Targeting template deleted', 'info');
        } catch {
            showNotification('Could not delete targeting template', 'error');
        } finally {
            setDeleteLoading(false);
        }
    }, [closeModal, targetingTemplate.id]);

    const positionEnabled = useCallback(
        (position: FacebookPositions, selectedPublisherPlatforms: number[]) => {
            const positionsPublisherPlatform = facebookPublisherPlatformOptions.find(
                (platform) => platform.value === position
            );
            return positionsPublisherPlatform && selectedPublisherPlatforms.includes(positionsPublisherPlatform.id);
        },
        [facebookPublisherPlatformOptions]
    );

    const getConditionalPositionValidation = (position: FacebookPositions) => {
        return (publisherPlatforms: number[]) => {
            if (positionEnabled(position, publisherPlatforms)) {
                return Yup.array().of(Yup.number()).min(1, 'Field is required');
            }

            return Yup.array();
        };
    };

    const validationSchema = Yup.object({
        devicePlatforms: Yup.array().of(Yup.number()).min(1, 'Field is required'),
        publisherPlatforms: Yup.array().of(Yup.number()).min(1, 'Field is required'),
        facebookPositions: Yup.array().when('publisherPlatforms', getConditionalPositionValidation('facebook')),
        instagramPositions: Yup.array().when('publisherPlatforms', getConditionalPositionValidation('instagram')),
        messengerPositions: Yup.array().when('publisherPlatforms', getConditionalPositionValidation('messenger')),
        audienceNetworkPositions: Yup.array().when(
            'publisherPlatforms',
            getConditionalPositionValidation('audience_network')
        ),
    });

    const handleSubmit = useCallback(
        async (values: TargetingFormProps, { setSubmitting, resetForm }: FormikHelpers<TargetingFormProps>) => {
            setSubmitting(true);
            setHasError(false);
            const response = await fetchWithToken(
                `/api/facebook/viewsets/facebook-campaign-targeting-template/${targetingTemplate.id}/`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(
                        getTemplateToBody(targetingTemplate.client)({
                            ...values,
                            messengerPositions: positionEnabled('messenger', values.publisherPlatforms)
                                ? values.messengerPositions
                                : [],
                            audienceNetworkPositions: positionEnabled('audience_network', values.publisherPlatforms)
                                ? values.audienceNetworkPositions
                                : [],
                            instagramPositions: positionEnabled('instagram', values.publisherPlatforms)
                                ? values.instagramPositions
                                : [],
                            facebookPositions: positionEnabled('facebook', values.publisherPlatforms)
                                ? values.facebookPositions
                                : [],
                        })
                    ),
                }
            );

            if (response.ok) {
                showNotification('Targeting template updated', 'info');
                setSubmitting(false);
                resetForm({ values });
            } else {
                showNotification('Targeting update failed', 'error');
                setHasError(true);
            }
        },
        [positionEnabled, targetingTemplate.client, targetingTemplate.id]
    );

    useEffect(() => {
        setInitialValues(mapToTargetingFormProps(targetingTemplate));
    }, [mapToTargetingFormProps, targetingTemplate]);

    useAbortableEffect(
        (signal) => {
            const mapLocations = async () => {
                const included = await mapFacebookTargetingLocationsToOptions(targetingTemplate.geo_locations, {
                    signal,
                });
                const excluded = await mapFacebookTargetingLocationsToOptions(
                    targetingTemplate.excluded_geo_locations,
                    { signal }
                );

                const geoLocations = [
                    ...included.map((l) => ({ ...l, isIncluded: true })),
                    ...excluded.map((l) => ({ ...l, isIncluded: false })),
                ].sort((a, b) => {
                    if (a.label < b.label) return -1;
                    if (a.label > b.label) return 1;
                    return 0;
                });
                setInitialValues((initialValues) => ({ ...initialValues, geoLocations }));
            };

            setLoading(true);
            mapLocations().then(() => setLoading(false));
        },
        [targetingTemplate.excluded_geo_locations, targetingTemplate.geo_locations]
    );

    return (
        <>
            <Modal
                className={styles.deleteTargetingTemplate}
                closeOnOverlayClick
                isOpen={showConfirmDelete}
                onClose={() => setShowConfirmDelete(false)}
            >
                <ModalTitle>Delete "{targetingTemplate.template_name}" template?</ModalTitle>
                <ModalContent>
                    <p>
                        You're going to delete "{targetingTemplate.template_name}" template. This operation cannot be
                        undone
                    </p>
                </ModalContent>
                <ModalFooter align="center">
                    <Button type="bordered" color="negative" loading={deleteLoading} onClick={onConfirmDelete}>
                        Delete template
                    </Button>
                </ModalFooter>
            </Modal>

            <Formik
                enableReinitialize
                validateOnMount
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ dirty, values, isSubmitting, resetForm, submitForm }) => {
                    const selectedPublisherPlatforms = values.publisherPlatforms;
                    return (
                        <>
                            <Modal
                                closeOnOverlayClick
                                isOpen={showDiscardChangesPrompt}
                                onClose={() => setShowDiscardChangesPrompt(false)}
                            >
                                <ModalTitle>Discard changes?</ModalTitle>
                                <ModalContent className={styles.discardChangesContent}>
                                    <p>This operation cannot be undone</p>
                                </ModalContent>
                                <ModalFooter>
                                    <Button
                                        type="filled"
                                        color="black"
                                        onClick={() => {
                                            resetForm();
                                            setShowDiscardChangesPrompt(false);
                                            closeModal();
                                        }}
                                    >
                                        Discard
                                    </Button>
                                </ModalFooter>
                            </Modal>

                            <Modal
                                closeOnOverlayClick
                                isOpen={isModalOpen}
                                onClose={() => {
                                    if (!dirty) {
                                        closeModal();
                                        return;
                                    }

                                    setShowDiscardChangesPrompt(true);
                                }}
                            >
                                <ModalTitle>Edit Facebook Targeting Template</ModalTitle>
                                <ModalContent loading={loading}>
                                    <div className={styles.modalContent}>
                                        {hasError && <div>Error creating targeting</div>}
                                        <Form className={styles.form}>
                                            <div className={cn(styles.row, styles.evenFields)}>
                                                <FormField>
                                                    <Label htmlFor="templateName">Template Name</Label>
                                                    <Field
                                                        name="templateName"
                                                        id="templateName"
                                                        placeholder="Enter template name"
                                                    />
                                                </FormField>
                                                <FormField>
                                                    <Label htmlFor="targetingName">Targeting Name</Label>
                                                    <Field
                                                        name="targetingName"
                                                        id="targetingName"
                                                        placeholder="Enter targeting name"
                                                    />
                                                </FormField>
                                            </div>
                                            <div className={cn(styles.row, styles.evenFields)}>
                                                <FormField>
                                                    <Label htmlFor="minAge">Min Age</Label>
                                                    <Field
                                                        type="number"
                                                        id="minAge"
                                                        name="minAge"
                                                        placeholder="Enter min age"
                                                    />
                                                </FormField>
                                                <FormField>
                                                    <Label htmlFor="maxAge">Max Age</Label>
                                                    <Field
                                                        type="number"
                                                        id="maxAge"
                                                        name="maxAge"
                                                        placeholder="Enter max age"
                                                    />
                                                </FormField>
                                            </div>
                                            {featureFlags.isEnabled('fb_custom_audience_targeting') && (
                                                <FormField>
                                                    <Label htmlFor="customAudiences">Custom Audiences</Label>
                                                    <Field name="customAudiences" id="customAudiences">
                                                        {({ form, field, meta }: FieldProps) => (
                                                            <FacebookTargetingCustomAudience
                                                                onChange={(customAudiences) =>
                                                                    form.setFieldValue(field.name, customAudiences)
                                                                }
                                                                value={field.value}
                                                                error={meta.error}
                                                            />
                                                        )}
                                                    </Field>
                                                </FormField>
                                            )}
                                            <FormField>
                                                <Label htmlFor="geoLocations">Location</Label>
                                                <Field name="geoLocations" id="geoLocations">
                                                    {({ form, field, meta }: FieldProps) => (
                                                        <FacebookTargetingLocationsInput
                                                            onChange={(l: FacebookTargetingLocationSearchOption[]) =>
                                                                form.setFieldValue(field.name, l)
                                                            }
                                                            locations={field.value}
                                                            error={meta.error}
                                                        />
                                                    )}
                                                </Field>
                                            </FormField>
                                            <FormField>
                                                <Label htmlFor="detailedTargeting">Detailed Targeting</Label>
                                                <Field name="detailedTargeting" id="detailedTargeting">
                                                    {({ form, field, meta }: FieldProps) => (
                                                        <FacebookDetailedTargetingInput
                                                            onChange={(s: FacebookTargetingAudienceSelectionValues) => {
                                                                form.setFieldValue(field.name, s);
                                                            }}
                                                            value={field.value}
                                                            error={meta.error}
                                                        />
                                                    )}
                                                </Field>
                                            </FormField>
                                            <div>
                                                <Label htmlFor="">Gender</Label>
                                                <div className={styles.row}>
                                                    <FormField className={cn(styles.row, styles.inline)}>
                                                        <Field type="checkbox" name="isMale" />
                                                        <Label htmlFor="isMale">Male</Label>
                                                    </FormField>
                                                    <FormField className={cn(styles.row, styles.inline)}>
                                                        <Field type="checkbox" name="isFemale" />
                                                        <Label htmlFor="isFemale">Female</Label>
                                                    </FormField>
                                                </div>
                                            </div>
                                            <FormField>
                                                <Label htmlFor="remarketingAudience">Remarketing Audiences</Label>
                                                <Field
                                                    name="remarketingAudience"
                                                    id="remarketingAudience"
                                                    placeholder="Enter remarketing audience"
                                                />
                                            </FormField>
                                            <FormField>
                                                <Label htmlFor="devicePlatforms">Device Platforms</Label>
                                                <MultiSelect
                                                    name="devicePlatforms"
                                                    optionLabel="name"
                                                    optionValue="id"
                                                    options={facebookDeviceOptions}
                                                    stylesConfig={multiSelectStyles}
                                                />
                                            </FormField>
                                            <FormField>
                                                <Label htmlFor="publisherPlatforms">Publisher Platforms</Label>
                                                <MultiSelect
                                                    name="publisherPlatforms"
                                                    optionLabel="name"
                                                    optionValue="id"
                                                    options={facebookPublisherPlatformOptions}
                                                    stylesConfig={multiSelectStyles}
                                                />
                                            </FormField>
                                            {positionEnabled('facebook', selectedPublisherPlatforms) && (
                                                <FormField>
                                                    <Label htmlFor="facebookPositions">Facebook Positions</Label>
                                                    <MultiSelect
                                                        options={facebookPositionOptions}
                                                        name="facebookPositions"
                                                        optionLabel="name"
                                                        optionValue="id"
                                                        stylesConfig={multiSelectStyles}
                                                    />
                                                </FormField>
                                            )}
                                            {positionEnabled('instagram', selectedPublisherPlatforms) && (
                                                <FormField>
                                                    <Label htmlFor="instagramPositions">Instagram Positions</Label>
                                                    <MultiSelect
                                                        options={instagramPositionOptions}
                                                        name="instagramPositions"
                                                        optionLabel="name"
                                                        optionValue="id"
                                                        stylesConfig={multiSelectStyles}
                                                    />
                                                </FormField>
                                            )}
                                            {positionEnabled('messenger', selectedPublisherPlatforms) && (
                                                <FormField>
                                                    <Label htmlFor="messengerPositions">Messenger Positions</Label>
                                                    <MultiSelect
                                                        options={messengerPositionOptions}
                                                        name="messengerPositions"
                                                        optionLabel="name"
                                                        optionValue="id"
                                                        stylesConfig={multiSelectStyles}
                                                    />
                                                </FormField>
                                            )}
                                            {positionEnabled('audience_network', selectedPublisherPlatforms) && (
                                                <FormField>
                                                    <Label htmlFor="audienceNetworkPositions">
                                                        Audience Network Positions
                                                    </Label>
                                                    <MultiSelect
                                                        options={audienceNetworkPositionOptions}
                                                        name="audienceNetworkPositions"
                                                        optionLabel="name"
                                                        optionValue="id"
                                                        stylesConfig={multiSelectStyles}
                                                    />
                                                </FormField>
                                            )}
                                            <FormField>
                                                <Label htmlFor="notes">Notes</Label>
                                                <TextArea name="notes" placeholder="Write notes" />
                                            </FormField>
                                        </Form>
                                    </div>
                                </ModalContent>
                                <ModalFooter>
                                    <Button
                                        className={styles.submit}
                                        disabled={!dirty}
                                        loading={isSubmitting}
                                        type="filled"
                                        color="black"
                                        onClick={submitForm}
                                    >
                                        Save Template
                                    </Button>

                                    <Button type="filled" color="negative" onClick={() => setShowConfirmDelete(true)}>
                                        Delete
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </>
                    );
                }}
            </Formik>
        </>
    );
}
