import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AppServer } from '../../mirage';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { User } from './user.types';

export const models = {
    user: Model as ModelDefinition<User>,
};

export const factories = {
    user: createFactory<User>({
        email(index: number) {
            return `user.email.${index}@thisisround.co.uk`;
        },
        groups() {
            return [];
        },
        username(index: number) {
            return `username.${index}`;
        },
    }),
};

export const serializers = {
    user: makeSerializer<User>([]),
};

export function handleRequests(server: AppServer) {
    server.get('/api/users/current-user/', (schema) => {
        return schema.first('user');
    });
}
