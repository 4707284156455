import { PhylloTiktokCreatorProfile, getPhylloTiktokCreatorProfiles } from '@round/api';
import { useCallback, useState } from 'react';

type UseTiktokInfluencerWideSearchResponse = {
    influencers: PhylloTiktokCreatorProfile[];
    count: number;
    isLoading: boolean;
    hasError: boolean;
    isInitialized: boolean;
    init: (requestInit?: RequestInit) => Promise<void>;
    reset: () => void;
};

export function useTiktokInfluencerWideSearch({
    page,
    page_size,
    min_follower_count,
    max_follower_count,
    min_average_views,
    max_average_views,
    engagement_rate,
    ordering,
    bio_phrase,
    audience_lookalikes,
    creator_lookalikes,
    has_contact_details,
    min_audience_locations,
    location,
    hashtags,
    follower_growth_interval,
    follower_growth_percentage_value,
}: Parameters<typeof getPhylloTiktokCreatorProfiles>[0]): UseTiktokInfluencerWideSearchResponse {
    const [influencers, setInfluencers] = useState<PhylloTiktokCreatorProfile[]>([]);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);

    const fetchData = useCallback(
        async (params: Parameters<typeof getPhylloTiktokCreatorProfiles>[0], requestInit?: RequestInit) => {
            setIsLoading(true);
            setHasError(false);
            try {
                const {
                    data: { results, count },
                } = await getPhylloTiktokCreatorProfiles(params, requestInit);

                setInfluencers(results);
                setCount(count);
                setIsInitialized(true);
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    return;
                }

                setHasError(true);
                throw error;
            } finally {
                setIsLoading(false);
            }
        },
        []
    );

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            await fetchData(
                {
                    page,
                    page_size,
                    min_follower_count,
                    max_follower_count,
                    min_average_views,
                    max_average_views,
                    engagement_rate,
                    ordering,
                    bio_phrase,
                    audience_lookalikes,
                    creator_lookalikes,
                    has_contact_details,
                    min_audience_locations,
                    location,
                    hashtags,
                    follower_growth_interval,
                    follower_growth_percentage_value,
                },
                requestInit
            );
        },
        [
            fetchData,
            page,
            page_size,
            min_follower_count,
            max_follower_count,
            min_average_views,
            max_average_views,
            engagement_rate,
            ordering,
            bio_phrase,
            audience_lookalikes,
            creator_lookalikes,
            has_contact_details,
            min_audience_locations,
            location,
            hashtags,
            follower_growth_interval,
            follower_growth_percentage_value,
        ]
    );

    const reset = useCallback(() => {
        setInfluencers([]);
        setIsInitialized(false);
        setIsLoading(false);
        setHasError(false);
    }, []);

    return {
        influencers,
        isLoading,
        hasError,
        count,
        isInitialized,
        init,
        reset,
    };
}
