import React from 'react';
import { ReactComponent as ChevronIcon } from '../../assets/icons/Chevron.svg';
import styles from './ExpansionPanel.module.css';
import cn from 'classnames';

type ExpansionPanelProps = {
    className?: string;
    barClassName?: string;
    expandButtonClassName?: string;
    barContent?: React.ReactNode | (() => React.ReactNode);
    children?: React.ReactNode | undefined;
    isExpanded: boolean;
    onToggle: (expanded: boolean) => void;
    toggleOnBarClicked?: boolean;
};

const ExpansionPanel = ({
    children,
    className,
    barContent,
    barClassName,
    expandButtonClassName,
    isExpanded,
    onToggle,
    toggleOnBarClicked,
}: ExpansionPanelProps) => {
    return (
        <div className={className}>
            <div
                className={cn(styles.container, barClassName, { [styles.clickable]: toggleOnBarClicked })}
                onClick={() => {
                    if (toggleOnBarClicked) {
                        onToggle(!isExpanded);
                    }
                }}
            >
                {typeof barContent === 'function' ? barContent() : barContent}
                <button
                    className={cn(styles.expandButton, expandButtonClassName, { [styles.expanded]: isExpanded })}
                    onClick={() => {
                        if (!toggleOnBarClicked) {
                            onToggle(!isExpanded);
                        }
                    }}
                >
                    <ChevronIcon />
                </button>
            </div>
            {isExpanded && children}
        </div>
    );
};

export default ExpansionPanel;
