import { Collection, Serializer } from 'miragejs';
import { SerializerInterface } from 'miragejs/serializer';
import { Factory } from 'miragejs';
import { Server } from 'miragejs/server';
import { ModelInstance, WithFactoryMethods } from 'miragejs/-types';
import { RouteHandlerContext } from './mirage';
import { PaginatedApiResponseData } from './types';

type WithFactoryHooks<Data> = WithFactoryMethods<Partial<Data>> & {
    afterCreate?: (obj: ModelInstance<Data>, server: Server) => void;
};

/**
 * Creates an instance of miragejs {@link Factory} with slightly extended type definition.
 *
 * **IMPORTANT:**
 *
 * We can't declare "server" as {@link AppServer} in type definition
 * because it could lead to circular reference.
 * Didn't dig into mirage's type definitions thoroughly
 * but I assume asserting it to {@link AppServer} is a good enough workaround
 * @example
 * createFactory({
 *     prop: value,
 *     afterCreate(obj, s) {
 *         const server = s as AppServer;
 *     }
 * })
 */
export function createFactory<Data>(data: WithFactoryHooks<Data>) {
    return Factory.extend<Partial<Data>>(data);
}

const shouldConvert = (key: string, value: any) => {
    return key === 'id' && typeof value === 'string';
};

const mapData = (data: any): any => {
    if (Array.isArray(data)) {
        return data.map((item) => mapData(item));
    }

    if (typeof data === 'object' && data !== null) {
        return Object.entries(data).reduce((acc, [key, value]) => {
            const newValue = shouldConvert(key, value) ? Number(value) : mapData(value);
            return {
                ...acc,
                [key]: newValue,
            };
        }, {});
    }

    return data;
};

export function makeSerializer<T>(include: Array<keyof T>) {
    return Serializer.extend({
        include,
        embed: true,
        root: false,
        serialize(primaryResource: any, request: any): any {
            const json = (Serializer.prototype as SerializerInterface).serialize?.apply(this, [
                primaryResource,
                request,
            ]);

            return mapData(json);
        },
        keyForEmbeddedRelationship(attributeName: string) {
            return attributeName;
        },
    });
}

type PaginationOptions = {
    page: string | number;
    page_size: string | number;
};

export function getPage<T>(collection: Collection<T>, { page_size, page }: PaginationOptions): Collection<T> {
    const begin = (Number(page) - 1) * Number(page_size);
    const end = Number(page) * Number(page_size);
    return collection.slice(begin, end);
}

export function buildRequestUrl(url: string, params: Record<string, string>) {
    return `${url}?${new URLSearchParams(params).toString()}`;
}

type BuildPaginatedResponseParams = {
    url: string;
    queryParams: Record<string, string>;
    serialize: RouteHandlerContext['serialize'];
};

export function buildPaginatedResponse<T>(
    collection: Collection<T>,
    { url, queryParams, serialize }: BuildPaginatedResponseParams
): PaginatedApiResponseData<T> {
    const { page = '1', page_size = '25', ...restQueryParams } = queryParams;
    const sliced = getPage(collection, { page, page_size });
    const hasNext = (Number(page) - 1) * Number(page_size) < collection.length;
    const hasPrevious = Number(page) > 1;

    return {
        results: serialize(sliced),
        previous: hasPrevious
            ? buildRequestUrl(url, {
                  page: String(Number(page) - 1),
                  page_size,
                  ...restQueryParams,
              })
            : null,
        next: hasNext
            ? buildRequestUrl(url, {
                  page: String(Number(page) + 1),
                  page_size,
                  ...restQueryParams,
              })
            : null,
        count: collection.length,
    };
}
