import { useBrands } from 'Modules/Plans/Brand/hooks/useBrands';
import { useProjects } from '../hooks/useProjects';
import { creatorbase } from '@round/api';
import { useCallback, useMemo, useState } from 'react';
import uniq from 'lodash/uniq';
import { useTeams } from 'Modules/Plans/Team/hooks/useTeams';
import { ProjectsTableRow } from './components/ProjectsTable/ProjectsTable';
import { useUsers } from 'Modules/Plans/User/hooks/useUsers';
import moment from 'moment';
import { isNumber } from 'utility/utility';

type Status = 'idle' | 'loading' | 'success' | 'error';

const today = moment();
const twoWeeksAgo = moment().subtract(14, 'days');

export function useProjectsList() {
    const [status, setStatus] = useState<Status>('idle');
    const { fetchData: fetchProjects, reset: resetProjects, ...projectsData } = useProjects();
    const { fetchData: fetchBrands, ...brandsData } = useBrands();
    const { fetchData: fetchTeams, ...teamsData } = useTeams();
    const { fetchData: fetchUsers, ...usersData } = useUsers();
    const [
        songStatsTimeSeries,
        setSongStatsTimeSeries,
    ] = useState<creatorbase.TimeSeriesResponse<'tiktok_daily_change'> | null>(null);

    const fetchData = useCallback(
        async (params: creatorbase.GetProjectsParams, requestInit?: RequestInit) => {
            try {
                setStatus('loading');
                const projectsResponse = await fetchProjects(params, requestInit);

                if (projectsResponse.status !== 200) {
                    setStatus('error');
                    return;
                }

                const brandIds = uniq(projectsResponse.data.results.map((project) => project.brand_id));
                const brandIdsToFetch = brandIds.filter(
                    (id) => !brandsData.data?.results.some((brand) => brand.id === id)
                );

                const teamIds = uniq(projectsResponse.data.results.map((project) => project.team_id));
                const teamIdsToFetch = teamIds.filter((id) => !teamsData.data?.results.some((team) => team.id === id));

                const userIds = uniq(
                    projectsResponse.data.results.map((project) => project.users_assigned_to_campaigns).flat()
                );
                const userIdsToFetch = userIds.filter((id) => !usersData.data?.results.some((user) => user.id === id));
                const songIds = uniq(projectsResponse.data.results.map((project) => project.song_id)).filter(isNumber);
                const songIdsToFetch = songIds.filter(
                    (id) => !Object.keys(songStatsTimeSeries || {}).includes(id.toString())
                );

                const [songTimeSeries] = await Promise.all([
                    songIdsToFetch.length
                        ? creatorbase.postTimeSeries({
                              type: 'song',
                              ids: songIds,
                              fields: ['tiktok_daily_change'],
                              start_date: twoWeeksAgo.format('YYYY-MM-DD'),
                              end_date: today.format('YYYY-MM-DD'),
                          })
                        : Promise.resolve(null),
                    brandIdsToFetch.length
                        ? fetchBrands({ id: brandIdsToFetch.join(','), page_size: brandIds.length }).then((res) => {
                              if (res.status !== 200) {
                                  setStatus('error');
                              }
                          })
                        : Promise.resolve([]),
                    teamIdsToFetch.length
                        ? fetchTeams({ id: teamIdsToFetch.join(','), page_size: teamIds.length }).then((res) => {
                              if (res.status !== 200) {
                                  setStatus('error');
                              }
                          })
                        : Promise.resolve([]),
                    userIdsToFetch.length
                        ? fetchUsers({ id: userIdsToFetch.join(','), page_size: userIdsToFetch.length }).then((res) => {
                              if (res.status !== 200) {
                                  setStatus('error');
                              }
                          })
                        : Promise.resolve([]),
                ]);

                if (songTimeSeries?.status === 200) {
                    setSongStatsTimeSeries((prev) => ({ ...prev, ...songTimeSeries.data }));
                }

                setStatus('success');
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                setStatus('error');
                throw e;
            }
        },
        [
            fetchProjects,
            fetchBrands,
            fetchTeams,
            brandsData.data?.results,
            teamsData.data?.results,
            fetchUsers,
            usersData.data?.results,
            songStatsTimeSeries,
        ]
    );

    const reset = useCallback(() => {
        resetProjects();
        setStatus('idle');
    }, [resetProjects]);

    const createProject = useCallback(
        async (data: creatorbase.PostProjectData) => {
            const response = await creatorbase.postProject(data);

            if (response.status === 201) {
                reset();
            }

            return response;
        },
        [reset]
    );

    const deleteProject = useCallback(
        async (projectId: creatorbase.Project['id']) => {
            const response = await creatorbase.deleteProject(projectId);
            if (response.status === 204) {
                reset();
            }
            return response;
        },
        [reset]
    );

    const rows: ProjectsTableRow[] = useMemo(
        () =>
            projectsData.data?.results.map((project) => {
                const brand = brandsData.data?.results.find((brand) => brand.id === project.brand_id) || null;
                const team = teamsData.data?.results.find((team) => team.id === project.team_id) || null;
                const users =
                    usersData.data?.results.filter((user) => project.users_assigned_to_campaigns.includes(user.id)) ||
                    [];
                const songTimeSeries = project.song_id ? songStatsTimeSeries?.[project.song_id] || null : null;

                return {
                    ...project,
                    brand,
                    team,
                    users,
                    songStatsTimeSeries: songTimeSeries,
                };
            }) || [],
        [projectsData.data, brandsData.data, teamsData.data, usersData.data, songStatsTimeSeries]
    );

    return {
        status,
        fetchData,
        createProject,
        deleteProject,
        reset,
        rows,
        hasNextPage: !!projectsData.data?.next,
        hasPreviousPage: !!projectsData.data?.previous,
        count: projectsData.data?.count,
    };
}
