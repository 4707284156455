import React, { useCallback, useState } from 'react';
import { TiktokHashtagStats } from '../../TikTok.types';
import { getTikTokHashtagStats } from '../../TikTok.api';
import ModalRight from '../../../../SharedComponents/ModalRight/ModalRight';
import LoadingSpinner from '../../../../SharedComponents/LoadingSpinner/LoadingSpinner';
import styles from './TikTokHashtagsModal.module.css';
import { buildTiktokHashtagUrl, numberWithCommas } from '../../../../helpers';
import TiktokStatsMultiLineChart from '../../../../SharedComponents/TiktokStatsMultiLineChart/TiktokStatsMultiLineChart';
import CloseIcon from '../../../../SharedComponents/svg/Icons/CloseIcon';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';

type TikTokHashtagsModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    hashtagId?: number;
};

const TikTokHashtagsModal = ({ isModalOpen, hashtagId, closeModal }: TikTokHashtagsModalProps) => {
    const [stats, setStats] = useState<TiktokHashtagStats[]>([]);

    const fetchStats = useCallback(
        async (requestInit?: RequestInit) => {
            if (!hashtagId) {
                return;
            }

            const stats = await getTikTokHashtagStats(hashtagId, requestInit);
            const sortedStats = stats.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

            const getDateString = (stats: TiktokHashtagStats) => {
                return stats.timestamp.split('T')[0];
            };

            const shown = new Set();
            sortedStats.reverse(); // Reverse Chronological
            let filteredStats = sortedStats.filter((el) => {
                const dateString = getDateString(el);
                const duplicate = shown.has(dateString);
                shown.add(dateString);
                return !duplicate;
            });
            sortedStats.reverse(); // Chronological
            if (sortedStats.length > 1 && getDateString(sortedStats[0]) === getDateString(sortedStats[1])) {
                filteredStats.push(sortedStats[0]);
            }

            setStats(filteredStats.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()));
        },
        [hashtagId]
    );

    const onClose = useCallback(() => {
        setStats([]);
        closeModal();
    }, [closeModal]);

    useAbortableEffect(
        (signal) => {
            fetchStats({ signal });
        },
        [fetchStats]
    );

    const stat = stats.length ? stats[0] : null;
    const hashtag = stat?.hashtag;

    return (
        <ModalRight isModalOpen={isModalOpen} closeModal={closeModal}>
            <div className={styles.content}>
                {(!hashtag || !stats.length) && <LoadingSpinner />}

                <div className={styles.headingBar}>
                    <CloseIcon width={20} onClick={onClose} className={styles.closeIcon} />
                    <div className={styles.headingBarContent}>
                        <div className={styles.topRow}>
                            <div className={styles.hashtagTitle}>
                                <a
                                    href={buildTiktokHashtagUrl(hashtag?.title ?? '')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    #{hashtag?.title}
                                </a>
                            </div>
                            <div className={styles.description}>{hashtag?.desc}</div>
                        </div>

                        {stat && (
                            <>
                                <div className={`${styles.greyHeading} ${styles.greyHeadingInline}`}>
                                    {numberWithCommas(stat.video_count)} video count
                                </div>

                                <div className={`${styles.greyHeading} ${styles.greyHeadingInline}`}>
                                    {numberWithCommas(stat.view_count)} view count
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className={styles.contentContainer}>
                    {stats.length ? (
                        <TiktokStatsMultiLineChart
                            datasets={[
                                {
                                    label: 'video count',
                                    data: stats.map((stat) => ({
                                        x: new Date(stat.timestamp),
                                        y: stat.video_count,
                                    })),
                                },
                                {
                                    label: 'view count',
                                    data: stats.map((stat) => ({
                                        x: new Date(stat.timestamp),
                                        y: stat.view_count,
                                    })),
                                },
                            ]}
                        />
                    ) : (
                        <LoadingSpinner />
                    )}
                </div>
            </div>
        </ModalRight>
    );
};

export default TikTokHashtagsModal;
