import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { AggregatedAudioStats } from './aggregatedAudioStats.types';

export const models = {
    aggregatedAudioStats: Model as ModelDefinition<AggregatedAudioStats>,
};

export const serializers = {
    aggregatedAudioStats: makeSerializer<AggregatedAudioStats>([]),
};

export const factories = {
    aggregatedAudioStats: createFactory<AggregatedAudioStats>({
        audio_count: 0,
        video_count: 0,
        video_count_daily_change: 0,
        video_count_weekly_change: 0,
    }),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/creatorbase/aggregated-audio-stats/';
    //TODO: reflect BE logic when posts API is ready
    server.post(rootUrl, async function (this: RouteHandlerContext, schema, request) {
        return schema.create('aggregatedAudioStats');
    });
}
