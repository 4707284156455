import React from 'react';
import Select from '../../../../../../ui/DataEntry/Select/Select';
import { StylesConfig, ValueType } from 'react-select';
import { TagOption } from 'App.types';
import { ControlWithSearchIcon } from '@round/ui-kit';

type TagsFilterProps = {
    options: TagOption[];
    placeholder?: string;
    className?: string;
    loading?: boolean;
    styles?: StylesConfig;
    errorLoading?: boolean;
    value: ValueType<TagOption, true>;
    onChange: (value: ValueType<TagOption, true>) => void;
};

export const defaultTagsSelectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        borderColor: '#C2CFE0',
        fontSize: '0.75rem',
    }),
    placeholder: (base) => ({
        ...base,
        color: '#90A0B7',
        fontSize: '0.75rem',
    }),
    dropdownIndicator: () => ({
        display: 'none',
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '0 0.5rem',
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: '0 0.5rem',
    }),
    multiValue: (base) => ({
        ...base,
        padding: '0',
    }),
};

const TagsFilter = ({
    placeholder,
    className,
    options,
    loading,
    errorLoading,
    value,
    onChange,
    styles = defaultTagsSelectStyles,
}: TagsFilterProps) => {
    return (
        <Select
            isMulti
            isLoading={loading}
            styles={styles}
            placeholder={placeholder}
            className={className}
            components={{ Control: ControlWithSearchIcon }}
            options={options}
            noOptionsMessage={() => (errorLoading ? 'Could not load tags' : 'No Options')}
            value={value}
            onChange={onChange}
        />
    );
};

export default TagsFilter;
