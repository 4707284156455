type IsValidTikTokPostUrlResult =
    | {
          isValid: true;
          message?: undefined;
      }
    | {
          isValid: false;
          message: string;
      };
export function isValidTikTokPostUrl(url: string, usernameToMatch?: string): IsValidTikTokPostUrlResult {
    if (!url.length) {
        return {
            isValid: false,
            message: `URL cannot be empty`,
        };
    }

    const baseTiktokLinkRegex = /.*tiktok\.com.*/;
    if (!baseTiktokLinkRegex.test(url)) {
        return {
            isValid: false,
            message: `URL doesn't look like a tiktok link`,
        };
    }

    if (!url.startsWith('https://')) {
        return {
            isValid: false,
            message: `URL must start with https://`,
        };
    }

    if (url.includes('?')) {
        return {
            isValid: false,
            message: `URL cannot contain query parameters. Try removing the ? and everything after it`,
        };
    }

    const shortenedLinkRegex = /^https:\/\/(vt|vm).*/;
    if (shortenedLinkRegex.test(url)) {
        const isMissingTrailingSlash = url[url.length - 1] !== '/';
        if (isMissingTrailingSlash) {
            return {
                isValid: false,
                message: `URL looks like it's missing a forward slash at the end`,
            };
        }

        const validShortLinkRegex = /^https:\/\/(vt|vm)\.tiktok\.com\/[A-Za-z0-9]+\/$/;
        if (!validShortLinkRegex.test(url)) {
            return {
                isValid: false,
                message: `URL doesn't look quite right`,
            };
        }

        return {
            isValid: true,
        };
    }

    const tikTokPostLinkRegex = /^https:\/\/www\.tiktok\.com\/t\/[A-Za-z0-9]+\/?$/;
    const tikTokPostLinkWithUsernameRegex = /^https:\/\/www\.tiktok\.com\/@[\w.]+\/video\/\d+\/?$/;

    const isValidPostLink = tikTokPostLinkRegex.test(url) || tikTokPostLinkWithUsernameRegex.test(url);

    if (!isValidPostLink) {
        return {
            isValid: false,
            message: `URL doesn't look like a tiktok post link`,
        };
    }

    const shouldTestUsernameMatch = usernameToMatch && tikTokPostLinkWithUsernameRegex.test(url);

    if (shouldTestUsernameMatch && !url.includes(`@${usernameToMatch}`)) {
        return {
            isValid: false,
            message: `The username isn't the one we expected`,
        };
    }

    return {
        isValid: true,
    };
}

export function sanitizeTikTokPostUrl(url: string): string {
    if (!url.length) {
        return '';
    }

    let sanitizedUrl = url;
    if (url.includes('?')) {
        sanitizedUrl = url.split('?')[0];
    }

    if (!sanitizedUrl.startsWith('https://')) {
        sanitizedUrl = `https://${sanitizedUrl}`;
    }

    const isShortLink = sanitizedUrl.split('//')[1].startsWith('vt') || sanitizedUrl.split('//')[1].startsWith('vm');

    if (!isShortLink && !sanitizedUrl.split('//')[1].startsWith('www.')) {
        sanitizedUrl = sanitizedUrl.replace('//', '//www.');
    }

    return sanitizedUrl;
}
