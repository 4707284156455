import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { creatorbase, PaginatedApiResponseData } from '@round/api';
import { createReducer } from 'helpers';

export type State = DataState<{
    postsStats: PaginatedApiResponseData<creatorbase.PostStats>;
    audios: creatorbase.TiktokAudio[];
}>;
export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export type Actions =
    | ReducerAction<'tiktokPostStatsIdle'>
    | ReducerAction<'loadTiktokPostStats'>
    | ReducerActionWithPayload<
          'tiktokPostStatsSuccess',
          { postsStats: PaginatedApiResponseData<creatorbase.PostStats>; audios: creatorbase.TiktokAudio[] }
      >
    | ReducerActionWithPayload<'tiktokPostStatsErrorLoading', string>;

export const reducer = createReducer<State, Actions>({
    tiktokPostStatsIdle: (state) => ({
        ...state,
        status: 'idle',
        error: null,
    }),
    loadTiktokPostStats: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    tiktokPostStatsErrorLoading: (state, { payload }) => ({
        ...state,
        status: 'error',
        error: payload,
    }),
    tiktokPostStatsSuccess: (_, { payload }) => ({
        data: payload,
        error: null,
        status: 'success',
    }),
});
