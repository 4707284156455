import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { EmailTemplate } from './emailTemplates.types';

export const factories = {
    emailTemplate: createFactory<EmailTemplate>({
        modified: '2021-09-01T10:00:00Z',
        notification_type: 'SONG_PROMO_INITIAL_UNREGISTERED',
        title: (index: number) => `title${index}`,
        updated_by: (index: number) => index,
    }),
};

export const models = {
    emailTemplate: Model as ModelDefinition<EmailTemplate>,
};

export const serializers = {
    emailTemplate: makeSerializer<EmailTemplate>([]),
};

export function handleRequests(server: AppServer) {
    const requestUrl = '/api/contact/email-templates/';
    server.get(requestUrl, function (this: RouteHandlerContext, schema, request) {
        const emailTemplates = schema.all('emailTemplate');
        return buildPaginatedResponse(emailTemplates, {
            url: requestUrl,
            serialize: (resource) => this.serialize(resource, 'emailTemplate'),
            queryParams: request.queryParams,
        });
    });
}
