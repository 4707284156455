import { IconProps } from './Icon.types';

const ArrowRightCircleIcon = ({ width = 14, height = 14, strokeWidth, className, onClick }: IconProps) => (
    <svg
        className={className}
        onClick={onClick}
        width={width}
        height={height}
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
    >
        <path
            d="M6.99984 12.8333C10.2215 12.8333 12.8332 10.2216 12.8332 6.99996C12.8332 3.7783 10.2215 1.16663 6.99984 1.16663C3.77818 1.16663 1.1665 3.7783 1.1665 6.99996C1.1665 10.2216 3.77818 12.8333 6.99984 12.8333Z"
            stroke="#727F8F"
            strokeWidth={strokeWidth}
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.26514 9.05913L8.31847 6.99996L6.26514 4.9408"
            stroke="#727F8F"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ArrowRightCircleIcon;
