import { creatorbase } from '@round/api';
import BrandForm, { BrandFormValues } from '../BrandForm/BrandForm';
import { Formik, FormikHelpers } from 'formik';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import { showNotification } from 'helpers';
import { brandFormValidationSchema, convertBrandApiErrorsToFormikErrors } from '../BrandForm/BrandForm.helpers';
import styles from './CreateBrandModal.module.css';
import Button from 'ui-new/whitelabel/Button/Button';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    createBrand: typeof creatorbase.postBrand;
};

const initialValues: BrandFormValues = {
    name: '',
    image: null,
    team: null,
};

const CreateBrandModal = ({ isOpen, onClose, createBrand }: Props) => {
    const handleSubmit = async (values: BrandFormValues, helpers: FormikHelpers<BrandFormValues>) => {
        const data: creatorbase.PostBrandData = {
            name: values.name,
            team_id: values.team!.value,
            image: values.image,
        };

        try {
            const response = await createBrand(data);

            if (response.status === 201) {
                showNotification('Artist created', 'info');
                helpers.resetForm();
                onClose();
                return;
            }

            if (Array.isArray(response.data)) {
                showNotification(response.data.join('. '), 'error');
                return;
            }

            if (response.status === 400) {
                //covers potential form type errors
                if ('detail' in response.data) {
                    showNotification('Could not create artist', 'error');
                    return;
                }

                helpers.setErrors(convertBrandApiErrorsToFormikErrors(response.data));
                return;
            }

            showNotification('Could not create artist', 'error');
        } catch {
            showNotification('Could not create artist', 'error');
        }
    };

    return (
        <Modal
            className={styles.modal}
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick
            ariaLabel="create artist modal"
        >
            <Modal.Header>
                <Modal.Title>Create an artist</Modal.Title>
            </Modal.Header>
            <Formik validationSchema={brandFormValidationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                {({ isValid, isSubmitting, submitForm, dirty }) => (
                    <>
                        <Modal.Body>
                            <BrandForm />
                        </Modal.Body>

                        <Modal.Actions>
                            <Button
                                appearance="primary"
                                onClick={submitForm}
                                disabled={!isValid || isSubmitting || !dirty}
                            >
                                Create artist
                            </Button>

                            <Button appearance="ghost" onClick={onClose}>
                                Cancel
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default CreateBrandModal;
