import { useCallback, useState } from 'react';
import { getPlannerUsers, UserPlanner } from '@round/api';
import { showNotification } from 'helpers';

type Params = Pick<Parameters<typeof getPlannerUsers>[0], 'search' | 'id' | 'page' | 'page_size'>;

export default function usePlannerUsers({ search, id, page, page_size }: Params = {}) {
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [planners, setPlanners] = useState<UserPlanner[]>([]);

    const fetchPlanners = useCallback(async (params: Params, requestInit?: RequestInit) => {
        try {
            setIsLoading(true);
            const response = await getPlannerUsers(params, requestInit);
            setPlanners(response.data.results);
            setIsInitialized(true);
            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return;
            }
            setIsInitialized(true);
            throw e;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            fetchPlanners({ search, id, page_size, page }, requestInit).catch(() => {
                showNotification('Could not fetch planners', 'error');
            });
        },
        [id, search, page_size, page, fetchPlanners]
    );

    const reset = useCallback(() => {
        setPlanners([]);
        setIsInitialized(false);
        setIsLoading(false);
    }, []);

    return {
        isInitialized,
        isLoading,
        planners,
        fetchData: fetchPlanners,
        init,
        reset,
    };
}
