import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { PublicReport } from './publicReport.types';

export const models = {
    creatorbasePublicReport: Model as ModelDefinition<PublicReport>,
};

export const serializers = {
    creatorbasePublicReport: makeSerializer<PublicReport>([]),
};

export const factories = {
    creatorbasePublicReport: createFactory<PublicReport>({}),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/creatorbase/public-reports/';

    server.post(rootUrl, function (this: RouteHandlerContext, schema, request) {
        const attrs = JSON.parse(request.requestBody);
        const publicReport = schema.create('creatorbasePublicReport', attrs);
        return publicReport;
    });

    server.delete(`${rootUrl}:id/`, (schema, request) => {
        const publicReport = schema.find('creatorbasePublicReport', request.params.id);
        if (!publicReport) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        publicReport.destroy();
        return new Response(204);
    });
}
