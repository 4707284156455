import useNonNullContext from 'Hooks/useNonNullContext';
import { PropsWithChildren, useState } from 'react';
import { DropdownContext } from '../Dropdown';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import { ControlledPopover } from '@round/ui-kit';
import cn from 'classnames';
import styles from '../Dropdown.module.css';

export const Menu = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
    const { isOpen, setIsOpen, anchorElement } = useNonNullContext(DropdownContext);
    const [menuRef, setMenuRef] = useState<HTMLElement | null>(null);

    useOnClickOutside([menuRef], () => {
        if (isOpen) {
            setIsOpen(false);
        }
    });

    return (
        <ControlledPopover isOpen={isOpen} anchorElement={anchorElement} overlayClassName={styles.overlay}>
            <menu ref={setMenuRef} className={cn(styles.menu, className)}>
                {children}
            </menu>
        </ControlledPopover>
    );
};
