import React, { useState } from 'react';
import styles from './TooltipCard.module.css';
import cn from 'classnames';
import { ReactComponent as QuestionMarkIcon } from '../../../assets/QuestionMark.svg';
import { Popover } from '@round/ui-kit';
import Card from '../../Card/Card';

type TooltipCardProps = {
    children: React.ReactNode;
    tooltipContent?: React.ReactNode;
    className?: string;
    disabled?: boolean;
};

const TooltipCard = ({ children, tooltipContent, className, disabled }: TooltipCardProps) => {
    const [cardRef, setCardRef] = useState<HTMLElement | null>(null);

    const hasTooltipContent = tooltipContent !== undefined;
    return (
        <>
            <div ref={setCardRef} className={cn(styles.card, className, { [styles.cursorHelp]: hasTooltipContent })}>
                {hasTooltipContent && (
                    <div className={styles.iconContainer}>
                        <QuestionMarkIcon className={styles.icon} />
                    </div>
                )}
                {children}
            </div>
            <Popover
                disabled={disabled}
                anchorElement={cardRef}
                showOn="hover"
                options={{ modifiers: [{ name: 'offset', options: { offset: [0, 10] } }] }}
            >
                <Card className={styles.tooltip}>{tooltipContent}</Card>
            </Popover>
        </>
    );
};

export default TooltipCard;
