import React, { ReactNode } from 'react';
import { UserGroup } from '@round/api';
import { useCheckUserGroupsAccess } from '../../hooks/useCheckUserGroupsAccess';
import UnauthorisedScreen from './UnauthorisedScreen/UnauthorisedScreen';

type ProtectedRouteProps = {
    children: ReactNode;
    groups: UserGroup[];
    hasAccessWhenMatches?: 'all' | 'any';
};

const ProtectedRoute = ({ children, groups, hasAccessWhenMatches = 'all' }: ProtectedRouteProps) => {
    const canAccess = useCheckUserGroupsAccess(['round_product', ...groups], hasAccessWhenMatches);
    if (typeof canAccess !== 'boolean') {
        return null;
    }

    if (!canAccess) {
        return <UnauthorisedScreen />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
