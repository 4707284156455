import { call } from '../../helpers';
import { ApiResponse, NotFoundResponse } from '../../types';
import { isPublicReportRequestInit } from '../utils';
import { PublicReport } from './publicReports.types';

export type GetPublicReportResponse = ApiResponse<PublicReport, 200> | NotFoundResponse;

export async function getPublicReport(uuid: string, requestInit?: RequestInit): Promise<GetPublicReportResponse> {
    const response = await call(`/api/influencer/public-reports/${uuid}/`, requestInit, {
        shouldBypassTokens: isPublicReportRequestInit(requestInit),
    });

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not find report');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
