import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { InstagramAudio, InstagramAudioMonitoringInfo, InstagramAudioStats } from './audios.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';

export type GetInstagramAudiosParams = Partial<
    PaginatedRequest & {
        id: string;
        identifier_search: string;
    }
>;

export async function getInstagramAudios(
    params: GetInstagramAudiosParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<InstagramAudio>, 200> | NotFoundResponse> {
    const response = await fetchWithToken(
        `/api/instagram/viewsets/audios/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not fetch instagram audios ${encodeUrlSearchParams(params)}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getInstagramAudio(
    audioId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<InstagramAudio, 200> | NotFoundResponse> {
    const response = await fetchWithToken(`/api/instagram/viewsets/audios/${audioId}/`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not fetch instagram audio ${audioId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PostInstagramAudioPayload = {
    identifier: string; // url or ID
};

type PostInstagramAudioResponse =
    | ApiResponse<InstagramAudio, 201>
    | ApiResponse<ApiResponseError<PostInstagramAudioPayload>, 400>;

export async function postInstagramAudio(data: PostInstagramAudioPayload): Promise<PostInstagramAudioResponse> {
    const response = await fetchWithToken('/api/instagram/viewsets/audios/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create instagram audio');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type GetInstagramAudioStatsParams = {
    audioId: number;
} & Partial<{
    reels_count_time_series_start_date: string;
    reels_count_time_series_end_date: string;
}>;

export async function getInstagramAudioStats(
    { audioId, ...params }: GetInstagramAudioStatsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<InstagramAudioStats, 200> | NotFoundResponse> {
    const response = await fetchWithToken(
        `/api/instagram/viewsets/audios/${audioId}/stats/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not fetch instagram audio ${audioId} stats`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getInstagramAudioMonitoring(
    audioId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<InstagramAudioMonitoringInfo, 200> | NotFoundResponse | ApiResponse<{ detail: string }, 403>> {
    const response = await fetchWithToken(`/api/instagram/viewsets/audios/${audioId}/monitor/`, requestInit);

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get monitoring info for audio ${audioId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
