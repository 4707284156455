import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { YouTubeInfluencerPost } from './youtubeInfluencerPosts.types';

export const models = {
    youtubeInfluencerPost: Model as ModelDefinition<YouTubeInfluencerPost>,
};

export const serializers = {
    youtubeInfluencerPost: makeSerializer<YouTubeInfluencerPost>([]),
};

export const factories = {
    youtubeInfluencerPost: createFactory<YouTubeInfluencerPost>({
        channel_id: null,
        channel_title: null,
        cost: (index: number) => index.toString(),
        currency_id: 1,
        estimated_views: null,
        estimated_views_override: null,
        group_id: null,
        notes: '',
        plan_id: null,
        post_url: '',
        video_id: null,
        xero_invoice_id: null,
        client_cost: 0,
        status: 'planned',
    }),
};

export function handleRequests(server: AppServer) {
    const getInfluencerPostsUrl = '/api/advertising/youtube-influencer-posts/';
    server.get(getInfluencerPostsUrl, function (this: RouteHandlerContext, schema, request) {
        const { plan_id = '' } = request.queryParams;
        const posts = schema
            .all('youtubeInfluencerPost')
            .filter((post) => !plan_id || post.plan_id === Number(plan_id));
        return buildPaginatedResponse(posts, {
            url: getInfluencerPostsUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'youtubeInfluencerPost'),
        });
    });

    server.post('/api/advertising/youtube-influencer-posts/', function (this: RouteHandlerContext, schema, request) {
        const post = schema.create('youtubeInfluencerPost', JSON.parse(request.requestBody));
        return new Response(201, {}, this.serialize(post, 'youtubeInfluencerPost'));
    });

    server.delete('/api/advertising/youtube-influencer-posts/:id/', (schema, request) => {
        const post = schema.find('youtubeInfluencerPost', request.params.id);
        if (!post) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        post.destroy();
        return new Response(204);
    });

    server.patch('/api/advertising/youtube-influencer-posts/:id/', (schema, request) => {
        const post = schema.find('youtubeInfluencerPost', request.params.id);
        if (!post) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        post.update(JSON.parse(request.requestBody));
        return post;
    });
}
