import { microwave } from '@round/api';
import styles from './FormOpen.module.css';
import CampaignDetails from '../CampaignDetails/CampaignDetails';
import { Image } from '@round/ui-kit';
import MicrosPaymentRequestForm from '../Form/MicrosPaymentRequestForm';

type Props = {
    uuid: string;
    data: microwave.TiktokPostInviteForm;
    onSuccess: (data: Partial<microwave.TiktokPostInviteForm>) => void;
};

const FormOpen = ({ uuid, data, onSuccess }: Props) => {
    const username = data?.user_unique_id;
    const nickname = data?.user_nickname;
    const userImageUrl = data?.user_avatar_thumb;

    return (
        <div className={styles.container}>
            <section className={styles.releaseContainer}>
                <CampaignDetails
                    releaseName={data.release_name}
                    artistName={data.artist_name}
                    imageUrl={data.image_url}
                    audioUrl={data.audio_url}
                />
            </section>
            <section className={styles.contentContainer}>
                <>
                    <article className={styles.userDataContainer}>
                        <Image src={userImageUrl} className={styles.image} alt="Tiktok user image" />
                        <div>
                            <div className={styles.title}>{nickname}</div>
                            <div className={styles.subtitle}>{`@${username}`}</div>
                        </div>
                    </article>

                    <div className={styles.titles}>
                        <h1 className={styles.title}>{`Payment request form for ${nickname}`}</h1>
                        <p className={styles.subtitle}>
                            Please enter your TikTok URL in the form below.
                            <br />
                            Please note that posts need to be live for at least 10 minutes before they can be accepted
                        </p>
                    </div>

                    <MicrosPaymentRequestForm uuid={uuid} hasLocation={data?.has_location} onSuccess={onSuccess} />
                </>
            </section>
        </div>
    );
};

export default FormOpen;
