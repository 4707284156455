import { creatorbase, influencer } from '@round/api';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { CellContext } from '@tanstack/react-table';
import { CampaignsTableMeta } from '../../types';
import RunDates from 'Modules/Plans/components/RunDates/RunDates';
import moment from 'moment';

type Row = creatorbase.PublicCampaign & {
    stats?: influencer.AggregatedPostStats | creatorbase.AggregatedPostStats | null;
};

type Context<T extends Row> = CellContext<T, T['stats']>;
const getTableMeta = getTableMetaHelper<CampaignsTableMeta>();

const DurationCell = <T extends Row>({ table, row: { original } }: Context<T>) => {
    const { isLoading, getIsCampaignStatsLoading } = getTableMeta(table);
    if (isLoading || getIsCampaignStatsLoading?.(original)) {
        return <Skeleton />;
    }

    if (!original.stats?.first_post_date || !original.stats.last_post_date) {
        return '-';
    }

    const startDate = moment(original.stats?.first_post_date).format('DD MMM');
    const endDate = moment(original.stats?.last_post_date).format('DD MMM');

    return <RunDates startDate={startDate} endDate={endDate} />;
};

export default DurationCell;
