import { ControlProps, components } from 'react-select';
import { GenericDropdownOption } from 'App.types';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import styles from './Control.module.css';

type Props<T extends GenericDropdownOption<any>, IsMulti extends boolean = false> = ControlProps<T, IsMulti>;

const Control = <T extends GenericDropdownOption<any>, IsMulti extends boolean = false>(props: Props<T, IsMulti>) => (
    <components.Control {...props}>
        <>
            <SearchIcon className={styles.searchIcon} />
            {props.children}
        </>
    </components.Control>
);

export default Control;
