import { microwave } from '@round/api';
import { Skeleton } from '@round/ui-kit';
import styles from './PlatformCell.module.css';
import { getPlatformIcon } from 'Modules/Advertising/Microwave/helpers';

type Props = {
    isLoading?: boolean;
    platform: microwave.Platform | undefined;
};

const PlatformCell = ({ platform, isLoading }: Props) => {
    if (isLoading) {
        return <Skeleton />;
    }

    if (!platform) {
        return null;
    }

    const Icon = getPlatformIcon(platform);
    return <Icon className={styles.icon} />;
};

export default PlatformCell;
