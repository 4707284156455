import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { Release } from './releases.types';

export const models = {
    microwaveRelease: Model as ModelDefinition<Release>,
};

export const serializers = {
    microwaveRelease: makeSerializer<Release>([]),
};

export const factories = {
    microwaveRelease: createFactory<Release>({
        brand_name: (index: number) => `brand name ${index}`,
        brand_image: '',
        campaign_ids: [],
        name: (index: number) => `release name ${index}`,
        type_name: 'Single',
    }),
};

export function handleRequests(server: AppServer) {
    const getReleasesUrl = '/api/microwave/releases/';
    server.get(getReleasesUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('microwaveRelease'), {
            url: getReleasesUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'microwaveRelease'),
        });
    });

    server.get('/api/microwave/releases/:id/', (schema, request) => {
        const release = schema.find('microwaveRelease', request.params.id);
        return release ?? new Response(404, {}, { detail: 'Not found.' });
    });
}
