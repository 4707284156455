import { useState } from 'react';
import { ValueType } from 'react-select';
import { GenericDropdownOption } from '../../../../../../App.types';
import { useSelectPaginatedOptions } from '../../../../../../Hooks/useSelectPaginatedOptions';
import { getTiktokHashTags } from '@round/api';
import useAbortableEffect from '../../../../../../Hooks/useAbortableEffect';
import { showNotification } from '../../../../../../helpers';

export default function useHashtagsSelect(initialHashtagIds: number[] = []) {
    const [selectedHashtags, setSelectedHashtags] = useState<ValueType<GenericDropdownOption<number>, true>>(null);
    const { props: hashtagSelectProps } = useSelectPaginatedOptions<
        GenericDropdownOption<number>,
        typeof getTiktokHashTags
    >({
        fetchOptions: getTiktokHashTags,
        mapToOption: (hashtag) => ({
            label: `#${hashtag.title}`,
            value: hashtag.id,
        }),
    });

    useAbortableEffect(
        (signal) => {
            const hashtagsToInit = initialHashtagIds.filter(
                (id) => !selectedHashtags?.find((option) => option.value === id)
            );

            if (hashtagsToInit.length) {
                getTiktokHashTags({ id: hashtagsToInit.join(','), page_size: hashtagsToInit.length }, { signal })
                    .then((response) => {
                        if (response.status === 200) {
                            const options = response.data.results.map((hashtag) => ({
                                label: `#${hashtag.title}`,
                                value: hashtag.id,
                            }));
                            setSelectedHashtags((prev) => (prev ?? []).concat(options));
                            return;
                        }

                        showNotification('Hashtags not found', 'error');
                    })
                    .catch(() => showNotification('Could not initialise hashtag select', 'error'));
            }
        },
        [initialHashtagIds, selectedHashtags]
    );

    return { hashtagSelectProps, selectedHashtags, setSelectedHashtags };
}
