import { AppServer, RouteHandlerContext } from '../../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { ClientBooking } from './booking.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';

export const models = {
    clientBooking: Model as ModelDefinition<ClientBooking>,
};

export const serializers = {
    clientBooking: makeSerializer<ClientBooking>([]),
};

export const factories = {
    clientBooking: createFactory<ClientBooking>({
        brief(index: number) {
            return `brief ${index}`;
        },
        campaign_id(index: number) {
            return index;
        },
        influencer(index: number) {
            return index;
        },
        platform() {
            return 'TIKTOK';
        },
        posted_at() {
            return new Date().getTime() / 1000;
        },
        price(index: number) {
            return index;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const getClientBookingsListUrl = '/api/apex/client/booking/';
    server.get(getClientBookingsListUrl, function (this: RouteHandlerContext, schema, request) {
        return buildPaginatedResponse(schema.all('clientBooking'), {
            url: getClientBookingsListUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize('clientBooking', resource),
        });
    });
}
