import { call, encodeUrlSearchParams, fetchAll } from '../../helpers';
import { isPublicReportRequestInit } from '../../influencer';
import {
    ApiResponse,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { TiktokUserStats } from './tiktokUserStats.types';

export async function fetchTiktokUserStats(
    params: Partial<PaginatedRequest> & { user_ids: number[]; latest: boolean },
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<TiktokUserStats>, 200> | NotFoundResponse | ForbiddenResponse> {
    const formattedUserIds = params.user_ids?.map((id) => id.toString()).join(',');
    const response = await call(
        `/api/tiktok/user-stats/${encodeUrlSearchParams({ ...params, user_ids: formattedUserIds })}`,
        requestInit,
        {
            shouldBypassTokens: isPublicReportRequestInit(requestInit),
        }
    );

    if (!response.ok) {
        throw new Error(`Couldn't fetch tiktok user stats for ${params.user_ids?.toString()}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getAllTiktokUserStats(user_id: number, requestInit?: RequestInit): Promise<TiktokUserStats[]> {
    return fetchAll(`/api/tiktok/user-stats/${encodeUrlSearchParams({ user_id })}`, requestInit);
}
