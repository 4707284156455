import React, { CSSProperties, useMemo } from 'react';
import { ContentTag, InfluencerTag, Release } from '@round/api';
import { Column } from 'react-table';
import ClipboardTable from '../../../../../../utility/copyToClipboard/ClipboardTable/ClipboardTable';
import {
    buildInstagramUserUrl,
    displayOptionalNumericTableValue,
    formatNumberToKNotation,
} from '../../../../../../helpers';

export type InstagramProposalClipboardTableRow = {
    account: string;
    followerCount: number | null;
    estimatedViews: number | null;
    locations: InfluencerTag[];
    contentTags: ContentTag[];
};

type InstagramProposalClipboardTableProps = {
    items: InstagramProposalClipboardTableRow[];
    groupName: string;
    release: Release;
    brief: string;
};

const tfootStyles: CSSProperties = {
    background: '#eeeeee',
    fontWeight: 'bold',
};

const tdStyles: CSSProperties = {
    border: '1px solid black',
    padding: '5px',
};

const InstagramProposalClipboardTable = ({
    release,
    groupName,
    items,
    brief,
}: InstagramProposalClipboardTableProps) => {
    const columns: Column<InstagramProposalClipboardTableRow>[] = useMemo(
        () => [
            {
                Header: () => <div style={{ textAlign: 'left' }}>{groupName}</div>,
                accessor: 'account',
                Cell: ({ value, row: { original } }) => {
                    if (!value) {
                        return <>-</>;
                    }

                    const accountUrl = buildInstagramUserUrl(value);

                    return (
                        <div>
                            <a href={accountUrl} rel="noopener noreferrer" target="_blank">
                                {accountUrl}
                            </a>

                            {typeof original.followerCount === 'number' && (
                                <span style={{ marginLeft: '5px' }}>
                                    ({formatNumberToKNotation(original.followerCount)})
                                </span>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: 'Est. views',
                accessor: 'estimatedViews',
                Cell: ({ value }) => <>{displayOptionalNumericTableValue(value)}</>,
            },
            {
                Header: 'Country',
                accessor: 'locations',
                Cell: ({ value }) => <>{value.map((t) => t.name).join(', ')}</>,
            },
            {
                Header: 'Category',
                accessor: 'contentTags',
                Cell: ({ value }) => <>{value.map((t) => t.name).join(', ')}</>,
            },
            {
                Header: 'Brief',
                id: 'brief',
                Cell: () => <pre style={{ fontFamily: 'inherit' }}>{brief}</pre>,
            },
        ],
        [groupName, brief]
    );

    const caption = `${release.brand.name} - ${release.name}`;
    const totalEstimatedViews = useMemo(() => {
        if (items.some((item) => typeof item.estimatedViews === 'number')) {
            return items.reduce((acc, item) => acc + (item.estimatedViews ?? 0), 0);
        }

        return null;
    }, [items]);

    return (
        <ClipboardTable
            columns={columns}
            data={items}
            caption={caption}
            renderRow={(row, rowIndex, rowsArray) => {
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map((cell, index) => {
                            const isBriefCell = cell.column.id === 'brief';
                            if (isBriefCell && rowIndex > 0) {
                                return null;
                            }

                            return (
                                <td
                                    {...cell.getCellProps()}
                                    colSpan={index > 0 ? 1 : 2}
                                    rowSpan={isBriefCell && rowIndex === 0 ? rowsArray.length : 1}
                                    style={tdStyles}
                                >
                                    {cell.render('Cell')}
                                </td>
                            );
                        })}
                    </tr>
                );
            }}
            renderFooter={() => (
                <tfoot style={tfootStyles}>
                    <tr>
                        <td style={tdStyles} colSpan={2} />
                        <td style={tdStyles}>{displayOptionalNumericTableValue(totalEstimatedViews)}</td>
                        <td style={tdStyles} colSpan={3} />
                    </tr>
                </tfoot>
            )}
        />
    );
};

export default InstagramProposalClipboardTable;
