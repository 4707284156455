import { creatorbase } from '@round/api';
import { showNotification } from 'helpers';
import { useCampaigns } from '../../hooks/useCampaigns';
import { usePosts } from 'Modules/Plans/Project/contexts/PostsContext';
import { useState } from 'react';
import Button from 'ui-new/whitelabel/Button/Button';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import styles from './DeleteCampaignModal.module.css';
import cn from 'classnames';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    campaignId: creatorbase.Campaign['id'] | null;
};

const DeleteCampaignModal = ({ isOpen, onClose, campaignId }: Props) => {
    const { getCampaign, deleteCampaign } = useCampaigns();
    const campaign = campaignId ? getCampaign(campaignId) : null;

    const { data: posts } = usePosts();
    const postCount = campaign ? posts?.filter((post) => post.campaign_id === campaign.id).length ?? 0 : 0;
    const postCountLabel = `${postCount} post${postCount !== 1 ? 's' : ''}`;

    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const handleDelete = async () => {
        if (!campaign) {
            return;
        }

        try {
            setIsDeleteLoading(true);
            const response = await deleteCampaign(campaign.id);
            if (response.status === 204) {
                showNotification('Campaign deleted', 'info');
                onClose();
                return;
            }

            showNotification('Could not delete campaign', 'error');
        } catch {
            showNotification('Could not delete campaign', 'error');
        } finally {
            setIsDeleteLoading(false);
        }
    };

    return (
        <Modal className={styles.modal} closeOnOverlayClick isOpen={isOpen} onClose={onClose}>
            <Modal.Header>
                <Modal.Title>Delete campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.body}>
                <p className={cn(styles.copy, styles.bold)}>You’re about to permanently delete the following:</p>
                <div className={styles.banner}>
                    <p className={styles.copy}>
                        <span className={styles.bold}>Campaign</span>: {campaign?.name}
                    </p>

                    <p className={styles.copy}>
                        <span className={styles.bold}>Posts</span>: {postCountLabel}
                    </p>
                </div>

                <p className={styles.copy}>You cannot restore campaigns and posts. They are deleted permanently.</p>
            </Modal.Body>

            <Modal.Actions>
                <Button appearance="critical" isLoading={isDeleteLoading} onClick={handleDelete}>
                    Delete 1 campaign and {postCountLabel}
                </Button>
                <Button appearance="ghost" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteCampaignModal;
