import React, { useCallback, useState } from 'react';
import { Form, Formik, FormikHelpers, Field } from 'formik';
import cn from 'classnames';
import styles from './ForgotPassword.module.css';

import { resetPassword } from '@round/api';
import FormField from '../../../../ui/DataEntry/FormField/FormField';
import Label from '../../../../ui/DataEntry/Label/Label';
import Button from '../../../../ui/Buttons/Button/Button';
import { Helmet } from 'react-helmet';

type FormValues = { email: string };

const initialValues: FormValues = { email: '' };

const buildCopy = (letterWasSent: boolean, errorMessage: string | undefined) => {
    if (letterWasSent) {
        return 'An email with a link to set a new password has been sent to the email address you have entered';
    }

    if (errorMessage) {
        return errorMessage;
    }

    return `If you have forgotten your password, please enter your account's email address above
            and click the "Reset my password" button.
            You will receive an email that contains a link to set a new password.`;
};

const ForgotPassword = () => {
    const [letterWasSent, setLetterWasSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const copy = buildCopy(letterWasSent, errorMessage);

    const onSubmit = useCallback(async ({ email }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        try {
            setSubmitting(true);
            await resetPassword(email);
            setErrorMessage('');
            setLetterWasSent(true);
        } catch (e) {
            const errorMessage = e instanceof Error ? e.message : 'Could not submit form';
            setErrorMessage(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }, []);

    return (
        <>
            <Helmet title="Forgot password | round" />
            <div className={styles.container}>
                <h1 className={styles.title}>Forgot password</h1>

                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form className={styles.form}>
                            <FormField>
                                <Label htmlFor="email">Email</Label>
                                <Field id="email" name="email" placeholder="Enter your email" />
                            </FormField>

                            <Button
                                className={styles.submit}
                                loading={isSubmitting}
                                disabled={letterWasSent}
                                type="filled"
                                color="black"
                                htmlType="submit"
                            >
                                Reset my password
                            </Button>
                            <p className={cn(styles.copy, { [styles.error]: !!errorMessage })}>{copy}</p>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default ForgotPassword;
