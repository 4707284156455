import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { Campaign, CampaignStats } from './campaigns.types';

export type GetCampaignsParams = Partial<
    PaginatedRequest & {
        release_id: string;
        influencer_campaign_id: string;
    }
>;

export type GetCampaignsResponse = ApiResponse<PaginatedApiResponseData<Campaign>, 200> | NotFoundResponse;

export async function getCampaigns(
    params: GetCampaignsParams,
    requestInit?: RequestInit
): Promise<GetCampaignsResponse> {
    const response = await fetchWithToken(`/api/microwave/campaigns/${encodeUrlSearchParams(params)}`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get microwave campaigns');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type CampaignApiBody = Pick<
    Campaign,
    | 'budget'
    | 'currency_id'
    | 'planned_start_date'
    | 'planned_end_date'
    | 'platform'
    | 'team_members'
    | 'target_post_count'
> & {
    release_id: number;
    instagram_audio_id?: number;
    tiktok_audio_id?: number;
};

type PostCampaignResponse =
    | ApiResponse<Campaign, 201>
    | ApiResponse<ApiResponseError<CampaignApiBody>, 400>
    | ApiResponse<string[], 400>;

export async function postCampaign(data: CampaignApiBody): Promise<PostCampaignResponse> {
    const response = await fetchWithToken('/api/microwave/campaigns/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create microwave campaign');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export type PatchCampaignData = Pick<
    CampaignApiBody,
    'budget' | 'currency_id' | 'planned_end_date' | 'planned_start_date' | 'target_post_count' | 'team_members'
>;

type PatchCampaignResponse =
    | ApiResponse<Campaign, 200>
    | ApiResponse<ApiResponseError<CampaignApiBody>, 400>
    | ApiResponse<string[], 400>
    | NotFoundResponse;

export async function patchCampaign(id: number, data: Partial<CampaignApiBody>): Promise<PatchCampaignResponse> {
    const response = await fetchWithToken(`/api/microwave/campaigns/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not update microwave campaign');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetCampaignStatsResponse =
    | ApiResponse<CampaignStats, 200>
    | NotFoundResponse
    // TODO: remove when all platforms are supported
    // Gives error if campaign is not youtube campaign
    | ApiResponse<{ detail: string }, 403>;

export async function getCampaignStats(
    campaignId: number,
    requestInit?: RequestInit
): Promise<GetCampaignStatsResponse> {
    const response = await fetchWithToken(`/api/microwave/campaigns/${campaignId}/stats/`, requestInit);

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get campaign stats');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
