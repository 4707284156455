import { ApiResponse, NotFoundResponse, PaginatedApiResponseData } from '@round/api';

type FetchFunction<T> = (
    id: number,
    requestInit?: RequestInit
) => Promise<ApiResponse<T, 200> | NotFoundResponse | ApiResponse<{ detail: string }, 403>>;
export async function getAllItems<T>(fetchFunction: FetchFunction<T>, ids: number[]) {
    if (!ids.length) {
        return [];
    }

    return Promise.all(
        ids.map((id) =>
            fetchFunction(id).then((response) => {
                if (response.status === 404 || response.status === 403) {
                    return null;
                }

                return response.data;
            })
        )
    ).then((items) => items.filter((item: Awaited<T> | null): item is Awaited<T> => !!item));
}

type PaginatedData<T> = {
    data: T[];
    count: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
};

export type InfiniteScrollState<T> = PaginatedData<T> & {
    lastFetchedPage: number | undefined;
};

export function mapPaginatedApiData<T>(apiData: PaginatedApiResponseData<T>): PaginatedData<T> {
    return {
        data: apiData.results,
        count: apiData.count,
        hasNextPage: Boolean(apiData.next),
        hasPreviousPage: Boolean(apiData.previous),
    };
}
