import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import AllCampaigns from '../../../Modules/Advertising/Projects/containers/AllCampaigns/AllCampaigns';
import Card from '../../../ui/Card/Card';
import styles from './AllCampaignsPage.module.css';
import MobileAllCampaigns from '../../../Modules/Advertising/Projects/containers/MobileAllCampaigns/MobileAllCampaigns';
import { useIsViewportMobileDimensions } from '../../../Hooks/useIsViewportMobileDimensions';

const AllCampaignsPage = () => {
    const showMobilePage = useIsViewportMobileDimensions();

    return (
        <div>
            <NavBar />
            {showMobilePage ? (
                <div className={styles.mobileContainer}>
                    <MobileAllCampaigns />
                </div>
            ) : (
                <Card className={styles.tableContainer}>
                    <AllCampaigns />
                </Card>
            )}
        </div>
    );
};

export default AllCampaignsPage;
