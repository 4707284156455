import { microwave } from '@round/api';
import { buildInstagramUserUrl, buildTiktokUserUrl, buildYoutubeChannelUrl } from 'helpers';

const getInfluencerTiktokAccountData = (influencer: microwave.MicrowaveInfluencer) => {
    if (!influencer.tiktok_user_id) {
        return undefined;
    }

    return {
        name: influencer.tiktok_user_nickname,
        profileUrl: influencer.tiktok_user_unique_id ? buildTiktokUserUrl(influencer.tiktok_user_unique_id) : undefined,
        username: influencer.tiktok_user_unique_id,
    };
};

const getInfluencerInstagramAccountData = (influencer: microwave.MicrowaveInfluencer) => {
    if (!influencer.instagram_user_id) {
        return undefined;
    }

    return {
        name: influencer.instagram_user_username,
        profileUrl: influencer.instagram_user_username
            ? buildInstagramUserUrl(influencer.instagram_user_username)
            : undefined,
        username: influencer.instagram_user_username,
    };
};

const getInfluencerYoutubeAccountData = (influencer: microwave.MicrowaveInfluencer) => {
    if (!influencer.youtube_channel_id) {
        return;
    }

    return {
        name: influencer.youtube_channel_title,
        profileUrl: influencer.youtube_channel_youtube_id
            ? buildYoutubeChannelUrl(influencer.youtube_channel_youtube_id)
            : undefined,
        username: influencer.youtube_channel_title,
    };
};

const accountDetailsGetterMap: { [key in microwave.Platform]?: Function } = {
    tiktok: getInfluencerTiktokAccountData,
    instagram: getInfluencerInstagramAccountData,
    youtube: getInfluencerYoutubeAccountData,
};

export const getMicrowaveInfluencerAccountData = (
    influencer: microwave.MicrowaveInfluencer,
    platform: microwave.Platform
) => {
    const platformData = accountDetailsGetterMap[platform]?.(influencer);

    if (!platformData) {
        const nextAvailablePlatformData = Object.values(accountDetailsGetterMap)
            .map((getter) => getter?.(influencer))
            .find((data) => !!data);
        return nextAvailablePlatformData;
    }

    return platformData;
};
