import { InstagramInfluencerUser } from '@round/api';
import { Form, Field, Formik, FormikHelpers, FieldProps, ErrorMessage } from 'formik';
import React, { useMemo } from 'react';
import { StylesConfig } from 'react-select';
import { GenericDropdownOption } from '../../../../../App.types';
import { OptionsContext } from '../../../../../contexts/OptionsContext/OptionsContext';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import Label from '../../../../../ui/DataEntry/Label/Label';
import Select from '../../../../../ui/DataEntry/Select/Select';
import TextArea from '../../../../../ui/TextArea/TextArea';
import { ChangeValueTypes } from '../../../../../utility/utility.types';
import styles from './UpdateInstagramInfluencerUserForm.module.css';
import * as Yup from 'yup';

type InstagramInfluencerUserApiBody = Omit<InstagramInfluencerUser, 'id' | 'monitored_user' | 'user' | 'username'>;

export type UpdateInstagramInfluencerUserFormValues = Partial<
    ChangeValueTypes<
        Omit<InstagramInfluencerUserApiBody, 'tags'>,
        'reels_currency' | 'story_currency' | 'feed_currency',
        GenericDropdownOption<number> | null
    >
>;

type UpdateInstagramInfluencerUserFormProps = {
    handleSubmit: (
        values: UpdateInstagramInfluencerUserFormValues,
        helpers: FormikHelpers<UpdateInstagramInfluencerUserFormValues>
    ) => void;
    initialValues: UpdateInstagramInfluencerUserFormValues;
};

const currencySelectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        borderColor: '#C2CFE0',
        fontSize: '0.9rem',
        height: '2.4rem',
        minWidth: '7rem',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: '#C2CFE0',
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: '0.9rem',
    }),
};

const validationSchema = Yup.object({
    contact_details: Yup.string().required('Contact Details are required'),
    reels_cost: Yup.number().required('Reels Cost is a required field').typeError('Please specify a number cost'),
    reels_currency: Yup.object()
        .required('Reels Currency is a required field')
        .typeError('Reels Currency is a required field'),
});

const UpdateInstagramInfluencerUserForm = ({ handleSubmit, initialValues }: UpdateInstagramInfluencerUserFormProps) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const currencyOptions: GenericDropdownOption<number>[] = useMemo(
        () => currencies.map((c) => ({ value: c.id, label: c.name })),
        [currencies]
    );
    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
        >
            <Form className={styles.form}>
                <div className={styles.formSection}>
                    <Label htmlFor="contact_details" className={styles.formLabel}>
                        Contact Details
                    </Label>
                    <Field name="contact_details" id="contact_details">
                        {(props: FieldProps<UpdateInstagramInfluencerUserFormValues['contact_details']>) => (
                            <input
                                name={props.field.name}
                                className={styles.contactDetails}
                                value={props.field.value}
                                onChange={(e) => props.form.setFieldValue('contact_details', e.currentTarget.value)}
                                onBlur={(e) => {
                                    props.form.handleBlur(e);
                                    props.form.handleSubmit();
                                }}
                                placeholder="Enter contact details"
                            />
                        )}
                    </Field>
                    <ErrorMessage name="contact_details">
                        {(message) => <span className={styles.errorMessage}>{message}</span>}
                    </ErrorMessage>
                </div>

                <div className={styles.formSection}>
                    <Label htmlFor="paypal_email" className={styles.formLabel}>
                        PayPal Email
                    </Label>
                    <Field name="paypal_email" id="paypal_email">
                        {(props: FieldProps<UpdateInstagramInfluencerUserFormValues['paypal_email']>) => (
                            <input
                                name={props.field.name}
                                className={styles.paypalEmail}
                                value={props.field.value ?? ''}
                                onChange={(e) => props.form.setFieldValue('paypal_email', e.currentTarget.value)}
                                onBlur={(e) => {
                                    props.form.handleBlur(e);
                                    props.form.handleSubmit();
                                }}
                                placeholder="Enter PayPal email address"
                            />
                        )}
                    </Field>
                    <ErrorMessage name="paypal_email">
                        {(message) => <span className={styles.errorMessage}>{message}</span>}
                    </ErrorMessage>
                </div>

                <div className={styles.formSplitSection}>
                    <div className={styles.costColumn}>
                        <div className={styles.formSection}>
                            <label className={styles.formLabel}>Reels Cost</label>
                            <div className={styles.costField}>
                                <Field name="reels_currency" id="reels_currency">
                                    {(props: FieldProps<UpdateInstagramInfluencerUserFormValues['reels_currency']>) => (
                                        <Select
                                            isSearchable={false}
                                            styles={currencySelectStyles}
                                            className={styles.currencySelect}
                                            placeholder="currency"
                                            value={props.field.value}
                                            onChange={(option) => {
                                                props.form.setFieldValue(props.field.name, option);
                                                props.form.setFieldTouched(props.field.name);
                                            }}
                                            options={currencyOptions}
                                            onBlur={(e) => {
                                                props.form.handleBlur(e);
                                                props.form.handleSubmit();
                                            }}
                                            blurInputOnSelect
                                        />
                                    )}
                                </Field>
                                <Field name="reels_cost" id="reels_cost">
                                    {(props: FieldProps<UpdateInstagramInfluencerUserFormValues['reels_cost']>) => (
                                        <input
                                            className={styles.costInput}
                                            name={props.field.name}
                                            type="number"
                                            placeholder="cost"
                                            value={props.field.value ?? ''}
                                            onChange={(e) =>
                                                props.form.setFieldValue('reels_cost', e.currentTarget.value)
                                            }
                                            onBlur={(e) => {
                                                props.form.handleBlur(e);
                                                props.form.handleSubmit();
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage name="reels_cost">
                                {(message) => <span className={styles.errorMessage}>{message}</span>}
                            </ErrorMessage>
                            <ErrorMessage name="reels_currency">
                                {(message) => <span className={styles.errorMessage}>{message}</span>}
                            </ErrorMessage>
                        </div>
                        <div className={styles.formSection}>
                            <label className={styles.formLabel}>Story Cost</label>
                            <div className={styles.costField}>
                                <Field name="story_currency" id="story_currency">
                                    {(props: FieldProps<UpdateInstagramInfluencerUserFormValues['story_currency']>) => (
                                        <Select
                                            isSearchable={false}
                                            styles={currencySelectStyles}
                                            className={styles.currencySelect}
                                            placeholder="currency"
                                            value={props.field.value}
                                            onChange={(option) => {
                                                props.form.setFieldValue(props.field.name, option);
                                                props.form.setFieldTouched(props.field.name);
                                            }}
                                            options={currencyOptions}
                                            onBlur={(e) => {
                                                props.form.handleBlur(e);
                                                props.form.handleSubmit();
                                            }}
                                            blurInputOnSelect
                                        />
                                    )}
                                </Field>
                                <Field name="story_cost" id="story_cost">
                                    {(props: FieldProps<UpdateInstagramInfluencerUserFormValues['story_cost']>) => (
                                        <input
                                            className={styles.costInput}
                                            name={props.field.name}
                                            type="number"
                                            placeholder="cost"
                                            value={props.field.value ?? ''}
                                            onChange={(e) =>
                                                props.form.setFieldValue('story_cost', e.currentTarget.value)
                                            }
                                            onBlur={(e) => {
                                                props.form.handleBlur(e);
                                                props.form.handleSubmit();
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                        </div>
                        <div className={styles.formSection}>
                            <label className={styles.formLabel}>Feed Cost</label>
                            <div className={styles.costField}>
                                <Field name="feed_currency" id="feed_currency">
                                    {(props: FieldProps<UpdateInstagramInfluencerUserFormValues['feed_currency']>) => (
                                        <Select
                                            isSearchable={false}
                                            styles={currencySelectStyles}
                                            className={styles.currencySelect}
                                            placeholder="currency"
                                            value={props.field.value}
                                            onChange={(option) => {
                                                props.form.setFieldValue(props.field.name, option);
                                                props.form.setFieldTouched(props.field.name);
                                            }}
                                            options={currencyOptions}
                                            onBlur={(e) => {
                                                props.form.handleBlur(e);
                                                props.form.handleSubmit();
                                            }}
                                            blurInputOnSelect
                                        />
                                    )}
                                </Field>
                                <Field name="feed_cost" id="feed_cost">
                                    {(props: FieldProps<UpdateInstagramInfluencerUserFormValues['feed_cost']>) => (
                                        <input
                                            className={styles.costInput}
                                            name={props.field.name}
                                            type="number"
                                            placeholder="cost"
                                            value={props.field.value ?? ''}
                                            onChange={(e) =>
                                                props.form.setFieldValue('feed_cost', e.currentTarget.value)
                                            }
                                            onBlur={(e) => {
                                                props.form.handleBlur(e);
                                                props.form.handleSubmit();
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                        </div>
                    </div>
                    <div className={styles.formSection}>
                        <Label htmlFor="notes" className={styles.formLabel}>
                            Notes
                        </Label>
                        <Field name="notes" id="notes">
                            {(props: FieldProps<UpdateInstagramInfluencerUserFormValues['notes']>) => (
                                <TextArea
                                    className={styles.notesInput}
                                    name={props.field.name}
                                    placeholder="Enter notes"
                                    value={props.field.value ?? ''}
                                    onChange={(e) => props.form.setFieldValue('notes', e.currentTarget.value)}
                                    onBlur={(e) => {
                                        props.form.handleBlur(e);
                                        props.form.handleSubmit();
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default UpdateInstagramInfluencerUserForm;
