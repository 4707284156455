import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../ui/General/Modal/Modal';
import Button from '../../../../../ui/Buttons/Button/Button';
import styles from './DeletePostConfirmationModal.module.css';

type DeletePostConfirmationModalProps = {
    isModalOpen: boolean;
    handleDelete: () => void;
    deleteLoading: boolean;
    onClose: () => void;
};

const DeletePostConfirmationModal = ({
    isModalOpen,
    handleDelete,
    deleteLoading,
    onClose,
}: DeletePostConfirmationModalProps) => {
    return (
        <Modal
            closeOnOverlayClick
            isOpen={isModalOpen}
            onClose={onClose}
            className={styles.modal}
            role="dialog"
            ariaLabel="confirmDeleteModal"
        >
            <ModalTitle>Delete Post?</ModalTitle>
            <ModalContent>Are you sure you want to delete this post? This operation cannot be undone</ModalContent>
            <ModalFooter align="center">
                <Button loading={deleteLoading} type="bordered" color="negative" onClick={handleDelete}>
                    Delete
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeletePostConfirmationModal;
