import { ClientSimple } from './client.types';
import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';
import { ApiResponse, PaginatedApiResponseData, PaginatedFetchParams } from '../../types';

export async function getAllClients(requestInit?: RequestInit): Promise<ClientSimple[]> {
    return fetchAll('/api/advertising/viewsets/client/', requestInit);
}

export async function getClients(
    params: Partial<PaginatedFetchParams & { search: string; id: string; planner_id: string }>,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<ClientSimple>, 200>> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/client/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Couldnt fetch clients');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
