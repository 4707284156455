import styles from './AccountBadge.module.css';
import cn from 'classnames';
import { Image } from '@round/ui-kit';
import { getPlatformIcon } from '../../helpers';
import { buildInstagramUserUrl, buildTiktokUserUrl, buildYoutubeChannelUrl } from 'helpers';
import { microwave } from '@round/api';

const getProfileUrl = (platform: microwave.Platform, username: string) => {
    const urlBuilderMap: { [key in microwave.Platform]?: Function } = {
        tiktok: buildTiktokUserUrl,
        instagram: buildInstagramUserUrl,
        youtube: buildYoutubeChannelUrl,
    };

    return urlBuilderMap[platform]?.(username);
};

type Props = {
    platform: microwave.Platform;
    username: string;
    imageUrl?: string;
    platformId: string;
};

const AccountBadge = ({ platform, username, imageUrl, platformId }: Props) => {
    const profileUrl = getProfileUrl(platform, platformId);
    const Icon = getPlatformIcon(platform);

    return (
        <a
            onClick={(e) => {
                e.stopPropagation();
            }}
            className={cn(styles.container, styles[platform])}
            href={profileUrl}
            target="_blank"
            rel="noopener noreferrer"
        >
            <span className={styles.icon}>
                <Icon />
            </span>
            <Image src={imageUrl} className={styles.image} />
            <span>{username}</span>
        </a>
    );
};

export default AccountBadge;
