import { AppServer, RouteHandlerContext } from '../../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AdminInfluencer, AdminRetrieveInfluencer } from './influencer.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../../mirage.helpers';

export const models = {
    adminInfluencer: Model as ModelDefinition<AdminInfluencer>,
    adminRetrieveInfluencer: Model as ModelDefinition<AdminRetrieveInfluencer>,
};

export const serializers = {
    adminInfluencer: makeSerializer<AdminInfluencer>([]),
    adminRetrieveInfluencer: makeSerializer<AdminRetrieveInfluencer>([]),
};

export const factories = {
    adminInfluencer: createFactory<AdminInfluencer>({
        instagram_display_name(index: number) {
            return `instagram display name ${index}`;
        },
        instagram_user_id(index: number) {
            return index;
        },
        instagram_username(index: number) {
            return `instagram username ${index}`;
        },
        tiktok_display_name(index: number) {
            return `tiktok display name ${index}`;
        },
        tiktok_user_id(index: number) {
            return index;
        },
        tiktok_username(index: number) {
            return `tiktok username ${index}`;
        },
        manager_name(index: number) {
            return `manager name ${index}`;
        },
    }),
    adminRetrieveInfluencer: createFactory<AdminRetrieveInfluencer>({
        first_name(index: number) {
            return `first name ${index}`;
        },
        last_name(index: number) {
            return `last name ${index}`;
        },
        email(index: number) {
            return `email${index}@domain.com`;
        },
        phone(index: number) {
            return `${index}0000000000`;
        },
        birthday() {
            return new Date().toISOString();
        },
        location(index: number) {
            return index;
        },
        exclusivity() {
            const random = Math.random();
            return random > 0.33 ? 'coleton_partnership' : random > 0.66 ? 'exclusive' : 'non_exclusive';
        },
        agency_commission(index: number) {
            return 1 + index / 100;
        },
        manager_commission(index: number) {
            return 1 + index / 100;
        },
        address_line_1(index: number) {
            return `address line 1 ${index}`;
        },
        address_city(index: number) {
            return `address city ${index}`;
        },
        address_region(index: number) {
            return `address region ${index}`;
        },
        address_postcode(index: number) {
            return `address postcode ${index}`;
        },
        content_tags: [],
        payment_terms() {
            const random = Math.random();
            return random > 0.33 ? 'accelerated_45' : random > 0.66 ? 'accelerated_30' : 'client_terms';
        },
        preferred_currency() {
            const random = Math.random();
            return random > 0.33 ? 1 : random > 0.66 ? 2 : 5;
        },
        bank_name(index: number) {
            return `bank name ${index}`;
        },
        bank_account_number(index: number) {
            return `${index}${index}${index}${index}${index}${index}${index}${index}`;
        },
        bank_sort_code(index: number) {
            return `${index}${index}${index}${index}${index}${index}`;
        },
    }),
};

export function handleRequests(server: AppServer) {
    const getAdminInfluencersListUrl = '/api/apex/admin/influencer/';
    server.get(getAdminInfluencersListUrl, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const influencers = schema
            .all('adminInfluencer')
            .filter(
                (inf) =>
                    !search ||
                    inf.instagram_display_name?.toLowerCase().includes(search.toLowerCase()) ||
                    inf.instagram_username?.toLowerCase().includes(search.toLowerCase()) ||
                    inf.tiktok_display_name?.toLowerCase().includes(search.toLowerCase()) ||
                    inf.tiktok_username?.toLowerCase().includes(search.toLowerCase())
            );

        return buildPaginatedResponse(influencers, {
            url: getAdminInfluencersListUrl,
            serialize: (resource) => this.serialize(resource, 'adminInfluencer'),
            queryParams: request.queryParams,
        });
    });

    server.get('/api/apex/admin/influencer/:id/', function (this: RouteHandlerContext, schema, request) {
        const influencer = schema.find('adminRetrieveInfluencer', request.params.id);
        if (!influencer) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return influencer;
    });

    server.post('/api/apex/admin/influencer/', function (this: RouteHandlerContext, schema, request) {
        return schema.create('adminRetrieveInfluencer', JSON.parse(request.requestBody));
    });

    server.patch('/api/apex/admin/influencer/:id/', function (this: RouteHandlerContext, schema, request) {
        const influencer = schema.find('adminRetrieveInfluencer', request.params.id);
        if (!influencer) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        influencer.update(JSON.parse(request.requestBody));
        return influencer;
    });
}
