import { PopoverOnOverflow, PopoverOptions } from '@round/ui-kit';
import styles from './TruncationPopover.module.css';
import cn from 'classnames';

type Props = {
    content: React.ReactNode;
    options?: PopoverOptions;
    className?: string;
    isDisabled?: boolean;
    children: (setRef: (instance: HTMLElement | SVGSVGElement | null) => void) => React.ReactNode | undefined;
};

const TruncationPopover = ({ content, options, className, isDisabled, children }: Props) => {
    return (
        <PopoverOnOverflow
            popoverContent={<div className={cn(styles.container, className)}>{content}</div>}
            popoverOptions={options}
            isDisabled={isDisabled}
        >
            {children}
        </PopoverOnOverflow>
    );
};

export default TruncationPopover;
