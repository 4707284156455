import { PropsWithChildren, useState } from 'react';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import styles from './PopoutEditCell.module.css';
import cn from 'classnames';

type Props = PropsWithChildren<{
    target: React.ReactNode;
    isEditing: boolean;
    onEditingChange: (isEditing: boolean) => void;
    editClassName?: string;
    isReadonly?: boolean;
}>;

const PopoutEditCell = ({ target, isReadonly, isEditing, onEditingChange, editClassName, children }: Props) => {
    const [editContainer, setEditContainer] = useState<HTMLDivElement | null>(null);

    const handleOnEditingChange = (value: boolean) => {
        if (isReadonly) {
            return;
        }
        onEditingChange(value);
    };

    useOnClickOutside(editContainer, () => {
        if (!isEditing) {
            return;
        }
        handleOnEditingChange(false);
    });

    return (
        <div
            className={cn(styles.container, { [styles.isReadonly]: isReadonly })}
            onDoubleClick={() => {
                handleOnEditingChange(true);
            }}
        >
            {target}
            <div
                className={cn(styles.editContainer, editClassName, { [styles.visible]: isEditing })}
                ref={setEditContainer}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default PopoutEditCell;
