import { useState } from 'react';
import Button from 'ui/Button/Button';
import FullScreenModal from 'ui/FullScreenModal/FullScreenModal';
import { OutstandingPaymentsTableRow } from '../../OutstandingPayments/helpers';
import { getFailedPaymentsMessage, getSuccessfulPaymentsMessage, RequestStatus } from '../helpers';
import styles from '../PaymentModal.module.css';
import ManualPaymentsModalTable from './ManualPaymentsModalTable';
import { microwave } from '@round/api';

type Props = {
    rows: OutstandingPaymentsTableRow[];
    isOpen: boolean;
    onClose: () => void;
    makePayment: typeof microwave.makeBankTransfer;
    onComplete: () => void;
};

const ManualPaymentsModal = ({ rows, isOpen, onClose, makePayment, onComplete }: Props) => {
    const [paymentResults, setPaymentResults] = useState<{
        [key: number]: RequestStatus;
    }>({});

    const [arePaymentRequestsLoading, setArePaymentRequestsLoading] = useState(false);
    const [areRequestsComplete, setAreRequestsComplete] = useState(false);

    const handleMakePayment = async (id: number): Promise<RequestStatus> => {
        try {
            const response = await makePayment(id);

            if (response.status === 204) {
                return {
                    success: true,
                    error: null,
                };
            }

            const errorMessage = Array.isArray(response.data) ? response.data.join(', ') : response.data.detail;

            return {
                success: false,
                error: errorMessage,
            };
        } catch (e) {
            return {
                success: false,
                error: `Couldn't make payment`,
            };
        }
    };

    const handleConfirm = async () => {
        if (!rows.length) {
            return;
        }

        setArePaymentRequestsLoading(true);
        await Promise.allSettled(
            rows.map(async (row) => {
                const result = await handleMakePayment(row.id);

                setPaymentResults((prev) => ({
                    ...prev,
                    [row.id]: result,
                }));
            })
        );

        setArePaymentRequestsLoading(false);
        setAreRequestsComplete(true);
    };

    const handleClose = () => {
        const areSomeRequestsSuccessful = Object.values(paymentResults).some((r) => r.success);
        if (areRequestsComplete && areSomeRequestsSuccessful) {
            onComplete();
        }

        setPaymentResults({});
        setAreRequestsComplete(false);
        onClose();
    };

    const successfulPaymentsCount = Object.values(paymentResults).filter((r) => r.success).length;
    const failedPaymentsCount = Object.values(paymentResults).filter((r) => !r.success).length;

    const successfulPaymentsDisplayMessage = getSuccessfulPaymentsMessage(successfulPaymentsCount, rows.length);
    const failedPaymentsDisplayMessage = getFailedPaymentsMessage(failedPaymentsCount, rows.length);
    return (
        <FullScreenModal isOpen={isOpen} onClose={handleClose}>
            <FullScreenModal.Header>Manual payment confirmation</FullScreenModal.Header>
            <FullScreenModal.Content className={styles.content}>
                <ManualPaymentsModalTable
                    data={rows.map((row) => ({
                        ...row,
                        paymentRequestStatus: paymentResults[row.id],
                    }))}
                    arePaymentsLoading={arePaymentRequestsLoading}
                />
            </FullScreenModal.Content>

            <FullScreenModal.Footer>
                <div className={styles.buttons}>
                    {areRequestsComplete ? (
                        <>
                            <div className={styles.results}>
                                {successfulPaymentsCount > 0 && (
                                    <span className={styles.success}>{successfulPaymentsDisplayMessage}</span>
                                )}
                                {failedPaymentsCount > 0 && (
                                    <span className={styles.failure}>{failedPaymentsDisplayMessage}</span>
                                )}
                            </div>
                            <Button appearance="primary" onClick={handleClose}>
                                Close
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button appearance="ghost" onClick={handleClose} disabled={arePaymentRequestsLoading}>
                                Cancel
                            </Button>
                            <Button
                                appearance="primary"
                                onClick={() => handleConfirm()}
                                isLoading={arePaymentRequestsLoading}
                            >
                                Confirm payment
                            </Button>
                        </>
                    )}
                </div>
            </FullScreenModal.Footer>
        </FullScreenModal>
    );
};

export default ManualPaymentsModal;
