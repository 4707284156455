import React, { useState } from 'react';
import cn from 'classnames';
import { MediaPlanItemGroup } from '@round/api';
import { ReactComponent as TrashIcon } from '../../../../../assets/TrashIcon.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/CopyIcon.svg';
import { ReactComponent as ArrowIcon } from '../../../../../assets/ArrowIcon.svg';
import styles from './AdvertisingGroupBar.module.css';
import { useCheckUserGroupsAccess } from '../../../../Auth/hooks/useCheckUserGroupsAccess';
import { ProtectedByUserGroups } from '../../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import { FCWithChildren } from '../../../../../utility/utility.types';

type AdvertisingGroupBarProps = {
    expanded: boolean;
    onExpandToggle: () => void;
    group: MediaPlanItemGroup;
    numberOfMediaPlanItems: number;
    duration: string;
    budget: string;
    amountSpent: string;
    percentSpentOfGroupBudget: number | string;
    onGroupDelete: (group: MediaPlanItemGroup) => Promise<void>;
    onGroupNameChange: (groupId: number, name: string) => Promise<void>;
    onDuplicateGroup: (groupId: number) => Promise<void>;
    onCreateItem: (mediaPlanId: number, groupId: number) => Promise<void>;
    onGroupOrderingIndexIncrease: (groupId: number) => Promise<void>;
    onGroupOrderingIndexDecrease: (groupId: number) => Promise<void>;
};

const AdvertisingGroupBar: FCWithChildren<AdvertisingGroupBarProps> = ({
    group,
    numberOfMediaPlanItems,
    duration,
    budget,
    amountSpent,
    percentSpentOfGroupBudget,
    onGroupDelete,
    onGroupNameChange,
    onDuplicateGroup,
    onCreateItem,
    children,
    onGroupOrderingIndexDecrease,
    onGroupOrderingIndexIncrease,
    expanded,
    onExpandToggle,
}) => {
    const canEdit = useCheckUserGroupsAccess(['advertising_editor']);
    const [groupName, setGroupName] = useState(group.name);
    const expandButtonLabel = expanded ? 'Collapse group' : 'Expand group';
    const toggleGroupExpand = () => onExpandToggle();
    const handleGroupNameChange = () => {
        if (groupName !== group.name) {
            onGroupNameChange(group.id, groupName);
        }
    };

    return (
        <>
            <div className={cn(styles.row, styles.group)} data-test-id={`advertising-group-${group.id}`}>
                <button
                    className={cn(styles.groupButton, styles.expandButton, { [styles.expanded]: expanded })}
                    aria-label={expandButtonLabel}
                    onClick={toggleGroupExpand}
                >
                    <ArrowIcon />
                </button>
                <div className={styles.groupInfoColumns}>
                    <div className={styles.groupInfoColumn}>
                        <input
                            className={cn(styles.groupInfoColumnHeading, styles.groupName)}
                            value={groupName}
                            disabled={!canEdit}
                            onChange={(e) => setGroupName(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleGroupNameChange()}
                            onBlur={handleGroupNameChange}
                        />
                        <span className={styles.groupInfoColumnValue}>
                            {numberOfMediaPlanItems} plan item{numberOfMediaPlanItems !== 1 ? 's' : ''}
                        </span>
                    </div>

                    <div className={styles.groupInfoColumn}>
                        <span className={styles.groupInfoColumnHeading}>Duration</span>
                        <span className={styles.groupInfoColumnValue}>{duration}</span>
                    </div>
                    <div className={styles.groupInfoColumn}>
                        <span className={styles.groupInfoColumnHeading}>Total Budget</span>
                        <span className={cn(styles.groupInfoColumnValue, styles.budget)}>{budget}</span>
                    </div>
                    <div className={styles.groupInfoColumn}>
                        <span className={styles.groupInfoColumnHeading}>Amount Spent</span>
                        <span className={cn(styles.groupInfoColumnValue, styles.amountSpent)}>
                            {amountSpent}
                            <span className={styles.percentSpentOfGroupBudget}>
                                {percentSpentOfGroupBudget}% of total budget
                            </span>
                        </span>
                    </div>
                </div>

                <ProtectedByUserGroups groups={['advertising_editor']}>
                    <button className={styles.groupButton} onClick={() => onCreateItem(group.media_plan, group.id)}>
                        + New Item
                    </button>

                    <button
                        className={styles.groupButton}
                        aria-label="Delete group"
                        onClick={() => onGroupDelete(group)}
                    >
                        <TrashIcon />
                    </button>

                    <button
                        className={styles.groupButton}
                        aria-label="Duplicate group"
                        onClick={() => onDuplicateGroup(group.id)}
                    >
                        <CopyIcon />
                    </button>

                    <div className={cn(styles.groupButton, styles.reorderingButtons)}>
                        <button
                            className={styles.reorderingButton}
                            onClick={() => onGroupOrderingIndexDecrease(group.id)}
                        >
                            <ArrowIcon height={8} />
                        </button>

                        <button
                            className={cn(styles.reorderingButton, styles.increaseOrderIndexButton)}
                            onClick={() => onGroupOrderingIndexIncrease(group.id)}
                        >
                            <ArrowIcon height={8} />
                        </button>
                    </div>
                </ProtectedByUserGroups>
            </div>
            {expanded && children}
        </>
    );
};

export default AdvertisingGroupBar;
