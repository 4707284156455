import { getInstagramPosts, getInstagramTopUserVideos, InstagramTopVideo, InstagramPost } from '@round/api';
import { useCallback, useState } from 'react';
import { InfluencerPlan } from '../../../../../App.types';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import {
    fetchInstagramInfluencerPostResults,
    getAllInfluencerPlans,
} from '../../../../Advertising/InfluencerPlan/InfluencerPlan.api';
import {
    InstagramInfluencerPost,
    InstagramInfluencerPostResult,
} from '../../../../Advertising/InfluencerPlan/types/Instagram.types';
import { getAllInstagramInfluencerPosts } from '../../../Influencer.api';

type UseInstagramUserPromotionsParams = {
    influencerId: number | null | undefined;
    userId: number | null | undefined;
};

type UseInstagramUserPromotionsResults = {
    influencerPosts: InstagramInfluencerPost[];
    influencerPlans: InfluencerPlan[];
    postResults: InstagramInfluencerPostResult[];
    instagramPosts: InstagramPost[];
    topVideos: InstagramTopVideo[];
    promotionsDataLoading: boolean;
    errorLoadingPromotionsData: boolean;
};

export function useInstagramUserPromotions({
    influencerId,
    userId,
}: UseInstagramUserPromotionsParams): UseInstagramUserPromotionsResults {
    const [promotionsDataLoading, setPromotionsDataLoading] = useState(false);
    const [errorLoadingPromotionsData, setErrorLoadingPromotionsData] = useState(false);
    const [influencerPosts, setInfluencerPosts] = useState<InstagramInfluencerPost[]>([]);
    const [instagramPosts, setInstagramPosts] = useState<InstagramPost[]>([]);
    const [influencerPlans, setInfluencerPlans] = useState<InfluencerPlan[]>([]);
    const [postResults, setPostResults] = useState<InstagramInfluencerPostResult[]>([]);
    const [topVideos, setTopVideos] = useState<InstagramTopVideo[]>([]);

    const fetchPromotions = useCallback(
        async (requestInit: RequestInit) => {
            if (!influencerId) {
                return;
            }
            try {
                setErrorLoadingPromotionsData(false);
                setPromotionsDataLoading(true);

                const instagramInfluencerPosts = await getAllInstagramInfluencerPosts({ influencerId }, requestInit);
                setInfluencerPosts(instagramInfluencerPosts);
                if (!instagramInfluencerPosts.length) {
                    return;
                }

                const influencerPlanIds = Array.from(new Set(instagramInfluencerPosts.map((p) => p.plan_id)));
                const influencerPostIds = instagramInfluencerPosts.map((post) => post.id);
                const instagramPostIds = instagramInfluencerPosts
                    .map((post) => post.post)
                    .filter((postId): postId is number => postId !== null);

                const [influencerPlansResponse, instagramPosts, postResultsResponse] = await Promise.all([
                    influencerPlanIds.length
                        ? getAllInfluencerPlans({ id: influencerPlanIds.join() })
                        : Promise.resolve([]),
                    instagramPostIds.length
                        ? getInstagramPosts({
                              id: instagramPostIds.join(),
                              page_size: instagramPostIds.length,
                          }).then((response) => (response.status === 200 ? response.data.results : []))
                        : Promise.resolve([]),
                    fetchInstagramInfluencerPostResults({ post_id: influencerPostIds.join() }),
                ]);
                setInfluencerPlans(influencerPlansResponse);
                setPostResults(postResultsResponse);
                setInstagramPosts(instagramPosts);

                if (!userId) {
                    return;
                }
                const topVideosResponse = await getInstagramTopUserVideos(userId, requestInit);
                if (topVideosResponse.status === 404) {
                    setErrorLoadingPromotionsData(true);
                    return;
                }
                if (topVideosResponse.status === 200) {
                    setTopVideos(topVideosResponse.data);
                }
            } catch {
                setErrorLoadingPromotionsData(true);
            } finally {
                setPromotionsDataLoading(false);
            }
        },
        [influencerId, userId]
    );

    useAbortableEffect(
        (signal) => {
            fetchPromotions({ signal });
        },
        [fetchPromotions]
    );

    return {
        influencerPosts,
        influencerPlans,
        postResults,
        instagramPosts,
        topVideos,
        promotionsDataLoading,
        errorLoadingPromotionsData,
    };
}
