import { TabsContext } from 'ui/DataDisplay/Tabs/Tabs';
import useNonNullContext from 'Hooks/useNonNullContext';
import { PropsWithChildren } from 'react';

type TabPanelProps = PropsWithChildren<{ name: string }>;

const TabPanel = ({ children, name }: TabPanelProps) => {
    const { activeTab } = useNonNullContext(TabsContext);
    if (activeTab !== name) {
        return null;
    }

    return <>{children}</>;
};

export default TabPanel;
