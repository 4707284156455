import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { creatorbase, PaginatedApiResponseData, TiktokAudioAsset } from '@round/api';
import { createReducer } from 'helpers';

type Payload = {
    audios: PaginatedApiResponseData<creatorbase.TiktokAudio>;
    assets: TiktokAudioAsset[];
    timeSeries: creatorbase.TimeSeriesResponse<'posts_daily_change'> | null;
};
export type State = DataState<Payload>;
export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export type Actions =
    | ReducerAction<'tiktokTopAudiosIdle'>
    | ReducerAction<'loadTiktokTopAudios'>
    | ReducerActionWithPayload<'tiktokTopAudiosSuccess', Payload>
    | ReducerActionWithPayload<'tiktokTopAudiosErrorLoading', string>;

export const reducer = createReducer<State, Actions>({
    tiktokTopAudiosIdle: (state) => ({
        ...state,
        status: 'idle',
        error: null,
    }),
    loadTiktokTopAudios: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    tiktokTopAudiosErrorLoading: (state, { payload }) => ({
        ...state,
        status: 'error',
        error: payload,
    }),
    tiktokTopAudiosSuccess: (_, { payload }) => ({
        data: payload,
        error: null,
        status: 'success',
    }),
});
