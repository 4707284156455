import { Image, Skeleton } from '@round/ui-kit';
import styles from './InfluencerBadge.module.css';

type Props = {
    data: {
        name: string;
        username: string;
        imageUrl: string;
        profileUrl: string;
    };
    isLoading?: boolean;
};

const InfluencerBadge = ({ data, isLoading }: Props) => {
    const { name, username, imageUrl, profileUrl } = data;
    return (
        <div className={styles.container}>
            <Image className={styles.image} src={imageUrl} alt="Influencer" loading={isLoading} />
            <div className={styles.accountDetails}>
                <p>
                    {isLoading ? (
                        <Skeleton />
                    ) : (
                        <a
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!profileUrl) {
                                    e.preventDefault();
                                }
                            }}
                            href={profileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.name}
                        >
                            {name}
                        </a>
                    )}
                </p>

                <p>{isLoading ? <Skeleton /> : <p className={styles.username}>@{username}</p>}</p>
            </div>
        </div>
    );
};

export default InfluencerBadge;
