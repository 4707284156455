import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import useNonNullContext from '../../../../hooks/useNonNullContext';
import { TabsContext } from '../../Tabs';
import TabNavItem from '../TabNavItem/TabNavItem';
import styles from './TabNav.module.css';
import { getNamesFromChildren } from '../../helpers';
import cn from 'classnames';

type TabNavChildType = React.ReactElement<typeof TabNavItem>;
type TabNavProps = {
    children?: TabNavChildType | TabNavChildType[];
    containerClassName?: string;
    className?: string;
    indicatorClassName?: string;
    trackClassName?: string;
};

const TabNav = ({ children, containerClassName, className, indicatorClassName, trackClassName }: TabNavProps) => {
    const { activeTab } = useNonNullContext(TabsContext);
    const tabNames = useMemo(() => getNamesFromChildren(children), [children]);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const [activeElement, setActiveElement] = useState<HTMLButtonElement | null>(null);

    useLayoutEffect(() => {
        if (containerRef.current) {
            const activeElement = containerRef.current.querySelector<HTMLButtonElement>(`[name=${activeTab}]`);
            setActiveElement(activeElement);
        }
    }, [activeTab]);

    const activeElementRect = activeElement?.getBoundingClientRect();
    const indicatorWidth = activeElementRect?.width;
    const left = (activeElementRect?.left ?? 0) - (containerRef.current?.getBoundingClientRect()?.left ?? 0);

    if (!tabNames.length) {
        return null;
    }

    return (
        <div className={containerClassName}>
            <div ref={containerRef} className={cn(styles.container, className)}>
                {children}
            </div>

            <div className={cn(styles.track, trackClassName)}>
                <div style={{ left, width: indicatorWidth }} className={cn(styles.indicator, indicatorClassName)} />
            </div>
        </div>
    );
};

export default TabNav;
