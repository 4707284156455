import React from 'react';
import { components, SingleValueProps } from 'react-select';
import styles from './PaymentStatusSelectValue.module.css';
import { GenericDropdownOption } from '../../../../../App.types';
import cn from 'classnames';
import { TiktokInfluencerPost } from '@round/api';

const PaymentStatusSelectValue = ({
    children,
    ...props
}: SingleValueProps<GenericDropdownOption<TiktokInfluencerPost['payment_status']>>) => (
    <components.SingleValue {...props}>
        <span
            className={cn(styles.value, {
                [styles.paid]: props.data.value === 'PAID',
                [styles.unpaid]: props.data.value === 'UNPAID',
                [styles.invoiceReceived]: props.data.value === 'INVOICE_RECEIVED',
            })}
        >
            {children}
        </span>
    </components.SingleValue>
);

export default PaymentStatusSelectValue;
