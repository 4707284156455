import { SkeletonProps } from 'react-loading-skeleton';
import Skeleton from './Skeleton';

type Props = SkeletonProps & {
    isInitialized: boolean;
    children: React.ReactNode;
};

const SkeletonizedValue = ({ isInitialized, children, ...props }: Props) => {
    return <>{isInitialized ? children : <Skeleton {...props} />}</>;
};

export default SkeletonizedValue;
