import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { ProjectTableMeta } from '../types';
import { creatorbase } from '@round/api';
import { CellContext } from '@tanstack/react-table';
import moment from 'moment/moment';

type Row = creatorbase.Project;
type Context<T extends Row> = CellContext<T, T['associations_updated_at']>;

const getTableMeta = getTableMetaHelper<ProjectTableMeta<Row>>();

const UpdatedCell = <T extends Row>({ getValue, row: { original }, table }: Context<T>) => {
    const { getIsRowLoading } = getTableMeta(table);
    const date = getValue();

    if (getIsRowLoading(original)) {
        return <Skeleton width="10rem" />;
    }

    return moment(date).format('DD MMM YY');
};

export default UpdatedCell;
