import React from 'react';
import styles from './InstagramArtist.module.css';
import { NavBar } from '../../../SharedComponents/NavBar/NavBar';
import InstagramArtistList from './InstagramArtistList/InstagramArtistList';

const InstagramArtist = () => {
    return (
        <div className={styles.instagramArtist}>
            <NavBar />
            <InstagramArtistList />
        </div>
    );
};

export default InstagramArtist;
