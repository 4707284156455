import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import { ApiResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { InstagramInfluencerUserStats } from './instagramInfluencerUserStats.types';

type GetInstagramInfluencerUserStatsParams = Partial<
    PaginatedRequest & {
        min_follower_count: string;
        max_follower_count: string;
        min_avg_video_plays: string;
        max_avg_video_plays: string;
        min_reels_cost: string;
        max_reels_cost: string;
        order_by_asc: string;
        order_by_desc: string;
        search: string;
        location_tags: string;
        only_apex: boolean;
        content_tags: number[][];
    }
>;

export async function getInstagramInfluencerUserStats(
    params: GetInstagramInfluencerUserStatsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<InstagramInfluencerUserStats>, 200>> {
    const response = await fetchWithToken(
        `/api/instagram/viewsets/influencer-user-stats-v2/${encodeUrlSearchParams({
            ...params,
            content_tags: JSON.stringify(params.content_tags),
        })}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error('Couldnt fetch instagram influencer user stats');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getInstagramInfluencerUserStatsById(
    influencerId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<InstagramInfluencerUserStats, 200> | ApiResponse<{ detail: string }, 404>> {
    const response = await fetchWithToken(
        `/api/instagram/viewsets/influencer-user-stats-v2/${influencerId}/`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Couldnt fetch instagram influencer user stats for influencer ${influencerId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
