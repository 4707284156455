import { ColumnDef } from '@tanstack/react-table';
import WrapperPaginationTable from '../../../ui/WrapperTable/WrapperPaginationTable/WrapperPaginationTable';
import range from 'lodash/range';
import { useState } from 'react';

type Person = {
    firstName: string;
    lastName: string;
    age: number;
    visits: number;
    status: string;
    progress: number;
    occupation: string;
    email: string;
    companyName: string;
    hobby: string;
};

export const defaultData: Person[] = range(0, 1000).map((i) => ({
    firstName: `first name ${i}`,
    lastName: `last name ${i}`,
    age: 24,
    visits: 100,
    status: 'In Relationship',
    progress: 50,
    companyName: 'Company',
    email: 'example@gmail.com',
    occupation: 'Accountant',
    hobby: 'hiking',
}));

const TablePlayground = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const columns: ColumnDef<Person>[] = [
        {
            accessorKey: 'firstName',
            header: 'First name',
        },
        {
            accessorKey: 'lastName',
            header: 'Last name',
        },
        {
            accessorKey: 'age',
        },
        {
            accessorKey: 'visits',
            footer: ({ table }) => table.getRowModel().rows.reduce((acc, row) => row.original.visits + acc, 0),
        },
        {
            accessorKey: 'status',
        },
        {
            accessorKey: 'progress',
        },
        {
            accessorKey: 'email',
        },
        {
            accessorKey: 'occupation',
        },
        {
            accessorKey: 'companyName',
            header: 'Company name',
        },
        {
            accessorKey: 'hobby',
        },
    ];

    return (
        <WrapperPaginationTable
            columns={columns}
            data={defaultData}
            columnPinning={{
                left: ['firstName', 'lastName'],
                right: ['status'],
            }}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            count={defaultData.length}
        />
    );
};

export default TablePlayground;
