import { creatorbase, PaginatedApiResponseData } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

const platforms = ['instagram', 'tiktok', 'youtube'] as const;

type State = Record<
    creatorbase.Platform,
    DataState<PaginatedApiResponseData<creatorbase.Campaign>> & {
        isInitialized: boolean;
    }
>;

type Actions =
    | ReducerActionWithPayload<'campaignsIdle', creatorbase.Platform>
    | ReducerActionWithPayload<'loadCampaigns', creatorbase.Platform>
    | ReducerActionWithPayload<
          'campaignsSuccess',
          {
              platform: creatorbase.Platform;
              data: PaginatedApiResponseData<creatorbase.Campaign>;
          }
      >
    | ReducerActionWithPayload<'errorLoadingCampaigns', { platform: creatorbase.Platform; message: string }>
    | ReducerActionWithPayload<'campaignsInitialized', creatorbase.Platform>
    | ReducerActionWithPayload<'addCampaign', { data: creatorbase.Campaign }>
    | ReducerActionWithPayload<'updateCampaign', { data: creatorbase.Campaign }>
    | ReducerActionWithPayload<'removeCampaign', { campaignId: number }>
    | ReducerActionWithPayload<'reorderCampaigns', { platform: creatorbase.Platform; order: number[] }>
    | ReducerAction<'resetCampaigns'>;

export const initialState: State = platforms.reduce((acc, platform) => {
    acc[platform] = {
        data: null,
        error: null,
        status: 'idle',
        isInitialized: false,
    };
    return acc;
}, {} as State);

export const getCampaign = (state: State, campaignId: number) => {
    return Object.values(state)
        .flatMap((platform) => platform.data?.results ?? [])
        .find((c) => c.id === campaignId);
};

export const reducer = createReducer<State, Actions>({
    campaignsIdle: (state, { payload: platform }) => ({
        ...state,
        [platform]: {
            ...state[platform],
            status: 'idle',
        },
    }),
    loadCampaigns: (state, { payload: platform }) => ({
        ...state,
        [platform]: {
            ...state[platform],
            status: 'loading',
        },
    }),
    errorLoadingCampaigns: (state, { payload: { platform, message } }) => ({
        ...state,
        [platform]: {
            ...state[platform],
            error: message,
            status: 'error',
        },
    }),
    campaignsSuccess: (state, { payload: { platform, data } }) => {
        const currentResults = state[platform].data?.results || [];
        return {
            ...state,
            [platform]: {
                data: {
                    ...data,
                    results: currentResults.concat(data.results),
                },
                error: null,
                status: 'success',
            },
        };
    },
    campaignsInitialized: (state, { payload: platform }) => ({
        ...state,
        [platform]: {
            ...state[platform],
            isInitialized: true,
        },
    }),
    addCampaign: (state, { payload: { data } }) => {
        const platform = data.platform;
        const currentResults = state[platform].data?.results || [];
        return {
            ...state,
            [platform]: {
                data: {
                    ...state[platform].data,
                    results: currentResults.concat(data),
                },
                error: null,
                status: 'success',
            },
        };
    },
    updateCampaign: (state, { payload: { data } }) => {
        const platform = data.platform;
        const currentResults = state[platform].data?.results || [];
        const updatedResults = currentResults.map((c) => (c.id === data.id ? data : c));
        return {
            ...state,
            [platform]: {
                data: {
                    ...state[platform].data,
                    results: updatedResults,
                },
                error: null,
                status: 'success',
            },
        };
    },
    removeCampaign: (state, { payload: { campaignId } }) => {
        const campaign = getCampaign(state, campaignId);

        if (!campaign) {
            return state;
        }

        const platform = campaign.platform;
        const currentResults = state[platform].data?.results || [];
        const updatedResults = currentResults.filter((c) => c.id !== campaignId);

        return {
            ...state,
            [platform]: {
                data: {
                    ...state[platform].data,
                    results: updatedResults,
                },
                error: null,
                status: 'success',
            },
        };
    },
    reorderCampaigns: (state, { payload: { platform, order } }) => {
        const currentResults = state[platform].data?.results || [];
        const reorderedResults = order
            .map((id) => currentResults.find((c) => c.id === id))
            .filter((el) => el !== undefined);

        return {
            ...state,
            [platform]: {
                data: {
                    ...state[platform].data,
                    results: reorderedResults,
                },
                error: null,
                status: 'success',
            },
        };
    },
    resetCampaigns: () => initialState,
});
