import { useCallback, useMemo, useState } from 'react';
import useInstagramInfluencerUsers from '../useInstagramInfluencerUsers';
import { showNotification } from 'helpers';
import { debounce, uniq } from 'lodash';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { GenericDropdownOption, InstagramUserImage } from 'App.types';
import { ValueType } from 'react-select';
import { InstagramInfluencerUser, getInstagramInfluencerUser, InstagramUser } from '@round/api';
import { useInstagramUsers } from '../useInstagramUsers';
import { isNumber } from 'utility/utility';
import useInstagramUserImages from '../useInstagramUserImages';

const PAGE_SIZE = 25;

type Params = {
    initOn?: 'menuOpen' | 'mount';
    initialId?: number;
};

export type InstagramInfluencerUserOption = GenericDropdownOption<number> & {
    imageUrl: string | undefined;
    accountHandle: string | undefined;
};

export const mapInfluencerUserToOption = (
    influencerUser: InstagramInfluencerUser,
    user?: InstagramUser | undefined,
    image?: InstagramUserImage | undefined
): InstagramInfluencerUserOption => ({
    value: influencerUser.id,
    label: influencerUser.username,
    imageUrl: image?.avatar_thumb.cached_url || image?.avatar_thumb.original_url || '',
    accountHandle: user?.username,
});

export function useInstagramInfluencerUsersSelect({ initOn = 'menuOpen', initialId }: Params) {
    const [areOptionsInitialized, setAreOptionsInitialized] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const { data, status, fetchData: fetchInfluencerUsers, reset } = useInstagramInfluencerUsers();
    const { state: instagramUsersState, fetchData: fetchInstagramUsers } = useInstagramUsers();
    const { data: userImagesData, fetchData: fetchInstagramUserImages } = useInstagramUserImages();

    const handleFetchData = useCallback(
        async (...params: Parameters<typeof fetchInfluencerUsers>) => {
            try {
                const response = await fetchInfluencerUsers(...params);
                if (response.status !== 200) {
                    showNotification('Could not fetch influencer user options', 'error');
                    return false;
                }

                const existingInstagramUsers = response.data.results.map((u) => u.id) ?? [];
                const instagramUsersToFetch = uniq(
                    response.data.results
                        .map((inf) => inf.user)
                        .filter(isNumber)
                        .filter((id) => !existingInstagramUsers.includes(id))
                );

                if (instagramUsersToFetch.length) {
                    await Promise.allSettled([
                        fetchInstagramUsers({
                            id: instagramUsersToFetch.toString(),
                            page_size: instagramUsersToFetch.length,
                        }),
                        fetchInstagramUserImages(instagramUsersToFetch),
                    ]);
                }

                return response.status === 200;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                const errorMessage = e instanceof Error ? e.message : 'could not fetch influencer user options';
                showNotification(errorMessage, 'error');
            }
        },
        [fetchInfluencerUsers, fetchInstagramUserImages, fetchInstagramUsers]
    );

    const debouncedHandleFetchData = useMemo(() => debounce(handleFetchData, 700), [handleFetchData]);

    useAbortableEffect(
        (signal) => {
            const initOptions = () =>
                handleFetchData({ page: 1, page_size: PAGE_SIZE, search }, { signal }).then((success) => {
                    if (success) {
                        setAreOptionsInitialized(true);
                    }
                });

            if (!areOptionsInitialized && (initOn !== 'menuOpen' || isMenuOpen)) {
                initOptions();
            }
        },
        [handleFetchData, search, areOptionsInitialized, initOn, isMenuOpen]
    );

    const loadNextPage = () => {
        handleFetchData({ page: page + 1, page_size: PAGE_SIZE, search }).then((success) => {
            if (success) {
                setPage((page) => page + 1);
            }
        });
    };

    const options = useMemo<InstagramInfluencerUserOption[]>(
        () =>
            data?.influencerUsers.map((influencerUser) => {
                const user = instagramUsersState.data?.users.find((u) => u.id === influencerUser.user);
                const image = userImagesData?.images.find((image) => image.user_id === influencerUser.user);
                return mapInfluencerUserToOption(influencerUser, user, image);
            }) ?? [],
        [data?.influencerUsers, instagramUsersState.data?.users, userImagesData?.images]
    );

    const [value, setValue] = useState<ValueType<InstagramInfluencerUserOption, false>>(null);
    const [isInitialValueInitialized, setIsInitialValueInitialized] = useState(false);

    useAbortableEffect(
        (signal) => {
            const getInitialValue = (id: number) => {
                getInstagramInfluencerUser(id, { signal })
                    .then((response) => {
                        if (response.status === 200) {
                            setValue(mapInfluencerUserToOption(response.data));
                            setIsInitialValueInitialized(true);
                            return;
                        }

                        showNotification("Couldn't fetch instagram influencer user initial value", 'error');
                    })
                    .catch((error) => {
                        if (error instanceof Error && error.name === 'AbortError') {
                            return;
                        }

                        showNotification("Couldn't fetch instagram influencer user initial value", 'error');
                    });
            };

            if (initialId && !isInitialValueInitialized) {
                getInitialValue(initialId);
            }
        },
        [initialId, isInitialValueInitialized]
    );

    return {
        props: {
            value,
            onChange: setValue,
            options,
            inputValue: search,
            onInputChange: (value: string) => {
                if (value === search) {
                    return;
                }

                reset();
                debouncedHandleFetchData({ page: 1, page_size: PAGE_SIZE, search: value });
                setPage(1);
                setSearch(value);
            },
            onMenuScrollToBottom: () => {
                if (!areOptionsInitialized || status === 'loading' || !data?.next) {
                    return;
                }

                loadNextPage();
            },
            isLoading: status === 'loading' || (!!search.length && status === 'idle'),
            isMenuOpen,
            onMenuOpen: () => setIsMenuOpen(true),
            onMenuClose: () => setIsMenuOpen(false),
            filterOption: null,
        },
        resetValue: () => {
            setValue(null);
            setIsInitialValueInitialized(false);
        },
    };
}
