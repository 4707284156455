import { Project, CampaignType } from './Projects.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../../helpers';
import { PaginatedApiResponseData, PaginatedRequest } from '../../../App.types';

type FilterOptions = {
    search?: string;
    status?: Project['status'];
    creator_planner?: string | null;
    campaign_type?: CampaignType;
};

type GetProjectOptions = PaginatedRequest & FilterOptions;
export async function getProjects(
    options: GetProjectOptions,
    requestInit?: RequestInit
): Promise<PaginatedApiResponseData<Project>> {
    const params = encodeUrlSearchParams(options);
    const response = await fetchWithToken(`/api/advertising/project-plans/${params}`, requestInit);
    if (!response.ok) {
        throw new Error('Could not fetch project plans');
    }

    return response.json();
}

export async function getNumberOfActiveCampaigns(requestInit?: RequestInit) {
    const response = await fetchWithToken(
        `/api/advertising/project-plans/?page=1&page_size=1&status=live`,
        requestInit
    );
    if (!response.ok) {
        throw new Error('Could not fetch number of active campaigns');
    }
    const { count } = (await response.json()) as PaginatedApiResponseData<Project>;
    return count;
}
