import React, { useMemo } from 'react';
import { InfluencerTag } from '@round/api';
import styles from './Tags.module.css';
import Badge from '../../../../../../ui/Badge/Badge';
import cn from 'classnames';
import { ReactComponent as CloseIcon } from '../../../../../../assets/Close.svg';
import Select from '../../../../../../ui/DataEntry/Select/Select';
import { GenericDropdownOption } from '../../../../../../App.types';
import { StylesConfig } from 'react-select';

type InfluencerUserTagsProps = {
    readonly?: boolean;
    selected: InfluencerTag[];
    tags: InfluencerTag[];
    type: InfluencerTag['type'];
    selectTag: (tag: InfluencerTag) => void;
    deleteTag: (tag: InfluencerTag) => void;
};

const labels: { [key in InfluencerTag['type']]: string } = {
    CATEGORY: 'Categories',
    LOCATION: 'Top Locations',
};

export const influencerTagSelectStyles: StylesConfig = {
    valueContainer: (base) => ({
        ...base,
        padding: '0.125rem 0.3125rem',
        width: '6.25rem',
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: '0.625rem',
    }),
    dropdownIndicator: () => ({ display: 'none' }),
    menu: (base) => ({
        ...base,
        minWidth: '12.5rem',
    }),
};

const InfluencerUserTags = ({ tags, selected, readonly, type, selectTag, deleteTag }: InfluencerUserTagsProps) => {
    const options: GenericDropdownOption<number>[] = useMemo(
        () =>
            tags
                .filter((t) => !selected.find((s) => s.id === t.id))
                .map((t) => ({
                    value: t.id,
                    label: t.name,
                })),
        [selected, tags]
    );

    return (
        <>
            <div className={styles.container}>
                <span className={styles.label}>{labels[type]}</span>
                <div className={styles.tags}>
                    {selected.map((tag) => (
                        <Badge
                            key={tag.name}
                            label={tag.name}
                            className={cn(styles.tag, {
                                [styles.category]: tag.type === 'CATEGORY',
                                [styles.location]: tag.type === 'LOCATION',
                            })}
                            icon={() =>
                                !readonly ? (
                                    <button className={styles.deleteButton} onClick={() => deleteTag(tag)}>
                                        <CloseIcon className={styles.deleteIcon} />
                                    </button>
                                ) : null
                            }
                        />
                    ))}
                </div>
                {!readonly && (
                    <Select
                        styles={influencerTagSelectStyles}
                        placeholder={`Add ${type.toLowerCase()}`}
                        value={null}
                        options={options}
                        onChange={(option) => {
                            const tag = tags.find((t) => t.id === option?.value);
                            if (tag) {
                                selectTag(tag);
                            }
                        }}
                    />
                )}
            </div>
        </>
    );
};

export default InfluencerUserTags;
