import { creatorbase } from '@round/api';
import { CellContext } from '@tanstack/react-table';
import cn from 'classnames';
import styles from './ExpandCell.module.css';
import IconButton from 'ui-new/Buttons/IconButton/IconButton';
import { ReactComponent as ChevronRightIcon } from 'assets/whitelabel/ChevronRight.svg';

type Row = Pick<creatorbase.Campaign, 'id'>;

type Context<T extends Row> = CellContext<T, T['id']>;

const ExpandCell = <T extends Row>({ row }: Context<T>) => (
    <IconButton onClick={() => row.toggleExpanded()} className={styles.expandButton}>
        <ChevronRightIcon className={cn({ [styles.expandIconExpanded]: row.getIsExpanded() })} />
    </IconButton>
);

export default ExpandCell;
