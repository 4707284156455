import { YoutubeInfluencerPostBody } from '@round/api';
import { Select } from '@round/ui-kit';
import { GenericDropdownOption } from 'App.types';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { ErrorMessage, Field, FieldProps, Form } from 'formik';
import useNonNullContext from 'Hooks/useNonNullContext';
import { useMemo } from 'react';
import { ValueType } from 'react-select';
import FormField from 'ui/DataEntry/FormField/FormField';
import Label from 'ui/DataEntry/Label/Label';
import styles from './YoutubeInfluencerPostForm.module.css';
import cn from 'classnames';

export type FormValues = Pick<YoutubeInfluencerPostBody, 'cost' | 'post_url'> & {
    currency: ValueType<GenericDropdownOption<number>, false>;
};

type Props = {
    readOnlyFields?: (keyof FormValues)[];
};

const YoutubeInfluencerPostForm = ({ readOnlyFields }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);

    const currencyOptions = useMemo(
        () => currencies.map<GenericDropdownOption<number>>((c) => ({ label: c.name, value: c.id })),
        [currencies]
    );

    return (
        <Form className={styles.form}>
            <div className={styles.row}>
                <FormField className={cn(styles.field, styles.currency)}>
                    <Label htmlFor="currency">Currency</Label>
                    <Field name="currency">
                        {(props: FieldProps) => (
                            <Select
                                name="currency"
                                options={currencyOptions}
                                value={props.field.value}
                                onChange={(option) => {
                                    props.form.setFieldValue(props.field.name, option);
                                }}
                                isDisabled={readOnlyFields?.includes('currency')}
                            />
                        )}
                    </Field>
                    <ErrorMessage name="currency">
                        {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                    </ErrorMessage>
                </FormField>

                <FormField className={cn(styles.field, styles.cost)}>
                    <Label htmlFor="cost">Cost</Label>
                    <Field
                        name="cost"
                        type="number"
                        placeholder="Enter cost"
                        disabled={readOnlyFields?.includes('cost')}
                    />
                    <ErrorMessage name="cost">
                        {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                    </ErrorMessage>
                </FormField>
            </div>

            <FormField className={styles.field}>
                <Label htmlFor="post_url">Post URL</Label>
                <Field name="post_url" placeholder="Enter post url" disabled={readOnlyFields?.includes('post_url')} />
                <ErrorMessage name="post_url">
                    {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                </ErrorMessage>
            </FormField>
        </Form>
    );
};

export default YoutubeInfluencerPostForm;
