import React, { useMemo, useState } from 'react';
import OutstandingPaymentsTable, {
    OutstandingPaymentsTableRow,
} from './OutstandingPaymentsTable/OutstandingPaymentsTable';
import styles from './OutstandingPayments.module.css';
import Skeleton from 'react-loading-skeleton';
import useUrlState from '../../../../Hooks/useUrlState';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import PaypalPaymentModal from './PaymentModals/SinglePaymentModals/PaypalPaymentModal';
import ManualPaypalPaymentModal from './PaymentModals/SinglePaymentModals/ManualPaypalPaymentModal/ManualPaypalPaymentModal';
import Button from 'ui/Button/Button';
import IconButton from 'ui-new/Buttons/IconButton/IconButton';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';
import { ReactComponent as PayPalIcon } from 'assets/PayPalShort.svg';
import BulkPaymentModal from 'Modules/Finance/Payments/OutstandingPayments/PaymentModals/BulkPaymentModal/BulkPaymentModal';
import useOutstandingPayments from './useOutstandingPayments';
import OutstandingPaymentsFilters, {
    OutstandingPaymentsFiltersParams,
} from './OutstandingPaymentsFilters/OutstandingPaymentsFilters';

type UrlState = {
    page: number;
    page_size: number;
} & Partial<OutstandingPaymentsFiltersParams>;

const initialUrlState: UrlState = {
    page: 1,
    page_size: 10,
};

const getFilters = (urlState: Partial<Record<keyof UrlState, string>>): OutstandingPaymentsFiltersParams => {
    return {
        plan_id: urlState.plan_id ? Number(urlState.plan_id) : undefined,
        tiktok_influencer_user_id: urlState.tiktok_influencer_user_id
            ? Number(urlState.tiktok_influencer_user_id)
            : undefined,
    };
};

const OutstandingPayments = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState);
    const [selectedRows, setSelectedRows] = useState<OutstandingPaymentsTableRow[]>([]);
    const [isBulkPayPalPaymentModalOpen, setIsBulkPayPalPaymentModalOpen] = useState(false);

    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const pageSize = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;
    const filters = useMemo(() => getFilters(urlState), [urlState]);

    const [isPaypalPaymentModalOpen, setIsPaypalPaymentModalOpen] = useState(false);
    const [isManualPaypalPaymentModalOpen, setIsManualPaypalPaymentModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<OutstandingPaymentsTableRow | null>(null);

    const onClickPaypalPayment = (row: OutstandingPaymentsTableRow) => {
        setSelectedRow(row);
        setIsPaypalPaymentModalOpen(true);
    };

    const onClickManualPaypalPayment = (row: OutstandingPaymentsTableRow) => {
        setSelectedRow(row);
        setIsManualPaypalPaymentModalOpen(true);
    };

    const { fetchData, status, ...outstandingPaymentsData } = useOutstandingPayments();

    useAbortableEffect(
        (signal) => {
            const isOutstandingPaymentsDataInitialized = status === 'initialized' || status === 'error';
            if (!isOutstandingPaymentsDataInitialized) {
                fetchData(
                    {
                        page: page,
                        page_size: pageSize,
                        paypal_payment_status: 'UNPAID',
                        ...filters,
                    },
                    { signal }
                ).catch(() => {});
            }
        },
        [fetchData, filters, page, pageSize, status]
    );

    const isTableLoading = status === 'not-initialized' || status === 'loading';
    const count = outstandingPaymentsData.data?.count ?? 0;
    const rowsDisplayedText = `displaying ${outstandingPaymentsData.data?.rows.length ?? 0} out of ${count} post${
        count !== 1 ? 's' : ''
    }`;

    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.titles}>
                        <h1 className={styles.title}>PayPal Payments</h1>
                        <p className={styles.rowsDisplayedText}>
                            {isTableLoading ? <Skeleton width="7rem" /> : rowsDisplayedText}
                        </p>
                    </div>

                    <div className={styles.actions}>
                        <OutstandingPaymentsFilters
                            value={filters}
                            onChange={(val) => {
                                setUrlState({ ...val, page: 1 });
                                outstandingPaymentsData.reset();
                            }}
                        />

                        {selectedRows.length > 0 && (
                            <>
                                <IconButton hint="Clear selection" onClick={() => setSelectedRows([])}>
                                    <TrashIcon />
                                </IconButton>
                                <Button
                                    appearance="outlined"
                                    rightIcon={<PayPalIcon />}
                                    onClick={() => {
                                        setIsBulkPayPalPaymentModalOpen(true);
                                    }}
                                >
                                    Pay all selected
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <OutstandingPaymentsTable
                    isLoading={isTableLoading}
                    data={outstandingPaymentsData.data?.rows ?? []}
                    count={count}
                    page={page}
                    setPage={(page) => {
                        setUrlState({ page });
                        outstandingPaymentsData.reset();
                    }}
                    pageSize={pageSize}
                    setPageSize={(pageSize) => {
                        setUrlState({ page_size: pageSize || initialUrlState.page_size });
                        outstandingPaymentsData.reset();
                    }}
                    noDataLabel={
                        status === 'error' ? "Sorry, we're experiencing technical issues" : 'No outstanding payments'
                    }
                    onClickPaypalPayment={onClickPaypalPayment}
                    onClickManualPaypalPayment={onClickManualPaypalPayment}
                    updatePostPaymentRequest={outstandingPaymentsData.update}
                    selectedRows={selectedRows}
                    onSelectedRowsChange={setSelectedRows}
                />
            </div>

            <PaypalPaymentModal
                isOpen={isPaypalPaymentModalOpen}
                onClose={() => setIsPaypalPaymentModalOpen(false)}
                data={
                    selectedRow
                        ? {
                              beneficiary:
                                  selectedRow.tiktokUser?.nickname ||
                                  selectedRow.instagramUser?.name ||
                                  selectedRow.youtubeInfluencerPost?.channel_title ||
                                  '',
                              paypalEmailAddress: selectedRow.paypal_email_address || '',
                              cost: Number(selectedRow.amount),
                              currencyId: selectedRow.currency,
                              reference: selectedRow.payment_reference ?? '',
                              postPaymentRequestId: selectedRow.id,
                          }
                        : null
                }
                makePayment={outstandingPaymentsData.payPayPal}
                updatePostPaymentRequest={outstandingPaymentsData.update}
            />

            <ManualPaypalPaymentModal
                isOpen={isManualPaypalPaymentModalOpen}
                onClose={() => setIsManualPaypalPaymentModalOpen(false)}
                data={
                    selectedRow
                        ? {
                              paypalEmailAddress: selectedRow.paypal_email_address,
                              amount: Number(selectedRow.amount),
                              currency: selectedRow.currency,
                              reference: selectedRow.payment_reference,
                              postUrl:
                                  selectedRow.tiktok_influencer_post?.post_url ||
                                  selectedRow.instagram_influencer_post?.post_url ||
                                  selectedRow.youtubeInfluencerPost?.post_url ||
                                  '',
                              postPaymentRequestId: selectedRow.id,
                          }
                        : null
                }
                updatePostPaymentRequest={outstandingPaymentsData.update}
                makePayment={outstandingPaymentsData.payPayPalManually}
            />

            <BulkPaymentModal
                isOpen={isBulkPayPalPaymentModalOpen}
                closeModal={() => setIsBulkPayPalPaymentModalOpen(false)}
                data={selectedRows}
                onComplete={() => {
                    setSelectedRows([]);
                    outstandingPaymentsData.reset();
                }}
                makePayment={outstandingPaymentsData.payPayPalBulk}
                updatePaymentRequest={outstandingPaymentsData.update}
            />
        </>
    );
};

export default OutstandingPayments;
