import { getYouTubeInfluencerPosts, GetYouTubeInfluencerPostsParams, YouTubeInfluencerPost } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';

type State = DataState<YouTubeInfluencerPost[]>;

const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export function useYoutubeInfluencerPosts() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(async (params: GetYouTubeInfluencerPostsParams, requestInit?: RequestInit) => {
        try {
            const response = await getYouTubeInfluencerPosts(params, requestInit);

            if (response.status === 404) {
                setState((prev) => ({
                    data: prev.data,
                    status: 'error',
                    error: 'Could not get YouTube influencer posts',
                }));
                return response;
            }

            setState((prev) => ({
                data: (prev.data ?? []).concat(response.data.results),
                error: null,
                status: 'success',
            }));
            return response;
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                throw e;
            }

            setState((prev) => ({ data: prev.data, error: 'Could not get YouTube influencer posts', status: 'error' }));
            throw e;
        }
    }, []);

    return {
        ...state,
        fetchData,
    };
}
