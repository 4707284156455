import Button from '../../Buttons/Button/Button';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../Modal/Modal';
import styles from './ConfirmationModal.module.css';

export type ConfirmationModalProps = {
    isOpen: boolean;
    handleConfirm: () => void;
    confirmActionLoading?: boolean;
    onClose: () => void;
    modalTitle: string;
    modalText: string;
    confirmButtonText: string;
    confirmButtonClassName?: string;
};

const ConfirmationModal = ({
    isOpen,
    handleConfirm,
    confirmActionLoading,
    onClose,
    modalTitle,
    modalText,
    confirmButtonText,
    confirmButtonClassName,
}: ConfirmationModalProps) => {
    return (
        <Modal
            closeOnOverlayClick
            isOpen={isOpen}
            onClose={onClose}
            className={styles.modal}
            role="dialog"
            ariaLabel="confirmModal"
        >
            <ModalTitle className={styles.title}>{modalTitle}</ModalTitle>
            <ModalContent>{modalText}</ModalContent>
            <ModalFooter className={styles.footer}>
                <Button
                    className={confirmButtonClassName}
                    loading={confirmActionLoading}
                    color="negative"
                    onClick={handleConfirm}
                >
                    {confirmButtonText}
                </Button>
                <Button type="filled" color="black" onClick={onClose}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmationModal;
