import React from 'react';
import Badge from '../../../components/Badge/Badge';
import { Creative } from '../../../../../../App.types';
import styles from './CreativesBadges.module.css';
import cn from 'classnames';

type CreativesBadgesProps = {
    items: Creative[];
    className?: string;
    onClick?: (creative: Creative) => void;
    disabled?: boolean;
};

const CreativesBadges = ({ items, className, onClick, disabled }: CreativesBadgesProps) => {
    return (
        <div className={cn(styles.row, className)}>
            {items.map((c) => (
                <Badge disabled={disabled} key={c.id} label={c.name} onClick={() => onClick && onClick(c)} />
            ))}
        </div>
    );
};

export default CreativesBadges;
